import React, { ReactNode } from "react"
import { ServiceManage, ServiceManageStatus } from "../../../../services/Manage.service"
import {
    ServicesStatusEnforcingTemplate,
    ServicesStatusPermissiveTemplate,
    ServicesStatusNoPolicyTemplate,
    ServicesStatusInactiveTemplate,
} from "./ServicesStatusCellRenderer.template"
import { LocalizationService } from "../../../../services/localization/Localization.service"

export class ServicesStatusCellRenderer extends React.Component<
    ServicesStatusCellRendererProps,
    {}
> {
    public render(): ReactNode {
        switch (this.props.data.status) {
            case ServiceManageStatus.POLICY_ENFORCING:
                return ServicesStatusEnforcingTemplate.call(this)
            case ServiceManageStatus.POLICY_PERMISSIVE:
                return ServicesStatusPermissiveTemplate.call(this)
            case ServiceManageStatus.NO_POLICY:
                return ServicesStatusNoPolicyTemplate.call(this)
            default:
                return ServicesStatusInactiveTemplate.call(this)
        }
    }

    private localizationService: LocalizationService = new LocalizationService()
}

interface ServicesStatusCellRendererProps {
    data: ServiceManage
}
