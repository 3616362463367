import { useMutation } from "@tanstack/react-query"
import { useAuthService } from "../../pre-v3/services/Auth.service"
import { MswUrl } from "../../pre-v3/services/Auth.store"
import { MswAuthApi } from "../api/MswAuth.api"
import { setQueryParmaToUrl } from "../../utils/url.utils"

export function useGenerateCscCurtainUrl() {
    const authApi = new MswAuthApi()
    const authService = useAuthService()
    return useMutation<MswUrl, string, MswUrl>({
        mutationFn: async (mswUrl: MswUrl) => {
            const jwt = authService.getCscAccessToken()
            const accessCode = await authApi.generateCscAccessCode({ msw_token: jwt })
            return setQueryParmaToUrl(mswUrl, "code", accessCode) as MswUrl
        },
    })
}
