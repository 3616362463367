// externals
import * as React from "react"
// locals
import { ServiceFormProps, useUpdateTags } from "../../service-form"
import { FormLabel, FormSection, Select, MultiInput, PortInput } from "../../../../components"
import { useServiceLocalization } from "../../../../services"
import styles from "./ServiceConnection.module.scss"
import { FormUtil } from "../../../../utils/Form.util"
import { ServiceTag } from "../../../../api/Manage.api"

type Props = ServiceFormProps & {
    defaultAllowOverride?: boolean
    showIncludeDomains?: boolean
}

export default function ServiceConnectionForm({
    edit,
    defaultAllowOverride = false,
    showIncludeDomains,
}: Props) {
    // grab the reference to
    const localization = useServiceLocalization()

    const spec = edit?.spec

    // some state to track the form
    const [port, setPort] = React.useState(spec?.metadata.tags[ServiceTag.APP_LISTEN_PORT] || "")
    const specAllowOverride = spec?.metadata.tags[ServiceTag.ALLOW_USER_OVERRIDE]
    const [allowOverride, setAllowOverride] = React.useState(
        typeof specAllowOverride === "boolean" ? specAllowOverride : defaultAllowOverride
    )
    const [includeDomains, setIncludeDomains] = React.useState<string[]>(
        spec?.metadata.tags[ServiceTag.INCLUDE_DOMAINS] || []
    )

    // when either of the values change, update the parent
    const updateTags = useUpdateTags()

    React.useEffect(() => {
        // only assign the connection settings for the right service app types
        updateTags((tags) => {
            const newTags: AnyMap = {
                ...tags,
                [ServiceTag.APP_LISTEN_PORT]: port,
                [ServiceTag.ALLOW_USER_OVERRIDE]: allowOverride,
            }

            if (showIncludeDomains) {
                newTags[ServiceTag.INCLUDE_DOMAINS] = includeDomains
            }

            return newTags
        })
    }, [port, allowOverride, includeDomains, showIncludeDomains, updateTags])

    // we need to validate the port value before we update out state
    const onPortChange = (value: string) => {
        const regex = /^[0-9\b]+$/
        const flag: boolean = FormUtil.isValidInput(value, regex)
        if (value === "" || flag) {
            setPort(value)
        }
    }

    return (
        <FormSection title={localization.getString("serviceConnectionSettings")}>
            <FormLabel
                title={localization.getString("assignedListenPortDescription")}
                htmlFor="connection-port"
            >
                <PortInput className={styles.port} value={port} onChange={onPortChange} id="port" />
            </FormLabel>
            <FormLabel
                title={localization.getString("giveEndUsersTheAbilityToOverrideTheseSettings")}
                htmlFor="override"
            >
                <div className={styles.selectContainer}>
                    <Select
                        options={[
                            {
                                displayName: localization.getString("yes"),
                                value: true,
                            },
                            {
                                displayName: localization.getString("no"),
                                value: false,
                            },
                        ]}
                        // @ts-ignore: select can handle this but I don't want the PR to leak more than it does to
                        //             and update the types
                        value={allowOverride}
                        onChange={(val) => setAllowOverride(Boolean(val))}
                    />
                </div>
            </FormLabel>
            {showIncludeDomains && (
                <FormLabel
                    title={localization.getString("domainsToProxy")}
                    tooltip={localization.getString("domainsToProxyDescription")}
                    htmlFor="domainsToProxy"
                >
                    <MultiInput
                        initialValues={includeDomains}
                        className={styles.input}
                        onSubmit={setIncludeDomains}
                        placeholder={localization.getString("domainsToProxyPlaceholder")}
                    />
                </FormLabel>
            )}
        </FormSection>
    )
}
