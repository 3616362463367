import { ITooltipParams, ValueFormatterParams } from "ag-grid-community"
import { DateUtil } from "./Date.util"
import { SelectValue } from "./SelectValue.util"

const FILTER_MODAL_PREFIX = "fm_"

export default class AgGridUtil {
    public static dateFormatter(params: ValueFormatterParams): string {
        return params.value ? DateUtil.format(params.value) : "-"
    }

    public static largeTimestampFormatter(params: ValueFormatterParams): string {
        return params.value ? DateUtil.format(DateUtil.convertLargeTimestamp(params.value)) : "-"
    }

    public static epochTimeFormatter({ value }: ValueFormatterParams): string {
        if (!value) {
            return "-"
        }

        const valueInt: number = typeof value === "string" ? parseInt(value) : value
        var d = new Date(0) // 0 sets the date to the epoch
        d.setUTCSeconds(valueInt / 1000)
        return d.toLocaleDateString("en-US")
    }

    public static nullableStringFormatter(params: ValueFormatterParams): string {
        return params.value || "-"
    }

    public static stringArrayFormatter(params: ValueFormatterParams): string {
        const arr: string[] = params.value
        return arr && arr.length > 0 ? arr.join(", ") : "-"
    }

    public static listFormatter(params: ValueFormatterParams): string {
        const arr: string[] = params.value || []
        return arr.length > 0 ? arr.join("\n") : "-"
    }

    public static listLengthFormatter(params: ValueFormatterParams): string {
        return params.value ? params.value.length.toString() : "0"
    }

    public static truncatedListFormatter(params: ValueFormatterParams): string {
        const arr: string[] = params.value || []
        if (arr.length > 2) {
            params.value = arr.slice(0, 2).concat("...")
        }
        return AgGridUtil.listFormatter(params)
    }

    public static alphaBetComparator(a: string, b: string): number {
        const firstString = a.toLowerCase().trim()
        const secondString = b.toLowerCase().trim()

        if (firstString > secondString) {
            return 1
        } else if (firstString < secondString) {
            return -1
        } else {
            return 0
        }
    }

    public static defaultTooltipValueGetter(params: ITooltipParams): string {
        if (typeof params.value === "string") {
            return AgGridUtil.textTooltipValueGetter(params)
        } else if (Array.isArray(params.value)) {
            return params.value.join(", ")
        } else {
            return ""
        }
    }

    private static textTooltipValueGetter(params: ITooltipParams): string {
        return params.valueFormatted || params.value
    }

    public static linkTooltipValueGetter(params: ITooltipParams): string {
        return params.value
    }

    public static serializeFilterModel(
        model: FilterModel,
        urlSearchParams = window.location.search
    ): string {
        const params: URLSearchParams = new URLSearchParams(urlSearchParams)
        for (let key of params.keys()) {
            if (key.startsWith(FILTER_MODAL_PREFIX)) {
                const actualKey = key.slice(3)
                if (!model[actualKey]) {
                    params.delete(key)
                }
            }
        }
        for (let key in model) {
            const fmKey = `${FILTER_MODAL_PREFIX}${key}`
            const value = model[key]?.filter

            if (value) {
                params.set(fmKey, value)
            } else {
                params.delete(fmKey)
            }
        }
        return params.toString()
    }

    public static deserializeFilterModel<FilterById extends string>(
        urlSearchParams = window.location.search
    ): FilterModel<FilterById> {
        try {
            const params: URLSearchParams = new URLSearchParams(urlSearchParams)
            const filter: FilterModel = {}
            for (let key of params.keys()) {
                if (key.startsWith(FILTER_MODAL_PREFIX)) {
                    const actualKey = key.slice(3)
                    const value: string | null = params.get(key)
                    if (value) {
                        filter[actualKey] = { filter: value }
                    }
                }
            }
            return filter
        } catch {
            return {}
        }
    }
}

export interface KeyValuePair {
    prevKey?: string
    key: string
    value: string
}

export type Paginated<T> = {
    data: T[]
    total: number
}

export interface PaginatedSearch<
    SortById extends string = string,
    FilterById extends string = string,
> {
    skip: number
    limit: number
    sortModel?: SortBy<SortById>[]
    filterModel?: FilterModel<FilterById>
    order?: "asc" | "desc"
    order_by?: string
}

export interface SortBy<SortById extends string = string> {
    colId: SortById
    sort: "asc" | "desc"
}

export function replaceColumnKeys<BeforeId extends string, AfterId extends string>(
    sortModel: SortBy<BeforeId>[],
    beforeToAfterDict: Record<BeforeId, AfterId>
): SortBy<AfterId>[] {
    return sortModel.map(({ colId, sort }) => ({ colId: beforeToAfterDict[colId], sort }))
}

export type FilterModel<FilterById extends string = string> = Partial<
    Record<FilterById, { filter: string }>
>

export interface DataFilter<FilterKey extends string = string> {
    key: FilterKey
    displayName?: string
    type: DataFilterType
    options?: SelectValue[]
    dataSource?: (search: string) => Promise<SelectValue[]>
    value?: SelectValue | SelectValue[]
}

export enum DataFilterType {
    INPUT,
    MULTIINPUT,
    LIST,
    MULTI_LIST,
    LOOKUP,
    MULTILOOKUP,
    DATETIMERANGE,
    DEBOUNCEDINPUT,
}
