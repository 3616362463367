import { IconDefinition } from "@fortawesome/fontawesome-svg-core"
import React, { Ref, forwardRef } from "react"
import { Button } from "../button/Button.component"

import styles from "./IconButton.module.scss"
import classNames from "classnames"
import { Icon } from "../Icon/Icon.component"

export interface Props
    extends React.ButtonHTMLAttributes<HTMLButtonElement>,
        IconButtonContentProps {
    disabled?: boolean
    action?: boolean
    brand?: boolean
}

/**
 * Icon buttons are commonly found in app bars and toolbars.
 *
 * Icons are also appropriate for toggle buttons that allow a single choice to
 * be selected or deselected, such as adding or removing a star to an item.
 *
 * You can find arguments for this component in <a
 * href="https://mui.com/material-ui/react-button/" target="_blank">the Button
 * documentation in Material UI</a>.
 */
function IconButtonComponent(props: Props, ref: Ref<HTMLButtonElement>): JSX.Element {
    const { className, icon, brand, action, ...buttonProps } = props

    const classes = classNames(styles.iconButton, className, {
        [styles.action]: action,
    })

    return (
        <Button brand={brand} {...buttonProps} ref={ref} className={classes}>
            <IconButtonContent icon={icon} />
        </Button>
    )
}

export const IconButton = forwardRef(IconButtonComponent)
interface IconButtonContentProps {
    /**
     * An icon from the Font Awesome library
     */
    icon: IconDefinition
}

function IconButtonContent(props: IconButtonContentProps) {
    const { icon } = props

    return <Icon icon={icon} />
}
