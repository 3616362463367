import { QueryClient, UseQueryResult, useQuery, useQueryClient } from "@tanstack/react-query"

import { Singleton } from "../../pre-v3/decorators/Singleton.decorator"
import { ClusterApi, ClusterRes } from "../api/Cluster.api"
import { isGlobalEdgeCluster } from "./shared/Cluster"
import { AccessTier, AccessTierService } from "./AccessTier.service"
import { Connector, getConnectors } from "./Connector.service"
import { AccessTierGroupApi, AccessTierGroupRes } from "../api/AccessTierGroup.api"

@Singleton("ClusterService")
class ClusterService {
    enableATG: boolean

    constructor(enableATG: boolean) {
        this.enableATG = enableATG
    }
    public getClusters(): Promise<Cluster[]> {
        return Promise.all([
            this.clusterApi.getClusters(),
            getConnectors(),
            this.accessTierService.getAccessTiers(this.enableATG),
            this.enableATG
                ? this.accessTierGroupApi.getAccessTierGroups()
                : { access_tier_groups: [] },
        ]).then(([clusterRes, connectors, accessTiers, { access_tier_groups }]) => {
            if (clusterRes.Configs) {
                const clusters: Cluster[] = clusterRes.Configs.map(
                    this.mapClusterConfigResToClusters
                )
                const connectorMap: Map<string, Connector[]> = connectors.data.reduce(
                    (map, connector) => {
                        const existing: Connector[] | undefined = map.get(connector.clusterName)
                        if (existing) {
                            existing.push(connector)
                            map.set(connector.clusterName, existing)
                        } else {
                            map.set(connector.clusterName, [connector])
                        }
                        return map
                    },
                    new Map<string, Connector[]>()
                )

                const accessTierMap: Map<string, AccessTier[]> = accessTiers.data.reduce(
                    (map, accessTier) => {
                        const existing: AccessTier[] | undefined = map.get(accessTier.clusterName)
                        if (existing) {
                            existing.push(accessTier)
                            map.set(accessTier.clusterName, existing)
                        } else {
                            map.set(accessTier.clusterName, [accessTier])
                        }
                        return map
                    },
                    new Map<string, AccessTier[]>()
                )

                const accessTierGroupMap: Map<string, AccessTierGroup[]> =
                    access_tier_groups.reduce((map, accessTierGroup) => {
                        const existing: AccessTierGroup[] | undefined = map.get(
                            accessTierGroup.cluster_name
                        )
                        if (existing) {
                            existing.push(mapAccessTierGroupRes(accessTierGroup))
                            map.set(accessTierGroup.cluster_name, existing)
                        } else {
                            map.set(accessTierGroup.cluster_name, [
                                mapAccessTierGroupRes(accessTierGroup),
                            ])
                        }
                        return map
                    }, new Map<string, AccessTierGroup[]>())

                clusters.forEach((cluster: Cluster) => {
                    cluster.accessTiers = accessTierMap.get(cluster.name)
                    cluster.connectors = connectorMap.get(cluster.name)
                    cluster.accessTierGroups = accessTierGroupMap.get(cluster.name)
                })

                return clusters
            }
            return []
        })
    }

    private clusterApi: ClusterApi = new ClusterApi()
    private accessTierGroupApi: AccessTierGroupApi = new AccessTierGroupApi()

    private accessTierService: AccessTierService = new AccessTierService()

    private mapClusterConfigResToClusters(res: ClusterRes): Cluster {
        return {
            name: res.ShieldName,
            isEdge: isGlobalEdgeCluster(res),
            shieldAddress: res.PublicAddr,
        }
    }
}

export interface Cluster {
    name: string
    accessTiers?: AccessTier[]
    connectors?: Connector[]
    accessTierGroups?: AccessTierGroup[]
    isEdge: boolean
    shieldAddress?: string
}

export enum ClusterHookKey {
    GET_CLUSTERS = "clusterService.getClusters",
}

export function useGetClusters(
    enableAccessTierGroups: boolean,
    options?: QueryOptions<Cluster[]>
): UseQueryResult<Cluster[]> {
    const queryClient = useQueryClient()
    return useQuery<Cluster[], string>({
        ...options,
        queryKey: [ClusterHookKey.GET_CLUSTERS],
        queryFn: () => helperGetClusters(enableAccessTierGroups, queryClient),
    })
}

export function helperGetClusters(
    enableAccessTierGroups: boolean,
    queryClient: QueryClient
): Promise<Cluster[]> {
    const clusterService = new ClusterService(enableAccessTierGroups)
    return queryClient.ensureQueryData({
        queryKey: [ClusterHookKey.GET_CLUSTERS],
        queryFn: () => clusterService.getClusters(),
    })
}

export interface AccessTierGroup {
    id: string
    name: string
    sharedAddress: string
    clusterName: string
}

function mapAccessTierGroupRes(res: AccessTierGroupRes): AccessTierGroup {
    return {
        id: res.id,
        name: res.name,
        sharedAddress: res.tunnel_enduser.shared_fqdn,
        clusterName: res.cluster_name,
    }
}
