import React from "react"
import { Input, InputProps } from "../input/Input.component"

import { OmittedDateTimeProps } from "../shared/datetime"

export interface DateInputProps extends Omit<InputProps, OmittedDateTimeProps> {
    onChangeValue?(value?: string): void
}

export const DateInput = React.forwardRef<HTMLInputElement, DateInputProps>((props, ref) => {
    const { onChange, onChangeValue, ...inputProps } = props

    const onChangeHandler = (event: React.ChangeEvent<HTMLInputElement>) => {
        const { value } = event.target
        onChange?.(event)
        onChangeValue?.(value || undefined)
    }

    return <Input type="date" {...inputProps} ref={ref} onChange={onChangeHandler} role="datebox" />
})
