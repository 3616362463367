import React from "react"
import styles from "./ServicesAddSshSettings.module.scss"
import classNames from "classnames/bind"
import { FormLabel, Input, Select } from "../../../../components"

export default function () {
    return (
        <div className={classNames(this.props.className, styles.container)}>
            <FormLabel
                title={this.ls.getString("sshHostDirective")}
                tooltip={this.ls.getString("sshHostDirectiveDescription")}
                htmlFor="sshHostDirective"
            >
                <Input
                    className={styles.formInput}
                    onChange={this.onHostChange}
                    value={this.state.host}
                    placeholder={this.ls.getString("sshHostDirectivePlaceholder")}
                    disabled={this.props.disabled}
                />
            </FormLabel>
            <FormLabel
                title={this.ls.getString(
                    "howShouldSonicWallCseHandleUserConnectionsToThisSshService"
                )}
                htmlFor="howShouldSonicWallCseHandleUserConnectionsToThisSshService"
            >
                <Select
                    options={this.state.certOptions}
                    value={this.state.certSelect}
                    onChange={this.onCertChange}
                    disabled={this.props.disabled}
                />
            </FormLabel>
            <FormLabel
                title={this.ls.getString("shouldTheDesktopAppUpdateTheSshConfigFileOnTheDevice")}
                htmlFor="shouldTheDesktopAppUpdateTheSshConfigFileOnTheDevice"
            >
                <Select
                    options={this.configOptions}
                    value={this.state.configSelect}
                    onChange={this.onConfigChange}
                    disabled={this.props.disabled}
                />
            </FormLabel>
        </div>
    )
}
