import { faAddressCard } from "@fortawesome/pro-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import React, { useState } from "react"

import {
    Button,
    ButtonElement,
    ButtonType,
    IconType,
} from "../../../../../../../components/button/Button.component"
import { useServiceLocalization } from "../../../../../../../pre-v3/services/localization/Localization.service"
import { ModalRef } from "../../../../../../../pre-v3/services/Modal.service"
import { encodeID } from "../../../../../../../pre-v3/utils/Url.util"
import { formatRoutePath, ROUTE } from "../../../../../../../routes"
import { AppText } from "../../../../../../components/app-text/AppText.component"
import { SelectInput } from "../../../../../../components/select-input/SelectInput.component"
import { TrustProfile } from "../../../../../../services/TrustProfile.service"
import styles from "./TrustProfileList.module.scss"

interface Props {
    trustProfiles: TrustProfile[]
    initialValue: string
    modalRef: ModalRef
}

export function CreateTrustProfile(props: Props) {
    const { initialValue, trustProfiles, modalRef } = props

    const localization = useServiceLocalization()

    const [selectedTrustProfile, setSelectedTrustProfile] = useState(initialValue)

    const trustProfileOptions = trustProfiles.map((trustProfile) => {
        return {
            displayName: trustProfile.name,
            value: trustProfile.id,
        }
    })

    function closeModal() {
        modalRef.close("")
    }

    return (
        <div className={styles.createModalItems}>
            <div className={styles.createModalItem}>
                <FontAwesomeIcon className={styles.createModalItemLeft} icon={faAddressCard} />
                <div className={styles.createModalItemContent}>
                    <AppText
                        className={styles.createModalItemContentHeading}
                        ls="createTrustProfile"
                    />
                    <AppText
                        className={styles.createModalItemContentDesc}
                        ls="configureAssignmentCriteriaAndAddApplicableTrustFactors"
                    />
                </div>
                <Button
                    className={styles.createModalItemRight}
                    asElement={ButtonElement.LINK}
                    buttonType={ButtonType.PRIMARY}
                    icon={IconType.ARROW_RIGHT}
                    aria-label={localization.getString("create")}
                    to={ROUTE.PROFILES_CREATE}
                    onClick={closeModal}
                />
            </div>
            <div className={styles.createModalItem}>
                <div className={styles.createModalItemLeft}>
                    <OverlapCardIcon />
                </div>
                <div className={styles.createModalItemContent}>
                    <AppText
                        className={styles.createModalItemContentHeading}
                        ls="copyAnExistingTrustProfile"
                    />
                    <AppText
                        className={styles.createModalItemContentDesc}
                        ls="modifyTheProfileNameThenChangeAssignmentCriteriaAndOrTrustFactors"
                    />
                    <div className={styles.selectProfileWrapper}>
                        <label className={styles.selectProfileLabel}>
                            <AppText ls="selectAProfile" />
                        </label>
                        <SelectInput
                            className={styles.selectProfile}
                            options={trustProfileOptions}
                            value={selectedTrustProfile}
                            onChange={setSelectedTrustProfile}
                        />
                    </div>
                </div>
                <Button
                    className={styles.createModalItemRight}
                    asElement={ButtonElement.LINK}
                    buttonType={ButtonType.PRIMARY}
                    icon={IconType.ARROW_RIGHT}
                    aria-label={localization.getString("copy")}
                    onClick={closeModal}
                    to={formatRoutePath(
                        ROUTE.PROFILES_CREATE,
                        {},
                        { trustProfileId: encodeID(selectedTrustProfile) }
                    )}
                />
            </div>
        </div>
    )
}

function OverlapCardIcon() {
    return (
        <div className={styles.overlapCard}>
            <FontAwesomeIcon className={styles.overlapCardOne} icon={faAddressCard} />
            <div className={styles.overlapCardTwoWrapper}>
                <FontAwesomeIcon className={styles.overlapCardTwo} icon={faAddressCard} />
            </div>
        </div>
    )
}
