import { useServiceLocalization } from "../pre-v3/services"

interface Input {
    ignoreCase?: boolean
}

export function useAlphaSort(input?: Input): (x: string, y: string) => number {
    const localization = useServiceLocalization()

    const collator = new Intl.Collator(localization.getLocale(), {
        sensitivity: input?.ignoreCase ? "accent" : undefined,
    })

    return collator.compare
}
