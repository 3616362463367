import { QueryClient } from "@tanstack/react-query"

import { ServiceTunnelApi } from "../api/ServiceTunnel.api"
import { ApiFunction } from "./shared/QueryKey"

const serviceName = "OldServiceTunnelService"

export function helperGetServiceTunnel(
    queryClient: QueryClient,
    id: string
): Promise<{ id: string }> {
    const serviceTunnelApi = new ServiceTunnelApi()

    return queryClient.ensureQueryData({
        queryKey: [ApiFunction.GET_SERVICE_TUNNEL, id, serviceName],
        queryFn: async () => {
            const serviceTunnelRes = await serviceTunnelApi.getServiceTunnelById(id)
            return { id: serviceTunnelRes.id }
        },
    })
}
