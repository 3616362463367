import React, { useState } from "react"
import { useHistory, useParams } from "react-router-dom"

import { ActionBarItem, IconType } from "../../../../pre-v3/services/ActionBar.service"
import { useServiceLocalization } from "../../../../pre-v3/services/localization/Localization.service"
import { ROUTE } from "../../../../routes"
import { Container } from "../../../components/container/Container.component"
import { Status, isMobileDevicesRole } from "../../../services/shared/Role"
import {
    EditableRoleFields,
    useDeleteRole,
    useDownloadRoleSpec,
    useGetRole,
    useUpdateRole,
} from "../../../services/Role.service"
import { Loader } from "../../../components/loader/Loader.component"
import { ErrorBanners } from "../../../components/banner/Banner.component"
import { UrlUtil, decodeID } from "../../../../pre-v3/utils/Url.util"
import { OverviewTopContainer } from "../../../components/overview/OverviewTopContainer/OverviewTopContainer.component"
import { DateUtil } from "../../../../pre-v3/utils/Date.util"
import { TabBar } from "../../../components/tab-bar/TabBar.component"
import { useServiceModal } from "../../../../pre-v3/services/Modal.service"
import { useGetAdminInfo } from "../../../services/Org.service"
import { RolesForm } from "../form/RolesForm.component"
import { RoleDevicesTab } from "./RoleDevicesTab.views"
import { RoleLinkedPoliciesTab } from "./RoleLinkedPoliciesTab.view"
import { PageHeading } from "../../../../components/page-heading/PageHeading.component"
import { Button, ButtonElement, ButtonType } from "../../../../components/button/Button.component"
import styles from "./RoleOverview.module.scss"
import { Tooltip } from "../../../components/tooltip/Tooltip.component"

export function RolesOverview() {
    const localization = useServiceLocalization()
    const history = useHistory()
    const params = useParams<{ id: string }>()
    const defaultTab = UrlUtil.getUrlParam("tab") as RoleOverviewTab
    const modalService = useServiceModal()

    let roleId = ""

    try {
        roleId = decodeID(params.id)
    } catch {}

    const [editedRole, setEditedRole] = useState<EditableRoleFields>()
    const [isEdit, setIsEdit] = useState<boolean>(false)
    const [tab, setTab] = useState<RoleOverviewTab>(
        defaultTab in RoleOverviewTab ? defaultTab : RoleOverviewTab.CONFIGURATION
    )

    const {
        mutateAsync: updateRole,
        isLoading: isRoleUpdating,
        error: updateRoleError,
        reset: resetCreateUpdateRole,
    } = useUpdateRole({
        onSuccess: () => {
            setIsEdit(false)
            setEditedRole(undefined)
        },
    })

    const {
        data: roleData,
        error: roleError,
        isFetching: isRoleLoading,
        isError: isRolesError,
        refetch: refetchRole,
    } = useGetRole(roleId, { enabled: Boolean(roleId) })

    const {
        mutateAsync: deleteRole,
        isLoading: isDeletingRole,
        error: deleteRoleError,
        reset: resetDeleteRole,
    } = useDeleteRole()

    const {
        isLoading: isDownloadingRoleSpec,
        error: downloadRoleSpecError,
        mutateAsync: downloadRoleSpec,
        reset: resetDownloadRoleSpec,
    } = useDownloadRoleSpec(roleId)

    const {
        isFetching: isGetAdminInfoLoading,
        error: adminInfoError,
        refetch: refetchAdminInfo,
        data: adminInfo,
    } = useGetAdminInfo()

    const errors: React.ReactNode[] = [
        roleError ? String(roleError) : null,
        deleteRoleError ? String(deleteRoleError) : null,
        downloadRoleSpecError ? String(downloadRoleSpecError) : null,
        adminInfoError && String(adminInfoError),
    ]

    const isLoading =
        isRoleUpdating ||
        isRoleLoading ||
        isDeletingRole ||
        isDownloadingRoleSpec ||
        isGetAdminInfoLoading
    const disableActions = isLoading || isRolesError

    const role = isEdit ? editedRole : roleData

    const actions: ActionBarItem[] = [
        ...(adminInfo?.canWriteRoles && roleData && !isMobileDevicesRole(roleData)
            ? [
                  {
                      icon: IconType.TRASH,
                      onClick: onDelete,
                      tooltip: localization.getString("delete"),
                      disabled: disableActions,
                  },
                  ...(isEdit
                      ? []
                      : [
                            {
                                icon: IconType.COPY,
                                onClick: onCopy,
                                tooltip: localization.getString("copy"),
                                disabled: disableActions,
                            },
                        ]),
                  {
                      icon: IconType.PEN,
                      tooltip: isEdit ? "" : localization.getString("edit"),
                      onClick: () => {
                          setEditedRole(roleData)
                          setIsEdit(true)

                          if (tab !== RoleOverviewTab.CONFIGURATION) {
                              // Note: Only editable tab is RoleOverviewTab.CONFIGURATION. So on edit we are forcing user to that tab.
                              setTab(RoleOverviewTab.CONFIGURATION)
                          }
                      },
                      disabled: disableActions || isEdit,
                  },
              ]
            : []),
        {
            icon: IconType.FILE_DOWNLOAD,
            tooltip: localization.getString("downloadRoleSpec"),
            onClick: () => downloadRoleSpec(),
            disabled: isLoading,
        },
    ]

    async function onRefresh() {
        resetDeleteRole()
        resetDownloadRoleSpec()
        resetCreateUpdateRole()
        refetchAdminInfo()
        const refetchedRole = await refetchRole()

        if (isEdit) {
            setEditedRole(refetchedRole.data)
        }
    }

    function onDelete() {
        const policyCount = roleData?.policyCount || 0

        if (policyCount > 0) {
            modalService
                .openDelete(
                    localization.getString("deleteRole"),
                    localization.getPluralString("deleteRoleDescription", policyCount)
                )
                .onClose(onDeleteRoleFn)
        } else {
            modalService
                .openDelete(
                    localization.getString("deleteRole"),
                    localization.getString("areYouSureYouWantToDeleteThisRole")
                )
                .onClose(onDeleteRoleFn)
        }
    }

    async function onDeleteRoleFn() {
        await deleteRole(roleId)
        history.push(ROUTE.ROLES)
    }

    function onCopy() {
        history.push({ pathname: ROUTE.ROLES_ADD, state: { copyRole: roleData } })
    }

    function onCancel() {
        setIsEdit(false)
        setEditedRole(undefined)
    }

    function onTabChange(updatedStep: RoleOverviewTab) {
        setTab(updatedStep)
        UrlUtil.setURlParams(
            "tab",
            updatedStep === RoleOverviewTab.CONFIGURATION ? "" : updatedStep
        )
    }

    return (
        <Loader isLoading={isLoading} medium center>
            <Container as="section" aria-labelledby={Id.HEADING} className={styles.container}>
                <header className={styles.header}>
                    <PageHeading id={Id.HEADING}>
                        {role?.name || localization.getString("roles")}
                    </PageHeading>
                    {roleId && (
                        <div className={styles.actionButtons}>
                            {actions.map((action) => (
                                <Tooltip title={action.tooltip} key={action.tooltip}>
                                    <Button
                                        icon={action.icon}
                                        onClick={action.onClick}
                                        asElement={ButtonElement.BUTTON}
                                        buttonType={ButtonType.SECONDARY}
                                        aria-label={action.tooltip}
                                        disabled={action.disabled}
                                    />
                                </Tooltip>
                            ))}
                            <Tooltip title={localization.getString("refresh")}>
                                <Button
                                    icon={IconType.REDO}
                                    onClick={onRefresh}
                                    asElement={ButtonElement.BUTTON}
                                    buttonType={ButtonType.SECONDARY}
                                    aria-label={localization.getString("refresh")}
                                />
                            </Tooltip>
                        </div>
                    )}
                </header>
                <ErrorBanners errors={errors} />
                {role && (
                    <>
                        <OverviewTopContainer
                            statusType={
                                roleData?.roleStatus === Status.ACTIVE ? "success" : "disabled"
                            }
                            statusLabelTitle={localization.getString("currentStatus")}
                            statusLabel={localization.getString(
                                roleData?.roleStatus === Status.ACTIVE ? "active" : "inactive"
                            )}
                            statusItems={[
                                {
                                    label: localization.getString("version"),
                                    value: roleData?.version,
                                },
                                {
                                    label: localization.getString("lastUpdated"),
                                    value: DateUtil.format(roleData?.lastUpdatedAt),
                                },
                            ]}
                            listItems={[
                                {
                                    label: localization.getString("description"),
                                    value: roleData?.description || "-",
                                },
                                {
                                    label: localization.getString("createdAt"),
                                    value: DateUtil.format(roleData?.createdAt),
                                },
                                {
                                    label: localization.getString("createdBy"),
                                    value: roleData?.createdBy,
                                },
                                {
                                    label: localization.getString("lastUpdatedBy"),
                                    value: roleData?.lastUpdatedBy,
                                },
                            ]}
                        />
                        <TabBar
                            tabs={[
                                {
                                    label: localization.getString("configuration"),
                                    id: RoleOverviewTab.CONFIGURATION,
                                    ariaControls:
                                        RoleOverviewTabAriaControl[RoleOverviewTab.CONFIGURATION],
                                },
                                ...(!isEdit
                                    ? [
                                          {
                                              label: localization.getString(
                                                  "linkedPoliciesTemplate",
                                                  (roleData?.policyCount || 0).toString()
                                              ),
                                              id: RoleOverviewTab.LINKED_POLICIES,
                                              ariaControls:
                                                  RoleOverviewTabAriaControl[
                                                      RoleOverviewTab.LINKED_POLICIES
                                                  ],
                                          },
                                          {
                                              label: localization.getString(
                                                  "devicesTemplate",
                                                  (roleData?.deviceCount || 0).toString()
                                              ),
                                              id: RoleOverviewTab.DEVICES,
                                              ariaControls:
                                                  RoleOverviewTabAriaControl[
                                                      RoleOverviewTab.DEVICES
                                                  ],
                                          },
                                      ]
                                    : []),
                            ]}
                            selectedTabId={tab}
                            onChange={onTabChange}
                        />
                        {tab === RoleOverviewTab.CONFIGURATION && (
                            <RolesForm
                                value={role}
                                onChange={setEditedRole}
                                onCancel={onCancel}
                                onSubmit={updateRole}
                                errors={[updateRoleError ? String(updateRoleError) : null]}
                                disabled={!isEdit}
                                isEdit
                            />
                        )}
                        {tab === RoleOverviewTab.LINKED_POLICIES && (
                            <RoleLinkedPoliciesTab roleId={roleData!.id} />
                        )}
                        {tab === RoleOverviewTab.DEVICES && (
                            <RoleDevicesTab roleName={roleData!.name} />
                        )}
                    </>
                )}
            </Container>
        </Loader>
    )
}

enum Id {
    HEADING = "heading",
}

export enum RoleOverviewTab {
    CONFIGURATION = "CONFIGURATION",
    LINKED_POLICIES = "LINKED_POLICIES",
    DEVICES = "DEVICES",
}

const RoleOverviewTabAriaControl: Record<RoleOverviewTab, string> = {
    [RoleOverviewTab.CONFIGURATION]: "configurationTab",
    [RoleOverviewTab.LINKED_POLICIES]: "linkedPoliciesTab",
    [RoleOverviewTab.DEVICES]: "usersAndDevicesTab",
}
