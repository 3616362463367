import React, { ReactNode } from "react"
import ServicesAddPolicyTemplate from "./ServicesAddPolicy.template"
import { LocalizationService } from "../../../../services/localization/Localization.service"
import { ToggleButtonItem } from "../../../../components/toggle-button/ToggleButton.component"
import { SecureService, PolicySecure } from "../../../../services/Secure.service"
import { Bind } from "../../../../decorators/Bind.decorator"
import { SelectItem } from "../../../../utils/SelectValue.util"
import { ServiceType } from "../../../../services/Manage.service"

export class ServicesAddPolicy extends React.Component<
    ServicesAddPolicyProps,
    ServicesAddPolicyState
> {
    constructor(props: ServicesAddPolicyProps) {
        super(props)

        this.enforcing = props.initialEnforcing || false
        this.state.policyId = props.initialPolicyId || ""

        if (this.enforcing) {
            this.enforcementOptions[0].selected = false
            this.enforcementOptions[1].selected = true
        }
    }

    public state: ServicesAddPolicyState = {
        policyId: "",
        policyOptions: [],
    }

    public render(): ReactNode {
        return ServicesAddPolicyTemplate.call(this)
    }

    public componentDidMount(): void {
        this.secureService
            .getPoliciesByServiceType(this.props.serviceType)
            .then((policiesByServiceType: PolicySecure[]) => {
                this.setState({
                    policyOptions: policiesByServiceType
                        .sort((a, b) => a.name.localeCompare(b.name))
                        .map((p) => {
                            return { displayName: p.name, value: p.id }
                        }),
                })
            })
    }

    private localizationService: LocalizationService = new LocalizationService()
    private secureService: SecureService = new SecureService()

    private enforcing: boolean

    private enforcementOptions: ToggleButtonItem[] = [
        {
            label: this.localizationService.getString("permissive"),
            onClick: () => this.setEnforcementMode(false),
            selected: true,
        },
        {
            label: this.localizationService.getString("enforcing"),
            onClick: () => this.setEnforcementMode(true),
        },
    ]

    @Bind
    private onPolicyChange(value: string): void {
        this.setState({ policyId: value })
        this.emitChange(value, this.enforcing)
    }

    @Bind
    private setEnforcementMode(enforcing: boolean): void {
        this.enforcing = enforcing
        this.emitChange(this.state.policyId, enforcing)
    }

    private emitChange(policyId: string, enforcing: boolean): void {
        if (this.props.onChange) {
            this.props.onChange(policyId, enforcing)
        }
    }
}

interface ServicesAddPolicyProps {
    required?: boolean
    initialPolicyId: string
    initialEnforcing: boolean
    serviceType: ServiceType
    onChange: (policyId: string, enabled: boolean) => void
}

interface ServicesAddPolicyState {
    policyId: string
    policyOptions: SelectItem[]
}
