import React, { ReactNode } from "react"
import { RouteComponentProps } from "react-router"

import { ROUTE } from "../../../../routes"
import { LocalizationService } from "../../../services/localization/Localization.service"
import ActionBarService from "../../../services/ActionBar.service"
import { InfraService, ClusterInfra, LogInfra, AccessTier } from "../../../services/Infra.service"
import { Tab } from "../../../components/tab-bar/TabBar.component"
import { Bind } from "../../../decorators/Bind.decorator"
import { SimpleTableItem } from "../../../components/simple-table/SimpleTable.component"
import { ColDef, GridApi, GridReadyEvent } from "ag-grid-community"
import { DateUtil } from "../../../utils/Date.util"
import { ClusterStatusCellRenderer } from "../cell-renderer/status/ClusterStatusCellRenderer.component"
import { ModalService } from "../../../services/Modal.service"
import { InfraApi } from "../../../api/Infra.api"
import AgGridUtil from "../../../utils/AgGrid.util"
import ClusterOverviewTemplate from "./ClusterOverview.template"

export class ClusterOverview extends React.Component<
    RouteComponentProps<ClusterOverviewRouteParams>,
    ClusterOverviewState
> {
    public state: ClusterOverviewState = { loading: true, tab: 1, error: "" }

    public render(): ReactNode {
        return ClusterOverviewTemplate.call(this)
    }

    public componentDidMount(): void {
        this.clusterName = this.props.match.params.clusterName
        this.fetchData()
    }

    public componentWillUnmount(): void {
        this.actionBarService.unregisterRefreshFn(this.fetchData)
    }

    private localizationService: LocalizationService = new LocalizationService()
    private actionBarService: ActionBarService = new ActionBarService()
    private infraService: InfraService = new InfraService()
    private infraApi: InfraApi = new InfraApi()
    private modalService: ModalService = new ModalService()

    private clusterName: string
    private gridApi: GridApi

    private clusterColumns: ColDef[] = [
        {
            headerName: this.localizationService.getString("installationParameter"),
            field: "installationParameter",
            width: 120,
        },
        {
            headerName: this.localizationService.getString("value"),
            field: "value",
            width: 300,
        },
        {
            headerName: this.localizationService.getString("actions"),
            field: "value",
            cellRenderer: "copyButtonCellRenderer",
            cellRendererParams: { displayValue: false },
        },
    ]

    private accessTierColumns: ColDef[] = [
        {
            headerName: this.localizationService.getString("status"),
            field: "status",
            cellRenderer: "netAgentStatusCellRenderer",
        },
        {
            headerName: this.localizationService.getString("accessTierName"),
            field: "name",
            tooltipValueGetter: AgGridUtil.linkTooltipValueGetter,
            cellRenderer: "accessTierNameCellRenderer",
            comparator: AgGridUtil.alphaBetComparator,
        },
        {
            headerName: this.localizationService.getString("publicAddress"),
            field: "address",
            comparator: AgGridUtil.alphaBetComparator,
        },
    ]

    private logsColumns: ColDef[] = [
        {
            headerName: this.localizationService.getString("insertTime"),
            field: "timestamp",
            width: 40,
            cellRenderer: "agGroupCellRenderer",
            cellRendererParams: {
                innerRenderer: "logsCellRenderer",
            },
        },
        {
            headerName: this.localizationService.getString("status"),
            field: "data",
            width: 100,
        },
    ]

    private getAccessTiersCount = (): number => {
        return this.state.accessTiers ? this.state.accessTiers.length : 0
    }

    private getTabs(): Tab[] {
        return [
            {
                label: this.localizationService.getString("installationAndDeviceCertParameters"),
                value: 1,
                active: true,
            },
            {
                label: `${this.localizationService.getString(
                    "accessTiers"
                )} (${this.getAccessTiersCount()})`,
                value: 2,
            },
            {
                label: this.localizationService.getString("logs"),
                value: 4,
            },
        ]
    }

    @Bind
    private onTabChange(value: any): void {
        this.setState({ tab: value })
    }

    @Bind
    private onClusterGridReady(event: GridReadyEvent): void {
        this.gridApi = event.api
        this.setClusterData()
    }

    @Bind
    private onAccessTierGridReady(event: GridReadyEvent): void {
        this.gridApi = event.api
        this.setAccessTierData()
    }

    @Bind
    private onLogsGridReady(event: GridReadyEvent): void {
        this.gridApi = event.api
        this.setLogsData()
    }

    @Bind
    private onFilter(value: string): void {
        if (this.gridApi) {
            this.gridApi.setQuickFilter(value)
        }
    }

    @Bind
    private onOpenDeleteModal(): void {
        this.setState({ error: "" })
        this.modalService
            .openConfirmation(
                this.localizationService.getString("confirmClusterDeletion"),
                this.localizationService.getString("deleteClusterExplanation")
            )
            .onClose(() => {
                if (this.state.cluster) {
                    this.infraApi
                        .deleteCluster(this.state.cluster.name)
                        .then(() => {
                            this.props.history.push(ROUTE.CLUSTERS)
                        })
                        .catch((error: Error) => {
                            this.setState({ error: error.message })
                        })
                } else {
                    this.props.history.push(ROUTE.CLUSTERS)
                }
            })
    }

    private setClusterData(): void {
        if (this.gridApi && this.state.cluster) {
            const { cluster } = this.state
            this.gridApi.setRowData([
                {
                    installationParameter:
                        this.localizationService.getString("shieldAddress") + ":",
                    value: cluster.address,
                },
                {
                    installationParameter: this.localizationService.getString("oneTimeKey") + ":",
                    value: cluster.oneTimeKey,
                },
                {
                    installationParameter:
                        this.localizationService.getString("oneTimeKeyExpiration") + ":",
                    value: DateUtil.format(cluster.oneTimeKeyExpiry),
                },
            ])
        }

        if (this.state.cluster) {
            this.setState({
                clusterData: [
                    {
                        label: this.localizationService.getString("name"),
                        value: this.state.cluster.name,
                    },
                    {
                        label: this.localizationService.getString("shieldId"),
                        value: this.state.cluster.id,
                    },
                ],
                clusterDetailsData: [
                    {
                        label: this.localizationService.getString("shieldVersion"),
                        value: this.state.cluster.version,
                    },
                    {
                        label: this.localizationService.getString("group"),
                        value: this.state.cluster.group,
                    },
                ],
                currentStatus: React.createElement(ClusterStatusCellRenderer, <any>{
                    data: this.state.cluster,
                }),
            })
        }
    }

    @Bind
    private setAccessTierData(): void {
        if (this.gridApi && this.state.accessTiers) {
            this.gridApi.setRowData(this.state.accessTiers)
        }
    }

    @Bind
    private setLogsData(): void {
        if (this.gridApi && this.state.logs) {
            this.gridApi.setRowData(this.state.logs)
        }
    }

    @Bind
    private setGridData(): void {
        switch (this.state.tab) {
            case 2:
                this.setAccessTierData()
                break
            case 4:
                this.setLogsData()
                break
            default:
                this.setClusterData()
        }
    }

    @Bind
    private setError(message: string): void {
        this.setState({ loading: false, error: message })
    }

    @Bind
    private fetchData(): void {
        this.setState({ loading: true, error: "" })
        if (this.clusterName && this.clusterName.length > 0) {
            this.infraService
                .getCluster(this.clusterName)
                .then((cluster: ClusterInfra | undefined) => {
                    if (cluster) {
                        this.setState(
                            {
                                cluster,
                                accessTiers: cluster.accessTiers,
                                loading: false,
                            },
                            this.setGridData
                        )
                    } else {
                        this.setError(this.localizationService.getString("failedToLoadCluster"))
                        this.setState({ loading: false })
                    }
                })
                .catch(() => {
                    this.setError(this.localizationService.getString("failedToLoadCluster"))
                    this.setState({ loading: false })
                })

            this.infraService
                .getClusterLogs(this.clusterName)
                .then((logs) => {
                    this.setState({ logs })
                })
                .catch(() => {
                    this.setError(this.localizationService.getString("failedToLoadLogs"))
                })
        } else {
            this.setError(this.localizationService.getString("failedToLoadCluster"))
        }
    }
}

interface ClusterOverviewRouteParams {
    clusterName: string
}

interface ClusterOverviewState {
    loading: boolean
    cluster?: ClusterInfra
    clusterData?: SimpleTableItem[]
    clusterDetailsData?: SimpleTableItem[]
    accessTiers?: AccessTier[]
    tab?: any
    error: string
    currentStatus?: any
    logs?: LogInfra[]
}
