import { faExclamation } from "@fortawesome/pro-solid-svg-icons"
import * as qs from "query-string"
import React, { Fragment, useCallback, useEffect, useState } from "react"
import { RouteComponentProps } from "react-router"

import { ROUTE, formatRoutePath } from "../../../../../routes"
import { ServiceAttr, ServiceMetadata, ServiceSpec } from "../../../../api/Manage.api"
import { SpecKind } from "../../../../api/Secure.api"
import { ErrorBanner, LargeMessage, LoadMask } from "../../../../components"
import {
    LocalizationService,
    useServiceLocalization,
} from "../../../../services/localization/Localization.service"
import ActionBarService, { useServiceActionBar } from "../../../../services/ActionBar.service"
import {
    ManageService,
    ServiceAppType,
    ServiceManage,
    ServiceType,
    useServiceManage,
} from "../../../../services/Manage.service"
import HostedServiceForm from "../../../services/service-form-hosted"
import {
    CloudResource,
    InventoryService,
    useServiceInventory,
} from "../../../../services/Inventory.service"
import { decodeID, encodeID } from "../../../../utils/Url.util"
import {
    mapCloudResourceToWebService,
    WebService,
} from "../../../../../v3/services/HostedService.service"
import { StringUtil } from "../../../../utils/String.util"
import { HostedWebsiteAdd } from "../../../../../v3/views/hosted-websites/create/HostedWebsiteAdd.component"
import styles from "./PublishCloudResource.module.scss"
import { useFeatureFlags } from "../../../../../hooks/useFeatureFlags.hook"

export function PublishCloudResource(props: PublishCloudResourceProps) {
    const ls: LocalizationService = useServiceLocalization()
    const { data: featureFlags } = useFeatureFlags()
    const actionBarService: ActionBarService = useServiceActionBar()
    const manageService: ManageService = useServiceManage()
    const inventoryService: InventoryService = useServiceInventory()

    const [loading, setLoading] = useState<boolean>(true)
    const [error, setError] = useState<string>("")
    const [resourceId, setResourceId] = useState<string>("")
    const [type, setType] = useState<ServiceType>(ServiceType.WEB_USER)
    const [appType, setAppType] = useState<ServiceAppType>(ServiceAppType.WEB)

    const [serviceManage, setServiceManage] = useState<ServiceManage>()

    const [webService, setWebService] = useState<WebService>()

    async function onSubmitNewHostedWebWebsite(data: WebService) {
        const serviceId: string = data.id || ""
        try {
            await inventoryService.publishResource(serviceId, resourceId)
            props.history.push(
                formatRoutePath(ROUTE.IAAS_DISCOVERY_DETAILS, { id: encodeID(resourceId) })
            )
        } catch (e) {
            const err = e as Error
            if (err?.message) {
                setError(err.message)
            } else {
                setError(ls.getString("failedToPublishResource"))
            }
        }
    }

    const onSubmit = () => {
        let service: ServiceManage
        return async (data: {
            metaData: ServiceMetadata
            attributes: ServiceAttr
            policyEnabled: boolean
            policyID: string
        }): Promise<void> => {
            try {
                service = await manageService.createRegisteredService({
                    metadata: data.metaData,
                    type: SpecKind.USER,
                    attributes: data.attributes,
                    policyId: data.policyID,
                    enabled: data.policyEnabled,
                })

                await inventoryService.publishResource(service.id, resourceId)
                props.history.push(
                    formatRoutePath(ROUTE.IAAS_DISCOVERY_DETAILS, { id: encodeID(resourceId) })
                )
            } catch (e) {
                const err = e as Error
                if (err?.message) {
                    setError(err.message)
                } else {
                    setError(ls.getString("failedToPublishResource"))
                }

                //delete the created service if publishing a resource fails
                if (service) {
                    //if the created service has policy attached, we first need to detach the policy from service.
                    //then disable and delete the service
                    if (data.policyID) {
                        await manageService.detachPolicyFromService(data.policyID, service.id)
                    }

                    //in order to delete the registered service, we need to disable it first
                    await manageService.disableRegisteredService(service.id)
                    await manageService.deleteRegisteredService(service.id)
                }
            }
        }
    }

    const fetchData = useCallback(() => {
        setLoading(true)
        let id = ""
        const appType = (qs.parse(props.location.search).appType ?? "") as ServiceAppType
        const type = (qs.parse(props.location.search).type ?? "") as ServiceType

        setType(type)
        setAppType(appType)
        try {
            id = decodeID(props.match.params.id)
        } catch {} // error handled in template
        if (id) {
            inventoryService
                .getCloudResource(id)
                .then((resource: CloudResource | undefined) => {
                    if (!resource) return

                    setResourceId(resource.id)

                    // populate resource private/public ip/address to service backend domain
                    const serviceBackendDomain = inventoryService.getResourceAddress(resource)

                    let backendPort = ""
                    if (resource.ports) {
                        const portsAndProtocol =
                            inventoryService.getResourcePortAndProtocol(resource)

                        if (portsAndProtocol.length > 0) {
                            //populate first available port
                            backendPort = portsAndProtocol[0].key
                        }
                    }

                    // casting cloud resource to ServiceManage to use Service Form
                    const service: ServiceManage =
                        inventoryService.mapCloudResourceToServiceManage(resource)

                    const hostedWebService: WebService = mapCloudResourceToWebService(resource)
                    //update service spec
                    const parsedSpec: ServiceSpec | undefined = service.spec
                    if (parsedSpec) {
                        parsedSpec.spec.backend.target.name = serviceBackendDomain
                        parsedSpec.spec.backend.target.port = backendPort
                    }

                    service.spec = parsedSpec
                    setServiceManage(service)

                    hostedWebService.backendDomain = serviceBackendDomain
                    hostedWebService.backendPort = StringUtil.getNumber(backendPort)
                    setWebService(hostedWebService)
                })
                .finally(() => {
                    setLoading(false)
                })
        } else {
            setLoading(false)
        }
    }, [inventoryService, actionBarService, ls, props])

    useEffect(() => {
        fetchData()
    }, [fetchData])

    return (
        <div className={styles.pageContent}>
            {loading ? (
                <LoadMask />
            ) : serviceManage ? (
                <Fragment>
                    {type === ServiceType.WEB_USER ? (
                        <HostedWebsiteAdd
                            initialValue={webService as WebService}
                            onSubmit={onSubmitNewHostedWebWebsite}
                            enableAccessTierGroups={
                                featureFlags?.adminConsole.enableAccessTierGroups || false
                            }
                        />
                    ) : (
                        <HostedServiceForm
                            edit={serviceManage}
                            enableFormField
                            type={type as ServiceType}
                            appType={appType as ServiceAppType}
                            onSubmit={onSubmit()}
                            hideBackend
                        />
                    )}
                    {error && <ErrorBanner>{error}</ErrorBanner>}
                </Fragment>
            ) : (
                <LargeMessage icon={faExclamation}>
                    {ls.getString("errorCouldNotFindCloudResource")}
                </LargeMessage>
            )}
        </div>
    )
}

interface PublishCloudResourceProps extends RouteComponentProps<{ id: string }> {}
