import React from "react"

import { LocalizationService, ModalRef } from "../../../../pre-v3/services"
import { AppText } from "../../../components/app-text/AppText.component"
import { ErrorBanner } from "../../../components/banner/Banner.component"
import { Loader } from "../../../components/loader/Loader.component"
import { TabBar, TabProps } from "../../../components/tab-bar/TabBar.component"
import {
    useGetClusters,
    useSaveRegisteredDomain,
    useValidateRegisteredDomain,
} from "../../../services/RegisteredDomain.service"
import { RegisteredDomainConfigurationForm } from "../configuration-form/RegisteredDomainConfigurationForm.component"
import { RegisteredDomainDnsForm } from "../dns-form/RegisteredDomainDnsForm.component"
import styles from "./RegisteredDomainsModal.module.scss"

export function RegisteredDomainsModal(props: RegisteredDomainsModalProps) {
    const ls: LocalizationService = new LocalizationService()

    const tabs: TabProps[] = [
        {
            id: "configuration",
            label: ls.getString("configuration"),
            ariaControls: "configuration-tab",
        },
        {
            id: "dnsSettings",
            label: ls.getString("dnsSettings"),
            ariaControls: "dnsSettings-tab",
        },
    ]

    const [currentTab, setCurrentTab] = React.useState("configuration")

    const clustersQuery = useGetClusters(props.enableAccessTierGroups, props.networkIds)

    const saveRegisteredDomain = useSaveRegisteredDomain(props.enableAccessTierGroups, {
        onSuccess: () => setCurrentTab("dnsSettings"),
    })

    const validate = useValidateRegisteredDomain({
        onSuccess: () =>
            saveRegisteredDomain.data && props.modalRef.close(saveRegisteredDomain.data),
    })

    function onConfigurationCancel(): void {
        props.modalRef.cancel(0)
    }

    function onDnsValidate(): void {
        saveRegisteredDomain.data && validate.mutate(saveRegisteredDomain.data.id)
    }

    function onDnsBack(): void {
        validate.reset()
        setCurrentTab("configuration")
    }

    if (clustersQuery.status === "loading") {
        return <Loader center medium />
    }

    if (clustersQuery.status === "error") {
        return <ErrorBanner children={String(clustersQuery.error)} />
    }

    return (
        <div>
            <AppText ls="addRegisteredDomainDescription" className={styles.description} />
            <TabBar
                className={styles.tabs}
                tabs={tabs}
                selectedTabId={currentTab}
                onChange={setCurrentTab}
                type="stepper"
            />
            {currentTab === "configuration" && (
                <RegisteredDomainConfigurationForm
                    id="configuration-tab"
                    registeredDomain={saveRegisteredDomain.data}
                    clusters={clustersQuery.data}
                    onSubmit={saveRegisteredDomain.mutate}
                    onCancel={onConfigurationCancel}
                    isSubmitting={saveRegisteredDomain.isLoading}
                    error={saveRegisteredDomain.error}
                    enableAccessTierGroups={props.enableAccessTierGroups}
                    canAddRegisteredDomain={props.canAddRegisteredDomain}
                />
            )}
            {currentTab === "dnsSettings" && (
                <RegisteredDomainDnsForm
                    id="dnsSettings-tab"
                    registeredDomain={saveRegisteredDomain.data}
                    onValidate={onDnsValidate}
                    onBack={onDnsBack}
                    isValidating={validate.isLoading}
                    error={validate.error}
                />
            )}
        </div>
    )
}

interface RegisteredDomainsModalProps {
    modalRef: ModalRef
    networkIds: string[]
    enableAccessTierGroups: boolean
    canAddRegisteredDomain?: boolean
}
