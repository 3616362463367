import { UrlUtil } from "../../pre-v3/utils/Url.util"
import { BaseApi, PaginationOptions, PaginatedResponse } from "./Base.api"

export class ServiceAccountApi extends BaseApi {
    public getServiceAccounts(
        getServiceAccountsParams?: Partial<GetServiceAccountsParams>
    ): Promise<PaginatedResponse<"service_accounts", ServiceAccountRes>> {
        const params = getServiceAccountsParams
            ? UrlUtil.convertToURLSearchParams(getServiceAccountsParams).toString()
            : ""

        return this.get(`/api/v2/service_account${params && `?${params}`}`, {
            default: this.localization.getString(
                "failedToGetSomething",
                this.localization.getString("serviceAccounts")
            ),
        })
    }

    public createServiceAccount(serviceAccountReq: ServiceAccountReq): Promise<ServiceAccountRes> {
        return this.post("/api/v2/service_account", serviceAccountReq, {
            409: this.localization.getString(
                "somethingNamedAlreadyExists",
                this.localization.getString("serviceAccount"),
                serviceAccountReq.name
            ),
            default: this.localization.getString("failedToCreateServiceAccount"),
        })
    }

    public updateServiceAccount(
        id: string,
        serviceAccountReq: ServiceAccountReq
    ): Promise<ServiceAccountRes> {
        return this.put(`/api/v2/service_account/${id}`, serviceAccountReq, {
            default: this.localization.getString("failedToUpdateServiceAccount"),
        })
    }

    public deleteServiceAccount(id: string): Promise<void> {
        return this.delete(
            `/api/v2/service_account/${id}`,
            {},
            {
                default: this.localization.getString("failedToDeleteServiceAccount"),
            }
        )
    }
}

type ServiceAccountOrderByReq = "name" | "description"

export interface GetServiceAccountsParams extends PaginationOptions<ServiceAccountOrderByReq> {
    id: string
    name_like: string
    service_account_type: ServiceAccountType
}

export interface ServiceAccountRes {
    id: string
    name: string
    description: string
    service_account_type: ServiceAccountType
    api_key?: string
    oidc_tokens?: OidcTokens
    roles: string[]
    updated_at: number
    updated_by: string
    last_authorized_at: number
}

export interface ServiceAccountReq {
    name: string
    description?: string
    service_account_type: ServiceAccountType
    api_key?: string
    oidc_tokens?: OidcTokens
}

type ServiceAccountType = "banyankey" | "externalkey" | "jwttoken"

interface OidcTokens {
    type: "gcp"
    claims: Claim[]
}

interface Claim {
    aud: string
    iss: string
    sub: string
}
