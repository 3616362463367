import classNames from "classnames/bind"
import React from "react"
import styles from "./NavButton.module.scss"
import { NavLink } from "react-router-dom"

export default function () {
    return (
        <NavLink
            exact={this.props.exact}
            activeClassName="navButtonActive"
            className={classNames(styles.navButton, this.props.className)}
            to={this.props.href}
            isActive={this.isActive}
            aria-label={this.props.ariaLabel}
        >
            <span className={styles.navLabel}>{this.props.children}</span>
        </NavLink>
    )
}
