import { Singleton } from "../decorators/Singleton.decorator"
import { CloudResourceStatus } from "../services"
import type { PaginatedResponse } from "../../v3/api/Base.api"
import { BaseV2Api } from "./BaseV2.api"

@Singleton("InventoryApi")
export class InventoryApi extends BaseV2Api {
    public getCloudResources(): Promise<PaginatedResponse<"result", CloudResourceRes>> {
        return this.get("/cloud_resource")
    }

    public getCloudResource(id: string): Promise<CloudResourceRes> {
        return this.get(`/cloud_resource/${encodeURIComponent(id)}`)
    }

    public getResourceAndServiceDetails(
        search: CloudResourceServiceReq
    ): Promise<PaginatedResponse<"result", CloudResourceServiceRes>> {
        const urlParams: URLSearchParams = new URLSearchParams(search as any)
        return this.get(`/cloud_resource_service?${urlParams.toString()}`)
    }

    public updateResourceStatus(
        id: string,
        payload: CloudResourceStatusReq
    ): Promise<PaginatedResponse<"result", CloudResourceRes>> {
        return this.patch(`/cloud_resource/${encodeURIComponent(id)}`, payload)
    }

    public publishResource(payload: CloudResourceServiceReq): Promise<void> {
        return this.post("/cloud_resource_service", payload)
    }

    public deleteResourceServiceAssociation(
        id: string
    ): Promise<PaginatedResponse<"result", CloudResourceServiceRes>> {
        return this.delete(`/cloud_resource_service/${id}`, {})
    }
}

export interface CloudResourceServiceReq {
    service_id?: string
    cloud_resource_id?: string
}

export interface CloudResourceStatusReq {
    status: CloudResourceStatus
}

export interface CloudResourceRes {
    id: string
    org_id: string
    resource_id: string
    resource_name: string
    resource_type: string
    parent_id: string
    public_dns_name: string
    private_dns_name: string
    public_ip: string
    private_ip: string
    region: string
    service_id: string
    status: string
    cloud_provider: string
    created_at: number
    updated_at: number
    account: string
    ports: string
    tags: CloudResourceTagRes[]
}

export interface CloudResourceTagRes {
    id: string
    cloud_resource_id: string
    name: string
    value: string
}

export interface CloudResourceServiceRes {
    id: string
    org_id: string
    cloud_resource_id: string
    service_id: string
    created_at?: number
    created_by?: string
    updated_at?: number
    updated_by?: string
    port?: number
    backend_port: string
    service_name: string
    service_type: string
    cluster_name?: string
    resource_id?: string
    resource_name: string
    resource_type?: string
    status?: string
    public_dns_name?: string
    private_dns_name?: string
    public_ip?: string
    private_ip?: string
}
