export function matchesWildcard(parent: string, child: string): boolean {
    // the parent url is something of the form foo.bar.baz.quz
    // any of those parts can be wildcards that stand for any number
    // of subdivisions

    // to match the child against the parent, we're going to turn parent into a regex
    let regexString = `^${parent}$`
        // *. can stand for any number of valid sections (note: . will be escaped in the next line)
        .replace(/\*\./g, "([a-zA-Z0-9-]+.)+")
        // we need to make sure that . is preserved
        .replace(/\./g, "\\.")
        // any * left over can stand for anything
        .replace(/\*/g, ".*")

    // check if the child matches the pattern
    return Boolean(child.match(new RegExp(regexString)))
}

export function removeLeadingWildcard(value: string): string {
    if (value.startsWith("*.")) {
        return value.substring(2)
    } else {
        return value
    }
}

export function hasLeadingWildcard(value: string): boolean {
    return value.startsWith("*")
}

export function concatUrl(a: string, b: string): string {
    if ((b.startsWith(".") && !a.endsWith(".")) || (!b.startsWith(".") && a.endsWith("."))) {
        return a + b
    } else if (b.startsWith(".") && a.endsWith(".")) {
        return a + b.substring(1)
    } else {
        return a + "." + b
    }
}

export function getFirstSubdomain(url: string): string {
    return url.split(".")[0]
}
