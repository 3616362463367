import * as RadixToast from "@radix-ui/react-toast"
import React from "react"

import styles from "./Toast.module.scss"
import classNames from "classnames"
import { Icon, IconSize, IconType } from "../icon/Icon.component"
import { ToastIconsMap, ToastVariant } from "./Toast.types"

export interface ToastProps {
    variant: ToastVariant
    actionLabel?: string
    onAction?: React.MouseEventHandler<HTMLButtonElement>
    duration?: number
}

export interface ToastApi {
    openToast(label: string): void
}

const Toast = React.forwardRef<ToastApi, ToastProps>((props, ref) => {
    const { variant, actionLabel, onAction, duration = 3000 } = props

    const classNameMap: Record<ToastVariant, string> = {
        [ToastVariant.WARNING]: styles.warning,
        [ToastVariant.INFORMATION]: styles.information,
        [ToastVariant.GENERIC_PRIMARY]: styles.genericPrimary,
        [ToastVariant.GENERIC_SECONDARY]: styles.genericSecondary,
        [ToastVariant.ERROR]: styles.error,
        [ToastVariant.SUCCESS]: styles.success,
    }

    const [label, setLabel] = React.useState<string>()

    React.useImperativeHandle(ref, () => ({
        openToast: setLabel,
    }))

    const onOpenChange = (open: boolean) => {
        if (!open) setLabel(undefined)
    }

    const onClose: React.MouseEventHandler = (e) => {
        e.preventDefault()
        setLabel(undefined)
    }

    return (
        <RadixToast.Root
            className={classNames(styles.toast, styles.floating, classNameMap[variant])}
            open={!!label}
            onOpenChange={onOpenChange}
            duration={duration}
            aria-label={label}
        >
            <RadixToast.Description asChild>
                <div className={styles.group}>
                    <Icon size={IconSize.LARGE} icon={ToastIconsMap[variant]} />
                    <span>{label}</span>
                </div>
            </RadixToast.Description>
            <div className={styles.group}>
                {actionLabel && (
                    <RadixToast.Action asChild altText={actionLabel}>
                        {actionLabel && (
                            <button className={styles.actionButton} onClick={onAction}>
                                {actionLabel}
                            </button>
                        )}
                    </RadixToast.Action>
                )}
                <RadixToast.Close asChild>
                    <button className={styles.close} onClick={onClose}>
                        <Icon icon={IconType.CROSS} />
                    </button>
                </RadixToast.Close>
            </div>
        </RadixToast.Root>
    )
})

export const ToastProvider: React.FC<React.PropsWithChildren> = ({ children }) => {
    return (
        <RadixToast.Provider swipeDirection="right">
            <RadixToast.Viewport className={styles.ToastViewport} />
            {children}
        </RadixToast.Provider>
    )
}

export const WarningToast = React.forwardRef<ToastApi, Omit<ToastProps, "variant">>(
    (props, ref) => <Toast {...props} ref={ref} variant={ToastVariant.WARNING} />
)

export const InformationToast = React.forwardRef<ToastApi, Omit<ToastProps, "variant">>(
    (props, ref) => <Toast {...props} ref={ref} variant={ToastVariant.INFORMATION} />
)

export const GenericPrimaryToast = React.forwardRef<ToastApi, Omit<ToastProps, "variant">>(
    (props, ref) => <Toast {...props} ref={ref} variant={ToastVariant.GENERIC_PRIMARY} />
)

export const GenericSecondaryToast = React.forwardRef<ToastApi, Omit<ToastProps, "variant">>(
    (props, ref) => <Toast {...props} ref={ref} variant={ToastVariant.GENERIC_SECONDARY} />
)

export const ErrorToast = React.forwardRef<ToastApi, Omit<ToastProps, "variant">>((props, ref) => (
    <Toast {...props} ref={ref} variant={ToastVariant.ERROR} />
))

export const SuccessToast = React.forwardRef<ToastApi, Omit<ToastProps, "variant">>(
    (props, ref) => <Toast {...props} ref={ref} variant={ToastVariant.SUCCESS} />
)
