import React from "react"
import styles from "./PreferredAppsOSCellRenderer.module.scss"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faCheck } from "@fortawesome/pro-solid-svg-icons"

export default function () {
    return (
        <div>
            {this.state.confirmed && <FontAwesomeIcon className={styles.icon} icon={faCheck} />}
        </div>
    )
}
