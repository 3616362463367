import React, { useRef } from "react"
import { Input } from "../input/Input.component"
import { Button, ButtonElement, ButtonType, IconType } from "../button/Button.component"
import { SuccessToast, ToastApi } from "../toast/Toast.components"
import { useServiceLocalization } from "../../pre-v3/services/localization/Localization.service"
import styles from "./CopyInput.module.scss"
import { useCopyToClipboard } from "../../hooks/useCopyToClipboard.hook"

interface CopyInputProps {
    text: string
    hideCopyButton?: boolean
    id?: string
}
export function CopyInput({ text, hideCopyButton, id }: CopyInputProps) {
    const localization = useServiceLocalization()
    const toastApi = useRef<ToastApi>(null)

    const copyToClipboard = useCopyToClipboard((text) => {
        toastApi.current?.openToast(localization.getString("valueSuccessfullyCopied", text))
    })
    const onCopy = async () => {
        copyToClipboard(text)
    }

    return (
        <div className={styles.copyInput}>
            <Input value={text} id={id} />
            {!hideCopyButton && (
                <Button
                    asElement={ButtonElement.BUTTON}
                    buttonType={ButtonType.SECONDARY}
                    icon={IconType.COPY}
                    onClick={onCopy}
                    type="button"
                >
                    {localization.getString("copy")}
                </Button>
            )}
            <SuccessToast ref={toastApi} />
        </div>
    )
}
