import React from "react"
import { useServiceLocalization } from "../../../../pre-v3/services/localization/Localization.service"
import styles from "./TrustIntegrationForm.module.scss"

// Note: Old components, move them to v3
import { InputWithHide } from "../../../../pre-v3/components/input-with-hide/InputWithHide.component"
import { FormLabel } from "../../../../pre-v3/components/form-label/FormLabel"
import { Input } from "../../../components/input/Input.component"

export interface Props {
    apiId: string
    onApiIdChange: (apiId: string) => void
    apiEndpoint: string
    onApiEndpointChange: (apiEndpoint: string) => void
    disabled: boolean
    apiSecret: string
    onApiSecretChange: (apiSecret: string) => void
    canWriteAll?: boolean
}

export function CrowdStrikeApiIntegration(props: Props) {
    const {
        apiId,
        onApiIdChange,
        apiEndpoint,
        onApiEndpointChange,
        disabled,
        apiSecret,
        onApiSecretChange,
    } = props

    const localization = useServiceLocalization()

    return (
        <>
            <FormLabel title={localization.getString("apiEndpoint")}>
                <Input
                    type="text"
                    value={apiEndpoint}
                    onChange={(e) => onApiEndpointChange(e.target.value)}
                    placeholder={localization.getString("apiEndpointPlaceholder")}
                    className={styles.input}
                    disabled={disabled}
                    required
                />
            </FormLabel>
            <FormLabel title={localization.getString("id")}>
                <Input
                    type={props.canWriteAll ? "text" : "password"}
                    value={apiId}
                    onChange={(e) => onApiIdChange(e.target.value)}
                    placeholder={localization.getString("enterId")}
                    className={styles.input}
                    disabled={disabled}
                    required
                />
            </FormLabel>
            <FormLabel title={localization.getString("secret")}>
                {disabled ? (
                    <InputWithHide
                        className={styles.input}
                        disabled
                        value={apiSecret}
                        hideEyeIcon={!props.canWriteAll}
                    />
                ) : (
                    <Input
                        type="text"
                        value={apiSecret}
                        onChange={(e) => onApiSecretChange(e.target.value)}
                        placeholder={localization.getString("enterSecret")}
                        className={styles.input}
                        disabled={disabled}
                        required
                    />
                )}
            </FormLabel>
        </>
    )
}
