import React, { Fragment } from "react"

import {
    Button,
    ButtonElement,
    ButtonType,
} from "../../../../../components/button/Button.component"
import { AppText } from "../../../../../v3/components/app-text/AppText.component"
import { ErrorBanner, InfoBanner } from "../../../../../v3/components/banner/Banner.component"
import { Input } from "../../../../../v3/components/input/Input.component"
import { Loader } from "../../../../../v3/components/loader/Loader.component"
import { Switch } from "../../../../../v3/components/switch/Switch.component"
import { TextArea } from "../../../../../v3/components/input/TextArea.component"
import { IdpType } from "../../../../../v3/services/UserProfile.service"
import {
    Select,
    FormLabel,
    FormRow,
    Form,
    FormColumns,
    SectionScreen,
} from "../../../../components"
import { ConfirmationDialog } from "../../../../components/dialog/confirmation/ConfirmationDialog.component"
import { OrgSignOnSettings } from "./OrgSignOnSettings.component"
import styles from "./OrgSignOnSettings.module.scss"

export default function (this: OrgSignOnSettings) {
    return (
        <Loader isLoading={this.state.isLoading}>
            {this.state.isBanyanIDP ? (
                <>
                    <FormRow>
                        {this.state.configureError && (
                            <ErrorBanner className={styles.error}>
                                {this.state.configureError}
                            </ErrorBanner>
                        )}
                    </FormRow>
                    <SectionScreen
                        title={this.localizationService.getString("signOnSettings")}
                        description={<AppText ls="signOnSettingsConfigureDescription" />}
                        onConfigureButtonClick={this.onConfigure}
                        disableButton={!this.props.canConfigureAdminSignOnSettings}
                    />
                </>
            ) : (
                <div>
                    <Form onSubmit={this.onSubmit} display="grid" labelWidth={215} floating>
                        <button
                            ref={(c) => (this.submitButton = c)}
                            type="submit"
                            className={styles.hiddenSubmitButton}
                        />
                        <FormColumns
                            right={<h2>{this.localizationService.getString("signOnSettings")}</h2>}
                        />
                        <FormLabel
                            title={this.localizationService.getString("orgName")}
                            htmlFor="issuerUrl"
                        >
                            <Input
                                id="issuerUrl"
                                defaultValue={this.state.orgName}
                                className={styles.formInput}
                                required
                                disabled
                            />
                        </FormLabel>
                        <FormLabel
                            title={this.localizationService.getString("orgId")}
                            htmlFor="orgId"
                        >
                            <Input
                                id="orgId"
                                name="orgId"
                                defaultValue={this.state.orgId}
                                className={styles.formInput}
                                required
                                disabled
                            />
                        </FormLabel>
                        <FormLabel
                            title={this.localizationService.getString("signOnMethod")}
                            htmlFor="signOnMethod"
                        >
                            <Select
                                id="signOnMethod"
                                name="signOnMethod"
                                required
                                value={this.state.ssoMethod}
                                options={this.ssoOptions}
                                // @ts-ignore
                                onChange={this.onMethodChange}
                                disabled={!this.props.canConfigureAdminSignOnSettings}
                            />
                        </FormLabel>
                        {this.state.ssoMethod === IdpType.SAML && (
                            <Fragment>
                                <FormLabel
                                    title={this.localizationService.getString("redirectUrlSamlAcs")}
                                    htmlFor="ssoUrl"
                                >
                                    <Input
                                        id="ssoUrl"
                                        name="ssoUrl"
                                        defaultValue={this.state.ssoUrl}
                                        className={styles.formInput}
                                        required
                                        disabled
                                    />
                                </FormLabel>
                                {this.state.spMetadataUrl && (
                                    <FormLabel
                                        title={this.localizationService.getString("spMetadataUrl")}
                                        htmlFor="spMetadataUrl"
                                    >
                                        <Input
                                            id="spMetadataUrl"
                                            name="spMetadataUrl"
                                            value={this.state.spMetadataUrl}
                                            className={styles.formInput}
                                            required
                                            disabled
                                        />
                                    </FormLabel>
                                )}
                                {this.state.spCertificateInfo?.Expires && (
                                    <FormLabel
                                        title={this.localizationService.getString("spExpires")}
                                        htmlFor="spExpires"
                                    >
                                        <Input
                                            id="spExpires"
                                            name="spExpires"
                                            value={this.state.spCertificateInfo.Expires}
                                            className={styles.formInput}
                                            disabled
                                        />
                                    </FormLabel>
                                )}
                                <FormLabel
                                    title={this.localizationService.getString("spIssuer")}
                                    htmlFor="spIssuer"
                                >
                                    <Input
                                        id="spIssuer"
                                        name="spIssuer"
                                        className={styles.formInput}
                                        defaultValue={
                                            this.state.spIssuer
                                                ? this.state.spIssuer
                                                : this.state.ssoUrl
                                        }
                                        required
                                        placeholder={this.localizationService.getString("spIssuer")}
                                        disabled={!this.props.canConfigureAdminSignOnSettings}
                                    />
                                </FormLabel>
                                {this.state.spCertificateInfo?.Certificate && (
                                    <FormLabel
                                        title={this.localizationService.getString(
                                            "spSigningCertificate"
                                        )}
                                        htmlFor="spSigningCertificate"
                                    >
                                        <TextArea
                                            className={styles.certTextArea}
                                            id="spSigningCertificate"
                                            disabled
                                            readOnly
                                            value={this.state.spCertificateInfo.Certificate}
                                        />
                                    </FormLabel>
                                )}
                                <FormLabel
                                    title={this.localizationService.getString("idpIssuer")}
                                    htmlFor="idpIssuer"
                                >
                                    <Input
                                        id="idpIssuer"
                                        name="idpIssuer"
                                        defaultValue={this.state.idpIssuer}
                                        className={styles.formInput}
                                        required
                                        placeholder={this.localizationService.getString(
                                            "idpIssuer"
                                        )}
                                        disabled={!this.props.canConfigureAdminSignOnSettings}
                                    />
                                </FormLabel>
                                <FormLabel
                                    title={this.localizationService.getString("idpMetadataUrl")}
                                    htmlFor="idpMetadataUrl"
                                >
                                    <Input
                                        id="idpMetadataUrl"
                                        name="idpMetadataUrl"
                                        defaultValue={this.state.idpMetadataUrl}
                                        className={styles.formInput}
                                        placeholder={this.localizationService.getString(
                                            "idpMetadataUrl"
                                        )}
                                        disabled={!this.props.canConfigureAdminSignOnSettings}
                                    />
                                </FormLabel>
                                <FormColumns
                                    right={this.localizationService.getString("or").toUpperCase()}
                                />
                                <FormLabel
                                    title={this.localizationService.getString("idpRawMetadataXML")}
                                    htmlFor="idpRawMetadata"
                                >
                                    <TextArea
                                        id="idpRawMetadata"
                                        defaultValue={this.state.idpRawMetadata}
                                        className={styles.formTextArea}
                                        placeholder={this.localizationService.getString(
                                            "idpRawMetadataPlaceholder"
                                        )}
                                        disabled={!this.props.canConfigureAdminSignOnSettings}
                                    />
                                </FormLabel>
                            </Fragment>
                        )}
                        <FormRow>
                            {this.state.error && <ErrorBanner>{this.state.error}</ErrorBanner>}
                            {this.state.success && <InfoBanner>{this.state.success}</InfoBanner>}
                        </FormRow>
                        {this.props.canConfigureAdminSignOnSettings && (
                            <FormColumns
                                right={
                                    <Button
                                        buttonType={ButtonType.PRIMARY}
                                        asElement={ButtonElement.BUTTON}
                                        loading={this.state.loading}
                                        onClick={this.onConfirmationDialogOpen}
                                        type="button"
                                    >
                                        {this.localizationService.getString("update")}
                                    </Button>
                                }
                            />
                        )}
                    </Form>
                    <Form onSubmit={this.onSubmitOtp} display="grid" labelWidth={215} floating>
                        <FormColumns
                            right={
                                <h2>{this.localizationService.getString("localAdminSettings")}</h2>
                            }
                        />
                        <FormLabel
                            title={this.localizationService.getString("enableTotp")}
                            htmlFor="enableTotp"
                        >
                            <Switch
                                id="enableTotp"
                                className={styles.totpSwitch}
                                value={this.state.totpEnabled}
                                onChange={(v) => this.setState({ totpEnabled: v })}
                                disabled={!this.props.canConfigureAdminSignOnSettings}
                            />
                        </FormLabel>
                        <FormRow>
                            {this.state.localSettingError && (
                                <ErrorBanner>{this.state.localSettingError}</ErrorBanner>
                            )}
                            {this.state.localSettingSuccess && (
                                <InfoBanner>{this.state.localSettingSuccess}</InfoBanner>
                            )}
                        </FormRow>
                        {this.props.canConfigureAdminSignOnSettings && (
                            <FormColumns
                                right={
                                    <Button
                                        buttonType={ButtonType.PRIMARY}
                                        asElement={ButtonElement.BUTTON}
                                        loading={this.state.isLocalSettingsLoading}
                                        type="submit"
                                    >
                                        {this.localizationService.getString("update")}
                                    </Button>
                                }
                            />
                        )}
                    </Form>
                    <ConfirmationDialog
                        open={this.state.showConfirmationDialog}
                        title={this.localizationService.getString("updateSignOnSettings")}
                        onClose={this.onConfirmationDialogClose}
                        onConfirm={this.onConfirm}
                    >
                        {this.localizationService.getString("updateSignOnSettingsDescription")}
                    </ConfirmationDialog>
                </div>
            )}
        </Loader>
    )
}
