import React from "react"
import styles from "./ServicesAddAppType.module.scss"
import { ToggleButton, FormLabel, Input } from "../../../../components"

export default function () {
    return (
        <div className={this.props.className}>
            <FormLabel
                title={this.localizationService.getString("serviceType")}
                htmlFor="serviceType"
            >
                {this.props.disabled ? (
                    <Input
                        className={styles.marginLeft}
                        disabled
                        value={this.state.appTypes[this.state.selectedIndex].label}
                    />
                ) : (
                    <ToggleButton className={styles.marginLeft} items={this.state.appTypes} />
                )}
            </FormLabel>
        </div>
    )
}
