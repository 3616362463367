import classNames from "classnames"
import * as React from "react"
import styles from "./TrustIntegrationForm.module.scss"
import { useEffect, useRef } from "react"
import { useServiceLocalization } from "../../../../pre-v3/services/localization/Localization.service"
import { Checkbox } from "../../../components/checkbox/Checkbox.component"
import { Platform, TrustIntegration } from "../../../services/TrustIntegration.service"

interface PlatformObj {
    platform: Platform
    header: string
    show?: boolean
}

interface Props {
    ztaScorePlatforms: Platform[]
    onZtaScorePlatformsChange?: (platforms: Platform[]) => void
    disabled?: boolean
    integrationPartner: TrustIntegration["integrationPartner"] | ""
    registeredPlatforms: Platform[]
    onRegisteredPlatformsChange?: (platforms: Platform[]) => void
    notActiveThreatPlatforms: Platform[]
    onNotActiveThreatPlatformsChange?: (platforms: Platform[]) => void
}

export function PlatformSelection(props: Props) {
    const localization = useServiceLocalization()
    const inputRef = useRef<HTMLInputElement | null>(null)

    const isCrowdStrike = props.integrationPartner === "crowdstrike"

    const platforms: PlatformObj[] = [
        {
            header: localization.getString("macOS"),
            platform: "macos",
            show: true,
        },
        {
            header: localization.getString("windows"),
            platform: "windows",
            show: true,
        },
        {
            header: localization.getString("linux"),
            platform: "linux",
            show: true,
        },
        {
            header: localization.getString("iOS"),
            platform: "ios",
        },
        {
            header: localization.getString("android"),
            platform: "android",
        },
    ]

    const platformRows = isCrowdStrike
        ? [
              {
                  name: localization.getString("ztaScore"),
                  selectedData: props.ztaScorePlatforms,
                  onSelectedDataChange: props.onZtaScorePlatformsChange,
              },
          ]
        : [
              {
                  name: localization.getString("registeredWith"),
                  selectedData: props.registeredPlatforms,
                  onSelectedDataChange: props.onRegisteredPlatformsChange,
              },
              {
                  name: localization.getString("notActiveThreat"),
                  selectedData: props.notActiveThreatPlatforms,
                  onSelectedDataChange: props.onNotActiveThreatPlatformsChange,
              },
          ]

    useEffect(() => {
        let error: string = ""

        const isSelected = platformRows.some((platformRow) => {
            return platformRow.selectedData.length !== 0
        })

        if (!isSelected) {
            error = isCrowdStrike
                ? localization.getString("operatingSystemSelectionIsRequired")
                : localization.getString("operatingSystemSelectionForAtLeastOneFactorIsRequired")
        }

        inputRef.current?.setCustomValidity(error)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [platformRows, isCrowdStrike])

    return (
        <div
            className={classNames(
                styles.gridContainer,
                styles.platformSelection,
                styles.fakeInputWrapper
            )}
        >
            <table className={classNames(styles.table)}>
                <thead>
                    <tr className={styles.tr}>
                        <th className={classNames(styles.text, styles.nameColumn)}>
                            {localization.getString("name")}
                        </th>
                        {platforms.map((platform, i) => (
                            <th key={i} className={classNames(styles.header, styles.text)}>
                                {platform.header}
                            </th>
                        ))}
                    </tr>
                </thead>
                <tbody>
                    {platformRows.map((row, rowIndex) => {
                        return (
                            <TableRow
                                key={rowIndex}
                                disabled={props.disabled}
                                data={platforms}
                                selectedData={row.selectedData}
                                onSelectedDataChange={row.onSelectedDataChange}
                                name={row.name}
                            />
                        )
                    })}
                </tbody>
            </table>
            <input className={styles.fakeInput} ref={inputRef} />
        </div>
    )
}

interface TableRowProps {
    selectedData: Platform[]
    data: PlatformObj[]
    disabled?: boolean
    onSelectedDataChange?: (selectedData: Platform[]) => void
    name: string
}

function TableRow(props: TableRowProps) {
    const { data, selectedData, disabled, onSelectedDataChange, name } = props

    function onChange(currentData: PlatformObj): void {
        let updateSelectedData: Platform[]

        if (selectedData.includes(currentData.platform)) {
            updateSelectedData = selectedData.filter((entity) => {
                return entity !== currentData.platform
            })
        } else {
            updateSelectedData = [...selectedData, currentData.platform]
        }

        onSelectedDataChange?.(updateSelectedData)
    }

    return (
        <tr>
            <td className={classNames(styles.text, styles.nameCell)}>{name}</td>
            {data.map((entity, i) => (
                <td className={styles.header} key={i}>
                    {entity.show && (
                        <Checkbox
                            checked={selectedData.includes(entity.platform)}
                            onChange={() => onChange(entity)}
                            disabled={disabled}
                        />
                    )}
                </td>
            ))}
        </tr>
    )
}
