import {
    QueryKey,
    UseMutationResult,
    UseQueryResult,
    useMutation,
    useQuery,
    useQueryClient,
} from "@tanstack/react-query"

import { Singleton } from "../../pre-v3/decorators/Singleton.decorator"
import { LanguageKey } from "../../pre-v3/services/localization/languages/en-US.language"
import { LocalizationService } from "../../pre-v3/services/localization/Localization.service"
import { Paginated, PaginatedSearch } from "../../pre-v3/utils/AgGrid.util"
import { DateUtil, HumanTimespan } from "../../pre-v3/utils/Date.util"
import { decodeID } from "../../pre-v3/utils/Url.util"
import {
    GetDevicesParams,
    DeviceApi,
    DeviceDiagnosticRes,
    DeviceOrderByReq,
    DeviceRes,
    DiagnosticErrorMessage,
    OwnershipRes,
    PlatformRes,
    TrustFactorRes,
    TrustLevelRes,
    AccessTierConnectionInfoRes,
} from "../api/Device.api"
import { ReportApi, ServiceEndUsersRes } from "../api/Report.api"
import { ServiceTunnelApi } from "../api/ServiceTunnel.api"
import { TrustProfileApi, TrustProfileRes } from "../api/TrustProfile.api"
import { DeviceOwnership } from "./shared/DeviceOwnership"
import { Platform } from "./shared/Platform"
import { ApiFunction } from "./shared/QueryKey"
import {
    TrustFactorType,
    descriptionDictionary,
    isTrustFactorType,
    nameDictionary,
} from "./shared/TrustFactorType"

@Singleton("DeviceService")
export class DeviceService {
    private static EXPIRY: HumanTimespan = { days: 28 } //expiry is days

    private deviceApi = new DeviceApi()
    private localization = new LocalizationService()
    private reportApi = new ReportApi()
    private trustProfileApi = new TrustProfileApi()

    public async getDevices(filter?: PaginatedSearch): Promise<Paginated<Device>> {
        const queryParams = getDevicesParams(filter)

        const { devices, count } = await this.deviceApi.getDevices(queryParams)

        return {
            data: devices.map((deviceRes) => this.mapDeviceResToDeviceDetails(deviceRes)),
            total: count,
        }
    }

    public async downloadDevicesCsv(): Promise<void> {
        const csvBlob = await this.deviceApi.getDevicesCsvBlob({ active: "true" })

        const blobUrl = URL.createObjectURL(new Blob([csvBlob], { type: "text/csv" }))
        const link = document.createElement("a")
        link.download = `${this.localization.getString("devices")}.csv`
        link.href = blobUrl
        link.click()
        URL.revokeObjectURL(blobUrl)
    }

    public removeDevices(devices: Device[]): Promise<void> {
        return this.deviceApi.deleteDevices({ device_ids: devices.map(getDeviceId) })
    }

    public async getTrustProfiles(hasMigrated?: boolean): Promise<TrustProfile[]> {
        const trustProfilesRes = await (hasMigrated
            ? this.trustProfileApi.getTrustProfiles()
            : Promise.resolve([]))

        return trustProfilesRes
            .map(mapTrustProfileFromRes)
            .sort((trustProfileA: TrustProfile, trustProfileB: TrustProfile): number =>
                this.compareTrustProfiles(trustProfileA, trustProfileB)
            )
    }

    public async getDeviceBySerialNumber(serialNumber: string): Promise<Device> {
        const { devices } = await this.deviceApi.getDevices({
            active: "true",
            serial_number: serialNumber,
        })
        const [deviceRes] = devices

        if (!deviceRes) return Promise.reject(this.localization.getString("deviceNotFound"))

        return this.mapDeviceResToDeviceDetails(deviceRes)
    }

    public async getDevicesByUserEmail(email: string): Promise<Device[]> {
        const { devices } = await this.deviceApi.getDevices({ active: "true", email })
        return devices.map((device) => this.mapDeviceResToDeviceDetails(device))
    }

    public async updateDeviceDetails(device: Device): Promise<Device> {
        await this.deviceApi.updateDevice(device.serialNumber, {
            Model: device.model,
            Ownership: device.deviceOwnership,
            Platform: platformResDict[device.platform as Exclude<Platform, Platform.CHROME>],
            OS: device.osVersion,
            Architecture: device.architecture,
            Banned: device.status.type === StatusType.BANNED,
        })

        return this.getDeviceBySerialNumber(device.serialNumber)
    }

    public removeDevice(device: Device): Promise<void> {
        return this.removeDevices([device])
    }

    public unregisterUser(device: Device, userEmail: string): Promise<void> {
        return this.deviceApi.unregisterUser({
            SerialNumber: device.serialNumber,
            Email: userEmail,
        })
    }

    public async getDeviceAccessActivity(device: Device): Promise<AccessActivity[]> {
        const endTime = Date.now()
        const startTime = endTime - DateUtil.DAY * 14

        const { service_endusers } = await this.reportApi.getServiceUserAccessedByDevice({
            report_type: "service_user_accessed_by_device",
            start_time: startTime,
            end_time: endTime,
            serial_number: device.serialNumber,
        })

        return service_endusers.map(mapAccessActivityFromRes)
    }

    public async getDeviceRemoteDiagnostics(device: Device): Promise<RemoteDiagnostics> {
        const diagnosticsRes = await this.deviceApi.getDeviceDiagnostic(device.id)
        return this.mapDiagnosticsRes(diagnosticsRes)
    }

    public async requestDeviceRemoteDiagnostics(device: Device): Promise<RemoteDiagnostics> {
        await this.deviceApi.sendDeviceDiagnosticReq(device.id)
        return this.getDeviceRemoteDiagnostics(device)
    }

    public async downloadDeviceRemoteDiagnosticsLogs(
        device: Device,
        remoteDiagnostics: SuccessRemoteDiagnostics
    ): Promise<void> {
        const logsBlob = await this.deviceApi.getDeviceDiagnosticLogs(device.id)

        const blobUrl = URL.createObjectURL(new Blob([logsBlob], { type: "application/zip" }))
        const link: HTMLAnchorElement = document.createElement("a")
        link.download = `${
            device.name + "_" + new Date(remoteDiagnostics.requestedAt).toISOString()
        }.zip`
        link.href = blobUrl
        link.click()
        URL.revokeObjectURL(blobUrl)
    }

    private getErrorMsg(error: DiagnosticErrorMessage): string {
        return this.localization.getString(ErrorMap[error] || "somethingWentWrongDescription")
    }

    private mapDeviceResToDeviceDetails(deviceRes: DeviceRes): Device {
        return {
            id: deviceRes.id,
            serialNumber: deviceRes.serial_number,
            name: deviceRes.name || deviceRes.serial_number,
            lastLoginAt: DateUtil.convertLargeTimestamp(deviceRes.last_login),
            platform: platformDict[deviceRes.platform],
            trustProfile: getTrustProfileFromDeviceRes(deviceRes),
            protectionProfile: deviceRes.threat_profile_id
                ? {
                      id: deviceRes.threat_profile_id,
                      name: deviceRes.threat_profile_display_name,
                      itpStatus: deviceRes.is_itp_enabled ? ItpStatus.ENABLED : ItpStatus.DISABLED,
                  }
                : undefined,
            status: this.getStatusFromDeviceRes(deviceRes),
            deviceOwnership: deviceOwnershipDict[deviceRes.ownership],
            appVersion: deviceRes.app_version,
            osVersion: deviceRes.os,
            model: deviceRes.model,
            architecture: deviceRes.architecture,
            roleNames: deviceRes.extra_details.role_names,
            userEmails: deviceRes.extra_details.emails || [],
            activeServiceTunnel: deviceRes.active_service_tunnel
                ? {
                      id: deviceRes.active_service_tunnel.id,
                      name: deviceRes.active_service_tunnel.name,
                      accessTierConnections:
                          deviceRes.active_service_tunnel.accesstier_connection_info.map(
                              mapAccessTierConnectionResToAccessTierConnection
                          ),
                  }
                : undefined,
        }
    }

    private getStatusFromDeviceRes(deviceRes: DeviceRes): Status {
        if (deviceRes.banned) return { type: StatusType.BANNED }

        switch (deviceRes.extra_details.trust.status) {
            case "Expired":
                return {
                    type: StatusType.EXPIRED,
                    lastEvaluatedAt: DateUtil.convertLargeTimestamp(deviceRes.last_evaluated_at),
                    expiredAt: DateUtil.convertLargeTimestamp(
                        deviceRes.extra_details.trust.expired_at
                    ),
                }

            case "Overridden":
                return {
                    type: StatusType.OVERRIDDEN,
                    lastEvaluatedAt: DateUtil.convertLargeTimestamp(deviceRes.last_evaluated_at),
                    overriddenTrustLevel: trustLevelDict[deviceRes.extra_details.trust.level],
                }

            case "Pending":
                return { type: StatusType.PENDING }

            case "Reporting":
                return {
                    type: StatusType.REPORTING,
                    lastEvaluatedAt: DateUtil.convertLargeTimestamp(deviceRes.last_evaluated_at),
                    trustLevel: trustLevelDict[deviceRes.extra_details.trust.level],
                    trustFactors: this.combineTrustFactorsResToTrustFactors(
                        deviceRes.extra_details.trust.factors
                    ),
                }
        }
    }

    private combineTrustFactorsResToTrustFactors(trustFactorsRes: TrustFactorRes[]): TrustFactor[] {
        if (trustFactorsRes.length <= 0) return []

        const emptyAcc: TrustFactorsAcc = {
            otherFactors: [],
        }

        const { fileCheck, propertyList, registryCheck, otherFactors } = trustFactorsRes.reduce(
            (acc, trustFactorRes) => this.reduceTrustFactorsRes(acc, trustFactorRes),
            emptyAcc
        )

        const trustFactors: TrustFactor[] = [
            ...otherFactors,
            ...(fileCheck ? [fileCheck] : []),
            ...(propertyList ? [propertyList] : []),
            ...(registryCheck ? [registryCheck] : []),
        ]

        return trustFactors.sort((trustFactorA, trustFactorB) =>
            this.compareTrustFactors(trustFactorA, trustFactorB)
        )
    }

    private reduceTrustFactorsRes(
        acc: TrustFactorsAcc,
        trustFactorRes: TrustFactorRes
    ): TrustFactorsAcc {
        const type = getTrustFactorType(trustFactorRes)

        switch (type) {
            case TrustFactorType.FILE_CHECK:
                return this.updateFileCheckTrustFactor(acc, trustFactorRes)

            case TrustFactorType.PLIST:
                return this.updatePropertyListTrustFactor(acc, trustFactorRes)

            case TrustFactorType.REGISTRY_CHECK:
                return this.updateRegistryCheckTrustFactor(acc, trustFactorRes)

            case TrustFactorType.APPLICATION_CHECK:
            case TrustFactorType.AUTO_UPDATE:
            case TrustFactorType.BANYAN_APP_VERSION:
            case TrustFactorType.DISK_ENCRYPTION:
            case TrustFactorType.FIREWALL:
            case TrustFactorType.NOT_ACTIVE_THREAT:
            case TrustFactorType.NOT_JAILBROKEN:
            case TrustFactorType.OPERATING_SYSTEM_VERSION:
            case TrustFactorType.REGISTERED_WITH:
            case TrustFactorType.SCREEN_LOCK:
            case TrustFactorType.ZTA_SCORE:
            case TrustFactorType.CS_REGISTERED_WITH:
            case TrustFactorType.CHROME_BROWSER_VERSION:
            case TrustFactorType.WS1_IS_COMPLIANT:
            case TrustFactorType.WS1_REGISTERED_WITH:
            case TrustFactorType.DEVICE_GEOLOCATION:
            case undefined: // For example, when the Device was Overridden
                return {
                    ...acc,
                    otherFactors: [
                        ...acc.otherFactors,
                        this.mapTrustFactorResToTrustFactor(trustFactorRes, type),
                    ],
                }
        }
    }

    private updateFileCheckTrustFactor(
        acc: TrustFactorsAcc,
        trustFactorRes: TrustFactorRes
    ): TrustFactorsAcc {
        const prevFileCheck =
            acc.fileCheck ??
            this.mapTrustFactorResToTrustFactor(trustFactorRes, TrustFactorType.FILE_CHECK)

        return { ...acc, fileCheck: addExtendedTrustFactor(prevFileCheck, trustFactorRes) }
    }

    private updatePropertyListTrustFactor(
        acc: TrustFactorsAcc,
        trustFactorRes: TrustFactorRes
    ): TrustFactorsAcc {
        const prevPropertyList =
            acc.propertyList ??
            this.mapTrustFactorResToTrustFactor(trustFactorRes, TrustFactorType.PLIST)

        return { ...acc, propertyList: addExtendedTrustFactor(prevPropertyList, trustFactorRes) }
    }

    private updateRegistryCheckTrustFactor(
        acc: TrustFactorsAcc,
        trustFactorRes: TrustFactorRes
    ): TrustFactorsAcc {
        const prevRegistryCheck =
            acc.registryCheck ??
            this.mapTrustFactorResToTrustFactor(trustFactorRes, TrustFactorType.REGISTRY_CHECK)

        return { ...acc, registryCheck: addExtendedTrustFactor(prevRegistryCheck, trustFactorRes) }
    }

    private mapTrustFactorResToTrustFactor(
        trustFactorRes: TrustFactorRes,
        type?: TrustFactorType
    ): TrustFactor {
        return {
            name: type ? this.localization.getString(nameDictionary[type]) : trustFactorRes.name,
            description: type
                ? this.localization.getString(descriptionDictionary[type])
                : trustFactorRes.description,
            source:
                trustFactorRes.type === "external"
                    ? trustFactorRes.source
                    : this.localization.getString("sonicWallCse"),
            isSatisfied: trustFactorRes.value === "true",
            extendedFactors: undefined,
        }
    }

    private mapDiagnosticsRes(diagnosticsRes: DeviceDiagnosticRes): RemoteDiagnostics {
        const requestedAt = DateUtil.convertLargeTimestamp(diagnosticsRes.message_posted_at)
        const logsCollectedAt = DateUtil.convertLargeTimestamp(
            diagnosticsRes.message_response_returned_at
        )

        if (!diagnosticsRes.message_posted_at) {
            return { status: RemoteDiagnosticsStatus.INACTIVE }
        }

        if (diagnosticsRes.message_posted_at && !diagnosticsRes.message_response_returned_at) {
            return { status: RemoteDiagnosticsStatus.PENDING, requestedAt }
        }

        if (
            DateUtil.hasBeenSince(
                DateUtil.convertLargeTimestamp(diagnosticsRes.message_response_returned_at),
                DeviceService.EXPIRY
            )
        ) {
            return { status: RemoteDiagnosticsStatus.EXPIRED, requestedAt, logsCollectedAt }
        }

        if (diagnosticsRes.message_response?.error_message) {
            return {
                status: RemoteDiagnosticsStatus.ERROR,
                requestedAt,
                errorMessage: this.getErrorMsg(diagnosticsRes.message_response.error_message),
            }
        }

        return { status: RemoteDiagnosticsStatus.SUCCESS, requestedAt, logsCollectedAt }
    }

    private compareTrustFactors(trustFactorA: TrustFactor, trustFactorB: TrustFactor): number {
        const locale = this.localization.getLocale()

        const compareSource = trustFactorA.source.localeCompare(trustFactorB.source, locale)

        if (compareSource !== 0) return compareSource

        return trustFactorA.name.localeCompare(trustFactorB.name, locale)
    }

    private compareTrustProfiles(trustProfileA: TrustProfile, trustProfileB: TrustProfile): number {
        const locale = this.localization.getLocale()

        return trustProfileA.name.localeCompare(trustProfileB.name, locale)
    }
}

const serviceName = "DeviceService"

enum DeviceHookKey {
    GET_DEVICES = "deviceService.getDevices",
    GET_DEVICE_BY_SERIAL_NUMBER = "deviceService.getDeviceBySerialNumber",
    GET_DEVICE_REMOTE_DIAGNOSTICS = "deviceService.getDeviceRemoteDiagnostics",
}

export function useGetDevices(
    params: PaginatedSearch = { skip: 0, limit: 1000 },
    options?: QueryOptions<Paginated<Device>>
): UseQueryResult<Paginated<Device>> {
    const deviceService = new DeviceService()

    return useQuery({
        ...options,
        queryKey: [DeviceHookKey.GET_DEVICES, params],
        queryFn: () => {
            return deviceService.getDevices(params)
        },
    })
}

export function useGetDevicesByRoleName(
    roleName: string
): UseMutationResult<Paginated<Device>, unknown, PaginatedSearch> {
    const deviceService = new DeviceService()

    return useMutation({
        mutationFn: (params: PaginatedSearch) => {
            return deviceService.getDevices({
                ...params,
                filterModel: { ...params?.filterModel, roles: { filter: roleName } },
            })
        },
    })
}

export function useDownloadDevicesCsv(
    options?: QueryOptions<void, unknown>
): UseMutationResult<void, unknown, void> {
    const deviceService = new DeviceService()

    return useMutation({
        ...options,
        mutationFn: () => deviceService.downloadDevicesCsv(),
    })
}

export function useRemoveDevices(
    options?: QueryOptions<void, unknown, Device[]>
): UseMutationResult<void, unknown, Device[]> {
    const deviceService = new DeviceService()

    const queryClient = useQueryClient()

    return useMutation<void, unknown, Device[]>({
        ...options,
        mutationFn: (devices) => deviceService.removeDevices(devices),
        onSuccess: () => {
            queryClient.removeQueries({ queryKey: [DeviceHookKey.GET_DEVICE_BY_SERIAL_NUMBER] })
            options?.onSuccess?.()
        },
    })
}

export function useGetTrustProfiles(
    hasMigrated?: boolean,
    options?: QueryOptions<TrustProfile[]>
): UseQueryResult<TrustProfile[]> {
    const deviceService = new DeviceService()

    return useQuery({
        ...options,
        queryKey: ["deviceService.getTrustProfiles", hasMigrated],
        queryFn: () => deviceService.getTrustProfiles(hasMigrated),
    })
}

function getDeviceBySerialNumberKey(serialNumber: string): QueryKey {
    return [DeviceHookKey.GET_DEVICE_BY_SERIAL_NUMBER, serialNumber]
}

export function useGetDeviceBySerialNumber(
    serialNumber: string,
    options?: QueryOptions<Device>
): UseQueryResult<Device> {
    const deviceService = new DeviceService()

    return useQuery({
        ...options,
        queryKey: getDeviceBySerialNumberKey(serialNumber),
        queryFn: () => deviceService.getDeviceBySerialNumber(serialNumber),
    })
}

export function useGetDevicesByUserEmail(
    email: string,
    options?: QueryOptions<Device[]>
): UseQueryResult<Device[]> {
    const deviceService = new DeviceService()
    return useQuery<Device[], string>({
        ...options,
        queryKey: ["deviceService.getDevicesByUserEmail", email],
        queryFn: async (): Promise<Device[]> => {
            return deviceService.getDevicesByUserEmail(email)
        },
        enabled: Boolean(email),
    })
}

export function useUpdateDeviceDetails(
    options?: QueryOptions<Device, unknown, Device>
): UseMutationResult<Device, unknown, Device> {
    const deviceService = new DeviceService()

    const queryClient = useQueryClient()

    return useMutation({
        ...options,
        mutationFn: (device) => deviceService.updateDeviceDetails(device),
        onSuccess: (device) => {
            queryClient.setQueryData(getDeviceBySerialNumberKey(device.serialNumber), device)
            options?.onSuccess?.(device)
        },
    })
}

export function useGetDeviceAccessActivity(
    device: Device,
    options?: QueryOptions<AccessActivity[]>
): UseQueryResult<AccessActivity[]> {
    const deviceService = new DeviceService()

    return useQuery({
        ...options,
        queryKey: ["deviceService.getDeviceAccessActivity", device.id],
        queryFn: () => deviceService.getDeviceAccessActivity(device),
    })
}

export function useUnregisterUser(
    device: Device,
    options?: QueryOptions<void, unknown, string>
): UseMutationResult<void, unknown, string> {
    const deviceService = new DeviceService()

    const queryClient = useQueryClient()

    return useMutation<void, unknown, string>({
        ...options,
        mutationFn: (userEmail) => deviceService.unregisterUser(device, userEmail),
        onSuccess: () => {
            queryClient.removeQueries(["userService.getUserBySerialNumber", device.serialNumber])
            options?.onSuccess?.()
        },
    })
}

export function useRemoveDevice(
    device: Device,
    options?: QueryOptions
): UseMutationResult<void, unknown, void> {
    const deviceService = new DeviceService()

    const queryClient = useQueryClient()

    return useMutation({
        ...options,
        mutationFn: () => deviceService.removeDevice(device),
        onSuccess: () => {
            queryClient.removeQueries(getDeviceBySerialNumberKey(device.serialNumber))
            options?.onSuccess?.()
        },
    })
}

function getDeviceRemoteDiagnosticsKey(device: Device): QueryKey {
    return [DeviceHookKey.GET_DEVICE_REMOTE_DIAGNOSTICS, device.id]
}

export function useGetDeviceRemoteDiagnostics(
    device: Device,
    options?: QueryOptions<RemoteDiagnostics>
): UseQueryResult<RemoteDiagnostics> {
    const deviceService = new DeviceService()

    return useQuery({
        ...options,
        queryKey: getDeviceRemoteDiagnosticsKey(device),
        queryFn: () => deviceService.getDeviceRemoteDiagnostics(device),
    })
}

export function useRequestDeviceRemoteDiagnostics(
    device: Device,
    options?: QueryOptions<RemoteDiagnostics>
): UseMutationResult<RemoteDiagnostics, unknown, void> {
    const deviceService = new DeviceService()

    const queryClient = useQueryClient()

    return useMutation({
        ...options,
        mutationFn: () => deviceService.requestDeviceRemoteDiagnostics(device),
        onSuccess: (remoteDiagnostics) => {
            queryClient.setQueryData(getDeviceRemoteDiagnosticsKey(device), remoteDiagnostics)
            options?.onSuccess?.(remoteDiagnostics)
        },
    })
}

export function useDownloadDeviceRemoteDiagnosticsLogs(
    device: Device,
    options?: QueryOptions<void, unknown, SuccessRemoteDiagnostics>
): UseMutationResult<void, unknown, SuccessRemoteDiagnostics> {
    const deviceService = new DeviceService()

    return useMutation<void, unknown, SuccessRemoteDiagnostics>({
        ...options,
        mutationFn: (remoteDiagnostics) =>
            deviceService.downloadDeviceRemoteDiagnosticsLogs(device, remoteDiagnostics),
    })
}

export function useGetServiceTunnels(
    options?: QueryOptions<ServiceTunnel[]>
): UseQueryResult<ServiceTunnel[]> {
    const serviceTunnelApi = new ServiceTunnelApi()

    return useQuery({
        ...options,
        queryKey: [ApiFunction.GET_SERVICE_TUNNELS, serviceName],
        queryFn: async (): Promise<ServiceTunnel[]> => {
            const { service_tunnels } = await serviceTunnelApi.getServiceTunnels()
            return service_tunnels
        },
    })
}

export interface ProtectionProfile {
    id: string
    name: string
    itpStatus: ItpStatus
}
export enum ItpStatus {
    ENABLED = "enabled",
    DISABLED = "disabled",
}

// Device Types

export interface Device {
    id: string
    serialNumber: string
    name: string
    lastLoginAt: number
    platform: Platform
    // TODO: Remove optional once all the Orgs have migrated to Granular Trust
    trustProfile?: TrustProfile
    protectionProfile?: ProtectionProfile
    status: Status
    deviceOwnership: DeviceOwnership
    appVersion: string
    osVersion: string
    model: string
    architecture: string
    roleNames: string[]
    userEmails: string[]
    activeServiceTunnel?: ServiceTunnelDetails
}

// Status Type

export type Status =
    | BannedStatus
    | ExpiredStatus
    | OverriddenStatus
    | PendingStatus
    | ReportingStatus

export enum StatusType {
    BANNED = "BANNED",
    EXPIRED = "EXPIRED",
    OVERRIDDEN = "OVERRIDDEN",
    PENDING = "PENDING",
    REPORTING = "REPORTING",
}

interface BannedStatus {
    type: StatusType.BANNED
}

interface ExpiredStatus {
    type: StatusType.EXPIRED
    lastEvaluatedAt: number
    expiredAt: number
}

interface OverriddenStatus {
    type: StatusType.OVERRIDDEN
    lastEvaluatedAt: number
    overriddenTrustLevel: TrustLevel
}

interface PendingStatus {
    type: StatusType.PENDING
}

interface ReportingStatus {
    type: StatusType.REPORTING
    lastEvaluatedAt: number
    trustLevel: TrustLevel
    trustFactors: TrustFactor[]
}

export interface TrustProfile {
    id: string
    name: string
}

// TODO: Move to own file with the Trust Level from the Trust Profile service
// once we remove the pre-migration Trust Factors view
export enum TrustLevel {
    // TODO: Remove the AlwaysAllow trust level once Adobe migrate - BC-8124
    ALWAYS_ALLOW = "ALWAYS_ALLOW",
    HIGH = "HIGH",
    MEDIUM = "MEDIUM",
    LOW = "LOW",
    ALWAYS_DENY = "ALWAYS_DENY",
}

export const allTrustLevels: TrustLevel[] = Object.values(TrustLevel)

export interface TrustFactor {
    name: string
    description: string
    source: string
    isSatisfied: boolean
    extendedFactors?: ExtendedFactor[]
}

export interface ExtendedFactor {
    name: string
    isSatisfied: boolean
}

// Service Tunnel Types

export interface ServiceTunnel {
    id: string
    name: string
}
export interface ServiceTunnelDetails extends ServiceTunnel {
    accessTierConnections: AccessTierConnection[]
}

export interface AccessTierConnection {
    id: string
    name: string
    ipAddress: string
}

// Access Activity Types

export interface AccessActivity {
    service: { id: string; name: string }
    user: { emailAddress: string; name: string }
    lastAuthorizedAt: number
    lastUnauthorizedAt?: number
}

// Start Remote Diagnostics Types

type RemoteDiagnostics =
    | ErrorRemoteDiagnostics
    | ExpiredRemoteDiagnostics
    | InactiveRemoteDiagnostics
    | PendingRemoteDiagnostics
    | SuccessRemoteDiagnostics

export enum RemoteDiagnosticsStatus {
    ERROR = "ERROR",
    EXPIRED = "EXPIRED",
    INACTIVE = "INACTIVE",
    PENDING = "PENDING",
    SUCCESS = "SUCCESS",
}

interface ErrorRemoteDiagnostics {
    status: RemoteDiagnosticsStatus.ERROR
    requestedAt: number
    errorMessage: string
}

interface ExpiredRemoteDiagnostics {
    status: RemoteDiagnosticsStatus.EXPIRED
    requestedAt: number
    logsCollectedAt: number
}

interface InactiveRemoteDiagnostics {
    status: RemoteDiagnosticsStatus.INACTIVE
}

interface PendingRemoteDiagnostics {
    status: RemoteDiagnosticsStatus.PENDING
    requestedAt: number
}

interface SuccessRemoteDiagnostics {
    status: RemoteDiagnosticsStatus.SUCCESS
    requestedAt: number
    logsCollectedAt: number
}

// End Remote Diagnostics Types

// Helper Types

type StatusForSearch = Exclude<StatusType, StatusType.OVERRIDDEN>

interface TrustFactorsAcc {
    fileCheck?: TrustFactor
    propertyList?: TrustFactor
    registryCheck?: TrustFactor
    otherFactors: TrustFactor[]
}

// Helper Functions

function getDevicesParams(search?: PaginatedSearch): Partial<GetDevicesParams> {
    return {
        ...getLastLoginParams(search?.filterModel?.lastLogin?.filter),
        active: "true",
        name: search?.filterModel?.deviceName?.filter
            ? encodeURIComponent(search.filterModel.deviceName.filter)
            : undefined,
        platform: search?.filterModel?.platform?.filter
            ? platformResDict[
                  search.filterModel.platform.filter as Exclude<Platform, Platform.CHROME>
              ]
            : undefined,
        trust_level: search?.filterModel?.trustLevel?.filter
            ? trustLevelResDict[search.filterModel.trustLevel.filter as TrustLevel]
            : undefined,
        ownership: search?.filterModel?.deviceOwnership?.filter as DeviceOwnership,
        threat_profile_id:
            search?.filterModel?.threatProfileId?.filter &&
            decodeID(search.filterModel.threatProfileId.filter),
        trust_profile_id:
            search?.filterModel?.trustProfile?.filter &&
            decodeID(search.filterModel.trustProfile.filter),
        banned: search?.filterModel?.status?.filter
            ? search.filterModel.status.filter === StatusType.BANNED
                ? "true"
                : "false"
            : undefined,
        app_version: search?.filterModel?.appVersion?.filter,
        role_names: search?.filterModel?.roles?.filter,
        trustscore_status: search?.filterModel?.status?.filter
            ? trustScoreStatusResDict[search.filterModel.status.filter as StatusForSearch]
            : undefined,
        skip: search?.skip,
        limit: search?.limit ?? 100,
        order: search?.sortModel?.[0]?.sort,
        order_by: search?.sortModel?.[0]?.colId
            ? orderByReqDict[search?.sortModel?.[0]?.colId]
            : undefined,
        serial_number: search?.filterModel?.serialNumber?.filter,
        active_service_tunnel_id:
            search?.filterModel?.activeServiceTunnel?.filter &&
            decodeID(search.filterModel.activeServiceTunnel.filter),
    }
}

function getLastLoginParams(
    filter?: string
): Partial<Pick<GetDevicesParams, "last_login_before" | "last_login_after">> {
    if (!filter) return {}

    const timeRange = DateUtil.deserializeTimeRange(filter)

    return {
        last_login_after: timeRange.start && DateUtil.convertToLargeTimestamp(timeRange.start),
        last_login_before: timeRange.end && DateUtil.convertToLargeTimestamp(timeRange.end),
    }
}

export const platformResDict: Record<Exclude<Platform, Platform.CHROME>, PlatformRes> = {
    [Platform.MACOS]: "macOS",
    [Platform.WINDOWS]: "Windows",
    [Platform.LINUX]: "Linux",
    [Platform.IOS]: "iOS",
    [Platform.ANDROID]: "Android",
}

const trustLevelResDict: Record<TrustLevel, TrustLevelRes> = {
    [TrustLevel.ALWAYS_ALLOW]: "AlwaysAllow",
    [TrustLevel.HIGH]: "High",
    [TrustLevel.MEDIUM]: "Medium",
    [TrustLevel.LOW]: "Low",
    [TrustLevel.ALWAYS_DENY]: "AlwaysDeny",
}

const orderByReqDict: Record<string, DeviceOrderByReq> = {
    deviceName: "name",
    lastLogin: "last_login",
    platform: "platform",
}

const trustScoreStatusResDict: Record<
    StatusForSearch,
    GetDevicesParams["trustscore_status"] | undefined
> = {
    [StatusType.BANNED]: undefined,
    [StatusType.EXPIRED]: "Expired",
    [StatusType.PENDING]: "Pending",
    [StatusType.REPORTING]: "Reporting",
}

export const statusTypeLabelDict: Record<StatusType, LanguageKey> = {
    [StatusType.BANNED]: "banned",
    [StatusType.EXPIRED]: "expired",
    [StatusType.OVERRIDDEN]: "overridden",
    [StatusType.PENDING]: "pending",
    [StatusType.REPORTING]: "reporting",
}

export const platformDict: Record<PlatformRes, Platform> = {
    macOS: Platform.MACOS,
    Windows: Platform.WINDOWS,
    Linux: Platform.LINUX,
    iOS: Platform.IOS,
    Android: Platform.ANDROID,
    Chrome: Platform.CHROME,
}

function getTrustProfileFromDeviceRes(deviceRes: DeviceRes): TrustProfile | undefined {
    if (!deviceRes.trust_profile_id || !deviceRes.trust_profile_display_name) return
    return { id: deviceRes.trust_profile_id, name: deviceRes.trust_profile_display_name }
}

function mapTrustProfileFromRes(trustProfileRes: TrustProfileRes): TrustProfile {
    return {
        id: trustProfileRes.profile_id,
        name: trustProfileRes.display_name,
    }
}

const trustLevelDict: Record<TrustLevelRes, TrustLevel> = {
    AlwaysDeny: TrustLevel.ALWAYS_DENY,
    Low: TrustLevel.LOW,
    Medium: TrustLevel.MEDIUM,
    High: TrustLevel.HIGH,
    AlwaysAllow: TrustLevel.ALWAYS_ALLOW,
}

export const trustLevelLabelDict: Record<TrustLevel, LanguageKey> = {
    [TrustLevel.ALWAYS_DENY]: "alwaysDeny",
    [TrustLevel.LOW]: "low",
    [TrustLevel.MEDIUM]: "medium",
    [TrustLevel.HIGH]: "high",
    [TrustLevel.ALWAYS_ALLOW]: "alwaysAllow",
}

export const statusForSearch: StatusForSearch[] = [
    StatusType.BANNED,
    StatusType.EXPIRED,
    StatusType.PENDING,
    StatusType.REPORTING,
]

function getTrustFactorType(trustFactorRes: TrustFactorRes): TrustFactorType | undefined {
    if (isTrustFactorType(trustFactorRes.name)) return trustFactorRes.name
    // Extended Factors have their unique name in the source
    if (isTrustFactorType(trustFactorRes.source)) return trustFactorRes.source
}

export const deviceOwnershipDict: Record<OwnershipRes, DeviceOwnership> = {
    "Employee Owned": DeviceOwnership.EMPLOYEE_OWNED,
    "Corporate Shared": DeviceOwnership.CORPORATE_SHARED,
    "Corporate Dedicated": DeviceOwnership.CORPORATE_DEDICATED,
    Other: DeviceOwnership.OTHER,
}

function addExtendedTrustFactor(
    prevTrustFactor: TrustFactor,
    trustFactorRes: TrustFactorRes
): TrustFactor {
    const isSatisfied = trustFactorRes.value === "true"

    const extendedFactor: ExtendedFactor = { name: trustFactorRes.name, isSatisfied }

    return {
        ...prevTrustFactor,
        isSatisfied: prevTrustFactor.isSatisfied && isSatisfied,
        extendedFactors: [...(prevTrustFactor.extendedFactors ?? []), extendedFactor],
    }
}

function getDeviceId(device: Device): string {
    return device.id
}

function mapAccessActivityFromRes(serviceEndUsersRes: ServiceEndUsersRes): AccessActivity {
    return {
        service: { id: serviceEndUsersRes.service_id, name: serviceEndUsersRes.service_name },
        user: { emailAddress: serviceEndUsersRes.email, name: serviceEndUsersRes.user_name },
        lastAuthorizedAt: DateUtil.convertLargeTimestamp(
            serviceEndUsersRes.last_authorized_timestamp
        ),
        lastUnauthorizedAt:
            serviceEndUsersRes.last_unauthorized_timestamp &&
            DateUtil.convertLargeTimestamp(serviceEndUsersRes.last_unauthorized_timestamp),
    }
}

function mapAccessTierConnectionResToAccessTierConnection(
    res: AccessTierConnectionInfoRes
): AccessTierConnection {
    return {
        id: res.accesstier_id,
        name: res.accesstier_name,
        ipAddress: res.ip_address,
    }
}

const ErrorMap: Record<DiagnosticErrorMessage, LanguageKey> = {
    SCRIPT_RETRIEVAL_FAILURE: "scriptRetrievalFailure",
    SCRIPT_UPLOAD_FAILURE: "scriptUploadFailure",
}
