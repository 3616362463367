import { faFilter, faRocket, faVectorSquare } from "@fortawesome/pro-regular-svg-icons"
import { CellDoubleClickedEvent, ColDef, GridApi, GridReadyEvent } from "ag-grid-community"
import React from "react"
import { RouteComponentProps } from "react-router"

import { ROUTE, formatRoutePath } from "../../../../routes"
import { useServiceActionBar } from "../../../services/ActionBar.service"
import AgGridUtil from "../../../utils/AgGrid.util"
import { Grid } from "../../../components/grid/Grid.component"
import {
    LinkService,
    useServiceLocalization,
    useServiceManage,
    useServiceModal,
    useServiceUser,
} from "../../../services"
import { ServiceAppType, ServiceType, ServiceManage } from "../../../services"
import { MenuButton } from "../../../components/menu-button/MenuButton.component"
import { MenuItem } from "../../../components/menu-item/MenuItem.component"
import { SelectItem } from "../../../utils/SelectValue.util"
import { ServicesCellRenderer } from "../cell-renderer/ServicesCellRenderer"
import { ServicesStatusCellRenderer } from "../cell-renderer/status/ServicesStatusCellRenderer.component"
import { Toolbar } from "../../../components/toolbar/Toolbar.component"
import { UserOrgDetails } from "../../../api/User.api"
import { ChoiceModal } from "../../../components/choice-modal/ChoiceModal"
import { encodeID } from "../../../utils/Url.util"
import {
    ContactUsScreen,
    ContactUsScreenSteps,
} from "../../../../v3/components/page-screen/PageScreen.component"
import { useIsTeamEdition } from "../../../../v3/services/Org.service"
import { Container } from "../../../../v3/components/container/Container.component"
import banyanRoute from "../../../../images/banyan-route.svg"
import styles from "./ServicesInfraList.module.scss"
import useTitle from "../../../../hooks/useTitle.hook"
import { PageHeading } from "../../../../components/page-heading/PageHeading.component"
import classnames from "classnames"
import {
    Button,
    ButtonElement,
    ButtonType,
    IconType,
} from "../../../../components/button/Button.component"
import { Tooltip } from "../../../../v3/components/tooltip/Tooltip.component"
import { useFeatureFlags } from "../../../../hooks/useFeatureFlags.hook"

export default function ServicesInfraList({ history }: RouteComponentProps) {
    useTitle(["infrastructureServices", "privateAccess", "adminConsole"])

    // we have to memoize our service references so the hooks don't update on every render
    const actionBarService = useServiceActionBar()
    const localizationService = useServiceLocalization()
    const manageService = useServiceManage()
    const modalService = useServiceModal()
    const userService = useServiceUser()
    const linkService = new LinkService()
    const gridApi = React.useRef<GridApi>()

    const { data: isTeamEdition = false, isFetching: isTeamEditionLoading } = useIsTeamEdition()
    const { data: featureFlags } = useFeatureFlags()

    const [isReadOnly, setIsReadOnly] = React.useState<boolean>(true)
    const [filterLabel, setFilterLabel] = React.useState<string>("")

    const fetchData = React.useCallback((): void => {
        if (!gridApi.current) {
            return
        }
        gridApi.current.showLoadingOverlay()
        manageService
            .getInfraServices(!featureFlags?.adminConsole.enableAccessTierGroups)
            .then((services: ServiceManage[]) => {
                gridApi.current?.hideOverlay()
                gridApi.current?.setRowData(services)
            })
    }, [manageService, userService])

    const onTypeFilterChange = (filter: SelectItem) => (): void => {
        if (!gridApi.current) {
            return
        }

        gridApi.current.getFilterInstance("appType")?.setModel({
            type: "equals",
            filter: !filter.value || filter.value === ServiceAppType.ALL ? "" : filter.value,
        })

        gridApi.current.onFilterChanged()

        setFilterLabel(filter.displayName)
    }

    const onGridReady = (event: GridReadyEvent): void => {
        gridApi.current = event.api
        fetchData()
    }

    const onFilter = (value: string): void => {
        gridApi.current?.setQuickFilter(value)
    }

    const onCellDoubleClicked = (event: CellDoubleClickedEvent): void => {
        if (event?.data) {
            history.push(
                formatRoutePath(ROUTE.INFRASTRUCTURE_DETAILS, { id: encodeID(event.data.id) })
            )
        }
    }

    const onAddService = (): void => {
        modalService.open(localizationService.getString("registerNewService"), {
            component: ChoiceModal,
            props: {
                description: localizationService.getString("registeredServiceDescription"),
                choices: [
                    {
                        title: localizationService.getString("sshService"),
                        description: localizationService.getString("sshServiceDescription"),
                        link: `${ROUTE.INFRASTRUCTURE_ADD}?type=${ServiceType.TCP_USER}&appType=${ServiceAppType.SSH}`,
                        className: "sshService",
                    },
                    {
                        title: localizationService.getString("rdpService"),
                        description: localizationService.getString("rdpServiceDescription"),
                        link: `${ROUTE.INFRASTRUCTURE_ADD}?type=${ServiceType.TCP_USER}&appType=${ServiceAppType.RDP}`,
                        className: "rdpService",
                    },
                    {
                        title: localizationService.getString("kubernetesService"),
                        description: localizationService.getString("kubernetesServiceDescription"),
                        link: `${ROUTE.INFRASTRUCTURE_ADD}?type=${ServiceType.TCP_USER}&appType=${ServiceAppType.K8S}`,
                        className: "kubernetesService",
                    },
                    {
                        title: localizationService.getString("databaseService"),
                        description: localizationService.getString("databaseServiceDescription"),
                        link: `${ROUTE.INFRASTRUCTURE_ADD}?type=${ServiceType.TCP_USER}&appType=${ServiceAppType.DATABASE}`,
                        className: "databaseService",
                    },
                    {
                        title: localizationService.getString("otherTCPService"),
                        description: localizationService.getString("otherTCPServiceDescription"),
                        link: `${ROUTE.INFRASTRUCTURE_ADD}?type=${ServiceType.TCP_USER}&appType=${ServiceAppType.GENERIC}`,
                        className: "otherTCPService",
                    },
                    {
                        title: localizationService.getString("customJSON"),
                        description: localizationService.getString("customJSONDescription"),
                        link: `${ROUTE.INFRASTRUCTURE_ADD}?type=${ServiceType.CUSTOM}&appType=${ServiceAppType.GENERIC}`,
                        className: "jsonTemplate",
                    },
                ],
            },
            maxWidth: "md",
        })
    }

    const columnDefs: ColDef[] = [
        {
            headerName: localizationService.getString("status"),
            field: "status",
            flex: 65,
            cellRenderer: "servicesStatusCellRenderer",
        },
        {
            headerName: localizationService.getString("serviceName"),
            field: "name",
            tooltipValueGetter: AgGridUtil.linkTooltipValueGetter,
            comparator: AgGridUtil.alphaBetComparator,
            flex: 150,
            cellRenderer: "servicesCellRenderer",
            filter: "agTextColumnFilter",
        },

        {
            headerName: localizationService.getString("type"),
            field: "appType",
            flex: 55,
            filter: "agTextColumnFilter",
            sortable: true,
            comparator: AgGridUtil.alphaBetComparator,
        },
        {
            headerName: localizationService.getString("policyAttached"),
            field: "policyName",
            flex: 90,
            comparator: AgGridUtil.alphaBetComparator,
            valueFormatter: AgGridUtil.nullableStringFormatter,
        },
        {
            headerName: localizationService.getString("lastUpdated"),
            field: "lastUpdatedAt",
            flex: 100,
            valueFormatter: AgGridUtil.dateFormatter,
        },
    ]

    const typeFilterOptions: SelectItem[] = [
        {
            displayName: localizationService.getString("allServices"),
            value: ServiceAppType.ALL,
        },
        {
            displayName: localizationService.getString("ssh"),
            value: ServiceAppType.SSH,
        },
        {
            displayName: localizationService.getString("rdp"),
            value: ServiceAppType.RDP,
        },
        {
            displayName: localizationService.getString("kubernetes"),
            value: ServiceAppType.K8S,
        },
        {
            displayName: localizationService.getString("database"),
            value: ServiceAppType.DATABASE,
        },
        {
            displayName: localizationService.getString("otherTCP"),
            value: ServiceAppType.GENERIC,
        },
    ]

    React.useEffect(() => {
        userService.getUserOrgDetails().then((details: UserOrgDetails) => {
            setFilterLabel(localizationService.getString("allServices"))
            setIsReadOnly(!userService.canCreateServices(details.Profile))
        })

        return () => {
            actionBarService.unregisterRefreshFn(fetchData)
        }
    }, [actionBarService, fetchData, localizationService, userService])

    const gridClasses = classnames(styles.gridWithToolbar, styles.sectionContainer)

    return isTeamEdition && !isTeamEditionLoading ? (
        <Container>
            <ContactUsScreen
                image={banyanRoute}
                title={localizationService.getString(
                    "upgradeForSomething",
                    localizationService.getString("infrastructureServices")
                )}
                docsLink={linkService.getLink("securingPrivateResourcesDoc")}
                subText={localizationService.getString("protectYourInfraServicesDesc")}
            >
                <ContactUsScreenSteps
                    steps={[
                        {
                            icon: faVectorSquare,
                            label: localizationService.getString(
                                "createInfrastructureServicesDesc"
                            ),
                        },
                        {
                            icon: faRocket,
                            label: localizationService.getString(
                                "deploySonicWallCseAccessTierOrLeverageSonicWallCseGlobalEdgeInfraDesc"
                            ),
                        },
                    ]}
                />
            </ContactUsScreen>
        </Container>
    ) : (
        <section className={gridClasses}>
            <header className={styles.header}>
                <PageHeading id={Id.HEADING}>
                    {localizationService.getString("infrastructure")}
                </PageHeading>
                <Tooltip title={localizationService.getString("refresh")}>
                    <Button
                        icon={IconType.REDO}
                        onClick={fetchData}
                        asElement={ButtonElement.BUTTON}
                        buttonType={ButtonType.SECONDARY}
                    />
                </Tooltip>
            </header>
            <Toolbar onSearchChange={onFilter}>
                <MenuButton className={styles.filterButton} label={filterLabel} icon={faFilter}>
                    {typeFilterOptions.map((filter: SelectItem) => (
                        <MenuItem onClick={onTypeFilterChange(filter)} key={filter.value}>
                            {filter.displayName}
                        </MenuItem>
                    ))}
                </MenuButton>
                {!isReadOnly && (
                    <Button
                        buttonType={ButtonType.PRIMARY}
                        asElement={ButtonElement.BUTTON}
                        icon={IconType.PLUS}
                        onClick={onAddService}
                    >
                        {localizationService.getString("registerService")}
                    </Button>
                )}
            </Toolbar>
            <div className={styles.gridContainer}>
                <Grid
                    onGridReady={onGridReady}
                    columnDefs={columnDefs}
                    pagination
                    context={{ history: history }}
                    onCellDoubleClicked={onCellDoubleClicked}
                    components={{
                        servicesCellRenderer: ServicesCellRenderer,
                        servicesStatusCellRenderer: ServicesStatusCellRenderer,
                    }}
                />
            </div>
        </section>
    )
}

enum Id {
    HEADING = "heading",
}
