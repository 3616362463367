import React from "react"

import { useServiceLocalization } from "../../../pre-v3/services"
import { ROUTE } from "../../../routes"
import { DoughnutChart, Color } from "../../components/chart/DoughnutChart.component"
import { Link } from "../../components/link/Link.component"
import { CountWidget } from "../../components/widget/CountWidget.component"
import { Widget } from "../../components/widget/Widget.component"
import { ConnectorStatus, useGetConnectorsStats } from "../../services/Connector.service"
import styles from "./Connectors.module.scss"
import { useHistory } from "react-router-dom"

export function Connectors(): JSX.Element {
    const localization = useServiceLocalization()
    const history = useHistory()
    const { data, isLoading } = useGetConnectorsStats()

    return (
        <React.Fragment>
            <Link to={ROUTE.CONNECTORS} className={styles.count}>
                <CountWidget title={localization.getString("connectors")} count={data?.total} />
            </Link>
            <Widget isLoading={isLoading} isEmpty={!data} className={styles.pieChart}>
                <DoughnutChart
                    title={localization.getString("allConnectors")}
                    subtitle={localization.getString("byStatus")}
                    seriesName={localization.getString("count")}
                    data={data && Object.entries(data.statusDictionary)}
                    getName={getStatus}
                    getValue={getValue}
                    getColor={getColor}
                    onSeriesClick={() => {
                        history.push(ROUTE.CONNECTORS)
                    }}
                />
            </Widget>
        </React.Fragment>
    )
}

type StatusDictionaryEntry = [ConnectorStatus, number]

function getStatus([status]: StatusDictionaryEntry): ConnectorStatus {
    return status
}

function getValue([, value]: StatusDictionaryEntry): number {
    return value
}

function getColor([status]: StatusDictionaryEntry): Color {
    return statusToColorDictionary[status]
}

const statusToColorDictionary: Record<ConnectorStatus, Color> = {
    Pending: Color.SANTA_GRAY,
    Reporting: Color.CARIBBEAN_GREEN,
    PartiallyReporting: Color.SUNGLOW,
    Terminated: Color.ALIZARIN_CRIMSON,
}
