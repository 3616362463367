import React from "react"

import { IconType } from "../../../pre-v3/services/ActionBar.service"
import { useServiceLocalization } from "../../../pre-v3/services/localization/Localization.service"
import { ErrorBanners } from "../../components/banner/Banner.component"
import { Container } from "../../components/container/Container.component"
import { HorizontalLine } from "../../components/form/HorizontalLine.component"
import { Loader } from "../../components/loader/Loader.component"
import { useGetOrgInfo } from "../../services/Org.service"
import { IdpType, useGetUserProfile } from "../../services/UserProfile.service"
import { AppBanner } from "../app/AppBanner.component"
import styles from "./MyProfile.module.scss"
import { RefreshToken } from "./RefreshToken.component"
import { UserProfile } from "./UserProfile.component"
import { PageHeading } from "../../../components/page-heading/PageHeading.component"
import { Button, ButtonElement, ButtonType } from "../../../components/button/Button.component"
import useTitle from "../../../hooks/useTitle.hook"
import { useFeatureFlags } from "../../../hooks/useFeatureFlags.hook"

export function MyProfile(): JSX.Element {
    useTitle(["myProfile", "profile", "adminConsole"])
    const localization = useServiceLocalization()

    const { data: orgInfo, error: orgInfoError, refetch: refetchOrgInfo } = useGetOrgInfo()
    const {
        data: userProfile,
        error: userProfileError,
        refetch: refetchUserProfile,
    } = useGetUserProfile()

    const { data: featureFlags } = useFeatureFlags()

    function onRefresh() {
        refetchOrgInfo()
        refetchUserProfile()
    }

    if (orgInfoError || userProfileError) {
        return <ErrorBanners errors={[String(orgInfoError), String(userProfileError)]} />
    }

    if (!orgInfo || !userProfile || !featureFlags)
        return <Loader isLoading children center medium />

    const canUpdatePassword = orgInfo.isMspOrg
        ? featureFlags.mspConsole.canUpdatePassword
        : featureFlags.adminConsole.canUpdateAdmin

    const canUpdateAdminPassword = featureFlags.adminConsole.canUpdateAdminPassword
    const canRefreshToken = featureFlags.adminConsole.canRefreshToken

    return (
        <React.Fragment>
            <AppBanner />
            <section aria-labelledby={Id.HEADING}>
                <Container className={styles.container}>
                    <header className={styles.header}>
                        <div>
                            <PageHeading id={Id.HEADING}>
                                {localization.getString("myProfile")}
                            </PageHeading>
                            {userProfile?.idpType === IdpType.SAML && (
                                <p className={styles.description}>
                                    {localization.getString("samlOrganizationDescription")}
                                </p>
                            )}
                        </div>
                        <Button
                            icon={IconType.REDO}
                            onClick={onRefresh}
                            asElement={ButtonElement.BUTTON}
                            buttonType={ButtonType.SECONDARY}
                        />
                    </header>
                    <UserProfile
                        canUpdatePassword={canUpdatePassword}
                        canUpdateAdminPassword={canUpdateAdminPassword}
                        {...userProfile}
                    />
                    <HorizontalLine />
                    <RefreshToken
                        {...userProfile}
                        edition={orgInfo.edition}
                        canRefreshToken={canRefreshToken}
                    />
                </Container>
            </section>
        </React.Fragment>
    )
}

enum Id {
    HEADING = "heading",
}
