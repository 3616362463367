import React from "react"

import { useServiceLocalization } from "../../../../pre-v3/services/localization/Localization.service"
import {
    FilterModel,
    Paginated,
    PaginatedSearch,
    replaceColumnKeys,
} from "../../../../pre-v3/utils/AgGrid.util"
import { replaceKeys } from "../../../../pre-v3/utils/Collection.util"
import { DateUtil } from "../../../../pre-v3/utils/Date.util"
import { encodeID } from "../../../../pre-v3/utils/Url.util"
import { ROUTE, formatRoutePath } from "../../../../routes"
import { Column, Grid, GridApi, sortWithServerSide } from "../../../components/grid/Grid.component"
import { RowTitle } from "../../../components/grid/RowTitle.component"
import {
    AccessActivity,
    AccessActivityFilterById as FilterById,
    AccessActivitySortById as SortById,
} from "../../../services/PrivateResource.service"

interface Props {
    filterModel: FilterModel<FilterById>
    getAccessActivities(
        search: PaginatedSearch<SortById, FilterById>
    ): Promise<Paginated<AccessActivity>>
}

function AccessActivityGridComponent(props: Props, ref: React.ForwardedRef<GridApi>): JSX.Element {
    const localization = useServiceLocalization()

    const userColumn: Column<AccessActivity> = {
        id: ColumnId.USER,
        name: localization.getString("user"),
        cellRenderer: renderUserCell,
        getTooltipValue: getUserName,
        sorting: sortWithServerSide(),
        isFilterable: true,
    }

    const deviceColumn: Column<AccessActivity> = {
        id: ColumnId.DEVICE,
        name: localization.getString("device"),
        cellRenderer: renderDeviceCell,
        getTooltipValue: getDeviceLabel,
        sorting: sortWithServerSide(),
        isFilterable: true,
    }

    const lastReportedColumn: Column<AccessActivity> = {
        id: ColumnId.LAST_REPORTED,
        name: localization.getString("lastReported"),
        cellRenderer: getLastReported,
        getTooltipValue: getLastReported,
        sorting: sortWithServerSide("desc"),
        isFilterable: true,
    }

    const columns: Column<AccessActivity>[] = [userColumn, deviceColumn, lastReportedColumn]

    const getServerSideData = (search: PaginatedSearch<ColumnId, ColumnId>) =>
        props.getAccessActivities({
            ...search,
            sortModel: search.sortModel && replaceColumnKeys(search.sortModel, sortByIdDict),
            filterModel: search.filterModel && replaceKeys(search.filterModel, filterByIdDict),
        })

    return (
        <Grid
            columns={columns}
            ref={ref}
            hasPagination
            serverSideProps={{
                getServerSideData,
                filterModel: replaceKeys(props.filterModel, filteredColumnDict),
            }}
        />
    )
}

export const AccessActivityGrid = React.forwardRef<GridApi, Props>(AccessActivityGridComponent)

enum ColumnId {
    USER = "user",
    DEVICE = "device",
    LAST_REPORTED = "lastReported",
}

function renderUserCell(accessActivity: AccessActivity): JSX.Element {
    const route = formatRoutePath(ROUTE.USERS_DETAILS, {
        id: encodeID(accessActivity.user.email),
    })

    return <RowTitle title={getUserName(accessActivity)} route={route} />
}

function getUserName(accessActivity: AccessActivity): string {
    return accessActivity.user.name
}

function renderDeviceCell(accessActivity: AccessActivity): JSX.Element {
    const route = formatRoutePath(ROUTE.DEVICES_DETAILS, {
        serialNumber: encodeID(accessActivity.device.serialNumber),
    })

    return <RowTitle title={getDeviceLabel(accessActivity)} route={route} />
}

function getDeviceLabel(accessActivity: AccessActivity): string {
    return accessActivity.device.name ?? accessActivity.device.serialNumber
}

function getLastReported(accessActivity: AccessActivity): string {
    return DateUtil.format(accessActivity.lastReportedAt)
}

const sortByIdDict: Record<ColumnId, SortById> = {
    [ColumnId.USER]: SortById.USER,
    [ColumnId.DEVICE]: SortById.DEVICE,
    [ColumnId.LAST_REPORTED]: SortById.LAST_REPORTED,
}

const filterByIdDict: Record<ColumnId, FilterById> = {
    [ColumnId.USER]: FilterById.USERS,
    [ColumnId.DEVICE]: FilterById.DEVICES,
    [ColumnId.LAST_REPORTED]: FilterById.LAST_REPORTED,
}

const filteredColumnDict: Record<FilterById, ColumnId> = {
    [FilterById.USERS]: ColumnId.USER,
    [FilterById.DEVICES]: ColumnId.DEVICE,
    [FilterById.LAST_REPORTED]: ColumnId.LAST_REPORTED,
}
