import React from "react"

import { useInput } from "./useInput.hook"

export interface InputProps extends React.InputHTMLAttributes<HTMLInputElement> {
    type?: InputType
    /**
     * Triggered by `onChange` and `onBlur` called with the event's value
     * @param value Event's value
     */
    onChangeValue?(value: string): void
}

/**
 * Used to create interactive controls for web-based forms in order to accept
 * data from the user
 */
export const Input = React.forwardRef<HTMLInputElement, InputProps>((props, ref) => {
    const { onChangeValue, ...inputProps } = props

    const extraProps = useInput({ ...inputProps, onChangeValue })

    return <input {...inputProps} {...extraProps} ref={ref} />
})

Input.displayName = "Input"

export type InputType =
    | "email"
    | "number"
    | "password"
    | "search"
    | "tel"
    | "text"
    | "url"
    | "date"
    | "time"
    | "file"
