import classNames from "classnames/bind"
import React from "react"

import { Button, ButtonElement, ButtonType } from "../../../components/button/Button.component"
import { useServiceLocalization } from "../../../pre-v3/services/localization/Localization.service"
import { FormUtil } from "../../../pre-v3/utils/Form.util"
import { PatternUtil } from "../../../pre-v3/utils/Pattern.util"
import { Profile, profileLabelDict } from "../../../utils/profile.utils"
import { ErrorBanner, SuccessBanner } from "../../components/banner/Banner.component"
import { Form } from "../../components/form/Form.component"
import { FormRow } from "../../components/form/FormRow.component"
import { Input } from "../../components/input/Input.component"
import { SecretInput } from "../../components/secret-input/SecretInput.component"
import { IdpType, useChangePassword } from "../../services/UserProfile.service"
import styles from "./UserProfile.module.scss"

export interface UserProfileProps {
    fullName: string
    emailAddress: string
    profile: Profile
    idpType: IdpType
    canUpdatePassword: boolean
    canUpdateAdminPassword: boolean
}

export function UserProfile(props: UserProfileProps): JSX.Element {
    const localization = useServiceLocalization()

    const confirmPasswordInput = React.useRef<HTMLInputElement>(null)
    const [isPasswordHidden, setIsPasswordHidden] = React.useState(true)

    const changePassword = useChangePassword()

    const onUpdatePassword: React.MouseEventHandler = (event) => {
        event.preventDefault()
        setIsPasswordHidden(false)
    }

    const onCancel: React.MouseEventHandler = (event) => {
        event.preventDefault()
        setIsPasswordHidden(true)
    }

    const onChangeConfirmPassword: React.ChangeEventHandler<HTMLInputElement> = (event) => {
        event.preventDefault()
        event.target.setCustomValidity("")
    }

    const onSubmit: React.FormEventHandler<HTMLFormElement> = (event) => {
        event.preventDefault()

        const currentPassword = FormUtil.getFieldValue(event, currentPasswordId)
        const newPassword = FormUtil.getFieldValue(event, newPasswordId)
        const confirmPassword = FormUtil.getFieldValue(event, confirmPasswordId)

        if (newPassword !== confirmPassword) {
            confirmPasswordInput.current?.setCustomValidity(
                localization.getString("thePasswordsEnteredDoNotMatch")
            )
            confirmPasswordInput.current?.reportValidity()
            return
        }

        changePassword.mutate({ currentPassword, newPassword })
    }

    const currentPasswordLabel = localization.getString("currentPassword")
    const newPasswordLabel = localization.getString("newPassword")
    const confirmPasswordLabel = localization.getString("confirmPassword")

    return (
        <Form onSubmit={onSubmit} className={styles.container}>
            <FormRow label={localization.getString("fullName")} htmlFor={fullNameId}>
                <Input value={props.fullName} id={fullNameId} name={fullNameId} disabled />
            </FormRow>
            <FormRow label={localization.getString("emailAddress")} htmlFor={emailAddressId}>
                <Input
                    value={props.emailAddress}
                    id={emailAddressId}
                    name={emailAddressId}
                    disabled
                />
            </FormRow>
            <FormRow label={localization.getString("profile")} htmlFor={profileId}>
                <Input
                    value={localization.getString(profileLabelDict[props.profile])}
                    id={profileId}
                    name={profileId}
                    disabled
                />
            </FormRow>
            {!isPasswordHidden && (
                <React.Fragment>
                    <FormRow label={currentPasswordLabel} htmlFor={currentPasswordId}>
                        <SecretInput
                            placeholder={currentPasswordLabel}
                            id={currentPasswordId}
                            name={currentPasswordId}
                            autoComplete="current-password"
                            hideValueLabel={localization.getString(
                                "hideSomething",
                                currentPasswordLabel
                            )}
                            showValueLabel={localization.getString(
                                "showSomething",
                                currentPasswordLabel
                            )}
                            autoFocus
                            required
                        />
                    </FormRow>
                    <FormRow label={newPasswordLabel} htmlFor={newPasswordId}>
                        <SecretInput
                            placeholder={newPasswordLabel}
                            id={newPasswordId}
                            name={newPasswordId}
                            autoComplete="new-password"
                            hideValueLabel={localization.getString(
                                "hideSomething",
                                newPasswordLabel
                            )}
                            showValueLabel={localization.getString(
                                "showSomething",
                                newPasswordLabel
                            )}
                            pattern={PatternUtil.PASSWORD.source}
                            title={localization.getString("passwordRequirementsDescription")}
                            required
                        />
                    </FormRow>
                    <FormRow label={confirmPasswordLabel} htmlFor={confirmPasswordId}>
                        <SecretInput
                            placeholder={confirmPasswordLabel}
                            id={confirmPasswordId}
                            name={confirmPasswordId}
                            autoComplete="new-password"
                            hideValueLabel={localization.getString(
                                "hideSomething",
                                confirmPasswordLabel
                            )}
                            showValueLabel={localization.getString(
                                "showSomething",
                                confirmPasswordLabel
                            )}
                            onChange={onChangeConfirmPassword}
                            ref={confirmPasswordInput}
                            required
                        />
                    </FormRow>
                </React.Fragment>
            )}
            {changePassword.isSuccess && (
                <SuccessBanner>
                    {localization.getString("passwordSuccessfullyChanged")}
                </SuccessBanner>
            )}
            {changePassword.isError && <ErrorBanner>{String(changePassword.error)}</ErrorBanner>}
            {props.canUpdateAdminPassword &&
                props.canUpdatePassword &&
                (isPasswordHidden ? (
                    <Button
                        className={styles.alignRight}
                        asElement={ButtonElement.BUTTON}
                        buttonType={ButtonType.SECONDARY}
                        onClick={onUpdatePassword}
                    >
                        {localization.getString("updatePassword")}
                    </Button>
                ) : (
                    <div className={classNames(styles.buttonGroup, styles.alignRight)}>
                        <Button
                            asElement={ButtonElement.BUTTON}
                            buttonType={ButtonType.SECONDARY}
                            onClick={onCancel}
                        >
                            {localization.getString("cancel")}
                        </Button>
                        <Button
                            asElement={ButtonElement.BUTTON}
                            buttonType={ButtonType.PRIMARY}
                            type="submit"
                            loading={changePassword.isLoading}
                        >
                            {localization.getString("save")}
                        </Button>
                    </div>
                ))}
        </Form>
    )
}

const fullNameId = "fullName"
const emailAddressId = "email"
const profileId = "profile"
const currentPasswordId = "currentPassword"
const newPasswordId = "newPassword"
const confirmPasswordId = "confirmPassword"
