import React from "react"

import { FilterBar } from "../../../../pre-v3/components/filter-bar/FilterBar.component"
import { useServiceLocalization } from "../../../../pre-v3/services/localization/Localization.service"
import { DataFilter, DataFilterType, FilterModel } from "../../../../pre-v3/utils/AgGrid.util"
import { SelectItem } from "../../../../pre-v3/utils/SelectValue.util"
import {
    ServiceTunnel,
    ServiceTunnelFilterById as FilterById,
} from "../../../services/PrivateResource.service"

interface Props {
    filterModel: FilterModel<FilterById>
    serviceTunnels: ServiceTunnel[]
    onFilter(filterModel: FilterModel<FilterById>): void
}

export function NetworkFilterBar(props: Props): JSX.Element {
    const localization = useServiceLocalization()

    const serviceTunnelsFilter: DataFilter<FilterById.SERVICE_TUNNELS> = {
        key: FilterById.SERVICE_TUNNELS,
        displayName: localization.getString("serviceTunnels"),
        type: DataFilterType.MULTI_LIST,
        options: props.serviceTunnels.map(mapServiceTunnel),
    }

    const portsFilter: DataFilter<FilterById.PORTS> = {
        key: FilterById.PORTS,
        displayName: localization.getString("ports"),
        type: DataFilterType.MULTIINPUT,
    }

    const protocolsFilter: DataFilter<FilterById.PROTOCOLS> = {
        key: FilterById.PROTOCOLS,
        displayName: localization.getString("protocols"),
        type: DataFilterType.MULTIINPUT,
    }

    const filters: DataFilter<FilterById>[] = [serviceTunnelsFilter, portsFilter, protocolsFilter]

    return (
        <FilterBar filters={filters} initialModel={props.filterModel} onChange={props.onFilter} />
    )
}

function mapServiceTunnel(serviceTunnel: ServiceTunnel): SelectItem {
    return {
        displayName: serviceTunnel.name,
        value: serviceTunnel.id,
    }
}
