import { IconDefinition } from "@fortawesome/fontawesome-common-types"
import classNames from "classnames/bind"
import * as React from "react"

import { useServiceLocalization } from "../../../../pre-v3/services"
import { KeyValuePair, KeyValuePairList } from "../../key-value-pair/KeyValuePair.component"
import { Status, StatusType } from "../../status/Status.component"
import styles from "./OverviewTopContainer.module.scss"

export { getStatusType } from "../../status/Status.component"
export type { StatusType } from "../../status/Status.component"

export interface KeyValuePairItems {
    label: string
    value: React.ReactNode
}
export interface Props {
    statusType: StatusType
    statusItems?: KeyValuePairItems[]
    statusLabel?: string
    statusLabelTitle?: string
    listItems?: KeyValuePairItems[]
    listClassName?: string
    className?: string
    hideStatusType?: boolean
    hideIcon?: boolean
    statusContainerClassName?: string
    icon?: IconDefinition
    iconClassName?: string
}

export function OverviewTopContainer(props: Props) {
    const ls = useServiceLocalization()
    const {
        statusType,
        statusItems,
        statusLabel,
        statusLabelTitle,
        listItems,
        listClassName,
        className,
        hideStatusType,
        hideIcon,
        statusContainerClassName,
        icon,
        iconClassName,
    } = props

    return (
        <div className={classNames(styles.container, className)}>
            <div
                className={classNames(
                    statusContainerClassName,
                    styles.statusInfo,
                    { [styles.success]: statusType === "success" },
                    { [styles.warning]: statusType === "warning" },
                    { [styles.error]: statusType === "error" },
                    { [styles.disabled]: statusType === "disabled" },
                    { [styles.disabled]: statusType === "unknown" }
                )}
            >
                {!hideStatusType && (
                    <div className={classNames(styles.flexGap6x, styles.columnFlexContainer)}>
                        <label className={styles.statusLabel}>
                            {statusLabelTitle ?? ls.getString("status")}
                        </label>
                        <Status
                            type={statusType}
                            label={statusLabel}
                            icon={icon}
                            hideIcon={hideIcon}
                            iconClassName={iconClassName}
                        />
                    </div>
                )}
                {statusItems && (
                    <div
                        className={classNames(
                            styles.columnFlexContainer,
                            styles.flexGap8x,
                            listClassName
                        )}
                    >
                        {statusItems.map((item) => (
                            <KeyValuePair {...item} key={item.label} />
                        ))}
                    </div>
                )}
            </div>
            {listItems && (
                <KeyValuePairList>
                    {listItems.map((item) => (
                        <KeyValuePair {...item} key={item.label} />
                    ))}
                </KeyValuePairList>
            )}
        </div>
    )
}
