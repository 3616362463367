import { Singleton } from "../../pre-v3/decorators/Singleton.decorator"
import { BaseApi } from "./Base.api"

@Singleton("ClusterApi")
export class ClusterApi extends BaseApi {
    public getClusters(): Promise<GetClustersRes> {
        return this.get("/api/experimental/v2/shield_config", {
            default: this.localization.getString("clusterNotFound"),
        })
    }
}

export interface GetClustersRes {
    Configs: ClusterRes[] | null
}

export interface ClusterRes {
    UUID: string
    ShieldName: string
    GroupType: string
    PublicAddr: string
}
