import { LanguageKey } from "../../../pre-v3/services/localization/languages/en-US.language"
import { StatusType } from "../../components/status/Status.component"
import { PolicyAttachment } from "../Policy.service"

export const statusMap: Record<RegisteredServiceStatus, StatusType> = {
    policyEnforcing: "success",
    policyPermissive: "warning",
    noPolicy: "error",
    inactive: "disabled",
}

export const labelMap: Record<RegisteredServiceStatus, LanguageKey> = {
    policyEnforcing: "policyEnforcing",
    policyPermissive: "policyPermissive",
    noPolicy: "noPolicy",
    inactive: "inactive",
}

export enum RegisteredServiceStatus {
    POLICY_ENFORCING = "policyEnforcing",
    POLICY_PERMISSIVE = "policyPermissive",
    NO_POLICY = "noPolicy",
    INACTIVE = "inactive",
}

export interface RegisteredService {
    id?: string
    name: string
    description?: string
    descriptionLink?: string
    icon?: string
    autorun?: boolean
    isAutorunLocked?: boolean
    createdAt?: number
    createdBy?: string
    updatedAt?: number
    updatedBy?: string

    policyAttachment?: PolicyAttachment
    status?: RegisteredServiceStatus
    dnsSearchDomains?: string[]
}
