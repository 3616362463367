import { IconDefinition } from "@fortawesome/fontawesome-common-types"
import classNames from "classnames/bind"
import * as React from "react"

import { useServiceLocalization } from "../../../pre-v3/services"
import { KeyValuePair } from "../../../v3/components/key-value-pair/KeyValuePair.component"
import { Status, StatusType } from "../../../v3/components/status/Status.component"
import styles from "./OverviewStatus.module.scss"
import { KeyValuePairItems } from "./OverviewTypes"
import { Card } from "../../card/Card.component"

export interface Props {
    statusType: StatusType
    statusItems?: KeyValuePairItems[]
    statusLabel?: string
    statusLabelTitle?: string
    listClassName?: string
    className?: string
    hideStatusType?: boolean
    hideIcon?: boolean
    statusContainerClassName?: string
    icon?: IconDefinition
    iconClassName?: string
}

export function OverviewStatus(props: Props) {
    const localization = useServiceLocalization()
    const {
        statusType,
        statusItems,
        statusLabel,
        statusLabelTitle,
        listClassName,
        className,
        hideStatusType,
        hideIcon,
        icon,
        iconClassName,
    } = props

    return (
        <Card className={classNames(className, styles.statusInfo)}>
            {!hideStatusType && (
                <div className={classNames(styles.flexGap6x, styles.columnFlexContainer)}>
                    <label className={styles.statusLabel}>
                        {statusLabelTitle ?? localization.getString("status")}
                    </label>
                    <Status
                        type={statusType}
                        label={statusLabel}
                        icon={icon}
                        hideIcon={hideIcon}
                        iconClassName={iconClassName}
                    />
                </div>
            )}
            {statusItems && (
                <div
                    className={classNames(
                        styles.columnFlexContainer,
                        styles.flexGap8x,
                        listClassName
                    )}
                >
                    {statusItems.map((item) => (
                        <KeyValuePair {...item} key={item.label} />
                    ))}
                </div>
            )}
        </Card>
    )
}
