import classNames from "classnames/bind"
import React, { useEffect } from "react"

import { Icon, IconType } from "../icon/Icon.component"
import styles from "./Modal.module.scss"

export enum ModalAction {
    COMPLETE = "COMPLETE",
    DISMISS = "DISMISS",
}

export type Response<Data> =
    | {
          action: ModalAction.COMPLETE
          data: Data
      }
    | {
          action: ModalAction.DISMISS
      }

export interface ModalApi<Data> {
    open(): Promise<Response<Data>>
    dismiss(): void
    complete(data: Data): void
}

export interface ModalProps {
    id: string
    title?: string
    children?: React.ReactNode
    childrenClassName?: string
    footer?: React.ReactNode
    large?: boolean
}

export function ModalWithoutRef<Data>(
    props: ModalProps,
    ref: React.ForwardedRef<ModalApi<Data>>
): JSX.Element {
    const dialogRef = React.useRef<HTMLDialogElement>(null)
    const resolveRef = React.useRef<(response: Response<Data>) => void>()
    const modalRef = React.useRef<HTMLDivElement>(null)

    const actions: ModalApi<Data> = {
        open: async () => {
            dialogRef.current?.showModal()
            return new Promise<Response<Data>>((resolve) => {
                resolveRef.current = resolve
            })
        },
        dismiss: () => {
            dialogRef.current?.close()
            resolveRef.current?.({ action: ModalAction.DISMISS })
        },
        complete: (data) => {
            dialogRef.current?.close()
            resolveRef.current?.({ action: ModalAction.COMPLETE, data })
        },
    }

    React.useImperativeHandle(ref, () => actions)

    useEffect(() => {
        const onBackDropClicked = (e: MouseEvent) => {
            if (modalRef.current && !modalRef.current.contains(e.target as Element)) {
                actions.dismiss()
            }
        }
        dialogRef.current?.addEventListener("click", onBackDropClicked)

        return () => {
            dialogRef.current?.removeEventListener("click", onBackDropClicked)
        }
    }, [])

    const onDismiss: React.ReactEventHandler = (e) => {
        e.preventDefault()
        dialogRef.current?.close()
        resolveRef.current?.({ action: ModalAction.DISMISS })
    }

    return (
        <dialog
            aria-labelledby={props.id}
            ref={dialogRef}
            className={styles.dialog}
            onCancel={onDismiss}
            onClose={onDismiss}
        >
            <div
                ref={modalRef}
                className={classNames(styles.modal, { [styles.large]: props.large })}
            >
                <div className={styles.header}>
                    {props.title && <h2 id={props.id}>{props.title}</h2>}
                    <button onClick={onDismiss} type="button">
                        <Icon icon={IconType.LIGHT_CROSS} />
                    </button>
                </div>
                {props.children && (
                    <div className={classNames(styles.content, props.childrenClassName)}>
                        {props.children}
                    </div>
                )}
                {props.footer && <div className={styles.footer}>{props.footer}</div>}
            </div>
        </dialog>
    )
}
