import { SyntheticEvent } from "react"

export class FormUtil {
    // Helper to bind input values to state
    // Sets value to state property defined by input id
    // Must be bound to a component (ex. onChange={FormUtil.updateValue.bind(this)})
    public static updateValue(event: SyntheticEvent<HTMLInputElement>): void {
        const target: HTMLInputElement = event.target as HTMLInputElement
        if (target && target.id) {
            ;(<any>this).setState({
                [target.id]: target.value,
            })
        }
    }

    public static getFieldValue(event: React.FormEvent<HTMLFormElement>, field: string): string {
        const item = event.currentTarget.elements.namedItem(field)
        return item && "value" in item ? item.value : ""
    }

    // Used to get values from individual inputs (event is onChange)
    public static getInputValue(event: SyntheticEvent): string {
        if (event && event.target) {
            return (event.target as HTMLInputElement).value
        }
        return ""
    }

    public static isValidRegex(regex: string): boolean {
        try {
            new RegExp(regex)
            return true
        } catch {
            return false
        }
    }

    public static isValidInput(value: string, regex: RegExp): boolean {
        return regex.test(value)
    }

    public static trimAny(body: any) {
        try {
            JSON.stringify(body)
            if (Array.isArray(body)) {
                return this.trimArray(body)
            } else if (typeof body === "object" && body !== null) {
                return this.trimObject(body)
            } else if (typeof body === "string") {
                return body.trim()
            }
        } catch (e) {
            console.error(e)
        }

        return body
    }

    private static trimObject(body: any) {
        let bodyCopy: any = { ...body }
        try {
            const keys = Object.keys(bodyCopy)
            for (const key of keys) {
                const value = bodyCopy[key]
                if (typeof value === "string") {
                    bodyCopy[key] = value.trim()
                } else if (Array.isArray(value)) {
                    bodyCopy[key] = this.trimArray(value)
                } else if (typeof value === "object" && value !== null) {
                    bodyCopy[key] = this.trimObject(value)
                }
            }
            return bodyCopy
        } catch (e) {
            console.error(e)
        }
        return body
    }

    private static trimArray(body: any[]) {
        let bodyCopy: any = [...body]
        try {
            if (Array.isArray(bodyCopy)) {
                bodyCopy.forEach((element: any) => {
                    if (typeof element === "string") element = element.trim()
                    else if (Array.isArray(element)) this.trimArray(element)
                    else if (typeof element === "object" && element !== null)
                        element = this.trimObject(element)
                    return element
                })
            }
            return bodyCopy
        } catch (e) {
            console.error(e)
        }
        return body
    }
}
