import React from "react"

import { useServiceLocalization } from "../../../../pre-v3/services/localization/Localization.service"
import { PatternUtil } from "../../../../pre-v3/utils/Pattern.util"
import { Input, InputProps } from "../../../components/input/Input.component"

type OmittedKeys = "type" | "required" | "pattern" | "title"

type NameInputProps = Omit<InputProps, OmittedKeys>

export function NameInput(props: NameInputProps): JSX.Element {
    const localization = useServiceLocalization()

    return (
        <Input
            {...props}
            type="text"
            required
            pattern={PatternUtil.SERVICE_NAME.source}
            title={localization.getString("serviceNameRegexDescription")}
        />
    )
}
