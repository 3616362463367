import { IconDefinition } from "@fortawesome/fontawesome-svg-core"
import React from "react"
import { faApple, faWindows, faLinux } from "@fortawesome/free-brands-svg-icons"
import styles from "./OsInput.module.scss"
import { LanguageKey } from "../../../pre-v3/services/localization/languages/en-US.language"
import { FormUtil } from "../../../pre-v3/utils/Form.util"
import { Icon } from "../Icon/Icon.component"
import { Input } from "../input/Input.component"

export interface Props {
    placeholder?: Record<OsType, LanguageKey>
    value?: OsInputValue
    onChange?: (value: OsInputValue) => void
    disabled?: boolean
    required?: boolean
    name?: string
    defaultValue?: OsInputValue
}

export function OsInput(props: Props) {
    const { defaultValue, placeholder, disabled, required, value, onChange } = props

    function onOsValueChange(osType: OsType): React.ChangeEventHandler<HTMLInputElement> {
        return (event: React.ChangeEvent<HTMLInputElement>): void => {
            onChange?.({
                ...value,
                [osType]: FormUtil.getInputValue(event),
            } as Record<OsType, string>)
        }
    }

    return (
        <div className={styles.osInputsContainer}>
            {Object.values(OsType).map((os, index) => {
                return (
                    <div className={styles.osInputContainer} key={index}>
                        <Icon icon={OsIconDict[os]} className={styles.osIcon} />
                        <Input
                            type="text"
                            value={value?.[os]}
                            onChange={onOsValueChange(os)}
                            placeholder={placeholder && placeholder[os]}
                            className={styles.osInput}
                            disabled={disabled}
                            required={required}
                            name={`${props.name}-${os.toLowerCase()}`}
                            defaultValue={defaultValue && defaultValue[os]}
                        />
                    </div>
                )
            })}
        </div>
    )
}

export enum OsType {
    MACOS = "MACOS",
    WINDOWS = "WINDOWS",
    LINUX = "LINUX",
}
export type OsInputValue = Record<OsType, string>

const OsIconDict: Record<OsType, IconDefinition> = {
    [OsType.MACOS]: faApple,
    [OsType.WINDOWS]: faWindows,
    [OsType.LINUX]: faLinux,
}

export const defaultOsValue: OsInputValue = {
    [OsType.MACOS]: "",
    [OsType.WINDOWS]: "",
    [OsType.LINUX]: "",
}
