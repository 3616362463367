import {
    UseMutationResult,
    UseQueryResult,
    useMapMutationResult,
    useMapQueryResult,
} from "../../utils/query"
import {
    AnswerRes,
    FeedbackReq,
    useGetAnswers as useLlmGetAnswers,
    useGetAnswer as useLlmGetAnswer,
    useGetRecommendations as useLlmGetRecommendations,
    useProvideFeedback as useLlmProvideFeedback,
} from "../api/LlmQuery.api"

export function useGetAnswers(): UseQueryResult<Answer[], unknown> {
    return useMapQueryResult<AnswerRes[], Answer[], unknown, typeof useLlmGetAnswers>(
        useLlmGetAnswers,
        [],
        (answers) => answers.map(mapAnswerResToAnswer)
    )
}

export function useGetAnswer(id = "last"): UseQueryResult<AnswerDetail | undefined, unknown> {
    return useMapQueryResult<
        AnswerRes | null,
        AnswerDetail | undefined,
        unknown,
        typeof useLlmGetAnswer
    >(useLlmGetAnswer, [id], (answerRes) =>
        answerRes ? mapAnswerResToAnswerDetail(answerRes) : undefined
    )
}

export function useProvideFeedback(): UseMutationResult<void, unknown, [AnswerDetail, Feedback]> {
    return useMapMutationResult<
        void,
        void,
        unknown,
        [string, FeedbackReq],
        [AnswerDetail, Feedback],
        typeof useLlmProvideFeedback
    >(
        useLlmProvideFeedback,
        [],
        () => {},
        ([answer, feedback]) => [
            answer.id,
            {
                was_helpful: feedback === Feedback.HELPFUL,
                created_at: new Date().toISOString(),
            },
        ]
    )
}

export function useGetRecommendations(currentPageUrl?: string) {
    return useLlmGetRecommendations({ current_page_url: currentPageUrl })
}

export interface Answer {
    id: string
    prompt: string
    createdAt: Date
}

export interface AnswerDetail {
    id: string
    prompt: string
    response: string
    hasProvidedFeedback: boolean
    sources: Source[]
}

export interface Source {
    label: string
    url: string
}

export enum Feedback {
    HELPFUL = "HELPFUL",
    NOT_HELPFUL = "NOT_HELPFUL",
}

function mapAnswerResToAnswer(answerRes: AnswerRes): Answer {
    return {
        id: answerRes.answer_id.toString(),
        prompt: answerRes.prompt,
        createdAt: new Date(answerRes.created_at),
    }
}

export function mapAnswerResToAnswerDetail(answerRes: AnswerRes): AnswerDetail {
    return {
        ...mapAnswerResToAnswer(answerRes),
        response: answerRes.response,
        hasProvidedFeedback: answerRes.has_provided_feedback,
        sources: answerRes.sources,
    }
}
