import { useMutation, useQuery } from "@tanstack/react-query"
import { AdminIDPType, AuthApi, OrgStatus } from "../../pre-v3/api/Auth.api"
import { Singleton } from "../../pre-v3/decorators/Singleton.decorator"
import { GenerateMfaRes, MfaApi } from "../api/Mfa.api"
import { MswAuthApi } from "../api/MswAuth.api"
import { useServiceLocalization } from "../../pre-v3/services"

@Singleton("LoginService")
export class LoginService {
    public async getOrgStatus(
        orgName: string
    ): Promise<{ orgStatus: OrgStatus; adminIDPType?: AdminIDPType; isAdminBanyanIDP: boolean }> {
        const {
            Message: orgStatus,
            AdminIDPType,
            IsAdminBanyanIDP,
        } = await this.authApi.checkOrg(orgName)
        return {
            orgStatus,
            adminIDPType: AdminIDPType === "" ? undefined : AdminIDPType,
            isAdminBanyanIDP: IsAdminBanyanIDP,
        }
    }

    public async login(
        orgName: string,
        emailAddress: string,
        password: string
    ): Promise<LoginPayload> {
        const res = await this.authApi.login({
            Email: emailAddress,
            Password: password,
            OrgName: orgName,
        })
        const next = res.mfa_required ? (res.mfa_configured ? "mfa" : "configure_mfa") : ""
        return {
            token: res.Message,
            next: next,
        }
    }

    public async resetPassword(
        orgName: string,
        email: string,
        password: string,
        uuid: string
    ): Promise<LoginPayload> {
        const res = await this.authApi.resetPassword(orgName, email, password, uuid)
        const next = res.mfa_required ? (res.mfa_configured ? "mfa" : "configure_mfa") : ""
        return {
            token: res.Message,
            next: next,
        }
    }

    public async samlLogin(orgName: string): Promise<string> {
        const { Message: href } = await this.authApi.samlLogin(orgName)
        return href
    }

    public async forgotPassword(orgName: string, emailAddress: string): Promise<void> {
        await this.authApi.forgotPassword(orgName, emailAddress)
    }

    public generateMfa(token: string): Promise<MfaSetup> {
        return this.mfaApi.generateMfa(token).then((res: GenerateMfaRes) => {
            return {
                code: res.base32_code,
                qrCode: res.qr_code_data_url,
            }
        })
    }

    // Use to register new MFA
    public verifyMfa(otp: string, token: string): Promise<void> {
        return this.mfaApi.verifyMfa(otp, token)
    }

    // Use to validate existing MFA
    public validateMfa(otp: string, token: string): Promise<string> {
        return this.mfaApi.validateMfa(otp, token).then((r) => r.token)
    }

    private authApi = new AuthApi()
    private mfaApi = new MfaApi()
}

export interface LoginPayload {
    token: string
    next: "configure_mfa" | "mfa" | ""
}

interface MfaSetup {
    code: string
    qrCode: string
}

interface ValidateMfaParams {
    otp: string
    token: string
}

interface LoginParams {
    orgName: string
    emailAddress: string
    password: string
}

interface ForgotPasswordParams {
    orgName: string
    emailAddress: string
}

export function useLogin() {
    const loginService = new LoginService()
    return useMutation<LoginPayload, string, LoginParams>({
        mutationFn: (params) =>
            loginService.login(params.orgName, params.emailAddress, params.password),
    })
}

export function useForgotPassword() {
    const loginService = new LoginService()
    return useMutation<void, string, ForgotPasswordParams>({
        mutationFn: (params) => loginService.forgotPassword(params.orgName, params.emailAddress),
    })
}

export function useGenerateMfa(token: string, options?: QueryOptions<MfaSetup>) {
    const loginService = new LoginService()
    return useQuery<MfaSetup, string>({
        queryKey: ["loginService.generateMfa", token],
        queryFn: () => loginService.generateMfa(token),
        ...options,
    })
}

export function useVerifyMfa(options?: QueryOptions<void, string, ValidateMfaParams>) {
    const loginService = new LoginService()
    return useMutation<void, string, ValidateMfaParams>({
        mutationFn: (params) => loginService.verifyMfa(params.otp, params.token),
        ...options,
    })
}

export function useValidateMfa() {
    const loginService = new LoginService()
    return useMutation<string, string, ValidateMfaParams>({
        mutationFn: (params) => loginService.validateMfa(params.otp, params.token),
    })
}

export function useMswLogin(options?: QueryOptions<MswTokens, unknown, MswLoginParams>) {
    const authApi = new MswAuthApi()
    const localization = useServiceLocalization()

    return useMutation<MswTokens, string, MswLoginParams>({
        mutationFn: async ({ code, orgId }: MswLoginParams) => {
            if (!code || !orgId) throw localization.getString("mswAuthorizationError")
            const { msw_token, banyan_token } = await authApi.mswLogin({ code, org_id: orgId })

            return {
                consoleAuthToken: banyan_token,
                cscAuthToken: msw_token,
            }
        },
        ...options,
    })
}

interface MswLoginParams {
    code?: string
    orgId?: string
}
interface MswTokens {
    consoleAuthToken: string
    cscAuthToken: string
}
