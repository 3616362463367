import React, { ReactNode, SyntheticEvent, createRef } from "react"
import TrustProviderIdentitySettingsTemplate from "./TrustProviderIdentitySettings.template"
import { LocalizationService } from "../../../../services/localization/Localization.service"
import { LinkService } from "../../../../services/link/Link.service"
import { ModalService } from "../../../../services/Modal.service"
import { Bind } from "../../../../decorators/Bind.decorator"
import { UserService } from "../../../../services/User.service"
import { OrgEdition } from "../../../../services"
import {
    UserOrgDetails,
    IDPName,
    IDPProtocol,
    DeviceRegistrationProviderType,
    IDPConfig,
    UserOrgIDPDetails,
    OrgDeviceIDPDetails,
    UserApi,
} from "../../../../api/User.api"
import ActionBarService from "../../../../services/ActionBar.service"
import { SettingsService } from "../../../../services/Settings.service"
import { MessageContent, OkCancelActions } from "../../../../components"
import { AppText } from "../../../../../v3/components/app-text/AppText.component"
import { UserService as UserServiceV3 } from "../../../../../v3/services/User.service"

interface TrustProviderIdentitySettingsProps {
    canConfigureEndUserSettings: boolean
}
export class TrustProviderIdentitySettings extends React.Component<
    TrustProviderIdentitySettingsProps,
    TrustProviderIdentitySettingsState
> {
    public state: TrustProviderIdentitySettingsState = {
        edition: OrgEdition.ENTERPRISE,
        error: "",
        success: "",
        deviceError: "",
        deviceSuccess: "",
        idpConfig: {},
        showConfirmDialog: false,
        showDeviceConfirmDialog: false,
        redirectUri: "",
        idpProtocol: "",
        selectedIdpName: "",
        idpName: "",
        deviceManagerProtocol: "",
        selectedDeviceManagerName: "",
        deviceManagerName: "",
        deviceRedirectUrl: "",
        isBanyanIDP: false,
        isLoading: false,
        configureError: "",
        deviceManagerConfig: {},
        isScimEnabled: false,
        enableScimToggle: true,
    }

    public render(): ReactNode {
        return TrustProviderIdentitySettingsTemplate.call(this)
    }

    public componentDidMount(): void {
        this.actionBarService.setItems(this.localizationService.getString("identityProvider"), [
            { label: this.localizationService.getString("enduserManagement") },
        ])

        this.fetchData()
    }

    private userService: UserService = new UserService()
    private userApi: UserApi = new UserApi()
    private localizationService: LocalizationService = new LocalizationService()
    private settingsService: SettingsService = new SettingsService()
    private linkService: LinkService = new LinkService()
    private modalService: ModalService = new ModalService()
    private actionBarService: ActionBarService = new ActionBarService()
    private userServiceV3: UserServiceV3 = new UserServiceV3()
    private idpProtocolOptions: IDPProtocol[] = [IDPProtocol.SAML, IDPProtocol.OIDC]
    private knownIdpNames: IDPName[] = [
        IDPName.OKTA,
        IDPName.COGNITO,
        IDPName.ONE_LOGIN,
        IDPName.OTHER,
    ]

    private deviceRegOptions: string[] = [
        DeviceRegistrationProviderType.NONE,
        DeviceRegistrationProviderType.OIDC,
        DeviceRegistrationProviderType.SAML,
    ]

    private idpConfig: IDPConfig
    private deviceManagerConfig: IDPConfig
    private idpDetails: UserOrgIDPDetails
    private deviceManagerDetails: OrgDeviceIDPDetails

    private appClientName: string = "Banyan TrustProvider"
    private deviceAppClientName: string = "Banyan DeviceRegistrationProvider"
    private userFormRef = createRef<HTMLFormElement>()

    @Bind
    private onConfigure(): void {
        this.modalService
            .open(
                this.localizationService.getString(
                    "configureSomething",
                    this.localizationService.getString("userIdentityProvider")
                ),
                {
                    component: MessageContent,
                    props: {
                        text: (
                            <AppText
                                ls={{
                                    key: "areYouSureYouWantToConfigureSomething",
                                    replaceVals: [
                                        this.localizationService.getString("userIdentityProvider"),
                                    ],
                                }}
                            />
                        ),
                    },
                    maxWidth: "sm",
                },
                {
                    component: OkCancelActions,
                    props: { okString: this.localizationService.getString("confirm") },
                }
            )
            .onClose(() => {
                this.setState({ isLoading: true })
                this.userServiceV3
                    .switchBanyanIDPToLocalIDP()
                    .then(() => {
                        this.setState({ isLoading: false })
                        this.fetchData()
                    })
                    .catch((e) => {
                        this.setState({ isLoading: false })
                        this.setState({ configureError: e })
                    })
            })
    }

    @Bind
    private onIdpProtocolChange(value: string): void {
        this.setState({ idpProtocol: value })
    }

    @Bind
    private onSelectedIdpNameChange(value: IDPName): void {
        this.setState({
            selectedIdpName: value,
            idpName: value === IDPName.OTHER ? "" : value, // clear the field for user input if necessary; otherwise store the name here
        })
    }

    @Bind
    private onIdpNameChange(event: SyntheticEvent): void {
        if (event && event.target) {
            this.setState({ idpName: (event.target as HTMLInputElement).value })
        }
    }

    @Bind
    private onDeviceManagerProtocolChange(value: string): void {
        this.setState({ deviceManagerProtocol: value })
    }

    @Bind
    private onSelectedDeviceManagerNameChange(value: IDPName): void {
        this.setState({
            selectedDeviceManagerName: value,
            deviceManagerName: value === IDPName.OTHER ? "" : value, // clear the field for user input if necessary; otherwise store the name here
        })
    }

    @Bind
    private onDeviceManagerNameChange(event: React.ChangeEvent<HTMLInputElement>): void {
        if (event?.target) {
            this.setState({ deviceManagerName: event.target.value })
        }
    }

    @Bind
    private onIssuerUrlChange(event: React.ChangeEvent<HTMLInputElement>): void {
        if (event?.target) {
            this.setState({ issuerUrl: event.target.value })
        }
    }

    @Bind
    private onClientSecretChange(event: React.ChangeEvent<HTMLInputElement>): void {
        if (event?.target) {
            this.setState((state) => ({
                idpConfig: { ...state.idpConfig, ClientSecret: event.target.value },
            }))
        }
    }

    @Bind
    private onDeviceClientSecretChange(event: React.ChangeEvent<HTMLInputElement>): void {
        if (!event?.target) return
        const secret = event.target.value

        if (this.deviceManagerConfig.ClientSecret) {
            this.setState((state) => ({
                deviceManagerConfig: { ...state.deviceManagerConfig, ClientSecret: secret },
            }))
        } else if (this.idpConfig.DeviceRegClientSecret) {
            this.setState((state) => ({
                idpConfig: { ...state.idpConfig, DeviceRegClientSecret: secret },
            }))
        }
    }

    @Bind
    private onRedirectUriChange(event: React.ChangeEvent<HTMLInputElement>): void {
        if (event?.target) {
            this.setState({ redirectUri: event.target.value })
        }
    }

    @Bind
    private onDeviceRedirectUriChange(event: React.ChangeEvent<HTMLInputElement>): void {
        if (event?.target) {
            this.setState({ deviceRedirectUrl: event.target.value })
        }
    }

    @Bind
    private onGroupsDelimChange(event: React.ChangeEvent<HTMLInputElement>): void {
        if (event?.target) {
            this.setState({
                idpConfig: {
                    ...this.state.idpConfig,
                    GroupsDelim: event.target.value,
                },
            })
        }
    }

    @Bind
    private onShowConfirmDialog(): void {
        this.setState({ showConfirmDialog: true })
    }

    @Bind
    private onHideConfirmDialog(): void {
        this.setState({ showConfirmDialog: false })
    }

    @Bind
    private onShowDeviceConfirmDialog(): void {
        this.setState({ showDeviceConfirmDialog: true })
    }

    @Bind
    private onHideDeviceConfirmDialog(): void {
        this.setState({ showDeviceConfirmDialog: false })
    }

    @Bind
    private onDeleteDeviceReg(): void {
        this.setState({ deviceIdpLoading: true, deviceSuccess: "", deviceError: "" })

        this.modalService
            .openConfirmation(
                this.localizationService.getString("deleteDeviceRegistrationProviderConfig"),
                this.localizationService.getString("deleteLastDeviceExplanation")
            )
            .onClose(() => {
                this.userApi
                    .deleteIDPConfig()
                    .then((res) => {
                        this.setState({
                            deviceIdpLoading: false,
                            deviceSuccess: res.Message,
                            deviceError: "",
                        })
                        this.fetchData()
                    })
                    .catch((error) => {
                        this.setState({
                            deviceIdpLoading: false,
                            deviceSuccess: "",
                            deviceError: error.message,
                        })
                    })
            })
            .onCancel(() => {
                this.setState({ deviceIdpLoading: false })
            })
    }

    @Bind
    private marshallIdpConfigDetails(form: any): UserOrgIDPDetails {
        let idpDetails: UserOrgIDPDetails = {
            IDPName: this.state.idpName,
            IDPConfig: {},
            IDPProto: this.state.idpProtocol,
        }
        idpDetails.IDPConfig.RedirectURL = this.state.redirectUri
        if (this.state.idpProtocol === IDPProtocol.OIDC) {
            idpDetails.IDPConfig.IssuerURL = form["issuerUrl"].value
            idpDetails.IDPConfig.ClientID = form["clientId"].value
            idpDetails.IDPConfig.ClientSecret = form["clientSecret"].value

            if (this.state.idpName === IDPName.COGNITO) {
                idpDetails.IDPConfig.AccessKeyId = form["accessKey"].value
                idpDetails.IDPConfig.SecretAccessKey = form["secretKey"].value
                idpDetails.IDPConfig.UserPoolId = form["userPoolId"].value
                idpDetails.IDPConfig.UserPoolDomain = form["authDomain"].value
            }
        } else if (this.state.idpProtocol === IDPProtocol.SAML) {
            idpDetails.IDPConfig.SSOURL = form["ssoUrl"].value
            idpDetails.IDPConfig.CAData = form["caData"].value
            idpDetails.IDPConfig.UsernameAttr = form["usernameAttr"].value
            idpDetails.IDPConfig.GroupsAttr = form["groupsAttr"].value
            idpDetails.IDPConfig.EmailAttr = form["emailAttr"].value
            idpDetails.IDPConfig.EntityIssuer =
                form["entityIssuer"].value || form["redirectUrl"].value
            if (form["groupsDelimiter"] && form["groupsDelimiter"].value) {
                idpDetails.IDPConfig.GroupsDelim = form["groupsDelimiter"].value
            }
        }
        return idpDetails
    }

    @Bind
    private marshallDeviceManagerConfigDetails(form: any): OrgDeviceIDPDetails {
        let deviceManagerDetails: OrgDeviceIDPDetails = {
            DeviceRegIDPName: this.state.deviceManagerName,
            DeviceRegIDPConfig: {},
            DeviceRegIDPProto: this.state.deviceManagerProtocol,
        }
        deviceManagerDetails.DeviceRegIDPConfig.RedirectURL = this.state.deviceRedirectUrl
        if (this.state.deviceManagerProtocol === IDPProtocol.OIDC) {
            deviceManagerDetails.DeviceRegIDPConfig.IssuerURL = form["deviceIssuerUrl"].value
            deviceManagerDetails.DeviceRegIDPConfig.ClientID = form["deviceClientId"].value
            deviceManagerDetails.DeviceRegIDPConfig.ClientSecret = form["deviceClientSecret"].value
            deviceManagerDetails.DeviceRegIDPConfig.RedirectURL = form["deviceRedirectUrl"].value

            if (this.state.deviceManagerName === IDPName.COGNITO) {
                deviceManagerDetails.DeviceRegIDPConfig.AccessKeyId = form["deviceAccessKey"].value
                deviceManagerDetails.DeviceRegIDPConfig.SecretAccessKey =
                    form["deviceSecretKey"].value
                deviceManagerDetails.DeviceRegIDPConfig.UserPoolId = form["deviceUserPoolId"].value
                deviceManagerDetails.DeviceRegIDPConfig.UserPoolDomain =
                    form["deviceAuthDomain"].value
            }
        } else if (this.state.deviceManagerProtocol === IDPProtocol.SAML) {
            deviceManagerDetails.DeviceRegIDPConfig.SSOURL = form["deviceSsoUrl"].value
            deviceManagerDetails.DeviceRegIDPConfig.CAData = form["deviceCaData"].value
            deviceManagerDetails.DeviceRegIDPConfig.UsernameAttr = form["deviceUsernameAttr"].value
            deviceManagerDetails.DeviceRegIDPConfig.GroupsAttr = form["deviceGroupsAttr"].value
            deviceManagerDetails.DeviceRegIDPConfig.EmailAttr = form["deviceEmailAttr"].value
            deviceManagerDetails.DeviceRegIDPConfig.EntityIssuer =
                form["deviceEntityIssuer"].value || form["deviceRedirectUrl"].value
            if (form["deviceGroupsDelimiter"] && form["deviceGroupsDelimiter"].value) {
                deviceManagerDetails.DeviceRegIDPConfig.GroupsDelim =
                    form["deviceGroupsDelimiter"].value
            }
        }
        return deviceManagerDetails
    }

    @Bind
    private onSubmitUserConfig(): void {
        this.setState({ success: "", error: "" })
        if (this.userFormRef.current) {
            const form: { [key: string]: HTMLInputElement } = this.userFormRef.current as any
            this.idpDetails = this.marshallIdpConfigDetails(form)

            this.onShowConfirmDialog()
        }
    }

    @Bind
    private onScimProvisioningStatusChange(state: boolean): void {
        if (state) {
            this.setState({ isScimEnabled: true })
        } else {
            this.setState({ isScimEnabled: false })
        }
    }

    @Bind
    private onSubmitDeviceConfig(event: SyntheticEvent): void {
        this.setState({ deviceSuccess: "", deviceError: "" })
        event.preventDefault()
        if (event.target) {
            const form: { [key: string]: HTMLInputElement } = event.target as any
            this.deviceManagerDetails = this.marshallDeviceManagerConfigDetails(form)

            this.onShowDeviceConfirmDialog()
        }
    }

    @Bind
    private onUpdateDeviceIdp(): void {
        this.onHideDeviceConfirmDialog()
        this.setState({ deviceIdpLoading: true })
        this.userApi.updateIDPConfig(undefined, this.deviceManagerDetails).then(
            () => {
                this.setState({
                    deviceIdpLoading: false,
                    deviceSuccess: this.localizationService.getString(
                        "deviceIdentityProviderConfigurationUpdated"
                    ),
                })
                this.settingsService.refreshLeftNav()
                this.fetchData()
            },
            (error: Error) => {
                this.setState({ deviceIdpLoading: false, deviceError: error.message })
            }
        )
    }

    @Bind
    private onUpdateUserIdp(): void {
        this.onHideConfirmDialog()
        this.setState({ userIdpLoading: true })
        this.setState({ enableScimToggle: false })
        this.userApi.updateIDPConfig(this.idpDetails).then(
            () => {
                this.setState({
                    userIdpLoading: false,
                    success: this.localizationService.getString(
                        "userIdentityProviderConfigurationUpdated"
                    ),
                })
                this.settingsService.refreshLeftNav()
                this.fetchData()
            },
            (error: Error) => {
                this.setState({ userIdpLoading: false, error: error.message })
            }
        )
    }

    @Bind
    private async fetchData(): Promise<void> {
        const edition: OrgEdition = await this.userService.getEdition()
        this.setState({ edition })

        const orgDetails: UserOrgDetails = await this.userService.getUserOrgDetails(true)
        this.setState({ isBanyanIDP: Boolean(orgDetails.IsAdminBanyanIDP) })
        this.setState({ isScimEnabled: Boolean(orgDetails.IsSCIMEnabled) })
        let idpConfig: IDPConfig = {}
        let deviceManagerConfig: IDPConfig = {}
        if (orgDetails.IDPProto !== IDPProtocol.UNSET) {
            this.setState({ enableScimToggle: false })
            try {
                idpConfig = JSON.parse(orgDetails.IDPConfig)
                this.setState({ issuerUrl: idpConfig.IssuerURL })
            } catch {
                this.setState({
                    error: this.localizationService.getString(
                        "couldNotParseExistingIdentityProviderConfig"
                    ),
                })
            }
        }
        if (orgDetails.DeviceRegIDPProto !== IDPProtocol.UNSET) {
            try {
                deviceManagerConfig = JSON.parse(orgDetails.DeviceRegIDPConfig)
            } catch {
                this.setState({
                    error: this.localizationService.getString(
                        "couldNotParseExistingIdentityProviderConfig"
                    ),
                })
            }
        }

        this.deviceManagerConfig = deviceManagerConfig
        this.idpConfig = idpConfig

        const trustProviderRedirectUrl: string =
            orgDetails.TrustproviderURL.replace("https://", `https://${orgDetails.OrgName}.`) +
            "/v2/callback"

        const redirectUrl: string = trustProviderRedirectUrl.replace(".trust", ".portal")

        this.setState({
            idpName: orgDetails.IDPName,
            selectedIdpName:
                orgDetails.IDPName && orgDetails.IDPName.length
                    ? this.knownIdpNames.find((name) => name === orgDetails.IDPName) ||
                      IDPName.OTHER
                    : "",
            idpConfig: idpConfig,
            deviceManagerConfig: deviceManagerConfig,
            idpProtocol: orgDetails.IDPProto,
            deviceManagerName: orgDetails.DeviceRegIDPName || this.state.deviceManagerName,
            selectedDeviceManagerName:
                orgDetails.DeviceRegIDPName && orgDetails.DeviceRegIDPName.length
                    ? this.knownIdpNames.find((name) => name === orgDetails.DeviceRegIDPName) ||
                      IDPName.OTHER
                    : "",
            deviceManagerProtocol: orgDetails.DeviceRegIDPProto,
            redirectUri: idpConfig.RedirectURL || redirectUrl,
            deviceRedirectUrl: deviceManagerConfig.RedirectURL || redirectUrl,
        })
    }
}

interface TrustProviderIdentitySettingsState {
    edition: OrgEdition
    error: string
    success: string
    deviceError: string
    deviceSuccess: string
    userIdpLoading?: boolean
    deviceIdpLoading?: boolean
    idpConfig: IDPConfig
    showConfirmDialog: boolean
    showDeviceConfirmDialog: boolean
    issuerUrl?: string
    redirectUri?: string
    idpProtocol?: string
    idpName: string
    selectedIdpName: string
    deviceManagerProtocol: string
    selectedDeviceManagerName: string
    deviceManagerName: string
    deviceRedirectUrl: string
    isBanyanIDP: boolean
    isLoading: boolean
    configureError: string
    deviceManagerConfig: IDPConfig
    isScimEnabled: boolean
    enableScimToggle: boolean
}
