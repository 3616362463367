import React, { useMemo } from "react"

import { useServiceLocalization } from "../../../../../../../pre-v3/services"
import {
    LargeCheckboxGroup,
    LargeCheckboxGroupOption,
} from "../../../../../../components/large-checkbox-group/LargeCheckboxGroup.component"
import { FormColumn } from "../../../../../../components/form/FormColumn.component"
import {
    Platform,
    allPlatforms,
    noPlatforms,
    platformIconDict,
    platformLabelDict,
} from "../../../../../../services/shared/Platform"

interface Props {
    appliedPlatform?: Record<Platform, boolean>
    onAppliedOsChange?(appliedPlatform: Record<Platform, boolean>): void
    disabled?: boolean
}

export function OperatingSystemsInput(props: Props): JSX.Element {
    const localization = useServiceLocalization()

    const { appliedPlatform, onAppliedOsChange, disabled } = props

    const selectedPlatforms = React.useMemo(
        () => allPlatforms.filter((platform) => appliedPlatform?.[platform]),
        [appliedPlatform]
    )

    const onChange = React.useCallback(
        (platforms: Platform[]): void => {
            onAppliedOsChange?.(platforms.reduce(reducePlatforms, noPlatforms))
        },
        [onAppliedOsChange]
    )

    const platformOptions: LargeCheckboxGroupOption[] = useMemo(() => {
        return allPlatforms.map((platform: Platform) => {
            return {
                id: platform,
                label: localization.getString(platformLabelDict[platform]),
                icon: platformIconDict[platform],
            }
        })
    }, [])

    return (
        <FormColumn label={localization.getString("selectOneOrMorePlatforms")}>
            <LargeCheckboxGroup
                options={platformOptions}
                value={selectedPlatforms}
                onChange={onChange}
                disabled={disabled}
            />
        </FormColumn>
    )
}

function reducePlatforms(
    appliedPlatform: Record<Platform, boolean>,
    platform: Platform
): Record<Platform, boolean> {
    return { ...appliedPlatform, [platform]: true }
}
