import { BaseV2Api } from "./BaseV2.api"
import { Singleton } from "../decorators/Singleton.decorator"

@Singleton("TrustScoreApi")
export class TrustScoreApi extends BaseV2Api {
    public addExclusions(...exclusions: TrustScoreExclusion[]): Promise<TrustScoreExclusion> {
        return this.post("/device_trustscore_exclusion", { exclusions })
    }

    public getExclusions(): Promise<{ exclusions: TrustScoreExclusion[] }> {
        return this.get("/device_trustscore_exclusion")
    }

    public deleteExclusions(...exclusions: TrustScoreExclusion[]): Promise<void> {
        return this.delete("/device_trustscore_exclusion", { exclusions })
    }
}

export type TrustScoreExclusion = {
    factor_name: string
    exclusion_type: "platform" | "ownership"
    exclusion_value: string
}

export type TrustScoreExclusionRes = {
    exclusions: TrustScoreExclusion[]
}

// TODO: Replace with TRUST_FACTOR_NAME in src/v3/services/TrustProfile.service.ts
export enum TrustFactorName {
    AUTO_UPDATE = "AutoUpdateEnabled",
    DISK_ENCRYPTION = "DiskEncryptionEnabled",
    FIREWALL = "FirewallEnabled",
    PREFERRED_APPS = "OrgPreferredAppsRunning",
    OS_UPDATES = "UpToDateOS",
    SCREEN_LOCK = "ScreenLockEnabled",
    NOT_JAILBROKEN = "NotJailbroken",
}
