import React from "react"

import { useServiceLocalization } from "../../../../pre-v3/services"
import { StringUtil } from "../../../../pre-v3/utils/String.util"
import { ROUTE } from "../../../../routes"
import {
    NonConfigurableTrustFactor,
    TrustFactor,
    isFileCheckTrustFactor,
    isPlistTrustFactor,
    isRegistryCheckTrustFactor,
} from "../../../services/TrustFactor.service"
import { Platform, getListOfPlatforms } from "../../../services/shared/Platform"
import {
    AvailablePlatforms,
    availablePlatformsClassName,
} from "../../../components/grid/AvailablePlatforms.component"
import { Column, Grid } from "../../../components/grid/Grid.component"
import { RowTitle, rowTitleClassName } from "../../../components/grid/RowTitle.component"
import { SearchInput } from "../../../components/search-input/SearchInput.component"
import styles from "./TrustFactorList.module.scss"
import { PageHeading } from "../../../../components/page-heading/PageHeading.component"
import {
    Button,
    ButtonElement,
    ButtonType,
    IconType,
} from "../../../../components/button/Button.component"
import trustStyles from "../../../../pages/trust/Trust.module.scss"
import { Tooltip } from "../../../components/tooltip/Tooltip.component"

interface Props {
    trustFactors?: TrustFactor[]
    onRefetchData(): Promise<TrustFactor[] | undefined>
}

export function TrustFactorList(props: Props): JSX.Element {
    const localization = useServiceLocalization()
    const searchLabel = localization.getString("searchByName")

    const [searchTerm, setSearchTerm] = React.useState("")

    const columns = useColumns()

    const filteredTrustFactors = React.useMemo(
        (): TrustFactor[] | undefined =>
            searchTerm
                ? props.trustFactors?.filter((trustFactor) =>
                      StringUtil.caseInsensitiveIncludes(trustFactor.name, searchTerm)
                  )
                : props.trustFactors,
        [props.trustFactors, searchTerm]
    )

    return (
        <section aria-labelledby={Id.HEADING} className={trustStyles.section}>
            <header className={trustStyles.header}>
                <PageHeading id={Id.HEADING}>{localization.getString("factors")}</PageHeading>
                <Tooltip title={localization.getString("refresh")}>
                    <Button
                        icon={IconType.REDO}
                        onClick={() => props.onRefetchData()}
                        asElement={ButtonElement.BUTTON}
                        buttonType={ButtonType.SECONDARY}
                        aria-label={localization.getString("refresh")}
                    />
                </Tooltip>
            </header>
            <SearchInput
                value={searchTerm}
                onChangeValue={setSearchTerm}
                placeholder={searchLabel}
                aria-label={searchLabel}
                className={styles.searchInput}
            />
            <Grid
                columns={columns}
                data={filteredTrustFactors as NonConfigurableTrustFactor[]}
                getId={getId}
                className={styles.grid}
            />
        </section>
    )
}

function getId(trustFactor: TrustFactor): string {
    if (isFileCheckTrustFactor(trustFactor)) return trustFactor.type
    if (isPlistTrustFactor(trustFactor)) return trustFactor.type
    if (isRegistryCheckTrustFactor(trustFactor)) return trustFactor.type

    return trustFactor.id
}

function useColumns(): Column<TrustFactor>[] {
    const localization = useServiceLocalization()
    const applicableOsLabel = localization.getString("applicablePlatform")
    const nameLabel = localization.getString("trustFactors")
    const sourceLabel = localization.getString("source")

    return React.useMemo(
        (): Column<TrustFactor>[] => [
            {
                id: "name",
                name: nameLabel,
                cellRenderer: (trustFactor) => (
                    <RowTitle
                        title={trustFactor.name}
                        description={trustFactor.description}
                        route={getRoute(trustFactor)}
                    />
                ),
                cellClassName: rowTitleClassName,
                getTooltipValue: "name",
            },
            {
                id: "source",
                name: sourceLabel,
                cellRenderer: "source",
                getTooltipValue: "source",
            },
            {
                id: "applicablePlatform",
                name: applicableOsLabel,
                cellRenderer: (trustFactor) => (
                    <AvailablePlatforms
                        isMacOsAvailable={trustFactor.applicablePlatform[Platform.MACOS]}
                        isWindowsAvailable={trustFactor.applicablePlatform[Platform.WINDOWS]}
                        isLinuxAvailable={trustFactor.applicablePlatform[Platform.LINUX]}
                        isIosAvailable={trustFactor.applicablePlatform[Platform.IOS]}
                        isAndroidAvailable={trustFactor.applicablePlatform[Platform.ANDROID]}
                        isChromeBrowserAvailable={trustFactor.applicablePlatform[Platform.CHROME]}
                    />
                ),
                cellClassName: availablePlatformsClassName,
                getTooltipValue: (trustFactor) =>
                    getListOfPlatforms(trustFactor.applicablePlatform, localization),
            },
        ],
        []
    )
}

function getRoute(trustFactor: TrustFactor): ROUTE | undefined {
    if (isPlistTrustFactor(trustFactor)) return ROUTE.FACTORS_PLIST
    if (isFileCheckTrustFactor(trustFactor)) return ROUTE.FACTORS_FILE_CHECK
    if (isRegistryCheckTrustFactor(trustFactor)) return ROUTE.FACTORS_REGISTRY_CHECK
}

enum Id {
    HEADING = "heading",
}
