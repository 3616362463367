import React from "react"
import styles from "./ClusterStatusCellRenderer.module.scss"
import classNames from "classnames/bind"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faCheckCircle, faPauseCircle } from "@fortawesome/pro-solid-svg-icons"
import { ClusterStatus } from "../../../../services/Infra.service"

export default function () {
    return (
        <div className={styles.container}>
            <div
                className={classNames(styles.statusIcon, {
                    [styles.statusIconReporting]:
                        this.props.data.status === ClusterStatus.REPORTING,
                })}
            >
                <FontAwesomeIcon
                    icon={
                        this.props.data.status === ClusterStatus.REPORTING
                            ? faCheckCircle
                            : faPauseCircle
                    }
                />
            </div>
            <div className={styles.statusLabel}>
                {this.props.data.status === ClusterStatus.REPORTING
                    ? this.localizationService.getString("reporting")
                    : this.localizationService.getString("inactive")}
            </div>
        </div>
    )
}
