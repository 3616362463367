import { faPlus } from "@fortawesome/pro-regular-svg-icons"
import React, { useEffect, useRef, useState } from "react"

import { HiddenInput } from "../../../components/input/HiddenInput.component"
import { LocalizationService, useServiceLocalization } from "../../../pre-v3/services"
import { CollectionUtil } from "../../../pre-v3/utils/Collection.util"
import { UrlUtil } from "../../../pre-v3/utils/Url.util"
import { MenuButton } from "../../../v3/components/menu/menu-button/MenuButton.component"
import { MenuItemProps } from "../../../v3/components/menu/menu-item/MenuItem.component"
import styles from "./ServiceTunnelNetworkSelect.module.scss"
import { FormRow } from "../../../components/form/FormRow.component"
import { Network } from "../../../v3/services/ServiceTunnelV2.service"

function hasGlobalEdge(network: Network[]): boolean {
    return network.some((i) => i.type === "connector")
}

function filterAndSort(
    network: Network[],
    localization: LocalizationService
): {
    accessTiers: Network[]
    connectors: Network[]
    accessTierGroups: Network[]
} {
    const sorted: Network[] = CollectionUtil.caseInsensitiveSort(network, "name", localization)

    return {
        // @cspell:ignore accesstier
        accessTiers: sorted.filter((a) => a.type === "accesstier"),
        accessTierGroups: sorted.filter((a) => a.type === "accessTierGroup"),
        connectors: sorted.filter((a) => a.type === "connector"),
    }
}

export function ServiceTunnelNetworkSelect(props: ServiceTunnelInfraSelectProps) {
    const { disabled } = props

    const localization = useServiceLocalization()

    const hiddenInput = useRef<HTMLInputElement>(null)
    const collisions = useRef<Set<string>>(new Set())

    const [menuItems, setMenuItems] = useState<MenuItemProps[]>([])

    function updateData(newSelected: Network[]): void {
        const selectedIds: string[] = newSelected.map((i) => i.id)
        const available: Network[] =
            newSelected.length > 0
                ? props.networks.filter((i) => !selectedIds.includes(i.id))
                : props.networks
        const { accessTiers, connectors, accessTierGroups } = filterAndSort(available, localization)
        const items = mapNetworksToMenuItems(
            accessTiers,
            connectors,
            accessTierGroups,
            props.hideAccessTier
        )
        setMenuItems(items)
        setValidity(newSelected)
    }

    function onSelect(item: Network): void {
        props.setSelected((prev: Network[]) => {
            const newSelected: Network[] = [...prev, item]
            updateData(newSelected)
            props.onChange?.(newSelected)
            return newSelected
        })
    }

    function mapNetworksToMenuItems(
        accessTiers: Network[],
        connectors: Network[],
        accessTierGroups: Network[],
        hideAccessTierMenu: boolean
    ): MenuItemProps[] {
        const items: MenuItemProps[] = []
        if (accessTiers.length > 0 && !hideAccessTierMenu) {
            items.push({
                label: localization.getString("accessTiers"),
                type: "divider",
            })
            items.push(
                ...accessTiers.map((at) => {
                    return {
                        label: at.name,
                        onClick: () => onSelect(at),
                    }
                })
            )
        }

        if (accessTierGroups.length > 0) {
            items.push({
                label: localization.getString("accessTierGroups"),
                type: "divider",
            })
            items.push(
                ...accessTierGroups.map((atg) => {
                    return {
                        label: atg.name,
                        onClick: () => onSelect(atg),
                    }
                })
            )
        }

        if (connectors.length > 0) {
            items.push({
                label: localization.getString("connectors"),
                type: "divider",
            })
            items.push(
                ...connectors.map((con) => {
                    return {
                        label: con.name,
                        onClick: () => onSelect(con),
                    }
                })
            )
        }

        return items
    }

    function setValidity(selectedNetwork: Network[]): void {
        const cidrs: string[] = selectedNetwork.flatMap((network) => network.privateIpRanges || [])
        const collide: Set<string> = UrlUtil.getCidrCollisions(cidrs)
        collisions.current = collide

        if (collide.size > 0) {
            hiddenInput.current?.setCustomValidity(
                localization.getString(
                    "oneOrMoreOfTheSelectedPrivateCIDRsCollideRemoveANetworkToContinue"
                )
            )
        } else {
            hiddenInput.current?.setCustomValidity("")
        }
    }

    useEffect(() => {
        updateData(props.selected || [])
    }, [props.selected, props.networks])

    return (
        <>
            <FormRow
                label={
                    props.hideLabel
                        ? ""
                        : localization.getString("whatNetworksShouldBeAccessibleViaThisTunnel")
                }
                description={
                    !props.hideLabel && localization.getString("selectANetworkDescription")
                }
                childrenClassName={styles.formRowChildren}
            >
                {!disabled && (
                    <>
                        <MenuButton
                            className={styles.button}
                            icon={faPlus}
                            label={localization.getString("addNetwork")}
                            menuItems={menuItems}
                        />
                        <HiddenInput type="text" className={styles.hiddenInput} ref={hiddenInput} />
                    </>
                )}
            </FormRow>
        </>
    )
}

interface ServiceTunnelInfraSelectProps {
    networks: Network[]
    initialValue?: Network[]
    onChange?: (infra: Network[]) => void
    disabled?: boolean
    hideLabel?: boolean
    selected: Network[]
    setSelected: React.Dispatch<React.SetStateAction<Network[]>>
    hideAccessTier: boolean
}
