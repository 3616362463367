import React, { ReactNode } from "react"
import styles from "./FormGroup.module.scss"
import classNames from "classnames/bind"
import { IconDefinition } from "@fortawesome/fontawesome-common-types"
import { Icon } from "../Icon/Icon.component"

export function FormGroup(props: FormGroupProps) {
    return (
        <div className={classNames(styles.outside, props.className)}>
            <div className={styles.label}>
                {props.icon && <Icon className={styles.icon} icon={props.icon} small />}
                {props.label}
            </div>
            <div className={styles.inside}>{props.children}</div>
        </div>
    )
}

interface FormGroupProps {
    label: string
    icon?: IconDefinition
    children: ReactNode
    className?: string
}
