import React from "react"
import { Redirect, Route, Switch, useLocation } from "react-router-dom"

import {
    NavigationItemProps,
    TopNavigationBar,
} from "../../components/top-navigation-bar/TopNavigationBar.component"
import { useFeatureFlags } from "../../hooks/useFeatureFlags.hook"
import useTitle from "../../hooks/useTitle.hook"
import { LanguageKey } from "../../pre-v3/services/localization/languages/en-US.language"
import { useServiceLocalization } from "../../pre-v3/services/localization/Localization.service"
import { doesRouteMatch, ROUTE } from "../../routes"
import { ErrorBanner } from "../../v3/components/banner/Banner.component"
import { Loader } from "../../v3/components/loader/Loader.component"
import { BanyanClient } from "./BanyanClient"
import { Certificates } from "./Certificates"
import { Configuration } from "./Configuration"
import { IdentityAndAccess } from "./IdentityAndAccess"

export function Settings(): JSX.Element {
    useTitle(["settings", "adminConsole"])

    const localization = useServiceLocalization()
    const location = useLocation()

    const featureFlagsQuery = useFeatureFlags()

    const getNavigationItemProps = (route: SecondLevelRoute): NavigationItemProps => ({
        key: route,
        label: localization.getString(secondLevelLabels[route]),
        to: route,
        active: doesRouteMatch<SecondLevelRoute>(route, location),
    })

    switch (featureFlagsQuery.status) {
        case "loading":
            return <Loader center medium />

        case "error":
            return <ErrorBanner>{String(featureFlagsQuery.error)}</ErrorBanner>

        case "success":
            return (
                <React.Fragment>
                    <header>
                        <TopNavigationBar
                            headingTitle={localization.getString("settings")}
                            navigationItems={secondLevelRoutes}
                            getNavigationItemProps={getNavigationItemProps}
                        />
                    </header>
                    <Switch>
                        <Route path={ROUTE.IDENTITY_AND_ACCESS} component={IdentityAndAccess} />
                        <Route path={ROUTE.SONICWALL_CSE_CLIENT} component={BanyanClient} />
                        <Route path={ROUTE.CONFIGURATION} component={Configuration} />
                        <Route path={ROUTE.CERTIFICATES} component={Certificates} />
                        <Redirect to={ROUTE.IDENTITY_AND_ACCESS} />
                    </Switch>
                </React.Fragment>
            )
    }
}

const secondLevelRoutes = [
    ROUTE.IDENTITY_AND_ACCESS,
    ROUTE.SONICWALL_CSE_CLIENT,
    ROUTE.CONFIGURATION,
    ROUTE.CERTIFICATES,
] satisfies ROUTE[]

type SecondLevelRoute = (typeof secondLevelRoutes)[number]

const secondLevelLabels: Record<SecondLevelRoute, LanguageKey> = {
    [ROUTE.IDENTITY_AND_ACCESS]: "identityAndAccess",
    [ROUTE.SONICWALL_CSE_CLIENT]: "sonicWallCseClient",
    [ROUTE.CONFIGURATION]: "configuration",
    [ROUTE.CERTIFICATES]: "certificates",
}
