import React, { useState } from "react"

import { useConfigureSmartSearch } from "../../context/SmartSearch.context"
import { ROUTE, formatRoutePath } from "../../routes"
import {
    Result,
    SmartSearchBar as SmartSearchBarComponent,
    SmartSearchBarApi,
} from "../smart-search-bar/SmartSearchBar.component"
import {
    Feedback,
    mapAnswerResToAnswerDetail,
    useGetRecommendations,
    useProvideFeedback,
} from "../../v3/services/SmartSearch.service"

import { useServiceLocalization } from "../../pre-v3/services/localization/Localization.service"
import { encodeID } from "../../pre-v3/utils/Url.util"
import { useAskQuestion } from "../../v3/api/LlmQuery.api"
import { EventSourceMessage } from "@microsoft/fetch-event-source"

interface Props {
    organizationName: string
    className?: string
}

export function SmartSearchBar(props: Props): JSX.Element {
    const localization = useServiceLocalization()

    const [streamedAnswer, setStreamAnswer] = useState<string>("")

    const handleMessage = (msg: EventSourceMessage) => {
        if (msg?.event === "progress") {
            setStreamAnswer((prev) => prev.concat(msg.data))
        }
    }

    const currentPageUrl = window.location.href
    const { data: recommendations } = useGetRecommendations(currentPageUrl)
    const { mutateAsync: askQuestion, data: completedAnswer } = useAskQuestion(
        currentPageUrl,
        props.organizationName,
        handleMessage
    )
    const { mutate: provideFeedback } = useProvideFeedback()

    const smartSearchBarApiRef = React.useRef<SmartSearchBarApi>(null)
    useConfigureSmartSearch(smartSearchBarApiRef.current?.addCannedPrompt)

    const onSearch = async (prompt: string): Promise<Result> => {
        handleClearStreamedAnswer()
        try {
            const answer = await askQuestion(prompt)

            if (answer.response === "NO_ANSWER" && !answer.has_response) {
                return { hasResult: false }
            }

            const mappedAnswer = mapAnswerResToAnswerDetail(answer)

            if (!mappedAnswer) return { hasResult: false }

            return {
                hasResult: true,
                result: mappedAnswer.response,
                link: formatRoutePath(
                    ROUTE.GET_HELP_SMART_SEARCH_RESULT,
                    {},
                    { answer: encodeID(mappedAnswer.id) }
                ),
            }
        } catch (error) {
            return { hasResult: false }
        }
    }

    const onFeedback = (wasHelpful: boolean) =>
        completedAnswer &&
        provideFeedback([
            mapAnswerResToAnswerDetail(completedAnswer),
            wasHelpful ? Feedback.HELPFUL : Feedback.NOT_HELPFUL,
        ])

    const handleClearStreamedAnswer = () => {
        setStreamAnswer("")
    }

    React.useEffect(() => {
        smartSearchBarApiRef.current?.clear()
    }, [currentPageUrl])

    return (
        <SmartSearchBarComponent
            streamedAnswer={streamedAnswer}
            onClearStreamedAnswer={handleClearStreamedAnswer}
            recommendations={recommendations ?? "loading"}
            onSearch={onSearch}
            onFeedback={onFeedback}
            className={props.className}
            placeholder={localization.getString("aiAssistedSmartSearch")}
            ref={smartSearchBarApiRef}
        />
    )
}
