import { BaseApi } from "./Base.api"

export class ScimProvisioningApi extends BaseApi {
    public toggleScimProvisioning(isScimEnabled: boolean): Promise<void> {
        return this.post(
            "/api/v2/scim/provision",
            {
                is_enabled: isScimEnabled,
            },
            {
                default: this.localization.getString("failedToUpdateTheScimProvisioningStatus"),
            }
        )
    }

    public getScimCredentials(): Promise<ScimCredentialsRes> {
        return this.get("/api/v2/scim/credentials", {
            default: this.localization.getString("failedToGetScimCredentials"),
        })
    }

    public generateScimCredentials(): Promise<ScimCredentialRes> {
        return this.post(
            "/api/v2/scim/credentials",
            {},
            {
                default: this.localization.getString("failedToGenerateScimCredentials"),
            }
        )
    }

    public deleteScimToken(tokenId: string): Promise<void> {
        return this.delete(
            `api/v2/scim/token/${tokenId}`,
            {},
            {
                default: this.localization.getString("failedToDeleteScimToken"),
            }
        )
    }
}

export interface ScimCredentialsRes {
    base_url: string
    tokens: TokenRes[]
}

export interface TokenRes {
    uuid: string
    created_at: number
}

export interface ScimCredentialRes {
    base_url: string
    token: string
}
