import { faExclamation } from "@fortawesome/pro-solid-svg-icons"
import React from "react"
import { useHistory, useParams } from "react-router-dom"

import { LanguageKey } from "../../../../pre-v3/services/localization/languages/en-US.language"
import { useServiceLocalization } from "../../../../pre-v3/services/localization/Localization.service"
import { decodeID } from "../../../../pre-v3/utils/Url.util"
import {
    Device,
    StatusType,
    useGetDeviceBySerialNumber,
    useRemoveDevice,
} from "../../../services/Device.service"
import { Platform, desktopPlatforms } from "../../../services/shared/Platform"
import { Container } from "../../../components/container/Container.component"
import { Loader } from "../../../components/loader/Loader.component"
import { LargeMessage } from "../../../components/large-message/LargeMessage.component"
import { TabBar, TabProps } from "../../../components/tab-bar/TabBar.component"
import styles from "./DeviceOverview.module.scss"
import { DeviceOverviewHeader } from "./DeviceOverviewHeader.component"
import { DeviceTrustFactors } from "./DeviceTrustFactors.component"
import { DeviceInformation, Api as DeviceInformationApi } from "./DeviceInformation.component"
import { RemoteDiagnostics } from "./RemoteDiagnostics.component"
import { PageHeading } from "../../../../components/page-heading/PageHeading.component"
import {
    ButtonElement,
    ButtonType,
    IconType,
    Button,
} from "../../../../components/button/Button.component"
import { useServiceModal } from "../../../../pre-v3/services"
import { MessageContent } from "../../../../pre-v3/components"
import { DeleteCancelActions } from "../../../../pre-v3/components/modal/delete-cancel/DeleteCancelActions"
import { ROUTE } from "../../../../routes"
import { ErrorBanner } from "../../../components/banner/Banner.component"
import { Tooltip } from "../../../components/tooltip/Tooltip.component"
import { DeviceAccessActivity, Api as DeviceAccessActivityApi } from "./AccessActivity.component"

interface Props {
    canWriteAll: boolean
}

export function DeviceOverview(props: Props): JSX.Element {
    const params = useParams<{ serialNumber: string }>()
    const localization = useServiceLocalization()
    const modalService = useServiceModal()
    const history = useHistory()
    const getDeviceQuery = useGetDeviceBySerialNumber(decodeID(params.serialNumber))

    const deviceContentRef = React.useRef<ContentApi>(null)

    const [tab, setTab] = React.useState(TabKey.TRUST_FACTORS)
    const [isInEditMode, setIsInEditMode] = React.useState(false)

    const getTabProps = useGetTabProps()

    const onEditDevice = () => {
        setTab(TabKey.DEVICE_INFORMATION)
        setIsInEditMode(true)
    }

    const onStopEditDevice = () => setIsInEditMode(false)

    const removeDevice = useRemoveDevice(getDeviceQuery.data as Device)

    const deviceName = getDeviceQuery.data?.name || ""

    function onDeleteDevice() {
        modalService
            .open(
                localization.getString("removeSomething", deviceName),
                {
                    component: MessageContent,
                    props: {
                        text: localization.getString("areYouSureYouWantToRemoveThisDevice"),
                    },
                },
                {
                    component: DeleteCancelActions,
                    props: {
                        okString: localization.getString(
                            "removeSomething",
                            localization.getString("device")
                        ),
                    },
                }
            )
            .onClose(async () => {
                await removeDevice.mutateAsync()
                history.push(ROUTE.DEVICES)
            })
    }

    function onRefresh() {
        getDeviceQuery.refetch()
        deviceContentRef.current?.refresh()
    }

    const tabList = getDeviceQuery.data
        ? canViewRemoteDiagnostics(getDeviceQuery.data)
            ? allTabs
            : tabsExceptRemoteDiagnostics
        : []

    const tabs = tabList.map(getTabProps)

    return (
        <Loader isLoading={getDeviceQuery.isLoading} center medium>
            {getDeviceQuery.data ? (
                <Container as="section" aria-labelledby={Id.HEADING} className={styles.container}>
                    {removeDevice.isError && (
                        <ErrorBanner>{String(removeDevice.error)}</ErrorBanner>
                    )}
                    <header className={styles.header}>
                        <PageHeading id={Id.HEADING}>{getDeviceQuery.data.name}</PageHeading>
                        <div className={styles.actions}>
                            {props.canWriteAll && (
                                <>
                                    <Tooltip title={localization.getString("editDevice")}>
                                        <Button
                                            icon={IconType.PEN}
                                            onClick={onEditDevice}
                                            asElement={ButtonElement.BUTTON}
                                            buttonType={ButtonType.SECONDARY}
                                            aria-label={localization.getString("editDevice")}
                                            disabled={isInEditMode}
                                        />
                                    </Tooltip>
                                    {getDeviceQuery.data.status.type !== StatusType.BANNED && (
                                        <Tooltip title={localization.getString("deleteDevice")}>
                                            <Button
                                                icon={IconType.TRASH}
                                                onClick={onDeleteDevice}
                                                asElement={ButtonElement.BUTTON}
                                                buttonType={ButtonType.SECONDARY}
                                                aria-label={localization.getString("deleteDevice")}
                                                disabled={removeDevice.isLoading}
                                            />
                                        </Tooltip>
                                    )}
                                </>
                            )}
                            <Tooltip title={localization.getString("refresh")}>
                                <Button
                                    icon={IconType.REDO}
                                    onClick={onRefresh}
                                    asElement={ButtonElement.BUTTON}
                                    buttonType={ButtonType.SECONDARY}
                                    aria-label={localization.getString("refresh")}
                                />
                            </Tooltip>
                        </div>
                    </header>
                    <DeviceOverviewHeader device={getDeviceQuery.data} />
                    <TabBar tabs={tabs} selectedTabId={tab} onChange={setTab} />
                    <Content
                        {...props}
                        tab={tab}
                        device={getDeviceQuery.data}
                        isInEditMode={isInEditMode}
                        onLeaveEditMode={onStopEditDevice}
                        ref={deviceContentRef}
                    />
                </Container>
            ) : (
                <LargeMessage icon={faExclamation}>{String(getDeviceQuery.error)}</LargeMessage>
            )}
        </Loader>
    )
}

enum Id {
    HEADING = "heading",
}

interface ContentApi {
    refresh(): void
}

interface ContentProps {
    tab: TabKey
    device: Device
    canWriteAll: boolean
    isInEditMode: boolean
    onLeaveEditMode(): void
}

const Content = React.forwardRef<ContentApi, ContentProps>((props, ref) => {
    const deviceAccessActivityRef = React.useRef<DeviceAccessActivityApi>(null)
    const deviceInformationRef = React.useRef<DeviceInformationApi>(null)

    React.useImperativeHandle(ref, () => ({
        refresh: () => {
            deviceAccessActivityRef.current?.refresh()
            deviceInformationRef.current?.refresh()
        },
    }))

    const tabPanelId = tabLabelDict[props.tab]

    switch (props.tab) {
        case TabKey.TRUST_FACTORS:
            return <DeviceTrustFactors {...props} tabPanelId={tabPanelId} />

        case TabKey.DEVICE_INFORMATION:
            return (
                <DeviceInformation {...props} tabPanelId={tabPanelId} ref={deviceInformationRef} />
            )

        case TabKey.ACCESS_ACTIVITY:
            return <DeviceAccessActivity {...props} ref={deviceAccessActivityRef} />

        case TabKey.REMOTE_DIAGNOSTICS:
            return canViewRemoteDiagnostics(props.device) ? (
                <RemoteDiagnostics {...props} tabPanelId={tabPanelId} />
            ) : (
                <React.Fragment />
            )
    }
})

Content.displayName = "Content"

enum TabKey {
    TRUST_FACTORS = "TRUST_FACTORS",
    DEVICE_INFORMATION = "DEVICE_INFORMATION",
    ACCESS_ACTIVITY = "ACCESS_ACTIVITY",
    REMOTE_DIAGNOSTICS = "REMOTE_DIAGNOSTICS",
}
const tabsExceptRemoteDiagnostics = [
    TabKey.TRUST_FACTORS,
    TabKey.DEVICE_INFORMATION,
    TabKey.ACCESS_ACTIVITY,
]
const allTabs = [...tabsExceptRemoteDiagnostics, TabKey.REMOTE_DIAGNOSTICS]

function useGetTabProps() {
    const localization = useServiceLocalization()

    return (tab: TabKey): TabProps<TabKey> => ({
        id: tab,
        label: localization.getString(tabLabelDict[tab]),
        ariaControls: tabLabelDict[tab],
    })
}

const tabLabelDict: Record<TabKey, LanguageKey> = {
    [TabKey.TRUST_FACTORS]: "trustFactors",
    [TabKey.DEVICE_INFORMATION]: "deviceInformation",
    [TabKey.ACCESS_ACTIVITY]: "accessActivity",
    [TabKey.REMOTE_DIAGNOSTICS]: "remoteDiagnostics",
}

function canViewRemoteDiagnostics(device: Device): boolean {
    return (desktopPlatforms as Platform[]).includes(device.platform)
}
