import { faTimes } from "@fortawesome/pro-light-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import React, { SyntheticEvent } from "react"

import { ButtonBase } from "../button/ButtonBase.component"
import styles from "./Pill.module.scss"
import classNames from "classnames/bind"

export function Pill(props: PillProps) {
    function onClick(): void {
        if (!props.disabled && props.onClick) {
            props.onClick(props.id)
        }
    }

    function onDelete(event: SyntheticEvent): void {
        event.stopPropagation()

        if (!props.disabled && props.onDelete) {
            props.onDelete(props.id)
        }
    }

    const isClickable = !!props.onClick

    return (
        <ButtonBase
            as="div"
            brand={props.brand}
            className={classNames(styles.pill, props.className, {
                [styles.error]: props.error,
                [styles.disabled]: props.disabled,
                [styles.notClickable]: !isClickable,
            })}
        >
            <button
                onClick={onClick}
                aria-label={props.buttonAriaLabel}
                disabled={props.disabled}
                className={styles.button}
                type="button"
            >
                {props.label}
            </button>
            {!props.disabled && (
                <button
                    type="button"
                    onClick={onDelete}
                    aria-label={props.iconAriaLabel}
                    disabled={props.disabled}
                    className={styles.button}
                >
                    <FontAwesomeIcon icon={faTimes} />
                </button>
            )}
        </ButtonBase>
    )
}

export interface PillProps {
    onClick?: (id: string) => void
    onDelete?: (id: string) => void
    label: string
    id: string
    brand?: boolean
    error?: boolean
    disabled?: boolean
    buttonAriaLabel?: string
    iconAriaLabel?: string
    className?: string
}
