import * as React from "react"

import sonicWallCseLogo from "../../../images/logo-horiz-black.svg"
import { ROUTE } from "../../../routes"
import { Background } from "../../../v3/components/background/Background.component"
import styles from "./OIDC.module.scss"
import { Banner, BannerType } from "../../components/banner/Banner.component"
import { faExclamationCircle } from "@fortawesome/pro-solid-svg-icons"
import { Link } from "react-router-dom"
import { RouteComponentProps } from "react-router"
import { SignInReq } from "../../api/OIDC.api"
import { UrlUtil } from "../../utils/Url.util"
import { useServiceLocalization, useServiceOIDC } from "../../services"
import { LoadMask, Input } from "../../components"
import { Button, ButtonElement, ButtonType } from "../../../components/button/Button.component"

export function OIDCLogin({ location, history }: RouteComponentProps) {
    const localization = useServiceLocalization()
    const oidcService = useServiceOIDC()

    const [loading, setLoading] = React.useState<boolean>(true)
    const [orgName, setOrgName] = React.useState<string>("")
    const [email, setEmail] = React.useState<string>("")
    const [password, setPassword] = React.useState<string>("")
    const [hasError, setHasError] = React.useState<boolean>(false)

    const form = React.useRef(null)

    React.useEffect(() => {
        const existingOrgName: string = oidcService.getOrgName()
        const newOrgName: string = existingOrgName ? existingOrgName : UrlUtil.getOrgNameFromUrl()
        setOrgName(newOrgName)

        const query = new URLSearchParams(location.search)
        const queryState: string = query.get("state") || ""

        oidcService.setOrgName(newOrgName)
        oidcService.setQueryState(queryState || "")

        if (queryState) {
            oidcService
                .authorize(newOrgName, queryState)
                .then((redirectUri: string) => {
                    if (redirectUri) {
                        oidcService.navigateTo(redirectUri, history)
                    }
                })
                .catch()
                .finally(() => setLoading(false))
        } else {
            setLoading(false)
        }
    }, [oidcService, location.search, history])

    function onSubmit(event: React.SyntheticEvent) {
        event.preventDefault()

        const query = new URLSearchParams(location.search)
        const queryState: string = query.get("state") || ""

        setLoading(true)
        setHasError(false)

        const payload: SignInReq = {
            username: email.toLowerCase(),
            password,
            orgName,
            state: queryState,
        }

        oidcService
            .signIn(payload)
            .then((redirectUri: string) => {
                if (redirectUri) {
                    oidcService.navigateTo(redirectUri, history)
                } else {
                    setHasError(true)
                }
            })
            .catch((e) => {
                setHasError(true)
            })
            .finally(() => {
                setLoading(false)
            })
    }
    return (
        <div className={styles.oidcLogin}>
            <Background className={styles.container}>
                <div className={styles.loginForm}>
                    <div className={styles.form}>
                        {loading && <LoadMask />}
                        <form onSubmit={onSubmit} ref={form} name="loginform">
                            <img
                                src={sonicWallCseLogo}
                                alt="sonicwall cse logo"
                                className={styles.logo}
                            />
                            <p className={styles.orgname}>
                                <strong>{orgName}</strong>
                            </p>
                            {hasError && (
                                <div className={styles.error}>
                                    {" "}
                                    <Banner
                                        className={styles.banner}
                                        type={BannerType.ERROR}
                                        icon={faExclamationCircle}
                                    >
                                        {localization.getString(
                                            "theUserNameOrPasswordYouHaveEnteredIsIncorrect"
                                        )}
                                    </Banner>
                                </div>
                            )}
                            <Input
                                autoFocus
                                name="username"
                                id="username"
                                type="email"
                                value={email}
                                onChange={(e) => setEmail(e.target.value)}
                                placeholder={localization.getString("email")}
                            />

                            <Input
                                type="password"
                                name="password"
                                id="password"
                                value={password}
                                onChange={(e) => setPassword(e?.target.value)}
                                placeholder={localization.getString("password")}
                            />

                            <Button
                                className={styles.submitButton}
                                asElement={ButtonElement.BUTTON}
                                buttonType={ButtonType.PRIMARY}
                                type="submit"
                            >
                                {localization.getString("login")}
                            </Button>
                            <br></br>
                            <div className={styles.message}>
                                <Link to={ROUTE.OIDC_FORGOT_PASSWORD}>
                                    {localization.getString("forgotYourPassword")}
                                </Link>
                            </div>
                        </form>
                    </div>
                </div>
            </Background>
        </div>
    )
}
