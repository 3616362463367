import { Singleton } from "../../pre-v3/decorators/Singleton.decorator"
import { BaseApi } from "./Base.api"

@Singleton("MultiFactorAuthenticationApi")
export class MultiFactorAuthenticationApi extends BaseApi {
    public resetMultiFactorAuthentication(body: ResetMultiFactorAuthenticationBody): Promise<void> {
        return this.post("/api/v2/mfa/reset", body, {
            default: this.localization.getString("failedToResetMultiFactorAuthentication"),
        })
    }
}

interface ResetMultiFactorAuthenticationBody {
    email: string
}
