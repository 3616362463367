import React, { ReactNode } from "react"
import { RouteComponentProps } from "react-router"

import { Profile } from "../../../../../../utils/profile.utils"
import { IdpType } from "../../../../../../v3/services/UserProfile.service"
import { LocalizationService } from "../../../../../services/localization/Localization.service"
import ActionBarService from "../../../../../services/ActionBar.service"
import { UserService } from "../../../../../services/User.service"
import { FormUtil } from "../../../../../utils/Form.util"
import { UrlUtil } from "../../../../../utils/Url.util"
import { Bind } from "../../../../../decorators/Bind.decorator"
import { UserApi, UserOrgDetails } from "../../../../../api/User.api"
import OrgAdminAddTemplate from "./OrgAdminAdd.template"
import { SelectItem } from "../../../../../utils/SelectValue.util"

export class OrgAdminAdd extends React.Component<RouteComponentProps, OrgAdminAddState> {
    public state: OrgAdminAddState = { profile: Profile.READONLY, isMspOrg: false }

    public render(): ReactNode {
        return OrgAdminAddTemplate.call(this)
    }

    public componentDidMount(): void {
        this.actionBarService.setItems(this.localizationService.getString("addAdmin"), [
            {
                label: this.localizationService.getString("adminSettings"),
            },
            {
                label: this.localizationService.getString("manageAdmins"),
                href: UrlUtil.getParentPath(this.props.location.pathname),
            },
        ])

        this.userService.getUserOrgDetails().then((orgDetails: UserOrgDetails) => {
            this.setState({ isMspOrg: orgDetails.IsMSPOrg })
            if (orgDetails.IDPType === IdpType.SAML) {
                this.setState({ warning: true })
            }
            if (orgDetails.Profile === Profile.OWNER) {
                this.profileOptions = this.profileOptions.concat(Profile.OWNER)
            }
        })
    }

    private localizationService: LocalizationService = new LocalizationService()
    private actionBarService: ActionBarService = new ActionBarService()
    private userService: UserService = new UserService()
    private userApi: UserApi = new UserApi()

    private profileOptions: string[] = [
        Profile.READONLY,
        Profile.ADMIN,
        Profile.POLICY_AUTHOR,
        Profile.SERVICE_AUTHOR,
    ]

    private mspProfileOptions: SelectItem[] = [
        {
            displayName: this.localizationService.getString("admin"),
            value: Profile.ADMIN,
        },
        {
            displayName: this.localizationService.getString("operator"),
            value: Profile.READONLY,
        },
    ]

    @Bind
    private onProfileChange(value: Profile): void {
        this.setState({ profile: value })
    }

    @Bind
    private onSubmit(event: React.FormEvent<HTMLFormElement>): void {
        event.preventDefault()
        this.setState({ success: "", error: "", submitting: true })

        this.userApi
            .addAdmin({
                First: FormUtil.getFieldValue(event, "firstName"),
                Last: FormUtil.getFieldValue(event, "lastName"),
                Email: FormUtil.getFieldValue(event, "email"),
                Profile: this.state.profile,
            })
            .then(
                () => {
                    this.setState({
                        submitting: false,
                        success: this.localizationService.getString("adminCreated"),
                    })
                    event.currentTarget.reset()
                },
                (err: Error) => {
                    this.setState({ submitting: false, error: err.message })
                }
            )
    }
}

interface OrgAdminAddState {
    submitting?: boolean
    success?: string
    error?: string
    warning?: boolean

    profile: Profile
    isMspOrg: boolean
}
