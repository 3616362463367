import { BaseApi, PaginatedResponse } from "./Base.api"
import { Singleton } from "../../pre-v3/decorators/Singleton.decorator"
import { OwnershipRes, PlatformRes } from "./Device.api"
import { UrlUtil } from "../../pre-v3/utils/Url.util"

@Singleton("RoleApi")
export class RoleApi extends BaseApi {
    public getRoles(searchParams?: RolesSearchParams): Promise<RoleRes[]> {
        const params = UrlUtil.convertToURLSearchParams({ RoleID: searchParams?.id })

        return this.get(`/api/v1/security_roles?${params.toString()}`, {
            default: this.localization.getString("errorCouldNotGetRoles"),
        })
    }

    public createRole(role: RoleSpecReq): Promise<RoleRes> {
        return this.post("/api/v1/insert_security_role", role, {
            default: this.localization.getString("errorCouldNotCreateRole"),
        })
    }

    public updateRole(role: RoleSpecReq): Promise<RoleRes> {
        return this.post("/api/v1/insert_security_role", role, {
            default: this.localization.getString("errorCouldNotUpdateRole"),
        })
    }

    public disableRole(id: string): Promise<void> {
        return this.post("/api/v1/disable_security_role?RoleID=" + id, undefined, {
            default: this.localization.getString("errorCouldNotDisableRole"),
        })
    }

    public deleteRole(id: string): Promise<void> {
        return this.delete("/api/v1/delete_security_role?RoleID=" + id, undefined, {
            default: this.localization.getString("errorCouldNotDeleteRole"),
        })
    }

    public getGroups(): Promise<PaginatedResponse<"names", string>> {
        return this.get("/api/v2/enduser_groups", {
            default: this.localization.getString("errorCouldNotGetGroups"),
        })
    }
}

export interface RoleRes {
    RoleID: string
    RoleName: string
    LastUpdatedAt: number
    RoleSpec: string // RoleSpecReq
    RoleVersion: number
    CreatedAt: number
    CreatedBy: string
    LastUpdatedBy: string
    Description: string
    DeviceCount: number
    PolicyCount: number
    Active: boolean
}

export interface RoleSpecReq {
    kind: string
    apiVersion: string
    type: string
    metadata: RoleMetadataReq
    spec: RoleAttr
}

export interface RoleMetadataReq {
    id?: string
    name: string
    description: string
    tags: StringMap
}

export interface RoleAttr {
    group?: string[]
    email?: string[]
    device_ownership?: OwnershipRes[]
    known_device_only?: boolean
    service_account?: string[]
    mdm_present?: boolean
    platform?: PlatformRes[]
    serial_numbers?: string[]
}

export interface RolesSearchParams {
    id?: string
}
