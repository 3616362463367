import React, { FormEvent, forwardRef, ReactNode, Ref, useCallback, useMemo } from "react"

import { Button, ButtonElement, ButtonType } from "../../../../components/button/Button.component"
import { useServiceLocalization } from "../../../../pre-v3/services/localization/Localization.service"
import { useServiceModal } from "../../../../pre-v3/services/Modal.service"
import { Accordion } from "../../../components/accordion/Accordion.component"
import { PillMultiSelect } from "../../../components/pill-multi-select/PillMultiSelect.component"
import { ErrorBanners } from "../../../components/banner/Banner.component"
import { Form } from "../../../components/form/Form.component"
import { FormButtons } from "../../../components/form/form-buttons/FormButtons.component"
import { AppFilter } from "../../../services/ItpPolicy.service"
import { AppDetailsModalWrapper } from "../../shared/AppModalWrapper"
import styles from "../ItpPolicy.module.scss"
import { Step, ariaControls } from "./ItpPolicyForm.component"

interface AppFilteringTabProps {
    apps: AppFilter[]
    value: AppFilteringFormValues
    onSubmit: () => void
    disabled?: boolean
    errors?: ReactNode[]
    onBack: () => void
    isEdit?: boolean
    onChange: (updatedValue: AppFilteringFormValues) => void
}

function AppFilteringTabComponent(props: AppFilteringTabProps, ref: Ref<HTMLFormElement>) {
    const { apps, value, disabled, errors, isEdit, onChange, onBack } = props
    const localization = useServiceLocalization()
    const modalService = useServiceModal()

    const allowAppValue = value.allowApplications.value
    const blockAppValue = value.blockApplications.value

    const appOptions = useMemo(() => {
        return (
            apps.map(({ label, value }) => {
                return {
                    label,
                    value,
                }
            }) || []
        )
    }, [apps])

    const allowAppsOptions = useMemo(() => {
        return appOptions.filter((app) => {
            return !blockAppValue.includes(app.value)
        })
    }, [appOptions, blockAppValue])

    const blockAppsOptions = useMemo(() => {
        return appOptions.filter((app) => {
            return !allowAppValue.includes(app.value)
        })
    }, [appOptions, allowAppValue])

    const onAllowAppsChange = useCallback(
        (updatedAllowedApps: string[]) => {
            onChange({
                ...value,
                allowApplications: {
                    ...value.allowApplications,
                    value: updatedAllowedApps,
                },
            })
        },
        [value]
    )

    const onBlockAppsChange = useCallback(
        (updatedBlockedApps: string[]) => {
            onChange({
                ...value,
                blockApplications: {
                    ...value.blockApplications,
                    value: updatedBlockedApps,
                },
            })
        },
        [value]
    )

    function onSubmit(event: FormEvent<HTMLFormElement>) {
        event.preventDefault()

        props.onSubmit()
    }

    function onSetEnabled(accordionKey: AccordionKey, updatedValue: boolean) {
        onChange({
            ...value,
            [accordionKey]: {
                ...value[accordionKey],
                enabled: updatedValue,
            },
        })
    }

    function openAppDetails(pill: string): void {
        modalService.open(localization.getString("about"), {
            component: AppDetailsModalWrapper,
            props: {
                appId: pill,
            },
            maxWidth: "md",
        })
    }

    return (
        <Form
            className={styles.tabContainer}
            onSubmit={onSubmit}
            id={ariaControls[Step.CONTENT_FILTERING]}
            ref={ref}
        >
            <Accordion
                open={value.blockApplications.enabled}
                disabled={disabled}
                type="switch"
                label={localization.getString("applicationFiltering")}
                tooltipInfo={localization.getString("enterSpecificAppsToBlockAccessTo")}
                onToggle={(enabled: boolean) => {
                    onSetEnabled(AccordionKey.BLOCK_APPLICATIONS, enabled)
                }}
                children={
                    <PillMultiSelect
                        label={localization.getString("whichAppsWouldYouLikeToBlockAccessTo")}
                        placeholder={localization.getString("selectAppsToFilter")}
                        options={blockAppsOptions}
                        value={blockAppValue}
                        onChange={onBlockAppsChange}
                        className={styles.categoryDropdown}
                        disabled={disabled}
                        required
                        onPillClick={(e) => {
                            openAppDetails(e)
                        }}
                    />
                }
            />
            <Accordion
                open={value.allowApplications.enabled}
                disabled={disabled}
                type="switch"
                label={localization.getString("applicationExceptions")}
                tooltipInfo={localization.getString("enterSpecificAppsThatBypassThisITPPolicy")}
                onToggle={(enabled: boolean) => {
                    onSetEnabled(AccordionKey.ALLOW_APPLICATIONS, enabled)
                }}
                children={
                    <PillMultiSelect
                        label={localization.getString("whichAppsShouldBypassThisITPPolicy")}
                        placeholder={localization.getString("selectAppsToFilter")}
                        options={allowAppsOptions}
                        value={allowAppValue}
                        onChange={onAllowAppsChange}
                        className={styles.categoryDropdown}
                        disabled={disabled}
                        required
                        onPillClick={(e) => {
                            openAppDetails(e)
                        }}
                    />
                }
            />
            {!disabled && (
                <FormButtons
                    leftButtons={
                        <Button
                            asElement={ButtonElement.BUTTON}
                            buttonType={ButtonType.SECONDARY}
                            type="button"
                            onClick={onBack}
                        >
                            {localization.getString(isEdit ? "cancel" : "back")}
                        </Button>
                    }
                    rightButtons={
                        <Button
                            asElement={ButtonElement.BUTTON}
                            buttonType={ButtonType.PRIMARY}
                            type="submit"
                        >
                            {localization.getString(isEdit ? "save" : "next")}
                        </Button>
                    }
                >
                    {errors && <ErrorBanners errors={errors} />}
                </FormButtons>
            )}
            <div className={styles.bottomPadding} />
        </Form>
    )
}

export const AppFilteringTab = forwardRef(AppFilteringTabComponent)

export interface AppFilteringFormValues {
    blockApplications: {
        enabled: boolean
        value: string[]
    }
    allowApplications: {
        enabled: boolean
        value: string[]
    }
}

enum AccordionKey {
    BLOCK_APPLICATIONS = "blockApplications",
    ALLOW_APPLICATIONS = "allowApplications",
}
