import React from "react"

import { IconType, useServiceLocalization } from "../../../../../pre-v3/services"
import { StringUtil } from "../../../../../pre-v3/utils/String.util"
import { encodeID } from "../../../../../pre-v3/utils/Url.util"
import { ROUTE, formatRoutePath } from "../../../../../routes"
import { File, FileCheckTrustFactor, TrustFactor } from "../../../../services/TrustFactor.service"
import { Platform, getListOfPlatforms } from "../../../../services/shared/Platform"
import {
    AvailablePlatforms,
    availablePlatformsClassName,
} from "../../../../components/grid/AvailablePlatforms.component"
import { Column, Grid } from "../../../../components/grid/Grid.component"
import { RowTitle, rowTitleClassName } from "../../../../components/grid/RowTitle.component"
import { SearchInput } from "../../../../components/search-input/SearchInput.component"
import styles from "./FileCheckList.module.scss"
import { PageHeading } from "../../../../../components/page-heading/PageHeading.component"
import {
    Button,
    ButtonElement,
    ButtonType,
} from "../../../../../components/button/Button.component"
import trustStyles from "../../../../../pages/trust/Trust.module.scss"
import { Tooltip } from "../../../../components/tooltip/Tooltip.component"

interface Props {
    fileCheckTrustFactor?: FileCheckTrustFactor
    isLoading: boolean
    onRefetchData(): Promise<TrustFactor[] | undefined>
    canAccessFileCheckFactor?: boolean
}

export function FileCheckList(props: Props): JSX.Element {
    const localization = useServiceLocalization()
    const searchLabel = localization.getString("searchByName")
    const addFileLabel = localization.getString("addFile")

    const [searchTerm, setSearchTerm] = React.useState("")

    const columns = useColumns()

    const filteredFileChecks = React.useMemo(
        () =>
            searchTerm
                ? props.fileCheckTrustFactor?.files.filter((trustFactor) =>
                      StringUtil.caseInsensitiveIncludes(trustFactor.name, searchTerm)
                  )
                : props.fileCheckTrustFactor?.files,
        [props.fileCheckTrustFactor, searchTerm]
    )

    return (
        <section aria-labelledby={Id.HEADING} className={trustStyles.section}>
            <header className={trustStyles.header}>
                <PageHeading id={Id.HEADING}>{localization.getString("fileCheck")}</PageHeading>
                <Tooltip title={localization.getString("refresh")}>
                    <Button
                        icon={IconType.REDO}
                        onClick={() => props.onRefetchData()}
                        asElement={ButtonElement.BUTTON}
                        buttonType={ButtonType.SECONDARY}
                        aria-label={localization.getString("refresh")}
                    />
                </Tooltip>
            </header>
            <div className={styles.toolbar}>
                <SearchInput
                    value={searchTerm}
                    onChangeValue={setSearchTerm}
                    placeholder={searchLabel}
                    aria-label={searchLabel}
                    className={styles.searchInput}
                />
                {props.canAccessFileCheckFactor && (
                    <Button
                        buttonType={ButtonType.PRIMARY}
                        asElement={ButtonElement.LINK}
                        to={ROUTE.FACTORS_FILE_CHECK_CREATE}
                        icon={IconType.PLUS}
                    >
                        {addFileLabel}
                    </Button>
                )}
            </div>
            <Grid
                columns={columns}
                data={props.isLoading ? undefined : filteredFileChecks}
                className={styles.grid}
            />
        </section>
    )
}

function useColumns(): Column<File>[] {
    const localization = useServiceLocalization()
    const appliedPlatform = localization.getString("appliedPlatform")
    const nameLabel = localization.getString("name")

    return React.useMemo(
        (): Column<File>[] => [
            {
                id: "name",
                name: nameLabel,
                cellRenderer: (fileCheck) => (
                    <RowTitle
                        title={fileCheck.name}
                        route={formatRoutePath(ROUTE.FACTORS_FILE_CHECK_DETAILS, {
                            id: encodeID(fileCheck.id),
                        })}
                    />
                ),
                cellClassName: rowTitleClassName,
                getTooltipValue: "name",
            },
            {
                id: "appliedPlatform",
                name: appliedPlatform,
                cellRenderer: (fileCheck) => (
                    <AvailablePlatforms
                        isMacOsAvailable={!!fileCheck.configurations[Platform.MACOS]}
                        isWindowsAvailable={!!fileCheck.configurations[Platform.WINDOWS]}
                        isLinuxAvailable={!!fileCheck.configurations[Platform.LINUX]}
                        isIosAvailable={false}
                        isAndroidAvailable={false}
                        isChromeBrowserAvailable={false}
                    />
                ),
                cellClassName: availablePlatformsClassName,
                getTooltipValue: (trustFactor) =>
                    getListOfPlatforms(
                        isPlatformAvailable(trustFactor.configurations),
                        localization
                    ),
            },
        ],
        []
    )
}

function isPlatformAvailable(configurations: File["configurations"]): Record<Platform, boolean> {
    return {
        [Platform.MACOS]: configurations[Platform.MACOS] !== null,
        [Platform.WINDOWS]: configurations[Platform.WINDOWS] !== null,
        [Platform.LINUX]: configurations[Platform.LINUX] !== null,
        [Platform.IOS]: false,
        [Platform.ANDROID]: false,
        [Platform.CHROME]: false,
    }
}

enum Id {
    HEADING = "heading",
}
