import classNames from "classnames/bind"
import React from "react"

import styles from "./Background.module.scss"

export interface Props {
    className?: string
    children?: React.ReactNode
}

/**
 * Display your content with a cool maze background
 */
export function Background(props: Props): JSX.Element {
    return <div className={classNames(styles.background, props.className)}>{props.children}</div>
}
