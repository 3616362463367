import { Singleton } from "../decorators/Singleton.decorator"
import { ClassType, ReactElement } from "react"
import React from "react"
import { OkCancelActions } from "../components/modal/ok-cancel/OkCancelActions.component"
import ServiceContext from "./context"
import { MessageContent } from "../components/modal/message/MessageContent"
import { DeleteCancelActions } from "../components/modal/delete-cancel/DeleteCancelActions"

export type ModalContent = ComponentWithProps & {
    maxWidth?: string
    withoutBody?: boolean
    fullWidth?: boolean
}

@Singleton("ModalService")
export class ModalService {
    public openMessage(title: string, message: string): ModalRef {
        return this.open(
            title,
            {
                component: MessageContent,
                props: { text: message },
            },
            {
                component: OkCancelActions,
                props: { hideCancel: true },
            }
        )
    }

    public openConfirmation(title: string, message: string): ModalRef {
        return this.open(
            title,
            {
                component: MessageContent,
                props: { text: message },
            },
            OkCancelActions
        )
    }

    public openDelete(title: string, message: string): ModalRef {
        return this.open(
            title,
            {
                component: MessageContent,
                props: { text: message },
            },
            DeleteCancelActions
        )
    }

    public open(
        title: string,
        content: ModalContent | ClassType<any, any, any>,
        actions?: ComponentWithProps | ClassType<any, any, any>
    ): ModalRef {
        if (!this.controllerOpenFn) {
            throw new Error("ModalService: Open function is not registered")
        }

        const modalId: number = this.id++

        const modal: ModalRef = {
            id: modalId,
            title: title,
            fullWidth: content.fullWidth,
            close: (result: any) => {
                this.close(modalId, result)
            },
            updateActions: (props: any) => {
                this.update(modalId, props)
            },
            updateContent: (props: any) => {
                this.updateContentProps(modalId, props)
            },
            cancel: (result: any) => {
                this.cancel(modalId, result)
            },
            onClose: this.generateOnCloseFn(modalId),
            onCancel: this.generateOnCancelFn(modalId),
        }
        if (content.component) {
            modal.content = React.createElement(content.component, {
                modalRef: modal,
                ...content.props,
            })
            modal.maxWidth = content.maxWidth || "sm"
            modal.withoutBody = content.withoutBody
        } else {
            modal.content = React.createElement(content, { modalRef: modal })
        }
        if (actions) {
            if (actions.component) {
                modal.actions = React.createElement(actions.component, {
                    modalRef: modal,
                    ...actions.props,
                })
            } else {
                modal.actions = React.createElement(actions, { modalRef: modal })
            }
        }

        this.modalMap[modalId] = modal

        this.controllerOpenFn(modal)
        return modal
    }

    public close(id: number, result?: any): void {
        if (this.controllerCloseFn) {
            this.controllerCloseFn(id)

            const cbs: Function[] = this.actionMap.close[id]
            if (cbs) {
                cbs.forEach((c) => c(result))
            }
        }

        delete this.actionMap.close[id]
        delete this.actionMap.cancel[id]
        delete this.modalMap[id]
    }

    public update(id: number, props: any): void {
        if (this.controllerUpdateFn && this.modalMap[id]) {
            const modal = this.modalMap[id]
            if (modal.actions) {
                modal.actions = React.cloneElement(modal.actions, {
                    ...modal.actions.props,
                    ...props,
                })
                this.controllerUpdateFn(modal)
            }
        }
    }

    public updateContentProps(id: number, props: any): void {
        if (this.controllerUpdateFn && this.modalMap[id]) {
            const modal = this.modalMap[id]
            if (modal.content) {
                modal.content = React.cloneElement(modal.content, {
                    ...modal.content.props,
                    ...props,
                })
                this.controllerUpdateFn(modal)
            }
        }
    }

    public cancel(id: number, result?: any): void {
        if (this.controllerCloseFn) {
            this.controllerCloseFn(id)

            const cbs: Function[] = this.actionMap.cancel[id]
            if (cbs) {
                cbs.forEach((c) => c(result))
            }
        }

        delete this.actionMap.close[id]
        delete this.actionMap.cancel[id]
        delete this.modalMap[id]
    }

    // these are the physical open/close functions
    public registerControllerOpenFn(cb: Function): void {
        this.controllerOpenFn = cb
    }

    public registerControllerUpdateFn(cb: (modal: ModalRef) => void): void {
        this.controllerUpdateFn = cb
    }

    public registerControllerCloseFn(cb: (id: number) => void): void {
        this.controllerCloseFn = cb
    }

    private controllerOpenFn: Function
    private controllerUpdateFn: (modal: ModalRef) => void
    private controllerCloseFn: (id: number) => void

    private id: number = 1

    // these are the callbacks
    private modalMap: { [key: number]: ModalRef } = {}
    private actionMap: {
        close: { [key: number]: Function[] }
        cancel: { [key: number]: Function[] }
    } = { close: {}, cancel: {} }

    private generateOnCloseFn(id: number): (cb: (result: any) => void) => ModalRef {
        return (cb: (result: any) => void) => {
            if (this.actionMap.close[id]) {
                this.actionMap.close[id].push(cb)
            } else {
                this.actionMap.close[id] = [cb]
            }
            return this.modalMap[id]
        }
    }

    private generateOnCancelFn(id: number): (cb: (result: any) => void) => ModalRef {
        return (cb: (result: any) => void) => {
            if (this.actionMap.cancel[id]) {
                this.actionMap.cancel[id].push(cb)
            } else {
                this.actionMap.cancel[id] = [cb]
            }
            return this.modalMap[id]
        }
    }
}

export interface ModalRef {
    id: number // private
    title: string // private
    content?: ReactElement // private
    maxWidth?: string // private
    withoutBody?: boolean // private
    actions?: ReactElement // private
    fullWidth?: boolean
    close: (result: any) => void // closes the modal w/ result
    updateActions: (props: any) => void // updates the props for the action component
    updateContent: (props: any) => void // updates the props for the content component
    cancel: (result: any) => void // cancels the modal w/ result
    onClose: (cb: (result: any) => void) => ModalRef // called when modal is closed
    onCancel: (cb: (result: any) => void) => ModalRef // called when modal is canceled
}

export interface ComponentWithProps {
    component: ClassType<any, any, any>
    props: any
}

export const useServiceModal = () => React.useContext(ServiceContext)?.modal || new ModalService()
