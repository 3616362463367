import React, { ReactNode } from "react"
import { RouteComponentProps } from "react-router"

import { ROUTE, formatRoutePath } from "../../../../routes"
import { LocalizationService } from "../../../services/localization/Localization.service"
import { Bind } from "../../../decorators/Bind.decorator"
import { ManageService, SaasManage } from "../../../services/Manage.service"
import { SettingsService, OidcConfig } from "../../../services/Settings.service"
import { LinkService } from "../../../services/link/Link.service"
import { SaasAuthProtocol } from "../../../api/Manage.api"
import { SaasAppUrlType } from "../../../services/Service.service"
import { decodeID, encodeID } from "../../../utils/Url.util"
import ServicesSaasConfigTemplate from "./ServicesSaasConfig.template"

export class ServicesSaasConfig extends React.Component<
    RouteComponentProps<ServicesSaasConfigRouteParams>,
    ServicesSaasConfigState
> {
    constructor(props: RouteComponentProps<ServicesSaasConfigRouteParams>) {
        super(props)

        try {
            this.id = decodeID(this.props.match.params.id)
        } catch {
            this.state.loading = false
        }
    }

    public state: ServicesSaasConfigState = { loading: true }

    public componentDidMount(): void {
        this.fetchData()
    }

    public render(): ReactNode {
        return ServicesSaasConfigTemplate.call(this)
    }

    private localizationService: LocalizationService = new LocalizationService()
    private linkService: LinkService = new LinkService()
    private manageService: ManageService = new ManageService()
    private settingsService: SettingsService = new SettingsService()

    private id: string

    @Bind
    private onContinue(): void {
        this.props.history.push(formatRoutePath(ROUTE.SAAS_APPS_DETAILS, { id: encodeID(this.id) }))
    }

    @Bind
    private fetchData(): void {
        if (this.id) {
            this.manageService.getSaasApp(this.id).then((app: SaasManage | undefined) => {
                if (app) {
                    this.setState({ loading: false, app: app })
                    if (app.protocol === SaasAuthProtocol.OIDC) {
                        this.settingsService.getOidcSettings().then((oidc: OidcConfig) => {
                            this.setState({ oidc })
                        })
                    }
                }
            })
        }
    }
}

interface ServicesSaasConfigRouteParams {
    id: string
    type: SaasAppUrlType
}

interface ServicesSaasConfigState {
    loading: boolean
    app?: SaasManage
    oidc?: OidcConfig
}
