import * as React from "react"
import classNames from "classnames"
import styles from "./FormRow.module.scss"

export function FormRow({
    children,
    className,
}: {
    children: React.ReactNode
    className?: string
}) {
    return <div className={classNames(className, styles.row)}>{children}</div>
}
