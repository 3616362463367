import React, { FC } from "react"
import styles from "./StatsDisplay.module.scss"
import { Link, LinkElement } from "../../../components/link/Link.component"
import { ROUTE, formatRoutePath } from "../../../routes"
import { LicenseType } from "../../../v3/services/User.service"

interface Props {
    displayNumber: number
    label: string
    isInUse?: boolean
    licenseType?: LicenseType
}

export const StatsDisplay: FC<Props> = ({
    displayNumber,
    label,
    isInUse,
    licenseType,
}): JSX.Element => {
    if (isInUse) {
        return (
            <Link
                asElement={LinkElement.LINK}
                className={styles.link}
                to={formatRoutePath(ROUTE.USERS, {}, { [`fm_${licenseType}License`]: "LICENSED" })}
            >
                <p className={styles.number}>{displayNumber}</p>
                <p className={styles.text}>{label}</p>
            </Link>
        )
    }

    return (
        <div>
            <p className={styles.number}>{displayNumber}</p>
            <p className={styles.text}>{label}</p>
        </div>
    )
}
