import React from "react"

import { IconType, useServiceLocalization } from "../../../../../pre-v3/services"
import { StringUtil } from "../../../../../pre-v3/utils/String.util"
import { encodeID } from "../../../../../pre-v3/utils/Url.util"
import { ROUTE, formatRoutePath } from "../../../../../routes"
import { PlistTrustFactor, TrustFactor, Plist } from "../../../../services/TrustFactor.service"
import { Column, Grid } from "../../../../components/grid/Grid.component"
import { RowTitle, rowTitleClassName } from "../../../../components/grid/RowTitle.component"
import { SearchInput } from "../../../../components/search-input/SearchInput.component"
import styles from "./PlistList.module.scss"
import trustStyles from "../../../../../pages/trust/Trust.module.scss"
import { PageHeading } from "../../../../../components/page-heading/PageHeading.component"
import {
    Button,
    ButtonElement,
    ButtonType,
} from "../../../../../components/button/Button.component"
import { useHistory } from "react-router-dom"
import { Tooltip } from "../../../../components/tooltip/Tooltip.component"

interface PlistListProps {
    plistTrustFactor?: PlistTrustFactor
    isLoading: boolean
    onRefetchData(): Promise<TrustFactor[] | undefined>
    canAccessPListCheckFactor?: boolean
}

export function PlistList(props: PlistListProps): JSX.Element {
    const localization = useServiceLocalization()
    const history = useHistory()
    const searchLabel = localization.getString("searchByName")
    const addPlistLabel = localization.getString("addPropertyList")

    const [searchTerm, setSearchTerm] = React.useState("")

    const columns = useColumns()

    const filteredPlist = React.useMemo(() => {
        const plists = props.plistTrustFactor?.plists || []

        return searchTerm
            ? plists.filter((plist) => StringUtil.caseInsensitiveIncludes(plist.name, searchTerm))
            : plists
    }, [props.plistTrustFactor, searchTerm])

    function onAddPropertyList() {
        history.push(ROUTE.FACTORS_PLIST_CREATE)
    }

    return (
        <section aria-labelledby={Id.HEADING} className={trustStyles.section}>
            <header className={trustStyles.header}>
                <PageHeading id={Id.HEADING}>
                    {localization.getString("propertyListCheck")}
                </PageHeading>
                <Tooltip title={localization.getString("refresh")}>
                    <Button
                        icon={IconType.REDO}
                        onClick={() => props.onRefetchData()}
                        asElement={ButtonElement.BUTTON}
                        buttonType={ButtonType.SECONDARY}
                        aria-label={localization.getString("refresh")}
                    />
                </Tooltip>
            </header>
            <div className={styles.toolbar}>
                <SearchInput
                    value={searchTerm}
                    onChangeValue={setSearchTerm}
                    placeholder={searchLabel}
                    aria-label={searchLabel}
                    className={styles.searchInput}
                />
                {props.canAccessPListCheckFactor && (
                    <Button
                        buttonType={ButtonType.PRIMARY}
                        asElement={ButtonElement.BUTTON}
                        onClick={onAddPropertyList}
                        icon={IconType.PLUS}
                    >
                        {addPlistLabel}
                    </Button>
                )}
            </div>
            <Grid
                columns={columns}
                data={props.isLoading ? undefined : filteredPlist}
                className={styles.grid}
            />
        </section>
    )
}

function useColumns(): Column<Plist>[] {
    const localization = useServiceLocalization()
    const nameLabel = localization.getString("name")
    const keyLabel = localization.getString("key")
    const valueLabel = localization.getString("value")

    return React.useMemo(
        (): Column<Plist>[] => [
            {
                id: "name",
                name: nameLabel,
                cellRenderer: (plist) => (
                    <RowTitle
                        title={plist.name}
                        route={formatRoutePath(ROUTE.FACTORS_PLIST_DETAILS, {
                            id: encodeID(plist.id),
                        })}
                    />
                ),
                cellClassName: rowTitleClassName,
                getTooltipValue: "name",
            },
            { id: "key", name: keyLabel, cellRenderer: getKey, getTooltipValue: getKey },
            { id: "value", name: valueLabel, cellRenderer: getValue, getTooltipValue: getValue },
        ],
        []
    )
}

function getKey(plist: Plist): string {
    return plist.configuration.key
}

function getValue(plist: Plist): string {
    return plist.configuration.value
}

enum Id {
    HEADING = "heading",
}
