import { DialogContent } from "@mui/material"
import React from "react"

import { useServiceLocalization } from "../../../../../pre-v3/services/localization/Localization.service"
import { File, TrustProfile } from "../../../../services/TrustFactor.service"
import { AppText } from "../../../../components/app-text/AppText.component"
import styles from "./DeleteFile.module.scss"

interface Props {
    file: File
}

export function DeleteFile(props: Props): JSX.Element {
    const localization = useServiceLocalization()

    return (
        <DialogContent>
            {props.file.trustProfiles.length > 0 && (
                <React.Fragment>
                    <AppText>
                        {localization.getPluralString(
                            "fileIsIncludedInTrustProfile",
                            props.file.trustProfiles.length,
                            props.file.name
                        )}
                    </AppText>
                    <ul className={styles.trustProfileList}>
                        {props.file.trustProfiles.map(mapTrustProfileListItem)}
                    </ul>
                </React.Fragment>
            )}
            <AppText
                ls={{ key: "areYouSureYouWantToDeleteSomething", replaceVals: [props.file.name] }}
            />
        </DialogContent>
    )
}

function mapTrustProfileListItem(trustProfile: TrustProfile): JSX.Element {
    return <li key={trustProfile.id}>{trustProfile.name}</li>
}
