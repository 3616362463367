import { DialogActions } from "@mui/material"
import React from "react"

import { Button, ButtonElement, ButtonType } from "../../../../components/button/Button.component"
import { ModalRef, useServiceLocalization } from "../../../../pre-v3/services"
import { FormUtil } from "../../../../pre-v3/utils/Form.util"
import { Form } from "../../../components/form/Form.component"
import { ErrorBanner } from "../../../components/banner/Banner.component"
import { FormRow } from "../../../components/form/FormRow.component"
import { useLookUpDomainInfo } from "../../../services/ItpPolicy.service"
import { PillMultiSelect } from "../../../components/pill-multi-select/PillMultiSelect.component"
import { FormColumn } from "../../../components/form/FormColumn.component"
import { Input } from "../../../components/input/Input.component"

interface Props {
    modalRef: ModalRef
}

export function LookupDomainModal(props: Props) {
    const localization = useServiceLocalization()
    const {
        mutateAsync: lookUpDomainInfo,
        isLoading: isLookupDomainLoading,
        error: lookupDomainError,
        data: lookupDomainInfo,
        reset: resetLookupDomains,
    } = useLookUpDomainInfo()

    const threatCategories: string[] = lookupDomainInfo?.threats || []
    const contentCategories: string[] = lookupDomainInfo?.categories || []
    const isLookupDataAvailable: boolean = Boolean(lookupDomainInfo)

    function onSubmit(event: React.FormEvent<HTMLFormElement>) {
        event.preventDefault()
        const domainName: string = FormUtil.getFieldValue(event, "domain")
        lookUpDomainInfo(domainName)
    }

    return (
        <Form onSubmit={onSubmit}>
            <FormRow
                label={localization.getString("domain")}
                description={localization.getString("domainDesc")}
                htmlFor="domain"
            >
                <Input
                    type="text"
                    placeholder={localization.getString("egWwwExampleDotCom")}
                    name="domain"
                    onChange={() => {
                        resetLookupDomains()
                    }}
                />
            </FormRow>
            {isLookupDataAvailable && (
                <>
                    {threatCategories.length > 0 ? (
                        <PillMultiSelect
                            disabled={true}
                            label={localization.getString("threatCategories")}
                            placeholder={""}
                            options={[]}
                            value={threatCategories}
                        />
                    ) : (
                        <FormColumn
                            label={localization.getString("threatCategories")}
                            description={localization.getString(
                                "noThreatCategoriesAssociatedWithDomain"
                            )}
                        ></FormColumn>
                    )}
                    {contentCategories.length > 0 ? (
                        <PillMultiSelect
                            disabled={true}
                            label={localization.getString("contentCategories")}
                            placeholder={""}
                            options={[]}
                            value={contentCategories}
                        />
                    ) : (
                        <FormColumn
                            label={localization.getString("contentCategories")}
                            description={localization.getString(
                                "noContentCategoriesAssociatedWithDomain"
                            )}
                        ></FormColumn>
                    )}
                </>
            )}

            {typeof lookupDomainError === "string" && (
                <ErrorBanner>{String(lookupDomainError)}</ErrorBanner>
            )}
            <DialogActions>
                <>
                    <Button
                        asElement={ButtonElement.BUTTON}
                        buttonType={ButtonType.SECONDARY}
                        onClick={props.modalRef.cancel}
                    >
                        {localization.getString("cancel")}
                    </Button>
                    <Button
                        asElement={ButtonElement.BUTTON}
                        buttonType={ButtonType.PRIMARY}
                        loading={isLookupDomainLoading}
                        type="submit"
                    >
                        {localization.getString("submit")}
                    </Button>
                </>
            </DialogActions>
        </Form>
    )
}
