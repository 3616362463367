import React from "react"
import { useHistory } from "react-router-dom"

import { Button, ButtonElement, ButtonType } from "../../../components/button/Button.component"
import { PageHeading } from "../../../components/page-heading/PageHeading.component"
import { useServiceLocalization } from "../../../pre-v3/services/localization/Localization.service"
import { encodeID } from "../../../pre-v3/utils/Url.util"
import { ROUTE, formatRoutePath } from "../../../routes"
import { Container } from "../../../v3/components/container/Container.component"
import { FormButtons } from "../../../v3/components/form/form-buttons/FormButtons.component"
import { Cluster } from "../../../v3/services/shared/Cluster"
import {
    CreateAccessTierGroupVariables,
    useCreateAccessTierGroup,
    useGetAccessTiers,
} from "../../../v3/services/AccessTierGroup.service"
import { ErrorToast, ToastApi } from "../../../components/toast/Toast.components"
import { AccessTierGroupsForm } from "../shared/AccessTierGroupsForm.component"
import styles from "./AccessTierGroupsCreate.module.scss"

interface Props {
    privateEdgeCluster: Cluster
}

export function AccessTierGroupsCreate(props: Props): JSX.Element {
    const history = useHistory()
    const localization = useServiceLocalization()

    const toastRef = React.useRef<ToastApi>(null)

    const [accessTierGroup, setAccessTierGroup] = React.useState(emptyAccessTierGroup)

    const { data: accessTiers } = useGetAccessTiers(props.privateEdgeCluster)

    const { mutate: createAccessTierGroup, isLoading: isCreatingAccessTierGroup } =
        useCreateAccessTierGroup(props.privateEdgeCluster, {
            onSuccess: (accessTierGroup) =>
                history.push(
                    formatRoutePath(ROUTE.ACCESS_TIER_GROUPS_DETAILS, {
                        id: encodeID(accessTierGroup.id),
                    })
                ),
            onError: (error) =>
                typeof error === "string"
                    ? toastRef.current?.openToast(error)
                    : console.error(error),
        })

    const onSubmit: React.FormEventHandler = (e) => {
        e.preventDefault()
        createAccessTierGroup(accessTierGroup)
    }

    const createLabel = localization.getString(
        "createSomething",
        localization.getString("accessTierGroup")
    )

    return (
        <React.Fragment>
            <Container
                as="form"
                onSubmit={onSubmit}
                aria-labelledby={Id.HEADING}
                className={styles.container}
            >
                <header>
                    <PageHeading id={Id.HEADING}>{createLabel}</PageHeading>
                </header>
                <AccessTierGroupsForm
                    accessTierGroup={accessTierGroup}
                    accessTiers={accessTiers}
                    onChange={setAccessTierGroup}
                    isEditMode
                />
                <FormButtons
                    rightButtons={
                        <Button
                            asElement={ButtonElement.BUTTON}
                            buttonType={ButtonType.PRIMARY}
                            type="submit"
                            loading={isCreatingAccessTierGroup}
                        >
                            {createLabel}
                        </Button>
                    }
                />
            </Container>
            <ErrorToast ref={toastRef} />
        </React.Fragment>
    )
}

enum Id {
    HEADING = "heading",
}

const emptyAccessTierGroup: CreateAccessTierGroupVariables = {
    name: "",
    sharedAddress: "",
    accessTiers: [],
}
