import { BaseApi, PaginationOptions } from "./Base.api"
import { Singleton } from "../decorators/Singleton.decorator"

@Singleton("SystemLogsApi")
export class SystemLogsApi extends BaseApi {
    public getSystemLogs(props: SystemLogProps): Promise<SystemLogRes> {
        const params: URLSearchParams = new URLSearchParams()
        for (const key in props) {
            params.append(key, (<any>props)[key])
        }
        return this.get("/api/v1/audit_logs?" + params.toString())
    }
}

export interface SystemLogProps extends PaginationOptions {
    id?: string
    end_time?: number
    start_time?: number
    type?: string
    admin_email?: string
    action?: string
    message?: string
}

export interface SystemLogRes {
    auditlogs: SystemLogItemRes[]
    count: number
}

export interface SystemLogItemRes {
    id: string
    org_id: string
    created_at: number
    message: string
    type: string
    action: string
    admin_email: string
    changes_new: NewChange
    changes_old: OldChange
}

export interface OldChange {
    ClusterName: string
    CreatedAt: number
    CreatedBy: string
    DeletedAt: number
    DeletedBy: string
    Description: string
    Domain: string
    Enabled: string
    External: string
    FriendlyName: string
    IsDefault: boolean
    LastUpdatedAt: number
    LastUpdatedBy: string
    OIDCClientSpec: string
    OIDCEnabled: string
    Port: string
    Protocol: string
    ServiceDiscovery: string
    ServiceID: string
    ServiceName: string
    ServiceSpec: string
    ServiceType: string
    ServiceVersion: string
    UserFacing: string
}

export interface NewChange {
    AudienceURI: string
    CallbackURL: string
    ClientID: string
    ClientSecret: string
    CreatedAt: number
    CreatedBy: string
    Description: string
    DisallowAsyncAuthRedirect: boolean
    Enabled: string
    ID: string
    Name: string
    NameIDAttributeSelector: string
    NameIDFormat: string
    NameIDValuetype: string
    OrgID: string
    Protocol: string
    Tags: StringMap
    Type: string
    UpdatedAt: number
    UpdatedBy: string
}
