import React from "react"
import { Redirect, Route, Switch } from "react-router-dom"

import { useFeatureFlags } from "../../hooks/useFeatureFlags.hook"
import useTitle from "../../hooks/useTitle.hook"
import { useServiceLinks } from "../../pre-v3/services/link/Link.service"
import { useServiceLocalization } from "../../pre-v3/services/localization/Localization.service"
import { ROUTE } from "../../routes"
import upgradeFullIllustration from "../../images/upgrade-full-page.svg"
import { AppText } from "../../v3/components/app-text/AppText.component"
import { Loader } from "../../v3/components/loader/Loader.component"
import { EmptyStateScreen } from "../../v3/components/page-screen/PageScreen.component"
import { AccessTiersList as AccessTierList } from "../../v3/views/access-tiers/list/AccessTiersList.view"
import { AccessTierCreate } from "./create/AccessTierCreate.page"
import { AccessTierDetails } from "./details/AccessTierDetails.page"
import styles from "./AccessTier.module.scss"

export function AccessTier(): JSX.Element {
    useTitle(["accessTiers", "network", "adminConsole"])

    const { data: featureFlags, isLoading: isFeatureFlagsLoading } = useFeatureFlags()
    const links = useServiceLinks()
    const localization = useServiceLocalization()

    if (isFeatureFlagsLoading)
        return (
            <Loader
                center
                medium
                title={localization.getString(
                    "loadingSomething",
                    localization.getString("accessTier")
                )}
            />
        )

    if (!featureFlags?.adminConsole.canAccessAccessTiers.canAccess) {
        return (
            <EmptyStateScreen>
                <img
                    src={upgradeFullIllustration}
                    alt={localization.getString("upgradeIllustration")}
                />
                <AppText
                    className={styles.text}
                    ls={{
                        key: "organizationWithNoPrivateEdge",
                        replaceVals: [
                            links.getLink("supportLink"),
                            links.getLink("deploymentModelsDoc"),
                        ],
                    }}
                />
            </EmptyStateScreen>
        )
    }

    return (
        <Switch>
            <Route path={ROUTE.ACCESS_TIERS_ADD}>
                <AccessTierCreate
                    canAccessAccessTierGroups={
                        featureFlags.adminConsole.enableAccessTierGroups ?? false
                    }
                    privateEdgeCluster={
                        featureFlags.adminConsole.canAccessAccessTiers.privateEdgeCluster
                    }
                    organization={featureFlags.adminConsole.canAccessAccessTiers.organization}
                    canAccessAccessTierInstallStep={
                        featureFlags.adminConsole.canAccessAccessTierInstallStep
                    }
                />
            </Route>
            <Route path={ROUTE.ACCESS_TIERS_DETAILS}>
                <AccessTierDetails
                    enableAccessTierGroups={
                        featureFlags?.adminConsole.enableAccessTierGroups ?? false
                    }
                />
            </Route>
            <Route path={ROUTE.ACCESS_TIERS}>
                <AccessTierList
                    canCreateAccessTier={featureFlags?.adminConsole.canCreateAccessTier}
                    enableAccessTierGroups={
                        featureFlags?.adminConsole.enableAccessTierGroups ?? false
                    }
                    shouldAddPrivateEdge={featureFlags?.adminConsole.shouldAddPrivateEdge ?? false}
                />
            </Route>
            <Redirect to={ROUTE.ACCESS_TIERS} />
        </Switch>
    )
}
