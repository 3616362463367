import React from "react"

import { AppText } from "../../../../../v3/components/app-text/AppText.component"
import styles from "./AddPreferredApp.module.scss"
import {
    Form,
    ErrorBanner,
    FormLabel,
    FormColumns,
    Select,
    SubmitButton,
    Input,
} from "../../../../components"

export default function () {
    return (
        <div>
            <Form onSubmit={this.onSubmit} display="grid" floating labelWidth={200}>
                <FormColumns
                    right={
                        <>
                            <h2>{this.localizationService.getString("addApplicationCheck")}</h2>
                            <AppText ls="addApplicationCheckDescription" />
                        </>
                    }
                />
                {this.state.error && <ErrorBanner>{this.state.error}</ErrorBanner>}
                <FormLabel
                    title={this.localizationService.getString("applicationName")}
                    htmlFor="appName"
                >
                    <Input
                        className={styles.formInput}
                        required
                        placeholder={this.localizationService.getString("applicationName")}
                    />
                </FormLabel>
                <FormLabel
                    title={this.localizationService.getString("mandatory")}
                    htmlFor="mandatory"
                >
                    <Select
                        required
                        value={this.state.mandatory}
                        options={this.mandatoryOptions}
                        onChange={this.onMandatoryChange}
                    />
                </FormLabel>
                <FormLabel
                    title={this.localizationService.getString("platforms")}
                    htmlFor="platforms"
                >
                    <Select
                        multiple
                        required
                        value={this.state.platforms}
                        options={this.platformOptions}
                        onChange={this.onPlatformChange}
                    />
                </FormLabel>
                {this.state.platforms.length > 0 && (
                    <FormColumns
                        right={
                            <>
                                <h3>{this.localizationService.getString("processNames")}</h3>
                                <AppText ls="applicationCheckProcessDescription" />
                            </>
                        }
                    />
                )}
                {this.state.platforms.map((p) => (
                    <FormLabel
                        title={
                            this.platformOptions.find((platform) => platform.value === p)
                                .displayName
                        }
                        htmlFor={p + "-id"}
                    >
                        <Input
                            className={styles.formInput}
                            required
                            placeholder={this.localizationService.getString("processName")}
                        />
                    </FormLabel>
                ))}
                {this.state.platforms.length > 0 && (
                    <FormColumns
                        right={<SubmitButton label={this.localizationService.getString("save")} />}
                    />
                )}
            </Form>
        </div>
    )
}
