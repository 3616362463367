import React, { ReactNode } from "react"
import {
    faCheckCircle,
    faExclamationCircle,
    faExclamationTriangle,
    faInfoCircle,
    IconDefinition,
} from "@fortawesome/pro-solid-svg-icons"
import classNames from "classnames"
import styles from "./Banner.module.scss"
import { Icon } from "../Icon/Icon.component"

interface ErrorBannerProps {
    children: ReactNode
    id?: string
    className?: string
}

export function ErrorBanner(props: ErrorBannerProps): JSX.Element {
    return <Banner {...props} type={BannerType.ERROR} icon={faExclamationCircle} />
}

interface ErrorBannersProps {
    errors: ReactNode[]
    className?: string
}

export function ErrorBanners(props: ErrorBannersProps) {
    const { errors, className } = props

    const errorExist = errors.some((error) => error)

    if (errorExist) {
        return (
            <div className={classNames(styles.errorBanners, className)}>
                {errors.map((error, errorIndex) => {
                    return error && <ErrorBanner key={errorIndex}>{error}</ErrorBanner>
                })}
            </div>
        )
    }

    return null
}

export function InfoBanner({ children, className }: { children: ReactNode; className?: string }) {
    return (
        <Banner type={BannerType.INFO} icon={faInfoCircle} className={className}>
            {children}
        </Banner>
    )
}

export function WarningBanner({
    children,
    className,
}: {
    children: ReactNode
    className?: string
}) {
    return (
        <Banner type={BannerType.WARNING} icon={faExclamationTriangle} className={className}>
            {children}
        </Banner>
    )
}

export function SuccessBanner({
    children,
    className,
}: {
    children: ReactNode
    className?: string
}) {
    return (
        <Banner type={BannerType.SUCCESS} icon={faCheckCircle} className={className}>
            {children}
        </Banner>
    )
}

function Banner(props: BannerProps) {
    return (
        <div
            role="alert"
            id={props.id}
            className={classNames(styles.banner, props.type, props.className)}
        >
            {props.icon && <Icon icon={props.icon} className={styles.bannerIcon} />}
            <div className={styles.bannerContent}>{props.children}</div>
        </div>
    )
}

export interface BannerProps {
    id?: string
    type: BannerType
    className?: string
    children: ReactNode
    icon: IconDefinition
}

export enum BannerType {
    INFO = "info",
    WARNING = "warning",
    ERROR = "error",
    SUCCESS = "success",
}
