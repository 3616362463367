import React from "react"

import {
    Button,
    ButtonElement,
    ButtonType,
} from "../../../../../../../components/button/Button.component"
import { useServiceLocalization } from "../../../../../../../pre-v3/services/localization/Localization.service"
import { ErrorBanner } from "../../../../../../components/banner/Banner.component"
import { FormButtons } from "../../../../../../components/form/form-buttons/FormButtons.component"
import {
    TrustProfileDetail,
    useUpdateTrustProfileFactors,
} from "../../../../../../services/TrustProfile.service"
import {
    areThereUnusedFactors,
    areThereUsedFactors,
    getUsedTrustFactors,
    useTrustFactors,
} from "../shared/useTrustFactors.hook"
import { TrustFactorsForm } from "../shared/TrustFactorsForm.component"
import { AddTrustFactorsButton } from "../shared/AddTrustFactorsButton.component"

interface Props {
    trustProfile: TrustProfileDetail
    disabled: boolean
    onCancel(): void
    onTrustProfileChange(): void
}

export function TrustFactors(props: Props): JSX.Element {
    const localization = useServiceLocalization()

    const {
        trustProfile,
        banyanTrustFactors,
        integrationTrustFactors,
        getError,
        onAddTrustFactor,
        onEditTrustFactor,
        onRemoveTrustFactor,
        onReset,
    } = useTrustFactors(props.trustProfile)

    const [error, setError] = React.useState<string>()

    const updateTrustProfileFactors = useUpdateTrustProfileFactors(trustProfile, {
        onSuccess: () => props.onTrustProfileChange(),
    })

    const onUpdate: React.MouseEventHandler = async (event) => {
        event.preventDefault()
        setError(undefined)

        const error = getError()
        if (error) {
            return setError(error)
        }

        await updateTrustProfileFactors.mutateAsync(
            getUsedTrustFactors(banyanTrustFactors, integrationTrustFactors)
        )
    }

    function onCancel() {
        onReset()
        props.onCancel()
    }

    return (
        <TrustFactorsForm
            trustFactors={getUsedTrustFactors(banyanTrustFactors, integrationTrustFactors)}
            onEditTrustFactor={onEditTrustFactor}
            onRemoveTrustFactor={onRemoveTrustFactor}
            disabled={props.disabled}
        >
            {!props.disabled && (
                <>
                    <AddTrustFactorsButton
                        banyanTrustFactors={banyanTrustFactors}
                        integrationTrustFactors={integrationTrustFactors}
                        onAddTrustFactor={onAddTrustFactor}
                        isDisabled={
                            !areThereUnusedFactors(banyanTrustFactors, integrationTrustFactors)
                        }
                    />
                    <FormButtons
                        leftButtons={
                            <Button
                                asElement={ButtonElement.BUTTON}
                                buttonType={ButtonType.SECONDARY}
                                onClick={onCancel}
                            >
                                {localization.getString("cancel")}
                            </Button>
                        }
                        rightButtons={
                            <Button
                                asElement={ButtonElement.BUTTON}
                                buttonType={ButtonType.PRIMARY}
                                onClick={onUpdate}
                                loading={updateTrustProfileFactors.isLoading}
                                disabled={
                                    !areThereUsedFactors(
                                        banyanTrustFactors,
                                        integrationTrustFactors
                                    )
                                }
                            >
                                {localization.getString("saveChanges")}
                            </Button>
                        }
                    >
                        {typeof updateTrustProfileFactors.error === "string" && (
                            <ErrorBanner>{updateTrustProfileFactors.error}</ErrorBanner>
                        )}
                        {error && <ErrorBanner>{error}</ErrorBanner>}
                    </FormButtons>
                </>
            )}
        </TrustFactorsForm>
    )
}
