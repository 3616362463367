import React from "react"
import { Redirect, Route, Switch, useLocation } from "react-router-dom"

import {
    NavigationItemProps,
    TopNavigationBar,
} from "../../components/top-navigation-bar/TopNavigationBar.component"
import useTitle from "../../hooks/useTitle.hook"
import { LanguageKey } from "../../pre-v3/services/localization/languages/en-US.language"
import { useServiceLocalization } from "../../pre-v3/services/localization/Localization.service"

import { ROUTE, doesRouteMatch } from "../../routes"

import { ServiceTunnel } from "../../pages/service-tunnel/ServiceTunnel.view"
import { Discovery } from "../../v3/views/private-resource/PrivateResource.view"
import { ServiceOverviewFallBackRoute } from "../../pre-v3/views/services/shared/ServiceOverviewFallBackRoute.view"
import { AccessPolicy } from "../access-policies/AccessPolicy.view"
import { HostedWebsite } from "../../v3/views/hosted-websites/HostedWebsite.view"
import { useFeatureFlags } from "../../hooks/useFeatureFlags.hook"
import { Loader } from "../../v3/components/loader/Loader.component"
import { Upsell } from "../../shared/upsell/Upsell.component"
import { Infrastructure } from "../infrastructure/Infrastucture.view"
import { IaasDiscovery } from "../iaas-discovery/IaasDiscovery.view"
import { LicenseEnforcementBanner } from "../shared/LicenseEnforcementBanner.component"

export function PrivateAccess(): JSX.Element {
    useTitle(["privateAccess", "adminConsole"])

    const localization = useServiceLocalization()
    const location = useLocation()
    const { data: featureFlags, isLoading: isFeatureFlagsLoading } = useFeatureFlags()

    const getNavigationItemProps = (route: SecondLevelRoute): NavigationItemProps => ({
        key: route,
        label: localization.getString(secondLevelLabels[route]),
        to: route,
        active: doesRouteMatch<SecondLevelRoute>(route, location),
    })

    if (isFeatureFlagsLoading || !featureFlags) return <Loader medium center />

    const infrastructureView = featureFlags.adminConsole.doShowUpsellForInfrastructure
        ? Upsell
        : Infrastructure
    const hostedWebsitesView = featureFlags.adminConsole.doShowUpsellForHostedWebsites
        ? Upsell
        : HostedWebsite
    const discoveryView = featureFlags.adminConsole.doShowUpsellForDiscovery ? Upsell : Discovery
    const iaasDiscoveryView = featureFlags.adminConsole.doShowUpsellForIaasDiscovery
        ? Upsell
        : IaasDiscovery

    return (
        <>
            <header>
                <TopNavigationBar
                    headingTitle={localization.getString("privateAccess")}
                    navigationItems={secondLevelRoutes}
                    getNavigationItemProps={getNavigationItemProps}
                />
            </header>
            {featureFlags.adminConsole.showLicenseEnforcementBannerInPrivateAccess && (
                <LicenseEnforcementBanner />
            )}
            {featureFlags.adminConsole.doShowUpsellForPrivateAccess ? (
                <Upsell />
            ) : (
                <Switch>
                    <Route path={ROUTE.SERVICE_TUNNELS} component={ServiceTunnel} />

                    <Route path={ROUTE.HOSTED_WEBSITES} component={hostedWebsitesView} />

                    <Route path={ROUTE.INFRASTRUCTURE} component={infrastructureView} />

                    <Route path={ROUTE.DISCOVERY} component={discoveryView} />

                    <Route path={ROUTE.IAAS_DISCOVERY} component={iaasDiscoveryView} />

                    <Route path={ROUTE.ACCESS_POLICIES} component={AccessPolicy} />
                    <Route
                        path={ROUTE.PRIVATE_ACCESS_SERVICES_DETAILS}
                        component={ServiceOverviewFallBackRoute}
                        exact
                    />
                    <Redirect to={ROUTE.SERVICE_TUNNELS} />
                </Switch>
            )}
        </>
    )
}

const secondLevelRoutes = [
    ROUTE.SERVICE_TUNNELS,
    ROUTE.HOSTED_WEBSITES,
    ROUTE.INFRASTRUCTURE,
    ROUTE.DISCOVERY,
    ROUTE.IAAS_DISCOVERY,
    ROUTE.ACCESS_POLICIES,
] satisfies ROUTE[]

type SecondLevelRoute = (typeof secondLevelRoutes)[number]

const secondLevelLabels: Record<SecondLevelRoute, LanguageKey> = {
    [ROUTE.SERVICE_TUNNELS]: "serviceTunnels",
    [ROUTE.HOSTED_WEBSITES]: "hostedWebsites",
    [ROUTE.INFRASTRUCTURE]: "infrastructure",
    [ROUTE.DISCOVERY]: "discovery",
    [ROUTE.IAAS_DISCOVERY]: "iaasDiscovery",
    [ROUTE.ACCESS_POLICIES]: "accessPolicies",
}
