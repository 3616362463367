export function Debounce<T extends Function>(
    target: object,
    key: string,
    descriptor: TypedPropertyDescriptor<T>
): TypedPropertyDescriptor<T> {
    return {
        configurable: true,
        enumerable: descriptor.enumerable,
        get(this: any): T {
            const debounced: Function = new Debouncer().debounce(<Function>descriptor.value, this)
            Object.defineProperty(this, key, {
                configurable: true,
                writable: true,
                enumerable: descriptor.enumerable,
                value: debounced,
            })
            return this[key]
        },
    }
}

class Debouncer {
    public debounce(fn: Function, scope?: any): Function {
        let _this = this

        return function (...args: any) {
            if (_this.timeout) {
                window.clearTimeout(_this.timeout)
                delete _this.timeout
            }
            _this.timeout = window.setTimeout(() => {
                delete _this.timeout
                fn.apply(scope, args)
            }, _this.duration)
        }
    }

    private timeout?: number
    private duration: number = 300
}
