import React from "react"
import { useHistory, useParams } from "react-router-dom"

import { DeleteCancelActions } from "../../../../../pre-v3/components/modal/delete-cancel/DeleteCancelActions"
import { useServiceLocalization } from "../../../../../pre-v3/services/localization/Localization.service"
import { IconType } from "../../../../../pre-v3/services/ActionBar.service"
import { useServiceModal } from "../../../../../pre-v3/services/Modal.service"
import { decodeID } from "../../../../../pre-v3/utils/Url.util"
import { ROUTE } from "../../../../../routes"
import {
    RegistryCheckTrustFactor,
    RegistryCheck,
    NewRegistryCheck,
    useDeleteRegistryKey,
    useUpdateRegistryKey,
} from "../../../../services/TrustFactor.service"
import { Container } from "../../../../components/container/Container.component"
import { LargeMessage } from "../../../../components/large-message/LargeMessage.component"
import { Loader } from "../../../../components/loader/Loader.component"
import { RegistryCheckForm } from "./RegistryCheckForm.component"
import { DeleteRegistryCheck } from "./DeleteRegistryCheck.component"
import {
    Button,
    ButtonElement,
    ButtonType,
} from "../../../../../components/button/Button.component"
import { PageHeading } from "../../../../../components/page-heading/PageHeading.component"
import trustStyles from "../../../../../pages/trust/Trust.module.scss"
import styles from "./RegistryCheckForm.module.scss"
import { Tooltip } from "../../../../components/tooltip/Tooltip.component"

interface Props {
    registryCheckTrustFactor?: RegistryCheckTrustFactor
    isLoading: boolean
    canAccessRegistryCheckFactor?: boolean
}

export function RegistryCheckEdit(props: Props): JSX.Element {
    const { isLoading, registryCheckTrustFactor } = props

    const { id: encodedRegistryCheckId } = useParams<{ id: string }>()

    const localization = useServiceLocalization()
    const history = useHistory()
    const modalService = useServiceModal()

    const registryCheck = React.useMemo((): RegistryCheck | undefined => {
        const registryCheckId = decodeID(encodedRegistryCheckId)
        return registryCheckTrustFactor?.registryChecks.find(
            (registryCheck) => registryCheck.id === registryCheckId
        )
    }, [registryCheckTrustFactor?.registryChecks, encodedRegistryCheckId])

    const {
        mutateAsync: updateRegistryCheckTrustFactor,
        isLoading: isUpdatingRegistryCheckTrustFactor,
        error: updateRegistryCheckTrustFactorError,
    } = useUpdateRegistryKey({ onSuccess: () => history.push(ROUTE.FACTORS_REGISTRY_CHECK) })

    const {
        mutateAsync: deleteRegistryCheckTrustFactor,
        isLoading: isDeletingRegistryCheckTrustFactor,
        error: deleteRegistryCheckTrustFactorError,
    } = useDeleteRegistryKey()

    const onDelete: React.MouseEventHandler = (event) => {
        event.preventDefault()

        if (!registryCheck) return

        modalService
            .open(
                localization.getString("deleteSomething", localization.getString("registryCheck")),
                {
                    component: DeleteRegistryCheck,
                    props: { registryCheck: registryCheck },
                    withoutBody: true,
                },
                { component: DeleteCancelActions }
            )
            .onClose(async () => {
                await deleteRegistryCheckTrustFactor(registryCheck)
                history.push(ROUTE.FACTORS_REGISTRY_CHECK)
            })
    }

    function onSubmit(editedRegistryCheck: NewRegistryCheck): void {
        updateRegistryCheckTrustFactor({
            ...registryCheck!,
            ...editedRegistryCheck,
        })
    }

    const errors = [
        typeof updateRegistryCheckTrustFactorError === "string" &&
            updateRegistryCheckTrustFactorError,
        typeof deleteRegistryCheckTrustFactorError === "string" &&
            deleteRegistryCheckTrustFactorError,
    ]

    return (
        <div className={styles.container}>
            <section aria-labelledby={Id.HEADING} className={trustStyles.section}>
                <header className={trustStyles.header}>
                    <PageHeading id={Id.HEADING}>{registryCheck?.name}</PageHeading>
                    {props.canAccessRegistryCheckFactor && (
                        <Tooltip title={localization.getString("delete")}>
                            <Button
                                icon={IconType.TRASH}
                                onClick={onDelete}
                                asElement={ButtonElement.BUTTON}
                                buttonType={ButtonType.SECONDARY}
                                aria-label={localization.getString("delete")}
                                disabled={isLoading}
                            />
                        </Tooltip>
                    )}
                </header>
            </section>
            <Loader
                isLoading={isDeletingRegistryCheckTrustFactor || isLoading}
                title={localization.getString("loadingRegistryCheck")}
                center
                medium
            >
                {registryCheck ? (
                    <Container>
                        <RegistryCheckForm
                            defaultValue={registryCheck}
                            onSubmit={onSubmit}
                            cancelHref={ROUTE.FACTORS_REGISTRY_CHECK}
                            submitting={isUpdatingRegistryCheckTrustFactor}
                            errors={errors}
                            canAccessRegistryCheckFactor={props.canAccessRegistryCheckFactor}
                        />
                    </Container>
                ) : (
                    <LargeMessage>{localization.getString("registryCheckNotFound")}</LargeMessage>
                )}
            </Loader>
        </div>
    )
}

enum Id {
    HEADING = "heading",
}
