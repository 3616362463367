import React from "react"
import { useHistory, useParams } from "react-router-dom"

import {
    Button,
    ButtonElement,
    ButtonType,
    IconType,
} from "../../../components/button/Button.component"
import { PageHeading } from "../../../components/page-heading/PageHeading.component"
import { LanguageKey } from "../../../pre-v3/services/localization/languages/en-US.language"
import { useServiceLocalization } from "../../../pre-v3/services/localization/Localization.service"
import { useServiceModal } from "../../../pre-v3/services/Modal.service"
import { decodeID } from "../../../pre-v3/utils/Url.util"
import { ROUTE } from "../../../routes"
import { Container } from "../../../v3/components/container/Container.component"
import {
    KeyValuePair,
    KeyValuePairList,
} from "../../../v3/components/key-value-pair/KeyValuePair.component"
import { Loader } from "../../../v3/components/loader/Loader.component"
import { TabBar, TabProps } from "../../../v3/components/tab-bar/TabBar.component"
import { Tooltip } from "../../../v3/components/tooltip/Tooltip.component"
import { Cluster } from "../../../v3/services/shared/Cluster"
import {
    AccessTierGroupDetails,
    useDeleteAccessTierGroup,
    useGetAccessTierGroupById,
} from "../../../v3/services/AccessTierGroup.service"
import { Banner, Variant } from "../../../components/banner/Banner.component"
import { ErrorToast, ToastApi } from "../../../components/toast/Toast.components"
import { AccessTiersInGroup } from "./AccessTiersInGroup.component"
import { AccessTierGroupConfigurationForm } from "./AccessTierGroupConfigurationForm.component"
import styles from "./AccessTierGroupsDetails.module.scss"

interface Props {
    privateEdgeCluster: Cluster
}

export function AccessTierGroupsDetails(props: Props): JSX.Element {
    const params = useParams<{ id: string }>()

    const localization = useServiceLocalization()
    const locale = localization.getLocale()

    const {
        data: accessTierGroup,
        status,
        error,
        refetch,
    } = useGetAccessTierGroupById(decodeID(params.id), props.privateEdgeCluster)

    const [currentTab, setCurrentTab] = React.useState(Tab.CONFIGURATION)
    const [isEditMode, setIsEditMode] = React.useState(false)

    if (status === "loading") {
        return (
            <Loader
                center
                medium
                title={localization.getString(
                    "loadingSomething",
                    localization.getString("accessTierGroup")
                )}
            />
        )
    }

    if (status === "error") {
        return (
            <Page
                heading={localization.getString("accessTierGroup")}
                headerRight={<RefreshButton onRefresh={refetch} />}
            >
                <Banner label={String(error)} variant={Variant.ERROR} />
            </Page>
        )
    }

    const getTabBarProps = (tab: Tab): TabProps<Tab> => ({
        id: tab,
        label: localization.getString(tabLabelDict[tab]),
        ariaControls: tab,
    })

    const onEdit: React.MouseEventHandler = (e) => {
        e.preventDefault()
        setIsEditMode(true)
        setCurrentTab(Tab.CONFIGURATION)
    }

    const onExitEditMode = () => setIsEditMode(false)

    const editLabel = localization.getString("edit")

    return (
        <Page
            heading={accessTierGroup.name}
            headerRight={
                <div className={styles.actionButtons}>
                    <Tooltip title={editLabel}>
                        <Button
                            icon={IconType.PEN}
                            onClick={onEdit}
                            asElement={ButtonElement.BUTTON}
                            buttonType={ButtonType.SECONDARY}
                            aria-label={editLabel}
                            disabled={isEditMode}
                        />
                    </Tooltip>
                    <DeleteButton accessTierGroup={accessTierGroup} />
                    <RefreshButton onRefresh={refetch} />
                </div>
            }
        >
            <KeyValuePairList>
                <KeyValuePair
                    label={localization.getString("lastUpdated")}
                    value={accessTierGroup.lastUpdatedAt.toLocaleString(locale)}
                />
                <KeyValuePair
                    label={localization.getString("createdAt")}
                    value={accessTierGroup.createdAt.toLocaleString(locale)}
                />
            </KeyValuePairList>
            <TabBar
                tabs={Object.values(Tab).map(getTabBarProps)}
                selectedTabId={currentTab}
                onChange={setCurrentTab}
            />
            <Pane
                currentTab={currentTab}
                accessTierGroup={accessTierGroup}
                privateEdgeCluster={props.privateEdgeCluster}
                isEditMode={isEditMode}
                onExitEditMode={onExitEditMode}
            />
        </Page>
    )
}

interface PageProps {
    heading: string
    headerRight?: React.ReactNode
    children?: React.ReactNode
}

function Page(props: PageProps): JSX.Element {
    return (
        <Container as="section" aria-labelledby={Id.HEADING} className={styles.container}>
            <header className={styles.header}>
                <PageHeading id={Id.HEADING}>{props.heading}</PageHeading>
                {props.headerRight}
            </header>
            {props.children}
        </Container>
    )
}

interface PaneProps {
    currentTab: Tab
    accessTierGroup: AccessTierGroupDetails
    privateEdgeCluster: Cluster
    isEditMode: boolean
    onExitEditMode(): void
}

function Pane(props: PaneProps): JSX.Element {
    switch (props.currentTab) {
        case Tab.CONFIGURATION:
            return (
                <AccessTierGroupConfigurationForm
                    id={Tab.CONFIGURATION}
                    accessTierGroup={props.accessTierGroup}
                    privateEdgeCluster={props.privateEdgeCluster}
                    isEditMode={props.isEditMode}
                    onExitEditMode={props.onExitEditMode}
                />
            )

        case Tab.ACCESS_TIERS:
            return (
                <AccessTiersInGroup
                    accessTiers={props.accessTierGroup.accessTiers}
                    id={Tab.ACCESS_TIERS}
                />
            )
    }
}

interface DeleteButtonProps {
    accessTierGroup: AccessTierGroupDetails
}

function DeleteButton(props: DeleteButtonProps): JSX.Element {
    const history = useHistory()
    const modalService = useServiceModal()
    const localization = useServiceLocalization()

    const errorToastRef = React.useRef<ToastApi>(null)

    const { mutate: deleteAccessTierGroup, isLoading } = useDeleteAccessTierGroup(
        props.accessTierGroup,
        {
            onSuccess: () => {
                history.push(ROUTE.ACCESS_TIER_GROUPS)
            },
            onError: errorToastRef.current?.openToast,
        }
    )

    const onDelete: React.MouseEventHandler = (e) => {
        e.preventDefault()

        modalService
            .openDelete(
                localization.getString(
                    "deleteSomething",
                    localization.getString("accessTierGroup")
                ),
                localization.getString("deleteAccessTierGroupExplanation")
            )
            .onClose(() => deleteAccessTierGroup(undefined))
    }

    const canDelete = props.accessTierGroup.accessTiers.length <= 0

    const deleteLabel = localization.getString("delete")
    const tooltipLabel = canDelete
        ? deleteLabel
        : localization.getString("detachAccessTiersBeforeDeletingAccessTierGroup")

    return (
        <React.Fragment>
            <Tooltip title={tooltipLabel}>
                <div>
                    <Button
                        icon={IconType.TRASH}
                        onClick={onDelete}
                        asElement={ButtonElement.BUTTON}
                        buttonType={ButtonType.SECONDARY}
                        disabled={!canDelete}
                        aria-label={deleteLabel}
                        loading={isLoading}
                    />
                </div>
            </Tooltip>
            <ErrorToast ref={errorToastRef} />
        </React.Fragment>
    )
}

interface RefreshButtonProps {
    onRefresh(): void
}

function RefreshButton(props: RefreshButtonProps): JSX.Element {
    const localization = useServiceLocalization()

    const onRefresh: React.MouseEventHandler = (e) => {
        e.preventDefault()
        props.onRefresh()
    }

    const refreshLabel = localization.getString("refresh")

    return (
        <Tooltip title={refreshLabel}>
            <Button
                icon={IconType.REDO}
                onClick={onRefresh}
                asElement={ButtonElement.BUTTON}
                buttonType={ButtonType.SECONDARY}
                aria-label={refreshLabel}
            />
        </Tooltip>
    )
}

enum Id {
    HEADING = "heading",
}

enum Tab {
    CONFIGURATION = "configuration",
    ACCESS_TIERS = "accessTiers",
}

const tabLabelDict: Record<Tab, LanguageKey> = {
    [Tab.CONFIGURATION]: "configuration",
    [Tab.ACCESS_TIERS]: "accessTiers",
}
