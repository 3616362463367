import React from "react"

import { useServiceLocalization } from "../../../../pre-v3/services/localization/Localization.service"
import {
    FilterModel,
    Paginated,
    PaginatedSearch,
    replaceColumnKeys,
} from "../../../../pre-v3/utils/AgGrid.util"
import { replaceKeys } from "../../../../pre-v3/utils/Collection.util"
import { DateUtil } from "../../../../pre-v3/utils/Date.util"
import { encodeID } from "../../../../pre-v3/utils/Url.util"
import { ROUTE, formatRoutePath } from "../../../../routes"
import { Column, Grid, GridApi, sortWithServerSide } from "../../../components/grid/Grid.component"
import { RowTitle } from "../../../components/grid/RowTitle.component"
import { FilterById, PrivateResource, SortById } from "../../../services/PrivateResource.service"

interface Props {
    filterModel: FilterModel<FilterById>
    getPrivateResources(
        search: PaginatedSearch<SortById, FilterById>
    ): Promise<Paginated<PrivateResource>>
}

function PrivateResourceGridComponent(props: Props, ref: React.ForwardedRef<GridApi>): JSX.Element {
    const localization = useServiceLocalization()

    const getServiceTunnelNames_ = getServiceTunnelNames(localization.getLocale())

    const addressColumn: Column<PrivateResource> = {
        id: ColumnId.ADDRESS,
        name: localization.getString("address"),
        cellRenderer: renderAddressCell,
        getTooltipValue: "address",
        sorting: sortWithServerSide(),
        isFilterable: true,
    }

    const networkColumn: Column<PrivateResource> = {
        id: ColumnId.NETWORK,
        name: localization.getString("network"),
        cellRenderer: getNetworkName,
        getTooltipValue: getNetworkName,
        sorting: sortWithServerSide(),
        isFilterable: true,
    }

    const serviceTunnelsColumn: Column<PrivateResource> = {
        id: ColumnId.SERVICE_TUNNELS,
        name: localization.getString("serviceTunnels"),
        cellRenderer: getServiceTunnelNames_,
        getTooltipValue: getServiceTunnelNames_,
        isFilterable: true,
    }

    const uniqueUsersDevicesColumn: Column<PrivateResource> = {
        id: ColumnId.UNIQUE_USERS_DEVICES,
        name: localization.getString("uniqueUsersDevices"),
        cellRenderer: "uniqueUsersDevicesCount",
        getTooltipValue: "uniqueUsersDevicesCount",
        sorting: sortWithServerSide("desc"),
    }

    const lastReportedColumn: Column<PrivateResource> = {
        id: ColumnId.LAST_REPORTED,
        name: localization.getString("lastReported"),
        cellRenderer: getLastReported,
        getTooltipValue: getLastReported,
        sorting: sortWithServerSide(),
    }

    const columns: Column<PrivateResource>[] = [
        addressColumn,
        networkColumn,
        serviceTunnelsColumn,
        uniqueUsersDevicesColumn,
        lastReportedColumn,
    ]

    const getServerSideData = (search: PaginatedSearch<SortedColumnId, FilteredColumnId>) =>
        props.getPrivateResources({
            ...search,
            sortModel: search.sortModel && replaceColumnKeys(search.sortModel, sortByIdDict),
            filterModel: search.filterModel && replaceKeys(search.filterModel, filterByIdDict),
        })

    return (
        <Grid
            columns={columns}
            ref={ref}
            hasPagination
            serverSideProps={{
                getServerSideData,
                filterModel: replaceKeys(props.filterModel, filteredColumnDict),
                filterableKeys: otherFilteredColumnIds,
            }}
        />
    )
}

export const PrivateResourceGrid = React.forwardRef<GridApi, Props>(PrivateResourceGridComponent)

enum ColumnId {
    ADDRESS = "address",
    NETWORK = "network",
    SERVICE_TUNNELS = "serviceTunnels",
    UNIQUE_USERS_DEVICES = "uniqueUsersDevices",
    LAST_REPORTED = "lastReported",
}

type SortedColumnId =
    | ColumnId.ADDRESS
    | ColumnId.NETWORK
    | ColumnId.UNIQUE_USERS_DEVICES
    | ColumnId.LAST_REPORTED
type FilteredColumnId =
    | ColumnId.ADDRESS
    | ColumnId.NETWORK
    | ColumnId.SERVICE_TUNNELS
    | FilterById.USERS

const otherFilteredColumnIds: FilteredColumnId[] = [FilterById.USERS]

function renderAddressCell(privateResource: PrivateResource): JSX.Element {
    const route = formatRoutePath(ROUTE.DISCOVERY_DETAILS, {
        id: encodeID(privateResource.id),
    })

    return <RowTitle title={privateResource.address} route={route} />
}

function getNetworkName(privateResource: PrivateResource): string {
    return privateResource.network.name
}

function getServiceTunnelNames(locale: string): (privateResource: PrivateResource) => string {
    const formatter = new Intl.ListFormat(locale, { type: "conjunction" })

    return (privateResource) =>
        formatter.format(privateResource.serviceTunnels.map((tunnel) => tunnel.name))
}

function getLastReported(privateResource: PrivateResource): string {
    return DateUtil.format(privateResource.lastReportedAt)
}

const sortByIdDict: Record<SortedColumnId, SortById> = {
    [ColumnId.ADDRESS]: SortById.NAME,
    [ColumnId.NETWORK]: SortById.NETWORK,
    [ColumnId.UNIQUE_USERS_DEVICES]: SortById.UNIQUE_USERS_DEVICES,
    [ColumnId.LAST_REPORTED]: SortById.LAST_REPORTED,
}

const filterByIdDict: Record<FilteredColumnId, FilterById> = {
    [ColumnId.ADDRESS]: FilterById.ADDRESS,
    [ColumnId.NETWORK]: FilterById.NETWORKS,
    [ColumnId.SERVICE_TUNNELS]: FilterById.SERVICE_TUNNELS,
    [FilterById.USERS]: FilterById.USERS,
}

const filteredColumnDict: Record<FilterById, FilteredColumnId> = {
    [FilterById.NETWORKS]: ColumnId.NETWORK,
    [FilterById.SERVICE_TUNNELS]: ColumnId.SERVICE_TUNNELS,
    [FilterById.USERS]: FilterById.USERS,
    [FilterById.ADDRESS]: ColumnId.ADDRESS,
}
