import { LanguageKey } from "../../../pre-v3/services/localization/languages/en-US.language"
import { StatusType } from "../../../utils/StatusType.utils"
import { AccessTierRes, StatusRes } from "../../api/AccessTier.api"

export interface NetworkSettings {
    cidrs?: string[]
    domains?: string[]
}

export const defaultUdpPortNumber = 51_820

interface TunnelEnduser {
    udp_port_number: number
    keepalive?: number
    cidrs?: string[]
    domains?: string[]
}

export function getTunnelEnduserReq(
    udpPortNumber?: number,
    networkSettings?: NetworkSettings,
    tunnelEnduser?: TunnelEnduser
): TunnelEnduser {
    return {
        ...tunnelEnduser,
        udp_port_number: udpPortNumber ?? tunnelEnduser?.udp_port_number ?? defaultUdpPortNumber,
        keepalive: 20,
        domains: networkSettings?.domains,
        cidrs: networkSettings?.cidrs,
    }
}

export interface AdvancedSettings {
    metricsCollectionAddress?: string
    eventsRateLimiting?: AdvancedSettingsStatus
    eventKeyRateLimiting?: AdvancedSettingsStatus
    forwardTrustCookie?: AdvancedSettingsStatus
    enableStrictTransport?: AdvancedSettingsStatus
    enablePrivateResourceDiscovery?: AdvancedSettingsStatus
}

export enum AdvancedSettingsStatus {
    DEFAULT = "default",
    ENABLED = "enabled",
    DISABLED = "disabled",
}

export enum Status {
    PENDING = "PENDING",
    REPORTING = "REPORTING",
    INACTIVE = "INACTIVE",
    TERMINATED = "TERMINATED",
}

export const statusFromResDict: Record<StatusRes, Status> = {
    Reporting: Status.REPORTING,
    Inactive: Status.INACTIVE,
    Terminated: Status.TERMINATED,
    Pending: Status.PENDING,
}

export const statusLabelKey: Record<Status, LanguageKey> = {
    [Status.REPORTING]: "reporting",
    [Status.INACTIVE]: "inactive",
    [Status.TERMINATED]: "terminated",
    [Status.PENDING]: "pending",
}

export const statusTypeKey: Record<Status, StatusType> = {
    [Status.REPORTING]: StatusType.SUCCESS,
    [Status.INACTIVE]: StatusType.WARNING,
    [Status.TERMINATED]: StatusType.ERROR,
    [Status.PENDING]: StatusType.DISABLED,
}

export function getNetagentVersions(accessTierRes: AccessTierRes): string[] {
    const versions = new Set<string>()

    accessTierRes.netagents.forEach((netagentRes) => {
        if (
            accessTierRes.status === "Terminated"
                ? netagentRes.Status === "Terminated"
                : netagentRes.Status !== "Terminated"
        ) {
            versions.add(netagentRes.Version)
        }
    })

    return [...versions]
}
