import React from "react"

export interface BroadcastChannelService {
    initialize(logout: LogoutFn): void
    destroy(): void
    broadcastMessage(props: BroadCaseMessageProps): void
}

type LogoutFn = (inactive?: boolean) => void

export function useBroadcastChannelService(): BroadcastChannelService {
    const bcRef = React.useRef<BroadcastChannel>()
    const [logout, setLogout] = React.useState<LogoutFn>()

    const broadcastListener = React.useCallback(
        (event: { data?: BroadCaseMessageProps }) => {
            if (event?.data?.type === "logout") {
                logout?.(event?.data?.payload)
            }
        },
        [logout]
    )

    const initialize = React.useCallback(
        (logout: LogoutFn) => {
            if (bcRef.current) return
            setLogout(() => logout)
            bcRef.current = new BroadcastChannel("BANYAN_INTER_TAB_MSG_CHANNEL")
            bcRef.current.addEventListener("message", broadcastListener)
        },
        [bcRef.current]
    )

    const destroy = React.useCallback(() => {
        setLogout(undefined)
        bcRef.current?.removeEventListener("message", broadcastListener)
    }, [bcRef.current])

    const broadcastMessage = React.useCallback(
        (props: BroadCaseMessageProps) => {
            const { type, payload } = props
            bcRef.current?.postMessage({ type, payload })
        },
        [bcRef.current]
    )

    return React.useMemo(
        () => ({
            initialize,
            destroy,
            broadcastMessage,
        }),
        [initialize, destroy, broadcastMessage]
    )
}

interface BroadCaseMessageProps {
    type: "updateLastInteractionTime" | "startActivityMonitor" | "stopActivityMonitor" | "logout"
    payload?: any
}
