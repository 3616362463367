import React from "react"
import styles from "./StatusCellRenderer.module.scss"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faCheckCircle, faPauseCircle, faMinusCircle } from "@fortawesome/pro-solid-svg-icons"
import classNames from "classnames/bind"
import { DataPolicyStatus } from "../../svc"

interface Props {
    data: {
        status: DataPolicyStatus
    }
}

export function StatusCellRenderer(props: Props) {
    switch (props.data.status) {
        case DataPolicyStatus.ENFORCING:
            return <StatusEnforcing />
        case DataPolicyStatus.PERMISSIVE:
            return <StatusReporting />
        case DataPolicyStatus.INACTIVE:
            return <StatusInactive />
        default:
            return <StatusInactive />
    }
}

function StatusEnforcing() {
    return (
        <div className={styles.container}>
            <div className={classNames(styles.statusIcon, styles.statusIconEnforcing)}>
                <FontAwesomeIcon icon={faCheckCircle} />
            </div>
            <div className={styles.statusLabel}>{DataPolicyStatus.ENFORCING}</div>
        </div>
    )
}

function StatusReporting() {
    return (
        <div className={styles.container}>
            <div className={classNames(styles.statusIcon, styles.statusIconPermissive)}>
                <FontAwesomeIcon icon={faMinusCircle} />
            </div>
            <div className={styles.statusLabel}>{DataPolicyStatus.PERMISSIVE}</div>
        </div>
    )
}

function StatusInactive() {
    return (
        <div className={styles.container}>
            <div className={classNames(styles.statusIcon, styles.statusIconInactive)}>
                <FontAwesomeIcon icon={faPauseCircle} />
            </div>
            <div className={styles.statusLabel}>{DataPolicyStatus.INACTIVE}</div>
        </div>
    )
}
