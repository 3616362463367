import * as React from "react"
import styles from "./SectionScreen.module.scss"
import { UpgradeButton } from "../upgrade-button/UpgradeButton"
import UpgradeIDPIllustration from "../../../images/upgrade-idp.svg"
import classnames from "classnames/bind"
import { useServiceLocalization } from "../../services"
import { Button, ButtonElement, ButtonType } from "../../../components/button/Button.component"

export function SectionScreen({
    title,
    description,
    className,
    onConfigureButtonClick,
    disableButton,
}: {
    title?: string
    description: React.ReactNode
    className?: string
    onConfigureButtonClick?: Function
    disableButton?: boolean
}) {
    const ls = useServiceLocalization()
    return (
        <table className={classnames(styles.form, className)}>
            <tr>
                <td className={styles.illustrationContainer}>
                    <img
                        alt="upgrade illustration"
                        src={UpgradeIDPIllustration}
                        className={styles.upgradeIllustration}
                    />
                </td>
                <td className={styles.container}>
                    {title && <h2>{title}</h2>}
                    <p className={styles.description}>{description}</p>
                    {!disableButton &&
                        (onConfigureButtonClick ? (
                            <Button
                                buttonType={ButtonType.PRIMARY}
                                asElement={ButtonElement.BUTTON}
                                onClick={() => onConfigureButtonClick()}
                            >
                                {ls.getString("configure")}
                            </Button>
                        ) : (
                            <UpgradeButton className={styles.upgradeSpacing} />
                        ))}
                </td>
            </tr>
        </table>
    )
}
