import { UrlUtil } from "../../pre-v3/utils/Url.util"
import { BaseApi } from "./Base.api"

export class ReportApi extends BaseApi {
    public getServiceUserAccessedByDevice(
        getReportParams: GetServiceUserAccessedByDeviceParams
    ): Promise<GetServiceUserAccessedByDeviceRes> {
        const params = UrlUtil.convertToURLSearchParams(getReportParams).toString()

        return this.get(`/api/v2/report?${params}`, {
            default: this.localization.getString("failedToLoadDeviceActivity"),
        })
    }

    public getServicesAccessedByServiceAccount(
        getReportParams: GetServicesAccessedByServiceAccountParams
    ): Promise<GetServicesAccessedByServiceAccountRes> {
        const params = UrlUtil.convertToURLSearchParams(getReportParams).toString()

        return this.get(`/api/v2/report?${params}`, {
            default: this.localization.getString(
                "failedToGetSomething",
                this.localization.getString("accessActivity")
            ),
        })
    }
}

interface BaseGetReportParams {
    start_time: number
    end_time: number
    limit?: 10 | 20 | 50
}

interface GetServiceUserAccessedByDeviceParams extends BaseGetReportParams {
    report_type: "service_user_accessed_by_device"
    serial_number: string
}

interface GetServiceUserAccessedByDeviceRes {
    service_endusers: ServiceEndUsersRes[]
}

export interface ServiceEndUsersRes {
    email: string
    last_authorized_timestamp: number
    last_unauthorized_timestamp: number
    service_id: string
    service_name: string
    user_name: string
}

interface GetServicesAccessedByServiceAccountParams extends BaseGetReportParams {
    report_type: "services_accessed_by_service_account"
    service_account_id: string
}

interface GetServicesAccessedByServiceAccountRes {
    services: ServiceRes[]
}

export interface ServiceRes {
    service_id: string
    service_name: string
    last_authorized_timestamp: number
}
