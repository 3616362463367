import React from "react"
import classNames from "classnames/bind"

import styles from "./ButtonAsLink.module.scss"

export interface Props extends React.ButtonHTMLAttributes<HTMLButtonElement> {
    brand?: boolean
}

export const ButtonAsLink = React.forwardRef<HTMLButtonElement, Props>((props, ref) => {
    const { brand, className, ...buttonProps } = props

    return (
        <button
            {...buttonProps}
            className={classNames(className, styles.button, { [styles.brand]: brand })}
            ref={ref}
        />
    )
})

ButtonAsLink.displayName = "ButtonAsLink"
