import React from "react"
import { useHistory } from "react-router-dom"

import { useServiceLocalization } from "../../../../../../../pre-v3/services"
import { NewTrustProfile } from "../../../../../../services/TrustProfile.service"
import { DetailsAndAssignmentStep } from "./useCreateTrustProfile.hook"
import { DetailsAndAssignmentForm } from "../shared/DetailsAndAssignmentForm.component"

interface Props {
    currentStep: DetailsAndAssignmentStep
    onError(error: string): void
    onFillDetailsAndAssignment(profile: NewTrustProfile): void
}

export function DetailsAndAssignment(props: Props): JSX.Element {
    const history = useHistory()
    const localization = useServiceLocalization()

    return (
        <DetailsAndAssignmentForm
            initialValue={props.currentStep.profile ?? props.currentStep.profileToCopy}
            onSubmit={props.onFillDetailsAndAssignment}
            onCancel={history.goBack}
            enableReset
            submitText={localization.getString("continue")}
        />
    )
}
