import React from "react"
import { DateRange } from "../date-range/DateRange.component"
import { TimeRange } from "../time-range/TimeRange.component"
import { useServiceLocalization } from "../../../pre-v3/services/localization/Localization.service"

import { DateInput } from "../date-input/DateInput.component"
import { DateUtil, TimeRange as TimeRangeType } from "../../../pre-v3/utils/Date.util"
import { Button } from "../button/Button.component"

import styles from "./DateTimePicker.module.scss"
import { timeDefaults } from "../shared/datetime"

enum DateTimeStateType {
    SingleDate = "singleDate",
    DateRange = "dateRange",
    Empty = "empty",
}

type DateTimeState =
    | { type: DateTimeStateType.SingleDate; date?: string }
    | { type: DateTimeStateType.DateRange; startDate?: string; endDate?: string }
    | { type: DateTimeStateType.Empty }

export interface DateTimeProps {
    onConfirm?: (timeRange: TimeRangeType) => void
    singleDateLabel: string
    rangeOfDatesLabel: string
    timeRangeLabel: string
    orDivider?: string
    toDivider?: string
    timeRangeValue?: TimeRangeType
    minDays?: number
    minDateEnabled?: boolean
    maxDateEnabled?: boolean
}

export function DateTimePicker(props: DateTimeProps) {
    const {
        onConfirm,
        singleDateLabel,
        rangeOfDatesLabel,
        orDivider,
        toDivider,
        timeRangeLabel,
        timeRangeValue,
        minDays = timeDefaults.minDays,
        minDateEnabled = false,
        maxDateEnabled = false,
    } = props

    const parseSingleorRangeDate = () => {
        if (timeRangeValue === undefined) return { type: DateTimeStateType.Empty }
        if (timeRangeValue?.delta === 0) {
            return {
                type: DateTimeStateType.SingleDate,
                date: timeRangeValue?.start
                    ? DateUtil.formatToLocalDateStr(timeRangeValue?.start)
                    : "",
            }
        } else {
            return {
                type: DateTimeStateType.DateRange,
                startDate: timeRangeValue?.start
                    ? DateUtil.formatToLocalDateStr(timeRangeValue?.start)
                    : "",
                endDate: timeRangeValue?.end
                    ? DateUtil.formatToLocalDateStr(timeRangeValue?.end)
                    : "",
            }
        }
    }

    const [dateState, setDateState] = React.useState<DateTimeState>(parseSingleorRangeDate)
    const [selectedStartTime, setSelectedStartTime] = React.useState<string | undefined>()
    const [selectedEndTime, setSelectedEndTime] = React.useState<string | undefined>()

    const ls = useServiceLocalization()

    const handleSingleDateChange = (date?: string) => {
        setDateState({ type: DateTimeStateType.SingleDate, date: date })
    }

    const handleDateRangeChange = (startDate?: string, endDate?: string) => {
        setDateState({ type: DateTimeStateType.DateRange, startDate, endDate })
    }

    const handleTimeChange = (startTime?: string, endTime?: string) => {
        setSelectedStartTime(startTime)
        setSelectedEndTime(endTime)
    }

    const handleDateValidation = () => {
        swapDates()
    }

    const handleTimeValidation = () => {
        swapTimes()
    }

    // swap dates if the start date is greater than the end date
    const swapDates = () => {
        if (dateState.type === DateTimeStateType.DateRange) {
            const start = dateState.startDate ? new Date(dateState.startDate) : undefined
            const end = dateState.endDate ? new Date(dateState.endDate) : undefined

            if (start && end && start > end) {
                setDateState({
                    type: DateTimeStateType.DateRange,
                    startDate: dateState.endDate,
                    endDate: dateState.startDate,
                })
            }
        }
    }

    // swap times if the start time is greater than the end time
    const swapTimes = () => {
        const placeholderDate = "01/01/2000"

        if (selectedStartTime && selectedEndTime) {
            const start = new Date(`${placeholderDate} ${selectedStartTime}`)
            const end = new Date(`${placeholderDate} ${selectedEndTime}`)

            if (start > end) {
                setSelectedStartTime(selectedEndTime)
                setSelectedEndTime(selectedStartTime)
            }
        }
    }

    const addTimeToDates = (date: string, time: string): number => {
        const dateSplit = date.split("-")
        // arrange the date to be mm-dd-yyyy
        const dateArranged = `${dateSplit[1]}-${dateSplit[2]}-${dateSplit[0]}`

        const dateObj = new Date(dateArranged)
        const selectedTime = time.split(":")

        dateObj.setHours(Number(selectedTime[0]))
        dateObj.setMinutes(Number(selectedTime[1]))
        dateObj.setSeconds(Number(selectedTime[2]))

        return dateObj.getTime()
    }

    const buildTimeRangeObject = () => {
        let selectedStart: string | undefined
        let selectedEnd: string | undefined
        let defaultMinDate: number | undefined

        // If no time is selected, set to default
        const startTime = selectedStartTime ? `${selectedStartTime}:00` : timeDefaults.startTime
        const endTime = selectedEndTime ? `${selectedEndTime}:59` : timeDefaults.endTime

        // If single date is selected, set start and end to the same date
        switch (dateState.type) {
            case "singleDate":
                selectedStart = dateState.date
                selectedEnd = dateState.date
                break
            case "dateRange":
                selectedStart = dateState.startDate
                selectedEnd = dateState.endDate
                break
            default:
                return
        }

        // If no start date is selected, set to default min date before selected end date
        if (!selectedStart && selectedEnd) {
            const date = new Date(
                addTimeToDates(selectedEnd, endTime) - getMinDaysInTime()
            ).toLocaleDateString()
            defaultMinDate = addTimeToDates(date, startTime) || 0
        }

        // If no end date is selected, set current date
        if (selectedStart && !selectedEnd) {
            const date = new Date(
                addTimeToDates(new Date().toDateString(), startTime)
            ).toLocaleDateString()
            selectedEnd = date
        }

        // Add time to start and end dates
        const start = selectedStart ? addTimeToDates(selectedStart, startTime) : defaultMinDate
        const end = selectedEnd ? addTimeToDates(selectedEnd, endTime) : 0
        const delta = start && end ? end - start : 0

        return {
            start,
            end,
            delta,
            name: "Custom",
        }
    }

    const handleConfirm: React.FormEventHandler = (e) => {
        e.preventDefault()
        e.stopPropagation()
        if (dateState.type === DateTimeStateType.Empty) return

        swapDates()
        swapTimes()
        const timeRange = buildTimeRangeObject()
        timeRange !== undefined && onConfirm?.(timeRange as TimeRangeType)
    }

    // used to get the max date for the date range
    const getToday = (): string | undefined => {
        if (!maxDateEnabled) return

        const today = DateUtil.formatToISOdate(Date.now())
        return today
    }

    const getMinDaysInTime = (): number => {
        return minDays * DateUtil.DAY
    }

    // used to get the min date for the date range
    // min date requires ISO date format
    const getMinDaysAgo = (skipMinDatesEnabled = false): string | undefined => {
        if (!minDateEnabled && !skipMinDatesEnabled) return

        const dateAgo = Date.now() - getMinDaysInTime()
        return DateUtil.formatToISOdate(dateAgo)
    }

    const timeProps = {
        startTime: selectedStartTime || "",
        endTime: selectedEndTime || "",
        onTimeChange: handleTimeChange,
        startTimeProps: {
            name: "filterStartTime",
            onBlur: handleTimeValidation,
            "aria-label": "filter Start Time",
        },
        endTimeProps: {
            name: "filterEndTime",
            onBlur: handleTimeValidation,
            "aria-label": "filter End Time",
        },
        divider: toDivider,
    }

    const dateRangeProps = {
        startDate: dateState.type === "dateRange" ? dateState.startDate : "",
        endDate: dateState.type === "dateRange" ? dateState.endDate : "",
        onDateChange: handleDateRangeChange,
        divider: toDivider,
        startDateProps: {
            name: "filterStartDate",
            max: getToday(),
            min: getMinDaysAgo(),
            onBlur: handleDateValidation,
            "aria-label": "filter Start Date",
        },
        endDateProps: {
            name: "filterEndDate",
            max: getToday(),
            min: getMinDaysAgo(),
            onBlur: handleDateValidation,
            "aria-label": "filter End Date",
        },
    }

    return (
        <form onSubmit={handleConfirm}>
            <div className={styles.DateTimePicker}>
                <div className={styles.dateRangeSection}>
                    <div className={styles.singleDate}>
                        {singleDateLabel}
                        <DateInput
                            value={dateState.type === "singleDate" ? dateState.date : ""}
                            onChangeValue={handleSingleDateChange}
                            max={maxDateEnabled ? getToday() : undefined}
                            min={minDateEnabled ? getMinDaysAgo() : undefined}
                            required={dateState.type === DateTimeStateType.Empty}
                            aria-label="filter Single Date"
                        />
                    </div>
                    <span>{orDivider}</span>
                    <div className={styles.rangeOfDates}>
                        {rangeOfDatesLabel}
                        <DateRange {...dateRangeProps} />
                    </div>
                </div>
                <div className={styles.sectionDivider} />
                <div className={styles.filterFooter}>
                    <div className={styles.timeRangeSection}>
                        {timeRangeLabel}
                        <TimeRange {...timeProps} />
                    </div>
                    <Button className={styles.confirmButton} brand type="submit">
                        {ls.getString("ok")}
                    </Button>
                </div>
            </div>
        </form>
    )
}
