import React, { ReactNode, SyntheticEvent } from "react"
import { RouteComponentProps } from "react-router"

import { ROUTE, formatRoutePath } from "../../../../../../routes"
import { LocalizationService } from "../../../../../services/localization/Localization.service"
import ActionBarService from "../../../../../services/ActionBar.service"
import { SettingsService, ServiceBundle } from "../../../../../services/Settings.service"
import { Bind } from "../../../../../decorators/Bind.decorator"
import { SelectItem } from "../../../../../utils/SelectValue.util"
import { ManageService, ServiceManage } from "../../../../../services/Manage.service"
import { decodeID, encodeID } from "../../../../../utils/Url.util"
import { ModalService } from "../../../../../services/Modal.service"
import EditServiceBundleTemplate from "./EditServiceBundle.template"

export class EditServiceBundle extends React.Component<
    RouteComponentProps<EditServiceBundleRouteParams>,
    EditServiceBundleState
> {
    constructor(props: RouteComponentProps<EditServiceBundleRouteParams>) {
        super(props)
        try {
            this.bundleName = decodeID(this.props.match.params.id)
        } catch {
            this.state.loading = false
        }
    }

    public state: EditServiceBundleState = {
        loading: true,
        bulkConnect: true,
        serviceIds: [],
        services: [],
    }

    public render(): ReactNode {
        return EditServiceBundleTemplate.call(this)
    }

    public componentDidMount(): void {
        if (this.bundleName) {
            this.actionBarService.setItems(
                this.bundleName || this.localizationService.getString("editServiceBundle"),
                [
                    {
                        label: this.localizationService.getString("desktopAndMobile"),
                    },
                    {
                        label: this.localizationService.getString("serviceBundles"),
                        href: formatRoutePath(ROUTE.BUNDLES_DETAILS, {
                            id: encodeID(this.bundleName),
                        }),
                    },
                    {
                        label: this.bundleName,
                    },
                ]
            )
            this.fetchData()
        } else {
            this.setState({
                loading: false,
                error: this.localizationService.getString("serviceBundleNotFound"),
            })
        }
    }

    public componentWillUnmount(): void {
        this.actionBarService.unregisterRefreshFn(this.fetchData)
    }

    private localizationService: LocalizationService = new LocalizationService()
    private actionBarService: ActionBarService = new ActionBarService()
    private settingsService: SettingsService = new SettingsService()
    private manageService: ManageService = new ManageService()
    private modalService: ModalService = new ModalService()
    private bundleName: string
    private bundleId: string

    private bulkConnectOptions: SelectItem[] = [
        {
            displayName: this.localizationService.getString(
                "enableOneClickConnectivityToAllServicesInBundle"
            ),
            value: true,
        },
        {
            displayName: this.localizationService.getString(
                "disableOneClickConnectivityToAllServicesInBundle"
            ),
            value: false,
        },
    ]

    @Bind
    private onBulkConnectChange(value: boolean): void {
        if (this.state.serviceBundle) {
            this.state.serviceBundle.bulkConnect = value
            this.setState({ serviceBundle: this.state.serviceBundle })
        }
    }

    @Bind
    private onServiceChange(value: string[]): void {
        this.state.serviceIds = value
        this.setState({ serviceIds: this.state.serviceIds })
    }

    @Bind
    private onSubmit(event: SyntheticEvent): void {
        this.setState({ error: "", success: "" })
        event.preventDefault()
        if (
            event.target &&
            this.state.serviceBundle?.bundleId &&
            this.state.serviceIds.length > 0
        ) {
            this.bundleId = this.state.serviceBundle.bundleId
            const form: { [key: string]: HTMLInputElement } = <any>event.target
            const serviceBundleData: ServiceBundle = {
                name: form.name.value,
                desc: form.description.value,
                bulkConnect: this.state.serviceBundle.bulkConnect,
                serviceIds: this.state.serviceIds,
            }
            this.setState({ loading: true })
            this.settingsService.updateServiceBundle(serviceBundleData, this.bundleId).then(
                () => {
                    this.props.history.push(
                        formatRoutePath(ROUTE.BUNDLES_DETAILS, {
                            id: encodeID(serviceBundleData.name),
                        })
                    )
                },
                (err: Error) => {
                    this.setState({ error: err.message, loading: false })
                }
            )
        } else {
            this.setState({
                loading: false,
                error: this.localizationService.getString("serviceBundleNotFound"),
            })
        }
    }

    @Bind
    private onOpenDeleteModal(): void {
        this.modalService
            .openConfirmation(
                this.localizationService.getString("confirmServiceBundleDeletion"),
                this.localizationService.getString("deleteServiceBundleExplanation")
            )
            .onClose(() => {
                if (!this.state.serviceBundle?.bundleId) return

                const bundleId = this.state.serviceBundle.bundleId
                this.setState({ loading: true, error: "", success: "" })
                this.settingsService.deleteServiceBundle(bundleId).then(
                    () => {
                        this.props.history.push(ROUTE.BUNDLES)
                    },
                    (err: Error) => {
                        this.setState({ error: err.message, loading: false })
                    }
                )
            })
    }

    @Bind
    private fetchData(): void {
        this.setState({ error: "", success: "", loading: true })
        this.settingsService
            .getServiceBundle(this.bundleName)
            .then(
                (serviceBundle: ServiceBundle | undefined) => {
                    if (serviceBundle) {
                        this.manageService.getRegisteredServices().then(
                            (services: ServiceManage[]) => {
                                this.setState({
                                    services: services.map((service) => {
                                        return { displayName: service.name, value: service.id }
                                    }),
                                })
                            },
                            () => {
                                this.setState({
                                    error: this.localizationService.getString(
                                        "failedToLoadServices"
                                    ),
                                })
                            }
                        )
                        this.setState({
                            serviceBundle: serviceBundle,
                            serviceIds: serviceBundle.serviceIds.map((s) => s),
                        })
                    } else {
                        this.setState({
                            loading: false,
                            error: this.localizationService.getString("serviceBundleNotFound"),
                        })
                    }
                },
                (err: Error) => {
                    this.setState({ error: err.message, loading: false })
                }
            )
            .finally(() => {
                this.setState({ loading: false })
            })
    }
}

interface EditServiceBundleRouteParams {
    id: string
}

interface EditServiceBundleState {
    error?: string
    bundleId?: string
    success?: string
    loading?: boolean
    serviceBundle?: ServiceBundle
    bulkConnect?: boolean
    serviceIds: string[]
    services?: SelectItem[]
}
