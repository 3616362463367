import { UseQueryResult, useQuery } from "@tanstack/react-query"
import { AnalyticsApi, OrgAnalyticsRes } from "../api/Analytics.api"
import { DateUtil } from "../../pre-v3/utils/Date.util"

export function useGetOrgAnalytics(
    options?: QueryOptions<OrgAnalytics>
): UseQueryResult<OrgAnalytics, string> {
    const analyticsApi = new AnalyticsApi()

    return useQuery({
        ...options,
        queryKey: ["anayticsService.getOrgAnalytics"],
        queryFn: async () => {
            const response = await analyticsApi.getOrgAnalytics()

            return mapOrgAnalytics(response)
        },
    })
}

function mapOrgAnalytics(analytics: OrgAnalyticsRes): OrgAnalytics {
    return {
        orgId: analytics.org_id,
        orgName: analytics.org_name,
        edition: analytics.edition,
        createdAt: DateUtil.formatLargeTimestamp(analytics.created_at),
        updatedAt: DateUtil.formatLargeTimestamp(analytics.updated_at),
        licenseCount: analytics.license_count,
    }
}

export interface OrgAnalytics {
    orgId: string
    orgName: string
    edition: "team" | "enterprise" | "unlimited"
    createdAt: string
    updatedAt: string
    licenseCount: number
}
