import { Singleton } from "../decorators/Singleton.decorator"
import { LocalizationService } from "./localization/Localization.service"
import { TrustScoreApi, TrustScoreExclusion as Exclusion } from "../api/TrustScore.api"
import { TrustFactorName } from "../api/TrustScore.api"
import { DeviceOwnership } from "./Settings.service"
import { SelectItem } from "../utils/SelectValue.util"

@Singleton("TrustFactorService")
export class TrustFactorService {
    public async getExclusions(): Promise<TrustScoreExclusion[]> {
        return (await this.trustScoreApi.getExclusions()).exclusions
    }

    private async getExclusionsForTrustFactor(
        factor: TrustFactorName
    ): Promise<TrustScoreExclusion[]> {
        return (await this.trustScoreApi.getExclusions()).exclusions.filter(
            (exclusion) => exclusion.factor_name === factor
        )
    }

    public addExclusions(...exclusions: TrustScoreExclusion[]): Promise<TrustScoreExclusion> {
        return this.trustScoreApi.addExclusions(...exclusions)
    }

    public deleteExclusions(...exclusions: TrustScoreExclusion[]): Promise<void> {
        return this.trustScoreApi.deleteExclusions(...exclusions)
    }

    public async getAppliedDevicesForTrustFactor(factor: TrustFactorName): Promise<SelectItem[]> {
        const exclusions = await this.getExclusionsForTrustFactor(factor)

        // look for any exclusions with the matching name
        const excluded: string[] = exclusions.map(({ exclusion_value }) => exclusion_value)

        return Object.entries(this.deviceOwnershipTypes())
            .filter(([value]) => !excluded.includes(value))
            .map(([value, displayName]) => ({
                value: value,
                displayName,
            }))
    }

    deviceOwnershipTypes(): { [key in DeviceOwnership]: string } {
        return {
            [DeviceOwnership.EMPLOYEE]: this.ls.getString("employeeOwned"),
            [DeviceOwnership.SHARED]: this.ls.getString("corporateShared"),
            [DeviceOwnership.DEDICATED]: this.ls.getString("corporateDedicated"),
            [DeviceOwnership.OTHER]: this.ls.getString("other"),
        }
    }

    private ls: LocalizationService = new LocalizationService()
    private trustScoreApi = new TrustScoreApi()
}

export type TrustScoreExclusion = Exclusion
