import React, { ReactNode } from "react"

import { Bind } from "../../decorators/Bind.decorator"
import { Debounce } from "../../decorators/Debounce.decorator"
import { AppNavLink } from "../../services/shared/AppNavLink"
import LeftNavTemplate from "./LeftNav.template"

export class LeftNav extends React.Component<LeftNavProps, LeftNavState> {
    public state: LeftNavState = { collapsed: false }

    public render(): ReactNode {
        return LeftNavTemplate.call(this)
    }

    public componentDidMount(): void {
        window.addEventListener("resize", this.onResize)
        this.prevWidth = window.innerWidth
        if (this.prevWidth < LeftNav.COLLAPSE_WIDTH) {
            this.setState({ collapsed: true })
        }
    }

    public componentWillUnmount(): void {
        window.removeEventListener("resize", this.onResize)
    }

    private static COLLAPSE_WIDTH: number = 1000
    private prevWidth: number

    @Bind
    private toggleCollapsed(): void {
        this.setState({ collapsed: !this.state.collapsed }, () => {
            if (window) {
                window.dispatchEvent(new Event("resize"))
            }
        })
    }

    @Debounce
    private onResize() {
        const currentWidth: number = window.innerWidth
        if (this.prevWidth >= LeftNav.COLLAPSE_WIDTH && currentWidth < LeftNav.COLLAPSE_WIDTH) {
            this.setState({ collapsed: true })
        } else if (
            this.prevWidth < LeftNav.COLLAPSE_WIDTH &&
            currentWidth >= LeftNav.COLLAPSE_WIDTH
        ) {
            this.setState({ collapsed: false })
        }
        this.prevWidth = currentWidth
    }
}

interface LeftNavProps {
    links: AppNavLink[]
}

interface LeftNavState {
    collapsed: boolean
}
