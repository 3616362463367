import React from "react"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faSpinner } from "@fortawesome/pro-solid-svg-icons"
import classNames from "classnames"

import { useServiceLocalization } from "../../../pre-v3/services/localization/Localization.service"
import styles from "./Loader.module.scss"

export interface LoaderProps {
    id?: string
    children?: React.ReactNode
    isLoading?: boolean
    className?: string
    center?: boolean
    medium?: boolean
    title?: string
}

export function Loader(props: LoaderProps): JSX.Element {
    const { id, children, isLoading = true, className, center, medium, title } = props

    const localization = useServiceLocalization()

    return isLoading ? (
        <div id={id} className={classNames(styles.loaderContainer, center && styles.center)}>
            <FontAwesomeIcon
                spin
                icon={faSpinner}
                className={classNames(styles.loader, className, medium && styles.medium)}
                title={title ?? localization.getString("loading")}
            />
        </div>
    ) : (
        <React.Fragment>{children}</React.Fragment>
    )
}
