import React from "react"

import { Button, ErrorBanner, InfoBanner, Form } from "../../../../../components"
import {
    ButtonElement,
    ButtonType,
    Button as ButtonV4,
} from "../../../../../../components/button/Button.component"
import styles from "./TrustProviderIssuingCerts.module.scss"
import { FormRow } from "../../../../../../components/form/FormRow.component"

export default function () {
    return (
        <div className={styles.pagePadded}>
            {this.state.error && <ErrorBanner>{this.state.error}</ErrorBanner>}
            {this.state.success && <InfoBanner>{this.state.success}</InfoBanner>}
            <div className={styles.issuingCertsContainer}>
                {this.state.issuingCert && (
                    <Form onSubmit={this.onSubmit} className={styles.issuingCertsContainer}>
                        <FormRow
                            label={this.localizationService.getString("issuingCaCertificate")}
                            className={styles.formSection}
                            htmlFor="issuingCaCertificate"
                        >
                            <textarea
                                className={styles.certTextArea}
                                disabled
                                readOnly
                                value={this.state.issuingCert}
                            />
                        </FormRow>
                        {!this.props.disabled && (
                            <>
                                <FormRow
                                    label={this.localizationService.getString(
                                        "ifYourIssuingDescription"
                                    )}
                                >
                                    {!this.state.showCertInput && (
                                        <Button
                                            onClick={this.handleShowCertInput}
                                            disabled={!this.props.canAccessTrustProviderCerts}
                                        >
                                            {this.localizationService.getString(
                                                "addRootCaCertificate"
                                            )}
                                        </Button>
                                    )}
                                </FormRow>
                                {this.state.showCertInput && (
                                    <>
                                        <FormRow
                                            label={this.localizationService.getString(
                                                "rootCaCertificate"
                                            )}
                                            className={styles.formSection}
                                            htmlFor="rootCaCertificate"
                                        >
                                            <textarea
                                                className={styles.certTextArea}
                                                value={this.state.rootCert}
                                                onChange={this.onChange}
                                            />
                                            <div className={styles.buttons}>
                                                <ButtonV4
                                                    buttonType={ButtonType.SECONDARY}
                                                    asElement={ButtonElement.BUTTON}
                                                    onClick={() =>
                                                        this.setState({ showCertInput: false })
                                                    }
                                                >
                                                    {this.localizationService.getString("cancel")}
                                                </ButtonV4>
                                                <ButtonV4
                                                    buttonType={ButtonType.PRIMARY}
                                                    asElement={ButtonElement.BUTTON}
                                                    loading={this.state.submitLoading}
                                                    type="submit"
                                                >
                                                    {this.localizationService.getString("save")}
                                                </ButtonV4>
                                            </div>
                                        </FormRow>
                                    </>
                                )}
                            </>
                        )}
                    </Form>
                )}

                {this.state.sshCaPubKey && (
                    <FormRow
                        label={this.localizationService.getString("sshCaPublicKey")}
                        className={styles.formSection}
                        htmlFor="sshCaPublicKey"
                    >
                        <textarea
                            className={styles.certTextArea}
                            disabled
                            readOnly
                            value={this.state.sshCaPubKey}
                        />
                    </FormRow>
                )}
            </div>
        </div>
    )
}
