import React from "react"
import { Link } from "../../../../../v3/components/link/Link.component"

export default function () {
    return (
        <Link to={this.state.link} onClick={this.onClick}>
            {this.props.data.name}
        </Link>
    )
}
