import React, { FC } from "react"
import { useServiceLinks, useServiceLocalization } from "../../../pre-v3/services"
import { Button, ButtonElement, ButtonType } from "../../../components/button/Button.component"
import { AppText } from "../../../v3/components/app-text/AppText.component"
import styles from "./CallToAction.module.scss"

interface Props {
    text: string
}
export const CallToAction: FC<Props> = ({ text }) => {
    const linkService = useServiceLinks()
    const localization = useServiceLocalization()
    return (
        <div className={styles.container}>
            <AppText className={styles.topText}>{text}</AppText>
            <p className={styles.bottomText}>{localization.getString("upgradeToday")}</p>
            <Button
                asElement={ButtonElement.LINK}
                buttonType={ButtonType.PRIMARY}
                to={linkService.getLink("salesContact")}
            >
                {localization.getString("contactSales")}
            </Button>
        </div>
    )
}
