import { ICellRendererParams } from "ag-grid-community"
import React, { ReactNode, SyntheticEvent } from "react"

import { ROUTE, formatRoutePath } from "../../../../../routes"
import { Bind } from "../../../../decorators/Bind.decorator"
import { encodeID } from "../../../../utils/Url.util"
import ServicesSaasCellRendererTemplate from "./ServicesSaasCellRenderer.template"

export class ServicesSaasCellRenderer extends React.Component<
    ServicesSaasCellRendererProps,
    ServicesSaasCellRendererState
> {
    public static getDerivedStateFromProps(
        props: ServicesSaasCellRendererProps
    ): ServicesSaasCellRendererState {
        return {
            link: formatRoutePath(ROUTE.SAAS_APPS_DETAILS, {
                id: encodeID(props.data.id || props.data.serviceId),
            }),
        }
    }

    public state: ServicesSaasCellRendererState = { link: "" }

    public render(): ReactNode {
        return ServicesSaasCellRendererTemplate.call(this)
    }

    public refresh(): boolean {
        return true
    }

    @Bind
    private onClick(event: SyntheticEvent): void {
        event.preventDefault()
        this.props.context.history.push(this.state.link)
    }
}

interface ServicesSaasCellRendererProps extends ICellRendererParams {
    context: any
}

interface ServicesSaasCellRendererState {
    link: string
}
