import React, { useEffect } from "react"
import classNames from "classnames/bind"
import styles from "./SystemLogsDetailCellRenderer.module.scss"
import { JsonTextArea } from "../../../../components/json-text-area/JsonTextArea.component"
import { MonitorSystemLog } from "../../../../services/SystemLogs.service"

interface Props {
    reactContainer: HTMLElement
    data: MonitorSystemLog
}

export function SystemLogsDetailCellRenderer({ reactContainer, data }: Props) {
    useEffect(() => {
        if (reactContainer) {
            reactContainer.style.height = "100%"
        }
    })

    return (
        <div className={classNames(styles.container)}>
            <JsonTextArea
                className={styles.json}
                height="230px"
                initialValue={data.raw ? data.raw : ""}
                readOnly
                defaultFoldChildren
            />
        </div>
    )
}
