import React, { useEffect, useRef, useState } from "react"
import { Tooltip } from "@mui/material"
import classNames from "classnames/bind"
import styles from "./OverflowTooltip.module.scss"

export type OverflowTooltipProps = {
    children: React.ReactNode | string
    className?: string
}

export function OverflowTooltip({ children, className, ...rest }: OverflowTooltipProps) {
    const [isOverflowed, setIsOverflowed] = useState(false)
    const textElementRef = useRef<HTMLDivElement>(null)

    useEffect(() => {
        checkOverflow()

        window.addEventListener("resize", checkOverflow)

        return () => {
            window.removeEventListener("resize", checkOverflow)
        }
    }, [children])

    const checkOverflow = () => {
        if (textElementRef?.current) {
            const isOverflowed: boolean =
                textElementRef.current.scrollWidth > textElementRef.current.clientWidth
            setIsOverflowed(isOverflowed)
        }
    }

    return (
        <Tooltip title={children} disableHoverListener={!isOverflowed} {...rest}>
            <div ref={textElementRef} className={classNames(styles.overflow, className)}>
                {children}
            </div>
        </Tooltip>
    )
}
