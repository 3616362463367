import React from "react"
import { DateInput, DateInputProps } from "../date-input/DateInput.component"
import styles from "./DateRange.module.scss"

type DateInputPropsWithoutValue = Omit<DateInputProps, "value" | "onChangeValue">

export interface DateRangeProps {
    startDate?: string
    endDate?: string
    onDateChange?: (startDate?: string, endDate?: string) => void
    divider?: string
    startDateProps?: DateInputPropsWithoutValue
    endDateProps?: DateInputPropsWithoutValue
}

export function DateRange(props: DateRangeProps) {
    const { startDate, endDate, divider = "-", onDateChange, startDateProps, endDateProps } = props

    // build both start date and end date if only one is selected
    const startDateChange = (selectedDate?: string) => {
        onDateChange?.(selectedDate, endDate)
    }

    const endDateChange = (selectedDate?: string) => {
        onDateChange?.(startDate, selectedDate)
    }

    return (
        <div className={styles.container}>
            <DateInput {...startDateProps} onChangeValue={startDateChange} value={startDate} />
            {divider}
            <DateInput {...endDateProps} onChangeValue={endDateChange} value={endDate} />
        </div>
    )
}
