import { Singleton } from "../../pre-v3/decorators/Singleton.decorator"
import { BaseApi, PaginatedResponse } from "./Base.api"

@Singleton("RegisteredDomainApi")
export class RegisteredDomainApi extends BaseApi {
    public getRegisteredDomains(): Promise<
        PaginatedResponse<"registered_domains", RegisteredDomainsItemRes>
    > {
        return this.get(`/api/v2/registered_domain`, {
            default: this.localization.getString("failedToFetchRegisteredDomains"),
        })
    }

    public createRegisteredDomain(
        domainData: RegisteredDomainsItemReq
    ): Promise<RegisteredDomainsItemRes> {
        return this.post(`/api/v2/registered_domain`, domainData, {
            409: this.localization.getString(
                "somethingNamedAlreadyExists",
                this.localization.getString("domain"),
                domainData.name
            ),
            default: this.localization.getString("failedToCreateRegisteredDomain"),
        })
    }

    public getRegisteredDomainById(id: string): Promise<RegisteredDomainsItemRes> {
        return this.get(`/api/v2/registered_domain/` + encodeURIComponent(id), {
            default: this.localization.getString("failedToFetchRegisteredDomain"),
        })
    }

    public validateRegisteredDomain(id: string): Promise<void> {
        return this.post(
            `/api/v2/registered_domain/` + encodeURIComponent(id) + "/validate",
            {},
            {
                default: this.localization.getString("failedToValidateRegisteredDomain"),
            }
        )
    }

    public deleteRegisteredDomain(id: string): Promise<void> {
        return this.delete(
            `/api/v2/registered_domain/` + id,
            {},
            {
                default: this.localization.getString("failedToDeleteRegisteredDomain"),
            }
        )
    }

    public createChallengeRecord(domainName: ChallengeRecordReq): Promise<ChallengeRecordRes> {
        return this.post(`/api/v2/registered_domain_challenge`, domainName, {
            default: this.localization.getString("failedToGenerateChallengeRecord"),
        })
    }

    public getChallengeRecordById(id: string): Promise<ChallengeRecordRes> {
        return this.get(`/api/v2/registered_domain_challenge/` + encodeURIComponent(id), {
            default: this.localization.getString("failedToFetchChallengeRecord"),
        })
    }
}

export interface RegisteredDomainsItemRes {
    id: string
    name: string
    cluster_name: string
    cname: string
    description: string
    registered_domain_challenge_id?: string
    status: StatusRes
    domain_name?: string
    acme_cname?: string
    created_at: number
    created_by: string
}

export type StatusRes = "Pending" | "Verified" | "Failed"

interface RegisteredDomainsItemReq {
    name: string
    cluster_name: string
    cname: string
    description: string
    registered_domain_challenge_id?: string
}

interface ChallengeRecordReq {
    registered_domain_name: string
}

export interface ChallengeRecordRes {
    id: string
    label: string
    value: string
}
