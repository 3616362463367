import { IconProp } from "@fortawesome/fontawesome-svg-core"
import {
    faBook,
    faCheckCircle,
    faCodeBranch,
    faCopy,
    faEye,
    faEyeSlash,
    faHeadset,
    faMinus,
    faPlus,
    faRedoAlt,
    faRoute,
    faSignalAlt,
    faTrash,
    faUpload,
    faUsers,
    faLink,
    faCheck as faSimpleCheck,
    faDownload as faOutlineDownload,
} from "@fortawesome/pro-regular-svg-icons"
import {
    faArchive,
    faArrowFromLeft,
    faArrowFromRight,
    faArrowRight,
    faBoxOpen,
    faCog,
    faDownload,
    faEmptySet,
    faFileDownload,
    faFileExport,
    faHome,
    faKeySkeleton,
    faLocationCircle,
    faNetworkWired,
    faPassport,
    faPauseCircle,
    faPen,
    faPlayCircle,
    faQuestion,
    faQuestionCircle,
    faRandom,
    faSearch,
    faShieldAlt,
    faSignOut,
    faSort,
    faSpinner,
    faThList,
    faTimes,
    faUserTie,
    faExclamationTriangle,
    faInfoCircle,
    faExclamationCircle,
    faCheckCircle as faSolidCheckCircle,
    faCaretRight,
    faCaretDown,
    faTimesCircle,
    faMinusCircle,
    faCheckCircle as faCheckCircleSolid,
} from "@fortawesome/pro-solid-svg-icons"
import { faTimes as faLightTimes } from "@fortawesome/pro-light-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import React from "react"
import classNames from "classnames"

import styles from "./Icon.module.scss"

export interface IconProps {
    icon: IconType
    className?: string
    size?: IconSize
    spin?: boolean
}

export const Icon = React.forwardRef<SVGElement, IconProps>((props, ref) => {
    const { className, size = IconSize.REGULAR, icon: iconName, spin } = props

    return (
        <FontAwesomeIcon
            icon={iconTypeDict[iconName]}
            className={classNames(className, {
                [styles[size]]: size,
            })}
            spin={spin}
            forwardedRef={ref}
            fixedWidth
        />
    )
})

Icon.displayName = "Icon"

export enum IconSize {
    X_SMALL = "xs",
    SMALL = "sm",
    REGULAR = "reg",
    LARGE = "lg",
    X_LARGE = "xl",
}

export enum IconType {
    GEAR = "gear",
    KEY = "key",
    LIST = "list",
    LINK = "link",
    NETWORK = "network",
    PASSPORT = "passport",
    QUESTION = "question",
    SPINNER = "spinner",
    CROSS = "cross",
    LIGHT_CROSS = "light-cross",
    SHIELD = "shield",
    TRASH = "trash",
    PLUS = "plus",
    MINUS = "minus",
    EYE = "eye",
    EYE_SLASH = "eye-slash",
    CHECK = "check",
    CHECK_SOLID = "check-solid",
    CHECK_SIMPLE = "check-simple",
    COPY = "copy",
    UPLOAD = "upload",
    LOCATION = "location",
    REDO = "redo",
    EMPTY_SET = "empty-set",
    DOWNLOAD = "download",
    OUTLINE_DOWNLOAD = "outline-download",
    PEN = "pen",
    RANDOM = "random",
    FILE_DOWNLOAD = "file-download",
    FILE_EXPORT = "file-export",
    SORT = "sort",
    PAUSE_CIRCLE = "pause_circle",
    PLAY_CIRCLE = "play-circle",
    SEARCH = "search",
    USER = "user",
    BOX_OPEN = "box-open",
    BOX_CLOSED = "box-closed",
    QUESTION_CIRCLE = "question-circle",
    ARROW_RIGHT = "arrow-right",
    ARROW_FROM_LEFT = "arrow-from-left",
    ARROW_FROM_RIGHT = "arrow-from-right",
    HOME = "home",
    SIGN_OUT = "sign-out",
    BOOK = "book",
    USERS = "users",
    CODE_BRANCH = "code-branch",
    SIGNAL_ALT = "signal-alt",
    ROUTE = "route",
    HEADSET = "headset",
    TRIANGLE_EXCLAMATION = "triangle-exclamation",
    CIRCLE_INFO = "circle-info",
    CIRCLE_EXCLAMATION = "circle-exclamation",
    SOLID_CHECK = "solid-check",
    CARET_RIGHT = "caret-right",
    CARET_DOWN = "caret-down",
    TIMES_CIRCLE = "times-circle",
    MINUS_CIRCLE = "minus-circle",
}

const iconTypeDict: Record<IconType, IconProp> = {
    [IconType.GEAR]: faCog,
    [IconType.KEY]: faKeySkeleton,
    [IconType.LIST]: faThList,
    [IconType.NETWORK]: faNetworkWired,
    [IconType.PASSPORT]: faPassport,
    [IconType.QUESTION]: faQuestion,
    [IconType.SPINNER]: faSpinner,
    [IconType.CROSS]: faTimes,
    [IconType.LIGHT_CROSS]: faLightTimes,
    [IconType.SHIELD]: faShieldAlt,
    [IconType.TRASH]: faTrash,
    [IconType.PLUS]: faPlus,
    [IconType.MINUS]: faMinus,
    [IconType.EYE]: faEye,
    [IconType.EYE_SLASH]: faEyeSlash,
    [IconType.CHECK]: faCheckCircle,
    [IconType.CHECK_SOLID]: faCheckCircleSolid,
    [IconType.COPY]: faCopy,
    [IconType.UPLOAD]: faUpload,
    [IconType.LOCATION]: faLocationCircle,
    [IconType.REDO]: faRedoAlt,
    [IconType.EMPTY_SET]: faEmptySet,
    [IconType.DOWNLOAD]: faDownload,
    [IconType.OUTLINE_DOWNLOAD]: faOutlineDownload,
    [IconType.PEN]: faPen,
    [IconType.RANDOM]: faRandom,
    [IconType.FILE_DOWNLOAD]: faFileDownload,
    [IconType.FILE_EXPORT]: faFileExport,
    [IconType.SORT]: faSort,
    [IconType.PAUSE_CIRCLE]: faPauseCircle,
    [IconType.PLAY_CIRCLE]: faPlayCircle,
    [IconType.SEARCH]: faSearch,
    [IconType.USER]: faUserTie,
    [IconType.BOX_OPEN]: faBoxOpen,
    [IconType.BOX_CLOSED]: faArchive,
    [IconType.QUESTION_CIRCLE]: faQuestionCircle,
    [IconType.ARROW_RIGHT]: faArrowRight,
    [IconType.ARROW_FROM_LEFT]: faArrowFromLeft,
    [IconType.ARROW_FROM_RIGHT]: faArrowFromRight,
    [IconType.HOME]: faHome,
    [IconType.SIGN_OUT]: faSignOut,
    [IconType.BOOK]: faBook,
    [IconType.USERS]: faUsers,
    [IconType.CODE_BRANCH]: faCodeBranch,
    [IconType.ROUTE]: faRoute,
    [IconType.SIGNAL_ALT]: faSignalAlt,
    [IconType.HEADSET]: faHeadset,
    [IconType.TRIANGLE_EXCLAMATION]: faExclamationTriangle,
    [IconType.CIRCLE_INFO]: faInfoCircle,
    [IconType.CIRCLE_EXCLAMATION]: faExclamationCircle,
    [IconType.SOLID_CHECK]: faSolidCheckCircle,
    [IconType.CARET_RIGHT]: faCaretRight,
    [IconType.CARET_DOWN]: faCaretDown,
    [IconType.LINK]: faLink,
    [IconType.TIMES_CIRCLE]: faTimesCircle,
    [IconType.MINUS_CIRCLE]: faMinusCircle,
    [IconType.CHECK_SIMPLE]: faSimpleCheck,
}
