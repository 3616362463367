import { ICellRendererParams } from "ag-grid-community"
import classNames from "classnames"
import React from "react"

import styles from "./GridCellRenderers.module.scss"
import { Network } from "../../../v3/services/ServiceTunnelV2.service"

export function CidrCellRenderer(props: ICellRendererParams) {
    const network: Network = props.data
    const cidrs: string[] = network.privateIpRanges
    const collisions: Set<string> = props.context?.collisions?.current
    return (
        <ul className={styles.list}>
            {cidrs.map((c, idx) => (
                <li
                    key={idx}
                    className={classNames(
                        { [styles.collision]: collisions?.has?.(c) },
                        styles.item
                    )}
                >
                    {c}
                </li>
            ))}
        </ul>
    )
}

export function DomainCellRenderer(props: ICellRendererParams) {
    const network: Network = props.data
    const domains: string[] = network.privateDomains
    return (
        <ul className={styles.list}>
            {domains.map((d, idx) => (
                <li key={idx} className={styles.item}>
                    {d}
                </li>
            ))}
        </ul>
    )
}
