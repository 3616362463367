import { UseQueryResult, useQuery } from "@tanstack/react-query"

import { BaseApi } from "./Base.api"
import { EventProps } from "../../pre-v3/api/EventProps.types"

enum EventTypesQueryKeys {
    GET_EVENT_TYPES = "EventTypes/getEventTypes",
}

export class EventTypesApi extends BaseApi {
    public getEventTypes(eventGroup: EventProps["event_group"]): Promise<EventTypesRes> {
        return this.get(`/api/v1/event_types?event_group=${eventGroup}`, {
            default: this.localization.getString("errorCouldNotGetEventTypes"),
        })
    }
}

export function useGetEventTypes(
    eventGroup: EventProps["event_group"]
): UseQueryResult<EventTypesRes> {
    const eventTypesApi = new EventTypesApi()

    return useQuery<EventTypesRes, string>({
        queryKey: [EventTypesQueryKeys.GET_EVENT_TYPES, eventGroup],
        queryFn: async () => await eventTypesApi.getEventTypes(eventGroup),
    })
}

interface EventTypesRes {
    request_id: string
    error_code: number
    error_description: string
    data: string[]
}
