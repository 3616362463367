import React from "react"

import {
    OsVersionFactor,
    OsVersionFactorPlatform,
} from "../../../../../../../services/TrustProfile.service"
import {
    faApple,
    IconDefinition,
    faFedora,
    faChrome,
    faUbuntu,
    faWindows,
    faAndroid,
    faLinux,
} from "@fortawesome/free-brands-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { useServiceLocalization } from "../../../../../../../../pre-v3/services"
import styles from "./OperatingSystem.module.scss"
import classNames from "classnames/bind"
import { Input } from "../../../../../../../components/input/Input.component"
import { LanguageKey } from "../../../../../../../../pre-v3/services/localization/languages/en-US.language"
import { AppText } from "../../../../../../../components/app-text/AppText.component"
import { InfoIcon } from "../../../../../../../components/info-icon/InfoIcon.component"

interface Props {
    trustFactor: OsVersionFactor
    className?: string
    onEdit(trustFactor: OsVersionFactor): void
    disabled?: boolean
}

export function OperatingSystem(props: Props): JSX.Element {
    const ls = useServiceLocalization()

    const selectedPlatforms = Object.values(OsVersionFactorPlatform)

    function onEdit(osVersionFactorPlatform: OsVersionFactorPlatform) {
        return (value: string) => {
            props.onEdit({
                ...props.trustFactor,
                config: {
                    ...props.trustFactor.config,
                    [osVersionFactorPlatform]: value,
                },
            })
        }
    }

    return (
        <div className={classNames(styles.outerContainer, props.className)}>
            <AppText ls="selectOperatingSystemMsg" className={styles.selectedPlatformsDesc} />
            {selectedPlatforms.map((platform) => {
                return (
                    <RenderOsInput
                        icon={platformIconDict[platform]}
                        platform={platform}
                        description={ls.getString(osVersionFactorPlatformDescriptionDict[platform])}
                        placeholder={ls.getString(osVersionFactorPlatformPlaceholderDict[platform])}
                        onChange={onEdit(platform)}
                        value={props.trustFactor.config[platform]}
                        disabled={props.disabled}
                    />
                )
            })}
        </div>
    )
}

interface RenderOsInputProps {
    platform: OsVersionFactorPlatform
    icon?: IconDefinition
    onChange: (value: string) => void
    value: string
    disabled?: boolean
    description: string
    placeholder: string
}

function RenderOsInput(props: RenderOsInputProps) {
    const ls = useServiceLocalization()

    return (
        <div className={styles.container}>
            {props.icon ? (
                <FontAwesomeIcon icon={props.icon} className={styles.icon} fixedWidth />
            ) : (
                <label className={styles.label}>
                    {ls.getString(osVersionFactorPlatformLabelDict[props.platform])}
                </label>
            )}
            <div className={styles.platform}>
                {ls.getString(osVersionFactorPlatformLabelDict[props.platform])}
                <InfoIcon tooltip={props.description} />
            </div>
            <span>{ls.getString("greaterThanOrEqualToSign")}</span>
            <Input
                className={styles.select}
                value={props.value}
                onChange={(e) => {
                    props.onChange(e.target.value)
                }}
                disabled={props.disabled}
                placeholder={props.placeholder}
            />
        </div>
    )
}

const osVersionFactorPlatformLabelDict: Record<OsVersionFactorPlatform, LanguageKey> = {
    [OsVersionFactorPlatform.MACOS]: "macOS",
    [OsVersionFactorPlatform.WINDOWS]: "windows",
    [OsVersionFactorPlatform.UBUNTU]: "ubuntu",
    [OsVersionFactorPlatform.IOS]: "iOS",
    [OsVersionFactorPlatform.ANDROID]: "android",
    [OsVersionFactorPlatform.FEDORA]: "fedora",
    [OsVersionFactorPlatform.CHROME_OS]: "chromeOs",
    [OsVersionFactorPlatform.ORACLE_LINUX]: "oracleLinux",
}

const osVersionFactorPlatformDescriptionDict: Record<OsVersionFactorPlatform, LanguageKey> = {
    [OsVersionFactorPlatform.MACOS]: "macOsVersionFormat",
    [OsVersionFactorPlatform.WINDOWS]: "windowsOsVersionFormat",
    [OsVersionFactorPlatform.UBUNTU]: "ubuntuOsVersionFormat",
    [OsVersionFactorPlatform.IOS]: "iOsVersionFormat",
    [OsVersionFactorPlatform.ANDROID]: "androidOsVersionFormat",
    [OsVersionFactorPlatform.FEDORA]: "fedoraOsVersionFormat",
    [OsVersionFactorPlatform.CHROME_OS]: "chromeOsVersionFormat",
    [OsVersionFactorPlatform.ORACLE_LINUX]: "oracleLinuxOsVersionFormat",
}

const osVersionFactorPlatformPlaceholderDict: Record<OsVersionFactorPlatform, LanguageKey> = {
    [OsVersionFactorPlatform.MACOS]: "macOsVersionPlaceholder",
    [OsVersionFactorPlatform.WINDOWS]: "windowsOsVersionPlaceholder",
    [OsVersionFactorPlatform.UBUNTU]: "ubuntuOsVersionPlaceholder",
    [OsVersionFactorPlatform.IOS]: "iOsVersionPlaceholder",
    [OsVersionFactorPlatform.ANDROID]: "androidOsVersionPlaceholder",
    [OsVersionFactorPlatform.FEDORA]: "fedoraOsVersionPlaceholder",
    [OsVersionFactorPlatform.CHROME_OS]: "chromeOsVersionPlaceholder",
    [OsVersionFactorPlatform.ORACLE_LINUX]: "oracleLinuxVersionPlaceholder",
}

export const platformIconDict: Record<OsVersionFactorPlatform, IconDefinition | undefined> = {
    [OsVersionFactorPlatform.MACOS]: faApple,
    [OsVersionFactorPlatform.WINDOWS]: faWindows,
    [OsVersionFactorPlatform.UBUNTU]: faUbuntu,
    [OsVersionFactorPlatform.IOS]: undefined,
    [OsVersionFactorPlatform.ANDROID]: faAndroid,
    [OsVersionFactorPlatform.FEDORA]: faFedora,
    [OsVersionFactorPlatform.CHROME_OS]: faChrome,
    [OsVersionFactorPlatform.ORACLE_LINUX]: faLinux,
}
