import {
    QueryClient,
    UseMutationResult,
    UseQueryResult,
    useMutation,
    useQuery,
    useQueryClient,
} from "@tanstack/react-query"
import { queryClient } from "../../queryClient"
import { useServiceLocalization } from "../../pre-v3/services"
import { DateUtil } from "../../pre-v3/utils/Date.util"
import { RoleApi, RoleRes, RoleSpecReq, RolesSearchParams } from "../api/Role.api"
import { Platform } from "./shared/Platform"
import { DeviceOwnership } from "./shared/DeviceOwnership"
import { deviceOwnershipDict, platformDict, platformResDict } from "./Device.service"
import { ServiceAccount } from "./ServiceAccount.service"
import { FileUtil } from "../../pre-v3/utils/File.util"
import { OwnershipRes, PlatformRes } from "../api/Device.api"
import { ApiFunction } from "./shared/QueryKey"
import { Status, getRoleReq, getStatusFromRes, isMobileDevicesRole } from "./shared/Role"

const serviceName = "RoleService"

const serviceKeys = {
    GET_ROLES: (searchParams?: RolesSearchParams) => [
        ApiFunction.GET_ROLES,
        searchParams,
        serviceName,
    ],
    GET_ROLE: (id: string) => [ApiFunction.GET_ROLE, id, serviceName],
    GET_SERVICE_ACCOUNT_ROLES: (serviceAccount: ServiceAccount) => [
        ApiFunction.GET_SERVICE_ACCOUNT_ROLES,
        serviceAccount.id,
        serviceName,
    ],
}

export function useGetGroupNames(options?: QueryOptions<string[]>): UseQueryResult<string[]> {
    const roleApi = new RoleApi()

    return useQuery(
        ["RoleService.getGroupNames"],
        (): Promise<string[]> => {
            return roleApi.getGroups().then(({ names }) => names)
        },
        options
    )
}

function helperGetRoles(
    queryClient: QueryClient,
    searchParams?: RolesSearchParams
): Promise<Role[]> {
    const roleApi = new RoleApi()

    return queryClient.ensureQueryData({
        queryKey: serviceKeys.GET_ROLES(searchParams),
        queryFn: async () => {
            const rolesRes = await roleApi.getRoles(searchParams)

            return rolesRes.map(mapRoleFromRes)
        },
    })
}

export function useGetRoles(options?: QueryOptions<Role[]>): UseQueryResult<Role[]> {
    const queryClient = useQueryClient()

    const query = useQuery({
        ...options,
        queryKey: serviceKeys.GET_ROLES(),
        queryFn: () => helperGetRoles(queryClient),
    })

    return {
        ...query,
        refetch: () => {
            queryClient.removeQueries(serviceKeys.GET_ROLES())
            return query.refetch()
        },
    }
}

export function useGetRole(id: string, options?: QueryOptions<Role>): UseQueryResult<Role> {
    const localization = useServiceLocalization()

    const query = useQuery({
        ...options,
        queryKey: serviceKeys.GET_ROLE(id),
        queryFn: () => {
            return helperGetRoles(queryClient, { id }).then((roles) => {
                if (roles.length === 0) {
                    return Promise.reject(localization.getString("couldNotFetchRoles"))
                }

                return roles[0]
            })
        },
    })

    return {
        ...query,
        refetch: () => {
            queryClient.removeQueries(serviceKeys.GET_ROLES({ id }))
            return query.refetch()
        },
    }
}

export function useDownloadRoleSpec(
    id: string,
    option?: QueryOptions<void>
): UseMutationResult<void, unknown, void> {
    const queryClient = useQueryClient()
    const localization = useServiceLocalization()

    return useMutation({
        ...option,
        mutationFn: () => {
            return helperGetRoles(queryClient, { id }).then((roles) => {
                if (roles.length === 0) {
                    return Promise.reject(localization.getString("couldNotFetchRoles"))
                }

                FileUtil.downloadJson({ jsonValue: roles[0].roleSpec, fileName: roles[0].name })
            })
        },
    })
}

export function useCreateRole(
    option?: QueryOptions<Role>
): UseMutationResult<Role, unknown, EditableRoleFields> {
    return useInsertRole(option)
}

export function useUpdateRole(
    option?: QueryOptions<Role>
): UseMutationResult<Role, unknown, EditableRoleFields> {
    return useInsertRole(option)
}

export function useDeleteRole(
    option?: QueryOptions<void>
): UseMutationResult<void, unknown, string> {
    const roleApi = new RoleApi()

    return useMutation(
        async (roleId: string) => {
            // Note: Role should be disabled before deleted. Until BE removes this logic FE will disable and delete.
            await roleApi.disableRole(roleId)
            await roleApi.deleteRole(roleId)
        },
        {
            ...option,
            onSuccess: (_data, roleId: string) => {
                queryClient.removeQueries(serviceKeys.GET_ROLES())
                queryClient.removeQueries(serviceKeys.GET_ROLE(roleId))
                option?.onSuccess?.()
            },
        }
    )
}

export function useGetServiceAccountRoles(
    serviceAccount: ServiceAccount,
    options?: QueryOptions<Role[]>
): UseQueryResult<Role[]> {
    const queryClient = useQueryClient()

    const query = useQuery({
        ...options,
        queryKey: serviceKeys.GET_SERVICE_ACCOUNT_ROLES(serviceAccount),
        queryFn: async () => {
            const allRoles = await helperGetRoles(queryClient)
            return allRoles.filter((role) => serviceAccount.roles.includes(role.name))
        },
    })

    return {
        ...query,
        refetch: () => {
            queryClient.removeQueries(serviceKeys.GET_ROLES())
            return query.refetch()
        },
    }
}

function mapRoleFromRes(roleRes: RoleRes): Role {
    const roleSpec: RoleSpecReq = JSON.parse(roleRes.RoleSpec)
    const policyCount = roleRes.PolicyCount || 0

    const deviceOwnershipRes: OwnershipRes[] | undefined = roleSpec.spec.device_ownership
    const platformsRes: PlatformRes[] | undefined = roleSpec.spec.platform

    const deviceOwnership: DeviceOwnership[] =
        deviceOwnershipRes?.map((ownership) => deviceOwnershipDict[ownership]) || []
    const platforms = platformsRes?.map((p) => platformDict[p]) || []

    return {
        id: roleRes.RoleID,
        name: roleSpec.metadata.name,
        description: roleSpec.metadata.description,
        version: roleRes.RoleVersion,
        createdAt: DateUtil.convertLargeTimestamp(roleRes.CreatedAt),
        createdBy: roleRes.CreatedBy,
        lastUpdatedAt: DateUtil.convertLargeTimestamp(roleRes.LastUpdatedAt),
        lastUpdatedBy: roleRes.LastUpdatedBy,
        groups: roleSpec.spec.group || [],
        emails: roleSpec.spec.email || [],
        byDeviceRegistration: Boolean(roleSpec.spec.known_device_only),
        byDeviceManagement: Boolean(roleSpec.spec.mdm_present),
        deviceOwnership,
        platforms,
        serviceAccounts: roleSpec.spec.service_account || [],
        policyCount,
        deviceCount: roleRes.DeviceCount || 0,
        serialNumbers: roleSpec.spec.serial_numbers || [],
        roleSpec: roleRes.RoleSpec,
        roleStatus: getStatusFromRes(roleRes),
    }
}

function useInsertRole(
    option?: QueryOptions<Role>
): UseMutationResult<Role, unknown, EditableRoleFields> {
    const roleApi = new RoleApi()
    const localization = useServiceLocalization()

    return useMutation(
        (role: EditableRoleFields) => {
            if (isMobileDevicesRole(role)) {
                return Promise.reject(
                    localization.getString(
                        "somethingNamedAlreadyExists",
                        localization.getString("role"),
                        role.name
                    )
                )
            }

            if (
                role.emails.length === 0 &&
                role.groups.length === 0 &&
                role.deviceOwnership.length === 0 &&
                role.platforms.length === 0 &&
                role.serialNumbers.length === 0 &&
                role.serviceAccounts.length === 0 &&
                !role.byDeviceManagement &&
                !role.byDeviceRegistration
            ) {
                // At least one attribute must be select.
                return Promise.reject(localization.getString("rolesMustHaveAtLeastOneAttribute"))
            }

            return roleApi
                .createRole(
                    getRoleReq(role, {
                        email: role.emails,
                        group: role.groups,
                        device_ownership: role.deviceOwnership,
                        platform: role.platforms.map(
                            (p) => platformResDict[p as Exclude<Platform, Platform.CHROME>]
                        ),
                        known_device_only: role.byDeviceRegistration,
                        mdm_present: role.byDeviceManagement,
                        service_account: role.serviceAccounts,
                        serial_numbers: role.serialNumbers,
                    })
                )
                .then(mapRoleFromRes)
        },
        {
            ...option,
            onSuccess: (role: Role) => {
                queryClient.removeQueries([ApiFunction.GET_ROLES])
                queryClient.removeQueries(serviceKeys.GET_ROLE(role.id))
                option?.onSuccess?.(role)
            },
        }
    )
}

export function getEmptyRole(): EditableRoleFields {
    return {
        name: "",
        description: "",
        groups: [],
        emails: [],
        deviceOwnership: [],
        serialNumbers: [],
        platforms: [],
        byDeviceRegistration: false,
        byDeviceManagement: false,
        serviceAccounts: [],
    }
}

export interface Role extends EditableRoleFields {
    id: string
    version: number
    createdAt: number
    createdBy: string
    lastUpdatedAt: number
    lastUpdatedBy: string
    policyCount: number
    deviceCount: number
    roleSpec: string
    roleStatus: Status
}

export interface EditableRoleFields {
    name: string
    description: string
    groups: string[]
    emails: string[]
    deviceOwnership: DeviceOwnership[]
    serialNumbers: string[]
    platforms: Platform[]
    byDeviceRegistration: boolean
    byDeviceManagement: boolean
    serviceAccounts: string[]
}
