import {
    UseMutationResult as TanstackUseMutationResult,
    UseQueryResult as TanstackUseQueryResult,
} from "@tanstack/react-query"

export type UseQueryResult<Data, Error> =
    | UseQueryResultSuccess<Data, Error>
    | UseQueryResultLoading<Data, Error>
    | UseQueryResultError<Data, Error>

export type UseMutationResult<TData, TError, TVariables> = Omit<
    TanstackUseMutationResult<TData, TError, TVariables>,
    "variables"
>

export function useMapQueryResult<
    DataIn,
    DataOut,
    Error,
    Hook extends (...args: never[]) => TanstackUseQueryResult<DataIn, Error>,
>(
    queryHook: Hook,
    inputs: Parameters<Hook>,
    mapData: (data: DataIn) => DataOut
): UseQueryResult<DataOut, Error> {
    const queryResult = queryHook(...inputs)
    return mapQueryResult(queryResult, mapData)
}

export function useMapMutationResult<
    DataIn,
    DataOut,
    Error,
    VariablesIn,
    VariablesOut,
    Hook extends (...args: never[]) => UseMutationResult<DataIn, Error, VariablesIn>,
>(
    mutationHook: Hook,
    inputs: Parameters<Hook>,
    mapData: (data: DataIn) => DataOut,
    mapVariables: (variables: VariablesOut) => VariablesIn
): UseMutationResult<DataOut, Error, VariablesOut> {
    const mutationResult = mutationHook(...inputs)

    return {
        ...mutationResult,
        mutate: (variables) => mutationResult.mutate(mapVariables(variables)),
        mutateAsync: async (variables) => {
            const result = await mutationResult.mutateAsync(mapVariables(variables))
            return mapData(result)
        },
        data: mutationResult.data && mapData(mutationResult.data),
    }
}

function mapQueryResult<DataIn, DataOut, Error>(
    queryResult: TanstackUseQueryResult<DataIn, Error>,
    mapData: (data: DataIn) => DataOut
): UseQueryResult<DataOut, Error> {
    const refetch = async (): Promise<DataOut> => {
        const queryResultInRefetch = await queryResult.refetch()
        return queryResultInRefetch.data
            ? mapData(queryResultInRefetch.data)
            : Promise.reject(queryResultInRefetch.error)
    }

    switch (queryResult.status) {
        case "success":
            return {
                ...queryResult,
                data: mapData(queryResult.data),
                refetch,
            }
        case "loading":
        case "error":
            return {
                ...queryResult,
                data: queryResult.data && mapData(queryResult.data),
                refetch,
            }
    }
}

interface BaseQueryResult<Data, Error> {
    refetch(): Promise<Data>
}

interface UseQueryResultSuccess<Data, Error> extends BaseQueryResult<Data, Error> {
    status: "success"
    isSuccess: true
    isLoading: boolean
    data: Data
}

interface UseQueryResultLoading<Data, Error> extends BaseQueryResult<Data, Error> {
    status: "loading"
    isSuccess: boolean
    isLoading: true
    data?: Data
}

interface UseQueryResultError<Data, Error> extends BaseQueryResult<Data, Error> {
    status: "error"
    isSuccess: false
    isLoading: false
    data?: Data
    error: Error
}
