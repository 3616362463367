import React from "react"

import { useServiceLocalization } from "../../../pre-v3/services"
import { ROUTE } from "../../../routes"
import { AppText, LocalizationProps } from "../../components/app-text/AppText.component"
import { BannerShell } from "../../components/banner-shell/BannerShell.component"
import { MIGRATION_DATE } from "../../services/TrustProfile.service"
import { AppBannerKey } from "./AppBanner.context"
import { useGranularTrustMigrationEducationBanner } from "./useGranularTrustMigrationEducationBanner.hook"

export function AppBanner(): JSX.Element {
    const localization = useServiceLocalization()
    const formatter = new Intl.DateTimeFormat(localization.getLocale())

    const granularTrustMigrationEducationBannerResult = useGranularTrustMigrationEducationBanner()

    const granularTrustMigrationEducationBannerLocalizationProps: LocalizationProps = {
        key: "seeStepsYouNeedToTakeForTheMigrationToTheNewTrustScoringModel",
        replaceVals: [ROUTE.GRANULAR_TRUST_MIGRATION_EDUCATION, formatter.format(MIGRATION_DATE)],
    }

    return (
        <React.Fragment>
            {[
                !granularTrustMigrationEducationBannerResult.hideBanner && (
                    <BannerShell
                        onClose={granularTrustMigrationEducationBannerResult.onClose}
                        key={AppBannerKey.GRANULAR_TRUST_MIGRATION_EDUCATION}
                    >
                        <AppText ls={granularTrustMigrationEducationBannerLocalizationProps} />
                    </BannerShell>
                ),
            ]}
        </React.Fragment>
    )
}
