import React, { Fragment } from "react"
import styles from "./NavMenuButton.module.scss"
import { Popover } from "@mui/material"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faCaretDown } from "@fortawesome/pro-solid-svg-icons"
import { MenuItem } from "../menu-item/MenuItem.component"
import classNames from "classnames/bind"
import { Clickable } from ".."

export default function () {
    return (
        <>
            <span
                ref={(c) => (this.anchor = c)}
                className={classNames(styles.navMenuButton, this.props.className, {
                    [styles.highlight]: this.props.highlight,
                })}
            >
                <Clickable onClick={this.openMenu}>
                    {this.props.children}
                    {this.props.caret && (
                        <span className={styles.caretDown}>
                            <FontAwesomeIcon icon={faCaretDown} />
                        </span>
                    )}
                </Clickable>
            </span>
            <Popover
                anchorEl={this.anchor}
                anchorOrigin={this.state.anchorOrigin}
                transformOrigin={this.state.transformOrigin}
                PaperProps={{
                    square: true,
                }}
                open={this.state.showMenu}
                onClose={this.closeMenu}
            >
                <ul className={styles.navMenu}>
                    {this.props.links &&
                        this.props.links.map((link, index) => {
                            return (
                                <MenuItem
                                    key={index}
                                    href={link.href}
                                    target={link.target}
                                    onClick={() => this.onLinkClick(link)}
                                    divider={link.divider}
                                >
                                    {link.label}
                                </MenuItem>
                            )
                        })}
                    {this.props.footer}
                </ul>
            </Popover>
        </>
    )
}
