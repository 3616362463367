import { faFilter } from "@fortawesome/pro-solid-svg-icons"
import { Tooltip } from "@mui/material"
import { GridReadyEvent, GridApi, ColDef, RowDoubleClickedEvent } from "ag-grid-community"
import React, { useEffect, useRef, useState } from "react"
import { useHistory } from "react-router-dom"

import {
    Button,
    ButtonElement,
    ButtonType,
    IconType,
} from "../../../../components/button/Button.component"
import { PageHeading } from "../../../../components/page-heading/PageHeading.component"
import AccessTierEmptyState from "../../../../images/access-tier-empty-state.svg"
import { useServiceLocalization } from "../../../../pre-v3/services/localization/Localization.service"
import AgGridUtil, { FilterModel } from "../../../../pre-v3/utils/AgGrid.util"
import { encodeID, UrlUtil } from "../../../../pre-v3/utils/Url.util"
import { Grid } from "../../../../pre-v3/components/grid/Grid.component"
import { formatRoutePath, ROUTE } from "../../../../routes"
import { toLocalizedList } from "../../../../utils/String.utils"
import {
    AccessTier,
    AccessTierStatus,
    statusLabelDict,
    statusTypeDict,
    useGetAccessTiers,
} from "../../../services/AccessTier.service"
import { ErrorBanner } from "../../../components/banner/Banner.component"
import { EmptyStateScreen } from "../../../components/page-screen/PageScreen.component"
import { AppText } from "../../../components/app-text/AppText.component"
import { Status, getStatusType } from "../../../components/status/Status.component"
import { Link } from "../../../components/link/Link.component"
import { SearchInput } from "../../../components/search-input/SearchInput.component"
import { Loader } from "../../../components/loader/Loader.component"
import { SelectItem } from "../../../../pre-v3/utils/SelectValue.util"
import { StringUtil } from "../../../../pre-v3/utils/String.util"
import { MenuButton } from "../../../components/menu/menu-button/MenuButton.component"
import { Menu } from "../../../components/menu/Menu.component"
import { MenuItemProps } from "../../../components/menu/menu-item/MenuItem.component"
import styles from "./AccessTiersList.module.scss"

interface Props {
    enableAccessTierGroups: boolean
    shouldAddPrivateEdge: boolean
    canCreateAccessTier: boolean
}

export function AccessTiersList(props: Props): JSX.Element {
    const ls = useServiceLocalization()
    const history = useHistory()
    const grid = useRef<GridApi>()
    const [search, setSearch] = useState<string>("")
    const [statusFilterLabel, setStatusFilterLabel] = React.useState<string>("")
    const initialModel: FilterModel = UrlUtil.readFilter()

    const {
        data: accessTierData,
        error: accessTierError,
        isFetching: isAccessTierLoading,
        refetch: refetchAccessTier,
        isError: isAccessTierError,
    } = useGetAccessTiers(props.enableAccessTierGroups)

    function onFilterChange(model: FilterModel): void {
        const filteredVal: string = model?.status?.filter?.toLowerCase() ?? ""

        grid.current?.setQuickFilter(filteredVal)
        setStatusFilterLabel(
            filteredVal ? StringUtil.capitalize(filteredVal) : ls.getString("allStatuses")
        )
        UrlUtil.writeFilter({ status: { filter: filteredVal } })
    }

    const onGridReady = (event: GridReadyEvent) => {
        grid.current = event.api
        grid.current.setRowData(accessTierData?.data || [])
        onFilterChange(initialModel)
    }

    useEffect(() => {
        const isFilterable: boolean = search.length > 2 || search.length === 0
        isFilterable && grid.current?.setQuickFilter?.(search)
    }, [search])

    const columns: ColDef[] = [
        {
            headerName: ls.getString("status"),
            field: "status",
            flex: 60,
            cellRenderer: StatusCellRenderer,
            filter: "agTextColumnFilter",
        },
        {
            headerName: ls.getString("name"),
            field: "name",
            tooltipValueGetter: AgGridUtil.linkTooltipValueGetter,
            flex: 80,
            cellRenderer: NameCellRenderer,
            filter: "agTextColumnFilter",
            comparator: AgGridUtil.alphaBetComparator,
        },
        {
            headerName: ls.getString("version"),
            field: "versions",
            flex: 65,
            valueFormatter: (params) =>
                toLocalizedList(params.value, ls.getLocale(), "conjunction"),
        },
        {
            headerName: ls.getString("instances"),
            field: "instanceCount",
            flex: 65,
        },
        {
            headerName: ls.getString("publicAddress"),
            field: "address",
            flex: 100,
            filter: "agTextColumnFilter",
        },
    ]

    if (isAccessTierError) {
        return <ErrorBanner className={styles.error}>{String(accessTierError)}</ErrorBanner>
    }

    if (!isAccessTierLoading && accessTierData && accessTierData.total === 0) {
        return (
            <EmptyStateScreen
                title={ls.getString("createYourFirstAccessTier")}
                subText={<AppText ls="createYourFirstAccessTierDesc" />}
                buttonProps={
                    props.canCreateAccessTier
                        ? {
                              buttonText: ls.getString("createAccessTier"),
                              buttonLink: ROUTE.ACCESS_TIERS_ADD,
                          }
                        : undefined
                }
            >
                <div>
                    <img
                        src={AccessTierEmptyState}
                        className={styles.screen}
                        alt={ls.getString("createYourFirstAccessTierDesc")}
                    />
                </div>
            </EmptyStateScreen>
        )
    }

    function onRowDoubleClicked(event: RowDoubleClickedEvent): void {
        if (event?.data) {
            history.push(
                formatRoutePath(ROUTE.ACCESS_TIERS_DETAILS, { id: encodeID(event.data.id) })
            )
        }
    }

    const statusFilterOptions: SelectItem[] = [
        {
            displayName: ls.getString("allStatuses"),
            value: "",
        },
        {
            displayName: ls.getString("inactive"),
            value: AccessTierStatus.INACTIVE,
        },
        {
            displayName: ls.getString("pending"),
            value: AccessTierStatus.PENDING,
        },
        {
            displayName: ls.getString("reporting"),
            value: AccessTierStatus.REPORTING,
        },
        {
            displayName: ls.getString("terminated"),
            value: AccessTierStatus.TERMINATED,
        },
    ]

    const accessTiersLabel = ls.getString("accessTiers")

    return (
        <section aria-labelledby={Id.HEADING} className={styles.section}>
            <header className={styles.header}>
                <PageHeading id={Id.HEADING}>{accessTiersLabel}</PageHeading>
                <Tooltip title={ls.getString("refresh")}>
                    <Button
                        icon={IconType.REDO}
                        onClick={() => refetchAccessTier()}
                        asElement={ButtonElement.BUTTON}
                        buttonType={ButtonType.SECONDARY}
                        aria-label={ls.getString("refresh")}
                    />
                </Tooltip>
            </header>
            <div className={styles.container}>
                <SearchInput
                    value={search}
                    onChangeValue={setSearch}
                    placeholder={ls.getString("search")}
                />
                <MenuButton label={statusFilterLabel} icon={faFilter}>
                    <Menu
                        items={statusFilterOptions.map((option): MenuItemProps => {
                            return {
                                label: option.displayName,
                                onClick: () => onFilterChange({ status: { filter: option.value } }),
                            }
                        })}
                    />
                </MenuButton>
                {props.canCreateAccessTier && (
                    <Button
                        buttonType={ButtonType.PRIMARY}
                        icon={IconType.PLUS}
                        asElement={ButtonElement.LINK}
                        to={ROUTE.ACCESS_TIERS_ADD}
                    >
                        {ls.getString("createAccessTier")}
                    </Button>
                )}
            </div>
            <Loader
                isLoading={isAccessTierLoading}
                medium
                center
                title={ls.getString("loadingSomething", accessTiersLabel)}
            >
                <Grid
                    onGridReady={onGridReady}
                    columnDefs={columns}
                    context={{ history }}
                    onRowDoubleClicked={onRowDoubleClicked}
                    pagination
                />
            </Loader>
        </section>
    )
}
interface CellRendererProps {
    value?: string
    data: AccessTier
}

export function NameCellRenderer(props: CellRendererProps) {
    if (!props.data.id) {
        return props.value
    }
    return (
        <Link to={formatRoutePath(ROUTE.ACCESS_TIERS_DETAILS, { id: encodeID(props.data.id) })}>
            {props.value}
        </Link>
    )
}

export function StatusCellRenderer(props: CellRendererProps) {
    const ls = useServiceLocalization()
    if (!props.data.status) {
        return props.value
    }
    return (
        <Status
            type={getStatusType(statusTypeDict[props.data.status])}
            label={ls.getString(statusLabelDict[props.data.status])}
        />
    )
}

enum Id {
    HEADING = "heading",
}
