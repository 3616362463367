import React, { ReactNode, SyntheticEvent } from "react"
import ToolbarTemplate from "./Toolbar.template"
import { Bind } from "../../decorators/Bind.decorator"
import { Debounce } from "../../decorators/Debounce.decorator"
import { LocalizationService } from "../../services/localization/Localization.service"

export class Toolbar extends React.Component<React.PropsWithChildren<ToolbarProps>, ToolbarState> {
    public state: ToolbarState = { searchValue: "" }

    public render(): ReactNode {
        return ToolbarTemplate.call(this)
    }

    private localizationService: LocalizationService = new LocalizationService()

    @Bind
    private onSearchClear(): void {
        this.setState({
            searchValue: "",
        })
        this.onSearchChangeDebounced("")
    }

    @Bind
    private onSearchChange(event: SyntheticEvent): void {
        const value: string = (event.target as HTMLInputElement).value
        this.setState({
            searchValue: value,
        })
        this.onSearchChangeDebounced(value)
    }

    @Debounce
    private onSearchChangeDebounced(value: string): void {
        if (this.props.onSearchChange) {
            this.props.onSearchChange(value)
        }
    }
}

interface ToolbarProps {
    className?: string
    placeholder?: string
    onSearchChange: (value: string) => void
}

interface ToolbarState {
    searchValue: string
}
