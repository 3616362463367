import React from "react"

import {
    TrustFactor,
    isBanyanAppVersionTrustFactor,
    isFileCheckTrustFactor,
    isZtaScoreTrustFactor,
    isOsVersionTrustFactor,
    isAppCheckTrustFactor,
    isPlistTrustFactor,
    isRegistryCheckTrustFactor,
    isChromeBrowserVersionTrustFactor,
    isGeolocationTrustFactor,
} from "../../../../../../../services/TrustProfile.service"
import { BanyanAppVersion } from "./BanyanAppVersion.component"
import { FileCheck } from "./fileCheck/FileCheck.component"
import { ZtaScore } from "./ZtaScore.component"
import { OperatingSystem } from "./OperatingSystem.component"
import { ApplicationCheck } from "./ApplicationCheckFactor.component"
import { PlistCheck } from "./plist/PlistCheck.component"
import { RegistryCheckComponent } from "./registryCheck/RegistryCheck.component"
import { BrowserVersion } from "./BrowserVersion.component"
import { DeviceGeolocation } from "./DeviceGeolocation.component"

interface Props {
    trustFactor: TrustFactor
    className?: string
    onEdit(trustFactor: TrustFactor): void
    disabled?: boolean
}

export function ComplexConfiguration(props: Props): JSX.Element {
    if (isPlistTrustFactor(props.trustFactor)) {
        return (
            <PlistCheck
                trustFactor={props.trustFactor}
                className={props.className}
                disabled={props.disabled}
                onEdit={props.onEdit}
            />
        )
    }

    if (isBanyanAppVersionTrustFactor(props.trustFactor)) {
        return (
            <BanyanAppVersion
                trustFactor={props.trustFactor}
                onEdit={props.onEdit}
                className={props.className}
                disabled={props.disabled}
            />
        )
    }

    if (isFileCheckTrustFactor(props.trustFactor)) {
        return (
            <FileCheck
                trustFactor={props.trustFactor}
                onEdit={props.onEdit}
                className={props.className}
                disabled={props.disabled}
            />
        )
    }

    if (isRegistryCheckTrustFactor(props.trustFactor)) {
        return (
            <RegistryCheckComponent
                trustFactor={props.trustFactor}
                className={props.className}
                disabled={props.disabled}
                onEdit={props.onEdit}
            />
        )
    }

    if (isZtaScoreTrustFactor(props.trustFactor)) {
        return (
            <ZtaScore
                trustFactor={props.trustFactor}
                onEdit={props.onEdit}
                className={props.className}
                disabled={props.disabled}
            />
        )
    }

    if (isOsVersionTrustFactor(props.trustFactor)) {
        return (
            <OperatingSystem
                trustFactor={props.trustFactor}
                onEdit={props.onEdit}
                className={props.className}
                disabled={props.disabled}
            />
        )
    }

    if (isGeolocationTrustFactor(props.trustFactor)) {
        return (
            <DeviceGeolocation
                trustFactor={props.trustFactor}
                onEdit={props.onEdit}
                className={props.className}
                disabled={props.disabled}
            />
        )
    }

    if (isChromeBrowserVersionTrustFactor(props.trustFactor)) {
        return (
            <BrowserVersion
                trustFactor={props.trustFactor}
                onEdit={props.onEdit}
                className={props.className}
                disabled={props.disabled}
            />
        )
    }

    if (isAppCheckTrustFactor(props.trustFactor)) {
        return (
            <ApplicationCheck
                trustFactor={props.trustFactor}
                onEdit={props.onEdit}
                className={props.className}
                disabled={props.disabled}
            />
        )
    }

    return <React.Fragment />
}
