import { TrustLevel } from "../../api/Entity.api"
import { NetAgentStatus } from "../../api/Infra.api"
import { DevicePlatform } from "../../api/Reporting.api"
import { PolicyEnforcement } from "../../services/Service.service"

export function fillSeries1D(data: DataPoint[], length: number): DataPoint[] {
    const target = [...data]

    for (let i = target.length - 1; i < 10; i++) {
        target.push({ displayName: "", value: null })
    }

    return target
}

export function categorizeSeries1D(series: DataPoint[]) {
    const idMap: StringMap = {}
    for (const point of series) {
        if (point.id) {
            idMap[point.displayName] = point.id
        }
    }

    return {
        categories: series.map((d) => d.displayName),
        series: [{ name: "Count", data: series.map((d) => d.value), type: "bar" as "bar" }],
        idMap,
    }
}

export function categorizeSeries1DGroup(data: Series1DGroup[]) {
    let seriesDataCounts: { [key: string]: (number | null)[] } = {}
    let groupPositions: { [key: string]: number } = {}
    let idMap: StringMap = {}

    for (let i = 0; i < data.length; i++) {
        groupPositions[data[i].groupName] = i
        if (data[i].groupId) {
            idMap[data[i].groupName] = data[i].groupId as any
        }

        for (let s of data[i].series) {
            if (seriesDataCounts[s.displayName] === undefined) {
                seriesDataCounts[s.displayName] = Array(data.length).fill(0)
            }
        }
    }

    for (let group of data) {
        for (let s of group.series) {
            let seriesName = s.displayName
            let value = s.value
            seriesDataCounts[seriesName][groupPositions[group.groupName]] = value
        }
    }

    const categories: string[] = Object.keys(groupPositions)
    const series = Object.entries(seriesDataCounts).map(([key, value]) => {
        return { name: key, data: value, type: "bar" as "bar" }
    })
    return { categories, series, idMap }
}

export const chartColorMap: StringMap = {
    [NetAgentStatus.REPORTING]: "#00C884",
    [NetAgentStatus.TERMINATED]: "#E52F18",
    [NetAgentStatus.INACTIVE]: "#A2AAC1",
    [TrustLevel.HIGH]: "#00C884",
    [TrustLevel.MID]: "#FDCE3A",
    [TrustLevel.LOW]: "#E52F18",
    [TrustLevel.PENDING]: "#B4BBD3",
    [TrustLevel.ALWAYS_ALLOW]: "#018458",
    [TrustLevel.ALWAYS_DENY]: "#8F1B0D",
    [PolicyEnforcement.ENFORCING]: "#00C884",
    [PolicyEnforcement.PERMISSIVE]: "#FDCE3A",
    [PolicyEnforcement.MIXED]: "#23B9D8",
    [DevicePlatform.ANDROID]: "#1442AB",
    [DevicePlatform.APPLE]: "#2B6FEA",
    [DevicePlatform.IOS]: "#9BCFFB",
    [DevicePlatform.LINUX]: "#D2E1EF",
    [DevicePlatform.MACOS]: "#A92882",
    [DevicePlatform.OTHER]: "#E5C1DA",
    [DevicePlatform.UNKNOWN]: "#8ED6EE",
    [DevicePlatform.UNREGISTERED]: "#15B9CC",
    [DevicePlatform.WINDOWS]: "#9BE7C7",
    [DevicePlatform.WIN_RT]: "#51C494",
}

export interface DataPoint {
    displayName: string
    value: null | number
    id?: string
}

export interface Series1DGroup {
    color?: string
    groupName: string
    groupId: string
    series: DataPoint[]
}
