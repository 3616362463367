import { faSpinner } from "@fortawesome/pro-solid-svg-icons"
import { IconDefinition } from "@fortawesome/fontawesome-common-types"
import classNames from "classnames/bind"
import React from "react"

import styles from "./Button.module.scss"
import { ButtonBase, Props as ButtonBaseProps } from "./ButtonBase.component"
import { Icon } from "../Icon/Icon.component"

export interface Props extends ButtonBaseProps {
    icon?: IconDefinition
}

export const Button = React.forwardRef<HTMLButtonElement, Props>((props, ref) => {
    const { className, icon, loading, children, disabled, type = "button", ...buttonProps } = props

    return (
        <ButtonBase
            {...buttonProps}
            type={type}
            className={classNames(className, styles.button)}
            disabled={loading || disabled}
            loading={loading}
            aria-busy={loading}
            ref={ref}
        >
            {icon && <Icon icon={icon} />}
            {loading && <Icon spin icon={faSpinner} />}
            {children}
        </ButtonBase>
    )
})

Button.displayName = "Button"
