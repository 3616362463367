import { ApiResponse } from "../../pre-v3/api/Base.api"
import { Domain } from "../services/UrlFiltering.service"
import { BaseApi } from "./Base.api"

export class UrlFilteringApi extends BaseApi {
    public toggleUrlFiltering(state: boolean): Promise<ApiResponse<UrlFilteringRes>> {
        return this.postForm(
            "/api/v1/update_org",
            {
                IsURLFilteringEnabled: state,
            },
            {
                default: this.localization.getString("errorCouldNotUpdateUrlFilteringFlag"),
            }
        )
    }

    public getBanyanManagedBypassDomains(): Promise<DomainRes[]> {
        return this.get("/api/v2/url_filtering/global_exclude_domains", {
            default: this.localization.getString(
                "errorCouldNotGetSonicWallCseManagedBypassDomains"
            ),
        })
    }

    public getAdminManagedBypassDomains(): Promise<DomainRes[]> {
        return this.get("/api/v2/url_filtering/org_exclude_domains", {
            default: this.localization.getString("errorCouldNotGetAdminManagedBypassDomains"),
        })
    }

    public createManagedBypassDomain(domains: string[]): Promise<DomainRes> {
        return this.post(
            "/api/v2/url_filtering/org_exclude_domains",
            { exclude_domains: [...domains] },
            {
                default: this.localization.getString("errorCouldNotCreateManagedBypassDomain"),
            }
        )
    }

    public updateManagedBypassDomain(domains: Domain[]): Promise<DomainRes> {
        return this.put("/api/v2/url_filtering/org_exclude_domains", domains, {
            default: this.localization.getString("errorCouldNotUpdateManagedBypassDomain"),
        })
    }

    public deleteManagedBypassDomain(domains: Domain[]): Promise<DomainRes> {
        return this.delete("/api/v2/url_filtering/org_exclude_domains", domains, {
            default: this.localization.getString("errorCouldNotDeleteManagedBypassDomain"),
        })
    }

    public getPacFile(): Promise<UrlFilteringPacRes> {
        return this.get("/api/v2/url_filtering/pac", {
            default: this.localization.getString("errorCouldNotGetPacFile"),
        })
    }

    public uploadPacFile(uploadPacFileReq: UploadPacFileReq): Promise<UrlFilteringPacRes> {
        return this.post("/api/v2/url_filtering/pac", uploadPacFileReq, {
            default: this.localization.getString("errorCouldNotUpdatePacFile"),
        })
    }
}

export type UrlFilteringRes = {
    Message: string
}

export interface DomainRes {
    id: string
    domain: string
    created_at: string
    updated_at: string
    created_by: string
    updated_by: string
}

export interface UrlFilteringPacRes {
    id: string
    org_id: string
    version: string
    pac?: string
    created_at: string
    updated_at: string
    created_by: string
    updated_by: string
}

export interface UrlFilteringReq {
    enabled: boolean
}

export interface UploadPacFileReq {
    pac: string
}
