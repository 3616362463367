import { UseQueryResult, useQuery } from "@tanstack/react-query"

import { DateUtil } from "../../pre-v3/utils/Date.util"
import { ReportApi, ServiceRes } from "../api/Report.api"
import { ServiceAccount } from "./ServiceAccount.service"

enum ReportHookKey {
    GET_SERVICES_ACCESSED_BY_SERVICE_ACCOUNT = "reportService.getServicesAccessedByServiceAccount",
}

export function useGetServicesAccessedByServiceAccount(
    serviceAccount: ServiceAccount,
    options?: QueryOptions<Service[]>
): UseQueryResult<Service[]> {
    const reportApi = new ReportApi()

    return useQuery({
        ...options,
        queryKey: [ReportHookKey.GET_SERVICES_ACCESSED_BY_SERVICE_ACCOUNT, serviceAccount.id],
        queryFn: async () => {
            const days = DateUtil.DAY * 90
            const endTime = Date.now()
            const startTime = endTime - days

            const accessActivityRes = await reportApi.getServicesAccessedByServiceAccount({
                report_type: "services_accessed_by_service_account",
                service_account_id: serviceAccount.id,
                start_time: startTime,
                end_time: endTime,
            })

            return accessActivityRes.services.map(mapServiceRes)
        },
    })
}

export interface Service {
    id: string
    name: string
    lastAuthorizedAt: number
}

function mapServiceRes(serviceRes: ServiceRes): Service {
    return {
        id: serviceRes.service_id,
        name: serviceRes.service_name,
        lastAuthorizedAt: DateUtil.convertLargeTimestamp(serviceRes.last_authorized_timestamp),
    }
}
