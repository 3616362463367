import React from "react"

import { Button, ButtonElement, ButtonType } from "../../../components/button/Button.component"
import { useServiceLocalization } from "../../../pre-v3/services/localization/Localization.service"
import { Form } from "../../../v3/components/form/Form.component"
import { FormButtons } from "../../../v3/components/form/form-buttons/FormButtons.component"
import {
    AccessTierDetails,
    AccessTierGroup,
    ApiKey,
    CreateAccessTierVariables,
    useCreateOrUpdateAccessTier,
} from "../../../v3/services/AccessTier.service"
import { Cluster } from "../../../v3/services/shared/Cluster"
import { ErrorToast, ToastApi } from "../../../components/toast/Toast.components"
import { AccessTierGeneralInformation } from "../shared/AccessTierGeneralInformation.component"
import {
    AccessTierNetworkSettings,
    NetworkSettingsValues,
} from "../shared/AccessTierNetworkSettings.component"

interface Props {
    accessTier: CreateAccessTierVariables
    originalAccessTier?: AccessTierDetails
    privateEdgeCluster: Cluster
    apiKeys: ApiKey[]
    accessTierGroups: AccessTierGroup[]
    canAccessAccessTierGroups: boolean
    onChange(accessTier: CreateAccessTierVariables): void
    onContinue(accessTier: AccessTierDetails): void
}

export function AccessTierConfigureTab(props: Props): JSX.Element {
    const localization = useServiceLocalization()

    const errorToastRef = React.useRef<ToastApi>(null)

    const [isServiceTunnelSecured, setIsServiceTunnelSecured] = React.useState(
        !!props.accessTier.networkSettings
    )

    const onServiceTunnelSecuredChange = (isServiceTunnelSecured: boolean) => {
        setIsServiceTunnelSecured(isServiceTunnelSecured)
        props.onChange({
            ...props.accessTier,
            networkSettings: isServiceTunnelSecured ? props.accessTier.networkSettings : undefined,
            isSecure: isServiceTunnelSecured,
        })
    }

    const { mutate: createAccessTier, isLoading: isCreatingAccessTier } =
        useCreateOrUpdateAccessTier(props.privateEdgeCluster, props.originalAccessTier, {
            onSuccess: props.onContinue,
            onError: (error) =>
                typeof error === "string"
                    ? errorToastRef.current?.openToast(error)
                    : console.error(error),
        })

    const onApiKeyChange = (apiKey: ApiKey) => props.onChange({ ...props.accessTier, apiKey })
    const onNetworkSettingsChange = (networkSettings: NetworkSettingsValues) =>
        props.onChange({ ...props.accessTier, networkSettings })

    const onSubmit: React.FormEventHandler = (event) => {
        event.preventDefault()
        createAccessTier(props.accessTier)
    }

    return (
        <Form onSubmit={onSubmit}>
            <ErrorToast ref={errorToastRef} />
            <AccessTierGeneralInformation
                values={props.accessTier}
                apiKeys={props.apiKeys}
                accessTierGroups={props.accessTierGroups}
                isCreateMode
                isServiceTunnelSecured={isServiceTunnelSecured}
                canAccessAccessTierGroups={props.canAccessAccessTierGroups}
                onChange={props.onChange}
                onApiKeyChange={onApiKeyChange}
                onServiceTunnelSecuredChange={onServiceTunnelSecuredChange}
            />
            {isServiceTunnelSecured && (
                <AccessTierNetworkSettings
                    values={
                        props.accessTier.accessTierGroup
                            ? {
                                  cidrs: props.accessTier.accessTierGroup.cidrRanges,
                                  domains: props.accessTier.accessTierGroup.privateDomains,
                              }
                            : props.accessTier.networkSettings
                    }
                    accessTierGroup={props.accessTier.accessTierGroup}
                    isReadMode={!!props.accessTier.accessTierGroup}
                    onChange={onNetworkSettingsChange}
                />
            )}
            <FormButtons
                rightButtons={
                    <Button
                        asElement={ButtonElement.BUTTON}
                        buttonType={ButtonType.PRIMARY}
                        type="submit"
                        loading={isCreatingAccessTier}
                    >
                        {localization.getString("continue")}
                    </Button>
                }
            />
        </Form>
    )
}
