import { UrlUtil } from "../../pre-v3/utils/Url.util"
import { BaseApi, PaginatedResponse, PaginationOptions } from "./Base.api"

export class AccessTierApi extends BaseApi {
    public static RESOLUTION_ERROR = "RESOLUTION_ERROR" as const
    public static REACHABILITY_ERROR = "REACHABILITY_ERROR" as const

    public getAccessTiers(
        search: Partial<AccessTierSearch> = {}
    ): Promise<PaginatedResponse<"access_tiers", AccessTierRes>> {
        if (!search.limit) {
            search.limit = 100
        }
        const params: URLSearchParams = UrlUtil.convertToURLSearchParams(search)
        return this.get("/api/v2/access_tier?" + params.toString(), {
            default: this.localization.getString("failedToFetchAccessTiers"),
        })
    }

    public getAccessTierById(id: string): Promise<AccessTierRes> {
        return this.get(`/api/v2/access_tier/${id}`, {
            default: this.localization.getString("failedToFetchAccessTier"),
        })
    }

    public createAccessTier(body: AccessTierBody): Promise<AccessTierRes> {
        const accessTierLabel = this.localization.getString("accessTier")

        return this.post("/api/v2/access_tier", body, {
            2: this.localization.getString(
                "somethingNamedAlreadyExists",
                accessTierLabel,
                body.metadata.name
            ),
            default: this.localization.getString("failedToCreateSomething", accessTierLabel),
        })
    }

    public updateAccessTier(id: string, body: AccessTierBody): Promise<AccessTierRes> {
        return this.put(`/api/v2/access_tier/${id}`, body, {
            default: this.localization.getString(
                "failedToUpdateSomething",
                this.localization.getString("accessTier")
            ),
        })
    }

    public testConnection(id: string): Promise<void> {
        return this.post(
            `/api/v2/access_tier/${id}/test`,
            {},
            {
                1: AccessTierApi.RESOLUTION_ERROR,
                2: AccessTierApi.REACHABILITY_ERROR,
                default: this.localization.getString("failedToTestAccessTierConnection"),
            }
        )
    }

    public deleteAccessTier(id: string): Promise<void> {
        return this.delete(
            `/api/v2/access_tier/${id}`,
            {},
            {
                1: this.localization.getString("deleteNetagentBeforeDeletingAccessTier"),
                default: this.localization.getString("failedToDeleteAccessTier"),
            }
        )
    }

    public deleteNetagent(deleteNetagentParams: DeleteNetagentParams): Promise<void> {
        const params = `?${UrlUtil.convertToURLSearchParams(deleteNetagentParams).toString()}`

        return this.delete(
            `/api/v1/delete_netagent${params}`,
            {},
            {
                default: this.localization.getString("failedToDeleteNetAgent"),
            }
        )
    }

    public sendAccessTierLogs(body: OneClickSupportBundleBody): Promise<void> {
        return this.post("/api/v1/one_click_support_bundle", body, {
            default: this.localization.getString("failedToSendAccessTierLogs"),
        })
    }

    public updateAccessTierAdvancedConfig(
        accessTierId: string,
        body: AdvancedConfigBody
    ): Promise<AdvancedConfigSpec> {
        return this.put(`/api/v2/access_tier/${accessTierId}/config`, body, {
            default: this.localization.getString("failedToUpdateAdvancedConfiguration"),
        })
    }

    public getAccessTierServices(
        accessTierId: string,
        params: PaginationOptions
    ): Promise<PaginatedResponse<"services", AccessTierServicesRes>> {
        const urlParams: URLSearchParams = new URLSearchParams(params as any)
        return this.get(`/api/v2/access_tier/${accessTierId}/services?${urlParams.toString()}`, {
            default: this.localization.getString("errorFailedToFetchAccessTierServices"),
        })
    }
}

export interface AccessTierSearch extends PaginationOptions {
    name: string
    cluster_name: string
    cluster_id: string
    address: string
    status: StatusRes
}

export type StatusRes = "Reporting" | "Inactive" | "Terminated" | "Pending"

export type DeploymentMethodRes = "docker" | "tarball" | "cloudformation" | "linux-package"

export type EdgeTypeRes = "private_edge" | "global_edge"

export interface AccessTierRes {
    id: string
    name: string
    created_at: number
    created_by: string
    disable_snat?: boolean
    deployment_method?: DeploymentMethodRes
    domains?: string[]
    netagents: NetagentRes[]
    spec: string
    status: StatusRes
    updated_at: number
    updated_by: string
    address: string
    cluster_name: string
    api_key_id: string
    access_tier_group_id: string
    description: string
    tunnel_enduser?: TunnelReq
    tunnel_satellite?: TunnelReq
    edge_type: EdgeTypeRes
}

export interface AccessTierBody {
    kind: "BanyanAccessTier"
    api_version: "rbac.banyanops.com/v1"
    type: "attribute-based"
    metadata: AccessTierMetadataReq
    spec: AccessTierSpecReq
}

export interface NetagentRes {
    HostTags: HostTags
    Hostname: string
    IPs: string[]
    SiteName: string
    Status: StatusRes
    Version: string
}

export interface HostTags {
    "com.banyanops.hosttag.os1"?: string
}

export interface AccessTierSpecJson {
    kind: "BanyanAccessTier"
    api_version: "rbac.banyanops.com/v1"
    type: "attribute-based"
    metadata: AccessTierMetadataReq
    spec: AccessTierSpecReq
}

interface AccessTierMetadataReq {
    name: string
}

interface AccessTierSpecReq {
    address: string
    domains: string[]
    cluster_name: string
    tunnel_satellite?: TunnelReq
    tunnel_enduser?: TunnelReq
    api_key_id: string
    deployment_method: DeploymentMethodRes
    description: string
}

export interface TunnelReq {
    udp_port_number: number
    keepalive?: number
    cidrs?: string[]
    domains?: string[]
    shared_fqdn?: string
}

export interface AdvancedConfigBody {
    kind: "BanyanAccessTierLocalConfig"
    api_version: "rbac.banyanops.com/v1"
    type: "attribute-based"
    metadata: {}
    spec: AdvancedConfigSpec
}

export interface AdvancedConfigSpec {
    logging?: {
        statsd?: boolean
        statsd_address?: string
    }
    events?: {
        access_event_credits_limiting?: boolean
        access_event_key_limiting?: boolean
    }
    hosted_web_services?: {
        forward_trust_cookie?: boolean
        disable_hsts?: boolean
    }
    service_discovery?: {
        service_discovery_enable: boolean
        service_discovery_msg_limit: number
        service_discovery_msg_timeout: number
    }
    miscellaneous?: {
        enable_ipv6_resolution: boolean
    }
    spec?: string
}

interface DeleteNetagentParams {
    CLUSTERNAME: string
    HOSTNAME: string
}

interface OneClickSupportBundleBody {
    ClusterName: string
    SiteName: string
}

export enum AccessTierServiceType {
    SERVICE_TUNNEL = "service_tunnel",
    WEB = "WEB",
    SSH = "SSH",
    RDP = "RDP",
    K8S = "K8S",
    DATABASE = "DATABASE",
    GENERIC = "GENERIC",
}

export interface AccessTierServicesRes {
    id: string
    service_id: string
    service_name: string
    service_type: AccessTierServiceType
    created_at: number
    updated_at: number
}
