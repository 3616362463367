import React from "react"
import styles from "./OkCancelActions.module.scss"
import { Button, ButtonElement, ButtonType } from "../../../../components/button/Button.component"

export default function () {
    return (
        <div className={styles.buttons}>
            {!this.props.hideCancel && (
                <Button
                    className={styles.cancelBtn}
                    onClick={this.props.modalRef.cancel}
                    asElement={ButtonElement.BUTTON}
                    buttonType={ButtonType.SECONDARY}
                >
                    {this.props.cancelString || this.ls.getString("cancel")}
                </Button>
            )}
            <Button
                onClick={this.props.modalRef.close}
                disabled={this.props.okDisabled}
                asElement={ButtonElement.BUTTON}
                buttonType={ButtonType.PRIMARY}
            >
                {this.props.okString || this.ls.getString("ok")}
            </Button>
        </div>
    )
}
