import { PublicInfo } from "../../../v3/services/ServiceTunnelV2.service"

export const checkPublicInfoDuplicates = (
    publicInfo: PublicInfo,
    oppositePublicInfo: PublicInfo
) => {
    const allDomains = [...publicInfo.domains, ...oppositePublicInfo.domains]
    const allIpRanges = [...publicInfo.ipRanges, ...oppositePublicInfo.ipRanges]

    if (allDomains.length < 2 && allIpRanges.length < 2) {
        return {
            dupeDomains: [],
            dupeIpRanges: [],
        }
    }

    const dupeDomains = publicInfo.domains.filter((domain) =>
        oppositePublicInfo.domains.includes(domain)
    )
    const dupeIpRanges = publicInfo.ipRanges.filter((domain) =>
        oppositePublicInfo.ipRanges.includes(domain)
    )

    return { dupeDomains, dupeIpRanges }
}
