import React, { ReactNode } from "react"
import ButtonTemplate from "./Button.template"
import { IconDefinition } from "@fortawesome/fontawesome-svg-core"

export interface ButtonProps {
    className?: string
    onClick?: React.MouseEventHandler<HTMLButtonElement>
    primary?: boolean
    error?: boolean
    brand?: boolean
    textPrimary?: boolean
    loading?: boolean
    type?: string
    icon?: IconDefinition
    disabled?: boolean
    ariaLabel?: string
    innerRef?: (c: ReactNode) => void
}

export class Button extends React.Component<React.PropsWithChildren<ButtonProps>, {}> {
    public render(): ReactNode {
        return ButtonTemplate.call(this)
    }
}
