import * as React from "react"
import { OnboardingButton } from ".."
import { useServiceLocalization, useServiceLinks } from "../../services"

interface Props {
    className?: string
}

/**
 * @deprecated Use UpgradeButton v3 instead
 */
export function UpgradeButton({ className }: Props) {
    const localization = useServiceLocalization()
    const links = useServiceLinks()

    return (
        <a href={links.getLink("teamEditionUpgrade")} className={className}>
            <OnboardingButton setup className={className}>
                {localization.getString("upgradeNow")}
            </OnboardingButton>
        </a>
    )
}
