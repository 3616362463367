import React from "react"
import { Redirect, Route, Switch } from "react-router-dom"
import { ROUTE } from "../../routes"
import { UserEdit } from "../../v3/views/app/users/edit/UserEdit.view"
import { UserOverview } from "../../v3/views/app/users/overview/UserOverview.view"
import { UserAdd } from "../../v3/views/app/users/add/UserAdd.view"
import { UsersList } from "../../v3/views/app/users/list/UsersList.view"
import useTitle from "../../hooks/useTitle.hook"
import { useFeatureFlags } from "../../hooks/useFeatureFlags.hook"
import { Loader } from "../../v3/components/loader/Loader.component"
import { useGetOrgInfo } from "../../v3/services/Org.service"
import { Banner, Variant } from "../../components/banner/Banner.component"
import { LicenseEnforcementBanner } from "../shared/LicenseEnforcementBanner.component"

export function Users() {
    useTitle(["users", "directory", "adminConsole"])
    const {
        data: featureFlags,
        status: featureFlagStatus,
        error: featureFlagsError,
    } = useFeatureFlags()
    const { data: orgInfo, status: orgInfoStatus } = useGetOrgInfo()

    if (featureFlagStatus === "loading" || orgInfoStatus === "loading")
        return <Loader medium center />

    if (featureFlagStatus === "error" || orgInfoStatus === "error") {
        return <Banner label={String(featureFlagsError)} variant={Variant.ERROR} />
    }

    return (
        <React.Fragment>
            {featureFlags.adminConsole.showLicenseEnforcementBannerInUserDirectory && (
                <LicenseEnforcementBanner />
            )}
            <Switch>
                {featureFlags.adminConsole.canAddUser ? (
                    <Route path={ROUTE.USERS_ADD} exact component={UserAdd} />
                ) : (
                    <Redirect exact path={ROUTE.USERS_ADD} to={ROUTE.USERS} />
                )}
                {featureFlags.adminConsole.canChangeUser ? (
                    <Route path={ROUTE.USERS_EDIT} component={UserEdit} />
                ) : (
                    <Redirect exact path={ROUTE.USERS_EDIT} to={ROUTE.USERS} />
                )}
                <Route path={ROUTE.USERS_DETAILS}>
                    <UserOverview orgInfo={orgInfo} />
                </Route>
                <Route path={ROUTE.USERS} component={UsersList} />
            </Switch>
        </React.Fragment>
    )
}
