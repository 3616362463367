import { TrustLevel, UnregisteredDevice } from "../api/Entity.api"
import { DeviceStatus } from "../api/EntityV2.api"
import { DateUtil } from "./Date.util"
import { TrustProfile } from "../../v3/services/TrustProfile.service"

export class EntityUtil {
    public static getUnregisteredDevices(
        devices: UnregisteredDevice[]
    ): UnregisteredDeviceEntity[] {
        return devices.map((device) => {
            return {
                name: device.name,
                platform: device.platform,
                lastLogin: DateUtil.convertLargeTimestamp(device.last_login!),
                ipAddress: device.ip_address,
                roles: device.roles,
                email: device.email,
                id: device.enduser_id,
                enduserDeviceId: device.enduser_device_id,
            }
        })
    }
}

interface UserEntity {
    name?: string
    email: string
    groups?: string[]
    role_names?: string[]
    lastLogin?: number
    status?: string
    source?: string
    devices?: DeviceEntity[]
    trustScore?: TrustScoreEntity
}

interface DeviceEntity {
    id?: string
    serial: string
    name?: string
    model?: string
    platform?: string
    roles?: string[]
    ownership?: string
    registeredStatus?: string
    knownStatus?: boolean
    source?: string
    lastLogin?: number
    compromised?: string
    architecture?: string
    users?: UserEntity[]
    trustScore?: TrustScoreEntity
    trust?: TrustEntityV2
    banned?: boolean
    appVersion?: string
    status?: DeviceStatus
    osVersion?: string
    trustProfile?: Pick<TrustProfile, "id" | "name">
}

export interface UnregisteredDeviceEntity {
    name: string
    platform: string
    lastLogin: number
    ipAddress: string
    roles: string[]
    email: string
    id: string
    enduserDeviceId: string
}

interface TrustScoreEntity {
    score: number
    level: TrustLevel
    maxScore: number
    maxLevel: TrustLevel
    timestamp: number
    factors: TrustFactorEntity[]
}

interface TrustEntityV2 {
    value: number
    level: TrustLevel
    maxLevel: TrustLevel
    status: DeviceStatus
    lastEvaluated: LastEvaluatedEntityTrustData
    expiredAt: number
    factors: TrustFactorEntityV2[]
}

interface LastEvaluatedEntityTrustData {
    lastEvaluatedAt: number
    lastEvaluatedValue: number
    lastEvaluatedLevel: TrustLevel
    lastEvaluatedFactors: TrustFactorEntityV2[]
}

interface TrustFactorEntity {
    name: string
    displayName?: string
    description: string
    source: string
    type: string
    value: boolean
    remediation?: RemediationEntity
}

interface TrustFactorEntityV2 {
    name: string
    value: boolean
    description: string
    source: string
    type: string
    remediation?: {
        description: string
        url: string
    }
}

interface RemediationEntity {
    windows: RemediationInstructions
    macos: RemediationInstructions
    linux: RemediationInstructions
}

interface RemediationInstructions {
    description: string
    url: string
}
