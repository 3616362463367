import React, { useCallback } from "react"

import { TopNavigationBar } from "../../components/top-navigation-bar/TopNavigationBar.component"
import { useServiceLinks } from "../../pre-v3/services/link/Link.service"
import { useServiceLocalization } from "../../pre-v3/services/localization/Localization.service"
import { NavigationItemProps } from "../../components/top-navigation-bar/TopNavigationBar.component"
import { ROUTE } from "../../routes"
import { Redirect, Route, Switch, matchPath, useLocation } from "react-router-dom"
import { MyProfile } from "../../v3/views/user-settings/MyProfile.view"
import useTitle from "../../hooks/useTitle.hook"

export function ProfileSection() {
    useTitle(["profile", "adminConsole"])
    const localization = useServiceLocalization()
    const location = useLocation()
    const linkService = useServiceLinks()

    const navigationItems = [
        {
            key: "1",
            label: localization.getString("myProfile"),
            to: ROUTE.MY_PROFILE,
        },
        {
            key: "2",
            label: localization.getString("fileATicket"),
            to: linkService.getLink("fileATicket"),
            isExternalLink: true,
        },
    ]

    const getNavigationItemProps = useCallback(
        (item: NavigationItemProps) => {
            const { pathname } = location
            const match = matchPath(pathname, {
                path: item.to,
            })
            return { ...item, active: !!match }
        },
        [location]
    )

    return (
        <>
            <header>
                <TopNavigationBar
                    headingTitle={localization.getString("profile")}
                    navigationItems={navigationItems}
                    getNavigationItemProps={getNavigationItemProps}
                />
            </header>
            <Switch>
                <Route path={ROUTE.MY_PROFILE} component={MyProfile} />
                <Redirect from={ROUTE.PROFILE} to={ROUTE.MY_PROFILE} />
            </Switch>
        </>
    )
}
