import React, { ChangeEvent, useRef } from "react"

import { useServiceLocalization } from "../../../pre-v3/services/localization/Localization.service"
import { PatternUtil } from "../../../pre-v3/utils/Pattern.util"
import { Input } from "../input/Input.component"

export interface PortInputProps
    extends Omit<
        React.DetailedHTMLProps<React.InputHTMLAttributes<HTMLInputElement>, HTMLInputElement>,
        "placeholder" | "type" | "inputMode" | "min" | "max" | "onChange"
    > {
    onChange?: (value: string) => void
}

const MIN = 0
const MAX = 65535

export function PortInput(props: PortInputProps) {
    const { onChange, ...restProps } = props
    const localization = useServiceLocalization()

    const portInputRef = useRef<HTMLInputElement>(null)

    function handleOnChange(event: ChangeEvent<HTMLInputElement>) {
        const value = event.target.value

        const isValidInput: boolean = PatternUtil.POSITIVE_NUMBER_REGEX.test(value)

        if (isValidInput) {
            onChange?.(event.target.value)

            handleValidate(event.target.value)
        }
    }

    function handleValidate(value: string) {
        const valueNumber = Number(value)

        let errorMsg: string = ""

        if (valueNumber < MIN || valueNumber > MAX) {
            errorMsg = localization.getString("pleaseEnterAValidPort")
        }

        portInputRef?.current?.setCustomValidity?.(errorMsg)
    }

    return (
        <Input
            {...restProps}
            inputMode="numeric"
            onChange={handleOnChange}
            placeholder={localization.getString("port")}
            ref={portInputRef}
            type="text"
        />
    )
}
