import React from "react"
import styles from "./ServicesAddKubernetes.module.scss"
import { FormLabel, Input } from "../../../../components"

export default function () {
    return (
        <div className={this.props.className}>
            <FormLabel
                title={this.ls.getString("clusterName")}
                tooltip={this.ls.getString("kubeClusterNameDescription")}
                htmlFor="clusterName"
            >
                <Input
                    className={styles.formInput}
                    placeholder={this.ls.getString("egBanyanSecuredCluster")}
                    aria-label={this.ls.getString("clusterName")}
                    defaultValue={this.state.clusterName}
                    disabled={this.props.disabled}
                    onChange={this.onClusterNameChange}
                />
            </FormLabel>
            <FormLabel
                title={this.ls.getString("kubeOidcProxyCAPublicKey")}
                htmlFor="kubeOidcProxyCAPublicKey"
            >
                <textarea
                    className={styles.formTextArea}
                    type="text"
                    placeholder={this.ls.getString("kubeOidcProxyCAPublicKey")}
                    aria-label={this.ls.getString("kubeOidcProxyCAPublicKey")}
                    defaultValue={this.state.caKey}
                    disabled={this.props.disabled}
                    onChange={this.onCaKeyChange}
                />
            </FormLabel>
        </div>
    )
}
