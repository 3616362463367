import React, { ReactNode } from "react"
import ConfirmationDialogTemplate from "./ConfirmationDialog.template"
import { LocalizationService } from "../../../services/localization/Localization.service"
import { Bind } from "../../../decorators/Bind.decorator"

// @deprecated - use Modal instead
export class ConfirmationDialog extends React.Component<
    React.PropsWithChildren<ConfirmationDialogProps>,
    {}
> {
    public render(): ReactNode {
        return ConfirmationDialogTemplate.call(this)
    }

    private localizationService: LocalizationService = new LocalizationService()

    @Bind
    private onCloseHandler(): void {
        if (this.props.onClose) {
            this.props.onClose()
        }
    }

    @Bind
    private onConfirmHandler(): void {
        if (this.props.onConfirm) {
            this.props.onConfirm()
        }
    }
}

export interface ConfirmationDialogProps {
    open: boolean
    title?: string
    cancelLabel?: string
    confirmLabel?: string
    confirmDisabled?: boolean
    onClose?: Function
    onConfirm?: Function
}
