import React from "react"
import { Redirect, Route, Switch } from "react-router-dom"

import { ROUTE } from "../../routes"
import { OIDCLogin } from "../../pre-v3/views/oidc/OIDCLogin"
import { OIDCForgotPassword } from "../../pre-v3/views/oidc/OIDCForgotPassword"
import { OIDCConfirmPassword } from "../../pre-v3/views/oidc/OIDCConfirmPassword"
import { OIDCNewPassword } from "../../pre-v3/views/oidc/OIDCNewPassword"
import { OIDCOrgSelector } from "../../pre-v3/views/oidc/OIDCOrgSelector"

export function Oidc(): JSX.Element {
    return (
        <Switch>
            <Route path={ROUTE.OIDC_LOGIN} component={OIDCLogin} />
            <Route path={ROUTE.OIDC_FORGOT_PASSWORD} component={OIDCForgotPassword} />
            <Route path={ROUTE.OIDC_CONFIRM_PASSWORD} component={OIDCConfirmPassword} />
            <Route path={ROUTE.OIDC_NEW_PASSWORD} component={OIDCNewPassword} />
            <Route path={ROUTE.OIDC_ORG_SELECTOR} component={OIDCOrgSelector} />
            <Redirect to={ROUTE.OIDC_ORG_SELECTOR} />
        </Switch>
    )
}
