import { ColDef, GridApi, GridReadyEvent } from "ag-grid-community"
import * as React from "react"
import { useHistory } from "react-router-dom"

import { Grid } from "../../../../../pre-v3/components/grid/Grid.component"
import { LocalizationService } from "../../../../../pre-v3/services"
import AgGridUtil from "../../../../../pre-v3/utils/AgGrid.util"
import { encodeID } from "../../../../../pre-v3/utils/Url.util"
import { ROUTE, formatRoutePath } from "../../../../../routes"
import { ErrorBanner } from "../../../../components/banner/Banner.component"
import { Link } from "../../../../components/link/Link.component"
import { SearchInput } from "../../../../components/search-input/SearchInput.component"
import { Device, useGetDevicesByUserEmail } from "../../../../services/Device.service"
import styles from "./UserOverview.module.scss"

interface Props {
    email: string
}

export function UserDeviceList(props: Props) {
    const ls = new LocalizationService()
    const gridApi = React.useRef<GridApi>()
    const history = useHistory()
    const [search, setSearch] = React.useState<string>("")

    const {
        data: deviceInfo,
        isFetching: isDeviceDataLoading,
        error: deviceError,
    } = useGetDevicesByUserEmail(props.email)

    function onGridReady(event: GridReadyEvent): void {
        gridApi.current = event.api
        if (gridApi.current && deviceInfo) {
            gridApi.current.setRowData(deviceInfo)
        }
    }

    const deviceColumns: ColDef[] = [
        {
            headerName: ls.getString("name"),
            field: "name",
            tooltipValueGetter: AgGridUtil.linkTooltipValueGetter,
            cellRenderer: DeviceCellRenderer,
            comparator: AgGridUtil.alphaBetComparator,
        },
        {
            headerName: ls.getString("serialNumber"),
            field: "serialNumber",
            comparator: AgGridUtil.alphaBetComparator,
        },
        {
            headerName: ls.getString("lastLogin"),
            field: "lastLoginAt",
            valueFormatter: AgGridUtil.dateFormatter,
        },
    ]

    React.useEffect(() => {
        gridApi.current?.setQuickFilter?.(search)
    }, [search])

    return (
        <>
            {deviceError && !isDeviceDataLoading && (
                <ErrorBanner>{ls.getString("failedToLoadUserActivity")}</ErrorBanner>
            )}
            {deviceInfo && !isDeviceDataLoading && (
                <>
                    <SearchInput
                        className={styles.search}
                        value={search}
                        onChangeValue={setSearch}
                        placeholder={ls.getString("search")}
                    />
                    <Grid
                        columnDefs={deviceColumns}
                        context={{ history }}
                        onGridReady={onGridReady}
                        pagination
                    />
                </>
            )}
        </>
    )
}

interface CellRendererProps {
    data: Device
    value: string
}

function DeviceCellRenderer(props: CellRendererProps) {
    if (!props.data.serialNumber) {
        return props.value || "-"
    }

    return (
        <Link
            to={formatRoutePath(ROUTE.DEVICES_DETAILS, {
                serialNumber: encodeID(props.data.serialNumber),
            })}
        >
            {props.value || "-"}
        </Link>
    )
}
