import React from "react"
import { Redirect, Route, Switch } from "react-router-dom"

import { useFeatureFlags } from "../../../hooks/useFeatureFlags.hook"
import UpgradeFullIllustration from "../../../images/upgrade-full-page.svg"
import { useServiceLinks } from "../../../pre-v3/services/link/Link.service"
import { useServiceLocalization } from "../../../pre-v3/services/localization/Localization.service"
import { ROUTE } from "../../../routes"
import { AppText } from "../../components/app-text/AppText.component"
import { ErrorBanner } from "../../components/banner/Banner.component"
import { Container } from "../../components/container/Container.component"
import { Loader } from "../../components/loader/Loader.component"
import { EmptyStateScreen } from "../../components/page-screen/PageScreen.component"
import { ConnectorAdd } from "./add/ConnectorAdd.view"
import { ConnectorList } from "./list/ConnectorList.view"
import { ConnectorOverview } from "./overview/ConnectorOverview.view"
import useTitle from "../../../hooks/useTitle.hook"

export function Connector(): JSX.Element {
    useTitle(["connectors", "network", "adminConsole"])
    const links = useServiceLinks()
    const localization = useServiceLocalization()

    const featureFlagsQuery = useFeatureFlags()

    if (featureFlagsQuery.status === "loading") {
        return <Loader children isLoading center medium />
    }

    if (featureFlagsQuery.status === "error") {
        return (
            <Container>
                <ErrorBanner>{String(featureFlagsQuery.error)}</ErrorBanner>
            </Container>
        )
    }

    const {
        globalEdge,
        canAccessConnectorInstallStep,
        canDeleteConnector,
        canEditConnector,
        canCreateConnector,
    } = featureFlagsQuery.data.adminConsole

    if (globalEdge.shouldAddGlobalEdge) {
        return (
            <EmptyStateScreen>
                <img
                    src={UpgradeFullIllustration}
                    alt={localization.getString("upgradeIllustration")}
                />
                <AppText
                    ls={{
                        key: "organizationWithNoGlobalEdge",
                        replaceVals: [
                            links.getLink("supportLink"),
                            links.getLink("deploymentModelsDoc"),
                        ],
                    }}
                />
            </EmptyStateScreen>
        )
    }

    return (
        <Switch>
            <Route path={ROUTE.CONNECTORS} exact>
                <ConnectorList canCreateConnector={canCreateConnector} />
            </Route>
            <Route path={ROUTE.CONNECTORS_ADD} exact>
                <ConnectorAdd
                    clusterName={globalEdge.globalEdgeClusterName}
                    canCreateConnector={canCreateConnector}
                />
            </Route>
            <Route path={ROUTE.CONNECTORS_DETAILS}>
                <ConnectorOverview
                    canDeleteConnector={canDeleteConnector}
                    canEditConnector={canEditConnector}
                    canAccessConnectorInstallStep={canAccessConnectorInstallStep}
                    canCreateConnector={canCreateConnector}
                />
            </Route>
            <Redirect to={ROUTE.CONNECTORS} />
        </Switch>
    )
}
