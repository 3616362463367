import React from "react"
import styles from "./TransferList.module.scss"
import { Button } from "../button/Button.component"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { InputWithAction } from "../input-with-action/InputWithAction.component"
import { MenuItem } from "../menu-item/MenuItem.component"
import { faSearch, faTimes } from "@fortawesome/pro-solid-svg-icons"
import classNames from "classnames/bind"
import { FormLabelContext } from ".."

export default function () {
    return (
        <FormLabelContext.Consumer>
            {({ htmlFor }) => (
                <div className={classNames(this.props.className, styles.transferList)}>
                    <div className={styles.pane}>
                        <div className={styles.header}>
                            <div className={styles.title}>{this.ls.getString("available")}</div>
                            <div className={styles.paneInputs}>
                                <InputWithAction
                                    placeholder={this.ls.getString("searchFilter")}
                                    onAction={(e) => this.onFilterClear(true)}
                                    onChange={(e) => this.onFilterChange(true, e)}
                                    disabled={this.props.disabled}
                                    value={this.state.availableFilter}
                                    id={`add_${htmlFor}`}
                                    name={`add_${htmlFor}`}
                                >
                                    <div className={styles.faContainer}>
                                        <FontAwesomeIcon
                                            icon={this.state.availableFilter ? faTimes : faSearch}
                                        />
                                    </div>
                                </InputWithAction>
                                <Button
                                    onClick={this.onSelectAll}
                                    className={classNames(styles.actionBtn, "addBtn")}
                                    disabled={this.props.disabled}
                                >
                                    {this.ls.getString("addAll")}
                                </Button>
                            </div>
                        </div>
                        <div className={styles.divider} />
                        <div
                            className={classNames(styles.list, {
                                [styles.disabled]: this.props.disabled,
                            })}
                        >
                            {this.state.availableFilteredList.map((item) =>
                                item ? (
                                    <MenuItem
                                        key={item.value || item}
                                        className={classNames({
                                            [styles.disabled]: this.props.disabled,
                                        })}
                                        onClick={() => this.onSelect(item)}
                                    >
                                        {item.displayName || item}
                                    </MenuItem>
                                ) : null
                            )}
                        </div>
                        <select
                            id={this.props.id || htmlFor}
                            name={this.props.name || htmlFor}
                            className={styles.hiddenInput}
                            value={this.props.value.length > 0 ? "1" : ""}
                            required={this.props.required}
                            onChange={() => {}}
                        >
                            <option value=""></option>
                            <option value="1">1</option>
                        </select>
                    </div>
                    <div className={classNames(styles.pane, styles.rightPane)}>
                        <div className={styles.header}>
                            <span className={styles.title}>{this.ls.getString("selected")}</span>
                            <div className={styles.paneInputs}>
                                <InputWithAction
                                    placeholder={this.ls.getString("searchFilter")}
                                    onAction={(e) => this.onFilterClear(false)}
                                    onChange={(e) => this.onFilterChange(false, e)}
                                    disabled={this.props.disabled}
                                    value={this.state.selectedFilter}
                                    id={`remove_${htmlFor}`}
                                    name={`remove_${htmlFor}`}
                                >
                                    <div className={styles.faContainer}>
                                        <FontAwesomeIcon
                                            icon={this.state.selectedFilter ? faTimes : faSearch}
                                        />
                                    </div>
                                </InputWithAction>
                                <Button
                                    onClick={this.onDeselectAll}
                                    className={classNames(styles.actionBtn, "removeBtn")}
                                    disabled={this.props.disabled}
                                >
                                    {this.ls.getString("removeAll")}
                                </Button>
                            </div>
                        </div>
                        <div className={styles.divider} />
                        <div className={styles.list}>
                            {this.state.selectedFilteredList?.map((item) =>
                                item ? (
                                    <MenuItem
                                        key={item.value || item}
                                        className={classNames({
                                            [styles.disabled]: this.props.disabled,
                                        })}
                                        onClick={() => this.onDeselect(item)}
                                    >
                                        {item.displayName || item}
                                    </MenuItem>
                                ) : null
                            )}
                        </div>
                    </div>
                </div>
            )}
        </FormLabelContext.Consumer>
    )
}
