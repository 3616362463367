import { GetClustersRes, ClusterRes } from "../../api/Cluster.api"

export interface Cluster {
    id: string
    name: string
    shieldAddress: string
}

export interface EdgeClusters {
    private?: Cluster
    global?: Cluster
}

export function isGlobalEdgeCluster(clusterRes: ClusterRes): boolean {
    return clusterRes.GroupType === "EDGE"
}

export function getEdgeClustersFromRes(getClustersRes: GetClustersRes): EdgeClusters {
    return getClustersRes.Configs?.reduce(reduceCluster, noEdgeClusters) ?? noEdgeClusters
}

const noEdgeClusters: EdgeClusters = {}

function reduceCluster(acc: EdgeClusters, clusterRes: ClusterRes): EdgeClusters {
    const cluster = mapClusterRes(clusterRes)
    return isGlobalEdgeCluster(clusterRes)
        ? { ...acc, global: cluster }
        : { ...acc, private: cluster }
}

export function mapClusterRes(clusterRes: ClusterRes): Cluster {
    return {
        id: clusterRes.UUID,
        name: clusterRes.ShieldName,
        shieldAddress: clusterRes.PublicAddr,
    }
}
