import React from "react"
import { Route, Switch } from "react-router-dom"

import { ApplicationCheck } from "../../../../../../../pre-v3/views/settings/preferred-apps/ApplicationCheck.component"
import { AddApplicationCheck } from "../../../../../../../pre-v3/views/settings/preferred-apps/add/AddApplicationCheck.component"
import { EditApplicationCheck } from "../../../../../../../pre-v3/views/settings/preferred-apps/edit/EditApplicationCheck.component"
import { OperatingSystemVersion } from "../../../../../../../pre-v3/views/settings/trust-score/OperatingSystemVersion.component"
import { ROUTE } from "../../../../../../../routes"
import { TrustFactorList } from "./TrustFactorList.view"

export function PreMigrationTrustFactors(): JSX.Element {
    return (
        <Switch>
            <Route
                key={ROUTE.APPLICATION_CHECK_ADD}
                path={ROUTE.APPLICATION_CHECK_ADD}
                component={AddApplicationCheck}
            />
            <Route
                key={ROUTE.APPLICATION_CHECK_DETAILS}
                path={ROUTE.APPLICATION_CHECK_DETAILS}
                component={EditApplicationCheck}
            />
            <Route
                key={ROUTE.APPLICATION_CHECK}
                path={ROUTE.APPLICATION_CHECK}
                component={ApplicationCheck}
            />
            <Route
                key={ROUTE.OPERATING_SYSTEM_VERSIONS}
                path={ROUTE.OPERATING_SYSTEM_VERSIONS}
                component={OperatingSystemVersion}
            />
            <Route
                path={[ROUTE.TRUST_FACTOR_SETTINGS, ROUTE.MSP_TRUST_FACTOR_SETTINGS]}
                component={TrustFactorList}
            />
        </Switch>
    )
}
