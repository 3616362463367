import {
    ColDef,
    GridApi,
    GridReadyEvent,
    ICellRendererParams,
    RowDoubleClickedEvent,
} from "ag-grid-community"
import React, { useRef, useState } from "react"
import { useHistory } from "react-router-dom"

import UpgradeFullIllustration from "../../../../images/upgrade-full-page.svg"
import { Grid } from "../../../../pre-v3/components/grid/Grid.component"
import { LocalizationService } from "../../../../pre-v3/services"
import { LanguageKey } from "../../../../pre-v3/services/localization/languages/en-US.language"
import AgGridUtil from "../../../../pre-v3/utils/AgGrid.util"
import { encodeID } from "../../../../pre-v3/utils/Url.util"
import { useDebouncedCallback } from "../../../../pre-v3/utils/UseDebouncedCallback.hook"
import { ROUTE, formatRoutePath } from "../../../../routes"
import { Link } from "../../../components/link/Link.component"
import { Loader } from "../../../components/loader/Loader.component"
import { EmptyStateScreen } from "../../../components/page-screen/PageScreen.component"
import { SearchInput } from "../../../components/search-input/SearchInput.component"
import { Status, StatusType } from "../../../components/status/Status.component"
import {
    RegisteredDomain,
    RegisteredDomainStatus,
    useGetRegisteredDomains,
} from "../../../services/RegisteredDomain.service"
import styles from "./RegisteredDomainList.module.scss"
import { ErrorBanner } from "../../../components/banner/Banner.component"
import {
    Button,
    ButtonElement,
    ButtonType,
    IconType,
} from "../../../../components/button/Button.component"

export interface Props {
    enableAccessTierGroups: boolean
    canAddRegisteredDomain?: boolean
}

export function RegisteredDomainList(props: Props): JSX.Element {
    const ls = new LocalizationService()
    const history = useHistory()

    const [filter, setFilter] = useState<string>("")
    const gridApi = useRef<GridApi>()

    const registeredDomains = useGetRegisteredDomains(props.enableAccessTierGroups, {
        onSuccess: (data: RegisteredDomain[]) => {
            if (gridApi.current) {
                gridApi.current.setRowData(data)
            }
        },
    })

    const columns: ColDef[] = [
        {
            headerName: ls.getString("status"),
            field: "status",
            flex: 65,
            cellRenderer: StatusCellRenderer,
            sortable: false,
        },
        {
            headerName: ls.getString("domain"),
            field: "name",
            tooltipValueGetter: AgGridUtil.linkTooltipValueGetter,
            flex: 120,
            cellRenderer: DomainCellRenderer,
        },
        {
            headerName: ls.getString("cluster"),
            field: "clusterName",
            flex: 65,
            valueFormatter: AgGridUtil.nullableStringFormatter,
        },
        {
            headerName: ls.getString("network"),
            field: "networkName",
            flex: 65,
            valueFormatter: AgGridUtil.nullableStringFormatter,
        },
        {
            headerName: ls.getString("description"),
            field: "description",
            flex: 100,
            valueFormatter: AgGridUtil.nullableStringFormatter,
        },
    ]

    function onFilterChange(newFilter: string): void {
        setFilter(newFilter)
        setFilterModel(newFilter)
    }

    const setFilterModel = useDebouncedCallback((filter: string) => {
        if (gridApi.current) {
            gridApi.current.setQuickFilter(filter)
        }
    }, [])

    function onRowDoubleClicked(event: RowDoubleClickedEvent<RegisteredDomain>): void {
        event.data?.id &&
            history.push(
                formatRoutePath(ROUTE.REGISTERED_DOMAINS_DETAILS, {
                    id: encodeID(event.data.id),
                })
            )
    }

    function onGridReady(event: GridReadyEvent): void {
        if (event?.api) {
            gridApi.current = event.api

            if (registeredDomains.data) {
                gridApi.current.setRowData(registeredDomains.data)
            }
        }
    }

    if (registeredDomains.isLoading) {
        return <Loader children isLoading center medium />
    }

    if (registeredDomains.data?.length === 0) {
        return (
            <EmptyStateScreen
                title={ls.getString("addRegisteredDomain")}
                subText={ls.getString("addRegisteredDomainDescription")}
                buttonProps={{
                    buttonText: ls.getString("addRegisteredDomain"),
                    buttonLink: ROUTE.REGISTERED_DOMAINS_ADD,
                }}
            >
                <div>
                    <img
                        src={UpgradeFullIllustration}
                        className={styles.screen}
                        alt={ls.getString("addRegisteredDomain")}
                    />
                </div>
            </EmptyStateScreen>
        )
    }

    return (
        <div className={styles.container}>
            {registeredDomains.error && (
                <ErrorBanner className={styles.errorBanner}>{registeredDomains.error}</ErrorBanner>
            )}
            <div className={styles.toolbar}>
                <div className={styles.left}>
                    <SearchInput
                        value={filter}
                        onChangeValue={onFilterChange}
                        placeholder={ls.getString("search")}
                    />
                </div>
                <div className={styles.right}>
                    {props.canAddRegisteredDomain && <CreateButton />}
                </div>
            </div>
            <div className={styles.gridContainer}>
                <Grid
                    onGridReady={onGridReady}
                    columnDefs={columns}
                    onRowDoubleClicked={onRowDoubleClicked}
                    pagination
                />
            </div>
        </div>
    )
}

function CreateButton(props: { disabled?: boolean }): JSX.Element {
    const ls = new LocalizationService()

    return (
        <Button
            {...(props.disabled
                ? { asElement: ButtonElement.BUTTON, disabled: true }
                : { asElement: ButtonElement.LINK, to: ROUTE.REGISTERED_DOMAINS_ADD })}
            buttonType={ButtonType.PRIMARY}
            icon={IconType.PLUS}
        >
            {ls.getString("addRegisteredDomain")}
        </Button>
    )
}

const statusMap: Record<RegisteredDomainStatus, StatusType> = {
    Pending: "unknown",
    Verified: "success",
    Failed: "error",
}

const labelMap: Record<RegisteredDomainStatus, LanguageKey> = {
    Pending: "pending",
    Verified: "verified",
    Failed: "error",
}

function StatusCellRenderer(props: ICellRendererParams) {
    const ls = new LocalizationService()
    const registeredDomain: RegisteredDomain = props.data
    if (!registeredDomain.status) {
        return props.value
    }
    return (
        <Status
            type={statusMap[registeredDomain.status]}
            label={ls.getString(labelMap[registeredDomain.status])}
        />
    )
}

function DomainCellRenderer(props: ICellRendererParams) {
    const registeredDomain: RegisteredDomain = props.data
    if (!registeredDomain.id) {
        return props.value
    }
    return (
        <Link
            to={formatRoutePath(ROUTE.REGISTERED_DOMAINS_DETAILS, {
                id: encodeID(registeredDomain.id),
            })}
        >
            {props.value}
        </Link>
    )
}
