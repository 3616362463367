import { ICellRendererParams } from "ag-grid-community"
import React, { ReactNode, SyntheticEvent } from "react"

import { ROUTE, formatRoutePath } from "../../../../../routes"
import { Bind } from "../../../../decorators/Bind.decorator"
import { encodeID } from "../../../../utils/Url.util"
import PreferredAppNameCellRendererTemplate from "./PreferredAppNameCellRenderer.template"

export class ApplicationNameCellRenderer extends React.Component<
    PreferredAppNameCellRendererProps,
    PreferredAppNameCellRendererState
> {
    public static getDerivedStateFromProps(
        props: PreferredAppNameCellRendererProps
    ): PreferredAppNameCellRendererState {
        return {
            link: formatRoutePath(ROUTE.APPLICATION_CHECK_DETAILS, {
                id: encodeID(props.data.name),
            }),
        }
    }

    public state: PreferredAppNameCellRendererState = { link: "" }

    public render(): ReactNode {
        return PreferredAppNameCellRendererTemplate.call(this)
    }

    public refresh(): boolean {
        return true
    }

    @Bind
    private onClick(event: SyntheticEvent): void {
        event.preventDefault()
        this.props.context.history.push(this.state.link)
    }
}

interface PreferredAppNameCellRendererProps extends ICellRendererParams {
    context: any
}

interface PreferredAppNameCellRendererState {
    link: string
}
