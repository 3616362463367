import { LocalizationService } from "../services/localization/Localization.service"
import { StringUtil } from "./String.util"

/**
 * Provide utility functions for immutable collections
 */
export class CollectionUtil {
    /**
     * Add the value to the end of the array
     * @param array Array you want to modify
     * @param value Value you want to add
     * @returns New array with the value at the end
     */
    public static addValue<T>(array: T[], value: T): T[] {
        return [...array, value]
    }

    /**
     * Remove a value from the array at an index
     * @param array Array you want to modify
     * @param index Index of the value you want to remove
     * @returns New array with all the previous values except the one removed
     */
    public static removeValueByIndex<T>(array: T[], index: number): T[] {
        return [...array.slice(0, index), ...array.slice(index + 1)]
    }

    /**
     * Tells if an array has content - loosely defined
     * @param array Array to test
     * @returns true if array "has content"
     */
    public static isTruthy<T>(array: T[] | undefined): boolean {
        return Array.isArray(array) && array.length > 0 && !!array[0]
    }

    /**
     * Safely resolve a key from an object
     * @param obj Object to access
     * @param key key to fetch
     * @returns value if exists, undefined otherwise
     */
    public static safeGetProperty<R>(obj: any, key: string): R | undefined {
        try {
            return obj[key]
        } catch {
            return undefined
        }
    }

    /**
     * Safely resolve an indexed object from an array
     * @param array array to access
     * @param idx index to fetch
     * @returns value if exists, undefined otherwise
     */
    public static safeGetIndex<T>(array: T[] | undefined, idx: number): T | undefined {
        return Array.isArray(array) ? array[idx] : undefined
    }

    /**
     * Returns an array of key/values of the enumerable properties of an object
     */
    public static entries<Key extends string | number | symbol, Value>(
        object: Record<Key, Value>
    ): [Key, Value][] {
        return Object.entries(object) as [Key, Value][]
    }

    public static createDictionary<Key extends string>(
        record: Record<Key, unknown>
    ): Record<Key, string> {
        return keys(record).reduce(reduceDictionary, {} as Record<Key, string>)
    }

    public static isObject(val: any): boolean {
        return !Array.isArray(val) && typeof val === "object" && val !== null
    }

    public static caseInsensitiveSort<T>(
        arr: T[],
        fieldName: keyof T,
        localization: LocalizationService
    ): T[] {
        const locale = localization.getLocale()

        return [...arr].sort((a, b) => {
            if (typeof a[fieldName] !== "string" || typeof b[fieldName] != "string") {
                return 1
            }
            return (a[fieldName] as any).localeCompare(b[fieldName] as any, locale)
        })
    }
}

export function replaceKeys<
    BeforeKey extends string | number | symbol,
    AfterKey extends string | number | symbol,
    Data,
>(
    record: Partial<Record<BeforeKey, Data>>,
    beforeToAfterDict: Record<BeforeKey, AfterKey>
): Partial<Record<AfterKey, Data>> {
    return keys(record).reduce(
        (acc, beforeKey) => {
            const afterKey = beforeToAfterDict[beforeKey]
            return { ...acc, [afterKey]: record[beforeKey] }
        },
        {} as Record<AfterKey, Data>
    )
}

function keys<Key extends string | number | symbol>(record: Partial<Record<Key, unknown>>): Key[] {
    return Object.keys(record) as Key[]
}

function reduceDictionary<Key extends string>(
    dict: Record<Key, string>,
    key: Key
): Record<Key, string> {
    return { ...dict, [key]: StringUtil.toCamelCase(key) }
}
