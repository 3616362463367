import React from "react"
import styles from "./MenuItem.module.scss"
import { Link } from "react-router-dom"

export function MenuItemDividerTemplate() {
    return <hr className={styles.menuItemDivider} />
}

export function MenuItemLinkTemplate() {
    return (
        <li className={styles.menuItem}>
            <Link
                onClick={this.props.onClick}
                to={this.props.href}
                className={styles.menuItemLink}
                target={this.props.target || "_self"}
            >
                {this.props.children}
            </Link>
        </li>
    )
}

export function MenuItemButtonTemplate() {
    return (
        <li className={styles.menuItem}>
            <button type="button" onClick={this.props.onClick} className={styles.menuItemButton}>
                {this.props.children}
            </button>
        </li>
    )
}

export function MenuItemExternalLinkTemplate() {
    return (
        <li className={styles.menuItem}>
            <a
                href={this.props.href}
                className={styles.menuItemLink}
                target={this.props.target || "_self"}
            >
                {this.props.children}
            </a>
        </li>
    )
}
