import classNames from "classnames/bind"
import React from "react"

import styles from "./Input.module.scss"

interface Input<HTMLElement extends HTMLTextAreaElement | HTMLInputElement> {
    className?: string
    disabled?: boolean
    placeholder?: string
    onBlur?: React.FocusEventHandler<HTMLElement>
    onChange?: React.ChangeEventHandler<HTMLElement>
    onChangeValue?(value: string): void
}

interface Output<HTMLElement extends HTMLTextAreaElement | HTMLInputElement> {
    className?: string
    placeholder?: string
    onBlur?: React.FocusEventHandler<HTMLElement>
    onChange?: React.ChangeEventHandler<HTMLElement>
}

export function useInput<HTMLElement extends HTMLTextAreaElement | HTMLInputElement>(
    input: Input<HTMLElement>
): Output<HTMLElement> {
    const onChange = React.useCallback<React.ChangeEventHandler<HTMLElement>>(
        (event) => {
            event.preventDefault()
            input.onChange?.(event)
            input.onChangeValue?.(event.target.value)
        },
        [input.onChange, input.onChangeValue]
    )

    const onBlur = React.useCallback<React.FocusEventHandler<HTMLElement>>(
        (event) => {
            input.onBlur?.(event)
            input.onChangeValue?.(event.target.value.trim())
        },
        [input.onBlur, input.onChangeValue]
    )

    return {
        className: classNames(styles.input, input.className),
        placeholder: input.disabled ? "" : input.placeholder,
        onChange,
        onBlur,
    }
}
