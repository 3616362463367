import React from "react"
import { useHistory, useParams } from "react-router-dom"

import { DeleteCancelActions } from "../../../../../pre-v3/components/modal/delete-cancel/DeleteCancelActions"
import { useServiceLocalization } from "../../../../../pre-v3/services/localization/Localization.service"
import { IconType } from "../../../../../pre-v3/services/ActionBar.service"
import { useServiceModal } from "../../../../../pre-v3/services/Modal.service"
import { decodeID } from "../../../../../pre-v3/utils/Url.util"
import { ROUTE } from "../../../../../routes"
import {
    PlistTrustFactor,
    Plist,
    NewPlist,
    useDeletePropertyList,
    useUpdatePropertyList,
} from "../../../../services/TrustFactor.service"
import { Container } from "../../../../components/container/Container.component"
import { LargeMessage } from "../../../../components/large-message/LargeMessage.component"
import { Loader } from "../../../../components/loader/Loader.component"
import { PlistForm } from "./PlistForm.component"
import { DeletePlist } from "./DeletePlist.component"
import {
    Button,
    ButtonElement,
    ButtonType,
} from "../../../../../components/button/Button.component"
import { PageHeading } from "../../../../../components/page-heading/PageHeading.component"
import trustStyles from "../../../../../pages/trust/Trust.module.scss"
import styles from "./PlistForm.module.scss"
import { Tooltip } from "../../../../components/tooltip/Tooltip.component"

interface PlistEditProps {
    plistTrustFactor?: PlistTrustFactor
    isLoading: boolean
    canAccessPListCheckFactor?: boolean
}

export function PlistEdit(props: PlistEditProps): JSX.Element {
    const { isLoading, plistTrustFactor } = props

    const { id: encodedPlistId } = useParams<{ id: string }>()

    const localization = useServiceLocalization()
    const history = useHistory()
    const modalService = useServiceModal()

    const plist = React.useMemo((): Plist | undefined => {
        const plistId = decodeID(encodedPlistId)
        return plistTrustFactor?.plists.find((plist) => plist.id === plistId)
    }, [plistTrustFactor?.plists, encodedPlistId])

    const {
        mutateAsync: updatePlistTrustFactor,
        isLoading: isUpdatingPlistTrustFactor,
        error: updatePlistTrustFactorError,
    } = useUpdatePropertyList({ onSuccess: () => history.push(ROUTE.FACTORS_PLIST) })

    const {
        mutateAsync: deletePlistTrustFactor,
        isLoading: isDeletingPlistTrustFactor,
        error: deletePlistTrustFactorError,
    } = useDeletePropertyList()

    const onDelete: React.MouseEventHandler = (event) => {
        event.preventDefault()

        if (!plist) return

        modalService
            .open(
                localization.getString("deletePropertyList"),
                { component: DeletePlist, props: { plist }, withoutBody: true },
                { component: DeleteCancelActions }
            )
            .onClose(async () => {
                await deletePlistTrustFactor(plist)
                history.push(ROUTE.FACTORS_PLIST)
            })
    }

    function onSubmit(editedPlist: NewPlist): void {
        updatePlistTrustFactor({
            ...plist!,
            ...editedPlist,
        })
    }

    const errors = [
        typeof updatePlistTrustFactorError === "string" && updatePlistTrustFactorError,
        typeof deletePlistTrustFactorError === "string" && deletePlistTrustFactorError,
    ]

    return (
        <div className={styles.container}>
            <section aria-labelledby={Id.HEADING} className={trustStyles.section}>
                <header className={trustStyles.header}>
                    <PageHeading id={Id.HEADING}>{plist?.name}</PageHeading>
                    {props.canAccessPListCheckFactor && (
                        <Tooltip title={localization.getString("delete")}>
                            <Button
                                icon={IconType.TRASH}
                                onClick={onDelete}
                                asElement={ButtonElement.BUTTON}
                                buttonType={ButtonType.SECONDARY}
                                aria-label={localization.getString("delete")}
                                disabled={isLoading}
                            />
                        </Tooltip>
                    )}
                </header>
            </section>
            <Loader
                isLoading={isDeletingPlistTrustFactor || isLoading}
                title={localization.getString("loadingPropertyList")}
                center
                medium
            >
                {plist ? (
                    <Container>
                        <PlistForm
                            defaultValue={plist}
                            onSubmit={onSubmit}
                            cancelHref={ROUTE.FACTORS_PLIST}
                            submitting={isUpdatingPlistTrustFactor}
                            errors={errors}
                            canAccessPListCheckFactor={props.canAccessPListCheckFactor}
                        />
                    </Container>
                ) : (
                    <LargeMessage>{localization.getString("propertyListNotFound")}</LargeMessage>
                )}
            </Loader>
        </div>
    )
}

enum Id {
    HEADING = "heading",
}
