// externals
import * as React from "react"
import { faTimes } from "@fortawesome/pro-light-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
// locals
import {
    ExemptedPathPattern,
    ExemptedPathPatternMethods,
    ExemptedPathPatternTemplates,
} from "../../../../api/Manage.api"
import { MultiInput, Select, FormLabel, Button, FormHeader, Input } from "../../../../components"
import { ServiceManage, useServiceLocalization, useServiceManage } from "../../../../services"
import styles from "./CorsExemptions.module.scss"

export type PatternWithID = ExemptedPathPattern & { id: string }

interface Props {
    value: PatternWithID[]
    onChange: (values: PatternWithID[]) => void | Promise<void>
    edit?: ServiceManage | null | undefined
}

type Updater = (newValue: PatternWithID[]) => PatternWithID[]

export default function CorsExemptions({ value, onChange }: Props) {
    const localization = useServiceLocalization()

    return (
        <>
            {value.map((pattern, i) => (
                <CorsInputGroup
                    key={pattern.id}
                    index={i}
                    pattern={pattern}
                    setPatterns={(updater: Updater) => {
                        onChange(updater(value))
                    }}
                />
            ))}
            <Button
                className={styles.addButton}
                onClick={() =>
                    onChange([
                        ...value,
                        {
                            id: Math.random().toString(),
                            methods: [ExemptedPathPatternMethods.ALL],
                            mandatory_headers: ["*"],
                            paths: ["/*"],
                            hosts: [
                                {
                                    origin_header: ["*"],
                                    target: ["*"],
                                },
                            ],
                            template: ExemptedPathPatternTemplates.CORS,
                            source_cidrs: [],
                        },
                    ])
                }
            >
                {localization.getString("addCorsExemption")}
            </Button>
        </>
    )
}
function CorsInputGroup({
    index,
    pattern,
    setPatterns,
}: {
    index: number
    pattern: PatternWithID
    setPatterns: (updater: Updater) => void
}) {
    // pull out the services we need
    const localization = useServiceLocalization()
    const manage = useServiceManage()

    // handlers to update the field of the specific index
    const updateOrigins = (value: string) => {
        // make a local copy of the list
        setPatterns((current) => {
            const local = [...current]

            // this form only builds up a single entry in the hosts field
            local[index].hosts = [
                {
                    origin_header: value ? [value] : [],
                    target: local[index]?.hosts?.[0]?.target || [],
                },
            ]
            return local
        })
    }
    const updatePaths = (value: string[]) => {
        setPatterns((current) => {
            const local = [...current]
            local[index].paths = value
            return local
        })
    }
    const updateHeaders = (value: string[]) => {
        setPatterns((current) => {
            const local = [...current]
            local[index].mandatory_headers = value
            return local
        })
    }
    const updateCidrs = (value: string[]) => {
        setPatterns((current) => {
            const local = [...current]
            local[index].source_cidrs = value
            return local
        })
    }
    const updateMethods = (value: string | string[]) => {
        setPatterns((current) => {
            const local = [...current]
            local[index].methods = value as ExemptedPathPatternMethods[]
            return local
        })
    }
    const removePattern = () => {
        setPatterns((current) => {
            const local = [...current]
            local.splice(index, 1)
            return local
        })
    }

    const updateTargets = (targets: string[]) => {
        // if the user clears the list, fill the form with *
        if (targets.length === 0) {
            targets = ["*"]
        }

        // update the target field for every origin header
        setPatterns((current) => {
            const local = [...current]
            local[index].hosts =
                local[index]?.hosts?.map((host) => ({
                    ...host,
                    target: targets,
                })) || ([] as PatternWithID["hosts"])
            return local
        })
    }

    return (
        <>
            <div className={styles.headerContainer}>
                <FormHeader>
                    {localization.getString("corsExemptionHash")}
                    {index + 1}
                </FormHeader>

                <button type="button" className={styles.closeButton} onClick={removePattern}>
                    <FontAwesomeIcon icon={faTimes} style={{ width: 12, height: 12 }} />
                </button>
            </div>
            <FormLabel title={localization.getString("originDomain")} htmlFor="originDomain">
                <Input
                    className={styles.formInput}
                    placeholder={localization.getString("originDomainPlaceholder")}
                    value={pattern.hosts?.[0]?.origin_header[0] || ""}
                    onChange={(e) => updateOrigins(e.target.value)}
                />
            </FormLabel>
            <FormLabel
                title={localization.getString("targetDomainsOptional")}
                tooltip={localization.getString("targetDomainsDescription")}
                htmlFor="targetDomainsOptional"
            >
                <MultiInput
                    initialValues={pattern.hosts?.flatMap((host) => host.target) || []}
                    onChange={updateTargets}
                    placeholder={localization.getString("targetDomainsPlaceholder")}
                />
            </FormLabel>
            <FormLabel title={localization.getString("methods")} htmlFor="methods">
                <Select
                    multiple
                    placeholder={localization.getString("validMethods")}
                    options={[
                        { displayName: localization.getString("ALL"), value: "*" },
                        {
                            displayName: ExemptedPathPatternMethods.GET,
                            value: ExemptedPathPatternMethods.GET,
                        },
                        {
                            displayName: ExemptedPathPatternMethods.POST,
                            value: ExemptedPathPatternMethods.POST,
                        },
                        {
                            displayName: ExemptedPathPatternMethods.PUT,
                            value: ExemptedPathPatternMethods.PUT,
                        },
                        {
                            displayName: ExemptedPathPatternMethods.DELETE,
                            value: ExemptedPathPatternMethods.DELETE,
                        },
                    ]}
                    value={pattern.methods || []}
                    onChange={updateMethods}
                />
            </FormLabel>
            <FormLabel
                title={localization.getString("mandatoryHeadersOptional")}
                htmlFor="mandatoryHeadersOptional"
            >
                <MultiInput
                    initialValues={pattern.mandatory_headers || []}
                    onChange={updateHeaders}
                    placeholder={localization.getString("mandatoryHeaders")}
                />
            </FormLabel>
            <FormLabel title={localization.getString("pathsOptional")} htmlFor="pathsOptional">
                <MultiInput
                    initialValues={pattern.paths || []}
                    onChange={updatePaths}
                    placeholder={localization.getString("paths")}
                />
            </FormLabel>
            <FormLabel
                title={localization.getString("sourceCidrsOptional")}
                htmlFor={`sourceCidrsOptional-${index}`}
            >
                <MultiInput
                    initialValues={pattern.source_cidrs || []}
                    onChange={updateCidrs}
                    placeholder={localization.getString("sourceCidrs")}
                    pattern={manage.CIDR_REGEX}
                />
            </FormLabel>
        </>
    )
}
