import { DateUtil } from "../../pre-v3/utils/Date.util"

export class DataPolicyService {
    private dataset: DataPolicy[] = [
        {
            id: "1",
            name: "No Large File Downloads",
            status: DataPolicyStatus.ENFORCING,
            created_at: DateUtil.convertLargeTimestamp(1612315842051832000),
            updated_at: DateUtil.convertLargeTimestamp(1674240444231314400),
            attachments: [
                {
                    type: AttachTypes.HOSTED_WEBSITE,
                    name: "TrialsPlatform",
                    id: "TrialsPlatform.cluster1.bnn",
                },
                {
                    type: AttachTypes.HOSTED_WEBSITE,
                    name: "Salesforce",
                    id: "Salesforce.cluster1.bnn",
                },
            ],
            assignments: ["Contractors"],
            download_rule: {
                file: true,
                detector: false,
                file_types: [FileTypes.PDF, FileTypes.PPT],
                file_size: ">1 MB",
            },
        },
        {
            id: "2",
            name: "Don't Send Sensitive Data",
            status: DataPolicyStatus.ENFORCING,
            created_at: DateUtil.convertLargeTimestamp(1612310476253539600),
            updated_at: DateUtil.convertLargeTimestamp(1684167204867082800),
            attachments: [
                {
                    type: AttachTypes.PUBLIC_APPLICATION,
                    name: "ChatGPT",
                    id: "123",
                },
            ],
            assignments: ["ANY"],
            upload_rule: {
                file: false,
                detector: true,
                detector_locations: [
                    DetectorLocationCategories.UNITED_STATES,
                    DetectorLocationCategories.EUROPE,
                ],
                detector_industries: [
                    DetectorIndustryCategories.FINANCE,
                    DetectorIndustryCategories.HEALTH,
                ],
                detector_types: [
                    DetectorTypeCategories.SPII,
                    DetectorTypeCategories.CREDENTIAL,
                    DetectorTypeCategories.GOVERNMENT_ID,
                ],
            },
        },
        {
            id: "3",
            name: "Check For Secrets",
            status: DataPolicyStatus.PERMISSIVE,
            created_at: DateUtil.convertLargeTimestamp(1685035046926464300),
            updated_at: DateUtil.convertLargeTimestamp(1685035046926464300),
            attachments: [
                {
                    type: AttachTypes.HOSTED_WEBSITE,
                    name: "TrialsPlatform",
                    id: "TrialsPlatform.cluster1.bnn",
                },
                {
                    type: AttachTypes.PUBLIC_APPLICATION,
                    name: "ChatGPT",
                    id: "123",
                },
            ],
            assignments: ["ANY"],
            download_rule: {
                file: false,
                detector: true,
                detector_types: [DetectorTypeCategories.CREDENTIAL],
            },
            upload_rule: {
                file: false,
                detector: true,
                detector_types: [DetectorTypeCategories.CREDENTIAL],
            },
        },
        {
            id: "4",
            name: "Test Various Detectors",
            status: DataPolicyStatus.INACTIVE,
            created_at: DateUtil.convertLargeTimestamp(1685035046926464300),
            updated_at: DateUtil.convertLargeTimestamp(1685035046926464300),
            attachments: [],
            assignments: [],
        },
    ]

    public getDataPolicies(): DataPolicy[] {
        return this.dataset
    }

    public getDataPolicy(id: string): DataPolicy {
        let el = this.dataset.find((elem) => elem.id === id)
        return el ? el : this.dataset[0]
    }
}

export interface DataPolicy {
    id: string
    name: string
    status: DataPolicyStatus
    upload_rule?: DataRule
    download_rule?: DataRule
    attachments: Attachment[]
    assignments: string[]
    created_at: number
    updated_at: number
}

export interface DataRule {
    file: boolean
    detector: boolean
    // if file
    file_types?: string[]
    file_size?: string
    // if detector (https://cloud.google.com/dlp/docs/reference/rest/v2/ListInfoTypesResponse)
    detector_locations?: string[]
    detector_industries?: string[]
    detector_types?: string[]
}

export interface Attachment {
    type: AttachTypes
    name: string
    id: string
}

export enum FileTypes {
    ANY = "Any File Type",
    PDF = "Adobe PDF",
    PPT = "Microsoft Powerpoint",
    IMAGE = "Image - JPG, PNG, etc",
}

export enum DetectorLocationCategories {
    GLOBAL = "Global",
    LATAM = "Latin America",
    EUROPE = "Europe",
    CANADA = "Canada",
    UNITED_KINGDOM = "United Kingdom",
    UNITED_STATES = "United States",
}

export enum DetectorIndustryCategories {
    ALL = "All",
    FINANCE = "Finance",
    HEALTH = "Health",
    TELECOMMUNICATIONS = "Telecommunications",
}

export enum DetectorTypeCategories {
    PII = "PII - Personally identifiable information",
    SPII = "SPII - Sensitive personally identifiable information",
    CREDENTIAL = "Credential - Secret information",
    GOVERNMENT_ID = "Govt ID - Identification issued by a government",
}

export enum AttachTypes {
    HOSTED_WEBSITE = "Hosted Website",
    PUBLIC_APPLICATION = "Public Application",
}

export enum DataPolicyStatus {
    ALLSTATUSES = "All Statuses",
    ENFORCING = "Enforcing",
    PERMISSIVE = "Visibility Only",
    INACTIVE = "Inactive",
}

export const useServiceDataPolicy = () => new DataPolicyService()
