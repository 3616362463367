import React from "react"

import banyanSvg from "../../../../../../../../images/logo-brand.svg"
import { useServiceLocalization } from "../../../../../../../../pre-v3/services"
import { StringUtil } from "../../../../../../../../pre-v3/utils/String.util"
import { TrustFactor } from "../../../../../../../services/TrustProfile.service"
import styles from "./BanyanFactor.module.scss"
import { FactorContainer } from "./FactorContainer.component"

interface Props {
    trustFactor: TrustFactor
    search: string
    onAddTrustFactor(trustFactor: TrustFactor): void
}

export function BanyanFactor(props: Props): JSX.Element {
    const localizationService = useServiceLocalization()

    if (!isVisible(props.trustFactor, props.search)) return <React.Fragment />

    return (
        <FactorContainer
            sourceLabel={props.trustFactor.label}
            logo={
                <div className={styles.logoContainer}>
                    <img
                        src={banyanSvg}
                        alt={localizationService.getString("sonicWallCse")}
                        className={styles.logo}
                    />
                </div>
            }
            description={props.trustFactor.description}
            selectedFactorLabel={props.trustFactor.label}
            onAddTrustFactor={() => props.onAddTrustFactor(props.trustFactor)}
        />
    )
}

function isVisible(trustFactor: TrustFactor, search: string): boolean {
    return (
        !search ||
        StringUtil.caseInsensitiveIncludes(trustFactor.source.name, search) ||
        StringUtil.caseInsensitiveIncludes(trustFactor.label, search) ||
        StringUtil.caseInsensitiveIncludes(trustFactor.description, search)
    )
}
