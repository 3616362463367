import * as React from "react"

import { ROUTE } from "../../../routes"
import { Background } from "../../../v3/components/background/Background.component"
import styles from "./OIDC.module.scss"
import logoImage from "../../../images/logo-horiz-black.svg"
import { ErrorBanner, InfoBanner, Input, Button } from "../../components"
import { ConfirmForgotPasswordReq, ResendCodeReq } from "../../api/OIDC.api"
import { isValidPasswordRegex } from "../../utils/OIDC.util"
import { RouteComponentProps } from "react-router"
import { useServiceLocalization, useServiceOIDC } from "../../services"

export function OIDCConfirmPassword({ history }: RouteComponentProps) {
    const localization = useServiceLocalization()
    const oidcService = useServiceOIDC()

    const orgName: string = oidcService.getOrgName()
    const email: string = oidcService.getEmail()

    const [code, setCode] = React.useState<string>("")
    const [password, setPassword] = React.useState<string>("")
    const [confirmPassword, setConfirmPassword] = React.useState<string>("")
    const [hasResentCode, setHasResentCode] = React.useState<boolean>(false)
    const [errorMessage, setErrorMessage] = React.useState<string>("")

    const form = React.useRef(null)

    React.useEffect(() => {
        if (!email || !orgName) {
            history.push(ROUTE.OIDC_LOGIN)
        }
    }, [email, history, orgName])

    function validateForm() {
        return code.length > 0 && password.length > 0 && confirmPassword.length > 0
    }

    function onResendCode(event: React.SyntheticEvent) {
        event.preventDefault()

        const payload: ResendCodeReq = {
            userName: email.toLowerCase(),
            orgName: orgName,
        }

        oidcService
            .resendCode(payload)
            .then(() => {
                setErrorMessage("")
                setHasResentCode(true)
            })
            .catch(() => {
                setErrorMessage(localization.getString("somethingWentWrongDescription"))
            })
    }

    function onSubmit(event: React.SyntheticEvent) {
        event.preventDefault()

        if (password !== confirmPassword) {
            setErrorMessage(localization.getString("thePasswordsEnteredDoNotMatch"))
            return
        } else {
            setErrorMessage("")
        }

        if (!isValidPasswordRegex(password)) {
            setErrorMessage(localization.getString("passwordRequirementsDescription"))
            return
        } else {
            setErrorMessage("")
        }

        const payload: ConfirmForgotPasswordReq = {
            userName: email,
            orgName: orgName,
            code: code,
            password: password,
        }

        oidcService.confirmForgotPassword(payload).catch(() => {
            setErrorMessage(localization.getString("unabletoConfirmSecurityCode"))
        })
    }

    function onCancel(): void {
        oidcService.setEmail()
        history.push(ROUTE.OIDC_LOGIN)
    }

    React.useEffect(() => {
        if (!email) {
            history.push(ROUTE.OIDC_LOGIN)
        }
    }, [email, history])

    return (
        <div className={styles.oidcLogin}>
            <Background>
                <div className={styles.loginForm}>
                    <div className={styles.form}>
                        {hasResentCode && (
                            <InfoBanner className={styles.banner}>
                                {localization.getString("codeSent")}
                            </InfoBanner>
                        )}
                        {errorMessage && (
                            <ErrorBanner className={styles.banner}>{errorMessage}</ErrorBanner>
                        )}

                        <form onSubmit={onSubmit} ref={form} name="forgotPasswordForm">
                            <img src={logoImage} alt="" className={styles.logo} />
                            <Input
                                name="username"
                                id="username"
                                type="email"
                                value={email}
                                disabled
                                placeholder={localization.getString("email")}
                            />
                            <Input
                                autoFocus
                                name="code"
                                id="code"
                                value={code}
                                onChange={(e) => setCode(e.target.value)}
                                placeholder={localization.getString("securityCode")}
                            />
                            <Input
                                name="password"
                                id="password"
                                type="password"
                                value={password}
                                onChange={(e) => setPassword(e.target.value)}
                                placeholder={localization.getString("newPassword")}
                            />
                            <Input
                                name="confirmpassword"
                                id="confirmpassword"
                                type="password"
                                value={confirmPassword}
                                onChange={(e) => setConfirmPassword(e.target.value)}
                                placeholder={localization.getString("confirmPassword")}
                            />

                            <Button
                                className={styles.formBtn}
                                type="submit"
                                disabled={!validateForm()}
                            >
                                {localization.getString("confirmPassword")}
                            </Button>
                            <br></br>
                            <div className={styles.message} onClick={onResendCode}>
                                <span className={styles.link}>
                                    {localization.getString("resendCode")}
                                </span>
                            </div>
                            <div className={styles.message} onClick={onCancel}>
                                <span className={styles.link}>
                                    {localization.getString("cancel")}
                                </span>
                            </div>
                        </form>
                    </div>
                </div>
            </Background>
        </div>
    )
}
