import {
    UseMutationResult,
    UseQueryResult,
    useMutation,
    useQuery,
    useQueryClient,
} from "@tanstack/react-query"

import { DomainRes, UrlFilteringApi, UrlFilteringPacRes } from "../api/UrlFiltering.api"
import { ApiFunction } from "./shared/QueryKey"
import { OrgDetailsRes } from "../api/Org.api"

enum UrlFilteringApiKeys {
    GET_BANYAN_MANAGED_BYPASS_DOMAINS = "GET_BANYAN_MANAGED_BYPASS_DOMAINS",
    GET_ADMIN_MANAGED_BYPASS_DOMAINS = "GET_ADMIN_MANAGED_BYPASS_DOMAINS",
    GET_PAC_FILE = "GET_PAC_FILE",
}

export interface Domain {
    id: string
    domain: string
}

export function useToggleUrlFiltering(options?: QueryOptions<void, string, [boolean]>) {
    const client = useQueryClient()

    const urlFilteringApi = new UrlFilteringApi()

    return useMutation<void, string, [boolean]>({
        ...options,
        mutationFn: async ([state]): Promise<void> => {
            await urlFilteringApi.toggleUrlFiltering(state)
        },
        onSuccess: (_data, [isURLFilteringEnabled]) => {
            client.setQueryData<OrgDetailsRes>(
                [ApiFunction.GET_ORG_DETAILS],
                (orgDetailsRes) =>
                    orgDetailsRes && {
                        ...orgDetailsRes,
                        IsURLFilteringEnabled: isURLFilteringEnabled,
                    }
            )
            options?.onSuccess?.()
        },
    })
}

export function useGetBanyanManagedBypassDomains(): UseQueryResult<Domain[]> {
    const urlFilteringApi = new UrlFilteringApi()

    return useQuery<Domain[], string>({
        queryKey: [UrlFilteringApiKeys.GET_BANYAN_MANAGED_BYPASS_DOMAINS],
        queryFn: async (): Promise<Domain[]> => {
            const domains = await urlFilteringApi.getBanyanManagedBypassDomains()
            return mapDomains(domains)
        },
    })
}

export function useGetAdminManagedBypassDomains(): UseQueryResult<Domain[]> {
    const urlFilteringApi = new UrlFilteringApi()

    return useQuery<Domain[], string>({
        queryKey: [UrlFilteringApiKeys.GET_ADMIN_MANAGED_BYPASS_DOMAINS],
        queryFn: async (): Promise<Domain[]> => {
            const domains = await urlFilteringApi.getAdminManagedBypassDomains()
            return domains === null ? [] : mapDomains(domains)
        },
    })
}

export function useAddManagedBypassDomain(
    options?: QueryOptions<Domain>
): UseMutationResult<Domain, string, string> {
    const urlFilteringApi = new UrlFilteringApi()
    const client = useQueryClient()

    return useMutation<Domain, string, string>({
        ...options,
        mutationFn: async (domain: string) =>
            await urlFilteringApi.createManagedBypassDomain([domain]),
        onSuccess: (domains) => {
            client.invalidateQueries([UrlFilteringApiKeys.GET_ADMIN_MANAGED_BYPASS_DOMAINS])
            options?.onSuccess?.(domains)
        },
    })
}

export function useEditManagedBypassDomain(
    options?: QueryOptions<Domain>
): UseMutationResult<Domain, string, Domain> {
    const urlFilteringApi = new UrlFilteringApi()
    const client = useQueryClient()

    return useMutation<Domain, string, Domain>({
        ...options,
        mutationFn: async (domain: Domain) =>
            await urlFilteringApi.updateManagedBypassDomain([domain]),
        onSuccess: (domains) => {
            client.invalidateQueries([UrlFilteringApiKeys.GET_ADMIN_MANAGED_BYPASS_DOMAINS])
            options?.onSuccess?.(domains)
        },
    })
}

export function useDeleteManagedBypassDomain(
    options?: QueryOptions<string>
): UseMutationResult<void, string, Domain> {
    const urlFilteringApi = new UrlFilteringApi()
    const client = useQueryClient()

    return useMutation<void, string, Domain>({
        ...options,
        mutationFn: async (domain: Domain): Promise<void> => {
            if (domain.id) {
                await urlFilteringApi.deleteManagedBypassDomain([domain])
            }
        },
        onSuccess: () => {
            client.invalidateQueries([UrlFilteringApiKeys.GET_ADMIN_MANAGED_BYPASS_DOMAINS])
        },
    })
}

export function useGetPacFile(): UseQueryResult<string | null> {
    const urlFilteringApi = new UrlFilteringApi()

    return useQuery<string | null, string>({
        queryKey: [UrlFilteringApiKeys.GET_PAC_FILE],
        queryFn: async (): Promise<string | null> => {
            const fileContent = await urlFilteringApi.getPacFile()
            return fileContent.pac || null
        },
    })
}

export function useUploadPacFile(
    options?: QueryOptions<UrlFilteringPacRes, string, File>
): UseMutationResult<UrlFilteringPacRes, string, File> {
    const urlFilteringApi = new UrlFilteringApi()
    const client = useQueryClient()

    return useMutation<UrlFilteringPacRes, string, File>(
        async (pacFile: File) => {
            return await urlFilteringApi.uploadPacFile({ pac: await pacFile.text() })
        },
        {
            ...options,
            onSuccess: (res) => {
                client.setQueryData<string | null>(
                    [UrlFilteringApiKeys.GET_PAC_FILE],
                    res.pac || null
                )
                options?.onSuccess?.(res)
            },
        }
    )
}

function mapDomains(domains: DomainRes[]): Domain[] {
    return domains.map((domain) => {
        return { id: domain.id, domain: domain.domain }
    })
}
