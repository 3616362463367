import * as React from "react"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faAngleRight, faAngleDown } from "@fortawesome/pro-regular-svg-icons"
import { PageBreak } from ".."
import styles from "./FormSection.module.scss"
import { Clickable } from "../clickable/Clickable"
import classnames from "classnames"

interface FormSectionProps {
    title: string
    children: React.ReactNode
    collapsible?: boolean
    startOpen?: boolean
    className?: string
    hideLine?: boolean
    hideTitle?: boolean
    constrainWidth?: boolean
    open?: boolean
    onOpenChange?: (val: boolean) => void
}

export function FormSection({
    title,
    children,
    collapsible,
    startOpen,
    className,
    hideLine,
    hideTitle,
    open,
    onOpenChange,
    constrainWidth = true,
}: FormSectionProps) {
    // a collapsible section should start closed unless told otherwise
    const [localOpen, setLocalOpen] = React.useState(startOpen || !collapsible)

    // if the collapsability changes we might need to reopen the section
    React.useEffect(() => {
        // if we are no longer collapsible but the section is closed, open it back up
        if (!collapsible && typeof open !== "boolean" && !localOpen) {
            setLocalOpen(true)
        }
    }, [collapsible, localOpen, open])

    // we could be controlled or not
    const showContents = typeof open === "boolean" ? open : localOpen
    const toggleContents = onOpenChange || setLocalOpen

    return (
        <>
            {!hideTitle && (
                <PageBreak hideLine={hideLine}>
                    {!collapsible ? (
                        <div>{title}</div>
                    ) : (
                        <Clickable
                            onClick={() => toggleContents(!showContents)}
                            className={styles.toggle}
                            style={{
                                cursor: "pointer",
                            }}
                        >
                            {showContents ? (
                                <FontAwesomeIcon icon={faAngleDown} className={styles.icon} />
                            ) : (
                                <FontAwesomeIcon icon={faAngleRight} className={styles.icon} />
                            )}
                            {title}
                        </Clickable>
                    )}
                </PageBreak>
            )}
            {showContents && (
                <section className={classnames(className, { [styles.section]: constrainWidth })}>
                    {children}
                </section>
            )}
        </>
    )
}
