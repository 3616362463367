import React, { ReactNode } from "react"
import OkCancelActionsTemplate from "./OkCancelActions.template"
import { ModalRef } from "../../../services/Modal.service"
import { LocalizationService } from "../../../services/localization/Localization.service"

export class OkCancelActions extends React.Component<OkCancelActionsProps, {}> {
    public render(): ReactNode {
        return OkCancelActionsTemplate.call(this)
    }

    private ls: LocalizationService = new LocalizationService()
}

interface OkCancelActionsProps {
    modalRef: ModalRef
    okString?: string
    cancelString?: string
    okDisabled?: boolean
    hideCancel?: boolean
}
