import React, { Fragment } from "react"
import styles from "./Select.module.scss"
import { Popover } from "@mui/material"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faChevronDown } from "@fortawesome/pro-light-svg-icons"
import classNames from "classnames/bind"
import { faTimes } from "@fortawesome/pro-regular-svg-icons"
import { Input, FormLabelContext } from ".."

function multiSelect() {
    return (
        <Fragment>
            <div
                className={classNames(styles.select, {
                    [styles.disabled]: this.props.disabled,
                    [styles.compact]: this.props.compact,
                })}
                ref={(c) => (this.anchor = c)}
                onClick={this.onToggleDropdown}
            >
                <div className={classNames(styles.value, styles.placeholder)}>
                    {this.props.placeholder}
                </div>
                <FontAwesomeIcon className={styles.chevron} icon={faChevronDown} />
                {input.call(this)}
            </div>
            {this.state.selected && this.state.selected.length > 0 && (
                <ul className={styles.selected}>
                    {this.state.selected.map((s) => (
                        <li
                            key={s.value || s}
                            className={classNames(styles.selectedItem, {
                                [styles.disabled]: this.props.disabled,
                            })}
                            onClick={() => this.onDeselect(s)}
                        >
                            {s.displayName || s}
                            {!this.props.disabled && <FontAwesomeIcon icon={faTimes} />}
                        </li>
                    ))}
                </ul>
            )}
            <Popover
                anchorEl={this.anchor}
                anchorOrigin={{ vertical: "bottom", horizontal: "left" }}
                transformOrigin={{ vertical: "top", horizontal: "left" }}
                PaperProps={{ square: true, style: { minWidth: this.state.anchorWidth } }}
                open={this.state.showDropdown}
                onClose={this.onCloseDropdown}
            >
                <ul className={styles.dropdown}>
                    {this.state.available &&
                        this.state.available.length > 0 &&
                        this.state.available.map((o) =>
                            o ? (
                                <li
                                    key={o.value || o}
                                    className={classNames(styles.dropdownItem, {
                                        [styles.divisor]: o.divisor,
                                    })}
                                    onClick={() => {
                                        if (!o.divisor) this.onSelect(o)
                                    }}
                                >
                                    {o.displayName || o}
                                </li>
                            ) : null
                        )}
                </ul>
            </Popover>
        </Fragment>
    )
}

function select() {
    return (
        <Fragment>
            <div
                className={classNames(styles.select, {
                    [styles.disabled]: this.props.disabled,
                    [styles.compact]: this.props.compact,
                })}
                ref={(c) => (this.anchor = c)}
                onClick={this.onToggleDropdown}
            >
                <div
                    className={classNames(styles.value, {
                        [styles.placeholder]:
                            this.props.value === undefined || this.props.value === "",
                    })}
                >
                    {this.state.selected
                        ? this.state.selected.displayName || this.state.selected
                        : this.props.placeholder}
                </div>
                <FontAwesomeIcon className={styles.chevron} icon={faChevronDown} />
                {input.call(this)}
            </div>
            <Popover
                anchorEl={this.anchor}
                anchorOrigin={{ vertical: "bottom", horizontal: "left" }}
                transformOrigin={{ vertical: "top", horizontal: "left" }}
                PaperProps={{ square: true, style: { minWidth: this.state.anchorWidth } }}
                open={this.state.showDropdown}
                onClose={this.onCloseDropdown}
            >
                <ul className={styles.dropdown}>
                    {this.props.noneOption && (
                        <li className={styles.dropdownItem} onClick={this.onSelectNoneOption}>
                            {this.props.noneOption}
                        </li>
                    )}
                    {this.props.options &&
                        this.props.options.length > 0 &&
                        this.props.options.map((o) =>
                            o ? (
                                <li
                                    key={o.value || o}
                                    className={styles.dropdownItem}
                                    onClick={() => this.onSelect(o)}
                                    aria-label={o.displayName || o}
                                >
                                    {o.displayName || o}
                                </li>
                            ) : null
                        )}
                </ul>
            </Popover>
        </Fragment>
    )
}

function input() {
    return (
        <FormLabelContext.Consumer>
            {({ htmlFor }) => (
                // TODO: Replace with the hidden input component
                <Input
                    id={this.props.id || htmlFor}
                    name={this.props.name || htmlFor}
                    className={styles.hiddenInput}
                    value={this.state.serializedValue}
                    onChange={() => {}}
                    required={this.props.required}
                    disabled={this.props.disabled}
                    aria-label={this.props["aria-label"]}
                />
            )}
        </FormLabelContext.Consumer>
    )
}

export default function () {
    return (
        <div className={this.props.className}>
            {this.props.multiple ? multiSelect.call(this) : select.call(this)}
        </div>
    )
}
