import { BaseApi } from "./Base.api"

export class TrustIntegrationApi extends BaseApi {
    public getTrustIntegrations(id?: string): Promise<TrustIntegrationRes[]> {
        return this.get(`/api/v2/integration${id ? `?integration_id=${id}` : ""}`, {
            default: this.localization.getString("internalErrorOccurredRefreshTryAgain"),
        })
    }

    public deleteTrustIntegration(id: string): Promise<void> {
        return this.delete(
            `/api/v2/integration`,
            { id },
            {
                default: this.localization.getString("thereWasAnIssueDeletingTheIntegration"),
            }
        )
    }

    public getSyncStats(id: string): Promise<TrustIntegrationSyncStatsRes[]> {
        return this.get(`/api/v2/integration/sync_stats?integration_id=${id}`, {
            default: this.localization.getString("internalErrorOccurredRefreshTryAgain"),
        })
    }

    public async getSyncStatsCsv(id: string): Promise<string> {
        return this.get(`/api/v2/integration/sync_stats/device_csv?integration_id=${id}`, {
            default: this.localization.getString("failedToDownloadTrustIntegrationSyncLogs"),
        })
    }

    public create(props: CreateTrustIntegrationReq): Promise<TrustIntegrationRes> {
        const { integrationPartner, data } = props

        return this.post(`/api/v2/integration/${integrationPartner}`, data, {
            default: this.localization.getString("internalErrorOccurredRefreshTryAgain"),
        })
    }

    public update(props: UpdateTrustIntegrationReq): Promise<TrustIntegrationRes> {
        const { integrationPartner, data, id } = props

        return this.put(
            `/api/v2/integration/${integrationPartner}`,
            { ...data, integration_id: id },
            {
                default: this.localization.getString("internalErrorOccurredRefreshTryAgain"),
            }
        )
    }

    public async testCredentials(props: TestCredentialsReq): Promise<void> {
        const { integrationPartner, data } = props

        const response = await this.post<
            TestCredentialsReq["data"],
            { api_response_code: number; api_response_message: string }
        >(`/api/v2/integration/test_credentials/${integrationPartner}`, data, {
            default: this.localization.getString("accessDeniedApiEndpointIsInvalid"),
        })

        switch (response.api_response_code) {
            case 400: {
                // SentinelOne api key is invalid
                throw this.localization.getString("accessDeniedApiKeyIsInvalid")
            }
            case 401: {
                // CrowdStrike id is invalid
                throw this.localization.getString("accessDeniedIdIsInvalid")
            }
            case 403: {
                // CrowdStrike secret is invalid
                throw this.localization.getString("accessDeniedSecretIsInvalid")
            }
        }
    }
}

export type TrustIntegrationSyncStatsRes = {
    id: string
    integration_id: string
    org_id: string
    num_devices_synced: number
    num_devices_passing_all_signals: number
    num_devices_passing_some_signals: number
    num_devices_failing_all_signals: number
    num_devices_unevaluated: number
    status: string
    last_sync_at: number
    created_at: number
    updated_at: number
}

type PlatformRes = "macos" | "windows" | "linux" | "ios" | "android"

export interface TrustIntegrationRes {
    id: string
    name: string
    description: string
    type: "EDR"
    integration_partner: "crowdstrike" | "sentinelone" | "workspaceone"
    sync_status: "inactive" | "pending" | "active" | "error"
    last_batch_update: number
    updated_at: number
    created_at: number
    created_by: string
    last_updated_by: string
    api_key: string
    api_endpoint: string
    api_key_expiry: string
    authentication_endpoint: string
    username: string
    password: string
    token_url: string
    saas_url: string
    client_id: string
    client_secret: string
    target_platforms: {
        platform: PlatformRes
    }[]
    signals: {
        active: true
        name: "s1.registeredWith" | "s1.activeThreats" | "zta.score"
        signal_min_threshold: "65" | "75"
        target_platforms: {
            platform: PlatformRes
        }[]
    }[]
}

export type TrustIntegrationReq = {
    name: string
    description: string
    secret: string
    id: string
    target_platforms: PlatformRes[]
    api_key: string
    base_url?: string
    partner_url: string
    token_url?: string
    saas_url?: string
    client_id?: string
    client_secret?: string
    signals: {
        name:
            | "s1.registeredWith"
            | "s1.activeThreats"
            | "zta.score"
            | "ws1.registeredWith"
            | "ws1.isCompliant"
        signal_min_threshold: number
        target_platforms?: PlatformRes[]
    }[]
}

interface CreateTrustIntegrationReq {
    data: TrustIntegrationReq
    integrationPartner: TrustIntegrationRes["integration_partner"]
}

interface UpdateTrustIntegrationReq {
    data: TrustIntegrationReq
    integrationPartner: TrustIntegrationRes["integration_partner"]
    id: string
}

export interface TestCredentialsReq {
    integrationPartner: TrustIntegrationRes["integration_partner"]
    data: {
        id?: string
        secret?: string
        api_key?: string
        base_url?: string
        partner_url?: string
        token_url?: string
        saas_url: string
        client_id: string
        client_secret: string
    }
}
