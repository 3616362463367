import React, { ReactNode } from "react"
import { IconProp } from "@fortawesome/fontawesome-svg-core"
import { IconButtonWithTooltipTemplate, IconButtonTemplate } from "./IconButton.template"

export class IconButton extends React.Component<IconButtonProps, {}> {
    public render(): ReactNode {
        if (this.props.tooltip && this.props.tooltip.length > 0) {
            return IconButtonWithTooltipTemplate.call(this)
        } else {
            return IconButtonTemplate.call(this)
        }
    }
}

interface IconButtonProps {
    id?: string
    className?: string
    label?: string
    icon: IconProp
    disabled?: boolean
    primary?: boolean
    brand?: boolean
    onClick: Function | null
    tooltip?: string | false
    tooltipPlacement?: string
}
