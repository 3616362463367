import * as React from "react"
import { ModalRef } from "../../../services/Modal.service"
import styles from "./MessageContent.module.scss"

interface MessageContentProps {
    modalRef: ModalRef
    text: string
}

export const MessageContent = ({ text }: MessageContentProps) => (
    <div className={styles.content}>{text}</div>
)
