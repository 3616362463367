import React from "react"

import { useServiceLocalization } from "../../../../../../pre-v3/services/localization/Localization.service"
import { CollectionUtil } from "../../../../../../pre-v3/utils/Collection.util"
import { TrustFactorRemediation } from "../../../../../services/TrustFactor.service"
import { DesktopPlatform, platformLabelDict } from "../../../../../services/shared/Platform"
import { Input } from "../../../../../components/input/Input.component"
import styles from "./RemediationDetails.module.scss"

interface Props {
    trustFactor: TrustFactorRemediation
    canWriteAll: boolean
    onTrustFactorChange(trustFactor: TrustFactorRemediation): void
}

export function RemediationDetails(props: Props): JSX.Element {
    const mapRemediationPerPlatform = React.useCallback(
        ([platform, message]: [DesktopPlatform, string | null]): JSX.Element => {
            if (typeof message !== "string") return <React.Fragment key={platform} />

            const onMessageChange = (message: string): void =>
                props.onTrustFactorChange({
                    ...props.trustFactor,
                    remediationPerPlatform: {
                        ...props.trustFactor.remediationPerPlatform,
                        [platform]: message,
                    },
                })

            return (
                <RemediationDetailsPerPlatform
                    key={platform}
                    platform={platform}
                    message={message}
                    canWriteAll={props.canWriteAll}
                    onMessageChange={onMessageChange}
                />
            )
        },
        [props]
    )

    return (
        <React.Fragment>
            {CollectionUtil.entries(props.trustFactor.remediationPerPlatform).map(
                mapRemediationPerPlatform
            )}
        </React.Fragment>
    )
}

interface RemediationDetailsPerPlatformProps {
    platform: DesktopPlatform
    message: string
    canWriteAll: boolean
    onMessageChange(message: string): void
}

function RemediationDetailsPerPlatform(props: RemediationDetailsPerPlatformProps): JSX.Element {
    const localization = useServiceLocalization()

    return (
        <React.Fragment>
            <span className={styles.label}>
                {localization.getString(platformLabelDict[props.platform])}
            </span>
            <Input
                value={props.message}
                onChangeValue={props.onMessageChange}
                disabled={!props.canWriteAll}
                required
            />
        </React.Fragment>
    )
}
