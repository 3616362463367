import React from "react"

/**
 * A hook to handle the execution and clean up for the global `setInterval`
 * method
 * @param onInterval A function to be executed every delay milliseconds. The
 * first execution happens after delay milliseconds.
 * @param ms The time, in milliseconds (thousandths of a second), the timer
 * should delay in between executions of the specified function or code.
 */
export function useInterval(onInterval: () => void, ms?: number): void {
    React.useEffect(() => {
        const intervalId = setInterval(() => onInterval(), ms)
        return () => clearInterval(intervalId)
    }, [onInterval, ms])
}
