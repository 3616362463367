import React from "react"

import {
    useActionBar,
    useServiceLocalization,
    useServiceModal,
    ModalRef,
} from "../../../../../../pre-v3/services"
import { AppText } from "../../../../../components/app-text/AppText.component"
import { ErrorBanner } from "../../../../../components/banner/Banner.component"
import { OldInput } from "../../../../../components/old-input/OldInput.component"
import {
    Button,
    ButtonElement,
    ButtonType,
} from "../../../../../../components/button/Button.component"
import {
    FAILED_TO_SET_TRUST_LEVEL_EXPIRATION,
    useGetTrustLevelExpirationInHours,
    useSetTrustLevelExpirationInHours,
} from "../../../../../services/TrustProfile.service"
import styles from "./TrustScoreExpiry.module.scss"

interface Props {
    canUpdateTrustScopeExpiry?: boolean
}

export function TrustScoreExpiry(props: Props): JSX.Element {
    const localization = useServiceLocalization()
    const modalService = useServiceModal()

    useActionBar({
        title: localization.getString("trustScoreExpiry"),
        items: [{ label: localization.getString("trustScoreSettings") }],
    })

    const {
        data: trustLevelExpirationInHours,
        error: trustLevelExpirationInHoursError,
        isFetching: isTrustLevelExpirationInHoursFetching,
    } = useGetTrustLevelExpirationInHours()

    const {
        mutateAsync: setTrustLevelExpirationInHours,
        error: setTrustLevelExpirationInHoursError,
    } = useSetTrustLevelExpirationInHours()

    const [expiration, setExpiration] = React.useState<number>()

    const onHoursUntilExpiredChange: React.ChangeEventHandler<HTMLInputElement> = React.useCallback(
        (event) => {
            event.preventDefault()
            setExpiration(event.target.valueAsNumber)
        },
        []
    )

    const onApply = () => {
        const expirationApplied = expiration ?? trustLevelExpirationInHours

        if (typeof expirationApplied !== "number") {
            return Promise.resolve()
        }

        return setTrustLevelExpirationInHours(expirationApplied)
    }

    const onSave: React.EventHandler<React.SyntheticEvent> = (event) => {
        event.preventDefault()

        if (isTrustLevelExpirationInHoursFetching) return

        modalService.open(
            localization.getString("trustLevelExpiration"),
            {
                component: () => <AppText ls="trustLevelExpirationDialog" />,
            },
            { component: Actions, props: { onApply } }
        )
    }

    const getError = (): string | undefined => {
        if (typeof trustLevelExpirationInHoursError === "string")
            return trustLevelExpirationInHoursError

        if (setTrustLevelExpirationInHoursError === FAILED_TO_SET_TRUST_LEVEL_EXPIRATION)
            return localization.getString("failedToUpdateTheTrustLevelExpiration")

        if (typeof setTrustLevelExpirationInHoursError === "string")
            return setTrustLevelExpirationInHoursError
    }

    const error = getError()

    return (
        <div className={styles.container}>
            {error && <ErrorBanner className={styles.errorBanner}>{error}</ErrorBanner>}
            <span className={styles.sectionTitle}>
                {localization.getString("trustLevelExpiration")}
            </span>
            <hr className={styles.horizontalLine} />
            <AppText ls="setExpirationTimeForDeviceTrustLevel" />
            <form className={styles.inputRow} onSubmit={onSave}>
                <label htmlFor="hoursUntilExpired">
                    {localization.getString("hoursUntilExpired")}
                </label>
                <OldInput
                    id="hoursUntilExpired"
                    type="number"
                    onChange={onHoursUntilExpiredChange}
                    value={expiration?.toString() ?? trustLevelExpirationInHours?.toString() ?? ""}
                    autoFocus
                    disabled={!props.canUpdateTrustScopeExpiry}
                />
            </form>
            <hr className={styles.horizontalLine} />
            {props.canUpdateTrustScopeExpiry && (
                <Button
                    onClick={onSave}
                    asElement={ButtonElement.BUTTON}
                    buttonType={ButtonType.PRIMARY}
                    className={styles.saveButton}
                >
                    {localization.getString("save")}
                </Button>
            )}
        </div>
    )
}

interface ActionsProps {
    modalRef: ModalRef
    onApply(): Promise<void>
}

export function Actions(props: ActionsProps): JSX.Element {
    const localization = useServiceLocalization()

    const [isLoading, setIsLoading] = React.useState(false)

    const onApply: React.MouseEventHandler = async (event) => {
        event.preventDefault()

        setIsLoading(true)

        try {
            await props.onApply()
        } finally {
            props.modalRef.close(null)
        }
    }

    return (
        <React.Fragment>
            <Button
                asElement={ButtonElement.BUTTON}
                buttonType={ButtonType.SECONDARY}
                onClick={props.modalRef.cancel}
            >
                {localization.getString("cancel")}
            </Button>
            <Button
                asElement={ButtonElement.BUTTON}
                buttonType={ButtonType.PRIMARY}
                loading={isLoading}
                onClick={onApply}
            >
                {localization.getString("apply")}
            </Button>
        </React.Fragment>
    )
}
