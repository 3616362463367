import "normalize.css"
import "ag-grid-community/styles/ag-grid.css"
import "ag-grid-community/styles/ag-theme-material.css"

import "./index.scss"

import { LicenseManager } from "ag-grid-enterprise"

LicenseManager.setLicenseKey(
    // cspell:disable-next-line
    "Using_this_{AG_Grid}_Enterprise_key_{AG-036518}_in_excess_of_the_licence_granted_is_not_permitted___Please_report_misuse_to_legal@ag-grid.com___For_help_with_changing_this_key_please_contact_info@ag-grid.com___{Banyan_Security_Inc}_is_granted_a_{Single_Application}_Developer_License_for_the_application_{banyan-ui}_only_for_{3}_Front-End_JavaScript_developers___All_Front-End_JavaScript_developers_working_on_{banyan-ui}_need_to_be_licensed___{banyan-ui}_has_been_granted_a_Deployment_License_Add-on_for_{1}_Production_Environment___This_key_works_with_{AG_Grid}_Enterprise_versions_released_before_{14_January_2025}____[v3]_[01]_MTczNjgxMjgwMDAwMA==b5062e11480bf0253765c0a8d1f9f795"
)
