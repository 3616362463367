import React, { useMemo } from "react"
import { Redirect, Route, Switch, useLocation } from "react-router-dom"

import {
    NavigationItemProps,
    TopNavigationBar,
} from "../../components/top-navigation-bar/TopNavigationBar.component"
import useTitle from "../../hooks/useTitle.hook"
import { LanguageKey } from "../../pre-v3/services/localization/languages/en-US.language"
import { useServiceLocalization } from "../../pre-v3/services/localization/Localization.service"
import { Dashboard as HomeOverview } from "../../pre-v3/views/dashboard/Dashboard"
import { SystemLogs } from "../../pre-v3/views/monitor/system-logs/SystemLogs.component"
import { ROUTE, doesRouteMatch } from "../../routes"
import { Events } from "../../v3/views/events/Events.view"
import { Licenses } from "../licenses/Licenses.view"
import { useGetOrgInfo } from "../../v3/services/Org.service"
import { Loader } from "../../v3/components/loader/Loader.component"

export function Home(): JSX.Element {
    useTitle(["home", "adminConsole"])

    const localization = useServiceLocalization()
    const location = useLocation()
    const { data: orgInfo, status: orgInfoStatus } = useGetOrgInfo()

    const getNavigationItemProps = (route: SecondLevelRoute): NavigationItemProps => ({
        key: route,
        label: localization.getString(secondLevelLabels[route]),
        to: route,
        active: doesRouteMatch<SecondLevelRoute>(route, location),
    })

    const navigationItems = useMemo(() => {
        if (orgInfo?.licenseType === "edition" || !orgInfo)
            return secondLevelRoutes.filter((route) => route !== ROUTE.LICENSES)
        return secondLevelRoutes
    }, [orgInfo])

    if (orgInfoStatus === "loading") return <Loader medium center />

    return (
        <React.Fragment>
            <header>
                <TopNavigationBar
                    headingTitle={localization.getString("home")}
                    navigationItems={navigationItems}
                    getNavigationItemProps={getNavigationItemProps}
                />
            </header>
            <Switch>
                <Route path={ROUTE.HOME_OVERVIEW} component={HomeOverview} />
                <Route path={ROUTE.EVENTS} component={Events} />
                <Route path={ROUTE.SYSTEM_LOGS} component={SystemLogs} />
                {orgInfo?.licenseType === "license" ? (
                    <Route path={ROUTE.LICENSES}>
                        <Licenses orgInfo={orgInfo} />
                    </Route>
                ) : (
                    <Redirect path={ROUTE.LICENSES} to={ROUTE.LICENSING} />
                )}
                <Redirect to={ROUTE.HOME_OVERVIEW} />
            </Switch>
        </React.Fragment>
    )
}

const secondLevelRoutes = [
    ROUTE.HOME_OVERVIEW,
    ROUTE.EVENTS,
    ROUTE.SYSTEM_LOGS,
    ROUTE.LICENSES,
] satisfies ROUTE[]

type SecondLevelRoute = (typeof secondLevelRoutes)[number]

const secondLevelLabels: Record<SecondLevelRoute, LanguageKey> = {
    [ROUTE.HOME_OVERVIEW]: "overview",
    [ROUTE.EVENTS]: "events",
    [ROUTE.SYSTEM_LOGS]: "systemLogs",
    [ROUTE.LICENSES]: "licenses",
}
