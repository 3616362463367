import classNames from "classnames/bind"
import React from "react"

import { useServiceLocalization } from "../../../../../../../../../pre-v3/services"
import {
    File,
    FileCheckTrustFactor,
    compareFiles,
} from "../../../../../../../../services/TrustProfile.service"
import styles from "./FileCheck.module.scss"
import { FilePills } from "./FilePills.component"
import { SearchableButton } from "./SearchableButton.component"

interface Props {
    trustFactor: FileCheckTrustFactor
    className?: string
    disabled?: boolean
    onEdit(trustFactor: FileCheckTrustFactor): void
}

export function FileCheck(props: Props): JSX.Element {
    const localization = useServiceLocalization()

    const onMoveFileToUsed = React.useCallback(
        (file: File) =>
            props.onEdit(moveFileToUsed(localization.getLocale(), props.trustFactor, file)),
        [props.trustFactor]
    )

    const onMoveFileToUnused = React.useCallback(
        (file: File) =>
            props.onEdit(moveFileToUnused(localization.getLocale(), props.trustFactor, file)),
        [props.trustFactor]
    )

    const onEditFile = React.useCallback(
        (file: File) => props.onEdit(replaceFile(props.trustFactor, file)),
        [props.trustFactor]
    )

    return (
        <div className={classNames(styles.container, props.className)}>
            {props.trustFactor.usedFiles.length > 0 && (
                <FilePills
                    files={props.trustFactor.usedFiles}
                    disabled={props.disabled}
                    onMoveFileToUnused={onMoveFileToUnused}
                    onEditFile={onEditFile}
                />
            )}
            {!props.disabled && (
                <SearchableButton
                    key={Math.random()}
                    files={props.trustFactor.unusedFiles}
                    onMoveFileToUsed={onMoveFileToUsed}
                />
            )}
        </div>
    )
}

function moveFileToUsed(
    locale: string,
    trustFactor: FileCheckTrustFactor,
    file: File
): FileCheckTrustFactor {
    return {
        ...trustFactor,
        usedFiles: [...trustFactor.usedFiles, file].sort((fileA, fileB) =>
            compareFiles(locale, fileA, fileB)
        ),
        unusedFiles: trustFactor.unusedFiles.filter((unusedFile) => unusedFile.id !== file.id),
    }
}

function moveFileToUnused(
    locale: string,
    trustFactor: FileCheckTrustFactor,
    file: File
): FileCheckTrustFactor {
    return {
        ...trustFactor,
        usedFiles: trustFactor.usedFiles.filter((usedFile) => usedFile.id !== file.id),
        unusedFiles: [...trustFactor.unusedFiles, file].sort((fileA, fileB) =>
            compareFiles(locale, fileA, fileB)
        ),
    }
}

function replaceFile(trustFactor: FileCheckTrustFactor, file: File): FileCheckTrustFactor {
    return {
        ...trustFactor,
        usedFiles: trustFactor.usedFiles.map((usedFile) =>
            usedFile.id === file.id ? file : usedFile
        ),
    }
}
