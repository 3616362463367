export const portCSVValidity = (ports: string) => {
    const portList = ports.split(/,\s?|-/)

    return portList.some((port) => {
        if (port === "*") return true
        if (Number(port) < 0 || Number(port) > 65535) return true
        return false
    })
}

export function toLocalizedList(
    values: string[] | undefined,
    locale: Intl.BCP47LanguageTag,
    listFormatType: Intl.ListFormatType
): string {
    if (!values || values.length <= 0) return "-"

    const formatter = new Intl.ListFormat(locale, {
        type: listFormatType,
    })

    return formatter.format(values)
}
