import classNames from "classnames/bind"
import React from "react"

import {
    Button,
    ButtonElement,
    ButtonType,
    IconType,
} from "../../../components/button/Button.component"
import { Input, InputProps } from "../input/Input.component"
import { Tooltip } from "../tooltip/Tooltip.component"
import styles from "./InputWithAction.module.scss"

export { IconType } from "../../../components/button/Button.component"
export type { InputType } from "../input/Input.component"

export interface InputWithActionProps extends InputProps {
    onAction: React.MouseEventHandler<HTMLButtonElement>
    tooltip?: string
    className?: string
    icon: IconType
    actionClassName?: string
    inputClassName?: string
    actionDisabled?: boolean
    actionAriaLabel?: string
}

export const InputWithAction = React.forwardRef<HTMLInputElement, InputWithActionProps>(
    (props, ref) => {
        const {
            onAction,
            actionClassName,
            icon,
            className,
            tooltip = "",
            inputClassName,
            actionDisabled,
            actionAriaLabel,
            ...inputProps
        } = props

        return (
            <div className={classNames(styles.container, className)}>
                <Input
                    {...inputProps}
                    className={classNames(styles.input, inputClassName)}
                    ref={ref}
                />
                <Tooltip title={tooltip}>
                    <Button
                        type="button"
                        onClick={onAction}
                        asElement={ButtonElement.BUTTON}
                        buttonType={ButtonType.SECONDARY}
                        disabled={actionDisabled}
                        icon={icon}
                        className={classNames(actionClassName)}
                        aria-label={actionAriaLabel}
                    />
                </Tooltip>
            </div>
        )
    }
)
