import React, { useMemo } from "react"

import { Button, ButtonElement, ButtonType } from "../../../../components/button/Button.component"
import { LanguageKey } from "../../../../pre-v3/services/localization/languages/en-US.language"
import { useServiceLocalization } from "../../../../pre-v3/services/localization/Localization.service"
import { SelectItem } from "../../../../pre-v3/utils/SelectValue.util"
import { encodeID } from "../../../../pre-v3/utils/Url.util"
import { ROUTE, formatRoutePath } from "../../../../routes"
import {
    Device,
    StatusType,
    useUnregisterUser,
    useUpdateDeviceDetails,
} from "../../../services/Device.service"
import {
    allDeviceOwnership,
    deviceOwnershipLabelDict,
    DeviceOwnership,
} from "../../../services/shared/DeviceOwnership"
import { ErrorBanners } from "../../../components/banner/Banner.component"
import { FormButtons } from "../../../components/form/form-buttons/FormButtons.component"
import { Loader } from "../../../components/loader/Loader.component"
import { SelectInput } from "../../../components/select-input/SelectInput.component"
import {
    SimpleTable,
    SimpleTableItem,
} from "../../../components/simple-table/SimpleTable.component"
import { Link } from "../../../components/link/Link.component"
import { Role, useGetRoles } from "../../../services/Role.service"
import { User, useGetUserBySerialNumber } from "../../../services/User.service"
import styles from "./DeviceInformation.module.scss"
import { DeviceUsersGrid } from "./DeviceUsersGrid.component"
import { DeviceRolesGrid } from "./DeviceRolesGrid.component"
import { AppText } from "../../../components/app-text/AppText.component"

export interface Api {
    refresh(): void
}

interface Props {
    tabPanelId: string
    device: Device
    isInEditMode: boolean
    onLeaveEditMode(): void
}

export const DeviceInformation = React.forwardRef<Api, Props>((props, ref) => {
    const localization = useServiceLocalization()

    const getUsersQuery = useGetUserBySerialNumber(props.device.serialNumber)

    const getRolesQuery = useGetRoles()
    const roles = getRolesQuery.data || []

    const deviceRoles: Role[] = useMemo(() => {
        return roles.filter((role) => {
            return props.device.roleNames.includes(role.name)
        })
    }, [roles])

    React.useImperativeHandle(ref, () => ({
        refresh: () => {
            getRolesQuery.refetch()
            getUsersQuery.refetch()
        },
    }))

    const updateDeviceDetails = useUpdateDeviceDetails()

    const unregisterUser = useUnregisterUser(props.device)

    const [device, setDevice] = React.useState(props.device)

    if (getUsersQuery.status === "loading" || getRolesQuery.status === "loading") {
        return <Loader children isLoading medium center />
    }

    if (getUsersQuery.status === "error" || getRolesQuery.status === "error") {
        return (
            <ErrorBanners
                errors={[
                    getUsersQuery.error && String(getUsersQuery.error),
                    getRolesQuery.error ? String(getRolesQuery.error) : null,
                ]}
            />
        )
    }

    const onUnregisterUser = async (user: User): Promise<void> => {
        await unregisterUser.mutateAsync(user.email)
    }

    const onSubmit: React.FormEventHandler = async (event) => {
        event.preventDefault()
        await updateDeviceDetails.mutateAsync(device)
        props.onLeaveEditMode()
    }

    const isLoading = updateDeviceDetails.isLoading || unregisterUser.isLoading

    return (
        <form id={props.tabPanelId} onSubmit={onSubmit} className={styles.container}>
            <ErrorBanners
                errors={[
                    updateDeviceDetails.error ? String(updateDeviceDetails.error) : null,
                    unregisterUser.error ? String(unregisterUser.error) : null,
                ]}
            />
            <Information {...props} device={device} onDeviceChange={setDevice} />
            <DeviceUsersGrid
                {...props}
                users={getUsersQuery.data}
                onUnregisterUser={onUnregisterUser}
            />
            <DeviceRolesGrid roles={deviceRoles} />
            {props.isInEditMode && (
                <FormButtons
                    leftButtons={
                        <Button
                            asElement={ButtonElement.BUTTON}
                            buttonType={ButtonType.SECONDARY}
                            onClick={props.onLeaveEditMode}
                        >
                            {localization.getString("cancel")}
                        </Button>
                    }
                    rightButtons={
                        <Button
                            type="submit"
                            asElement={ButtonElement.BUTTON}
                            buttonType={ButtonType.PRIMARY}
                            loading={isLoading}
                        >
                            {localization.getString("saveChanges")}
                        </Button>
                    }
                />
            )}
        </form>
    )
})

DeviceInformation.displayName = "DeviceInformation"

interface InformationProps {
    device: Device
    isInEditMode: boolean
    onDeviceChange: React.Dispatch<React.SetStateAction<Device>>
}

function Information(props: InformationProps): JSX.Element {
    const [leftItems, rightItems] = useInformationItems(
        props.device,
        props.isInEditMode,
        props.onDeviceChange
    )

    return (
        <div className={styles.informationContainer}>
            <SimpleTable items={leftItems} />
            <SimpleTable items={rightItems} />
        </div>
    )
}

function useInformationItems(
    device: Device,
    isInEditMode: boolean,
    onDeviceChange: React.Dispatch<React.SetStateAction<Device>>
): [SimpleTableItem[], SimpleTableItem[]] {
    const localization = useServiceLocalization()

    return React.useMemo((): [SimpleTableItem[], SimpleTableItem[]] => {
        const onOwnershipChange = (deviceOwnership: DeviceOwnership): void => {
            onDeviceChange((oldDevice) => ({ ...oldDevice, deviceOwnership }))
        }

        const ownershipItem: SimpleTableItem = {
            label: localization.getString("ownership"),
            value: isInEditMode ? (
                <SelectInput
                    value={device.deviceOwnership}
                    options={allDeviceOwnership}
                    onChange={onOwnershipChange}
                />
            ) : (
                localization.getString(deviceOwnershipLabelDict[device.deviceOwnership])
            ),
        }

        const protectionProfile: SimpleTableItem = {
            label: localization.getString("itpPolicy"),
            value: device.protectionProfile ? (
                <div className={styles.itpPolicy}>
                    <Link
                        to={formatRoutePath(ROUTE.INTERNET_THREAT_PROTECTION_DETAILS, {
                            id: encodeID(device.protectionProfile.id),
                        })}
                    >
                        {device.protectionProfile.name}
                    </Link>
                    <span className={styles.itpStatus}>
                        ({localization.getString(device.protectionProfile.itpStatus)})
                    </span>
                </div>
            ) : (
                localization.getString("none")
            ),
        }

        const onBannedChange = (isBanned: string): void => {
            onDeviceChange((oldDevice) => ({
                ...oldDevice,
                status: {
                    ...oldDevice.status,
                    type: isBanned === "yes" ? StatusType.BANNED : StatusType.PENDING,
                },
            }))
        }

        const bannedSelectOptions: SelectItem[] = [
            { displayName: localization.getString("yes"), value: "yes" },
            { displayName: localization.getString("no"), value: "no" },
        ]

        const bannedItem: SimpleTableItem = {
            label: localization.getString("bannedStatus"),
            value: isInEditMode ? (
                <SelectInput
                    value={getBannedStatusValue(device.status.type)}
                    options={bannedSelectOptions}
                    onChange={onBannedChange}
                />
            ) : (
                localization.getString(getBannedStatusValue(device.status.type))
            ),
        }

        const modelItem: SimpleTableItem = {
            label: localization.getString("model"),
            value: device.model,
        }

        const architectureItem: SimpleTableItem = {
            label: localization.getString("architecture"),
            value: device.architecture,
        }
        const activeServiceTunnelItem: SimpleTableItem = {
            label: localization.getString("activeServiceTunnel"),
            value: device.activeServiceTunnel ? (
                <Link
                    to={formatRoutePath(ROUTE.SERVICE_TUNNELS_DETAILS, {
                        id: encodeID(device.activeServiceTunnel.id),
                    })}
                >
                    {device.activeServiceTunnel.name}
                </Link>
            ) : (
                localization.getString("none")
            ),
        }

        const leftTable = [ownershipItem, bannedItem, protectionProfile]
        const rightTable = [modelItem, architectureItem, activeServiceTunnelItem]

        const [accessTierConnection, ...moreConnections] =
            device.activeServiceTunnel?.accessTierConnections ?? []

        if (!accessTierConnection) return [leftTable, rightTable]

        const accessTierConnectionItem: SimpleTableItem = {
            label: localization.getString("accessTiers"),
            value: (
                <AppText
                    className={styles.accessTierConnectionItem}
                    ls={{
                        key: moreConnections.length
                            ? "accessTierConnectionTextAndMore"
                            : "accessTierConnectionText",
                        replaceVals: [
                            accessTierConnection.name,
                            formatRoutePath(ROUTE.ACCESS_TIERS_DETAILS, {
                                id: encodeID(accessTierConnection.id),
                            }),
                            accessTierConnection.ipAddress,
                        ],
                    }}
                />
            ),
        }

        return [leftTable, [...rightTable, accessTierConnectionItem]]
    }, [device, isInEditMode, localization, onDeviceChange])
}

function getBannedStatusValue(status: StatusType): LanguageKey {
    return status === StatusType.BANNED ? "yes" : "no"
}
