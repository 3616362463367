import React from "react"
import styles from "./EditPreferredApp.module.scss"
import { LoadMask } from "../../../../components/load-mask/LoadMask.component"
import { LargeMessage } from "../../../../components/large-message/LargeMessage.component"
import { faExclamation } from "@fortawesome/pro-solid-svg-icons"
import {
    Select,
    Button,
    ConfirmationDialog,
    ErrorBanner,
    InfoBanner,
    Form,
    FormColumns,
    FormRow,
    FormLabel,
    Input,
} from "../../../../components"
import { AppText } from "../../../../../v3/components/app-text/AppText.component"

export default function () {
    return (
        <div>
            {this.state.loading && <LoadMask />}
            {!this.state.loading && !this.state.app && (
                <LargeMessage icon={faExclamation} className={styles.missingMessage}>
                    {this.localizationService.getString("appNotFound")}
                </LargeMessage>
            )}
            {this.state.app && (
                <Form onSubmit={this.onSubmit} display="grid" floating labelWidth={200}>
                    <FormColumns right={<h2>{this.state.app.name}</h2>} />
                    <FormRow>
                        {this.state.error && <ErrorBanner>{this.state.error}</ErrorBanner>}
                        {this.state.success && <InfoBanner>{this.state.success}</InfoBanner>}
                    </FormRow>
                    <FormLabel
                        title={this.localizationService.getString("mandatory")}
                        htmlFor="mandatory"
                    >
                        <Select
                            required
                            value={this.state.app.mandatory.toString()}
                            options={this.mandatoryOptions}
                            onChange={this.onMandatoryChange}
                        />
                    </FormLabel>
                    <FormLabel
                        title={this.localizationService.getString("platforms")}
                        htmlFor="platforms"
                    >
                        <Select
                            multiple
                            required
                            value={this.state.platforms}
                            options={this.platformOptions}
                            onChange={this.onPlatformChange}
                        />
                    </FormLabel>
                    {this.state.platforms.length > 0 && (
                        <FormColumns
                            right={
                                <>
                                    <h3>{this.localizationService.getString("processNames")}</h3>
                                    <AppText ls="applicationCheckProcessDescription" />
                                </>
                            }
                        />
                    )}
                    {this.state.platforms.map((p) => (
                        <FormLabel
                            key={p}
                            title={
                                this.platformOptions.find((platform) => platform.value === p)
                                    .displayName
                            }
                            htmlFor={p + "-id"}
                        >
                            <Input
                                className={styles.formInput}
                                required
                                defaultValue={this.state.processMap[p]}
                                placeholder={this.localizationService.getString("processName")}
                            />
                        </FormLabel>
                    ))}
                    {this.state.platforms.length > 0 && (
                        <FormColumns
                            right={
                                <div className={styles.formSubmit}>
                                    <Button
                                        className={styles.deleteButton}
                                        error
                                        onClick={this.onShowDeleteDialog}
                                    >
                                        {this.localizationService.getString("delete")}
                                    </Button>
                                    <Button className={styles.submitButton} type="submit" primary>
                                        {this.localizationService.getString("update")}
                                    </Button>
                                </div>
                            }
                        />
                    )}
                </Form>
            )}
            <ConfirmationDialog
                open={this.state.showDeleteDialog}
                title={this.localizationService.getString("deleteApplicationCheck")}
                onClose={this.onHideDeleteDialog}
                onConfirm={this.onDelete}
            >
                {this.localizationService.getString(
                    "areYouSureYouWantToDeleteThisApplicationCheck"
                )}
            </ConfirmationDialog>
        </div>
    )
}
