import classNames from "classnames/bind"
import * as React from "react"
import styles from "./KeyValueInput.module.scss"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faMinus, faPlus } from "@fortawesome/pro-regular-svg-icons"
import { Button, Input } from ".."

export type KVPair = [string, string]

interface Props {
    value: KVPair[]
    onChange: (val: KVPair[]) => void | Promise<void>
    className?: string
    disabled?: boolean
    keyHeader?: string
    keyPlaceholder?: string
    valueHeader?: string
    valuePlaceholder?: string
    validateKey?: (
        value: string,
        values: KVPair[],
        index: number
    ) => string | null | undefined | false
    validateValue?: (
        value: string,
        values: KVPair[],
        index: number
    ) => string | null | undefined | false
}

export function KeyValueInput({
    value,
    onChange,
    className,
    disabled: parentDisabled,
    keyHeader,
    valueHeader,
    keyPlaceholder,
    valuePlaceholder,
    validateKey,
    validateValue,
}: Props) {
    const [lastKeyValid, setLastKeyValid] = React.useState(true)
    const [lastValueValid, setLastValueValid] = React.useState(true)

    // some handlers to mutate the parent state
    const updateKey = (idx: number) => (e: React.ChangeEvent<HTMLInputElement>) => {
        const local = [...value]

        // if we are adding to an empty list
        if (idx === -1) {
            // add an entry
            local.push(["", ""])
            // point the function to the first entry
            idx++
        }

        local[idx] = [e.target.value, local[idx][1]]
        onChange(local)
    }

    const updateValue = (idx: number) => (e: React.ChangeEvent<HTMLInputElement>) => {
        const local = [...value]

        // if we are adding to an empty list
        if (idx === -1) {
            // add an entry
            local.push(["", ""])
            // point the function to the first entry
            idx++
        }

        local[idx] = [local[idx][0], e.target.value]
        onChange(local)
    }

    const removeItem = (idx: number) => () => {
        const local = [...value]
        local.splice(idx, 1)
        onChange(local)
    }

    const addItem = () => {
        onChange([...value, ["", ""]])
    }

    // disable the buttons if the tail of the list has a key and value
    const lastKey = value[value.length - 1]?.[0] || ""
    const lastValue = value[value.length - 1]?.[1] || ""
    const disabled = !lastKey || !lastValue

    return (
        <>
            <div className={classNames(className, styles.container, styles.header)}>
                <label className={classNames(styles.formLabel, styles.labelLeft)}>
                    {keyHeader}
                </label>
                <label className={classNames(styles.formLabel, styles.labelLeft)}>
                    {valueHeader}
                </label>
            </div>
            <div
                className={classNames(className, {
                    [styles.container]: !parentDisabled,
                    [styles.containerDisabled]: parentDisabled,
                })}
            >
                {/* ignore the last value in the map, that will populate the Input */}
                {value.length > 1 &&
                    value.slice(0, -1).map(([key, value], i) => (
                        <React.Fragment key={i + 1}>
                            <Input disabled={parentDisabled} value={key} onChange={updateKey(i)} />
                            <Input
                                disabled={parentDisabled}
                                value={value}
                                onChange={updateValue(i)}
                            />

                            {!parentDisabled && (
                                <Button className={styles.button} onClick={removeItem(i)}>
                                    <FontAwesomeIcon icon={faMinus} />
                                </Button>
                            )}
                        </React.Fragment>
                    ))}
                <Input
                    disabled={parentDisabled}
                    value={lastKey}
                    onChange={updateKey(value.length - 1)}
                    placeholder={keyPlaceholder}
                    validate={
                        validateKey &&
                        ((keyValue: string) => validateKey(keyValue, value, value.length - 1))
                    }
                    onValidateChange={setLastKeyValid}
                />
                <Input
                    disabled={parentDisabled}
                    value={lastValue}
                    onChange={updateValue(value.length - 1)}
                    placeholder={valuePlaceholder}
                    validate={
                        validateValue &&
                        ((keyValue: string) => validateValue(keyValue, value, value.length - 1))
                    }
                    onValidateChange={setLastValueValid}
                />

                {!parentDisabled && (
                    <Button
                        className={styles.button}
                        onClick={() => addItem()}
                        disabled={disabled || !lastKeyValid || !lastValueValid}
                    >
                        <FontAwesomeIcon icon={faPlus} />
                    </Button>
                )}
            </div>
        </>
    )
}
