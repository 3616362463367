import * as React from "react"
import { Singleton } from "../decorators/Singleton.decorator"
import {
    InfraApi,
    Shield,
    Activity,
    NetAgent,
    ShieldActivity,
    NetAgentStatus,
    ClusterGroupType,
    ConnectorRes,
    HostInfo,
    TunnelInfraRes,
    AccessTierRes,
    AccessTiersParams,
    ConnectorSpecReq,
    ConnectorReq,
    ConnectorClusterReq,
    ConnectorSearch,
    AccessTierStatusRes,
} from "../api/Infra.api"
import { Paginated, PaginatedSearch } from "../utils/AgGrid.util"
import ServiceContext from "./context"
import { ClusterAccessTier } from "./ServiceTunnel.service"
import { AccessTierGroupApi } from "../../v3/api/AccessTierGroup.api"

@Singleton("InfraService")
export class InfraService {
    public getClusters(): Promise<ClusterInfra[]> {
        return Promise.all([this.infraApi.getShieldConfig(), this.getAccessTiers()]).then(
            ([shieldConfig, accessTiersRes]) => {
                const clusters: ClusterInfra[] = shieldConfig.data?.Configs
                    ? shieldConfig.data.Configs.map(this.mapShieldToClusterInfra)
                    : []
                let accessTierMap: { [key: string]: AccessTier[] } = {}
                const accessTiers: AccessTier[] = accessTiersRes.data
                accessTiers.forEach((accessTier: AccessTier) => {
                    accessTierMap[accessTier.clusterName] = (
                        accessTierMap[accessTier.clusterName] || []
                    ).concat(accessTier)
                })

                clusters.forEach((cluster: ClusterInfra) => {
                    cluster.accessTiers = accessTierMap[cluster.name] || []
                    const lastActivity: ShieldActivity =
                        shieldConfig.data.ShieldLastActivitiesMap[cluster.id]
                    if (lastActivity) {
                        // Last Activity < 10 min ago = REPORTING otherwise INACTIVE
                        const insertTimeInMs = parseInt(lastActivity.InsertTime) / 1000000
                        cluster.status =
                            Date.now() - insertTimeInMs > 1000 * 60 * 10
                                ? ClusterStatus.INACTIVE
                                : ClusterStatus.REPORTING
                    }
                })
                return clusters
            }
        )
    }

    public getCluster(clusterName: string): Promise<ClusterInfra | undefined> {
        return this.getClusters().then((clusters) => clusters.find((c) => c.name === clusterName))
    }

    public getClusterLogs(clusterName: string): Promise<LogInfra[]> {
        return this.infraApi
            .getShieldActivityForCluster(clusterName, 100)
            .then((a) => a.map(this.mapActivityToLogInfra))
    }

    private getAccessTiers(params?: PaginatedSearch): Promise<Paginated<AccessTier>> {
        const search: AccessTiersParams = {
            skip: params?.skip || 0,
            limit: params?.limit || 10000,
            order_by: params?.order_by || "name",
            order: params?.order || "asc",
        }
        if (params?.filterModel?.name) {
            search.name = params.filterModel.name.filter
        }
        if (params?.filterModel?.clusterName) {
            search.cluster_name = params.filterModel.clusterName.filter
        }
        if (params?.filterModel?.status) {
            search.status = params.filterModel.status.filter
        }

        if (params?.filterModel?.address) {
            search.address = params.filterModel.address.filter
        }

        return this.infraApi.getAccessTiers(search).then((response) => {
            return {
                total: response.data.count,
                data: response.data.access_tiers.map(this.mapAccessTier.bind(this)),
            }
        })
    }

    private async getAccessTiersByClusterName(clusterName: string): Promise<Paginated<AccessTier>> {
        const params: PaginatedSearch = {
            skip: 0,
            limit: 10000,
            filterModel: {
                clusterName: { filter: clusterName },
            },
        }
        return this.getAccessTiers(params)
    }

    public getAccessTierNames(clusterName?: string): Promise<string[]> {
        if (clusterName) {
            return this.getAccessTiersByClusterName(clusterName).then(
                (res: Paginated<AccessTier>) => {
                    return res.data ? res.data.map((d) => d.name) : []
                }
            )
        } else {
            return this.getAccessTiers().then((res: Paginated<AccessTier>) => {
                return res.data ? res.data.map((d) => d.name) : []
            })
        }
    }

    private accessTierGroupsApi = new AccessTierGroupApi()

    public async getAccessTierGroupNames() {
        const { access_tier_groups } = await this.accessTierGroupsApi.getAccessTierGroups()
        return access_tier_groups.map((group) => group.name)
    }

    public async getAccessTierByName(name: string): Promise<AccessTier> {
        const search: AccessTiersParams = {
            skip: 0,
            limit: 1,
            name,
        }

        const response = await this.infraApi.getAccessTiers(search)

        return this.mapAccessTier(response.data.access_tiers[0])
    }

    private mapTunnelInfra(tunnelInfraRes: TunnelInfraRes): TunnelInfra {
        return {
            id: tunnelInfraRes.id,
            orgId: tunnelInfraRes.org_id,
            accessTierId: tunnelInfraRes.access_tier_id,
            tunnelPeerType: tunnelInfraRes.tunnel_peer_type,
            dnsSearchDomains: tunnelInfraRes.dns_search_domains,
            udpPortNumber: tunnelInfraRes.udp_port_number,
            tunnelIpAddress: tunnelInfraRes.tunnel_ip_address,
            wireguardPublicKey: tunnelInfraRes.wireguard_public_key,
            dnsEnabled: tunnelInfraRes.dns_enabled,
            keepalive: tunnelInfraRes.keepalive,
            createdAt: tunnelInfraRes.created_at,
            updatedAt: tunnelInfraRes.updated_at,
            cidrs: tunnelInfraRes.cidrs,
            domains: tunnelInfraRes.domains,
        }
    }

    private mapNetAgentInfra(
        netagentInfraResArr: NetAgent[],
        clusterName: string
    ): NetAgentInfra[] {
        let netagents: NetAgentInfra[] = []

        netagentInfraResArr.forEach((netagentInfraRes: NetAgent) => {
            netagents.push({
                hostname: netagentInfraRes.Hostname,
                ips: netagentInfraRes.IPs,
                version: netagentInfraRes.Version,
                visibility: netagentInfraRes.Visibility,
                cidrs: netagentInfraRes.CIDRs,
                hostTags: netagentInfraRes.HostTags,
                uname: netagentInfraRes.Uname,
                siteName: netagentInfraRes.SiteName,
                clusterId: netagentInfraRes.ClusterID,
                clusterName: clusterName,
                isAccessTier: true,
                status: netagentInfraRes.Status,
            })
        })
        return netagents
    }

    private mapAccessTier(accessTierRes: AccessTierRes): AccessTier {
        return {
            id: accessTierRes.id,
            name: accessTierRes.name,
            clusterName: accessTierRes.cluster_name,
            address: accessTierRes.address,
            domains: accessTierRes.domains,
            status: fromAccessTierStatusRes[accessTierRes.status],
            // cspell:ignore netagents
            netagents:
                accessTierRes.netagents &&
                this.mapNetAgentInfra(accessTierRes.netagents, accessTierRes.cluster_name),
            instanceCount: accessTierRes.netagents?.length || 0,
            tunnelSatellite:
                accessTierRes.tunnel_satellite &&
                this.mapTunnelInfra(accessTierRes.tunnel_satellite),
            tunnelEndUser:
                accessTierRes.tunnel_enduser && this.mapTunnelInfra(accessTierRes.tunnel_enduser),
            createdAt: accessTierRes.created_at,
            createdBy: accessTierRes.created_by,
            updatedAt: accessTierRes.updated_at,
            updatedBy: accessTierRes.updated_by,
        }
    }

    public getConnectors(
        params: PaginatedSearch = { skip: 0, limit: 10000 }
    ): Promise<Paginated<Connector>> {
        var search: ConnectorSearch = {
            skip: params.skip,
            limit: params.limit,
        }

        if (params.filterModel?.displayName) {
            search.display_name = params.filterModel.displayName.filter
        }

        return this.infraApi.getConnectors(search).then((response) => {
            return {
                total: response.data.count,
                data: response.data.satellites?.map(this.mapConnectorResToConnector) || [],
            }
        })
    }

    private mapConnectorResToConnector(ConnectorRes: ConnectorRes): Connector {
        let clusterATs: ClusterAccessTier[] = []
        let disableSnat = false

        try {
            const data: ConnectorReq = JSON.parse(ConnectorRes.spec)
            clusterATs = mapConnectorReqSpecToClusterAccessTiers(data.spec)
            disableSnat = data.spec.disable_snat
        } catch {} // unparsable, ignore

        return {
            id: ConnectorRes.id,
            orgId: ConnectorRes.org_id,
            name: ConnectorRes.name,
            displayName: ConnectorRes.display_name,
            cidrs: ConnectorRes.cidrs || [],
            domains: ConnectorRes.domains || [],
            accessTiers: ConnectorRes.access_tiers
                ? ConnectorRes.access_tiers.map((a) => {
                      return {
                          id: a.access_tier_id,
                          allowedIps: a.allowed_ips,
                          endpoint: a.endpoint,
                          healthy: a.healthy,
                          name: a.access_tier_name,
                          wireguardPublicKey: a.wireguard_public_key,
                      }
                  })
                : [],
            tunnelIpAddress: ConnectorRes.tunnel_ip_address,
            keepalive: ConnectorRes.keepalive,
            wireguardPublicKey: ConnectorRes.wireguard_public_key,
            createdAt: ConnectorRes.created_at,
            createdBy: ConnectorRes.created_by,
            updatedAt: ConnectorRes.updated_at,
            updatedBy: ConnectorRes.updated_by,
            apiKeyId: ConnectorRes.api_key_id,
            connectorVersion: ConnectorRes.connector_version,
            hostInfo: ConnectorRes.host_info,
            clusterAccessTiers: clusterATs,
            disableSnat,
        }
    }

    private infraApi: InfraApi = new InfraApi()

    private mapShieldToClusterInfra(shield: Shield): ClusterInfra {
        return {
            id: shield.UUID,
            name: shield.ShieldName,
            version: shield.ShieldVersion,
            accessTiers: [],
            group: shield.GroupType,
            managerType: shield.ClusterMgrType,
            managerIP: shield.ClusterMgrIP,
            address: shield.PublicAddr || "<shield-host-ip-address>:1200",
            gpgPassword: "gobany@n",
            oneTimeKeyEnabled: shield.OTKEnabled.toUpperCase() === "TRUE",
            oneTimeKey: shield.OneTimeKey,
            oneTimeKeyExpiry: new Date(shield.OTKExpiryTime).getTime(),
            scepServerUrl:
                window.location.origin +
                "/api/v1/shieldscep/" +
                shield.OrgID +
                "/" +
                shield.UUID +
                "/scep",
            scepChallengePassword: shield.OrgID.split("").reverse().join(""),
            caCert: shield.CACert,
            sshCaPubKey: shield.SSHCAPublicKey,
            orgId: shield.OrgID,
            status: ClusterStatus.INACTIVE,
        }
    }

    private mapActivityToLogInfra(activity: Activity): LogInfra {
        return {
            timestamp: new Date(activity.InsertTime).getTime(),
            data: activity.Status,
            id: activity.UUID,
            jsonString: JSON.stringify(activity),
        }
    }
}

export const useServiceInfra = () => React.useContext(ServiceContext)?.infra || new InfraService()

// Types

interface NetAgentInfra {
    hostname: string
    ips: string[]
    version: string
    visibility: boolean
    cidrs: string
    hostTags: StringMap
    uname: string
    siteName: string
    clusterId: string
    clusterName: string
    isAccessTier: boolean
    status: NetAgentStatus
}

export enum EnforcementMode {
    ACCESS_TIER = "ACCESS_TIER",
    ACCESS_TIER_GROUP = "ACCESS_TIER_GROUP",
    NET_AGENT = "NET_AGENT",
}

export interface AccessTier {
    id: string
    name: string
    clusterName: string
    address: string
    status: AccessTierStatus
    domains?: string[]
    tunnelSatellite?: TunnelInfra
    tunnelEndUser?: TunnelInfra
    netagents: NetAgentInfra[]
    instanceCount: number
    createdAt: number
    createdBy: string
    updatedAt: number
    updatedBy: string
}

enum AccessTierStatus {
    PENDING = "Pending",
    REPORTING = "Reporting",
    INACTIVE = "Inactive",
    TERMINATED = "Terminated",
}

export interface ClusterInfra {
    id: string
    name: string
    version: string
    accessTiers: AccessTier[]
    group: ClusterGroupType
    managerType: string
    managerIP: string
    address: string
    gpgPassword: string
    oneTimeKeyEnabled: boolean
    oneTimeKey: string
    oneTimeKeyExpiry: number
    scepServerUrl: string
    scepChallengePassword: string
    caCert: string
    sshCaPubKey: string
    orgId: string
    status: ClusterStatus
}

export enum ClusterStatus {
    REPORTING,
    INACTIVE,
}

interface TunnelInfra {
    id: string
    orgId: string
    accessTierId: string
    tunnelPeerType: string
    dnsSearchDomains: string
    udpPortNumber: number
    tunnelIpAddress: string
    wireguardPublicKey: string
    dnsEnabled: boolean
    keepalive: number
    createdAt: number
    updatedAt: number
    cidrs: string[]
    domains: string[]
}

export interface LogInfra {
    timestamp: number
    data: string
    id: string
    jsonString: string
}

export interface Connector {
    id: string
    orgId?: string
    name: string
    displayName: string
    cidrs: string[]
    domains: string[]
    accessTiers?: ConnectorAccessTier[]
    tunnelIpAddress?: string
    keepalive: number
    wireguardPublicKey?: string
    createdAt?: number
    createdBy?: string
    updatedAt?: number
    updatedBy?: string
    apiKeyId: string
    connectorVersion?: string
    hostInfo?: HostInfo
    clusterAccessTiers: ClusterAccessTier[]
    disableSnat: boolean
}

interface ConnectorAccessTier {
    id: string
    allowedIps: string
    endpoint: string
    healthy: boolean
    name: string
    wireguardPublicKey: string
}

// Helper Functions

const fromAccessTierStatusRes: Record<AccessTierStatusRes, AccessTierStatus> = {
    Pending: AccessTierStatus.PENDING,
    Reporting: AccessTierStatus.REPORTING,
    Inactive: AccessTierStatus.INACTIVE,
    Terminated: AccessTierStatus.TERMINATED,
}

function mapConnectorReqSpecToClusterAccessTiers(spec: ConnectorSpecReq): ClusterAccessTier[] {
    if (spec.peer_access_tiers && spec.peer_access_tiers.length > 0) {
        return spec.peer_access_tiers.map((p: ConnectorClusterReq): ClusterAccessTier => {
            return {
                clusterName: p.cluster,
                accessTierNames: p.access_tiers,
                connectorNames: p.connectors,
            }
        })
    }
    return []
}
