import React from "react"
import { Redirect, Route, Switch } from "react-router-dom"

import useTitle from "../../hooks/useTitle.hook"
import { ROUTE } from "../../routes"
import { ClustersList } from "../../pre-v3/views/clusters/list/ClustersList.component"
import { ClusterOverview as ClusterDetails } from "../../pre-v3/views/clusters/overview/ClusterOverview.component"

export function Clusters() {
    useTitle(["clusters", "network", "adminConsole"])

    return (
        <Switch>
            <Route path={ROUTE.CLUSTERS_DETAILS} component={ClusterDetails} />
            <Route path={ROUTE.CLUSTERS} component={ClustersList} />
            <Redirect to={ROUTE.CLUSTERS} />
        </Switch>
    )
}
