import React, { ReactNode } from "react"
import ServicesAddBackendTemplate from "./ServicesAddBackend.template"
import { LocalizationService, ManageService } from "../../../../services"
import { Bind } from "../../../../decorators/Bind.decorator"
import { BackendAttr } from "../../../../api/Manage.api"
import { ToggleButtonItem } from "../../../../components/toggle-button/ToggleButton.component"

export class ServicesAddBackend extends React.Component<
    ServicesAddBackendProps,
    ServicesAddBackendState
> {
    constructor(props: ServicesAddBackendProps) {
        super(props)

        if (props.initialValue) {
            this.state.backendAttr = props.initialValue

            if (props.initialValue.allow_patterns && props.initialValue.allow_patterns.length) {
                this.state.allowedHostnames = props.initialValue.allow_patterns[0].hostnames || []
                this.state.allowedCidrRanges = props.initialValue.allow_patterns[0].cidrs || []
            }

            if (props.initialValue.http_connect) {
                this.backendTypes[0].selected = false
                this.backendTypes[1].selected = true
                this.state.backendType = BackendType.HTTP_CONNECT
            }
        }
    }

    private localizationService: LocalizationService = new LocalizationService()
    private manageService: ManageService = new ManageService()

    public state: ServicesAddBackendState = {
        backendAttr: {
            target: {
                name: "",
                port: "",
                tls: false,
                tls_insecure: false,
                client_certificate: false,
            },
            dns_overrides: {},
            whitelist: [],
            http_connect: false,
            allow_patterns: [],
        },
        backendType: BackendType.FIXED,
        allowedHostnames: [],
        allowedCidrRanges: [],
    }

    public render(): ReactNode {
        return ServicesAddBackendTemplate.call(this)
    }

    private inputForm: HTMLFormElement
    private allowedHostnames: string[]

    private backendTypes: ToggleButtonItem[] = [
        {
            label: this.localizationService.getString("fixedBackendDomain"),
            value: BackendType.FIXED,
            onClick: () => {
                this.setBackendType(BackendType.FIXED)
            },
            selected: true,
        },
        {
            label: this.localizationService.getString("cliendSpecifiedUsingHttpConnect"),
            value: BackendType.HTTP_CONNECT,
            onClick: () => {
                this.setBackendType(BackendType.HTTP_CONNECT)
            },
            selected: false,
        },
    ]

    private setBackendType(type: BackendType): void {
        this.setState(
            {
                backendType: type,
                backendAttr: Object.assign(this.state.backendAttr, {
                    http_connect: type === BackendType.HTTP_CONNECT,
                }),
            },
            this.emitBackend
        )
    }

    @Bind
    private onFormChange(): void {
        if (this.inputForm) {
            const target = {
                name: this.inputForm["domain"].value.trim(),
                port: this.inputForm["port"].value,
                tls: this.inputForm["tls"] && this.inputForm["tls"].checked,
                tls_insecure:
                    this.inputForm["tls-insecure"] && this.inputForm["tls-insecure"].checked,
                client_certificate:
                    this.inputForm["client-certificate"] &&
                    this.inputForm["client-certificate"].checked,
            }
            this.setState(
                {
                    backendAttr: { ...this.state.backendAttr, target },
                },
                this.emitBackend
            )
        }
    }

    @Bind
    private onAllowedHostnamesChange(hostnames: string[]): void {
        let allow_patterns = this.state.backendAttr.allow_patterns || []
        if (allow_patterns.length > 0) {
            allow_patterns = [{ ...allow_patterns[0], hostnames }]
        } else {
            allow_patterns = [{ hostnames }]
        }
        this.setState(
            {
                backendAttr: { ...this.state.backendAttr, allow_patterns },
                allowedHostnames: hostnames,
            },
            this.emitBackend
        )
    }

    @Bind
    private onAllowedCidrRangesChange(cidrs: string[]): void {
        let allow_patterns = this.state.backendAttr.allow_patterns || []
        if (allow_patterns.length > 0) {
            allow_patterns = [{ ...allow_patterns[0], cidrs }]
        } else {
            allow_patterns = [{ cidrs }]
        }
        this.setState(
            {
                backendAttr: { ...this.state.backendAttr, allow_patterns },
                allowedCidrRanges: cidrs,
            },
            this.emitBackend
        )
    }

    @Bind
    private emitBackend(): void {
        if (this.props.onChange) {
            this.props.onChange(this.state.backendAttr)
        }
    }
}

interface ServicesAddBackendProps {
    className: string
    initialValue: BackendAttr
    disabled: boolean
    onChange: (backendAttr: BackendAttr) => void
    showTls: boolean
    showConnectToggle: Boolean
    showAllowPatterns: boolean
}

interface ServicesAddBackendState {
    backendAttr: BackendAttr
    backendType: BackendType
    allowedHostnames: string[]
    allowedCidrRanges: string[]
}

export enum BackendType {
    FIXED = "FIXED",
    HTTP_CONNECT = "HTTP_CONNECT",
}
