import { Tooltip as MuiTooltip, TooltipClasses } from "@mui/material"
import classNames from "classnames/bind"
import React from "react"

import styles from "./Tooltip.module.scss"

export interface Props {
    /**
     * Text to display in the tooltip
     */
    title: string
    /**
     * Additional styling
     */
    className?: string
    children: React.ReactElement
}

export function Tooltip(props: Props): JSX.Element {
    return (
        <MuiTooltip
            title={props.title}
            className={classNames(styles.tooltipContainer, props.className)}
            placement="top"
            classes={tooltipClasses}
            arrow
        >
            {props.children}
        </MuiTooltip>
    )
}

const tooltipClasses: Partial<TooltipClasses> = {
    popper: styles.tooltip,
    tooltip: styles.tooltipElement,
}
