import React, { useMemo, useCallback } from "react"
import { Redirect, Route, Switch, useLocation } from "react-router-dom"

import { TopNavigationBar } from "../../components/top-navigation-bar/TopNavigationBar.component"
import useTitle from "../../hooks/useTitle.hook"
import { useServiceLocalization } from "../../pre-v3/services/localization/Localization.service"
import { ROUTE, doesRouteMatch } from "../../routes"
import { Factors } from "./Factors.view"
import { TrustIntegration } from "../../v3/views/trust-integration/TrustIntegration.view"
import { TrustProfile } from "../../v3/views/app/settings/trustScoreSettings/trustProfiles/TrustProfile.view"
import { Remediation } from "../../v3/views/app/secure/trust-scoring/remediation/Remediation.view"
import { useFeatureFlags } from "../../hooks/useFeatureFlags.hook"
import { Loader } from "../../v3/components/loader/Loader.component"
import { Upsell } from "../../shared/upsell/Upsell.component"

export function Trust() {
    useTitle(["trust", "adminConsole"])

    const localization = useServiceLocalization()
    const location = useLocation()
    const { data: featureFlags, isLoading: isFeatureFlagsLading } = useFeatureFlags()

    const navigationItems = useMemo(
        () => [
            {
                key: 1,
                label: localization.getString("profiles"),
                to: ROUTE.PROFILES,
            },
            {
                key: 2,
                label: localization.getString("factors"),
                to: ROUTE.FACTORS,
            },
            {
                key: 3,
                label: localization.getString("integrations"),
                to: ROUTE.INTEGRATIONS,
            },
            {
                key: 4,
                label: localization.getString("remediation"),
                to: ROUTE.REMEDIATION,
            },
        ],
        []
    )

    const getNavigationItemProps = useCallback(
        (item: (typeof navigationItems)[number]) => {
            const match = doesRouteMatch(item.to, location)
            return { ...item, active: match }
        },
        [location]
    )

    if (!featureFlags || isFeatureFlagsLading) return <Loader medium center />

    const integrationView = featureFlags.adminConsole.doShowUpsellForIntegration
        ? Upsell
        : TrustIntegration

    return (
        <React.Fragment>
            <header>
                <TopNavigationBar
                    headingTitle={localization.getString("trust")}
                    navigationItems={navigationItems}
                    getNavigationItemProps={getNavigationItemProps}
                />
            </header>
            <Switch>
                <Route path={ROUTE.PROFILES} component={TrustProfile} />
                <Route path={ROUTE.INTEGRATIONS} component={integrationView} />
                <Route path={ROUTE.FACTORS} component={Factors} />
                <Route path={ROUTE.REMEDIATION} component={Remediation} />
                <Redirect to={ROUTE.PROFILES} />
            </Switch>
        </React.Fragment>
    )
}
