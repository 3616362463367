import React, { ReactNode } from "react"
import ServicesAddBanyanproxySettingsTemplate from "./ServicesAddBanyanproxySettings.template"
import { LocalizationService } from "../../../../services/localization/Localization.service"
import { Bind } from "../../../../decorators/Bind.decorator"
import { SelectItem } from "../../../../utils/SelectValue.util"
import { FormUtil } from "../../../../utils/Form.util"

export class ServicesAddBanyanproxySettings extends React.Component<
    ServicesAddBanyanproxySettingsProps,
    ServicesAddBanyanproxySettingsState
> {
    public state: ServicesAddBanyanproxySettingsState = { override: true, port: "" }

    public componentDidMount(): void {
        if (typeof this.props.initialOverride !== "undefined") {
            this.setState({ override: this.props.initialOverride })
        }
        if (typeof this.props.initialPort !== "undefined") {
            this.setState({ port: this.props.initialPort })
        }
    }

    public render(): ReactNode {
        return ServicesAddBanyanproxySettingsTemplate.call(this)
    }

    private ls: LocalizationService = new LocalizationService()

    private overrideOptions: SelectItem[] = [
        {
            displayName: this.ls.getString("yes"),
            value: true,
        },
        {
            displayName: this.ls.getString("no"),
            value: false,
        },
    ]

    @Bind
    private onPortChange(value: string): void {
        const regex = /^[0-9\b]+$/
        const flag: boolean = FormUtil.isValidInput(value, regex)
        if (value === "" || flag) {
            this.setState({ port: value }, this.emit)
        }
    }

    @Bind
    private onOverrideChange(value: boolean): void {
        this.setState({ override: value }, this.emit)
    }

    @Bind
    private emit(): void {
        if (this.props.onChange) {
            this.props.onChange(this.state.port, this.state.override)
        }
    }
}

interface ServicesAddBanyanproxySettingsProps {
    onChange?: (port?: string, override?: boolean) => void
    initialPort?: string
    initialOverride?: boolean
}

interface ServicesAddBanyanproxySettingsState {
    port?: string
    override?: boolean
}
