import React, { useCallback, useMemo } from "react"

import { TopNavigationBar } from "../../components/top-navigation-bar/TopNavigationBar.component"
import { useFeatureFlags } from "../../hooks/useFeatureFlags.hook"
import { useServiceLocalization } from "../../pre-v3/services/localization/Localization.service"
import { ROUTE, doesRouteMatch } from "../../routes"
import { Route, useLocation, Switch, Redirect } from "react-router-dom"
import { ItpPolicy } from "../../v3/views/itp-policy/ItpPolicy.view"
import { PublicResource } from "../../v3/views/discovered-resources/public-resources/PublicResource.view"
import { SaasApps } from "./SaasApps.view"
import useTitle from "../../hooks/useTitle.hook"
import { DataLostPrevention } from "./DataLostPrevention.view"
import { Loader } from "../../v3/components/loader/Loader.component"
import { Upsell } from "../../shared/upsell/Upsell.component"
import { LicenseEnforcementBanner } from "../shared/LicenseEnforcementBanner.component"

export function InternetAccess() {
    useTitle(["internetAccess", "adminConsole"])

    const { data: featureFlags, isLoading: isFeatureFlagLoading } = useFeatureFlags()
    const localization = useServiceLocalization()
    const location = useLocation()

    const navigationItems = useMemo(
        () => [
            {
                key: 1,
                label: localization.getString("internetThreatProtection"),
                to: ROUTE.INTERNET_THREAT_PROTECTION,
            },
            { key: 2, label: localization.getString("saasApps"), to: ROUTE.SAAS_APPS },
            ...(featureFlags?.adminConsole.canAccessAppDiscovery
                ? [
                      {
                          key: 3,
                          label: localization.getString("appDiscovery"),
                          to: ROUTE.APP_DISCOVERY,
                      },
                  ]
                : []),
            ...(featureFlags?.adminConsole.canAccessDataLossPrevention
                ? [
                      {
                          key: 4,
                          label: localization.getString("dataLostPrevention"),
                          to: ROUTE.DATA_LOST_PREVENTION,
                      },
                  ]
                : []),
        ],
        [featureFlags, localization]
    )

    const getNavigationItemProps = useCallback(
        (item: (typeof navigationItems)[number]) => {
            const match = doesRouteMatch(item.to, location)
            return { ...item, active: match }
        },
        [location]
    )

    if (!featureFlags || isFeatureFlagLoading) return <Loader medium center />

    const saasAppsView = featureFlags.adminConsole.doShowUpsellForSaasApps ? Upsell : SaasApps
    const appDiscoveryView = featureFlags.adminConsole.doShowUpsellForAppDiscovery
        ? Upsell
        : PublicResource

    return (
        <>
            <header>
                <TopNavigationBar
                    headingTitle={localization.getString("internetAccess")}
                    navigationItems={navigationItems}
                    getNavigationItemProps={getNavigationItemProps}
                />
            </header>
            {featureFlags.adminConsole.showLicenseEnforcementBannerInInternetAccess && (
                <LicenseEnforcementBanner />
            )}
            {featureFlags.adminConsole.doShowUpsellForInternetAccess ? (
                <Upsell />
            ) : (
                <Switch>
                    <Route path={ROUTE.INTERNET_THREAT_PROTECTION} component={ItpPolicy} />
                    <Route path={ROUTE.SAAS_APPS} component={saasAppsView} />
                    {featureFlags.adminConsole.canAccessAppDiscovery && (
                        <Route path={ROUTE.APP_DISCOVERY} component={appDiscoveryView} />
                    )}
                    {featureFlags.adminConsole.canAccessDataLossPrevention && (
                        <Route path={ROUTE.DATA_LOST_PREVENTION} component={DataLostPrevention} />
                    )}
                    <Redirect from={ROUTE.INTERNET_ACCESS} to={ROUTE.INTERNET_THREAT_PROTECTION} />
                </Switch>
            )}
        </>
    )
}
