import React, { FC, useMemo } from "react"
import { SplineChart, SplineChartXType } from "../../../components/charts/SplineChart.component"
import { ChartData, Interval, useServiceLocalization } from "../../../pre-v3/services"
import { DateUtil } from "../../../pre-v3/utils/Date.util"
import { LanguageKey } from "../../../pre-v3/services/localization/languages/en-US.language"
import { Loader } from "../../components/loader/Loader.component"
import styles from "./EventChart.module.scss"

interface Props {
    data: ChartData[]
    isLoading: boolean
}

const rangeToSubtitleRange: Record<
    Interval,
    { key: LanguageKey; replace: readonly string[] } | undefined
> = {
    [Interval.SIX_HOURS]: { key: "xHours", replace: ["6"] },
    [Interval.THREE_HOURS]: { key: "xHours", replace: ["3"] },
    [Interval.ONE_HOUR]: { key: "hourly", replace: [] },
    [Interval.THIRTY_MINUTES]: { key: "xMinutes", replace: ["30"] },
    [Interval.FIFTEEN_MINUTES]: { key: "xMinutes", replace: ["15"] },
    [Interval.NULL]: undefined,
} as const

const getValue = (data: ChartData) => data.totalEvents
const getStart = (data: ChartData[]) => {
    if (!data.length) return 0
    return new Date(data[0].dateTime).getTime()
}
const getInterval = (data: ChartData[]) => {
    if (!data.length) return 0
    return data[0].widthMins * 60 * 1000
}

export const EventsChart: FC<Props> = ({ data, isLoading }) => {
    const localization = useServiceLocalization()

    const subtitle = useMemo(() => {
        if (!data.length) return ""
        const timeRange = rangeToSubtitleRange[data[0].widthMins || Interval.NULL]
        const timeRangeStr = !timeRange
            ? ""
            : localization.getString(timeRange.key, ...timeRange.replace)
        const fromDate = DateUtil.formatToShortLocalDateStr(
            new Date(data[0].dateTime).getTime(),
            localization.getLocale()
        )
        const toDate = DateUtil.formatToShortLocalDateStr(
            new Date(data[data.length - 1].dateTime).getTime(),
            localization.getLocale()
        )
        return localization.getString("eventChartSubtitle", timeRangeStr, fromDate, toDate)
    }, [data])

    return (
        <div className={styles.mainContainer}>
            {isLoading && (
                <div className={styles.loaderContainer}>
                    <Loader medium />
                </div>
            )}
            <SplineChart
                subtitle={subtitle}
                xType={SplineChartXType.DATETIME}
                dataName={localization.getString("events")}
                data={data}
                getValue={getValue}
                getPointsInterval={getInterval}
                getPointStart={getStart}
            />
        </div>
    )
}
