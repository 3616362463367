import * as React from "react"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faStar } from "@fortawesome/pro-solid-svg-icons"
import styles from "./PremiumFeatureTag.module.scss"
import classnames from "classnames/bind"
import { useServiceLocalization } from "../../services/localization/Localization.service"

export function PremiumFeatureTag({ className }: { className?: string }) {
    const localization = useServiceLocalization()
    return (
        <span className={classnames(styles.premiumFeatureTag, className)}>
            <FontAwesomeIcon icon={faStar} style={{ marginRight: 2 }} />
            {localization.getString("premiumFeature")}
        </span>
    )
}
