import React from "react"
import { ModalRef } from "../../../pre-v3/services"
import { useGetPublicResourceById } from "../../../v3/services/PublicResource.service"
import {
    AppDetailsModal,
    AppDetailsModalProps,
} from "../../components/shared/app-details-modal/AppDetailsModal.component"
import { Loader } from "../../components/loader/Loader.component"

interface AppDetailsModalWrapperProps {
    appId: string
    modalRef: ModalRef
}

export function AppDetailsModalWrapper(props: AppDetailsModalWrapperProps): JSX.Element {
    const { appId, modalRef } = props

    const { data: resource, isFetching: isResourceLoading } = useGetPublicResourceById(appId)

    const appDetails: AppDetailsModalProps["appDetails"] = {
        name: resource?.name || "",
        description: resource?.description || "",
        domains: resource?.domains || [],
        logo: resource?.logoBitmap || "",
        sensitivity: resource?.sensitivity || [],
        category: resource?.category || "",
        helpfulLinks: resource?.helpfulLinks || [],
        url: resource?.appUrl || "",
    }

    return (
        <Loader isLoading={isResourceLoading} medium center>
            <AppDetailsModal modalRef={modalRef} appDetails={appDetails} />
        </Loader>
    )
}
