import React, { ReactNode, SyntheticEvent } from "react"
import TrustProviderInviteCodeSettingsTemplate from "./TrustProviderInviteCodeSettings.template"
import { LocalizationService } from "../../../../../services/localization/Localization.service"
import { LinkService } from "../../../../../services/link/Link.service"
import { UserApi, InviteCodeConfig } from "../../../../../api/User.api"
import { Bind } from "../../../../../decorators/Bind.decorator"
import { OrgEdition, UserService } from "../../../../../services"

interface Props {
    canUpdateInviteCode?: boolean
}

export class TrustProviderInviteCodeSettings extends React.Component<
    Props,
    TrustProviderInviteCodeSettingsState
> {
    public state: TrustProviderInviteCodeSettingsState = {
        error: "",
        success: "",
        showConfirmDialog: false,
        inviteCode: "",
        edition: OrgEdition.ENTERPRISE,
    }

    public render(): ReactNode {
        return TrustProviderInviteCodeSettingsTemplate.call(this)
    }

    public componentDidMount(): void {
        this.userService.getEdition().then((edition) => this.setState({ edition }))
        this.userApi.getInviteCode().then(
            (config: InviteCodeConfig) => {
                this.setState({ inviteCode: config.invite_code })
            },
            (error: Error) => {
                this.setState({ error: error.message })
            }
        )
    }

    @Bind
    private onShowConfirmDialog(): void {
        this.setState({ showConfirmDialog: true })
    }

    @Bind
    private onHideConfirmDialog(): void {
        this.setState({ showConfirmDialog: false })
    }

    @Bind
    private onSubmit(event: SyntheticEvent): void {
        this.setState({ success: "", error: "" })
        event.preventDefault()
        if (event.target) {
            const form: { [key: string]: HTMLInputElement } = <any>event.target
            this.setState({ inviteCode: form["inviteCode"].value })
            this.onShowConfirmDialog()
        }
    }

    @Bind
    private onUpdateInviteCode(): void {
        this.onHideConfirmDialog()
        this.setState({ loading: true })
        this.userApi.setInviteCode(this.state.inviteCode).then(
            () => {
                this.setState({
                    loading: false,
                    success: this.localizationService.getString("inviteCodeUpdated"),
                })
            },
            (error: Error) => {
                this.setState({ loading: false, error: error.message })
            }
        )
    }

    private localizationService: LocalizationService = new LocalizationService()
    private linkService: LinkService = new LinkService()
    private userApi: UserApi = new UserApi()
    private userService: UserService = new UserService()
}

interface TrustProviderInviteCodeSettingsState {
    error: string
    success: string
    loading?: boolean
    inviteCode: string
    showConfirmDialog: boolean
    edition: OrgEdition
}
