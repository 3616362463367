import React, { ReactNode } from "react"

import { Bind } from "../../../../decorators/Bind.decorator"
import { LocalizationService } from "../../../../services/localization/Localization.service"
import { OperatingSystem, SettingsService } from "../../../../services/Settings.service"
import ActionBarService from "../../../../services/ActionBar.service"
import { SelectItem } from "../../../../utils/SelectValue.util"
import OperatingSystemVersionsTemplate from "./OperatingSystemVersions.template"
import { TrustProfileService } from "../../../../../v3/services/TrustProfile.service"

interface State {
    osSelect?: OSSelection[]
    error?: string
    success?: string
    osMap: Record<string, string[][]>
}

interface OSSelection {
    os: OperatingSystem
    releases: number
    displayName?: string
}

export class OperatingSystemVersions extends React.Component<{}, State> {
    public state: State = { osMap: {} }

    public render(): ReactNode {
        return OperatingSystemVersionsTemplate.call(this)
    }

    public componentDidMount(): void {
        this.actionBarService.registerRefreshFn(this.fetchData)
        this.fetchData()
    }

    public componentWillUnmount(): void {
        this.actionBarService.unregisterRefreshFn(this.fetchData)
    }

    private localizationService: LocalizationService = new LocalizationService()
    private actionBarService: ActionBarService = new ActionBarService()
    private settingsService: SettingsService = new SettingsService()
    private trustProfileService: TrustProfileService = new TrustProfileService()

    private selectItems: SelectItem[] = [
        {
            displayName: this.localizationService.getString("latestVersion"),
            value: 1,
        },
        {
            displayName: this.localizationService.getString("last2Versions"),
            value: 2,
        },
        {
            displayName: this.localizationService.getString("last3Versions"),
            value: 3,
        },
    ]

    @Bind
    private onReleaseChange(selection: OSSelection, value: number): void {
        selection.releases = value
        this.setState({ osSelect: this.state.osSelect })
    }

    @Bind
    private onSave(): void {
        if (this.state.osSelect) {
            this.setState({ success: "", error: "" })
            const map: NumberMap = this.state.osSelect.reduce((map: NumberMap, s: OSSelection) => {
                map[s.os.toLowerCase()] = s.releases
                return map
            }, {})
            this.settingsService.setPlatformConfig(map).then(
                () => {
                    this.setState({
                        success: this.localizationService.getString(
                            "successfullySavedOperatingSystemSettings"
                        ),
                    })
                },
                () => {
                    this.setState({
                        error: this.localizationService.getString(
                            "failedToSaveOperatingSystemSettings"
                        ),
                    })
                }
            )
            // TODO: when we have multiple profiles, pass the profile id
            this.trustProfileService.updateTrustProfileOsVersion("profileId", {
                ANDROID: map["android"] || 3,
                IOS: map["ios"] || 3,
                MACOS: map["macos"] || 3,
                WINDOWS: map["windows"] || 3,
                LINUX: map["ubuntu"] || 3, // we track linux via "ubuntu", not confusing at all
            })
        }
    }

    private fetchData(): void {
        this.setState({ success: "", error: "" })

        const osSelect: OSSelection[] = [
            {
                os: OperatingSystem.MACOS,
                releases: 2,
                displayName: this.localizationService.getString("macOS"),
            },
            {
                os: OperatingSystem.WINDOWS,
                releases: 2,
                displayName: this.localizationService.getString("windows"),
            },
            {
                os: OperatingSystem.UBUNTU,
                releases: 2,
                displayName: this.localizationService.getString("linux"),
            },
            {
                os: OperatingSystem.IOS,
                releases: 2,
                displayName: this.localizationService.getString("iOS"),
            },
            {
                os: OperatingSystem.ANDROID,
                releases: 2,
                displayName: this.localizationService.getString("android"),
            },
        ]

        this.settingsService.getLatestOS().then((map) => {
            this.setState({ osMap: map })
        })

        this.settingsService
            .getPlatformConfig()
            .then(
                (osMap: NumberMap) => {
                    osSelect.forEach((s) => {
                        s.releases = osMap[s.os.toLowerCase()] || 1
                    })
                },
                () => {
                    this.setState({
                        error: this.localizationService.getString("failedToLoadSettings"),
                    })
                }
            )
            .finally(() => {
                this.setState({ osSelect: osSelect })
            })
    }
}
