import classNames from "classnames"
import React from "react"
import { Pill, PillProps } from "../pill/Pill.component"
import styles from "./PillContainer.module.scss"

export type { PillProps } from "../pill/Pill.component"

export interface PillContainerProps {
    pills: PillProps[]
    className?: string
}

export function PillContainer(props: PillContainerProps) {
    const { pills, className } = props

    return (
        <div className={classNames(className, styles.pillsContainer)}>
            {pills.map((pill) => {
                return <Pill key={pill.id} {...pill} />
            })}
        </div>
    )
}
