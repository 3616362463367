import React, { ReactNode, SyntheticEvent } from "react"
import { RouteComponentProps as Props } from "react-router"

import { ROUTE } from "../../../../../routes"
import { LocalizationService } from "../../../../services/localization/Localization.service"
import ActionBarService from "../../../../services/ActionBar.service"
import {
    OperatingSystem,
    PreferredApp,
    SettingsService,
} from "../../../../services/Settings.service"
import { Bind } from "../../../../decorators/Bind.decorator"
import { FormUtil } from "../../../../utils/Form.util"
import { SelectItem } from "../../../../utils/SelectValue.util"
import AddApplicationCheckTemplate from "./AddApplicationCheck.template"

interface State {
    error?: string
    mandatory: string
    platforms: string[]
}

export class AddApplicationCheck extends React.Component<Props, State> {
    public state: State = { mandatory: true.toString(), platforms: [] }

    public render(): ReactNode {
        return AddApplicationCheckTemplate.call(this)
    }

    public componentDidMount(): void {
        this.actionBarService.setItems(this.localizationService.getString("addApplication"), [
            {
                label: this.localizationService.getString("trustScoreSettings"),
            },
            {
                label: this.localizationService.getString("trustFactors"),
                href: ROUTE.TRUST_FACTOR_SETTINGS,
            },
            {
                label: this.localizationService.getString("applicationCheck"),
                href: ROUTE.APPLICATION_CHECK,
            },
        ])
    }

    private localizationService: LocalizationService = new LocalizationService()
    private actionBarService: ActionBarService = new ActionBarService()
    private settingsService: SettingsService = new SettingsService()

    private mandatoryOptions: SelectItem[] = [
        {
            displayName: this.localizationService.getString(
                "yesRestrictAccessIfThisAppIsNotRunning"
            ),
            value: true.toString(),
        },
        {
            displayName: this.localizationService.getString("noButFactorThisIntoTheTrustLevel"),
            value: false.toString(),
        },
    ]

    private platformOptions: SelectItem[] = [
        {
            displayName: this.localizationService.getString("macOS"),
            value: OperatingSystem.MACOS,
        },
        {
            displayName: this.localizationService.getString("windows"),
            value: OperatingSystem.WINDOWS,
        },
        {
            displayName: this.localizationService.getString("linux"),
            value: OperatingSystem.UBUNTU,
        },
    ]

    @Bind
    private onMandatoryChange(value: string): void {
        this.setState({ mandatory: value })
    }

    @Bind
    private onPlatformChange(value: string[]): void {
        this.setState({ platforms: value })
    }

    @Bind
    private onSubmit(event: SyntheticEvent): void {
        this.setState({ error: "" })
        event.preventDefault()
        if (event.target && this.state.platforms.length > 0) {
            const form: { [key: string]: HTMLInputElement } = <any>event.target
            const app: PreferredApp = {
                name: form["appName"].value,
                mandatory: this.state.mandatory === true.toString(),
                platforms: [],
            }
            for (let i = 0; i < this.state.platforms.length; i++) {
                const platform: string = this.state.platforms[i]
                const process: string = form[platform + "-id"].value

                const isRegex = /^\/.*\/$/
                if (isRegex.test(process)) {
                    const processRegex: string = process.slice(1, -1)
                    if (!FormUtil.isValidRegex(processRegex)) {
                        this.setState({
                            error: this.localizationService.getString(
                                "errorAProcessNameContainsAnInvalidRegex"
                            ),
                        })
                        return
                    }
                }

                app.platforms.push({
                    os: platform,
                    process: process,
                })
            }
            this.settingsService.addPreferredApp(app).then(
                () => {
                    this.props.history.push(ROUTE.APPLICATION_CHECK)
                },
                (err: Error) => {
                    this.setState({ error: err.message })
                }
            )
        }
    }
}
