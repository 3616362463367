import React, { ReactNode, ReactElement } from "react"
import ModalControllerTemplate from "./ModalController.template"
import { Bind } from "../../../decorators/Bind.decorator"
import { ModalBase } from "../base/ModalBase.component"
import { ModalService, ModalRef } from "../../../services/Modal.service"

/**
 * @deprecated Use the Modal component instead
 * src/components/modal/Modal.component.tsx
 */
export class ModalController extends React.Component<{}, ModalControllerState> {
    public state: ModalControllerState = { modals: [] }

    public componentDidMount(): void {
        this.modalService.registerControllerOpenFn(this.onOpen)
        this.modalService.registerControllerUpdateFn(this.onUpdate)
        this.modalService.registerControllerCloseFn(this.onClose)
    }

    public render(): ReactNode {
        return ModalControllerTemplate.call(this)
    }

    private modalService: ModalService = new ModalService()

    @Bind
    private onOpen(modal: ModalRef): void {
        const ele = React.createElement(ModalBase, {
            modalRef: modal,
            key: modal.id,
        })
        this.state.modals.push(ele)
        this.setState({ modals: this.state.modals })
    }

    @Bind
    private onUpdate(modal: ModalRef): void {
        const idx = this.state.modals.findIndex((m) => m.key === modal.id.toString())
        const ele = React.cloneElement(this.state.modals[idx], {
            modalRef: modal,
            key: modal.id,
        })
        this.state.modals[idx] = ele
        this.setState({ modals: this.state.modals })
    }

    @Bind
    private onClose(id: number): void {
        this.setState({ modals: this.state.modals.filter((m) => m.props.modalRef.id !== id) })
    }
}

interface ModalControllerState {
    modals: ReactElement[]
}
