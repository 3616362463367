import classNames from "classnames/bind"
import React from "react"

import { Badge, BadgeProps } from "../badge/Badge.component"
import styles from "./Step.module.scss"

export interface Props extends Omit<React.LiHTMLAttributes<HTMLLIElement>, "onClick"> {
    state: State
    label: string
    number?: number
    onClick?(): void
}

export function Step(props: Props): JSX.Element {
    const { className, state, label, number, role, onClick: originalOnClick, ...liProps } = props

    const onClick: React.MouseEventHandler = (event) => {
        event.preventDefault()
        originalOnClick?.()
    }

    const isActive = state === State.ACTIVE
    const isDisabled = state === State.DISABLED
    const isClickable = !isDisabled && typeof originalOnClick === "function"

    return (
        <li
            {...liProps}
            role={role}
            onClick={isClickable ? onClick : undefined}
            aria-disabled={isDisabled}
            aria-selected={isActive}
            className={classNames(styles.step, className, {
                [styles.activeStep]: isActive,
                [styles.disabledStep]: isDisabled,
                [styles.clickable]: isClickable,
            })}
        >
            {typeof number === "number" && (
                <Badge {...badgePropsDict[state]} number={number} className={styles.badge} />
            )}
            <label className={styles.label}>{label}</label>
        </li>
    )
}

export enum State {
    ACTIVE = "ACTIVE",
    DISABLED = "DISABLED",
    SOLID = "SOLID",
}

const badgePropsDict: Record<State, Partial<BadgeProps>> = {
    [State.ACTIVE]: { brand: true },
    [State.DISABLED]: { disabled: true },
    [State.SOLID]: {},
}
