import React from "react"
import classNames from "classnames/bind"

import { Box, Props as BoxProps } from "../box/Box"
import styles from "./Container.module.scss"

const defaultElement: React.ElementType<React.HTMLAttributes<HTMLDivElement>> = "div"

export type Props<E extends React.ElementType = typeof defaultElement> = BoxProps<E> & {
    fullWidth?: boolean
}

export function Container<E extends React.ElementType = typeof defaultElement>(
    props: Props<E>
): JSX.Element {
    const { fullWidth, ...boxProps } = props
    return (
        // @ts-expect-error
        <Box
            {...boxProps}
            className={classNames(styles.container, props.className, {
                [styles.bounded]: !fullWidth,
            })}
        />
    )
}
