import React from "react"
import styles from "./TrustScoreOSSettings.module.scss"
import { Select } from "../../../../components/select/Select.component"
import {
    Button,
    ButtonElement,
    ButtonType,
} from "../../../../../components/button/Button.component"
import { Banner } from "../../../../components/banner/Banner.component"
import { faExclamationCircle } from "@fortawesome/pro-solid-svg-icons"
import { LoadMask } from "../../../../components/load-mask/LoadMask.component"
import { AppText } from "../../../../../v3/components/app-text/AppText.component"

export default function () {
    return (
        <div>
            {!this.state.osSelect && <LoadMask />}
            {this.state.error && (
                <Banner type="error" icon={faExclamationCircle}>
                    {this.state.error}
                </Banner>
            )}
            {this.state.success && (
                <Banner type="info" icon={faExclamationCircle}>
                    {this.state.success}
                </Banner>
            )}
            <AppText className={styles.description} ls="operatingSystemVersionsDescription" />
            {this.state.osSelect &&
                this.state.osSelect.map((item) => (
                    <div key={item.os}>
                        <div className={styles.osName}>{item.displayName || item.os}</div>
                        <div className={styles.osSetting}>
                            <div className={styles.osSelect}>
                                <Select
                                    options={this.selectItems}
                                    value={item.releases}
                                    onChange={(value) => {
                                        this.onReleaseChange(item, value)
                                    }}
                                />
                            </div>
                            <ul className={styles.osList}>
                                {this.state.osMap[item.os.toLowerCase()] &&
                                    this.state.osMap[item.os.toLowerCase()][item.releases - 1].map(
                                        (os) => (
                                            <li key={os}>
                                                {os}{" "}
                                                {this.localizationService.getString("orGreater")}
                                            </li>
                                        )
                                    )}
                            </ul>
                        </div>
                    </div>
                ))}
            <Button
                buttonType={ButtonType.PRIMARY}
                asElement={ButtonElement.BUTTON}
                className={styles.saveButton}
                onClick={this.onSave}
            >
                {this.localizationService.getString("save")}
            </Button>
        </div>
    )
}
