import { faExclamation } from "@fortawesome/pro-solid-svg-icons"
import React from "react"

import {
    Button,
    ButtonElement,
    ButtonType,
} from "../../../../../../components/button/Button.component"
import { Profile } from "../../../../../../utils/profile.utils"
import {
    Form,
    Select,
    LargeMessage,
    FormColumns,
    FormRow,
    ErrorBanner,
    InfoBanner,
    FormLabel,
} from "../../../../../components"
import { ConfirmationDialog } from "../../../../../components/dialog/confirmation/ConfirmationDialog.component"
import styles from "./OrgAdminDetail.module.scss"
import { AdminActionBar } from "../../../../../../pages/directory/admins/AdminActionBar.component"
import { Input } from "../../../../../../v3/components/input/Input.component"
import { Loader } from "../../../../../../v3/components/loader/Loader.component"

export default function () {
    let readOnly = false

    if (this.state.admin) {
        if (this.state.email === this.state.admin.email) {
            // Prevent end user from deleting/updating own account.
            readOnly = true
        } else if (this.state.userIsOwner) {
            // Allow owners to do anything, except update their own account.
            readOnly = false
        } else if (this.state.admin.profile === Profile.OWNER) {
            // Prevent non-owner admins from managing owner accounts.
            readOnly = true
        }
    }

    return (
        <AdminActionBar isMsp={this.state.isMspOrg} onRefresh={this.fetchData}>
            <div>
                {this.state.loading && <Loader medium center />}
                {!this.state.admin && !this.state.loading && (
                    <LargeMessage icon={faExclamation} className={styles.missingMessage}>
                        {this.localizationService.getString("userNotFound")}
                    </LargeMessage>
                )}
                {this.state.admin && (
                    <Form onSubmit={this.onSubmit} display="grid" floating labelWidth={100}>
                        <FormColumns
                            right={<h2>{this.localizationService.getString("adminSettings")}</h2>}
                        />
                        <FormRow>
                            {this.state.error && <ErrorBanner>{this.state.error}</ErrorBanner>}
                            {this.state.success && <InfoBanner>{this.state.success}</InfoBanner>}
                        </FormRow>

                        <FormLabel
                            title={this.localizationService.getString("email")}
                            htmlFor="email"
                        >
                            <Input
                                defaultValue={this.state.admin.email}
                                className={styles.formInput}
                                required
                                disabled
                            />
                        </FormLabel>
                        <FormLabel
                            title={this.localizationService.getString("name")}
                            htmlFor="name"
                        >
                            <Input
                                defaultValue={this.state.admin.name}
                                className={styles.formInput}
                                required
                                disabled
                            />
                        </FormLabel>
                        <FormLabel
                            title={this.localizationService.getString("profile")}
                            htmlFor="profile"
                        >
                            {readOnly || !this.props.canUpdate ? (
                                <Input
                                    defaultValue={this.state.admin.profile}
                                    className={styles.formInput}
                                    required
                                    disabled
                                    id="profile"
                                />
                            ) : (
                                <Select
                                    required
                                    value={this.state.admin.profile}
                                    options={
                                        this.state.isMspOrg
                                            ? this.mspProfileOptions
                                            : this.profileOptions
                                    }
                                    onChange={this.onProfileChange}
                                />
                            )}
                        </FormLabel>
                        {!readOnly && (
                            <FormColumns
                                rightClassName={styles.formButtons}
                                right={
                                    <>
                                        {this.props.canDelete && (
                                            <Button
                                                asElement={ButtonElement.BUTTON}
                                                buttonType={ButtonType.DESTRUCTIVE}
                                                onClick={this.onShowDeleteDialog}
                                                type="button"
                                            >
                                                {this.localizationService.getString("delete")}
                                            </Button>
                                        )}
                                        {this.state.showResetMfa && (
                                            <Button
                                                asElement={ButtonElement.BUTTON}
                                                buttonType={ButtonType.SECONDARY}
                                                onClick={this.onResetMfa}
                                                type="button"
                                            >
                                                {this.localizationService.getString("resetMfa")}
                                            </Button>
                                        )}
                                        {this.props.canUpdate && (
                                            <Button
                                                type="submit"
                                                asElement={ButtonElement.BUTTON}
                                                buttonType={ButtonType.PRIMARY}
                                                loading={this.state.submitting}
                                            >
                                                {this.localizationService.getString("update")}
                                            </Button>
                                        )}
                                    </>
                                }
                            />
                        )}
                    </Form>
                )}
                <ConfirmationDialog
                    open={this.state.showDeleteDialog}
                    onClose={this.onHideDeleteDialog}
                    onConfirm={this.onDelete}
                    title={this.localizationService.getString("deleteAdmin")}
                >
                    {this.localizationService.getString("deleteAdminDescription")}
                </ConfirmationDialog>
            </div>
        </AdminActionBar>
    )
}
