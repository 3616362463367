import React from "react"

import { LanguageKey } from "../../../../../../../pre-v3/services/localization/languages/en-US.language"
import { useServiceLocalization } from "../../../../../../../pre-v3/services/localization/Localization.service"
import {
    AppCheckConfig,
    IntegrationPartner,
    isAppCheckTrustFactor,
    NewTrustProfileDetail,
    TrustFactor,
    TrustSourceType,
    IntegrationTrustSource,
    TRUST_EFFECT,
    isFileCheckTrustFactor,
    isPlistTrustFactor,
    isRegistryCheckTrustFactor,
    isOsVersionTrustFactor,
    isChromeBrowserVersionTrustFactor,
} from "../../../../../../services/TrustProfile.service"

interface UseTrustFactorsResult<TrustProfile extends NewTrustProfileDetail>
    extends State<TrustProfile> {
    getError(): string | null
    onAddTrustFactor(trustFactor: TrustFactor): void
    onEditTrustFactor(trustFactor: TrustFactor): void
    onRemoveTrustFactor(trustFactor: TrustFactor): void
    onReset(): void
}

export function useTrustFactors<TrustProfile extends NewTrustProfileDetail>(
    trustProfile: TrustProfile
): UseTrustFactorsResult<TrustProfile> {
    const localization = useServiceLocalization()

    const [state, dispatch] = React.useReducer<
        (state: State<TrustProfile>, event: Event<TrustProfile>) => State<TrustProfile>,
        TrustProfile
    >(trustFactorsReducer, trustProfile, initialize)

    const getErrorCallback = (): string | null => {
        const tuple = getError(state)
        return tuple && localization.getString(errorDict[tuple[0]], tuple[1])
    }

    const onAddTrustFactor = React.useCallback(
        (trustFactor: TrustFactor) => dispatch({ type: EventType.TRUST_FACTOR_ADDED, trustFactor }),
        []
    )

    const onEditTrustFactor = React.useCallback(
        (trustFactor: TrustFactor) =>
            dispatch({ type: EventType.TRUST_FACTOR_EDITED, trustFactor }),
        []
    )

    const onRemoveTrustFactor = React.useCallback(
        (trustFactor: TrustFactor) =>
            dispatch({ type: EventType.TRUST_FACTOR_REMOVED, trustFactor }),
        []
    )

    const onReset = React.useCallback(
        () => dispatch({ type: EventType.TRUST_FACTORS_RESET, trustProfile }),
        [trustProfile]
    )

    return {
        ...state,
        getError: getErrorCallback,
        onAddTrustFactor,
        onEditTrustFactor,
        onRemoveTrustFactor,
        onReset,
    }
}

interface State<TrustProfile extends NewTrustProfileDetail> {
    trustProfile: TrustProfile
    banyanTrustFactors: TrustFactors
    integrationTrustFactors: IntegrationTrustFactors
}

export interface TrustFactors {
    used: TrustFactor[]
    unused: TrustFactor[]
}

export interface TrustFactorsPerIntegration {
    integrationPartner: IntegrationPartner
    integrationId: string
    integrationName: string
    description?: string
    trustFactors: TrustFactors
}

interface TrustFactorsPerIntegrationPartner {
    selected?: TrustFactorsPerIntegration
    unselected: Partial<Record<string, TrustFactorsPerIntegration>>
}

export type IntegrationTrustFactors = Record<IntegrationPartner, TrustFactorsPerIntegrationPartner>

export function getUsedTrustFactors(
    banyanTrustFactors: TrustFactors,
    integrationTrustFactors: IntegrationTrustFactors
): TrustFactor[] {
    return [
        ...getUsed(banyanTrustFactors),
        ...Object.values(integrationTrustFactors).reduce(reduceUsedIntegrationTrustFactors, []),
    ]
}

export function getUnusedTrustFactors(
    banyanTrustFactors: TrustFactors,
    integrationTrustFactors: IntegrationTrustFactors
): TrustFactor[] {
    return [
        ...getUnused(banyanTrustFactors),
        ...Object.values(integrationTrustFactors).reduce(reduceUnusedIntegrationTrustFactors, []),
    ]
}

function reduceUsedIntegrationTrustFactors(
    acc: TrustFactor[],
    perIntegration: TrustFactorsPerIntegrationPartner
): TrustFactor[] {
    if (!perIntegration.selected) return acc
    return [...acc, ...getUsed(perIntegration.selected.trustFactors)]
}

function reduceUnusedIntegrationTrustFactors(
    acc: TrustFactor[],
    perIntegration: TrustFactorsPerIntegrationPartner
): TrustFactor[] {
    if (perIntegration.selected) return acc
    return [
        ...acc,
        ...Object.values(perIntegration.unselected).flatMap((integration) =>
            integration ? getUnused(integration.trustFactors) : []
        ),
    ]
}

export function areThereUsedFactors(
    banyanTrustFactors: TrustFactors,
    integrationTrustFactors: IntegrationTrustFactors
): boolean {
    return hasFactors(banyanTrustFactors, integrationTrustFactors, getUsed)
}

function getUsed({ used }: TrustFactors): TrustFactor[] {
    return used
}

export function areThereUnusedFactors(
    banyanTrustFactors: TrustFactors,
    integrationTrustFactors: IntegrationTrustFactors
): boolean {
    return hasFactors(banyanTrustFactors, integrationTrustFactors, getUnused)
}

function getUnused({ unused }: TrustFactors): TrustFactor[] {
    return unused
}

function hasFactors(
    banyanTrustFactors: TrustFactors,
    integrationTrustFactors: IntegrationTrustFactors,
    getTrustFactors: (pair: TrustFactors) => TrustFactor[]
): boolean {
    return (
        getTrustFactors(banyanTrustFactors).length >= 1 ||
        Object.values(integrationTrustFactors).some(({ selected, unselected }) =>
            typeof selected === "undefined"
                ? Object.values(unselected).some(
                      (integration) =>
                          integration && getTrustFactors(integration.trustFactors).length >= 1
                  )
                : getTrustFactors(selected.trustFactors).length >= 1
        )
    )
}

function initialize<TrustProfile extends NewTrustProfileDetail>(
    trustProfile: TrustProfile
): State<TrustProfile> {
    const onlyUsedTrustFactors = trustProfile.trustFactors.reduce(
        reduceUsedTrustFactor,
        getEmptyState(trustProfile)
    )

    return trustProfile.unusedTrustFactors.reduce(reduceUnusedTrustFactor, onlyUsedTrustFactors)
}

function getEmptyState<TrustProfile extends NewTrustProfileDetail>(
    trustProfile: TrustProfile
): State<TrustProfile> {
    const emptyTrustFactorsPerIntegrationPartner: TrustFactorsPerIntegrationPartner = {
        unselected: {},
    }

    return {
        trustProfile,
        banyanTrustFactors: { used: [], unused: [] },
        integrationTrustFactors: {
            [TrustSourceType.CROWD_STRIKE]: emptyTrustFactorsPerIntegrationPartner,
            [TrustSourceType.SENTINEL_ONE]: emptyTrustFactorsPerIntegrationPartner,
            [TrustSourceType.WORKSPACE_ONE]: emptyTrustFactorsPerIntegrationPartner,
        },
    }
}

function reduceUsedTrustFactor<TrustProfile extends NewTrustProfileDetail>(
    state: State<TrustProfile>,
    trustFactor: TrustFactor
): State<TrustProfile> {
    switch (trustFactor.source.type) {
        case TrustSourceType.BANYAN:
            return {
                ...state,
                banyanTrustFactors: {
                    ...state.banyanTrustFactors,
                    used: [...state.banyanTrustFactors.used, trustFactor],
                },
            }

        case TrustSourceType.WORKSPACE_ONE:
        case TrustSourceType.CROWD_STRIKE:
        case TrustSourceType.SENTINEL_ONE:
            const integrationPartnerTrustFactors =
                state.integrationTrustFactors[trustFactor.source.type]

            if (
                integrationPartnerTrustFactors.selected &&
                integrationPartnerTrustFactors.selected.integrationId !==
                    trustFactor.source.integrationId
            ) {
                console.warn(
                    "You can't have more than one Trust Integration per Partner!",
                    integrationPartnerTrustFactors.selected,
                    trustFactor
                )
                return reduceUnusedTrustFactor(state, trustFactor)
            }

            const updatedIntegrationPartnerTrustFactors: TrustFactorsPerIntegrationPartner = {
                ...integrationPartnerTrustFactors,
                selected: addTrustFactorsToIntegration(trustFactor.source, {
                    used: [
                        ...(integrationPartnerTrustFactors.selected?.trustFactors.used ?? []),
                        trustFactor,
                    ],
                    unused: [],
                }),
            }

            return {
                ...state,
                integrationTrustFactors: {
                    ...state.integrationTrustFactors,
                    [trustFactor.source.type]: updatedIntegrationPartnerTrustFactors,
                },
            }
    }
}

function reduceUnusedTrustFactor<TrustProfile extends NewTrustProfileDetail>(
    state: State<TrustProfile>,
    trustFactor: TrustFactor
): State<TrustProfile> {
    switch (trustFactor.source.type) {
        case TrustSourceType.BANYAN:
            return {
                ...state,
                banyanTrustFactors: {
                    ...state.banyanTrustFactors,
                    unused: [...state.banyanTrustFactors.unused, trustFactor],
                },
            }

        case TrustSourceType.CROWD_STRIKE:
        case TrustSourceType.SENTINEL_ONE:
        case TrustSourceType.WORKSPACE_ONE:
            let updatedIntegrationPartnerTrustFactors: TrustFactorsPerIntegrationPartner

            const integrationPartnerTrustFactors =
                state.integrationTrustFactors[trustFactor.source.type]

            const integrationTrustFactors =
                integrationPartnerTrustFactors.unselected[trustFactor.source.integrationId]

            if (
                integrationPartnerTrustFactors.selected &&
                integrationPartnerTrustFactors.selected.integrationId ===
                    trustFactor.source.integrationId
            ) {
                // If another partner of this factor is selected, then we add this factor to unsed factor of selected parter.
                updatedIntegrationPartnerTrustFactors = {
                    ...integrationPartnerTrustFactors,
                    selected: {
                        ...integrationPartnerTrustFactors.selected,
                        trustFactors: {
                            used: integrationPartnerTrustFactors.selected?.trustFactors?.used ?? [],
                            unused: [
                                ...(integrationPartnerTrustFactors.selected?.trustFactors.unused ??
                                    []),
                                trustFactor,
                            ],
                        },
                    },
                }
            } else {
                updatedIntegrationPartnerTrustFactors = {
                    ...integrationPartnerTrustFactors,
                    unselected: {
                        ...integrationPartnerTrustFactors.unselected,
                        [trustFactor.source.integrationId]: integrationTrustFactors
                            ? {
                                  ...integrationTrustFactors,
                                  trustFactors: {
                                      ...integrationTrustFactors.trustFactors,
                                      unused: [
                                          ...integrationTrustFactors.trustFactors.unused,
                                          trustFactor,
                                      ],
                                  },
                              }
                            : addTrustFactorsToIntegration(trustFactor.source, {
                                  used: [],
                                  unused: [trustFactor],
                              }),
                    },
                }
            }

            return {
                ...state,
                integrationTrustFactors: {
                    ...state.integrationTrustFactors,
                    [trustFactor.source.type]: updatedIntegrationPartnerTrustFactors,
                },
            }
    }
}

enum EventType {
    TRUST_FACTORS_RESET = "TRUST_FACTORS_RESET",
    TRUST_FACTOR_ADDED = "TRUST_FACTOR_ADDED",
    TRUST_FACTOR_EDITED = "TRUST_FACTOR_EDITED",
    TRUST_FACTOR_REMOVED = "TRUST_FACTOR_REMOVED",
}

type Event<TrustProfile extends NewTrustProfileDetail> =
    | TrustFactorsReset<TrustProfile>
    | TrustFactorAdded
    | TrustFactorEdited
    | TrustFactorRemoved

interface TrustFactorsReset<TrustProfile extends NewTrustProfileDetail> {
    type: EventType.TRUST_FACTORS_RESET
    trustProfile: TrustProfile
}

interface TrustFactorAdded {
    type: EventType.TRUST_FACTOR_ADDED
    trustFactor: TrustFactor
}

interface TrustFactorEdited {
    type: EventType.TRUST_FACTOR_EDITED
    trustFactor: TrustFactor
}

interface TrustFactorRemoved {
    type: EventType.TRUST_FACTOR_REMOVED
    trustFactor: TrustFactor
}

function trustFactorsReducer<TrustProfile extends NewTrustProfileDetail>(
    state: State<TrustProfile>,
    event: Event<TrustProfile>
): State<TrustProfile> {
    switch (event.type) {
        case EventType.TRUST_FACTORS_RESET:
            return initialize(event.trustProfile)

        case EventType.TRUST_FACTOR_ADDED:
            return addFactorToState(state, event.trustFactor)

        case EventType.TRUST_FACTOR_EDITED:
            return editUsedFactor(state, event.trustFactor)

        case EventType.TRUST_FACTOR_REMOVED:
            return removeFactorFromState(state, event.trustFactor)
    }
}

function addFactorToState<TrustProfile extends NewTrustProfileDetail>(
    state: State<TrustProfile>,
    baseTrustFactor: TrustFactor
): State<TrustProfile> {
    // TODO: Remove this logic when we don't have TRUST_EFFECT.NOT_EVALUATED
    const trustFactor: TrustFactor = {
        ...baseTrustFactor,
        effect:
            baseTrustFactor.effect === TRUST_EFFECT.NOT_EVALUATED
                ? TRUST_EFFECT.LOW_TRUST_LEVEL
                : baseTrustFactor.effect,
    }

    switch (trustFactor.source.type) {
        case TrustSourceType.BANYAN:
            return {
                ...state,
                banyanTrustFactors: moveFactorToUsed(trustFactor, state.banyanTrustFactors),
            }

        case TrustSourceType.CROWD_STRIKE:
        case TrustSourceType.SENTINEL_ONE:
        case TrustSourceType.WORKSPACE_ONE:
            const integrationTrustFactors = state.integrationTrustFactors[trustFactor.source.type]

            const updatedIntegrationTrustFactors: IntegrationTrustFactors = {
                ...state.integrationTrustFactors,
                [trustFactor.source.type]: moveToSelectedIntegrationTrustFactors(
                    integrationTrustFactors,
                    trustFactor,
                    trustFactor.source
                ),
            }

            return { ...state, integrationTrustFactors: updatedIntegrationTrustFactors }
    }
}

function moveToSelectedIntegrationTrustFactors(
    { selected, unselected }: TrustFactorsPerIntegrationPartner,
    trustFactor: TrustFactor,
    integration: IntegrationTrustSource
): TrustFactorsPerIntegrationPartner {
    if (!selected) {
        const { [integration.integrationId]: integrationTrustFactors, ...restUnselected } =
            unselected

        if (!integrationTrustFactors) {
            console.warn("There's a Trust Factor without an accompanying Integration.", {
                trustFactor,
            })
            return { unselected }
        }

        return {
            selected: addTrustFactorsToIntegration(
                integration,
                moveFactorToUsed(trustFactor, integrationTrustFactors.trustFactors)
            ),
            unselected: restUnselected,
        }
    }

    if (selected.integrationId !== integration.integrationId) {
        console.warn(
            "You can't have more than one Trust Integration per Partner!",
            selected,
            trustFactor
        )
        return { selected, unselected }
    }

    return {
        selected: addTrustFactorsToIntegration(
            integration,
            moveFactorToUsed(trustFactor, selected.trustFactors)
        ),
        unselected,
    }
}

function addTrustFactorsToIntegration(
    integration: IntegrationTrustSource,
    trustFactors: TrustFactors
): TrustFactorsPerIntegration {
    return {
        integrationPartner: integration.type,
        integrationId: integration.integrationId,
        integrationName: integration.name,
        description: integration.description,
        trustFactors,
    }
}

function moveFactorToUsed(trustFactor: TrustFactor, trustFactors: TrustFactors): TrustFactors {
    return {
        used: [...trustFactors.used, trustFactor],
        unused: trustFactors.unused.filter(({ id }) => trustFactor.id !== id),
    }
}

function editUsedFactor<TrustProfile extends NewTrustProfileDetail>(
    state: State<TrustProfile>,
    trustFactor: TrustFactor
): State<TrustProfile> {
    switch (trustFactor.source.type) {
        case TrustSourceType.BANYAN:
            return {
                ...state,
                banyanTrustFactors: {
                    ...state.banyanTrustFactors,
                    used: state.banyanTrustFactors.used.map((factor) =>
                        factor.id === trustFactor.id ? trustFactor : factor
                    ),
                },
            }

        case TrustSourceType.CROWD_STRIKE:
        case TrustSourceType.SENTINEL_ONE:
        case TrustSourceType.WORKSPACE_ONE:
            const { selected, unselected } = state.integrationTrustFactors[trustFactor.source.type]
            if (!selected) return state

            const integrationTrustFactors: TrustFactorsPerIntegrationPartner = {
                selected: {
                    ...selected,
                    trustFactors: {
                        ...selected.trustFactors,
                        used: selected.trustFactors.used.map((factor) =>
                            factor.id === trustFactor.id ? trustFactor : factor
                        ),
                    },
                },
                unselected,
            }

            return {
                ...state,
                integrationTrustFactors: {
                    ...state.integrationTrustFactors,
                    [trustFactor.source.type]: integrationTrustFactors,
                },
            }
    }
}

function removeFactorFromState<TrustProfile extends NewTrustProfileDetail>(
    state: State<TrustProfile>,
    trustFactor: TrustFactor
): State<TrustProfile> {
    switch (trustFactor.source.type) {
        case TrustSourceType.BANYAN:
            return {
                ...state,
                banyanTrustFactors: moveFactorToUnused(trustFactor, state.banyanTrustFactors),
            }

        case TrustSourceType.CROWD_STRIKE:
        case TrustSourceType.SENTINEL_ONE:
        case TrustSourceType.WORKSPACE_ONE:
            const { selected, unselected } = state.integrationTrustFactors[trustFactor.source.type]

            if (!selected) return state

            const updatedSelected: TrustFactorsPerIntegration = {
                ...selected,
                trustFactors: moveFactorToUnused(trustFactor, selected.trustFactors),
            }

            return {
                ...state,
                integrationTrustFactors: {
                    ...state.integrationTrustFactors,
                    [trustFactor.source.type]: moveSelectedIntegration(updatedSelected, unselected),
                },
            }
    }
}

function moveFactorToUnused(trustFactor: TrustFactor, trustFactors: TrustFactors): TrustFactors {
    return {
        used: trustFactors.used.filter(({ id }) => trustFactor.id !== id),
        unused: [...trustFactors.unused, trustFactor],
    }
}

function moveSelectedIntegration(
    selected: TrustFactorsPerIntegration,
    unselected: Partial<Record<string, TrustFactorsPerIntegration>>
): TrustFactorsPerIntegrationPartner {
    if (selected.trustFactors.used.length > 0) {
        return { selected, unselected }
    }
    return { unselected: { ...unselected, [selected.integrationId]: selected } }
}

enum Error {
    NO_FILES_TO_CHECK = "NO_FILES_TO_CHECK",
    NO_PROCESS_NAME = "NO_PROCESS_NAME",
    NO_PLIST = "NO_PLIST",
    NO_REGISTRY_KEY = "NO_REGISTRY_KEY",
    NO_OS_VERSION = "NO_OS_VERSION",
    NO_CHROME_BROWSER_VERSION = "NO_CHROME_BROWSER_VERSION", //We are configuring only chrome in this release. this will be updated to accomodate more browsers
}

const errorDict: Record<Error, LanguageKey> = {
    [Error.NO_FILES_TO_CHECK]: "addAFileToEnableTheFileCheckTrustFactor",
    [Error.NO_PROCESS_NAME]: "provideAtLeastOneProcessNameToEnableAnApplicationCheckForZoom",
    [Error.NO_PLIST]: "addAPropertyListToEnableThePropertyListCheckTrustFactor",
    [Error.NO_REGISTRY_KEY]: "addARegistryKeyToEnableTheRegistryCheckTrustFactor",
    [Error.NO_OS_VERSION]: "operatingSystemVersionFactorRequiresThatAllPlatformsBeConfigured",
    [Error.NO_CHROME_BROWSER_VERSION]: "chromeBrowserFactorRequiresThatAllPlatformsBeConfigured",
}

function getError<TrustProfile extends NewTrustProfileDetail>(
    state: State<TrustProfile>
): [Error, string] | null {
    return getUsedTrustFactors(state.banyanTrustFactors, state.integrationTrustFactors).reduce(
        reduceTrustFactorError,
        null
    )
}

function reduceTrustFactorError(
    error: [Error, string] | null,
    factor: TrustFactor
): [Error, string] | null {
    if (error) return error

    if (isAppCheckTrustFactor(factor)) {
        const emptyConfig = factor.config.find((config) => !hasAtLeastOneProcess(config))

        if (emptyConfig) {
            return [Error.NO_PROCESS_NAME, emptyConfig.name]
        }
    }

    if (isFileCheckTrustFactor(factor) && factor.usedFiles.length <= 0) {
        return [Error.NO_FILES_TO_CHECK, ""]
    }

    if (isPlistTrustFactor(factor) && factor.usedPlist.length <= 0) {
        return [Error.NO_PLIST, ""]
    }

    if (isRegistryCheckTrustFactor(factor) && factor.usedRegistryCheck.length <= 0) {
        return [Error.NO_REGISTRY_KEY, ""]
    }

    if (isOsVersionTrustFactor(factor) && !Object.values(factor.config).every((v) => v)) {
        return [Error.NO_OS_VERSION, ""]
    }

    if (
        isChromeBrowserVersionTrustFactor(factor) &&
        !Object.values(factor.config).every((v) => v)
    ) {
        return [Error.NO_CHROME_BROWSER_VERSION, ""]
    }

    return null
}

function hasAtLeastOneProcess(config: AppCheckConfig): boolean {
    return Object.values(config.processName).some(hasValue)
}

function hasValue(value: string): boolean {
    return value.length > 0
}
