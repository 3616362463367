// external imports
import * as React from "react"
// local imports
import { useServiceLocalization, useServiceManage, useServiceUser } from "../../../services"
import {
    FormLabel,
    FormSection,
    MultiInput,
    Input,
    KeyValueInput,
    Switch,
    ToggleButton,
} from "../../../components"
import { ServiceFormProps, useMetadata, useUpdateAttributes, useUpdateTags } from "../service-form"
import ServiceAttributesForm from "./attributes"
import {
    ExemptedPathPatternMethods,
    ExemptedPathPatternTemplates,
    ServiceAttr,
} from "../../../api/Manage.api"
import styles from "./ServiceForm.module.scss"
import CorsExemptions, { PatternWithID } from "./cors-exemptions/CorsExemptions"
import { useSetErrors, useSetShowError } from "../service-form"
import { useServiceService } from "../../../services"
import { UserOrgDetails } from "../../../api/User.api"

export default function WebServiceForm({ edit }: ServiceFormProps) {
    // add any information specific to web services
    const updateAttributes = useUpdateAttributes()
    const updateTags = useUpdateTags()
    const localization = useServiceLocalization()
    const metaData = useMetadata()
    const setErrors = useSetErrors()
    const showErrors = useSetShowError()
    const serviceService = useServiceService()
    const userService = useServiceUser()
    const manage = useServiceManage()

    // parse the spec to initialize the advanced settings
    const spec = edit?.spec?.spec

    // group the patterns according to their template
    const { corsExemptions, cidrExemption, pathExemption } = serviceService.groupPatterns(
        spec?.http_settings.exempted_paths?.patterns || []
    )

    const oidcSetting = spec?.http_settings.oidc_settings
    const [corsPatterns, _setCorsPatterns] = React.useState<PatternWithID[]>(corsExemptions || [])
    // we have to memoize the set so we don't get infinite loops
    const setCorsPatterns = React.useMemo(() => _setCorsPatterns, [])

    const defaultCustomHeaders = Object.entries(spec?.http_settings.headers || {})
    const defaultSuppressDTVFlag: boolean =
        spec?.http_settings.oidc_settings?.suppress_device_trust_verification || false
    const defaultDisablePrivateDnsFlag: boolean = spec?.attributes.disable_private_dns || false

    const [oidcPaths, setOidcPaths] = React.useState<string[]>(pathExemption?.paths || [])
    const [oidcCidrs, setOidcCidrs] = React.useState<string[]>(cidrExemption?.source_cidrs || [])
    const [customHeaders, setCustomHeaders] = React.useState<[string, string][]>(
        defaultCustomHeaders || []
    )

    const [asyncAuthState, setAsyncAuthState] = React.useState<boolean>(false)

    const [suppressDTVFlag, setSuppressDTVFlag] = React.useState<boolean>(
        defaultSuppressDTVFlag || false
    )

    const [disablePrivateDnsFlag, setDisablePrivateDnsFlag] = React.useState<boolean>(
        defaultDisablePrivateDnsFlag || false
    )

    const [serviceAccountAccess, setServiceAccountAccess] = React.useState(
        Boolean(
            spec?.http_settings.token_loc &&
                (spec?.http_settings.token_loc.authorization_header ||
                    spec?.http_settings.token_loc.custom_header ||
                    spec?.http_settings.token_loc.query_param)
        )
    )

    // pull the initial state for service account access
    let initialLocation: "auth" | "custom" | "query" = "auth"
    let initialLocationValue: string = ""
    if (spec?.http_settings.token_loc?.custom_header) {
        initialLocation = "custom"
        initialLocationValue = spec.http_settings.token_loc.custom_header
    } else if (spec?.http_settings.token_loc?.query_param) {
        initialLocation = "query"
        initialLocationValue = spec.http_settings.token_loc.query_param
    }
    const [apiKeyLocation, setApiKeyLocation] = React.useState<"query" | "auth" | "custom">(
        initialLocation
    )
    const [locationValue, setLocationValue] = React.useState(initialLocationValue)

    // add any information we need for all web services
    const { domain } = metaData.tags
    React.useEffect(() => {
        userService.getUserOrgDetails().then(
            (details: UserOrgDetails) => {
                if (details.AsyncAuthEnabled) {
                    setAsyncAuthState(true)
                }
            },
            () => {
                setAsyncAuthState(false)
            }
        )

        updateAttributes((attr) => {
            const newAttr: ServiceAttr = {
                ...attr,
                attributes: {
                    ...attr.attributes,
                    disable_private_dns: disablePrivateDnsFlag,
                },
                http_settings: {
                    ...attr.http_settings,
                    enabled: true,
                    oidc_settings: {
                        ...attr.http_settings?.oidc_settings,
                        enabled: true,
                        service_domain_name: "https://" + domain,
                        suppress_device_trust_verification: suppressDTVFlag,
                    },
                    headers: {},
                    exempted_paths: {
                        enabled: false,
                        paths: [],
                        patterns: [],
                    },
                },
            }

            // if we are enabling service access
            if (serviceAccountAccess) {
                // set the parameter with all default values
                newAttr.http_settings.token_loc = {
                    authorization_header: false,
                    query_param: "",
                    custom_header: "",
                }

                if (apiKeyLocation === "auth") {
                    newAttr.http_settings.token_loc!.authorization_header = true
                } else if (apiKeyLocation === "query") {
                    newAttr.http_settings.token_loc!.query_param = locationValue
                } else if (apiKeyLocation === "custom") {
                    newAttr.http_settings.token_loc!.custom_header = locationValue
                }
            } else {
                delete newAttr.http_settings.token_loc
            }

            // we should only add the oidc exemptions if we have all of the necessary info
            if (oidcPaths.length > 0 || oidcCidrs.length > 0) {
                // make sure the settings blocks are enabled
                newAttr.http_settings.enabled = true
                newAttr.http_settings.exempted_paths!.enabled = true

                if (oidcPaths.length > 0) {
                    // create a single exemption for the oidc info
                    newAttr.http_settings.exempted_paths!.patterns!.push({
                        paths: oidcPaths,
                        source_cidrs: [],
                        mandatory_headers: ["*"],
                        methods: [ExemptedPathPatternMethods.ALL],
                        template: ExemptedPathPatternTemplates.PATH,
                        hosts: [{ origin_header: ["*"], target: ["*"] }],
                    })
                }

                if (oidcCidrs.length > 0) {
                    // create a single exemption for the oidc info
                    newAttr.http_settings.exempted_paths!.patterns!.push({
                        paths: ["/*"],
                        source_cidrs: oidcCidrs,
                        mandatory_headers: ["*"],
                        methods: [ExemptedPathPatternMethods.ALL],
                        template: ExemptedPathPatternTemplates.CIDR,
                        hosts: [{ origin_header: ["*"], target: ["*"] }],
                    })
                }
            }

            // if we have cors patterns, add them to the list
            if (corsPatterns.length > 0) {
                // make sure the settings blocks are enabled
                newAttr.http_settings.enabled = true
                newAttr.http_settings.exempted_paths!.enabled = true

                // every pattern we are adding needs a CORS counterpart
                newAttr.http_settings.exempted_paths!.patterns!.push(
                    ...corsPatterns.flatMap((pattern) => [
                        // remove the id field from the payload (its just there so we have a key for the list)
                        Object.fromEntries(Object.entries(pattern).filter(([key]) => key !== "id")),
                        {
                            hosts: pattern.hosts,
                            methods: [ExemptedPathPatternMethods.OPTIONS],
                            mandatory_headers: [
                                "Access-Control-Request-Method",
                                "Access-Control-Request-Headers",
                            ],
                            paths: ["/*"],
                        },
                    ])
                )
            }

            // if we have custom headers to assign
            if (customHeaders.length > 0) {
                newAttr.http_settings.headers = Object.fromEntries(
                    customHeaders.filter((header) => header[0] && header[1])
                )
            }

            return newAttr
        })

        // if there are cors patterns, we need to validate them
        if (corsPatterns.length > 0) {
            // look at the cors patterns for one with a missing domain or methods
            const missingCorsDomain = !!corsPatterns.find(
                (pattern) =>
                    !pattern.hosts ||
                    pattern.hosts.length === 0 ||
                    pattern.hosts.find((host) => host.origin_header.length === 0)
            )
            const missingCorsMethods = !!corsPatterns.find((pattern) => !pattern.methods?.length)

            // update the form error state
            setErrors((errs) => ({
                ...errs,
                CORS_DOMAIN:
                    missingCorsDomain &&
                    localization.getString("aCorsExemptionMustHaveAValidOriginDomain"),
                CORS_METHODS:
                    missingCorsMethods &&
                    localization.getString("aCorsExemptionMustSpecifyMethods"),
            }))

            showErrors((old) => ({
                ...old,
                CORS_DOMAIN: missingCorsDomain,
                CORS_METHODS: missingCorsMethods,
            }))
        }
        // there are no cors patterns, make sure the error state is clear
        else {
            setErrors((errs) => ({
                ...errs,
                CORS_DOMAIN: false,
                CORS_METHODS: false,
            }))
        }

        updateTags((tags) => ({
            ...tags,
        }))
    }, [
        domain,
        updateAttributes,
        corsPatterns,
        oidcPaths,
        oidcCidrs,
        customHeaders,
        updateTags,
        localization,
        setErrors,
        showErrors,
        suppressDTVFlag,
        disablePrivateDnsFlag,
        userService,
        serviceAccountAccess,
        apiKeyLocation,
        locationValue,
    ])

    return (
        <>
            <ServiceAttributesForm
                edit={edit}
                defaultPort="443"
                hideDnsOverride
                hideBackend
                showTls
                allowLetsEncrypt
            />
            <FormSection
                title={localization.getString("exemptionsOptional")}
                collapsible
                startOpen={corsPatterns.length > 0 || oidcPaths.length > 0 || oidcCidrs.length > 0}
            >
                <FormLabel
                    title={localization.getString("crossOriginResourceSharing")}
                    className={styles.advancedSection}
                >
                    <CorsExemptions value={corsPatterns} onChange={setCorsPatterns} edit={edit} />
                </FormLabel>
                <FormLabel
                    title={localization.getString("exemptedPathsOptional")}
                    htmlFor="exemptedPaths"
                >
                    <MultiInput
                        initialValues={oidcPaths}
                        onChange={setOidcPaths}
                        placeholder={localization.getString("exemptedPaths")}
                    />
                </FormLabel>
                <FormLabel
                    title={localization.getString("exemptedCidrsOptional")}
                    htmlFor="exemptedCidrs"
                >
                    <MultiInput
                        initialValues={oidcCidrs}
                        onChange={setOidcCidrs}
                        placeholder={localization.getString("exemptedCidrs")}
                        pattern={manage.CIDR_REGEX}
                    />
                </FormLabel>
            </FormSection>
            <FormSection
                title={localization.getString("advancedConfigurationOptional")}
                collapsible
                startOpen={
                    customHeaders.length > 0 ||
                    suppressDTVFlag ||
                    disablePrivateDnsFlag ||
                    serviceAccountAccess
                }
            >
                <FormLabel
                    title={localization.getString("customHTTPHeadersToSendToBackendOptional")}
                    htmlFor="customHTTPHeadersToSendToBackendOptional"
                >
                    <KeyValueInput
                        value={customHeaders}
                        onChange={setCustomHeaders}
                        validateKey={(val, entries) => {
                            return (
                                entries.find(
                                    ([key], i) => i !== customHeaders.length - 1 && key === val
                                ) && localization.getString("valuesMustBeUnique")
                            )
                        }}
                    />
                </FormLabel>
                <FormLabel
                    title={localization.getString("enableServiceAccountAccess")}
                    htmlFor="enableServiceAccountAccess"
                    tooltip={localization.getString("oldServiceAccountDescription")}
                >
                    <Switch
                        className={styles.switch}
                        disabled={false}
                        value={serviceAccountAccess}
                        onChange={setServiceAccountAccess}
                    />
                </FormLabel>
                {serviceAccountAccess && (
                    <>
                        <FormLabel
                            title={localization.getString(
                                "howAreTheServiceAccountCredentialsBeingSent"
                            )}
                            htmlFor="serviceAccountCredLocation"
                        >
                            <ToggleButton
                                className={styles.options}
                                items={[
                                    {
                                        label: localization.getString("authorizationHeader"),
                                        selected: apiKeyLocation === "auth",
                                        onClick: () => setApiKeyLocation("auth"),
                                    },
                                    {
                                        label: localization.getString("customHeader"),
                                        selected: apiKeyLocation === "custom",
                                        onClick: () => setApiKeyLocation("custom"),
                                    },
                                    {
                                        label: localization.getString("queryParameter"),
                                        selected: apiKeyLocation === "query",
                                        onClick: () => setApiKeyLocation("query"),
                                    },
                                ]}
                            />
                        </FormLabel>
                        {apiKeyLocation === "query" && (
                            <FormLabel
                                title={localization.getString("parameterName")}
                                htmlFor="parameter"
                            >
                                <Input
                                    value={locationValue}
                                    onChange={(e) => setLocationValue(e.target.value)}
                                    className={styles.input}
                                    required
                                />
                            </FormLabel>
                        )}
                        {apiKeyLocation === "custom" && (
                            <FormLabel
                                title={localization.getString("headerName")}
                                htmlFor="headerName"
                            >
                                <Input
                                    value={locationValue}
                                    onChange={(e) => setLocationValue(e.target.value)}
                                    className={styles.input}
                                    required
                                />
                            </FormLabel>
                        )}
                    </>
                )}
                {asyncAuthState && (
                    <FormLabel
                        inline={false}
                        title={localization.getString("suppressDeviceTrustVerification")}
                        tooltip={localization.getString(
                            "suppressDeviceTrustVerificationDescription"
                        )}
                        htmlFor="suppressDeviceTrustVerification"
                    >
                        <Switch
                            className={styles.switch}
                            disabled={false}
                            value={suppressDTVFlag}
                            onChange={setSuppressDTVFlag}
                        />
                    </FormLabel>
                )}
                <FormLabel
                    inline={false}
                    title={localization.getString("disablePrivateDns")}
                    tooltip={localization.getString("disablePrivateDnsDescription")}
                    htmlFor="disablePrivateDns"
                >
                    <Switch
                        className={styles.switch}
                        disabled={false}
                        value={disablePrivateDnsFlag}
                        onChange={setDisablePrivateDnsFlag}
                    />
                </FormLabel>
            </FormSection>
        </>
    )
}
