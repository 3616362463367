import React from "react"
import {
    ServiceTunnelApi,
    ServiceTunnelClusterReq,
    ServiceTunnelObjRes,
    ServiceTunnelReq,
    ServiceTunnelSpecReq,
} from "../api/ServiceTunnel.api"
import { Singleton } from "../decorators/Singleton.decorator"
import { ServiceManageStatus } from "./Manage.service"

@Singleton("ServiceTunnelServiceV3")
class ServiceTunnelService {
    public getServiceTunnels(): Promise<ServiceTunnel[]> {
        return this.serviceTunnelApi.getServiceTunnels().then((res) => {
            if (res.service_tunnels) {
                return res.service_tunnels.map((s) => this.mapServiceTunnelObjResToServiceTunnel(s))
            } else {
                return []
            }
        })
    }

    private serviceTunnelApi: ServiceTunnelApi = new ServiceTunnelApi()

    private mapServiceTunnelObjResToServiceTunnel(obj: ServiceTunnelObjRes): ServiceTunnel {
        let clusterATs: ClusterAccessTier[] = []
        let icon: string = ""
        let descriptionLink: string = ""
        try {
            const data: ServiceTunnelReq = JSON.parse(obj.spec)
            clusterATs = this.mapServiceTunnelReqSpecToClusterAccessTiers(data.spec)
            icon = data?.metadata?.tags?.icon || ""
            descriptionLink = data?.metadata?.tags?.description_link
        } catch {} // unparsable, ignore

        let status: ServiceManageStatus
        if (!obj.enabled) {
            status = ServiceManageStatus.INACTIVE
        } else if (obj.AttachedPolicy && obj.AttachedPolicy.policy_id) {
            status = obj.AttachedPolicy.policy_status
                ? ServiceManageStatus.POLICY_ENFORCING
                : ServiceManageStatus.POLICY_PERMISSIVE
        } else {
            status = ServiceManageStatus.NO_POLICY
        }

        return {
            id: obj.id,
            name: obj.name,
            friendlyName: obj.friendly_name,
            description: obj.description,
            descriptionLink: descriptionLink,
            icon: icon,
            createdAt: obj.created_at,
            createdBy: obj.created_by,
            lastUpdatedAt: obj.updated_at,
            lastUpdatedBy: obj.updated_by,
            enabled: obj.enabled,
            clusterAccessTiers: clusterATs,
            status: status,
            policyId: obj.AttachedPolicy ? obj.AttachedPolicy.policy_id : "",
            policyName: obj.AttachedPolicy ? obj.AttachedPolicy.policy_name : "",
            policyEnabled: obj.AttachedPolicy ? obj.AttachedPolicy.policy_status : false,
            policyAttachedOn: obj.AttachedPolicy ? obj.AttachedPolicy.attached_at : 0,
        }
    }

    private mapServiceTunnelReqSpecToClusterAccessTiers(
        spec: ServiceTunnelSpecReq
    ): ClusterAccessTier[] {
        if (spec.peer_access_tiers && spec.peer_access_tiers.length > 0) {
            return spec.peer_access_tiers.map((p: ServiceTunnelClusterReq): ClusterAccessTier => {
                return {
                    clusterName: p.cluster,
                    accessTierNames: p.access_tiers,
                    connectorNames: p.connectors,
                }
            })
        }
        return []
    }
}

interface ServiceTunnel {
    id: string
    name: string
    friendlyName: string
    description: string
    descriptionLink: string
    icon: string
    createdAt?: number
    createdBy?: string
    lastUpdatedAt?: number
    lastUpdatedBy?: string
    enabled: boolean
    clusterAccessTiers: ClusterAccessTier[]
    status: ServiceManageStatus
    policyId: string
    policyName: string
    policyEnabled: boolean
    policyAttachedOn?: number
}

export interface ClusterAccessTier {
    clusterName: string
    accessTierNames: string[]
    connectorNames?: string[]
}

export function useServiceTunnelService(): ServiceTunnelService {
    return React.useMemo(() => new ServiceTunnelService(), [])
}
