import * as React from "react"

import {
    Button,
    ButtonElement,
    ButtonType,
} from "../../../../../components/button/Button.component"
import { ModalRef } from "../../../../../pre-v3/services/Modal.service"
import { useServiceLocalization } from "../../../../../pre-v3/services/localization/Localization.service"
import { SelectItem } from "../../../../../pre-v3/utils/SelectValue.util"
import { encodeID } from "../../../../../pre-v3/utils/Url.util"
import { ROUTE } from "../../../../../routes"
import { Container } from "../../../../components/container/Container.component"
import { Form } from "../../../../components/form/Form.component"
import { ErrorBanners, InfoBanner } from "../../../../components/banner/Banner.component"
import { FormButtons } from "../../../../components/form/form-buttons/FormButtons.component"
import { SelectInput } from "../../../../components/select-input/SelectInput.component"
import {
    useGetServiceTunnels,
    useUpdateServiceTunnel,
} from "../../../../services/PublicResource.service"
import { FormColumn } from "../../../../components/form/FormColumn.component"
import { ToggleButton } from "../../../../components/toggle-button/ToggleButton"
import { ServiceTunnelAndStateGrid } from "./ServiceTunnelAndStateGrid.component"
import { Loader } from "../../../../components/loader/Loader.component"
import { AppText } from "../../../../components/app-text/AppText.component"
import styles from "./PublicResourceOverview.module.scss"

interface Props {
    modalRef: ModalRef
    appId: string
}

export function ServiceTunnelModal({ modalRef, appId }: Props) {
    const localization = useServiceLocalization()

    const {
        isLoading: isServiceTunnelsLoading,
        error: serviceTunnelListError,
        data: serviceTunnels,
        refetch: refetchTunnels,
    } = useGetServiceTunnels()

    const [selectedTunnel, setSelectedTunnel] = React.useState<string>("")

    const tunnelOptions: SelectItem[] = React.useMemo(() => {
        if (!serviceTunnels) return []
        return serviceTunnels.map((s) => ({
            displayName: s.name,
            value: s.id,
        }))
    }, [serviceTunnels])

    const activeConnectionCount: number = React.useMemo(() => {
        if (!serviceTunnels) return 0
        const filteredData = serviceTunnels
            .map((accessTier) => {
                return accessTier
            })
            .filter((d) => d.id === selectedTunnel)
        return filteredData.length > 0 ? filteredData[0].activeConnectionsCount : 0
    }, [serviceTunnels, selectedTunnel])

    const {
        mutateAsync: onUpdateServiceTunnel,
        isLoading: isServiceTunnelUpdating,
        error: updateServiceTunnelError,
    } = useUpdateServiceTunnel()

    const serviceTunnelBySelectedId = React.useMemo(() => {
        return serviceTunnels?.find((tunnel) => tunnel.id === selectedTunnel)
    }, [selectedTunnel])

    const serviceTunnelListContainingSelectedApp: ServiceTunnelGrid[] = React.useMemo(() => {
        if (!serviceTunnels) return []
        else {
            const filteredData = serviceTunnels.filter(
                (d) => d.applicationExclude.includes(appId) || d.applicationInclude.includes(appId)
            )
            return filteredData.map((f) => {
                return {
                    id: f.id,
                    tunnelName: f.name,
                    state: f.applicationExclude.includes(appId)
                        ? ServiceTunnelState.EXCLUDE
                        : ServiceTunnelState.INCLUDE,
                }
            })
        }
    }, [serviceTunnels, appId])

    const alreadyAttachedServiceTunnel = serviceTunnelListContainingSelectedApp.find(
        (t) => t.id === selectedTunnel
    )
    const [state, setState] = React.useState<ServiceTunnelState | undefined>(undefined)

    React.useEffect(() => {
        setState(alreadyAttachedServiceTunnel?.state || undefined)
    }, [alreadyAttachedServiceTunnel])

    const submitForm = async (event: React.SyntheticEvent) => {
        event.preventDefault()

        if (serviceTunnelBySelectedId) {
            let includedApplications = serviceTunnelBySelectedId?.applicationInclude || []
            let excludedApplications = serviceTunnelBySelectedId?.applicationExclude || []

            if (appId) {
                if (state === ServiceTunnelState.EXCLUDE) {
                    if (includedApplications.includes(appId)) {
                        includedApplications = includedApplications.filter(
                            (element) => element !== appId
                        )
                    }
                    if (!excludedApplications.includes(appId)) {
                        excludedApplications.push(appId)
                    }
                } else if (state === ServiceTunnelState.INCLUDE) {
                    if (excludedApplications.includes(appId)) {
                        excludedApplications = excludedApplications.filter(
                            (element) => element !== appId
                        )
                    }

                    if (!includedApplications.includes(appId)) {
                        includedApplications.push(appId)
                    }
                } else {
                    if (
                        excludedApplications.includes(appId) ||
                        includedApplications.includes(appId)
                    ) {
                        excludedApplications = excludedApplications.filter(
                            (element) => element !== appId
                        )
                        includedApplications = includedApplications.filter(
                            (element) => element !== appId
                        )
                    }
                }
            }

            serviceTunnelBySelectedId.applicationInclude = includedApplications
            serviceTunnelBySelectedId.applicationExclude = excludedApplications

            await onUpdateServiceTunnel(serviceTunnelBySelectedId).then(() => {
                modalRef.close(refetchTunnels)
            })
        } else {
            modalRef.cancel("")
        }
    }

    const errors: React.ReactNode[] = [
        typeof updateServiceTunnelError === "string" && updateServiceTunnelError,
        typeof serviceTunnelListError === "string" && serviceTunnelListError,
    ]

    const tunnelId: string = serviceTunnelBySelectedId?.id || ""
    const path = window.location.origin + ROUTE.DEVICES
    const url = `${path}?fm_activeServiceTunnel=${encodeID(tunnelId)}`

    return (
        <Container>
            <Loader isLoading={isServiceTunnelsLoading} medium center>
                <Form onSubmit={submitForm}>
                    <ErrorBanners errors={errors} />
                    <ServiceTunnelAndStateGrid
                        serviceTunnels={serviceTunnelListContainingSelectedApp}
                        isDataLoading={isServiceTunnelsLoading}
                    />
                    <div className={styles.serviceTunnelContainer}>
                        <FormColumn
                            label={localization.getString("selectAServiceTunnel")}
                            className={styles.selectInput}
                        >
                            <SelectInput
                                options={tunnelOptions}
                                value={selectedTunnel}
                                onChange={setSelectedTunnel}
                                disabled={serviceTunnels?.length === 0}
                            />
                        </FormColumn>
                        {selectedTunnel && (
                            <InfoBanner className={styles.infoBanner}>
                                <AppText className={styles.appText}>
                                    {localization.getPluralString(
                                        "thisServiceTunnelContainsCountActiveConnection",
                                        activeConnectionCount,
                                        url
                                    )}
                                </AppText>
                            </InfoBanner>
                        )}
                    </div>
                    {selectedTunnel && (
                        <div className={styles.serviceTunnelContainer}>
                            <FormColumn
                                label={localization.getString("serviceTunnelActions")}
                                description={localization.getString("serviceTunnelActionsDesc")}
                                className={styles.selectInput}
                            />
                            <ToggleButton
                                className={styles.toggleButton}
                                items={[
                                    {
                                        label: localization.getString("exclude"),
                                        onClick: () => {
                                            setState(ServiceTunnelState.EXCLUDE)
                                        },
                                        selected: state === ServiceTunnelState.EXCLUDE,
                                    },
                                    {
                                        label: localization.getString("include"),
                                        onClick: () => {
                                            setState(ServiceTunnelState.INCLUDE)
                                        },
                                        selected: state === ServiceTunnelState.INCLUDE,
                                    },
                                    {
                                        label: localization.getString("remove"),
                                        onClick: () => {
                                            setState(ServiceTunnelState.REMOVE)
                                        },
                                        selected: state === ServiceTunnelState.REMOVE,
                                    },
                                ]}
                            />
                        </div>
                    )}
                    <FormButtons
                        rightButtons={
                            <>
                                {serviceTunnels?.length !== 0 && (
                                    <Button
                                        asElement={ButtonElement.BUTTON}
                                        buttonType={ButtonType.SECONDARY}
                                        onClick={modalRef.cancel}
                                    >
                                        {localization.getString("cancel")}
                                    </Button>
                                )}
                                {serviceTunnels?.length === 0 ? (
                                    <Button
                                        asElement={ButtonElement.BUTTON}
                                        buttonType={ButtonType.SECONDARY}
                                        onClick={modalRef.cancel}
                                    >
                                        {localization.getString("close")}
                                    </Button>
                                ) : (
                                    <Button
                                        asElement={ButtonElement.BUTTON}
                                        buttonType={ButtonType.PRIMARY}
                                        loading={isServiceTunnelUpdating}
                                        type="submit"
                                    >
                                        {selectedTunnel
                                            ? localization.getString("save")
                                            : localization.getString("ok")}
                                    </Button>
                                )}
                            </>
                        }
                    />
                </Form>
            </Loader>
        </Container>
    )
}

enum ServiceTunnelState {
    INCLUDE = "Include",
    EXCLUDE = "Exclude",
    REMOVE = "Remove",
}

export interface ServiceTunnelGrid {
    id: string
    tunnelName: string
    state: ServiceTunnelState
}
