import React from "react"
import { Route, Switch } from "react-router-dom"

import useTitle from "../../hooks/useTitle.hook"
import { ROUTE } from "../../routes"
import { Cluster } from "../../v3/services/shared/Cluster"
import { AccessTierGroupsCreate } from "./create/AccessTierGroupsCreate.view"
import { AccessTierGroupsDetails } from "./details/AccessTierGroupsDetails.view"
import { AccessTierGroupsList } from "./list/AccessTierGroupsList.view"

interface Props {
    privateEdgeCluster: Cluster
}

export function AccessTierGroups(props: Props): JSX.Element {
    useTitle(["accessTierGroups", "network", "adminConsole"])

    return (
        <Switch>
            <Route path={ROUTE.ACCESS_TIER_GROUPS_CREATE}>
                <AccessTierGroupsCreate privateEdgeCluster={props.privateEdgeCluster} />
            </Route>
            <Route path={ROUTE.ACCESS_TIER_GROUPS_DETAILS}>
                <AccessTierGroupsDetails privateEdgeCluster={props.privateEdgeCluster} />
            </Route>
            <Route path={ROUTE.ACCESS_TIER_GROUPS} component={AccessTierGroupsList} />
        </Switch>
    )
}
