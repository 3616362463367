import { faExclamation } from "@fortawesome/pro-solid-svg-icons"
import React from "react"
import {
    Redirect,
    Route,
    Switch,
    generatePath,
    useHistory,
    useParams,
    useRouteMatch,
} from "react-router-dom"

import { LanguageKey } from "../../../../pre-v3/services/localization/languages/en-US.language"
import { useServiceLocalization } from "../../../../pre-v3/services/localization/Localization.service"
import { decodeID, encodeID } from "../../../../pre-v3/utils/Url.util"
import { ROUTE, formatRoutePath } from "../../../../routes"
import { ErrorBanner } from "../../../components/banner/Banner.component"
import { Container } from "../../../components/container/Container.component"
import { LargeMessage } from "../../../components/large-message/LargeMessage.component"
import { Link } from "../../../components/link/Link.component"
import { Loader } from "../../../components/loader/Loader.component"
import { TabBar, TabProps } from "../../../components/tab-bar/TabBar.component"
import {
    AddressedWith,
    Network,
    NetworkType,
    useGetPrivateResourceById,
} from "../../../services/PrivateResource.service"
import { AccessActivityPanel } from "./AccessActivityPanel.view"
import { NetworkPanel } from "./NetworkPanel.view"
import styles from "./PrivateResourceOverview.module.scss"
import { ResourceIpPanel } from "./ResourceIpPanel.view"
import { PageHeading } from "../../../../components/page-heading/PageHeading.component"
import { Button, ButtonElement, ButtonType } from "../../../../components/button/Button.component"
import { IconType } from "../../../../components/icon/Icon.component"
import { Tooltip } from "../../../components/tooltip/Tooltip.component"

export function PrivateResourceOverview(): JSX.Element {
    const history = useHistory()
    const params = useParams<{ id: string }>()

    const currentTab = useCurrentTab()

    const localization = useServiceLocalization()
    const discoveryLabel = localization.getString("discovery")

    const getDiscoveryQuery = useGetPrivateResourceById(decodeID(params.id))

    const { data: privateResource, refetch } = getDiscoveryQuery

    if (getDiscoveryQuery.status === "loading") {
        return (
            <Loader
                title={localization.getString("loadingSomething", discoveryLabel)}
                center
                medium
            />
        )
    }

    if (getDiscoveryQuery.status === "error") {
        return (
            <Container className={styles.container}>
                <ErrorBanner children={String(getDiscoveryQuery.error)} />
            </Container>
        )
    }

    if (getDiscoveryQuery.data === null) {
        return (
            <LargeMessage icon={faExclamation}>
                {localization.getString("somethingNotFound", "Resource")}
            </LargeMessage>
        )
    }

    const { addressedWith } = getDiscoveryQuery.data

    const tabs = addressedWithTabsDict[addressedWith].map<TabProps<TabKey>>((tab) => ({
        id: tab,
        label: localization.getString(tabLabelDict[tab]),
        ariaControls: panelDict[tab],
    }))

    const setTab = (tab: TabKey) => history.push(generatePath(tabRouteDict[tab], params))

    const onRefresh: React.MouseEventHandler<HTMLButtonElement> = async (event) => {
        event.preventDefault()
        await refetch()
    }

    return (
        <section className={styles.sectionContainer}>
            <header className={styles.header}>
                <div className={styles.titleAndSub}>
                    <PageHeading id={Id.HEADING}>{privateResource?.address ?? ""}</PageHeading>
                    {privateResource ? (
                        <NetworkLink network={privateResource.network} />
                    ) : (
                        <React.Fragment />
                    )}
                </div>
                <Tooltip title={localization.getString("refresh")}>
                    <Button
                        icon={IconType.REDO}
                        onClick={onRefresh}
                        asElement={ButtonElement.BUTTON}
                        buttonType={ButtonType.SECONDARY}
                    />
                </Tooltip>
            </header>
            <section className={styles.container}>
                <TabBar tabs={tabs} selectedTabId={currentTab} onChange={setTab} />
                <Switch>
                    {addressedWith === AddressedWith.IP && (
                        <Route path={ROUTE.DISCOVERY_NETWORK}>
                            <NetworkPanel
                                id={panelDict.network}
                                privateResource={getDiscoveryQuery.data}
                            />
                        </Route>
                    )}
                    <Route path={ROUTE.DISCOVERY_ACCESS_ACTIVITY}>
                        <AccessActivityPanel
                            id={panelDict.accessActivity}
                            privateResource={getDiscoveryQuery.data}
                        />
                    </Route>
                    {addressedWith === AddressedWith.FQDN && (
                        <Route path={ROUTE.DISCOVERY_IPS}>
                            <ResourceIpPanel
                                id={panelDict.resourceIps}
                                privateResource={getDiscoveryQuery.data}
                            />
                        </Route>
                    )}
                    <Redirect to={tabRouteDict[defaultTabPerAddressedWithDict[addressedWith]]} />
                </Switch>
            </section>
        </section>
    )
}

enum TabKey {
    NETWORK = "network",
    ACCESS_ACTIVITY = "accessActivity",
    RESOURCE_IPS = "resourceIps",
}

const addressedWithTabsDict: Record<AddressedWith, TabKey[]> = {
    [AddressedWith.IP]: [TabKey.NETWORK, TabKey.ACCESS_ACTIVITY],
    [AddressedWith.FQDN]: [TabKey.RESOURCE_IPS, TabKey.ACCESS_ACTIVITY],
}

const defaultTabPerAddressedWithDict: Record<AddressedWith, TabKey> = {
    [AddressedWith.IP]: TabKey.NETWORK,
    [AddressedWith.FQDN]: TabKey.RESOURCE_IPS,
}

function useCurrentTab(): TabKey {
    const accessActivityMatch = useRouteMatch(ROUTE.DISCOVERY_ACCESS_ACTIVITY)
    const resourceIpsMatch = useRouteMatch(ROUTE.DISCOVERY_IPS)

    if (accessActivityMatch) return TabKey.ACCESS_ACTIVITY
    if (resourceIpsMatch) return TabKey.RESOURCE_IPS

    return TabKey.NETWORK
}

const tabLabelDict: Record<TabKey, LanguageKey> = {
    [TabKey.NETWORK]: "network",
    [TabKey.ACCESS_ACTIVITY]: "accessActivity",
    [TabKey.RESOURCE_IPS]: "resourceIps",
}

const tabRouteDict: Record<TabKey, ROUTE> = {
    [TabKey.NETWORK]: ROUTE.DISCOVERY_NETWORK,
    [TabKey.ACCESS_ACTIVITY]: ROUTE.DISCOVERY_ACCESS_ACTIVITY,
    [TabKey.RESOURCE_IPS]: ROUTE.DISCOVERY_IPS,
}

const panelDict: Record<TabKey, string> = {
    [TabKey.NETWORK]: "networkPanel",
    [TabKey.ACCESS_ACTIVITY]: "accessActivityPanel",
    [TabKey.RESOURCE_IPS]: "resourceIpsPanel",
}

interface NetworkLinkProps {
    network: Network
}

function NetworkLink(props: NetworkLinkProps): JSX.Element {
    const localization = useServiceLocalization()

    const route = formatRoutePath(getNetworkRoute(props.network), {
        id: encodeID(props.network.id),
    })

    return (
        <div className={styles.networkContainer}>
            <span className={styles.network}>{localization.getString("network")}</span>
            <Link to={route}>{props.network.name}</Link>
        </div>
    )
}

function getNetworkRoute(network: Network): ROUTE {
    switch (network.type) {
        case NetworkType.AccessTier:
            return ROUTE.ACCESS_TIERS_DETAILS

        case NetworkType.Connector:
            return ROUTE.CONNECTORS_DETAILS
    }
}

enum Id {
    HEADING = "heading",
}
