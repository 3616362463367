import classNames from "classnames"
import React, { FormEvent } from "react"

import {
    ButtonElement,
    Button,
    ButtonType,
} from "../../../../../components/button/Button.component"
import { useServiceLocalization } from "../../../../../pre-v3/services/localization/Localization.service"
import { FormUtil } from "../../../../../pre-v3/utils/Form.util"
import { SelectItem } from "../../../../../pre-v3/utils/SelectValue.util"
import { AppText } from "../../../../components/app-text/AppText.component"
import { ErrorBanners } from "../../../../components/banner/Banner.component"
import { FormButtons } from "../../../../components/form/form-buttons/FormButtons.component"
import { Form } from "../../../../components/form/Form.component"
import { FormGroup } from "../../../../components/form/FormGroup.component"
import { FormRow } from "../../../../components/form/FormRow.component"
import { Input } from "../../../../components/input/Input.component"
import { SelectWithInput } from "../../../../components/select-with-text-input/SelectWithInput.component"
import {
    maxFilePathLengthDict,
    Platform,
    platformIconDict,
    platformLabelDict,
    prefixOptionsDict,
    validFilePathDict,
} from "../../../../services/shared/Platform"
import { NewPlist, PlistConfiguration } from "../../../../services/TrustFactor.service"
import styles from "./PlistForm.module.scss"

interface PlistFormProps<Plist extends NewPlist> {
    id?: string
    defaultValue?: Plist
    onSubmit?: (plist: NewPlist) => void
    cancelHref?: string
    submitting?: boolean
    hideActions?: boolean
    errors?: React.ReactNode[]
    canAccessPListCheckFactor?: boolean
}

export function PlistForm<Plist extends NewPlist>(props: PlistFormProps<Plist>) {
    const { hideActions, defaultValue, errors, cancelHref, submitting, id } = props
    const localization = useServiceLocalization()

    function onSubmit(event: FormEvent<HTMLFormElement>): void {
        event.preventDefault()
        event.stopPropagation()

        const name: string = FormUtil.getFieldValue(event, "name")

        props.onSubmit?.({
            name,
            configuration: getPlistConfigurationFromForm(event),
        })
    }

    function getPlistConfigurationFromForm(event: FormEvent<HTMLFormElement>): PlistConfiguration {
        const prefix = FormUtil.getFieldValue(event, `${platform}-file-selection`)
        const path = FormUtil.getFieldValue(event, `${platform}-file-value`)
        const key = FormUtil.getFieldValue(event, `${platform}-key`)
        const value = FormUtil.getFieldValue(event, `${platform}-value`)

        return {
            filePath: {
                prefix: prefix,
                path: path,
            },
            key,
            value,
        }
    }

    return (
        <Form id={id} onSubmit={onSubmit}>
            <AppText ls="aPropertyListFileIsUsedToConfigureSystemAndApplicationsSettings" />
            <FormRow
                label={localization.getString("factorName")}
                htmlFor="name"
                description={localization.getString("theFactorNameWillBeDisplayedToEndusers")}
            >
                <Input
                    id="name"
                    name="name"
                    defaultValue={defaultValue?.name}
                    placeholder={localization.getString("propertyListNamePlaceholder")}
                    required
                    disabled={!props.canAccessPListCheckFactor}
                />
            </FormRow>
            <PlistConfigurationInput
                defaultValue={defaultValue?.configuration}
                canAccessPListCheckFactor={props.canAccessPListCheckFactor}
            />
            {props.canAccessPListCheckFactor && !hideActions && (
                <FormButtons
                    rightButtons={
                        <>
                            {cancelHref && (
                                <Button
                                    asElement={ButtonElement.LINK}
                                    to={cancelHref}
                                    buttonType={ButtonType.SECONDARY}
                                >
                                    {localization.getString("cancel")}
                                </Button>
                            )}
                            <Button
                                asElement={ButtonElement.BUTTON}
                                buttonType={ButtonType.PRIMARY}
                                loading={submitting}
                                type="submit"
                            >
                                {localization.getString("save")}
                            </Button>
                        </>
                    }
                >
                    {errors && <ErrorBanners errors={errors} />}
                </FormButtons>
            )}
        </Form>
    )
}

interface PlistConfigurationInputProps {
    defaultValue?: PlistConfiguration
    canAccessPListCheckFactor?: boolean
}

const platform = Platform.MACOS

const options: SelectItem[] = prefixOptionsDict[platform]

function PlistConfigurationInput(props: PlistConfigurationInputProps) {
    const { defaultValue } = props

    const localization = useServiceLocalization()

    const platformLabel = localization.getString(platformLabelDict[platform])
    const filePathLabel = localization.getString("filePath")

    return (
        <FormGroup label={platformLabel} icon={platformIconDict[platform]}>
            <FormRow
                label={filePathLabel}
                description={localization.getString("filePathDescriptionUnix")}
                childrenClassName={styles.doubleFlex}
            >
                <SelectWithInput
                    options={options}
                    name={`${platform}-file`}
                    required
                    placeholder={localization.getString("propertyListPathPlaceholder")}
                    pattern={validFilePathDict[platform].source}
                    maxLength={maxFilePathLengthDict[platform]}
                    defaultValue={{
                        value: defaultValue?.filePath.path,
                        selection: defaultValue?.filePath.prefix,
                    }}
                    inputAriaLabel={`${platformLabel} ${filePathLabel}`}
                    disabled={!props.canAccessPListCheckFactor}
                />
            </FormRow>
            <FormRow
                label={localization.getString("KeyExpectedValue")}
                description={localization.getString(
                    "theExpectedValueIsAlwaysCheckedAsAStringForExampleATrueValueIsEvaluatedAs1"
                )}
                childrenClassName={classNames(styles.doubleFlex, styles.keyValuePairContainer)}
            >
                <Input
                    name={`${platform}-key`}
                    type="text"
                    defaultValue={defaultValue?.key}
                    placeholder={localization.getString("propertyListKeyPlaceholder")}
                    aria-label={localization.getString("propertyListKey")}
                    required
                    disabled={!props.canAccessPListCheckFactor}
                />
                <AppText className={styles.operator} ls="equalTo" />
                <Input
                    name={`${platform}-value`}
                    type="text"
                    defaultValue={defaultValue?.value}
                    placeholder={localization.getString("propertyListValuePlaceholder")}
                    aria-label={localization.getString(
                        "somethingOptional",
                        localization.getString("propertyListValue")
                    )}
                    required
                    disabled={!props.canAccessPListCheckFactor}
                />
            </FormRow>
        </FormGroup>
    )
}
