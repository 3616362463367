import classNames from "classnames"
import React, { SyntheticEvent, useRef, useState } from "react"
import { useServiceLocalization } from "../../../pre-v3/services"

import { IconType, InputWithAction } from "../input-with-action/InputWithAction.component"
import styles from "./TextWithCopy.module.scss"

export interface TextWithCopyProps {
    value: string
    className?: string
    onCopy?: () => {}
    hideInputText?: boolean
}

export function TextWithCopy(props: TextWithCopyProps) {
    const localization = useServiceLocalization()

    const [isActive, setIsActive] = useState<boolean>(false)
    const timeout = useRef<number>(0)

    function onCopy(event: SyntheticEvent): void {
        event.stopPropagation()
        navigator.clipboard.writeText(props.value)

        if (timeout.current) {
            window.clearTimeout(timeout.current)
            timeout.current = 0
        }

        setIsActive(true)
        timeout.current = window.setTimeout(() => {
            setIsActive(false)
        }, 1000)

        props.onCopy?.()
    }

    return (
        <InputWithAction
            type={props.hideInputText ? "password" : "text"}
            className={props.className}
            tooltip={localization.getString("copy")}
            disabled
            value={props.value}
            onAction={onCopy}
            actionClassName={classNames(styles.copyIcon, { [styles.active]: isActive })}
            icon={isActive ? IconType.CHECK : IconType.COPY}
        />
    )
}
