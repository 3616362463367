import classNames from "classnames/bind"
import React from "react"

import styles from "./PageHeading.module.scss"

export function PageHeading(attributes: React.HTMLAttributes<HTMLHeadingElement>): JSX.Element {
    const { className: originalClassName, children, ...otherAttributes } = attributes

    const className = classNames(styles.heading, originalClassName)

    return (
        <h2 {...otherAttributes} className={className}>
            {children}
        </h2>
    )
}
