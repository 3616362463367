import classNames from "classnames/bind"
import React from "react"

import styles from "./HorizontalLine.module.scss"

interface Props {
    className?: string
}

export function HorizontalLine(props: Props): JSX.Element {
    return <hr {...props} className={classNames(styles.line, props.className)} />
}
