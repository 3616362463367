import { GridApi, ColDef, GridReadyEvent } from "ag-grid-community"
import React, { ReactNode } from "react"
import { RouteComponentProps } from "react-router"

import { formatRoutePath, ROUTE } from "../../../../../../routes"
import { SimpleTableItem } from "../../../../../../v3/components/simple-table/SimpleTable.component"
import { LocalizationService } from "../../../../../services/localization/Localization.service"
import ActionBarService, { IconType } from "../../../../../services/ActionBar.service"
import { Bind } from "../../../../../decorators/Bind.decorator"
import { decodeID, UrlUtil } from "../../../../../utils/Url.util"
import { DateUtil } from "../../../../../utils/Date.util"
import { SettingsService, ServiceBundle } from "../../../../../services/Settings.service"
import AgGridUtil from "../../../../../utils/AgGrid.util"
import { UserService } from "../../../../../services/User.service"
import { UserOrgDetails } from "../../../../../api/User.api"
import { Tab } from "../../../../../components/tab-bar/TabBar.component"
import { SelectItem } from "../../../../../utils/SelectValue.util"
import ServiceBundleOverviewTemplate from "./ServiceBundleOverview.template"

export interface ServiceBundleOverviewRouteParams {
    id: string
}

export interface ServiceBundleProps extends RouteComponentProps<ServiceBundleOverviewRouteParams> {
    canAccessServiceBundle?: boolean
}
export class ServiceBundleOverview extends React.Component<
    ServiceBundleProps,
    ServiceBundleOverviewState
> {
    constructor(props: ServiceBundleProps) {
        super(props)
        try {
            this.bundleName = decodeID(this.props.match.params.id)
        } catch {
            this.state.loading = false
        }
    }
    public state: ServiceBundleOverviewState = { loading: true, readOnly: true, tab: 2 }

    public render(): ReactNode {
        return ServiceBundleOverviewTemplate.call(this)
    }

    public componentDidMount(): void {
        this.bundleId = this.props.match.params.id
        if (this.bundleId) {
            this.actionBarService.setItems(this.bundleName, [
                {
                    label: this.localizationService.getString("desktopAndMobile"),
                    href: "",
                },
                {
                    label: this.localizationService.getString("serviceBundles"),
                    href: UrlUtil.getParentPath(this.props.location.pathname),
                },
            ])
            this.fetchData()
        } else {
            this.setState({ loading: false })
        }

        this.userService.getUserOrgDetails().then((details: UserOrgDetails) => {
            const readOnly: boolean = !this.userService.canCreateServices(details.Profile)
            this.setState({ readOnly })

            if (!readOnly) {
                this.actionBarService.setActions([
                    {
                        icon: IconType.PEN,
                        onClick: this.onEdit,
                        tooltip: this.localizationService.getString("editServiceBundle"),
                    },
                ])
            }
        })
    }

    private localizationService: LocalizationService = new LocalizationService()
    private actionBarService: ActionBarService = new ActionBarService()
    private userService: UserService = new UserService()
    private settingsService: SettingsService = new SettingsService()
    private gridApi: GridApi

    private bundleId: string
    private bundleName: string

    private attachmentColumns: ColDef[] = [
        {
            headerName: this.localizationService.getString("serviceName"),
            field: "serviceId",
            tooltipValueGetter: AgGridUtil.linkTooltipValueGetter,
            cellRenderer: "servicesCellRenderer",
            comparator: AgGridUtil.alphaBetComparator,
        },
    ]

    private bulkConnectOptions: SelectItem[] = [
        {
            displayName: this.localizationService.getString(
                "enableOneClickConnectivityToAllServicesInBundle"
            ),
            value: true,
        },
        {
            displayName: this.localizationService.getString(
                "disableOneClickConnectivityToAllServicesInBundle"
            ),
            value: false,
        },
    ]

    private getTabs(): Tab[] {
        return [
            {
                label: this.localizationService.getString("specification"),
                value: 1,
            },
            {
                label: `${this.localizationService.getString(
                    "services"
                )} (${this.getServicesCount()})`,
                value: 2,
                active: true,
            },
        ]
    }

    private getServicesCount(): number {
        return this.state.serviceBundle ? this.state.serviceBundle.serviceIds.length : 0
    }

    @Bind
    private onTabChange(value: any): void {
        this.setState({ tab: value })
    }

    @Bind
    private onGridReady(event: GridReadyEvent): void {
        this.gridApi = event.api
        this.setServicesData()
    }

    private setServicesData(): void {
        if (this.gridApi && this.state.serviceBundle) {
            let servicesDetails = []
            for (let i = 0; i < this.state.serviceBundle.serviceIds.length; i++) {
                servicesDetails.push({
                    serviceId: this.state.serviceBundle.serviceIds[i],
                })
            }
            this.gridApi.setRowData(servicesDetails)
        }
    }

    private setDataTableItems(serviceBundleDetails: ServiceBundle | undefined): void {
        if (serviceBundleDetails?.createdAt && serviceBundleDetails.lastUpdatedAt) {
            this.setState({
                dataTableItems: [
                    {
                        label: this.localizationService.getString("description"),
                        value: serviceBundleDetails.desc,
                    },
                    {
                        label: this.localizationService.getString("createdAt"),
                        value: DateUtil.format(serviceBundleDetails.createdAt),
                    },
                    {
                        label: this.localizationService.getString("lastUpdated"),
                        value: DateUtil.format(serviceBundleDetails.lastUpdatedAt),
                    },
                ],
            })
        }
    }

    @Bind
    private onEdit(): void {
        this.props.history.push(formatRoutePath(ROUTE.BUNDLES_EDIT, { id: this.bundleId }))
    }

    @Bind
    private fetchData(): void {
        this.setState({ loading: true, error: "" })
        if (this.bundleName) {
            this.setState({ error: "", loading: true })
            this.settingsService.getServiceBundle(this.bundleName).then(
                (serviceBundle: ServiceBundle | undefined) => {
                    this.actionBarService.setTitle(this.bundleName)
                    this.setState({ loading: false, serviceBundle: serviceBundle })
                    this.setDataTableItems(serviceBundle)
                },
                (err: Error) => {
                    this.setState({ loading: false, error: err.message })
                }
            )
        } else {
            this.setState({
                loading: false,
                error: this.localizationService.getString("serviceBundleNotFound"),
            })
        }
    }
}

interface ServiceBundleOverviewState {
    loading?: boolean
    error?: string
    tab?: any
    serviceBundle?: ServiceBundle
    dataTableItems?: SimpleTableItem[]
    readOnly: boolean
    reportingStatus?: any
    success?: string
    lastUpdatedAt?: string
    showDeleteDialog?: boolean
    bulkConnect?: boolean
    serviceIds?: string[]
}
