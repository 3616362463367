import { ColDef, GridApi, GridReadyEvent } from "ag-grid-community"
import * as React from "react"
import { useHistory } from "react-router-dom"

import { Grid } from "../../../../../pre-v3/components/grid/Grid.component"
import {
    AccessActivity,
    LocalizationService,
    useGetAccessActivityForUser,
} from "../../../../../pre-v3/services"
import AgGridUtil from "../../../../../pre-v3/utils/AgGrid.util"
import { DateUtil, TimeRangeName } from "../../../../../pre-v3/utils/Date.util"
import { encodeID } from "../../../../../pre-v3/utils/Url.util"
import { ROUTE, formatRoutePath } from "../../../../../routes"
import { ErrorBanner } from "../../../../components/banner/Banner.component"
import { Link } from "../../../../components/link/Link.component"
import { SearchInput } from "../../../../components/search-input/SearchInput.component"
import styles from "./UserOverview.module.scss"

interface Props {
    email: string
}

export function UserActivity(props: Props) {
    const ls = new LocalizationService()
    const gridApi = React.useRef<GridApi>()
    const history = useHistory()
    const [search, setSearch] = React.useState<string>("")

    const { start = 0, end = Date.now() } = DateUtil.convertDeltaToTimeRange({
        name: TimeRangeName.LAST_2_WEEKS,
        delta: DateUtil.DAY * 14,
    })

    const {
        data: userActivity,
        isFetching: isDataLoading,
        error: userActivityError,
    } = useGetAccessActivityForUser(props.email, start, end)

    function onGridReady(event: GridReadyEvent): void {
        gridApi.current = event.api
        if (gridApi.current && userActivity) {
            gridApi.current.setRowData(userActivity)
        }
    }

    const activityColumns: ColDef[] = [
        {
            headerName: ls.getString("service"),
            field: "serviceName",
            tooltipValueGetter: AgGridUtil.linkTooltipValueGetter,
            cellRenderer: ServicesCellRenderer,
            comparator: AgGridUtil.alphaBetComparator,
        },
        {
            headerName: ls.getString("device"),
            field: "deviceFriendlyName",
            tooltipValueGetter: AgGridUtil.linkTooltipValueGetter,
            cellRenderer: DeviceCellRenderer,
            comparator: AgGridUtil.alphaBetComparator,
        },
        {
            headerName: ls.getString("lastAuthAccess"),
            field: "lastAuthorizedTimestamp",
            valueFormatter: AgGridUtil.dateFormatter,
        },
        {
            headerName: ls.getString("lastUnauthAccess"),
            field: "lastUnauthorizedTimestamp",
            valueFormatter: AgGridUtil.dateFormatter,
        },
    ]

    React.useEffect(() => {
        gridApi.current?.setQuickFilter?.(search)
    }, [search])

    return (
        <>
            {userActivityError && !isDataLoading && (
                <ErrorBanner>{ls.getString("failedToLoadUserActivity")}</ErrorBanner>
            )}
            {userActivity && !isDataLoading && (
                <>
                    <SearchInput
                        className={styles.search}
                        value={search}
                        onChangeValue={setSearch}
                        placeholder={ls.getString("search")}
                    />
                    <Grid
                        columnDefs={activityColumns}
                        context={{ history }}
                        onGridReady={onGridReady}
                        pagination
                    />
                </>
            )}
        </>
    )
}

function DeviceCellRenderer(props: { data: AccessActivity }) {
    if (!props.data.serial) {
        return props.data.deviceFriendlyName || "-"
    }

    return (
        <Link
            to={formatRoutePath(ROUTE.DEVICES_DETAILS, {
                serialNumber: encodeID(props.data.serial),
            })}
        >
            {props.data.deviceFriendlyName || "-"}
        </Link>
    )
}

function ServicesCellRenderer(props: { data: AccessActivity }) {
    if (!props.data.serviceId) {
        return props.data.serviceName
    }

    return (
        <Link
            to={formatRoutePath(ROUTE.PRIVATE_ACCESS_SERVICES_DETAILS, {
                id: encodeID(props.data.serviceId),
            })}
        >
            {props.data.serviceName}
        </Link>
    )
}
