import React, { ReactNode } from "react"

import { ROUTE } from "../../../../routes"
import { LanguageKey } from "../../../services/localization/languages/en-US.language"
import { LocalizationService } from "../../../services/localization/Localization.service"
import ActionBarService from "../../../services/ActionBar.service"
import OperatingSystemVersionTemplate from "./OperatingSystemVersion.template"

export class OperatingSystemVersion extends React.Component {
    public render(): ReactNode {
        return OperatingSystemVersionTemplate.call(this)
    }

    public componentDidMount(): void {
        const title = this.localizationService.getString(titleKey)

        this.actionBarService.setItems(title, [
            {
                label: this.localizationService.getString("trustScoreSettings"),
            },
            {
                label: this.localizationService.getString("trustFactors"),
                href: ROUTE.TRUST_FACTOR_SETTINGS,
            },
        ])
    }

    private localizationService: LocalizationService = new LocalizationService()
    private actionBarService: ActionBarService = new ActionBarService()
}

const titleKey: LanguageKey = "operatingSystemVersion"
