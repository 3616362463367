import { IconType } from "../icon/Icon.component"

export enum ToastVariant {
    WARNING = "warning",
    INFORMATION = "information",
    GENERIC_PRIMARY = "generic-primary",
    GENERIC_SECONDARY = "generic-secondary",
    ERROR = "error",
    SUCCESS = "success",
}

export const ToastIconsMap: Record<ToastVariant, IconType> = {
    [ToastVariant.WARNING]: IconType.TRIANGLE_EXCLAMATION,
    [ToastVariant.INFORMATION]: IconType.CIRCLE_INFO,
    [ToastVariant.GENERIC_PRIMARY]: IconType.CIRCLE_INFO,
    [ToastVariant.GENERIC_SECONDARY]: IconType.CIRCLE_INFO,
    [ToastVariant.ERROR]: IconType.CIRCLE_EXCLAMATION,
    [ToastVariant.SUCCESS]: IconType.SOLID_CHECK,
} as const
