import React from "react"
import { FormLabel, Select, ToggleButton } from "../../../../components"

export default function () {
    const label = this.props.required
        ? this.localizationService.getString("attachAPolicyRequired")
        : this.localizationService.getString("attachAPolicyOptional")
    return (
        <>
            <FormLabel title={label} htmlFor="addPolicy">
                <Select
                    noneOption={this.localizationService.getString("none")}
                    value={this.state.policyId}
                    options={this.state.policyOptions}
                    onChange={this.onPolicyChange}
                />
            </FormLabel>
            {this.state.policyId && (
                <FormLabel
                    title={this.localizationService.getString("chooseAnEnforcementMode")}
                    htmlFor="enforcementMode"
                >
                    <ToggleButton items={this.enforcementOptions} />
                </FormLabel>
            )}
        </>
    )
}
