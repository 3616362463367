import { LanguageKey } from "../pre-v3/services/localization/languages/en-US.language"

export enum StatusType {
    SUCCESS = "SUCCESS",
    WARNING = "WARNING",
    ERROR = "ERROR",
    DISABLED = "DISABLED",
    UNKNOWN = "UNKNOWN",
}

export const statusLabelKey: Record<StatusType, LanguageKey> = {
    [StatusType.SUCCESS]: "success",
    [StatusType.WARNING]: "warning",
    [StatusType.ERROR]: "error",
    [StatusType.DISABLED]: "disabled",
    [StatusType.UNKNOWN]: "unknown",
}
