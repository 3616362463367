import { faRocket, faShieldAlt, faVectorSquare } from "@fortawesome/pro-regular-svg-icons"
import { Tooltip } from "@mui/material"
import {
    GridApi,
    GridReadyEvent,
    CellDoubleClickedEvent,
    ValueFormatterParams,
} from "ag-grid-community"
import React from "react"
import { RouteComponentProps } from "react-router"

import { ROUTE, formatRoutePath } from "../../../../routes"
import { SaasAppType, SaasAuthProtocol } from "../../../api/Manage.api"
import { useServiceLocalization } from "../../../services/localization/Localization.service"
import { SaasManage, useServiceManage } from "../../../services/Manage.service"
import AgGridUtil from "../../../utils/AgGrid.util"
import { SaasAppUrlType } from "../../../services/Service.service"
import { useServiceUser } from "../../../services/User.service"
import styles from "./ServicesSaasList.module.scss"
import { Toolbar } from "../../../components/toolbar/Toolbar.component"
import { Grid } from "../../../components/grid/Grid.component"
import { ServicesStatusCellRenderer } from "../cell-renderer/status/ServicesStatusCellRenderer.component"
import { ServicesSaasCellRenderer } from "../cell-renderer/saas/ServicesSaasCellRenderer.component"
import {
    useServiceModal,
    useServiceActionBar,
    OrgEdition,
    useServiceLinks,
} from "../../../services"
import { ChoiceModal, ChoiceModalChoice } from "../../../components/choice-modal/ChoiceModal"
import { encodeID } from "../../../utils/Url.util"
import {
    ContactUsScreen,
    ContactUsScreenSteps,
} from "../../../../v3/components/page-screen/PageScreen.component"
import banyanRoute from "../../../../images/banyan-route.svg"
import { Container } from "../../../../v3/components/container/Container.component"
import { PageHeading } from "../../../../components/page-heading/PageHeading.component"
import {
    Button,
    ButtonElement,
    ButtonType,
    IconType,
} from "../../../../components/button/Button.component"

export function ServicesSaasList(props: RouteComponentProps) {
    // pull out the services we use
    const localization = useServiceLocalization()
    const userService = useServiceUser()
    const manageService = useServiceManage()
    const modalService = useServiceModal()
    const actionBarService = useServiceActionBar()
    const linkService = useServiceLinks()

    // we need to track the current edition in state
    const [edition, setEdition] = React.useState<OrgEdition>(OrgEdition.ENTERPRISE)

    // we need a place to store the grid api
    const gridApi: React.MutableRefObject<GridApi | null> = React.useRef<GridApi>(null)

    // a function to call that fetches the data
    const fetchData = React.useCallback(async () => {
        // if we don't have a grid api loaded yet, don't do anything
        if (!gridApi.current) {
            return
        }

        // show the loading state for the grid
        gridApi.current.showLoadingOverlay()

        // load the current edition
        const edition: OrgEdition = await userService.getEdition()
        setEdition(edition)

        if (edition === OrgEdition.TEAM) {
            actionBarService.unregisterRefreshFn(fetchData)
        }

        // load the saas apps into the grid
        try {
            gridApi.current.setRowData(await manageService.getSaasApps())
        } catch {
            // if there was a problem, load an empty grid
            gridApi.current.setRowData([])
        }

        // either way, we're not loading anymore
        gridApi.current.hideOverlay()
    }, [manageService, actionBarService, localization, userService])

    // start off as read only before we look up the user's info
    const [readOnly, setReadOnly] = React.useState(true)

    // when the component first mounts, look up if the user can create services
    React.useEffect(() => {
        userService.getUserOrgDetails().then((details) => {
            setReadOnly(!userService.canCreateServices(details.Profile))
        })
    }, [userService])

    // a function to open the modal
    const openModal = () => {
        modalService.open(localization.getString("publishSaasApplication"), {
            component: ChoiceModal,
            props: {
                description: localization.getString("policyDescription"),
                choices: [
                    {
                        title: localization.getString("idpRouted"),
                        description: localization.getString("idpRoutedDescription"),
                        link: formatRoutePath(ROUTE.SAAS_APPS_ADD, {
                            type: SaasAppUrlType.IDP_ROUTED,
                        }),
                        className: "addIdpRouted",
                    },
                    {
                        title: localization.getString("cseFederated"),
                        description: localization.getString("cseFederatedDescription"),
                        link: formatRoutePath(ROUTE.SAAS_APPS_ADD, {
                            type: SaasAppUrlType.SAAS,
                        }),
                        className: "addSassService",
                    },
                ] satisfies ChoiceModalChoice[],
            },
            maxWidth: "md",
        })
    }

    return (
        <section aria-labelledby={Id.HEADING} className={styles.container}>
            <header className={styles.spaceBetween}>
                <PageHeading id={Id.HEADING}>
                    {localization.getString("saasApplications")}
                </PageHeading>
                <Tooltip title={localization.getString("refresh")}>
                    <Button
                        icon={IconType.REDO}
                        onClick={fetchData}
                        asElement={ButtonElement.BUTTON}
                        buttonType={ButtonType.SECONDARY}
                        aria-label={localization.getString("refresh")}
                    />
                </Tooltip>
            </header>
            {edition === OrgEdition.TEAM ? (
                <Container>
                    <ContactUsScreen
                        image={banyanRoute}
                        title={localization.getString(
                            "upgradeForSomething",
                            localization.getString("saasApplications")
                        )}
                        docsLink={linkService.getLink("saasApplicationsDoc")}
                    >
                        <ContactUsScreenSteps
                            steps={[
                                {
                                    icon: faShieldAlt,
                                    label: localization.getString(
                                        "createsFederationBetweenSaasAppDesc"
                                    ),
                                },
                                {
                                    icon: faVectorSquare,
                                    label: localization.getString(
                                        "sonicWallCseBecomesAnAuthenticationFactorDesc"
                                    ),
                                },
                                {
                                    icon: faRocket,
                                    label: localization.getString("multiFactorAuthenticationDesc"),
                                },
                            ]}
                        />
                    </ContactUsScreen>
                </Container>
            ) : (
                <>
                    <div className={styles.spaceBetween}>
                        <Toolbar
                            onSearchChange={(value: string): void => {
                                if (gridApi.current) {
                                    gridApi.current.setQuickFilter(value)
                                }
                            }}
                            className={styles.search}
                        />
                        {!readOnly && (
                            <Button
                                buttonType={ButtonType.PRIMARY}
                                asElement={ButtonElement.BUTTON}
                                icon={IconType.PLUS}
                                onClick={openModal}
                            >
                                {localization.getString("publishSaasApplication")}
                            </Button>
                        )}
                    </div>
                    <Grid
                        className={styles.grid}
                        onGridReady={(event: GridReadyEvent): void => {
                            gridApi.current = event.api
                            fetchData()
                        }}
                        columnDefs={[
                            {
                                headerName: localization.getString("status"),
                                field: "status",
                                flex: 130,
                                cellRenderer: "servicesStatusCellRenderer",
                            },
                            {
                                headerName: localization.getString("serviceName"),
                                field: "name",
                                tooltipValueGetter: AgGridUtil.linkTooltipValueGetter,
                                flex: 130,
                                cellRenderer: "servicesSaasCellRenderer",
                                comparator: AgGridUtil.alphaBetComparator,
                            },
                            {
                                headerName: localization.getString("type"),
                                field: "type",
                                flex: 130,
                                cellRenderer: "typeCellRenderer",
                                comparator: AgGridUtil.alphaBetComparator,
                            },
                            {
                                headerName: localization.getString("authProtocol"),
                                field: "protocol",
                                flex: 90,
                                valueFormatter(params: ValueFormatterParams): string {
                                    if (params.value === SaasAuthProtocol.OIDC) {
                                        return localization.getString("oidc")
                                    }
                                    return localization.getString("saml")
                                },
                            },
                            {
                                headerName: localization.getString("policyAttached"),
                                field: "policyName",
                                flex: 90,
                                valueFormatter: AgGridUtil.nullableStringFormatter,
                                comparator: AgGridUtil.alphaBetComparator,
                            },
                            {
                                headerName: localization.getString("lastUpdated"),
                                field: "lastUpdatedAt",
                                flex: 100,
                                valueFormatter: AgGridUtil.dateFormatter,
                            },
                        ]}
                        pagination
                        context={{ history: props.history }}
                        onCellDoubleClicked={(event: CellDoubleClickedEvent) => {
                            if (event?.data) {
                                props.history.push(
                                    formatRoutePath(ROUTE.SAAS_APPS_DETAILS, {
                                        id: encodeID(event.data.id),
                                    })
                                )
                            }
                        }}
                        components={{
                            servicesSaasCellRenderer: ServicesSaasCellRenderer,
                            servicesStatusCellRenderer: ServicesStatusCellRenderer,
                            typeCellRenderer: function TypeCellRenderer({
                                data,
                            }: {
                                data: SaasManage
                            }) {
                                const localization = useServiceLocalization()

                                if (data.type === SaasAppType.BANYAN_FIRST) {
                                    return localization.getString("cseFederated")
                                } else if (data.type === SaasAppType.IDP_FIRST) {
                                    return localization.getString("idpRouted")
                                }

                                return localization.getString("unknown")
                            },
                        }}
                    />
                </>
            )}
        </section>
    )
}

enum Id {
    HEADING = "heading",
}
