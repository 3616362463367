import React from "react"
import styles from "./MultiInput.module.scss"
import { Button } from "../button/Button.component"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faMinus, faPlus } from "@fortawesome/pro-regular-svg-icons"
import classNames from "classnames/bind"
import { Input, FormLabelContext } from ".."

export default function () {
    // if we are controlled, there are no inputs, and the form is disabled we just need
    // to show an empty input
    if (this.props.value && this.props.value.length === 0 && this.props.disabled) {
        return (
            <div className={styles.inputRow}>
                <Input disabled className={classNames(styles.input, this.props.inputClassName)} />
            </div>
        )
    }

    // if the input is disabled we want to show all of the values in a controlled situation
    let values = this.state.values
    if (this.props.value) {
        values = this.props.disabled ? this.props.value : this.props.value.slice(0, -1)
    }

    // the last value in the list is shown in a non-disabled input
    const lastValue = this.props.value
        ? this.props.value[this.props.value.length - 1]
        : this.state.inputValue

    // don't allow the value to be added if there is no input value or the last value in a controlled situation is
    // empty
    let preventAdd = !this.state.inputValue
    if (this.props.value) {
        preventAdd = this.props.value.length === 0 || !this.props.value[this.props.value.length - 1]
    }

    // the input is forced to have a value if the prop says so, we're in a controlled situation
    // and there is no current value
    const inputValueRequired =
        this.props.required &&
        this.props.value &&
        (this.props.value.length === 0 || (this.props.value.length === 1 && !this.props.value[0]))

    return (
        <div className={this.props.className}>
            {values.map((v, i) => (
                <div className={styles.inputRow} key={v}>
                    <FormLabelContext.Consumer>
                        {({ htmlFor }) => (
                            <FormLabelContext.Provider value={{ htmlFor: htmlFor + "-" + v }}>
                                <Input
                                    className={classNames(styles.input, this.props.inputClassName)}
                                    disabled
                                    value={v}
                                />
                            </FormLabelContext.Provider>
                        )}
                    </FormLabelContext.Consumer>
                    {!this.props.disabled && (
                        <Button className={styles.button} onClick={() => this.onRemove(i)}>
                            <FontAwesomeIcon icon={faMinus} />
                        </Button>
                    )}
                </div>
            ))}
            {!this.props.disabled && (
                <div className={styles.inputRow}>
                    <Input
                        autoFocus={this.props.autoFocus}
                        className={classNames(styles.input, this.props.inputClassName)}
                        onKeyPress={this.onKeyPress}
                        placeholder={this.props.placeholder}
                        onChange={this.onChange}
                        value={lastValue || ""}
                        pattern={this.props.pattern}
                        ref={(e) => (this.inputField = e)}
                        required={inputValueRequired}
                        type={this.props.type || "text"}
                    />
                    <Button
                        className={styles.button}
                        onClick={this.onAdd}
                        disabled={preventAdd}
                        ariaLabel={this.localizationService.getString("addFilter")}
                    >
                        <FontAwesomeIcon icon={faPlus} />
                    </Button>
                </div>
            )}
        </div>
    )
}
