import classNames from "classnames/bind"
import React from "react"

import {
    BaseAvailablePlatforms,
    Props as BaseAvailablePlatformsProps,
} from "./BaseAvailablePlatforms.component"
import styles from "./AvailablePlatforms.module.scss"

export interface Props extends BaseAvailablePlatformsProps {
    className?: string
}

export function AvailablePlatforms(props: Props): JSX.Element {
    const { className, ...otherProps } = props

    return (
        <div className={classNames(styles.container, className)}>
            <BaseAvailablePlatforms {...otherProps} />
        </div>
    )
}
