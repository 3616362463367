import React from "react"

import { FilterBar } from "../../../../pre-v3/components/filter-bar/FilterBar.component"
import { useServiceLocalization } from "../../../../pre-v3/services/localization/Localization.service"
import { DataFilter, DataFilterType, FilterModel } from "../../../../pre-v3/utils/AgGrid.util"
import { SelectItem } from "../../../../pre-v3/utils/SelectValue.util"
import { encodeID } from "../../../../pre-v3/utils/Url.util"
import {
    ServiceTunnel,
    StatusType,
    TrustLevel,
    TrustProfile,
    allTrustLevels,
    statusForSearch,
    statusTypeLabelDict,
    trustLevelLabelDict,
} from "../../../services/Device.service"
import {
    DeviceOwnership,
    allDeviceOwnership,
    deviceOwnershipLabelDict,
} from "../../../services/shared/DeviceOwnership"
import { Role } from "../../../services/Role.service"
import { Platform, allPlatforms, platformLabelDict } from "../../../services/shared/Platform"
import { FilterKey } from "./FilterKey"

interface Props {
    hasMigrated: boolean
    roles?: Role[]
    serviceTunnels?: ServiceTunnel[]
    trustProfiles?: TrustProfile[]
    filterModel: FilterModel
    onFilter(filterModel: FilterModel): void
}

export function DeviceFilterBar(props: Props): JSX.Element {
    const filters = useFilters(
        props.hasMigrated,
        props.roles,
        props.serviceTunnels,
        props.trustProfiles
    )

    return (
        <FilterBar filters={filters} initialModel={props.filterModel} onChange={props.onFilter} />
    )
}

function useFilters(
    hasMigrated: boolean,
    roles?: Role[],
    serviceTunnels?: ServiceTunnel[],
    trustProfiles?: TrustProfile[]
): DataFilter[] {
    const localization = useServiceLocalization()

    return React.useMemo((): DataFilter[] => {
        const platformFilter: DataFilter = {
            key: FilterKey.PLATFORM,
            displayName: localization.getString("platform"),
            type: DataFilterType.LIST,
            options: allPlatforms.map(
                (platform: Platform): SelectItem => ({
                    displayName: localization.getString(platformLabelDict[platform]),
                    value: platform,
                })
            ),
        }

        const ownershipFilter: DataFilter = {
            key: FilterKey.OWNERSHIP,
            displayName: localization.getString("ownership"),
            type: DataFilterType.LIST,
            options: allDeviceOwnership.map(
                (ownership: DeviceOwnership): SelectItem => ({
                    displayName: localization.getString(deviceOwnershipLabelDict[ownership]),
                    value: ownership,
                })
            ),
        }

        const trustLevelFilter: DataFilter = {
            key: FilterKey.TRUST_LEVEL,
            displayName: localization.getString("trustLevel"),
            type: DataFilterType.LIST,
            options: allTrustLevels.map(
                (level: TrustLevel): SelectItem => ({
                    displayName: localization.getString(trustLevelLabelDict[level]),
                    value: level,
                })
            ),
        }

        const trustProfileFilter: DataFilter | undefined = trustProfiles
            ? {
                  key: FilterKey.TRUST_PROFILE,
                  displayName: localization.getString("trustProfile"),
                  type: DataFilterType.LIST,
                  options: trustProfiles.map(mapTrustProfileItem),
              }
            : undefined

        const statusFilter: DataFilter = {
            key: FilterKey.STATUS,
            displayName: localization.getString("status"),
            type: DataFilterType.LIST,
            options: statusForSearch.map(
                (status: StatusType): SelectItem => ({
                    displayName: localization.getString(statusTypeLabelDict[status]),
                    value: status,
                })
            ),
        }

        const deviceNameFilter: DataFilter = {
            key: FilterKey.NAME,
            displayName: localization.getString("deviceName"),
            type: DataFilterType.DEBOUNCEDINPUT,
        }

        const rolesFilter: DataFilter | undefined = roles
            ? {
                  key: FilterKey.ROLES,
                  displayName: localization.getString("roles"),
                  type: DataFilterType.MULTILOOKUP,
                  options: roles.map(getRoleName),
              }
            : undefined

        const appVersionFilter: DataFilter = {
            key: FilterKey.APP_VERSION,
            displayName: localization.getString("appVersion"),
            type: DataFilterType.DEBOUNCEDINPUT,
        }

        const lastLoginFilter: DataFilter = {
            key: FilterKey.LAST_LOGIN,
            displayName: localization.getString("lastLogin"),
            type: DataFilterType.DATETIMERANGE,
        }

        const activeServiceTunnelFilter: DataFilter | undefined = serviceTunnels
            ? {
                  key: FilterKey.ACTIVE_SERVICE_TUNNEL,
                  displayName: localization.getString("activeServiceTunnel"),
                  type: DataFilterType.LOOKUP,
                  options: serviceTunnels.map(mapServiceTunnelItem),
              }
            : undefined

        return [
            platformFilter,
            ownershipFilter,
            trustLevelFilter,
            ...(hasMigrated && trustProfileFilter ? [trustProfileFilter] : []),
            statusFilter,
            deviceNameFilter,
            ...(rolesFilter ? [rolesFilter] : []),
            appVersionFilter,
            lastLoginFilter,
            ...(activeServiceTunnelFilter ? [activeServiceTunnelFilter] : []),
        ]
    }, [hasMigrated, roles, trustProfiles])
}

function mapServiceTunnelItem(serviceTunnel: ServiceTunnel): SelectItem {
    return { displayName: serviceTunnel.name, value: encodeID(serviceTunnel.id!) }
}

function mapTrustProfileItem(trustProfile: TrustProfile): SelectItem {
    return { displayName: trustProfile.name, value: encodeID(trustProfile.id) }
}

function getRoleName(role: Role): string {
    return role.name
}
