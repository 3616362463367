import React from "react"
import { MenuItem, MenuItemProps } from "./menu-item/MenuItem.component"
import styles from "./Menu.module.scss"
import classNames from "classnames/bind"
import { LocalizationService } from "../../../pre-v3/services"

export function Menu(props: MenuProps) {
    const localization: LocalizationService = new LocalizationService()
    return (
        <ul className={classNames(styles.menu, props.className)}>
            {props.items.length > 0 ? (
                props.items.map((menuItemProps, idx) => <MenuItem key={idx} {...menuItemProps} />)
            ) : (
                <MenuItem
                    label={props.noDataMessage || localization.getString("noDataToDisplay")}
                />
            )}
        </ul>
    )
}

export interface MenuProps {
    items: MenuItemProps[]
    className?: string
    noDataMessage?: string
}
