import React from "react"
import styles from "./Lookup.module.scss"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faSpinner, faSearch, faCheck } from "@fortawesome/pro-solid-svg-icons"
import classNames from "classnames/bind"
import { InputWithAction } from "../input-with-action/InputWithAction.component"
import { Clickable } from ".."

export default function () {
    return (
        <div className={classNames(styles.container, this.props.className)}>
            <InputWithAction
                autoFocus
                className={styles.lookupInput}
                onKeyDown={this.onKeyDown}
                onChange={this.onChange}
                value={this.state.query}
                onAction={() => {}}
                placeholder={this.props.placeholder}
                innerRef={this.inputRef}
            >
                <FontAwesomeIcon
                    icon={this.state.loading ? faSpinner : faSearch}
                    spin={this.state.loading}
                />
            </InputWithAction>
            {this.props.multi && this.state.selected.length > 0 && (
                <ul className={styles.selectedContainer}>
                    {this.state.selected.map((selected, i) => (
                        <li key={i}>
                            <Clickable
                                className={styles.selectedItem}
                                onClick={(event) => {
                                    this.onDeselect(event, selected)
                                }}
                            >
                                <FontAwesomeIcon icon={faCheck} className={styles.selectedIcon} />
                                {selected.displayName || selected}
                            </Clickable>
                        </li>
                    ))}
                </ul>
            )}
            {this.state.results.length > 0 && (
                <ul ref={(ref) => (this.listRef = ref)} className={styles.lookupResultsContainer}>
                    {this.state.results.map((result, i) => (
                        <li key={i}>
                            <Clickable
                                className={styles.lookupResult}
                                onKeyDown={this.onAnchorKeyDown}
                                onClick={(event) => {
                                    this.onSelected(event, result)
                                }}
                            >
                                {result.displayName || result}
                            </Clickable>
                        </li>
                    ))}
                </ul>
            )}
        </div>
    )
}
