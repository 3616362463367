import React from "react"
import { Redirect, Route, Switch } from "react-router-dom"

import { useFeatureFlags } from "../../../hooks/useFeatureFlags.hook"
import { ActionBar } from "../../../pre-v3/components/action-bar/ActionBar.component"
import { useServiceLocalization } from "../../../pre-v3/services/localization/Localization.service"
import { Loader } from "../../components/loader/Loader.component"
import { MspOrgList } from "./list/MspOrgList.view"
import { MspOrgOverview } from "./overview/MspOrgOverview.view"
import { ROUTE } from "../../../routes"

export function MspOrgManagement() {
    const { data: featureFlags, status: featureFlagsStatus } = useFeatureFlags()
    const localization = useServiceLocalization()

    if (featureFlagsStatus === "loading")
        return (
            <Loader
                center
                medium
                title={localization.getString(
                    "loadingSomething",
                    localization.getString("orgManagement")
                )}
            />
        )

    return (
        <>
            <ActionBar />
            <Switch>
                <Route path={ROUTE.MSP_ORG_DETAILS}>
                    <MspOrgOverview
                        canAssignAdmin={featureFlags?.mspConsole.canAssignAdmin ?? false}
                    />
                </Route>
                <Route exact path={ROUTE.MSP_ORG_MANAGEMENT} component={MspOrgList} />
                <Redirect to={ROUTE.MSP_ORG_MANAGEMENT} />
            </Switch>
        </>
    )
}
