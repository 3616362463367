import { Singleton } from "../../pre-v3/decorators/Singleton.decorator"
import { UrlUtil } from "../../pre-v3/utils/Url.util"
import { BaseApi } from "./Base.api"

@Singleton("MswAuthApi")
export class MswAuthApi extends BaseApi {
    public mswLogin(search: MswLoginSearch): Promise<TokensRes> {
        const params: URLSearchParams = UrlUtil.convertToURLSearchParams(search)
        return this.post(
            "api/v2/msw_sso?" + params.toString(),
            {},
            {
                404: this.localization.getString("mswAuthorizationError"),
                401: this.localization.getString("mswAuthorizationError"),
                4025: this.localization.getString("mswAuthorizationError"),
                4026: this.localization.getString("mswAuthorizationError"),
                default: this.localization.getString("mswInternalError"),
            }
        )
    }

    public generateCscAccessCode(body: GenerateCscAccessCodeBody): Promise<string> {
        return this.postForm(
            // @cspell:ignore cscaccesscode
            "api/v2/msw_generate_cscaccesscode",
            body,
            {
                default: this.localization.getString("errorGeneratingCscAccessCode"),
            }
        )
    }
}

export interface TokensRes {
    msw_token: string
    banyan_token: string
}

export interface MswLoginSearch {
    code: string
    org_id: string
}

export interface GenerateCscAccessCodeBody {
    msw_token: string
}
