import React from "react"
import { Redirect, Route, Switch } from "react-router-dom"
import { ROUTE } from "../../routes"
import { ServicesAddSaas } from "../../pre-v3/views/services/add/saas/ServicesAddSaas.component"
import { ServicesSaasConfig } from "../../pre-v3/views/services/saas-config/ServicesSaasConfig.component"
import { ServicesSaasEdit } from "../../pre-v3/views/services/saas-edit/ServicesSaasEdit.component"
import { ServicesSaasOverview } from "../../pre-v3/views/services/saas-overview/ServicesSaasOverview.component"
import { ServicesSaasList } from "../../pre-v3/views/services/saas-list/ServicesSaasList.component"
import useTitle from "../../hooks/useTitle.hook"

export function SaasApps() {
    useTitle(["saasApps", "internetAccess", "adminConsole"])
    return (
        <Switch>
            <Route path={ROUTE.SAAS_APPS_ADD} exact component={ServicesAddSaas} />
            <Route path={ROUTE.SAAS_APPS_CONFIGURATION} exact component={ServicesSaasConfig} />
            <Route path={ROUTE.SAAS_APPS_EDIT} exact component={ServicesSaasEdit} />
            <Route path={ROUTE.SAAS_APPS_DETAILS} exact component={ServicesSaasOverview} />
            <Route path={ROUTE.SAAS_APPS} exact component={ServicesSaasList} />
            <Redirect to={ROUTE.SAAS_APPS} />
        </Switch>
    )
}
