import React from "react"
import { Redirect, Route, RouteComponentProps, Switch, useHistory } from "react-router-dom"

import useTitle from "../../hooks/useTitle.hook"
import { ServiceBundleAdd as BundlesAdd } from "../../pre-v3/views/settings/trust-provider/service-bundles/add/ServiceBundleAdd.component"
import { EditServiceBundle as BundlesEdit } from "../../pre-v3/views/settings/trust-provider/service-bundles/edit/EditServiceBundle.component"
import { ServiceBundleList as BundlesList } from "../../pre-v3/views/settings/trust-provider/service-bundles/list/ServiceBundleList.component"
import { ServiceBundleOverview as BundlesDetails } from "../../pre-v3/views/settings/trust-provider/service-bundles/overview/ServiceBundleOverview.component"
import { ROUTE } from "../../routes"

interface Props {
    canAccessServiceBundle?: boolean
}

export function Bundles(props: Props): JSX.Element {
    useTitle(["bundles", "sonicWallCseClient", "settings", "adminConsole"])
    const history = useHistory()
    return (
        <Switch>
            <Route path={ROUTE.BUNDLES_ADD} component={BundlesAdd} />
            <Route path={ROUTE.BUNDLES_EDIT} component={BundlesEdit} />
            <Route
                path={ROUTE.BUNDLES_DETAILS}
                component={(detailProps: RouteComponentProps<{ id: string }>) => (
                    <BundlesDetails
                        {...detailProps}
                        canAccessServiceBundle={props.canAccessServiceBundle}
                    />
                )}
            />
            <Route path={ROUTE.BUNDLES}>
                <BundlesList
                    canAccessServiceBundle={props.canAccessServiceBundle}
                    history={history}
                />
            </Route>
            <Redirect to={ROUTE.BUNDLES} />
        </Switch>
    )
}
