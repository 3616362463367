import * as React from "react"

import logoImage from "../../../images/logo-horiz-black.svg"
import { ROUTE } from "../../../routes"
import { Background } from "../../../v3/components/background/Background.component"
import styles from "./OIDC.module.scss"
import { ErrorBanner, Button, Input } from "../../components"
import { isValidPasswordRegex } from "../../utils/OIDC.util"
import { NewPasswordReq } from "../../api/OIDC.api"
import { RouteComponentProps } from "react-router"
import { useServiceLocalization, useServiceOIDC } from "../../services"

export function OIDCNewPassword({ history }: RouteComponentProps) {
    const localization = useServiceLocalization()
    const oidcService = useServiceOIDC()

    const user: User = oidcService.getUser()
    const orgName: string = oidcService.getOrgName()
    const queryState: string = oidcService.getQueryState()

    const [newPassword, setNewPassword] = React.useState<string>("")
    const [confirmPassword, setConfirmPassword] = React.useState<string>("")
    const [errorMessage, setErrorMessage] = React.useState<string>("")

    const form = React.useRef(null)

    React.useEffect(() => {
        if (!user || !orgName) {
            history.push(ROUTE.OIDC_LOGIN)
        }
    }, [history, orgName, user])

    function validateForm() {
        return newPassword.length > 0 && confirmPassword.length > 0
    }

    function onSubmit(event: React.SyntheticEvent) {
        event.preventDefault()

        if (newPassword !== confirmPassword) {
            setErrorMessage(localization.getString("thePasswordsEnteredDoNotMatch"))
            return
        } else {
            setErrorMessage("")
        }

        if (!isValidPasswordRegex(newPassword)) {
            setErrorMessage(localization.getString("passwordRequirementsDescription"))
            return
        } else {
            setErrorMessage("")
        }

        const payload: NewPasswordReq = {
            username: user.UserName,
            password: newPassword,
            orgName,
            session: user.Session,
            state: queryState,
        }

        oidcService.newPassword(payload).catch(() => {
            setErrorMessage(localization.getString("somethingWentWrongDescription"))
        })
    }

    return (
        <div className={styles.oidcLogin}>
            <Background>
                <div className={styles.loginForm}>
                    <div className={styles.form}>
                        <form onSubmit={onSubmit} ref={form} name="forgotPasswordForm">
                            <img src={logoImage} alt="" className={styles.logo} />
                            {errorMessage && (
                                <ErrorBanner className={styles.banner}>{errorMessage}</ErrorBanner>
                            )}
                            <Input
                                name="password"
                                id="password"
                                type="password"
                                value={newPassword}
                                onChange={(e) => setNewPassword(e.target.value)}
                                placeholder={localization.getString("newPassword")}
                                required
                            />
                            <Input
                                name="confirmpassword"
                                id="confirmpassword"
                                type="password"
                                value={confirmPassword}
                                onChange={(e) => setConfirmPassword(e.target.value)}
                                placeholder={localization.getString("confirmPassword")}
                            />

                            <Button
                                className={styles.formBtn}
                                type="submit"
                                disabled={!validateForm()}
                            >
                                {localization.getString("changePassword")}
                            </Button>
                        </form>
                    </div>
                </div>
            </Background>
        </div>
    )
}

interface User {
    UserName: string
    Session: string
}
