import { useLayoutEffect } from "react"
import { useServiceLocalization } from "../pre-v3/services/localization/Localization.service"
import { LanguageKey } from "../pre-v3/services/localization/languages/en-US.language"

export default function useTitle(titles: LanguageKey[]) {
    const localization = useServiceLocalization()

    useLayoutEffect(() => {
        const base = localization.getString("sonicWallCse")
        const titlesLocalized = titles.map((ele) => localization.getString(ele))
        const title = titlesLocalized.join(" - ")

        document.title = title ? title + " - " + base : base
    }, [])
}
