import "./commonImports"

import React from "react"
import { createRoot } from "react-dom/client"

import * as serviceWorker from "./serviceWorker"
import { App } from "./pages/app/App"

createRoot(document.getElementById("root")!).render(<App />)

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.register()

declare global {
    type StringMap = { [key: string]: string }
    type NumberMap = { [key: string]: number }
    type AnyMap = { [key: string]: any }
    type ObjectCount<T extends string> = { [key in T]: string } & { count: number }
}
