import * as React from "react"
import { ExemptedPathPattern, ServiceAttr } from "../../../api/Manage.api"
import { ServiceType, useServiceLocalization } from "../../../services"
import {
    FormHeader,
    FormLabel,
    Input,
    KeyValueInput,
    MultiInput,
    ToggleButton,
} from "../../../components"
import styles from "./ServicesInfo.module.scss"
import classNames from "classnames/bind"
import { Switch } from "../../../../v3/components/switch/Switch.component"

interface Props {
    type: ServiceType
    corsPatterns: ExemptedPathPattern[] | undefined
    pathExemptions: string[] | undefined
    cidrExemptions: string[] | undefined
    customHeaders: [string, string][] | undefined
    userFacing: boolean | null
    suppressDTVFlag: boolean
    disablePrivateDnsFlag: boolean
    serviceAccount: ServiceAttr["http_settings"]["token_loc"]
}

export function AdvancedSummary({
    userFacing,
    corsPatterns,
    pathExemptions,
    cidrExemptions,
    customHeaders,
    suppressDTVFlag,
    disablePrivateDnsFlag,
    serviceAccount,
}: Props) {
    const localization = useServiceLocalization()

    return (
        <section className={styles.advancedSummary}>
            {corsPatterns && corsPatterns.length > 0 && (
                <>
                    <FormLabel
                        title={localization.getString("corsExemptions")}
                        className={styles.outerLabel}
                    />
                    <div className={styles.exemptionContainer}>
                        {corsPatterns.map((pattern, index) => (
                            <div className={styles.corsExemption} key={index}>
                                {corsPatterns.length > 1 && (
                                    <FormHeader className={styles.corsHeader}>
                                        {localization.getString("corsExemptionHash")}
                                        {index + 1}
                                    </FormHeader>
                                )}
                                <FormLabel
                                    title={localization.getString("originDomain")}
                                    className={styles.innerLabel}
                                    htmlFor={`originDomain-${index}`}
                                >
                                    <MultiInput
                                        className={styles.input}
                                        initialValues={pattern.hosts?.flatMap(
                                            (host) => host.origin_header
                                        )}
                                        onChange={() => {}}
                                        placeholder={localization.getString("originDomain")}
                                        disabled={true}
                                    />
                                </FormLabel>
                                <FormLabel
                                    title={localization.getString("validMethods")}
                                    className={styles.innerLabel}
                                    htmlFor={`methods-${index}`}
                                >
                                    <MultiInput
                                        className={styles.input}
                                        initialValues={pattern.methods}
                                        onChange={() => {}}
                                        placeholder={localization.getString("validMethods")}
                                        disabled={true}
                                    />
                                </FormLabel>
                                <FormLabel
                                    title={localization.getString("targetDomains")}
                                    className={styles.innerLabel}
                                    htmlFor={`target-${index}`}
                                >
                                    <MultiInput
                                        className={styles.input}
                                        initialValues={pattern.hosts?.flatMap(
                                            (host) => host.target
                                        )}
                                        onChange={() => {}}
                                        placeholder={localization.getString("targetDomains")}
                                        disabled={true}
                                    />
                                </FormLabel>
                                {pattern.mandatory_headers &&
                                    pattern.mandatory_headers.length > 0 && (
                                        <FormLabel
                                            title={localization.getString("mandatoryHeaders")}
                                            className={styles.innerLabel}
                                            htmlFor={`headers-${index}`}
                                        >
                                            <MultiInput
                                                className={styles.input}
                                                initialValues={pattern.mandatory_headers}
                                                onChange={() => {}}
                                                placeholder={localization.getString(
                                                    "mandatoryHeaders"
                                                )}
                                                disabled={true}
                                            />
                                        </FormLabel>
                                    )}
                                {pattern.paths && pattern.paths.length > 0 && (
                                    <FormLabel
                                        title={localization.getString("paths")}
                                        className={styles.innerLabel}
                                        htmlFor={`paths-${index}`}
                                    >
                                        <MultiInput
                                            className={styles.input}
                                            initialValues={pattern.paths}
                                            onChange={() => {}}
                                            placeholder={localization.getString("paths")}
                                            disabled={true}
                                        />
                                    </FormLabel>
                                )}
                                {pattern.source_cidrs && pattern.source_cidrs.length > 0 && (
                                    <FormLabel
                                        title={localization.getString("sourceCidrs")}
                                        className={styles.innerLabel}
                                        htmlFor={`cidrs-${index}`}
                                    >
                                        <MultiInput
                                            className={styles.input}
                                            initialValues={pattern.source_cidrs}
                                            onChange={() => {}}
                                            placeholder={localization.getString("sourceCidrs")}
                                            disabled={true}
                                        />
                                    </FormLabel>
                                )}
                            </div>
                        ))}
                    </div>
                </>
            )}
            {cidrExemptions && cidrExemptions.length > 0 && (
                <FormLabel
                    title={localization.getString("exemptedCidrs")}
                    className={styles.label}
                    htmlFor="exempted-cidrs"
                >
                    <MultiInput
                        className={styles.input}
                        initialValues={cidrExemptions}
                        onChange={() => {}}
                        placeholder={localization.getString("exemptedCidrs")}
                        disabled={true}
                    />
                </FormLabel>
            )}
            {pathExemptions && pathExemptions.length > 0 && (
                <FormLabel
                    title={localization.getString("exemptedPaths")}
                    className={styles.label}
                    htmlFor="exemptedPaths"
                >
                    <MultiInput
                        className={styles.input}
                        initialValues={pathExemptions}
                        onChange={() => {}}
                        placeholder={localization.getString("exemptedPaths")}
                        disabled={true}
                    />
                </FormLabel>
            )}
            {customHeaders && customHeaders.length > 0 && (
                <FormLabel
                    title={localization.getString("customHTTPHeadersToSendToBackend")}
                    className={classNames(styles.label)}
                    htmlFor="customHTTPHeadersToSendToBackend"
                >
                    <KeyValueInput
                        disabled={true}
                        value={customHeaders}
                        onChange={() => {}}
                        className={styles.input}
                    />
                </FormLabel>
            )}
            {suppressDTVFlag && (
                <FormLabel
                    inline={false}
                    title={localization.getString("suppressDeviceTrustVerification")}
                    tooltip={localization.getString("suppressDeviceTrustVerificationDescription")}
                    htmlFor="suppressDeviceTrustVerification"
                >
                    <Switch
                        className={styles.switch}
                        disabled={true}
                        value={suppressDTVFlag}
                        onChange={() => {}}
                    ></Switch>
                </FormLabel>
            )}
            {disablePrivateDnsFlag && (
                <FormLabel
                    inline={false}
                    title={localization.getString("disablePrivateDns")}
                    tooltip={localization.getString("disablePrivateDnsDescription")}
                    htmlFor="disablePrivateDns"
                >
                    <Switch
                        className={styles.switch}
                        disabled={true}
                        value={disablePrivateDnsFlag}
                        onChange={() => {}}
                    ></Switch>
                </FormLabel>
            )}
            {serviceAccount && (
                <>
                    <FormLabel title={localization.getString("enableServiceAccountAccess")}>
                        <Switch value={true} disabled />
                    </FormLabel>
                    <>
                        <FormLabel
                            title={localization.getString(
                                "howAreTheServiceAccountCredentialsBeingSent"
                            )}
                            htmlFor="serviceAccountCredLocation"
                        >
                            <ToggleButton
                                disabled
                                className={styles.options}
                                items={[
                                    {
                                        label: localization.getString("authorizationHeader"),
                                        selected: serviceAccount.authorization_header,
                                    },
                                    {
                                        label: localization.getString("queryParameter"),
                                        selected: !!serviceAccount.query_param,
                                    },
                                    {
                                        label: localization.getString("customHeader"),
                                        selected: !!serviceAccount.custom_header,
                                    },
                                ]}
                            />
                        </FormLabel>
                        {!!serviceAccount.query_param && (
                            <FormLabel
                                title={localization.getString("parameterName")}
                                htmlFor="parameter"
                                slim
                            >
                                <Input value={serviceAccount.query_param} required disabled />
                            </FormLabel>
                        )}
                        {!!serviceAccount.custom_header && (
                            <FormLabel
                                title={localization.getString("headerName")}
                                htmlFor="headerName"
                                slim
                            >
                                <Input value={serviceAccount.custom_header} required disabled />
                            </FormLabel>
                        )}
                    </>
                </>
            )}
        </section>
    )
}
