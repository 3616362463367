import { IconDefinition } from "@fortawesome/fontawesome-svg-core"
import classNames from "classnames"
import React from "react"

import { Button } from "../button/Button.component"
import { ButtonLink, Props as ButtonLinkProps } from "../button-link/ButtonLink.component"
import { Icon } from "../Icon/Icon.component"
import styles from "./RoundButton.module.scss"

export interface Props extends React.ButtonHTMLAttributes<HTMLButtonElement | HTMLAnchorElement> {
    disabled?: boolean
    to?: ButtonLinkProps["to"]
    icon: IconDefinition
    /**
     * Labels of the button
     */
    leftLabel?: string
    rightLabel?: string
    brand?: boolean
    error?: boolean
}

export function RoundButton(props: Props) {
    const { className, icon, leftLabel, rightLabel, to, ...buttonProps } = props

    const classes = classNames(styles.iconButton, className, {
        [styles.noLabel]: !leftLabel && !rightLabel,
    })

    const children = (
        <React.Fragment>
            {leftLabel && <label className={styles.leftLabel}>{leftLabel}</label>}
            <Icon icon={icon} className={styles.icon} />
            {rightLabel && <label className={styles.rightLabel}>{rightLabel}</label>}
        </React.Fragment>
    )

    return to ? (
        <ButtonLink {...buttonProps} to={to} className={classes}>
            {children}
        </ButtonLink>
    ) : (
        <Button {...buttonProps} className={classes}>
            {children}
        </Button>
    )
}
