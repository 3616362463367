import { IconDefinition } from "@fortawesome/fontawesome-common-types"
import React, { useContext } from "react"
import styles from "./MenuItem.module.scss"
import classNames from "classnames/bind"
import { Link } from "react-router-dom"
import { MenuPopoverContent } from "../menu-popover/MenuPopover.component"
import { Icon } from "../../Icon/Icon.component"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faSpinner } from "@fortawesome/pro-solid-svg-icons"

export function MenuItem(props: MenuItemProps) {
    switch (props.type) {
        case "link":
            return LinkMenuItem(props)
        case "divider":
            return DividerMenuItem(props)
        case "loading":
            return LoadingMenuItem(props)
        default:
            return ButtonMenuItem(props)
    }
}

export interface MenuItemProps {
    type?: "button" | "link" | "divider" | "loading"
    onClick?: React.MouseEventHandler<HTMLLIElement>
    icon?: IconDefinition
    label?: string
    href?: string
    className?: string
    closeMenuOnClick?: boolean
}

function ButtonMenuItem(props: MenuItemProps) {
    const { closeMenuOnClick = true } = props

    const menu = useContext(MenuPopoverContent)

    function onClick(event: React.MouseEvent<HTMLLIElement, MouseEvent>): void {
        if (closeMenuOnClick) {
            menu.close()
        }
        props.onClick?.(event)
    }

    return (
        <li
            aria-label={props.label}
            onClick={onClick}
            className={classNames(
                styles.menuItem,
                { [styles.menuItemClickable]: props.onClick },
                props.className
            )}
        >
            {props.icon && <Icon className={styles.icon} icon={props.icon} />}
            {props.label}
        </li>
    )
}

function DividerMenuItem(props: MenuItemProps) {
    return props.label ? (
        <li
            aria-label={props.label}
            className={classNames(styles.menuItemDivider, props.className)}
        >
            {props.icon && <Icon className={styles.icon} icon={props.icon} />}
            {props.label}
        </li>
    ) : (
        <hr />
    )
}

function LinkMenuItem(props: MenuItemProps) {
    return (
        <li aria-label={props.label} className={classNames(styles.menuItem, props.className)}>
            <Link to={props.href!} className={styles.menuItemLink}>
                {props.icon && <Icon className={styles.icon} icon={props.icon} />}
                {props.label}
            </Link>
        </li>
    )
}

function LoadingMenuItem(props: MenuItemProps) {
    return (
        <li className={classNames(styles.menuItem, styles.loading, props.className)}>
            <FontAwesomeIcon icon={faSpinner} spin />
        </li>
    )
}
