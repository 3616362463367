import React from "react"
import styles from "./ServicesAddBackend.module.scss"
import {} from "../../../../components/info-tip/InfoTip.component"
import {
    ToggleButton,
    InfoTip,
    MultiInput,
    FormLabel,
    Input,
    Checkbox,
    PortInput,
} from "../../../../components"
import classNames from "classnames/bind"
import { BackendType } from "./ServicesAddBackend.component"

export default function () {
    const showTlsOptions = this.state.backendAttr.target.tls
    return (
        <div className={this.props.className}>
            {this.props.showConnectToggle && (
                <FormLabel
                    slim
                    title={this.localizationService.getString(
                        "howShouldIncomingConnectionsBeProxiedToTheBackend"
                    )}
                    htmlFor="howShouldIncomingConnectionsBeProxiedToTheBackend"
                >
                    <ToggleButton
                        disabled={this.props.disabled}
                        className={styles.toggleButton}
                        items={this.backendTypes}
                    />
                </FormLabel>
            )}
            {this.state.backendType === "FIXED" && (
                <FormLabel
                    title={
                        <>
                            {this.localizationService.getString("backendDomain")}
                            <InfoTip
                                message={this.localizationService.getString(
                                    "backendDomainExplanation"
                                )}
                            />
                        </>
                    }
                    slim
                    htmlFor="backendDomainType"
                >
                    <Input
                        id="domain"
                        className={styles.domainInput}
                        required
                        defaultValue={this.state.backendAttr.target.name}
                        disabled={this.props.disabled}
                        placeholder={this.localizationService.getString(
                            "domainNameInternalPlaceholder"
                        )}
                    />
                    <PortInput
                        className={styles.portInput}
                        required
                        defaultValue={this.state.backendAttr.target.port}
                        disabled={this.props.disabled}
                        id="port"
                    />
                    <br />
                    {this.props.showTls && (
                        <>
                            <Checkbox
                                id="tls"
                                className={styles.checkbox}
                                defaultChecked={this.state.backendAttr.target.tls}
                                disabled={this.props.disabled}
                            />
                            <label htmlFor="tls" className={styles.checkboxLabel}>
                                {this.localizationService.getString("tls")}
                                <InfoTip
                                    message={this.localizationService.getString("tlsDescription")}
                                />
                            </label>
                            {showTlsOptions && (
                                <>
                                    <Checkbox
                                        id="tls-insecure"
                                        className={styles.checkbox}
                                        defaultChecked={this.state.backendAttr.target.tls_insecure}
                                        disabled={this.props.disabled}
                                    />
                                    <label htmlFor="tls-insecure" className={styles.checkboxLabel}>
                                        {this.localizationService.getString("tlsInsecure")}
                                        <InfoTip
                                            message={this.localizationService.getString(
                                                "tlsInsecureDescription"
                                            )}
                                        />
                                    </label>
                                    <Checkbox
                                        id="client-certificate"
                                        className={styles.checkbox}
                                        defaultChecked={
                                            this.state.backendAttr.target.client_certificate
                                        }
                                        disabled={this.props.disabled}
                                    />
                                    <label
                                        htmlFor="client-certificate"
                                        className={styles.checkboxLabel}
                                    >
                                        {this.localizationService.getString("clientCertificate")}
                                        <InfoTip
                                            message={this.localizationService.getString(
                                                "clientCertificateDescription"
                                            )}
                                        />
                                    </label>
                                </>
                            )}
                        </>
                    )}
                </FormLabel>
            )}
            {this.props.showAllowPatterns &&
                this.state.backendType === BackendType.HTTP_CONNECT && (
                    <>
                        <FormLabel
                            title={
                                <>
                                    {this.localizationService.getString(
                                        "backendAllowedHostnamesAndCIDRRanges"
                                    )}
                                    <InfoTip
                                        message={this.localizationService.getString(
                                            "backendHostnameCIDRExplanation"
                                        )}
                                    />
                                </>
                            }
                            htmlFor="backendAllowedHostnamesAndCIDRRanges"
                        >
                            <div
                                className={classNames(styles.indent, {
                                    [styles.disabled]:
                                        this.props.disabled && !this.state.allowedHostnames.length,
                                })}
                            >
                                <FormLabel
                                    title={this.localizationService.getString("hostnames") + ":"}
                                    htmlFor="allowedBackendHostname"
                                    slim
                                >
                                    <MultiInput
                                        className={styles.inputContainer}
                                        initialValues={this.state.allowedHostnames}
                                        placeholder={this.localizationService.getString(
                                            "allowedBackendHostname"
                                        )}
                                        onChange={this.onAllowedHostnamesChange}
                                        disabled={this.props.disabled}
                                    />
                                </FormLabel>
                            </div>
                            <div
                                className={classNames(styles.indent, {
                                    [styles.disabled]:
                                        this.props.disabled && !this.state.allowedCidrRanges.length,
                                })}
                            >
                                <FormLabel
                                    title={this.localizationService.getString("cidrRanges") + ":"}
                                    htmlFor="allowedBackendCidrRange"
                                    slim
                                >
                                    <MultiInput
                                        className={styles.inputContainer}
                                        initialValues={this.state.allowedCidrRanges}
                                        placeholder={this.localizationService.getString(
                                            "allowedBackendCidrRange"
                                        )}
                                        onChange={this.onAllowedCidrRangesChange}
                                        disabled={this.props.disabled}
                                        pattern={this.manageService.CIDR_REGEX}
                                    />
                                </FormLabel>
                            </div>
                        </FormLabel>
                    </>
                )}
        </div>
    )
}
