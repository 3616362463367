import React from "react"
import { useHistory } from "react-router-dom"

import { useServiceLocalization } from "../../../../../pre-v3/services/localization/Localization.service"
import { ROUTE } from "../../../../../routes"
import { NewRegistryCheck, useCreateRegistryKey } from "../../../../services/TrustFactor.service"
import { Container } from "../../../../components/container/Container.component"
import { RegistryCheckForm } from "./RegistryCheckForm.component"
import trustStyles from "../../../../../pages/trust/Trust.module.scss"
import styles from "./RegistryCheckForm.module.scss"
import { PageHeading } from "../../../../../components/page-heading/PageHeading.component"

interface Props {
    canAccessRegistryCheckFactor?: boolean
}

export function RegistryCheckAdd(props: Props): JSX.Element {
    const history = useHistory()
    const localization = useServiceLocalization()

    const {
        mutateAsync: createRegistryCheckTrustFactor,
        error: createRegistryCheckTrustFactorError,
        isLoading: createRegistryCheckTrustFactorLoading,
    } = useCreateRegistryKey({ onSuccess: () => history.push(ROUTE.FACTORS_REGISTRY_CHECK) })

    function onSubmit(registryCheck: NewRegistryCheck): void {
        createRegistryCheckTrustFactor(registryCheck)
    }

    const errors = [
        typeof createRegistryCheckTrustFactorError === "string" &&
            createRegistryCheckTrustFactorError,
    ]

    return (
        <div className={styles.container}>
            <section aria-labelledby={Id.HEADING} className={trustStyles.section}>
                <header className={trustStyles.header}>
                    <PageHeading id={Id.HEADING}>
                        {localization.getString("addRegistryKey")}
                    </PageHeading>
                </header>
            </section>
            <Container>
                <RegistryCheckForm
                    onSubmit={onSubmit}
                    cancelHref={ROUTE.FACTORS_REGISTRY_CHECK}
                    submitting={createRegistryCheckTrustFactorLoading}
                    errors={errors}
                    canAccessRegistryCheckFactor={props.canAccessRegistryCheckFactor}
                />
            </Container>
        </div>
    )
}

enum Id {
    HEADING = "heading",
}
