import React from "react"
import styles from "./ConfirmationDialog.module.scss"
import { Dialog, DialogTitle, DialogContent, DialogActions } from "@mui/material"
import { Button, ButtonElement, ButtonType } from "../../../../components/button/Button.component"

export default function () {
    return (
        <Dialog open={this.props.open} onClose={this.onCloseHandler} classes={dialogClasses}>
            {this.props.title && <DialogTitle>{this.props.title}</DialogTitle>}
            <DialogContent>{this.props.children}</DialogContent>
            <DialogActions className={styles.dialogActions}>
                <Button
                    onClick={this.onCloseHandler}
                    asElement={ButtonElement.BUTTON}
                    buttonType={ButtonType.SECONDARY}
                >
                    {this.props.cancelLabel || this.localizationService.getString("cancel")}
                </Button>
                <Button
                    autoFocus
                    disabled={this.props.confirmDisabled}
                    onClick={this.onConfirmHandler}
                    asElement={ButtonElement.BUTTON}
                    buttonType={ButtonType.PRIMARY}
                >
                    {this.props.confirmLabel || this.localizationService.getString("confirm")}
                </Button>
            </DialogActions>
        </Dialog>
    )
}

const dialogClasses = { container: styles.container, paper: styles.paper }
