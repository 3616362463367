import ServiceContext from "./context"

export * from "./ActionBar.service"
export * from "./Entity.service"
export * from "./Events.service"
export * from "./Infra.service"
export * from "./localization/Localization.service"
export * from "./Manage.service"
export * from "./Master.service"
export * from "./Modal.service"
export * from "./OIDC.service"
export * from "./Reporting.service"
export * from "./Secure.service"
export * from "./Settings.service"
export * from "./Stats.service"
export * from "./TrustFactor.service"
export * from "./User.service"
export * from "./Certs.service"
export * from "./link/Link.service"
export * from "./Service.service"
export * from "./Settings.service"
export * from "./Inventory.service"
export * from "./SystemLogs.service"

// exported for testing
export const ServiceProvider = ServiceContext.Provider
