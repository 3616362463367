import React from "react"

import { Grid, ICellRendererParams } from "../../../../pre-v3/components/grid/Grid.component"
import ActionBarService from "../../../../pre-v3/services/ActionBar.service"
import { useServiceLocalization } from "../../../../pre-v3/services/localization/Localization.service"
import AgGridUtil from "../../../../pre-v3/utils/AgGrid.util"
import { StringUtil } from "../../../../pre-v3/utils/String.util"
import { ROUTE, formatRoutePath } from "../../../../routes"
import { ErrorBanner } from "../../../components/banner/Banner.component"
import { RowTitle } from "../../../components/grid/RowTitle.component"
import { Loader } from "../../../components/loader/Loader.component"
import { SearchInput } from "../../../components/search-input/SearchInput.component"
import { Role, useGetServiceAccountRoles } from "../../../services/Role.service"
import { ServiceAccount } from "../../../services/ServiceAccount.service"
import styles from "./ServiceAccountRolesTab.module.scss"
import { encodeID } from "../../../../pre-v3/utils/Url.util"

interface Props {
    serviceAccount: ServiceAccount
}

export function ServiceAccountRolesTab(props: Props): JSX.Element {
    const localization = useServiceLocalization()

    const actionBarService = new ActionBarService()

    const [search, setSearch] = React.useState("")

    const getRoles = useGetServiceAccountRoles(props.serviceAccount)

    React.useEffect(() => {
        actionBarService.registerRefreshFn(getRoles.refetch)

        return () => {
            actionBarService.unregisterRefreshFn(getRoles.refetch)
        }
    }, [getRoles.refetch])

    const filteredRoles = React.useMemo(
        (): Role[] | undefined =>
            search
                ? getRoles.data?.filter((role) =>
                      StringUtil.caseInsensitiveIncludes(role.name, search)
                  )
                : getRoles.data,
        [getRoles.data, search]
    )

    if (getRoles.isLoading) {
        return <Loader isLoading children center medium />
    }

    if (getRoles.isError) {
        return <ErrorBanner>{String(getRoles.error)}</ErrorBanner>
    }

    return (
        <div className={styles.container}>
            <SearchInput
                value={search}
                onChangeValue={setSearch}
                placeholder={localization.getString("search")}
                className={styles.searchInput}
            />
            <Grid
                rowData={filteredRoles}
                columnDefs={[
                    {
                        headerName: localization.getString("roleName"),
                        field: "name",
                        tooltipValueGetter: AgGridUtil.linkTooltipValueGetter,
                        cellRenderer: renderNameCell,
                        comparator: AgGridUtil.alphaBetComparator,
                        flex: 1,
                    },
                    {
                        headerName: localization.getString("lastUpdated"),
                        field: "lastUpdatedAt",
                        valueFormatter: AgGridUtil.dateFormatter,
                        flex: 1,
                    },
                ]}
                autoHeight
            />
        </div>
    )
}

function renderNameCell(params: ICellRendererParams<Role, string>): JSX.Element {
    const route =
        params.data && formatRoutePath(ROUTE.ROLES_DETAILS, { id: encodeID(params.data.id) })

    return <RowTitle title={params.value} route={route} />
}
