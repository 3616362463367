import ClickAwayListener from "@mui/material/ClickAwayListener"
import Popper from "@mui/material/Popper"
import React, { useEffect } from "react"

import { useServiceLocalization } from "../../pre-v3/services/localization/Localization.service"
import { Input, InputProps } from "../../v3/components/input/Input.component"
import { Icon, IconType } from "../icon/Icon.component"
import styles from "./SmartSearchBar.module.scss"
import { Button, ButtonElement, ButtonType } from "../button/Button.component"
import { Link, LinkElement } from "../link/Link.component"
import { Loader } from "../../v3/components/loader/Loader.component"
import Markdown from "react-markdown"

type OmittedProps = "onChangeValue" | "value"
type Recommendations = string[] | "loading"

export interface SmartSearchBarProps extends Omit<InputProps, OmittedProps> {
    streamedAnswer: string
    recommendations: Recommendations
    onSearch(prompt: string): Promise<Result>
    onFeedback(wasHelpful: boolean): void
    onClearStreamedAnswer: () => void
}

export type Result = { hasResult: true; result: string; link: string } | { hasResult: false }

export interface SmartSearchBarApi {
    addCannedPrompt(prompt: string): void
    clear(): void
}

export const SmartSearchBar = React.forwardRef<SmartSearchBarApi, SmartSearchBarProps>(
    (props, ref) => {
        const {
            className,
            onFeedback,
            onFocus,
            onSearch,
            recommendations,
            streamedAnswer,
            onClearStreamedAnswer,
            ...inputProps
        } = props

        const localization = useServiceLocalization()

        const searchInputRef = React.useRef<HTMLInputElement>(null)
        const searchInputContainerRef = React.useRef<HTMLDivElement>(null)
        const [isPromptPaneOpen, setIsPromptPaneOpen] = React.useState(false)
        const [prompt, setPrompt] = React.useState("")
        const [result, setResult] = React.useState<Result>()
        const [isLoading, setIsLoading] = React.useState(false)

        const onSearchInputFocus: React.FocusEventHandler<HTMLInputElement> = (event) => {
            event.preventDefault()
            setIsPromptPaneOpen(true)
            onFocus?.(event)
        }

        const onPaneClose = () => setIsPromptPaneOpen(false)

        const getResults = async (prompt: string): Promise<Result> => {
            try {
                setIsLoading(true)
                return await onSearch(prompt)
            } catch (error) {
                return { hasResult: false }
            } finally {
                setIsLoading(false)
            }
        }

        const onRecommendationClick = async (recommendation: string) => {
            setPrompt(recommendation)
            setResult(await getResults(recommendation))
        }

        const onCannedPromptClick = React.useCallback(
            (partialPrompt: string) => {
                setPrompt(partialPrompt)
                searchInputRef.current?.focus()
            },
            [setPrompt, searchInputRef]
        )

        const onSubmit: React.FormEventHandler = async (event) => {
            event.preventDefault()

            if (prompt) setResult(await getResults(prompt))
        }

        const onClear = () => {
            setIsPromptPaneOpen(false)
            setPrompt("")
            setResult(undefined)
            onClearStreamedAnswer()
        }

        React.useImperativeHandle(ref, () => ({
            addCannedPrompt: onCannedPromptClick,
            clear: onClear,
        }))

        useEffect(() => {
            if (!isPromptPaneOpen) {
                onClear()
            }
        }, [isPromptPaneOpen, onClear])

        return (
            <ClickAwayListener onClickAway={onPaneClose}>
                <form onSubmit={onSubmit} className={className}>
                    <div className={styles.searchInputContainer} ref={searchInputContainerRef}>
                        <button
                            type="submit"
                            className={styles.searchButton}
                            aria-label={localization.getString("search")}
                        >
                            <Icon icon={IconType.SEARCH} />
                        </button>
                        <Input
                            {...inputProps}
                            value={prompt}
                            onChangeValue={setPrompt}
                            onFocus={onSearchInputFocus}
                            className={styles.searchInput}
                            ref={searchInputRef}
                            maxLength={256}
                        />
                    </div>
                    {searchInputContainerRef.current && (
                        <Popper
                            open={isPromptPaneOpen}
                            anchorEl={searchInputContainerRef.current}
                            className={styles.resultPaneContainer}
                        >
                            <div
                                className={styles.searchCard}
                                aria-live="polite"
                                aria-busy={isLoading || recommendations === "loading"}
                                aria-atomic
                            >
                                <PromptPane
                                    streamedAnswer={streamedAnswer}
                                    recommendations={recommendations}
                                    result={result}
                                    isLoading={isLoading}
                                    onPaneClose={onPaneClose}
                                    onRecommendationClick={onRecommendationClick}
                                    onCannedPromptClick={onCannedPromptClick}
                                    onFeedback={onFeedback}
                                />
                            </div>
                        </Popper>
                    )}
                </form>
            </ClickAwayListener>
        )
    }
)

SmartSearchBar.displayName = "SmartSearchBar"

interface PromptPaneProps {
    recommendations: Recommendations
    className?: string
    result?: Result
    isLoading: boolean
    streamedAnswer: string
    onPaneClose(): void
    onFeedback(wasHelpful: boolean): void
    onRecommendationClick(recommendation: string): void
    onCannedPromptClick(partialPrompt: string): void
}

function PromptPane(props: PromptPaneProps): JSX.Element {
    const localization = useServiceLocalization()

    const [showFeedback, setShowFeedback] = React.useState(true)

    if ((props.streamedAnswer === "" && props.isLoading) || props.recommendations === "loading") {
        return (
            <Loader
                title={localization.getString(
                    "loadingSomething",
                    localization.getString("recommendations")
                )}
                className={styles.loader}
                center
                medium
            />
        )
    }

    // If there is a streamed answer or a result, sometimes the stream will not produce a data event
    // due to caching, so the result will be shown after loading is complete.
    if (props.streamedAnswer !== "" || props.result?.hasResult) {
        const onPositiveFeedbackClick: React.MouseEventHandler = (event) => {
            event.preventDefault()
            props.onFeedback(true)
            setShowFeedback(false)
        }

        const onNegativeFeedbackClick: React.MouseEventHandler = (event) => {
            event.preventDefault()
            props.onFeedback(false)
            setShowFeedback(false)
        }

        const hasNoAnswerRes = props.streamedAnswer.indexOf("NO_ANSWER") >= 0

        return (
            <React.Fragment>
                <div className={styles.smartSearchResults}>
                    <span className={styles.smartSearchResultsText}>
                        {localization.getString("smartSearchResults")}
                    </span>
                    {!hasNoAnswerRes && (
                        <Markdown className={styles.markdownResult}>
                            {props.result?.hasResult
                                ? props.result?.result
                                : `${props.streamedAnswer}`}
                        </Markdown>
                    )}

                    {props.result?.hasResult && (
                        <React.Fragment>
                            <Button
                                buttonType={ButtonType.PRIMARY}
                                asElement={ButtonElement.LINK}
                                to={props.result.link}
                                onClick={props.onPaneClose}
                                className={styles.moreOptionsButton}
                            >
                                {localization.getString("viewFullResultsAndMoreOptions")}
                            </Button>
                        </React.Fragment>
                    )}

                    {(!props.result?.hasResult || hasNoAnswerRes) && (
                        <p className={styles.noSmartSearchResults}>
                            {localization.getString("noSmartResultsReturned")}
                        </p>
                    )}
                </div>
                {props.result?.hasResult && showFeedback && (
                    <div className={styles.feedback}>
                        <span>{localization.getString("wasThisResponseHelpful")}</span>
                        <Link
                            onClick={onPositiveFeedbackClick}
                            asElement={LinkElement.BUTTON}
                            className={styles.link}
                        >
                            {localization.getString("yes")}
                        </Link>
                        <Link
                            onClick={onNegativeFeedbackClick}
                            asElement={LinkElement.BUTTON}
                            className={styles.link}
                        >
                            {localization.getString("no")}
                        </Link>
                    </div>
                )}
                {props.result?.hasResult && !showFeedback && !hasNoAnswerRes && (
                    <div className={styles.feedback}>
                        <span>{localization.getString("thanksForTheFeedback")}</span>
                    </div>
                )}
            </React.Fragment>
        )
    }

    if (props.recommendations.length > 0) {
        return (
            <PromptList
                prompts={props.recommendations}
                onPromptClick={props.onRecommendationClick}
                getPromptText={identity}
            />
        )
    }

    const cannedPrompts = [
        localization.getString("explain"),
        localization.getString("showMeHowTo"),
        localization.getString("whatIsTheBestWayTo"),
        localization.getString("whyDoINeedTo"),
    ]

    return (
        <PromptList
            prompts={cannedPrompts}
            onPromptClick={(prompt) => props.onCannedPromptClick(`${prompt} `)}
            getPromptText={(prompt) => localization.getString("somethingEllipsis", prompt)}
        />
    )
}

interface PromptListProps {
    prompts: string[]
    onPromptClick(prompt: string): void
    getPromptText(prompt: string): string
}

function PromptList(props: PromptListProps): JSX.Element {
    const localization = useServiceLocalization()

    return (
        <ul className={styles.promptList}>
            <li className={styles.promptListTitle}>
                {localization.getString("typeYourQuestionOrChoosePrompt")}
            </li>
            {props.prompts.map((prompt) => {
                const onClick: React.MouseEventHandler = (event) => {
                    event.preventDefault()
                    props.onPromptClick(prompt)
                }

                const promptText = props.getPromptText(prompt)

                return (
                    <li key={prompt} className={styles.promptListItem}>
                        <button
                            aria-label={localization.getString("askSomething", promptText)}
                            onClick={onClick}
                            className={styles.promptButton}
                        >
                            {promptText}
                        </button>
                    </li>
                )
            })}
        </ul>
    )
}

function identity<T>(value: T): T {
    return value
}
