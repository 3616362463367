import { DialogActions, DialogContent } from "@mui/material"
import React from "react"

import {
    Button,
    ButtonElement,
    ButtonType,
} from "../../../../../../components/button/Button.component"
import { ModalRef, useServiceLocalization } from "../../../../../../pre-v3/services"
import { AppText } from "../../../../../components/app-text/AppText.component"
import { TrustFactor, TRUST_EFFECT } from "../../../../../services/TrustProfile.service"
import { EffectSlider } from "../trustProfiles/shared/EffectSlider.component"
import { TrustEffectDescription } from "../trustProfiles/shared/TrustEffectDescription.component"
import styles from "./EditEffectDialog.module.scss"

interface Props {
    modalRef: ModalRef
    trustFactor: TrustFactor
}

export function EditEffectDialog(props: Props): JSX.Element {
    const localization = useServiceLocalization()

    const [effect, setEffect] = React.useState(props.trustFactor.effect)

    return (
        <React.Fragment>
            <DialogContent className={styles.content}>
                <div className={styles.left}>
                    <AppText
                        ls={{
                            key: "ifFactorIsNotSatisfiedTheEffectWillBe",
                            replaceVals: [props.trustFactor.label],
                        }}
                    />
                    <EffectSlider
                        value={effect}
                        data={Object.values(TRUST_EFFECT)}
                        onChange={setEffect}
                    />
                </div>
                <TrustEffectDescription effect={effect} className={styles.right} />
            </DialogContent>
            <DialogActions>
                <Button
                    asElement={ButtonElement.BUTTON}
                    buttonType={ButtonType.SECONDARY}
                    onClick={props.modalRef.cancel}
                >
                    {localization.getString("cancel")}
                </Button>
                <Button
                    asElement={ButtonElement.BUTTON}
                    buttonType={ButtonType.PRIMARY}
                    onClick={() => props.modalRef.close(effect)}
                >
                    {localization.getString("save")}
                </Button>
            </DialogActions>
        </React.Fragment>
    )
}
