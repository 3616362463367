import { Ref, useCallback, useState } from "react"

export function useCallbackRef<T>(): ReturnValue<T> {
    const [el, setEl] = useState<T>()

    const ref = useCallback((node: T) => {
        setEl(node)
    }, [])

    return [ref, el]
}

type ReturnValue<T> = [Ref<T>, T | undefined]
