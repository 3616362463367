import React from "react"
import { faArrowRight } from "@fortawesome/pro-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"

import { useServiceLocalization } from "../../../../../../../../pre-v3/services"
import styles from "./FactorContainer.module.scss"

interface Props {
    sourceLabel: string
    logo: React.ReactNode
    description?: string
    children?: React.ReactNode
    selectedFactorLabel: string
    onAddTrustFactor(): void
}

export function FactorContainer(props: Props): JSX.Element {
    const localizationService = useServiceLocalization()

    const onClick: React.MouseEventHandler = (event) => {
        event.preventDefault()
        props.onAddTrustFactor()
    }

    return (
        <div className={styles.container}>
            {props.logo}
            <div className={styles.content}>
                <h6>{props.sourceLabel}</h6>
                {props.description && <p>{props.description}</p>}
                {props.children}
            </div>
            <button
                className={styles.button}
                onClick={onClick}
                aria-label={localizationService.getString(
                    "addSomething",
                    props.selectedFactorLabel
                )}
            >
                <FontAwesomeIcon icon={faArrowRight} />
            </button>
        </div>
    )
}
