import {
    QueryKey,
    UseMutationResult,
    UseQueryResult,
    useMutation,
    useQuery,
    useQueryClient,
} from "@tanstack/react-query"

import { LanguageKey } from "../../pre-v3/services/localization/languages/en-US.language"
import { Paginated, PaginatedSearch } from "../../pre-v3/utils/AgGrid.util"
import { DateUtil } from "../../pre-v3/utils/Date.util"
import {
    GetServiceAccountsParams,
    ServiceAccountApi,
    ServiceAccountReq,
    ServiceAccountRes,
} from "../api/ServiceAccount.api"
import JsonStableStringify from "json-stable-stringify"

enum ServiceAccountHookKey {
    GET_SERVICE_ACCOUNTS = "serviceAccountService.getServiceAccounts",
    GET_SERVICE_ACCOUNT = "serviceAccountService.getServiceAccount",
}

export async function getServiceAccounts(
    search?: PaginatedSearch
): Promise<Paginated<ServiceAccount>> {
    const serviceAccountApi = new ServiceAccountApi()

    const { count, service_accounts } = await serviceAccountApi.getServiceAccounts(
        getParams(search)
    )

    return {
        data: service_accounts.map(mapServiceAccountRes),
        total: count,
    }
}

function getServiceAccountsKey(params?: string): QueryKey {
    return [ServiceAccountHookKey.GET_SERVICE_ACCOUNTS, ...(params ? [params] : [])]
}

export function useGetServiceAccounts(
    params: PaginatedSearch = { skip: 0, limit: 1000 },
    options?: QueryOptions<Paginated<ServiceAccount>>
): UseQueryResult<Paginated<ServiceAccount>> {
    const paramsKey: string = JsonStableStringify(params)

    return useQuery({
        ...options,
        queryKey: getServiceAccountsKey(paramsKey),
        queryFn: async () => {
            return getServiceAccounts(params)
        },
    })
}

function getServiceAccountKey(id: string): QueryKey {
    return [ServiceAccountHookKey.GET_SERVICE_ACCOUNT, id]
}

export function useCreateServiceAccount(
    options?: QueryOptions<ServiceAccount, unknown, NewServiceAccount>
): UseMutationResult<ServiceAccount, unknown, NewServiceAccount> {
    const queryClient = useQueryClient()

    const serviceAccountApi = new ServiceAccountApi()

    return useMutation({
        ...options,
        mutationFn: async (newServiceAccount: NewServiceAccount) => {
            const serviceAccountRes = await serviceAccountApi.createServiceAccount(
                toServiceAccountReq(newServiceAccount)
            )

            return mapServiceAccountRes(serviceAccountRes)
        },
        onSuccess: (serviceAccount) => {
            queryClient.removeQueries(getServiceAccountsKey())
            queryClient.setQueryData(getServiceAccountKey(serviceAccount.id), serviceAccount)
            options?.onSuccess?.(serviceAccount)
        },
    })
}

export function useGetServiceAccount(
    id: string,
    options?: QueryOptions<ServiceAccount>
): UseQueryResult<ServiceAccount | null> {
    const serviceAccountApi = new ServiceAccountApi()

    return useQuery({
        ...options,
        queryKey: getServiceAccountKey(id),
        queryFn: async () => {
            const serviceAccountsRes = await serviceAccountApi.getServiceAccounts({ id })

            const [serviceAccountRes] = serviceAccountsRes.service_accounts

            return serviceAccountRes ? mapServiceAccountRes(serviceAccountRes) : null
        },
    })
}

export function useUpdateServiceAccount(
    options?: QueryOptions<ServiceAccount, unknown, ServiceAccount>
): UseMutationResult<ServiceAccount, unknown, ServiceAccount> {
    const serviceAccountApi = new ServiceAccountApi()

    const queryClient = useQueryClient()

    return useMutation({
        ...options,
        mutationFn: async (serviceAccount: ServiceAccount) => {
            const serviceAccountRes = await serviceAccountApi.updateServiceAccount(
                serviceAccount.id,
                toServiceAccountReq(serviceAccount)
            )

            return mapServiceAccountRes(serviceAccountRes)
        },
        onSuccess: (serviceAccount) => {
            queryClient.removeQueries(getServiceAccountsKey())
            queryClient.setQueryData(getServiceAccountKey(serviceAccount.id), serviceAccount)
            options?.onSuccess?.(serviceAccount)
        },
    })
}

export function useDeleteServiceAccount(
    options?: QueryOptions<void, unknown, ServiceAccount>
): UseMutationResult<void, unknown, ServiceAccount> {
    const serviceAccountApi = new ServiceAccountApi()

    const queryClient = useQueryClient()

    return useMutation({
        ...options,
        mutationFn: (serviceAccount) => serviceAccountApi.deleteServiceAccount(serviceAccount.id),
        onSuccess: (_data, serviceAccount) => {
            queryClient.removeQueries(getServiceAccountsKey())
            queryClient.removeQueries(getServiceAccountKey(serviceAccount.id))
            options?.onSuccess?.()
        },
    })
}

export interface ServiceAccount {
    id: string
    name: string
    description?: string
    details: ServiceAccountDetails
    roles: string[]
    updatedAt: number
    updatedBy: string
    lastAuthorizedAt: number
}

export interface NewServiceAccount {
    name: string
    description?: string
    details: ServiceAccountDetails
}

export enum ServiceAccountType {
    BANYAN_GENERATED = "banyankey",
    API_KEY = "externalkey",
    JWT = "jwttoken",
}

export const serviceAccountTypeLabelDict: Record<ServiceAccountType, LanguageKey> = {
    [ServiceAccountType.API_KEY]: "externalApiKey",
    [ServiceAccountType.BANYAN_GENERATED]: "sonicWallCseGeneratedKey",
    [ServiceAccountType.JWT]: "externalJwtKey",
}

export interface BanyanKeyDetails {
    type: ServiceAccountType.BANYAN_GENERATED
    apiKey: string
}

export interface ApiKeyDetails {
    type: ServiceAccountType.API_KEY
    apiKey: string
}

interface JwtTokenDetails {
    type: ServiceAccountType.JWT
    issuer: string
    audience: string
    subjects: string[]
}

export type ServiceAccountDetails = BanyanKeyDetails | ApiKeyDetails | JwtTokenDetails

function getParams(search?: PaginatedSearch): Partial<GetServiceAccountsParams> {
    const { name, "details.type": type } = search?.filterModel ?? {}

    return {
        skip: search?.skip,
        limit: search?.limit,
        name_like: name?.filter,
        service_account_type: isServiceAccountType(type?.filter) ? type?.filter : undefined,
        sort: search?.sortModel?.[0]?.sort,
        order_by: isServiceAccountOrderBy(search?.sortModel?.[0]?.colId)
            ? search?.sortModel?.[0].colId
            : undefined,
    }
}

function isServiceAccountType(
    type?: string
): type is GetServiceAccountsParams["service_account_type"] {
    return ["banyankey", "externalkey", "jwttoken"].includes(type as string)
}

function isServiceAccountOrderBy(
    orderBy?: string
): orderBy is GetServiceAccountsParams["order_by"] {
    return ["name", "description"].includes(orderBy as string)
}

function mapServiceAccountRes(serviceAccountRes: ServiceAccountRes): ServiceAccount {
    return {
        id: serviceAccountRes.id,
        name: serviceAccountRes.name,
        description: serviceAccountRes.description || undefined,
        details: getDetails(serviceAccountRes),
        roles: serviceAccountRes.roles,
        updatedAt: DateUtil.convertLargeTimestamp(serviceAccountRes.updated_at),
        updatedBy: serviceAccountRes.updated_by,
        lastAuthorizedAt: DateUtil.convertLargeTimestamp(serviceAccountRes.last_authorized_at),
    }
}

function getDetails(serviceAccountRes: ServiceAccountRes): ServiceAccountDetails {
    switch (serviceAccountRes.service_account_type) {
        case "banyankey":
            if (!serviceAccountRes.api_key) {
                throw new Error("Invalid API key in Service Account response")
            }

            return { type: ServiceAccountType.BANYAN_GENERATED, apiKey: serviceAccountRes.api_key }

        case "externalkey":
            if (!serviceAccountRes.api_key) {
                throw new Error("Invalid API key in Service Account response")
            }

            return {
                type: ServiceAccountType.API_KEY,
                apiKey: serviceAccountRes.api_key,
            }

        case "jwttoken": {
            const { oidc_tokens } = serviceAccountRes

            if (!oidc_tokens || oidc_tokens.claims.length <= 0) {
                throw new Error("Invalid JWT token in Service Account response")
            }

            return {
                type: ServiceAccountType.JWT,
                audience: oidc_tokens.claims[0].aud,
                issuer: oidc_tokens.claims[0].iss,
                subjects: oidc_tokens.claims.map((claim) => claim.sub),
            }
        }
    }
}

function toServiceAccountReq(newServiceAccount: NewServiceAccount): ServiceAccountReq {
    const { name, description, details } = newServiceAccount

    const baseServiceAccount: Pick<ServiceAccountReq, "name" | "description"> = {
        name,
        description,
    }

    switch (details.type) {
        case ServiceAccountType.BANYAN_GENERATED:
            return {
                ...baseServiceAccount,
                service_account_type: "banyankey",
            }

        case ServiceAccountType.API_KEY:
            return {
                ...baseServiceAccount,
                service_account_type: "externalkey",
                api_key: details.apiKey,
            }

        case ServiceAccountType.JWT:
            const { audience, issuer, subjects } = details

            return {
                ...baseServiceAccount,
                service_account_type: "jwttoken",
                oidc_tokens: {
                    type: "gcp",
                    claims: subjects.map((subject) => ({
                        aud: audience,
                        iss: issuer,
                        sub: subject,
                    })),
                },
            }
    }
}
