import { useQuery } from "@tanstack/react-query"
import React from "react"

import { Banner, Variant } from "../../../components/banner/Banner.component"
import { useFeatureFlags } from "../../../hooks/useFeatureFlags.hook"
import useTitle from "../../../hooks/useTitle.hook"
import { LicenseEnforcementBanner } from "../../../pages/shared/LicenseEnforcementBanner.component"
import { Loader } from "../../../v3/components/loader/Loader.component"
import { AppBanner } from "../../../v3/views/app/AppBanner.component"
import { Connectors } from "../../../v3/views/dashboard/Connectors.component"
import { useServiceReporting } from "../../services/Reporting.service"
import styles from "./Dashboard.module.scss"
import { Devices } from "./widgets/Devices"
import { UserCount } from "./widgets/UserCount"
import { Services } from "./widgets/Services"
import { Policies } from "./widgets/Policies"
import { RoleCount } from "./widgets/RoleCount"
import { AccessTiers } from "./widgets/AccessTiers"
import { Top10 } from "./widgets/Top10"
import { ServicesBars } from "./widgets/ServicesBars"
import { DevicesSankey } from "./widgets/DevicesSankey"
import { DateUtil, TimeRange, TimeRangeName } from "../../utils/Date.util"
import { NoDataView } from "./NoDataView"
import { DateRangeSelect } from "../../../v3/components/date-range-select/DateRangeSelect.component"

export function Dashboard() {
    useTitle(["overview", "home", "adminConsole"])

    const reportingService = useServiceReporting()

    // we need to window the graphs to a particular time
    const [timeRange, setTimeRange] = React.useState<TimeRange>(initialTimeRange)

    const {
        data: featureFlags,
        status: featureFlagsStatus,
        error: featureFlagsError,
    } = useFeatureFlags()

    // we might need to show the "no data view"
    const {
        data: areThereAccessedServices,
        status: areThereServicesStatus,
        error: areThereServicesError,
    } = useQuery({
        queryKey: ["areThereServices"],
        queryFn: () => reportingService.areThereAccessedServices(),
    })

    if (featureFlagsStatus === "loading" || areThereServicesStatus === "loading") {
        return <Loader center medium title="Loading Home Overview" />
    }

    if (featureFlagsStatus === "error" || areThereServicesStatus === "error") {
        return (
            <Banner
                label={String(featureFlagsError) || String(areThereServicesError)}
                variant={Variant.ERROR}
            />
        )
    }

    return (
        <React.Fragment>
            {featureFlags.adminConsole.showLicenseEnforcementBannerInHomeOverview && (
                <LicenseEnforcementBanner />
            )}
            <section aria-labelledby={Id.HEADING}>
                <AppBanner />
                <article className={styles.container}>
                    {areThereAccessedServices ? (
                        <>
                            <header className={styles.header}>
                                <DateRangeSelect
                                    timeRange={timeRange}
                                    onTimeRangeChange={setTimeRange}
                                />
                            </header>
                            <section className={styles.dashboardGrid}>
                                <UserCount />
                                <RoleCount />
                                <Top10 timeRange={timeRange} />
                                <Devices />
                                {featureFlags.adminConsole.emphasizeGlobalEdge ? (
                                    <Connectors />
                                ) : (
                                    <AccessTiers />
                                )}
                                <Policies />
                                <Services />
                                <ServicesBars timeRange={timeRange} />
                                <DevicesSankey timeRange={timeRange} />
                            </section>
                        </>
                    ) : (
                        <NoDataView headingId={Id.HEADING} />
                    )}
                </article>
            </section>
        </React.Fragment>
    )
}

const initialTimeRange = DateUtil.convertDeltaToTimeRange({
    name: TimeRangeName.LAST_30_DAYS,
    delta: DateUtil.DAY * 30,
})

enum Id {
    HEADING = "heading",
}
