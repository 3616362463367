import React from "react"
import styles from "./JsonTextArea.module.scss"
import classNames from "classnames/bind"
import { Button } from "../button/Button.component"
import AceEditor from "react-ace"
// eslint-disable-next-line
import brace from "brace"
import "brace/mode/json"
import "brace/theme/tomorrow"

export default function () {
    return (
        <div className={classNames(styles.jsonTextArea, this.props.className)}>
            <AceEditor
                mode="json"
                theme="tomorrow"
                onChange={this.onValueChange}
                name={this.name}
                width="100%"
                height={this.props.height || "500px"}
                fontSize="14px"
                showPrintMargin={false}
                onLoad={this.onLoad}
                highlightActiveLine={!this.props.readOnly}
                readOnly={this.props.readOnly}
                style={this.props.readOnly ? { backgroundColor: "#EAEBF2" } : {}}
                value={this.state.json}
            ></AceEditor>
            <div className={styles.jsonActions}>
                <Button className={styles.copyButton} textPrimary onClick={this.onToggleFold}>
                    {this.state.foldChildren
                        ? this.localizationService.getString("expandAll")
                        : this.localizationService.getString("collapseAll")}
                </Button>
                {this.props.readOnly && (
                    <Button className={styles.copyButton} primary onClick={this.onCopy}>
                        {this.localizationService.getString("copy")}
                    </Button>
                )}
            </div>
        </div>
    )
}
