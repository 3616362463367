import React from "react"
import { Redirect, Route, Switch } from "react-router-dom"
import { DataPolicyList } from "../../test-drive/data-policy/list/DataPolicyList.view"
import { DataPolicyOverview } from "../../test-drive/data-policy/overview/DataPolicyOverview.view"
import { ROUTE } from "../../routes"

export function DataLostPrevention() {
    return (
        <Switch>
            <Route path={ROUTE.DATA_LOST_PREVENTION} component={DataPolicyList} exact />
            <Route path={ROUTE.DATA_LOST_PREVENTION_DETAILS} component={DataPolicyOverview} exact />
            <Redirect to={ROUTE.DATA_LOST_PREVENTION} />
        </Switch>
    )
}
