import * as React from "react"
import { BannerType, ErrorBanner, InfoBanner, WarningBanner } from "../../../components"
import { ModalRef } from "../../../services/Modal.service"
import styles from "./ServicesOverview.module.scss"

interface ConvertToCustomServiceModalProps {
    modalRef: ModalRef
    text: string
    bannerText?: string
    bannerType?: BannerType
    className?: string
}

export const ConvertToCustomServiceModal = ({
    bannerText,
    text,
    bannerType,
    className,
}: ConvertToCustomServiceModalProps) => (
    <div className={className}>
        {bannerType && (
            <div className={styles.banner}>
                {bannerType === BannerType.WARNING && <WarningBanner>{bannerText}</WarningBanner>}
                {bannerType === BannerType.INFO && <InfoBanner>{bannerText}</InfoBanner>}
                {bannerType === BannerType.ERROR && <ErrorBanner>{bannerText}</ErrorBanner>}
            </div>
        )}
        <div className={styles.content}>{text}</div>
    </div>
)
