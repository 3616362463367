import React, { useState } from "react"

import { PageHeading } from "../../../components/page-heading/PageHeading.component"
import { useServiceLocalization } from "../../../pre-v3/services/localization/Localization.service"
import { PaginatedSearch } from "../../../pre-v3/utils/AgGrid.util"
import { replaceKeys } from "../../../pre-v3/utils/Collection.util"
import { Column, Grid, GridApi } from "../../../v3/components/grid/Grid.component"
import { SearchInput } from "../../../v3/components/search-input/SearchInput.component"
import { Tooltip } from "../../../v3/components/tooltip/Tooltip.component"
import {
    Button,
    ButtonElement,
    ButtonType,
    IconType,
} from "../../../components/button/Button.component"
import {
    AccessTierGroup,
    FilterById,
    useGetAccessTierGroups,
} from "../../../v3/services/AccessTierGroup.service"
import styles from "./AccessTierGroupsList.module.scss"
import { ROUTE, formatRoutePath } from "../../../routes"
import { RowTitle } from "../../../v3/components/grid/RowTitle.component"
import { encodeID } from "../../../pre-v3/utils/Url.util"
import { GRID_COLUM_ID } from "./GridColumIds"

export function AccessTierGroupsList(): JSX.Element {
    const [search, setSearch] = useState("")
    const localization = useServiceLocalization()
    const formatLastUpdatedAt = useFormatLastUpdatedAt()
    const { getAccessTierGroups, clearCache: clearAccessTierGroupsCache } = useGetAccessTierGroups()

    const gridRef = React.useRef<GridApi>(null)

    const columns: Column<AccessTierGroup>[] = [
        {
            id: GRID_COLUM_ID.NAME,
            name: localization.getString("name"),
            cellRenderer: (group) => (
                <RowTitle
                    title={group.name}
                    route={formatRoutePath(ROUTE.ACCESS_TIER_GROUPS_DETAILS, {
                        id: encodeID(group.id),
                    })}
                />
            ),
            isFilterable: true,
            getTooltipValue: GRID_COLUM_ID.NAME,
        },
        {
            id: GRID_COLUM_ID.LAST_UPDATE,
            name: localization.getString("lastUpdated"),
            cellRenderer: formatLastUpdatedAt,
            getTooltipValue: formatLastUpdatedAt,
        },
        {
            id: GRID_COLUM_ID.SHARED_ADDRESS,
            name: localization.getString("sharedAddress"),
            cellRenderer: GRID_COLUM_ID.SHARED_ADDRESS,
            getTooltipValue: GRID_COLUM_ID.SHARED_ADDRESS,
        },
    ]

    const onRefresh = async () => {
        await clearAccessTierGroupsCache()
        gridRef.current?.refreshData()
    }

    const getServerSideData = (search: PaginatedSearch<string, FilteredColumnId>) =>
        getAccessTierGroups({
            ...search,
            filterModel: search.filterModel && replaceKeys(search.filterModel, filterByIdDict),
        })

    return (
        <section aria-labelledby={Id.HEADING} className={styles.container}>
            <header className={styles.header}>
                <div>
                    <PageHeading id={Id.HEADING}>
                        {localization.getString("accessTierGroups")}
                    </PageHeading>
                    <p className={styles.subtitle}>
                        {localization.getString("addAccessTiersToYourGroups")}
                    </p>
                </div>
                <RefreshButton onRefresh={onRefresh} />
            </header>
            <div className={styles.tools}>
                <SearchInput
                    value={search}
                    onChangeValue={setSearch}
                    placeholder={localization.getString("searchByName")}
                />
                <Button
                    to={ROUTE.ACCESS_TIER_GROUPS_CREATE}
                    asElement={ButtonElement.LINK}
                    buttonType={ButtonType.PRIMARY}
                    icon={IconType.PLUS}
                >
                    {localization.getString("createAccessTierGroup")}
                </Button>
            </div>
            <Grid
                ref={gridRef}
                columns={columns}
                hasPagination
                serverSideProps={{
                    getServerSideData,
                    filterModel: { [GRID_COLUM_ID.NAME]: { filter: search } },
                    filterableKeys: [GRID_COLUM_ID.NAME],
                }}
            />
        </section>
    )
}

enum Id {
    HEADING = "heading",
}

type FilteredColumnId = GRID_COLUM_ID.NAME

const filterByIdDict: Record<FilteredColumnId, FilterById> = {
    [GRID_COLUM_ID.NAME]: FilterById.NAME,
}

function useFormatLastUpdatedAt() {
    const localization = useServiceLocalization()
    const locale = localization.getLocale()

    return (accessTierGroup: AccessTierGroup) => {
        return accessTierGroup.lastUpdatedAt.toLocaleString(locale)
    }
}

interface RefreshButtonProps {
    onRefresh(): void
}

function RefreshButton(props: RefreshButtonProps): JSX.Element {
    const localization = useServiceLocalization()

    const onRefresh: React.MouseEventHandler = (e) => {
        e.preventDefault()
        props.onRefresh()
    }

    const refreshLabel = localization.getString("refresh")

    return (
        <Tooltip title={refreshLabel}>
            <Button
                icon={IconType.REDO}
                onClick={onRefresh}
                asElement={ButtonElement.BUTTON}
                buttonType={ButtonType.SECONDARY}
                aria-label={refreshLabel}
            />
        </Tooltip>
    )
}
