import { GridApi, ColDef, GridReadyEvent } from "ag-grid-community"
import React, { ReactNode } from "react"
import { RouteComponentProps } from "react-router"

import { ROUTE } from "../../../../../../routes"
import ActionBarService from "../../../../../services/ActionBar.service"
import { LocalizationService } from "../../../../../services/localization/Localization.service"
import { Bind } from "../../../../../decorators/Bind.decorator"
import AgGridUtil from "../../../../../utils/AgGrid.util"
import { SettingsService, ServiceBundle } from "../../../../../services/Settings.service"
import ServiceBundleListTemplate from "./ServiceBundleList.template"

interface Props extends Omit<RouteComponentProps, "match" | "location"> {
    canAccessServiceBundle?: boolean
}
export class ServiceBundleList extends React.Component<Props, ServiceBundlesListState> {
    public state: ServiceBundlesListState = { readOnly: true, error: false }

    public render(): ReactNode {
        return ServiceBundleListTemplate.call(this)
    }

    public componentDidMount(): void {
        this.actionBarService.setItems(this.localizationService.getString("serviceBundles"), [
            {
                label: this.localizationService.getString("desktopAndMobile"),
            },
        ])
    }

    public componentWillUnmount(): void {
        this.actionBarService.unregisterRefreshFn(this.fetchData)
    }

    private actionBarService: ActionBarService = new ActionBarService()
    private localizationService: LocalizationService = new LocalizationService()
    private settingsService: SettingsService = new SettingsService()

    private gridApi: GridApi
    private columnDefs: ColDef[] = [
        {
            headerName: this.localizationService.getString("name"),
            field: "name",
            tooltipValueGetter: AgGridUtil.linkTooltipValueGetter,
            width: 75,
            comparator: AgGridUtil.alphaBetComparator,
            cellRenderer: "serviceBundleNameCellRenderer",
        },
        {
            headerName: this.localizationService.getString("description"),
            field: "desc",
            width: 125,
            comparator: AgGridUtil.alphaBetComparator,
        },
        {
            headerName: this.localizationService.getString("services"),
            field: "serviceIds.length",
            width: 75,
        },
        {
            headerName: this.localizationService.getString("createdAt"),
            field: "createdAt",
            width: 100,
            valueFormatter: AgGridUtil.dateFormatter,
        },
        {
            headerName: this.localizationService.getString("lastUpdated"),
            field: "lastUpdatedAt",
            width: 100,
            valueFormatter: AgGridUtil.dateFormatter,
        },
    ]

    @Bind
    private onRefresh(): void {
        this.fetchData()
    }

    @Bind
    private onGridReady(event: GridReadyEvent): void {
        this.gridApi = event.api
        this.fetchData()
    }

    @Bind
    private onAddServiceBundle(): void {
        this.props.history.push(ROUTE.BUNDLES_ADD)
    }

    @Bind
    private onFilter(value: string): void {
        if (this.gridApi) {
            this.gridApi.setQuickFilter(value)
        }
    }

    @Bind
    private fetchData(): void {
        if (this.gridApi) {
            this.gridApi.showLoadingOverlay()
            this.settingsService
                .getServiceBundles()
                .then((serviceBundles: ServiceBundle[]) => {
                    this.gridApi.hideOverlay()
                    this.gridApi.setRowData(serviceBundles)
                })
                .catch(() => {
                    this.gridApi.showNoRowsOverlay()
                    this.setState({ error: true })
                })
        }
    }
}

interface ServiceBundlesListState {
    readOnly: boolean
    error: boolean
}
