import React, { ReactNode } from "react"
import SwitchTemplate from "./Switch.template"
import { Bind } from "../../decorators/Bind.decorator"

export class Switch extends React.Component<SwitchProps, {}> {
    public render(): ReactNode {
        return SwitchTemplate.call(this)
    }

    @Bind
    private onClick(): void {
        if (this.props.onChange && !this.props.disabled) {
            this.props.onChange(!this.props.value)
        }
    }
}

interface SwitchProps {
    disabled?: boolean
    value: boolean
    onChange?: (value: boolean) => void
    className?: string
}
