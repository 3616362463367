import { faRocket, faShieldAlt, faVectorSquare } from "@fortawesome/pro-regular-svg-icons"
import React from "react"

import {
    Button,
    ButtonElement,
    ButtonType,
} from "../../../../../components/button/Button.component"
import { Container } from "../../../../../v3/components/container/Container.component"
import {
    ContactUsScreen,
    ContactUsScreenSteps,
} from "../../../../../v3/components/page-screen/PageScreen.component"
import { Switch } from "../../../../../v3/components/switch/Switch.component"
import { StatusCode } from "../../../../api/Base.api"
import {
    Form,
    FormLabel,
    ErrorBanner,
    InfoBanner,
    FormColumns,
    FormRow,
} from "../../../../components"
import { LoadMask } from "../../../../components/load-mask/LoadMask.component"
import { Select } from "../../../../components/select/Select.component"
import banyanRoute from "../../../../../images/banyan-route.svg"
import { OrgEdition } from "../../../../services/User.service"
import styles from "./CloakExceptionSettings.module.scss"
import { Input } from "../../../../../v3/components/input/Input.component"
import { MultiInput } from "../../../../../v3/components/multi-input/MultiInput.component"

export default function () {
    return this.state.edition === OrgEdition.TEAM ? (
        <Container>
            <ContactUsScreen
                image={banyanRoute}
                title={this.localizationService.getString(
                    "upgradeForSomething",
                    this.localizationService.getString("unregisteredDevices")
                )}
                docsLink={this.linkService.getLink("unregisteredDevicesDoc")}
            >
                <ContactUsScreenSteps
                    steps={[
                        {
                            icon: faShieldAlt,
                            label: this.localizationService.getString(
                                "provideMethodsThatLimitDesc"
                            ),
                        },
                        {
                            icon: faVectorSquare,
                            label: this.localizationService.getString(
                                "configureWhichIPAddressesUnregisteredDevices"
                            ),
                        },
                        {
                            icon: faRocket,
                            label: this.localizationService.getString(
                                "whenDeviceRegistrationIsChallengingDesc"
                            ),
                        },
                    ]}
                />
            </ContactUsScreen>
        </Container>
    ) : (
        <div className={styles.pagePadded}>
            {!this.state.cloakExceptions && <LoadMask></LoadMask>}
            {this.state.cloakExceptions && (
                <Form display="grid" floating labelWidth={200} onSubmit={this.onSubmit}>
                    <FormRow>
                        {this.state.success && <InfoBanner>{this.state.success}</InfoBanner>}
                        {this.state.error && <ErrorBanner>{this.state.error}</ErrorBanner>}
                    </FormRow>
                    <FormRow className={styles.formRow}>
                        <h2>{this.localizationService.getString("unregisteredDevices")}</h2>
                        <p>
                            {this.localizationService.getString("unregisteredDevicesDescription")}
                        </p>
                    </FormRow>
                    <FormRow className={styles.formRow}>
                        <h3>
                            {this.localizationService.getString(
                                "allowUnregisteredDevicesToAccessServices"
                            )}
                        </h3>
                        <p>
                            {this.localizationService.getString(
                                "allowUnregisteredDevicesToAccessServicesDescription"
                            )}
                        </p>
                    </FormRow>
                    <FormLabel title={this.localizationService.getString("cidrs")}>
                        <MultiInput
                            values={this.state.tokenWhitelistCidrs}
                            onChange={this.onTokenCidrChange}
                            placeholder={this.localizationService.getString(
                                "publicCidrPlaceholder"
                            )}
                            patternProps={{
                                pattern: this.manageService.CIDR_REGEX,
                                errorMessage:
                                    this.localizationService.getString("publicCidrPlaceholder"),
                            }}
                            disabled={!this.props.canUpdateUnregisteredDeviceConfig}
                        />
                    </FormLabel>
                    <FormRow className={styles.formRow}>
                        <h3>
                            {this.localizationService.getString(
                                "allowUnregisteredDevicesToReceiveAnHttpResponse"
                            )}
                        </h3>
                        <p>
                            {this.localizationService.getString(
                                "allowUnregisteredDevicesToReceiveAnHttpResponseDescription"
                            )}
                        </p>
                    </FormRow>
                    <FormLabel
                        title={this.localizationService.getString("cidrs")}
                        htmlFor="noTokenCidrs"
                    >
                        <MultiInput
                            values={this.state.noTokenWhitelistCidrs}
                            onChange={this.onNoTokenCidrChange}
                            placeholder={this.localizationService.getString("cidrPlaceholder")}
                            patternProps={{
                                pattern: this.manageService.CIDR_REGEX,
                                errorMessage: this.localizationService.getString("cidrPlaceholder"),
                            }}
                            disabled={!this.props.canUpdateUnregisteredDeviceConfig}
                        />
                    </FormLabel>
                    <FormLabel
                        title={this.localizationService.getString("responseType")}
                        htmlFor="responseType"
                    >
                        <Select
                            required
                            value={this.state.responseType}
                            options={this.responseTypeOptions}
                            onChange={this.onResponseTypeChange}
                            disabled={!this.props.canUpdateUnregisteredDeviceConfig}
                        />
                    </FormLabel>
                    {this.state.responseType === StatusCode.REDIRECT && (
                        <FormLabel
                            title={this.localizationService.getString("redirectLink")}
                            htmlFor="redirectLink"
                        >
                            <Input
                                id="redirectLink"
                                className={styles.formInput}
                                defaultValue={this.state.responseMessage}
                                disabled={!this.props.canUpdateUnregisteredDeviceConfig}
                            />
                        </FormLabel>
                    )}
                    {this.state.responseType === StatusCode.UNAUTHORIZED && (
                        <FormLabel
                            title={this.localizationService.getString("unauthorizedMessage")}
                            htmlFor="unauthorizedMessage"
                        >
                            <textarea
                                id="unauthorizedMessage"
                                className={styles.formTextArea}
                                defaultValue={this.state.responseMessage}
                                disabled={!this.props.canUpdateUnregisteredDeviceConfig}
                            />
                        </FormLabel>
                    )}
                    {this.props.canUpdateUnregisteredDeviceConfig && (
                        <FormColumns
                            right={
                                <Button
                                    asElement={ButtonElement.BUTTON}
                                    buttonType={ButtonType.PRIMARY}
                                    type="submit"
                                    className={styles.formSubmit}
                                    loading={this.state.submitting}
                                >
                                    {this.localizationService.getString("update")}
                                </Button>
                            }
                        />
                    )}
                </Form>
            )}
            <Form display="grid" floating>
                <FormRow className={styles.formRow}>
                    <div className={styles.container}>
                        <h3>
                            {this.localizationService.getString(
                                "enableOrgWideDeviceTrustVerification"
                            )}
                        </h3>
                        {/* TODO: Implement loading for this component */}
                        <Switch
                            className={styles.pageBreakSwitch}
                            value={this.state.authAsyncState}
                            onChange={this.onStateChange}
                            disabled={!this.props.canUpdateDeviceTrustVerfication}
                        ></Switch>
                    </div>
                    <p>
                        {this.localizationService.getString(
                            "enableOrgWideDeviceTrustVerificationDescription"
                        )}
                    </p>
                </FormRow>
            </Form>
        </div>
    )
}
