import classNames from "classnames/bind"
import * as React from "react"
import { Popover } from "@mui/material"
import { AppNavLink } from "../../services/shared/AppNavLink"
import styles from "./UserMenu.module.scss"
import { MenuItem } from ".."
import { ButtonAsLink } from "../../../v3/components/button/ButtonAsLink.component"

interface UserMenuProps {
    links: AppNavLink[]
    fullName: string
    orgName: string
    className?: string
}

export function UserMenu(props: UserMenuProps) {
    const [showMenu, setShowMenu] = React.useState(false)
    const buttonRef = React.useRef<HTMLDivElement>(null)

    return (
        <div className={classNames(styles.userMenu, props.className)} ref={buttonRef}>
            <ButtonAsLink onClick={() => setShowMenu(true)} className={styles.buttonLink}>
                {props.fullName} @ {props.orgName}
            </ButtonAsLink>
            <Popover
                anchorEl={buttonRef.current}
                anchorOrigin={{
                    vertical: "bottom",
                    horizontal: "right",
                }}
                transformOrigin={{
                    vertical: "top",
                    horizontal: "right",
                }}
                open={showMenu}
                onClose={() => setShowMenu(false)}
            >
                <ul className={styles.navLinkContainer} onClick={() => setShowMenu(false)}>
                    {props.links.map((link, index) => {
                        return (
                            <MenuItem
                                key={index}
                                href={link.href}
                                target={link.target}
                                onClick={link.onClick}
                                divider={link.divider}
                            >
                                {link.label}
                            </MenuItem>
                        )
                    })}
                </ul>
            </Popover>
        </div>
    )
}
