import React from "react"
import { Switch, Route } from "react-router-dom"

import { ROUTE } from "../../../../../routes"
import { RegistryCheckTrustFactor, TrustFactor } from "../../../../services/TrustFactor.service"
import { RegistryCheckAdd } from "./RegistryCheckAdd.view"
import { RegistryCheckEdit } from "./RegistryCheckEdit.view"
import { RegistryCheckList } from "./RegistryCheckList.view"

interface Props {
    registryCheckTrustFactor?: RegistryCheckTrustFactor
    isLoading: boolean
    onRefetchData(): Promise<TrustFactor[] | undefined>
    canAccessRegistryCheckFactor?: boolean
}

export function RegistryCheck(props: Props): JSX.Element {
    return (
        <Switch>
            <Route path={ROUTE.FACTORS_REGISTRY_CHECK_CREATE}>
                <RegistryCheckAdd
                    canAccessRegistryCheckFactor={props.canAccessRegistryCheckFactor}
                />
            </Route>
            <Route path={ROUTE.FACTORS_REGISTRY_CHECK_DETAILS}>
                <RegistryCheckEdit {...props} />
            </Route>
            <Route>
                <RegistryCheckList {...props} />
            </Route>
        </Switch>
    )
}
