import React from "react"

import { useServiceLocalization } from "../../../../../pre-v3/services"
import { StringUtil } from "../../../../../pre-v3/utils/String.util"
import { encodeID } from "../../../../../pre-v3/utils/Url.util"
import { ROUTE, formatRoutePath } from "../../../../../routes"
import { Column, Grid } from "../../../../components/grid/Grid.component"
import { RowTitle, rowTitleClassName } from "../../../../components/grid/RowTitle.component"
import { SearchInput } from "../../../../components/search-input/SearchInput.component"
import {
    RegistryCheck,
    RegistryCheckTrustFactor,
    TrustFactor,
} from "../../../../services/TrustFactor.service"
import styles from "./RegistryCheckList.module.scss"
import trustStyles from "../../../../../pages/trust/Trust.module.scss"
import { PageHeading } from "../../../../../components/page-heading/PageHeading.component"
import {
    Button,
    ButtonElement,
    ButtonType,
    IconType,
} from "../../../../../components/button/Button.component"
import { useHistory } from "react-router-dom"
import { Tooltip } from "../../../../components/tooltip/Tooltip.component"

interface Props {
    registryCheckTrustFactor?: RegistryCheckTrustFactor
    isLoading: boolean
    onRefetchData(): Promise<TrustFactor[] | undefined>
    canAccessRegistryCheckFactor?: boolean
}

export function RegistryCheckList(props: Props): JSX.Element {
    const localization = useServiceLocalization()
    const history = useHistory()
    const searchLabel = localization.getString("searchByName")
    const addRegistryCheckLabel = localization.getString("addRegistryKey")

    const [searchTerm, setSearchTerm] = React.useState("")

    const columns = useColumns()

    const filteredRegistryCheck = React.useMemo(() => {
        const registryChecks = props.registryCheckTrustFactor?.registryChecks || []

        return searchTerm
            ? registryChecks.filter((registryCheck) =>
                  StringUtil.caseInsensitiveIncludes(registryCheck.name, searchTerm)
              )
            : registryChecks
    }, [props.registryCheckTrustFactor, searchTerm])

    function onAddRegistryCheckList() {
        history.push(ROUTE.FACTORS_REGISTRY_CHECK_CREATE)
    }

    return (
        <section aria-labelledby={Id.HEADING} className={trustStyles.section}>
            <header className={trustStyles.header}>
                <PageHeading id={Id.HEADING}>{localization.getString("registryCheck")}</PageHeading>
                <Tooltip title={localization.getString("refresh")}>
                    <Button
                        icon={IconType.REDO}
                        onClick={() => props.onRefetchData()}
                        asElement={ButtonElement.BUTTON}
                        buttonType={ButtonType.SECONDARY}
                        aria-label={localization.getString("refresh")}
                    />
                </Tooltip>
            </header>
            <div className={styles.toolbar}>
                <SearchInput
                    value={searchTerm}
                    onChangeValue={setSearchTerm}
                    placeholder={searchLabel}
                    aria-label={searchLabel}
                    className={styles.searchInput}
                />
                {props.canAccessRegistryCheckFactor && (
                    <Button
                        buttonType={ButtonType.PRIMARY}
                        asElement={ButtonElement.BUTTON}
                        onClick={onAddRegistryCheckList}
                        icon={IconType.PLUS}
                    >
                        {addRegistryCheckLabel}
                    </Button>
                )}
            </div>
            <Grid
                columns={columns}
                data={props.isLoading ? undefined : filteredRegistryCheck}
                className={styles.grid}
            />
        </section>
    )
}

function useColumns(): Column<RegistryCheck>[] {
    const localization = useServiceLocalization()
    const nameLabel = localization.getString("name")
    const keyLabel = localization.getString("key")
    const valueLabel = localization.getString("value")

    return React.useMemo(
        (): Column<RegistryCheck>[] => [
            {
                id: "name",
                name: nameLabel,
                cellRenderer: (registryCheck) => (
                    <RowTitle
                        title={registryCheck.name}
                        route={formatRoutePath(ROUTE.FACTORS_REGISTRY_CHECK_DETAILS, {
                            id: encodeID(registryCheck.id),
                        })}
                    />
                ),
                cellClassName: rowTitleClassName,
                getTooltipValue: "name",
            },
            { id: "key", name: keyLabel, cellRenderer: getCheck, getTooltipValue: getCheck },
            { id: "value", name: valueLabel, cellRenderer: getValue, getTooltipValue: getValue },
        ],
        []
    )
}

function getCheck(registryCheck: RegistryCheck): string {
    return registryCheck.configuration.key
}

function getValue(registryCheck: RegistryCheck): string {
    return registryCheck.configuration.value
}

enum Id {
    HEADING = "heading",
}
