import React from "react"
import styles from "./InputWithAction.module.scss"
import classNames from "classnames/bind"
import { Input, FormLabelContext } from ".."

export default function () {
    return (
        <div className={classNames(styles.container, this.props.className)}>
            <FormLabelContext.Consumer>
                {({ htmlFor }) => (
                    <Input
                        type={this.props.type}
                        disabled={this.props.disabled}
                        required={this.props.required}
                        readOnly={this.props.readOnly}
                        onChange={this.props.onChange}
                        placeholder={this.props.placeholder}
                        value={this.props.value}
                        id={this.props.id || htmlFor}
                        name={this.props.name || htmlFor}
                        ref={this.props.innerRef}
                        autoFocus={this.props.autoFocus}
                        onKeyPress={this.props.onKeyPress}
                        onKeyDown={this.props.onKeyDown}
                        className={classNames(styles.input)}
                    />
                )}
            </FormLabelContext.Consumer>
            {this.props.onAction && (
                <div
                    onClick={this.props.onAction}
                    className={classNames(styles.action, this.props.actionClassName)}
                >
                    {this.props.children}
                </div>
            )}
        </div>
    )
}
