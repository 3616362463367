import { ICellRendererParams } from "ag-grid-community"
import React, { ReactNode, SyntheticEvent } from "react"

import { ROUTE, formatRoutePath } from "../../../../routes"
import { Bind } from "../../../decorators/Bind.decorator"
import ClustersCellRendererTemplate from "./ClustersCellRenderer.template"

export class ClustersCellRenderer extends React.Component<
    ClustersCellRendererProps,
    ClustersCellRendererState
> {
    public static getDerivedStateFromProps(
        props: ClustersCellRendererProps
    ): ClustersCellRendererState {
        return {
            link: formatRoutePath(ROUTE.CLUSTERS_DETAILS, {
                clusterName: props.value || props.data.name,
            }),
        }
    }

    public state: ClustersCellRendererState = { link: "" }

    public render(): ReactNode {
        return ClustersCellRendererTemplate.call(this)
    }

    public refresh(): boolean {
        return true
    }

    @Bind
    private clickHandler(event: SyntheticEvent): void {
        event.preventDefault()
        this.props.context.history.push(this.state.link)
    }
}

interface ClustersCellRendererProps extends ICellRendererParams {
    data: {
        name?: string
    }
    context: any
}

interface ClustersCellRendererState {
    link: string
}
