import React from "react"
import styles from "./Upsell.module.scss"
import { PageHeading } from "../../components/page-heading/PageHeading.component"
import { useServiceLinks, useServiceLocalization } from "../../pre-v3/services"
import spaImage from "../../images/spa.svg"
import siaImage from "../../images/sia.svg"
import { LanguageKey } from "../../pre-v3/services/localization/languages/en-US.language"
import { GenericPrimaryLabel, GenericSecondaryLabel } from "../../components/label/Label.component"
import { Button, ButtonElement, ButtonType } from "../../components/button/Button.component"

interface Features {
    type: "Basic" | "Advanced"
    name: LanguageKey
    description: LanguageKey
}

const spaFeatures: Features[] = [
    {
        type: "Basic",
        name: "modernCloudVpn",
        description: "modernCloudVpnDescription",
    },
    {
        type: "Basic",
        name: "secureNetworkAccess",
        description: "secureNetworkAccessDescription",
    },
    {
        type: "Advanced",
        name: "zeroTouchNetworkAccess",
        description: "zeroTouchNetworkAccessDescription",
    },
    {
        type: "Advanced",
        name: "enhancedManagementOfUsersAndDevices",
        description: "enhancedManagementOfUsersAndDevicesDescription",
    },
    {
        type: "Advanced",
        name: "seamlessJourneyToServicesModel",
        description: "seamlessJourneyToServicesModelDescription",
    },
]

const siaFeatures: Features[] = [
    {
        type: "Basic",
        name: "domainBasedWebFiltering",
        description: "domainBasedWebFilteringDescription",
    },
    {
        type: "Advanced",
        name: "urlBasedWebFiltering",
        description: "urlBasedWebFilteringDescription",
    },
    {
        type: "Advanced",
        name: "enhancedVisibilityAndCompliance",
        description: "enhancedVisibilityAndComplianceDescription",
    },
    {
        type: "Advanced",
        name: "comprehensiveAccessControlsForSaasApps",
        description: "comprehensiveAccessControlsForSaasAppsDescription",
    },
]

export function Upsell() {
    const linkService = useServiceLinks()
    const localization = useServiceLocalization()

    return (
        <div className={styles.upsell}>
            <PageHeading id={Id.HEADING} className={styles.heading}>
                {localization.getString("protectYourOrganizationUpgradeToAccessThisFeature")}
            </PageHeading>

            <div className={styles.description}>{localization.getString("upsellDescription")}</div>

            <div className={styles.plans}>
                <div className={styles.planCard}>
                    <div className={styles.cardTitle}>
                        {localization.getString("securePrivateAccessPlans")}
                    </div>
                    <img src={spaImage} alt="" width={"295px"} />

                    <FeatureTable features={spaFeatures} />
                </div>

                <div className={styles.planCard}>
                    <div className={styles.cardTitle}>
                        {localization.getString("secureInternetAccessPlans")}
                    </div>
                    <img src={siaImage} alt="" width={"255px"} />

                    <FeatureTable features={siaFeatures} />

                    <div className={styles.cardFooter}>
                        <Button
                            type="button"
                            asElement={ButtonElement.LINK}
                            buttonType={ButtonType.PRIMARY}
                            to={linkService.getLink("salesContact")}
                        >
                            {localization.getString("contactSales")}
                        </Button>
                    </div>
                </div>
            </div>
        </div>
    )
}

function FeatureTable({ features }: { features: Features[] }) {
    const localization = useServiceLocalization()

    return (
        <div className={styles.featuresTable}>
            {features.map((feature) => {
                return (
                    <div key={feature.name} className={styles.feature}>
                        <div className={styles.featureType}>
                            {feature.type === "Basic" ? (
                                <GenericPrimaryLabel>{feature.type}</GenericPrimaryLabel>
                            ) : (
                                <GenericSecondaryLabel darkMode>
                                    {feature.type}
                                </GenericSecondaryLabel>
                            )}
                        </div>

                        <div>
                            <div className={styles.featureName}>
                                {localization.getString(feature.name)}
                            </div>
                            <div className={styles.featureDescription}>
                                {localization.getString(feature.description)}
                            </div>
                        </div>
                    </div>
                )
            })}
        </div>
    )
}

enum Id {
    HEADING = "heading",
}
