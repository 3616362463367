import { AuthStore } from "../services/Auth.store"
import { FormUtil } from "../utils/Form.util"

export class BaseApi {
    private authStore: AuthStore = new AuthStore()

    protected get<R>(url: string): Promise<R> {
        const authToken: string = this.authStore.getJwtToken()
        const headers: Headers = new Headers()
        headers.set("Accept", "application/json")
        if (authToken && authToken.length > 0) {
            headers.set("Authorization", "Bearer " + authToken)
        }
        return fetch(url, {
            method: "get",
            headers: headers,
        }).then(this.parseResponse)
    }

    protected getBlob(url: string): Promise<Blob> {
        const authToken: string = this.authStore.getJwtToken()
        const headers: Headers = new Headers()
        headers.set("Accept", "text/csv")
        if (authToken && authToken.length > 0) {
            headers.set("Authorization", "Bearer " + authToken)
        }
        return fetch(url, {
            method: "get",
            headers: headers,
        }).then(this.parseBlobResponse)
    }

    protected post<T, R>(url: string, body: T): Promise<R> {
        const authToken: string = this.authStore.getJwtToken()
        const headers: Headers = new Headers()
        headers.set("Content-Type", "application/json")
        if (authToken && authToken.length > 0) {
            headers.set("Authorization", "Bearer " + authToken)
        }
        return fetch(url, {
            method: "post",
            headers: headers,
            body: JSON.stringify(FormUtil.trimAny(body)),
        }).then(this.parseResponse)
    }

    protected postForm<T, R>(url: string, body: T): Promise<R> {
        return fetch(url, {
            method: "post",
            headers: {
                "Content-Type": "application/x-www-form-urlencoded; charset=UTF-8",
                Authorization: "Bearer " + this.authStore.getJwtToken(),
            },
            body: new URLSearchParams(<any>body),
        }).then(this.parseResponse)
    }

    protected put<T, R>(url: string, body: T): Promise<R> {
        const authToken: string = this.authStore.getJwtToken()
        const headers: Headers = new Headers()
        headers.set("Content-Type", "application/json")
        if (authToken && authToken.length > 0) {
            headers.set("Authorization", "Bearer " + authToken)
        }
        return fetch(url, {
            method: "put",
            headers: headers,
            body: JSON.stringify(FormUtil.trimAny(body)),
        }).then(this.parseResponse)
    }

    protected postCustomResponse<T>(url: string, body: T): Promise<Response> {
        const authToken: string = this.authStore.getJwtToken()
        const headers: Headers = new Headers()
        headers.set("Content-Type", "application/json")
        if (authToken && authToken.length > 0) {
            headers.set("Authorization", "Bearer " + authToken)
        }
        return fetch(url, {
            method: "post",
            headers: headers,
            body: JSON.stringify(FormUtil.trimAny(body)),
        })
    }

    protected delete<T, R>(url: string, body?: T): Promise<R> {
        const authToken: string = this.authStore.getJwtToken()
        const headers: Headers = new Headers()
        headers.set("Accept", "application/json")
        if (authToken && authToken.length > 0) {
            headers.set("Authorization", "Bearer " + authToken)
        }
        return fetch(url, {
            method: "delete",
            headers: headers,
            body: JSON.stringify(FormUtil.trimAny(body)),
        }).then(this.parseResponse)
    }

    protected patch<T, R>(url: string, body: T): Promise<R> {
        const authToken: string = this.authStore.getJwtToken()
        const headers: Headers = new Headers()
        headers.set("Content-Type", "application/json")
        if (authToken && authToken.length > 0) {
            headers.set("Authorization", "Bearer " + authToken)
        }
        return fetch(url, {
            method: "PATCH",
            headers: headers,
            body: JSON.stringify(FormUtil.trimAny(body)),
        }).then(this.parseResponse)
    }

    protected parseResponse(res: Response): Promise<any> {
        if (res.status >= 200 && res.status < 300) {
            return res.text().then((text: string) => {
                if (text.length > 0) {
                    return Promise.resolve(JSON.parse(text))
                }
                return Promise.resolve()
            })
        } else {
            return res.json().then(
                (data: any) => {
                    if (data.hasOwnProperty("error_description")) {
                        return Promise.reject({ message: data.error_description })
                    } else {
                        return Promise.reject({ message: data.Message || data.message })
                    }
                },
                () => {
                    return Promise.reject({ message: "Error: Network request failed." })
                }
            )
        }
    }

    protected parseBlobResponse(res: Response): Promise<Blob> {
        if (res.status >= 200 && res.status < 300) {
            return Promise.resolve(res.blob())
        } else {
            return res.json().then(
                (data: any) => {
                    return Promise.reject({ message: data.Message })
                },
                () => {
                    return Promise.reject({ message: "Error: Network request failed." })
                }
            )
        }
    }
}

export interface MessageRes {
    Message: string
}

export interface ApiResponse<T> {
    request_id: string
    error_code: number
    error_description: string
    data: T
}

export enum StatusCode {
    REDIRECT = 302,
    UNAUTHORIZED = 401,
}

export interface PaginationOptions {
    skip: number
    limit: number
    order?: "asc" | "desc"
    order_by?: string
}
