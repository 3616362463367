import React from "react"

import {
    Button,
    ButtonElement,
    ButtonType,
    IconType,
} from "../../../components/button/Button.component"
import { useServiceLocalization } from "../../../pre-v3/services/localization/Localization.service"
import styles from "./BannerShell.module.scss"

export interface Props {
    children: React.ReactNode
    onClose(): void
}

export function BannerShell(props: Props): JSX.Element {
    const localization = useServiceLocalization()

    return (
        <div className={styles.banner}>
            <div className={styles.content}>{props.children}</div>
            <Button
                asElement={ButtonElement.BUTTON}
                buttonType={ButtonType.SECONDARY}
                className={styles.iconButton}
                icon={IconType.CROSS}
                aria-label={localization.getString("close")}
                onClick={props.onClose}
            />
        </div>
    )
}
