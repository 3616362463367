import React, { FC, ReactNode, useCallback, useEffect, useRef } from "react"
import { Icon, IconType } from "../../../components/icon/Icon.component"
import styles from "./Modal.module.scss"
import classNames from "classnames"

export interface ModalProps {
    children: ReactNode
    open: boolean
    title?: string
    onClose?: () => void
    className?: string
    footer?: ReactNode
}

/**
 * @deprecated use "src/components/modal/Modal.component" instead
 */

export const Modal: FC<ModalProps> = ({ children, open, title, onClose, className, footer }) => {
    const dialogRef = useRef<HTMLDialogElement>(null)

    const close = useCallback(() => {
        dialogRef.current?.close()
    }, [])

    useEffect(() => {
        if (!dialogRef.current) return

        if (open) dialogRef.current.showModal()
        if (dialogRef.current.open && !open) close()
    }, [open])

    return (
        <dialog ref={dialogRef} className={classNames(styles.modal, className)} onClose={onClose}>
            <div className={styles.header}>
                {title && <h2>{title}</h2>}
                <button onClick={close} type="button">
                    <Icon icon={IconType.LIGHT_CROSS} />
                </button>
            </div>
            <div className={styles.content}>{children}</div>
            {footer}
        </dialog>
    )
}
