import { Singleton } from "../../pre-v3/decorators/Singleton.decorator"
import { UrlUtil } from "../../pre-v3/utils/Url.util"
import { BaseApi } from "./Base.api"

@Singleton("ApiKeyApi")
export class ApiKeyApi extends BaseApi {
    public getApiKeys(getApiKeysParams: GetApiKeysParams = {}): Promise<ApiKeyRes[]> {
        const params = `?${UrlUtil.convertToURLSearchParams(getApiKeysParams).toString()}`

        return this.get(`/api/v2/api_key${params}`, {
            default: this.localization.getString(
                "somethingNotFound",
                this.localization.getString("apiKeys")
            ),
        })
    }

    public getApiKey(id: string): Promise<ApiKeyRes> {
        return this.get(`/api/experimental/v2/api_key/${encodeURIComponent(id)}`, {
            default: this.localization.getString("apiKeyNotFound"),
        })
    }

    public getApiKeyScopes(): Promise<ApiKeyScope[]> {
        return this.get("/api/experimental/v2/api_key/scope", {
            default: this.localization.getString("apiKeyScopeFound"),
        })
    }

    public createApiKey(apiKey: ApiKeyReq): Promise<ApiKeyRes> {
        return this.post("/api/experimental/v2/api_key", apiKey, {
            default: this.localization.getString("failedToCreateApiKey"),
        })
    }
}

export interface GetApiKeysParams {
    scope?: ApiKeyScope
}

export interface ApiKeyRes {
    id: string
    name: string
    secret: string
    description: string
    scope: ApiKeyScope
}

export enum ApiKeyScope {
    SATELLITE = "satellite",
    CONNECTOR = "Connector",
    ADMIN = "Admin",
    SERVICE_AUTHOR = "ServiceAuthor",
    POLICY_AUTHOR = "PolicyAuthor",
    EVENT_WRITER = "EventWriter",
    READONLY = "ReadOnly",
    ACCESS_TIER = "access_tier",
}

export interface ApiKeyReq {
    name: string
    description?: string
    scope: ApiKeyScope
}
