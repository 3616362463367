import React, { useState } from "react"
import { useParams, useHistory } from "react-router-dom"

import { ROUTE } from "../../../../../../../routes"
import { Loader } from "../../../../../../components/loader/Loader.component"
import { ErrorBanners } from "../../../../../../components/banner/Banner.component"
import {
    NewTrustProfile,
    useDeleteTrustProfile,
    useGetTrustProfile,
    useUpdateDetailsAndAssignment,
} from "../../../../../../services/TrustProfile.service"
import { decodeID } from "../../../../../../../pre-v3/utils/Url.util"
import { IconType } from "../../../../../../../pre-v3/services/ActionBar.service"
import { useServiceLocalization } from "../../../../../../../pre-v3/services/localization/Localization.service"
import { useServiceModal } from "../../../../../../../pre-v3/services/Modal.service"
import { MessageContent } from "../../../../../../../pre-v3/components/modal/message/MessageContent"
import { DeleteCancelActions } from "../../../../../../../pre-v3/components/modal/delete-cancel/DeleteCancelActions"
import { OverviewTopContainer } from "../../../../../../components/overview/OverviewTopContainer/OverviewTopContainer.component"
import { DateUtil } from "../../../../../../../pre-v3/utils/Date.util"
import { Container } from "../../../../../../components/container/Container.component"
import { TabBar } from "../../../../../../components/tab-bar/TabBar.component"
import { LanguageKey } from "../../../../../../../pre-v3/services/localization/languages/en-US.language"
import { DetailsAndAssignmentForm } from "../shared/DetailsAndAssignmentForm.component"
import { TrustFactors } from "./TrustFactors.component"
import styles from "./TrustProfileOverview.module.scss"
import { PageHeading } from "../../../../../../../components/page-heading/PageHeading.component"
import {
    Button,
    ButtonElement,
    ButtonType,
} from "../../../../../../../components/button/Button.component"
import trustStyles from "../../../../../../../pages/trust/Trust.module.scss"
import { Tooltip } from "../../../../../../components/tooltip/Tooltip.component"

interface Props {
    canChangeTrustProfile: boolean
}

export function TrustProfileOverview(props: Props): JSX.Element {
    const params = useParams<{ id: string }>()

    const localization = useServiceLocalization()
    const history = useHistory()
    const modalService = useServiceModal()

    const [selectedStep, setSelectedStep] = useState(Step.DETAILS_AND_ASSIGNMENT)
    const [isEditing, setIsEditing] = useState(false)

    const {
        data: trustProfile,
        isFetching: isTrustProfileLoading,
        refetch: refetchTrustProfile,
        error: trustProfileError,
    } = useGetTrustProfile(decodeID(params.id), { enabled: Boolean(params.id) })

    const {
        mutateAsync: deleteTrustProfile,
        isLoading: isDeletingTrustProfile,
        error: deleteTrustProfileError,
    } = useDeleteTrustProfile(decodeID(params.id))

    const {
        mutateAsync: onUpdateDetailsAndAssignment,
        error: updateDetailsAndAssignmentError,
        isLoading: isUpdateDetailsAndAssignmentLoading,
    } = useUpdateDetailsAndAssignment({ onSuccess: () => setIsEditingFalse() })

    const errors = [
        typeof trustProfileError === "string" && trustProfileError,
        typeof deleteTrustProfileError === "string" && deleteTrustProfileError,
    ]

    const isLoading =
        isDeletingTrustProfile || isTrustProfileLoading || isUpdateDetailsAndAssignmentLoading

    const detailsAndAssignmentFormErrors = [
        typeof updateDetailsAndAssignmentError === "string" && updateDetailsAndAssignmentError,
    ]

    function setIsEditingFalse() {
        setIsEditing(false)
    }

    function onDelete() {
        modalService
            .open(
                localization.getString("deleteTrustProfile"),
                {
                    component: MessageContent,
                    props: {
                        text: localization.getString(
                            "onceDeletedAllRelevantDataAboutThisTrustProfileWillBeRemovedFromSonicWallCse"
                        ),
                    },
                },
                {
                    component: DeleteCancelActions,
                    props: {
                        okString: localization.getString("deleteTrustProfile"),
                    },
                }
            )
            .onClose(async () => {
                await deleteTrustProfile()
                history.push(ROUTE.PROFILES)
            })
    }

    function onCopy() {
        history.push(`${ROUTE.PROFILES_CREATE}?trustProfileId=${params.id}`)
    }

    function getTabProps(step: Step) {
        return {
            id: step,
            label: localization.getString(StepLabel[step]),
            ariaControls: StepAriaControl[step],
        }
    }

    async function onUpdateDetailsAndAssignmentEdit(formValues: NewTrustProfile) {
        await onUpdateDetailsAndAssignment({
            id: decodeID(params.id),
            ...formValues,
        })
    }

    return (
        <div className={styles.container}>
            <section aria-labelledby={Id.HEADING} className={trustStyles.section}>
                <header className={trustStyles.header}>
                    <PageHeading id={Id.HEADING}>{trustProfile?.name}</PageHeading>
                    <div className={styles.actionButtons}>
                        {props.canChangeTrustProfile && (
                            <>
                                <Tooltip title={localization.getString("delete")}>
                                    <Button
                                        icon={IconType.TRASH}
                                        onClick={onDelete}
                                        asElement={ButtonElement.BUTTON}
                                        buttonType={ButtonType.SECONDARY}
                                        aria-label={localization.getString("delete")}
                                        disabled={isLoading}
                                    />
                                </Tooltip>
                                <Tooltip title={localization.getString("copy")}>
                                    <Button
                                        icon={IconType.COPY}
                                        onClick={onCopy}
                                        asElement={ButtonElement.BUTTON}
                                        buttonType={ButtonType.SECONDARY}
                                        aria-label={localization.getString("copy")}
                                        disabled={isLoading}
                                    />
                                </Tooltip>
                                <Tooltip title={localization.getString("edit")}>
                                    <Button
                                        icon={IconType.PEN}
                                        onClick={() => {
                                            if (
                                                trustProfile?.isDefaultProfile &&
                                                selectedStep === Step.DETAILS_AND_ASSIGNMENT
                                            ) {
                                                setSelectedStep(Step.TRUST_FACTORS)
                                            }
                                            setIsEditing(true)
                                        }}
                                        asElement={ButtonElement.BUTTON}
                                        buttonType={ButtonType.SECONDARY}
                                        aria-label={localization.getString("edit")}
                                        disabled={isEditing}
                                    />
                                </Tooltip>
                            </>
                        )}
                        <Tooltip title={localization.getString("refresh")}>
                            <Button
                                icon={IconType.REDO}
                                onClick={() => refetchTrustProfile()}
                                asElement={ButtonElement.BUTTON}
                                buttonType={ButtonType.SECONDARY}
                                aria-label={localization.getString("refresh")}
                            />
                        </Tooltip>
                    </div>
                </header>
            </section>
            <Loader isLoading={isLoading} center medium>
                <ErrorBanners className={styles.errors} errors={errors} />
                {trustProfile && (
                    <Container className={styles.container}>
                        <OverviewTopContainer
                            statusContainerClassName={styles.statusContainer}
                            statusType="success"
                            hideIcon
                            statusLabelTitle={localization.getString("devices")}
                            statusLabel={trustProfile.deviceCount.toString()}
                            statusItems={[
                                {
                                    label: localization.getString("lastUpdated"),
                                    value: DateUtil.format(
                                        trustProfile.lastUpdatedAt.getTime() || 0
                                    ),
                                },
                                {
                                    label: localization.getString("profilePriority"),
                                    value: trustProfile.isDefaultProfile
                                        ? localization.getString("default")
                                        : trustProfile.priority,
                                },
                            ]}
                            listItems={[
                                {
                                    label: localization.getString("description"),
                                    value: trustProfile.description || "-",
                                },
                                {
                                    label: localization.getString("createdAt"),
                                    value: DateUtil.format(trustProfile.createdAt.getTime() || 0),
                                },
                                {
                                    label: localization.getString("createdBy"),
                                    value: trustProfile.createdBy || "-",
                                },
                                {
                                    label: localization.getString("lastUpdatedBy"),
                                    value: trustProfile.lastUpdatedBy || "-",
                                },
                            ]}
                        />
                        <TabBar
                            tabs={Object.values(Step).map(getTabProps)}
                            selectedTabId={selectedStep}
                            onChange={(selectedStepId) => {
                                setIsEditingFalse()
                                setSelectedStep(selectedStepId)
                            }}
                        />
                        <div className={styles.tabContainer}>
                            {selectedStep === Step.DETAILS_AND_ASSIGNMENT && (
                                <div
                                    id={StepAriaControl[Step.DETAILS_AND_ASSIGNMENT]}
                                    className={styles.tabs}
                                >
                                    <DetailsAndAssignmentForm
                                        initialValue={trustProfile}
                                        disabled={!isEditing || trustProfile?.isDefaultProfile}
                                        onSubmit={onUpdateDetailsAndAssignmentEdit}
                                        errors={detailsAndAssignmentFormErrors}
                                        onCancel={setIsEditingFalse}
                                    />
                                </div>
                            )}
                            {selectedStep === Step.TRUST_FACTORS && (
                                <div
                                    id={StepAriaControl[Step.TRUST_FACTORS]}
                                    className={styles.tabs}
                                >
                                    <TrustFactors
                                        trustProfile={trustProfile}
                                        disabled={!isEditing}
                                        onCancel={setIsEditingFalse}
                                        onTrustProfileChange={setIsEditingFalse}
                                    />
                                </div>
                            )}
                        </div>
                    </Container>
                )}
            </Loader>
        </div>
    )
}

enum Step {
    DETAILS_AND_ASSIGNMENT = "DETAILS_AND_ASSIGNMENT",
    TRUST_FACTORS = "TRUST_FACTORS",
}

const StepLabel: Record<Step, LanguageKey> = {
    [Step.DETAILS_AND_ASSIGNMENT]: "detailsAndAssignment",
    [Step.TRUST_FACTORS]: "trustFactors",
}

const StepAriaControl: Record<Step, string> = {
    [Step.DETAILS_AND_ASSIGNMENT]: "detailsAndAssignment",
    [Step.TRUST_FACTORS]: "trustFactors",
}

enum Id {
    HEADING = "heading",
}
