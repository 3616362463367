import { UrlUtil } from "../../pre-v3/utils/Url.util"
import { BaseApi, PaginatedResponse, PaginationOptions } from "./Base.api"

export class AccessTierGroupApi extends BaseApi {
    public getAccessTierGroups(
        getAccessTierGroupsParams: Partial<GetAccessTierGroupsParams> = {}
    ): Promise<PaginatedResponse<"access_tier_groups", AccessTierGroupRes>> {
        const params = `?${UrlUtil.convertToURLSearchParams(getAccessTierGroupsParams).toString()}`

        return this.get(`/api/v2/access_tier_groups${params}`, {
            default: this.localization.getString(
                "failedToGetSomething",
                this.localization.getString("accessTierGroups")
            ),
        })
    }

    public getAccessTierGroupById(id: string): Promise<AccessTierGroupRes> {
        const accessTierGroupLabel = this.localization.getString("accessTierGroup")

        return this.get(`/api/v2/access_tier_groups/${id}`, {
            404: this.localization.getString("somethingNotFound", accessTierGroupLabel),
            default: this.localization.getString("failedToGetSomething", accessTierGroupLabel),
        })
    }

    public createAccessTierGroup(body: AccessTierGroupBody): Promise<AccessTierGroupRes> {
        return this.post(`/api/v2/access_tier_groups`, body, {
            409: this.localization.getString(
                "somethingNamedAlreadyExists",
                this.localization.getString("accessTierGroup"),
                body.name
            ),
            default: this.localization.getString("failedToCreateSomething", body.name),
        })
    }

    public updateAccessTierGroup(
        id: string,
        body: AccessTierGroupBody
    ): Promise<AccessTierGroupRes> {
        return this.put(`/api/v2/access_tier_groups/${id}`, body, {
            default: this.localization.getString(
                "failedToUpdateSomething",
                this.localization.getString("accessTierGroup")
            ),
        })
    }

    public attachAccessTierToGroup(
        accessTierGroupId: string,
        body: AttachAccessTierToGroupBody
    ): Promise<AttachAccessTierToGroupRes> {
        return this.post(`/api/v2/access_tier_groups/${accessTierGroupId}/attach`, body, {
            default: this.localization.getString(
                "failedToAttachSomething",
                this.localization.getString("accessTiers")
            ),
        })
    }

    public detachAccessTierToGroup(
        accessTierGroupId: string,
        body: DetachAccessTierToGroupBody
    ): Promise<DetachAccessTierToGroupRes> {
        return this.delete(`/api/v2/access_tier_groups/${accessTierGroupId}/detach`, body, {
            default: this.localization.getString(
                "failedToDetachSomething",
                this.localization.getString("accessTiers")
            ),
        })
    }

    public deleteAccessTierGroup(id: string): Promise<void> {
        return this.delete(
            `/api/v2/access_tier_groups/${id}`,
            {},
            {
                default: this.localization.getString(
                    "failedToDeleteSomething",
                    this.localization.getString("accessTierGroup")
                ),
            }
        )
    }
}

export interface GetAccessTierGroupsParams extends PaginationOptions {
    shared_fqdn: string
    access_tier_group_name_like: string
}

export interface AccessTierGroupRes {
    id: string
    name: string
    description: string
    access_tier_ids: string[] | null
    advanced_settings: string
    created_at: number
    updated_at: number
    cluster_name: string
    tunnel_enduser: {
        udp_port_number: number
        shared_fqdn: string
        cidrs?: string[]
        domains?: string[]
    }
}

export interface AccessTierGroupBody {
    name: string
    description?: string
    shared_fqdn: string
    cluster_name: string
    tunnel_enduser: {
        cidrs?: string[]
        domains?: string[]
    }
    advanced_settings: AdvancedSettingsJson
}

export interface AdvancedSettingsJson {
    logging?: {
        statsd?: boolean
        statsd_address?: string
    }
    events?: {
        access_event_credits_limiting?: boolean
        access_event_key_limiting?: boolean
    }
    hosted_web_services?: {
        forward_trust_cookie?: boolean
        disable_hsts?: boolean
    }
    service_discovery?: {
        service_discovery_enable?: boolean
        service_discovery_msg_limit?: number
        service_discovery_msg_timeout?: number
    }
    miscellaneous?: {
        enable_ipv6_resolution?: boolean
    }
}

export interface AttachAccessTierToGroupBody {
    access_tier_ids: string[]
}

export interface AttachAccessTierToGroupRes {
    access_tier_ids: string[]
}

export interface DetachAccessTierToGroupBody {
    access_tier_ids: string[]
}

export interface DetachAccessTierToGroupRes {
    access_tier_ids: string[]
}
