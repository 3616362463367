import React from "react"
import styles from "./ClustersCaCert.module.scss"
import { Button } from "../../../components/button/Button.component"
import { CopyButton } from "../../../components/copy-button/CopyButton"

export default function () {
    return (
        <div>
            <Button onClick={this.onToggleCert}>
                {this.state.showCert
                    ? this.localizationService.getString("hideCertificate")
                    : this.localizationService.getString("showCertificate")}
            </Button>
            {this.state.showCert && (
                <>
                    <CopyButton primary copyText={this.props.cert} className={styles.copyButton} />
                    <textarea
                        className={styles.certTextArea}
                        disabled
                        readOnly
                        value={this.props.cert}
                    ></textarea>
                </>
            )}
        </div>
    )
}
