import { BaseApi } from "./Base.api"
import { Singleton } from "../../pre-v3/decorators/Singleton.decorator"

@Singleton("ProtectionProfileApi")
export class ProtectionProfileApi extends BaseApi {
    public getDnsFilters(): Promise<DnsFilterRes[]> {
        return this.get("/api/v2/dnsfilter/categories", {
            default: this.localization.getString("errorCouldNotGetDnsFilters"),
        })
    }

    public getProtectionProfile(id: string): Promise<ProtectionProfileRes[]> {
        return this.get(`/api/v2/threatprofile?id=${encodeURIComponent(id)}`, {
            default: this.localization.getString("errorCouldNotGetItpPolicies"),
        })
    }

    public getProtectionProfiles(): Promise<ProtectionProfileRes[]> {
        return this.get("/api/v2/threatprofile", {
            default: this.localization.getString("errorCouldNotGetItpPolicies"),
        })
    }

    public createProtectionProfile(params: NewProtectionProfileReq): Promise<ProtectionProfileRes> {
        return this.post("/api/v2/threatprofile", params, {
            409: this.localization.getString(
                "somethingNamedAlreadyExists",
                this.localization.getString("itpPolicy"),
                params.profile_name
            ),
            default: this.localization.getString("errorCouldNotCreateItpPolicy"),
        })
    }

    public updateProtectionProfile(params: ProtectionProfileRes): Promise<ProtectionProfileRes> {
        return this.put("/api/v2/threatprofile", params, {
            409: this.localization.getString(
                "somethingNamedAlreadyExists",
                this.localization.getString("itpPolicy"),
                params.profile_name
            ),
            default: this.localization.getString("errorCouldNotUpdateItpPolicy"),
        })
    }

    public deleteProtectionProfile(id: string): Promise<void> {
        return this.delete(
            "/api/v2/threatprofile",
            { profile_id: id },
            {
                default: this.localization.getString("errorCouldNotDeleteItpPolicy"),
            }
        )
    }

    public reorderProtectionProfiles(
        params: ReorderProtectionProfilesReq
    ): Promise<ProtectionProfileRes[]> {
        return this.put("/api/v2/threatprofile/priority", params, {
            default: this.localization.getString("errorCouldNotReorderItpPolicies"),
        })
    }

    public getDnsReport(): Promise<DnsReportReq> {
        return this.get("/api/v2/dnsfilter/report", {
            default: this.localization.getString("errorCouldNotGetDnsReport"),
        })
    }

    public generateDnsReport() {
        return this.post(
            "/api/v2/dnsfilter/generate_report",
            {},
            {
                default: this.localization.getString("errorCouldNotGenerateDnsReport"),
            }
        )
    }

    public getProtectionProfileSyncStatus(): Promise<SyncStatusRes> {
        return this.get("/api/v2/threatprofiles/sync/status", {
            default: this.localization.getString("failedToGetSyncStatus"),
        })
    }

    public getProtectionProfileLookupDomain(domain: string): Promise<LookUpDomainRes> {
        return this.get(`/api/v2/dnsfilter/lookup_domain?domain=${domain}`, {
            default: this.localization.getString("thereWasAnErrorLookingUpTheDomain"),
        })
    }
}

export interface DnsReportReq {
    url: string
    request_timestamp: number
    success_timestamp: number
    status: "pending" | "success" | "error" | "no_status"
}

export interface ProtectionProfileRes {
    profile_id: string
    priority: number
    created_at: number
    created_by: string
    updated_at: number
    updated_by: string
    profile_name: string
    description?: string
    whitelist_domains: string[]
    blacklist_domains: string[]
    allow_applications: string[]
    block_applications: string[]
    blacklist_categories: number[]
    block_page_message: string
    roles: string[]
    extra_details: {
        device_count: number
    }
}

export interface SyncStatusRes {
    id: string
    status: "InProgress" | "Completed"
    last_synced_at: number
    eta: number
    out_of_sync_at: number
}

export type NewProtectionProfileReq = Pick<
    ProtectionProfileRes,
    | "blacklist_categories"
    | "blacklist_domains"
    | "whitelist_domains"
    | "block_applications"
    | "allow_applications"
    | "profile_name"
    | "description"
    | "roles"
    | "block_page_message"
>

interface ReorderProtectionProfilesReq {
    threat_profile_priorities: ThreatProfilePriority[]
}

export interface ThreatProfilePriority {
    profile_id: string
    priority: number
}

export interface DnsFilterRes {
    id: string
    attributes: {
        name: string
        description: string
        security: boolean
    }
}

export interface LookUpDomainRes {
    domainName: string
    threats: string[]
    categories: string[]
    applications: string[]
    threatProfiles: string[]
}
