import React, { Fragment } from "react"
import styles from "./MenuButton.module.scss"
import { Button } from "../button/Button.component"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { Popover } from "@mui/material"
import classNames from "classnames/bind"
import { faChevronDown } from "@fortawesome/pro-light-svg-icons"

export default function () {
    return (
        <Fragment>
            <Button
                onClick={this.openMenu}
                innerRef={(c) => (this.anchor = c)}
                className={classNames(this.props.className, {
                    [styles.rounded]: this.props.rounded,
                })}
                primary={this.props.primary}
            >
                {this.props.icon && <FontAwesomeIcon icon={this.props.icon} />}
                {this.props.icon && this.props.label && (
                    <span className={styles.iconSpacing}></span>
                )}
                {this.props.label}
                {this.props.showDownArrow && (
                    <FontAwesomeIcon className={styles.chevronDown} icon={faChevronDown} />
                )}
            </Button>
            <Popover
                anchorEl={this.anchor}
                anchorOrigin={
                    this.props.anchorOrigin || {
                        vertical: "bottom",
                        horizontal: "left",
                    }
                }
                transformOrigin={
                    this.props.transformOrigin || {
                        vertical: "top",
                        horizontal: "left",
                    }
                }
                PaperProps={{ square: true }}
                open={this.state.showMenu}
                onClose={this.closeMenu}
            >
                <ul className={styles.menuButtonChildren} onClick={this.closeMenu}>
                    {this.props.children}
                </ul>
            </Popover>
        </Fragment>
    )
}
