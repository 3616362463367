import { Singleton } from "../decorators/Singleton.decorator"
import React from "react"
import {
    ExemptedPathPattern,
    ExemptedPathPatternMethods,
    ExemptedPathPatternTemplates,
} from "../api/Manage.api"
import ServiceContext from "./context"

@Singleton("ServiceService")
export class ServiceService {
    public groupPatterns(patterns: ExemptedPathPattern[]): {
        pathExemption: ExemptedPathPattern | null
        corsExemptions: (ExemptedPathPattern & { id: string })[]
        cidrExemption: ExemptedPathPattern | null
    } {
        // we have to look for three existing groups of exemptions: CORS, CIDR, and paths
        let corsExemptions: (ExemptedPathPattern & { id: string })[] = []
        let cidrExemption: ExemptedPathPattern | null = null
        let pathExemption: ExemptedPathPattern | null = null

        // look at every existing exemption and categorize them appropriate
        for (const pattern of patterns || []) {
            // ignore any with OPTIONS in their methods
            if (pattern.methods?.includes(ExemptedPathPatternMethods.OPTIONS)) {
                continue
            }

            // if there are no methods, we found an old pattern that used to encompass CIDR
            // and path exemptions in a single object (an "and" operation).
            if (pattern.methods?.length === 0) {
                // use it for both the cidr and path exemption
                cidrExemption = pattern
                pathExemption = pattern

                // we're done
                continue
            }

            // look at the pattern's template to identify

            // there can only be one exemption marked CIDR
            if (pattern.template === ExemptedPathPatternTemplates.CIDR) {
                cidrExemption = pattern
            }
            // there can only be exemption marked PATH
            else if (pattern.template === ExemptedPathPatternTemplates.PATH) {
                pathExemption = pattern
            }
            // there could be multiple exemptions marked CORS. If there's no template and we
            // got this far, then its an old CORS exemption before we introduced template
            else if (!pattern.template || pattern.template === ExemptedPathPatternTemplates.CORS) {
                corsExemptions.push({
                    ...pattern,
                    id: Math.random().toString(),
                })
            }
        }

        return {
            corsExemptions,
            pathExemption,
            cidrExemption,
        }
    }
}

export enum PolicyEnforcement {
    ENFORCING = "Enforcing",
    PERMISSIVE = "Permissive",
    INACTIVE = "Inactive",
    MIXED = "Mixed",
}

export enum SaasAppUrlType {
    SAAS = "cse",
    IDP_ROUTED = "idp-routed",
}

export const useServiceService = () =>
    React.useContext(ServiceContext)?.service || new ServiceService()
