import { MasterService } from "../services/Master.service"

export function Singleton<T>(serviceName: string): any {
    return (constructor: T) => {
        if (!window.masterService) {
            window.masterService = new MasterService()
        }
        return (...args: any[]) => window.masterService!.getService(constructor, serviceName, args)
    }
}

declare global {
    interface Window {
        masterService?: MasterService
    }
}
