import React from "react"
import classNames from "classnames/bind"

import styles from "./OldInput.module.scss"

export interface Props extends React.InputHTMLAttributes<HTMLInputElement> {
    /**
     * Triggered by `onChange` and `onBlur` called with the event's value
     * @param value Event's value
     */
    onChangeValue?(value: string): void
    /**
     * Input control's value
     */
    value?: string
}

/**
 * Used to create interactive controls for web-based forms in order to accept
 * data from the user
 */
export const OldInput = React.forwardRef<HTMLInputElement, Props>((props, ref) => {
    const { onChangeValue, ...inputProps } = props

    const onChange: React.ChangeEventHandler<HTMLInputElement> = (event) => {
        event.preventDefault()
        props.onChange?.(event)
        onChangeValue?.(event.target.value)
    }

    const onBlur: React.FocusEventHandler<HTMLInputElement> = (event) => {
        props.onBlur?.(event)
        onChangeValue?.(event.target.value.trim())
    }

    return (
        <input
            {...inputProps}
            onChange={onChange}
            onBlur={onBlur}
            className={classNames(styles.input, props.className)}
            ref={ref}
        />
    )
})

OldInput.displayName = "Input"
