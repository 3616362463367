import { faPlus } from "@fortawesome/pro-solid-svg-icons"
import {
    ColDef,
    GridApi,
    GridReadyEvent,
    ICellRendererParams,
    ITooltipParams,
    ValueGetterParams,
} from "ag-grid-community"
import React, { useEffect, useMemo, useRef, useState } from "react"

import { IconType } from "../../../../pre-v3/services/ActionBar.service"
import { useServiceLocalization } from "../../../../pre-v3/services/localization/Localization.service"
import { formatRoutePath, ROUTE } from "../../../../routes"
import { ErrorBanners } from "../../../components/banner/Banner.component"
import { RowTitle } from "../../../components/grid/RowTitle.component"
import { UrlUtil, encodeID } from "../../../../pre-v3/utils/Url.util"
import { Container } from "../../../components/container/Container.component"
import { Loader } from "../../../components/loader/Loader.component"
import { Status as RoleStatus } from "../../../services/shared/Role"
import { Role, useGetRoles } from "../../../services/Role.service"
import { SearchInput } from "../../../components/search-input/SearchInput.component"
import { Status } from "../../../components/status/Status.component"
import { Link } from "../../../components/link/Link.component"
import { Grid } from "../../../../pre-v3/components/grid/Grid.component"
import { RoleOverviewTab } from "../overview/RolesOverview.view"
import { EmptyStateScreen } from "../../../components/page-screen/PageScreen.component"
import { useServiceLinks } from "../../../../pre-v3/services/link/Link.service"
import { AppText } from "../../../components/app-text/AppText.component"
import { useGetAdminInfo } from "../../../services/Org.service"
import AgGridUtil from "../../../../pre-v3/utils/AgGrid.util"
import styles from "./RolesList.module.scss"
import { PageHeading } from "../../../../components/page-heading/PageHeading.component"
import { Button, ButtonElement, ButtonType } from "../../../../components/button/Button.component"
import { Tooltip } from "../../../components/tooltip/Tooltip.component"

export function RolesList(): JSX.Element {
    const localization = useServiceLocalization()
    const grid = useRef<GridApi>()
    const linkService = useServiceLinks()

    const [search, setSearch] = useState(UrlUtil.getUrlParam("search"))

    const {
        isFetching: isRolesLoading,
        data: roles,
        error: rolesError,
        isError: isRolesError,
        refetch: refetchRoles,
    } = useGetRoles()

    const {
        isFetching: isGetAdminInfoLoading,
        error: adminInfoError,
        refetch: refetchAdminInfo,
        data: adminInfo,
    } = useGetAdminInfo()

    const columns = useColumns()

    useEffect(() => {
        grid.current?.setQuickFilter?.(search)
        UrlUtil.setURlParams("search", search)
    }, [search])

    function onRefresh() {
        refetchRoles()
        refetchAdminInfo()
    }

    function onGridReady(event: GridReadyEvent) {
        grid.current = event.api
        event.api.setQuickFilter?.(search)
    }

    if (!isRolesLoading && !isGetAdminInfoLoading && !isRolesError && roles?.length === 0) {
        return (
            <Container
                as="section"
                aria-labelledby={Id.HEADING}
                className={styles.container}
                fullWidth
            >
                <header className={styles.header}>
                    <PageHeading id={Id.HEADING}>{localization.getString("roles")}</PageHeading>
                    <Tooltip title={localization.getString("refresh")}>
                        <Button
                            icon={IconType.REDO}
                            onClick={onRefresh}
                            asElement={ButtonElement.BUTTON}
                            buttonType={ButtonType.SECONDARY}
                            aria-label={localization.getString("refresh")}
                        />
                    </Tooltip>
                </header>
                <EmptyStateScreen
                    title={localization.getString("createYourFirstRole")}
                    subText={
                        <AppText
                            ls={{
                                key: "createYourFirstRoleDescription",
                                replaceVals: [linkService.getLink("manageRoles")],
                            }}
                        />
                    }
                    buttonProps={
                        adminInfo?.canWriteRoles
                            ? {
                                  buttonText: localization.getString("createRole"),
                                  buttonLink: ROUTE.ROLES_ADD,
                                  buttonIcon: faPlus,
                              }
                            : undefined
                    }
                />
            </Container>
        )
    }

    return (
        <Container as="section" aria-labelledby={Id.HEADING} className={styles.container} fullWidth>
            <header className={styles.header}>
                <PageHeading id={Id.HEADING}>{localization.getString("roles")}</PageHeading>
                <Tooltip title={localization.getString("refresh")}>
                    <Button
                        icon={IconType.REDO}
                        onClick={onRefresh}
                        asElement={ButtonElement.BUTTON}
                        buttonType={ButtonType.SECONDARY}
                        aria-label={localization.getString("refresh")}
                    />
                </Tooltip>
            </header>
            <div className={styles.headerSection}>
                <SearchInput
                    placeholder={localization.getString("search")}
                    onChangeValue={setSearch}
                    value={search}
                />
                {adminInfo?.canWriteRoles && (
                    <Button
                        buttonType={ButtonType.PRIMARY}
                        icon={IconType.PLUS}
                        asElement={ButtonElement.LINK}
                        to={ROUTE.ROLES_ADD}
                    >
                        {localization.getString("addRole")}
                    </Button>
                )}
            </div>
            <Loader isLoading={isRolesLoading || isGetAdminInfoLoading} medium center>
                {isRolesError && (
                    <ErrorBanners
                        errors={[
                            rolesError ? String(rolesError) : null,
                            adminInfoError && String(adminInfoError),
                        ]}
                    />
                )}
                {!isRolesError && (
                    <Grid
                        onGridReady={onGridReady}
                        rowData={roles!}
                        columnDefs={columns}
                        pagination
                    />
                )}
            </Loader>
        </Container>
    )
}

enum Id {
    HEADING = "heading",
}

function useColumns(): ColDef[] {
    const localization = useServiceLocalization()
    const locale = localization.getLocale()

    return useMemo((): (ColDef & { field: keyof Role })[] => {
        return [
            {
                field: "roleStatus",
                headerName: localization.getString("status"),
                cellRenderer: (props: ICellRendererParams<Role, RoleStatus>) => (
                    <Status
                        type={props.value === RoleStatus.ACTIVE ? "success" : "disabled"}
                        label={localization.getString(props.value)}
                    />
                ),
                tooltipValueGetter: (props: ITooltipParams) => localization.getString(props.value),
            },
            {
                field: "name",
                headerName: localization.getString("name"),
                cellRenderer: (props: ICellRendererParams<Role, string>) => {
                    return <RowTitle title={props.value} route={getRoute({ role: props.data! })} />
                },
                comparator: AgGridUtil.alphaBetComparator,
            },
            {
                field: "policyCount",
                headerName: localization.getString("linkedPolicies"),

                cellRenderer: (props: ICellRendererParams<Role, string>) => {
                    return (
                        <Link
                            to={getRoute({
                                role: props.data!,
                                tab: RoleOverviewTab.LINKED_POLICIES,
                            })}
                        >
                            {props.value}
                        </Link>
                    )
                },
            },
            {
                field: "deviceCount",
                headerName: localization.getString("devices"),
                cellRenderer: (props: ICellRendererParams<Role, string>) => (
                    <Link to={getRoute({ role: props.data!, tab: RoleOverviewTab.DEVICES })}>
                        {props.value}
                    </Link>
                ),
            },
            {
                field: "lastUpdatedAt",
                headerName: localization.getString("lastUpdated"),
                tooltipValueGetter: (props: ITooltipParams<Role, Number | undefined>) => {
                    if (!props.data?.lastUpdatedAt) return "-"
                    return new Date(props.data?.lastUpdatedAt).toLocaleString(locale)
                },
                cellRenderer: (props: ICellRendererParams<Role, number>) => {
                    return new Date(props.value).toLocaleString(locale)
                },
                filterValueGetter: (props: ValueGetterParams<Role>) => {
                    if (!props.data?.lastUpdatedAt) return "-"
                    return new Date(props.data?.lastUpdatedAt).toLocaleString(locale)
                },
            },
        ]
    }, [localization])
}

interface GetRouteProps {
    role: Role
    tab?: RoleOverviewTab
}

function getRoute(props: GetRouteProps): ROUTE {
    const { role, tab } = props

    return formatRoutePath(
        ROUTE.ROLES_DETAILS,
        { id: encodeID(role.id) },
        tab ? { tab } : undefined
    )
}
