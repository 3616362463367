import { ColDef, GridApi, GridReadyEvent, ICellRendererParams } from "ag-grid-community"
import * as React from "react"
import { useHistory } from "react-router-dom"

import { Grid } from "../../../../../pre-v3/components/grid/Grid.component"
import { LocalizationService } from "../../../../../pre-v3/services"
import AgGridUtil from "../../../../../pre-v3/utils/AgGrid.util"
import { ROUTE, formatRoutePath } from "../../../../../routes"
import { Link } from "../../../../components/link/Link.component"
import { SearchInput } from "../../../../components/search-input/SearchInput.component"
import { Status as RoleStatus } from "../../../../services/shared/Role"
import { Role } from "../../../../services/User.service"
import { encodeID } from "../../../../../pre-v3/utils/Url.util"
import { Status } from "../../../../components/status/Status.component"
import styles from "./UserOverview.module.scss"

interface Props {
    roles: Role[]
}

export function UserRolesList(props: Props) {
    const ls = new LocalizationService()
    const gridApi = React.useRef<GridApi>()
    const history = useHistory()
    const [search, setSearch] = React.useState<string>("")

    const roleColumns: ColDef[] = [
        {
            headerName: ls.getString("status"),
            width: 80,
            field: "status",
            cellRenderer: (params: ICellRendererParams<Role>) => {
                return (
                    <Status
                        type={params.data?.status === RoleStatus.ACTIVE ? "success" : "disabled"}
                        label={ls.getString(
                            params.data?.status === RoleStatus.ACTIVE ? "active" : "inactive"
                        )}
                    />
                )
            },
        },
        {
            headerName: ls.getString("name"),
            field: "name",
            tooltipValueGetter: AgGridUtil.linkTooltipValueGetter,
            cellRenderer: (params: { data: Role }) => {
                if (!params.data.id) {
                    return params.data.name
                }
                return (
                    <Link
                        to={formatRoutePath(ROUTE.ROLES_DETAILS, {
                            id: encodeID(params.data.id),
                        })}
                    >
                        {params.data.name}
                    </Link>
                )
            },
            comparator: AgGridUtil.alphaBetComparator,
        },
        {
            headerName: ls.getString("lastUpdated"),
            field: "lastUpdatedAt",
            valueFormatter: AgGridUtil.dateFormatter,
        },
    ]

    const onGridReady = (event: GridReadyEvent) => {
        gridApi.current = event.api
    }

    React.useEffect(() => {
        gridApi.current?.setQuickFilter?.(search)
    }, [search])

    return (
        <React.Fragment>
            <SearchInput
                className={styles.search}
                value={search}
                onChangeValue={setSearch}
                placeholder={ls.getString("search")}
            />
            <Grid
                rowData={props.roles}
                columnDefs={roleColumns}
                context={{ history }}
                onGridReady={onGridReady}
                pagination
            />
        </React.Fragment>
    )
}
