import React from "react"
import styles from "./ServicesAddBanyanproxySettings.module.scss"
import { Select } from "../../../../components/select/Select.component"
import { FormLabel, PortInput } from "../../../../components"

export default function () {
    return (
        <div className={this.props.className}>
            <FormLabel title={this.ls.getString("assignedListenPortDescription")} htmlFor="port">
                <PortInput
                    className={styles.port}
                    value={this.state.port}
                    disabled={this.props.disabled}
                    onChange={this.onPortChange}
                    id="port"
                />
            </FormLabel>
            <FormLabel
                title={this.ls.getString("giveEndUsersTheAbilityToOverrideTheseSettings")}
                htmlFor="giveEndUsersTheAbilityToOverrideTheseSettings"
            >
                <Select
                    options={this.overrideOptions}
                    value={this.state.override}
                    onChange={this.onOverrideChange}
                    disabled={this.props.disabled}
                />
            </FormLabel>
        </div>
    )
}
