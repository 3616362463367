import React, { ReactNode } from "react"
import InputWithHideTemplate from "./InputWithHide.template"
import { Bind } from "../../decorators/Bind.decorator"
import { LocalizationService } from "../../services/localization/Localization.service"

export class InputWithHide extends React.Component<InputWithHideProps, {}> {
    public state: InputWithHideState = {
        hide: true,
        value: "",
    }

    public componentDidMount(): void {
        this.setState({ value: this.props.value })
    }

    public static getDerivedStateFromProps(
        nextProps: InputWithHideProps,
        prevState: InputWithHideState
    ): Partial<InputWithHideState> | null {
        return nextProps.value === prevState.value ? null : { value: nextProps.value }
    }

    public render(): ReactNode {
        return InputWithHideTemplate.call(this)
    }

    private localizationService: LocalizationService = new LocalizationService()

    @Bind
    private onHide(): void {
        this.setState({ hide: !this.state.hide })
    }

    @Bind
    private onChange(event: Event): void {
        if (event?.target) {
            const value: string = (event.target as any).value
            this.setState({ value })
        }
    }
}

interface InputWithHideProps {
    value?: string
    disabled?: boolean
    className?: string
    hideEyeIcon?: boolean
}

interface InputWithHideState {
    hide: boolean
    value: string
}
