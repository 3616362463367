import * as React from "react"
import { Button, ButtonElement, ButtonType } from "../../../../components/button/Button.component"
import { Select } from "../../../components/select/Select.component"
import { LocalizationService } from "../../../services/localization/Localization.service"
import { ModalRef } from "../../../services/Modal.service"
import { DeviceOwnership } from "../../../services/Settings.service"
import { TrustFactorService, TrustScoreExclusion } from "../../../services/TrustFactor.service"
import { SelectItem } from "../../../utils/SelectValue.util"
import { TrustFactorType as TRUST_FACTOR_NAME } from "../../../../v3/services/shared/TrustFactorType"
import styles from "./PreferredAppsSettings.module.scss"
import { DialogActions, DialogContent } from "@mui/material"
import { FormLabel } from "../../../components/form-label/FormLabel"

interface Props {
    modalRef: ModalRef
    initial: SelectItem[] | null
    onSubmit: () => void | Promise<void>
}

export default function EditApplicableDeviceOwnershipsModalContent(props: Props): JSX.Element {
    const { modalRef, initial, onSubmit: submit } = props

    // grab the services we need
    const trustFactorService = new TrustFactorService()
    const localizationService = new LocalizationService()

    const [loading, setLoading] = React.useState(false)

    // get the mapping from ownership enum to localized values
    const ownershipEnums = trustFactorService.deviceOwnershipTypes()

    const options = Object.entries(ownershipEnums).map(([value, displayName]) => ({
        value: value as DeviceOwnership,
        displayName,
    }))

    // start off with all of the operation systems selected
    const [selectedFactors, setSelectedFactors] = React.useState<SelectItem[]>(initial || options)

    const onSubmit = async () => {
        setLoading(true)

        const selectedValues = selectedFactors.map(({ value }) => value)
        const initialValues = initial?.map(({ value }) => value) || []

        // look for exclusions that have been removed (ie in initial but not in state)
        const removed = initialValues.filter((value) => !selectedValues.includes(value))
        // look for exclusions that have been added (ie in state but not in the initial list)
        const added = selectedValues.filter((value) => !initialValues.includes(value))

        await Promise.all([
            // if an entry is missing, that means a new exclusion
            trustFactorService.addExclusions(
                ...removed.map((value) => ({
                    factor_name: TRUST_FACTOR_NAME.APPLICATION_CHECK,
                    exclusion_type: "ownership" as TrustScoreExclusion["exclusion_type"],
                    exclusion_value: value,
                }))
            ),

            // if an entry is added, that means we have to allow it (remove the exclusion)
            trustFactorService.deleteExclusions(
                ...added.map((value) => ({
                    factor_name: TRUST_FACTOR_NAME.NOT_ACTIVE_THREAT,
                    exclusion_type: "ownership" as TrustScoreExclusion["exclusion_type"],
                    exclusion_value: value,
                }))
            ),
        ])

        setLoading(false)

        if (submit) {
            submit()
        }
        if (modalRef) {
            modalRef.close(selectedFactors)
        }
    }
    const onClose = () => {
        if (modalRef) {
            modalRef.close(selectedFactors)
        }
    }

    return (
        <>
            <DialogContent>
                <FormLabel
                    title={localizationService.getString(
                        "applyThisTrustFactorToTheFollowingDeviceCategories"
                    )}
                    slim
                    htmlFor="device-factors"
                >
                    <Select
                        multiple
                        value={selectedFactors.map((factor) => factor.value)}
                        options={options}
                        onChange={(items) =>
                            setSelectedFactors(options.filter((opt) => items.includes(opt.value)))
                        }
                    />
                </FormLabel>
            </DialogContent>
            <DialogActions className={styles.dialogActions}>
                <Button
                    buttonType={ButtonType.SECONDARY}
                    asElement={ButtonElement.BUTTON}
                    onClick={onClose}
                    className={styles.cancelButton}
                >
                    {localizationService.getString("cancel")}
                </Button>
                <Button
                    buttonType={ButtonType.PRIMARY}
                    asElement={ButtonElement.BUTTON}
                    onClick={onSubmit}
                    loading={loading}
                >
                    {localizationService.getString("update")}
                </Button>
            </DialogActions>
        </>
    )
}
