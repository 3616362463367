import * as React from "react"
import classNames from "classnames/bind"
import styles from "./FormHeader.module.scss"

interface Props {
    children: React.ReactNode
    className?: string
}

export function FormHeader({ children, className }: Props) {
    return <h4 className={classNames(styles.header, className)}>{children}</h4>
}
