import React, { ReactNode } from "react"
import ServiceActivityListTemplate from "./ServiceActivityList.template"
import * as H from "history"
import { LocalizationService } from "../../../services/localization/Localization.service"
import ActionBarService from "../../../services/ActionBar.service"
import { GridApi, ColDef, GridReadyEvent } from "ag-grid-community"
import AgGridUtil from "../../../utils/AgGrid.util"
import { Bind } from "../../../decorators/Bind.decorator"
import { DateUtil } from "../../../utils/Date.util"
import { AccessActivity, ReportingService } from "../../../services/Reporting.service"

export class ServiceActivityList extends React.Component<
    ServiceActivityListProps,
    ServiceActivityListState
> {
    public state: ServiceActivityListState = { error: false }

    public render(): ReactNode {
        return ServiceActivityListTemplate.call(this)
    }

    public componentDidMount(): void {
        this.actionBarService.registerRefreshFn(this.fetchData)
    }

    public componentWillUnmount(): void {
        this.actionBarService.unregisterRefreshFn(this.fetchData)
    }

    private reportingService: ReportingService = new ReportingService()
    private localizationService: LocalizationService = new LocalizationService()
    private actionBarService: ActionBarService = new ActionBarService()

    private gridApi: GridApi
    private columns: ColDef[] = [
        {
            headerName: this.localizationService.getString("user"),
            field: "name",
            tooltipValueGetter: AgGridUtil.linkTooltipValueGetter,
            cellRenderer: "usersNameCellRenderer",
            comparator: AgGridUtil.alphaBetComparator,
        },
        {
            headerName: this.localizationService.getString("device"),
            field: "deviceFriendlyName",
            tooltipValueGetter: AgGridUtil.linkTooltipValueGetter,
            cellRenderer: "devicesCellRenderer",
            comparator: AgGridUtil.alphaBetComparator,
        },
        {
            headerName: this.localizationService.getString("lastAuthAccess"),
            field: "lastAuthorizedTimestamp",
            valueFormatter: AgGridUtil.dateFormatter,
        },
        {
            headerName: this.localizationService.getString("lastUnauthAccess"),
            field: "lastUnauthorizedTimestamp",
            valueFormatter: AgGridUtil.dateFormatter,
        },
    ]

    @Bind
    private onGridReady(event: GridReadyEvent): void {
        this.gridApi = event.api
        this.fetchData()
    }

    @Bind
    private onFilter(value: string): void {
        if (this.gridApi) {
            this.gridApi.setQuickFilter(value)
        }
    }

    @Bind
    private fetchData(): void {
        if (this.props.serviceId) {
            const days: number = DateUtil.DAY * 14
            this.reportingService
                .getAccessActivityForService(this.props.serviceId, Date.now() - days, Date.now())
                .then(
                    (activity: AccessActivity[]) => {
                        if (this.gridApi) {
                            this.gridApi.setRowData(activity)
                        }
                    },
                    () => {
                        this.setState({ error: true })
                    }
                )
        }
    }
}

interface ServiceActivityListProps {
    serviceId: string
    history: H.History
}

interface ServiceActivityListState {
    error: boolean
}
