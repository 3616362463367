import { ApiResponse, BaseApi, MessageRes } from "./Base.api"
import { Singleton } from "../decorators/Singleton.decorator"
import { ServiceBundle } from "../services/Settings.service"

@Singleton("SettingsApi")
export class SettingsApi extends BaseApi {
    public getLatestOS(platform?: string): Promise<{ [key: string]: LatestOS[] }> {
        let url: string = "/api/v1/latest_os"
        if (platform) {
            url += "?platform=" + platform
        }
        return this.get(url)
    }

    public getPlatformConfig(): Promise<PlatformConfig> {
        return this.get("/api/v1/trustscore_config/device/latest_os")
    }

    public setPlatformConfig(config: PlatformConfig): Promise<MessageRes> {
        return this.put("/api/v1/trustscore_config/device/latest_os", {
            kind: "BanyanLatestOSConfig",
            apiVersion: "ts.banyanops.com/v1",
            spec: config,
        })
    }

    public getPreferredApps(name?: string): Promise<OrgPreferredApp[]> {
        return this.get(
            "/api/v1/trustscore_config/device/org_preferred_apps" +
                (name ? "?app_name=" + name : "")
        )
    }

    public setPreferredApps(apps: OrgPreferredApp[]): Promise<void> {
        return this.put("/api/v1/trustscore_config/device/org_preferred_apps", {
            kind: "BanyanOrgPrefAppsConfig",
            apiVersion: "ts.banyanops.com/v1",
            spec: {
                org_preferred_apps: apps,
            },
        })
    }

    public deletePreferredApps(apps: OrgPreferredApp[]): Promise<void> {
        return this.delete("/api/v1/trustscore_config/device/org_preferred_apps", {
            kind: "BanyanOrgPrefAppsConfig",
            apiVersion: "ts.banyanops.com/v1",
            spec: {
                org_preferred_apps: apps,
            },
        })
    }

    public insertPasswordlessConfig(redirectUri?: string): Promise<MessageRes> {
        return this.postForm("/api/v1/insert_pwless_config", {
            redirect_url: redirectUri,
        })
    }

    public deletePasswordlessConfig(): Promise<MessageRes> {
        return this.delete("/api/v1/delete_pwless_config")
    }

    public getOidcSettings(): Promise<OidcApi> {
        return this.get("/api/v1/oidc_settings")
    }

    public getMdmDeployConfig(): Promise<MdmDeployConfig> {
        return this.get("/api/v1/mdm_deploy_config")
    }

    public setOtpSkipRole(roleName: string): Promise<MdmDeployConfig> {
        return this.getMdmDeployConfig().then((deployConfig: MdmDeployConfig) => {
            return this.post("/api/v1/mdm_deploy_config", {
                kind: "BanyanMdmDeployConfig",
                apiVersion: "mdmdeployconfig.banyanops.com/v1",
                spec: {
                    mdm_deploy_key: deployConfig.mdm_deploy_key,
                    otp_skip_role: roleName,
                },
            })
        })
    }

    public createMdmDeployKey(): Promise<MdmDeployConfig> {
        return this.post("/api/v1/mdm_deploy_config/deploy_key", {})
    }

    public deleteMdmDeployKey(): Promise<MessageRes> {
        return this.delete("/api/v1/mdm_deploy_config/deploy_key", {})
    }

    public getOtpSkipRoles(): Promise<OtpSkipRoles> {
        return this.get("/api/v1/mdm_deploy_config/otp_skip_roles")
    }

    public getServiceBundles(): Promise<ApiResponse<ServiceBundleRes>> {
        return this.get("/api/experimental/v2/bundle")
    }

    public updateServiceBundle(bundledata: ServiceBundleItemReq, id?: string): Promise<void> {
        return this.put("/api/experimental/v2/bundle/" + id, bundledata)
    }

    public getServiceBundle(serviceBundleName: string): Promise<ApiResponse<ServiceBundleRes>> {
        return this.get(
            "/api/experimental/v2/bundle?bundle_name=" + encodeURIComponent(serviceBundleName)
        )
    }

    public deleteServiceBundle(id: string): Promise<ServiceBundle> {
        return this.delete("/api/experimental/v2/bundle/" + id)
    }

    public getApiKeys(): Promise<ApiResponse<ApiKeyRes[]>> {
        return this.get("/api/experimental/v2/api_key")
    }

    public getApiKey(id: string): Promise<ApiResponse<ApiKeyRes>> {
        return this.get(`/api/experimental/v2/api_key/${encodeURIComponent(id)}`)
    }

    public createApiKey(apiKey: ApiKeyOptions): Promise<ApiResponse<string>> {
        return this.post("/api/experimental/v2/api_key", apiKey)
    }

    public updateApiKey(id: string, apiKey: ApiKeyOptions): Promise<ApiResponse<string>> {
        return this.put(`/api/experimental/v2/api_key/${encodeURIComponent(id)}`, apiKey)
    }

    public deleteApiKey(id: string): Promise<ApiResponse<string>> {
        return this.delete(`/api/experimental/v2/api_key/${encodeURIComponent(id)}`)
    }

    public getTunnelEndpointCidrs(): Promise<ApiResponse<TunnelEndpointCidrRes[]>> {
        return this.get("/api/experimental/v2/tunnel_cidr")
    }

    public getApiKeyScopes(): Promise<ApiResponse<ApiKeyScope[]>> {
        return this.get("/api/experimental/v2/api_key/scope")
    }

    public createTunnelEndpointCidr(
        epCidr: TunnelEndpointCidrOptions
    ): Promise<ApiResponse<string>> {
        return this.post("/api/experimental/v2/tunnel_cidr", epCidr)
    }

    public deleteTunnelEndpointCidr(id: string): Promise<ApiResponse<string>> {
        return this.delete(`/api/experimental/v2/tunnel_cidr/${encodeURIComponent(id)}`)
    }

    // no getTunnelEndpointCidr
    // no updateTunnelEndpointCidr
    public insertServiceBundle(bundledata: ServiceBundleItemReq): Promise<void> {
        return this.post("/api/experimental/v2/bundle", bundledata)
    }
}

export interface LatestOS {
    os_name: string
    releases: OSRelease[]
}

export interface OSRelease {
    os_version: string
    os_release_date: string
}

export interface PlatformConfig {
    num_releases: NumberMap
}

export interface OrgPreferredAppConfig {
    org_preferred_apps: OrgPreferredApp[]
}

export interface OrgPreferredApp {
    app_name: string
    app_version?: string
    mandatory?: string
    platform: string
    process_name?: string
}

export interface OidcApi {
    authorization_endpoint: string
    issuer_url: string
    jwks_endpoint: string
    redirect_url: string
    token_endpoint: string
    userinfo_endpoint: string
    openid_configuration_endpoint: string
    scope: string
}

export interface MdmDeployConfig {
    mdm_deploy_key: string
    otp_skip_role?: string
}

export interface OtpSkipRoles {
    otp_skip_roles: string[]
}

export interface ServiceBundleRes {
    service_bundles: ServiceBundleItemRes[]
}

export interface ServiceBundleItemRes {
    id: string
    bundle_name: string
    description: string
    is_admin_created: boolean
    bulk_connect: boolean
    created_at: number
    updated_at: number
    service_ids: string[]
}

export interface ServiceBundleItemReq {
    id?: string
    bundle_name: string
    description: string
    bulk_connect: boolean
    service_ids: string[]
}

export interface ApiKeyRes {
    id: string
    org_id: string
    name: string
    secret: string
    description: string
    scope: ApiKeyScope
    created_by: string
    updated_by: string
    created_at: number
    updated_at: number
}

export interface ApiKeyOptions {
    name: string
    description?: string
    scope: ApiKeyScope
}

export enum ApiKeyScope {
    SATELLITE = "satellite",
    CONNECTOR = "Connector",
    ADMIN = "Admin",
    SERVICE_AUTHOR = "ServiceAuthor",
    POLICY_AUTHOR = "PolicyAuthor",
    EVENT_WRITER = "EventWriter",
    READONLY = "ReadOnly",
}

export interface TunnelEndpointCidrRes {
    id: string
    used_for: TunnelEndpointCidrUsedFor
    cidr_range: string
    created_at: number
    created_by: string
}

export interface TunnelEndpointCidrOptions {
    used_for: TunnelEndpointCidrUsedFor
    cidr_range: string
}

export enum TunnelEndpointCidrUsedFor {
    SATELLITE = "satellite",
    ACCESS_TIER_SATELLITE = "access_tier_satellite",
    ACCESS_TIER_ENDUSER_DEVICE = "access_tier_enduser_device",
    ENDUSER_DEVICE = "enduser_device",
}
