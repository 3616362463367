import React from "react"

import { Button, ButtonElement, ButtonType } from "../../../components/button/Button.component"
import {
    ModalApi as BaseModalApi,
    ModalAction,
    ModalWithoutRef,
} from "../../../components/modal/Modal.component"
import { useServiceLinks } from "../../../pre-v3/services/link/Link.service"
import { useServiceLocalization } from "../../../pre-v3/services/localization/Localization.service"
import { AppText } from "../../../v3/components/app-text/AppText.component"
import { Checkbox } from "../../../v3/components/checkbox/Checkbox.component"
import { AccessTierDetails, useSendAccessTierLogs } from "../../../v3/services/AccessTier.service"
import { Banner, Variant } from "../../../components/banner/Banner.component"
import styles from "./SendLogsModal.module.scss"

export { ModalAction } from "../../../components/modal/Modal.component"

export type ModalApi = BaseModalApi<void>

export interface Props {
    accessTier: AccessTierDetails
}

export const SendLogsModal = React.forwardRef<ModalApi, Props>((props, ref) => {
    const linkService = useServiceLinks()
    const localization = useServiceLocalization()

    const modalRef = React.useRef<ModalApi>(null)

    const [willSendLogs, setWillSendLogs] = React.useState(false)
    const [willShareLogs, setWillShareLogs] = React.useState(false)
    const [wereLogsSent, setWereLogsSent] = React.useState(false)

    React.useImperativeHandle(ref, () => ({
        open: () => modalRef.current?.open() ?? Promise.resolve({ action: ModalAction.DISMISS }),
        dismiss: () => {
            modalRef.current?.dismiss()
            setWereLogsSent(false)
        },
        complete: () => {
            modalRef.current?.complete()
            setWereLogsSent(false)
        },
    }))

    const {
        mutate: sendAccessTierLogs,
        isLoading: isSendingLogs,
        error: sendAccessTierLogsError,
    } = useSendAccessTierLogs(props.accessTier, {
        onSuccess: () => setWereLogsSent(true),
    })

    const onDismiss: React.MouseEventHandler = (e) => {
        e.preventDefault()
        modalRef.current?.dismiss()
        setWereLogsSent(false)
    }

    const onComplete: React.MouseEventHandler = (e) => {
        e.preventDefault()
        modalRef.current?.complete()
        setWereLogsSent(false)
    }

    const onSendLogs: React.MouseEventHandler = (e) => {
        e.preventDefault()
        sendAccessTierLogs()
    }

    return ModalWithoutRef(
        {
            id: Id.SEND_LOGS_MODAL,
            title: localization.getString("sendLogsFromAccessTier", props.accessTier.name),
            childrenClassName: styles.children,
            children: (
                <React.Fragment>
                    {!wereLogsSent && (
                        <React.Fragment>
                            <AppText
                                ls={{
                                    key: "getAssistanceTroubleshootingDesc",
                                    replaceVals: [
                                        props.accessTier.name,
                                        linkService.getLink("privacyPolicy"),
                                    ],
                                }}
                            />
                            <div className={styles.checkBox}>
                                <Checkbox checked={willSendLogs} onChange={setWillSendLogs}>
                                    {localization.getString("iConfirmIWantToSendLogDataDesc")}
                                </Checkbox>
                                <Checkbox checked={willShareLogs} onChange={setWillShareLogs}>
                                    {localization.getString("iUnderstandThatBySendingDescription")}
                                </Checkbox>
                            </div>
                        </React.Fragment>
                    )}
                    {wereLogsSent && !isSendingLogs && (
                        <React.Fragment>
                            <Banner
                                label={localization.getString("successfullySentLogsToSonicWallCse")}
                                variant={Variant.SUCCESS}
                            />
                            <AppText
                                ls={{ key: "logsSentDesc", replaceVals: [props.accessTier.name] }}
                            />
                        </React.Fragment>
                    )}
                    {typeof sendAccessTierLogsError === "string" && (
                        <React.Fragment>
                            <Banner label={sendAccessTierLogsError} variant={Variant.ERROR} />
                            <AppText
                                ls={{
                                    key: "errorLogsFailedDesc",
                                    replaceVals: [props.accessTier.name],
                                }}
                            />
                        </React.Fragment>
                    )}
                </React.Fragment>
            ),
            footer: (
                <div className={styles.footer}>
                    <Button
                        asElement={ButtonElement.BUTTON}
                        buttonType={ButtonType.SECONDARY}
                        onClick={onDismiss}
                    >
                        {localization.getString("cancel")}
                    </Button>
                    {!wereLogsSent && (
                        <Button
                            asElement={ButtonElement.BUTTON}
                            buttonType={ButtonType.PRIMARY}
                            disabled={!willSendLogs || !willShareLogs}
                            loading={isSendingLogs}
                            onClick={onSendLogs}
                        >
                            {localization.getString("sendToSonicWallCse")}
                        </Button>
                    )}
                    {(wereLogsSent || !!sendAccessTierLogsError) && (
                        <Button
                            asElement={ButtonElement.BUTTON}
                            buttonType={ButtonType.PRIMARY}
                            onClick={onComplete}
                        >
                            {localization.getString("done")}
                        </Button>
                    )}
                </div>
            ),
        },
        modalRef
    )
})

enum Id {
    SEND_LOGS_MODAL = "sendLogsModal",
}
