import { Singleton } from "../../pre-v3/decorators/Singleton.decorator"
import { BaseApi } from "./Base.api"

@Singleton("MfaApi")
export class MfaApi extends BaseApi {
    public generateMfa(token: string): Promise<GenerateMfaRes> {
        return this.postAuth(
            "/api/v2/mfa/generate",
            token,
            {},
            {
                401: this.localization.getString("youAreNotAuthorizedToPerformThisAction"),
                default: this.localization.getString("anErrorOccurredPleaseTryAgainLater"),
            }
        )
    }

    public verifyMfa(otp: string, token: string): Promise<void> {
        return this.postAuth(
            "/api/v2/mfa/verify",
            token,
            { otp_token: otp },
            {
                400: this.localization.getString("invalidToken"),
                default: this.localization.getString("anErrorOccurredPleaseTryAgainLater"),
            }
        )
    }

    public validateMfa(otp: string, token: string): Promise<ValidateMfaRes> {
        return this.postAuth(
            "/api/v2/mfa/validate",
            token,
            { otp_token: otp },
            {
                400: this.localization.getString("invalidToken"),
                default: this.localization.getString("anErrorOccurredPleaseTryAgainLater"),
            }
        )
    }
}

export interface GenerateMfaRes {
    base32_code: string
    qr_code_data_url: string
}

export interface ValidateMfaRes {
    token: string
}
