import React, { ReactNode, SyntheticEvent } from "react"

import { ROUTE } from "../../../../../routes"
import { LocalizationService } from "../../../../services/localization/Localization.service"
import ActionBarService from "../../../../services/ActionBar.service"
import {
    OperatingSystem,
    PreferredApp,
    SettingsService,
    AppPlatform,
} from "../../../../services/Settings.service"
import { Bind } from "../../../../decorators/Bind.decorator"
import { RouteComponentProps } from "react-router"
import { FormUtil } from "../../../../utils/Form.util"
import { SelectItem } from "../../../../utils/SelectValue.util"
import { decodeID } from "../../../../utils/Url.util"
import EditApplicationCheckTemplate from "./EditApplicationCheck.template"

type Props = RouteComponentProps<{ id: string }>

interface State {
    error?: string
    success?: string
    loading?: boolean
    platforms: string[]
    app?: PreferredApp
    processMap: StringMap
    showDeleteDialog: boolean
}

export class EditApplicationCheck extends React.Component<Props, State> {
    constructor(props: Props) {
        super(props)

        try {
            this.appName = decodeID(this.props.match.params.id)
        } catch {
            this.state.loading = false
        }
    }

    public state: State = {
        loading: true,
        showDeleteDialog: false,
        platforms: [],
        processMap: {},
    }

    public render(): ReactNode {
        return EditApplicationCheckTemplate.call(this)
    }

    public componentDidMount(): void {
        this.actionBarService.setItems(
            this.appName || this.localizationService.getString("editApplication"),
            [
                {
                    label: this.localizationService.getString("trustScoreSettings"),
                },
                {
                    label: this.localizationService.getString("trustFactors"),
                    href: ROUTE.TRUST_FACTOR_SETTINGS,
                },
                {
                    label: this.localizationService.getString("applicationCheck"),
                    href: ROUTE.APPLICATION_CHECK,
                },
            ],
            this.fetchData
        )

        this.fetchData()
    }

    public componentWillUnmount(): void {
        this.actionBarService.unregisterRefreshFn(this.fetchData)
    }

    private localizationService: LocalizationService = new LocalizationService()
    private actionBarService: ActionBarService = new ActionBarService()
    private settingsService: SettingsService = new SettingsService()

    private appName: string

    private mandatoryOptions: SelectItem[] = [
        {
            displayName: this.localizationService.getString(
                "yesRestrictAccessIfThisAppIsNotRunning"
            ),
            value: "true",
        },
        {
            displayName: this.localizationService.getString("noButFactorThisIntoTheTrustLevel"),
            value: "false",
        },
    ]

    private platformOptions: SelectItem[] = [
        {
            displayName: this.localizationService.getString("macOS"),
            value: OperatingSystem.MACOS,
        },
        {
            displayName: this.localizationService.getString("windows"),
            value: OperatingSystem.WINDOWS,
        },
        {
            displayName: this.localizationService.getString("linux"),
            value: OperatingSystem.UBUNTU,
        },
    ]

    @Bind
    private onMandatoryChange(value: string): void {
        if (this.state.app) {
            this.state.app.mandatory = value.toLowerCase() === "true"
            this.setState({ app: this.state.app })
        }
    }

    @Bind
    private onPlatformChange(value: string[]): void {
        this.setState({ platforms: value })
    }

    @Bind
    private onSubmit(event: SyntheticEvent): void {
        this.setState({ error: "", success: "" })
        event.preventDefault()
        if (event.target && this.state.app && this.state.platforms.length > 0) {
            const form: { [key: string]: HTMLInputElement } = <any>event.target
            const platforms: AppPlatform[] = []
            for (let i = 0; i < this.state.platforms.length; i++) {
                const platform: string = this.state.platforms[i]
                const process: string = form[platform + "-id"].value

                const isRegex = /^\/.*\/$/
                if (isRegex.test(process)) {
                    const processRegex: string = process.slice(1, -1)
                    if (!FormUtil.isValidRegex(processRegex)) {
                        this.setState({
                            error: this.localizationService.getString(
                                "errorAProcessNameContainsAnInvalidRegex"
                            ),
                        })
                        return
                    }
                }

                platforms.push({
                    os: platform,
                    process: process,
                })
            }
            this.state.app.platforms = platforms
            this.setState({ loading: true })
            this.settingsService
                .updatePreferredApp(this.state.app)
                .then(
                    () => {
                        this.setState({
                            success: this.localizationService.getString(
                                "successfullyUpdatedApplicationCheck"
                            ),
                        })
                    },
                    (err: Error) => {
                        this.setState({ error: err.message })
                    }
                )
                .finally(() => {
                    this.setState({ loading: false })
                })
        }
    }

    @Bind
    private onShowDeleteDialog(): void {
        this.setState({ showDeleteDialog: true })
    }

    @Bind
    private onHideDeleteDialog(): void {
        this.setState({ showDeleteDialog: false })
    }

    @Bind
    private onDelete(): void {
        if (this.state.app) {
            this.setState({ loading: true, error: "", success: "" })
            this.settingsService
                .deletePreferredApp(this.state.app)
                .then(
                    () => {
                        this.props.history.push(ROUTE.APPLICATION_CHECK)
                    },
                    (err: Error) => {
                        this.setState({
                            error: err.message,
                        })
                    }
                )
                .finally(() => {
                    this.setState({ loading: false })
                })
        }
    }

    @Bind
    private fetchData(): void {
        if (this.appName) {
            this.setState({ error: "", success: "", loading: true })
            this.settingsService
                .getPreferredApp(this.appName)
                .then((app: PreferredApp | undefined) => {
                    if (app) {
                        this.setState({
                            app: app,
                            platforms: app.platforms.map((p) => p.os),
                            processMap: app.platforms.reduce(
                                (map: StringMap, item: AppPlatform) => {
                                    map[item.os] = item.process
                                    return map
                                },
                                {}
                            ),
                        })
                    }
                })
                .finally(() => {
                    this.setState({ loading: false })
                })
        }
    }
}
