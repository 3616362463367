import React from "react"
import styles from "./ServicesEditDeleteDialog.module.scss"
import { ConfirmationDialog, LoadMask, Banner } from "../../../../components"
import { faExclamationCircle } from "@fortawesome/pro-solid-svg-icons"

export default function () {
    return (
        <ConfirmationDialog
            open={this.props.open}
            onClose={this.props.onClose}
            onConfirm={this.onConfirm}
            title={this.localizationService.getString("deleteService")}
            confirmLabel={this.localizationService.getString("delete")}
        >
            {this.state.loading && <LoadMask />}
            {this.state.error && (
                <Banner className={styles.errorBanner} type="error" icon={faExclamationCircle}>
                    {this.state.error}
                </Banner>
            )}
            {this.localizationService.getString("deleteServiceDescription")}
        </ConfirmationDialog>
    )
}
