import React from "react"
import { useHistory } from "react-router-dom"

import {
    Button,
    ButtonElement,
    ButtonType,
} from "../../../../../../../components/button/Button.component"
import { LoadMask, OkCancelActions } from "../../../../../../../pre-v3/components"
import { LanguageKey } from "../../../../../../../pre-v3/services/localization/languages/en-US.language"
import {
    useServiceLocalization,
    LocalizationService,
} from "../../../../../../../pre-v3/services/localization/Localization.service"
import { useActionBar } from "../../../../../../../pre-v3/services/ActionBar.service"
import { useServiceModal } from "../../../../../../../pre-v3/services/Modal.service"
import { StringUtil } from "../../../../../../../pre-v3/utils/String.util"
import { encodeID } from "../../../../../../../pre-v3/utils/Url.util"
import { ROUTE, formatRoutePath } from "../../../../../../../routes"
import { AppText, AppTextProps } from "../../../../../../components/app-text/AppText.component"
import { ErrorBanner, InfoBanner } from "../../../../../../components/banner/Banner.component"
import {
    AvailablePlatforms,
    availablePlatformsClassName,
} from "../../../../../../components/grid/AvailablePlatforms.component"
import {
    Grid,
    Column,
    sortAlphabetically,
    sortByCustomFn,
} from "../../../../../../components/grid/Grid.component"
import { RowTitle, rowTitleClassName } from "../../../../../../components/grid/RowTitle.component"
import { SearchInput } from "../../../../../../components/search-input/SearchInput.component"
import {
    OrgInfo,
    useHasGranularTrustMigrationOccurred,
    useMigrateToGranularTrust,
} from "../../../../../../services/Org.service"
import { getListOfPlatforms } from "../../../../../../services/shared/Platform"
import {
    FAILED_TO_EDIT_EFFECT,
    TrustFactor,
    TRUST_EFFECT,
    TrustSourceType,
    useGetTrustProfile,
    useUpdateTrustProfileEffect,
} from "../../../../../../services/TrustProfile.service"
import { Platform } from "../../../../../../services/shared/Platform"
import { TrustFactorType as TRUST_FACTOR_NAME } from "../../../../../../services/shared/TrustFactorType"
import styles from "./TrustFactors.module.scss"
import { TrustFactorEffect, trustFactorEffectClassName } from "./TrustFactorEffect.component"
import { UpcomingProductEnhancement } from "./UpcomingProductEnhancement.component"

export function TrustFactorList(): JSX.Element {
    const history = useHistory()
    const localization = useServiceLocalization()
    const modalService = useServiceModal()

    const [name, setName] = React.useState("")

    const {
        data: trustProfile,
        refetch,
        error: trustProfileError,
    } = useGetTrustProfile(undefined, { keepPreviousData: true })

    const { data: hasGranularTrustMigrationOcurred } = useHasGranularTrustMigrationOccurred({
        placeholderData: { isGranularTrustEnabled: true } as OrgInfo,
    })

    const {
        mutateAsync: migrateToGranularTrust,
        error: migrateToGranularTrustError,
        isLoading: isMigrateToGranularTrustLoading,
    } = useMigrateToGranularTrust()

    const {
        mutate: editEffectInTrustProfile,
        error: editEffectError,
        isLoading: isEditEffectInTrustProfileLoading,
    } = useUpdateTrustProfileEffect(trustProfile?.id)

    useActionBar({
        title: localization.getString("trustFactors"),
        items: [{ label: localization.getString("trustScoreSettings") }],
        fetchData: async () => {
            await refetch({})
            setName("")
        },
    })

    const trustFactors = React.useMemo(() => {
        if (!name) return trustProfile?.trustFactors
        return trustProfile?.trustFactors.filter((trustFactor) => hasName(trustFactor, name))
    }, [trustProfile?.trustFactors, name])

    const editEffect = React.useCallback(
        (effect: TRUST_EFFECT, factor: TrustFactor): void => {
            if (trustProfile) editEffectInTrustProfile([effect, factor, trustProfile])
        },
        [editEffectInTrustProfile, trustProfile]
    )

    const columns = useColumns(editEffect)

    const getError = (): string | undefined => {
        if (typeof migrateToGranularTrustError === "string") return migrateToGranularTrustError
        if (typeof trustProfileError === "string") return trustProfileError
        if (editEffectError) {
            if (editEffectError === FAILED_TO_EDIT_EFFECT) {
                return localization.getString("failedToEditTheTrustEffect")
            }
            if (typeof editEffectError === "string") {
                return editEffectError
            }
        }
    }

    const error = getError()

    const onMigrate: React.MouseEventHandler = (event) => {
        event.preventDefault()

        const appTextProps: AppTextProps = {
            ls: "theMigrationToTheNewTrustScoringModelCannotBeReverted",
        }

        modalService
            .open(
                localization.getString("sonicWallCsesNewTrustScoringModel"),
                { component: AppText, props: appTextProps },
                {
                    component: OkCancelActions,
                    props: { okString: localization.getString("yesFinishMigration") },
                }
            )
            .onClose(async () => {
                await migrateToGranularTrust()
                history.push(ROUTE.PROFILES)
            })
    }

    return (
        <React.Fragment>
            {(isMigrateToGranularTrustLoading || isEditEffectInTrustProfileLoading) && <LoadMask />}
            {!hasGranularTrustMigrationOcurred && <UpcomingProductEnhancement />}
            <div className={styles.container}>
                {error && <ErrorBanner className={styles.errorBanner}>{error}</ErrorBanner>}
                <SearchInput
                    value={name}
                    onChangeValue={setName}
                    placeholder={localization.getString("searchByName")}
                    className={styles.input}
                />
                <Grid
                    data={trustProfileError ? [] : trustFactors}
                    columns={columns}
                    className={styles.grid}
                />
                {!hasGranularTrustMigrationOcurred && (
                    <React.Fragment>
                        <InfoBanner className={styles.completeMigrationBanner}>
                            <AppText ls="completeMigrationInstruction" />
                        </InfoBanner>
                        <Button
                            asElement={ButtonElement.BUTTON}
                            buttonType={ButtonType.PRIMARY}
                            disabled={typeof error === "string"}
                            onClick={onMigrate}
                            className={styles.migrateButton}
                        >
                            {localization.getString("migrate")}
                        </Button>
                    </React.Fragment>
                )}
            </div>
        </React.Fragment>
    )
}

function useColumns(
    editEffect: (effect: TRUST_EFFECT, factor: TrustFactor) => void
): Column<TrustFactor>[] {
    const localization = useServiceLocalization()

    return React.useMemo<Column<TrustFactor>[]>(
        () => [
            {
                id: "name",
                name: localization.getString("name"),
                cellRenderer: (trustFactor) => (
                    <RowTitle
                        title={trustFactor.label}
                        description={trustFactor.description}
                        route={getRoute(trustFactor) ?? undefined}
                    />
                ),
                cellClassName: rowTitleClassName,
                getTooltipValue: "label",
                sorting: sortAlphabetically("label"),
            },
            {
                id: "source",
                name: localization.getString("source"),
                cellRenderer: displaySource,
                getTooltipValue: displaySource,
                sorting: sortAlphabetically(displaySource),
            },
            {
                id: "applicablePlatform",
                name: localization.getString("applicablePlatform"),
                cellRenderer: (trustFactor) => (
                    <AvailablePlatforms
                        isMacOsAvailable={trustFactor.applicablePlatform[Platform.MACOS]}
                        isWindowsAvailable={trustFactor.applicablePlatform[Platform.WINDOWS]}
                        isLinuxAvailable={trustFactor.applicablePlatform[Platform.LINUX]}
                        isIosAvailable={trustFactor.applicablePlatform[Platform.IOS]}
                        isAndroidAvailable={trustFactor.applicablePlatform[Platform.ANDROID]}
                        isChromeBrowserAvailable={trustFactor.applicablePlatform[Platform.CHROME]}
                    />
                ),
                cellClassName: availablePlatformsClassName,
                getTooltipValue: (trustFactor) =>
                    getListOfPlatforms(trustFactor.applicablePlatform, localization),
            },
            {
                id: "effect",
                name: localization.getString("effect"),
                cellRenderer: (trustFactor) => {
                    const editEffectFn = (effect: TRUST_EFFECT): void =>
                        editEffect(effect, trustFactor)
                    return TrustFactorEffect(trustFactor, editEffectFn)
                },
                cellClassName: trustFactorEffectClassName,
                getTooltipValue: (trustFactor) => getEffectTooltipValue(trustFactor, localization),
                sorting: sortByCustomFn(sortEffect),
            },
        ],
        [localization, editEffect]
    )
}

function hasName(trustFactor: TrustFactor, name: string): boolean {
    return StringUtil.caseInsensitiveIncludes(trustFactor.label, name)
}

function displaySource(trustFactor: TrustFactor): string {
    return trustFactor.source.name
}

function getEffectTooltipValue(
    trustFactor: TrustFactor,
    localization: LocalizationService
): string {
    return localization.getString(effectDictionary[trustFactor.effect])
}

const effectDictionary: Record<TRUST_EFFECT, LanguageKey> = {
    [TRUST_EFFECT.NOT_EVALUATED]: "notEvaluated",
    [TRUST_EFFECT.ALWAYS_DENY]: "alwaysDeny",
    [TRUST_EFFECT.LOW_TRUST_LEVEL]: "lowTrustLevel",
    [TRUST_EFFECT.MEDIUM_TRUST_LEVEL]: "mediumTrustLevel",
    [TRUST_EFFECT.NO_EFFECT]: "noEffect",
}

function sortEffect(trustFactorA: TrustFactor, trustFactorB: TrustFactor): number {
    return effectOrder[trustFactorA.effect] - effectOrder[trustFactorB.effect]
}

const effectOrder: Record<TRUST_EFFECT, number> = {
    [TRUST_EFFECT.NOT_EVALUATED]: 1,
    [TRUST_EFFECT.ALWAYS_DENY]: 2,
    [TRUST_EFFECT.LOW_TRUST_LEVEL]: 3,
    [TRUST_EFFECT.MEDIUM_TRUST_LEVEL]: 4,
    [TRUST_EFFECT.NO_EFFECT]: 5,
}

function getRoute(trustFactor: TrustFactor): ROUTE | null {
    switch (trustFactor.source.type) {
        case TrustSourceType.BANYAN: {
            if (trustFactor.name === TRUST_FACTOR_NAME.APPLICATION_CHECK)
                return ROUTE.APPLICATION_CHECK
            if (trustFactor.name === TRUST_FACTOR_NAME.OPERATING_SYSTEM_VERSION)
                return ROUTE.OPERATING_SYSTEM_VERSIONS
            return null
        }

        case TrustSourceType.WORKSPACE_ONE:
        case TrustSourceType.CROWD_STRIKE:
        case TrustSourceType.SENTINEL_ONE:
            return formatRoutePath(ROUTE.INTEGRATIONS_EDIT, {
                id: encodeID(trustFactor.source.integrationId),
            })
    }
}
