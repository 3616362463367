import React from "react"
import {
    TrustFactorAccordion,
    Props as TrustFactorAccordionProps,
} from "./TrustFactorAccordion.component"
import styles from "./TrustFactorsForm.module.scss"
import { useServiceLocalization } from "../../../../../../../pre-v3/services/localization/Localization.service"
import { Banner, Variant } from "../../../../../../../components/banner/Banner.component"

interface Props extends TrustFactorAccordionProps {
    children: React.ReactNode
}

export function TrustFactorsForm(props: Props): JSX.Element {
    const { children, ...accordionProps } = props
    const localization = useServiceLocalization()
    return (
        <div className={styles.container}>
            <Banner
                label={localization.getString("addTrustFactorsDescription")}
                variant={Variant.INFORMATION}
            />
            <TrustFactorAccordion {...accordionProps} />
            {children}
        </div>
    )
}
