import * as React from "react"
import { useHistory } from "react-router"

import { Button, ButtonElement, ButtonType } from "../../../../components/button/Button.component"
import { useServiceLocalization } from "../../../../pre-v3/services/localization/Localization.service"
import { ModalRef } from "../../../../pre-v3/services/Modal.service"
import { FormUtil } from "../../../../pre-v3/utils/Form.util"
import { encodeID } from "../../../../pre-v3/utils/Url.util"
import { ROUTE, formatRoutePath } from "../../../../routes"
import {
    WebService,
    getParamsToCloneHostedWebsite,
    useCreateHostedService,
} from "../../../services/HostedService.service"
import { Form } from "../../../components/form/Form.component"
import { ErrorBanner } from "../../../components/banner/Banner.component"
import { FormRow } from "../../../components/form/FormRow.component"
import { Container } from "../../../components/container/Container.component"
import { FormButtons } from "../../../components/form/form-buttons/FormButtons.component"
import { NameInput } from "../shared/NameInput.component"

interface Props {
    service: WebService
    modalRef: ModalRef
}

export function ServiceCloneModal({ service, modalRef }: Props) {
    const localization = useServiceLocalization()
    const history = useHistory()

    const defaultServiceName = `${service.name}-clone`

    const {
        isLoading: isServiceRegistering,
        error: serviceError,
        mutate: createWebService,
    } = useCreateHostedService<WebService>(false, {
        onSuccess: (hostedWebsite) => {
            if (!hostedWebsite.id) return
            history.push(
                formatRoutePath(ROUTE.HOSTED_WEBSITES_DETAILS, {
                    id: encodeID(hostedWebsite.id),
                })
            )
            modalRef.close(null)
        },
    })

    const submitForm = async (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault()
        const newHostedWebsiteName = FormUtil.getFieldValue(event, "serviceName")
        createWebService(getParamsToCloneHostedWebsite(service, newHostedWebsiteName))
    }

    return (
        <Container>
            <Form onSubmit={submitForm}>
                {typeof serviceError === "string" && (
                    <ErrorBanner>{String(serviceError)}</ErrorBanner>
                )}
                <FormRow
                    label={localization.getString("whatWouldYouLikeToNameTheService")}
                    description={localization.getString(
                        "thisIsTheNameDisplayedInTheCseAppDirectoryForEndUsers"
                    )}
                    htmlFor="serviceName"
                >
                    <NameInput
                        defaultValue={defaultServiceName}
                        name="serviceName"
                        placeholder={localization.getString("serviceName")}
                    />
                </FormRow>
                <FormButtons
                    rightButtons={
                        <>
                            <Button
                                asElement={ButtonElement.BUTTON}
                                buttonType={ButtonType.SECONDARY}
                                onClick={modalRef.cancel}
                            >
                                {localization.getString("cancel")}
                            </Button>
                            <Button
                                asElement={ButtonElement.BUTTON}
                                buttonType={ButtonType.PRIMARY}
                                loading={isServiceRegistering}
                                type="submit"
                            >
                                {localization.getString("cloneService")}
                            </Button>
                        </>
                    }
                />
            </Form>
        </Container>
    )
}
