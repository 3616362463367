import React from "react"

import { useServiceLocalization } from "../../../../pre-v3/services"
import { ModalRef } from "../../../../pre-v3/services/Modal.service"
import { ROUTE } from "../../../../routes"
import { Button } from "../../../components/button/Button.component"
import { Link } from "../../../components/link/Link.component"
import { RouteUtils } from "../Route.utils"

interface SamlLoginDialogProps {
    orgName: string
    modalRef: ModalRef
}

export function SamlLoginDialog(props: SamlLoginDialogProps): JSX.Element {
    const localization = useServiceLocalization()

    return (
        <React.Fragment>
            <p>{localization.getString("samlLoginDescription")}</p>
            <p>
                <Link
                    to={RouteUtils.appendOrgToRoute(ROUTE.LOCAL_ACCOUNT_FORM, props.orgName)}
                    onClick={props.modalRef.cancel}
                >
                    {localization.getString("toLogInWithALocalAccountClickHere")}
                </Link>
            </p>
        </React.Fragment>
    )
}

interface ActionsProps {
    modalRef: ModalRef
}

export function Actions(props: ActionsProps): JSX.Element {
    const localization = useServiceLocalization()

    return (
        <React.Fragment>
            <Button onClick={props.modalRef.cancel}>{localization.getString("cancel")}</Button>
            <Button brand onClick={props.modalRef.close}>
                {localization.getString("continue")}
            </Button>
        </React.Fragment>
    )
}
