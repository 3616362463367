import React, { useCallback, useEffect, useRef } from "react"

import { LocalizationService } from "../../../services/localization/Localization.service"
import { AppBanner } from "../../../../v3/views/app/AppBanner.component"
import {
    IServerSideDatasource,
    IServerSideGetRowsParams,
    GridApi,
    ColDef,
    GridReadyEvent,
} from "ag-grid-community"
import { FilterModel, DataFilter, DataFilterType, SortBy } from "../../../utils/AgGrid.util"
import { TimeRange, DateUtil } from "../../../utils/Date.util"
import { EventsUtil } from "../../../utils/Events.util"
import { UrlUtil } from "../../../utils/Url.util"
import {
    AdminUser,
    UserService,
    useServiceLocalization,
    useServiceUser,
    SystemLogsService,
} from "../../../services"
import classNames from "classnames/bind"
import styles from "./SystemLogs.module.scss"
import { Grid, FilterBar } from "../../../components"
import { SystemLogsDetailCellRenderer } from "./detail-cell-renderer/SystemLogsDetailCellRenderer.component"
import { ErrorBanner } from "../../../../v3/components/banner/Banner.component"
import { PageHeading } from "../../../../components/page-heading/PageHeading.component"
import {
    Button,
    ButtonElement,
    ButtonType,
    IconType,
} from "../../../../components/button/Button.component"
import useTitle from "../../../../hooks/useTitle.hook"
import { Tooltip } from "../../../../v3/components/tooltip/Tooltip.component"

export function SystemLogs(): JSX.Element {
    useTitle(["systemLogs", "home", "adminConsole"])

    const ls: LocalizationService = useServiceLocalization()
    const userService: UserService = useServiceUser()
    const gridApi = useRef<GridApi>()
    const systemLogsService: SystemLogsService = new SystemLogsService()

    const [logCount, setLogCount] = React.useState(0)
    const [admins, setAdmins] = React.useState<string[]>([])
    const [error, setError] = React.useState<string>()

    const initialModel: FilterModel = UrlUtil.readFilter()

    const filters: DataFilter[] = [
        {
            key: FilterType.ACTION,
            displayName: ls.getString("action"),
            type: DataFilterType.LIST,
            options: systemLogsService.getActionTypes(),
        },
        {
            key: FilterType.ADMIN_EMAIL,
            displayName: ls.getString("adminEmail"),
            type: DataFilterType.LOOKUP,
            dataSource: (search: string) => {
                if (admins) {
                    return Promise.resolve(admins.filter((email) => email.includes(search)))
                } else {
                    return Promise.resolve([])
                }
            },
        },
        {
            key: FilterType.TYPE,
            displayName: ls.getString("type"),
            type: DataFilterType.LIST,
            options: systemLogsService.getActivityTypes(),
        },
        {
            key: FilterType.TIMESTAMP,
            displayName: ls.getString("timestamp"),
            type: DataFilterType.DATETIMERANGE,
        },
    ]

    const columns: ColDef[] = [
        {
            headerName: ls.getString("timestamp"),
            field: "timestamp",
            width: 150,
            sortable: true,
            filter: "agTextColumnFilter",
            cellRenderer: "agGroupCellRenderer",
            sort: "desc",
            sortingOrder: ["desc", "asc"],
        },
        {
            headerName: ls.getString("message"),
            field: "activity",
            sortable: false,
            filter: "agTextColumnFilter",
        },
        {
            headerName: ls.getString("action"),
            field: "action",
            filter: "agTextColumnFilter",
            sortable: false,
        },
        {
            headerName: ls.getString("type"),
            field: "type",
            sortable: false,
            filter: "agTextColumnFilter",
        },
        {
            headerName: ls.getString("admin"),
            field: "adminEmail",
            sortable: false,
            filter: "agTextColumnFilter",
        },
    ]

    const onSetFilter = (model: FilterModel) => {
        if (gridApi && gridApi.current) {
            gridApi.current.setFilterModel(model)
        }
        UrlUtil.writeFilter(model)
    }

    const onGridReady = (event: GridReadyEvent) => {
        if (event && event.api) {
            gridApi.current = event.api
        }
        if (Object.keys(initialModel).length > 0 && gridApi.current) {
            gridApi.current.setFilterModel(initialModel)
        }
        fetchData()
    }

    const onRefresh = () => {
        if (gridApi.current) {
            gridApi.current.refreshServerSideStore()
        }
    }

    const fetchData = () => {
        if (gridApi.current) {
            gridApi.current.setServerSideDatasource(
                DataSource(gridApi.current, setLogCount, setError)
            )
        }
    }

    const getAdmins = useCallback(() => {
        userService.getAdmins().then(
            (res: AdminUser[]) => {
                setAdmins(res.map((r) => r.email.trim()))
            },
            () => {
                setAdmins([])
            }
        )
    }, [userService])

    useEffect(() => {
        getAdmins()
    }, [getAdmins])

    return (
        <React.Fragment>
            <AppBanner />
            <section aria-labelledby={Id.HEADING} className={styles.container}>
                <header className={styles.header}>
                    <PageHeading id={Id.HEADING}>{ls.getString("systemLogs")}</PageHeading>
                    <Tooltip title={ls.getString("refresh")}>
                        <Button
                            icon={IconType.REDO}
                            onClick={onRefresh}
                            asElement={ButtonElement.BUTTON}
                            buttonType={ButtonType.SECONDARY}
                        />
                    </Tooltip>
                </header>
                <div className={styles.content}>
                    {error && <ErrorBanner className={styles.errorBanner}>{error}</ErrorBanner>}
                    <div className={styles.filterContainer}>
                        <FilterBar
                            filters={filters}
                            initialModel={initialModel}
                            className={styles.filterBar}
                            onChange={onSetFilter}
                        />
                    </div>

                    <div className={styles.logCount}>
                        {ls.getPluralString(
                            "showingLogs",
                            logCount,
                            EventsUtil.getFormattedEventsCount(logCount)
                        )}
                    </div>

                    <div className={classNames("ag-theme-material", styles.grid)}>
                        <Grid
                            onGridReady={onGridReady}
                            columnDefs={columns}
                            serverSidePagination
                            components={{
                                systemLogsDetailCellRenderer: SystemLogsDetailCellRenderer,
                            }}
                            masterDetail
                            detailCellRenderer="systemLogsDetailCellRenderer"
                        />
                    </div>
                </div>
            </section>
        </React.Fragment>
    )
}

enum Id {
    HEADING = "heading",
}

function DataSource(
    gridApi: GridApi,
    setLogsCount: Function,
    onError: (error: string) => void
): IServerSideDatasource {
    const systemLogsService: SystemLogsService = new SystemLogsService()
    const getRows = (params: IServerSideGetRowsParams) => {
        const skipLimit: { skip: number; limit: number } = {
            skip: 0,
            limit: 10000,
        }
        if (params.request.startRow) {
            skipLimit.skip = params.request.startRow
        }

        if (params.request.endRow) {
            skipLimit.limit = params.request.endRow - skipLimit.skip
        }

        const filterModel: FilterModel = params.request.filterModel
        const sortModel: SortBy[] = params.request.sortModel

        let startTime: number = 0
        let endTime: number = DateUtil.convertToLargeTimestamp(Date.now())
        if (filterModel?.timestamp) {
            const timeRange: TimeRange = DateUtil.deserializeTimeRange(filterModel.timestamp.filter)
            startTime = timeRange.start ? DateUtil.convertToLargeTimestamp(timeRange.start) : 0
            endTime = timeRange.end ? DateUtil.convertToLargeTimestamp(timeRange.end) : 0
        }

        gridApi.showLoadingOverlay()
        systemLogsService
            .getSystemLogs({ ...skipLimit, sortModel, filterModel }, startTime, endTime)
            .then(
                (result) => {
                    params.success({
                        rowData: result.data,
                        rowCount: result.total,
                    })
                    gridApi.hideOverlay()
                    if (result.total === 0) {
                        gridApi.showNoRowsOverlay()
                    }
                    setLogsCount(result.total)
                },
                (error) => {
                    params.fail()
                    gridApi.showNoRowsOverlay()

                    if (error?.message) onError(error.message)
                }
            )
    }

    return { getRows }
}

export enum FilterType {
    TYPE = "type",
    TIMESTAMP = "timestamp",
    ACTIVITY = "message",
    ACTION = "action",
    ADMIN_EMAIL = "adminEmail",
}
