import React from "react"
import styles from "./TrustProviderAppDeploymentSettings.module.scss"
import { PageBreak } from "../../../../components/page-break/PageBreak.component"
import { TrustProviderDownloadApp } from "./download-app/TrustProviderDownloadApp.component"
import { TrustProviderInviteCodeSettings } from "./invite-code/TrustProviderInviteCodeSettings.component"
import { TrustProviderMdmConfigSettings } from "./mdm-config/TrustProviderMdmConfigSettings.component"

export default function () {
    return (
        <div className={styles.pagePadded}>
            <PageBreak className={styles.pageBreak}>
                {this.localizationService.getString("downloadAndInstallCseAppsOnDesktopAndMobile")}
            </PageBreak>
            <TrustProviderDownloadApp />

            <PageBreak className={styles.pageBreak}>
                {this.localizationService.getString("inviteCode")}
            </PageBreak>
            <TrustProviderInviteCodeSettings canUpdateInviteCode={this.props.canUpdateInviteCode} />

            <TrustProviderMdmConfigSettings canUpdateMdmSetting={this.props.canUpdateMdmSetting} />
        </div>
    )
}
