import { faExclamation } from "@fortawesome/pro-solid-svg-icons"
import React from "react"
import { RouteComponentProps } from "react-router"
import { useHistory, useRouteMatch } from "react-router-dom"

import { ROUTE, formatRoutePath } from "../../../../routes"
import { SpecKind } from "../../../api/Secure.api"
import {
    ServiceAppType,
    ServiceType,
    useActionBar,
    useRegisteredService,
    useServiceLocalization,
    useServiceManage,
} from "../../../services"
import { LargeMessage } from "../../../components"
import { decodeID, encodeID, UrlUtil } from "../../../utils/Url.util"
import HostedServiceForm from "../service-form-hosted"
import { SubmitHandler } from "../service-form"
import styles from "./EditServiceForm.module.scss"
import { PageHeading } from "../../../../components/page-heading/PageHeading.component"
import classnames from "classnames"
import { Loader } from "../../../../v3/components/loader/Loader.component"

type EditServiceProps = RouteComponentProps<{
    id: string
}>

export default function EditService({ match, location }: EditServiceProps) {
    // pull out the route params we care about
    let serviceID: string
    // if the url parameter is not a valid base-64 string, just use the value
    // in the URL (this will likely fail and show the error state as desired)
    try {
        serviceID = decodeID(match.params.id)
    } catch {
        serviceID = match.params.id
    }

    // grab the information for the service we are editing
    const { data, loading, error } = useRegisteredService(serviceID)

    // grab the service references we need
    const localization = useServiceLocalization()

    // generate the submit handler for the form
    const onSubmit = useOnSubmit(serviceID, data?.policyId, data?.nameId || "")

    const isInfrastructureEdit = match.path === ROUTE.INFRASTRUCTURE_EDIT

    // make sure the action bar is up to date
    useActionBar({
        title: localization.getString("editService"),
        items: [
            {
                label: localization.getString("manageServices"),
                href: "",
            },
            {
                label: isInfrastructureEdit
                    ? localization.getString("infrastructureServices")
                    : localization.getString("hostedWebsites"),
                href: isInfrastructureEdit ? ROUTE.INFRASTRUCTURE : ROUTE.HOSTED_WEBSITES,
            },
            {
                label: serviceID,
                href: UrlUtil.getParentPath(location.pathname),
            },
        ],
    })

    // pull out the type and app type from the service spec
    const spec = data?.spec
    const appType = spec?.metadata.tags.service_app_type
    const type = spec?.metadata.tags.template

    // render the service form editing the result
    const classes = classnames(styles.container, "pre-v3")

    return (
        <>
            {loading && <Loader center medium />}
            {(error || (!data && !loading)) && (
                <LargeMessage icon={faExclamation} className={styles.missingMessage}>
                    {localization.getString("serviceNotFound")}
                </LargeMessage>
            )}
            {data && (
                <div aria-labelledby={Id.HEADING} className={classes}>
                    <header>
                        <PageHeading id={Id.HEADING}>{serviceID}</PageHeading>
                    </header>
                    <section>
                        <HostedServiceForm
                            edit={data}
                            type={type as ServiceType}
                            appType={appType as ServiceAppType}
                            onSubmit={onSubmit}
                        />
                    </section>
                </div>
            )}
        </>
    )
}

// onSubmit is called when the form is submitted
const useOnSubmit: (
    serviceID: string,
    currentPolicy: string | undefined,
    nameId: string
) => SubmitHandler = (serviceID, currentPolicy, nameId) => {
    const manageService = useServiceManage()
    const history = useHistory()
    const match = useRouteMatch()

    const isInfra = match.path === ROUTE.INFRASTRUCTURE_EDIT

    return async ({ metaData, attributes, policyEnabled, policyID }): Promise<void> => {
        const service = await manageService.editRegisteredService({
            metadata: metaData,
            id: serviceID,
            type: SpecKind.USER,
            attributes,
            oldPolicyId: currentPolicy,
            policyId: policyID,
            enabled: policyEnabled,
            nameId,
        })

        // the target URL
        const targetURL = isInfra ? ROUTE.INFRASTRUCTURE_DETAILS : ROUTE.HOSTED_WEBSITES_DETAILS

        history.push(formatRoutePath(targetURL, { id: encodeID(service.id) }))
    }
}

enum Id {
    HEADING = "heading",
}
