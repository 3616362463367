import React, { ReactNode, useRef, useState } from "react"

import { ROUTE } from "../../../../../routes"
import styles from "./UserAdd.module.scss"
import { useServiceLocalization } from "../../../../../pre-v3/services/localization/Localization.service"
import { useHistory } from "react-router-dom"
import { encodeID } from "../../../../../pre-v3/utils/Url.util"
import { TransferList } from "../../../../../pre-v3/components/transfer-list/TransferList.component"
import { useQuery } from "@tanstack/react-query"
import { RoleSecure, useServiceSecure } from "../../../../../pre-v3/services"
import { SelectItem } from "../../../../../pre-v3/utils/SelectValue.util"
import { Form } from "../../../../components/form/Form.component"
import { Input } from "../../../../components/input/Input.component"
import { FormRow } from "../../../../components/form/FormRow.component"
import { FormButtons } from "../../../../components/form/form-buttons/FormButtons.component"
import { Container } from "../../../../components/container/Container.component"
import { ErrorBanners } from "../../../../components/banner/Banner.component"
import {
    ButtonElement,
    Button,
    ButtonType,
} from "../../../../../components/button/Button.component"
import { ToggleButton } from "../../../../components/toggle-button/ToggleButton"
import { FormColumn } from "../../../../components/form/FormColumn.component"
import { User, UserGroup, useAddUser } from "../../../../services/User.service"
import { PageHeading } from "../../../../../components/page-heading/PageHeading.component"
import { useFeatureFlags } from "../../../../../hooks/useFeatureFlags.hook"

export function UserAdd() {
    const ls = useServiceLocalization()
    const secureService = useServiceSecure()
    const history = useHistory()

    const [name, setName] = useState("")
    const [email, setEmail] = useState("")
    const [selectedRoles, setSelectedRoles] = useState<string[]>([])
    const [isAdminEnabled, setIsAdminEnabled] = useState<boolean>(false)

    const rolesMap = useRef<Map<string, RoleSecure>>(new Map())

    const {
        data: selectedOptions,
        isFetching: isRolesLoading,
        error: rolesError,
    } = useQuery<SelectItem[], string>(["get-roles"], () => {
        return secureService.getRoles().then((roles) => {
            const options: SelectItem[] = roles
                .filter(
                    (r) =>
                        ![UserGroup.ALL_USERS, UserGroup.ALL_ADMINS].includes(r.name as UserGroup)
                )
                .map((role) => {
                    rolesMap.current.set(role.id, role)
                    return { displayName: role.name, value: role.id }
                })
            return options
        })
    })

    const { data: featureFlags } = useFeatureFlags()

    const options = selectedOptions && selectedOptions.length > 0 ? selectedOptions : []

    const {
        mutate: createUser,
        error: createUserError,
        isLoading: isCreateUserLoading,
    } = useAddUser({
        onSuccess: () => {
            history.push(`${ROUTE.USERS}?newUser=${encodeID(email)}`)
        },
    })

    const isDataLoading: boolean = isRolesLoading || isCreateUserLoading || !featureFlags

    const errors: ReactNode[] = [createUserError, rolesError]

    function onSubmit(e: React.SyntheticEvent) {
        e.preventDefault()
        const roles: RoleSecure[] = []
        for (const role of selectedRoles) {
            if (rolesMap.current.has(role)) {
                roles.push(rolesMap.current.get(role)!)
            }
        }

        const user: User = {
            id: "",
            name,
            email: email.toLowerCase(),
            isAdminUser: isAdminEnabled,
            // The attached roles in the creation process comes from the `roles`
            // declaration above.
            roles: [],
        }

        createUser([user, roles])
    }

    return (
        <Container className={styles.container}>
            <section aria-label={Id.HEADING}>
                <header className={styles.header}>
                    <PageHeading id={Id.HEADING}>{ls.getString("users")}</PageHeading>
                </header>
                <Form onSubmit={onSubmit} className={styles.form}>
                    <FormRow label={ls.getString("name")}>
                        <Input
                            type="text"
                            className={styles.formInput}
                            placeholder={ls.getString("name")}
                            value={name}
                            onChangeValue={setName}
                            required
                        />
                    </FormRow>
                    <FormRow label={ls.getString("email")}>
                        <Input
                            className={styles.formInput}
                            type="email"
                            placeholder={ls.getString("email")}
                            value={email}
                            onChangeValue={setEmail}
                            required
                        />
                    </FormRow>
                    {featureFlags?.adminConsole.canAddAdmin && (
                        <FormRow
                            label={ls.getString(
                                "shouldThisUserHaveAccessToSonicWallCseCommandCenter"
                            )}
                            description={ls.getString(
                                "usersThatAreAbleToAccessCommandCenterWillBePlacedInAllAdminsRole"
                            )}
                            childrenClassName={styles.flex}
                        >
                            <ToggleButton
                                items={[
                                    {
                                        label: ls.getString("yes"),
                                        onClick: () => {
                                            setIsAdminEnabled(true)
                                        },
                                        selected: isAdminEnabled,
                                    },
                                    {
                                        label: ls.getString("no"),
                                        onClick: () => {
                                            setIsAdminEnabled(false)
                                        },
                                        selected: !isAdminEnabled,
                                    },
                                ]}
                            />
                        </FormRow>
                    )}
                    <FormColumn
                        label={ls.getString("roles")}
                        className={styles.multiSelect}
                        description={ls.getString("allUsersDescription")}
                    >
                        <TransferList
                            options={options}
                            value={selectedRoles}
                            onChange={setSelectedRoles}
                            className={styles.transferList}
                        />
                    </FormColumn>
                    <FormButtons
                        rightButtons={
                            <>
                                <Button
                                    asElement={ButtonElement.LINK}
                                    to={ROUTE.USERS}
                                    buttonType={ButtonType.SECONDARY}
                                >
                                    {ls.getString("cancel")}
                                </Button>
                                <Button
                                    asElement={ButtonElement.BUTTON}
                                    buttonType={ButtonType.PRIMARY}
                                    type="submit"
                                    loading={isDataLoading}
                                >
                                    {ls.getString("addUser")}
                                </Button>
                            </>
                        }
                    >
                        <ErrorBanners errors={errors} />
                    </FormButtons>
                </Form>
            </section>
        </Container>
    )
}

enum Id {
    HEADING = "heading",
}
