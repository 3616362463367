import React, { FC } from "react"
import { KeyValuePairItems } from "../../../../components/overview/OverviewTopContainer/OverviewTypes"
import { StatusType } from "../../../components/status/Status.component"
import { OverviewStatus } from "../../../../components/overview/OverviewTopContainer/OverviewStatus.component"
import { OverviewDetails } from "../../../../components/overview/OverviewTopContainer/OverviewDetails.component"
import styles from "./ConnectorTopContainer.module.scss"

interface Props {
    statusType: StatusType
    statusItems: KeyValuePairItems[]
    statusLabel: string
    listItems: KeyValuePairItems[]
}

export const ConnectorTopContainer: FC<Props> = ({
    statusType,
    statusItems,
    statusLabel,
    listItems,
}) => {
    return (
        <div className={styles.container}>
            <OverviewStatus
                statusType={statusType}
                statusItems={statusItems}
                statusLabel={statusLabel}
            />
            <OverviewDetails listItems={listItems} />
        </div>
    )
}
