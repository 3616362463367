import { Tooltip } from "@mui/material"
import React from "react"

import {
    Button,
    ButtonElement,
    ButtonType,
    IconType,
} from "../../../../../components/button/Button.component"
import styles from "./OrgAdminSettings.module.scss"
import { Toolbar } from "../../../../components/toolbar/Toolbar.component"
import { Grid } from "../../../../components/grid/Grid.component"
import { AdminActionBar } from "../../../../../pages/directory/admins/AdminActionBar.component"

export default function () {
    const cannotAddAdmin = !this.state.userCanAddAdmin

    return (
        <AdminActionBar onRefresh={this.fetchData}>
            <div className={styles.toolbar}>
                <Toolbar onSearchChange={this.onFilter} className={styles.search} />
                {this.props.canAdd && (
                    <Tooltip
                        title={
                            cannotAddAdmin &&
                            this.localizationService.getString("cannotAddLocalAdminsDescription")
                        }
                        placement="left"
                    >
                        <span>
                            <Button
                                asElement={ButtonElement.BUTTON}
                                buttonType={ButtonType.PRIMARY}
                                icon={IconType.PLUS}
                                disabled={cannotAddAdmin}
                                onClick={this.onAddAdmin}
                            >
                                {this.localizationService.getString("addAdmin")}
                            </Button>
                        </span>
                    </Tooltip>
                )}
            </div>
            <Grid
                onGridReady={this.onGridReady}
                columnDefs={this.columns}
                context={{ history: this.props.history }}
                pagination
                onRowDoubleClicked={this.onRowDoubleClicked}
            />
        </AdminActionBar>
    )
}
