import React, { Fragment } from "react"
import styles from "./ServiceBundleOverview.module.scss"
import { LoadMask } from "../../../../../components/load-mask/LoadMask.component"
import { LargeMessage } from "../../../../../components/large-message/LargeMessage.component"
import { faExclamation, faExclamationCircle } from "@fortawesome/pro-solid-svg-icons"
import { Banner } from "../../../../../components/banner/Banner.component"
import { ServicesCellRenderer } from "../../../../services/cell-renderer/ServicesCellRenderer"
import { SimpleTable } from "../../../../../components/simple-table/SimpleTable.component"
import { TabBar } from "../../../../../components/tab-bar/TabBar.component"
import { Grid } from "../../../../../components/grid/Grid.component"
import { Select } from "../../../../../components/select/Select.component"
import { FormLabel } from "../../../../../components/form-label/FormLabel"
import { PageHeading } from "../../../../../../components/page-heading/PageHeading.component"
import {
    Button,
    ButtonElement,
    ButtonType,
    IconType,
} from "../../../../../../components/button/Button.component"
import { Tooltip } from "../../../../../../v3/components/tooltip/Tooltip.component"

export default function () {
    return (
        <section className={styles.section}>
            <header className={styles.header}>
                <PageHeading>{this.state.serviceBundle?.name}</PageHeading>
                <div className={styles.actionButtons}>
                    {this.props.canAccessServiceBundle && !this.state.readOnly && (
                        <Tooltip title={this.localizationService.getString("editServiceBundle")}>
                            <Button
                                icon={IconType.PEN}
                                onClick={this.onEdit}
                                asElement={ButtonElement.BUTTON}
                                buttonType={ButtonType.SECONDARY}
                                aria-label={this.localizationService.getString("editServiceBundle")}
                            />
                        </Tooltip>
                    )}
                    <Tooltip title={this.localizationService.getString("refresh")}>
                        <Button
                            icon={IconType.REDO}
                            onClick={this.fetchData}
                            asElement={ButtonElement.BUTTON}
                            buttonType={ButtonType.SECONDARY}
                            aria-label={this.localizationService.getString("refresh")}
                        />
                    </Tooltip>
                </div>
            </header>
            <div className={styles.overviewContainer}>
                {this.state.loading && <LoadMask />}
                {!this.state.serviceBundle && !this.state.loading && (
                    <LargeMessage icon={faExclamation} className={styles.missingMessage}>
                        {this.localizationService.getString("serviceBundleNotFound")}
                    </LargeMessage>
                )}
                {this.state.serviceBundle && (
                    <Fragment>
                        {this.state.error && (
                            <Banner type="error" icon={faExclamationCircle}>
                                {this.state.error}
                            </Banner>
                        )}
                        <div className={styles.overviewLeftContainer}>
                            <SimpleTable
                                className={styles.SimpleTable}
                                items={this.state.dataTableItems}
                            />
                        </div>
                        <div className={styles.overviewRightContainer}>
                            <TabBar tabs={this.getTabs()} onChange={this.onTabChange} />
                            <div className={styles.overviewRightGridContainer}>
                                {this.state.tab === 1 && (
                                    <FormLabel
                                        title={this.localizationService.getString("bulkConnect")}
                                        htmlFor="bulkConnect"
                                        className={styles.bulkConnectSelect}
                                    >
                                        <Select
                                            required
                                            value={this.state.serviceBundle.bulkConnect}
                                            options={this.bulkConnectOptions}
                                            disabled
                                        ></Select>
                                    </FormLabel>
                                )}
                                {this.state.tab === 2 && (
                                    <Grid
                                        onGridReady={this.onGridReady}
                                        columnDefs={this.attachmentColumns}
                                        components={{
                                            servicesCellRenderer: ServicesCellRenderer,
                                        }}
                                        context={{ history: this.props.history }}
                                        pagination
                                    ></Grid>
                                )}
                            </div>
                        </div>
                    </Fragment>
                )}
            </div>
        </section>
    )
}
