import React from "react"
import styles from "./UsersNameCellRenderer.module.scss"
import { Link } from "../../../../../v3/components/link/Link.component"

export default function () {
    const value = this.props.value || "-"
    return (
        <Link to={this.state.link} onClick={this.clickHandler} className={styles.nameValue}>
            {value}
        </Link>
    )
}
