import React from "react"

import { useServiceLocalization } from "../../../../pre-v3/services/localization/Localization.service"
import {
    FilterModel,
    Paginated,
    PaginatedSearch,
    replaceColumnKeys,
} from "../../../../pre-v3/utils/AgGrid.util"
import { replaceKeys } from "../../../../pre-v3/utils/Collection.util"
import { DateUtil } from "../../../../pre-v3/utils/Date.util"
import { Column, Grid, GridApi, sortWithServerSide } from "../../../components/grid/Grid.component"
import {
    ResourceIp,
    ResourceIpFilterById as FilterById,
    ResourceIpSortById as SortById,
} from "../../../services/PrivateResource.service"

interface Props {
    filterModel: FilterModel<FilterById>
    getResourceIps(search: PaginatedSearch<SortById, FilterById>): Promise<Paginated<ResourceIp>>
}

function ResourceIpGridComponent(props: Props, ref: React.ForwardedRef<GridApi>): JSX.Element {
    const localization = useServiceLocalization()

    const getServiceTunnelNames_ = getServiceTunnelNames(localization.getLocale())

    const ipColumn: Column<ResourceIp> = {
        id: ColumnId.IP,
        name: localization.getString("ip"),
        cellRenderer: "ip",
        getTooltipValue: "ip",
        sorting: sortWithServerSide(),
        isFilterable: true,
    }

    const serviceTunnelsColumn: Column<ResourceIp> = {
        id: ColumnId.SERVICE_TUNNELS,
        name: localization.getString("serviceTunnels"),
        cellRenderer: getServiceTunnelNames_,
        getTooltipValue: getServiceTunnelNames_,
        isFilterable: true,
    }

    const lastSeenColumn: Column<ResourceIp> = {
        id: ColumnId.LAST_SEEN,
        name: localization.getString("lastSeen"),
        cellRenderer: getLastSeen,
        getTooltipValue: getLastSeen,
        sorting: sortWithServerSide("desc"),
        isFilterable: true,
    }

    const columns: Column<ResourceIp>[] = [ipColumn, serviceTunnelsColumn, lastSeenColumn]

    const getServerSideData = (search: PaginatedSearch<SortedColumnId, FilteredColumnId>) =>
        props.getResourceIps({
            ...search,
            sortModel: search.sortModel && replaceColumnKeys(search.sortModel, sortByIdDict),
            filterModel: search.filterModel && replaceKeys(search.filterModel, filterByIdDict),
        })

    return (
        <Grid
            getId={getResourceIpId}
            columns={columns}
            ref={ref}
            hasPagination
            serverSideProps={{
                getServerSideData,
                filterModel: replaceKeys(props.filterModel, filteredColumnDict),
            }}
        />
    )
}

export const ResourceIpGrid = React.forwardRef<GridApi, Props>(ResourceIpGridComponent)

enum ColumnId {
    IP = "ip",
    SERVICE_TUNNELS = "serviceTunnels",
    LAST_SEEN = "lastSeen",
}

type SortedColumnId = ColumnId.IP | ColumnId.LAST_SEEN
type FilteredColumnId = ColumnId.IP | ColumnId.SERVICE_TUNNELS

function getResourceIpId(resourceIp: ResourceIp): string {
    return resourceIp.ip
}

function getServiceTunnelNames(locale: string): (resourceIp: ResourceIp) => string {
    const formatter = new Intl.ListFormat(locale, { type: "conjunction" })

    return (resourceIp) => formatter.format(resourceIp.serviceTunnels.map((tunnel) => tunnel.name))
}

function getLastSeen(resourceIp: ResourceIp): string {
    return DateUtil.format(resourceIp.lastSeenAt)
}

const sortByIdDict: Record<SortedColumnId, SortById> = {
    [ColumnId.IP]: SortById.IP,
    [ColumnId.LAST_SEEN]: SortById.LAST_SEEN,
}

const filterByIdDict: Record<FilteredColumnId, FilterById> = {
    [ColumnId.IP]: FilterById.IPS,
    [ColumnId.SERVICE_TUNNELS]: FilterById.SERVICE_TUNNELS,
}

const filteredColumnDict: Record<FilterById, FilteredColumnId> = {
    [FilterById.IPS]: ColumnId.IP,
    [FilterById.SERVICE_TUNNELS]: ColumnId.SERVICE_TUNNELS,
}
