import React from "react"

import { DateUtil, TimeRange, TimeRangeName } from "../../../pre-v3/utils/Date.util"
import { SelectInput } from "../select-input/SelectInput.component"

import styles from "./DateRangeSelect.module.scss"

export interface DateRangeSelectProps {
    timeRange: TimeRange
    onTimeRangeChange(timeRange: TimeRange): void
}

export function DateRangeSelect(props: DateRangeSelectProps): JSX.Element {
    const [selectedDateRange, setSelectedDateRange] = React.useState<TimeRange>(props.timeRange)

    const onTimeRangeChange = (value: string) => {
        const dateRange = DateUtil.getTimeRanges()
        const timeRange = dateRange.find((r) => r.name === value) || dateRange[0]
        props.onTimeRangeChange(timeRange)
    }

    const displayTimeRange = DateUtil.timeRangeToUserReadableStr(selectedDateRange)

    const options = ranges.map((r) => ({
        displayName: r.name,
        value: r.name,
    }))

    return (
        <div className={styles.DateRangeSelect}>
            <SelectInput
                options={options}
                defaultValue={displayTimeRange}
                onChange={onTimeRangeChange}
                placeholder={"Select Input"}
                aria-label="Date Range Select Input"
            />
        </div>
    )
}

export const ranges: TimeRange[] = [
    {
        name: TimeRangeName.LAST_DAY,
        delta: DateUtil.DAY,
    },
    {
        name: TimeRangeName.LAST_WEEK,
        delta: DateUtil.DAY * 7,
    },
    {
        name: TimeRangeName.LAST_30_DAYS,
        delta: DateUtil.DAY * 30,
    },
    {
        name: TimeRangeName.LAST_60_DAYS,
        delta: DateUtil.DAY * 60,
    },
    {
        name: TimeRangeName.LAST_90_DAYS,
        delta: DateUtil.DAY * 90,
    },
]
