import { faCog, faQuestionCircle } from "@fortawesome/pro-regular-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import React from "react"
import { Link } from "react-router-dom"

import { ROUTE } from "../../../routes"
import { AppNavLink } from "../../services/shared/AppNavLink"
import { useEdition, OrgEdition } from "../../services/User.service"
import { NavButton } from "../nav-button/NavButton.component"
import { NavMenuButton } from "../nav-menu-button/NavMenuButton.component"
import { UserMenu } from "../user-menu/UserMenu"
import styles from "./TopNav.module.scss"
import { useServiceLocalization } from "../../services"

interface TopNavProps {
    logoLink: string
    settingsLink?: string
    userMenuLinks: AppNavLink[]
    supportLinks?: AppNavLink[]
    fullName: string
    orgName: string
    appVersion: string
    hideSettings?: boolean
    children?: React.ReactNode
}

export function TopNav(props: TopNavProps): JSX.Element {
    const edition = useEdition()
    const localization = useServiceLocalization()

    return (
        <div className={styles.topNav}>
            <Link
                to={props.logoLink || window.location.pathname}
                className={styles.sonicWallCSELogo}
            ></Link>
            <div className={styles.topNavChildren}>{props.children}</div>
            {!props.hideSettings && (
                <NavButton
                    href={props.settingsLink || ROUTE.SIGN_ON_SETTINGS}
                    className={styles.topNavSettings}
                    ariaLabel={localization.getString("settings")}
                >
                    <FontAwesomeIcon icon={faCog} />
                </NavButton>
            )}
            <NavMenuButton
                links={props.supportLinks?.filter((link) => {
                    // if the link is only visible in team edition
                    if (link.teamEditionOnly && edition !== OrgEdition.TEAM) {
                        return false
                    }
                    // if the link is hidden in team edition
                    if (link.teamEditionHidden && edition === OrgEdition.TEAM) {
                        return false
                    }

                    // otherwise show the link
                    return true
                })}
                highlight
                footer={<li className={styles.version}>v{props.appVersion}</li>}
                className={styles.supportMenu}
            >
                <FontAwesomeIcon icon={faQuestionCircle} />
            </NavMenuButton>
            {props.userMenuLinks && props.userMenuLinks.length > 0 && (
                <UserMenu
                    links={props.userMenuLinks}
                    fullName={props.fullName}
                    orgName={props.orgName}
                    className={styles.userMenu}
                />
            )}
        </div>
    )
}
