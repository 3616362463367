import React, { ReactNode } from "react"
import styles from "./Licensing.module.scss"
import { IconType, useServiceLinks, useServiceLocalization } from "../../../pre-v3/services"
import { useGetOrgAnalytics } from "../../services/Analytics.service"
import { AppText } from "../../components/app-text/AppText.component"
import { Card } from "../../components/card/Card.component"
import classNames from "classnames"
import { useGetOrgInfo } from "../../services/Org.service"
import { StringUtil } from "../../../pre-v3/utils/String.util"
import { ButtonLink } from "../../components/button-link/ButtonLink.component"
import { Loader } from "../../components/loader/Loader.component"
import { ErrorBanners } from "../../components/banner/Banner.component"
import { PageHeading } from "../../../components/page-heading/PageHeading.component"
import { ButtonElement, ButtonType, Button } from "../../../components/button/Button.component"
import { Tooltip } from "../../components/tooltip/Tooltip.component"

export function Licensing() {
    const ls = useServiceLocalization()
    const links = useServiceLinks()
    const {
        data: orgAnalytics,
        refetch: refetchOrgAnalytics,
        isFetching: isOrgAnalyticsLoading,
        error: orgAnalyticsError,
    } = useGetOrgAnalytics()
    const {
        data: orgInfo,
        refetch: refetchOrgInfo,
        isFetching: isOrgInfoLoading,
        error: orgInfoError,
    } = useGetOrgInfo()

    const orgType = orgInfo?.orgType || "-"
    const edition = orgInfo?.edition || "-"
    const licenseCount: number = orgAnalytics?.licenseCount || 0
    const orgName: string = orgInfo?.orgName || "-"

    function onRefresh() {
        refetchOrgAnalytics()
        refetchOrgInfo()
    }

    return (
        <div className={styles.topContainer}>
            <Loader isLoading={isOrgAnalyticsLoading || isOrgInfoLoading} center medium>
                <header className={styles.header}>
                    <div>
                        <PageHeading>{ls.getString("licensing")}</PageHeading>
                        <AppText
                            ls={{
                                key: "toLearnMoreAboutLicensingCheckOutHelpDocs",
                                replaceVals: [links.getLink("licensingDoc")],
                            }}
                            className={styles.actionBarDescription}
                        />
                    </div>

                    <Tooltip title={ls.getString("refresh")}>
                        <Button
                            icon={IconType.REDO}
                            onClick={onRefresh}
                            asElement={ButtonElement.BUTTON}
                            buttonType={ButtonType.SECONDARY}
                            aria-label={ls.getString("refresh")}
                        />
                    </Tooltip>
                </header>
                <ErrorBanners
                    errors={[
                        orgAnalyticsError && String(orgAnalyticsError),
                        orgInfoError && String(orgInfoError),
                    ]}
                />
                <div className={styles.cardContainer}>
                    <Card className={styles.card}>
                        <CardData
                            title={ls.getString("organization")}
                            value={orgName}
                            description={ls.getString("someEnvironment", orgType)}
                        />
                    </Card>
                    <Card className={styles.card}>
                        <CardData
                            title={ls.getString("licenses")}
                            value={licenseCount.toString()}
                            description={ls.getString("totalActiveUsersOver90Days")}
                        />
                    </Card>
                    <Card className={styles.card}>
                        <CardData
                            title={ls.getString("currentPlan")}
                            value={ls.getString("someEdition", StringUtil.capitalize(edition))}
                        >
                            {edition !== "unlimited" && (
                                <div className={styles.upgrade}>
                                    <ButtonLink to={links.getLink("licensingDoc")} brand>
                                        {ls.getString("upgrade")}
                                    </ButtonLink>
                                    <label className={styles.description}>
                                        {ls.getString("chatWithOurSalesTeam")}
                                    </label>
                                </div>
                            )}
                        </CardData>
                    </Card>
                </div>
                <div className={classNames(styles.card, styles.image)}>
                    <CardData
                        title={ls.getString("unsureAboutWhatPlanIsRightForYou")}
                        description={ls.getString("learnMoreAboutSonicWallCsesDifferentPlansDesc")}
                    >
                        <div className={styles.compareButton}>
                            <ButtonLink to={links.getLink("pricingDoc")}>
                                {ls.getString("compareAllPlans")}
                            </ButtonLink>
                        </div>
                    </CardData>
                </div>
            </Loader>
        </div>
    )
}

interface CardDataProps {
    title: string
    description?: string
    value?: string
    children?: ReactNode
}

function CardData(props: CardDataProps) {
    return (
        <div className={styles.outerContainer}>
            <div className={styles.innerContainer}>
                <label className={styles.title}>{props.title}</label>
                {props.value && <div className={styles.value}>{props.value}</div>}
                {props.description && (
                    <label className={styles.description}>{props.description}</label>
                )}
            </div>
            {props.children}
        </div>
    )
}
