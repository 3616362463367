import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import {
    faCheckCircle,
    faMinusCircle,
    faPauseCircle,
    faTimesCircle,
} from "@fortawesome/pro-solid-svg-icons"
import classNames from "classnames/bind"
import React from "react"

import { NetAgentStatus } from "../../../../api/Infra.api"
import { useServiceLocalization } from "../../../../services"
import styles from "./NetAgentStatusCellRenderer.module.scss"

interface Props {
    data: {
        status: NetAgentStatus
        failedReason?: string
    }
}

export function NetAgentStatusCellRenderer(props: Props) {
    switch (props.data.status) {
        case NetAgentStatus.REPORTING:
            return <StatusReporting />
        case NetAgentStatus.TERMINATED:
            return <StatusTerminated />
        case NetAgentStatus.INACTIVE:
            return <StatusInactive />
        case NetAgentStatus.PENDING:
        default:
            return <StatusPending />
    }
}

function StatusReporting() {
    const ls = useServiceLocalization()

    return (
        <div className={styles.container}>
            <div className={classNames(styles.statusIcon, styles.statusIconReporting)}>
                <FontAwesomeIcon icon={faCheckCircle} />
            </div>
            <div className={styles.statusLabel}>{ls.getString("reporting")}</div>
        </div>
    )
}

function StatusTerminated() {
    const ls = useServiceLocalization()

    return (
        <div className={styles.container}>
            <div className={classNames(styles.statusIcon, styles.statusIconTerminated)}>
                <FontAwesomeIcon icon={faTimesCircle} />
            </div>
            <div className={styles.statusLabel}>{ls.getString("terminated")}</div>
        </div>
    )
}

function StatusPending() {
    const ls = useServiceLocalization()

    return (
        <div className={styles.container}>
            <div className={classNames(styles.statusIcon, styles.statusIconPending)}>
                <FontAwesomeIcon icon={faPauseCircle} />
            </div>
            <div className={styles.statusLabel}>{ls.getString("pending")}</div>
        </div>
    )
}

function StatusInactive() {
    const ls = useServiceLocalization()

    return (
        <div className={styles.container}>
            <div className={classNames(styles.statusIcon, styles.statusIconInactive)}>
                <FontAwesomeIcon icon={faMinusCircle} />
            </div>
            <div className={styles.statusLabel}>{ls.getString("inactive")}</div>
        </div>
    )
}
