import { faCircle } from "@fortawesome/pro-solid-svg-icons"
import React, { ReactNode, useMemo, useState } from "react"
import { useParams } from "react-router-dom"

import {
    LocalizationService,
    useServiceLocalization,
} from "../../../../../pre-v3/services/localization/Localization.service"
import { IconType } from "../../../../../pre-v3/services/ActionBar.service"
import { Loader } from "../../../../components/loader/Loader.component"
import { ErrorBanners } from "../../../../components/banner/Banner.component"
import { Container } from "../../../../components/container/Container.component"
import { decodeID } from "../../../../../pre-v3/utils/Url.util"
import {
    KeyValuePair,
    KeyValuePairList,
} from "../../../../components/key-value-pair/KeyValuePair.component"
import { TabBar } from "../../../../components/tab-bar/TabBar.component"
import {
    ApplicationType,
    PublicResourceStatus,
    SecurityAction,
    iconMap,
    labelMap,
    statusMap,
    useGetPublicResourceById,
    useUpdateResourceStatus,
} from "../../../../services/PublicResource.service"
import { Accordion } from "../../../../components/accordion/Accordion.component"
import { Card } from "../../../../components/card/Card.component"
import { Icon } from "../../../../components/Icon/Icon.component"
import { Button } from "../../../../components/button/Button.component"
import { PillProps } from "../../../../components/pill/Pill.component"
import { PillContainer } from "../../../../components/pill-container/PillContainer.component"
import { AppText } from "../../../../components/app-text/AppText.component"
import { useServiceLinks } from "../../../../../pre-v3/services/link/Link.service"
import { Tooltip } from "../../../../components/tooltip/Tooltip.component"
import { Status } from "../../../../components/status/Status.component"
import { Link } from "../../../../components/link/Link.component"
import { useServiceModal } from "../../../../../pre-v3/services/Modal.service"
import { ServiceTunnelModal } from "./ServiceTunnelModal.component"
import { ItpPolicyModal } from "./ItpPolicyModal.component"
import { PublicResourceAccessLog } from "./PublicResourceAccessLog.component"
import { PublicResourceDomainGrid } from "./PublicResourceDomainGrid.component"
import styles from "./PublicResourceOverview.module.scss"
import { PageHeading } from "../../../../../components/page-heading/PageHeading.component"
import {
    Button as ButtonV4,
    ButtonElement,
    ButtonType,
} from "../../../../../components/button/Button.component"
import { DeletePublicResourceAction } from "./DeletePubicResourceModal.component"
import { CreateAppModal } from "../add/CreateAppModal.component"
import { Modal } from "../../../../../v4/components/modal/Modal.component"
import { useFeatureFlags } from "../../../../../hooks/useFeatureFlags.hook"

interface Props {
    canUpdateCustomApp?: boolean
}

export function PublicResourceOverview(props: Props) {
    const params = useParams<{ id: string }>()
    const localization = useServiceLocalization()
    const links = useServiceLinks()

    const id: string = decodeID(params.id)

    const [showModal, setShowModal] = useState<"edit" | "delete" | false>(false)

    const {
        data: resource,
        isFetching: isResourceLoading,
        refetch: refetchResource,
        error: resourceError,
    } = useGetPublicResourceById(id)

    const isAppProtected: boolean = resource?.status === PublicResourceStatus.PROTECTED
    const isAppIgnored: boolean = resource?.status === PublicResourceStatus.IGNORED

    const {
        mutateAsync: updateResourceStatus,
        isLoading: isStatusLoading,
        error: statusError,
    } = useUpdateResourceStatus(id)

    const { data: featureFlags } = useFeatureFlags()

    const errors: ReactNode[] = [
        typeof resourceError === "string" && resourceError,
        typeof statusError === "string" && statusError,
    ]

    const tabs: string[] = [
        localization.getString("applicationDetails"),
        localization.getString("accessLog"),
    ]
    const [currentTab, setCurrentTab] = useState(0)

    const hasAtLeastOneSecurityAction: boolean = resource
        ? resource.isBnnDLP || resource.isBnnFed || resource.isBnnITP || resource.isBnnTunnel
        : false

    async function updateResourceStatusAction() {
        const isIgnore: boolean = isAppIgnored ? false : true
        await updateResourceStatus(isIgnore)
    }

    function onDelete() {
        setShowModal("delete")
    }

    function onEdit() {
        setShowModal("edit")
    }

    function closeModal() {
        setShowModal(false)
    }

    const modalTitle = useMemo(() => {
        if (!showModal) return ""
        if (showModal === "delete") return `${localization.getString("delete")} ${resource?.name}`
        return `${localization.getString("edit")} ${resource?.name}`
    }, [showModal])

    return (
        <Loader isLoading={isResourceLoading || isStatusLoading} center medium>
            <Modal open={!!showModal} onClose={closeModal} title={modalTitle}>
                {showModal === "edit" ? (
                    <CreateAppModal
                        appId={id}
                        initialValue={resource}
                        closeModal={() => setShowModal(false)}
                    />
                ) : (
                    <DeletePublicResourceAction
                        appId={id}
                        name={resource?.name || ""}
                        closeModal={closeModal}
                    />
                )}
            </Modal>
            <Container as="section" className={styles.container} aria-labelledby={Id.HEADING}>
                <header className={styles.header}>
                    <PageHeading id={Id.HEADING}>{resource?.name}</PageHeading>
                    <div className={styles.actionButtons}>
                        {props.canUpdateCustomApp && !isAppProtected && (
                            <ButtonV4
                                icon={IconType.EMPTY_SET}
                                onClick={updateResourceStatusAction}
                                asElement={ButtonElement.BUTTON}
                                buttonType={ButtonType.SECONDARY}
                                aria-label={localization.getString(
                                    isAppIgnored ? "observe" : "ignore"
                                )}
                            >
                                {localization.getString(isAppIgnored ? "observe" : "ignore")}
                            </ButtonV4>
                        )}
                        {props.canUpdateCustomApp &&
                            (resource?.type === ApplicationType.CUSTOM &&
                            featureFlags?.adminConsole.canEditCustomApp ? (
                                <Tooltip title={localization.getString("edit")}>
                                    <ButtonV4
                                        type="button"
                                        asElement={ButtonElement.BUTTON}
                                        buttonType={ButtonType.SECONDARY}
                                        icon={IconType.PEN}
                                        onClick={onEdit}
                                    />
                                </Tooltip>
                            ) : null)}
                        {props.canUpdateCustomApp &&
                            (resource?.type === ApplicationType.CUSTOM &&
                            featureFlags?.adminConsole.canDeleteCustomApp ? (
                                <Tooltip title={localization.getString("delete")}>
                                    <ButtonV4
                                        type="button"
                                        asElement={ButtonElement.BUTTON}
                                        buttonType={ButtonType.DESTRUCTIVE}
                                        icon={IconType.TRASH}
                                        onClick={onDelete}
                                    />
                                </Tooltip>
                            ) : null)}
                        <ButtonV4
                            icon={IconType.REDO}
                            onClick={refetchResource as () => void}
                            asElement={ButtonElement.BUTTON}
                            buttonType={ButtonType.SECONDARY}
                            aria-label={localization.getString("refresh")}
                        />
                    </div>
                </header>
                {resource?.description && resource.appUrl && (
                    <AppText
                        className={styles.description}
                        ls={{
                            key: "somethingLearnMore",
                            replaceVals: [resource.description, resource.appUrl],
                        }}
                    />
                )}
                {resource?.description && !resource.appUrl && (
                    <p className={styles.description}>{resource.description}</p>
                )}
                {<ErrorBanners errors={errors} />}
                {resource && (
                    <>
                        <KeyValuePairList>
                            <KeyValuePair
                                label={localization.getString("resourceStatus")}
                                value={
                                    <Status
                                        type={statusMap[resource.status]}
                                        label={localization.getString(labelMap[resource.status])}
                                        icon={iconMap[resource.status]}
                                        iconClassName={styles.icon}
                                    />
                                }
                            />
                            <KeyValuePair
                                label={localization.getString("category")}
                                value={resource.category}
                            />
                        </KeyValuePairList>
                        {hasAtLeastOneSecurityAction && (
                            <Accordion
                                label={localization.getString("securityActions")}
                                type="plain"
                                open
                            >
                                <div>
                                    <p>
                                        {localization.getString(
                                            "takeSecurityMeasuresByPerformingActionsDesc"
                                        )}
                                    </p>
                                    <div className={styles.cardContainer}>
                                        {resource.isBnnITP && (
                                            <Card className={styles.card}>
                                                <CardData
                                                    disabled={isAppIgnored}
                                                    canUpdateCustomApp={props.canUpdateCustomApp}
                                                    isActive={resource.isBlock}
                                                    value={localization.getString("itpPolicy")}
                                                    description={localization.getString(
                                                        "useInternetThreatProtectionDesc"
                                                    )}
                                                    securityAction={SecurityAction.BNN_ITP}
                                                    refetchData={refetchResource}
                                                />
                                            </Card>
                                        )}
                                        {resource.isBnnTunnel && (
                                            <Card className={styles.card}>
                                                <CardData
                                                    disabled={isAppIgnored}
                                                    canUpdateCustomApp={props.canUpdateCustomApp}
                                                    isActive={resource.isIpAllowed}
                                                    value={localization.getString("serviceTunnel")}
                                                    refetchData={refetchResource}
                                                    description={
                                                        <AppText
                                                            ls={{
                                                                key: "forceAccessDesc",
                                                                replaceVals: [
                                                                    links.getLink(
                                                                        "serviceTunnelDoc"
                                                                    ),
                                                                ],
                                                            }}
                                                        />
                                                    }
                                                    securityAction={SecurityAction.BNN_TUNNEL}
                                                />
                                            </Card>
                                        )}
                                        {resource.isBnnFed && (
                                            <Card className={styles.card}>
                                                <CardData
                                                    disabled={isAppIgnored}
                                                    canUpdateCustomApp={props.canUpdateCustomApp}
                                                    isActive={resource.isIdentityProviderFederated}
                                                    value={localization.getString(
                                                        "identityProviderFederated"
                                                    )}
                                                    description={
                                                        <AppText
                                                            ls={{
                                                                key: "secureThisResourceDesc",
                                                                replaceVals: [
                                                                    links.getLink("fedPolicyDoc"),
                                                                ],
                                                            }}
                                                        />
                                                    }
                                                    securityAction={SecurityAction.BNN_FED}
                                                />
                                            </Card>
                                        )}
                                        {resource.isBnnDLP && (
                                            <Card className={styles.card}>
                                                <CardData
                                                    disabled={isAppIgnored}
                                                    canUpdateCustomApp={props.canUpdateCustomApp}
                                                    isActive={resource.isDlpPolicy}
                                                    value={localization.getString("dlpPolicy")}
                                                    description={localization.getString(
                                                        "addADataLossProtectionDesc"
                                                    )}
                                                    securityAction={SecurityAction.BNN_DLP}
                                                />
                                            </Card>
                                        )}
                                    </div>
                                </div>
                            </Accordion>
                        )}
                        <TabBar
                            className={styles.securityAction}
                            tabs={tabs.map((tab, tabIndex) => {
                                return {
                                    id: tabIndex,
                                    label: tab,
                                    ariaControls: `tab-panel-${tab}`,
                                }
                            })}
                            selectedTabId={currentTab}
                            onChange={setCurrentTab}
                        />
                        {currentTab === 0 && (
                            <>
                                <Accordion
                                    label={localization.getString("generalInformation")}
                                    type="plain"
                                    open
                                >
                                    <div className={styles.accordionContent}>
                                        <PublicResourceDomainGrid resource={resource} />
                                    </div>
                                </Accordion>
                                {resource.sensitivity.length > 0 && (
                                    <Accordion
                                        label={localization.getString("sensitivity")}
                                        type="plain"
                                        open
                                    >
                                        <PillContainer
                                            className={styles.pillContainer}
                                            pills={resource.sensitivity.map(
                                                (app, appIndex): PillProps => {
                                                    const appId = `${appIndex}`

                                                    return {
                                                        id: appId,
                                                        label: app,
                                                        disabled: true,
                                                    }
                                                }
                                            )}
                                        />
                                    </Accordion>
                                )}
                            </>
                        )}
                        {currentTab === 1 && (
                            <Accordion
                                label={localization.getString("accessLog")}
                                type="plain"
                                open
                            >
                                <PublicResourceAccessLog id={id} />
                            </Accordion>
                        )}
                        {resource.helpfulLinks.length > 0 && (
                            <div className={styles.helpfulLinks}>
                                <Accordion
                                    label={localization.getString("helpfulLinks")}
                                    type="plain"
                                    open
                                >
                                    <>
                                        {resource.helpfulLinks.map((link, i) => {
                                            return (
                                                <Link key={i} to={link}>
                                                    {(i ? ", " : "") + link}
                                                </Link>
                                            )
                                        })}
                                    </>
                                </Accordion>
                            </div>
                        )}
                    </>
                )}
            </Container>
        </Loader>
    )
}

enum Id {
    HEADING = "heading",
}

interface CardDataProps {
    description: ReactNode
    value: string
    isActive?: boolean
    securityAction: SecurityAction
    disabled?: boolean
    canUpdateCustomApp?: boolean
    refetchData?: Function
}

function CardData(props: CardDataProps) {
    const params = useParams<{ id: string }>()
    const modalService = useServiceModal()
    const linkService = useServiceLinks()
    const id: string = decodeID(params.id)

    function openServiceTunnelModal() {
        modalService
            .open(localization.getString("serviceTunnel"), {
                component: ServiceTunnelModal,
                props: {
                    appId: id,
                },
                maxWidth: "lg",
            })
            .onClose(async (f?: Function) => {
                await props.refetchData?.()
                await f?.()
            })
    }

    function openItpPolicyModal() {
        modalService
            .open(localization.getString("itpPolicy"), {
                component: ItpPolicyModal,
                props: {
                    appId: id,
                },
                maxWidth: "lg",
            })
            .onClose(async (f?: Function) => {
                await props.refetchData?.()
                await f?.()
            })
    }

    function openDoc(url: string) {
        window.open(url, "_blank", "noopener,noreferrer")
    }

    function onSecurityActionClick() {
        switch (props.securityAction) {
            case SecurityAction.BNN_TUNNEL:
                return openServiceTunnelModal()
            case SecurityAction.BNN_ITP:
                return openItpPolicyModal()
            case SecurityAction.BNN_FED:
                return openDoc(linkService.getLink("fedPolicyDoc"))
            case SecurityAction.BNN_DLP:
                openDoc(linkService.getLink("dlpPolicyDoc"))
        }
    }

    const localization = new LocalizationService()
    return (
        <div className={styles.outerContainer}>
            <div className={styles.innerContainer}>
                <div className={styles.nameContainer}>
                    {props.isActive && <Icon icon={faCircle} className={styles.iconColor} />}
                    <label className={styles.title}>
                        {props.isActive
                            ? localization.getString("active")
                            : localization.getString("inactive")}
                    </label>
                </div>
                <div className={styles.value}>{props.value}</div>
                <label className={styles.description}>{props.description}</label>
            </div>
            <div className={styles.compareButton}>
                <Tooltip
                    title={
                        props.disabled
                            ? localization.getString("securityActionsCannotBeAppliedIfAppIsIgnored")
                            : !props.canUpdateCustomApp
                              ? localization.getString(
                                    "yourAccountDoesNotHavePermissionToViewThisPage"
                                )
                              : ""
                    }
                >
                    <span>
                        <Button
                            onClick={() => onSecurityActionClick()}
                            disabled={props.disabled || !props.canUpdateCustomApp}
                        >
                            {localization.getString("manage")}
                        </Button>
                    </span>
                </Tooltip>
            </div>
        </div>
    )
}
