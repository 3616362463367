import { AuthStore } from "../services/Auth.store"
import { FormUtil } from "../utils/Form.util"

export class BaseV2Api {
    private authStore = new AuthStore()

    constructor() {
        this.parseResponse = this.parseResponse.bind(this)
    }

    protected get<R>(url: string, token?: string): Promise<R> {
        const headers: Headers = this.getHeaders(token)
        return fetch(this.getUrl(url), {
            method: "get",
            headers: headers,
        }).then((r) => this.parseResponse<R>(r))
    }

    protected getBlob(url: string): Promise<Blob> {
        const authToken: string = this.authStore.getJwtToken()
        const headers: Headers = new Headers()
        headers.set("Accept", "text/csv")
        if (authToken && authToken.length > 0) {
            headers.set("Authorization", "Bearer " + authToken)
        }
        return fetch(this.getUrl(url), {
            method: "get",
            headers: headers,
        }).then(this.parseBlobResponse)
    }

    protected post<T, R>(url: string, body: T, token?: string): Promise<R> {
        const headers: Headers = this.getHeaders(token)
        return fetch(this.getUrl(url), {
            method: "post",
            headers: headers,
            body: JSON.stringify(FormUtil.trimAny(body)),
        }).then((r) => this.parseResponse<R>(r))
    }

    protected postOIDC<T, R>(url: string, body: T): Promise<R> {
        return fetch(url, {
            method: "post",
            body: JSON.stringify(FormUtil.trimAny(body)),
        }).then((r) => this.parseResponse<R>(r))
    }

    protected delete<T, R>(url: string, body: T, token?: string): Promise<R> {
        const headers: Headers = this.getHeaders(token)
        return fetch(this.getUrl(url), {
            method: "delete",
            headers: headers,
            body: JSON.stringify(FormUtil.trimAny(body)),
        }).then((r) => this.parseResponse<R>(r))
    }

    protected patch<T, R>(url: string, body: T, token?: string): Promise<R> {
        const headers: Headers = this.getHeaders(token)
        return fetch(this.getUrl(url), {
            method: "PATCH",
            headers: headers,
            body: JSON.stringify(FormUtil.trimAny(body)),
        }).then((r) => this.parseResponse<R>(r))
    }

    protected parseResponse<R>(res: Response): Promise<R> {
        if (res.status >= 200 && res.status < 300) {
            return res.json().then(
                (result: ApiV2<R>) => {
                    return result.data as R
                },
                () => {
                    return Promise.reject()
                }
            )
        } else {
            return res.json().then(
                (result: ApiV2<R>) => {
                    return Promise.reject(result)
                },
                () => {
                    return Promise.reject()
                }
            )
        }
    }

    protected parseBlobResponse(res: Response): Promise<Blob> {
        if (res.status >= 200 && res.status < 300) {
            return Promise.resolve(res.blob())
        } else {
            return res.json().then(
                (result: any) => {
                    return Promise.reject(result)
                },
                () => {
                    return Promise.reject()
                }
            )
        }
    }

    private getHeaders(token?: string): Headers {
        const headers: Headers = new Headers()
        const jwt: string = this.authStore.getJwtToken()
        headers.set("Accept", "application/json")
        if (token) {
            headers.set("authorization", token)
        } else if (jwt) {
            headers.set("authorization", jwt)
        }
        return headers
    }

    private getBaseUrl(): string {
        return "/api/experimental/v2"
    }

    private getUrl(url: string): string {
        //although banyanidp uses the base v2 structure, it does not utilize the same url as v2 api's
        if (url.startsWith("http") || url.startsWith("/api/v1/banyanidp")) {
            return url
        } else {
            return this.getBaseUrl() + url
        }
    }
}

export interface ApiV2<T> {
    request_id?: string
    error_code?: number
    error_description?: string
    data?: T
}
