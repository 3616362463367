import React from "react"
import { useHistory } from "react-router"

import {
    Button,
    ButtonElement,
    ButtonType,
} from "../../../../../../../components/button/Button.component"
import ActionBarService, { IconType } from "../../../../../../../pre-v3/services/ActionBar.service"
import { useServiceLocalization } from "../../../../../../../pre-v3/services/localization/Localization.service"
import { encodeID } from "../../../../../../../pre-v3/utils/Url.util"
import { ROUTE, formatRoutePath } from "../../../../../../../routes"
import { ErrorBanner } from "../../../../../../components/banner/Banner.component"
import { FormButtons } from "../../../../../../components/form/form-buttons/FormButtons.component"
import {
    NewTrustProfileDetail,
    useCreateTrustProfile,
} from "../../../../../../services/TrustProfile.service"
import { AddTrustFactorsButton } from "../shared/AddTrustFactorsButton.component"
import { TrustFactorsForm } from "../shared/TrustFactorsForm.component"
import {
    areThereUnusedFactors,
    areThereUsedFactors,
    getUnusedTrustFactors,
    getUsedTrustFactors,
    useTrustFactors,
} from "../shared/useTrustFactors.hook"

interface Props {
    trustProfile: NewTrustProfileDetail
    onBack(profile: NewTrustProfileDetail): void
}

export function TrustFactors(props: Props): JSX.Element {
    const actionBarService = new ActionBarService()

    const history = useHistory()
    const localization = useServiceLocalization()

    const {
        banyanTrustFactors,
        integrationTrustFactors,
        getError,
        onAddTrustFactor,
        onEditTrustFactor,
        onRemoveTrustFactor,
        onReset,
    } = useTrustFactors(props.trustProfile)

    const [error, setError] = React.useState<string>()

    React.useEffect(() => {
        actionBarService.setActions([
            {
                icon: IconType.REDO,
                tooltip: localization.getString("refresh"),
                onClick: onReset,
            },
        ])
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const createTrustProfile = useCreateTrustProfile()

    const onCreate: React.MouseEventHandler = async (event) => {
        event.preventDefault()
        setError(undefined)

        const error = getError()
        if (error) {
            return setError(error)
        }

        const newProfile = await createTrustProfile.mutateAsync({
            ...props.trustProfile,
            trustFactors: getUsedTrustFactors(banyanTrustFactors, integrationTrustFactors),
        })

        history.push(formatRoutePath(ROUTE.PROFILES_DETAILS, { id: encodeID(newProfile.id) }))
    }

    const onBack: React.MouseEventHandler = (event) => {
        event.preventDefault()
        props.onBack({
            ...props.trustProfile,
            trustFactors: getUsedTrustFactors(banyanTrustFactors, integrationTrustFactors),
            unusedTrustFactors: getUnusedTrustFactors(banyanTrustFactors, integrationTrustFactors),
        })
    }

    return (
        <TrustFactorsForm
            trustFactors={getUsedTrustFactors(banyanTrustFactors, integrationTrustFactors)}
            onEditTrustFactor={onEditTrustFactor}
            onRemoveTrustFactor={onRemoveTrustFactor}
        >
            <React.Fragment>
                <AddTrustFactorsButton
                    banyanTrustFactors={banyanTrustFactors}
                    integrationTrustFactors={integrationTrustFactors}
                    onAddTrustFactor={onAddTrustFactor}
                    isDisabled={!areThereUnusedFactors(banyanTrustFactors, integrationTrustFactors)}
                />
                <FormButtons
                    leftButtons={
                        <Button
                            asElement={ButtonElement.BUTTON}
                            buttonType={ButtonType.SECONDARY}
                            onClick={onBack}
                        >
                            {localization.getString("back")}
                        </Button>
                    }
                    rightButtons={
                        <Button
                            asElement={ButtonElement.BUTTON}
                            buttonType={ButtonType.PRIMARY}
                            onClick={onCreate}
                            loading={createTrustProfile.isLoading}
                            disabled={
                                !areThereUsedFactors(banyanTrustFactors, integrationTrustFactors)
                            }
                        >
                            {localization.getString("create")}
                        </Button>
                    }
                >
                    {typeof createTrustProfile.error === "string" && (
                        <ErrorBanner>{createTrustProfile.error}</ErrorBanner>
                    )}
                    {error && <ErrorBanner>{error}</ErrorBanner>}
                </FormButtons>
            </React.Fragment>
        </TrustFactorsForm>
    )
}
