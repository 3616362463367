import classNames from "classnames/bind"
import React from "react"

import styles from "./HiddenInput.module.scss"

export type HiddenInputProps = React.InputHTMLAttributes<HTMLInputElement>

export const HiddenInput = React.forwardRef<HTMLInputElement, HiddenInputProps>((props, ref) => (
    <input
        {...props}
        ref={ref}
        className={classNames(styles.hiddenInput, props.className)}
        onChange={props.onChange ?? noOp}
    />
))
HiddenInput.displayName = "HiddenInput"

function noOp() {}
