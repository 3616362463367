import React from "react"

import { useServiceLocalization } from "../../../../pre-v3/services/localization/Localization.service"
import { DateUtil } from "../../../../pre-v3/utils/Date.util"
import { ROUTE, formatRoutePath } from "../../../../routes"
import { Device, TrustProfile, StatusType } from "../../../services/Device.service"
import { ErrorBanner, InfoBanner } from "../../../components/banner/Banner.component"
import { Link } from "../../../components/link/Link.component"
import { encodeID } from "../../../../pre-v3/utils/Url.util"
import styles from "./DeviceTrustFactors.module.scss"
import { DeviceTrustFactorGrid } from "./DeviceTrustFactorGrid.component"

interface Props {
    tabPanelId?: string
    device: Device
}

export function DeviceTrustFactors(props: Props): JSX.Element {
    const localization = useServiceLocalization()

    switch (props.device.status.type) {
        case StatusType.BANNED:
            return (
                <Container {...props}>
                    <ErrorBanner>{localization.getString("deviceBannedWarning")}</ErrorBanner>
                </Container>
            )

        case StatusType.EXPIRED:
            return (
                <Container {...props}>
                    <InfoBanner>
                        {localization.getString(
                            "deviceExpiredWarning",
                            DateUtil.format(props.device.status.expiredAt)
                        )}
                    </InfoBanner>
                    <div className={styles.infoContainers}>
                        <LastEvaluatedInfo lastEvaluatedAt={props.device.status.lastEvaluatedAt} />
                        {props.device.trustProfile && (
                            <TrustProfileInfo trustProfile={props.device.trustProfile} />
                        )}
                    </div>
                </Container>
            )

        case StatusType.OVERRIDDEN:
            return (
                <Container {...props}>
                    <InfoBanner>{localization.getString("deviceOverriddenWarning")}</InfoBanner>
                    <div className={styles.infoContainers}>
                        <LastEvaluatedInfo lastEvaluatedAt={props.device.status.lastEvaluatedAt} />
                        {props.device.trustProfile && (
                            <TrustProfileInfo trustProfile={props.device.trustProfile} />
                        )}
                    </div>
                </Container>
            )

        case StatusType.PENDING:
            return (
                <Container {...props}>
                    <ErrorBanner>{localization.getString("devicePendingWarning")}</ErrorBanner>
                    {props.device.trustProfile && (
                        <TrustProfileInfo trustProfile={props.device.trustProfile} />
                    )}
                </Container>
            )

        case StatusType.REPORTING:
            return (
                <Container {...props}>
                    <div className={styles.infoContainers}>
                        <LastEvaluatedInfo lastEvaluatedAt={props.device.status.lastEvaluatedAt} />
                        {props.device.trustProfile && (
                            <TrustProfileInfo trustProfile={props.device.trustProfile} />
                        )}
                    </div>
                    <DeviceTrustFactorGrid trustFactors={props.device.status.trustFactors} />
                </Container>
            )
    }
}

function Container(props: React.PropsWithChildren<Props>): JSX.Element {
    return (
        <div id={props.tabPanelId} className={styles.container}>
            {props.children}
        </div>
    )
}

interface LastEvaluatedInfoProps {
    lastEvaluatedAt: number
}

function LastEvaluatedInfo(props: LastEvaluatedInfoProps): JSX.Element {
    const localization = useServiceLocalization()

    return (
        <div className={styles.infoContainer}>
            <h6 className={styles.title}>{localization.getString("lastEvaluatedForTrustLevel")}</h6>
            <span className={styles.link}>{DateUtil.format(props.lastEvaluatedAt)}</span>
        </div>
    )
}

interface TrustProfileInfoProps {
    trustProfile: TrustProfile
}

function TrustProfileInfo(props: TrustProfileInfoProps): JSX.Element {
    const localization = useServiceLocalization()

    const route = formatRoutePath(ROUTE.PROFILES_DETAILS, {
        id: encodeID(props.trustProfile.id),
    })

    return (
        <div className={styles.infoContainer}>
            <h6 className={styles.title}>{localization.getString("trustProfile")}</h6>
            <Link to={route} className={styles.link}>
                {props.trustProfile.name}
            </Link>
        </div>
    )
}
