import React from "react"
import styles from "./PolicyInfo.module.scss"
import { LargeMessage, Select, FormLabel } from "../../../components"
import { faExclamation } from "@fortawesome/pro-solid-svg-icons"
import { ServiceType, PolicyType } from "../../../services"
import { PolicyForm } from "../form/PolicyForm"

export default function () {
    return (
        <div className={this.props.className}>
            {this.props.policy && (
                <>
                    {this.state.error && (
                        <LargeMessage icon={faExclamation}>{this.state.error}</LargeMessage>
                    )}
                    {!this.state.error && (
                        <>
                            {this.state.policyType !== PolicyType.CUSTOM && (
                                <FormLabel
                                    title={this.ls.getString(
                                        "whatTypeOfServiceIsThisPolicyIntendedFor"
                                    )}
                                    className={styles.policyAttribute}
                                    inline={false}
                                    width={300}
                                    htmlFor="whatTypeOfServiceIsThisPolicyIntendedFor"
                                >
                                    <Select
                                        disabled
                                        options={[
                                            {
                                                displayName: this.ls.getString(
                                                    "webForAccessingHttpServicesViaWebBrowser"
                                                ),
                                                value: ServiceType.WEB_USER,
                                            },
                                            {
                                                displayName: this.ls.getString(
                                                    "infrastructureForRemoteAccessUsingATcpBasedProtocol"
                                                ),
                                                value: ServiceType.TCP_USER,
                                            },
                                            {
                                                displayName: this.ls.getString(
                                                    "tunnelForRemoteAccessIntoYourPrivateNetwork"
                                                ),
                                                value: ServiceType.TUNNEL,
                                            },
                                        ]}
                                        value={this.state.subType}
                                    />
                                </FormLabel>
                            )}
                            <PolicyForm
                                edit={this.props.policy}
                                disabled
                                className={styles.policyForm}
                            />
                        </>
                    )}
                </>
            )}
        </div>
    )
}
