import React from "react"
import styles from "./ServicesAddDomain.module.scss"
import {
    InfoTip,
    FormLabel,
    Input,
    FormLabelContext,
    Checkbox,
    PortInput,
} from "../../../../components"
import { ServiceType } from "../../../../services/Manage.service"

export default function () {
    return (
        <FormLabel
            slim
            title={
                <>
                    {this.localizationService.getString("serviceDomainName")}
                    <InfoTip
                        message={
                            this.props.serviceType === ServiceType.WEB_USER
                                ? this.localizationService.getString("sniDescription")
                                : this.localizationService.getString("serviceDomainNameDescription")
                        }
                    />
                </>
            }
            htmlFor="serviceDomainName"
        >
            <div>
                <Input
                    id="domain"
                    className={styles.domainInput}
                    required
                    defaultValue={this.state.domain}
                    disabled={this.props.disabled}
                    onChange={this.onDomainChange}
                    placeholder={this.localizationService.getString("domainNamePlaceholder")}
                />
                <FormLabelContext.Provider value={{ htmlFor: "serviceDomainName-value" }}>
                    <PortInput
                        className={styles.portInput}
                        required
                        disabled={
                            this.props.disabled || this.props.serviceType === ServiceType.WEB_USER
                        }
                        defaultValue={this.state.port}
                        onChange={this.onPortChange}
                        id="port"
                    />
                </FormLabelContext.Provider>
            </div>
            {this.props.disabled && this.props.showLetsEncrypt && (
                <>
                    <Checkbox
                        id="tls"
                        className={styles.checkbox}
                        defaultChecked={this.props.letsEncrypt}
                        disabled={this.props.disabled}
                    />
                    <label htmlFor="tls" className={styles.checkboxLabel}>
                        {this.localizationService.getString("letsEncrypt")}
                    </label>
                </>
            )}
        </FormLabel>
    )
}
