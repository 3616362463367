import React, { ReactNode, useRef, useState } from "react"
import { useServiceLocalization } from "../../../../pre-v3/services/localization/Localization.service"
import { Form } from "../../../../pre-v3/components/form/Form"
import { Button } from "../../../components/button/Button.component"
import styles from "./MspOrgForm.module.scss"
import { Loader } from "../../../components/loader/Loader.component"
import { FormRow } from "../../../components/form/FormRow.component"
import { Input } from "../../../components/input/Input.component"
import { ErrorBanners } from "../../../components/banner/Banner.component"
import {
    MspOrgInfo,
    OrgType,
    isLegacyOrgType,
    useCreateMspOrg,
    useUpdateMspOrg,
} from "../../../services/MspOrgManagement.service"
import { FormButtons } from "../../../components/form/form-buttons/FormButtons.component"
import { SelectInput } from "../../../components/select-input/SelectInput.component"
import { Checkbox } from "../../../components/checkbox/Checkbox.component"
import { Edition } from "../../../services/shared/Edition"
import { PatternUtil } from "../../../../pre-v3/utils/Pattern.util"
import { HiddenInput } from "../../../../components/input/HiddenInput.component"

export interface Props {
    initialValue?: MspOrgInfo
    onCancel?: () => void
    afterSubmit?: () => void
    disabled?: boolean
    isCreate?: boolean
    isEdit?: boolean
}

export function MspOrgForm(props: Props): JSX.Element {
    const { initialValue, onCancel, disabled, isCreate, afterSubmit, isEdit } = props
    const localization = useServiceLocalization()
    const [orgName, setOrgName] = useState<string>(initialValue?.orgName || "")
    const [description, setDescription] = useState<string>(initialValue?.description || "")
    const [edition, setEdition] = useState<Edition>(initialValue?.edition || Edition.ENTERPRISE)
    const [globalEdge, setGlobalEdge] = useState<boolean>(initialValue?.isGlobalEdge || false)
    const [privateEdge, setPrivateEdge] = useState<boolean>(initialValue?.isPrivateEdge || false)
    const typeOptions: OrgType[] = Object.values(OrgType)
    const [type, setType] = useState(initialValue?.type || typeOptions[0])
    const hiddenInput = useRef<HTMLInputElement>(null)

    const orgTypeOptions = React.useMemo(() => {
        if (initialValue && isLegacyOrgType(initialValue.type)) {
            return [initialValue.type, ...typeOptions]
        } else {
            return typeOptions
        }
    }, [initialValue])

    const {
        mutate: createOrg,
        error: createOrgError,
        isLoading: isCreateOrgLoading,
    } = useCreateMspOrg(afterSubmit)

    const orgId: string = initialValue?.id || ""
    const {
        mutate: updateOrg,
        error: updateOrgError,
        isLoading: isUpdateOrgLoading,
    } = useUpdateMspOrg(orgId, afterSubmit)

    function onSubmit(event: React.SyntheticEvent<HTMLFormElement, Event>) {
        event.preventDefault()
        if (isCreate) {
            createOrg({
                orgName,
                type,
                description,
            })
        }

        if (isEdit) {
            updateOrg({
                type,
                description,
            })
        }
    }

    const errors: ReactNode[] = [
        typeof createOrgError === "string" && createOrgError,
        typeof updateOrgError === "string" && updateOrgError,
    ]

    React.useEffect(() => {
        const validityMessage = isLegacyOrgType(type)
            ? localization.getString("testingAndInternalOrgsAreNotSupportedMsg")
            : ""
        hiddenInput.current?.setCustomValidity(validityMessage)
    }, [type])

    return (
        <Loader isLoading={false}>
            <Form onSubmit={onSubmit} className={styles.container}>
                <FormRow
                    label={localization.getString("organizationName")}
                    description={
                        !(disabled || isEdit) ? localization.getString("orgNameRequirements") : ""
                    }
                >
                    <Input
                        pattern={PatternUtil.ORG_NAME.source}
                        type="text"
                        placeholder={localization.getString("organizationName")}
                        value={orgName}
                        onChangeValue={setOrgName}
                        disabled={disabled || isEdit}
                        title={localization.getString("orgNameRequirements")}
                        minLength={4}
                        maxLength={30}
                    />
                </FormRow>
                <FormRow label={localization.getString("description")}>
                    <Input
                        type="text"
                        placeholder={localization.getString("description")}
                        value={description}
                        onChangeValue={setDescription}
                        disabled={disabled}
                    />
                </FormRow>
                <FormRow label={localization.getString("organizationType")}>
                    <SelectInput
                        disabled={disabled}
                        value={type}
                        options={orgTypeOptions}
                        id="type"
                        onChange={setType}
                    />
                    <HiddenInput type="text" className={styles.hiddenInput} ref={hiddenInput} />
                </FormRow>
                {!isCreate && (
                    <FormRow label={localization.getString("edition")}>
                        <SelectInput
                            disabled={disabled || isEdit}
                            value={edition}
                            options={Object.values(Edition)}
                            id="edition"
                            onChange={setEdition}
                        />
                    </FormRow>
                )}
                {!isCreate && (
                    <FormRow label={localization.getString("deploymentType")}>
                        <div className={styles.checkboxes}>
                            <Checkbox
                                disabled={disabled || isEdit}
                                checked={globalEdge}
                                onChange={setGlobalEdge}
                            >
                                {localization.getString("globalEdge")}
                            </Checkbox>
                            <Checkbox
                                disabled={disabled || isEdit}
                                checked={privateEdge}
                                onChange={setPrivateEdge}
                            >
                                {localization.getString("privateEdge")}
                            </Checkbox>
                        </div>
                    </FormRow>
                )}
                <ErrorBanners errors={errors} />
                {!disabled && (
                    <FormButtons
                        rightButtons={
                            <>
                                {(onCancel || isEdit) && (
                                    <Button onClick={onCancel}>
                                        {localization.getString("cancel")}
                                    </Button>
                                )}
                                <Button
                                    brand
                                    type="submit"
                                    loading={isCreateOrgLoading || isUpdateOrgLoading}
                                >
                                    {localization.getString("save")}
                                </Button>
                            </>
                        }
                    ></FormButtons>
                )}
            </Form>
        </Loader>
    )
}
