import { Popover, PopoverOrigin } from "@mui/material"
import classNames from "classnames/bind"
import React from "react"
import { ChromePicker, ColorChangeHandler } from "react-color"

import styles from "./ColorPicker.module.scss"

export interface ColorPickerProps {
    color?: string
    onColorChange?: (color: string) => void
    className?: string
}

export function ColorPicker(props: ColorPickerProps): JSX.Element {
    const colorSwatchRef = React.useRef<HTMLDivElement>(null)

    const [isOpen, setIsOpen] = React.useState(false)

    const onClick = React.useCallback<React.MouseEventHandler>((event) => {
        event.preventDefault()
        setIsOpen(true)
    }, [])

    const onClose = React.useCallback(() => setIsOpen(false), [])

    const onColorChange = React.useCallback<ColorChangeHandler>(
        (newColor, event) => {
            event.preventDefault()
            props.onColorChange?.(newColor.hex)
        },
        [props.onColorChange]
    )

    return (
        <React.Fragment>
            <button onClick={onClick} className={classNames(styles.container, props.className)}>
                <div
                    className={styles.colorSwatch}
                    ref={colorSwatchRef}
                    style={{ backgroundColor: props.color }}
                />
                {props.color?.toUpperCase()}
            </button>
            <Popover
                open={isOpen}
                anchorEl={colorSwatchRef.current}
                onClose={onClose}
                anchorOrigin={anchorOrigin}
                transformOrigin={transformOrigin}
            >
                <ChromePicker color={props.color} onChangeComplete={onColorChange} />
            </Popover>
        </React.Fragment>
    )
}

const anchorOrigin: PopoverOrigin = { vertical: "top", horizontal: "right" }
const transformOrigin: PopoverOrigin = { vertical: "top", horizontal: "left" }
