import { BaseApi, MessageRes } from "./Base.api"
import { Singleton } from "../decorators/Singleton.decorator"

@Singleton("SecureApi")
export class SecureApi extends BaseApi {
    public getRoles(): Promise<Role[]> {
        return this.get("/api/v1/security_roles")
    }

    public insertRole(spec: RoleSpec): Promise<Role> {
        return this.post("/api/v1/insert_security_role", spec)
    }

    public getPolicies(): Promise<Policy[]> {
        return this.get("/api/v1/security_policies")
    }

    public async getLatestVersions(): Promise<PackageVersions> {
        const { latest_versions } = await this.get<{ latest_versions: PackageVersions }>(
            "/docs/api.json"
        )

        return latest_versions
    }

    public getPoliciesByServiceType(policyServiceType: PolicyServiceTypeRes): Promise<Policy[]> {
        if (policyServiceType === PolicyServiceTypeRes.CUSTOM) return this.getPolicies()
        else return this.get("/api/v1/security_policies?servicetype=" + policyServiceType)
    }

    public getPolicy(policyId: string): Promise<Policy[]> {
        return this.get("/api/v1/security_policies?PolicyID=" + encodeURIComponent(policyId))
    }
    public async getPolicyByName(name: string): Promise<Policy | null> {
        return (await this.getPolicies()).find((policy) => policy.PolicyName === name) || null
    }

    public insertPolicy(spec: PolicySpec): Promise<Policy> {
        return this.post("/api/v1/insert_security_policy", spec)
    }

    public deletePolicy(id: string): Promise<MessageRes> {
        return this.delete("/api/v1/delete_security_policy?PolicyID=" + id)
    }
}

export interface Role {
    ClientID: string
    ContainerID: string
    ContainerName: string
    RoleID: string
    RoleName: string
    RoleSpec: string
    RoleType: string
    RoleVersion: number
    CreatedAt: number
    CreatedBy: string
    LastUpdatedAt: number
    LastUpdatedBy: string
    Description: string
    Enabled: string
}

export interface Policy {
    PolicyID: string
    PolicyName: string
    PolicySpec: string
    PolicyVersion: number
    Description: string
    LastUpdatedAt: number
    LastUpdatedBy: string
    CreatedAt: number
    CreatedBy: string
}

interface RoleSpec {
    kind: string
    apiVersion: string
    type: string
    metadata: RoleMetadata
    spec: RoleAttr
}

export interface RoleMetadata {
    id?: string
    name: string
    description: string
    tags: StringMap
}

export interface RoleAttr {
    group?: string[]
    email?: string[]
    device_ownership?: string[]
    known_device_only?: boolean
    repo_tag?: string[]
    label_selector?: StringMap[]
    service_account?: string[]
    mdm_present?: boolean
    platform?: string[]
}

export interface PolicySpec {
    kind: string
    apiVersion: string
    metadata: PolicyMetadata
    type: string
    spec: PolicyAttr
}

export interface PolicyMetadata {
    id?: string
    name: string
    description: string
    tags: StringMap
}

export interface PolicyAttr {
    access: PolicyAccess[]
    exception?: PolicyException
    options?: PolicyOptions
}

interface PolicyOptions {
    disable_tls_client_authentication: boolean
    l7_protocol: PolicyL7Protocol
}

export interface PolicyAccess {
    roles: string[]
    rules: PolicyRules
}

interface PolicyRules {
    l7_access?: PolicyL7Access[]
    l4_access?: PolicyL4Access
    conditions: PolicyConditions
}

interface PolicyL4Access {
    deny: L4Rule[]
    allow: L4Rule[]
}

interface L4Rule {
    cidrs: string[]
    protocols: L4Protocol[]
    ports: string[]
    fqdns: string[]
}

export enum L4Protocol {
    ALL = "ALL",
    TCP = "TCP",
    UDP = "UDP",
    ICMP = "ICMP",
}

export interface PolicyL7Access {
    resources: string[]
    actions: PolicyL7Action[]
}

export interface PolicyConditions {
    start_time?: string
    end_time?: string
    trust_level?: string
}

interface PolicyException {
    src_addr: string[]
}

export enum PolicyL7Action {
    "CREATE" = "CREATE",
    "READ" = "READ",
    "UPDATE" = "UPDATE",
    "DELETE" = "DELETE",
    // TODO: Find a name for this option
    "*" = "*",
}
type PolicyL7Protocol = "http" | "kafka" | "mysql" | ""

export enum SpecKind {
    USER = "origin",
    WORKLOAD = "attribute",
    CUSTOM = "custom",
}

export enum PolicyServiceTypeRes {
    WEB = "WEB",
    TCP = "TCP",
    TUNNEL = "TUNNEL",
    CUSTOM = "CUSTOM",
}

export type PackageVersions = {
    shield: string
    netagent: string
    connector: string
    command_center: string
    desktop_app: string
    mobile_app: string
}
