import React, { ReactNode, SyntheticEvent } from "react"
import { Bind } from "../../../../decorators/Bind.decorator"
import { LocalizationService } from "../../../../services/localization/Localization.service"
import { ServiceAppType } from "../../../../services/Manage.service"
import ServicesAddDnsOverridesTemplate from "./ServicesAddDnsOverrides.template"

export class ServicesAddDnsOverrides extends React.Component<ServicesAddDnsOverridesProps, {}> {
    public render(): ReactNode {
        return ServicesAddDnsOverridesTemplate.call(this)
    }

    private ls: LocalizationService = new LocalizationService()

    @Bind
    private onChange(event: SyntheticEvent): void {
        const value: string = (event.target as HTMLInputElement).value
        if (this.props.onChange) {
            this.props.onChange(value)
        }
    }
}

interface ServicesAddDnsOverridesProps {
    disabled: boolean
    serviceAppType: ServiceAppType
    initialValue: string
    onChange: (value: string) => void
}
