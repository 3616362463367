import React from "react"
import styles from "./ServicesAddSaasAuthenticator.module.scss"
import { Select } from "../../../../components/select/Select.component"
import { SaasAuthProtocol } from "../../../../api/Manage.api"
import { SaasNameIdValueOpts } from "../../../../api/Manage.api"
import { ToggleButton } from "../../../../components/toggle-button/ToggleButton.component"
import { FormLabel } from "../../../../components"
import { Input } from "../../../../../v3/components/input/Input.component"

export default function () {
    const redirectInputPlaceholder =
        this.state.authProtocolOption === SaasAuthProtocol.SAML
            ? this.localizationService.getString("enterRedirectUrl") +
              ` (${this.localizationService.getString("samlAcs")})`
            : this.localizationService.getString("enterRedirectUrlEgDescription")
    if (!this.state.loading) {
        return (
            <>
                {!this.props.defaultAuthProtocol && (
                    <FormLabel
                        title={this.localizationService.getString(
                            this.props.idpRouted
                                ? "whichAuthenticationProtocolWillThisIdpRoutedApplicationUse"
                                : "whichAuthenticationProtocolWillThisSaasApplicationUse"
                        )}
                        htmlFor="authenticationProtocol"
                    >
                        <ToggleButton
                            className={styles.options}
                            items={this.authProtocolTypes}
                            value={this.state.authProtocolOption}
                        />
                    </FormLabel>
                )}
                {
                    <>
                        <FormLabel
                            title={
                                <>
                                    {this.localizationService.getString("redirectUri")}
                                    {this.state.authProtocolOption === SaasAuthProtocol.SAML &&
                                        ` (${this.localizationService.getString("samlAcs")})`}
                                </>
                            }
                            htmlFor="redirectUri"
                        >
                            <Input
                                className={styles.formInput}
                                value={this.state.redirectUri}
                                onChange={this.onRedirectUrlChange}
                                placeholder={redirectInputPlaceholder}
                            />
                        </FormLabel>
                        {this.state.authProtocolOption === SaasAuthProtocol.SAML && (
                            <>
                                <FormLabel
                                    title={this.localizationService.getString(
                                        "audienceUriDescription"
                                    )}
                                    htmlFor="audienceUriDescription"
                                >
                                    <Input
                                        className={styles.formInput}
                                        value={this.state.audienceUri}
                                        onChange={this.onAudienceUrlChange}
                                        placeholder={this.localizationService.getString(
                                            "enterAudienceUriDescription"
                                        )}
                                    />
                                </FormLabel>
                                <FormLabel
                                    title={this.localizationService.getString("nameIdFormat")}
                                    htmlFor="nameIdFormat"
                                >
                                    <Select
                                        options={this.nameIdOptions}
                                        value={this.state.nameIdFormat}
                                        onChange={this.onNameIdFormatChange}
                                    />
                                </FormLabel>
                                <FormLabel
                                    title={this.localizationService.getString("selectNameIdValue")}
                                    htmlFor="selectNameIdValue"
                                >
                                    <Select
                                        options={this.nameIdValueOptions}
                                        value={this.state.nameIdValueType}
                                        onChange={this.onNameIdSelectorChange}
                                    />
                                </FormLabel>
                                {this.state.nameIdValueType === SaasNameIdValueOpts.CUSTOM && (
                                    <FormLabel
                                        title={this.localizationService.getString(
                                            "nameIdCustomAttribute"
                                        )}
                                        htmlFor="nameIdCustomAttribute"
                                    >
                                        <Input
                                            className={styles.formInput}
                                            value={this.state.nameIdAttributeSelector}
                                            onChange={this.onCustomNameIdValueChange}
                                            placeholder={this.localizationService.getString(
                                                "enterYourCustomAttributeSelector"
                                            )}
                                        />
                                    </FormLabel>
                                )}
                            </>
                        )}
                    </>
                }
            </>
        )
    } else {
        return null
    }
}
