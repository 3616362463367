import React from "react"
import { Redirect, useLocation } from "react-router-dom"

import { useServiceLocalization } from "../../../../pre-v3/services"
import { ROUTE } from "../../../../routes"
import { ErrorBanner, InfoBanner } from "../../../components/banner/Banner.component"
import { Button, ButtonElement, ButtonType } from "../../../../components/button/Button.component"
import { OldInput } from "../../../components/old-input/OldInput.component"
import { useForgotPassword } from "../../../services/Login.service"
import styles from "../Login.module.scss"
import { RouteUtils } from "../Route.utils"
import { SwitchOrgMessage } from "../SwitchOrgMessage.component"

enum ResetPasswordFormState {
    INITIAL = "INITIAL",
    LOADING = "LOADING",
    ERROR = "ERROR",
    SUCCESS = "SUCCESS",
}

export function ResetPasswordForm(): JSX.Element {
    const forgotPassword = useForgotPassword()

    const location = useLocation()
    const localization = useServiceLocalization()

    const [state, setState] = React.useState(ResetPasswordFormState.INITIAL)
    const [emailAddress, setEmailAddress] = React.useState("")

    const orgName = RouteUtils.getOrgNameFromUrl(location)

    if (!orgName) return <Redirect to={ROUTE.ORG_FORM} />

    const onSubmit: React.FormEventHandler<HTMLFormElement> = async (event) => {
        event.preventDefault()
        setState(ResetPasswordFormState.LOADING)

        try {
            await forgotPassword.mutateAsync({ orgName, emailAddress })
            setState(ResetPasswordFormState.SUCCESS)
        } catch (_error) {
            setState(ResetPasswordFormState.ERROR)
        }
    }

    const isSubmitLoading = state === ResetPasswordFormState.LOADING
    const isErrorShown = state === ResetPasswordFormState.ERROR
    const isSuccessShown = state === ResetPasswordFormState.SUCCESS

    const emailAddressLabel = localization.getString("emailAddress")

    return (
        <React.Fragment>
            <div className={styles.resetPassword}>{localization.getString("resetPassword")}</div>
            {isErrorShown && (
                <ErrorBanner className={styles["space-below"]}>
                    {localization.getString("somethingWentWrongDescription")}
                </ErrorBanner>
            )}
            {isSuccessShown && (
                <InfoBanner className={styles["space-below"]}>
                    {localization.getString("passwordResetEmailExplanation")}
                </InfoBanner>
            )}
            <p className={styles.resetPasswordExplainer}>
                {localization.getString("resetPasswordExplanation")}
            </p>
            <form onSubmit={onSubmit} className={styles.form}>
                <OldInput
                    type="email"
                    value={emailAddress}
                    onChangeValue={setEmailAddress}
                    required
                    autoFocus
                    aria-label={emailAddressLabel}
                    placeholder={emailAddressLabel}
                    className={styles["space-below"]}
                />
                <Button
                    asElement={ButtonElement.BUTTON}
                    buttonType={ButtonType.PRIMARY}
                    className={styles.submitButton}
                    type="submit"
                    loading={isSubmitLoading}
                >
                    {localization.getString("resetPassword")}
                </Button>
            </form>
            <SwitchOrgMessage />
        </React.Fragment>
    )
}
