import classNames from "classnames/bind"
import React, { ReactNode } from "react"
import styles from "./SimpleTable.module.scss"

export function SimpleTable(props: Props) {
    const { className, items } = props

    return (
        <table className={classNames(className, styles.simpleTable)}>
            <tbody>
                {items.map((item) => (
                    <tr key={item.label}>
                        <td className={styles.label}>{item.label}</td>
                        <td className={styles.valueCell}>{item.value}</td>
                    </tr>
                ))}
            </tbody>
        </table>
    )
}

interface Props {
    className?: string
    items: SimpleTableItem[]
}

export interface SimpleTableItem {
    label: string
    value: string | ReactNode
    valueClassName?: string
}
