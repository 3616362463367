import { ReactNode } from "react"
import MenuButtonTemplate from "./MenuButton.template"
import { BaseMenu, BaseMenuState } from "../base-menu/BaseMenu.component"
import { IconDefinition } from "@fortawesome/pro-solid-svg-icons"

export class MenuButton extends BaseMenu<
    React.PropsWithChildren<MenuButtonProps>,
    MenuButtonState
> {
    public render(): ReactNode {
        return MenuButtonTemplate.call(this)
    }
}

interface MenuButtonProps {
    className?: string
    icon: IconDefinition
    label?: string
    showDownArrow?: boolean
}

interface MenuButtonState extends BaseMenuState {}
