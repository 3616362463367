import * as React from "react"
import { Singleton } from "../decorators/Singleton.decorator"
import ServiceContext from "./context"
import {
    EntityApi,
    DeviceSearch,
    EndUserSearch,
    TrustLevel,
    UnregisteredDeviceSearch,
    Platform,
} from "../api/Entity.api"
import EntityV2Api from "../api/EntityV2.api"
import { EntityUtil, UnregisteredDeviceEntity } from "../utils/Entity.util"
import { Paginated, PaginatedSearch, SortBy } from "../utils/AgGrid.util"
import { decodeID } from "../utils/Url.util"

@Singleton("EntityService")
export class EntityService {
    public async getUserEmails(params: PaginatedSearch): Promise<string[]> {
        const search: EndUserSearch = {
            skip: params.skip,
            limit: params.limit,
            active: "true",
        }
        if (params.filterModel && params.filterModel.email) {
            search.email = params.filterModel.email.filter
        }

        if (params.sortModel && params.sortModel.length > 0) {
            let sortModel: SortBy[] = this.mapSortModel(params.sortModel)
            search.order = sortModel[0].sort
            search.orderby = sortModel[0].colId
        }

        const { endusers } = await this.entityApi.getUsers(search)

        return endusers.map((user) => user.Email)
    }

    public deleteUnregisteredDevices(ids: string[]): Promise<void> {
        const payload = {
            enduser_device_ids: ids,
        }
        return this.entityApiV2.deleteUnregisteredDevices(payload)
    }

    public async getDeviceSerialNumbers(
        params: PaginatedSearch,
        startTime?: number,
        endTime?: number
    ): Promise<string[]> {
        const search: DeviceSearch = {
            skip: params.skip,
            limit: params.limit,
            active: "true",
        }
        if (params.filterModel?.name) {
            search.name = params.filterModel.name.filter
        }
        if (params.filterModel?.status) {
            search.trustscore_status = params.filterModel.status.filter
        }
        if (params.filterModel?.trustScore) {
            search.trust_level = <TrustLevel>params.filterModel.trustScore.filter
        }
        if (params.filterModel?.platform) {
            search.platform = params.filterModel.platform.filter as Platform
        }
        if (params.filterModel?.serial) {
            search.serial_number = params.filterModel.serial.filter
        }
        if (params.filterModel?.roles) {
            search.role_names = params.filterModel.roles.filter
        }
        if (params.filterModel?.appVersion) {
            search.app_version = params.filterModel.appVersion.filter
        }
        if (params.filterModel?.ownership) {
            search.ownership = params.filterModel.ownership.filter
        }
        if (params.filterModel?.trustProfile) {
            search.trust_profile_id = decodeID(params.filterModel.trustProfile.filter)
        }

        if (startTime !== undefined && endTime) {
            search.last_login_after = startTime
            search.last_login_before = endTime
        }

        if (params.sortModel && params.sortModel.length > 0) {
            search.order = params.sortModel[0].sort
            switch (params.sortModel[0].colId) {
                case "lastLogin":
                    search.order_by = "last_login"
                    break
                case "trustScore":
                    search.order_by = "trustscore"
                    break
                case "status":
                    search.order_by = "trustscore_status"
                    break
                case "appVersion":
                    search.order_by = "app_version"
                    break
                default:
                    search.order_by = params.sortModel[0].colId
            }
        }

        const { devices } = await this.entityApiV2.getDevices(search)

        return devices.map((device) => device.serial_number)
    }

    public getUnregisteredDeviceEntities(
        params: PaginatedSearch
    ): Promise<Paginated<UnregisteredDeviceEntity>> {
        const search: UnregisteredDeviceSearch = {
            skip: params.skip,
            limit: params.limit,
            active: "true",
        }
        if (params.filterModel?.email) {
            search.email = params.filterModel.email.filter
        }
        if (params.filterModel?.platform) {
            search.platform = params.filterModel.platform.filter
        }
        if (params.sortModel && params.sortModel.length > 0) {
            search.order = params.sortModel[0].sort
            switch (params.sortModel[0].colId) {
                case "lastLogin":
                    search.order_by = "last_login"
                    break
                default:
                    search.order_by = params.sortModel[0].colId
            }
        }

        return this.entityApi.getUnregisteredDevices(search).then((res) => {
            return {
                total: res.count,
                data: EntityUtil.getUnregisteredDevices(res.devices),
            }
        })
    }

    private entityApi: EntityApi = new EntityApi()
    private entityApiV2: EntityV2Api = new EntityV2Api()

    private mapSortModel(sortModel: SortBy[]): SortBy[] {
        return sortModel.map((column) => {
            return column.colId === "lastLogin" ? { ...column, colId: "last_login" } : column
        })
    }
}

export const useServiceEntity = () =>
    React.useContext(ServiceContext)?.entity || new EntityService()
