import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import classnames from "classnames/bind"
import React from "react"

import { useServiceLocalization } from "../../../../../../../../pre-v3/services"
import {
    BanyanAppVersionTrustFactor,
    useGetBanyanAppVersions,
} from "../../../../../../../services/TrustProfile.service"
import {
    DesktopPlatform,
    Platform,
    platformIconDict,
    platformLabelDict,
} from "../../../../../../../services/shared/Platform"
import { AppText } from "../../../../../../../components/app-text/AppText.component"
import { SelectInput } from "../../../../../../../components/select-input/SelectInput.component"
import styles from "./BanyanAppVersion.module.scss"

interface Props {
    trustFactor: BanyanAppVersionTrustFactor
    className?: string
    onEdit(trustFactor: BanyanAppVersionTrustFactor): void
    disabled?: boolean
}

export function BanyanAppVersion(props: Props): JSX.Element {
    const { data: versions } = useGetBanyanAppVersions()

    if (!versions) return <React.Fragment />

    return (
        <div className={classnames(styles.container, props.className)}>
            <AppText ls="selectWhichCseAppVersionADeviceIsAllowedToUse" />
            <VersionSelect {...props} versions={versions} platform={Platform.MACOS} />
            <VersionSelect {...props} versions={versions} platform={Platform.WINDOWS} />
            <VersionSelect {...props} versions={versions} platform={Platform.LINUX} />
        </div>
    )
}

interface VersionSelectProps extends Omit<Props, "className"> {
    platform: Exclude<DesktopPlatform, Platform.CHROME>
    versions: Record<Platform, string[]>
}

function VersionSelect(props: VersionSelectProps): JSX.Element {
    const localization = useServiceLocalization()
    const banyanAppVersionLabel = localization.getString("cseAppVersion")
    const greaterThanOrEqualToSignLabel = localization.getString("greaterThanOrEqualToSign")
    const platformLabel = localization.getString(platformLabelDict[props.platform])

    const onChange = (version: string): void =>
        props.onEdit({
            ...props.trustFactor,
            configuration: { ...props.trustFactor.configuration, [props.platform]: version },
        })

    return (
        <div className={styles.versionSelectContainer}>
            <label className={styles.versionLabel}>
                <FontAwesomeIcon
                    icon={platformIconDict[props.platform]}
                    className={styles.platformIcon}
                    fixedWidth
                    aria-label={platformLabel}
                />
                <span>{banyanAppVersionLabel}</span>
                <span>{greaterThanOrEqualToSignLabel}</span>
            </label>
            <SelectInput
                options={props.versions[props.platform]}
                value={props.trustFactor.configuration[props.platform]}
                onChange={onChange}
                className={styles.versionSelect}
                disabled={props.disabled}
            />
        </div>
    )
}
