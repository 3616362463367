import React from "react"
import {
    faCheckCircle,
    faExclamationCircle,
    faPauseCircle,
    faTimesCircle,
    IconDefinition,
} from "@fortawesome/pro-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import classNames from "classnames"
import { TrustIntegration } from "../../../services/TrustIntegration.service"
import { useServiceLocalization } from "../../../../pre-v3/services/localization/Localization.service"
import styles from "./StatusCellRenderer.module.scss"

interface Props {
    value: TrustIntegration["syncStatus"]
    data: TrustIntegration
}

export function StatusCellRenderer(props: Props) {
    const localization = useServiceLocalization()

    const StatusTypes: StatusTypes = {
        active: {
            icon: faCheckCircle,
            text: localization.getString("active"),
            className: styles.success,
        },
        inactive: {
            icon: faExclamationCircle,
            text: localization.getString("inactive"),
            className: styles.inactive,
        },
        pending: {
            icon: faPauseCircle,
            text: localization.getString("pending"),
            className: styles.pending,
        },
        error: {
            icon: faTimesCircle,
            text: localization.getString("error"),
            className: styles.error,
        },
    }

    const { icon, className, text } = StatusTypes[props.value]

    return (
        <div className={styles.container}>
            <FontAwesomeIcon icon={icon} className={classNames(styles.statusIcon, className)} />
            <span className={styles.label}>{text}</span>
        </div>
    )
}

type StatusTypes = Record<
    TrustIntegration["syncStatus"],
    { icon: IconDefinition; text: string; className: string }
>
