import React, { ReactNode, SyntheticEvent } from "react"
import { RouteComponentProps } from "react-router"

import { ROUTE, formatRoutePath } from "../../../../routes"
import { SaasManage, ManageService, SaasInitialAuth } from "../../../services/Manage.service"
import { LocalizationService } from "../../../services/localization/Localization.service"
import { decodeID, encodeID } from "../../../utils/Url.util"
import { Bind } from "../../../decorators/Bind.decorator"
import { SaasAuthenticatorValue } from "../add/saas-authenticator/ServicesAddSaasAuthenticator.component"
import { SaasAppUrlType } from "../../../services/Service.service"
import { UserService } from "../../../services"
import { UserOrgDetails } from "../../../api/User.api"
import ServicesSaasEditTemplate from "./ServicesSaasEdit.template"

export class ServicesSaasEdit extends React.Component<
    RouteComponentProps<ServicesSaasEditRouteParams>,
    ServicesSaasEditState
> {
    constructor(props: RouteComponentProps<ServicesSaasEditRouteParams>) {
        super(props)

        try {
            this.id = decodeID(this.props.match.params.id)
        } catch {
            this.state.loading = false
        }
    }

    public state: ServicesSaasEditState = {
        loading: true,
        submitting: false,
        statusDialogOpen: false,
        deleteDialogOpen: false,
        isIdpRouted: false,
    }

    public componentDidMount(): void {
        this.userService.getUserOrgDetails().then(
            (details: UserOrgDetails) => {
                if (details.AsyncAuthEnabled) {
                    this.setState({ asyncAuthState: true })
                }
            },
            () => {
                this.setState({ asyncAuthState: false })
            }
        )
        this.fetchData()
    }

    public render(): ReactNode {
        return ServicesSaasEditTemplate.call(this)
    }

    private id: string
    private oldPolicyId: string

    private submitButton: HTMLButtonElement

    private localizationService: LocalizationService = new LocalizationService()
    private manageService: ManageService = new ManageService()
    private userService: UserService = new UserService()

    @Bind
    private openStatusDialog(): void {
        this.setState({ statusDialogOpen: true })
    }

    @Bind
    private closeStatusDialog(success: boolean): void {
        this.setState({ statusDialogOpen: false })
        if (success) {
            this.fetchData()
        }
    }

    @Bind
    private openDeleteDialog(): void {
        this.setState({ deleteDialogOpen: true })
    }

    @Bind
    private closeDeleteDialog(success: boolean): void {
        if (success) {
            this.props.history.push(ROUTE.SAAS_APPS)
        } else {
            this.setState({ deleteDialogOpen: false })
        }
    }

    @Bind
    private onAuthenticatorChange(value: SaasAuthenticatorValue): void {
        if (this.state.service) {
            this.setState({
                service: { ...this.state.service, ...value },
            })
        }
    }

    @Bind
    private onPolicyChange(id: string, enabled: boolean): void {
        if (this.state.service) {
            this.state.service.policyId = id
            this.state.service.policyEnabled = enabled
        }
    }

    @Bind
    public onChange(value: boolean) {
        if (this.state.service) {
            this.state.service.suppressDeviceTrustVerification = value
            this.setState({ suppressDTVFlag: value })
        }
    }

    @Bind
    public onPasswordlessAuthChange(value: boolean) {
        if (this.state.service) {
            this.state.service.passwordlessAuthentication = value
            this.setState({ passwordlessAuthentication: value })
        }
    }

    @Bind
    private onSubmitForm(): void {
        this.submitButton.click()
    }

    @Bind
    private onCancel(): void {
        this.props.history.push(formatRoutePath(ROUTE.SAAS_APPS_DETAILS, { id: encodeID(this.id) }))
    }

    @Bind
    private onEditService(event: SyntheticEvent): void {
        event.preventDefault()
        if (!(event.target && this.state.service)) return

        this.setState({ error: "", submitting: true })

        const form: { [key: string]: HTMLInputElement } = <any>event.target
        //update description
        this.state.service.description = form.serviceDescription.value

        this.manageService.editSaasApp(this.state.service, this.oldPolicyId).then(
            (service: SaasManage) => {
                this.setState({ submitting: false })

                this.props.history.push(
                    formatRoutePath(ROUTE.SAAS_APPS_DETAILS, {
                        id: encodeID(service.id),
                    })
                )
            },
            (err: Error) => {
                this.setState({ error: err.message, submitting: false })
            }
        )
    }

    @Bind
    private fetchData(): void {
        this.setState({ loading: true, error: "" })
        if (this.id) {
            this.manageService.getSaasApp(this.id).then(
                (service: SaasManage | undefined) => {
                    this.setState({ loading: false, service })

                    if (!service) return

                    this.oldPolicyId = service.policyId!

                    this.setState({
                        isIdpRouted: service.initialAuth === SaasInitialAuth.IDP,
                        suppressDTVFlag: service.suppressDeviceTrustVerification,
                        passwordlessAuthentication: service.passwordlessAuthentication,
                    })
                },
                () => {
                    this.setState({ loading: false, service: undefined })
                }
            )
        } else {
            this.setState({ loading: false, service: undefined })
        }
    }
}

interface ServicesSaasEditRouteParams {
    id: string
    type: SaasAppUrlType
}

interface ServicesSaasEditState {
    loading: boolean
    error?: string
    submitting: boolean
    service?: SaasManage
    statusDialogOpen: boolean
    deleteDialogOpen: boolean
    suppressDTVFlag?: boolean
    asyncAuthState?: boolean
    passwordlessAuthentication?: boolean
    isIdpRouted: boolean
}
