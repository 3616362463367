import React from "react"

import { useInput } from "./useInput.hook"

export interface TextAreaProps extends React.TextareaHTMLAttributes<HTMLTextAreaElement> {
    /**
     * Triggered by `onChange` and `onBlur` called with the event's value
     * @param value Event's value
     */
    onChangeValue?(value: string): void
}

/**
 * Used to create interactive controls for web-based forms in order to accept
 * data from the user
 */
export const TextArea = React.forwardRef<HTMLTextAreaElement, TextAreaProps>((props, ref) => {
    const { onChangeValue, ...textAreaProps } = props

    const extraProps = useInput({ ...textAreaProps, onChangeValue })

    return <textarea {...textAreaProps} {...extraProps} ref={ref} />
})

TextArea.displayName = "TextArea"
