import React, { useState } from "react"
import { useHistory } from "react-router-dom"

import { useActionBar } from "../../../../pre-v3/services/ActionBar.service"
import { useServiceLocalization } from "../../../../pre-v3/services/localization/Localization.service"
import { encodeID } from "../../../../pre-v3/utils/Url.util"
import { ROUTE, formatRoutePath } from "../../../../routes"
import { AppText } from "../../../components/app-text/AppText.component"
import { ErrorBanner } from "../../../components/banner/Banner.component"
import { Loader } from "../../../components/loader/Loader.component"
import { TabBar, TabProps } from "../../../components/tab-bar/TabBar.component"
import {
    useGetClusters,
    useSaveRegisteredDomain,
    useValidateRegisteredDomain,
} from "../../../services/RegisteredDomain.service"
import { RegisteredDomainConfigurationForm } from "../configuration-form/RegisteredDomainConfigurationForm.component"
import { RegisteredDomainDnsForm } from "../dns-form/RegisteredDomainDnsForm.component"
import styles from "./RegisteredDomainAdd.module.scss"

export interface Props {
    enableAccessTierGroups: boolean
    canAddRegisteredDomain?: boolean
}

export function RegisteredDomainAdd(props: Props): JSX.Element {
    const ls = useServiceLocalization()
    const history = useHistory()

    const tabs: TabProps[] = [
        {
            id: "configuration",
            label: ls.getString("configuration"),
            ariaControls: "configuration-tab",
        },
        {
            id: "dnsSettings",
            label: ls.getString("dnsSettings"),
            ariaControls: "dnsSettings-tab",
        },
    ]
    const [currentTab, setCurrentTab] = useState<string>("configuration")

    const clustersQuery = useGetClusters(props.enableAccessTierGroups)

    const saveRegisteredDomain = useSaveRegisteredDomain(props.enableAccessTierGroups, {
        onSuccess: () => setCurrentTab("dnsSettings"),
    })

    const validate = useValidateRegisteredDomain({
        onSuccess: () =>
            saveRegisteredDomain.data &&
            history.push(
                formatRoutePath(ROUTE.REGISTERED_DOMAINS_DETAILS, {
                    id: encodeID(saveRegisteredDomain.data.id),
                })
            ),
    })

    useActionBar({
        title: ls.getString("addRegisteredDomain"),
        items: [
            {
                label: ls.getString("certificates"),
            },
            {
                label: ls.getString("registeredDomains"),
                href: ROUTE.REGISTERED_DOMAINS,
            },
        ],
    })

    function onConfigurationCancel(): void {
        history.push(ROUTE.REGISTERED_DOMAINS)
    }

    function onDnsValidate(): void {
        saveRegisteredDomain.data && validate.mutate(saveRegisteredDomain.data.id)
    }

    function onDnsBack(): void {
        validate.reset()
        setCurrentTab("configuration")
    }

    if (clustersQuery.status === "loading") {
        return <Loader children isLoading center medium />
    }

    if (clustersQuery.status === "error") {
        return <ErrorBanner children={String(clustersQuery.error)} />
    }

    return (
        <div>
            <AppText ls="addRegisteredDomainDescription" className={styles.description} />
            <TabBar
                className={styles.tabs}
                tabs={tabs}
                selectedTabId={currentTab}
                onChange={setCurrentTab}
                type="stepper"
            />
            {currentTab === "configuration" && (
                <RegisteredDomainConfigurationForm
                    id="configuration-tab"
                    clusters={clustersQuery.data}
                    registeredDomain={saveRegisteredDomain.data}
                    onSubmit={saveRegisteredDomain.mutate}
                    onCancel={onConfigurationCancel}
                    isSubmitting={saveRegisteredDomain.isLoading}
                    error={saveRegisteredDomain.error}
                    enableAccessTierGroups={props.enableAccessTierGroups}
                    canAddRegisteredDomain={props.canAddRegisteredDomain}
                />
            )}
            {currentTab === "dnsSettings" && (
                <RegisteredDomainDnsForm
                    id="dnsSettings-tab"
                    registeredDomain={saveRegisteredDomain.data}
                    onValidate={onDnsValidate}
                    onBack={onDnsBack}
                    isValidating={validate.isLoading}
                    error={validate.error}
                />
            )}
        </div>
    )
}
