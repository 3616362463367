import React from "react"
import { useHistory } from "react-router"
import { useRouteMatch } from "react-router-dom"

import {
    Button,
    ButtonElement,
    ButtonType,
} from "../../../../../components/button/Button.component"
import { ROUTE } from "../../../../../routes"
import {
    useServiceLocalization,
    useServiceInfra,
    ClusterInfra,
    useServiceLinks,
} from "../../../../services"
import { Select, IconSelect, FormSection, Tooltip, FormLabel } from "../../../../components"
import {
    ServiceFormProps,
    useSetErrors,
    useSetShowError,
    useUpdateMetadata,
    useUpdateTags,
    useSetCluster,
} from ".."
import { SelectItem } from "../../../../utils/SelectValue.util"
import { Switch } from "../../../../../v3/components/switch/Switch.component"
import EditStatusDialog from "../dialog-status"
import DeleteDialog from "../dialog-delete"
import styles from "./ServiceDetails.module.scss"
import { Input } from "../../../../../v3/components/input/Input.component"
import { TextArea } from "../../../../../v3/components/input/TextArea.component"
import { AppText } from "../../../../../v3/components/app-text/AppText.component"
import { StringUtil } from "../../../../utils/String.util"

export default function ServiceDetailsForm({
    edit,
    enableFormField,
    hideCluster,
    hideDisableDelete,
    hideConnectOnLogin,
    showRdpPros,
}: ServiceDetailsFormProps) {
    // grab the services we'll use
    const localization = useServiceLocalization()
    const infraService = useServiceInfra()

    // we need some of the info from the local form context
    const updateMetadata = useUpdateMetadata()
    const updateTags = useUpdateTags()
    const setErrors = useSetErrors()
    const setShowError = useSetShowError()
    const setCluster = useSetCluster()

    // grab a reference to the history
    const history = useHistory()
    const match = useRouteMatch()

    // pull out the service spec
    const spec = edit?.spec

    const linkService = useServiceLinks()

    // some state to track the form values
    const [name, setName] = React.useState(edit ? edit.name : "")
    const [description, setDescription] = React.useState(edit ? edit.description : "")
    const [link, setLink] = React.useState(spec ? spec.metadata.tags.description_link || "" : "")
    const [icon, setIcon] = React.useState(spec?.metadata.tags.icon)
    const [clusterName, setClusterName] = React.useState(spec ? spec.metadata.cluster : "")
    const [enabled, setEnabled] = React.useState(edit ? edit.enabled : true)
    const [rdpProps, setRdpProps] = React.useState(
        spec?.metadata.tags.rdp_settings
            ? StringUtil.arrayToStringList(spec.metadata.tags.rdp_settings)
            : ""
    )

    // some state to track the dialogs
    const [statusDialogOpen, setStatusDialogOpen] = React.useState(false)
    const [deleteDialogOpen, setDeleteDialogOpen] = React.useState(false)

    const [updatedName, setUpdatedName] = React.useState(false)

    //always enabled when adding a new service
    const [userFacing, setUserFacing] = React.useState(
        spec ? spec.metadata.tags.user_facing === "true" : true
    )

    const [connectOnLogin, setConnectOnLogin] = React.useState(spec ? spec.metadata.autorun : false)

    // we need to load the list of valid clusters
    const [clusterOptions, setClusterOptions] = React.useState<(SelectItem & ClusterInfra)[]>([])
    React.useEffect(() => {
        if (!hideCluster) {
            infraService.getClusters().then((clusters) => {
                // update the total list
                setClusterOptions(
                    clusters.map((cluster) => ({
                        displayName: cluster.name,
                        value: cluster.name,
                        ...cluster,
                    }))
                )

                // if theres only one cluster, select it
                if (clusters.length === 1) {
                    setClusterName(clusters[0].name)
                }

                // if we have a cluster name
                if (clusterName) {
                    // find the matching cluster
                    const cluster = clusters.find((opt) => opt.name === clusterName)
                    if (cluster) {
                        setCluster(cluster)
                    }
                }
            })
        }
    }, [infraService, setCluster, clusterName, hideCluster])

    // when a form value changes, we need to update the parent's state
    React.useEffect(() => {
        updateMetadata((old) => ({
            ...old,
            name,
            description,
            cluster: clusterName,
            autorun: connectOnLogin,
            tags: {
                ...old.tags,
                icon,
                description_link: link,
                user_facing: userFacing ? "true" : "false",
                rdp_settings: StringUtil.stringListToArray(rdpProps),
            },
        }))
    }, [
        name,
        description,
        userFacing,
        connectOnLogin,
        link,
        icon,
        clusterName,
        updatedName,
        localization,
        setErrors,
        setShowError,
        updateMetadata,
        updateTags,
        rdpProps,
    ])
    return (
        <FormSection title={localization.getString("serviceDetails")}>
            <FormLabel title={localization.getString("serviceName")} htmlFor="serviceName">
                <Input
                    id="serviceName"
                    className={styles.formInput}
                    required
                    disabled={Boolean(edit) && !enableFormField}
                    placeholder={localization.getString("serviceName")}
                    value={name}
                    onChange={(e) => {
                        setName(e.target.value)
                        setUpdatedName(true)
                    }}
                />
            </FormLabel>

            <FormLabel
                title={localization.getString("descriptionShownToEndUsers")}
                htmlFor="serviceDescription"
            >
                <Input
                    id="serviceDescription"
                    className={styles.formInput}
                    placeholder={localization.getString("enterServiceDescription")}
                    value={description}
                    onChange={(e) => {
                        setDescription(e.target.value)
                    }}
                />
            </FormLabel>

            <FormLabel
                title={localization.getString("linkShownToEndUsersOptional")}
                htmlFor="serviceLink"
            >
                <Input
                    className={styles.formInput}
                    type="url"
                    placeholder={localization.getString("linkShownToEndUsersDescription")}
                    value={link}
                    onChange={(e) => setLink(e.target.value)}
                />
            </FormLabel>

            <FormLabel title={localization.getString("icon")} htmlFor="icon">
                <IconSelect onChange={setIcon} initialValue={icon} />
            </FormLabel>

            {!hideCluster && (
                <FormLabel title={localization.getString("clusterName")} htmlFor="clusterName">
                    {/* the user cannot change the cluster once its been set */}
                    {edit && !enableFormField ? (
                        <Input className={styles.formInput} disabled value={clusterName} />
                    ) : (
                        <Select
                            className={styles.formInput}
                            options={clusterOptions}
                            required
                            value={clusterName}
                            onChange={(val) => {
                                setClusterName(val as string)
                                setCluster(clusterOptions.find((cluster) => cluster.value === val))
                            }}
                        />
                    )}
                </FormLabel>
            )}
            <FormLabel
                inline={false}
                title={localization.getString("showServiceInServiceCatalogInCseApp")}
                htmlFor="userFacing"
            >
                <Switch
                    className={styles.switch}
                    disabled={false}
                    value={userFacing}
                    onChange={setUserFacing}
                />
            </FormLabel>
            {showRdpPros && (
                <FormLabel
                    inline={false}
                    title={localization.getString("appendRdpFileProperties")}
                    description={
                        <AppText
                            ls={{
                                key: "rdpPropertiesAvailableForTheRdpFileCanBeFoundHere",
                                replaceVals: [linkService.getLink("rdpProperties")],
                            }}
                        />
                    }
                    htmlFor="appendRdpFileProperties"
                >
                    <TextArea
                        id="appendRdpFileProperties"
                        value={rdpProps}
                        onChange={(e) => setRdpProps(e.target.value)}
                    />
                </FormLabel>
            )}
            {!hideConnectOnLogin && (
                <FormLabel
                    inline={false}
                    title={localization.getString("connectOnLogin")}
                    description={localization.getString(
                        "connectOnLoginInfrastructureServiceDescription"
                    )}
                >
                    <Switch
                        className={styles.switch}
                        disabled={false}
                        value={connectOnLogin}
                        onChange={setConnectOnLogin}
                        aria-label={localization.getString("connectOnLogin")}
                    />
                </FormLabel>
            )}
            {/* Only show the status toggle if we are editing an existing service */}
            {edit && !hideDisableDelete && !enableFormField && (
                <>
                    <FormLabel title={localization.getString("status")} htmlFor="status">
                        <Input
                            disabled
                            className={styles.formInput}
                            value={
                                edit.enabled
                                    ? localization.getString("enabled")
                                    : localization.getString("disabled")
                            }
                        />
                        <div className={styles.buttons}>
                            <Button
                                type="button"
                                className={styles.statusButton}
                                onClick={() => setStatusDialogOpen(true)}
                                asElement={ButtonElement.BUTTON}
                                buttonType={ButtonType.SECONDARY}
                            >
                                {enabled
                                    ? localization.getString("disable")
                                    : localization.getString("enable")}
                            </Button>
                            {enabled || edit.policyId ? (
                                <Tooltip
                                    title={localization.getString(
                                        "serviceDeleteDisabledDescription"
                                    )}
                                    placement="right"
                                >
                                    <span style={{ display: "inline-block" }}>
                                        <Button
                                            type="button"
                                            disabled
                                            asElement={ButtonElement.BUTTON}
                                            buttonType={ButtonType.PRIMARY}
                                            onClick={() => {}}
                                        >
                                            {localization.getString("delete")}
                                        </Button>
                                    </span>
                                </Tooltip>
                            ) : (
                                <Button
                                    type="button"
                                    onClick={() => setDeleteDialogOpen(true)}
                                    asElement={ButtonElement.BUTTON}
                                    buttonType={ButtonType.PRIMARY}
                                >
                                    {localization.getString("delete")}
                                </Button>
                            )}
                        </div>
                    </FormLabel>

                    <EditStatusDialog
                        service={{ ...edit, enabled }}
                        open={statusDialogOpen}
                        onClose={(success: boolean) => {
                            setStatusDialogOpen(false)
                            if (success) {
                                setEnabled((val) => !val)
                            }
                        }}
                    />
                    <DeleteDialog
                        service={edit}
                        open={deleteDialogOpen}
                        onClose={(success: boolean) => {
                            if (success) {
                                const isInfra = match.path === ROUTE.INFRASTRUCTURE_EDIT

                                history.push(isInfra ? ROUTE.INFRASTRUCTURE : ROUTE.HOSTED_WEBSITES)
                            } else {
                                setDeleteDialogOpen(false)
                            }
                        }}
                    />
                </>
            )}
        </FormSection>
    )
}

interface ServiceDetailsFormProps extends ServiceFormProps {
    hideCluster?: boolean
    enableFormField?: boolean
    hideDisableDelete?: boolean
    hideConnectOnLogin?: boolean
    showRdpPros?: boolean
}
