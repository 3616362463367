import React from "react"
import { Route, Switch } from "react-router-dom"

import { ROUTE } from "../../../../routes"
import { PublicResourceList } from "./list/PublicResourceList.component"
import { PublicResourceOverview } from "./overview/PublicResourceOverview.view"
import useTitle from "../../../../hooks/useTitle.hook"
import { useFeatureFlags } from "../../../../hooks/useFeatureFlags.hook"
import { Banner, Variant } from "../../../../components/banner/Banner.component"
import { Loader } from "../../../components/loader/Loader.component"
import { useServiceLocalization } from "../../../../pre-v3/services/localization/Localization.service"

export function PublicResource() {
    useTitle(["appDiscovery", "internetAccess", "adminConsole"])
    const { data: featureFlags, isLoading, isError, error: featureFlagError } = useFeatureFlags()
    const localization = useServiceLocalization()
    if (isLoading) {
        return <Loader center medium title={localization.getString("loading")} />
    }

    if (isError) {
        return <Banner label={String(featureFlagError)} variant={Variant.ERROR} />
    }
    return (
        <Switch>
            <Route path={ROUTE.APP_DISCOVERY_DETAILS}>
                <PublicResourceOverview
                    canUpdateCustomApp={featureFlags.adminConsole.canUpdateCustomApp}
                />
            </Route>
            <Route path={ROUTE.APP_DISCOVERY}>
                <PublicResourceList
                    canCreateCustomApp={featureFlags.adminConsole.canCreateCustomApp}
                />
            </Route>
        </Switch>
    )
}
