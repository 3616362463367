import React from "react"

import { useServiceLocalization } from "../../../pre-v3/services/localization/Localization.service"
import { StringUtil } from "../../../pre-v3/utils/String.util"
import { encodeID } from "../../../pre-v3/utils/Url.util"
import { ROUTE, formatRoutePath } from "../../../routes"
import { ButtonLink } from "../../components/button-link/ButtonLink.component"
import {
    Column,
    Grid,
    sortAlphabetically,
    sortByTimeStamp,
} from "../../components/grid/Grid.component"
import { Answer } from "../../services/SmartSearch.service"

interface Props {
    search?: string
    answers: Answer[]
}

export function SmartSearchGrid(props: Props): JSX.Element {
    const { search, answers } = props

    const localization = useServiceLocalization()
    const parseDate = useParseDate()

    if (answers.length <= 0) {
        return (
            <React.Fragment>
                {localization.getString("emptySmartSearchHistoryResult")}
            </React.Fragment>
        )
    }

    const promptColumn: Column<Answer> = {
        id: "prompt",
        name: localization.getString("prompt"),
        cellRenderer: "prompt",
        getTooltipValue: "prompt",
        sorting: sortAlphabetically("prompt"),
    }

    const dateColumn: Column<Answer> = {
        id: "date",
        name: localization.getString("date"),
        cellRenderer: parseDate,
        getTooltipValue: parseDate,
        sorting: sortByTimeStamp("createdAt"),
        width: 60,
    }

    const actionsColumn: Column<Answer> = {
        id: "actions",
        name: localization.getString("actions"),
        cellRenderer: (answer) => <ViewResultsButton answer={answer} />,
        getTooltipValue: () => localization.getString("viewResults"),
        width: 60,
    }

    const columns: Column<Answer>[] = [promptColumn, dateColumn, actionsColumn]

    const filteredAnswers = search
        ? answers.filter((answer) => StringUtil.caseInsensitiveIncludes(answer.prompt, search))
        : answers

    return <Grid columns={columns} data={filteredAnswers} hasPagination />
}

interface ViewResultsButtonProps {
    answer: Answer
}

function ViewResultsButton(props: ViewResultsButtonProps): JSX.Element {
    const localization = useServiceLocalization()

    return (
        <ButtonLink
            to={formatRoutePath(
                ROUTE.GET_HELP_SMART_SEARCH_RESULT,
                {},
                { answer: encodeID(props.answer.id) }
            )}
            aria-label={localization.getString("viewResultsForSomething", props.answer.prompt)}
        >
            {localization.getString("viewResults")}
        </ButtonLink>
    )
}

function useParseDate(): (answer: Answer) => string {
    const localization = useServiceLocalization()
    return (answer) => answer.createdAt.toLocaleString(localization.getLocale())
}
