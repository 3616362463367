import { LanguageKey } from "../../../pre-v3/services/localization/languages/en-US.language"

export enum TrustFactorType {
    APPLICATION_CHECK = "OrgPreferredAppsRunning",
    AUTO_UPDATE = "AutoUpdateEnabled",
    BANYAN_APP_VERSION = "BanyanAppVersion",
    DISK_ENCRYPTION = "DiskEncryptionEnabled",
    FILE_CHECK = "File Check",
    FIREWALL = "FirewallEnabled",
    NOT_ACTIVE_THREAT = "s1.activeThreats",
    NOT_JAILBROKEN = "NotJailbroken",
    OPERATING_SYSTEM_VERSION = "UpToDateOS",
    REGISTERED_WITH = "s1.registeredWith",
    REGISTRY_CHECK = "Registry Key",
    SCREEN_LOCK = "ScreenLockEnabled",
    ZTA_SCORE = "zta.score",
    CS_REGISTERED_WITH = "cs.registeredWith",
    PLIST = "Plist",
    CHROME_BROWSER_VERSION = "ChromeBrowserVersion",
    WS1_REGISTERED_WITH = "ws1.registeredWith",
    WS1_IS_COMPLIANT = "ws1.isCompliant",
    DEVICE_GEOLOCATION = "Device Geolocation",
}

export const nameDictionary: Record<TrustFactorType, LanguageKey> = {
    [TrustFactorType.APPLICATION_CHECK]: "applicationCheck",
    [TrustFactorType.AUTO_UPDATE]: "autoUpdate",
    [TrustFactorType.BANYAN_APP_VERSION]: "cseAppVersion",
    [TrustFactorType.DISK_ENCRYPTION]: "diskEncryption",
    [TrustFactorType.FILE_CHECK]: "fileCheck",
    [TrustFactorType.FIREWALL]: "firewall",
    [TrustFactorType.NOT_ACTIVE_THREAT]: "notActiveThreat",
    [TrustFactorType.NOT_JAILBROKEN]: "notJailbroken",
    [TrustFactorType.OPERATING_SYSTEM_VERSION]: "operatingSystemVersion",
    [TrustFactorType.REGISTERED_WITH]: "registeredWith",
    [TrustFactorType.REGISTRY_CHECK]: "registryCheck",
    [TrustFactorType.SCREEN_LOCK]: "screenLock",
    [TrustFactorType.ZTA_SCORE]: "ztaScore",
    [TrustFactorType.PLIST]: "propertyListCheck",
    [TrustFactorType.CS_REGISTERED_WITH]: "registeredWith",
    [TrustFactorType.CHROME_BROWSER_VERSION]: "chromeBrowserVersion", ////We are configuring only chrome in this release. We will extend to more browsers later
    [TrustFactorType.WS1_REGISTERED_WITH]: "registeredWith",
    [TrustFactorType.WS1_IS_COMPLIANT]: "isCompliant",
    [TrustFactorType.DEVICE_GEOLOCATION]: "deviceGeoLocation",
}

export const descriptionDictionary: Record<TrustFactorType, LanguageKey> = {
    [TrustFactorType.APPLICATION_CHECK]: "applicationCheckDescription",
    [TrustFactorType.AUTO_UPDATE]: "autoUpdateDescription",
    [TrustFactorType.BANYAN_APP_VERSION]: "cseAppVersionDescription",
    [TrustFactorType.DISK_ENCRYPTION]: "diskEncryptionDescription",
    [TrustFactorType.FILE_CHECK]: "fileCheckDescription",
    [TrustFactorType.FIREWALL]: "firewallDescription",
    [TrustFactorType.NOT_ACTIVE_THREAT]: "notActiveThreatDescription",
    [TrustFactorType.NOT_JAILBROKEN]: "notJailbrokenDescription",
    [TrustFactorType.OPERATING_SYSTEM_VERSION]: "operatingSystemVersionDescription",
    [TrustFactorType.REGISTERED_WITH]: "registeredWithDescription",
    [TrustFactorType.REGISTRY_CHECK]: "registryCheckDescription",
    [TrustFactorType.SCREEN_LOCK]: "screenLockDescription",
    [TrustFactorType.ZTA_SCORE]: "ztaScoreDescription",
    [TrustFactorType.PLIST]: "satisfiedIfPropertyListExistsAndOrkeysMatchExpectedValues",
    [TrustFactorType.CS_REGISTERED_WITH]: "registeredWithDescription",
    [TrustFactorType.CHROME_BROWSER_VERSION]: "chromeBrowserVersionDesc",
    [TrustFactorType.WS1_REGISTERED_WITH]: "registeredWithDescription",
    [TrustFactorType.WS1_IS_COMPLIANT]: "isCompliantDescription",
    [TrustFactorType.DEVICE_GEOLOCATION]: "deviceGeoLocationDescText",
}

const trustFactorTypes = Object.values(TrustFactorType)

export function isTrustFactorType(type: string): type is TrustFactorType {
    return trustFactorTypes.includes(type as TrustFactorType)
}
