import React from "react"

import { useServiceLocalization } from "../../../pre-v3/services"
import { ROUTE } from "../../../routes"
import { Link } from "../../components/link/Link.component"
import styles from "./Login.module.scss"

export function SwitchOrgMessage(): JSX.Element {
    const localization = useServiceLocalization()

    return (
        <p className={styles.textAlignCenter}>
            {localization.replaceJSX("loginToADifferentOrgSwitchOrg", <OrgFormLink />)}
        </p>
    )
}

function OrgFormLink(props: React.PropsWithChildren<{}>): JSX.Element {
    return <Link to={ROUTE.ORG_FORM}>{props.children}</Link>
}
