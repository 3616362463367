import React from "react"

import { ServiceAppType, ServiceManage, ServiceType } from "../../../services"
import ServiceForm, { useMetadata, useUpdateAttributes, SubmitHandler } from "../service-form"
import K8sServiceForm from "./k8s"
import GenericServiceForm from "./generic"
import RDPServiceForm from "./rdp"
import SSHServiceForm from "./ssh"
import WebServiceForm from "./web"
import CustomServiceForm from "./custom"

// the ServiceForm component is responsible for showing the correct form for
// a given type of service. If `edit` is passed, it will show the form modifying
// the provided Service
export default function HostedServiceForm({
    appType,
    type,
    edit,
    enableFormField,
    onSubmit,
    hideBackend,
}: {
    appType: ServiceAppType
    type: ServiceType
    edit?: ServiceManage
    onSubmit: SubmitHandler
    enableFormField?: boolean
    hideBackend?: boolean
}) {
    let content
    if (type === ServiceType.CUSTOM) {
        content = <CustomServiceForm edit={edit} />
    } else if (appType === ServiceAppType.K8S) {
        content = <K8sServiceForm edit={edit} />
    } else if (appType === ServiceAppType.RDP) {
        content = <RDPServiceForm edit={edit} hideBackend={hideBackend} />
    } else if (appType === ServiceAppType.SSH) {
        content = <SSHServiceForm edit={edit} hideBackend={hideBackend} />
    } else if (appType === ServiceAppType.WEB) {
        content = <WebServiceForm edit={edit} />
    } else {
        content = <GenericServiceForm edit={edit} hideBackend={hideBackend} />
    }

    return (
        <ServiceForm
            edit={edit}
            enableFormField={enableFormField}
            onSubmit={onSubmit}
            type={type}
            appType={appType}
        >
            {type !== ServiceType.CUSTOM && <HostedServiceData />}
            {content}
        </ServiceForm>
    )
}

// a component we can embed in the context to add the hosted service-specific info
function HostedServiceData() {
    const setAttributes = useUpdateAttributes()
    const metaData = useMetadata()

    // some global state for all the forms
    React.useEffect(() => {
        setAttributes((attr) => ({
            ...attr,

            attributes: {
                ...attr.attributes,
                frontend_addresses: [{ cidr: "", port: metaData.tags.port! }],
                tls_sni: metaData.tags.domain ? [metaData.tags.domain] : [],
            },
            cert_settings: {
                ...attr.cert_settings,
                dns_names: metaData.tags.domain ? [metaData.tags.domain] : [],
            },
        }))
    }, [metaData.tags.domain, metaData.tags.port, setAttributes])

    return null
}
