import React, { ReactNode } from "react"
import NavButtonTemplate from "./NavButton.template"
import { Bind } from "../../decorators/Bind.decorator"

export class NavButton extends React.Component<React.PropsWithChildren<NavButtonProps>, {}> {
    public render(): ReactNode {
        return NavButtonTemplate.call(this)
    }

    @Bind
    private isActive(a: null, location: Location): boolean {
        if (!this.props.href) {
            return false
        }

        const currentPath: string[] = this.props.href.split("/")
        const buttonPath: string[] = location.pathname.split("/")

        if (!currentPath[0]) {
            currentPath.shift()
        }
        if (!buttonPath[0]) {
            buttonPath.shift()
        }

        if (currentPath.length >= 2 && buttonPath.length >= 2) {
            if (currentPath[0] === "app") {
                return currentPath[0] === buttonPath[0] && currentPath[1] === buttonPath[1]
            } else if (currentPath[0] === "ng") {
                return currentPath[0] === buttonPath[0]
            }
        }
        return false
    }
}

export interface NavButtonProps {
    exact?: boolean
    href: string
    className?: string
    ariaLabel?: string
}
