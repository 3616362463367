import React, { Fragment } from "react"
import styles from "./TrustProviderMdmConfigSettings.module.scss"
import {
    Button,
    ButtonElement,
    ButtonType,
} from "../../../../../../components/button/Button.component"
import { Banner, BannerType } from "../../../../../components/banner/Banner.component"
import { InputWithCopy } from "../../../../../components/input-with-copy/InputWithCopy"
import { ConfirmationDialog } from "../../../../../components/dialog/confirmation/ConfirmationDialog.component"
import { Select } from "../../../../../components/select/Select.component"
import { PageBreak } from "../../../../../components/page-break/PageBreak.component"
import { InfoBanner, ErrorBanner } from "../../../../../components"
import { AppText } from "../../../../../../v3/components/app-text/AppText.component"

export default function () {
    return (
        <Fragment>
            <PageBreak className={styles.pageBreak}>
                {this.ls.getString("otpBasedEmailVerification")}
            </PageBreak>
            <div className={styles.form}>
                {this.ls.getString("otpBasedEmailVerificationDescription")}
                {this.state.otpError && (
                    <Banner className={styles.marginTop} type={BannerType.ERROR}>
                        {this.ls.getString("errorUpdatingOtpSkipRole")}
                    </Banner>
                )}
                {this.state.otpSuccess && (
                    <Banner className={styles.marginTop} type={BannerType.INFO}>
                        {this.ls.getString("otpSkipRoleUpdated")}
                    </Banner>
                )}
                <Select
                    className={styles.marginTop}
                    options={this.state.otpSkipRoleOptions}
                    value={this.state.otpSkipRole}
                    onChange={this.onUpdateOtpSkipRole}
                    noneOption={this.ls.getString("none")}
                    placeholder={this.ls.getString("none")}
                    disabled={!this.props.canUpdateMdmSetting}
                />
            </div>
            <PageBreak className={styles.pageBreak}>
                <div className={styles.flexRow}>
                    {this.ls.getString("zeroTouchDeploymentUsingADeviceManager")}
                </div>
            </PageBreak>
            <div className={styles.form}>
                <AppText
                    ls={{
                        key: "useADeploymentKeyToEnableAZeroTouchDeployment",
                        replaceVals: [this.linkService.getLink("deploymentKeyDocs")],
                    }}
                />
                {this.state.success && (
                    <ErrorBanner className={styles.marginTop}>
                        {this.state.success + " "}
                    </ErrorBanner>
                )}
                {this.state.error && (
                    <InfoBanner className={styles.marginTop}>{this.state.error} </InfoBanner>
                )}
                {this.state.deployKey ? (
                    <div>
                        <InputWithCopy
                            value={this.state.deployKey}
                            className={styles.marginTop}
                            hideCopyButton={!this.props.canUpdateMdmSetting}
                        />
                        {this.props.canUpdateMdmSetting && (
                            <Button
                                loading={this.state.deployKeyLoading}
                                onClick={this.onRevokeDeploymentKey}
                                buttonType={ButtonType.DESTRUCTIVE}
                                buttonElement={ButtonElement.BUTTON}
                                className={styles.marginTop}
                            >
                                {this.ls.getString("revokeMdmDeploymentKey")}
                            </Button>
                        )}
                    </div>
                ) : (
                    <div>
                        {this.props.canUpdateMdmSetting && (
                            <Button
                                loading={this.state.deployKeyLoading}
                                onClick={this.onGenerateDeploymentKey}
                                buttonType={ButtonType.PRIMARY}
                                asElement={ButtonElement.BUTTON}
                                className={styles.marginTop}
                            >
                                {this.ls.getString("generateMdmDeploymentKey")}
                            </Button>
                        )}
                    </div>
                )}
            </div>
            <ConfirmationDialog
                open={this.state.showConfirmDialog}
                title={this.ls.getString("deleteMdmDeploymentKey")}
                confirmLabel={this.ls.getString("delete")}
                onClose={this.onHideConfirmDialog}
                onConfirm={this.onSubmitRevokeDeploymentKey}
            >
                <p>{this.ls.getString("areYouSureYouWantToPerformThisAction")}</p>
                <p>{this.ls.getString("deletingAnMdmKeyCannotBeUndoneDescription")}</p>
            </ConfirmationDialog>
        </Fragment>
    )
}
