import React from "react"
import { useLocation } from "react-router-dom"

import { useServiceLocalization } from "../../../../pre-v3/services/localization/Localization.service"
import { FilterModel } from "../../../../pre-v3/utils/AgGrid.util"
import { UrlUtil } from "../../../../pre-v3/utils/Url.util"
import { GridApi } from "../../../components/grid/Grid.component"
import {
    FilterById,
    useGetNetworks,
    useGetPrivateResources,
    useGetServiceTunnels,
    useGetUsers,
} from "../../../services/PrivateResource.service"
import { PrivateResourceFilterBar } from "./PrivateResourceFilterBar.component"
import { PrivateResourceGrid } from "./PrivateResourceGrid.component"
import styles from "./PrivateResourceList.module.scss"
import { PageHeading } from "../../../../components/page-heading/PageHeading.component"
import { Button, ButtonElement, ButtonType } from "../../../../components/button/Button.component"
import { IconType } from "../../../../components/icon/Icon.component"
import { Tooltip } from "../../../components/tooltip/Tooltip.component"

export function PrivateResourceList(): JSX.Element {
    const location = useLocation()
    const localization = useServiceLocalization()

    const { getPrivateResources, clearCache: clearPrivateResourcesCache } = useGetPrivateResources()
    const { getNetworks, clearCache: clearNetworksCache } = useGetNetworks()
    const { data: serviceTunnels = [], refetch: refetchServiceTunnels } = useGetServiceTunnels()
    const { getUsers, clearCache: clearUsersCache } = useGetUsers()

    const gridRef = React.useRef<GridApi>(null)

    const [filterModel, setFilterModel] = React.useState(
        UrlUtil.readFilter<FilterById>(location.search)
    )

    const onFilter = (newFilterModel: FilterModel<FilterById>): void => {
        setFilterModel(newFilterModel)
        UrlUtil.writeFilter(newFilterModel)
    }

    const onRefresh: React.MouseEventHandler<HTMLButtonElement> = async (event): Promise<void> => {
        event.preventDefault()
        await Promise.all([
            clearPrivateResourcesCache(),
            clearNetworksCache(),
            clearUsersCache(),
            refetchServiceTunnels(),
        ])
        gridRef.current?.refreshData()
    }

    return (
        <section aria-labelledby={Id.HEADING} className={styles.sectionContainer}>
            <header className={styles.header}>
                <PageHeading id={Id.HEADING}>{localization.getString("discovery")}</PageHeading>
                <Tooltip title={localization.getString("refresh")}>
                    <Button
                        icon={IconType.REDO}
                        onClick={onRefresh}
                        asElement={ButtonElement.BUTTON}
                        buttonType={ButtonType.SECONDARY}
                        aria-label={localization.getString("refresh")}
                    />
                </Tooltip>
            </header>
            <section className={styles.container}>
                <PrivateResourceFilterBar
                    filterModel={filterModel}
                    onFilter={onFilter}
                    getNetworks={getNetworks}
                    getUsers={getUsers}
                    serviceTunnels={serviceTunnels}
                />
                <PrivateResourceGrid
                    filterModel={filterModel}
                    getPrivateResources={getPrivateResources}
                    ref={gridRef}
                />
            </section>
        </section>
    )
}

enum Id {
    HEADING = "heading",
}
