import classNames from "classnames/bind"
import React, { FormEvent, useEffect, useState } from "react"
import QRCode from "react-qr-code"
import { useHistory, useLocation } from "react-router-dom"

import { useAuthService } from "../../../../pre-v3/services/Auth.service"
import { useServiceLinks } from "../../../../pre-v3/services/link/Link.service"
import { useServiceLocalization } from "../../../../pre-v3/services/localization/Localization.service"
import { FormUtil } from "../../../../pre-v3/utils/Form.util"
import { ROUTE } from "../../../../routes"
import { AppText } from "../../../components/app-text/AppText.component"
import { ErrorBanner } from "../../../components/banner/Banner.component"
import { Button } from "../../../components/button/Button.component"
import { TextWithCopy } from "../../../components/text-with-copy/TextWithCopy.component"
import { useGenerateMfa, useValidateMfa, useVerifyMfa } from "../../../services/Login.service"
import styles from "../Login.module.scss"

export function ConfigureMfa() {
    const location = useLocation<{ token: string }>()
    const history = useHistory()
    const authService = useAuthService()
    const ls = useServiceLocalization()
    const linkService = useServiceLinks()

    const [error, setError] = useState<boolean>(false)
    const [token, setToken] = useState<string>("")

    useEffect(() => {
        if (!location.state?.token) {
            setError(true)
        } else {
            setToken(location.state.token)
        }
    }, [location])

    const mfa = useGenerateMfa(token, {
        enabled: !!token,
    })

    const verifyMfa = useVerifyMfa({
        onSuccess: (_data, variables) => {
            validateMfa.mutateAsync(variables!).then((token: string) => {
                authService.setLogin(token)
                history.push(authService.getLoginUrl())
            })
        },
    })

    const validateMfa = useValidateMfa()

    function goBack(): void {
        if (history.length > 1) {
            history.goBack()
        } else {
            history.push(ROUTE.LOGIN)
        }
    }

    function onSubmit(event: FormEvent<HTMLFormElement>): void {
        event.preventDefault()

        const otp: string = FormUtil.getFieldValue(event, "otp")
        verifyMfa.mutate({ otp, token })
    }

    if (error) {
        return (
            <div className={styles.form}>
                <p className={styles["space-below"]}>{ls.getString("mfaStateError")}</p>
                <Button brand onClick={goBack}>
                    {ls.getString("goBack")}
                </Button>
            </div>
        )
    }

    return (
        <form className={styles.form} onSubmit={onSubmit}>
            <AppText
                ls={{
                    key: "yourOrganizationRequiresOneTimePasscodeDescription",
                    replaceVals: [linkService.getLink("oneTimePasscode")],
                }}
            />
            <QRCode className={styles.alignCenter} value={mfa.data?.qrCode || ""} size={128} />
            <span className={classNames(styles.textAlignCenter, styles.verticalMargin)}>
                {ls.getString("or")}
            </span>
            <TextWithCopy className={styles["space-below"]} value={mfa.data?.code || ""} />
            <AppText className={styles.textAlignCenter} ls="oneTimePasscode" />
            <input
                name="otp"
                type="text"
                className={styles["space-below"]}
                required
                placeholder={ls.getString("enterOneTimePasscode")}
            />
            {validateMfa.error && (
                <ErrorBanner className={styles["space-below"]}>{validateMfa.error}</ErrorBanner>
            )}
            {verifyMfa.error && (
                <ErrorBanner className={styles["space-below"]}>{verifyMfa.error}</ErrorBanner>
            )}
            <Button
                className={styles.submitButton}
                brand
                loading={validateMfa.isLoading || verifyMfa.isLoading}
                type="submit"
            >
                {ls.getString("continue")}
            </Button>
        </form>
    )
}
