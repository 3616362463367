import * as qs from "query-string"
import React from "react"
import { RouteComponentProps } from "react-router"
import { useHistory, useRouteMatch } from "react-router-dom"

import { ROUTE, formatRoutePath } from "../../../../routes"
import {
    ServiceAppType,
    ServiceType,
    useActionBar,
    useServiceLocalization,
    useServiceManage,
} from "../../../services"
import HostedServiceForm from "../service-form-hosted"
import { SubmitHandler } from "../service-form"
import { SpecKind } from "../../../api/Secure.api"
import { encodeID } from "../../../utils/Url.util"
import { PageHeading } from "../../../../components/page-heading/PageHeading.component"
import styles from "./ServicesAdd.module.scss"
import classnames from "classnames"

type EditServiceProps = RouteComponentProps

export default function AddServiceForm({ location }: EditServiceProps) {
    // grab the service references we need
    const localization = useServiceLocalization()

    // generate the submit handler for the form
    const onSubmit = useOnSubmit()

    // pull out the type and app type for the service
    let appType = (qs.parse(location.search)["appType"] || "") as ServiceAppType
    if (!ServiceAppType[appType as keyof typeof ServiceAppType]) {
        appType = ServiceAppType.GENERIC
    }
    let type = (qs.parse(location.search)["type"] || "") as ServiceType
    if (!ServiceType[type as keyof typeof ServiceType]) {
        type = ServiceType.CUSTOM
    }

    // figure out the title to use
    let title = localization.getString("customServiceJson")
    if (appType === ServiceAppType.K8S) {
        title = localization.getString("kubernetesService")
    } else if (appType === ServiceAppType.RDP) {
        title = localization.getString("rdpService")
    } else if (appType === ServiceAppType.SSH) {
        title = localization.getString("sshService")
    } else if (appType === ServiceAppType.WEB) {
        title = localization.getString("webService")
    } else if (appType === ServiceAppType.DATABASE) {
        title = localization.getString("databaseService")
    } else if (type === ServiceType.TCP_USER) {
        title = localization.getString("tcpService")
    }

    // make sure the action bar is up to date
    useActionBar({
        title,
        items: [
            {
                label: localization.getString("privateAccess"),
                href: "",
            },
            {
                label:
                    type === ServiceType.WEB_USER
                        ? localization.getString("hostedWebsites")
                        : localization.getString("infrastructureServices"),
                href: type === ServiceType.WEB_USER ? ROUTE.HOSTED_WEBSITES : ROUTE.INFRASTRUCTURE,
            },
        ],
    })

    const classes = classnames(styles.container, "pre-v3")

    return (
        <div aria-labelledby={Id.HEADING} className={classes}>
            <header>
                <PageHeading id={Id.HEADING}>{title}</PageHeading>
            </header>
            <section>
                <HostedServiceForm onSubmit={onSubmit} appType={appType} type={type} />
            </section>
        </div>
    )
}

// onSubmit is called when the form is submitted
const useOnSubmit: () => SubmitHandler = () => {
    const manageService = useServiceManage()
    const history = useHistory()
    const match = useRouteMatch()

    const isInfra = match.path === ROUTE.INFRASTRUCTURE_ADD

    return async ({ metaData, attributes, policyEnabled, policyID }): Promise<void> => {
        const service = await manageService.createRegisteredService({
            metadata: metaData,
            type: SpecKind.USER,
            attributes,
            policyId: policyID,
            enabled: policyEnabled,
        })

        // the target URL
        const targetURL = isInfra ? ROUTE.INFRASTRUCTURE_DETAILS : ROUTE.HOSTED_WEBSITES_DETAILS

        history.push(formatRoutePath(targetURL, { id: encodeID(service.id) }))
    }
}

enum Id {
    HEADING = "heading",
}
