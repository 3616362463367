import React, { Fragment } from "react"
import styles from "./IconSelect.module.scss"
import { Button } from "../button/Button.component"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import classNames from "classnames/bind"
import { Toolbar } from "../toolbar/Toolbar.component"
import { Dialog, DialogTitle, DialogContent } from "@mui/material"

export default function () {
    return (
        <div>
            {this.state.selectedIcon ? (
                <Fragment>
                    <div className={styles.selectedIconContainer}>
                        <FontAwesomeIcon
                            className={styles.selectedIcon}
                            icon={this.state.selectedIcon}
                        />
                        <div className={styles.iconName}>{this.state.selectedIcon.iconName}</div>
                    </div>
                    <Button
                        className={styles.button}
                        onClick={this.onShowDialog}
                        disabled={this.props.disabled}
                    >
                        {this.localizationService.getString("changeIcon")}
                    </Button>
                </Fragment>
            ) : (
                <Button
                    className={styles.button}
                    onClick={this.onShowDialog}
                    disabled={this.props.disabled}
                >
                    {this.localizationService.getString("selectAnIcon")}
                </Button>
            )}
            {this.state.showDialog && (
                <Dialog
                    open={this.state.showDialog}
                    maxWidth="md"
                    onClose={this.onHideDialog}
                    classes={dialogClasses}
                >
                    <DialogTitle>{this.localizationService.getString("selectAnIcon")}</DialogTitle>
                    <DialogContent>
                        <Toolbar className={styles.toolbar} onSearchChange={this.onFilter} />
                        <div className={styles.content}>
                            {this.state.icons &&
                                this.state.icons.map((icon) => (
                                    <div
                                        key={icon.iconName}
                                        className={classNames(styles.iconContainer, {
                                            [styles.iconHighlight]:
                                                this.state.selectedIcon &&
                                                this.state.selectedIcon.iconName === icon.iconName,
                                        })}
                                        onClick={() => {
                                            this.onIconSelected(icon)
                                        }}
                                    >
                                        <FontAwesomeIcon className={styles.icon} icon={icon} />
                                        <p className={styles.iconName}>{icon.iconName}</p>
                                    </div>
                                ))}
                        </div>
                    </DialogContent>
                </Dialog>
            )}
        </div>
    )
}

const dialogClasses = { container: styles.container, paper: styles.paper }
