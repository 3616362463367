import React from "react"

import { GeolocationTrustFactor } from "../../../../../../../services/TrustProfile.service"
import { useServiceLocalization } from "../../../../../../../../pre-v3/services/localization/Localization.service"
import styles from "./DeviceGeolocation.module.scss"
import classNames from "classnames/bind"
import { PillMultiSelect } from "../../../../../../../components/pill-multi-select/PillMultiSelect.component"
import { useGetCountries } from "../../../../../../../services/DeviceGeoLocation.service"
import { FormRow } from "../../../../../../../../components/form/FormRow.component"
import { ToggleButton } from "../../../../../../../components/toggle-button/ToggleButton"
import { Banner, Variant } from "../../../../../../../../components/banner/Banner.component"
import { IconType } from "../../../../../../../../components/button/Button.component"

interface Props {
    trustFactor: GeolocationTrustFactor
    className?: string
    onEdit(trustFactor: GeolocationTrustFactor): void
    disabled?: boolean
}

export function DeviceGeolocation(props: Props): JSX.Element {
    const ls = useServiceLocalization()
    const {
        data: countryData,
        isLoading: isCountryDataLoading,
        error: countryDataError,
    } = useGetCountries()

    const [allowUnknownGeoCountry, setAllowUnknownGeoCountry] = React.useState<string>(
        props.trustFactor.config.IS_ALLOW_COUNTRY || "false"
    )

    function onPillDataChange(country: string[]) {
        props.onEdit({
            ...props.trustFactor,
            config: {
                ...props.trustFactor.config,
                BLOCK_COUNTRY_LIST: country.toString(),
            },
        })
    }

    function onToggleChange(value: boolean) {
        setAllowUnknownGeoCountry(value.toString())
        props.onEdit({
            ...props.trustFactor,
            config: {
                ...props.trustFactor.config,
                IS_ALLOW_COUNTRY: value.toString(),
            },
        })
    }

    return (
        <div className={classNames(styles.outerContainer, props.className)}>
            {typeof countryDataError === "string" ? (
                <Banner label={countryDataError} variant={Variant.ERROR} />
            ) : (
                <>
                    <PillMultiSelect
                        label={ls.getString(
                            "selectCountriesInWhichTheDeviceWillFailTheTrustFactor"
                        )}
                        placeholder={ls.getString("selectCountries")}
                        options={
                            countryData?.countries?.map((countryName) => {
                                return {
                                    label: countryName,
                                    value: countryName,
                                }
                            }) || []
                        }
                        value={
                            props.trustFactor.config.BLOCK_COUNTRY_LIST.length
                                ? props.trustFactor.config.BLOCK_COUNTRY_LIST.split(",")
                                : []
                        }
                        onChange={onPillDataChange}
                        className={styles.attachRoles}
                        icon={IconType.PLUS}
                        disabled={props.disabled}
                        loading={isCountryDataLoading}
                    />
                    <FormRow
                        label={ls.getString("satisfyTrustFactorIfDeviceLocationCannotBeDetermined")}
                    >
                        <ToggleButton
                            disabled={props.disabled}
                            items={[
                                {
                                    label: ls.getString("enable"),
                                    onClick: () => {
                                        onToggleChange(true)
                                    },
                                    selected: allowUnknownGeoCountry === "true",
                                },
                                {
                                    label: ls.getString("disable"),
                                    onClick: () => {
                                        onToggleChange(false)
                                    },
                                    selected: allowUnknownGeoCountry === "false",
                                },
                            ]}
                        />
                    </FormRow>
                </>
            )}
        </div>
    )
}
