import React from "react"
import { Input } from "../../../../../../v3/components/input/Input.component"
import styles from "./TrustProviderInviteCodeSettings.module.scss"
import { ErrorBanner, InfoBanner, Form, FormRow, FormLabel } from "../../../../../components"
import { ConfirmationDialog } from "../../../../../components/dialog/confirmation/ConfirmationDialog.component"
import { OrgEdition } from "../../../../../services"
import {
    Button,
    ButtonElement,
    ButtonType,
} from "../../../../../../components/button/Button.component"

export default function () {
    const disabled = this.state.edition === OrgEdition.TEAM
    return (
        <Form onSubmit={this.onSubmit} floating>
            <FormRow>
                {this.state.error && <ErrorBanner>{this.state.error}</ErrorBanner>}
                {this.state.success && <InfoBanner>{this.state.success}</InfoBanner>}
            </FormRow>
            <FormLabel
                title={this.localizationService.getString("inviteCodeRequiredDescription")}
                htmlFor="inviteCode"
            >
                <Input
                    id="inviteCode"
                    name="inviteCode"
                    defaultValue={this.state.inviteCode}
                    className={styles.formInput}
                    required
                    disabled={disabled || !this.props.canUpdateInviteCode}
                    placeholder={this.localizationService.getString("inviteCode")}
                />
            </FormLabel>
            {this.props.canUpdateInviteCode && !disabled && (
                <Button
                    buttonType={ButtonType.PRIMARY}
                    asElement={ButtonElement.BUTTON}
                    loading={this.state.loading}
                    type="submit"
                >
                    {this.localizationService.getString("updateInviteCode")}
                </Button>
            )}
            <ConfirmationDialog
                open={this.state.showConfirmDialog}
                title={this.localizationService.getString("cseDesktopAndMobileApps")}
                confirmLabel={this.localizationService.getString("continue")}
                onClose={this.onHideConfirmDialog}
                onConfirm={this.onUpdateInviteCode}
            >
                <p>{this.localizationService.getString("inviteCodeConfirmExplanation")}</p>
            </ConfirmationDialog>
        </Form>
    )
}
