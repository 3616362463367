import * as React from "react"
import { useHtmlFor } from ".."

interface Props extends React.InputHTMLAttributes<HTMLInputElement> {
    validate?: ((value: string) => string | null | undefined | false) | false
    onValidateChange?: (value: boolean) => void | Promise<void>
}

/**
 * @deprecated Please use src/v3/components/input/Input.component.tsx
 */
export const Input = React.forwardRef(function Input(
    { onChange, onFocus, validate, onValidateChange, ...props }: Props,
    parentRef: React.Ref<HTMLInputElement>
) {
    const htmlFor = useHtmlFor()
    const ref = React.useRef<HTMLInputElement>(
        null
    ) as React.MutableRefObject<HTMLInputElement | null>
    const [valid, setValid] = React.useState(true)

    // the function to call that validates the input
    const validateValue = (e: React.ChangeEvent<HTMLInputElement>) => {
        // if there is a validation rule, check it
        if (validate && e.target.value) {
            const validationError = validate(e.target.value)
            if (validationError && ref.current) {
                ref.current.setCustomValidity(validationError)
                setValid(false)
            } else {
                ref.current?.setCustomValidity("")
                setValid(true)
            }
        }
    }

    // when the validation state changes, invoke the callback
    React.useEffect(() => {
        onValidateChange?.(valid)
    }, [valid, onValidateChange])

    return (
        <input
            type="text"
            name={htmlFor}
            id={htmlFor}
            {...props}
            ref={(e) => {
                if (typeof parentRef === "function") {
                    parentRef(e)
                }

                ref.current = e
            }}
            data-test-id={htmlFor}
            onChange={(e) => {
                onChange?.(e)
                validateValue(e)
            }}
            onFocus={(e) => {
                // make sure we bubble up
                onFocus?.(e)
                validateValue(e)
            }}
        />
    )
})
