import { ColDef, GridApi, GridReadyEvent } from "ag-grid-community"
import React, { useRef, useState } from "react"
import { Link } from "react-router-dom"
import { Grid } from "../../../pre-v3/components/grid/Grid.component"
import {
    LocalizationService,
    useServiceLocalization,
} from "../../../pre-v3/services/localization/Localization.service"
import { useActionBar } from "../../../pre-v3/services/ActionBar.service"
import AgGridUtil from "../../../pre-v3/utils/AgGrid.util"
import { ErrorBanner, MenuItem } from "../../../pre-v3/components"
import { Toolbar } from "../../../pre-v3/components/toolbar/Toolbar.component"
import { MenuButton } from "../../../v3/components/menu/menu-button/MenuButton.component"
import { SelectItem } from "../../../pre-v3/utils/SelectValue.util"
import { faFilter } from "@fortawesome/pro-solid-svg-icons"
import classNames from "classnames/bind"
import styles from "./OnPremGatewayList.module.scss"
import { OnPremGatewayStatus, OnPremGatewayService, useServiceOnPremGateway } from "../svc"
import { StatusCellRenderer } from "../cell-renderers/status/StatusCellRenderer"
import { IconButton } from "../../../pre-v3/components/icon-button/IconButton.component"
import { faPlus } from "@fortawesome/pro-light-svg-icons"
import { PageHeading } from "../../../components/page-heading/PageHeading.component"
import {
    Button,
    ButtonElement,
    ButtonType,
    IconType,
} from "../../../components/button/Button.component"

export function OnPremGatewayList() {
    const ls: LocalizationService = useServiceLocalization()
    const [statusFilterLabel, setStatusFilterLabel] = React.useState<string>(
        OnPremGatewayStatus.ALL_STATUSES
    )
    const OnPremGatewayService: OnPremGatewayService = useServiceOnPremGateway()

    const columnDefs: ColDef[] = [
        {
            headerName: ls.getString("status"),
            field: "status",
            flex: 90,
            cellRenderer: "statusCellRenderer",
            filter: "agSetColumnFilter",
            comparator: AgGridUtil.alphaBetComparator,
        },
        {
            headerName: "Name",
            field: "name",
            tooltipValueGetter: AgGridUtil.linkTooltipValueGetter,
            flex: 250,
            cellRenderer: "nameCellRenderer",
            comparator: AgGridUtil.alphaBetComparator,
            filter: "agSetColumnFilter",
        },
        {
            headerName: "Connection Type",
            field: "connection_type",
            tooltipValueGetter: AgGridUtil.linkTooltipValueGetter,
            flex: 120,
            comparator: AgGridUtil.alphaBetComparator,
            filter: "agSetColumnFilter",
        },
        {
            headerName: "Brand",
            field: "spec.brand",
            flex: 120,
        },
        {
            headerName: "Public Address",
            field: "spec.public_ip_fqdn",
            flex: 120,
        },
    ]

    const statusFilterOptions: SelectItem[] = [
        {
            displayName: ls.getString("allStatuses"),
            value: "",
        },
        {
            displayName: OnPremGatewayStatus.REPORTING,
            value: OnPremGatewayStatus.REPORTING,
        },
        {
            displayName: OnPremGatewayStatus.PENDING,
            value: OnPremGatewayStatus.PENDING,
        },
        {
            displayName: OnPremGatewayStatus.TERMINATED,
            value: OnPremGatewayStatus.TERMINATED,
        },
    ]

    const gridApi = useRef<GridApi>()
    const [error, setError] = useState<string>("")

    function onGridReady(event: GridReadyEvent) {
        gridApi.current = event.api
        fetchData()
    }

    const onStatusFilterChange = (filter: SelectItem) => (): void => {
        if (!gridApi.current) {
            return
        }

        gridApi.current.getFilterInstance("status")?.setModel({
            type: "equals",
            filter:
                !filter.value || filter.value === OnPremGatewayStatus.ALL_STATUSES
                    ? ""
                    : filter.value,
        })

        gridApi.current.onFilterChanged()

        setStatusFilterLabel(filter.displayName)
    }

    const onFilter = (value: string): void => {
        gridApi.current?.setQuickFilter(value)
    }

    const fetchData = () => {
        if (gridApi.current) {
            setError("")
            gridApi.current.setRowData(OnPremGatewayService.getOnPremGateways())
        }
    }

    useActionBar({
        title: "On-prem Gateways",
        items: [
            {
                label: "Global Edge Network",
                href: "",
            },
        ],
        fetchData,
        actions: [],
    })

    const openModal = () => {}

    return (
        <section aria-labelledby="gateway-heading" className={styles.section}>
            <header className={styles.header}>
                <PageHeading id="gateway-heading">On-prem Gateways</PageHeading>
                <Button
                    icon={IconType.REDO}
                    onClick={fetchData}
                    asElement={ButtonElement.BUTTON}
                    buttonType={ButtonType.SECONDARY}
                    aria-label="Refresh"
                />
            </header>
            {error && <ErrorBanner>{error}</ErrorBanner>}
            <Toolbar onSearchChange={onFilter}>
                <MenuButton
                    className={styles.filterButton}
                    label={statusFilterLabel}
                    icon={faFilter}
                >
                    {statusFilterOptions.map((filter: SelectItem) => (
                        <MenuItem onClick={onStatusFilterChange(filter)} key={filter.value}>
                            {filter.displayName}
                        </MenuItem>
                    ))}
                </MenuButton>
                <IconButton icon={faPlus} brand label="Add On-prem Gateway" onClick={openModal} />
            </Toolbar>
            <Grid
                onGridReady={onGridReady}
                columnDefs={columnDefs}
                pagination
                components={{
                    nameCellRenderer: NameCellRenderer,
                    statusCellRenderer: StatusCellRenderer,
                }}
            />
        </section>
    )
}

function NameCellRenderer(props: any) {
    if (props.data && props.data.id) {
        return (
            <Link
                className={classNames(styles.nameLink)}
                to={"/admin-console/secure/dlp-policies/:id".replace(":id", props.data.id)}
            >
                {props.data.name ? props.data.name : props.data.id}
            </Link>
        )
    } else {
        return (
            <span>{props.data.resourceName ? props.data.resourceName : props.data.resourceId}</span>
        )
    }
}
