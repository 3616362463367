import React from "react"
import { useServiceLinks, useServiceLocalization } from "../../../../../pre-v3/services"
import { AppText } from "../../../../components/app-text/AppText.component"
import { CodeWithCopy } from "../../../../components/code-with-copy/CodeWithCopy"

import styles from "../AccessTiersCreate.module.scss"

export function TarballInstaller({
    apiSecretKey,
    accessTierName,
    hideDoc,
    netagentVersion: netAgentVersion,
    canAccessAccessTierInstallStep,
}: Props) {
    const ls = useServiceLocalization()
    const links = useServiceLinks()

    return (
        <>
            <p className={styles.title}>{ls.getString("installationSteps")}</p>
            <div className={styles.row}>
                <label className={styles.label}>
                    {ls.getString("downloadAndExtractTheBinary")}
                </label>
                <CodeWithCopy
                    className={styles.textBox}
                    title={ls.getString("step", "1")}
                    text={getTarballDownloadCommand(netAgentVersion)}
                    hideCopyButton={!canAccessAccessTierInstallStep}
                />
            </div>
            <br />
            <div className={styles.row}>
                <label className={styles.label}>
                    {ls.getString("updateConfigYamlWithSonicWallCseVariables")}
                </label>
                <CodeWithCopy
                    className={styles.textBox}
                    title={ls.getString("step", "2")}
                    text={getConfigYamlParameters(apiSecretKey, accessTierName)}
                    hideCopyButton={!canAccessAccessTierInstallStep}
                />
            </div>
            <br />
            <div className={styles.row}>
                <label className={styles.label}>{ls.getString("runNetagentInstallScript")}</label>
                <CodeWithCopy
                    className={styles.textBox}
                    title={ls.getString("step", "3")}
                    text={tarballInstallCommand}
                    hideCopyButton={!canAccessAccessTierInstallStep}
                />
            </div>
            {!hideDoc && (
                <AppText
                    className={styles.info}
                    ls={{
                        key: "pleaseCheckDocumentForPreReqAndMoreDetails",
                        replaceVals: [links.getLink("tarballInstallDocForAccessTier")],
                    }}
                />
            )}
        </>
    )
}

interface Props {
    apiSecretKey: string
    accessTierName: string
    hideDoc?: boolean
    netagentVersion: string
    canAccessAccessTierInstallStep?: boolean
}

function getConfigYamlParameters(apiSecretKey: string, accessTierName: string): string {
    return [
        `echo "command_center_url: ${window.location.origin}`,
        `api_key_secret: ${apiSecretKey}`,
        `access_tier_name: '${accessTierName}'" > config.yaml`,
    ].join("\n")
}

function getTarballDownloadCommand(netAgentVersion: string) {
    return `wget https://www.banyanops.com/netting/netagent-${netAgentVersion}.tar.gz
tar zxf netagent-${netAgentVersion}.tar.gz
cd netagent-${netAgentVersion}/`
}

const tarballInstallCommand = `sudo ./setup-netagent.sh`
