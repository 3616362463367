import React, { ReactNode, SyntheticEvent } from "react"
import ServicesAddDomainTemplate from "./ServicesAddDomain.template"
import { LocalizationService } from "../../../../services/localization/Localization.service"
import { Bind } from "../../../../decorators/Bind.decorator"
import { ServiceType } from "../../../../services/Manage.service"

export class ServicesAddDomain extends React.Component<
    ServicesAddDomainProps,
    ServicesAddDomainState
> {
    public state: ServicesAddDomainState = { domain: "", port: "" }

    public render(): ReactNode {
        return ServicesAddDomainTemplate.call(this)
    }

    public componentDidMount(): void {
        if (this.props.serviceType === ServiceType.WEB_USER) {
            this.state.port = "443"
        } else if (this.props.serviceType === ServiceType.TCP_USER) {
            this.state.port = "8443"
        }
        if (this.props.initialValue) {
            this.state.domain = this.props.initialValue.name
            this.state.port = this.props.initialValue.port
            this.setState({
                domain: this.props.initialValue.name,
                port: this.props.initialValue.port,
            })
        }
        this.emitDomain()
    }

    private localizationService: LocalizationService = new LocalizationService()

    @Bind
    private onDomainChange(event: SyntheticEvent): void {
        if (event.target) {
            const domainInput: HTMLInputElement = <HTMLInputElement>event.target
            this.state.domain = domainInput.value
            this.emitDomain()
        }
    }

    @Bind
    private onPortChange(value: string): void {
        this.state.port = value
        this.emitDomain()
    }

    @Bind
    private emitDomain(): void {
        if (this.props.onChange) {
            this.props.onChange({ name: this.state.domain, port: this.state.port })
        }
    }
}

interface ServicesAddDomainProps {
    className: string
    serviceType: ServiceType
    initialValue: ServiceDomain
    disabled: boolean
    onChange: (domain: ServiceDomain) => void
    showLetsEncrypt?: boolean
    letsEncrypt?: boolean
}

interface ServicesAddDomainState {
    domain: string
    port: string
}

export interface ServiceDomain {
    name: string
    port: string
}
