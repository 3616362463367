import React, { useRef } from "react"
import { useServiceLocalization } from "../../../../services"
import styles from "./TrustProviderAdvancedSettings.module.scss"
import { Accordion } from "../../../../../v3/components/accordion/Accordion.component"
import { TrustProviderIssuingCerts } from "./issuing-certs/TrustProviderIssuingCerts.component"
import { TrustProviderTokenExpiry } from "./token-expiry/TrustProviderTokenExpiry.component"
import { UserExpiry } from "./user-expiry/UserExpiry.component"
import { GeolocationSetting } from "./geolocation-setting/GeolocationSetting.component"
import {
    ErrorToast,
    SuccessToast,
    ToastApi,
} from "../../../../../components/toast/Toast.components"
import { Loader } from "../../../../../v3/components/loader/Loader.component"
import {
    useGetOrgIsGeolocationEnabled,
    useOptimisticSetGeolocation,
} from "../../../../../v3/services/Settings.service"

interface Props {
    canAccessTrustProviderCerts?: boolean
    canUpdateTokenExpiry?: boolean
    canUpdateGeoLocationSetting?: boolean
    canUpdateUserExpiry?: boolean
}

export const TrustProviderAdvancedSettings = (props: Props) => {
    const localization = useServiceLocalization()
    const errorToastRef = useRef<ToastApi>(null)
    const successToastRef = useRef<ToastApi>(null)

    const { data: isGeolocationEnabled, isLoading: isGeolocationEnabledLoading } =
        useGetOrgIsGeolocationEnabled()
    const { mutate: setGeolocation } = useOptimisticSetGeolocation({
        onError: (error) => {
            errorToastRef.current?.openToast(error)
        },
        onSuccess: () => {
            successToastRef.current?.openToast(
                localization.getString("geolocationSettingUpdatedSuccessfully")
            )
        },
    })

    if (isGeolocationEnabledLoading)
        return (
            <Loader
                title={localization.getString(
                    "loadingSomething",
                    localization.getString("advancedSettings")
                )}
                medium
                center
            />
        )

    return (
        <>
            <div className={styles.container}>
                <Accordion
                    label={localization.getString("privateCertificateAuthorityCA")}
                    type="plain"
                    description={localization.getString("privateCertificateAuthorityCADesc")}
                    open
                >
                    <TrustProviderIssuingCerts
                        canAccessTrustProviderCerts={props.canAccessTrustProviderCerts}
                    />
                </Accordion>

                <Accordion
                    label={localization.getString("authenticationTokensAndCertificates")}
                    type="plain"
                    description={localization.getString("authenticationTokensAndCertificatesDesc")}
                    open
                >
                    <TrustProviderTokenExpiry canUpdateTokenExpiry={props.canUpdateTokenExpiry} />
                </Accordion>

                <Accordion
                    label={localization.getString("userAndDeviceInactivity")}
                    type="plain"
                    open
                >
                    <UserExpiry canUpdateUserExpiry={props.canUpdateUserExpiry} />
                </Accordion>

                <GeolocationSetting
                    isEnabled={isGeolocationEnabled ?? false}
                    onChange={setGeolocation}
                    canUpdateGeoLocationSetting={props.canUpdateGeoLocationSetting}
                />
            </div>
            <ErrorToast ref={errorToastRef} />
            <SuccessToast ref={successToastRef} />
        </>
    )
}
