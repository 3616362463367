import React, { useMemo } from "react"
import { Redirect, Route, Switch, useLocation } from "react-router-dom"

import {
    IconType,
    LeftNavigationBar,
    NavigationItemProps,
} from "../../components/left-navigation-bar/LeftNavigationBar.component"
import { useActivityMonitor } from "../../hooks/useActivityMonitor.hook"
import { AdminInfo, getFullName, useAdminInfo } from "../../hooks/useAdminInfo.hook"
import { useFeatureFlags } from "../../hooks/useFeatureFlags.hook"
import { useProtectPages } from "../../hooks/useProtectPages.hook"
import useTitle from "../../hooks/useTitle.hook"
import { LanguageKey } from "../../pre-v3/services/localization/languages/en-US.language"
import { useServiceLocalization } from "../../pre-v3/services/localization/Localization.service"
import { useAuthService } from "../../pre-v3/services/Auth.service"
import { ROUTE, doesRouteMatch } from "../../routes"
import { Loader } from "../../v3/components/loader/Loader.component"
import { GetHelp } from "../get-help/GetHelp"
import { Home } from "../home/Home"
import { Networks } from "../networks/Networks"
import { Settings } from "../settings/Settings"
import styles from "./AdminConsole.module.scss"
import { ProfileSection } from "../profile/ProfileSection.view"
import { PrivateAccess } from "../private-access/PrivateAccess"
import { InternetAccess } from "../internet-access/InternetAccess.view"
import { Directory } from "../directory/Directory.view"
import { Trust } from "../trust/Trust.view"
import { AppConsole } from "../../utils/consoles.utils"
import { SonicWallCurtain } from "../../components/sonic-wall-curtain/SonicWallCurtain.component"
import { useGenerateCscCurtainUrl } from "../../v3/services/MswCsc.service"

export function AdminConsole(): JSX.Element {
    useActivityMonitor()
    useTitle(["adminConsole"])
    const isLoading = useProtectPages(AppConsole.ADMIN)

    const authService = useAuthService()
    const location = useLocation()
    const localization = useServiceLocalization()
    const { isLoading: isAdminInfoLoading } = useAdminInfo()
    const { data: featureFlags } = useFeatureFlags()

    const parentOrg = useMemo(() => authService.getParentConsole(), [])
    const mswUrl = useMemo(() => authService.getMswCscUrl(), [])
    const { mutateAsync: generateScsUrl } = useGenerateCscCurtainUrl()

    const navigationItems = topLevelRoutes

    if (isLoading || isAdminInfoLoading) {
        const loadingTitle = localization.getString(
            "loadingSomething",
            localization.getString("adminConsole")
        )

        return <Loader center medium title={loadingTitle} />
    }

    const getNavigationItemProps = (route: TopLevelRoute): NavigationItemProps => ({
        key: route,
        icon: topLevelIcons[route],
        label: localization.getString(topLevelLabels[route]),
        to: route,
        active: doesRouteMatch<TopLevelRoute>(route, location),
    })

    const onBackToParentConsole = () => {
        authService.unImpersonate()
        window.location.href = window.location.origin + authService.getLoginUrl()
    }

    return (
        <div className={styles.container}>
            <header>
                <LeftNavigationBar
                    label={localization.getString("mainMenu")}
                    navigationItems={navigationItems}
                    getNavigationItemProps={getNavigationItemProps}
                    initiallyExpanded
                    routeToHome={ROUTE.HOME}
                    routeToProfile={ROUTE.PROFILE}
                    onSignOut={authService.logoutOfAllTabs.bind(authService)}
                    isImpersonated={authService.isImpersonatedActive.bind(authService)}
                    onBackToParentConsole={onBackToParentConsole}
                    parentConsole={parentOrg}
                />
            </header>
            <main>
                <Switch>
                    <Route path={ROUTE.HOME} component={Home} />
                    <Route path={ROUTE.PRIVATE_ACCESS} component={PrivateAccess} />
                    <Route path={ROUTE.TRUST} component={Trust} />
                    <Route path={ROUTE.INTERNET_ACCESS} component={InternetAccess} />
                    <Route path={ROUTE.NETWORKS} component={Networks} />
                    <Route path={ROUTE.DIRECTORY} component={Directory} />
                    <Route path={ROUTE.SETTINGS} component={Settings} />
                    <Route path={ROUTE.GET_HELP} component={GetHelp} />
                    <Route path={ROUTE.PROFILE} component={ProfileSection} />
                    <Redirect to={ROUTE.HOME} />
                </Switch>
            </main>
            {mswUrl && <SonicWallCurtain generateUrl={() => generateScsUrl(mswUrl)} />}
        </div>
    )
}

const topLevelRoutes = [
    ROUTE.HOME,
    ROUTE.PRIVATE_ACCESS,
    ROUTE.INTERNET_ACCESS,
    ROUTE.TRUST,
    ROUTE.NETWORKS,
    ROUTE.DIRECTORY,
    ROUTE.SETTINGS,
    ROUTE.GET_HELP,
] satisfies ROUTE[]

type TopLevelRoute = (typeof topLevelRoutes)[number]

const topLevelIcons: Record<TopLevelRoute, IconType> = {
    [ROUTE.HOME]: IconType.HOME,
    [ROUTE.PRIVATE_ACCESS]: IconType.KEY,
    [ROUTE.INTERNET_ACCESS]: IconType.PASSPORT,
    [ROUTE.TRUST]: IconType.SHIELD,
    [ROUTE.NETWORKS]: IconType.NETWORK,
    [ROUTE.DIRECTORY]: IconType.LIST,
    [ROUTE.SETTINGS]: IconType.GEAR,
    [ROUTE.GET_HELP]: IconType.QUESTION,
}

const topLevelLabels: Record<TopLevelRoute, LanguageKey> = {
    [ROUTE.HOME]: "home",
    [ROUTE.PRIVATE_ACCESS]: "privateAccess",
    [ROUTE.INTERNET_ACCESS]: "internetAccess",
    [ROUTE.TRUST]: "trust",
    [ROUTE.NETWORKS]: "networks",
    [ROUTE.DIRECTORY]: "directory",
    [ROUTE.SETTINGS]: "settings",
    [ROUTE.GET_HELP]: "getHelp",
}

function getAdminFullName(adminInfo: AdminInfo): string | undefined {
    return adminInfo.isImpersonated
        ? undefined
        : getFullName(adminInfo.firstName, adminInfo.lastName)
}
