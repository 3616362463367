import { BaseV2Api } from "./BaseV2.api"
import { Singleton } from "../decorators/Singleton.decorator"

const banyanIDPUrl = "/api/v1/banyanidp/enduser"

@Singleton("OIDCApi")
export class OIDCApi extends BaseV2Api {
    public signIn(payload: SignInReq): Promise<SignInRes> {
        return this.postOIDC(banyanIDPUrl + "/signin", payload)
    }

    public newPassword(payload: NewPasswordReq): Promise<NewPasswordRes> {
        return this.postOIDC(banyanIDPUrl + "/newpassword", payload)
    }

    public forgotPassword(payload: ForgotPasswordReq): Promise<void> {
        return this.postOIDC(banyanIDPUrl + "/forgotpassword", payload)
    }

    public resendCode(payload: ResendCodeReq): Promise<void> {
        return this.postOIDC(banyanIDPUrl + "/resendconfirmationcode", payload)
    }

    public confirmForgotPassword(payload: ConfirmForgotPasswordReq): Promise<void> {
        return this.postOIDC(banyanIDPUrl + "/confirmforgotpassword", payload)
    }

    public changePassword(payload: ChangePasswordReq): Promise<void> {
        return this.postOIDC(banyanIDPUrl + "/changepassword", payload)
    }

    public authorize(orgName: string, state?: string): Promise<SignInRes> {
        let url = banyanIDPUrl + "/authorize?org_name=" + orgName
        if (state) {
            url = url + "&state=" + state
        }
        return this.get(url)
    }
}

export interface NewPasswordReq {
    username: string
    password: string
    orgName: string
    session: string
    state: string
}

export interface NewPasswordRes {
    UserName?: string
    AccessToken?: string
    IdToken?: string
    ChallengeName?: string
    Session?: string
    State?: string
    OrgName?: string
    Error?: string
    ErrorMessage?: string
    RedirectURL?: string
    SessionKey?: string
    IsAdmin: boolean
}

export interface SignInReq {
    username: string
    password: string
    orgName: string
    state: string
}

export interface SignInRes {
    UserName?: string
    AccessToken?: string
    IdToken?: string
    ChallengeName?: SignInChallengeName
    Session?: string
    State?: string
    OrgName: string
    Error?: string
    ErrorMessage?: string
    RedirectURL?: string
    SessionKey?: string
    IsAdmin: boolean
}

export enum SignInChallengeName {
    NEW_PASSWORD_REQUIRED = "NEW_PASSWORD_REQUIRED",
}

export interface ForgotPasswordReq {
    username: string
    orgName: string
}

export interface ResendCodeReq {
    userName: string
    orgName: string
}

export interface ConfirmForgotPasswordReq {
    userName: string
    orgName: string
    code: string
    password: string
}

export interface ChangePasswordReq {
    userName: string
    password: string
    oldPassword: string
    accessToken: string
}
