import React from "react"
import styles from "./StatusCellRenderer.module.scss"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faCheckCircle, faDotCircle, faLocationCircle } from "@fortawesome/pro-solid-svg-icons"
import classNames from "classnames/bind"
import { CloudResourceStatus, useServiceLocalization } from "../../../../../services"

interface Props {
    data: {
        status: CloudResourceStatus
    }
}

export function StatusCellRenderer(props: Props) {
    switch (props.data.status) {
        case CloudResourceStatus.DISCOVERED:
            return <StatusDiscovered />
        case CloudResourceStatus.PUBLISHED:
            return <StatusPublished />
        case CloudResourceStatus.IGNORED:
            return <StatusIgnored />
        default:
            return <StatusDiscovered />
    }
}

function StatusDiscovered() {
    const ls = useServiceLocalization()

    return (
        <div className={styles.container}>
            <div className={classNames(styles.statusIcon, styles.statusIconDiscovered)}>
                <FontAwesomeIcon icon={faLocationCircle} />
            </div>
            <div className={styles.statusLabel}>{ls.getString("discovered")}</div>
        </div>
    )
}

function StatusIgnored() {
    const ls = useServiceLocalization()

    return (
        <div className={styles.container}>
            <div className={classNames(styles.statusIcon, styles.statusIconIgnored)}>
                <FontAwesomeIcon icon={faDotCircle} />
            </div>
            <div className={styles.statusLabel}>{ls.getString("ignored")}</div>
        </div>
    )
}

function StatusPublished() {
    const ls = useServiceLocalization()

    return (
        <div className={styles.container}>
            <div className={classNames(styles.statusIcon, styles.statusIconPublished)}>
                <FontAwesomeIcon icon={faCheckCircle} />
            </div>
            <div className={styles.statusLabel}>{ls.getString("published")}</div>
        </div>
    )
}
