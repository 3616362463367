import React from "react"

import { LanguageKey } from "../../../pre-v3/services/localization/languages/en-US.language"
import { useServiceLocalization } from "../../../pre-v3/services/localization/Localization.service"
import { encodeID } from "../../../pre-v3/utils/Url.util"
import { ROUTE, formatRoutePath } from "../../../routes"
import { Column, Grid, GridApi } from "../../../v3/components/grid/Grid.component"
import { RowTitle } from "../../../v3/components/grid/RowTitle.component"
import {
    AccessTierDetails as AccessTier,
    Service,
    ServiceType,
    useGetServicesByAccessTier,
} from "../../../v3/services/AccessTier.service"
import styles from "./AccessTierServicesTab.module.scss"

interface Props {
    id: string
    accessTier: AccessTier
}

export interface Api {
    refresh: () => Promise<void>
}

export const AccessTierServicesTab = React.forwardRef<Api, Props>((props, ref) => {
    const columns = useColumns()

    const gridRef = React.useRef<GridApi>(null)

    const { getServicesByAccessTier, clearCache } = useGetServicesByAccessTier(props.accessTier)

    React.useImperativeHandle(ref, () => ({
        refresh: async () => {
            await clearCache()
            gridRef.current?.refreshData()
        },
    }))

    return (
        <div id={props.id} className={styles.container}>
            <Grid
                ref={gridRef}
                columns={columns}
                hasPagination
                serverSideProps={{ getServerSideData: getServicesByAccessTier }}
            />
        </div>
    )
})

AccessTierServicesTab.displayName = "AccessTierServicesTab"

function useColumns(): Column<Service>[] {
    const localization = useServiceLocalization()
    const formatServiceType = useFormatServiceType()
    const formatLastUpdated = useFormatLastUpdated()

    return React.useMemo(() => {
        const nameColumn: Column<Service> = {
            id: "name",
            name: localization.getString("serviceName"),
            cellRenderer: (service) => (
                <RowTitle title={service.name} route={getServiceRoute(service)} />
            ),
            getTooltipValue: "name",
        }

        const typeColumn: Column<Service> = {
            id: "type",
            name: localization.getString("serviceType"),
            cellRenderer: formatServiceType,
            getTooltipValue: formatServiceType,
        }

        const lastUpdatedColumn: Column<Service> = {
            id: "lastUpdated",
            name: localization.getString("lastUpdated"),
            cellRenderer: formatLastUpdated,
            getTooltipValue: formatLastUpdated,
        }

        return [nameColumn, typeColumn, lastUpdatedColumn]
    }, [localization, formatServiceType, formatLastUpdated])
}

function getServiceRoute(service: Service): ROUTE {
    switch (service.type) {
        case ServiceType.HOSTED_WEBSITE:
            return formatRoutePath(ROUTE.HOSTED_WEBSITES_DETAILS, {
                id: encodeID(service.id),
            })

        case ServiceType.GENERIC_TCP:
        case ServiceType.SSH:
        case ServiceType.RDP:
        case ServiceType.KUBERNETES:
        case ServiceType.DATABASE:
            return formatRoutePath(ROUTE.INFRASTRUCTURE_DETAILS, {
                id: encodeID(service.id),
            })
        case ServiceType.SERVICE_TUNNEL:
            return formatRoutePath(ROUTE.SERVICE_TUNNELS_DETAILS, { id: encodeID(service.id) })
    }
}

function useFormatServiceType() {
    const localization = useServiceLocalization()

    return (service: Service) => localization.getString(serviceTypeToKey[service.type])
}

const serviceTypeToKey: Record<ServiceType, LanguageKey> = {
    [ServiceType.HOSTED_WEBSITE]: "hostedWebsite",
    [ServiceType.GENERIC_TCP]: "genericTcp",
    [ServiceType.SSH]: "ssh",
    [ServiceType.RDP]: "rdp",
    [ServiceType.KUBERNETES]: "kubernetes",
    [ServiceType.DATABASE]: "database",
    [ServiceType.SERVICE_TUNNEL]: "serviceTunnel",
}

function useFormatLastUpdated() {
    const localization = useServiceLocalization()
    const locale = localization.getLocale()

    return (service: Service) => service.lastUpdatedAt.toLocaleString(locale)
}
