import React from "react"
import { Switch, Route } from "react-router-dom"

import { ROUTE } from "../../../../../routes"
import { FileCheckTrustFactor, TrustFactor } from "../../../../services/TrustFactor.service"
import { FileCheckAdd } from "./FileCheckAdd.view"
import { FileCheckList } from "./FileCheckList.view"
import { FileCheckEdit } from "./FileCheckEdit.view"

interface Props {
    fileCheckTrustFactor?: FileCheckTrustFactor
    isLoading: boolean
    onRefetchData(): Promise<TrustFactor[] | undefined>
    canAccessFileCheckFactor?: boolean
}

export function FileCheck(props: Props): JSX.Element {
    return (
        <Switch>
            <Route path={ROUTE.FACTORS_FILE_CHECK_CREATE}>
                <FileCheckAdd canAccessFileCheckFactor={props.canAccessFileCheckFactor} />
            </Route>
            <Route path={ROUTE.FACTORS_FILE_CHECK_DETAILS}>
                <FileCheckEdit {...props} />
            </Route>
            <Route>
                <FileCheckList {...props} />
            </Route>
        </Switch>
    )
}
