import classNames from "classnames/bind"
import React from "react"
import { Box, Props as BoxProps } from "../box/Box"

import styles from "./ButtonBase.module.scss"

const defaultElement: React.ElementType<React.HTMLAttributes<HTMLButtonElement>> = "button"

export type Props<E extends React.ElementType = typeof defaultElement> = BoxProps<E> &
    GetVariantStyle

function ButtonBaseComponent<E extends React.ElementType = typeof defaultElement>(
    props: Props<E>,
    ref: any
): JSX.Element {
    const {
        as = defaultElement,
        className,
        children,
        brand,
        loading,
        error,
        ...buttonProps
    } = props

    return (
        <Box
            {...buttonProps}
            as={as}
            ref={ref}
            className={classNames(
                className,
                getVariantStyle({
                    brand,
                    loading,
                    error,
                })
            )}
        >
            {children}
        </Box>
    )
}

export const ButtonBase = React.forwardRef(ButtonBaseComponent) as <
    E extends React.ElementType = typeof defaultElement,
>(
    props: Props<E> & { ref?: any }
) => JSX.Element

interface GetVariantStyle {
    brand?: boolean
    loading?: boolean
    error?: boolean
}

function getVariantStyle(props: GetVariantStyle) {
    const { loading, ...variantStyles } = props
    const { brand, error } = props

    const isDefault = !Object.values(variantStyles).find((style) => style)

    return classNames(
        styles.variant,
        { [styles.default]: isDefault },
        { [styles.brand]: brand },
        { [styles.loading]: loading },
        { [styles.error]: error }
    )
}
