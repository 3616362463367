import { useQuery } from "@tanstack/react-query"
import { CountryRes, DeviceGeoLocationApi } from "../api/DeviceGeoLocationApi.api"

export function useGetCountries() {
    const deviceGeolocationApi = new DeviceGeoLocationApi()

    return useQuery<Country, string>({
        queryKey: ["deviceGeolocationService.getCountries"],
        queryFn: async () => {
            const data: CountryRes = await deviceGeolocationApi.getCountries()
            return {
                countries: data.countries,
            }
        },
    })
}

export interface Country {
    countries: string[]
}
