import React, { ReactNode } from "react"
import MultiInputTemplate from "./MultiInput.template"
import { Bind } from "../../decorators/Bind.decorator"
import { LocalizationService } from "../../services/localization/Localization.service"

export class MultiInput extends React.Component<MultiInputProps, MultiInputState> {
    public state: MultiInputState = { values: [], inputValue: "" }

    public render(): ReactNode {
        return MultiInputTemplate.call(this)
    }

    public componentDidMount(): void {
        this.setState({ values: this.props.initialValues || [] }, this.setValidity)
    }

    private localizationService: LocalizationService = new LocalizationService()
    private inputField: HTMLInputElement

    @Bind
    private onKeyPress(event: KeyboardEvent): void {
        if (event.charCode === 13) {
            // Enter key
            event.preventDefault()
            this.onAdd()
        }
    }

    @Bind
    private onChange(event?: React.ChangeEvent<HTMLInputElement>): void {
        const values = this.props.value || this.state.values
        const inputValue = event?.target.value || this.state.inputValue || ""

        // if we are updating a controlled input we just need to change the last value
        let newValues = [...values]
        if (this.props.value) {
            newValues[Math.max(0, this.props.value.length - 1)] = inputValue
        } else {
            newValues.push(inputValue)
        }

        if (event && event.target) {
            this.state.inputValue = (<any>event.target).value
            this.setState({ inputValue: this.state.inputValue })
        }

        // what we pass the on change handler depends if the component is controlled or not
        if (this.props.value) {
            // check the validity
            this.setValidity(newValues)
            this.emit(newValues)
        }
        // if we aren't controlled, only emit the newvalues if they are unique
        else {
            // check the validity
            this.setValidity(newValues)
            if (this.state.inputValue.length > 0 && !values.includes(this.state.inputValue)) {
                this.emit(newValues)
            } else {
                // we didn't change the values so validate the input according to the old ones
                newValues = values
                this.emit(values)
            }
        }
    }

    @Bind
    private onAdd(): void {
        // if the field is invalid, report the message
        if (!this.inputField.checkValidity()) {
            this.inputField.reportValidity()
            return
        }

        // if we are dealing with a controlled input we need to add an empty element to the end of the
        // value prop so we can collect new values
        if (this.props.value && this.props.onChange && this.inputField.value) {
            this.props.onChange(this.props.value.concat(""))
            return
        }

        if (!this.state.inputValue) {
            return
        }

        // the input value is valid
        const newState = {
            ...this.state,
            values: this.state.values.concat(this.state.inputValue),
            inputValue: "",
        }

        this.setState(newState, this.onChange)

        if (this.props.onSubmit) {
            this.props.onSubmit(newState.values)
        }
    }

    @Bind
    private onRemove(idx: number): void {
        // if we are a controlled component call the onChange handler with the index removed
        if (this.props.value && this.props.onChange) {
            const newValue: string[] = this.props.value.filter((_, i) => i !== idx)
            this.props.onChange(newValue)

            // make sure we keep the validity state in sync
            this.setValidity(newValue)

            return
        }

        const newState = {
            ...this.state,
            values: [...this.state.values],
        }
        newState.values.splice(idx, 1)

        this.setState(newState, this.onChange)

        if (this.props.onSubmit) {
            this.props.onSubmit(newState.values)
        }
    }

    private emit(values: string[]): void {
        if (this.props.onChange) {
            this.props.onChange(values)
        }
    }

    @Bind
    private setValidity(newValues?: string[]) {
        const controlledValue = newValues || this.props.value

        const inputValue = controlledValue
            ? controlledValue[controlledValue.length - 1]
            : this.state.inputValue

        let validationError = ""

        // if there are no values in the input and the field is required
        if (this.props.required && this.inputField) {
            const values = controlledValue || this.state.values

            // if there is no value
            if (!inputValue && values.length === 0) {
                validationError = this.localizationService.getString("pleaseFillOutThisField")
            }
        }
        if (
            controlledValue &&
            new Set(controlledValue).size !== controlledValue.length &&
            this.inputField
        ) {
            validationError = this.localizationService.getString("valuesMustBeUnique")
        }

        if (this.inputField) {
            this.inputField.setCustomValidity(validationError)
        }
    }
}

interface MultiInputProps {
    autoFocus?: boolean
    initialValues?: string[]
    value?: string[]
    placeholder?: string
    pattern?: string
    onChange?: (values: string[]) => void
    disabled?: boolean
    className?: string
    onSubmit?: (values: string[]) => void
    type?: string
    required?: boolean
}

interface MultiInputState {
    values: string[]
    inputValue: string
}
