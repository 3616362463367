import React from "react"
import { Redirect, Route, Switch, useLocation } from "react-router-dom"

import {
    NavigationItemProps,
    TopNavigationBar,
} from "../../components/top-navigation-bar/TopNavigationBar.component"
import { useFeatureFlags } from "../../hooks/useFeatureFlags.hook"
import { useServiceLocalization } from "../../pre-v3/services/localization/Localization.service"
import { ROUTE, doesRouteMatch } from "../../routes"
import { LanguageKey } from "../../pre-v3/services/localization/languages/en-US.language"
import { Loader } from "../../v3/components/loader/Loader.component"
import { SearchResultPanel } from "../../v3/views/smart-search/SearchResultPanel.view"
import { SmartSearchHistoryPanel } from "../../v3/views/smart-search/SmartSearchHistoryPanel.view"
import { Banner, Variant } from "../../components/banner/Banner.component"
import { GetHelpHome } from "./GetHelpHome.page"

export function GetHelp() {
    const localization = useServiceLocalization()
    const location = useLocation()
    const featureFlagsQuery = useFeatureFlags()

    const getHelpLabel = localization.getString("getHelp")

    if (featureFlagsQuery.status === "loading") {
        return (
            <Loader
                center
                medium
                title={localization.getString("loadingSomething", getHelpLabel)}
            />
        )
    }

    const getNavigationItemProps = (route: SecondLevelRoute): NavigationItemProps => ({
        key: route,
        label: localization.getString(secondLevelLabels[route]),
        to: route,
        active: doesRouteMatch<SecondLevelRoute>(route, location),
    })

    return (
        <>
            <header>
                <TopNavigationBar
                    headingTitle={getHelpLabel}
                    navigationItems={
                        featureFlagsQuery.data?.adminConsole.isAiAssistedAdminSearchEnabled
                            ? secondLevelRoutes
                            : onlyHome
                    }
                    getNavigationItemProps={getNavigationItemProps}
                />
            </header>
            {featureFlagsQuery.status === "error" && (
                <Banner label={String(featureFlagsQuery.error)} variant={Variant.ERROR} />
            )}
            <Switch>
                <Route path={ROUTE.GET_HELP_HOME}>
                    <GetHelpHome
                        hasCommunityForum={
                            featureFlagsQuery.data?.adminConsole.hasCommunityForum ?? false
                        }
                        hasSupport={featureFlagsQuery.data?.adminConsole.hasSupport ?? false}
                    />
                </Route>
                {featureFlagsQuery.data?.adminConsole.isAiAssistedAdminSearchEnabled && (
                    <Route
                        path={ROUTE.GET_HELP_SMART_SEARCH_RESULT}
                        component={SearchResultPanel}
                    />
                )}
                {featureFlagsQuery.data?.adminConsole.isAiAssistedAdminSearchEnabled && (
                    <Route
                        path={ROUTE.GET_HELP_SMART_SEARCH_HISTORY}
                        component={SmartSearchHistoryPanel}
                    />
                )}
                <Redirect to={ROUTE.GET_HELP_HOME} />
            </Switch>
        </>
    )
}

const onlyHome: SecondLevelRoute[] = [ROUTE.GET_HELP_HOME]

const secondLevelRoutes = [
    ROUTE.GET_HELP_HOME,
    ROUTE.GET_HELP_SMART_SEARCH_RESULT,
    ROUTE.GET_HELP_SMART_SEARCH_HISTORY,
] satisfies ROUTE[]

type SecondLevelRoute = (typeof secondLevelRoutes)[number]

const secondLevelLabels: Record<SecondLevelRoute, LanguageKey> = {
    [ROUTE.GET_HELP_HOME]: "home",
    [ROUTE.GET_HELP_SMART_SEARCH_RESULT]: "searchResult",
    [ROUTE.GET_HELP_SMART_SEARCH_HISTORY]: "smartSearchHistory",
}
