import React, { useRef, useState, useEffect, ReactNode } from "react"
import styles from "./MspManagedOrgList.module.scss"
import { useServiceLocalization } from "../../../../../pre-v3/services/localization/Localization.service"
import AgGridUtil from "../../../../../pre-v3/utils/AgGrid.util"
import { GridReadyEvent, GridApi, ColDef } from "ag-grid-community"
import { useActionBar } from "../../../../../pre-v3/services/ActionBar.service"
import { SearchInput } from "../../../../components/search-input/SearchInput.component"
import { ErrorBanners } from "../../../../components/banner/Banner.component"
import { Loader } from "../../../../components/loader/Loader.component"

// Note: Old components, move them to v3
import { Grid } from "../../../../../pre-v3/components/grid/Grid.component"
import {
    MspAdminOrgInfo,
    useGetMspAdminOrgs,
    useMasqueradeMspAdmin,
} from "../../../../services/MspOrgManagement.service"
import { ActionBar } from "../../../../../pre-v3/components/action-bar/ActionBar.component"
import { Button } from "../../../../components/button/Button.component"
import { useAuthService } from "../../../../../pre-v3/services/Auth.service"
import { useServiceModal } from "../../../../../pre-v3/services/Modal.service"
import { MessageContent } from "../../../../../pre-v3/components/modal/message/MessageContent"
import { OkCancelActions } from "../../../../../pre-v3/components/modal/ok-cancel/OkCancelActions.component"

interface Props {
    email?: string
}

export function MspManagedOrgList(props: Props) {
    const localization = useServiceLocalization()
    const authService = useAuthService()
    const modalService = useServiceModal()
    const grid = useRef<GridApi>()

    const [search, setSearch] = useState<string>("")

    const {
        data,
        refetch: fetchMspOrgs,
        isFetching: isMspOrgsLoading,
        isError: isMspOrgError,
        error: mspOrgsError,
    } = useGetMspAdminOrgs(props.email || "")

    useEffect(() => {
        grid.current?.setQuickFilter?.(search)
    }, [search])

    useActionBar({
        title: localization.getString("assignedOrgs"),
        fetchData: () => {
            fetchMspOrgs()
        },
    })

    function onGridReady(event: GridReadyEvent) {
        grid.current = event.api
    }

    const {
        mutateAsync: loginToOrganization,
        isLoading: isDataLoading,
        error: masqueradeAdminError,
    } = useMasqueradeMspAdmin()

    const errors: ReactNode[] = [
        typeof mspOrgsError === "string" && mspOrgsError,
        typeof masqueradeAdminError === "string" && masqueradeAdminError,
    ]

    function onLoginToOrg(data: MspAdminOrgInfo): void {
        modalService
            .open(
                localization.getString("confirmViewAsSomething", data.role),
                {
                    component: MessageContent,
                    props: {
                        text: localization.getString("confirmViewAsAdminExplanation", data.role),
                    },
                },
                {
                    component: OkCancelActions,
                    props: { okString: localization.getString("viewAsSomething", data.role) },
                }
            )
            .onClose(async () => {
                await loginToOrganization(data.id)
                window.location.href = window.location.origin + authService.getLoginUrl()
            })
    }

    const columns: ColDef<MspAdminOrgInfo>[] = [
        {
            headerName: localization.getString("orgName"),
            field: "orgName",
            flex: 50,
            comparator: AgGridUtil.alphaBetComparator,
            tooltipValueGetter: AgGridUtil.linkTooltipValueGetter,
        },
        {
            headerName: localization.getString("role"),
            field: "role",
            tooltipValueGetter: AgGridUtil.linkTooltipValueGetter,
            comparator: AgGridUtil.alphaBetComparator,
            flex: 50,
        },
        {
            headerName: localization.getString("lastLogin"),
            flex: 50,
            field: "lastLoginAt",
            valueFormatter: AgGridUtil.dateFormatter,
        },
        {
            headerName: localization.getString("manage"),
            cellRenderer: () => (
                <Button className={styles.button} loading={isDataLoading}>
                    {localization.getString("loginToOrganization")}
                </Button>
            ),
            onCellClicked: async (e) => {
                if (e.data) {
                    onLoginToOrg(e.data)
                }
            },
        },
    ]

    return (
        <>
            <ActionBar />
            <div className={styles.container}>
                <SearchInput
                    className={styles.search}
                    value={search}
                    onChangeValue={setSearch}
                    placeholder={localization.getString("search")}
                />
                <Loader isLoading={isMspOrgsLoading} center medium>
                    <ErrorBanners className={styles.errorBanner} errors={errors} />
                    {!isMspOrgError && (
                        <Grid
                            onGridReady={onGridReady}
                            columnDefs={columns}
                            rowData={data}
                            pagination
                        />
                    )}
                </Loader>
            </div>
        </>
    )
}
