import classNames from "classnames/bind"
import React from "react"

import styles from "./Card.module.scss"

export type Props = React.DetailedHTMLProps<React.HTMLAttributes<HTMLDivElement>, HTMLDivElement>

export function Card(props: Props): JSX.Element {
    return <div {...props} className={classNames(styles.card, props.className)} />
}
