import { BaseApi, PaginatedResponse, PaginationOptions } from "./Base.api"
import { Singleton } from "../../pre-v3/decorators/Singleton.decorator"
import { UrlUtil } from "../../pre-v3/utils/Url.util"

@Singleton("DeviceApi")
export class DeviceApi extends BaseApi {
    public getDevices(
        getDevicesParams?: Partial<GetDevicesParams>
    ): Promise<PaginatedResponse<"devices", DeviceRes>> {
        const params = getDevicesParams
            ? UrlUtil.convertToURLSearchParams(getDevicesParams).toString()
            : ""

        return this.get(`/api/v2/devices${params && `?${params}`}`, {
            default: this.localization.getString("couldNotFetchDevices"),
        })
    }

    public getDevicesCsvBlob(
        getDevicesCsvBlobParams?: Partial<GetDevicesCsvBlobParams>
    ): Promise<Blob> {
        const params =
            getDevicesCsvBlobParams &&
            UrlUtil.convertToURLSearchParams(getDevicesCsvBlobParams).toString()

        return this.get(`/api/v2/devices/csv${params && "?" + params}`, {
            default: this.localization.getString("failedToDownloadDevicesCSV"),
        })
    }

    public updateDevice(serialNumber: string, req: UpdateDeviceReq): Promise<void> {
        return this.post(`/api/v1/mdm/update_device?SerialNumber=${serialNumber}`, req, {
            default: this.localization.getString("couldNotUpdateDevice"),
        })
    }

    public deleteDevices(req: DeleteDevicesReq): Promise<void> {
        return this.delete("/api/v2/devices", req, {
            default: this.localization.getString("couldNotDeleteDevices"),
        })
    }

    public unregisterUser(unregisterUserParams: UnregisterUserParams): Promise<void> {
        const params = UrlUtil.convertToURLSearchParams(unregisterUserParams).toString()

        return this.delete(
            `/api/v1/delete_device?${params}`,
            {},
            {
                default: this.localization.getString("couldNotUnregisterUser"),
            }
        )
    }

    public getDeviceDiagnostic(deviceId: string): Promise<DeviceDiagnosticRes> {
        return this.get(`/api/v2/inbox/status/${encodeURIComponent(deviceId)}`, {
            default: this.localization.getString("errorCouldNotGetDeviceDiagnosticStatus"),
        })
    }

    public sendDeviceDiagnosticReq(deviceId: string): Promise<SendDeviceDiagnosticRes> {
        return this.post(
            `/api/v2/inbox/${encodeURIComponent(deviceId)}`,
            {},
            {
                default: this.localization.getString("errorFailedToRequestDeviceDiagnostics"),
            }
        )
    }

    public getDeviceDiagnosticLogs(deviceId: string): Promise<Blob> {
        return this.getBlob(`/api/v2/device/logs/${encodeURIComponent(deviceId)}`, {
            default: this.localization.getString("errorFailedToFetchDeviceLogs"),
        })
    }
}

export interface GetDevicesParams extends PaginationOptions<DeviceOrderByReq> {
    serial_number: string
    name: string
    platform: PlatformRes
    trust_level: TrustLevelRes
    ownership: OwnershipRes
    banned: BooleanRes
    app_version: string
    role_names: string
    last_login_before: number
    last_login_after: number
    trustscore_status: Exclude<TrustScoreStatusRes, "Overridden">
    trust_profile_id: string
    threat_profile_id: string
    active: BooleanRes
    email: string
    active_service_tunnel_id: string
}

export type DeviceOrderByReq =
    | "created_at"
    | "name"
    | "serialnumber"
    | "model"
    | "last_login"
    | "platform"
    | "app_version"
    | "trustscore"
    | "trustscore_status"

type BooleanRes = "true" | "false"
export type OwnershipRes = "Employee Owned" | "Corporate Shared" | "Corporate Dedicated" | "Other"
export type PlatformRes = "macOS" | "Windows" | "Linux" | "iOS" | "Android" | "Chrome"
// TODO: Remove the AlwaysAllow trust level once Adobe migrate - BC-8124
export type TrustLevelRes = "AlwaysDeny" | "Low" | "Medium" | "High" | "AlwaysAllow"
type TrustScoreStatusRes = "Pending" | "Expired" | "Overridden" | "Reporting"

export interface DeviceRes {
    id: string
    name: string
    serial_number: string
    model: string
    ownership: OwnershipRes
    platform: PlatformRes
    architecture: string
    banned: boolean
    os: string
    app_version: string
    last_login: number
    last_evaluated_at: number
    trust_profile_id: string
    trust_profile_display_name: string
    threat_profile_display_name: string
    threat_profile_id: string
    extra_details: {
        emails?: string[]
        role_names: string[]
        trust: {
            level: TrustLevelRes
            status: TrustScoreStatusRes
            expired_at: number
            factors: TrustFactorRes[]
        }
    }
    active_service_tunnel: ServiceTunnelRes | null
    is_itp_enabled: boolean
}

interface ServiceTunnelRes {
    id: string
    name: string
    accesstier_connection_info: AccessTierConnectionInfoRes[]
}

export interface AccessTierConnectionInfoRes {
    accesstier_id: string
    accesstier_name: string
    ip_address: string
}

export interface TrustFactorRes {
    name: string
    value: BooleanRes
    type: "internal" | "external" | "extended"
    source: string
    description: string
}

interface GetDevicesCsvBlobParams {
    active: BooleanRes
}

interface UpdateDeviceReq {
    Model: string
    Ownership: OwnershipRes
    Platform: PlatformRes
    OS: string
    Architecture: string
    Banned: boolean
}

interface DeleteDevicesReq {
    device_ids: string[]
}

interface UnregisterUserParams {
    SerialNumber: string
    Email?: string
}

export interface DeviceDiagnosticRes {
    message_posted_at: number
    message_acknowledged_at: number
    message_response: {
        diagnostics_upload_success: boolean
        error_message: DiagnosticErrorMessage
    }
    message_response_returned_at: number
}

export interface SendDeviceDiagnosticRes {
    inbox_status_path: string
}

export type DiagnosticErrorMessage = "SCRIPT_RETRIEVAL_FAILURE" | "SCRIPT_UPLOAD_FAILURE"
