import React, { ReactNode } from "react"
import PreferredAppsOSCellRendererTemplate from "./PreferredAppsOSCellRenderer.template"
import { ColDef } from "ag-grid-community"
import { AppPlatform } from "../../../../services/Settings.service"

export class PreferredAppsOSCellRenderer extends React.Component<
    PreferredAppsOSCellRendererProps,
    PreferredAppsOSCellRendererState
> {
    public static getDerivedStateFromProps(
        props: PreferredAppsOSCellRendererProps
    ): PreferredAppsOSCellRendererState {
        const columnOs = props.colDef.cellRendererParams
            ? props.colDef.cellRendererParams.columnOs
            : ""
        const appPlatform: AppPlatform | undefined = props.value.find(
            (app) => app.os.toLowerCase() === columnOs.toLowerCase()
        )
        return { confirmed: appPlatform !== undefined }
    }

    public state: PreferredAppsOSCellRendererState = { confirmed: false }

    public render(): ReactNode {
        return PreferredAppsOSCellRendererTemplate.call(this)
    }
}

interface PreferredAppsOSCellRendererProps {
    colDef: ColDef
    value: AppPlatform[]
}

interface PreferredAppsOSCellRendererState {
    confirmed: boolean
}
