import React, { ReactNode } from "react"
import SubmitButtonTemplate from "./SubmitButton.template"

export interface SubmitButtonProps {
    label: string
    loading?: boolean
    className?: string
}

export class SubmitButton extends React.Component<SubmitButtonProps, {}> {
    public render(): ReactNode {
        return SubmitButtonTemplate.call(this)
    }
}
