import React, { ReactNode } from "react"
import ActionBarTemplate from "./ActionBar.template"
import ActionBarService, {
    Breadcrumb,
    ActionBarEventDetail,
    ActionBarItem,
} from "../../services/ActionBar.service"
import { Bind } from "../../decorators/Bind.decorator"
import { TimeRange } from "../../utils/Date.util"
import { LocalizationService } from "../../services/localization/Localization.service"

export class ActionBar extends React.Component<{}, ActionBarState> {
    constructor(props: {}) {
        super(props)
        this.state = {
            title: this.actionBarService.getTitle(),
            breadcrumbs: this.actionBarService.getBreadcrumbs() || [],
            showRefresh: this.actionBarService.getShowRefresh(),
            actions: this.actionBarService.getActions(),
            description: this.actionBarService.getDescription(),
        }
    }

    public componentDidMount(): void {
        window.addEventListener(ActionBarEvent.ACTION_BAR_EVENT, this.onActionBarBreadcrumbChange)
    }

    public componentWillUnmount(): void {
        window.removeEventListener(
            ActionBarEvent.ACTION_BAR_EVENT,
            this.onActionBarBreadcrumbChange
        )
    }

    public render(): ReactNode {
        return ActionBarTemplate.call(this)
    }

    private actionBarService: ActionBarService = new ActionBarService()
    private localizationService: LocalizationService = new LocalizationService()

    @Bind
    private onActionBarBreadcrumbChange(event: CustomEvent<ActionBarEventDetail>): void {
        this.setState({
            title: event.detail.title,
            breadcrumbs: event.detail.breadcrumbs || [],
            showRefresh: event.detail.showRefresh,
            actions: event.detail.actions,
            description: event.detail.description,
        })
    }

    @Bind
    private onRefresh(): void {
        this.actionBarService.onRefresh()
    }
}

interface ActionBarState {
    title: React.ReactNode
    breadcrumbs: Breadcrumb[]
    showRefresh: boolean
    timeRange?: TimeRange
    actions: ActionBarItem[]
    description?: React.ReactNode
}

export enum ActionBarEvent {
    ACTION_BAR_EVENT = "ACTION_BAR_EVENT",
}

declare global {
    interface WindowEventMap {
        [ActionBarEvent.ACTION_BAR_EVENT]: CustomEvent<ActionBarEventDetail>
    }
}
