import { useServiceLocalization, RegisteredDomainStatus, IssuedCertStatus } from "../../../services"
import React from "react"
import styles from "./StatusCellRenderer.module.scss"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faCheckCircle, faPauseCircle, faTimesCircle } from "@fortawesome/pro-solid-svg-icons"
import classNames from "classnames/bind"
import { InfoTip } from "../../info-tip/InfoTip.component"

interface Props {
    data: {
        status: RegisteredDomainStatus | IssuedCertStatus
        failedReason?: string
    }
}

export function StatusCellRenderer(props: Props) {
    switch (props.data.status) {
        case RegisteredDomainStatus.VERIFIED:
        case IssuedCertStatus.ACTIVE:
            return <StatusVerified />
        case RegisteredDomainStatus.FAILED:
        case IssuedCertStatus.FAILED:
            return <StatusFailed reason={props.data.failedReason} />
        default:
            return <StatusPending />
    }
}

function StatusVerified() {
    const ls = useServiceLocalization()

    return (
        <div className={styles.container}>
            <div className={classNames(styles.statusIcon, styles.statusIconVerified)}>
                <FontAwesomeIcon icon={faCheckCircle} />
            </div>
            <div className={styles.statusLabel}>{ls.getString("verified")}</div>
        </div>
    )
}

function StatusPending() {
    const ls = useServiceLocalization()

    return (
        <div className={styles.container}>
            <div className={classNames(styles.statusIcon, styles.statusIconPending)}>
                <FontAwesomeIcon icon={faPauseCircle} />
            </div>
            <div className={styles.statusLabel}>{ls.getString("pending")}</div>
        </div>
    )
}

function StatusFailed({ reason }: { reason?: string }) {
    const ls = useServiceLocalization()

    return (
        <div className={styles.container}>
            <div className={classNames(styles.statusIcon, styles.statusIconFailed)}>
                <FontAwesomeIcon icon={faTimesCircle} />
            </div>
            <div className={styles.statusLabel}>{ls.getString("failed")}</div>
            {reason && <InfoTip message={reason} className={styles.failedTooltip} />}
        </div>
    )
}
