export class OnPremGatewayService {
    private dataset: OnPremGateway[] = [
        {
            id: "1",
            name: "Branch-Danville",
            description: "ISP doesn't offer static IP so set up with No-IP Dynamic DNS",
            status: OnPremGatewayStatus.REPORTING,
            connection_type: ConnectionTypes.DNS,
            spec: {
                public_ip_fqdn: "bnn123.ddns.net",
                brand: "Unknown",
            },
            policies: [],
        },
        {
            id: "2",
            name: "Branch-Auburn",
            status: OnPremGatewayStatus.REPORTING,
            connection_type: ConnectionTypes.IPSEC,
            spec: {
                public_ip_fqdn: "12.87.117.104",
                brand: "Check Point",
            },
            policies: [],
        },
        {
            id: "3",
            name: "Branch-Davis",
            status: OnPremGatewayStatus.TERMINATED,
            connection_type: ConnectionTypes.IPSEC,
            spec: {
                public_ip_fqdn: "12.87.117.178",
                brand: "Untangle NG",
            },
            policies: [],
        },
        {
            id: "4",
            name: "Branch-Vacaville",
            status: OnPremGatewayStatus.REPORTING,
            connection_type: ConnectionTypes.IPSEC_DNS,
            spec: {
                public_ip_fqdn: "12.87.117.221",
                brand: "SonicWall",
            },
            policies: [],
        },
        {
            id: "5",
            name: "AWS-Transit-Gateway",
            status: OnPremGatewayStatus.PENDING,
            connection_type: ConnectionTypes.IPSEC,
            spec: {
                public_ip_fqdn: "45.24.217.195",
                brand: "AWS",
            },
            policies: [],
        },
        {
            id: "6",
            name: "HQ-Sacramento",
            status: OnPremGatewayStatus.PENDING,
            connection_type: ConnectionTypes.NONE,
            spec: {
                public_ip_fqdn: "12.87.117.37",
                brand: "FortiGate",
            },
            policies: [],
        },
    ]

    public getOnPremGateways(): OnPremGateway[] {
        return this.dataset
    }
}

export interface OnPremGateway {
    id: string
    name: string
    description?: string
    status: OnPremGatewayStatus
    connection_type: ConnectionTypes
    spec: OnPremGatewaySpec
    policies: []
}

export interface OnPremGatewaySpec {
    // need for tunnel &/or dns filtering
    public_ip_fqdn: string

    // pretty charts & reports
    brand?: string
    physical_location?: string

    // identify when on-prem for disabling app features
    mac_address?: string
    disable_app_itp?: boolean
    disable_app_tunnel?: boolean

    // params for IPSec tunnel
    ipsec_json?: string
}

export enum OnPremGatewayStatus {
    ALL_STATUSES = "All Statuses",
    REPORTING = "Reporting",
    PENDING = "Pending",
    TERMINATED = "Terminated",
}

export enum ConnectionTypes {
    NONE = "None",
    DNS = "DNS",
    IPSEC = "IPSec",
    IPSEC_DNS = "IPSec + DNS",
}

export const useServiceOnPremGateway = () => new OnPremGatewayService()
