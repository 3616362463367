import React from "react"
import useDeepCompareEffect from "use-deep-compare-effect"

import { IconType } from "../../components/button/Button.component"
import { ActionBarEvent } from "../components/action-bar/ActionBar.component"
import { Singleton } from "../decorators/Singleton.decorator"
import ServiceContext from "./context"

export { IconType } from "../../components/button/Button.component"

@Singleton("ActionBarService")
export default class ActionBarService {
    public getBreadcrumbs(): Breadcrumb[] {
        return this.breadcrumbs
    }

    public getTitle(): React.ReactNode {
        return this.title
    }

    public getDescription(): React.ReactNode {
        return this.description
    }

    public getShowRefresh(): boolean {
        return this.showRefresh
    }

    public getActions(): ActionBarItem[] {
        return this.actions
    }

    public setActions(actions: ActionBarItem[]): void {
        this.actions = actions
        this.sendActionBarEvent()
    }

    public setItems(
        title: React.ReactNode,
        breadcrumbs: Breadcrumb[] | null = [],
        refreshFn?: Function,
        actions?: ActionBarItem[],
        description?: React.ReactNode
    ): void {
        this.title = title
        this.description = description
        this.breadcrumbs = breadcrumbs || []
        this.showRefresh = refreshFn !== undefined
        this.actions = []
        actions?.forEach((action) => {
            if (typeof action !== "string") {
                this.actions.push(action)
            }
        })

        if (refreshFn) {
            this.registerRefreshFn(refreshFn)
        }

        this.sendActionBarEvent()
    }

    public setTitle(title: React.ReactNode): void {
        this.title = title
        this.sendActionBarEvent()
    }

    public registerRefreshFn(fn: Function): void {
        this.refreshMap.set(fn, fn)
    }

    public unregisterRefreshFn(fn: Function): void {
        this.refreshMap.delete(fn)

        if (this.refreshMap.size === 0) {
            this.showRefresh = false
            this.sendActionBarEvent()
        }
    }

    public onRefresh(): void {
        this.refreshMap.forEach((fn) => fn())
    }

    private breadcrumbs: Breadcrumb[] = []
    private title: React.ReactNode = ""
    private description: React.ReactNode = ""
    private showRefresh: boolean = false
    private actions: ActionBarItem[] = []
    private refreshMap: Map<Function, Function> = new Map()

    private sendActionBarEvent(): void {
        window.dispatchEvent(
            new CustomEvent(ActionBarEvent.ACTION_BAR_EVENT, {
                detail: {
                    title: this.title,
                    description: this.description,
                    breadcrumbs: this.breadcrumbs,
                    showRefresh: this.showRefresh,
                    actions: this.actions,
                },
            })
        )
    }
}

export interface Breadcrumb {
    label: string
    href?: string
}

export interface ActionBarItem {
    icon: IconType
    tooltip: string
    onClick?: React.MouseEventHandler<HTMLButtonElement>
    disabled?: boolean
    className?: string
    label?: string
    href?: string
}

export interface ActionBarEventDetail {
    title: string
    description: string
    breadcrumbs: Breadcrumb[]
    showRefresh: boolean
    showTimeRangePicker: boolean
    actions: ActionBarItem[]
}

export const useServiceActionBar = () =>
    React.useContext(ServiceContext)?.actionBar || new ActionBarService()

export function useActionBar({
    title,
    items,
    fetchData,
    actions,
    description,
}: {
    title: React.ReactNode
    items?: Breadcrumb[]
    fetchData?: () => void | Promise<void>
    actions?: ActionBarItem[]
    description?: React.ReactNode
}) {
    // grab a reference to the action bar service
    const service = useServiceActionBar()

    // make sure the action bar is kept up to date
    useDeepCompareEffect(() => {
        service.setItems(title, items, fetchData, actions, description)

        // clean up the refresh reference
        return () => {
            if (fetchData) {
                service.unregisterRefreshFn(fetchData)
            }
        }
    }, [service, items, actions, title, description, fetchData])
}
