import {
    QueryKey,
    UseQueryResult,
    useMutation,
    useQuery,
    useQueryClient,
} from "@tanstack/react-query"

import { ApiKeyApi, ApiKeyRes, ApiKeyScope } from "../api/ApiKey.api"
import { ApiFunction } from "./shared/QueryKey"

enum ApiKeyHookKey {
    GET_API_KEY = "apiKeyService.getApiKey",
    GET_API_KEY_SCOPE = "apiKeyService.getApiKeyScope",
}

const serviceName = "ApiKeyService"

function getApiKeyKey(apiKeyId: string): QueryKey {
    return [ApiKeyHookKey.GET_API_KEY, apiKeyId]
}

export function useGetApiKey(
    apiKeyId: string,
    options?: QueryOptions<ApiKey>
): UseQueryResult<ApiKey, string> {
    const apiKeyApi = new ApiKeyApi()

    return useQuery({
        ...options,
        queryKey: getApiKeyKey(apiKeyId),
        queryFn: async () => {
            const apiKeyRes = await apiKeyApi.getApiKey(apiKeyId)
            return mapApiKeyResToApiKey(apiKeyRes)
        },
    })
}

export function useGetApiKeyScope(
    options?: QueryOptions<ApiKeyScope[]>
): UseQueryResult<ApiKeyScope[], string> {
    const apiKeyApi = new ApiKeyApi()

    return useQuery({
        ...options,
        queryKey: [ApiKeyHookKey.GET_API_KEY_SCOPE],
        queryFn: async () => {
            const apiKeyRes = await apiKeyApi.getApiKeyScopes()
            return apiKeyRes
        },
    })
}

export function useCreateApiKey(options?: QueryOptions<NewApiKey>) {
    const apiKeyApi = new ApiKeyApi()
    const queryClient = useQueryClient()
    return useMutation<ApiKey, string, NewApiKey>({
        mutationFn: async (data: NewApiKey): Promise<ApiKey> => {
            const response = await apiKeyApi.createApiKey({
                name: data.name,
                description: data.description,
                scope: data.scope,
            })
            return mapApiKeyResToApiKey(response)
        },
        onSuccess: (newKey: ApiKey) => {
            options?.onSuccess?.(newKey)
            queryClient.invalidateQueries([ApiFunction.GET_API_KEYS])
        },
    })
}

export function useGetApiKeys() {
    const apiKeyApi = new ApiKeyApi()

    return useQuery<ApiKey[], string>({
        queryKey: [ApiFunction.GET_API_KEYS, serviceName],
        queryFn: async () => {
            const res: ApiKeyRes[] = await apiKeyApi.getApiKeys()
            return res?.map(mapApiKeyResToApiKey) || []
        },
    })
}

export interface NewApiKey {
    name: string
    description: string
    scope: ApiKeyScope
}

export interface ApiKey {
    id: string
    name: string
    secret: string
    description: string
    scope: ApiKeyScope
}

function mapApiKeyResToApiKey(key: ApiKeyRes): ApiKey {
    return {
        id: key.id,
        name: key.name,
        secret: key.secret,
        description: key.description,
        scope: key.scope,
    }
}
