import React from "react"

import {
    IconType,
    InputType,
    InputWithAction,
    InputWithActionProps,
} from "../input-with-action/InputWithAction.component"

export interface SecretInputProps extends Omit<InputWithActionProps, "onAction" | "icon" | "type"> {
    hideValueLabel: string
    showValueLabel: string
}

/**
 * Provides a secret input that allows the User to toggle the visibility of its
 * input
 */
export const SecretInput = React.forwardRef<HTMLInputElement, SecretInputProps>((props, ref) => {
    const { hideValueLabel, showValueLabel, ...inputProps } = props

    const [isHidden, setIsHidden] = React.useState(true)

    const onHiddenClick: React.MouseEventHandler = (event) => {
        event.preventDefault()
        setIsHidden(!isHidden)
    }

    const { label, inputType, eyeIcon }: HiddenProps = isHidden
        ? { label: showValueLabel, inputType: "password", eyeIcon: IconType.EYE }
        : { label: hideValueLabel, inputType: "text", eyeIcon: IconType.EYE_SLASH }

    return (
        <InputWithAction
            {...inputProps}
            type={inputType}
            icon={eyeIcon}
            onAction={onHiddenClick}
            actionAriaLabel={label}
            ref={ref}
        />
    )
})

interface HiddenProps {
    label: string
    inputType: InputType
    eyeIcon: IconType
}
