import React from "react"

import {
    Button,
    ButtonElement,
    ButtonType,
} from "../../../../../components/button/Button.component"
import { ColDef, Grid } from "../../../../../pre-v3/components"
import { ModalRef } from "../../../../../pre-v3/services/Modal.service"
import { useServiceLocalization } from "../../../../../pre-v3/services/localization/Localization.service"
import AgGridUtil from "../../../../../pre-v3/utils/AgGrid.util"
import { SelectItem } from "../../../../../pre-v3/utils/SelectValue.util"
import { encodeID } from "../../../../../pre-v3/utils/Url.util"
import { ROUTE, formatRoutePath } from "../../../../../routes"
import { Container } from "../../../../components/container/Container.component"
import { Form } from "../../../../components/form/Form.component"
import { ErrorBanners, InfoBanner } from "../../../../components/banner/Banner.component"
import { FormButtons } from "../../../../components/form/form-buttons/FormButtons.component"
import { SelectInput } from "../../../../components/select-input/SelectInput.component"
import { useGetItpPoliciesWithAppInfo } from "../../../../services/PublicResource.service"
import { FormColumn } from "../../../../components/form/FormColumn.component"
import { ToggleButton } from "../../../../components/toggle-button/ToggleButton"
import { Loader } from "../../../../components/loader/Loader.component"
import {
    ItpPolicyDetails,
    useGetItpPolicy,
    useUpdateItpPolicy,
} from "../../../../services/ItpPolicy.service"
import { AppText } from "../../../../components/app-text/AppText.component"
import styles from "./PublicResourceOverview.module.scss"

interface Props {
    modalRef: ModalRef
    appId: string
}

export function ItpPolicyModal({ modalRef, appId }: Props) {
    const localization = useServiceLocalization()

    const {
        isFetching: isFetchingItpPolicy,
        data: itpPolicyList,
        error: itpPoliciesError,
        refetch: itpPoliciesRefetch,
    } = useGetItpPoliciesWithAppInfo()

    const [selectedPolicy, setSelectedPolicy] = React.useState<string>("")

    const itpPolicyOptions: SelectItem[] = React.useMemo(() => {
        if (!itpPolicyList) return []
        return itpPolicyList.map((s) => ({
            displayName: s.name,
            value: s.id,
        }))
    }, [itpPolicyList])

    const deviceCount: number = React.useMemo(() => {
        if (!itpPolicyList) return 0
        const filteredData = itpPolicyList
            .map((policy) => {
                return policy
            })
            .filter((d) => d.id === selectedPolicy)
        return filteredData.length > 0 ? filteredData[0].deviceCount : 0
    }, [itpPolicyList, selectedPolicy])

    const { data: itpPolicyBySelectedId, error: itpPolicyError } = useGetItpPolicy(selectedPolicy, {
        enabled: !!selectedPolicy,
    })

    const {
        isLoading: isUpdatingItpPolicy,
        mutateAsync: updateItpPolicy,
        error: updateItpPolicyError,
    } = useUpdateItpPolicy(itpPolicyBySelectedId as ItpPolicyDetails)

    const itpPoliciesContainingSelectedApp: ItpPolciyGrid[] = React.useMemo(() => {
        if (!itpPolicyList) return []
        const filteredData = itpPolicyList
            .map((policy) => {
                return policy
            })
            .filter((d) => d.allowApps.includes(appId) || d.blockApps.includes(appId))
        return filteredData.map((f) => {
            return {
                id: f.id,
                policyName: f.name,
                state: f.allowApps.includes(appId) ? ItpPolicyState.ALLOW : ItpPolicyState.BLOCK,
            }
        })
    }, [itpPolicyList, appId])

    const alreadyAttachedItpPolicy = itpPoliciesContainingSelectedApp.find(
        (t) => t.id === selectedPolicy
    )
    const [state, setState] = React.useState<ItpPolicyState | undefined>(undefined)

    React.useEffect(() => {
        setState(alreadyAttachedItpPolicy?.state || undefined)
    }, [alreadyAttachedItpPolicy])

    const submitForm = async (event: React.SyntheticEvent) => {
        event.preventDefault()
        if (itpPolicyBySelectedId) {
            let allowedApplications = itpPolicyBySelectedId.allowApplications.value || []
            let blockedApplications = itpPolicyBySelectedId.blockApplications.value || []

            if (appId) {
                if (state === ItpPolicyState.BLOCK) {
                    if (allowedApplications.includes(appId)) {
                        allowedApplications = allowedApplications.filter(
                            (element) => element !== appId
                        )
                    }
                    blockedApplications.push(appId)
                } else if (state === ItpPolicyState.ALLOW) {
                    if (blockedApplications.includes(appId)) {
                        blockedApplications = blockedApplications.filter(
                            (element) => element !== appId
                        )
                    }
                    allowedApplications.push(appId)
                } else {
                    if (
                        blockedApplications.includes(appId) ||
                        allowedApplications.includes(appId)
                    ) {
                        blockedApplications = blockedApplications.filter(
                            (element) => element !== appId
                        )
                        allowedApplications = allowedApplications.filter(
                            (element) => element !== appId
                        )
                    }
                }
            }

            itpPolicyBySelectedId.allowApplications.value = allowedApplications
            itpPolicyBySelectedId.blockApplications.value = blockedApplications
            itpPolicyBySelectedId.allowApplications.enabled =
                allowedApplications.length > 0 ? true : false
            itpPolicyBySelectedId.blockApplications.enabled =
                blockedApplications.length > 0 ? true : false

            const response = await updateItpPolicy(itpPolicyBySelectedId)
            if (response) {
                modalRef.close(itpPoliciesRefetch)
            }
        } else {
            modalRef.cancel("")
        }
    }

    const errors: React.ReactNode[] = [
        typeof itpPolicyError === "string" && itpPolicyError,
        typeof updateItpPolicyError === "string" && updateItpPolicyError,
        typeof itpPoliciesError === "string" && itpPoliciesError,
    ]

    const column: ColDef[] = [
        {
            headerName: localization.getString("policyName"),
            field: "policyName",
            tooltipValueGetter: AgGridUtil.linkTooltipValueGetter,
            flex: 75,
            comparator: AgGridUtil.alphaBetComparator,
        },
        {
            headerName: localization.getString("state"),
            field: "state",
            tooltipValueGetter: AgGridUtil.linkTooltipValueGetter,
            flex: 75,
            comparator: AgGridUtil.alphaBetComparator,
        },
    ]

    const policyId: string = itpPolicyBySelectedId?.id || ""
    const path: string =
        policyId &&
        `${formatRoutePath(ROUTE.INTERNET_THREAT_PROTECTION_DETAILS, {
            id: encodeID(policyId),
        })}?step=DEVICES`
    const url: string = window.location.origin + path

    return (
        <Container>
            <Loader isLoading={isFetchingItpPolicy} medium center>
                <Form onSubmit={submitForm}>
                    <ErrorBanners errors={errors} />
                    <div className={styles.gridContainer}>
                        <p>
                            {localization
                                .getPluralString(
                                    "youCurrentlyHaveCountPolicyActiveForThisApp",
                                    itpPoliciesContainingSelectedApp?.length || 0
                                )
                                .toString()}
                        </p>
                        {itpPoliciesContainingSelectedApp.length > 0 && (
                            <Grid
                                columnDefs={column}
                                rowData={itpPoliciesContainingSelectedApp}
                                pagination
                                autoHeight
                            ></Grid>
                        )}
                    </div>
                    <div className={styles.serviceTunnelContainer}>
                        <FormColumn
                            label={localization.getString("selectAnItpPolicy")}
                            className={styles.selectInput}
                        >
                            <SelectInput
                                options={itpPolicyOptions}
                                value={selectedPolicy}
                                onChange={setSelectedPolicy}
                                disabled={itpPolicyList?.length === 0}
                            />
                        </FormColumn>
                        {selectedPolicy && (
                            <InfoBanner className={styles.infoBanner}>
                                <AppText className={styles.appText}>
                                    {localization.getPluralString(
                                        "thisPolicyContainsCountDeviceDesc",
                                        deviceCount,
                                        url
                                    )}
                                </AppText>
                            </InfoBanner>
                        )}
                    </div>
                    {selectedPolicy && (
                        <div className={styles.serviceTunnelContainer}>
                            <FormColumn
                                label={localization.getString("policyActions")}
                                description={localization.getString("policyActionsDesc")}
                                className={styles.selectInput}
                            />
                            <ToggleButton
                                className={styles.toggleButton}
                                items={[
                                    {
                                        label: localization.getString("allow"),
                                        onClick: () => {
                                            setState(ItpPolicyState.ALLOW)
                                        },
                                        selected: state === ItpPolicyState.ALLOW,
                                    },
                                    {
                                        label: localization.getString("block"),
                                        onClick: () => {
                                            setState(ItpPolicyState.BLOCK)
                                        },
                                        selected: state === ItpPolicyState.BLOCK,
                                    },
                                    {
                                        label: localization.getString("remove"),
                                        onClick: () => {
                                            setState(ItpPolicyState.REMOVE)
                                        },
                                        selected: state === ItpPolicyState.REMOVE,
                                    },
                                ]}
                            />
                        </div>
                    )}
                    <FormButtons
                        rightButtons={
                            <>
                                {itpPolicyList?.length !== 0 && (
                                    <Button
                                        asElement={ButtonElement.BUTTON}
                                        buttonType={ButtonType.SECONDARY}
                                        onClick={modalRef.cancel}
                                    >
                                        {localization.getString("cancel")}
                                    </Button>
                                )}
                                {itpPolicyList?.length === 0 ? (
                                    <Button
                                        asElement={ButtonElement.BUTTON}
                                        buttonType={ButtonType.SECONDARY}
                                        onClick={modalRef.cancel}
                                    >
                                        {localization.getString("close")}
                                    </Button>
                                ) : (
                                    <Button
                                        asElement={ButtonElement.BUTTON}
                                        buttonType={ButtonType.PRIMARY}
                                        loading={isUpdatingItpPolicy}
                                        type="submit"
                                    >
                                        {selectedPolicy
                                            ? localization.getString("save")
                                            : localization.getString("ok")}
                                    </Button>
                                )}
                            </>
                        }
                    />
                </Form>
            </Loader>
        </Container>
    )
}

enum ItpPolicyState {
    ALLOW = "Allow",
    BLOCK = "Block",
    REMOVE = "Remove",
}

export interface ItpPolciyGrid {
    id: string
    policyName: string
    state: ItpPolicyState
}
