import React from "react"
import { Bind } from "../../decorators/Bind.decorator"

export class BaseMenu<P, S extends BaseMenuState> extends React.Component<P, S> {
    public state: S = <S>{ showMenu: false }

    @Bind
    protected openMenu(): void {
        this.setState({
            showMenu: true,
        })
    }

    @Bind
    protected closeMenu(): void {
        this.setState({
            showMenu: false,
        })
    }
}

export interface BaseMenuState {
    showMenu: boolean
}
