import React, { useEffect, useState } from "react"
import { FC } from "react"
import { Accordion } from "../../../../../../v3/components/accordion/Accordion.component"
import { Switch } from "../../../../../../v3/components/switch/Switch.component"
import { FormLabel } from "../../../../../../components/form/FormLabel.component"
import styles from "./GeolocationSettiong.module.scss"
import classNames from "classnames"
import { useServiceLocalization } from "../../../../../services"

interface Props {
    isEnabled: boolean
    onChange: (enabled: boolean) => void
    canUpdateGeoLocationSetting?: boolean
}
export const GeolocationSetting: FC<Props> = ({
    isEnabled,
    onChange,
    canUpdateGeoLocationSetting,
}) => {
    const [isInitiallyEnabled, setIsInitiallyEnabled] = useState<boolean>()
    const localization = useServiceLocalization()

    useEffect(() => {
        setIsInitiallyEnabled(isEnabled)
    }, [])

    return (
        <Accordion
            label={
                <Header
                    isEnabled={isEnabled}
                    onChange={onChange}
                    canUpdateGeoLocationSetting={canUpdateGeoLocationSetting}
                />
            }
            description={localization.getString("useGeolocationDescription")}
            type="plain"
            open
            className={classNames({ [styles.showOnTop]: !isInitiallyEnabled })}
        >
            <div className={styles.accordionContent}>
                <FormLabel
                    label={localization.getString("whatBeingCollected")}
                    description={localization.getString("whatBeingCollectedDescription")}
                />
                <FormLabel
                    label={localization.getString("howDoWeUseTheData")}
                    description={localization.getString("howDoWeUseTheDataDescription")}
                />
            </div>
        </Accordion>
    )
}

function Header({ isEnabled, onChange, canUpdateGeoLocationSetting }: Props) {
    const localization = useServiceLocalization()
    return (
        <div className={styles.header}>
            <label htmlFor="switch">{localization.getString("useGeolocation")}</label>
            <Switch
                value={isEnabled}
                onChange={onChange}
                id="switch"
                disabled={!canUpdateGeoLocationSetting}
            />
        </div>
    )
}
