import React, { ReactNode } from "react"
import ClusterStatusCellRendererTemplate from "./ClusterStatusCellRenderer.template"
import { ClusterInfra } from "../../../../services/Infra.service"
import { LocalizationService } from "../../../../services/localization/Localization.service"

export class ClusterStatusCellRenderer extends React.Component<{}, {}> {
    public render(): ReactNode {
        return ClusterStatusCellRendererTemplate.call(this)
    }

    private localizationService: LocalizationService = new LocalizationService()
}

interface ClusterStatusCellRendererProps {
    data: ClusterInfra
}
