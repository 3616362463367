import { faExternalLinkSquare } from "@fortawesome/pro-regular-svg-icons"
import * as React from "react"

import { Button, ButtonElement, ButtonType } from "../../../../components/button/Button.component"
import { useServiceLocalization } from "../../../../pre-v3/services/localization/Localization.service"
import { ModalRef } from "../../../../pre-v3/services/Modal.service"
import { UrlUtil } from "../../../../pre-v3/utils/Url.util"
import { PublicResourceDomainGrid } from "../../../views/discovered-resources/public-resources/overview/PublicResourceDomainGrid.component"
import { Link } from "../../link/Link.component"
import { Icon } from "../../Icon/Icon.component"
import styles from "./AppDetailsModal.module.scss"

export interface AppDetailsModalProps {
    appDetails: {
        name: string
        description: string
        domains: string[]
        logo: string
        sensitivity: string[]
        category: string
        helpfulLinks: string[]
        url?: string
    }
    modalRef: ModalRef
}

export function AppDetailsModal(props: AppDetailsModalProps): JSX.Element {
    const localization = useServiceLocalization()
    const { appDetails: appDetail } = props

    return (
        <div className={styles.appDetailsModal}>
            <div className={styles.appDetail}>
                {appDetail?.logo && (
                    <div className={styles.appIcon}>
                        <img
                            className={styles.imageIcon}
                            src={appDetail.logo}
                            alt={appDetail.name}
                            aria-label="appIcon"
                        ></img>
                    </div>
                )}

                <HeaderDescription
                    url={appDetail?.url || ""}
                    name={appDetail?.name || ""}
                    description={appDetail?.description || ""}
                />
            </div>

            <div className={styles.appDetailMeta}>
                <div className={styles.metaRow}>
                    <div className={styles.title}>{localization.getString("category")}</div>
                    <div className={styles.value}>{appDetail?.category}</div>
                </div>
                {appDetail.sensitivity.length > 0 && (
                    <SensitivityTags sensitivity={appDetail?.sensitivity || []} />
                )}

                {appDetail?.helpfulLinks.length > 0 && (
                    <HelpfulLinks helpfulLinks={appDetail?.helpfulLinks || []} />
                )}
            </div>

            {appDetail && (
                <div className={styles.appDetailTables}>
                    <PublicResourceDomainGrid resource={appDetail} className={styles.modalHeight} />
                </div>
            )}

            <div className={styles.appDetailsFooter}>
                <Button
                    className={styles.confirmButton}
                    buttonType={ButtonType.PRIMARY}
                    asElement={ButtonElement.BUTTON}
                    type="button"
                    onClick={props.modalRef?.cancel}
                >
                    {localization.getString("ok")}
                </Button>
            </div>
        </div>
    )
}

interface HeaderDescriptionProps {
    url?: string
    name: string
    description: string
}

function HeaderDescription({ url, name, description }: HeaderDescriptionProps) {
    let appNameLink

    if (url) {
        const parsedUrl = UrlUtil.appendHttps(url)
        appNameLink = (
            <Link to={parsedUrl}>
                {name}
                <Icon icon={faExternalLinkSquare} small className={styles.appLink} />
            </Link>
        )
    } else {
        appNameLink = <span>{name}</span>
    }

    return (
        <div className={styles.appDetailHeader}>
            <div className={styles.appDetailTitle}>{appNameLink}</div>
            <div className={styles.appDetailDescription}>{description}</div>
        </div>
    )
}

function SensitivityTags({ sensitivity }: { sensitivity: string[] }) {
    const localization = useServiceLocalization()

    return (
        <div className={styles.metaRow}>
            <div className={styles.title}>{localization.getString("sensitivity")}</div>
            <div className={styles.appDetailSensitivity}>{sensitivity.join(", ")}</div>
        </div>
    )
}

function HelpfulLinks({ helpfulLinks }: { helpfulLinks: string[] }) {
    const localization = useServiceLocalization()

    return (
        <div className={styles.metaRow}>
            <div className={styles.title}>{localization.getString("helpfulLinks")}</div>
            <div className={styles.appDetailHelpfulLinks}>
                {helpfulLinks.map((link, index) => {
                    return (
                        <React.Fragment key={index}>
                            <Link to={link} aria-label={localization.getString("helpfulLinks")}>
                                {link}
                            </Link>
                            {index < helpfulLinks.length - 1 && (
                                <span className={styles.comma}>{","}</span>
                            )}
                        </React.Fragment>
                    )
                })}
            </div>
        </div>
    )
}
