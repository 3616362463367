import React, { ReactNode, SyntheticEvent } from "react"
import FilterMenuTemplate from "./FilterMenu.template"
import { LocalizationService } from "../../services/localization/Localization.service"
import { Bind } from "../../decorators/Bind.decorator"
import { DataFilter, DataFilterType, FilterModel } from "../../utils/AgGrid.util"
import { SelectValue, SelectItem } from "../../utils/SelectValue.util"
import { Debounce } from "../../decorators/Debounce.decorator"
import { DateUtil, TimeRange } from "../../utils/Date.util"

export class FilterMenu extends React.Component<FilterMenuProps, FilterMenuState> {
    public state: FilterMenuState = { showFilterMenu: false }

    public componentDidMount(): void {
        if (this.props.onReady) {
            const filterMenuApi = new FilterMenuApi(this)
            this.props.onReady(filterMenuApi)
        }
    }

    public render(): ReactNode {
        return FilterMenuTemplate.call(this)
    }

    private ls: LocalizationService = new LocalizationService()

    @Bind
    public openMenu(key?: string): void {
        let selectedFilter: DataFilter | undefined = undefined
        if (key) {
            selectedFilter = this.props.filters.find((f) => f.key === key)
        }
        this.setState({ showFilterMenu: !key, selectedFilter: selectedFilter })
    }

    @Bind
    public closeMenu(): void {
        this.setState({ showFilterMenu: false, selectedFilter: undefined })
    }

    @Bind
    private onViewAll(): void {
        this.setState({ showFilterMenu: true, selectedFilter: undefined })
    }

    @Bind
    private onViewFilter(filter: DataFilter): void {
        // filter.value = "";
        this.setState({ showFilterMenu: false, selectedFilter: filter })
    }

    @Bind
    private onFilterKeyPress(event: KeyboardEvent): void {
        if (event.key === "Enter") {
            this.onFilterSubmit()
        }
    }

    @Bind
    private onInputFilterChange(event: SyntheticEvent): void {
        const value: string = (event.target as HTMLInputElement).value
        if (this.state.selectedFilter) {
            this.state.selectedFilter.value = value
            this.setState({ selectedFilter: this.state.selectedFilter })
        }
    }

    @Bind
    private onTimeRangeChange(timeRange: TimeRange): void {
        if (this.state.selectedFilter) {
            this.setState(
                {
                    selectedFilter: {
                        ...this.state.selectedFilter,
                        value: DateUtil.serializeTimeRange(timeRange),
                    },
                },
                this.onFilterSubmit
            )
        }
    }

    @Bind
    private onMultiInputFilterChange(value: string[]): void {
        if (this.state.selectedFilter) {
            this.state.selectedFilter.value = value
            this.setState({ selectedFilter: this.state.selectedFilter })
        }
    }

    @Bind
    private onMultiListFilterChange(value: string[]): void {
        if (this.state.selectedFilter) {
            this.state.selectedFilter.value = value
            this.setState({ selectedFilter: this.state.selectedFilter })
            this.onFilterSubmit()
        }
    }

    @Bind
    private onLookupSelected(value: SelectValue | SelectValue[]): void {
        if (this.state.selectedFilter) {
            this.state.selectedFilter.value = value
            this.onFilterSubmit()
        }
    }

    @Bind
    private onDebouncedInputFilterChange(event: SyntheticEvent): void {
        const value: string = (event.target as HTMLInputElement).value
        if (this.state.selectedFilter) {
            this.state.selectedFilter.value = value
            this.setState({ selectedFilter: this.state.selectedFilter })
            this.emit()
        }
    }

    @Debounce
    private emit(): void {
        this.onFilterSubmit()
    }

    @Bind
    private onFilterSubmit(): void {
        const previousModel: FilterModel = this.props.model ?? {}
        if (!this.state.selectedFilter) return this.props.onChange?.(previousModel)

        if (
            ![
                DataFilterType.MULTI_LIST,
                DataFilterType.MULTIINPUT,
                DataFilterType.MULTILOOKUP,
                DataFilterType.DEBOUNCEDINPUT,
            ].includes(this.state.selectedFilter.type)
        ) {
            this.setState({ showFilterMenu: false, selectedFilter: undefined })
        }

        const updatedModel: FilterModel = {
            ...previousModel,
            [this.state.selectedFilter.key]: {
                filter: this.serializeFilterValue(this.state.selectedFilter),
            },
        }

        this.props.onChange?.(updatedModel)
    }

    private serializeFilterValue(filter: DataFilter): string {
        if (filter.value) {
            // the filter could be a list of strings, just concatenate them with |
            if (Array.isArray(filter.value)) {
                const items: SelectItem[] = filter.value as SelectItem[]
                const strs: string[] = filter.value as string[]
                if (items.length > 0) {
                    if (items[0].value) {
                        // Array of SelectItem
                        return items.map((i) => i.value).join("|")
                    } else {
                        // Array of String
                        return strs.join("|")
                    }
                } else {
                    return ""
                }
            } else {
                const item: SelectItem = filter.value as SelectItem
                const str: string = filter.value as string
                // Single SelectItem or String
                return item.value || str
            }
        } else {
            return ""
        }
    }
}

export class FilterMenuApi {
    constructor(filterMenu: FilterMenu) {
        this.filterMenu = filterMenu
    }

    public show(key?: string): void {
        if (this.filterMenu) {
            this.filterMenu.openMenu(key)
        }
    }

    public hide(): void {
        if (this.filterMenu) {
            this.filterMenu.closeMenu()
        }
    }

    private filterMenu: FilterMenu
}

interface FilterMenuProps {
    buttonClassName: string
    menuClassName: string
    filterViewClassName: string
    filters: DataFilter[]
    model: FilterModel
    onReady?: (api: FilterMenuApi) => void
    onChange?: (model: FilterModel) => void
}

interface FilterMenuState {
    showFilterMenu: boolean
    selectedFilter?: DataFilter
}
