import { faPlus } from "@fortawesome/pro-regular-svg-icons"
import React from "react"

import { useServiceLocalization } from "../../../pre-v3/services/localization/Localization.service"
import { PatternUtil } from "../../../pre-v3/utils/Pattern.util"
import { Accordion } from "../../../v3/components/accordion/Accordion.component"
import { Input } from "../../../v3/components/input/Input.component"
import {
    MenuSelect,
    MenuSelectOption,
} from "../../../v3/components/menu-select/MenuSelect.component"
import { Option, SelectInput } from "../../../v3/components/select-input/SelectInput.component"
import { ToggleButton, ToggleButtonItem } from "../../../v3/components/toggle-button/ToggleButton"
import { AccessTierGroup, ApiKey } from "../../../v3/services/AccessTier.service"
import { FormRow } from "../../../components/form/FormRow.component"
import { Banner, Variant } from "../../../components/banner/Banner.component"
import styles from "./AccessTierGeneralInformation.module.scss"

export interface GeneralInformationValues {
    name?: string
    description?: string
    apiKey: ApiKey
    accessTierGroup?: AccessTierGroup
    publicAddress?: string
}

interface Props {
    values: GeneralInformationValues
    apiKeys: ApiKey[]
    accessTierGroups: AccessTierGroup[]
    canAccessAccessTierGroups: boolean
    isCreateMode?: boolean
    isEditMode?: boolean
    isServiceTunnelSecured?: boolean
    onChange(values: GeneralInformationValues): void
    onApiKeyChange(apiKey: ApiKey): void
    onServiceTunnelSecuredChange(isServiceTunnelSecured: boolean): void
}

export function AccessTierGeneralInformation(props: Props): JSX.Element {
    const localization = useServiceLocalization()

    const apiKeyOptions = React.useMemo(
        () =>
            props.apiKeys.map<Option>((key) => ({
                value: key.id,
                displayName: key.name,
            })),
        [props.apiKeys]
    )

    const accessTierGroupOptions = React.useMemo(
        () => props.accessTierGroups.map(getAccessTierGroupOption),
        [props.accessTierGroups]
    )

    const onChangeName = (name: string) => props.onChange({ ...props.values, name })
    const onDescriptionChange = (description: string) =>
        props.onChange({ ...props.values, description })
    const onPublicAddressChange = (publicAddress: string) =>
        props.onChange({ ...props.values, publicAddress })
    const onApiKeyChange = (apiKeyId: string) => {
        const apiKey = props.apiKeys.find((key) => key.id === apiKeyId)
        apiKey && props.onApiKeyChange(apiKey)
    }
    const onChangeAccessTierGroup = (accessTierGroupId: string) =>
        props.onChange({
            ...props.values,
            accessTierGroup: props.accessTierGroups.find((group) => group.id === accessTierGroupId),
        })

    const isServiceTunnelSecuredItems = React.useMemo<ToggleButtonItem[]>(
        () => [
            {
                label: localization.getString("yes"),
                onClick: () => props.onServiceTunnelSecuredChange(true),
                selected: props.isServiceTunnelSecured,
            },
            {
                label: localization.getString("no"),
                onClick: () => props.onServiceTunnelSecuredChange(false),
                selected: !props.isServiceTunnelSecured,
            },
        ],
        [localization, props.isServiceTunnelSecured, props.onServiceTunnelSecuredChange]
    )

    const isDisabled = !props.isCreateMode && !props.isEditMode

    return (
        <Accordion label={localization.getString("generalInformation")} defaultOpen type="plain">
            <div className={styles.accordionContent}>
                <FormRow
                    label={localization.getString("accessTierName")}
                    description={localization.getString("accessTierNameDesc")}
                    htmlFor={Id.NAME}
                >
                    <Input
                        id={Id.NAME}
                        type="text"
                        placeholder={localization.getString("accessTierName")}
                        value={props.values.name ?? ""}
                        onChangeValue={onChangeName}
                        required
                        disabled={!props.isCreateMode}
                    />
                </FormRow>
                <FormRow
                    label={localization.getString(
                        "somethingOptional",
                        localization.getString("description")
                    )}
                    description={localization.getString(
                        "anOptionalFieldShownInTheWebConsoleToAdminUsersOnlyForYourOwnInternalNotes"
                    )}
                >
                    <Input
                        type="text"
                        placeholder={localization.getString(
                            "somethingOptional",
                            localization.getString("description")
                        )}
                        value={props.values.description ?? ""}
                        onChangeValue={onDescriptionChange}
                        disabled={isDisabled}
                    />
                </FormRow>
                <FormRow
                    label={localization.getString("apiKey")}
                    description={localization.getString("apiKeyDesc")}
                >
                    <SelectInput
                        disabled={isDisabled}
                        required
                        value={props.values.apiKey.id}
                        onChange={onApiKeyChange}
                        options={apiKeyOptions}
                    />
                </FormRow>
                {!props.isCreateMode && (
                    <FormRow
                        label={localization.getString("publicAddressOfAccessTier")}
                        description={localization.getString("publicAddressOfAccessTierDesc")}
                        htmlFor={Id.PUBLIC_ADDRESS}
                    >
                        <Input
                            id={Id.PUBLIC_ADDRESS}
                            type="text"
                            placeholder={localization.getString(
                                "publicAddressOfAccessTierPlaceholder"
                            )}
                            value={props.values.publicAddress ?? ""}
                            onChangeValue={onPublicAddressChange}
                            disabled={isDisabled}
                            pattern={PatternUtil.NO_SPACE.source}
                            title={localization.getString("publicAddressShouldNotContainAnySpaces")}
                        />
                    </FormRow>
                )}
                {props.canAccessAccessTierGroups && (
                    <React.Fragment>
                        <Banner
                            label={localization.getString("accessTierGroupInfoBanner")}
                            variant={Variant.INFORMATION}
                        />
                        <FormRow
                            label={localization.getString("accessTierGroup")}
                            description={localization.getString(
                                "attachToAnAccessTierGroupDescription"
                            )}
                            htmlFor={Id.ACCESS_TIER_GROUP}
                        >
                            {props.isCreateMode || props.isEditMode ? (
                                <MenuSelect
                                    label={localization.getString("attachToAnAccessTierGroup")}
                                    options={accessTierGroupOptions}
                                    value={props.values.accessTierGroup?.id ?? ""}
                                    onSelect={onChangeAccessTierGroup}
                                    icon={faPlus}
                                />
                            ) : (
                                <Input
                                    id={Id.ACCESS_TIER_GROUP}
                                    value={props.values.accessTierGroup?.name}
                                    disabled
                                />
                            )}
                        </FormRow>
                    </React.Fragment>
                )}
                <FormRow
                    label={localization.getString("willYouBeSecuringServiceTunnelWithAccessTier")}
                    childrenClassName={styles.isServiceTunnelSecured}
                >
                    <ToggleButton disabled={isDisabled} items={isServiceTunnelSecuredItems} />
                </FormRow>
            </div>
        </Accordion>
    )
}

enum Id {
    NAME = "name",
    PUBLIC_ADDRESS = "publicAddress",
    ACCESS_TIER_GROUP = "accessTierGroup",
}

function getAccessTierGroupOption(accessTierGroup: AccessTierGroup): MenuSelectOption {
    return {
        label: accessTierGroup.name,
        value: accessTierGroup.id,
    }
}
