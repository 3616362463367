import React from "react"
import { useServiceLinks, useServiceLocalization } from "../../../../../pre-v3/services"
import { AppText } from "../../../../components/app-text/AppText.component"
import { CodeWithCopy } from "../../../../components/code-with-copy/CodeWithCopy"
import { getConnectivityParameters } from "../../../../services/AccessTier.service"
import styles from "../AccessTiersCreate.module.scss"

export function DockerInstaller({
    apiSecretKey,
    accessTierName,
    hideDoc,
    netagentVersion: netAgentVersion,
    udpPortNumber,
    canAccessAccessTierInstallStep,
}: Props) {
    const ls = useServiceLocalization()
    const links = useServiceLinks()

    return (
        <>
            <p className={styles.title}>{ls.getString("installationSteps")}</p>
            <div className={styles.row}>
                <label className={styles.label}>
                    {ls.getString("dockerConnectivityDescription")}
                </label>
                <CodeWithCopy
                    className={styles.textBox}
                    title={ls.getString("step", "1")}
                    text={getConnectivityParameters(apiSecretKey, accessTierName)}
                    hideCopyButton={!canAccessAccessTierInstallStep}
                />
            </div>
            <br />
            <div className={styles.row}>
                <label className={styles.label}>{ls.getString("dockerInstallDescription")}</label>

                <CodeWithCopy
                    className={styles.textBox}
                    title={ls.getString("step", "2")}
                    text={getDockerInstallCommand(udpPortNumber, netAgentVersion)}
                    hideCopyButton={!canAccessAccessTierInstallStep}
                />
            </div>
            {!hideDoc && (
                <AppText
                    className={styles.info}
                    ls={{
                        key: "pleaseCheckDocumentForPreReqAndMoreDetails",
                        replaceVals: [links.getLink("dockerInstallDocForAccessTier")],
                    }}
                />
            )}
        </>
    )
}

interface Props {
    apiSecretKey?: string
    accessTierName?: string
    hideDoc?: boolean
    netagentVersion: string
    udpPortNumber: number
    canAccessAccessTierInstallStep?: boolean
}

function getDockerInstallCommand(udpPortNumber: number, netAgentVersion: string) {
    return `sudo -E docker run --privileged --cap-add=NET_ADMIN \\
--restart unless-stopped \\
-v /dev:/dev -v /lib/modules:/lib/modules \\
-e API_KEY_SECRET -e COMMAND_CENTER_URL -e ACCESS_TIER_NAME \\
-p 80:80/tcp -p 443:443/tcp -p 8443:8443/tcp -p ${udpPortNumber}:${udpPortNumber}/udp \\
-d gcr.io/banyan-pub/netagent:${netAgentVersion}`
}
