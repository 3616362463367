import { faExclamationCircle } from "@fortawesome/pro-regular-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import classNames from "classnames/bind"
import React from "react"

import sonicWallCSELogo from "../../../../images/logo-horiz-black.svg"
import styles from "./BrowserErrorPreview.module.scss"

export interface BrowserErrorPreviewProps {
    logoFile?: string
    primaryColor?: string
    errorMessage?: string
    errorLink?: string
    className?: string
}

export function BrowserErrorPreview(props: BrowserErrorPreviewProps): JSX.Element {
    // TODO: Change this type to use satisfies once we upgrade to TypeScript 4.9
    const topBarStyle: React.CSSProperties | undefined = props.primaryColor
        ? { backgroundColor: props.primaryColor }
        : undefined

    const logoImageStyle: React.CSSProperties | undefined = props.logoFile
        ? { backgroundImage: `url(${props.logoFile})` }
        : undefined

    return (
        <div className={classNames(styles.container, props.className)}>
            <div className={styles.topBar} style={topBarStyle}>
                <div className={styles.logoImage} style={logoImageStyle} />
            </div>
            <div className={styles.content}>
                <div className={classNames(styles.card, styles.title)}>
                    <FontAwesomeIcon icon={faExclamationCircle} />
                    Banyan Service Unavailable
                </div>
                <div className={classNames(styles.card, styles.body)}>
                    <div>
                        There was an error with your request. This message generated automatically.
                        Some additional text goes here.
                    </div>
                    {props.errorMessage && <div>{props.errorMessage}</div>}
                    {props.errorLink && <div className={styles.button}>Get Help</div>}
                </div>
                <table className={classNames(styles.card, styles.table)}>
                    <thead>
                        <tr>
                            <th scope="col" align="left">
                                Reference ID:
                            </th>
                            <th scope="col" align="left">
                                Timestamp:
                            </th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td>ABCDEF-1234567890</td>
                            <td>2023-05-06 10:0025 UTC</td>
                        </tr>
                    </tbody>
                </table>
                {props.logoFile && (
                    <div className={styles.poweredBy}>
                        Powered by:
                        <img src={sonicWallCSELogo} width={60} alt="Banyan Security" />
                    </div>
                )}
            </div>
        </div>
    )
}
