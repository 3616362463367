import { LanguageKey } from "../../../pre-v3/services/localization/languages/en-US.language"

export enum DeviceOwnership {
    CORPORATE_DEDICATED = "Corporate Dedicated",
    CORPORATE_SHARED = "Corporate Shared",
    EMPLOYEE_OWNED = "Employee Owned",
    OTHER = "Other",
}

export const allDeviceOwnership: DeviceOwnership[] = Object.values(DeviceOwnership)

export const noDeviceOwnership: Record<DeviceOwnership, boolean> = {
    [DeviceOwnership.CORPORATE_DEDICATED]: false,
    [DeviceOwnership.CORPORATE_SHARED]: false,
    [DeviceOwnership.EMPLOYEE_OWNED]: false,
    [DeviceOwnership.OTHER]: false,
}

export const deviceOwnershipLabelDict: Record<DeviceOwnership, LanguageKey> = {
    [DeviceOwnership.CORPORATE_DEDICATED]: "corporateDedicated",
    [DeviceOwnership.CORPORATE_SHARED]: "corporateShared",
    [DeviceOwnership.EMPLOYEE_OWNED]: "employeeOwned",
    [DeviceOwnership.OTHER]: "other",
}
