import { faTimes } from "@fortawesome/pro-regular-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import React from "react"
import { FilterMenu } from "../filter-menu/FilterMenu.component"
import styles from "./FilterBar.module.scss"
import classNames from "classnames/bind"
import { Input } from ".."

function renderPill(p) {
    return (
        <div key={p.key} className={styles.pill}>
            <div className={styles.label} onClick={() => this.onFilterClick(p.key)}>
                {`${p.displayName}: ${p.value}`}
            </div>
            <div className={styles.iconContainer} onClick={() => this.onRemoveFilter(p.key)}>
                <FontAwesomeIcon icon={faTimes} className={styles.icon} />
            </div>
        </div>
    )
}

export default function () {
    return (
        <div className={classNames(styles.container, this.props.className)}>
            {this.state.pills && this.state.pills.map((p) => renderPill.call(this, p))}
            <FilterMenu
                buttonClassName={styles.filterMenuButton}
                menuClassName={styles.filterMenu}
                filterViewClassName={styles.filterView}
                filters={this.props.filters}
                onReady={this.onFilterMenuReady}
                onChange={this.onFilterChange}
                model={this.state.model}
            />
            <Input
                onKeyPress={this.onShowMenu}
                className={styles.input}
                aria-label={this.ls.getString("filterBy")}
                placeholder={this.ls.getString("filterBy") + "..."}
                value=""
                onChange={() => {}}
                onClick={this.onShowMenu}
            />
        </div>
    )
}
