import * as React from "react"

import { ROUTE } from "../../../../routes"
import { useServiceLocalization } from "../../../services/localization/Localization.service"
import { ServicesStatsDisplayData, useServiceStats } from "../../../services/Stats.service"
import { CountWidget } from "../Widget"

export function Services() {
    const localization = useServiceLocalization()
    const stats = useServiceStats()

    const [data, setData] = React.useState<ServicesStatsDisplayData | null>(null)

    React.useEffect(() => {
        stats.getServicesStatsDisplayData().then(setData)
    }, [stats])

    return (
        <CountWidget
            gridArea="service-count"
            title={localization.getString("services")}
            count={data?.total}
            link={ROUTE.HOSTED_WEBSITES}
        />
    )
}
