import { ICellRendererParams } from "ag-grid-community"
import React, { ReactNode, SyntheticEvent } from "react"

import { ROUTE, formatRoutePath } from "../../../../../routes"
import { Bind } from "../../../../decorators/Bind.decorator"
import { encodeID } from "../../../../utils/Url.util"
import DevicesCellRendererTemplate from "./DevicesCellRenderer.template"

export class DevicesCellRenderer extends React.Component<
    DevicesCellRendererProps,
    DevicesCellRendererState
> {
    public static getDerivedStateFromProps(
        props: DevicesCellRendererProps
    ): DevicesCellRendererState {
        return {
            link: props.data.serial
                ? formatRoutePath(ROUTE.DEVICES_DETAILS, {
                      serialNumber: encodeID(props.data.serial),
                  })
                : "",
            serial: props.data.serial || "",
        }
    }

    public state: DevicesCellRendererState = { link: "", serial: "" }

    public render(): ReactNode {
        return DevicesCellRendererTemplate.call(this)
    }

    public refresh(): boolean {
        return true
    }

    @Bind
    private clickHandler(event: SyntheticEvent): void {
        event.preventDefault()
        this.props.context.history.push(this.state.link)
    }
}

interface DevicesCellRendererProps extends ICellRendererParams {
    context: any
}

interface DevicesCellRendererState {
    serial: string
    link: string
}
