import { faCheckCircle, faCopy } from "@fortawesome/pro-regular-svg-icons"
import classNames from "classnames/bind"
import React, { SyntheticEvent, useRef, useState } from "react"
import { LocalizationService } from "../../../pre-v3/services"
import { IconButton } from "../icon-button/IconButton.component"
import { Tooltip } from "../tooltip/Tooltip.component"
import styles from "./CopyButton.module.scss"

export function CopyButton(props: CopyButtonProps) {
    const ls: LocalizationService = new LocalizationService()

    const [isActive, setIsActive] = useState<boolean>(false)
    const timeout = useRef<number>(0)

    function onCopy(event: SyntheticEvent): void {
        event.stopPropagation()
        if (timeout.current) {
            window.clearTimeout(timeout.current)
            timeout.current = 0
        }

        setIsActive(true)
        timeout.current = window.setTimeout(() => {
            setIsActive(false)
        }, 1000)

        props.onCopy?.()
    }

    return (
        <Tooltip title={ls.getString("copy")}>
            <IconButton
                className={classNames({ [styles.active]: isActive }, props.className)}
                icon={isActive ? faCheckCircle : faCopy}
                action={props.action}
                brand={props.brand}
                onClick={onCopy}
            />
        </Tooltip>
    )
}

interface CopyButtonProps {
    action?: boolean
    brand?: boolean
    className?: string
    onCopy?: () => void
}
