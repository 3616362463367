import { Singleton } from "../../pre-v3/decorators/Singleton.decorator"
import { UrlUtil } from "../../pre-v3/utils/Url.util"
import { BaseApi, PaginatedResponse, PaginationOptions } from "./Base.api"

@Singleton("ApplicationsApi")
export class ApplicationsApi extends BaseApi {
    public getApplications(
        search: ApplicationSearch
    ): Promise<PaginatedResponse<"application_inventory", ApplicationRes>> {
        if (!search.limit) {
            search.limit = 100
        }
        const params: URLSearchParams = UrlUtil.convertToURLSearchParams(search)
        return this.get("/api/v2/appdiscovery/applications?" + params.toString(), {
            default: this.localization.getString("failedToFetchApplications"),
        })
    }

    public getApplicationById(id: string): Promise<ApplicationDetailRes> {
        return this.get(`/api/v2/appdiscovery/applications/${id}`, {
            default: this.localization.getString("failedToFetchApplication"),
        })
    }

    public updateApplicationStatus(id: string, data: IgnoreResourceReq): Promise<void> {
        return this.post(`/api/v2/appdiscovery/org/applications/${id}`, data, {
            default: this.localization.getString("failedToUpdateApplicationStatus"),
        })
    }

    public updateSecurityAction(id: string, data: SecurityActionReq): Promise<void> {
        return this.post(`/api/v2/appdiscovery/org/applications/${id}`, data, {
            default: this.localization.getString("failedToUpdateSecurityAction"),
        })
    }

    public getUsersAndDevicesInfo(
        id: string,
        search: ApplicationSearch
    ): Promise<PaginatedResponse<"users_devices", UserDeviceRes>> {
        if (!search.limit) {
            search.limit = 100
        }
        const params: URLSearchParams = UrlUtil.convertToURLSearchParams(search)
        return this.get(
            `/api/v2/appdiscovery/applications/${id}/users_devices?` + params.toString(),
            {
                default: this.localization.getString("failedToFetchUserAndDeviceInformation"),
            }
        )
    }

    public createCustomApplication(app: CustomApplicationRes): Promise<string> {
        return this.post("/api/v2/appdiscovery/applications", app, {
            default: this.localization.getString("createCustomAppError"),
        })
    }

    public updateCustomApplication(id: string, app: CustomApplicationRes): Promise<string> {
        return this.put(`/api/v2/appdiscovery/applications/${id}`, app, {
            default: this.localization.getString("editCustomAppError"),
        })
    }

    public deleteCustomApplication(id: string) {
        return this.delete(`/api/v2/appdiscovery/applications/${id}`, undefined, {
            default: this.localization.getString("errorDeletingCustomApp"),
        })
    }
}

export interface IgnoreResourceReq {
    ignore: boolean
}

export interface SecurityActionReq {
    identity_provider_federated?: boolean
    ip_allowed?: boolean
    dlp_policy?: boolean
    block?: boolean
}

export interface ApplicationSearch extends PaginationOptions {
    category?: string
    status?: string
    application_name?: string
    app_type?: AppType
    bnn_itp?: boolean
    bnn_tunnel?: boolean
}

export type Status = "Protected" | "Discovered" | "Ignored"
export type AppType = "saas" | "consumer" | "custom"

export interface ApplicationRes {
    application_id: string
    application_name: string
    number_of_devices: number
    status: Status
    category: string
    logo_bitmap: string
    sensitivity: string
    url: string
    helpful_links: string[]
    app_type: AppType
    bnn_itp: boolean
    bnn_tunnel: boolean
    bnn_dlp: boolean
    bnn_fed: boolean
}

export interface ApplicationDetailRes {
    application_name: string
    block: boolean
    description: string
    dlp_policy: boolean
    domains: string[]
    identityProvider_federated: boolean
    ignore: boolean
    ip_allowed: boolean
    logo_bitmap: string
    networks: string[]
    sensitivity: string
    status: Status
    category: string
    url: string
    helpful_links: string[]
    bnn_itp: boolean
    bnn_tunnel: boolean
    bnn_dlp: boolean
    bnn_fed: boolean
    app_type: AppType
}

export interface UserDeviceRes {
    device_name: string
    user_name: string
    timestamp: number
    roles: string[]
}

export interface CustomApplicationRes {
    application_name: string
    description?: string
    url?: string
    logo?: string
    domains: string[]
    helpful_links?: string[]
}
