import React from "react"

import { useServiceLocalization } from "../../../../../../../pre-v3/services"
import { useUrlSearch, decodeID } from "../../../../../../../pre-v3/utils/Url.util"
import {
    TrustProfileDetail,
    NewTrustProfile,
    NewTrustProfileDetail,
    TrustFactor,
    useGetTrustFactors,
    useGetTrustProfile,
} from "../../../../../../services/TrustProfile.service"

interface UseCreateTrustProfileResult {
    currentStep: CurrentStep
    onFillDetailsAndAssignment(profile: NewTrustProfile): void
    onError(error: string): void
    onGoBackToDetailsAndAssignmentClick(profile: NewTrustProfileDetail): void
}

export function useCreateTrustProfile(): UseCreateTrustProfileResult {
    const localization = useServiceLocalization()

    const { trustProfileId } = useUrlSearch("trustProfileId")
    const isExpectingCopy = typeof trustProfileId === "string"

    const [state, dispatch] = React.useReducer(trustProfileReducer, isExpectingCopy, initialize)

    // We cannot rely on the on success callback since it won't trigger if
    // Trust Profile is in the cache. For now, we can use React's useEffect.
    const { data: trustProfileToCopy } = useGetTrustProfile(
        trustProfileId && decodeID(trustProfileId),
        {
            enabled: isExpectingCopy,
            onError: (error) =>
                typeof error === "string"
                    ? dispatch({ type: EventType.PROFILE_FETCHING_FAILED, error })
                    : console.error(error),
        }
    )

    React.useEffect(() => {
        if (trustProfileToCopy) {
            dispatch({
                type: EventType.PROFILE_TO_COPY_FETCHED,
                profile: trustProfileToCopy,
                copyName: localization.getString("copyOfSomething", trustProfileToCopy.name),
            })
        }
    }, [localization, trustProfileToCopy])

    useGetTrustFactors({
        enabled: !isExpectingCopy,
        onSuccess: (trustFactors) =>
            dispatch({ type: EventType.TRUST_FACTORS_FETCHED, trustFactors }),
        onError: (error) =>
            typeof error === "string"
                ? dispatch({ type: EventType.TRUST_FACTORS_FETCHING_FAILED, error })
                : console.error(error),
    })

    const onFillDetailsAndAssignment = React.useCallback(
        (profile: NewTrustProfile): void =>
            dispatch({ type: EventType.DETAILS_AND_ASSIGNMENT_FILLED, profile }),
        []
    )

    const onError = React.useCallback(
        (error: string): void => dispatch({ type: EventType.PROFILE_FETCHING_FAILED, error }),
        []
    )

    const onGoBackToDetailsAndAssignmentClick = React.useCallback(
        (profile: NewTrustProfileDetail): void =>
            dispatch({ type: EventType.GO_BACK_TO_DETAILS_AND_ASSIGNMENT_CLICK, profile }),
        []
    )

    return {
        currentStep: state,
        onFillDetailsAndAssignment,
        onError,
        onGoBackToDetailsAndAssignmentClick,
    }
}

export enum Step {
    DETAILS_AND_ASSIGNMENT = "DETAILS_AND_ASSIGNMENT",
    TRUST_FACTORS = "TRUST_FACTORS",
}

export const steps = Object.values(Step)

export interface BaseStep {
    step: Step
    isLoading?: boolean
    profileFetchingError?: string
    profile?: NewTrustProfileDetail
}

export type CurrentStep = DetailsAndAssignmentStep | TrustFactorsStep

export interface DetailsAndAssignmentStep extends BaseStep {
    step: Step.DETAILS_AND_ASSIGNMENT
    profileToCopy?: TrustProfileDetail
    trustFactorFetchingError?: string
    trustFactors?: TrustFactor[]
}

function initialize(isExpectingCopy: boolean): DetailsAndAssignmentStep {
    return {
        step: Step.DETAILS_AND_ASSIGNMENT,
        isLoading: isExpectingCopy,
    }
}

interface TrustFactorsStep extends BaseStep {
    step: Step.TRUST_FACTORS
    profile: NewTrustProfileDetail
}

enum EventType {
    GO_BACK_TO_DETAILS_AND_ASSIGNMENT_CLICK = "GO_BACK_TO_DETAILS_AND_ASSIGNMENT_CLICK",
    PROFILE_TO_COPY_FETCHED = "PROFILE_TO_COPY_FETCHED",
    PROFILE_FETCHING_FAILED = "PROFILE_FETCHING_FAILED",
    TRUST_FACTORS_FETCHED = "TRUST_FACTORS_FETCHED",
    TRUST_FACTORS_FETCHING_FAILED = "TRUST_FACTORS_FETCHING_FAILED",
    DETAILS_AND_ASSIGNMENT_FILLED = "DETAILS_AND_ASSIGNMENT_FILLED",
}

type Event =
    | GoBackToDetailsAndAssignmentClick
    | ProfileToCopyFetched
    | ProfileFetchingFailed
    | TrustFactorsFetched
    | TrustFactorsFetchingFailed
    | DetailsAndAssignmentFilled

interface GoBackToDetailsAndAssignmentClick {
    type: EventType.GO_BACK_TO_DETAILS_AND_ASSIGNMENT_CLICK
    profile: NewTrustProfileDetail
}

interface ProfileToCopyFetched {
    type: EventType.PROFILE_TO_COPY_FETCHED
    profile: TrustProfileDetail
    copyName: string
}

interface ProfileFetchingFailed {
    type: EventType.PROFILE_FETCHING_FAILED
    error: string
}

interface TrustFactorsFetched {
    type: EventType.TRUST_FACTORS_FETCHED
    trustFactors: TrustFactor[]
}

interface TrustFactorsFetchingFailed {
    type: EventType.TRUST_FACTORS_FETCHING_FAILED
    error: string
}

interface DetailsAndAssignmentFilled {
    type: EventType.DETAILS_AND_ASSIGNMENT_FILLED
    profile: NewTrustProfile
}

function trustProfileReducer(currentStep: CurrentStep, event: Event): CurrentStep {
    switch (event.type) {
        case EventType.GO_BACK_TO_DETAILS_AND_ASSIGNMENT_CLICK:
            return currentStep.step === Step.DETAILS_AND_ASSIGNMENT
                ? currentStep
                : { ...currentStep, step: Step.DETAILS_AND_ASSIGNMENT, profile: event.profile }

        case EventType.PROFILE_TO_COPY_FETCHED:
            return currentStep.step === Step.DETAILS_AND_ASSIGNMENT
                ? syncProfileToCopy(event.profile, event.copyName)
                : currentStep

        case EventType.PROFILE_FETCHING_FAILED:
            return { ...currentStep, isLoading: false, profileFetchingError: event.error }

        case EventType.TRUST_FACTORS_FETCHED:
            return currentStep.step === Step.DETAILS_AND_ASSIGNMENT
                ? { ...currentStep, ...event }
                : currentStep

        case EventType.TRUST_FACTORS_FETCHING_FAILED:
            return currentStep.step === Step.DETAILS_AND_ASSIGNMENT
                ? { ...currentStep, trustFactorFetchingError: event.error }
                : currentStep

        case EventType.DETAILS_AND_ASSIGNMENT_FILLED:
            return currentStep.step === Step.DETAILS_AND_ASSIGNMENT
                ? goToNextStep(currentStep, event.profile)
                : currentStep
    }
}

function syncProfileToCopy(
    profile: TrustProfileDetail,
    copyName: string
): DetailsAndAssignmentStep {
    return {
        step: Step.DETAILS_AND_ASSIGNMENT,
        profileToCopy: { ...profile, name: copyName },
    }
}

function goToNextStep(step: DetailsAndAssignmentStep, profile: NewTrustProfile): CurrentStep {
    if (step.profile) {
        return { ...step, step: Step.TRUST_FACTORS, profile: { ...step.profile, ...profile } }
    }

    if (step.profileToCopy) {
        return { ...step, step: Step.TRUST_FACTORS, profile: { ...step.profileToCopy, ...profile } }
    }

    if (step.trustFactors) {
        return {
            ...step,
            step: Step.TRUST_FACTORS,
            profile: { ...profile, trustFactors: [], unusedTrustFactors: step.trustFactors },
        }
    }

    return step
}
