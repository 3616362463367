import { Singleton } from "../decorators/Singleton.decorator"
import {
    SettingsApi,
    LatestOS,
    OSRelease,
    OrgPreferredApp,
    OidcApi,
    ServiceBundleItemRes,
} from "../api/Settings.api"
import {
    ApiKeyScope,
    ApiKeyRes,
    TunnelEndpointCidrUsedFor,
    TunnelEndpointCidrRes,
} from "../api/Settings.api"
import { ApiResponse, MessageRes } from "../api/Base.api"
import { ManageApi, RegisteredService } from "../api/Manage.api"
import { DateUtil } from "../utils/Date.util"
import { AppCheckConfig, TrustProfileService } from "../../v3/services/TrustProfile.service"
import ServiceContext from "./context"
import React from "react"

@Singleton("SettingsService")
export class SettingsService {
    public static SETTINGS_LEFT_NAV_EVENT: string = "SETTINGS_LEFT_NAV_EVENT"

    public refreshLeftNav(): void {
        window.dispatchEvent(new CustomEvent(SettingsEvent.LEFT_NAV_EVENT))
    }

    public getLatestOS(): Promise<{ [key: string]: string[][] }> {
        return this.settingsApi.getLatestOS().then((osMap: { [key: string]: LatestOS[] }) => {
            const map: { [key: string]: string[][] } = {}
            Object.keys(osMap).forEach((k) => {
                map[k] = [[], [], []]
                osMap[k].forEach((latestOS: LatestOS) => {
                    const releases: OSRelease[] = this.getLatestReleases(latestOS.releases, 3)
                    releases.forEach((r: OSRelease, i: number) => {
                        map[k][i].push(latestOS.os_name + " " + r.os_version)
                    })
                })
            })
            return map
        })
    }

    public getPlatformConfig(): Promise<NumberMap> {
        return this.settingsApi.getPlatformConfig().then((config) => {
            return config.num_releases
        })
    }

    public setPlatformConfig(config: NumberMap): Promise<MessageRes> {
        return this.settingsApi.setPlatformConfig({ num_releases: config })
    }

    public getPreferredApps(name?: string): Promise<PreferredApp[]> {
        return this.settingsApi.getPreferredApps(name).then((apps: OrgPreferredApp[]) => {
            const map: { [key: string]: PreferredApp } = {}
            apps.forEach((app: OrgPreferredApp) => {
                if (map[app.app_name]) {
                    map[app.app_name].platforms.push({
                        os: this.getOSName(app.platform),
                        process: app.process_name || "",
                    })
                } else {
                    map[app.app_name] = {
                        name: app.app_name,
                        mandatory: app.mandatory === "true",
                        platforms: [
                            {
                                os: this.getOSName(app.platform),
                                process: app.process_name || "",
                            },
                        ],
                    }
                }
            })
            return Object.values(map)
        })
    }

    public getPreferredApp(name: string): Promise<PreferredApp | undefined> {
        return this.getPreferredApps(name).then((apps) =>
            apps.find((a) => a.name.toLowerCase() === name.toLowerCase())
        )
    }

    public addPreferredApp(app: PreferredApp): Promise<void> {
        const orgApps: OrgPreferredApp[] = []
        app.platforms.forEach((platform: AppPlatform) => {
            orgApps.push({
                app_name: app.name,
                mandatory: app.mandatory.toString().toUpperCase(),
                platform: platform.os.toLowerCase(),
                process_name: platform.process,
            })
        })

        return this.settingsApi.setPreferredApps(orgApps).then(() => {
            this.getPreferredApps().then((updated: PreferredApp[]) => {
                this.updateTrustProfileAppCheckConfig(updated)
            })
        })
    }

    public updatePreferredApp(app: PreferredApp): Promise<void> {
        return this.settingsApi
            .deletePreferredApps([
                <OrgPreferredApp>{
                    app_name: app.name,
                },
            ])
            .then(() => {
                return this.addPreferredApp(app)
            })
    }

    public deletePreferredApp(app: PreferredApp): Promise<void> {
        if (app.platforms && app.platforms.length > 0) {
            return this.settingsApi
                .deletePreferredApps(
                    app.platforms.map((platform: AppPlatform): OrgPreferredApp => {
                        return {
                            app_name: app.name,
                            platform: platform.os.toLowerCase(),
                        }
                    })
                )
                .then(() => {
                    this.getPreferredApps().then((updated: PreferredApp[]) => {
                        this.updateTrustProfileAppCheckConfig(updated)
                    })
                })
        } else {
            return this.settingsApi
                .deletePreferredApps([
                    <OrgPreferredApp>{
                        app_name: app.name,
                    },
                ])
                .then(() => {
                    this.getPreferredApps().then((updated: PreferredApp[]) => {
                        this.updateTrustProfileAppCheckConfig(updated)
                    })
                })
        }
    }

    public getOidcSettings(): Promise<OidcConfig> {
        return this.settingsApi.getOidcSettings().then((oidc: OidcApi): OidcConfig => {
            return {
                authorizationEndpoint: oidc.authorization_endpoint,
                issuerUrl: oidc.issuer_url,
                jwksEndpoint: oidc.jwks_endpoint,
                redirectUrl: oidc.redirect_url,
                tokenEndpoint: oidc.token_endpoint,
                userinfoEndpoint: oidc.userinfo_endpoint,
                oidcDiscoveryEndpoint: oidc.openid_configuration_endpoint,
                scope: oidc.scope,
            }
        })
    }

    public getExternalServiceConfig(): Promise<ExternalServiceConfig> {
        return this.manageApi.getExternalService().then((services: RegisteredService[]) => {
            if (services && services.length > 0) {
                const external: RegisteredService = services[0]
                try {
                    const config: any = JSON.parse(external.OIDCClientSpec)
                    return {
                        clientId: config.client_id,
                        clientSecret: config.client_secret,
                        issuerUrl: config.trust_auth,
                    }
                } catch {
                    return {}
                }
            }
            return {}
        })
    }

    public async getApiKeyScopes(): Promise<ApiKeyScope[]> {
        const scopesResponse = await this.settingsApi.getApiKeyScopes()

        return scopesResponse.data
    }

    public getOrgApiKeys(): Promise<ApiKey[]> {
        return this.settingsApi.getApiKeys().then((response: ApiResponse<ApiKeyRes[]>) => {
            return (
                response.data?.map((key: ApiKeyRes) => {
                    return {
                        id: key.id,
                        orgId: key.org_id,
                        name: key.name,
                        secret: key.secret,
                        description: key.description,
                        scope: key.scope,
                        createdAt: DateUtil.convertLargeTimestamp(key.created_at),
                        createdBy: key.created_by,
                    }
                }) || []
            )
        })
    }

    public getOrgApiKey(id: string): Promise<ApiKey> {
        return this.settingsApi.getApiKey(id).then((response: ApiResponse<ApiKeyRes>) => {
            const data: ApiKeyRes = response.data
            return {
                id: data.id,
                orgId: data.org_id,
                name: data.name,
                secret: data.secret,
                description: data.description,
                scope: data.scope,
                createdAt: DateUtil.convertLargeTimestamp(data.created_at),
                createdBy: data.created_by,
            }
        })
    }

    public getOrgTunnelEndpointCidrs(): Promise<TunnelEndpointCidr[]> {
        return this.settingsApi
            .getTunnelEndpointCidrs()
            .then((response: ApiResponse<TunnelEndpointCidrRes[]>) => {
                return (
                    response.data?.map((epCidr: TunnelEndpointCidrRes) => {
                        return {
                            id: epCidr.id,
                            usedFor: epCidr.used_for,
                            cidrRange: epCidr.cidr_range,
                            createdAt: DateUtil.convertLargeTimestamp(epCidr.created_at),
                            createdBy: epCidr.created_by,
                        }
                    }) || []
                )
            })
    }

    private trustProfileService: TrustProfileService = new TrustProfileService()
    private settingsApi: SettingsApi = new SettingsApi()
    private manageApi: ManageApi = new ManageApi()

    private updateTrustProfileAppCheckConfig(apps: PreferredApp[]): Promise<void> {
        const appConfig: AppCheckConfig[] = []
        for (const app of apps) {
            const processMap: Map<string, string> = new Map()
            app.platforms.forEach((platform: AppPlatform) => {
                processMap.set(platform.os, platform.process)
            })
            appConfig.push({
                name: app.name,
                mandatory: app.mandatory,
                processName: {
                    MACOS: processMap.get(OperatingSystem.MACOS) || "",
                    WINDOWS: processMap.get(OperatingSystem.WINDOWS) || "",
                    LINUX: processMap.get(OperatingSystem.UBUNTU) || "",
                },
            })
        }
        // TODO: Replace profile ID when we have multiple profiles
        return this.trustProfileService.updateTrustProfileAppCheck("profileId", appConfig).then()
    }

    private getLatestReleases(releases: OSRelease[], count: number): OSRelease[] {
        while (releases.length < count) {
            releases.push(releases[releases.length - 1])
        }
        return releases.slice(0, count)
    }

    private getOSName(os: string): string {
        if (os.toLowerCase() === OperatingSystem.WINDOWS.toLowerCase()) {
            return OperatingSystem.WINDOWS
        }
        if (os.toLowerCase() === OperatingSystem.ANDROID.toLowerCase()) {
            return OperatingSystem.ANDROID
        }
        if (os.toLowerCase() === OperatingSystem.IOS.toLowerCase()) {
            return OperatingSystem.IOS
        }
        if (os.toLowerCase() === OperatingSystem.MACOS.toLowerCase()) {
            return OperatingSystem.MACOS
        }
        if (os.toLowerCase() === OperatingSystem.UBUNTU.toLowerCase()) {
            return OperatingSystem.UBUNTU
        }
        return ""
    }

    public getServiceBundles(): Promise<ServiceBundle[]> {
        return this.settingsApi.getServiceBundles().then((serviceBundle) => {
            const service_bundles: ServiceBundle[] = serviceBundle.data?.service_bundles
                ? serviceBundle.data.service_bundles.map(this.mapServiceBundle)
                : []
            return service_bundles
        })
    }

    public getServiceBundle(serviceBundleName: string): Promise<ServiceBundle> {
        return this.settingsApi.getServiceBundle(serviceBundleName).then((serviceBundle) => {
            const service_bundle: ServiceBundle[] = serviceBundle.data?.service_bundles
                ? serviceBundle.data.service_bundles.map(this.mapServiceBundle)
                : []
            return service_bundle[0]
        })
    }

    public createServiceBundle(bundledata: ServiceBundle): Promise<void> {
        return this.settingsApi.insertServiceBundle({
            bundle_name: bundledata.name,
            description: bundledata.desc,
            bulk_connect: bundledata.bulkConnect,
            service_ids: bundledata.serviceIds,
        })
    }

    public updateServiceBundle(bundledata: ServiceBundle, id: string): Promise<void> {
        return this.settingsApi.updateServiceBundle(
            {
                bundle_name: bundledata.name,
                description: bundledata.desc,
                bulk_connect: bundledata.bulkConnect,
                service_ids: bundledata.serviceIds,
            },
            id
        )
    }

    public deleteServiceBundle(id: string): Promise<ServiceBundle> {
        return this.settingsApi.deleteServiceBundle(id)
    }

    private mapServiceBundle(bundle: ServiceBundleItemRes): ServiceBundle {
        return {
            bundleId: bundle.id,
            name: bundle.bundle_name,
            desc: bundle.description,
            bulkConnect: bundle.bulk_connect,
            lastUpdatedAt: DateUtil.convertLargeTimestamp(bundle.updated_at),
            createdAt: DateUtil.convertLargeTimestamp(bundle.created_at),
            isAdminCreated: bundle.is_admin_created,
            serviceIds: bundle.service_ids,
        }
    }
}

export interface PreferredApp {
    name: string
    mandatory: boolean
    platforms: AppPlatform[]
}

export interface AppPlatform {
    os: string
    process: string
}

export interface OidcConfig {
    authorizationEndpoint: string
    issuerUrl: string
    jwksEndpoint: string
    redirectUrl: string
    tokenEndpoint: string
    userinfoEndpoint: string
    oidcDiscoveryEndpoint: string
    scope: string
}

export interface ExternalServiceConfig {
    clientId?: string
    clientSecret?: string
    issuerUrl?: string
}

export interface ApiKey {
    id: string
    orgId: string
    name: string
    secret: string
    description: string
    scope: ApiKeyScope
    createdBy: string
    createdAt: number
}

export enum OperatingSystem {
    WINDOWS = "Windows",
    MACOS = "MacOS",
    IOS = "iOS",
    ANDROID = "Android",
    UBUNTU = "Ubuntu",
    LINUX = "Linux",
    DARWIN = "Darwin",
    UNKNOWN = "UNKNOWN",
}

export enum DeviceOwnership {
    EMPLOYEE = "E",
    SHARED = "S",
    DEDICATED = "C",
    OTHER = "O",
}

export class SettingsEvent {
    public static LEFT_NAV_EVENT = "SETTINGS_LEFT_NAV_EVENT"
}

export interface ServiceBundle {
    bundleId?: string
    name: string
    desc: string
    bulkConnect: boolean
    lastUpdatedAt?: number
    createdAt?: number
    isAdminCreated?: boolean
    serviceIds: string[]
}

export interface TunnelEndpointCidr {
    id: string
    usedFor: TunnelEndpointCidrUsedFor
    cidrRange: string
    createdBy: string
    createdAt: number
}

export const useServiceSettings = () =>
    React.useContext(ServiceContext)?.settings || new SettingsService()
