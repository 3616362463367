import { faExclamation, faExclamationCircle } from "@fortawesome/pro-solid-svg-icons"
import classNames from "classnames/bind"
import React, { Fragment } from "react"

import { NameCellRenderer } from "../../../../v3/views/access-tiers/list/AccessTiersList.view"
import {
    Grid,
    FormLabel,
    TextBlock,
    Toolbar,
    Banner,
    LargeMessage,
    SimpleTable,
    TabBar,
    LoadMask,
    CopyButtonCellRenderer,
    Tooltip,
} from "../../../components"
import { ClustersCaCert } from "../ca-cert/ClustersCaCert.component"
import { NetAgentStatusCellRenderer } from "../cell-renderer/net-agent-status/NetAgentStatusCellRenderer"
import { LogsCellRenderer } from "../cell-renderer/logs/LogsCellRenderer.component"
import { ClustersSshCa } from "../ssh-ca/ClustersSshCa.component"
import styles from "./ClusterOverview.module.scss"
import { PageHeading } from "../../../../components/page-heading/PageHeading.component"
import {
    Button,
    ButtonElement,
    ButtonType,
    IconType,
} from "../../../../components/button/Button.component"
import { ClusterStatus } from "../../../services/Infra.service"

export default function () {
    const { cluster } = this.state
    return (
        <>
            <header className={styles.header}>
                <PageHeading>{cluster?.name}</PageHeading>
                <div className={styles.actionButtons}>
                    {cluster?.status === ClusterStatus.INACTIVE && (
                        <Tooltip title={this.localizationService.getString("delete")}>
                            <Button
                                icon={IconType.TRASH}
                                onClick={() => this.onOpenDeleteModal()}
                                asElement={ButtonElement.BUTTON}
                                buttonType={ButtonType.SECONDARY}
                                aria-label={this.localizationService.getString("delete")}
                            />
                        </Tooltip>
                    )}
                    <Tooltip title={this.localizationService.getString("refresh")}>
                        <Button
                            icon={IconType.REDO}
                            onClick={() => this.fetchData()}
                            asElement={ButtonElement.BUTTON}
                            buttonType={ButtonType.SECONDARY}
                            aria-label={this.localizationService.getString("refresh")}
                        />
                    </Tooltip>
                </div>
            </header>
            <div className={styles.overviewContainer}>
                {this.state.loading && <LoadMask />}
                {!cluster && !this.state.loading && (
                    <LargeMessage icon={faExclamation} className={styles.missingMessage}>
                        {this.localizationService.getString("clusterNotFound")}
                    </LargeMessage>
                )}

                {cluster && (
                    <Fragment>
                        <div className={styles.overviewLeftContainer}>
                            <div
                                className={classNames(
                                    styles.statusBlock,
                                    cluster.status === 0 ? styles.success : styles.warning
                                )}
                            >
                                <p className={styles.statusLabel}>
                                    {this.localizationService.getString("currentStatus")}
                                </p>
                                <div className={styles.statusContent}>
                                    {this.state.currentStatus}
                                </div>
                                <SimpleTable
                                    className={styles.statusTable}
                                    items={this.state.clusterData}
                                />
                            </div>
                            <SimpleTable
                                className={styles.simpleTable}
                                items={this.state.clusterDetailsData}
                            />
                        </div>
                        <div className={styles.overviewRightContainer}>
                            {this.state.error && (
                                <Banner
                                    type="error"
                                    icon={faExclamationCircle}
                                    className={styles.gridBanner}
                                >
                                    {this.state.error}
                                </Banner>
                            )}
                            <TabBar tabs={this.getTabs()} onChange={this.onTabChange} />
                            <div className={styles.overviewRightGridContainer}>
                                {this.state.tab === 1 && (
                                    <Fragment>
                                        <div className={styles.installationParamsGridContainer}>
                                            <Grid
                                                onGridReady={this.onClusterGridReady}
                                                columnDefs={this.clusterColumns}
                                                components={{
                                                    copyButtonCellRenderer: CopyButtonCellRenderer,
                                                }}
                                            />
                                        </div>
                                        <div className={styles.certParamsContainer}>
                                            <FormLabel
                                                title={this.localizationService.getString(
                                                    "caCertificate"
                                                )}
                                                slim
                                                htmlFor="caCertificate"
                                            >
                                                <ClustersCaCert cert={cluster.caCert} />
                                            </FormLabel>

                                            <FormLabel
                                                title={this.localizationService.getString(
                                                    "sshCaPublicKey"
                                                )}
                                                slim
                                                htmlFor="sshCaPublicKey"
                                            >
                                                <ClustersSshCa pubKey={cluster.sshCaPubKey} />
                                            </FormLabel>
                                        </div>
                                    </Fragment>
                                )}
                                {this.state.tab === 2 && (
                                    <Fragment>
                                        <div className={styles.toolbarContainer}>
                                            <Toolbar onSearchChange={this.onFilter} />
                                        </div>
                                        <Grid
                                            onGridReady={this.onAccessTierGridReady}
                                            columnDefs={this.accessTierColumns}
                                            context={{ history: this.props.history }}
                                            components={{
                                                netAgentStatusCellRenderer:
                                                    NetAgentStatusCellRenderer,
                                                accessTierNameCellRenderer: NameCellRenderer,
                                            }}
                                        />
                                    </Fragment>
                                )}
                                {this.state.tab === 4 && (
                                    <Fragment>
                                        <Grid
                                            onGridReady={this.onLogsGridReady}
                                            columnDefs={this.logsColumns}
                                            masterDetail
                                            detailRowHeight="130"
                                            pagination
                                            detailCellRenderer="clusterLogsDetail"
                                            components={{
                                                clusterLogsDetail: TextBlock,
                                                logsCellRenderer: LogsCellRenderer,
                                            }}
                                        />
                                    </Fragment>
                                )}
                            </div>
                        </div>
                    </Fragment>
                )}
            </div>
        </>
    )
}
