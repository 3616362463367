import {
    UseMutationResult,
    UseQueryResult,
    useMutation,
    useQuery,
    useQueryClient,
} from "@tanstack/react-query"
import React from "react"

import { LanguageKey } from "../../pre-v3/services/localization/languages/en-US.language"
import { useServiceLocalization } from "../../pre-v3/services/localization/Localization.service"
import { DateUtil } from "../../pre-v3/utils/Date.util"
import {
    DnsFilterRes,
    NewProtectionProfileReq,
    ProtectionProfileApi,
    ProtectionProfileRes,
    ThreatProfilePriority,
} from "../api/ProtectionProfile.api"
import { RoleApi, RoleRes, RoleSpecReq } from "../api/Role.api"
import { ApiFunction } from "./shared/QueryKey"
import { getMobileDevicesRoleRec, isMobileDevicesRole } from "./shared/Role"
import { ApplicationsApi, ApplicationRes, ApplicationSearch, Status } from "../api/Applications.api"

function mapResource(response: ApplicationRes): AppFilter {
    return {
        value: response.application_id,
        label: response.application_name,
        description: response.application_name,
    }
}

// Hooks

const serviceName = "ItpPolicyService"

export function useGetITPApplications(options?: QueryOptions<AppFilter[]>) {
    return useQuery<AppFilter[], string>({
        ...options,
        queryKey: ["itpPolicyService.getITPApplications"],
        queryFn: async (): Promise<AppFilter[]> => {
            const itpApplicationsApi: ApplicationsApi = new ApplicationsApi()
            const search: ApplicationSearch = {
                skip: 0,
                limit: 1000,
                bnn_itp: true,
            }

            return itpApplicationsApi.getApplications(search).then((response) => {
                return response.application_inventory?.map(mapResource) || []
            })
        },
    })
}

export function useGetItpPolicies(
    options?: QueryOptions<ItpPolicyList>
): UseQueryResult<ItpPolicyList> {
    const protectionProfileApi = new ProtectionProfileApi()

    return useQuery({
        ...options,
        queryKey: [ApiFunction.GET_ITP_POLICIES, serviceName],
        queryFn: async (): Promise<ItpPolicyList> => {
            const protectionProfilesRes = await protectionProfileApi.getProtectionProfiles()

            const emptyItpPolicyList: ItpPolicyList = {
                excludedDevicesPolicy: getDefaultExcludedDevicesPolicy(),
                itpPolicies: [],
            }

            return protectionProfilesRes.reduce(reduceItpPolicyList, emptyItpPolicyList)
        },
    })
}

export function useGetItpPolicySyncStatus(
    options?: QueryOptions<ItpPolicySyncStatus>
): UseQueryResult<ItpPolicySyncStatus> {
    const protectionProfileApi = new ProtectionProfileApi()

    return useQuery({
        ...options,
        queryKey: [ApiFunction.GET_ITP_POLICY_SYNC_STATUS, serviceName],
        queryFn: async (): Promise<ItpPolicySyncStatus> => {
            const response = await protectionProfileApi.getProtectionProfileSyncStatus()

            return {
                id: response.id,
                eta: DateUtil.convertLargeTimestamp(response.eta),
                lastSyncedAt: response.last_synced_at,
                status: response.status,
                outOfSyncAt: response.out_of_sync_at,
            }
        },
    })
}

export function useReorderItpPolicies(
    options?: QueryOptions<ItpPolicyList, unknown, ItpPolicy[]>
): UseMutationResult<ItpPolicyList, unknown, ItpPolicy[]> {
    const queryClient = useQueryClient()

    const protectionProfileApi = new ProtectionProfileApi()

    return useMutation({
        ...options,
        mutationKey: [ApiFunction.REORDER_ITP_POLICIES, serviceName],
        mutationFn: async (orderedItpPolicies: ItpPolicy[]): Promise<ItpPolicyList> => {
            const protectionProfilesRes = await protectionProfileApi.reorderProtectionProfiles({
                threat_profile_priorities: orderedItpPolicies.map(mapPolicyToThreatProfilePriority),
            })

            const emptyItpPolicyList: ItpPolicyList = {
                excludedDevicesPolicy: getDefaultExcludedDevicesPolicy(),
                itpPolicies: [],
            }

            return protectionProfilesRes.reduce(reduceItpPolicyList, emptyItpPolicyList)
        },
        onSuccess: (itpPolicyList, orderedItpPolicies) => {
            queryClient.setQueryData<ItpPolicyList>(
                [ApiFunction.GET_ITP_POLICIES, serviceName],
                itpPolicyList
            )
            queryClient.removeQueries([ApiFunction.GET_ITP_POLICY_SYNC_STATUS])
            queryClient.removeQueries([ApiFunction.GET_ITP_POLICY])
            options?.onSuccess?.(itpPolicyList, orderedItpPolicies)
        },
    })
}

export function useLookUpDomainInfo(
    options?: QueryOptions<LookUpDomain, string, string>
): UseMutationResult<LookUpDomain, string, string> {
    const protectionProfileApi = new ProtectionProfileApi()

    return useMutation({
        ...options,
        mutationFn: async (domain: string): Promise<LookUpDomain> => {
            const response = await protectionProfileApi.getProtectionProfileLookupDomain(domain)

            return {
                domainName: response.domainName,
                threats: response.threats,
                categories: response.categories,
            }
        },
    })
}

export function useGetItpPolicy(
    id: string,
    options?: QueryOptions<ItpPolicyDetails>
): UseQueryResult<ItpPolicyDetails> {
    const localization = useServiceLocalization()

    const protectionProfileApi = new ProtectionProfileApi()

    return useQuery({
        ...options,
        queryKey: [ApiFunction.GET_ITP_POLICY, id, serviceName],
        queryFn: async (): Promise<ItpPolicyDetails> => {
            if (id === excludedDevicesPolicyName) {
                return Promise.resolve(getDefaultExcludedDevicesPolicyDetails())
            }

            const [[protectionProfileRes], dnsFiltersRes] = await Promise.all([
                protectionProfileApi.getProtectionProfile(id),
                protectionProfileApi.getDnsFilters(),
            ])

            if (!protectionProfileRes) {
                throw localization.getString("errorItpPolicyNotFound")
            }

            const dnsFilters = dnsFiltersRes.reduce(reduceDnsFiltersRes, {
                threats: [],
                categories: [],
            })

            return mapProtectionProfileReqToItpPolicyDetails(dnsFilters, protectionProfileRes)
        },
    })
}

export function useCreateItpPolicy(
    options?: QueryOptions<ItpPolicyDetails, unknown, UserEditableItpPolicyFields>
): UseMutationResult<ItpPolicyDetails, unknown, UserEditableItpPolicyFields> {
    const queryClient = useQueryClient()
    const localization = useServiceLocalization()

    const protectionProfileApi = new ProtectionProfileApi()

    return useMutation({
        ...options,
        mutationKey: [ApiFunction.CREATE_ITP_POLICY, serviceName],
        mutationFn: async (itpPolicyFields): Promise<ItpPolicyDetails> => {
            if (itpPolicyFields.name === excludedDevicesPolicyName) {
                return Promise.reject(
                    localization.getString(
                        "somethingNamedAlreadyExists",
                        localization.getString("itpPolicy"),
                        itpPolicyFields.name
                    )
                )
            }

            if (!isValid(itpPolicyFields)) {
                return Promise.reject(localization.getString("itpPolicyInvalidMessage"))
            }

            const newPolicy = await protectionProfileApi.createProtectionProfile(
                mapCreateItpPolicyToReq(itpPolicyFields)
            )

            const dnsFiltersRes = await protectionProfileApi.getDnsFilters()

            const dnsFilters = dnsFiltersRes.reduce(reduceDnsFiltersRes, {
                threats: [],
                categories: [],
            })

            return mapProtectionProfileReqToItpPolicyDetails(dnsFilters, newPolicy)
        },
        onSuccess: (itpPolicy, itpPolicyFields) => {
            queryClient.setQueryData<ItpPolicyDetails>(
                [ApiFunction.GET_ITP_POLICY, itpPolicy.id, serviceName],
                itpPolicy
            )
            queryClient.removeQueries([ApiFunction.GET_ITP_POLICIES])
            options?.onSuccess?.(itpPolicy, itpPolicyFields)
        },
    })
}

export function useUpdateItpPolicy(
    itpPolicy: ItpPolicyDetails,
    options?: QueryOptions<ItpPolicyDetails, unknown, UserEditableItpPolicyFields>
): UseMutationResult<ItpPolicyDetails, unknown, UserEditableItpPolicyFields> {
    const queryClient = useQueryClient()
    const localization = useServiceLocalization()

    const protectionProfileApi = new ProtectionProfileApi()
    const roleApi = new RoleApi()

    return useMutation({
        ...options,
        mutationKey: [ApiFunction.UPDATE_ITP_POLICY, itpPolicy?.id, serviceName],
        mutationFn: async (itpPolicyFields): Promise<ItpPolicyDetails> => {
            if (!itpPolicy.id) {
                return Promise.reject(localization.getString("cannotUpdateAnItpPolicyWithoutAnId"))
            }
            if (itpPolicy.id === excludedDevicesPolicyName) {
                const excludeItpFromMobileRoleRes =
                    (await roleApi.getRoles()).find(isMobileDevicesRoleRes) ??
                    (await roleApi.createRole(getMobileDevicesRoleRec()))

                const excludeItpFromMobileRoleSpec: RoleSpecReq = JSON.parse(
                    excludeItpFromMobileRoleRes.RoleSpec
                )

                const excludeItpFromMobile = excludeItpFromMobileRoleSpec.metadata.name

                const excludedDevicesPolicy = await protectionProfileApi.createProtectionProfile(
                    mapCreateItpPolicyToReq({
                        ...itpPolicyFields,
                        roles: itpPolicyFields.roles.includes(excludeItpFromMobile)
                            ? itpPolicyFields.roles
                            : [excludeItpFromMobile, ...itpPolicyFields.roles],
                    })
                )

                const [excludedDevicesPolicyRes] =
                    await protectionProfileApi.reorderProtectionProfiles({
                        threat_profile_priorities: [
                            {
                                profile_id: excludedDevicesPolicy.profile_id,
                                priority: 0,
                            },
                        ],
                    })

                const dnsFiltersRes = await protectionProfileApi.getDnsFilters()

                const dnsFilters = dnsFiltersRes.reduce(reduceDnsFiltersRes, {
                    threats: [],
                    categories: [],
                })

                return mapProtectionProfileReqToItpPolicyDetails(
                    dnsFilters,
                    excludedDevicesPolicyRes
                )
            }

            if (
                itpPolicyFields.name === excludedDevicesPolicyName &&
                itpPolicy.name !== excludedDevicesPolicyName
            ) {
                return Promise.reject(
                    localization.getString(
                        "somethingNamedAlreadyExists",
                        localization.getString("itpPolicy"),
                        itpPolicyFields.name
                    )
                )
            }

            if (!isValid(itpPolicyFields) && itpPolicy.name !== excludedDevicesPolicyName) {
                return Promise.reject(localization.getString("itpPolicyInvalidMessage"))
            }

            const updateProtectionProfileRes = await protectionProfileApi.updateProtectionProfile(
                mapUpdateItpPolicyToReq({
                    ...itpPolicy,
                    ...itpPolicyFields,
                })
            )

            const dnsFiltersRes = await protectionProfileApi.getDnsFilters()

            const dnsFilters = dnsFiltersRes.reduce(reduceDnsFiltersRes, {
                threats: [],
                categories: [],
            })

            return mapProtectionProfileReqToItpPolicyDetails(dnsFilters, updateProtectionProfileRes)
        },
        onSuccess: (updatedItpPolicy, itpPolicyFields) => {
            queryClient.removeQueries([ApiFunction.GET_ITP_POLICIES])

            if (itpPolicy.id === excludedDevicesPolicyName) {
                queryClient.setQueryData<ItpPolicyDetails>(
                    [ApiFunction.GET_ITP_POLICY, itpPolicy.id, serviceName],
                    updatedItpPolicy
                )
                queryClient.invalidateQueries([ApiFunction.GET_ROLES])
            }

            queryClient.setQueryData<ItpPolicyDetails>(
                [ApiFunction.GET_ITP_POLICY, updatedItpPolicy.id, serviceName],
                updatedItpPolicy
            )
            options?.onSuccess?.(updatedItpPolicy, itpPolicyFields)
        },
    })
}

export function useDeleteItpPolicy(
    itpPolicy: ItpPolicyDetails,
    options?: QueryOptions<void, unknown, void>
): UseMutationResult<void, unknown, void> {
    const queryClient = useQueryClient()

    const protectionProfileApi = new ProtectionProfileApi()

    return useMutation({
        ...options,
        mutationKey: [ApiFunction.DELETE_ITP_POLICY, itpPolicy.id, serviceName],
        mutationFn: async (): Promise<void> => {
            await protectionProfileApi.deleteProtectionProfile(itpPolicy.id)

            const protectionProfilesRes = await protectionProfileApi.getProtectionProfiles()

            if (protectionProfilesRes.length <= 0) return

            const threatProfilePriorities = prioritizeAfterDeleting(protectionProfilesRes)

            if (threatProfilePriorities.length <= 0) return

            await protectionProfileApi.reorderProtectionProfiles({
                threat_profile_priorities: threatProfilePriorities,
            })
        },
        onSuccess: () => {
            queryClient.removeQueries([ApiFunction.GET_ITP_POLICIES])
            queryClient.removeQueries([ApiFunction.GET_ITP_POLICY, itpPolicy.id])
            options?.onSuccess?.()
        },
    })
}

export function useGetDnsReport(options?: QueryOptions<DnsReport>): UseQueryResult<DnsReport> {
    const protectionProfileApi = new ProtectionProfileApi()

    return useQuery({
        ...options,
        queryKey: [ApiFunction.GET_DNS_REPORT, serviceName],
        queryFn: async (): Promise<DnsReport> => {
            const dnsReportReq = await protectionProfileApi.getDnsReport()

            return {
                status: dnsReportReq.status,
                requestTimestamp: dnsReportReq.request_timestamp,
                successTimestamp: dnsReportReq.success_timestamp,
                url: dnsReportReq.url,
            }
        },
    })
}

export function useGenerateDnsReport(
    options?: QueryOptions<void>
): UseMutationResult<void, unknown, void> {
    const queryClient = useQueryClient()

    const protectionProfileApi = new ProtectionProfileApi()

    return useMutation({
        ...options,
        mutationKey: [ApiFunction.GENERATE_DNS_REPORT, serviceName],
        mutationFn: async (): Promise<void> => {
            await protectionProfileApi.generateDnsReport()
        },
        onSuccess: () => {
            queryClient.refetchQueries([ApiFunction.GET_DNS_REPORT])
            options?.onSuccess?.()
        },
    })
}

export function useGetDnsFilters(options?: QueryOptions<DnsFilters>): UseQueryResult<DnsFilters> {
    const protectionProfileApi = new ProtectionProfileApi()

    return useQuery({
        ...options,
        queryKey: [ApiFunction.GET_DNS_FILTERS, serviceName],
        queryFn: async (): Promise<DnsFilters> => {
            const dnsFiltersRes = await protectionProfileApi.getDnsFilters()

            return dnsFiltersRes.reduce(reduceDnsFiltersRes, {
                threats: [],
                categories: [],
            })
        },
    })
}

export function useGetPoliciesRolesNames() {
    const roleApi = new RoleApi()
    return useQuery<string[], string>({
        queryKey: [ApiFunction.GET_ROLES, serviceName],
        queryFn: async () => {
            const rolesRes = await roleApi.getRoles()
            return reduceRolesResToNames(rolesRes)
        },
    })
}

// Types

export interface ItpPolicyList {
    excludedDevicesPolicy: ItpPolicy
    itpPolicies: ItpPolicy[]
}

export interface ItpPolicy {
    id: string
    name: string
    priority: number
    lastUpdatedAt: number
    deviceCount: number
}

export interface ItpApplicationDetail {
    name: string
    isBlock: boolean
    description: string
    isDlpPolicy: boolean
    domains: string[]
    isIdentityProviderFederated: boolean
    ignore: boolean
    isIpAllowed: boolean
    logo: string
    networks: string[]
    sensitivity: string[]
    status: Status
    category: string
    helpfulLinks: string[]
    url: string
}

export interface ItpPolicyDetails extends UserEditableItpPolicyFields {
    id: string
    createdAt: number
    createdBy: string
    lastUpdatedAt: number
    lastUpdatedBy: string
    priority: number
    deviceCount: number
}

export interface UserEditableItpPolicyFields {
    threats: string[]
    categories: {
        enabled: boolean
        value: string[]
    }
    blacklistDomains: {
        enabled: boolean
        value: string[]
    }
    whitelistDomains: {
        enabled: boolean
        value: string[]
    }
    blockApplications: {
        enabled: boolean
        value: string[]
    }
    allowApplications: {
        enabled: boolean
        value: string[]
    }
    name: string
    description?: string
    roles: string[]
    blockPageMessage: string
}

export interface DnsFilter {
    label: string
    description: string
    value: string
}

export interface AppFilter {
    label: string
    description: string
    value: string
}

export interface DnsFilters {
    threats: DnsFilter[]
    categories: DnsFilter[]
}

export interface DnsReport {
    url: string
    requestTimestamp: number
    successTimestamp: number
    status: DnsReportStatus
}

export interface ItpPolicySyncStatus {
    id: string
    status: SyncStatus
    lastSyncedAt: number
    eta: number
    outOfSyncAt: number
}

export const labelMap: Record<SyncStatus, LanguageKey> = {
    InProgress: "syncInProgress",
    Completed: "upToDate",
    Error: "failedToGetSyncStatus",
}

export type SyncStatus = "InProgress" | "Completed" | "Error"

export type DnsReportStatus = "pending" | "success" | "error" | "no_status"

export interface LookUpDomain {
    domainName: string
    threats: string[]
    categories: string[]
}

// Service Functions

export function isExcludedDevicesPolicy(
    itpPolicy: ItpPolicy | UserEditableItpPolicyFields
): boolean {
    return itpPolicy.name === excludedDevicesPolicyName
}

export function useEmptyItpPolicyFields(): UserEditableItpPolicyFields {
    const localization = useServiceLocalization()

    return React.useMemo(
        (): UserEditableItpPolicyFields => ({
            threats: [],
            categories: {
                enabled: false,
                value: [],
            },
            blacklistDomains: {
                enabled: false,
                value: [],
            },
            whitelistDomains: {
                enabled: false,
                value: [],
            },
            blockApplications: {
                enabled: false,
                value: [],
            },
            allowApplications: {
                enabled: false,
                value: [],
            },
            name: "",
            roles: [],
            blockPageMessage: localization.getString(
                "pleaseContactYourAdministratorIfYouHaveAnyQuestions"
            ),
        }),
        []
    )
}

// Helper Functions

// Name of the special ITP Policy that is used to exclude devices from ITP
export const excludedDevicesPolicyName = "Excluded Devices"

function getDefaultExcludedDevicesPolicy(): ItpPolicy {
    return {
        id: excludedDevicesPolicyName,
        name: excludedDevicesPolicyName,
        priority: 0,
        lastUpdatedAt: 0,
        deviceCount: 0,
    }
}

function getDefaultExcludedDevicesPolicyDetails(): ItpPolicyDetails {
    return {
        ...getDefaultExcludedDevicesPolicy(),
        createdAt: 0,
        createdBy: "-",
        lastUpdatedBy: "-",
        threats: [],
        categories: { enabled: false, value: [] },
        blacklistDomains: { enabled: false, value: [] },
        whitelistDomains: { enabled: false, value: [] },
        blockApplications: { enabled: false, value: [] },
        allowApplications: { enabled: false, value: [] },
        roles: ["MobileDevices"],
        blockPageMessage: "",
    }
}

function reduceItpPolicyList(
    acc: ItpPolicyList,
    protectionProfileRes: ProtectionProfileRes
): ItpPolicyList {
    if (protectionProfileRes.profile_name === excludedDevicesPolicyName) {
        return {
            ...acc,
            excludedDevicesPolicy: mapProtectionProfileReqToItpPolicy(protectionProfileRes),
        }
    }

    return {
        ...acc,
        itpPolicies: [...acc.itpPolicies, mapProtectionProfileReqToItpPolicy(protectionProfileRes)],
    }
}

function mapProtectionProfileReqToItpPolicy(protectionProfileRes: ProtectionProfileRes): ItpPolicy {
    return {
        id: protectionProfileRes.profile_id,
        name: protectionProfileRes.profile_name,
        priority: protectionProfileRes.priority,
        lastUpdatedAt: DateUtil.convertLargeTimestamp(protectionProfileRes.updated_at),
        deviceCount: protectionProfileRes.extra_details.device_count,
    }
}

function mapProtectionProfileReqToItpPolicyDetails(
    dnsFilters: DnsFilters,
    protectionProfileRes: ProtectionProfileRes
): ItpPolicyDetails {
    const { threats, categories } =
        protectionProfileRes.blacklist_categories.reduce<DeniedCategories>(
            (acc, dnsFilterValue) => reduceDeniedCategories(dnsFilters, acc, dnsFilterValue),
            { threats: [], categories: [] }
        )
    const apps = [{}]

    return {
        ...mapProtectionProfileReqToItpPolicy(protectionProfileRes),
        threats,
        categories: {
            enabled: categories.length > 0,
            value: categories,
        },
        blacklistDomains: {
            enabled: protectionProfileRes.blacklist_domains.length > 0,
            value: protectionProfileRes.blacklist_domains,
        },
        whitelistDomains: {
            enabled: protectionProfileRes.whitelist_domains.length > 0,
            value: protectionProfileRes.whitelist_domains,
        },
        blockApplications: {
            enabled: protectionProfileRes.block_applications.length > 0,
            value: protectionProfileRes.block_applications || [],
        },
        allowApplications: {
            enabled: protectionProfileRes.allow_applications.length > 0,
            value: protectionProfileRes.allow_applications || [],
        },
        description: protectionProfileRes.description || undefined,
        roles: protectionProfileRes.roles,
        blockPageMessage: protectionProfileRes.block_page_message,

        createdAt: DateUtil.convertLargeTimestamp(protectionProfileRes.created_at),
        createdBy: protectionProfileRes.created_by,
        lastUpdatedBy: protectionProfileRes.updated_by,
    }
}

interface DeniedCategories {
    threats: string[]
    categories: string[]
}

function reduceDeniedCategories(
    dnsFilters: DnsFilters,
    acc: DeniedCategories,
    dnsFilterValue: number
): DeniedCategories {
    const dnsFilterValueString = dnsFilterValue.toString()

    const isThreat = dnsFilters.threats.find(({ value }) => value === dnsFilterValueString)
    const isCategory = dnsFilters.categories.find(({ value }) => value === dnsFilterValueString)

    return {
        threats: isThreat ? [...acc.threats, dnsFilterValueString] : acc.threats,
        categories: isCategory ? [...acc.categories, dnsFilterValueString] : acc.categories,
    }
}

function reduceDnsFiltersRes(acc: DnsFilters, dnsFilterRes: DnsFilterRes): DnsFilters {
    const isThreat: boolean = dnsFilterRes.attributes.security
    const dnsFilter: DnsFilter = {
        label: dnsFilterRes.attributes.name,
        description: dnsFilterRes.attributes.description,
        value: dnsFilterRes.id,
    }

    return {
        threats: isThreat ? [...acc.threats, dnsFilter] : acc.threats,
        categories: isThreat ? acc.categories : [...acc.categories, dnsFilter],
    }
}

interface GetValueIfEnabledProps<T> {
    enabled: boolean
    value: T[]
}

function getValueIfEnabled<T>(props: GetValueIfEnabledProps<T>): T[] {
    return props.enabled ? props.value : []
}

function isValid(profile: UserEditableItpPolicyFields): boolean {
    return (
        profile.threats.length > 0 ||
        (profile.categories.enabled && profile.categories.value.length > 0) ||
        (profile.blacklistDomains.enabled && profile.blacklistDomains.value.length > 0)
    )
}

function mapCreateItpPolicyToReq(itpPolicy: UserEditableItpPolicyFields): NewProtectionProfileReq {
    return {
        blacklist_categories: [
            ...itpPolicy.threats,
            ...getValueIfEnabled(itpPolicy.categories),
        ].map((t: string) => Number(t)),
        blacklist_domains: getValueIfEnabled(itpPolicy.blacklistDomains),
        whitelist_domains: getValueIfEnabled(itpPolicy.whitelistDomains),
        block_applications: getValueIfEnabled(itpPolicy.blockApplications),
        allow_applications: getValueIfEnabled(itpPolicy.allowApplications),
        profile_name: itpPolicy.name,
        description: itpPolicy.description,
        roles: itpPolicy.roles,
        block_page_message: itpPolicy.blockPageMessage,
    }
}

function mapUpdateItpPolicyToReq(itpPolicy: ItpPolicyDetails): ProtectionProfileRes {
    return {
        profile_id: itpPolicy.id,
        priority: itpPolicy.priority,
        created_at: itpPolicy.createdAt,
        created_by: itpPolicy.createdBy,
        updated_at: itpPolicy.lastUpdatedAt,
        updated_by: itpPolicy.lastUpdatedBy,
        extra_details: { device_count: itpPolicy.deviceCount },
        ...mapCreateItpPolicyToReq(itpPolicy),
    }
}

function mapPolicyToThreatProfilePriority(
    itpPolicy: ItpPolicy,
    priority: number
): ThreatProfilePriority {
    return {
        profile_id: itpPolicy.id,
        priority: priority + 1,
    }
}

function prioritizeAfterDeleting(
    protectionProfilesRes: ProtectionProfileRes[]
): ThreatProfilePriority[] {
    const [threatProfilesWithPriorities] = protectionProfilesRes.reduce<
        [ThreatProfilePriority[], number]
    >(reducePrioritiesAfterDeleting, [[], 0])
    return threatProfilesWithPriorities
}

function reducePrioritiesAfterDeleting(
    acc: [ThreatProfilePriority[], number],
    res: ProtectionProfileRes
): [ThreatProfilePriority[], number] {
    if (res.profile_name === excludedDevicesPolicyName) return acc

    const [threatProfilesWithPriorities, priority] = acc
    const nextPriority = priority + 1

    const profileWithUpdatedPriority: ThreatProfilePriority = {
        profile_id: res.profile_id,
        priority: nextPriority,
    }

    return [[...threatProfilesWithPriorities, profileWithUpdatedPriority], nextPriority]
}

function isMobileDevicesRoleRes(roleRes: RoleRes): boolean {
    const roleSpec: RoleSpecReq = JSON.parse(roleRes.RoleSpec)

    return isMobileDevicesRole(roleSpec.metadata.name)
}

function isOnlyMobileRole(roleRes: RoleRes) {
    const specs: RoleSpecReq = JSON.parse(roleRes.RoleSpec)
    const { platform } = specs.spec
    if (!platform) return false

    const isOnlyIos = platform.length === 1 && platform[0] === "iOS"
    const isOnlyAndroid = platform.length === 1 && platform[0] === "Android"
    const isOnlyAndroidAndIos =
        platform.length === 2 && platform.includes("Android") && platform.includes("iOS")
    if (isOnlyIos || isOnlyAndroid || isOnlyAndroidAndIos) return true

    return false
}

function reduceRolesResToNames(rolesRes: RoleRes[]): string[] {
    return rolesRes.reduce<string[]>((acc, res) => {
        if (isMobileDevicesRoleRes(res) || isOnlyMobileRole(res)) return acc
        return [...acc, res.RoleName]
    }, [])
}
