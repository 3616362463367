import React, { ReactNode } from "react"
import BannerTemplate from "./Banner.template"
import {
    faCheckCircle,
    faExclamationCircle,
    IconDefinition,
} from "@fortawesome/pro-solid-svg-icons"

export function ErrorBanner({ children, className }: { children: ReactNode; className?: string }) {
    return (
        <Banner type={BannerType.ERROR} icon={faExclamationCircle} className={className}>
            {children}
        </Banner>
    )
}

export function InfoBanner({ children, className }: { children: ReactNode; className?: string }) {
    return (
        <Banner type={BannerType.INFO} icon={faExclamationCircle} className={className}>
            {children}
        </Banner>
    )
}

export function WarningBanner({
    children,
    className,
}: {
    children: ReactNode
    className?: string
}) {
    return (
        <Banner type={BannerType.WARNING} icon={faExclamationCircle} className={className}>
            {children}
        </Banner>
    )
}

export function SuccessBanner({
    children,
    className,
}: {
    children: ReactNode
    className?: string
}) {
    return (
        <Banner type={BannerType.SUCCESS} icon={faCheckCircle} className={className}>
            {children}
        </Banner>
    )
}

export class Banner extends React.Component<BannerProps, {}> {
    public render(): ReactNode {
        return BannerTemplate.call(this)
    }
}

export interface BannerProps {
    type: BannerType
    className?: string
    children: ReactNode
    icon: IconDefinition
}

export enum BannerType {
    INFO = "info",
    WARNING = "warning",
    ERROR = "error",
    SUCCESS = "success",
}
