import { faPlus } from "@fortawesome/pro-solid-svg-icons"
import React from "react"

import { useServiceLocalization, useServiceModal } from "../../../../../../../../../pre-v3/services"
import { useCallbackRef } from "../../../../../../../../../pre-v3/utils/UseCallbackRef"
import { File } from "../../../../../../../../services/TrustProfile.service"
import { Button } from "../../../../../../../../components/button/Button.component"
import { MenuItemProps } from "../../../../../../../../components/menu/menu-item/MenuItem.component"
import { MenuPopover } from "../../../../../../../../components/menu/menu-popover/MenuPopover.component"
import { SearchableMenu } from "../../../../../../../../components/menu/searchable-menu/SearchableMenu.component"
import { FileModal, AddFileModalActions } from "./FileModal.component"
import styles from "./SearchableButton.module.scss"

interface Props {
    files: File[]
    onMoveFileToUsed(file: File): void
}

export function SearchableButton(props: Props): JSX.Element {
    const localization = useServiceLocalization()
    const modalService = useServiceModal()

    const createFileLabel = localization.getString("createAFile")

    const [buttonRef, anchorEl] = useCallbackRef<HTMLButtonElement>()

    const mapFileMenuItem = React.useCallback(
        (file: File): MenuItemProps => ({
            type: "button",
            label: file.name,
            onClick: () => props.onMoveFileToUsed(file),
        }),
        [props.onMoveFileToUsed]
    )

    const menuItems = React.useMemo(
        (): MenuItemProps[] => props.files.map(mapFileMenuItem),
        [props.files]
    )

    const onCreateFile: React.MouseEventHandler = (event) => {
        event.preventDefault()

        modalService
            .open(
                localization.getString("addFile"),
                {
                    component: FileModal,
                    maxWidth: "lg",
                },
                AddFileModalActions
            )
            .onClose(props.onMoveFileToUsed)
    }

    const createFileButton = React.useMemo(
        (): MenuItemProps => ({
            type: "button",
            label: createFileLabel,
            icon: faPlus,
            className: styles.createFileButton,
            onClick: onCreateFile,
        }),
        []
    )

    if (props.files.length <= 0) {
        return (
            <Button icon={faPlus} onClick={onCreateFile} className={styles.addFileButton}>
                {createFileLabel}
            </Button>
        )
    }

    return (
        <React.Fragment>
            <Button icon={faPlus} ref={buttonRef} className={styles.addFileButton}>
                {localization.getString("file")}
            </Button>
            <MenuPopover anchorEl={anchorEl}>
                <SearchableMenu items={menuItems} staticItem={createFileButton} />
            </MenuPopover>
        </React.Fragment>
    )
}
