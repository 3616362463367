import React, { Fragment } from "react"
import styles from "./ClustersSshCa.module.scss"
import { Button } from "../../../components/button/Button.component"
import { CopyButton } from "../../../components"

export default function () {
    return (
        <div>
            <Button onClick={this.onTogglePubKey}>
                {this.state.showPubKey
                    ? this.localizationService.getString("hidePublicKey")
                    : this.localizationService.getString("showPublicKey")}
            </Button>
            {this.state.showPubKey && (
                <Fragment>
                    <CopyButton className={styles.copyButton} copyText={this.props.pubKey} />
                    <textarea
                        className={styles.certTextArea}
                        disabled
                        readOnly
                        value={this.props.pubKey}
                    ></textarea>
                </Fragment>
            )}
        </div>
    )
}
