import * as React from "react"

import { ROUTE, formatRoutePath } from "../../../../routes"
import { Chart, FormLabel, ToggleButton } from "../../../components"
import { useServiceLocalization, useServiceReporting } from "../../../services"
import { TimeRange } from "../../../utils/Date.util"
import { DevicePlatform } from "../../../api/Reporting.api"
import { encodeID } from "../../../utils/Url.util"
import {
    categorizeSeries1D,
    categorizeSeries1DGroup,
    chartColorMap,
    DataPoint,
    Series1DGroup,
} from "../utils"
import { NoDataOverlay, Widget } from "../Widget"
import styles from "./Widgets.module.scss"

interface Props {
    timeRange: TimeRange
}

export const ServicesBars = React.memo(function (props: Props) {
    const localization = useServiceLocalization()
    const reportingService = useServiceReporting()

    // filter states
    const [groupType, setGroupType] = React.useState<GroupType>(GroupType.PLATFORM)
    const [accessType, setAccessType] = React.useState<AccessType>(AccessType.AUTHORIZED)
    const [limit, setLimit] = React.useState(10)
    const [loading, setLoading] = React.useState(false)

    // chart data is either a list of groups or a list of data points
    const [data, setData] = React.useState<
        (({ kind: "group" } & Series1DGroup) | { kind: "1d"; series: DataPoint[] })[]
    >([])

    // when the filters/factors change we need to grab new data
    React.useEffect(() => {
        setLoading(true)
        if (accessType === AccessType.AUTHORIZED && groupType === GroupType.SERVICE) {
            reportingService
                .getTopServicesAccessedByUniqueUsers(
                    props.timeRange.start!,
                    props.timeRange.end!,
                    limit
                )
                .then((services) => {
                    setData([
                        {
                            kind: "1d",
                            series: services.map((s) => ({
                                displayName: s.name,
                                id: s.id,
                                value: s.count,
                            })),
                        },
                    ])
                    setLoading(false)
                })
        } else if (accessType === AccessType.BLOCKED && groupType === GroupType.SERVICE) {
            reportingService
                .getTopServicesAccessedByUniqueUsersBlocked(
                    props.timeRange.start!,
                    props.timeRange.end!,
                    limit
                )
                .then((services) => {
                    setData([
                        {
                            kind: "1d",
                            series: services.map((s) => ({
                                displayName: s.name,
                                id: s.id,
                                value: s.count,
                            })),
                        },
                    ])
                    setLoading(false)
                })
        } else if (accessType === AccessType.AUTHORIZED && groupType === GroupType.PLATFORM) {
            reportingService
                .getTopServicesAllowedByPlatform(
                    props.timeRange.start!,
                    props.timeRange.end!,
                    limit
                )
                .then((services) => {
                    setData(
                        services.map((service) => {
                            return {
                                kind: "group",
                                groupName: service.serviceName,
                                groupId: service.id,
                                series: service.platformAccessCounts.map((c) => {
                                    return { displayName: c.platform, value: c.count }
                                }),
                            }
                        })
                    )
                    setLoading(false)
                })
        } else if (accessType === AccessType.BLOCKED && groupType === GroupType.PLATFORM) {
            reportingService
                .getTopServicesBlockedByPlatform(
                    props.timeRange.start!,
                    props.timeRange.end!,
                    limit
                )
                .then((services) => {
                    setData(
                        services.map((service) => {
                            return {
                                kind: "group",
                                groupName: service.serviceName,
                                groupId: service.id,
                                series: service.platformAccessCounts.map((c) => {
                                    return { displayName: c.platform, value: c.count }
                                }),
                            }
                        })
                    )
                    setLoading(false)
                })
        } else if (accessType === AccessType.AUTHORIZED && groupType === GroupType.TRUSTLEVEL) {
            reportingService
                .getTopServicesAllowedByTrustLevel(
                    props.timeRange.start!,
                    props.timeRange.end!,
                    limit
                )
                .then((services) => {
                    setData(
                        services.map((service) => {
                            return {
                                kind: "group",
                                groupName: service.serviceName,
                                groupId: service.id,
                                series: service.trustLevelAccessCounts.map((c) => {
                                    return { displayName: c.trustlevel, value: c.count }
                                }),
                            }
                        })
                    )
                    setLoading(false)
                })
        } else if (accessType === AccessType.BLOCKED && groupType === GroupType.TRUSTLEVEL) {
            reportingService
                .getTopServicesBlockedByTrustLevel(
                    props.timeRange.start!,
                    props.timeRange.end!,
                    limit
                )
                .then((services) => {
                    setData(
                        services.map((service) => {
                            return {
                                kind: "group",
                                groupName: service.serviceName,
                                groupId: service.id,
                                series: service.trustLevelAccessCounts.map((c) => {
                                    return { displayName: c.trustlevel, value: c.count }
                                }),
                            }
                        })
                    )
                    setLoading(false)
                })
        }
    }, [groupType, accessType, limit, reportingService, props.timeRange])

    // data holds the raw data, we need to figure out the categories and series to show
    const { categories, series, idMap } = React.useMemo(() => {
        // if we dont have data yet, just return empty lists
        if (data.length === 0) {
            return {
                categories: [],
                series: [],
                idMap: {},
            }
        }

        // if the data is grouped
        if (data[0]?.kind === "group") {
            return categorizeSeries1DGroup(data as Series1DGroup[])
        }

        // data is 1 dimensional
        return categorizeSeries1D(data[0].series)
    }, [data])

    // make sure the legend has the right title
    let legendTitle = ""
    if (groupType === GroupType.TRUSTLEVEL) {
        legendTitle = localization.getString("trustLevels")
    } else if (groupType === GroupType.PLATFORM) {
        legendTitle = localization.getString("devicePlatforms")
    }

    return (
        <Widget gridArea="service-access" loading={false} hasData={true}>
            <h2 className={styles.title}>
                {localization.getString("topServicesAccessedByUniqueUsers")}
            </h2>
            <div className={styles.toggleContainer}>
                <FormLabel
                    title={localization.getString("showUniqueUsersAccessingAServiceWith")}
                    slim
                    labelClassName={styles.toggleButtonLabel}
                >
                    <ToggleButton
                        items={[
                            {
                                label: localization.getString("authorizedAccess"),
                                onClick: () => {
                                    setAccessType(AccessType.AUTHORIZED)
                                },
                                selected: accessType === AccessType.AUTHORIZED,
                            },
                            {
                                label: localization.getString("blockedAttempt"),
                                onClick: () => {
                                    setAccessType(AccessType.BLOCKED)
                                },
                                selected: accessType === AccessType.BLOCKED,
                            },
                        ]}
                        className={styles.toggleButton}
                    />
                </FormLabel>
                <FormLabel
                    title={localization.getString("groupBy")}
                    slim
                    labelClassName={styles.toggleButtonLabel}
                >
                    <ToggleButton
                        items={[
                            {
                                label: localization.getString("service"),
                                onClick: () => {
                                    setGroupType(GroupType.SERVICE)
                                },
                                selected: groupType === GroupType.SERVICE,
                            },
                            {
                                label: "+ " + localization.getString("deviceOS"),
                                onClick: () => {
                                    setGroupType(GroupType.PLATFORM)
                                },
                                selected: groupType === GroupType.PLATFORM,
                            },
                            {
                                label: "+ " + localization.getString("trustLevel"),
                                onClick: () => {
                                    setGroupType(GroupType.TRUSTLEVEL)
                                },
                                selected: groupType === GroupType.TRUSTLEVEL,
                            },
                        ]}
                        className={styles.toggleButton}
                    />
                </FormLabel>
                <FormLabel
                    title={localization.getString("limitTo")}
                    slim
                    labelClassName={styles.toggleButtonLabel}
                >
                    <ToggleButton
                        items={[
                            {
                                label: localization.getString("top") + " 10",
                                onClick: () => {
                                    setLimit(10)
                                },
                                selected: limit === 10,
                            },
                            {
                                label: localization.getString("top") + " 20",
                                onClick: () => {
                                    setLimit(20)
                                },
                                selected: limit === 20,
                            },
                        ]}
                        className={styles.toggleButton}
                    />
                </FormLabel>
            </div>
            {series.length === 0 && !loading ? (
                <NoDataOverlay />
            ) : (
                <Chart
                    options={{
                        chart: {
                            type: "bar",
                            backgroundColor: "transparent",
                        },
                        xAxis: {
                            categories,
                            allowDecimals: false,
                            title: {
                                text: localization.getString("serviceName"),
                            },
                            labels: {
                                style: {
                                    color: "#222C36",
                                    fontWeight: "600",
                                },
                                formatter(ctx) {
                                    // all labels are links to the service overview
                                    const value: string = ctx.value.toString().split(".")[0]
                                    const id = idMap[value]

                                    const hrefLink = formatRoutePath(
                                        ROUTE.PRIVATE_ACCESS_SERVICES_DETAILS,
                                        {
                                            id: encodeID(id),
                                        }
                                    )

                                    return `<a href="${hrefLink}">${value}</a>`
                                },
                            },
                        },
                        yAxis: {
                            gridLineWidth: 1,
                            allowDecimals: false,
                            gridLineColor: "#D7D9E4",
                            title: {
                                text: "",
                            },
                        },
                        legend: {
                            enabled: true,
                            layout: "vertical",
                            align: "right",
                            verticalAlign: "middle",
                            symbolRadius: 0,
                            itemStyle: {
                                color: "#222C36",
                                fontWeight: "600",
                            },
                            title: {
                                text: legendTitle,
                                style: { color: "#222C36" },
                            },
                        },
                        colors: [
                            chartColorMap[DevicePlatform.ANDROID],
                            chartColorMap[DevicePlatform.APPLE],
                            chartColorMap[DevicePlatform.IOS],
                            chartColorMap[DevicePlatform.LINUX],
                            chartColorMap[DevicePlatform.MACOS],
                            chartColorMap[DevicePlatform.OTHER],
                        ],
                        plotOptions: {
                            series: {
                                stacking: "normal",
                            },
                            bar: {
                                dataLabels: {
                                    enabled: false,
                                },
                                borderColor: undefined,
                                groupPadding: 0.05,
                                maxPointWidth: 40,
                            },
                        },
                        series: series.map((s) => {
                            if (s.name) {
                                // Pending API fixes
                                if (s.name === "android") {
                                    s.name = DevicePlatform.ANDROID
                                }
                                if (s.name === "ios") {
                                    s.name = DevicePlatform.IOS
                                }
                            }
                            return {
                                ...s,
                                color: chartColorMap[s.name],
                            }
                        }),
                    }}
                />
            )}
        </Widget>
    )
})

enum GroupType {
    SERVICE = "SERVICE",
    PLATFORM = "PLATFORM",
    TRUSTLEVEL = "TRUSTLEVEL",
}

enum AccessType {
    AUTHORIZED = "AUTHORIZED",
    BLOCKED = "BLOCKED",
}
