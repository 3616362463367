import React, { forwardRef, Ref } from "react"
import classNames from "classnames/bind"

import styles from "./Form.module.scss"

export interface Props extends React.FormHTMLAttributes<HTMLFormElement> {}

function FormComponent(props: Props, ref: Ref<HTMLFormElement>): JSX.Element {
    return <form {...props} ref={ref} className={classNames(styles.form, props.className)} />
}

export const Form = forwardRef(FormComponent)
