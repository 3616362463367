import { DateUtil, HumanTimespan } from "../utils/Date.util"

const CACHE_KEY = "__cache"

/**
 * Decorator to cache values from a function. The function must return a promise.
 * IMPORTANT: this assumes the final parameter of the function is an optional { force: boolean }
 * if that parameter is detected as the final paramter, it can be used to bust the cached
 * value of that function. If that paramter is not detected, it assumes force = false
 * @param time duration before cache is invalid. default 15 minutes
 */
export function Cache(time: HumanTimespan = { minutes: 15 }): any {
    return (target: any, propertyKey: string, descriptor: PropertyDescriptor) => {
        // called once per decorator use
        return {
            configurable: descriptor.configurable,
            enumerable: descriptor.enumerable,
            get(this: any): any {
                // called the first time the decorated method is invoked, once per class instance
                Object.defineProperty(this, propertyKey, {
                    value: (...args: any) =>
                        cacheFn(this, propertyKey, time, descriptor.value, args),
                    configurable: true,
                    enumerable: false,
                    writable: false,
                })

                return this[propertyKey]
            },
        }
    }
}

export class CacheDecorator {
    /**
     * Deletes the cached value for a method
     * @param context the instance of a class with the annotated cached method
     * @param propertyKey the name of the cached method
     */
    public static invalidateCache(context: any, propertyKey: string): void {
        Object.defineProperty(context[propertyKey], CACHE_KEY, {
            configurable: true,
            enumerable: false,
            writable: false,
            value: undefined,
        })
    }
}

/**
 * Parses args, returns force value from last arg if it exists
 * and is of the correct type
 */
function shouldForce(props: any): boolean {
    if (props.length < 1) {
        return false
    }
    const lastArg: any = props[props.length - 1]
    if (!lastArg || typeof lastArg !== "object") {
        return false
    }
    const keys: string[] = Object.keys(lastArg)
    if (keys.length !== 1) {
        return false
    }
    return !!lastArg.force
}

/**
 * INTERNAL ONLY
 * @param context the instance of the object that owns the method (commonly known as "this")
 * @param propertyKey the name of the method, as a string
 * @param timespan the amount of time to cache the result until it is considered outdated
 * @param originalFn the original method, the one to be cached
 * @param args the original arguments for the method, possibly terminated with a CacheBuster object
 * @returns
 */
function cacheFn(
    context: any,
    propertyKey: string,
    timespan: HumanTimespan,
    originalFn: any,
    args: any
): any {
    let cachedValue: any
    let cachedPromise: Promise<any> | undefined
    const force: boolean = shouldForce(args)
    const descriptor: PropertyDescriptor | undefined = Object.getOwnPropertyDescriptor(
        context[propertyKey],
        CACHE_KEY
    )
    if (descriptor && descriptor.value) {
        const cachedTime: number = descriptor.value.lastUpdated
        const expired: boolean = DateUtil.hasBeenSince(cachedTime, timespan) // 15 minutes
        if (!expired) {
            cachedValue = descriptor.value.value
            cachedPromise = descriptor.value.promise
        }
    }

    if (!force) {
        if (cachedValue) {
            return Promise.resolve(cachedValue)
        }
        if (cachedPromise) {
            return cachedPromise
        }
    }

    cachedPromise = originalFn
        .apply(context, args)
        .then((result: any) => {
            Object.defineProperty(context[propertyKey], CACHE_KEY, {
                configurable: true,
                enumerable: false,
                writable: false,
                value: { value: result, lastUpdated: Date.now(), cachedPromise: undefined },
            })

            return result
        })
        .finally(() => (cachedPromise = undefined))

    Object.defineProperty(context[propertyKey], CACHE_KEY, {
        configurable: true,
        enumerable: false,
        writable: false,
        value: { promise: cachedPromise, lastUpdated: Date.now() },
    })

    return cachedPromise
}

export interface CacheBuster {
    force: boolean
}

interface CachedValue {
    value: any
    promise: Promise<any>
    lastUpdated: number
}
