import React from "react"
import { Grid } from "../../../components/grid/Grid.component"
import { Banner } from "../../../components/banner/Banner.component"
import { UsersEmailCellRenderer } from "./email/UsersEmailCellRenderer.component"
import styles from "./UnregisteredDevicesList.module.scss"
import { faExclamationCircle } from "@fortawesome/pro-solid-svg-icons"
import { FilterBar } from "../../../components"
import { PageHeading } from "../../../../components/page-heading/PageHeading.component"
import { IconType } from "../../../services"
import { Button, ButtonElement, ButtonType } from "../../../../components/button/Button.component"
import { Tooltip } from "../../../../v3/components/tooltip/Tooltip.component"
import { Upsell } from "../../../../shared/upsell/Upsell.component"

export default function () {
    if (this.props.doShowUpsellView) {
        return <Upsell />
    }

    return (
        <section aria-labelledby="heading" className={styles.container}>
            <header className={styles.header}>
                <PageHeading id="heading">{this.ls.getString("unregisteredDevices")}</PageHeading>
                <div className={styles.actionButtons}>
                    {this.state.isAdminProfile && (
                        <Tooltip
                            title={
                                this.state.selectedDevicesLen
                                    ? "deleteDevice"
                                    : "selectAUnregisteredDeviceToDelete"
                            }
                        >
                            <Button
                                icon={IconType.TRASH}
                                onClick={this.onDeleteDevices}
                                asElement={ButtonElement.BUTTON}
                                buttonType={ButtonType.SECONDARY}
                                aria-label={
                                    this.state.selectedDevicesLen
                                        ? "deleteDevice"
                                        : "selectAUnregisteredDeviceToDelete"
                                }
                                disabled={this.state.selectedDevicesLen === 0}
                            />
                        </Tooltip>
                    )}
                    <Tooltip title={this.ls.getString("downloadDevices")}>
                        <Button
                            icon={IconType.FILE_DOWNLOAD}
                            onClick={this.onDownloadDevices}
                            asElement={ButtonElement.BUTTON}
                            buttonType={ButtonType.SECONDARY}
                            aria-label={this.ls.getString("downloadDevices")}
                        />
                    </Tooltip>
                    <Tooltip title={this.ls.getString("refresh")}>
                        <Button
                            icon={IconType.REDO}
                            onClick={this.onRefresh}
                            asElement={ButtonElement.BUTTON}
                            buttonType={ButtonType.SECONDARY}
                            aria-label={this.ls.getString("refresh")}
                        />
                    </Tooltip>
                </div>
            </header>
            {this.state.error && (
                <Banner type="error" icon={faExclamationCircle}>
                    {this.state.error}
                </Banner>
            )}
            <FilterBar
                filters={this.filters}
                className={styles.filterBar}
                onChange={this.onFilterChange}
                initialModel={this.initialModel}
            />
            <Grid
                onGridReady={this.onGridReady}
                columnDefs={this.columns}
                context={{ history: this.props.history }}
                serverSidePagination
                onRowDoubleClicked={this.rowDoubleClickedHandler}
                onRowSelected={this.rowSelectedHandler}
                components={{ usersEmailCellRenderer: UsersEmailCellRenderer }}
                multiSelect
                getRowId={this.getRowId}
            />
        </section>
    )
}
