import { faPlus } from "@fortawesome/pro-light-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import classNames from "classnames/bind"
import React, { SyntheticEvent, useEffect, useMemo, useState } from "react"
import { LocalizationService, ModalService } from "../../../../../../../../pre-v3/services"
import { FormUtil } from "../../../../../../../../pre-v3/utils/Form.util"
import { AppText } from "../../../../../../../components/app-text/AppText.component"
import { Button } from "../../../../../../../components/button/Button.component"
import { Form } from "../../../../../../../components/form/Form.component"
import { FormButtons } from "../../../../../../../components/form/form-buttons/FormButtons.component"
import { FormRow } from "../../../../../../../components/form/FormRow.component"
import { Input } from "../../../../../../../components/input/Input.component"
import { OsInput, OsInputValue } from "../../../../../../../components/os-input/OsInput.component"
import {
    PillContainer,
    PillProps,
} from "../../../../../../../components/pill-container/PillContainer.component"
import {
    ToggleButton,
    ToggleButtonItem,
} from "../../../../../../../components/toggle-button/ToggleButton"

import { AppCheckConfig, AppCheckFactor } from "../../../../../../../services/TrustProfile.service"
import { Platform } from "../../../../../../../services/shared/Platform"
import styles from "./ApplicationCheckFactor.module.scss"

interface Props {
    trustFactor: AppCheckFactor
    className?: string
    onEdit(trustFactor: AppCheckFactor): void
    disabled?: boolean
}

const emptyApp: AppCheckConfig = {
    name: "",
    mandatory: false,
    processName: {
        [Platform.MACOS]: "",
        [Platform.WINDOWS]: "",
        [Platform.LINUX]: "",
    },
}

export function ApplicationCheck(props: Props): JSX.Element {
    const ls: LocalizationService = new LocalizationService()
    const modalService: ModalService = new ModalService()
    const apps: AppCheckConfig[] = props.trustFactor.config

    const [selectedAppId, setSelectedAppId] = useState<string>("")
    const [newApp, setNewApp] = useState<boolean>(false)

    const showAppForm = selectedAppId || newApp

    useEffect(() => {
        if (props.disabled) {
            onCancel()
        }
    }, [props.disabled])

    function findApp(appId: string): AppCheckConfig {
        return apps[Number(appId)]
    }

    function onAppSelected(appId: string): void {
        setSelectedAppId(appId)
    }

    function onAppDeleted(appId: string): void {
        const appToDelete: AppCheckConfig = findApp(appId)

        modalService
            .openConfirmation(
                ls.getString("deleteApplicationCheck"),
                ls.getString("areYouSureYouWantToDeleteTheApplication", appToDelete.name)
            )
            .onClose(() => {
                if (selectedAppId === appId) {
                    setSelectedAppId("")
                }

                const newApps: AppCheckConfig[] = [...apps]
                    .filter((a) => a.name !== appToDelete.name)
                    .sort(sortApps)
                props.onEdit({
                    ...props.trustFactor,
                    config: newApps,
                })
            })
    }

    function onAdd(): void {
        setNewApp(true)
    }

    function onSave(app: AppCheckConfig): void {
        if (selectedAppId) {
            const newApps: AppCheckConfig[] = [...apps]
                .filter((a, aIndex) => `${aIndex}` !== selectedAppId)
                .concat(app)
                .sort(sortApps)
            props.onEdit({
                ...props.trustFactor,
                config: newApps,
            })
        } else {
            const newApps: AppCheckConfig[] = [...apps, app].sort(sortApps)
            props.onEdit({
                ...props.trustFactor,
                config: newApps,
            })
        }
        setSelectedAppId("")
        setNewApp(false)
    }

    function onCancel(): void {
        setSelectedAppId("")
        setNewApp(false)
    }

    return (
        <div className={classNames(styles.container, props.className)}>
            <AppText ls="applicationCheckFactorDescription" />
            {apps && apps.length > 0 && (
                <PillContainer
                    pills={apps.map((app, appIndex): PillProps => {
                        const appId = `${appIndex}`

                        return {
                            id: appId,
                            brand: appId === selectedAppId,
                            label: app.name,
                            onClick: onAppSelected,
                            onDelete: onAppDeleted,
                            disabled: props.disabled,
                        }
                    })}
                    className={styles.pillContainer}
                />
            )}
            {showAppForm && (
                <ApplicationCheckForm
                    app={newApp ? emptyApp : findApp(selectedAppId)}
                    onCancel={onCancel}
                    onSave={onSave}
                />
            )}
            {!showAppForm && !props.disabled && (
                <Button onClick={onAdd}>
                    <FontAwesomeIcon icon={faPlus} />
                    {ls.getString("app")}
                </Button>
            )}
        </div>
    )
}

function sortApps(a: AppCheckConfig, b: AppCheckConfig): number {
    return a.name > b.name ? 1 : -1
}

interface ApplicationCheckFormProps {
    app: AppCheckConfig
    onCancel: () => void
    onSave: (app: AppCheckConfig) => void
}

function ApplicationCheckForm(props: ApplicationCheckFormProps): JSX.Element {
    const ls: LocalizationService = new LocalizationService()

    const [name, setName] = useState<string>(props.app.name)
    const [mandatory, setMandatory] = useState<boolean>(props.app.mandatory)
    const [processNames, setProcessNames] = useState<OsInputValue>(props.app.processName)

    const yesNo: ToggleButtonItem[] = useMemo(() => {
        return [
            {
                label: ls.getString("yes"),
                value: "yes",
                selected: mandatory,
                onClick: () => setMandatory(true),
            },
            {
                label: ls.getString("no"),
                value: "no",
                selected: !mandatory,
                onClick: () => setMandatory(false),
            },
        ]
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [mandatory])

    useEffect(() => {
        setName(props.app.name)
        setMandatory(props.app.mandatory)
        setProcessNames(props.app.processName)
    }, [props.app])

    function onSubmit(event: SyntheticEvent): void {
        event.preventDefault()
        props.onSave({
            name: name,
            mandatory: mandatory,
            processName: processNames,
        })
    }

    function onCancel(event: SyntheticEvent): void {
        event.preventDefault()

        props.onCancel()
    }

    return (
        <Form onSubmit={onSubmit}>
            <FormRow label={ls.getString("applicationName")}>
                <Input
                    name="appName"
                    type="text"
                    required
                    value={name}
                    onChange={(e) => setName(FormUtil.getInputValue(e))}
                />
            </FormRow>
            <FormRow
                label={ls.getString("mandatory")}
                description={ls.getString("applicationCheckFactorMandatoryDescription")}
                childrenClassName={styles.flex}
            >
                <ToggleButton items={yesNo} />
            </FormRow>
            <FormRow
                label={ls.getString("processNames")}
                description={ls.getString("applicationCheckFactorProcessNamesDescription")}
            >
                <OsInput
                    name="appCheckFactor"
                    value={processNames}
                    onChange={(v) => setProcessNames(v)}
                />
            </FormRow>
            <FormButtons
                leftButtons={
                    <>
                        <Button type="button" onClick={onCancel}>
                            {ls.getString("cancel")}
                        </Button>
                        <Button brand type="submit">
                            {ls.getString("save")}
                        </Button>
                    </>
                }
            />
        </Form>
    )
}
