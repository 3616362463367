import { faFileImage } from "@fortawesome/pro-light-svg-icons"
import { faTimes } from "@fortawesome/pro-regular-svg-icons"
import classNames from "classnames"
import React from "react"

import { Icon } from "../Icon/Icon.component"
import { RoundButton } from "../round-button/RoundButton.component"
import styles from "./ImageUpload.module.scss"

type OmittedProps = "hidden" | "type" | "onChange" | "value" | "onDragOver" | "onDrop" | "multiple"

export interface ImageUploadProps extends Omit<React.ComponentProps<"input">, OmittedProps> {
    label: string
    value?: string
    removeLabel?: string
    onChange?(file?: string): void
}

export function ImageUpload(props: ImageUploadProps): JSX.Element {
    const {
        accept = "image/*",
        value,
        className,
        label,
        removeLabel,
        onChange: originalOnChange,
        ...inputProps
    } = props

    const [file, setFile] = React.useState(value)

    React.useEffect(() => setFile(value), [value])

    const updateValue = async (fileList: FileList | null) => {
        const file = fileList?.[0] && (await getBase64(fileList[0]))
        setFile(file)
        originalOnChange?.(file)
    }

    const onChange: React.ChangeEventHandler<HTMLInputElement> = (event) => {
        event.preventDefault()
        updateValue(event.target.files)
    }

    const onDragOver: React.DragEventHandler = (event) => event.preventDefault()

    const onDrop: React.DragEventHandler = (event) => {
        event.preventDefault()

        if (!doesMatchAccept(event.dataTransfer.files, accept)) return

        updateValue(event.dataTransfer.files)
    }

    const onKeyPressOrUp: React.KeyboardEventHandler<HTMLSpanElement> = (event) => {
        if (relevantKeyPresses.includes(event.key)) {
            event.preventDefault()
            event.currentTarget.click()
        }
    }

    const onRemove: React.MouseEventHandler = (event) => {
        event.preventDefault()
        updateValue(null)
    }

    if (file) {
        return (
            <div className={classNames(styles.imageContainer, className)}>
                <RoundButton
                    icon={faTimes}
                    aria-label={removeLabel}
                    onClick={onRemove}
                    className={styles.removeButton}
                />
                <div className={styles.image} style={{ backgroundImage: `url("${file}")` }} />
            </div>
        )
    }

    return (
        <label className={className}>
            <span
                role="button"
                tabIndex={0}
                className={styles.container}
                onDragOver={onDragOver}
                onDrop={onDrop}
                onKeyPress={onKeyPressOrUp}
                onKeyUp={onKeyPressOrUp}
            >
                <Icon icon={faFileImage} className={styles.imageIcon} />
                {label}
            </span>
            <input
                {...inputProps}
                type="file"
                accept={accept}
                onChange={onChange}
                multiple={false}
                hidden
            />
        </label>
    )
}

function doesMatchAccept(files: FileList | null, acceptValue: string): boolean {
    if (!files) return true

    const acceptArray = acceptValue.split(",").map((type) => type.trim())

    for (const file of files) {
        if (!acceptArray.some((type) => file.type.match(type))) return false
    }

    return true
}

const relevantKeyPresses = ["Enter", " "]

function getBase64(file: File) {
    return new Promise<string>((resolve, reject) => {
        const reader = new FileReader()
        reader.readAsDataURL(file)
        reader.onload = () => resolve(reader.result as string)
        reader.onerror = (error) => reject(error)
    })
}
