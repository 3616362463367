import * as React from "react"
import ActionBarService from "./ActionBar.service"
import { EntityService } from "./Entity.service"
import { InfraService } from "./Infra.service"
import { LocalizationService } from "./localization/Localization.service"
import { ManageService } from "./Manage.service"
import { ModalService } from "./Modal.service"
import { ReportingService } from "./Reporting.service"
import { SecureService } from "./Secure.service"
import { SettingsService } from "./Settings.service"
import { StatsService } from "./Stats.service"
import { TrustFactorService } from "./TrustFactor.service"
import { UserService } from "./User.service"
import { CertsService } from "./Certs.service"
import { LinkService } from "./link/Link.service"
import { ServiceService } from "./Service.service"
import { InventoryService } from "./Inventory.service"

type ServiceMap = {
    actionBar: ActionBarService
    entity: EntityService
    infra: InfraService
    localization: LocalizationService
    manage: ManageService
    modal: ModalService
    reporting: ReportingService
    secure: SecureService
    settings: SettingsService
    service: ServiceService
    stats: StatsService
    trustFactor: TrustFactorService
    user: UserService
    certs: CertsService
    links: LinkService
    inventory: InventoryService
}

// the default context provides non-mocked values of the services
export default React.createContext<ServiceMap | null>(null)
