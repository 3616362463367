import { Dispatch, SetStateAction, useState } from "react"

export function useFallbackState<T>(
    defaultValue: T | (() => T),
    parentState?: T,
    setParentState?: React.Dispatch<T>
): [T, Dispatch<SetStateAction<T>>] {
    const [state, setState] = useState<T>(parentState ?? defaultValue)

    const localState = parentState ?? state

    function setLocalState(actionOrValue: SetStateAction<T>) {
        const updatedState =
            typeof actionOrValue === "function"
                ? (actionOrValue as (prevState: T) => T)(localState)
                : actionOrValue

        setState(updatedState)

        if (typeof setParentState === "function") {
            setParentState(updatedState)
        }
    }

    return [localState, setLocalState]
}
