import { BaseApi } from "./Base.api"
import { Singleton } from "../../pre-v3/decorators/Singleton.decorator"

@Singleton("AnalyticsApi")
export class AnalyticsApi extends BaseApi {
    public getOrgAnalytics(): Promise<OrgAnalyticsRes> {
        return this.get(`/api/v2/org/analytics`, {
            default: this.localization.getString("failedToGetOrgAnalytics"),
        })
    }
}

export interface OrgAnalyticsRes {
    org_id: string
    org_name: string
    edition: "team" | "enterprise" | "unlimited"
    created_at: number
    updated_at: number
    license_count: number
}
