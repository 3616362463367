import {
    QueryKey,
    UseMutationResult,
    UseQueryResult,
    useMutation,
    useQuery,
    useQueryClient,
} from "@tanstack/react-query"

import { LinkKey, LinkService } from "../../pre-v3/services/link/Link.service"
import { LanguageKey } from "../../pre-v3/services/localization/languages/en-US.language"
import { LocalizationService } from "../../pre-v3/services/localization/Localization.service"
import { CollectionUtil } from "../../pre-v3/utils/Collection.util"
import {
    ConfigRes,
    PlatformRes,
    RemediationRes,
    TrustFactorApi,
    TrustFactorRes,
    TrustFactorSource,
    TrustProfileRes,
    UpdateTrustFactorRemediationReq,
} from "../api/TrustFactor.api"
import {
    DesktopPlatform,
    Platform,
    noPlatforms,
    onlyDesktopPlatforms,
    onlyMac,
    onlyWindows,
} from "./shared/Platform"
import { TrustFactorType, descriptionDictionary, nameDictionary } from "./shared/TrustFactorType"

/**
 * Manipulate the Master List of Trust Factors
 */
export class TrustFactorService {
    private linkService = new LinkService()
    private localization = new LocalizationService()
    private trustFactorApi = new TrustFactorApi()

    public async getTrustFactors(): Promise<TrustFactor[]> {
        const trustFactors = await this.trustFactorApi.getTrustFactors()

        try {
            return this.reduceSortTrustFactors(trustFactors)
        } catch (error) {
            throw this.localization.getString("failedToFetchTrustFactors")
        }
    }

    public async createFileCheckTrustFactor(fileCheck: NewFile): Promise<File> {
        const res = await this.trustFactorApi.createTrustFactor({
            display_name: fileCheck.name,
            source: "File Check",
            type: "extended",
            config: getFileCheckConfigRes(fileCheck.configurations),
        })

        return mapFileCheck(res)
    }

    public async updateFileCheckTrustFactor(fileCheck: EditFile): Promise<File> {
        const res = await this.trustFactorApi.updateTrustFactor({
            factor_id: fileCheck.id,
            name: fileCheck.name,
            display_name: fileCheck.name,
            type: "extended",
            config: getFileCheckConfigRes(fileCheck.configurations),
            source: TrustFactorSource.fileCheck,
        })

        return mapFileCheck(res)
    }

    public async deleteFileCheckTrustFactor(fileCheck: File): Promise<void> {
        await this.trustFactorApi.deleteTrustFactor({
            factor_id: fileCheck.id,
            source: "File Check",
            type: "extended",
        })
    }

    public async createPlistTrustFactor(plist: NewPlist): Promise<Plist> {
        const res = await this.trustFactorApi.createTrustFactor({
            display_name: plist.name,
            source: TrustFactorType.PLIST,
            type: "extended",
            config: getPlistConfigRes(plist.configuration),
        })

        return mapPlist(res)
    }

    public async updatePlistTrustFactor(plist: EditPlist): Promise<Plist> {
        const res = await this.trustFactorApi.updateTrustFactor({
            factor_id: plist.id,
            name: plist.name,
            display_name: plist.name,
            type: "extended",
            config: getPlistConfigRes(plist.configuration),
            source: TrustFactorSource.pList,
        })

        return mapPlist(res)
    }

    public async deletePlistTrustFactor(plist: Plist): Promise<void> {
        await this.trustFactorApi.deleteTrustFactor({
            factor_id: plist.id,
            source: TrustFactorType.PLIST,
            type: "extended",
        })
    }

    public async createRegistryCheckTrustFactor(
        registryCheck: NewRegistryCheck
    ): Promise<RegistryCheck> {
        const res = await this.trustFactorApi.createTrustFactor({
            display_name: registryCheck.name,
            source: TrustFactorType.REGISTRY_CHECK,
            type: "extended",
            config: getRegistryCheckConfigRes(registryCheck.configuration),
        })

        return mapRegistryCheck(res)
    }

    public async updateRegistryCheckTrustFactor(
        registryCheck: EditRegistryCheck
    ): Promise<RegistryCheck> {
        const res = await this.trustFactorApi.updateTrustFactor({
            factor_id: registryCheck.id,
            name: registryCheck.name,
            display_name: registryCheck.name,
            type: "extended",
            config: getRegistryCheckConfigRes(registryCheck.configuration),
            source: TrustFactorSource.registryKey,
        })

        return mapRegistryCheck(res)
    }

    public async deleteRegistryCheckTrustFactor(registryCheck: RegistryCheck): Promise<void> {
        await this.trustFactorApi.deleteTrustFactor({
            factor_id: registryCheck.id,
            source: TrustFactorType.REGISTRY_CHECK,
            type: "extended",
        })
    }

    public async getRemediationPerTrustFactor(): Promise<TrustFactorRemediation[]> {
        const trustFactors = await this.trustFactorApi.getTrustFactors()
        return this.combineRemediationPerTrustFactor(trustFactors).sort(
            this.compareTrustFactors.bind(this)
        )
    }

    public async updateRemediationPerTrustFactor(
        remediation: TrustFactorRemediation[]
    ): Promise<TrustFactorRemediation[]> {
        const remediationBody = remediation.reduce(reduceUpdateTrustFactorsRemediationReq, [])
        const updatedRemediation =
            await this.trustFactorApi.updateTrustFactorsRemediation(remediationBody)

        return this.combineRemediationPerTrustFactor(updatedRemediation)
    }

    /**
     * We need to combine the different configurable Trust Factors to show them
     * as one in the UI.
     * @param res List of Trust Factors generated by the BE
     */
    private reduceSortTrustFactors(res: TrustFactorRes[]): TrustFactor[] {
        const fileCheckType = TrustFactorType.FILE_CHECK
        const plistType = TrustFactorType.PLIST
        const registryCheckType = TrustFactorType.REGISTRY_CHECK

        // Set up all the Trust Factors that needs to be rolled up into one
        const emptyAcc: TrustFactorAcc = {
            nonConfigurableTrustFactors: [],
            fileCheckTrustFactor: {
                type: fileCheckType,
                name: this.localization.getString(nameDictionary[fileCheckType]),
                description: this.localization.getString(descriptionDictionary[fileCheckType]),
                source: this.localization.getString("sonicWallCse"),
                applicablePlatform: onlyDesktopPlatforms,
                trustProfiles: [],
                files: [],
            },
            fileCheckTrustProfileMap: new Map(),
            plistTrustFactor: {
                type: plistType,
                name: this.localization.getString(nameDictionary[plistType]),
                description: this.localization.getString(descriptionDictionary[plistType]),
                source: this.localization.getString("sonicWallCse"),
                applicablePlatform: onlyMac,
                trustProfiles: [],
                plists: [],
            },
            plistTrustProfileMap: new Map(),
            registryCheckTrustFactor: {
                type: registryCheckType,
                name: this.localization.getString(nameDictionary[registryCheckType]),
                description: this.localization.getString(descriptionDictionary[registryCheckType]),
                source: this.localization.getString("sonicWallCse"),
                applicablePlatform: onlyWindows,
                trustProfiles: [],
                registryChecks: [],
            },
            registryCheckTrustProfileMap: new Map(),
        }

        // Roll up some Trust Factors and map others
        const {
            nonConfigurableTrustFactors,
            fileCheckTrustFactor: incompleteFileCheckTrustFactor,
            fileCheckTrustProfileMap,
            plistTrustFactor: incompletePlistTrustFactor,
            plistTrustProfileMap,
            registryCheckTrustFactor: incompleteRegistryCheckTrustFactor,
            registryCheckTrustProfileMap,
        } = res.reduce(this.reduceTrustFactor.bind(this), emptyAcc)

        const fileCheckTrustFactor: FileCheckTrustFactor = {
            ...incompleteFileCheckTrustFactor,
            files: CollectionUtil.caseInsensitiveSort(
                incompleteFileCheckTrustFactor.files,
                "name",
                this.localization
            ),
            trustProfiles: Array.from(fileCheckTrustProfileMap.values()),
        }

        const plistTrustFactor: PlistTrustFactor = {
            ...incompletePlistTrustFactor,
            plists: CollectionUtil.caseInsensitiveSort(
                incompletePlistTrustFactor.plists,
                "name",
                this.localization
            ),
            trustProfiles: Array.from(plistTrustProfileMap.values()),
        }

        const registryCheckTrustFactor: RegistryCheckTrustFactor = {
            ...incompleteRegistryCheckTrustFactor,
            registryChecks: CollectionUtil.caseInsensitiveSort(
                incompleteRegistryCheckTrustFactor.registryChecks,
                "name",
                this.localization
            ),
            trustProfiles: Array.from(registryCheckTrustProfileMap.values()),
        }

        return [
            ...nonConfigurableTrustFactors,
            fileCheckTrustFactor,
            plistTrustFactor,
            registryCheckTrustFactor,
        ].sort(this.compareTrustFactors.bind(this))
    }

    private reduceTrustFactor(acc: TrustFactorAcc, res: TrustFactorRes): TrustFactorAcc {
        if (res.source === TrustFactorType.PLIST) return addPlistTrustFactor(acc, res)
        if (res.source === TrustFactorType.FILE_CHECK) return addFileCheckTrustFactor(acc, res)
        if (res.source === TrustFactorType.REGISTRY_CHECK) {
            return addRegistryCheckTrustFactor(acc, res)
        }

        const trustFactor = this.mapNonConfigurableTrustFactor(res)

        if (!trustFactor) return acc

        return {
            ...acc,
            nonConfigurableTrustFactors: [...acc.nonConfigurableTrustFactors, trustFactor],
        }
    }

    private compareTrustFactors(
        trustFactorA: ComparableTrustFactor,
        trustFactorB: ComparableTrustFactor
    ): number {
        const locale = this.localization.getLocale()

        const compareSource = trustFactorA.source.localeCompare(trustFactorB.source, locale)

        if (compareSource !== 0) return compareSource

        return trustFactorA.name.localeCompare(trustFactorB.name, locale)
    }

    private mapNonConfigurableTrustFactor(
        res: TrustFactorRes
    ): NonConfigurableTrustFactor | undefined {
        const trustFactorType = toNonConfigurableTrustFactorType[res.name]

        if (!trustFactorType) {
            console.warn(`We don't recognize the Trust Factor named "${res.name}"`)
            return
        }

        return {
            id: res.factor_id,
            type: trustFactorType,
            name: this.localization.getString(nameDictionary[trustFactorType]),
            description: this.localization.getString(descriptionDictionary[trustFactorType]),
            source:
                res.type === "integration"
                    ? res.source
                    : this.localization.getString("sonicWallCse"),
            applicablePlatform: res.applicable_platforms.reduce(reducePlatforms, noPlatforms),
            trustProfiles: res.factor_usage?.map(mapTrustProfile) ?? [],
        }
    }

    /**
     * We need to combine the different configurable Trust Factors to show them
     * as one in the UI.
     */
    private combineRemediationPerTrustFactor(
        trustFactorRes: TrustFactorRes[]
    ): TrustFactorRemediation[] {
        const emptyAcc: TrustFactorRemediationAcc = {
            nonConfigurableTrustFactors: [],
            fileCheckTrustFactor: {
                id: TrustFactorType.FILE_CHECK,
                type: TrustFactorType.FILE_CHECK,
                name: this.localization.getString("fileCheck"),
                source: this.localization.getString("sonicWallCse"),
                remediationPerPlatform: emptyRemediationPerPlatform,
                files: [],
            },
            plistFactor: {
                id: TrustFactorType.PLIST,
                type: TrustFactorType.PLIST,
                name: this.localization.getString("propertyListCheck"),
                source: this.localization.getString("sonicWallCse"),
                remediationPerPlatform: emptyRemediationPerPlatform,
                plist: [],
            },
            registryCheckFactor: {
                id: TrustFactorType.REGISTRY_CHECK,
                type: TrustFactorType.REGISTRY_CHECK,
                name: this.localization.getString("registryCheck"),
                source: this.localization.getString("sonicWallCse"),
                remediationPerPlatform: emptyRemediationPerPlatform,
                registryChecks: [],
            },
        }

        const {
            nonConfigurableTrustFactors,
            fileCheckTrustFactor,
            plistFactor,
            registryCheckFactor,
        } = trustFactorRes.reduce(this.reduceTrustFactorRemediationAcc.bind(this), emptyAcc)
        return [
            ...nonConfigurableTrustFactors,
            ...(fileCheckTrustFactor.files.length > 0 ? [fileCheckTrustFactor] : []),
            ...(plistFactor.plist.length > 0 ? [plistFactor] : []),
            ...(registryCheckFactor.registryChecks.length > 0 ? [registryCheckFactor] : []),
        ]
    }

    private reduceTrustFactorRemediationAcc(
        acc: TrustFactorRemediationAcc,
        trustFactorRes: TrustFactorRes
    ): TrustFactorRemediationAcc {
        if (!trustFactorRes.applicable_platforms.some(hasDesktopPlatform)) {
            return acc
        }

        if (trustFactorRes.source === TrustFactorType.PLIST) {
            return {
                ...acc,
                plistFactor: this.mapPlistRemediation(trustFactorRes, acc.plistFactor),
            }
        }

        if (trustFactorRes.source === TrustFactorType.FILE_CHECK) {
            return {
                ...acc,
                fileCheckTrustFactor: this.mapFileCheckRemediation(
                    trustFactorRes,
                    acc.fileCheckTrustFactor
                ),
            }
        }

        if (trustFactorRes.source === TrustFactorType.REGISTRY_CHECK) {
            return {
                ...acc,
                registryCheckFactor: this.mapRegistryCheckRemediation(
                    trustFactorRes,
                    acc.registryCheckFactor
                ),
            }
        }
        const trustFactorType = toNonConfigurableTrustFactorType[trustFactorRes.name]
        if (!trustFactorType) {
            console.warn(`We don't recognize the Trust Factor named "${trustFactorRes.name}"`)
            return acc
        }

        return {
            ...acc,
            nonConfigurableTrustFactors: [
                ...acc.nonConfigurableTrustFactors,
                this.mapNonConfigurableRemediation(trustFactorRes, trustFactorType),
            ],
        }
    }

    private mapNonConfigurableRemediation(
        trustFactorRes: TrustFactorRes,
        trustFactorType: NonConfigurableTrustFactorType
    ): NonConfigurableTrustFactorRemediation {
        const name = this.localization.getString(nameDictionary[trustFactorType])
        return {
            id: trustFactorRes.factor_id,
            type: trustFactorType,
            name:
                trustFactorRes.type === "integration" ? `${name} - ${trustFactorRes.source}` : name,
            source:
                trustFactorRes.type === "integration"
                    ? trustFactorRes.source
                    : this.localization.getString("sonicWallCse"),
            remediationPerPlatform: this.getRemediationPerPlatform(
                trustFactorRes,
                trustFactorType,
                emptyRemediationPerPlatform
            ),
        }
    }

    private mapPlistRemediation(
        trustFactorRes: TrustFactorRes,
        plistTrustFactor: PlistTrustFactorRemediation
    ): PlistTrustFactorRemediation {
        return {
            ...plistTrustFactor,
            remediationPerPlatform: this.getRemediationPerPlatform(
                trustFactorRes,
                TrustFactorType.PLIST,
                plistTrustFactor.remediationPerPlatform
            ),
            plist: [...plistTrustFactor.plist, { id: trustFactorRes.factor_id }],
        }
    }

    private mapFileCheckRemediation(
        trustFactorRes: TrustFactorRes,
        fileCheck: FileCheckTrustFactorRemediation
    ): FileCheckTrustFactorRemediation {
        return {
            ...fileCheck,
            remediationPerPlatform: this.getRemediationPerPlatform(
                trustFactorRes,
                TrustFactorType.FILE_CHECK,
                fileCheck.remediationPerPlatform
            ),
            files: [...fileCheck.files, { id: trustFactorRes.factor_id }],
        }
    }

    private mapRegistryCheckRemediation(
        trustFactorRes: TrustFactorRes,
        registryCheckTrustFactor: RegistryCheckTrustFactorRemediation
    ): RegistryCheckTrustFactorRemediation {
        return {
            ...registryCheckTrustFactor,
            remediationPerPlatform: this.getRemediationPerPlatform(
                trustFactorRes,
                TrustFactorType.REGISTRY_CHECK,
                registryCheckTrustFactor.remediationPerPlatform
            ),
            registryChecks: [
                ...registryCheckTrustFactor.registryChecks,
                { id: trustFactorRes.factor_id },
            ],
        }
    }

    private getRemediationPerPlatform(
        trustFactorRes: TrustFactorRes,
        trustFactorType: TrustFactorType,
        previousRemediations: RemediationPerPlatform
    ): RemediationPerPlatform {
        return trustFactorRes.applicable_platforms.reduce(
            (acc: RemediationPerPlatform, platformRes: PlatformRes): RemediationPerPlatform => {
                const platform = fromDesktopPlatformString[platformRes]

                if (!platform) return acc

                return {
                    ...acc,
                    [platform]:
                        previousRemediations[platform] ??
                        this.getRemediationMessage(
                            trustFactorRes,
                            platformRes,
                            trustFactorType,
                            platform
                        ),
                }
            },
            previousRemediations
        )
    }

    private getRemediationMessage(
        trustFactorRes: TrustFactorRes,
        platformRes: PlatformRes,
        trustFactorType: TrustFactorType,
        platform: DesktopPlatform
    ): string | null {
        const remediationRes = trustFactorRes.remediations.find((r) => r.platform === platformRes)

        if (remediationRes) return remediationRes.message

        const [remediationMessageKey, remediationLinkKey] =
            remediationDictionary[trustFactorType][platform]

        if (!remediationMessageKey) return null

        const link = remediationLinkKey ? this.linkService.getLink(remediationLinkKey) : ""

        return this.localization.getString(remediationMessageKey, link)
    }
}

enum TrustFactorHookKey {
    GET_TRUST_FACTORS = "trustFactorService.getTrustFactors",
}

export function useGetTrustFactors(
    options?: QueryOptions<TrustFactor[]>
): UseQueryResult<TrustFactor[]> {
    const trustFactorService = new TrustFactorService()

    return useQuery({
        ...options,
        queryKey: [TrustFactorHookKey.GET_TRUST_FACTORS],
        queryFn: () => trustFactorService.getTrustFactors(),
    })
}

export function useCreateFile(
    options?: QueryOptions<File>
): UseMutationResult<File, string, NewFile> {
    const trustFactorService = new TrustFactorService()

    const queryClient = useQueryClient()

    return useMutation({
        ...options,
        mutationFn: (fileCheck: NewFile): Promise<File> =>
            trustFactorService.createFileCheckTrustFactor(fileCheck),
        onSuccess: (newFile: File) => {
            queryClient.setQueryData(
                [TrustFactorHookKey.GET_TRUST_FACTORS],
                (trustFactors: TrustFactor[] | undefined): TrustFactor[] | undefined =>
                    trustFactors?.map((trustFactor: TrustFactor): TrustFactor => {
                        if (!isFileCheckTrustFactor(trustFactor)) return trustFactor
                        return { ...trustFactor, files: [...trustFactor.files, newFile] }
                    })
            )

            options?.onSuccess?.(newFile)
        },
    })
}

export function useUpdateFile(
    options?: QueryOptions<File>
): UseMutationResult<File, string, EditFile> {
    const trustFactorService = new TrustFactorService()

    const queryClient = useQueryClient()

    return useMutation({
        ...options,
        mutationFn: (fileCheck: EditFile): Promise<File> =>
            trustFactorService.updateFileCheckTrustFactor(fileCheck),
        onSuccess: (editedFile: File) => {
            queryClient.setQueryData(
                [TrustFactorHookKey.GET_TRUST_FACTORS],
                (trustFactors: TrustFactor[] | undefined): TrustFactor[] | undefined =>
                    trustFactors?.map((trustFactor: TrustFactor): TrustFactor => {
                        if (!isFileCheckTrustFactor(trustFactor)) return trustFactor
                        return {
                            ...trustFactor,
                            files: trustFactor.files.map(
                                (file: File): File =>
                                    file.id === editedFile.id ? editedFile : file
                            ),
                        }
                    })
            )

            options?.onSuccess?.(editedFile)
        },
    })
}

export function useDeleteFile(options?: QueryOptions): UseMutationResult<void, string, File> {
    const trustFactorService = new TrustFactorService()

    const queryClient = useQueryClient()

    return useMutation({
        ...options,
        mutationFn: (fileCheck: File): Promise<void> =>
            trustFactorService.deleteFileCheckTrustFactor(fileCheck),
        onSuccess: (_data: void, deletedFile: File) => {
            queryClient.setQueryData(
                [TrustFactorHookKey.GET_TRUST_FACTORS],
                (trustFactors: TrustFactor[] | undefined): TrustFactor[] | undefined =>
                    trustFactors?.map((trustFactor: TrustFactor): TrustFactor => {
                        if (!isFileCheckTrustFactor(trustFactor)) return trustFactor
                        return {
                            ...trustFactor,
                            files: trustFactor.files.filter(
                                (file: File) => file.id !== deletedFile.id
                            ),
                        }
                    })
            )

            options?.onSuccess?.()
        },
    })
}

export function useCreatePropertyList(
    options?: QueryOptions<Plist>
): UseMutationResult<Plist, string, NewPlist> {
    const trustFactorService = new TrustFactorService()

    const queryClient = useQueryClient()

    return useMutation({
        ...options,
        mutationFn: (plist: NewPlist): Promise<Plist> =>
            trustFactorService.createPlistTrustFactor(plist),
        onSuccess: (newPlist: Plist) => {
            queryClient.setQueryData(
                [TrustFactorHookKey.GET_TRUST_FACTORS],
                (trustFactors: TrustFactor[] | undefined): TrustFactor[] | undefined =>
                    trustFactors?.map((trustFactor: TrustFactor): TrustFactor => {
                        if (!isPlistTrustFactor(trustFactor)) return trustFactor
                        return { ...trustFactor, plists: [...trustFactor.plists, newPlist] }
                    })
            )

            options?.onSuccess?.(newPlist)
        },
    })
}

export function useUpdatePropertyList(
    options?: QueryOptions<Plist>
): UseMutationResult<Plist, unknown, EditPlist> {
    const trustFactorService = new TrustFactorService()

    const queryClient = useQueryClient()

    return useMutation({
        ...options,
        mutationFn: (plist: EditPlist): Promise<Plist> =>
            trustFactorService.updatePlistTrustFactor(plist),
        onSuccess: (editedPlist: Plist) => {
            queryClient.setQueryData(
                [TrustFactorHookKey.GET_TRUST_FACTORS],
                (trustFactors: TrustFactor[] | undefined): TrustFactor[] | undefined =>
                    trustFactors?.map((trustFactor: TrustFactor): TrustFactor => {
                        if (!isPlistTrustFactor(trustFactor)) return trustFactor
                        return {
                            ...trustFactor,
                            plists: trustFactor.plists.map((plist) =>
                                plist.id === editedPlist.id ? editedPlist : plist
                            ),
                        }
                    })
            )

            options?.onSuccess?.(editedPlist)
        },
    })
}

export function useDeletePropertyList(
    options?: QueryOptions
): UseMutationResult<void, unknown, Plist> {
    const trustFactorService = new TrustFactorService()

    const queryClient = useQueryClient()

    return useMutation({
        ...options,
        mutationFn: (plist: Plist): Promise<void> =>
            trustFactorService.deletePlistTrustFactor(plist),
        onSuccess: (_data: void, deletedPlist: Plist) => {
            queryClient.setQueryData(
                [TrustFactorHookKey.GET_TRUST_FACTORS],
                (trustFactors: TrustFactor[] | undefined): TrustFactor[] | undefined =>
                    trustFactors?.map((trustFactor: TrustFactor): TrustFactor => {
                        if (!isPlistTrustFactor(trustFactor)) return trustFactor
                        return {
                            ...trustFactor,
                            plists: trustFactor.plists.filter(
                                (plist) => plist.id !== deletedPlist.id
                            ),
                        }
                    })
            )

            options?.onSuccess?.()
        },
    })
}

export function useCreateRegistryKey(
    options?: QueryOptions<RegistryCheck>
): UseMutationResult<RegistryCheck, unknown, NewRegistryCheck> {
    const trustFactorService = new TrustFactorService()

    const queryClient = useQueryClient()

    return useMutation({
        ...options,
        mutationFn: (registryCheck: NewRegistryCheck): Promise<RegistryCheck> =>
            trustFactorService.createRegistryCheckTrustFactor(registryCheck),
        onSuccess: (newRegistryCheck: RegistryCheck) => {
            queryClient.setQueryData(
                [TrustFactorHookKey.GET_TRUST_FACTORS],
                (trustFactors: TrustFactor[] | undefined): TrustFactor[] | undefined =>
                    trustFactors?.map((trustFactor: TrustFactor): TrustFactor => {
                        if (!isRegistryCheckTrustFactor(trustFactor)) return trustFactor
                        return {
                            ...trustFactor,
                            registryChecks: [...trustFactor.registryChecks, newRegistryCheck],
                        }
                    })
            )

            options?.onSuccess?.(newRegistryCheck)
        },
    })
}

export function useUpdateRegistryKey(
    options?: QueryOptions<RegistryCheck>
): UseMutationResult<RegistryCheck, unknown, EditRegistryCheck> {
    const trustFactorService = new TrustFactorService()

    const queryClient = useQueryClient()

    return useMutation({
        ...options,
        mutationFn: (registryCheck: EditRegistryCheck): Promise<RegistryCheck> =>
            trustFactorService.updateRegistryCheckTrustFactor(registryCheck),
        onSuccess: (editedRegistryCheck: RegistryCheck) => {
            queryClient.setQueryData(
                [TrustFactorHookKey.GET_TRUST_FACTORS],
                (trustFactors: TrustFactor[] | undefined): TrustFactor[] | undefined =>
                    trustFactors?.map((trustFactor: TrustFactor): TrustFactor => {
                        if (!isRegistryCheckTrustFactor(trustFactor)) return trustFactor
                        return {
                            ...trustFactor,
                            registryChecks: trustFactor.registryChecks.map((registryCheck) =>
                                registryCheck.id === editedRegistryCheck.id
                                    ? editedRegistryCheck
                                    : registryCheck
                            ),
                        }
                    })
            )

            options?.onSuccess?.(editedRegistryCheck)
        },
    })
}

export function useDeleteRegistryKey(
    options?: QueryOptions
): UseMutationResult<void, unknown, RegistryCheck> {
    const trustFactorService = new TrustFactorService()

    const queryClient = useQueryClient()

    return useMutation({
        ...options,
        mutationFn: (registryCheck: RegistryCheck): Promise<void> =>
            trustFactorService.deleteRegistryCheckTrustFactor(registryCheck),
        onSuccess: (_data: void, deletedRegistryCheck: RegistryCheck) => {
            queryClient.setQueryData(
                [TrustFactorHookKey.GET_TRUST_FACTORS],
                (trustFactors: TrustFactor[] | undefined): TrustFactor[] | undefined =>
                    trustFactors?.map((trustFactor: TrustFactor): TrustFactor => {
                        if (!isRegistryCheckTrustFactor(trustFactor)) return trustFactor
                        return {
                            ...trustFactor,
                            registryChecks: trustFactor.registryChecks.filter(
                                (registryCheck) => registryCheck.id !== deletedRegistryCheck.id
                            ),
                        }
                    })
            )

            options?.onSuccess?.()
        },
    })
}

const getRemediationPerTrustFactorKey: QueryKey = [
    "trustFactorService.getRemediationPerTrustFactor",
]

export function useGetRemediationPerTrustFactor(
    options?: QueryOptions<TrustFactorRemediation[]>
): UseQueryResult<TrustFactorRemediation[]> {
    const trustFactorService = new TrustFactorService()

    return useQuery({
        ...options,
        queryKey: getRemediationPerTrustFactorKey,
        queryFn: () => trustFactorService.getRemediationPerTrustFactor(),
    })
}

export function useUpdateRemediationPerTrustFactor(
    options?: QueryOptions<TrustFactorRemediation[], string, TrustFactorRemediation[]>
): UseMutationResult<TrustFactorRemediation[], string, TrustFactorRemediation[]> {
    const trustFactorService = new TrustFactorService()

    const queryClient = useQueryClient()

    return useMutation({
        ...options,
        mutationFn: (
            remediationPerTrustFactor: TrustFactorRemediation[]
        ): Promise<TrustFactorRemediation[]> =>
            trustFactorService.updateRemediationPerTrustFactor(remediationPerTrustFactor),
        onSuccess: (remediationPerTrustFactor: TrustFactorRemediation[]) => {
            if (remediationPerTrustFactor.length > 0) {
                queryClient.setQueryData(
                    getRemediationPerTrustFactorKey,
                    (
                        oldRemediations: TrustFactorRemediation[] | undefined
                    ): TrustFactorRemediation[] | undefined =>
                        oldRemediations?.map(
                            (oldRemediation: TrustFactorRemediation): TrustFactorRemediation =>
                                remediationPerTrustFactor.find(
                                    ({ id }) => oldRemediation.id === id
                                ) ?? oldRemediation
                        )
                )
            }

            options?.onSuccess?.(remediationPerTrustFactor)
        },
    })
}

// START TYPES

export interface BaseTrustFactor {
    name: string
    description: string
    source: string
    applicablePlatform: Record<Platform, boolean>
    trustProfiles: TrustProfile[]
}

export type TrustFactor =
    | NonConfigurableTrustFactor
    | FileCheckTrustFactor
    | PlistTrustFactor
    | RegistryCheckTrustFactor

export interface TrustProfile {
    id: string
    name: string
}

// Definitions for the different Trust Factors type

/**
 * If you can configure these Trust Factors in a view outside of a Trust
 * Profile, add their type to this list
 */
type ConfigurableTrustFactorType =
    | TrustFactorType.FILE_CHECK
    | TrustFactorType.PLIST
    | TrustFactorType.REGISTRY_CHECK

type NonConfigurableTrustFactorType = Exclude<TrustFactorType, ConfigurableTrustFactorType>

export interface NonConfigurableTrustFactor extends BaseTrustFactor {
    id: string
    type: NonConfigurableTrustFactorType
}

// Remediation

interface BaseTrustFactorRemediation {
    name: string
    source: string
    remediationPerPlatform: RemediationPerPlatform
}

type RemediationPerPlatform = Record<DesktopPlatform, string | null>

export type TrustFactorRemediation =
    | NonConfigurableTrustFactorRemediation
    | FileCheckTrustFactorRemediation
    | PlistTrustFactorRemediation
    | RegistryCheckTrustFactorRemediation

interface NonConfigurableTrustFactorRemediation extends BaseTrustFactorRemediation {
    id: string
    type: NonConfigurableTrustFactorType
    name: string
    source: string
    remediationPerPlatform: RemediationPerPlatform
}

// Plist

export interface PlistTrustFactor extends BaseTrustFactor {
    type: TrustFactorType.PLIST
    plists: Plist[]
}

interface BasePlist {
    id: string
}

export interface Plist {
    id: string
    name: string
    configuration: PlistConfiguration
    trustProfiles: TrustProfile[]
}

export interface NewPlist {
    name: string
    configuration: PlistConfiguration
}

export interface EditPlist {
    id: string
    name: string
    configuration: PlistConfiguration
}

export interface PlistConfiguration {
    filePath: { prefix: string; path: string }
    key: string
    value: string
}

// File Check

export interface FileCheckTrustFactor extends BaseTrustFactor {
    type: TrustFactorType.FILE_CHECK
    files: File[]
}

interface BaseFile {
    id: string
}

export interface File {
    id: string
    name: string
    configurations: FileCheckConfigurations
    trustProfiles: TrustProfile[]
}

export interface NewFile {
    name: string
    configurations: FileCheckConfigurations
}

export interface EditFile {
    id: string
    name: string
    configurations: FileCheckConfigurations
}

type FileCheckConfigurations = Record<DesktopPlatform, FileCheckConfiguration | undefined>

export interface FileCheckConfiguration {
    filePath: { prefix: string; path: string }
    sha256Hash?: string
}

interface FileCheckTrustFactorRemediation extends BaseTrustFactorRemediation {
    id: string
    type: TrustFactorType.FILE_CHECK
    name: string
    source: string
    remediationPerPlatform: RemediationPerPlatform
    files: BaseFile[]
}

interface PlistTrustFactorRemediation extends BaseTrustFactorRemediation {
    id: string
    type: TrustFactorType.PLIST
    name: string
    source: string
    plist: BasePlist[]
}

// Registry Check

export interface RegistryCheckTrustFactor extends BaseTrustFactor {
    type: TrustFactorType.REGISTRY_CHECK
    registryChecks: RegistryCheck[]
}

interface BaseRegistryCheck {
    id: string
}

export interface RegistryCheck {
    id: string
    name: string
    configuration: RegistryCheckConfiguration
    trustProfiles: TrustProfile[]
}

export interface NewRegistryCheck {
    name: string
    configuration: RegistryCheckConfiguration
}

export interface EditRegistryCheck {
    id: string
    name: string
    configuration: RegistryCheckConfiguration
}

export interface RegistryCheckConfiguration {
    keyPath: { prefix: string; path: string }
    key: string
    value: string
}

interface RegistryCheckTrustFactorRemediation extends BaseTrustFactorRemediation {
    id: string
    type: TrustFactorType.REGISTRY_CHECK
    name: string
    source: string
    registryChecks: BaseRegistryCheck[]
}

// Helper Types

interface ComparableTrustFactor {
    name: string
    source: string
}

interface TrustFactorAcc {
    nonConfigurableTrustFactors: NonConfigurableTrustFactor[]
    fileCheckTrustFactor: FileCheckTrustFactor
    fileCheckTrustProfileMap: Map<string, TrustProfile>
    plistTrustFactor: PlistTrustFactor
    plistTrustProfileMap: Map<string, TrustProfile>
    registryCheckTrustFactor: RegistryCheckTrustFactor
    registryCheckTrustProfileMap: Map<string, TrustProfile>
}

interface TrustFactorRemediationAcc {
    nonConfigurableTrustFactors: NonConfigurableTrustFactorRemediation[]
    fileCheckTrustFactor: FileCheckTrustFactorRemediation
    plistFactor: PlistTrustFactorRemediation
    registryCheckFactor: RegistryCheckTrustFactorRemediation
}

// END TYPES

const toNonConfigurableTrustFactorType: Record<string, NonConfigurableTrustFactorType> = {
    AutoUpdateEnabled: TrustFactorType.AUTO_UPDATE,
    BanyanAppVersion: TrustFactorType.BANYAN_APP_VERSION,
    DiskEncryptionEnabled: TrustFactorType.DISK_ENCRYPTION,
    FirewallEnabled: TrustFactorType.FIREWALL,
    NotJailbroken: TrustFactorType.NOT_JAILBROKEN,
    OrgPreferredAppsRunning: TrustFactorType.APPLICATION_CHECK,
    "s1.activeThreats": TrustFactorType.NOT_ACTIVE_THREAT,
    "s1.registeredWith": TrustFactorType.REGISTERED_WITH,
    ScreenLockEnabled: TrustFactorType.SCREEN_LOCK,
    UpToDateOS: TrustFactorType.OPERATING_SYSTEM_VERSION,
    "zta.score": TrustFactorType.ZTA_SCORE,
    "cs.registeredWith": TrustFactorType.CS_REGISTERED_WITH,
    ChromeBrowserVersion: TrustFactorType.CHROME_BROWSER_VERSION,
    "ws1.registeredWith": TrustFactorType.WS1_REGISTERED_WITH,
    "ws1.isCompliant": TrustFactorType.WS1_IS_COMPLIANT,
    "Device Geolocation": TrustFactorType.DEVICE_GEOLOCATION,
}

const fromDesktopPlatformString: Record<PlatformRes, DesktopPlatform | null> = {
    macos: Platform.MACOS,
    windows: Platform.WINDOWS,
    linux: Platform.LINUX,
    ios: null,
    android: null,
    chrome: Platform.CHROME,
}

const fromPlatformString: Record<PlatformRes, Platform> = {
    macos: Platform.MACOS,
    windows: Platform.WINDOWS,
    linux: Platform.LINUX,
    ios: Platform.IOS,
    android: Platform.ANDROID,
    chrome: Platform.CHROME,
}

const toPlatformString: Record<Platform, PlatformRes> = {
    [Platform.MACOS]: "macos",
    [Platform.WINDOWS]: "windows",
    [Platform.LINUX]: "linux",
    [Platform.IOS]: "ios",
    [Platform.ANDROID]: "android",
    [Platform.CHROME]: "chrome",
}

// Trust Factor Helpers

function reducePlatforms(
    acc: Record<Platform, boolean>,
    platform: PlatformRes
): Record<Platform, boolean> {
    return { ...acc, [fromPlatformString[platform]]: true }
}

const desktopPlatformsRes: PlatformRes[] = ["macos", "windows", "linux", "chrome"]
function hasDesktopPlatform(res: PlatformRes): boolean {
    return desktopPlatformsRes.includes(res)
}

function mapTrustProfile(res: TrustProfileRes): TrustProfile {
    return { id: res.profile_id, name: res.profile_name }
}

// Remediation Helpers

const emptyRemediationPerPlatform: RemediationPerPlatform = {
    [Platform.MACOS]: null,
    [Platform.WINDOWS]: null,
    [Platform.LINUX]: null,
    [Platform.CHROME]: null,
}

export const remediationDictionary: Record<
    TrustFactorType,
    Record<DesktopPlatform, [LanguageKey | null, LinkKey | null]>
> = {
    [TrustFactorType.APPLICATION_CHECK]: {
        [Platform.MACOS]: ["applicationCheckRemediationText", "applicationCheckSupportUrl"],
        [Platform.WINDOWS]: ["applicationCheckRemediationText", "applicationCheckSupportUrl"],
        [Platform.LINUX]: ["applicationCheckRemediationText", "applicationCheckSupportUrl"],
        [Platform.CHROME]: [null, null],
    },
    [TrustFactorType.AUTO_UPDATE]: {
        [Platform.MACOS]: ["autoUpdateMacOsRemediationText", "autoUpdateMacOsSupportUrl"],
        [Platform.WINDOWS]: ["autoUpdateWindowsRemediationText", "autoUpdateWindowsSupportUrl"],
        [Platform.LINUX]: ["autoUpdateLinuxRemediationText", "autoUpdateLinuxSupportUrl"],
        [Platform.CHROME]: [null, null],
    },
    [TrustFactorType.BANYAN_APP_VERSION]: {
        [Platform.MACOS]: ["cseAppVersionRemediationText", null],
        [Platform.WINDOWS]: ["cseAppVersionRemediationText", null],
        [Platform.LINUX]: ["cseAppVersionRemediationText", null],
        [Platform.CHROME]: [null, null],
    },
    [TrustFactorType.DISK_ENCRYPTION]: {
        [Platform.MACOS]: ["diskEncryptionMacOsRemediationText", "diskEncryptionMacOsSupportUrl"],
        [Platform.WINDOWS]: [
            "diskEncryptionWindowsRemediationText",
            "diskEncryptionWindowsSupportUrl",
        ],
        [Platform.LINUX]: ["diskEncryptionLinuxRemediationText", "diskEncryptionLinuxSupportUrl"],
        [Platform.CHROME]: [null, null],
    },
    [TrustFactorType.FILE_CHECK]: {
        [Platform.MACOS]: ["fileCheckRemediationText", null],
        [Platform.WINDOWS]: ["fileCheckRemediationText", null],
        [Platform.LINUX]: ["fileCheckRemediationText", null],
        [Platform.CHROME]: [null, null],
    },
    [TrustFactorType.FIREWALL]: {
        [Platform.MACOS]: ["firewallMacOsRemediationText", "firewallMacOsSupportUrl"],
        [Platform.WINDOWS]: ["firewallWindowsRemediationText", "firewallWindowsSupportUrl"],
        [Platform.LINUX]: [null, null],
        [Platform.CHROME]: [null, null],
    },
    [TrustFactorType.NOT_ACTIVE_THREAT]: {
        [Platform.MACOS]: ["activeThreatRemediationText", null],
        [Platform.WINDOWS]: ["activeThreatRemediationText", null],
        [Platform.LINUX]: ["activeThreatRemediationText", null],
        [Platform.CHROME]: [null, null],
    },
    [TrustFactorType.NOT_JAILBROKEN]: {
        [Platform.MACOS]: [null, null],
        [Platform.WINDOWS]: [null, null],
        [Platform.LINUX]: [null, null],
        [Platform.CHROME]: [null, null],
    },
    [TrustFactorType.OPERATING_SYSTEM_VERSION]: {
        [Platform.MACOS]: [
            "operatingSystemVersionMacOsRemediationText",
            "operatingSystemVersionMacOsSupportUrl",
        ],
        [Platform.WINDOWS]: [
            "operatingSystemVersionWindowsRemediationText",
            "operatingSystemVersionWindowsSupportUrl",
        ],
        [Platform.LINUX]: [
            "operatingSystemVersionLinuxRemediationText",
            "operatingSystemVersionLinuxSupportUrl",
        ],
        [Platform.CHROME]: [null, null],
    },
    [TrustFactorType.REGISTERED_WITH]: {
        [Platform.MACOS]: ["registeredWithRemediationText", null],
        [Platform.WINDOWS]: ["registeredWithRemediationText", null],
        [Platform.LINUX]: ["registeredWithRemediationText", null],
        [Platform.CHROME]: [null, null],
    },
    [TrustFactorType.CS_REGISTERED_WITH]: {
        [Platform.MACOS]: ["registeredWithRemediationText", null],
        [Platform.WINDOWS]: ["registeredWithRemediationText", null],
        [Platform.LINUX]: ["registeredWithRemediationText", null],
        [Platform.CHROME]: [null, null],
    },
    [TrustFactorType.REGISTRY_CHECK]: {
        [Platform.MACOS]: [null, null],
        [Platform.WINDOWS]: ["registryCheckRemediationText", null],
        [Platform.LINUX]: [null, null],
        [Platform.CHROME]: [null, null],
    },
    [TrustFactorType.SCREEN_LOCK]: {
        [Platform.MACOS]: [null, null],
        [Platform.WINDOWS]: [null, null],
        [Platform.LINUX]: [null, null],
        [Platform.CHROME]: [null, null],
    },
    [TrustFactorType.ZTA_SCORE]: {
        [Platform.MACOS]: ["ztaScoreRemediationText", null],
        [Platform.WINDOWS]: ["ztaScoreRemediationText", null],
        [Platform.LINUX]: ["ztaScoreRemediationText", null],
        [Platform.CHROME]: [null, null],
    },
    [TrustFactorType.PLIST]: {
        [Platform.MACOS]: [
            "thisDeviceDoesNotHaveTheCorrectValuesForThePlistsListedAbovePleaseReachOutToSupport",
            null,
        ],
        [Platform.WINDOWS]: [null, null],
        [Platform.LINUX]: [null, null],
        [Platform.CHROME]: [null, null],
    },
    [TrustFactorType.CHROME_BROWSER_VERSION]: {
        [Platform.MACOS]: [null, null],
        [Platform.WINDOWS]: [null, null],
        [Platform.LINUX]: [null, null],
        [Platform.CHROME]: ["chromeBrowserVersionRemediationText", "chromeBrowserVersionUpdateUrl"],
    },
    [TrustFactorType.WS1_IS_COMPLIANT]: {
        [Platform.MACOS]: ["isCompliantRemediationText", null],
        [Platform.WINDOWS]: ["isCompliantRemediationText", null],
        [Platform.LINUX]: ["isCompliantRemediationText", null],
        [Platform.CHROME]: [null, null],
    },
    [TrustFactorType.WS1_REGISTERED_WITH]: {
        [Platform.MACOS]: ["registeredWithRemediationText", null],
        [Platform.WINDOWS]: ["registeredWithRemediationText", null],
        [Platform.LINUX]: ["registeredWithRemediationText", null],
        [Platform.CHROME]: [null, null],
    },
    [TrustFactorType.DEVICE_GEOLOCATION]: {
        [Platform.MACOS]: ["deviceGeoLocationRemediationText", null], //update remediation text
        [Platform.WINDOWS]: ["deviceGeoLocationRemediationText", null],
        [Platform.LINUX]: ["deviceGeoLocationRemediationText", null],
        [Platform.CHROME]: [null, null],
    },
}

function reduceUpdateTrustFactorsRemediationReq(
    acc: UpdateTrustFactorRemediationReq[],
    remediation: TrustFactorRemediation
): UpdateTrustFactorRemediationReq[] {
    switch (remediation.type) {
        case TrustFactorType.FILE_CHECK:
            return [...acc, ...getFileCheckRemediationReq(remediation)]

        case TrustFactorType.PLIST:
            return [...acc, ...getPlistRemediationReq(remediation)]

        case TrustFactorType.REGISTRY_CHECK:
            return [...acc, ...getRegistryCheckRemediationReq(remediation)]

        default:
            return [...acc, getNonConfigurableRemediationReq(remediation)]
    }
}

function getPlistRemediationReq(
    remediation: PlistTrustFactorRemediation
): UpdateTrustFactorRemediationReq[] {
    return remediation.plist.map(({ id }) => ({
        factor_id: id,
        remediations: getRemediationsReq(remediation.remediationPerPlatform),
    }))
}

function getFileCheckRemediationReq(
    remediation: FileCheckTrustFactorRemediation
): UpdateTrustFactorRemediationReq[] {
    return remediation.files.map(({ id }) => ({
        factor_id: id,
        remediations: getRemediationsReq(remediation.remediationPerPlatform),
    }))
}

function getRegistryCheckRemediationReq(
    remediation: RegistryCheckTrustFactorRemediation
): UpdateTrustFactorRemediationReq[] {
    return remediation.registryChecks.map(({ id }) => ({
        factor_id: id,
        remediations: getRemediationsReq(remediation.remediationPerPlatform),
    }))
}

function getNonConfigurableRemediationReq(
    remediation: NonConfigurableTrustFactorRemediation
): UpdateTrustFactorRemediationReq {
    return {
        factor_id: remediation.id,
        remediations: getRemediationsReq(remediation.remediationPerPlatform),
    }
}

function getRemediationsReq(remediationPerPlatform: RemediationPerPlatform): RemediationRes[] {
    return CollectionUtil.entries(remediationPerPlatform).reduce(reduceRemediationReq, [])
}

function reduceRemediationReq(
    acc: RemediationRes[],
    [platform, message]: [DesktopPlatform, string | null]
): RemediationRes[] {
    if (!message) return acc

    const remediationReq: RemediationRes = {
        platform: toPlatformString[platform],
        message,
    }

    return [...acc, remediationReq]
}

// File Check Helpers

export function isFileCheckTrustFactor(
    trustFactor: TrustFactor
): trustFactor is FileCheckTrustFactor {
    return trustFactor.type === TrustFactorType.FILE_CHECK
}

export function isPlistTrustFactor(trustFactor: TrustFactor): trustFactor is PlistTrustFactor {
    return trustFactor.type === TrustFactorType.PLIST
}

export function isRegistryCheckTrustFactor(
    trustFactor: TrustFactor
): trustFactor is RegistryCheckTrustFactor {
    return trustFactor.type === TrustFactorType.REGISTRY_CHECK
}

export function isMissingFilePath(fileConfiguration: FileCheckConfiguration | undefined): boolean {
    return !fileConfiguration || !fileConfiguration.filePath.path
}

function addPlistTrustFactor(acc: TrustFactorAcc, res: TrustFactorRes): TrustFactorAcc {
    const plist = mapPlist(res)

    plist.trustProfiles.forEach((trustProfile) => {
        acc.plistTrustProfileMap.set(trustProfile.id, trustProfile)
    })

    return {
        ...acc,
        plistTrustFactor: {
            ...acc.plistTrustFactor,
            plists: [...acc.plistTrustFactor.plists, plist],
        },
    }
}

function addFileCheckTrustFactor(acc: TrustFactorAcc, res: TrustFactorRes): TrustFactorAcc {
    const fileCheck = mapFileCheck(res)

    fileCheck.trustProfiles.forEach((trustProfile) => {
        acc.fileCheckTrustProfileMap.set(trustProfile.id, trustProfile)
    })

    return {
        ...acc,
        fileCheckTrustFactor: {
            ...acc.fileCheckTrustFactor,
            files: [...acc.fileCheckTrustFactor.files, fileCheck],
        },
    }
}

function addRegistryCheckTrustFactor(acc: TrustFactorAcc, res: TrustFactorRes): TrustFactorAcc {
    const registryCheck = mapRegistryCheck(res)

    registryCheck.trustProfiles.forEach((trustProfile) => {
        acc.registryCheckTrustProfileMap.set(trustProfile.id, trustProfile)
    })

    return {
        ...acc,
        registryCheckTrustFactor: {
            ...acc.registryCheckTrustFactor,
            registryChecks: [...acc.registryCheckTrustFactor.registryChecks, registryCheck],
        },
    }
}

function mapFileCheck(res: TrustFactorRes): File {
    return {
        id: res.factor_id,
        name: res.display_name,
        configurations:
            res.config?.reduce(reduceFileCheckConfigurations, noFileCheckConfigurations) ??
            noFileCheckConfigurations,
        trustProfiles: res.factor_usage?.map(mapTrustProfile) ?? [],
    }
}

function mapPlist(res: TrustFactorRes): Plist {
    return {
        id: res.factor_id,
        name: res.display_name,
        configuration:
            res.config?.reduce(reducePlistConfiguration, noPlistConfiguration) ??
            noPlistConfiguration,
        trustProfiles: res.factor_usage?.map(mapTrustProfile) ?? [],
    }
}

function mapRegistryCheck(res: TrustFactorRes): RegistryCheck {
    return {
        id: res.factor_id,
        name: res.display_name,
        configuration:
            res.config?.reduce(reduceRegistryCheckConfiguration, noRegistryCheckConfiguration) ??
            noRegistryCheckConfiguration,
        trustProfiles: res.factor_usage?.map(mapTrustProfile) ?? [],
    }
}

const noPlistConfiguration: PlistConfiguration = {
    filePath: { prefix: "", path: "" },
    key: "",
    value: "",
}

const noRegistryCheckConfiguration: RegistryCheckConfiguration = {
    keyPath: { prefix: "", path: "" },
    key: "",
    value: "",
}

function reducePlistConfiguration(
    configuration: PlistConfiguration,
    res: ConfigRes
): PlistConfiguration {
    const configKeyParts = res.key.split(".")
    const configurationType = configKeyParts.pop()

    if (
        !configurationType ||
        !(
            configurationType === "path" ||
            configurationType === "prefix" ||
            configurationType === "key" ||
            configurationType === "value"
        )
    ) {
        console.warn(`Config has a key labeled "${res.key}" that we don't recognize`, res)
        return configuration
    }

    return {
        filePath: {
            prefix: configurationType === "prefix" ? res.value : configuration.filePath.prefix,
            path: configurationType === "path" ? res.value : configuration.filePath.path,
        },
        key: configurationType === "key" ? res.value : configuration.key,
        value: configurationType === "value" ? res.value : configuration.value,
    }
}

function reduceRegistryCheckConfiguration(
    configuration: RegistryCheckConfiguration,
    res: ConfigRes
): RegistryCheckConfiguration {
    const configKeyParts = res.key.split(".")
    const configurationType = configKeyParts.pop()

    if (
        !configurationType ||
        !(
            configurationType === "path" ||
            configurationType === "prefix" ||
            configurationType === "key" ||
            configurationType === "value"
        )
    ) {
        console.warn(`Config has a key labeled "${res.key}" that we don't recognize`, res)
        return configuration
    }

    return {
        keyPath: {
            prefix: configurationType === "prefix" ? res.value : configuration.keyPath.prefix,
            path: configurationType === "path" ? res.value : configuration.keyPath.path,
        },
        key: configurationType === "key" ? res.value : configuration.key,
        value: configurationType === "value" ? res.value : configuration.value,
    }
}

const noFileCheckConfigurations: FileCheckConfigurations = {
    [Platform.MACOS]: undefined,
    [Platform.WINDOWS]: undefined,
    [Platform.LINUX]: undefined,
    [Platform.CHROME]: undefined,
}

function reduceFileCheckConfigurations(
    configurations: FileCheckConfigurations,
    res: ConfigRes
): FileCheckConfigurations {
    // Here's an example of a key we should expect: file.path.windows
    // Three terms separated by periods
    // The first term is always file
    // The second term is either `path` or `sha256`
    // The third term is either `macos`, `windows`, or `linux`
    const configKeyParts = res.key.split(".")
    const platformString = configKeyParts.pop()

    if (!platformString) {
        console.warn("Config has no key", res)
        return configurations
    }

    const platform = fromPlatformString[platformString as PlatformRes]

    if (!platform) {
        console.warn(
            `Config has a platform labeled "${platformString}" that we don't recognize`,
            res
        )
        return configurations
    }

    if (platform === Platform.ANDROID || platform === Platform.IOS) {
        console.warn(
            `File Check can only accept Config for Desktop Platforms, not "${platformString}"`,
            res
        )
        return configurations
    }

    const configurationType = configKeyParts.pop()

    if (
        !configurationType ||
        !(
            configurationType === "path" ||
            configurationType === "prefix" ||
            configurationType === "sha256"
        )
    ) {
        console.warn(`Config has a key labeled "${res.key}" that we don't recognize`, res)
        return configurations
    }

    const existingConfiguration = configurations[platform]

    const configuration: FileCheckConfiguration = {
        filePath: {
            prefix:
                configurationType === "prefix"
                    ? res.value
                    : existingConfiguration?.filePath.prefix ?? "",
            path:
                configurationType === "path"
                    ? res.value
                    : existingConfiguration?.filePath.path ?? "",
        },
        sha256Hash: configurationType === "sha256" ? res.value : existingConfiguration?.sha256Hash,
    }

    return { ...configurations, [platform]: configuration }
}

function getFileCheckConfigRes(configs: FileCheckConfigurations): ConfigRes[] {
    const empty: ConfigRes[] = []
    return CollectionUtil.entries(configs).reduce(reduceFileCheckConfigRes, empty)
}

function getPlistConfigRes(config: PlistConfiguration): ConfigRes[] {
    return [
        { key: "plist.prefix", value: config.filePath.prefix },
        { key: "plist.path", value: config.filePath.path },
        { key: "plist.key", value: config.key },
        { key: "plist.value", value: config.value },
    ]
}

function getRegistryCheckConfigRes(config: RegistryCheckConfiguration): ConfigRes[] {
    return [
        { key: "registry.prefix", value: config.keyPath.prefix },
        { key: "registry.path", value: config.keyPath.path },
        { key: "registry.key", value: config.key },
        { key: "registry.value", value: config.value },
    ]
}

function reduceFileCheckConfigRes(
    acc: ConfigRes[],
    [platform, config]: [DesktopPlatform, FileCheckConfiguration | undefined]
): ConfigRes[] {
    if (!config) return acc

    const platformString = toPlatformString[platform]

    const prefixConfig: ConfigRes = {
        key: `file.prefix.${platformString}`,
        value: config.filePath.prefix,
    }

    const pathConfig: ConfigRes = {
        key: `file.path.${platformString}`,
        value: config.filePath.path,
    }

    return [
        ...acc,
        prefixConfig,
        pathConfig,
        ...getSha256HashConfig(platformString, config.sha256Hash),
    ]
}

function getSha256HashConfig(platformString: string, sha256Hash?: string): ConfigRes[] {
    if (!sha256Hash) return []

    const sha256HashConfig: ConfigRes = {
        key: `file.sha256.${platformString}`,
        value: sha256Hash,
    }

    return [sha256HashConfig]
}
