import { ColDef, GridApi, GridReadyEvent } from "ag-grid-community"
import * as React from "react"
import styles from "./PublicResourceOverview.module.scss"
import { useGetUserDeviceInfoById } from "../../../../services/PublicResource.service"
import { useServiceLocalization } from "../../../../../pre-v3/services/localization/Localization.service"
import AgGridUtil from "../../../../../pre-v3/utils/AgGrid.util"
import { ErrorBanner } from "../../../../components/banner/Banner.component"
import { SearchInput } from "../../../../components/search-input/SearchInput.component"
import { Grid } from "../../../../../pre-v3/components/grid/Grid.component"

interface Props {
    id: string
}

export function PublicResourceAccessLog(props: Props) {
    const ls = useServiceLocalization()
    const gridApi = React.useRef<GridApi>()
    const [search, setSearch] = React.useState<string>("")

    const {
        data: usersDevicesInfo,
        isLoading: isDataLoading,
        error: infoError,
    } = useGetUserDeviceInfoById(props.id)

    function onGridReady(event: GridReadyEvent): void {
        gridApi.current = event.api
        if (gridApi.current && usersDevicesInfo) {
            gridApi.current.setRowData(usersDevicesInfo.data)
        }
        event.api.setQuickFilter(search)
    }

    function onSearchChange(updatedSearch: string) {
        gridApi.current?.setQuickFilter(updatedSearch)
        setSearch(updatedSearch)
    }

    const activityColumns: ColDef[] = [
        {
            headerName: ls.getString("lastAccessed"),
            field: "timestamp",
        },
        {
            headerName: ls.getString("device"),
            field: "deviceName",
            tooltipValueGetter: AgGridUtil.linkTooltipValueGetter,
            comparator: AgGridUtil.alphaBetComparator,
        },
        {
            headerName: ls.getString("user"),
            field: "userName",
            tooltipValueGetter: AgGridUtil.linkTooltipValueGetter,
            comparator: AgGridUtil.alphaBetComparator,
        },
    ]

    React.useEffect(() => {
        gridApi.current?.setQuickFilter?.(search)
    }, [search])

    return (
        <>
            {typeof infoError === "string" && <ErrorBanner>{String(infoError)}</ErrorBanner>}
            {usersDevicesInfo && !isDataLoading && (
                <>
                    <SearchInput
                        className={styles.search}
                        value={search}
                        onChangeValue={onSearchChange}
                        placeholder={ls.getString("search")}
                    />
                    <Grid
                        columnDefs={activityColumns}
                        onGridReady={onGridReady}
                        pagination
                        autoHeight
                    ></Grid>
                </>
            )}
        </>
    )
}
