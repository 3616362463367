import React, { useMemo, useState } from "react"
import {
    matchPath,
    Redirect,
    Route,
    Switch,
    useHistory,
    useLocation,
    useParams,
} from "react-router-dom"

import {
    Button,
    ButtonElement,
    ButtonType,
    IconType,
} from "../../../../components/button/Button.component"
import { PageHeading } from "../../../../components/page-heading/PageHeading.component"
import { ErrorBanner, MessageContent } from "../../../../pre-v3/components"
import { DeleteCancelActions } from "../../../../pre-v3/components/modal/delete-cancel/DeleteCancelActions"
import { LanguageKey } from "../../../../pre-v3/services/localization/languages/en-US.language"
import { LocalizationService } from "../../../../pre-v3/services/localization/Localization.service"
import { useServiceModal } from "../../../../pre-v3/services/Modal.service"
import { DateUtil } from "../../../../pre-v3/utils/Date.util"
import { decodeID } from "../../../../pre-v3/utils/Url.util"
import { formatRoutePath, ROUTE } from "../../../../routes"
import { Container } from "../../../components/container/Container.component"
import { Loader } from "../../../components/loader/Loader.component"
import { StatusType } from "../../../components/status/Status.component"
import {
    Connector,
    ConnectorStatus,
    canConnectorBeDeleted,
    useDeleteConnector,
    useGetConnectorById,
    useUpdateConnector,
} from "../../../services/Connector.service"
import { ConnectorConfigurationForm } from "../form/configuration/ConnectorConfigurationForm.component"
import { ConnectorInstallForm } from "../form/install/ConnectorInstallForm.component"
import { ConnectorServices } from "../form/services/ConnectorServices.component"
import styles from "./ConnectorOverview.module.scss"
import { Tooltip } from "../../../components/tooltip/Tooltip.component"
import {
    NavigationTabBar,
    TabProps,
} from "../../../../components/navigation-tab-bar/NavigationTabBar.component"
import { ConnectorTopContainer } from "./ConncetorTopContainer.component"
import { formatToLocalDateTimeStr } from "../../../../utils/Date.utils"
import { useFeatureFlags } from "../../../../hooks/useFeatureFlags.hook"

interface Props {
    canEditConnector: boolean
    canDeleteConnector: boolean
    canAccessConnectorInstallStep?: boolean
    canCreateConnector?: boolean
}

export function ConnectorOverview(props: Props) {
    const history = useHistory()
    const modalService = useServiceModal()

    const localization = new LocalizationService()

    const { id } = useParams<{ id: string }>()
    const [isFormDisabled, setIsFormDisabled] = useState<boolean>(true)

    const {
        error: fetchError = "",
        isLoading,
        data: connector,
        refetch: refetchConnector,
    } = useGetConnectorById(decodeID(id))

    const {
        error: saveError = "",
        isLoading: submitting,
        mutate: onSave,
    } = useUpdateConnector({
        onSuccess: () => setIsFormDisabled(true),
    })

    const { data: featureFlags, isFetching: isFeatureFlagsLoading } = useFeatureFlags()

    const {
        mutateAsync: deleteConnector,
        isLoading: isConnectorDeleting,
        error: deleteError = "",
    } = useDeleteConnector()

    function onEdit(): void {
        setIsFormDisabled(false)
    }

    function onCancel(): void {
        setTimeout(() => {
            setIsFormDisabled(true)
        })
    }

    const onDelete = () => {
        if (connector) {
            modalService
                .open(
                    localization.getString("deleteConnector"),
                    {
                        component: MessageContent,
                        props: {
                            text: localization.getString("deleteConnectorDescription"),
                        },
                    },
                    {
                        component: DeleteCancelActions,
                        props: { okString: localization.getString("delete") },
                    }
                )
                .onClose(async () => {
                    await deleteConnector(connector)
                    history.push(ROUTE.CONNECTORS)
                })
        }
    }

    const canDeleteConnector = canUserDeleteConnector(connector)

    const error = fetchError || deleteError
    const getTabProps = useGetTabProps(id, connector)

    const tabs = useMemo(() => {
        return allTabs.filter((tab) => {
            switch (tab) {
                case TabKey.SERVICES:
                    return featureFlags?.adminConsole.doShowServicesInConnector
                case TabKey.INSTALL:
                    return canInstallConnector(connector)
                default:
                    return true
            }
        })
    }, [featureFlags?.adminConsole.doShowServicesInConnector, connector])

    return (
        <Loader
            isLoading={isLoading || isFeatureFlagsLoading || isConnectorDeleting}
            title={localization.getString("loadingAPIKeysToAddConnectors")}
            medium
        >
            <Container as="section" aria-labelledby={Id.HEADING} className={styles.container}>
                <header className={styles.header}>
                    <PageHeading id={Id.HEADING}>{connector?.displayName}</PageHeading>
                    <div className={styles.actionButtons}>
                        {props.canEditConnector && canEditConnector(connector) && (
                            <Tooltip title={localization.getString("edit")}>
                                <Button
                                    icon={IconType.PEN}
                                    onClick={onEdit}
                                    asElement={ButtonElement.BUTTON}
                                    buttonType={ButtonType.SECONDARY}
                                    aria-label={localization.getString("edit")}
                                    disabled={!isFormDisabled}
                                />
                            </Tooltip>
                        )}
                        {props.canDeleteConnector && (
                            <Tooltip
                                title={localization.getString(
                                    canDeleteConnector ? "delete" : "cannotDeleteConnectorMessage"
                                )}
                            >
                                <span>
                                    <Button
                                        icon={IconType.TRASH}
                                        onClick={onDelete}
                                        asElement={ButtonElement.BUTTON}
                                        buttonType={ButtonType.SECONDARY}
                                        aria-label={localization.getString("delete")}
                                        disabled={!isFormDisabled || !canDeleteConnector}
                                    />
                                </span>
                            </Tooltip>
                        )}
                        <Tooltip title={localization.getString("refresh")}>
                            <Button
                                icon={IconType.REDO}
                                onClick={() => refetchConnector()}
                                asElement={ButtonElement.BUTTON}
                                buttonType={ButtonType.SECONDARY}
                                aria-label={localization.getString("refresh")}
                            />
                        </Tooltip>
                    </div>
                </header>
                {error && <ErrorBanner>{error}</ErrorBanner>}
                {connector && (
                    <>
                        <ConnectorTopContainer
                            statusType={statusMap[connector.status as ConnectorStatus]}
                            statusLabel={localization.getString(labelMap[connector.status!])}
                            statusItems={[
                                ...(connector.hostName
                                    ? [
                                          {
                                              label: localization.getString("hostname"),
                                              value: connector.hostName,
                                          },
                                      ]
                                    : []),
                                ...(connector.connectorVersion
                                    ? [
                                          {
                                              label: localization.getString("connectorVersion"),
                                              value: connector.connectorVersion,
                                          },
                                      ]
                                    : []),
                                ...(connector.lastConnectTime
                                    ? [
                                          {
                                              label: localization.getString("lastConnectTime"),
                                              value: formatToLocalDateTimeStr(
                                                  connector.lastConnectTime,
                                                  localization.getLocale()
                                              ),
                                          },
                                      ]
                                    : []),
                            ]}
                            listItems={[
                                {
                                    label: localization.getString("lastUpdated"),
                                    value: DateUtil.format(connector.updatedAt),
                                },
                                {
                                    label: localization.getString("lastUpdatedBy"),
                                    value: connector.updatedBy,
                                },
                                {
                                    label: localization.getString("createdAt"),
                                    value: DateUtil.format(connector.createdAt),
                                },
                                {
                                    label: localization.getString("createdBy"),
                                    value: connector.createdBy,
                                },
                            ]}
                        />
                        <NavigationTabBar tabs={tabs} getTabProps={getTabProps} />
                        <Switch>
                            <Route path={ROUTE.CONNECTORS_DETAILS_CONFIGURE}>
                                <ConnectorConfigurationForm
                                    initialValue={connector}
                                    disabled={isFormDisabled}
                                    isEdit
                                    error={saveError || ""}
                                    onCancel={onCancel}
                                    onSubmit={onSave}
                                    submitting={submitting}
                                    submitLabel={localization.getString("save")}
                                    canCreateConnector={props.canCreateConnector}
                                />
                            </Route>
                            <Route path={ROUTE.CONNECTORS_DETAILS_SERVICES}>
                                <ConnectorServices services={connector.services} />
                            </Route>
                            <Route path={ROUTE.CONNECTORS_DETAILS_INSTALL}>
                                <ConnectorInstallForm
                                    connector={connector!}
                                    canAccessConnectorInstallStep={
                                        props.canAccessConnectorInstallStep
                                    }
                                />
                            </Route>
                            <Redirect
                                to={formatRoutePath(ROUTE.CONNECTORS_DETAILS_CONFIGURE, { id })}
                            />
                        </Switch>
                    </>
                )}
            </Container>
        </Loader>
    )
}

enum Id {
    HEADING = "heading",
}

const statusMap: Record<ConnectorStatus, StatusType> = {
    Reporting: "success",
    PartiallyReporting: "warning",
    Terminated: "error",
    Pending: "disabled",
}

const labelMap: Record<ConnectorStatus, LanguageKey> = {
    Reporting: "reporting",
    PartiallyReporting: "partiallyReporting",
    Terminated: "terminated",
    Pending: "pending",
}

enum TabKey {
    CONFIGURE = "CONFIGURE",
    SERVICES = "SERVICES",
    INSTALL = "INSTALL",
}

const allTabs = [TabKey.CONFIGURE, TabKey.SERVICES, TabKey.INSTALL]

function useGetTabProps(id: string, connector?: Connector) {
    const localization = new LocalizationService()
    const location = useLocation()

    const labels: Record<TabKey, string> = {
        CONFIGURE: localization.getString("configure"),
        SERVICES: localization.getString(
            "servicesWithCount",
            (connector?.services.length ?? 0).toString()
        ),
        INSTALL: localization.getString("install"),
    }

    const path: Record<TabKey, ROUTE> = {
        [TabKey.CONFIGURE]: formatRoutePath(ROUTE.CONNECTORS_DETAILS_CONFIGURE, {
            id,
        }),
        [TabKey.SERVICES]: formatRoutePath(ROUTE.CONNECTORS_DETAILS_SERVICES, { id }),
        [TabKey.INSTALL]: formatRoutePath(ROUTE.CONNECTORS_DETAILS_INSTALL, { id }),
    }

    const isActive = useMemo<Record<TabKey, boolean>>(() => {
        return {
            [TabKey.CONFIGURE]: !!matchPath(location.pathname, {
                path: path[TabKey.CONFIGURE],
            }),
            [TabKey.SERVICES]: !!matchPath(location.pathname, {
                path: path[TabKey.SERVICES],
            }),
            [TabKey.INSTALL]: !!matchPath(location.pathname, {
                path: path[TabKey.INSTALL],
            }),
        }
    }, [location.pathname])

    return (tab: TabKey): TabProps => {
        return {
            key: tab,
            label: labels[tab],
            to: path[tab],
            active: isActive[tab],
        }
    }
}

function canUserDeleteConnector(connector?: Connector): boolean {
    return connector ? canConnectorBeDeleted(connector) : false
}
function canEditConnector(connector?: Connector): boolean {
    return !connector?.isSonicOs
}
function canInstallConnector(connector?: Connector): boolean {
    return !connector?.isSonicOs
}
