import React from "react"
import { useHistory, useLocation } from "react-router-dom"

import sonicwallCseLogo from "../../../../images/logo-horiz-black.svg"
import { ROUTE } from "../../../../routes"
import { Background } from "../../../../v3/components/background/Background.component"
import { LoginPayload, LoginService } from "../../../../v3/services/Login.service"
import { ErrorBanner } from "../../../components/banner/Banner.component"
import { Input } from "../../../components/input/Input"
import { useServiceLocalization } from "../../../services/localization/Localization.service"
import { useAuthService } from "../../../services/Auth.service"
import styles from "./LoginReset.module.scss"
import { Button, ButtonElement, ButtonType } from "../../../../components/button/Button.component"

export function LoginReset(): JSX.Element {
    const history = useHistory()
    const localization = useServiceLocalization()
    const loginService = new LoginService()
    const authService = useAuthService()

    const { orgName, uuid, email, type } = useQueryParameters()

    const [password, setPassword] = React.useState<string>("")
    const [confirmPassword, setConfirmPassword] = React.useState<string>("")

    const [errorMessage, setErrorMessage] = React.useState<string | null>(null)
    const [isLoading, setIsLoading] = React.useState<boolean>(false)

    const onSubmit = async (event: React.SyntheticEvent): Promise<void> => {
        event.preventDefault()

        setErrorMessage(null)

        if (password !== confirmPassword) {
            setErrorMessage(localization.getString("thePasswordsEnteredDoNotMatch"))
            return
        }
        setIsLoading(true)

        try {
            const res: LoginPayload = await loginService.resetPassword(
                orgName,
                email,
                password,
                uuid
            )

            switch (res.next) {
                case "configure_mfa":
                    history.push(ROUTE.LOCAL_ACCOUNT_CONFIGURE_MFA, { token: res.token })
                    break
                case "mfa":
                    history.push(ROUTE.LOCAL_ACCOUNT_MFA, { token: res.token })
                    break
                default: {
                    authService.setLogin(res.token)
                    history.push(authService.getLoginUrl())
                }
            }
        } catch (e) {
            const error = e as Error
            setErrorMessage(error.message)
        }

        setIsLoading(false)
    }

    // this form is used to reset an existing user password as well as set a password for a new user
    // so the text used in the form should reflect what the user is doing here
    let title = localization.getString("resetYourPassword")
    let buttonText = localization.getString("resetPassword")
    let inputText = localization.getString("newPassword")
    if (type?.toLowerCase() === "new_user") {
        title = localization.getString("setYourPassword")
        buttonText = localization.getString("setPassword")
        inputText = localization.getString("password")
    }

    return (
        <div className={styles.login}>
            <Background>
                <div className={styles.loginForm}>
                    <div className={styles.form}>
                        <form onSubmit={onSubmit} name="loginform">
                            <img src={sonicwallCseLogo} alt="" className={styles.logo} />
                            <p>{title}</p>
                            {errorMessage && (
                                <div className={styles.banner}>
                                    <ErrorBanner>{errorMessage}</ErrorBanner>
                                </div>
                            )}
                            <Input autoFocus name="orgName" id="orgName" value={orgName} disabled />

                            <Input autoFocus name="email" id="email" value={email} disabled />

                            <Input
                                name="password"
                                id="password"
                                type="password"
                                value={password}
                                onChange={(e) => setPassword(e.target.value)}
                                placeholder={inputText}
                                required
                                autoFocus
                            />
                            <Input
                                name="confirmpassword"
                                id="confirmpassword"
                                type="password"
                                value={confirmPassword}
                                onChange={(e) => setConfirmPassword(e.target.value)}
                                placeholder={localization.getString("confirmPassword")}
                            />

                            <Button
                                className={styles.submitButton}
                                loading={isLoading}
                                type="submit"
                                buttonType={ButtonType.PRIMARY}
                                asElement={ButtonElement.BUTTON}
                            >
                                {buttonText}
                            </Button>
                        </form>
                    </div>
                </div>
            </Background>
        </div>
    )
}

type QueryParameters = {
    orgName: string
    uuid: string
    email: string
    type?: "new_user" | "existing_user"
}

function useQueryParameters(): QueryParameters {
    const location = useLocation()
    const params = new URLSearchParams(location.search)

    return {
        // cspell:disable-next-line
        orgName: params.get("orgname") ?? "",
        uuid: params.get("uuid") ?? "",
        email: params.get("email") ?? "",
        type: getType(params),
    }
}

function getType(params: URLSearchParams): QueryParameters["type"] {
    const type = params.get("type")

    if (type !== "new_user" && type !== "existing_user") {
        return
    }

    return type
}
