import React, { ReactNode, SyntheticEvent } from "react"
import TrustProviderMdmConfigSettingsTemplate from "./TrustProviderMdmConfigSettings.template"
import { LocalizationService } from "../../../../../services/localization/Localization.service"
import { SettingsService } from "../../../../../services/Settings.service"
import { LinkService } from "../../../../../services/link/Link.service"
import { SecureService, UserService } from "../../../../../services"
import { SettingsApi } from "../../../../../api/Settings.api"
import { Bind } from "../../../../../decorators/Bind.decorator"
import { InviteCodeConfig, UserApi } from "../../../../../api/User.api"
import { macOSZTScript } from "./zt-script-macos"
import { PackageVersions } from "../../../../../api/Secure.api"
import { windowsZTScript } from "./zt-script-windows"

interface Props {
    canUpdateMdmSetting: boolean
}
export class TrustProviderMdmConfigSettings extends React.Component<
    Props,
    TrustProviderMdmConfigSettingsState
> {
    public state: TrustProviderMdmConfigSettingsState = {
        error: "",
        success: "",
        showConfirmDialog: false,
        deployKeyLoading: false,
        deployKey: "",
        otpSkipRoleOptions: [],
        otpSkipRole: "",
    }

    public render(): ReactNode {
        return TrustProviderMdmConfigSettingsTemplate.call(this)
    }

    public componentDidMount(): void {
        this.fetchData()
    }

    public fetchData(): void {
        this.userApi.getInviteCode().then((config: InviteCodeConfig) => {
            this.setState({ inviteCode: config.invite_code })
        })

        this.secureService.getLatestVersions().then((versions: PackageVersions) => {
            this.setState({ appVersion: versions.desktop_app })
        })

        Promise.all([this.settingsApi.getOtpSkipRoles(), this.settingsApi.getMdmDeployConfig()])
            .then(([roles, mdmConfig]) => {
                this.setState({
                    deployKey: mdmConfig.mdm_deploy_key,
                    otpSkipRole: mdmConfig.otp_skip_role,
                    otpSkipRoleOptions: roles.otp_skip_roles,
                })
            })
            .catch((error: Error) => {
                this.setState({ error: error.message })
            })
    }

    @Bind
    private onShowConfirmDialog(): void {
        this.setState({ showConfirmDialog: true })
    }

    @Bind
    private onHideConfirmDialog(): void {
        this.setState({ showConfirmDialog: false, deployKeyLoading: false })
    }

    @Bind
    private onGenerateDeploymentKey(): void {
        this.setState({ deployKeyLoading: true, success: "", error: "" })
        this.settingsApi.createMdmDeployKey().then(
            (mdmConfig) => {
                this.setState({
                    success: "",
                    error: "",
                    deployKeyLoading: false,
                    deployKey: mdmConfig.mdm_deploy_key,
                })
            },
            (error: Error) => {
                this.setState({
                    success: "",
                    error: error.message,
                    deployKeyLoading: false,
                    deployKey: "",
                })
            }
        )
    }

    @Bind
    private onRevokeDeploymentKey(event: SyntheticEvent): void {
        this.setState({ success: "", error: "", deployKeyLoading: true })
        event.preventDefault()
        if (event.target) {
            this.onShowConfirmDialog()
        }
    }

    @Bind
    private onSubmitRevokeDeploymentKey(): void {
        this.settingsApi.deleteMdmDeployKey().then(
            () => {
                this.setState({
                    success: this.ls.getString("deploymentKeyRevoked"),
                    error: "",
                    deployKeyLoading: false,
                    showConfirmDialog: false,
                })
                this.fetchData()
            },
            (error: Error) => {
                this.setState({
                    success: "",
                    error: error.message,
                    deployKeyLoading: false,
                    showConfirmDialog: false,
                })
                this.fetchData()
            }
        )
    }

    @Bind
    private onUpdateOtpSkipRole(roleName: string): void {
        const oldRole: string | undefined = this.state.otpSkipRole
        this.setState({ otpSkipRole: roleName, otpSuccess: false, otpError: false })
        this.settingsApi.setOtpSkipRole(roleName).then(
            () => {
                this.setState({ otpSuccess: true })
            },
            (error: Error) => {
                this.setState({ otpError: true, otpSkipRole: oldRole })
            }
        )
    }

    @Bind
    private onDownloadWindows(): void {
        this.setState({ windowsLoading: true, error: "" })
        try {
            this.downloadDeployScript(windowsZTScript, "windows")
        } catch {
            this.setState({ error: this.ls.getString("errorCouldNotDownloadZTScript") })
        }
        this.setState({ windowsLoading: false })
    }

    @Bind
    private onDownloadMac(): void {
        this.setState({ macLoading: true, error: "" })
        try {
            this.downloadDeployScript(macOSZTScript, "macos")
        } catch {
            this.setState({ error: this.ls.getString("errorCouldNotDownloadZTScript") })
        }
        this.setState({ macLoading: false })
    }

    private downloadDeployScript(raw: string, os: string): void {
        if (!this.state.inviteCode || !this.state.deployKey || !this.state.appVersion) {
            this.setState({ error: this.ls.getString("errorCouldNotDownloadZTScript") })
            return
        }

        const script: string = raw
            .replace("ZT_INVITE_CODE", this.state.inviteCode)
            .replace("ZT_DEPLOY_KEY", this.state.deployKey)
            .replace("ZT_APP_VERSION", this.state.appVersion)
        const blobUrl: string = URL.createObjectURL(new Blob([script]))
        let link: HTMLAnchorElement = document.createElement("a")
        link.download = `banyan-zt-${os}-v${this.state.appVersion}.${os === "macos" ? "sh" : "ps1"}`
        link.href = blobUrl
        link.click()
        URL.revokeObjectURL(blobUrl)
    }

    private ls: LocalizationService = new LocalizationService()
    private settingsService: SettingsService = new SettingsService()
    private linkService: LinkService = new LinkService()
    private settingsApi: SettingsApi = new SettingsApi()
    private userService: UserService = new UserService()
    private secureService: SecureService = new SecureService()
    private userApi: UserApi = new UserApi()
}

interface TrustProviderMdmConfigSettingsState {
    error: string
    success: string
    deployKeyLoading: boolean
    deployKey: string
    showConfirmDialog: boolean

    otpSuccess?: boolean
    otpError?: boolean
    otpSkipRole?: string
    otpSkipRoleOptions: string[]

    inviteCode?: string
    appVersion?: string
    macLoading?: boolean
    windowsLoading?: boolean
}
