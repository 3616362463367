import React from "react"

type Handler = (prompt: string) => void

export function SmartSearchProvider(props: React.PropsWithChildren): JSX.Element {
    const stateResult = React.useState<Handler>()

    return (
        <SmartSearchContext.Provider value={stateResult}>
            {props.children}
        </SmartSearchContext.Provider>
    )
}

export function useSmartSearch(): Handler | undefined {
    const [handler] = React.useContext(SmartSearchContext)

    return handler
}

/**
 * WARNING: Only provide a handler that will not change between renders
 */
export function useConfigureSmartSearch(handler?: Handler): void {
    const [, setHandler] = React.useContext(SmartSearchContext)

    React.useEffect(() => {
        setHandler(() => handler)

        return () => {
            setHandler(undefined)
        }
    }, [handler, setHandler])
}

type SmartSearchContextApi = [
    Handler | undefined,
    React.Dispatch<React.SetStateAction<Handler | undefined>>,
]

const SmartSearchContext = React.createContext<SmartSearchContextApi>([undefined, () => {}])
