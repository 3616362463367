import classNames from "classnames/bind"
import React from "react"

import {
    Button,
    ButtonElement,
    ButtonType,
    IconType,
} from "../../../components/button/Button.component"
import { Tooltip } from "../tooltip/Tooltip.component"
import { FormLabel, Props as FormLabelProps } from "./FormLabel.component"
import styles from "./FormColumn.module.scss"

interface Props extends FormLabelProps {
    indented?: boolean
    alignItemsStart?: boolean
    className?: string
    labelClassName?: string
    children?: React.ReactNode
    removeLabel?: string
    onRemove?: () => void
}

export function FormColumn(props: Props): JSX.Element {
    return (
        <div className={styles.rowContainer}>
            <div
                className={classNames(styles.column, props.className, {
                    [styles.alignItemsStart]: props.alignItemsStart,
                    [styles.indented]: props.indented,
                })}
            >
                <FormLabel
                    label={props.label}
                    description={props.description}
                    htmlFor={props.htmlFor}
                    className={classNames(props.labelClassName, styles.label)}
                />
                {props.children}
            </div>
            {props.removeLabel && (
                <Tooltip title={props.removeLabel}>
                    <Button
                        asElement={ButtonElement.BUTTON}
                        buttonType={ButtonType.SECONDARY}
                        className={styles.removeButton}
                        icon={IconType.TRASH}
                        onClick={props.onRemove}
                    />
                </Tooltip>
            )}
        </div>
    )
}
