import {
    MutationOptions,
    useMutation,
    useQuery,
    useQueryClient,
    UseQueryResult,
} from "@tanstack/react-query"
import { AppConfigApi } from "../api/AppConfig.api"

const enum appConfigKeys {
    APP_CONFIG = "appConfigService.appConfig",
}

export function useGetAppConfig(
    options?: QueryOptions<AppConfig>
): UseQueryResult<AppConfig, string> {
    const appConfigApi: AppConfigApi = new AppConfigApi()

    return useQuery({
        ...options,
        queryKey: [appConfigKeys.APP_CONFIG],
        queryFn: async (): Promise<AppConfig> => {
            const { data } = await appConfigApi.getAppConfig()

            return {
                nrptConfig: data.nrpt_config,
                id: data.id,
                orgId: data.org_id,
            }
        },
    })
}

export function useUpdateNrptConfig(
    options?: MutationOptions<void, string, { value: boolean; appConfig?: AppConfig }>
) {
    const appConfigApi: AppConfigApi = new AppConfigApi()
    const queryClient = useQueryClient()

    return useMutation({
        ...options,
        mutationFn: async (config: { value: boolean; appConfig?: AppConfig }): Promise<void> => {
            if (config.appConfig?.id) {
                await appConfigApi.updateAppConfig({ nrpt_config: config.value })
            } else {
                await appConfigApi.createAppConfig({ nrpt_config: config.value })
            }
        },
        onSuccess: (...args) => {
            queryClient.invalidateQueries([appConfigKeys.APP_CONFIG])
            options?.onSuccess?.(...args)
        },
    })
}

export interface AppConfig {
    nrptConfig: boolean
    id?: string
    orgId?: string
}
