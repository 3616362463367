import React from "react"
import { useLocation } from "react-router-dom"

import { useServiceActionBar } from "../../../../pre-v3/services/ActionBar.service"
import { FilterModel } from "../../../../pre-v3/utils/AgGrid.util"
import { UrlUtil } from "../../../../pre-v3/utils/Url.util"
import { GridApi } from "../../../components/grid/Grid.component"
import {
    AccessActivityFilterById as FilterById,
    PrivateResourceDetails as PrivateResource,
    useGetAccessActivitiesByPrivateResource,
    useGetDevices,
    useGetUsers,
} from "../../../services/PrivateResource.service"
import { AccessActivityFilterBar } from "./AccessActivityFilterBar.component"
import { AccessActivityGrid } from "./AccessActivityGrid.component"
import styles from "./AccessActivityPanel.module.scss"

interface Props {
    id: string
    privateResource: PrivateResource
}

export function AccessActivityPanel(props: Props): JSX.Element {
    const actionBarService = useServiceActionBar()
    const location = useLocation()

    const gridRef = React.useRef<GridApi>(null)

    const [filterModel, setFilterModel] = React.useState(
        UrlUtil.readFilter<FilterById>(location.search)
    )

    const onFilter = (newFilterModel: FilterModel<FilterById>): void => {
        setFilterModel(newFilterModel)
        UrlUtil.writeFilter(newFilterModel)
    }

    const { getAccessActivities, clearCache: clearAccessActivitiesCache } =
        useGetAccessActivitiesByPrivateResource(props.privateResource)
    const { getDevices, clearCache: clearDevicesCache } = useGetDevices()
    const { getUsers, clearCache: clearUsersCache } = useGetUsers()

    React.useEffect(() => {
        const refresh = async () => {
            await Promise.all([
                clearAccessActivitiesCache(),
                clearDevicesCache(),
                clearUsersCache(),
            ])
            gridRef.current?.refreshData()
        }

        actionBarService.registerRefreshFn(refresh)
        return () => actionBarService.unregisterRefreshFn(refresh)
    }, [clearAccessActivitiesCache, gridRef.current?.refreshData])

    return (
        <div id={props.id} className={styles.container}>
            <AccessActivityFilterBar
                filterModel={filterModel}
                onFilter={onFilter}
                getDevices={getDevices}
                getUsers={getUsers}
            />
            <AccessActivityGrid
                filterModel={filterModel}
                getAccessActivities={getAccessActivities}
                ref={gridRef}
            />
        </div>
    )
}
