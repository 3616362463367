import {
    ColDef,
    GridApi,
    GridReadyEvent,
    IServerSideDatasource,
    IServerSideGetRowsParams,
    RowDoubleClickedEvent,
    RowSelectedEvent,
    GetRowIdParams,
} from "ag-grid-community"
import React, { ReactNode } from "react"
import { RouteComponentProps } from "react-router-dom"

import { ROUTE, formatRoutePath } from "../../../../routes"
import { isProfileAdmin, profileResDict } from "../../../../utils/profile.utils"
import { Bind } from "../../../decorators/Bind.decorator"
import { Debounce } from "../../../decorators/Debounce.decorator"
import ActionBarService from "../../../services/ActionBar.service"
import { EntityService } from "../../../services/Entity.service"
import { LocalizationService } from "../../../services/localization/Localization.service"
import AgGridUtil, {
    DataFilter,
    DataFilterType,
    FilterModel,
    SortBy,
} from "../../../utils/AgGrid.util"
import { UnregisteredDeviceEntity } from "../../../utils/Entity.util"
import UnregisteredDevicesListTemplate from "./UnregisteredDevicesList.template"
import { EntityApi, Platform } from "../../../api/Entity.api"
import { encodeID, UrlUtil } from "../../../utils/Url.util"
import { ModalService, UserService } from "../../../services"
import { DeleteCancelActions } from "../../../components/modal/delete-cancel/DeleteCancelActions"
import { UserOrgDetails } from "../../../api/User.api"
import { MessageContent } from "../../../components/modal/message/MessageContent"

export class UnregisteredDevicesList extends React.Component<
    UnregisteredDevicesListProps,
    UnregisteredDevicesListState
> {
    constructor(props: UnregisteredDevicesListProps) {
        super(props)
        this.initialModel = UrlUtil.readFilter()
    }

    public state: UnregisteredDevicesListState = {
        error: "",
        isAdminProfile: false,
        selectedDevicesLen: 0,
    }

    public componentDidMount(): void {
        this.userService.getUserOrgDetails().then((res: UserOrgDetails) => {
            const isAdminProfile = isProfileAdmin(profileResDict[res.Profile])
            this.setState({ isAdminProfile })
        })
    }

    public componentWillUnmount(): void {
        this.actionBarService.unregisterRefreshFn(this.onRefresh)
    }

    public render(): ReactNode {
        return UnregisteredDevicesListTemplate.call(this)
    }

    private actionBarService: ActionBarService = new ActionBarService()
    private ls: LocalizationService = new LocalizationService()
    private entityService: EntityService = new EntityService()
    private userService: UserService = new UserService()
    private modalService: ModalService = new ModalService()
    private entityApi: EntityApi = new EntityApi()
    private gridApi: GridApi
    private initialModel: FilterModel = {}

    @Bind
    private onDeleteDevices(): void {
        let selectedRow: UnregisteredDeviceEntity[] = this.gridApi.getSelectedRows()
        let enduserDeviceIds: string[] = []
        if (selectedRow.length > 0) {
            for (let row of selectedRow) {
                enduserDeviceIds.push(row.enduserDeviceId)
            }
        }
        this.modalService
            .open(
                this.ls.getString("removeDevices"),
                {
                    component: MessageContent,
                    props: {
                        text:
                            enduserDeviceIds.length === 1
                                ? this.ls.getString("deleteOneUnregisteredDeviceExplanation")
                                : this.ls.getString(
                                      "deleteUnregisteredDeviceExplanation",
                                      enduserDeviceIds.length.toString()
                                  ),
                    },
                },
                DeleteCancelActions
            )
            .onClose(() => {
                if (enduserDeviceIds.length > 0) {
                    this.entityService.deleteUnregisteredDevices(enduserDeviceIds).then(
                        () => {
                            this.setState({ error: "" })
                            this.fetchData()
                            this.onRefresh()
                        },
                        () => {
                            this.setState({
                                error: this.ls.getString("failedToDeleteDevices"),
                            })
                            this.onRefresh()
                        }
                    )
                } else {
                    this.setState({ error: this.ls.getString("selectAUnregisteredDeviceToDelete") })
                }
            })
            .onCancel(() => {
                this.onRefresh()
            })
    }

    private columns: ColDef[] = [
        {
            headerName: this.ls.getString("email"),
            field: "email",
            filter: "agTextColumnFilter",
            tooltipValueGetter: AgGridUtil.linkTooltipValueGetter,
            cellRenderer: "usersEmailCellRenderer",
            sort: "asc",
        },
        {
            headerName: this.ls.getString("platform"),
            field: "platform",
            filter: "agTextColumnFilter",
            sortable: false,
        },
        {
            headerName: this.ls.getString("latestIpAddress"),
            field: "ipAddress",
            valueFormatter: AgGridUtil.nullableStringFormatter,
            sortable: false,
        },
        {
            headerName: this.ls.getString("roles"),
            field: "roles",
            valueFormatter: AgGridUtil.stringArrayFormatter,
            sortable: false,
        },
        {
            headerName: this.ls.getString("lastLogin"),
            field: "lastLogin",
            valueFormatter: AgGridUtil.dateFormatter,
        },
    ]

    private filters: DataFilter[] = [
        {
            key: FilterType.EMAIL,
            displayName: this.ls.getString("email"),
            type: DataFilterType.DEBOUNCEDINPUT,
        },
        {
            key: FilterType.PLATFORM,
            displayName: this.ls.getString("platform"),
            type: DataFilterType.LIST,
            options: Object.values(Platform),
        },
    ]

    @Bind
    private onRefresh(): void {
        this.onSelectionChange([])
        if (this.gridApi) {
            this.gridApi.deselectAll()
            this.gridApi.refreshServerSideStore()
        }
    }

    @Bind
    private rowSelectedHandler(event: RowSelectedEvent): void {
        if (event && event.data) {
            this.onSelectionChange(this.gridApi.getSelectedRows())
        }
    }

    private onSelectionChange(selections: UnregisteredDeviceEntity[]): void {
        this.setState({ selectedDevicesLen: selections.length })
    }

    @Bind
    private onGridReady(event: GridReadyEvent): void {
        this.gridApi = event.api

        if (Object.keys(this.initialModel).length > 0) {
            this.gridApi.setFilterModel(this.initialModel)
        }
        this.fetchData()
    }

    @Debounce
    private onFilterChange(model: FilterModel): void {
        if (this.gridApi) {
            this.gridApi.setFilterModel(model)
        }
        UrlUtil.writeFilter(model)
    }

    @Bind
    private onDownloadDevices(): void {
        this.setState({ error: "" })
        this.entityApi
            .getUnregisteredDevicesCSV()
            .then((blob: Blob) => {
                const blobUrl = URL.createObjectURL(blob)
                let link: HTMLAnchorElement = document.createElement("a")
                link.download = `${this.ls.getString("unregistered")}-${this.ls.getString(
                    "devices"
                )}.csv`
                link.href = blobUrl
                link.click()
                URL.revokeObjectURL(blobUrl)
            })
            .catch((err: Error) => {
                this.setState({ error: this.ls.getString("failedToDownloadDevicesCSV") })
            })
    }

    private fetchData(): void {
        this.gridApi?.setServerSideDatasource(new DataSource(this.gridApi))
    }

    @Bind
    private rowDoubleClickedHandler(event: RowDoubleClickedEvent): void {
        if (event?.data?.email) {
            const path = formatRoutePath(ROUTE.USERS_DETAILS, {
                id: encodeID(event.data.email),
            })
            this.props.history.push(path)
        }
    }

    public getRowId(rowData: GetRowIdParams): string {
        return rowData.data.enduserDeviceId
    }
}

class DataSource implements IServerSideDatasource {
    constructor(gridApi: GridApi) {
        this.gridApi = gridApi
    }
    getRows(params: IServerSideGetRowsParams): void {
        const skipLimit: { skip: number; limit: number } = {
            skip: 0,
            limit: 10_000,
        }
        if (params.request.startRow) {
            skipLimit.skip = params.request.startRow
        }

        if (params.request.endRow) {
            skipLimit.limit = params.request.endRow - skipLimit.skip
        }

        const sortModel: SortBy[] = params.request.sortModel
        const filterModel: FilterModel = params.request.filterModel

        this.gridApi.showLoadingOverlay()
        this.entityService
            .getUnregisteredDeviceEntities({ ...skipLimit, sortModel, filterModel })
            .then((res) => {
                params.success({ rowData: res.data, rowCount: res.total })
                res.total ? this.gridApi.hideOverlay() : this.gridApi.showNoRowsOverlay()
            }, params.fail)
    }

    private gridApi: GridApi
    private entityService: EntityService = new EntityService()
}

interface UnregisteredDevicesListProps extends Omit<RouteComponentProps, "location" | "match"> {
    doShowUpsellView?: boolean
}

interface UnregisteredDevicesListState {
    filterLabel?: string
    error: string
    isAdminProfile: boolean
    selectedDevicesLen: number
}

enum FilterType {
    EMAIL = "email",
    PLATFORM = "platform",
}
