import React from "react"

import { Button, ButtonElement, ButtonType } from "../../../../components/button/Button.component"
import { useServiceLocalization } from "../../../../pre-v3/services/localization/Localization.service"
import { SelectItem } from "../../../../pre-v3/utils/SelectValue.util"
import { ErrorBanner } from "../../../components/banner/Banner.component"
import { Form } from "../../../components/form/Form.component"
import { FormButtons } from "../../../components/form/form-buttons/FormButtons.component"
import { FormRow } from "../../../components/form/FormRow.component"
import { Input } from "../../../components/input/Input.component"
import { SelectInput } from "../../../components/select-input/SelectInput.component"
import { ToggleButton, ToggleButtonItem } from "../../../components/toggle-button/ToggleButton"
import {
    AccessTier,
    BaseDomainCluster,
    Cluster,
    Clusters,
    ClusterType,
    ClustersType,
    RegisteredDomainDetails,
    UnsavedRegisteredDomain,
    AccessTierGroup,
} from "../../../services/RegisteredDomain.service"

interface Props {
    id?: string
    registeredDomain?: RegisteredDomainDetails
    clusters: Clusters
    disabled?: boolean
    error?: string | null
    isSubmitting?: boolean
    onSubmit?(value: UnsavedRegisteredDomain): void
    onCancel?(): void
    enableAccessTierGroups: boolean
    canAddRegisteredDomain?: boolean
}

export function RegisteredDomainConfigurationForm(props: Props): JSX.Element {
    const ls = useServiceLocalization()

    const [registeredDomain, setRegisteredDomain] = React.useState<UnsavedRegisteredDomain>(() =>
        getInitialRegisteredDomain(props.clusters, props.registeredDomain)
    )
    const options = useNetworkOptions(props.clusters, props.enableAccessTierGroups)

    const networkToggleItems: ToggleButtonItem[] = [
        {
            label: ls.getString("globalEdge"),
            value: ClusterType.GLOBAL_EDGE,
            selected: registeredDomain.cluster.type === ClusterType.GLOBAL_EDGE,
            onClick: () => setRegisteredDomain((prev) => selectGlobalEdge(prev, props.clusters)),
        },
        {
            label: ls.getString("privateEdge"),
            value: ClusterType.PRIVATE_EDGE,
            selected: registeredDomain.cluster.type === ClusterType.PRIVATE_EDGE,
            onClick: () => setRegisteredDomain((prev) => selectPrivateEdge(prev, props.clusters)),
        },
    ]

    const onSubmit: React.FormEventHandler = (event) => {
        event.preventDefault()
        props.onSubmit?.(registeredDomain)
    }

    function onChange(id: string) {
        if (props.clusters.type === ClustersType.GLOBAL_EDGE) return
        if (props.clusters.privateEdgeCluster.accessTiers.map((at) => at.id).includes(id)) {
            setRegisteredDomain((prev) => selectAccessTier(prev, id, props.clusters))
        } else if (
            props.clusters.privateEdgeCluster.accessTierGroups.map((group) => group.id).includes(id)
        ) {
            setRegisteredDomain((prev) => selectAccessTierGroup(prev, id, props.clusters))
        }
    }

    return (
        <Form onSubmit={onSubmit} id={props.id}>
            <FormRow label={ls.getString("domainName")}>
                <Input
                    value={registeredDomain.name}
                    onChangeValue={(name) => setRegisteredDomain((prev) => ({ ...prev, name }))}
                    type="text"
                    name="name"
                    placeholder={ls.getString("registeredDomainExample")}
                    required
                    disabled={props.disabled}
                />
            </FormRow>
            <FormRow label={ls.getString("somethingOptional", ls.getString("description"))}>
                <Input
                    value={registeredDomain.description}
                    onChangeValue={(description) =>
                        setRegisteredDomain((prev) => ({ ...prev, description }))
                    }
                    type="text"
                    name="description"
                    placeholder={ls.getString("enterDescription")}
                    disabled={props.disabled}
                />
            </FormRow>
            {props.clusters.type === ClustersType.BOTH && (
                <FormRow
                    label={ls.getString("chooseSomething", ls.getString("networkConfiguration"))}
                >
                    <ToggleButton items={networkToggleItems} required disabled={props.disabled} />
                </FormRow>
            )}
            {(props.clusters.type === ClustersType.PRIVATE_EDGE ||
                registeredDomain.cluster.type === ClusterType.PRIVATE_EDGE) && (
                <FormRow id="network" label={ls.getString("network")}>
                    <SelectInput
                        labelId={
                            registeredDomain.accessTier.id
                                ? ConfigurationField.ACCESS_TIER
                                : ConfigurationField.ACCESS_TIER_GROUP
                        }
                        name="network"
                        value={
                            registeredDomain.accessTier.id || registeredDomain.accessTierGroup.id
                        }
                        onChange={onChange}
                        options={options}
                        required
                        disabled={props.disabled}
                    />
                </FormRow>
            )}
            {props.canAddRegisteredDomain && !props.disabled && (
                <FormButtons
                    rightButtons={
                        <>
                            <Button
                                asElement={ButtonElement.BUTTON}
                                buttonType={ButtonType.SECONDARY}
                                type="button"
                                onClick={props.onCancel}
                            >
                                {ls.getString("cancel")}
                            </Button>
                            <Button
                                type="submit"
                                asElement={ButtonElement.BUTTON}
                                buttonType={ButtonType.PRIMARY}
                                loading={props.isSubmitting}
                            >
                                {ls.getString("saveAndContinue")}
                            </Button>
                        </>
                    }
                >
                    {props.error && <ErrorBanner>{props.error}</ErrorBanner>}
                </FormButtons>
            )}
        </Form>
    )
}

function getInitialRegisteredDomain(
    clusters: Clusters,
    registeredDomain?: RegisteredDomainDetails
): UnsavedRegisteredDomain {
    if (!registeredDomain) {
        return {
            name: "",
            description: "",
            ...getInitialClusterAndAccessTierAndAccessTierGroup(clusters),
        }
    }

    return {
        id: registeredDomain.id,
        name: registeredDomain.name,
        description: registeredDomain.description,
        cluster: registeredDomain.cluster,
        // Older Registered Domains may not be tied to an Access Tier.
        // In this case, the Access Tier key will point to the CNAME.
        accessTier:
            typeof registeredDomain.cluster.accessTier === "string"
                ? emptyAccessTier
                : registeredDomain.cluster.accessTier,
        accessTierGroup:
            typeof registeredDomain.cluster.accessTierGroup === "string"
                ? emptyAccessTierGroup
                : registeredDomain.cluster.accessTierGroup,
    }
}

const emptyAccessTier: AccessTier = {
    id: "",
    name: "",
    address: "",
}

const emptyAccessTierGroup: AccessTierGroup = {
    id: "",
    name: "",
    tunnel_enduser: {
        shared_fqdn: "",
    },
}

function getInitialClusterAndAccessTierAndAccessTierGroup(clusters: Clusters): {
    cluster: BaseDomainCluster
    accessTier: AccessTier
    accessTierGroup: AccessTierGroup
} {
    switch (clusters.type) {
        case ClustersType.BOTH:
            return {
                cluster: {
                    id: "",
                    name: "",
                },
                accessTier: emptyAccessTier,
                accessTierGroup: emptyAccessTierGroup,
            }

        case ClustersType.GLOBAL_EDGE:
            return {
                cluster: {
                    ...clusters.globalEdgeCluster,
                    type: ClusterType.GLOBAL_EDGE,
                },
                accessTier: clusters.globalEdgeCluster.accessTiers[0] ?? emptyAccessTier,
                accessTierGroup:
                    clusters.globalEdgeCluster.accessTierGroups[0] ?? emptyAccessTierGroup,
            }

        case ClustersType.PRIVATE_EDGE:
            const [firstAccessTier, ...otherAccessTiers] = clusters.privateEdgeCluster.accessTiers
            const [firstAccessTierGroup, ...otherAccessTierGroups] =
                clusters.privateEdgeCluster.accessTierGroups

            return {
                cluster: {
                    ...clusters.privateEdgeCluster,
                    type: ClusterType.PRIVATE_EDGE,
                },
                accessTier:
                    firstAccessTier && otherAccessTiers.length <= 0
                        ? firstAccessTier
                        : emptyAccessTier,
                accessTierGroup:
                    firstAccessTierGroup && otherAccessTierGroups.length <= 0
                        ? firstAccessTierGroup
                        : emptyAccessTierGroup,
            }
    }
}

enum ConfigurationField {
    ACCESS_TIER = "accessTier",
    ACCESS_TIER_GROUP = "accessTierGroup",
}

function useNetworkOptions(clusters: Clusters, enableAccessTierGroups: boolean): SelectItem[] {
    const ls = useServiceLocalization()
    const accessTiersDivider = {
        displayName: ls.getString("accessTier"),
        value: "accessTier",
        divisor: true,
    }
    const groupDivider = {
        displayName: ls.getString("accessTierGroups"),
        value: "accessTierGroup",
        divisor: true,
    }
    var menuOptions: SelectItem[] = []
    return React.useMemo(() => {
        if (clusters.type === ClustersType.GLOBAL_EDGE) return []
        const accessTiers = clusters.privateEdgeCluster.accessTiers.map((accessTier) => ({
            displayName: accessTier.name,
            value: accessTier.id,
        }))

        const accessTierGroups = clusters.privateEdgeCluster.accessTierGroups.map((group) => ({
            displayName: group.name,
            value: group.id,
        }))
        if (accessTiers.length > 0) {
            menuOptions.push(accessTiersDivider, ...accessTiers)
        }
        if (accessTierGroups.length > 0 && enableAccessTierGroups) {
            menuOptions.push(groupDivider, ...accessTierGroups)
        }
        return menuOptions
    }, [clusters])
}

function flattenClusters(clusters: Clusters): Cluster[] {
    switch (clusters.type) {
        case ClustersType.BOTH:
            return [clusters.globalEdgeCluster, clusters.privateEdgeCluster]

        case ClustersType.GLOBAL_EDGE:
            return [clusters.globalEdgeCluster]

        case ClustersType.PRIVATE_EDGE:
            return [clusters.privateEdgeCluster]
    }
}

function selectGlobalEdge(
    prev: UnsavedRegisteredDomain,
    clusters: Clusters
): UnsavedRegisteredDomain {
    if (clusters.type !== ClustersType.BOTH) return prev
    return {
        ...prev,
        cluster: {
            ...clusters.globalEdgeCluster,
            type: ClusterType.GLOBAL_EDGE,
        },
        accessTier: clusters.globalEdgeCluster.accessTiers[0],
    }
}

function selectPrivateEdge(
    prev: UnsavedRegisteredDomain,
    clusters: Clusters
): UnsavedRegisteredDomain {
    if (clusters.type !== ClustersType.BOTH) return prev

    const [firstAccessTier, ...otherAccessTiers] = clusters.privateEdgeCluster.accessTiers
    const [firstAccessTierGroup, ...otherAccessTierGroups] =
        clusters.privateEdgeCluster.accessTierGroups

    return {
        ...prev,
        cluster: {
            ...clusters.privateEdgeCluster,
            type: ClusterType.PRIVATE_EDGE,
        },
        accessTier:
            firstAccessTier && otherAccessTiers.length <= 0 ? firstAccessTier : emptyAccessTier,
        accessTierGroup:
            firstAccessTierGroup && otherAccessTierGroups.length <= 0
                ? firstAccessTierGroup
                : emptyAccessTierGroup,
    }
}

function selectAccessTier(
    prev: UnsavedRegisteredDomain,
    id: string,
    clusters: Clusters
): UnsavedRegisteredDomain {
    const result = flattenClusters(clusters).reduce<[BaseDomainCluster, AccessTier] | undefined>(
        (acc, cluster) => {
            const accessTier = cluster.accessTiers.find((accessTier) => accessTier.id === id)

            return accessTier ? [{ ...cluster, type: ClusterType.PRIVATE_EDGE }, accessTier] : acc
        },
        undefined
    )

    if (!result) return prev

    Object.assign(prev, {
        accessTierGroup: emptyAccessTierGroup,
    })
    const [cluster, accessTier] = result
    return { ...prev, cluster, accessTier }
}

function selectAccessTierGroup(
    prev: UnsavedRegisteredDomain,
    id: string,
    clusters: Clusters
): UnsavedRegisteredDomain {
    const result = flattenClusters(clusters).reduce<
        [BaseDomainCluster, AccessTierGroup] | undefined
    >((acc, cluster) => {
        const accessTierGroup = cluster.accessTierGroups.find((group) => group.id === id)

        return accessTierGroup
            ? [{ ...cluster, type: ClusterType.PRIVATE_EDGE }, accessTierGroup]
            : acc
    }, undefined)

    if (!result) return prev

    Object.assign(prev, {
        accessTier: emptyAccessTier,
    })
    const [cluster, accessTierGroup] = result
    return { ...prev, cluster, accessTierGroup }
}
