import { ColDef, ValueGetterParams } from "ag-grid-community"
import * as React from "react"
import styles from "./PublicResourceOverview.module.scss"
import { PublicResourceDetail } from "../../../../services/PublicResource.service"
import { StringUtil } from "../../../../../pre-v3/utils/String.util"
import { useServiceLocalization } from "../../../../../pre-v3/services/localization/Localization.service"
import AgGridUtil from "../../../../../pre-v3/utils/AgGrid.util"
import { SearchInput } from "../../../../components/search-input/SearchInput.component"
import { Grid } from "../../../../../pre-v3/components/grid/Grid.component"
import classNames from "classnames"

interface Props {
    resource: Partial<PublicResourceDetail>
    className?: string
}

export function PublicResourceDomainGrid(props: Props) {
    const localization = useServiceLocalization()
    const [search, setSearch] = React.useState<string>("")

    const filteredDomains = React.useMemo(
        (): string[] | undefined =>
            search
                ? props.resource?.domains?.filter((domain) =>
                      StringUtil.caseInsensitiveIncludes(domain, search)
                  )
                : props.resource?.domains,
        [props.resource?.domains, search]
    )

    const totalDomains: number = props.resource?.domains?.length || 0

    const column: ColDef[] = [
        {
            headerName: localization.getString(
                "somethingCount",
                localization.getString("domains"),
                totalDomains.toString()
            ),
            field: "domains",
            tooltipValueGetter: AgGridUtil.linkTooltipValueGetter,
            valueGetter: (params: ValueGetterParams): string => {
                return params.data
            },
            flex: 75,
            comparator: AgGridUtil.alphaBetComparator,
        },
    ]

    return (
        <div className={classNames(styles.table, props.className)}>
            <SearchInput
                className={styles.search}
                value={search}
                onChangeValue={setSearch}
                placeholder={localization.getString("search")}
            />
            <Grid rowData={filteredDomains} columnDefs={column} pagination autoHeight />
        </div>
    )
}
