import React, { ReactNode, SyntheticEvent } from "react"
import ServicesAddSshSettingsTemplate from "./ServicesAddSshSettings.template"
import { LocalizationService } from "../../../../services/localization/Localization.service"
import { SSHServiceType } from "../../../../services/Manage.service"
import { Bind } from "../../../../decorators/Bind.decorator"
import { SelectItem } from "../../../../utils/SelectValue.util"

export class ServicesAddSshSettings extends React.Component<
    ServicesAddSshSettingsProps,
    ServicesAddSshSettingsState
> {
    public state: ServicesAddSshSettingsState = {
        host: "",
        certOptions: [],
        certSelect: SSHServiceType.TRUSTCERT,
        configSelect: true,
    }

    public componentDidMount(): void {
        if (this.props.initialHost) {
            this.setState({ host: this.props.initialHost })
        }
        if (this.props.initialSshServiceType) {
            this.setState({ certSelect: this.props.initialSshServiceType })
        }
        if (typeof this.props.initialWriteConfig !== "undefined") {
            this.setState({ configSelect: this.props.initialWriteConfig })
        }
        this.setCertOptions()
    }

    public componentDidUpdate(prevProps: ServicesAddSshSettingsProps) {
        if (prevProps.mustUseTrustCert !== this.props.mustUseTrustCert) {
            this.setCertOptions()
        }
    }

    public render(): ReactNode {
        return ServicesAddSshSettingsTemplate.call(this)
    }

    private ls: LocalizationService = new LocalizationService()

    private configOptions: SelectItem[] = [
        {
            displayName: this.ls.getString("yes"),
            value: true,
        },
        {
            displayName: this.ls.getString("no"),
            value: false,
        },
    ]

    private setCertOptions(): void {
        if (this.props.mustUseTrustCert) {
            this.setState({
                certOptions: [
                    {
                        displayName: this.ls.getString("onlyUseTheTrustCert"),
                        value: SSHServiceType.TRUSTCERT,
                    },
                    {
                        displayName: this.ls.getString("useBothTheTrustCertAndTheSshCert"),
                        value: SSHServiceType.BOTH,
                    },
                ],
            })
            if (this.state.certSelect === SSHServiceType.SSHCERT) {
                this.setState({ certSelect: SSHServiceType.TRUSTCERT }, this.emit)
            }
        } else {
            this.setState({
                certOptions: [
                    {
                        displayName: this.ls.getString("onlyUseTheTrustCert"),
                        value: SSHServiceType.TRUSTCERT,
                    },
                    {
                        displayName: this.ls.getString("onlyUseTheSshCert"),
                        value: SSHServiceType.SSHCERT,
                    },
                    {
                        displayName: this.ls.getString("useBothTheTrustCertAndTheSshCert"),
                        value: SSHServiceType.BOTH,
                    },
                ],
            })
        }
    }

    @Bind
    private onHostChange(event: SyntheticEvent): void {
        const value: string = (event.target as HTMLInputElement).value
        this.setState({ host: value }, this.emit)
    }

    @Bind
    private onCertChange(value: SSHServiceType): void {
        this.setState({ certSelect: value }, this.emit)
    }

    @Bind
    private onConfigChange(value: boolean): void {
        this.setState({ configSelect: value }, this.emit)
    }

    @Bind
    private emit(): void {
        if (this.props.onChange) {
            this.props.onChange(this.state.host, this.state.certSelect, this.state.configSelect)
        }
    }
}

interface ServicesAddSshSettingsProps {
    onChange?: (host: string, certType: SSHServiceType, writeConfig: boolean) => void
    initialHost?: string
    initialSshServiceType?: SSHServiceType
    initialWriteConfig?: boolean
    mustUseTrustCert?: boolean
    disabled?: boolean
}

interface ServicesAddSshSettingsState {
    host: string
    certOptions: SelectItem[]
    certSelect: SSHServiceType
    configSelect: boolean
}
