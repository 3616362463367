import * as React from "react"
import { Chart } from "."

interface Props {
    data?: { displayName: string; value: number; color?: string }[]
    title?: string
    subtitle?: string
    options?: Highcharts.PlotPieOptions
}

export function PieChart(props: Props) {
    return (
        <Chart
            title={props.title}
            subtitle={props.subtitle}
            options={{
                chart: {
                    backgroundColor: "transparent",
                    type: "pie",
                },
                legend: {
                    enabled: true,
                    layout: "vertical",
                    align: "right",
                    verticalAlign: "middle",
                    symbolRadius: 0,
                    itemStyle: {
                        color: "#222C36",
                        fontWeight: "600",
                    },
                },
                plotOptions: {
                    pie: {
                        innerSize: "75%",
                        showInLegend: true,
                        dataLabels: {
                            enabled: false,
                        },
                        borderColor: undefined,
                        ...props.options,
                    },
                },
                series: [
                    {
                        type: "pie",
                        id: "Count",
                        name: "Count",
                        data: props.data?.map((d) => {
                            return { name: d.displayName, y: d.value, color: d.color }
                        }),
                    },
                ],
            }}
        />
    )
}
