import React, { FC, ReactElement, useMemo } from "react"
import { IconType } from "../../../pre-v3/services"
import { useServiceLocalization } from "../../../pre-v3/services/localization/Localization.service"
import { PageHeading } from "../../../components/page-heading/PageHeading.component"
import { Button, ButtonElement, ButtonType } from "../../../components/button/Button.component"
import styles from "./AdminActionBar.module.scss"
import { Tooltip } from "../../../v3/components/tooltip/Tooltip.component"
import { useLocation } from "react-router-dom"
import { ROUTE } from "../../../routes"

interface Props {
    onRefresh?: () => void
    children: ReactElement
}

export const AdminActionBar: FC<Props> = ({ onRefresh, children }) => {
    const localization = useServiceLocalization()
    const location = useLocation()

    const isMspOrMom = useMemo(() => {
        return (
            new RegExp(ROUTE.MSP_CONSOLE).test(location.pathname) ||
            new RegExp(ROUTE.MOM_CONSOLE).test(location.pathname)
        )
    }, [location])

    if (isMspOrMom) return children

    return (
        <section aria-labelledby={Id.HEADING} className={styles.container}>
            <header className={styles.header}>
                <PageHeading id={Id.HEADING}>{localization.getString("manageAdmins")}</PageHeading>
                {onRefresh && (
                    <Tooltip title={localization.getString("refresh")}>
                        <Button
                            icon={IconType.REDO}
                            onClick={onRefresh}
                            asElement={ButtonElement.BUTTON}
                            buttonType={ButtonType.SECONDARY}
                            aria-label={localization.getString("refresh")}
                        />
                    </Tooltip>
                )}
            </header>
            {children}
        </section>
    )
}

enum Id {
    HEADING = "heading",
}
