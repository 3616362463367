import { useMutation } from "@tanstack/react-query"
import {
    ColDef,
    GridApi,
    GridReadyEvent,
    IServerSideDatasource,
    IServerSideGetRowsParams,
} from "ag-grid-community"
import React, { useMemo, useRef } from "react"
import { useHistory, useParams } from "react-router-dom"

import { ErrorBanner } from "../../../../pre-v3/components/banner/Banner.component"
import { Grid } from "../../../../pre-v3/components/grid/Grid.component"
import { useServiceLocalization } from "../../../../pre-v3/services/localization/Localization.service"
import AgGridUtil, {
    FilterModel,
    Paginated,
    PaginatedSearch,
    SortBy,
} from "../../../../pre-v3/utils/AgGrid.util"
import { DateUtil } from "../../../../pre-v3/utils/Date.util"
import { UrlUtil, encodeID } from "../../../../pre-v3/utils/Url.util"
import { ROUTE, formatRoutePath } from "../../../../routes"
import { Link } from "../../../components/link/Link.component"
import { SearchInput } from "../../../components/search-input/SearchInput.component"
import { Device, DeviceService } from "../../../services/Device.service"
import styles from "../ItpPolicy.module.scss"

export function ItpPolicyDevicesTab(): JSX.Element {
    const [deviceSearch, setDeviceSearch] = React.useState<string>(
        UrlUtil.getUrlParam("deviceSearch")
    )

    const columns = useColumns()
    const params = useParams<{ id: string }>()
    const localization = useServiceLocalization()
    const grid = useRef<GridApi>()
    const history = useHistory()
    const deviceService = new DeviceService()

    const { error: getDevicesError, mutateAsync: getDevices } = useMutation(
        ["get-devices"],
        (filter?: PaginatedSearch): Promise<Paginated<Device>> => {
            return deviceService.getDevices(filter)
        }
    )

    const onGridReady = (event: GridReadyEvent) => {
        grid.current = event.api
        onFilterChange(deviceSearch)
        event.api.setServerSideDatasource(DataSource(event.api, getDevices))
    }

    function onDeviceSearchChange(updatedSearch: string) {
        setDeviceSearch(updatedSearch)
        UrlUtil.setURlParams("deviceSearch", updatedSearch)
        onFilterChange(updatedSearch)
    }

    function onFilterChange(search: string) {
        grid.current?.setFilterModel({
            threatProfileId: { filter: params.id },
            deviceName: { filter: search },
        })
    }

    return (
        <React.Fragment>
            {getDevicesError ? (
                <ErrorBanner className={styles.errorBanner}>{String(getDevicesError)}</ErrorBanner>
            ) : (
                <>
                    <SearchInput
                        className={styles.deviceSearch}
                        value={deviceSearch}
                        onChangeValue={onDeviceSearchChange}
                        placeholder={localization.getString("searchByName")}
                    />
                    <Grid
                        className={styles.grid}
                        onGridReady={onGridReady}
                        serverSidePagination
                        columnDefs={columns}
                        context={{ history }}
                    />
                </>
            )}
        </React.Fragment>
    )
}

interface CellRendererProps {
    value?: string
    data: Device
}

function useColumns(): ColDef[] {
    const localization = useServiceLocalization()

    return useMemo((): ColDef[] => {
        return [
            {
                colId: "deviceName",
                field: "name",
                headerName: localization.getString("name"),
                cellRenderer: (props: CellRendererProps) => {
                    return (
                        <Link
                            to={formatRoutePath(ROUTE.DEVICES_DETAILS, {
                                serialNumber: encodeID(props.data.serialNumber),
                            })}
                            aria-label={props.data.name}
                        >
                            {props.data.name}
                        </Link>
                    )
                },
                filter: "agTextColumnFilter",
                sortable: true,
                comparator: AgGridUtil.alphaBetComparator,
            },
            {
                headerName: localization.getString("userEmails"),
                sortable: false,
                cellRenderer: (props: CellRendererProps) => {
                    const { userEmails = [] } = props.data || {}
                    const noOfUserEmail = userEmails.length

                    if (noOfUserEmail === 1) {
                        return (
                            <Link
                                to={formatRoutePath(ROUTE.USERS_DETAILS, {
                                    id: encodeID(userEmails[0]),
                                })}
                            >
                                {userEmails[0]}
                            </Link>
                        )
                    }
                    if (noOfUserEmail > 1) {
                        return localization.getString("noOfUsers", noOfUserEmail.toString())
                    }
                    return "--"
                },
                tooltipValueGetter: (props: Partial<CellRendererProps>) => {
                    const { userEmails = [] } = props.data || {}

                    return userEmails.length > 0 ? userEmails.join(", ") : "--"
                },
            },
            {
                colId: "lastLogin",
                field: "lastLoginAt",
                headerName: localization.getString("lastLogin"),
                valueFormatter: (props: CellRendererProps) =>
                    DateUtil.format(props.data.lastLoginAt),
                tooltipValueGetter: (props: Partial<CellRendererProps>) =>
                    DateUtil.format(props.data?.lastLoginAt),
                filter: "agTextColumnFilter",
                sortable: true,
            },
            { colId: "threatProfileId", hide: true, filter: "agTextColumnFilter" },
            { colId: "deviceName", hide: true, filter: "agTextColumnFilter" },
        ]
    }, [])
}

function DataSource(
    gridApi: GridApi,
    apiCallback: (filter?: PaginatedSearch) => Promise<Paginated<Device>>
): IServerSideDatasource {
    const getRows = (params: IServerSideGetRowsParams) => {
        const skip: number = params.request.startRow || 0
        const limit: number = (params.request.endRow || 0) - (params.request.startRow || 0)
        const filterModel: FilterModel = params.request.filterModel
        const sortModel: SortBy[] = params.request.sortModel

        gridApi.showLoadingOverlay()

        apiCallback({ skip, limit, filterModel, sortModel }).then(
            (res: Paginated<Device>) => {
                params.success({
                    rowData: res.data,
                    rowCount: res.total,
                })
                gridApi.hideOverlay()
                if (res.total === 0) {
                    gridApi.showNoRowsOverlay()
                }
            },
            () => {
                params.fail()
                gridApi.showNoRowsOverlay()
            }
        )
    }

    return { getRows }
}
