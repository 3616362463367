import { ApiResponse, BaseApi } from "./Base.api"
import { Singleton } from "../decorators/Singleton.decorator"

@Singleton("AppConfigApi")
export class AppConfigApi extends BaseApi {
    public getAppConfig(): Promise<ApiResponse<OrgAppConfigRes>> {
        return this.get("/api/v2/org/app_config")
    }

    public updateAppConfig({
        nrpt_config,
    }: OrgAppConfigReq): Promise<ApiResponse<OrgAppConfigRes>> {
        return this.put("/api/v2/org/app_config", { nrpt_config })
    }

    public createAppConfig({
        nrpt_config,
    }: OrgAppConfigReq): Promise<ApiResponse<OrgAppConfigRes>> {
        return this.post("/api/v2/org/app_config", { nrpt_config })
    }
}

export interface OrgAppConfigRes {
    nrpt_config: boolean
    id: string
    org_id: string
}

export interface OrgAppConfigReq {
    nrpt_config: boolean
}
