import React from "react"

import { useServiceLocalization } from "../../../../../../../pre-v3/services"
import { FormRow } from "../../../../../../components/form/FormRow.component"
import { Input } from "../../../../../../components/input/Input.component"

interface Props extends Omit<React.InputHTMLAttributes<HTMLInputElement>, "type" | "placeholder"> {}

export function NameInput(props: Props): JSX.Element {
    const localization = useServiceLocalization()

    return (
        <FormRow
            label={localization.getString("nameYourTrustProfile")}
            description={localization.getString(
                "weRecommendYouChooseANameThatRepresentsTheUsersAssignedToThisTrustProfile"
            )}
            htmlFor={props.id}
        >
            <Input
                {...props}
                type="text"
                required
                placeholder={localization.getString("enterTrustProfileName")}
            />
        </FormRow>
    )
}
