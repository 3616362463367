import React from "react"
import styles from "./Status.module.scss"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faSpinner } from "@fortawesome/pro-solid-svg-icons"
import { BaseStatus } from "./BaseStatus"

export function StatusLoading({ tooltip, text }: { tooltip?: string; text?: string }) {
    return (
        <BaseStatus
            text={text}
            tooltip={tooltip}
            icon={<FontAwesomeIcon spin icon={faSpinner} />}
            iconClass={styles.statusIconLoading}
        />
    )
}
