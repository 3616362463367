import React, { FC } from "react"
import { PolicySecure } from "../../../../../pre-v3/services/Secure.service"
import { useServiceLocalization } from "../../../../../pre-v3/services/localization/Localization.service"
import { ConfirmationDialog } from "../../../../../pre-v3/components/dialog/confirmation/ConfirmationDialog.component"
import { LoadMask } from "../../../../../pre-v3/components/load-mask/LoadMask.component"
import { Banner, BannerType } from "../../../../../pre-v3/components/banner/Banner.component"
import styles from "./PolicyEditDeleteDialog.module.scss"
import { faExclamationCircle } from "@fortawesome/pro-solid-svg-icons"
import { useDeletePolicy } from "../../../../services/Policy.service"

interface PolicyEditDeleteDialogProps {
    policy: PolicySecure
    open: boolean
    onClose: (success: boolean) => void
}

export const PolicyEditDeleteDialog: FC<PolicyEditDeleteDialogProps> = ({
    policy,
    open,
    onClose,
}) => {
    const localizationService = useServiceLocalization()
    const { mutateAsync: deletePolicy, isLoading: loading, error } = useDeletePolicy()

    const onConfirm = () => {
        if (!policy) {
            onClose(false)
            return
        }

        deletePolicy({ id: policy.id }).then(() => onClose(true))
    }

    return (
        <ConfirmationDialog
            open={open}
            onClose={onClose}
            onConfirm={onConfirm}
            title={localizationService.getString("deletePolicy")}
            confirmLabel={localizationService.getString("delete")}
        >
            {loading && <LoadMask />}
            {error && (
                <Banner
                    className={styles.errorBanner}
                    type={BannerType.ERROR}
                    icon={faExclamationCircle}
                >
                    {error.message}
                </Banner>
            )}
            {localizationService.getString("deletePolicyDescription")}
        </ConfirmationDialog>
    )
}
