import React from "react"
import styles from "./Toolbar.module.scss"
import { InputWithAction } from "../input-with-action/InputWithAction.component"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faSearch, faTimes } from "@fortawesome/pro-solid-svg-icons"
import classnames from "classnames/bind"

export default function () {
    return (
        <div className={classnames(styles.toolbar, this.props.className)}>
            <div className={styles.toolbarSearchContainer}>
                <InputWithAction
                    onChange={this.onSearchChange}
                    onAction={this.onSearchClear}
                    placeholder={
                        this.props.placeholder || this.localizationService.getString("search")
                    }
                    value={this.state.searchValue}
                >
                    <div className={styles.faContainer}>
                        <FontAwesomeIcon icon={this.state.searchValue ? faTimes : faSearch} />
                    </div>
                </InputWithAction>
            </div>
            <div className={styles.toolbarButtonsContainer}>{this.props.children}</div>
        </div>
    )
}
