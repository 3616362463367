import { BaseApi } from "./Base.api"

export class AccessTierFacingApi extends BaseApi {
    public getAccessTierAdvancedConfig(accessTierName: string): Promise<AdvancedConfigRes> {
        return this.get(`/api/v2/access_tier_facing/${accessTierName}/config`, {
            default: this.localization.getString("failedToFetchAdvancedConfiguration"),
        })
    }
}

export interface AdvancedConfigRes {
    logging?: {
        statsd?: boolean
        statsd_address?: string
    }
    events?: {
        access_event_credits_limiting?: boolean
        access_event_key_limiting?: boolean
    }
    hosted_web_services?: {
        forward_trust_cookie?: boolean
        disable_hsts?: boolean
    }
    service_discovery?: {
        service_discovery_enable: boolean
        service_discovery_msg_limit: number
        service_discovery_msg_timeout: number
    }
    miscellaneous?: {
        enable_ipv6_resolution: boolean
    }
    spec?: string
}
