import React from "react"

import { Button, ButtonElement, ButtonType } from "../../../components/button/Button.component"
import { useServiceLinks } from "../../../pre-v3/services/link/Link.service"
import { LanguageKey } from "../../../pre-v3/services/localization/languages/en-US.language"
import { useServiceLocalization } from "../../../pre-v3/services/localization/Localization.service"
import { useServiceModal } from "../../../pre-v3/services/Modal.service"
import { AppText } from "../../components/app-text/AppText.component"
import { ErrorBanner, InfoBanner, WarningBanner } from "../../components/banner/Banner.component"
import { UpgradeButton } from "../../components/button-link/UpgradeButton.component"
import { FormColumn } from "../../components/form/FormColumn.component"
import { TextWithCopy } from "../../components/text-with-copy/TextWithCopy.component"
import {
    AccountType,
    useGenerateRefreshToken,
    useRevokeRefreshToken,
} from "../../services/UserProfile.service"
import styles from "./RefreshToken.module.scss"
import { Edition } from "../../services/shared/Edition"

export interface RefreshTokenProps {
    accountType: AccountType
    edition?: Edition
    hasRefreshToken: boolean
    isRefreshTokenEnabledForSaml: boolean
    canRefreshToken?: boolean
}

export function RefreshToken(props: RefreshTokenProps): JSX.Element {
    const generateRefreshToken = useGenerateRefreshToken()
    const linkService = useServiceLinks()
    const localization = useServiceLocalization()
    const modalService = useServiceModal()
    const revokeRefreshToken = useRevokeRefreshToken({
        onSuccess: () => generateRefreshToken.reset(),
    })

    const onGenerateRefreshToken: React.MouseEventHandler = (event) => {
        event.preventDefault()
        generateRefreshToken.mutate()
    }

    const onRevokeRefreshToken: React.MouseEventHandler = (event) => {
        event.preventDefault()

        modalService
            .openConfirmation(
                localization.getString("warning"),
                localization.getString("revokeRefreshTokenExplanation")
            )
            .onClose(() => revokeRefreshToken.mutate())
    }

    const isSamlUserWithDisabledRefreshToken =
        props.accountType === AccountType.SAML && !props.isRefreshTokenEnabledForSaml

    return (
        <FormColumn
            label={localization.getString("refreshToken")}
            description={
                <AppText
                    ls={{
                        key: refreshTokenLabelDict[props.accountType],
                        replaceVals: [linkService.getLink("commandCenterApiGuide")],
                    }}
                />
            }
        >
            {props.hasRefreshToken ? (
                <React.Fragment>
                    <InfoBanner>
                        {localization.getString("refreshTokenPreviousExplanation")}
                    </InfoBanner>
                    {isSamlUserWithDisabledRefreshToken && (
                        <WarningBanner>
                            <AppText
                                ls="refreshTokenPreviousWarningForSamlUser"
                                className={styles.appText}
                            />
                        </WarningBanner>
                    )}
                </React.Fragment>
            ) : generateRefreshToken.data ? (
                <React.Fragment>
                    <TextWithCopy value={generateRefreshToken.data} />
                    <WarningBanner>
                        {localization.getString("refreshTokenOnceExplanation")}
                    </WarningBanner>
                </React.Fragment>
            ) : isSamlUserWithDisabledRefreshToken ? (
                <React.Fragment />
            ) : props.edition === "team" ? (
                props.canRefreshToken && <UpgradeButton className={styles.alignLeft} />
            ) : (
                props.canRefreshToken && (
                    <React.Fragment>
                        {generateRefreshToken.isError && (
                            <ErrorBanner>{String(generateRefreshToken.error)}</ErrorBanner>
                        )}
                        <Button
                            asElement={ButtonElement.BUTTON}
                            buttonType={ButtonType.SECONDARY}
                            onClick={onGenerateRefreshToken}
                            loading={generateRefreshToken.isLoading}
                            className={styles.alignLeft}
                        >
                            {localization.getString("generateRefreshToken")}
                        </Button>
                    </React.Fragment>
                )
            )}
            {props.canRefreshToken && (generateRefreshToken.isSuccess || props.hasRefreshToken) && (
                <React.Fragment>
                    {revokeRefreshToken.isError && (
                        <ErrorBanner>{String(revokeRefreshToken.error)}</ErrorBanner>
                    )}
                    <Button
                        onClick={onRevokeRefreshToken}
                        loading={revokeRefreshToken.isLoading}
                        asElement={ButtonElement.BUTTON}
                        buttonType={ButtonType.DESTRUCTIVE}
                        className={styles.alignRight}
                    >
                        {localization.getString("revokeRefreshToken")}
                    </Button>
                </React.Fragment>
            )}
        </FormColumn>
    )
}

const refreshTokenLabelDict: Record<AccountType, LanguageKey> = {
    [AccountType.LOCAL]: "refreshTokenDescriptionForLocalUser",
    [AccountType.SAML]: "refreshTokenDescriptionForSamlUser",
}
