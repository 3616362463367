import classNames from "classnames"
import React from "react"

import {
    ButtonElement,
    Button,
    ButtonType,
} from "../../../../../components/button/Button.component"
import { useServiceLocalization } from "../../../../../pre-v3/services"
import { FormUtil } from "../../../../../pre-v3/utils/Form.util"
import { SelectItem } from "../../../../../pre-v3/utils/SelectValue.util"
import { AppText } from "../../../../components/app-text/AppText.component"
import { ErrorBanners } from "../../../../components/banner/Banner.component"
import { Input } from "../../../../components/input/Input.component"
import { Form } from "../../../../components/form/Form.component"
import { FormButtons } from "../../../../components/form/form-buttons/FormButtons.component"
import { FormGroup } from "../../../../components/form/FormGroup.component"
import { FormRow } from "../../../../components/form/FormRow.component"
import { SelectWithInput } from "../../../../components/select-with-text-input/SelectWithInput.component"
import {
    NewRegistryCheck,
    RegistryCheckConfiguration,
} from "../../../../services/TrustFactor.service"
import {
    Platform,
    platformIconDict,
    platformLabelDict,
    validFilePathDict,
} from "../../../../services/shared/Platform"
import styles from "./RegistryCheckForm.module.scss"

interface Props<RegistryCheck extends NewRegistryCheck> {
    id?: string
    defaultValue?: RegistryCheck
    onSubmit?: (plist: NewRegistryCheck) => void
    cancelHref?: string
    submitting?: boolean
    hideActions?: boolean
    errors?: React.ReactNode[]
    canAccessRegistryCheckFactor?: boolean
}

export function RegistryCheckForm<RegistryCheck extends NewRegistryCheck>(
    props: Props<RegistryCheck>
): JSX.Element {
    const { hideActions, defaultValue, errors, cancelHref, submitting, id } = props
    const localization = useServiceLocalization()

    function onSubmit(event: React.FormEvent<HTMLFormElement>): void {
        event.preventDefault()
        event.stopPropagation()

        const name: string = FormUtil.getFieldValue(event, "name")

        props.onSubmit?.({
            name,
            configuration: getRegistryCheckConfigurationFromForm(event),
        })
    }

    function getRegistryCheckConfigurationFromForm(
        event: React.FormEvent<HTMLFormElement>
    ): RegistryCheckConfiguration {
        const prefix = FormUtil.getFieldValue(event, `${platform}-file-selection`)
        const path = FormUtil.getFieldValue(event, `${platform}-file-value`)
        const key = FormUtil.getFieldValue(event, `${platform}-key`)
        const value = FormUtil.getFieldValue(event, `${platform}-value`)

        return {
            keyPath: {
                prefix: prefix,
                path: path,
            },
            key,
            value,
        }
    }

    return (
        <Form id={id} onSubmit={onSubmit}>
            <FormRow
                label={localization.getString("factorName")}
                htmlFor="name"
                description={localization.getString("theFactorNameWillBeDisplayedToEndusers")}
            >
                <Input
                    id="name"
                    name="name"
                    defaultValue={defaultValue?.name}
                    placeholder={localization.getString("registryCheckNamePlaceholder")}
                    required
                    disabled={!props.canAccessRegistryCheckFactor}
                />
            </FormRow>
            <RegistryCheckConfigurationInput
                defaultValue={defaultValue?.configuration}
                canAccessRegistryCheckFactor={props.canAccessRegistryCheckFactor}
            />
            {props.canAccessRegistryCheckFactor && !hideActions && (
                <FormButtons
                    leftButtons={
                        cancelHref && (
                            <Button
                                asElement={ButtonElement.LINK}
                                to={cancelHref}
                                buttonType={ButtonType.SECONDARY}
                            >
                                {localization.getString("cancel")}
                            </Button>
                        )
                    }
                    rightButtons={
                        <Button
                            asElement={ButtonElement.BUTTON}
                            buttonType={ButtonType.PRIMARY}
                            loading={submitting}
                            type="submit"
                        >
                            {localization.getString("save")}
                        </Button>
                    }
                >
                    {errors && <ErrorBanners errors={errors} />}
                </FormButtons>
            )}
        </Form>
    )
}

interface RegistryCheckConfigurationInputProps {
    defaultValue?: RegistryCheckConfiguration
    canAccessRegistryCheckFactor?: boolean
}

const platform = Platform.WINDOWS

const options: SelectItem[] = [
    { displayName: "HKEY_LOCAL_MACHINE (HKLM)", value: "HKLM" },
    { displayName: "HKEY_CURRENT_USER (HKCU)", value: "HKCU" },
]

function RegistryCheckConfigurationInput(props: RegistryCheckConfigurationInputProps) {
    const { defaultValue } = props

    const localization = useServiceLocalization()

    const platformLabel = localization.getString(platformLabelDict[platform])
    const registryKeyPathLabel = localization.getString("registryKeyPath")

    return (
        <FormGroup label={platformLabel} icon={platformIconDict[platform]}>
            <FormRow
                label={registryKeyPathLabel}
                description={localization.getString("registryKeyPathDescription")}
                childrenClassName={styles.doubleFlex}
            >
                <SelectWithInput
                    options={options}
                    name={`${platform}-file`}
                    required
                    placeholder={localization.getString("registryKeyPathPlaceholder")}
                    pattern={validFilePathDict[platform].source}
                    defaultValue={{
                        value: defaultValue?.keyPath.path,
                        selection: defaultValue?.keyPath.prefix,
                    }}
                    disabled={!props.canAccessRegistryCheckFactor}
                />
            </FormRow>
            <FormRow
                label={localization.getString("registryKey")}
                description={localization.getString("registryKeyDescription")}
                childrenClassName={classNames(styles.doubleFlex, styles.keyValuePairContainer)}
            >
                <Input
                    name={`${platform}-key`}
                    type="text"
                    defaultValue={defaultValue?.key}
                    placeholder={localization.getString("registryKeyPlaceholder")}
                    required
                    disabled={!props.canAccessRegistryCheckFactor}
                />
                <AppText className={styles.operator} ls="equalTo" />
                <Input
                    name={`${platform}-value`}
                    type="text"
                    defaultValue={defaultValue?.value}
                    placeholder={localization.getString("registryKeyValuePlaceholder")}
                    required
                    disabled={!props.canAccessRegistryCheckFactor}
                />
            </FormRow>
        </FormGroup>
    )
}
