import React from "react"
import styles from "./LoadMask.module.scss"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faSpinner } from "@fortawesome/pro-solid-svg-icons"

export default function () {
    return (
        <div className={styles.loadmask}>
            <div className={styles.spinner} data-testid="loading">
                <FontAwesomeIcon icon={faSpinner} spin size="3x" />
                <h2>{this.props.label}</h2>
            </div>
        </div>
    )
}
