import React from "react"

import { Button, ButtonElement, ButtonType } from "../../../components/button/Button.component"
import { useServiceLocalization } from "../../../pre-v3/services/localization/Localization.service"
import { PatternUtil } from "../../../pre-v3/utils/Pattern.util"
import { Accordion } from "../../../v3/components/accordion/Accordion.component"
import { FormButtons } from "../../../v3/components/form/form-buttons/FormButtons.component"
import { Form } from "../../../v3/components/form/Form.component"
import { FormRow } from "../../../v3/components/form/FormRow.component"
import { Input } from "../../../v3/components/input/Input.component"
import { AccessTierDetails, useUpdateAccessTier } from "../../../v3/services/AccessTier.service"
import { Banner, Variant } from "../../../components/banner/Banner.component"
import { ErrorToast, ToastApi } from "../../../components/toast/Toast.components"
import { TestConnectionModal, ModalApi } from "../shared/TestConnectionModal.component"
import styles from "./AccessTierValidate.module.scss"
import { ROUTE, formatRoutePath } from "../../../routes"
import { encodeID } from "../../../pre-v3/utils/Url.util"

interface Props {
    accessTier: AccessTierDetails
    onBack(): void
    onChange(accessTier: AccessTierDetails): void
}

export function AccessTierValidateTab(props: Props): JSX.Element {
    const ls = useServiceLocalization()

    const errorToastRef = React.useRef<ToastApi>(null)
    const modalRef = React.useRef<ModalApi>(null)

    const { mutate: updateAccessTier, isLoading: isUpdatingAccessTier } = useUpdateAccessTier(
        props.accessTier,
        {
            onSuccess: () => modalRef.current?.open(),
            onError: (error) =>
                typeof error === "string"
                    ? errorToastRef.current?.openToast(error)
                    : console.error(error),
        }
    )

    const onSubmit: React.FormEventHandler = (e) => {
        e.preventDefault()
        updateAccessTier(props.accessTier)
    }

    const onBack: React.MouseEventHandler = (e) => {
        e.preventDefault()
        props.onBack()
    }

    const onPublicAddressChange = (publicAddress: string) =>
        props.onChange({ ...props.accessTier, publicAddress })

    const onDismissModal: React.MouseEventHandler = (e) => {
        e.preventDefault()
        modalRef.current?.dismiss()
    }

    return (
        <Form onSubmit={onSubmit}>
            <Accordion label={ls.getString("validateAndTest")} defaultOpen type="plain">
                <div className={styles.accordionContent}>
                    <Banner label={ls.getString("validateText")} variant={Variant.INFORMATION} />
                    <FormRow
                        label={ls.getString("publicAddressOfAccessTier")}
                        description={ls.getString("publicAddressOfAccessTierDesc")}
                    >
                        <Input
                            type="text"
                            placeholder={ls.getString("publicAddressOfAccessTierPlaceholder")}
                            value={props.accessTier.publicAddress ?? ""}
                            onChangeValue={onPublicAddressChange}
                            required
                            pattern={PatternUtil.NO_SPACE.source}
                            title={ls.getString("publicAddressShouldNotContainAnySpaces")}
                        />
                    </FormRow>
                </div>
            </Accordion>
            <FormButtons
                leftButtons={
                    <Button
                        buttonType={ButtonType.SECONDARY}
                        asElement={ButtonElement.BUTTON}
                        type="button"
                        onClick={onBack}
                    >
                        {ls.getString("back")}
                    </Button>
                }
                rightButtons={
                    <Button
                        buttonType={ButtonType.PRIMARY}
                        asElement={ButtonElement.BUTTON}
                        type="submit"
                        loading={isUpdatingAccessTier}
                    >
                        {ls.getString("testConnectionAndValidateAccessTier")}
                    </Button>
                }
            />
            <ErrorToast ref={errorToastRef} />
            <TestConnectionModal
                accessTier={props.accessTier}
                ref={modalRef}
                footer={
                    <Button
                        asElement={ButtonElement.LINK}
                        buttonType={ButtonType.PRIMARY}
                        to={formatRoutePath(ROUTE.ACCESS_TIERS_DETAILS, {
                            id: encodeID(props.accessTier.id),
                        })}
                    >
                        {ls.getString("done")}
                    </Button>
                }
                footerWhenError={
                    <React.Fragment>
                        <Button
                            asElement={ButtonElement.BUTTON}
                            buttonType={ButtonType.SECONDARY}
                            onClick={onDismissModal}
                        >
                            {ls.getString("goBackAndResolveIssues")}
                        </Button>
                        <Button
                            asElement={ButtonElement.LINK}
                            buttonType={ButtonType.PRIMARY}
                            to={formatRoutePath(ROUTE.ACCESS_TIERS_DETAILS, {
                                id: encodeID(props.accessTier.id),
                            })}
                        >
                            {ls.getString("proceedWithUnvalidatedAccessTier")}
                        </Button>
                    </React.Fragment>
                }
            />
        </Form>
    )
}
