import React from "react"

import { Button, ButtonElement, ButtonType } from "../../../../components/button/Button.component"
import { useServiceLocalization } from "../../../../pre-v3/services/localization/Localization.service"
import { ErrorBanner } from "../../../components/banner/Banner.component"
import { Form } from "../../../components/form/Form.component"
import { FormButtons } from "../../../components/form/form-buttons/FormButtons.component"
import { ServiceAccountForm } from "../shared/ServiceAccountForm.component"
import { ServiceAccount, useUpdateServiceAccount } from "../../../services/ServiceAccount.service"

interface Props {
    serviceAccount: ServiceAccount
    isEditing: boolean
    onStopEditing(): void
}

export function ServiceAccountOverviewTab(props: Props): JSX.Element {
    const localization = useServiceLocalization()

    const [serviceAccount, setServiceAccount] = React.useState(props.serviceAccount)

    const updateServiceAccount = useUpdateServiceAccount({
        onSuccess: (serviceAccount) => {
            props.onStopEditing()
            setServiceAccount(serviceAccount)
        },
    })

    const onChange = (updatedServiceAccount: Partial<ServiceAccount>) => {
        setServiceAccount({
            ...serviceAccount,
            ...updatedServiceAccount,
        })
    }

    const onCancel: React.MouseEventHandler = (event) => {
        event.preventDefault()
        props.onStopEditing()
        setServiceAccount(props.serviceAccount)
    }

    const onSubmit: React.FormEventHandler = (event) => {
        event.preventDefault()
        serviceAccount && updateServiceAccount.mutate(serviceAccount)
    }

    return (
        <Form onSubmit={onSubmit}>
            <ServiceAccountForm
                serviceAccount={serviceAccount}
                onChange={onChange}
                isEditing={props.isEditing}
                isDisabled={!props.isEditing}
                isOverview
            />
            {props.isEditing && (
                <React.Fragment>
                    {updateServiceAccount.isError && (
                        <ErrorBanner>{String(updateServiceAccount.error)}</ErrorBanner>
                    )}
                    <FormButtons
                        leftButtons={
                            <Button
                                asElement={ButtonElement.BUTTON}
                                buttonType={ButtonType.SECONDARY}
                                onClick={onCancel}
                            >
                                {localization.getString("cancel")}
                            </Button>
                        }
                        rightButtons={
                            <Button
                                type="submit"
                                asElement={ButtonElement.BUTTON}
                                buttonType={ButtonType.PRIMARY}
                                loading={updateServiceAccount.isLoading}
                            >
                                {localization.getString("saveChanges")}
                            </Button>
                        }
                    />
                </React.Fragment>
            )}
        </Form>
    )
}
