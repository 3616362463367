import React, { ReactNode, SyntheticEvent } from "react"
import OidcSettingsTemplate from "./OidcSettings.template"
import { LocalizationService } from "../../../services/localization/Localization.service"
import ActionBarService from "../../../services/ActionBar.service"
import { UserService } from "../../../services/User.service"
import { PasswordlessConfig, UserOrgDetails } from "../../../api/User.api"
import { SettingsApi } from "../../../api/Settings.api"
import {
    SettingsService,
    OidcConfig,
    ExternalServiceConfig,
} from "../../../services/Settings.service"
import { Bind } from "../../../decorators/Bind.decorator"
import { LinkService } from "../../../services/link/Link.service"
import { OrgEdition } from "../../../services"

interface Props {
    canCreatePasswordLessAuth?: boolean
}
export class OidcSettings extends React.Component<Props, OidcSettingsState> {
    public state: OidcSettingsState = {
        showDeleteDialog: false,
        loading: false,
        temporarilyShowOidcEndpoints: false,
        passwordlessRedirectUri: "",
        externalAppName: "",
        edition: OrgEdition.ENTERPRISE,
    }

    public render(): ReactNode {
        return OidcSettingsTemplate.call(this)
    }

    public componentDidMount(): void {
        this.actionBarService.setItems(this.localizationService.getString("oidcSettings"), [
            { label: this.localizationService.getString("enduserManagement") },
        ])

        this.userService.getEdition().then((edition) => this.setState({ edition }))

        this.getOidcConfig()
        this.getPasswordlessConfig()
        this.getExternalServiceConfig()
    }

    private getOidcConfig(): void {
        this.settingsService.getOidcSettings().then((oidcConfig: OidcConfig) => {
            this.setState({ oidcConfig })
        })
    }

    private getPasswordlessConfig(): void {
        this.userService.getUserOrgDetails(true).then((orgDetails: UserOrgDetails) => {
            const passwordlessConfig = this.userService.getPWLessConfig(orgDetails)

            this.setState({
                passwordlessConfig,
                appClientName: `Passwordless - ${orgDetails.OrgName}`,
                externalAppName: `External - ${orgDetails.OrgName}`,
                temporarilyShowOidcEndpoints: Boolean(
                    passwordlessConfig?.AuthorizationEndpoint?.endsWith("/auth-idp")
                ),
            })
        })
    }

    private getExternalServiceConfig(): void {
        this.settingsService.getExternalServiceConfig().then((config: ExternalServiceConfig) => {
            this.setState({
                externalClientId: config.clientId,
            })
        })
    }

    private localizationService: LocalizationService = new LocalizationService()
    private linkService: LinkService = new LinkService()
    private actionBarService: ActionBarService = new ActionBarService()
    private userService: UserService = new UserService()
    private settingsService: SettingsService = new SettingsService()
    private settingsApi: SettingsApi = new SettingsApi()

    @Bind
    private onRedirectUrlChange(event: SyntheticEvent): void {
        if (event && event.target) {
            const value: string = (<HTMLInputElement>event.target).value
            this.setState({ passwordlessRedirectUri: value })
        }
    }

    @Bind
    private enablePasswordless(): void {
        this.setState({ loading: true })
        this.settingsApi
            .insertPasswordlessConfig(this.state.passwordlessRedirectUri)
            .then(
                () => {
                    this.getPasswordlessConfig()
                },
                (err: Error) => {
                    this.setState({ error: err.message })
                }
            )
            .finally(() => {
                this.setState({ loading: false })
            })
    }

    @Bind
    private deletePasswordless(): void {
        this.settingsApi
            .deletePasswordlessConfig()
            .then(
                () => {
                    this.getPasswordlessConfig()
                },
                (err: Error) => {
                    this.setState({ error: err.message })
                }
            )
            .finally(() => {
                this.setState({ showDeleteDialog: false })
            })
    }

    @Bind
    private onShowDeleteDialog(): void {
        this.setState({ showDeleteDialog: true })
    }

    @Bind
    private onHideDeleteDialog(): void {
        this.setState({ showDeleteDialog: false })
    }
}

interface OidcSettingsState {
    error?: string
    loading: boolean
    temporarilyShowOidcEndpoints: boolean // Retain backwards compatibility for 2.14~2.18.
    passwordlessRedirectUri: string
    passwordlessConfig?: PasswordlessConfig
    oidcConfig?: OidcConfig
    showDeleteDialog: boolean
    appClientName?: string
    redirectUri?: string
    externalAppName?: string
    externalClientId?: string
    edition: OrgEdition
}
