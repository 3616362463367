import HighchartsReact from "highcharts-react-official"
import HighCharts from "highcharts"
import React from "react"
import { DateUtil } from "../../pre-v3/utils/Date.util"
import styles from "./SplineChart.module.scss"
import { useServiceLocalization } from "../../pre-v3/services"

interface OptionsInputs {
    title?: string
    subtitle: string
    xType?: SplineChartXType
    pointInterval: number
    pointStart: number
    data: number[]
    dataName: string
    lineColor?: string
    gradientColors?: [string, string]
}

export enum SplineChartXType {
    CATEGORY = "category",
    DATETIME = "datetime",
    LINEAR = "linear",
    LOGARITHMIC = "logarithmic",
    TREEGRID = "treegrid",
}

interface Props<T> extends Omit<OptionsInputs, "pointInterval" | "pointStart" | "data"> {
    data: T[]
    getValue(data: T): number
    getPointsInterval(data: T[]): number
    getPointStart(data: T[]): number
}

export function SplineChart<T>(props: Props<T>) {
    const { data, getValue, getPointsInterval, getPointStart, ...otherProps } = props
    const localization = useServiceLocalization()
    return (
        <HighchartsReact
            highcharts={HighCharts}
            options={getOptions(
                {
                    ...otherProps,
                    data: data.map(getValue),
                    pointInterval: getPointsInterval(data),
                    pointStart: getPointStart(data),
                },
                localization.getLocale()
            )}
            containerProps={containerProps}
        />
    )
}

function getOptions(inputs: OptionsInputs, language: string): HighCharts.Options {
    return {
        chart: {
            type: "areaspline",
        },
        time: {
            useUTC: false,
        },
        title: {
            text: inputs.title,
            align: "left",
            style: {
                fontFamily: "Libre Caslon Text",
                fontSize: "18px",
                fontWeight: "700",
            },
        },
        subtitle: {
            text: inputs.subtitle,
            align: "left",
            style: {
                fontFamily: "Red Hat Display",
                fontSize: "14px",
                fontWeight: "400",
            },
        },
        tooltip: {
            backgroundColor: "rgba(50, 59, 82, 0.8)",
            borderWidth: 0,
            borderRadius: 4,
            borderColor: "rgba(0, 0, 0, 0)",
            formatter: function () {
                const xValue =
                    inputs.xType === "datetime" && typeof this.x === "number"
                        ? DateUtil.formatToLocalDateTimeStr(this.x, language)
                        : this.x
                return `<b style="color:white">${this.y} ${inputs.dataName}</b>
                </br>
                <span style="color:white">${xValue}</span>`
            },
        },
        xAxis: {
            type: inputs.xType,
            labels: { overflow: "justify" },
            dateTimeLabelFormats:
                inputs.xType === "datetime"
                    ? {
                          hour: "%l%P",
                          day: "%d/%m",
                      }
                    : undefined,
            minorTicks: true,
            minorTickPosition: "outside",
            minorTickWidth: 1,
            minorGridLineWidth: 0,
            minorTickLength: 13,
            minorTickColor: "#D9D9D9",
            tickColor: "#5A6787",
            lineColor: "#5A6787",
        },
        yAxis: {
            minorGridLineWidth: 1,
            gridLineWidth: 1,
            title: {
                text: "",
            },
        },
        plotOptions: {
            areaspline: {
                lineWidth: 4,
                color: inputs.lineColor || "#FB6920",
                fillOpacity: 0.5,
                fillColor: {
                    linearGradient: { x1: 0, y1: 0, x2: 0, y2: 1 },
                    stops: [
                        [
                            0,
                            inputs.gradientColors
                                ? inputs.gradientColors[0]
                                : "rgba(251, 105, 32, 0.40)",
                        ],
                        [
                            1,
                            inputs.gradientColors
                                ? inputs.gradientColors[1]
                                : "rgba(251, 105, 32, 0)",
                        ],
                    ],
                },
                states: {
                    hover: {
                        lineWidth: 5,
                    },
                },
                marker: {
                    enabled: false,
                },
                pointInterval: inputs.pointInterval,
                pointStart: inputs.pointStart,
            },
        },
        legend: {
            enabled: false,
        },
        credits: {
            enabled: false,
        },
        series: [
            {
                name: inputs.dataName,
                data: inputs.data,
                type: "areaspline",
                pointInterval: inputs.pointInterval,
                pointStart: inputs.pointStart,
            },
        ],
    }
}

const containerProps: React.HTMLAttributes<HTMLDivElement> = { className: styles.container }
