import { faExclamation } from "@fortawesome/pro-solid-svg-icons"
import React, { FormEvent, ReactNode, useEffect, useState } from "react"
import { useHistory, useParams } from "react-router-dom"

import { LoadMask } from "../../../../../pre-v3/components/load-mask/LoadMask.component"
import { useServiceLocalization } from "../../../../../pre-v3/services"
import { decodeID } from "../../../../../pre-v3/utils/Url.util"
import { ROUTE, formatRoutePath } from "../../../../../routes"
import { ErrorBanners } from "../../../../components/banner/Banner.component"
import {
    ButtonElement,
    Button,
    ButtonType,
} from "../../../../../components/button/Button.component"
import { Container } from "../../../../components/container/Container.component"
import { Form } from "../../../../components/form/Form.component"
import { FormButtons } from "../../../../components/form/form-buttons/FormButtons.component"
import { FormRow } from "../../../../components/form/FormRow.component"
import { Input } from "../../../../components/input/Input.component"
import { LargeMessage } from "../../../../components/large-message/LargeMessage.component"
import { ToggleButton } from "../../../../components/toggle-button/ToggleButton"
import { useGetUserByEmail, useUpdateUser } from "../../../../services/User.service"
import styles from "./UserEdit.module.scss"
import { PageHeading } from "../../../../../components/page-heading/PageHeading.component"

export function UserEdit() {
    const localization = useServiceLocalization()
    const history = useHistory()
    const params: { id: string } = useParams()

    const [isAdminEnabled, setIsAdminEnabled] = useState<boolean>(false)

    const {
        data: userInfo,
        isFetching: isUserInfoLoading,
        error: userInfoError,
    } = useGetUserByEmail(decodeID(params.id))

    const isAdminUser: boolean = userInfo?.isAdminUser || false

    const {
        mutate: updateUser,
        error: updateUserError,
        isLoading: isUpdateUserLoading,
    } = useUpdateUser({
        onSuccess: () => {
            history.push(formatRoutePath(ROUTE.USERS_DETAILS, { id: params.id }))
        },
    })

    const isDataLoading = isUserInfoLoading || isUpdateUserLoading
    const errors: ReactNode[] = [updateUserError]

    function onSubmit(e: FormEvent) {
        e.preventDefault()

        if (!userInfo) {
            return
        }

        updateUser([userInfo.email, isAdminEnabled])
    }

    useEffect(() => {
        isAdminUser && setIsAdminEnabled(isAdminUser)
    }, [isAdminUser])

    return (
        <Container>
            <section aria-labelledby={Id.HEADING}>
                <header className={styles.header}>
                    <PageHeading id={Id.HEADING}>{localization.getString("users")}</PageHeading>
                </header>
                {isDataLoading && <LoadMask />}
                {!userInfo && !isDataLoading && (
                    <LargeMessage icon={faExclamation} className={styles.missingMessage}>
                        {userInfoError}
                    </LargeMessage>
                )}
                {userInfo && (
                    <Form onSubmit={onSubmit} className={styles.form}>
                        <FormRow label={localization.getString("email")}>
                            <Input className={styles.formInput} value={userInfo.email} disabled />
                        </FormRow>
                        <FormRow
                            label={localization.getString(
                                "shouldThisUserHaveAccessToSonicWallCseCommandCenter"
                            )}
                            description={localization.getString(
                                "usersThatAreAbleToAccessCommandCenterWillBePlacedInAllAdminsRole"
                            )}
                            childrenClassName={styles.flex}
                        >
                            <ToggleButton
                                items={[
                                    {
                                        label: localization.getString("yes"),
                                        onClick: () => {
                                            setIsAdminEnabled(true)
                                        },
                                        selected: isAdminEnabled,
                                    },
                                    {
                                        label: localization.getString("no"),
                                        onClick: () => {
                                            setIsAdminEnabled(false)
                                        },
                                        selected: !isAdminEnabled,
                                    },
                                ]}
                            />
                        </FormRow>
                        <FormButtons
                            rightButtons={
                                <>
                                    <Button
                                        asElement={ButtonElement.LINK}
                                        to={formatRoutePath(ROUTE.USERS_DETAILS, { id: params.id })}
                                        buttonType={ButtonType.SECONDARY}
                                    >
                                        {localization.getString("cancel")}
                                    </Button>
                                    <Button
                                        asElement={ButtonElement.BUTTON}
                                        buttonType={ButtonType.PRIMARY}
                                        type="submit"
                                        loading={isDataLoading}
                                    >
                                        {localization.getString("save")}
                                    </Button>
                                </>
                            }
                        >
                            <ErrorBanners errors={errors} />
                        </FormButtons>
                    </Form>
                )}
            </section>
        </Container>
    )
}

enum Id {
    HEADING = "heading",
}
