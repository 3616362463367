import classNames from "classnames/bind"
import React from "react"
import ReactMarkdown from "react-markdown"
import { ReactMarkdownProps } from "react-markdown/lib/complex-types"

import { LanguageKey } from "../../../pre-v3/services/localization/languages/en-US.language"
import { LocalizationService } from "../../../pre-v3/services/localization/Localization.service"
import { Badge } from "../badge/Badge.component"
import { Link } from "../link/Link.component"
import styles from "./AppText.module.scss"

const components = {
    a: ({ children, href }: any) => {
        const content: string = children[0] || ""
        return <Link to={href}>{content}</Link>
    },
    ol: OlMarkdownComponent,
}

export function AppText(props: AppTextProps) {
    const localization: LocalizationService = new LocalizationService()

    let content: string = ""
    if (props.ls) {
        if (typeof props.ls === "string") {
            content = localization.getString(props.ls as LanguageKey)
        } else {
            content = localization.getString(props.ls.key as LanguageKey, ...props.ls.replaceVals)
        }
    } else {
        content = props.children || ""
    }

    return (
        <ReactMarkdown
            className={classNames(styles.markdown, props.className)}
            components={components}
        >
            {content}
        </ReactMarkdown>
    )
}

export interface AppTextProps {
    children?: string
    ls?: LocalizationProps | LanguageKey
    className?: string
}

export interface LocalizationProps {
    key: LanguageKey
    replaceVals: string[]
}

function OlMarkdownComponent(props: ReactMarkdownProps): JSX.Element {
    return (
        <ol>
            {
                React.Children.toArray(props.children).reduce(
                    reduceOrderedList,
                    emptyReduceOrderedListResult
                ).children
            }
        </ol>
    )
}

interface ReduceOrderedListResult {
    liCount: number
    children: React.ReactNode[]
}

const emptyReduceOrderedListResult: ReduceOrderedListResult = {
    liCount: 0,
    children: [],
}

function reduceOrderedList(
    acc: ReduceOrderedListResult,
    child: React.ReactNode
): ReduceOrderedListResult {
    if (!React.isValidElement(child) || child.type !== "li")
        return { ...acc, children: [...acc.children, child] }

    const nextCount = acc.liCount + 1

    return {
        liCount: nextCount,
        children: [
            ...acc.children,
            <li className={styles.listItem} key={child.key}>
                <Badge warning number={nextCount} className={styles.badge} />
                <span>{child.props.children}</span>
            </li>,
        ],
    }
}
