import React, { ReactNode, SyntheticEvent } from "react"
import { Bind } from "../../../../decorators/Bind.decorator"
import { LocalizationService } from "../../../../services/localization/Localization.service"
import { FormUtil } from "../../../../utils/Form.util"
import ServicesAddKubernetesTemplate from "./ServicesAddKubernetes.template"

export class ServicesAddKubernetes extends React.Component<
    ServicesAddKubernetesProps,
    ServicesAddKubernetesState
> {
    public state: ServicesAddKubernetesState = {}

    public componentDidMount(): void {
        if (this.props.initialClusterName) {
            this.setState({ clusterName: this.props.initialClusterName })
        }
        if (this.props.initialCaKey) {
            this.setState({ caKey: this.props.initialCaKey })
        }
    }

    public render(): ReactNode {
        return ServicesAddKubernetesTemplate.call(this)
    }

    private ls: LocalizationService = new LocalizationService()

    @Bind
    private onClusterNameChange(event: SyntheticEvent): void {
        this.setState(
            {
                clusterName: FormUtil.getInputValue(event),
            },
            this.emit
        )
    }

    @Bind
    private onCaKeyChange(event: SyntheticEvent): void {
        this.setState(
            {
                caKey: FormUtil.getInputValue(event),
            },
            this.emit
        )
    }

    private emit(): void {
        if (this.props.onChange) {
            this.props.onChange(this.state.clusterName, this.state.caKey)
        }
    }
}

interface ServicesAddKubernetesProps {
    onChange?: (clusterName?: string, caKey?: string) => void
    initialClusterName: string
    initialCaKey: string
    disabled: boolean
}

interface ServicesAddKubernetesState {
    clusterName?: string
    caKey?: string
}
