import React, { Fragment } from "react"
import styles from "./ServicesInfo.module.scss"
import { LargeMessage } from "../../../components/large-message/LargeMessage.component"
import { ServicesAddAppType } from "./app-type/ServicesAddAppType.component"
import { ServicesAddDomain } from "./domain/ServicesAddDomain.component"
import { FormContext, FormLabel, Input, Select } from "../../../components"
import { ServicesAddBackend } from "./backend/ServicesAddBackend.component"
import { JsonTextArea } from "../../../components/json-text-area/JsonTextArea.component"
import { faExclamation } from "@fortawesome/pro-solid-svg-icons"
import { ServicesAddSshSettings } from "./ssh-settings/ServicesAddSshSettings.component"
import { ServicesAddBanyanproxySettings } from "./banyanproxy-settings/ServicesAddBanyanproxySettings.component"
import { ServiceAppType, ServiceType } from "../../../services/Manage.service"
import { ServicesAddKubernetes } from "./kubernetes/ServicesAddKubernetes.component"
import { ServicesAddIncludeDomains } from "./include-domains/ServicesAddIncludeDomains.component"
import { ServicesAddDnsOverrides } from "./dns-overrides/ServicesAddDnsOverrides.component"
import { AdvancedSummary } from "./AdvancedSummary"
import { Switch } from "../../../../v3/components/switch/Switch.component"
import { TextArea } from "../../../../v3/components/input/TextArea.component"

export default function () {
    const shouldConnectOnLogin = this.props.service.spec.metadata.autorun
    const connectOnLoginLabel = this.localizationService.getString("connectOnLogin")

    return (
        <FormContext.Provider value={{ slim: true }}>
            <div className={this.props.className}>
                {this.props.service && (
                    <Fragment>
                        {this.state.error ? (
                            <LargeMessage icon={faExclamation}>{this.state.error}</LargeMessage>
                        ) : (
                            <Fragment>
                                <div className={styles.formFields}>
                                    {this.state.showAppType && (
                                        <ServicesAddAppType
                                            className={styles.attribute}
                                            disabled
                                            initialValue={this.state.serviceAppType}
                                        />
                                    )}
                                    {this.state.showDomain && (
                                        <ServicesAddDomain
                                            className={styles.attributeContainer}
                                            disabled
                                            serviceType={this.state.serviceType}
                                            onChange={this.onDomainChange}
                                            initialValue={{
                                                name: this.state.domain,
                                                port: this.state.port,
                                            }}
                                            showLetsEncrypt={
                                                this.state.serviceAppType === ServiceAppType.WEB
                                            }
                                            letsEncrypt={this.state.letsEncrypt}
                                        />
                                    )}
                                    {this.state.serviceType &&
                                        this.state.serviceType !== ServiceType.CUSTOM && (
                                            <>
                                                {!this.state.connector ? (
                                                    <FormLabel
                                                        title={this.localizationService.getString(
                                                            this.state.accessTiersHeader
                                                        )}
                                                        htmlFor="netAgent"
                                                    >
                                                        <Select
                                                            value={this.state.accessTiers}
                                                            disabled
                                                            className={styles.hostInput}
                                                            multiple
                                                            options={this.state.accessTiers}
                                                        />
                                                    </FormLabel>
                                                ) : (
                                                    <FormLabel
                                                        title={this.localizationService.getString(
                                                            "connector"
                                                        )}
                                                    >
                                                        <Input
                                                            className={styles.hostInput}
                                                            disabled
                                                            value={this.state.connector}
                                                        />
                                                    </FormLabel>
                                                )}
                                            </>
                                        )}
                                    {this.state.showBackend && (
                                        <ServicesAddBackend
                                            showTls={this.state.showTls}
                                            showConnectToggle={this.state.showConnectToggle}
                                            showAllowPatterns={this.state.showBackendAllowPatterns}
                                            className={styles.sectionContainer}
                                            disabled
                                            onChange={this.onBackendChange}
                                            initialValue={this.state.backendAttr}
                                        />
                                    )}
                                    {this.state.showDnsOverride && (
                                        <ServicesAddDnsOverrides
                                            className={styles.sectionContainer}
                                            initialValue={this.state.dnsOverride}
                                            serviceAppType={this.state.serviceAppType}
                                            disabled
                                        />
                                    )}
                                    {this.state.showSshSettings && (
                                        <ServicesAddSshSettings
                                            className={styles.sectionContainer}
                                            disabled
                                            initialHost={this.state.sshHost}
                                            initialSshServiceType={this.state.sshServiceType}
                                            initialWriteConfig={this.state.sshWriteConfig}
                                        />
                                    )}
                                    {this.state.includeDomains?.length > 0 && (
                                        <ServicesAddIncludeDomains
                                            className={styles.sectionContainer}
                                            disabled
                                            initialValues={this.state.includeDomains}
                                        />
                                    )}
                                    {this.state.showBanyanproxySettings && (
                                        <ServicesAddBanyanproxySettings
                                            className={styles.sectionContainer}
                                            disabled
                                            initialPort={this.state.tcpListenPort}
                                            initialOverride={this.state.tcpOverride}
                                        />
                                    )}
                                    {this.state.showKubeSettings && (
                                        <ServicesAddKubernetes
                                            className={styles.sectionContainer}
                                            disabled
                                            initialClusterName={this.state.kubeClusterName}
                                            initialCaKey={this.state.kubeCaKey}
                                        />
                                    )}
                                    {this.state.serviceType &&
                                        this.state.serviceType !== ServiceType.CUSTOM && (
                                            <AdvancedSummary
                                                type={this.state.serviceType}
                                                cidrExemptions={this.state.cidrExemptions}
                                                corsPatterns={this.state.corsPatterns}
                                                pathExemptions={this.state.pathExemptions}
                                                customHeaders={this.state.customHeaders}
                                                userFacing={this.state.userFacing}
                                                suppressDTVFlag={this.state.suppressDTVFlag}
                                                disablePrivateDnsFlag={
                                                    this.state.disablePrivateDnsFlag
                                                }
                                                serviceAccount={this.state.serviceAccount}
                                            />
                                        )}
                                    {this.state.showRdpProps && (
                                        <FormLabel
                                            inline={false}
                                            title={this.localizationService.getString(
                                                "appendRdpFileProperties"
                                            )}
                                            htmlFor="appendRdpFileProperties"
                                        >
                                            <TextArea
                                                id="appendRdpFileProperties"
                                                className={styles.textArea}
                                                value={this.state.rdpProps}
                                                disabled
                                            />
                                        </FormLabel>
                                    )}
                                    {!this.state.showCustom && (
                                        <FormLabel
                                            inline={false}
                                            title={connectOnLoginLabel}
                                            description={this.localizationService.getString(
                                                "connectOnLoginInfrastructureServiceDescription"
                                            )}
                                        >
                                            <Switch
                                                className={styles.switch}
                                                value={shouldConnectOnLogin}
                                                aria-label={connectOnLoginLabel}
                                                disabled
                                            />
                                        </FormLabel>
                                    )}
                                </div>
                                {!this.state.showCustom && (
                                    <a
                                        className={styles.download}
                                        href={this.state.jsonBlob}
                                        download={this.props.service.name + ".json"}
                                    >
                                        {this.localizationService.getString("downloadServiceSpec")}
                                    </a>
                                )}
                                {this.state.showCustom && (
                                    <JsonTextArea
                                        initialValue={JSON.stringify(this.props.service.spec)}
                                        readOnly
                                        className={styles.serviceSpec}
                                    />
                                )}
                            </Fragment>
                        )}
                    </Fragment>
                )}
            </div>
        </FormContext.Provider>
    )
}
