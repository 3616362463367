import { faAngleDown } from "@fortawesome/pro-regular-svg-icons"
import { AccordionSummary } from "@mui/material"
import classNames from "classnames"
import React, { ReactNode } from "react"
import { Icon } from "../Icon/Icon.component"
import { InfoIcon } from "../info-icon/InfoIcon.component"
import { Switch } from "../switch/Switch.component"
import styles from "./Accordion.module.scss"
import { Tooltip } from "../tooltip/Tooltip.component"

export interface AccordionSummaryProps {
    label: ReactNode
    description?: ReactNode
    tooltipInfo?: string
    value?: boolean
    onChange?: (updatedValue: boolean) => void
    disabled?: boolean
    titleChildren?: ReactNode
    switchTooltipText?: string
}

export function AccordionSummaryWithExpand(props: AccordionSummaryProps) {
    const { label, value, onChange, description, tooltipInfo, disabled } = props

    return (
        <AccordionSummary
            className={classNames(styles.accordionSummary, styles.accordionSummaryWithExpand, {
                [styles.accordionSummaryDisabled]: disabled,
            })}
            onClick={() => {
                if (!disabled) {
                    onChange?.(!value)
                }
            }}
            classes={{
                expanded: styles.accordionSummaryExpanded,
                content: styles.accordionSummaryContent,
            }}
        >
            <Icon
                icon={faAngleDown}
                className={classNames(styles.expandIcon, { [styles.rotate]: value })}
            />
            <div className={styles.labelContainer}>
                <div className={styles.label}>
                    {label}
                    {tooltipInfo && <InfoIcon tooltip={tooltipInfo} />}
                </div>
                {description && <div className={styles.description}>{description}</div>}
            </div>
        </AccordionSummary>
    )
}

export function AccordionSummaryWithSwitch(props: AccordionSummaryProps) {
    const { label, value, onChange, description, tooltipInfo, disabled, switchTooltipText } = props

    return (
        <AccordionSummary
            className={classNames(styles.accordionSummary, {
                [styles.accordionSummaryDisabled]: disabled,
            })}
            onClick={() => {
                if (!disabled) {
                    onChange?.(!value)
                }
            }}
            classes={{
                content: styles.accordionSummaryContent,
                expanded: styles.accordionSummaryExpanded,
            }}
        >
            <div className={styles.labelContainer}>
                <div>
                    {label}
                    {tooltipInfo && <InfoIcon tooltip={tooltipInfo} />}
                </div>
                {description && <div className={styles.description}>{description}</div>}
            </div>
            {props.titleChildren && <div className={styles.children}>{props.titleChildren}</div>}
            <Tooltip title={switchTooltipText || ""}>
                <span>
                    <Switch
                        value={value}
                        disabled={disabled}
                        className={styles.accordionSummarySwitchWrapper}
                    />
                </span>
            </Tooltip>
        </AccordionSummary>
    )
}

export function AccordionSummaryPlain(props: Omit<AccordionSummaryProps, "value" | "onChange">) {
    const { label, description, tooltipInfo, disabled } = props

    return (
        <AccordionSummary
            className={classNames(styles.accordionSummary, styles.accordionSummaryPlain, {
                [styles.accordionSummaryDisabled]: disabled,
            })}
            classes={{
                expanded: styles.accordionSummaryExpanded,
                content: styles.accordionSummaryContent,
            }}
        >
            <div className={styles.labelContainer}>
                {label}
                {description && <div className={styles.description}>{description}</div>}
            </div>
            {tooltipInfo && <InfoIcon tooltip={tooltipInfo} />}
            {props.titleChildren && <div className={styles.children}>{props.titleChildren}</div>}
        </AccordionSummary>
    )
}
