import React, { FormEvent, useEffect, useState } from "react"
import { useHistory, useLocation } from "react-router-dom"

import { useAuthService } from "../../../../pre-v3/services/Auth.service"
import { useServiceLocalization } from "../../../../pre-v3/services/localization/Localization.service"
import { FormUtil } from "../../../../pre-v3/utils/Form.util"
import { ROUTE } from "../../../../routes"
import { ErrorBanner } from "../../../components/banner/Banner.component"
import { Button } from "../../../components/button/Button.component"
import { useValidateMfa } from "../../../services/Login.service"
import styles from "../Login.module.scss"

export function MfaForm() {
    const location = useLocation<{ token: string }>()
    const history = useHistory()
    const authService = useAuthService()
    const ls = useServiceLocalization()

    const [error, setError] = useState<boolean>(false)
    const [token, setToken] = useState<string>("")

    useEffect(() => {
        if (!location.state?.token) {
            setError(true)
        } else {
            setToken(location.state.token)
        }
    }, [location])

    const validateMfa = useValidateMfa()

    function goBack(): void {
        if (history.length > 1) {
            history.goBack()
        } else {
            history.push(ROUTE.LOGIN)
        }
    }

    function onSubmit(event: FormEvent<HTMLFormElement>): void {
        event.preventDefault()

        const otp: string = FormUtil.getFieldValue(event, "otp")

        validateMfa.mutateAsync({ otp, token }).then((token: string) => {
            authService.setLogin(token)
            history.push(authService.getLoginUrl())
        })
    }

    if (error) {
        return (
            <div className={styles.form}>
                <p className={styles["space-below"]}>{ls.getString("mfaStateError")}</p>
                <Button brand onClick={goBack}>
                    {ls.getString("goBack")}
                </Button>
            </div>
        )
    }

    return (
        <form className={styles.form} onSubmit={onSubmit}>
            <p className={styles.textAlignCenter}>{ls.getString("oneTimePasscode")}</p>
            <input
                name="otp"
                className={styles["space-below"]}
                type="text"
                required
                autoFocus
                placeholder={ls.getString("enterOneTimePasscode")}
            />
            {validateMfa.error && (
                <ErrorBanner className={styles["space-below"]}>{validateMfa.error}</ErrorBanner>
            )}
            <Button
                className={styles.submitButton}
                brand
                loading={validateMfa.isLoading}
                type="submit"
            >
                {ls.getString("continue")}
            </Button>
        </form>
    )
}
