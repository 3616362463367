import { Popover } from "@mui/material"
import React, { createContext, ReactNode, useCallback, useEffect, useState } from "react"

export function MenuPopover(props: MenuPopoverProps) {
    const [showPopover, setShowPopover] = useState<boolean>(false)

    const onClose = useCallback(() => {
        setShowPopover(false)
    }, [])

    const onToggle = useCallback(() => {
        setShowPopover((prev) => !prev)
    }, [])

    useEffect(() => {
        if (props.anchorEl) {
            props.anchorEl.addEventListener("click", onToggle)
        }
        return () => {
            if (props.anchorEl) {
                props.anchorEl.removeEventListener("click", onToggle)
            }
        }
    }, [props.anchorEl])

    return (
        <Popover
            classes={{
                paper: props.className,
            }}
            anchorEl={props.anchorEl}
            open={showPopover}
            onClose={onClose}
            anchorOrigin={{
                vertical: "bottom",
                horizontal: "left",
            }}
            transformOrigin={{
                vertical: "top",
                horizontal: "left",
            }}
        >
            <MenuPopoverContent.Provider value={{ close: onClose }}>
                {props.children}
            </MenuPopoverContent.Provider>
        </Popover>
    )
}

interface MenuPopoverProps {
    /**
     * Make sure to use "useCallbackRef", the normal "useRef" will not trigger
     * the render cycle for this component
     */
    anchorEl?: HTMLElement
    children: ReactNode
    className?: string
}

export const MenuPopoverContent = createContext({
    close: (): void => {},
})
