import { BaseApi } from "./Base.api"
import { Singleton } from "../decorators/Singleton.decorator"
import { EventProps } from "./EventProps.types"

@Singleton("EventsApi")
export class EventsApi extends BaseApi {
    public getEvents(props: EventProps): Promise<EventRes> {
        const params: URLSearchParams = new URLSearchParams()
        for (const key in props) {
            params.append(key, (<any>props)[key])
        }
        return this.get("/api/v1/events?" + params.toString())
    }

    public getEventsCount(props: EventProps): Promise<EventCountRes> {
        const params: URLSearchParams = new URLSearchParams()
        for (const key in props) {
            params.append(key, (<any>props)[key])
        }
        return this.get<EventCountRes>("/api/v1/events/count?" + params.toString())
    }
}

export interface EventCountDetailsRes {
    start: string
    width_mins: number
    num_total_events: number
}

export interface EventCountRes {
    data: number
    details: EventCountDetailsRes[]
}

export interface EventRes {
    data: EventItemRes[]
}

export interface EventItemRes {
    action: string
    created_at: number
    group_id: string
    id: string
    device_id: string
    external_id: string
    message: string
    org_id: string
    org_name: string
    result: string
    severity: string
    service: EventItemService
    sub_type: string
    trustscore: EventTrustScoreRes
    type: string
    user_principal: EventPrincipalRes
}

export interface EventItemService {
    id: string
    name: string
    type?: string
    version?: string
}

interface EventTrustScoreRes {
    id: string
    score: number
    timestamp: number
    type: string
}

interface EventPrincipalRes {
    device: EventDeviceRes
    user: EventUserRes
}

interface EventDeviceRes {
    architecture: string
    compliance_status: string
    compromised_status: string
    friendly_name: string
    id: string
    last_mdm_data_synced_at: number
    mac_address: string
    model: string
    oem_info: string
    ownership: string
    platform: string
    registration_status: string
    serial_number: string
    source: string
    udid: string
}

interface EventUserRes {
    email: string
    groups: string[]
    roles: string[]
}
