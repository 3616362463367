export class FileUtil {
    public static downloadJson(props: downloadJsonProps) {
        let value = ""

        try {
            value = JSON.parse(props.jsonValue)
        } catch {
            console.error("Erro: In downloadJson, Provided value is not a json")
            return
        }

        // download the blob with a ghost anchor
        const a = document.createElement("a")
        document.body.appendChild(a)
        const url = URL.createObjectURL(
            new Blob([JSON.stringify(value, null, 4) + "\n"], { type: "application/json" })
        )
        a.href = url
        a.download = `${props.fileName}.json`
        a.click()
        setTimeout(() => {
            window.URL.revokeObjectURL(url)
            document.body.removeChild(a)
        }, 0)
    }
}

interface downloadJsonProps {
    jsonValue: string
    fileName: string
}
