import { BaseV2Api } from "./BaseV2.api"
import { Singleton } from "../decorators/Singleton.decorator"
import { DeviceSearch, TrustLevel } from "./Entity.api"

@Singleton("EntityV2Api")
export default class EntityV2Api extends BaseV2Api {
    public getDevices(search: DeviceSearch): Promise<DeviceResV2> {
        const urlParams: URLSearchParams = new URLSearchParams(search as any)
        return this.get("/devices?" + urlParams.toString())
    }

    public deleteUnregisteredDevices(payload: { enduser_device_ids: string[] }): Promise<void> {
        return this.delete("/unregistered_device/endusers", payload)
    }
}

interface DeviceResV2 {
    count: number
    devices: DeviceV2[]
}

interface DeviceV2 {
    id: string
    serial_number: string
    name: string
    model: string
    ownership: string
    platform: string
    architechture?: string
    source: string
    banned: boolean
    os: string
    mdm_present: boolean
    mdm_vendor_name: string
    mdm_compliant: string
    app_version: string
    created_at: number
    last_login: number
    trust_profile_id: string
    extra_details: {
        trust: Trust
        trustscore: TrustDataV2
        role_names: string[]
        emails: string[]
        number_of_users: number
    }
}

interface Trust {
    value: number
    level: TrustLevel
    status: DeviceStatus
    last_evaluated: LastEvaluatedTrustData
    expired_at: number
    factors: TrustFactorV2[]
}

interface LastEvaluatedTrustData {
    last_evaluated_at: number
    last_evaluated_value: number
    last_evaluated_level: TrustLevel
    last_evaluated_factors: TrustFactorV2[]
}

interface TrustDataV2 {
    entity_trustscore: number
    override_trustscore: number
    access_trustscore: number
    override_active: boolean
    trust_level: TrustLevel
    updated_at: number
}

interface TrustFactorV2 {
    name: string
    value: string
    type: "internal" | "external"
    source: string
    description: string
    remediation: {
        description: string
        url: string
    }
}

export enum DeviceStatus {
    EXPIRED = "Expired",
    OVERRIDDEN = "Overridden",
    REPORTING = "Reporting",
    PENDING = "Pending",
}
