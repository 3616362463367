import React from "react"
import { useServiceLinks, useServiceLocalization } from "../../../../../pre-v3/services"
import { AppText } from "../../../../components/app-text/AppText.component"
import { CodeWithCopy } from "../../../../components/code-with-copy/CodeWithCopy"

import styles from "../AccessTiersCreate.module.scss"

export function CloudFormationInstaller({
    apiSecretKey,
    accessTierName,
    hideDoc,
    canAccessAccessTierInstallStep,
}: Props) {
    const ls = useServiceLocalization()
    const links = useServiceLinks()

    return (
        <>
            <div className={styles.row}>
                <label className={styles.label}>{ls.getString("accessTierName")}</label>
                <CodeWithCopy
                    className={styles.textBox}
                    title={ls.getString("stackName")}
                    text={accessTierName || ""}
                    hideCopyButton={!canAccessAccessTierInstallStep}
                />
            </div>
            <br />
            <div className={styles.row}>
                <label className={styles.label}>{ls.getString("apiKey")}</label>
                <CodeWithCopy
                    className={styles.textBox}
                    title={ls.getString("apiKeySecret")}
                    text={apiSecretKey || ""}
                    hideCopyButton={!canAccessAccessTierInstallStep}
                />
            </div>
            {!hideDoc && (
                <AppText
                    className={styles.info}
                    ls={{
                        key: "pleaseCheckDocumentForPreReqAndMoreDetails",
                        replaceVals: [links.getLink("cloudFormationInstallDoc")],
                    }}
                />
            )}
        </>
    )
}

interface Props {
    apiSecretKey?: string
    accessTierName?: string
    hideDoc?: boolean
    canAccessAccessTierInstallStep?: boolean
}
