import React from "react"

import { Profile } from "../../utils/profile.utils"
import { AccountType } from "../../v3/services/UserProfile.service"
import { Singleton } from "../decorators/Singleton.decorator"
import {
    UserOrgDetails,
    UserApi,
    Admin,
    PasswordlessConfig,
    IDPConfig,
    CloakExceptions,
    OrgDetails,
    InviteCodeConfig,
} from "../api/User.api"
import ServiceContext from "./context"

@Singleton("UserService")
export class UserService {
    public getUserOrgDetails(force?: boolean): Promise<UserOrgDetails> {
        if (this.userOrgDetails && !force) {
            return Promise.resolve(this.userOrgDetails)
        }

        const promise: Promise<UserOrgDetails> = this.userApi.getUserOrgDetails()
        promise.then((userOrgDetails: UserOrgDetails) => {
            this.userOrgDetails = userOrgDetails
            let firstInitial: string = userOrgDetails.First[0] || ""
            let lastInitial: string = userOrgDetails.Last[0] || ""
            if (firstInitial.length === 0 && lastInitial.length === 0) {
                firstInitial = "B"
                lastInitial = "N"
            }
            this.userOrgDetails.UserInitials = firstInitial + lastInitial
        })
        return promise
    }

    public getOrgDetails(force?: boolean): Promise<OrgDetails> {
        if (this.orgDetails && !force) {
            return Promise.resolve(this.orgDetails)
        } else {
            const promise: Promise<OrgDetails> = this.userApi.getOrgDetails()
            promise.then((orgDetails: OrgDetails) => {
                this.orgDetails = orgDetails
            })
            return promise
        }
    }

    public async getEdition(): Promise<OrgEdition> {
        // look up the org details
        const details = await this.getOrgDetails()

        return details.Edition
    }

    public getUserOrgUnregisteredDevicesEnabled(): Promise<boolean> {
        let promise: Promise<boolean> = this.getUserOrgDetails().then(
            (orgDetails: UserOrgDetails) => {
                try {
                    const cloakExceptions: CloakExceptions = JSON.parse(orgDetails.CloakExceptions)
                    return cloakExceptions.token.whitelist_cidrs.length > 0
                } catch {
                    return false
                }
            }
        )
        return promise
    }

    public getAdmins(): Promise<AdminUser[]> {
        return this.userApi.getAdmins().then((admins) => admins.map(this.mapAdminToAdminUser))
    }

    public getAdmin(email: String): Promise<AdminUser> {
        return this.userApi.getAdmins().then((admins: Admin[]) => {
            const admin: Admin | undefined = admins.find((a) => a.Email === email)
            if (admin) {
                return this.mapAdminToAdminUser(admin)
            } else {
                throw new Error("Admin not found.")
            }
        })
    }

    public getEndUsersCSV(): Promise<Blob> {
        return this.userApi.getEndUsersCSV()
    }

    public deleteUserMetaData(keys: string[]): Promise<UserMetaData> {
        return this.userApi.deleteUserMetaData(keys).then((response) => {
            return response.data as UserMetaData
        })
    }

    public getUserMetaData(): Promise<UserMetaData> {
        return this.userApi
            .getUserMetaData()
            .then((response) => {
                return response.data as UserMetaData
            })
            .catch(() => {
                return {}
            })
    }

    public setUserMetadata(data: { [key: string]: any }): Promise<void> {
        return this.userApi.insertUserState(data)
    }

    public setOnboardingState(userState: OnboardingState): Promise<void> {
        return this.userApi.insertUserState({
            state: userState,
        })
    }

    private isProfileAdmin(profile: string): boolean {
        return (
            profile === Profile.SUPER_ADMIN ||
            profile === Profile.ADMIN ||
            profile === Profile.OWNER
        )
    }

    public canCreateServices(profile: string): boolean {
        return this.isProfileAdmin(profile) || profile === Profile.SERVICE_AUTHOR
    }

    public canCreatePolicies(profile: string): boolean {
        return this.isProfileAdmin(profile) || profile === Profile.POLICY_AUTHOR
    }

    public getInviteCode(): Promise<InviteCodeConfig> {
        return this.userApi.getInviteCode()
    }

    public getIDPConfig(orgDetails: UserOrgDetails): IDPConfig | undefined {
        try {
            return JSON.parse(orgDetails.IDPConfig)
        } catch {
            return undefined
        }
    }

    public getPWLessConfig(orgDetails: UserOrgDetails): PasswordlessConfig | undefined {
        try {
            return JSON.parse(orgDetails.PWLessConfig)
        } catch {
            try {
                const idpConfig: IDPConfig = JSON.parse(orgDetails.IDPConfig)
                return <PasswordlessConfig>idpConfig.PWLessConfig
            } catch {
                return undefined
            }
        }
    }

    public setUserInactivityInterval(days: string): Promise<void> {
        return this.userApi.setUserInactivityInterval(days).then()
    }

    public updateAuthAsyncState(state: boolean): Promise<void> {
        return this.userApi.updateAuthAsyncState(state).then()
    }

    public async getIsBanyanIdp(): Promise<boolean> {
        const orgDetails = await this.getOrgDetails()

        return orgDetails.IDPName === "BANYAN"
    }

    private userOrgDetails: UserOrgDetails
    private orgDetails: OrgDetails
    private userApi: UserApi = new UserApi()

    private mapAdminToAdminUser(a: Admin): AdminUser {
        return {
            name: a.First + " " + a.Last,
            email: a.Email,
            registered: a.Registered ? a.Registered.toUpperCase() === "TRUE" : false,
            profile: <Profile>a.Profile,
            accountType: a.AccountType,
        }
    }
}

export interface UserMetaData {
    onboardingServiceType?: string
    onboardingStep?: number
    onboardingConnectorIP?: string
    onboardingService?: string
    onboardingFinished?: boolean
    state?: OnboardingState
}

export interface AdminUser {
    name: string
    email: string
    registered: boolean
    profile: Profile
    accountType?: AccountType
}

export enum OrgEdition {
    TEAM = "Team",
    ENTERPRISE = "Enterprise",
}

export enum OnboardingState {
    ONBOARDING_SETUP_INITIATED = "setup-initiated",
    CONNECTOR_INSTALLED = "connector-installed",
    DEVICE_REGISTERED = "device-registered",
    SERVICE_REGISTERED = "service-registered",
    MEMBERS_INVITED = "members-invited",
    ONBOARDING_SETUP_COMPLETE = "setup-complete",
}

export const useServiceUser = () => React.useContext(ServiceContext)?.user || new UserService()

export const useEdition = () => {
    const service = useServiceUser()

    const [edition, setEdition] = React.useState<OrgEdition>(OrgEdition.ENTERPRISE)

    React.useEffect(() => {
        service.getEdition().then(setEdition)
    }, [service])

    return edition
}
