import { Breakpoint, useTheme } from "@mui/material"

import { useWindowSize } from "./UseWindowSize.hook"

export function useIsBreakpointUp(breakpoint: Breakpoint): boolean {
    const theme = useTheme()
    const breakpointValue = theme.breakpoints.values[breakpoint]

    const { width } = useWindowSize()

    return width >= breakpointValue
}
