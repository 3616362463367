import React, { ReactNode } from "react"

import { AppNavLink } from "../../services/shared/AppNavLink"
import {
    MenuItemDividerTemplate,
    MenuItemButtonTemplate,
    MenuItemLinkTemplate,
    MenuItemExternalLinkTemplate,
} from "./MenuItem.template"

export class MenuItem extends React.Component<MenuItemProps, MenuItemState> {
    public static getDerivedStateFromProps(props: MenuItemProps): MenuItemState {
        let type: MenuItemType = MenuItemType.BUTTON
        if (props.divider) {
            type = MenuItemType.DIVIDER
        } else if (props.href && props.href.length > 0) {
            if (props.href.toLowerCase().startsWith("http")) {
                type = MenuItemType.EXTERNAL_LINK
            } else {
                type = MenuItemType.LINK
            }
        }
        return { type: type }
    }

    public state: MenuItemState = { type: MenuItemType.LINK }

    public render(): ReactNode {
        switch (this.state.type) {
            case MenuItemType.DIVIDER:
                return MenuItemDividerTemplate.call(this)
            case MenuItemType.BUTTON:
                return MenuItemButtonTemplate.call(this)
            case MenuItemType.EXTERNAL_LINK:
                return MenuItemExternalLinkTemplate.call(this)
            case MenuItemType.LINK:
            default:
                return MenuItemLinkTemplate.call(this)
        }
    }
}

interface MenuItemProps extends AppNavLink {
    children?: any
}

interface MenuItemState {
    type: MenuItemType
}

enum MenuItemType {
    LINK,
    EXTERNAL_LINK,
    BUTTON,
    DIVIDER,
}
