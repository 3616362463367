import { UseQueryResult, useMutation, useQuery, useQueryClient } from "@tanstack/react-query"
import { TrustedNetworkConfigRes, TrustedNetworksApi } from "../api/TrustedNetworks.api"
import { PatternUtil } from "../../pre-v3/utils/Pattern.util"

enum TrustedNetworkHookKey {
    GET_TRUSTED_NETWORK = "trustedNetworkService.getTrustedNetworkConfig",
    GET_NRPT_CONFIG = "orgService.getNrptConfig",
}

export function useGetTrustedNetwork(
    options?: QueryOptions<TrustedNetwork, string>
): UseQueryResult<TrustedNetwork, string> {
    const trustedNetworkApi = new TrustedNetworksApi()

    return useQuery({
        ...options,
        queryKey: [TrustedNetworkHookKey.GET_TRUSTED_NETWORK],
        queryFn: async () => {
            const response = await trustedNetworkApi.getTrustedNetworksConfig()
            return mapTrustedNetwork(response)
        },
        cacheTime: 0,
    })
}

export function useConfigureTrustedNetwork(options?: QueryOptions<void, string, TrustedNetwork>) {
    const trustedNetworkApi = new TrustedNetworksApi()
    const client = useQueryClient()
    return useMutation<void, string, TrustedNetwork>({
        ...options,
        mutationFn: (data: TrustedNetwork): Promise<void> => {
            return trustedNetworkApi.configureTrustedNetworkConfig({
                mac_addresses: data.macAddresses,
                disable_service_tunnel: data.serviceTunnelStatus,
            })
        },
        onSuccess: () => {
            client.removeQueries([TrustedNetworkHookKey.GET_TRUSTED_NETWORK])
            options?.onSuccess?.()
        },
    })
}

export function useUpdateTrustedNetwork(options?: QueryOptions<void, string, TrustedNetwork>) {
    const trustedNetworkApi = new TrustedNetworksApi()
    const client = useQueryClient()
    return useMutation<void, string, TrustedNetwork>({
        ...options,
        mutationFn: (data: TrustedNetwork): Promise<void> => {
            return trustedNetworkApi.updateTrustedNetworkConfig({
                mac_addresses: data.macAddresses,
                disable_service_tunnel: data.serviceTunnelStatus,
            })
        },
        onSuccess: () => {
            client.removeQueries([TrustedNetworkHookKey.GET_TRUSTED_NETWORK])
            options?.onSuccess?.()
        },
    })
}

function mapTrustedNetwork(data: TrustedNetworkConfigRes): TrustedNetwork {
    return {
        macAddresses: data.mac_addresses,
        serviceTunnelStatus: data.disable_service_tunnel,
    }
}

export function getSanitizedMacAddressList(address: string) {
    if (!address) return []
    const updatedAddress = address.replace(PatternUtil.NUMBER_LETTER, "0$1")
    const macAddresses = updatedAddress
        .replace(/\n/g, ",")
        .split(",")
        .map((item) => {
            return item.trim()
        })
        .filter((address) => address)
    return macAddresses
}
export interface TrustedNetwork {
    macAddresses: string[]
    serviceTunnelStatus: boolean
}
