import React from "react"

import { ROUTE } from "../../../../routes"
import { useServiceLocalization } from "../../../services/localization/Localization.service"
import { useServiceStats } from "../../../services/Stats.service"
import { CountWidget } from "../Widget"

export function RoleCount() {
    const localization = useServiceLocalization()
    const stats = useServiceStats()

    const [count, setCount] = React.useState<number>()

    React.useEffect(() => {
        stats.getRolesStatsDisplayData().then((data) => {
            setCount(data.total)
        })
    }, [stats])

    return (
        <CountWidget
            gridArea="role-count"
            title={localization.getString("roles")}
            count={count}
            link={ROUTE.ROLES}
        />
    )
}
