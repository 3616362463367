import React, { useMemo } from "react"
import {
    Redirect,
    Route,
    Switch,
    matchPath,
    useHistory,
    useLocation,
    useParams,
} from "react-router-dom"

import { useFeatureFlags } from "../../../../../hooks/useFeatureFlags.hook"
import { useServiceLocalization } from "../../../../../pre-v3/services/localization/Localization.service"
import { IconType } from "../../../../../pre-v3/services/ActionBar.service"
import { useServiceModal } from "../../../../../pre-v3/services/Modal.service"
import { DateUtil } from "../../../../../pre-v3/utils/Date.util"
import { decodeID, encodeID } from "../../../../../pre-v3/utils/Url.util"
import { ROUTE, formatRoutePath } from "../../../../../routes"
import { toLocalizedList } from "../../../../../utils/String.utils"
import { Container } from "../../../../components/container/Container.component"
import { KeyValuePairItems } from "../../../../components/overview/OverviewTopContainer/OverviewTopContainer.component"
import { ErrorBanner, ErrorBanners } from "../../../../components/banner/Banner.component"
import { UserActivity } from "./UserActivity.component"
import { UserDeviceList } from "./UserDeviceList.component"
import { UserRolesList } from "./UserRolesList.component"
import { UserLicenseInfoList } from "./UserLicenseList.component"

import { Loader } from "../../../../components/loader/Loader.component"
import {
    Role,
    labelMap,
    licenseStatusMap,
    statusMap,
    useDeleteUsers,
    useGetUserByEmail,
} from "../../../../services/User.service"
import styles from "./UserOverview.module.scss"
import { PageHeading } from "../../../../../components/page-heading/PageHeading.component"
import {
    Button,
    ButtonElement,
    ButtonType,
} from "../../../../../components/button/Button.component"
import { Tooltip } from "../../../../components/tooltip/Tooltip.component"
import {
    UserLicenseInformation,
    LicenseStatus,
    useGetUserLicenseUsageByEmail,
    LicenseType,
} from "../../../../services/UserLicenseUsage.service"
import { OverviewStatus } from "../../../../../components/overview/OverviewTopContainer/OverviewStatus.component"
import { OverviewDetails } from "../../../../../components/overview/OverviewTopContainer/OverviewDetails.component"
import {
    NavigationTabBar,
    TabProps,
} from "../../../../../components/navigation-tab-bar/NavigationTabBar.component"
import { UserLicenseModal } from "./UserLicenseModal.component"
import { ModalApi } from "../../../../../components/modal/ConfirmationModal.component"
import { OrgInfo } from "../../../../services/Org.service"

interface Props {
    orgInfo: OrgInfo
}

export function UserOverview(props: Props) {
    const params = useParams<{ id: string }>()
    const localization = useServiceLocalization()
    const history = useHistory()
    const modalService = useServiceModal()
    const getTabProps = useGetUserTabProps(params.id)
    const userLicenseModalRef = React.useRef<ModalApi<void>>(null)

    const {
        data: user,
        refetch: refetchUserInfo,
        error: userError,
        status: userStatus,
    } = useGetUserByEmail(decodeID(params.id))

    const {
        data: licenseUsage,
        status: licenseUsageStatus,
        error: licenseUsageError,
        refetch: licenseUsageRefetch,
    } = useGetUserLicenseUsageByEmail(decodeID(params.id))

    const {
        data: featureFlags,
        status: featureFlagsStatus,
        error: featureFlagsError,
    } = useFeatureFlags()

    const tabs: string[] = [
        localization.getString("devices"),
        localization.getString("userAccessActivity"),
        localization.getString("roles"),
    ]

    const {
        mutateAsync: deleteUser,
        error: deleteUserError,
        status: deleteUserStatus,
    } = useDeleteUsers()

    const errors: React.ReactNode[] = [deleteUserError]

    const licenseUsageList = useMemo<UserLicenseInformation[]>(() => {
        return [
            getLicense(licenseUsage?.sia, LicenseType.SIA),
            getLicense(licenseUsage?.spa, LicenseType.SPA),
        ]
    }, [licenseUsage])

    const filteredTabs = useMemo(() => {
        return featureFlags?.adminConsole.showUserLicenseUsageInfo
            ? detailsTabs
            : detailsTabs.filter((tab) => tab !== TabKey.LICENSE)
    }, [featureFlags?.adminConsole.showUserLicenseUsageInfo])

    if (
        userStatus === "loading" ||
        deleteUserStatus === "loading" ||
        licenseUsageStatus === "loading" ||
        featureFlagsStatus === "loading"
    ) {
        return <Loader center medium />
    }

    if (
        userStatus === "error" ||
        licenseUsageStatus === "error" ||
        deleteUserStatus === "error" ||
        featureFlagsStatus === "error"
    ) {
        return (
            <ErrorBanner>
                {String(userError) ||
                    String(licenseUsageError) ||
                    String(deleteUserError) ||
                    String(featureFlagsError)}
            </ErrorBanner>
        )
    }

    const userId: string = user.id || ""
    if (featureFlags.adminConsole.showUserLicenseUsageInfo) {
        tabs.push(localization.getString("licenseInfo"))
    }

    function onEditUser() {
        user?.email &&
            history.push(
                formatRoutePath(ROUTE.USERS_EDIT, {
                    id: encodeID(user.email),
                })
            )
    }

    function onDeleteUser() {
        if (!user) {
            return
        }

        modalService
            .openConfirmation(
                localization.getString("confirmUserRemoval"),
                localization.getString("removeUserExplanation", user.name)
            )
            .onClose(async () => {
                await deleteUser([userId])
                history.push(ROUTE.USERS)
            })
    }

    function mapLicenseColumn(): KeyValuePairItems[] {
        let licensesColumn: KeyValuePairItems[] = []

        if (featureFlags?.adminConsole.showSpaUserLicense) {
            licensesColumn.push({
                label: localization.getString("license", localization.getString("spa")),
                value: licenseUsage?.spa?.status
                    ? localization.getString(licenseStatusMap[licenseUsage.spa.status])
                    : localization.getString("notLicensed"),
            })
        }

        if (featureFlags?.adminConsole.showSiaUserLicense) {
            licensesColumn.push({
                label: localization.getString("license", localization.getString("sia")),
                value: licenseUsage?.sia?.status
                    ? localization.getString(licenseStatusMap[licenseUsage.sia.status])
                    : localization.getString("notLicensed"),
            })
        }

        return licensesColumn
    }

    const onCloseUserLicenseModal: React.MouseEventHandler = (e) => {
        e.preventDefault()
        userLicenseModalRef.current?.dismiss()
    }

    const onEditUserLicense: React.MouseEventHandler = (e) => {
        e.preventDefault()
        userLicenseModalRef.current?.open()
    }

    const handleRefresh = () => {
        refetchUserInfo()
        licenseUsageRefetch()
    }

    return (
        <React.Fragment>
            <Container as="section" aria-labelledby={Id.HEADING} className={styles.container}>
                <header className={styles.header}>
                    <PageHeading id={Id.HEADING}>{user?.name}</PageHeading>
                    <div className={styles.actionButtons}>
                        {featureFlags.adminConsole.showUserLicenseModal.canAccess && (
                            <Tooltip title={localization.getString("licenses")}>
                                <Button
                                    icon={IconType.PEN}
                                    onClick={onEditUserLicense}
                                    asElement={ButtonElement.BUTTON}
                                    buttonType={ButtonType.SECONDARY}
                                    aria-label={localization.getString("licenses")}
                                >
                                    {localization.getString("licenses")}
                                </Button>
                            </Tooltip>
                        )}
                        {featureFlags.adminConsole.canChangeUser && (
                            <Tooltip title={localization.getString("edit")}>
                                <Button
                                    icon={IconType.PEN}
                                    onClick={onEditUser}
                                    asElement={ButtonElement.BUTTON}
                                    buttonType={ButtonType.SECONDARY}
                                    aria-label={localization.getString("edit")}
                                />
                            </Tooltip>
                        )}
                        {featureFlags.adminConsole.canRemoveUsers && (
                            <Tooltip
                                title={
                                    featureFlags.adminConsole.isScimEnabled
                                        ? localization.getString("userCannotBeDeletedDesc")
                                        : localization.getString("deleteUser")
                                }
                            >
                                <span>
                                    <Button
                                        icon={IconType.TRASH}
                                        onClick={onDeleteUser}
                                        asElement={ButtonElement.BUTTON}
                                        buttonType={ButtonType.SECONDARY}
                                        aria-label={localization.getString("deleteUser")}
                                        disabled={featureFlags.adminConsole.isScimEnabled}
                                    />
                                </span>
                            </Tooltip>
                        )}
                        <Tooltip title={localization.getString("refresh")}>
                            <Button
                                icon={IconType.REDO}
                                onClick={() => handleRefresh()}
                                asElement={ButtonElement.BUTTON}
                                buttonType={ButtonType.SECONDARY}
                                aria-label={localization.getString("refresh")}
                            />
                        </Tooltip>
                    </div>
                </header>
                <ErrorBanners errors={errors} />

                <div className={styles.overviewTopContainer}>
                    <OverviewStatus
                        statusType={user.status ? statusMap[user.status] : "unknown"}
                        statusLabel={
                            user.status
                                ? localization.getString(labelMap[user.status])
                                : localization.getString("unknown")
                        }
                        statusItems={
                            featureFlags?.adminConsole.doesUseBanyanIdp
                                ? [
                                      {
                                          label: localization.getString("invitedAt"),
                                          value: DateUtil.format(user.invitedAt || 0),
                                      },
                                  ]
                                : []
                        }
                    />

                    <OverviewDetails
                        listItems={[
                            {
                                label: localization.getString("email"),
                                value: user.email,
                            },
                            {
                                label: localization.getString("roles"),
                                value:
                                    user.roles.length > 0
                                        ? toLocalizedList(
                                              user.roles.map(getRoleName),
                                              localization.getLocale(),
                                              "conjunction"
                                          )
                                        : localization.getString("none"),
                            },
                            {
                                label: localization.getString("lastLogin"),
                                value: DateUtil.format(user.lastLoginAt || 0),
                            },
                        ]}
                        listItemsCol2={mapLicenseColumn()}
                    />
                </div>

                <NavigationTabBar tabs={filteredTabs} getTabProps={getTabProps} />
                <Switch>
                    <Route path={ROUTE.USERS_DETAILS_DEVICES}>
                        <UserDeviceList email={user.email} />
                    </Route>
                    <Route path={ROUTE.USERS_DETAILS_ACTIVITY}>
                        <UserActivity email={user.email} />
                    </Route>
                    <Route path={ROUTE.USERS_DETAILS_ROLES}>
                        <UserRolesList roles={user.roles} />
                    </Route>
                    {featureFlags.adminConsole.showUserLicenseUsageInfo &&
                        props.orgInfo?.licenseType === "license" && (
                            <Route path={ROUTE.USERS_DETAILS_LICENSE}>
                                <UserLicenseInfoList
                                    licenseUsage={licenseUsageList}
                                    user={user}
                                    licenseInfo={props.orgInfo.license}
                                />
                            </Route>
                        )}
                    <Redirect to={ROUTE.USERS_DETAILS_DEVICES} />
                </Switch>
            </Container>
            {featureFlags.adminConsole.showUserLicenseModal.canAccess && (
                <UserLicenseModal
                    ref={userLicenseModalRef}
                    email={user.email}
                    licenseInfo={featureFlags.adminConsole.showUserLicenseModal.orgLicenseInfo}
                    userLicenseInformation={licenseUsage}
                    footer={
                        <Button
                            asElement={ButtonElement.BUTTON}
                            buttonType={ButtonType.PRIMARY}
                            onClick={onCloseUserLicenseModal}
                        >
                            {localization.getString("done")}
                        </Button>
                    }
                />
            )}
        </React.Fragment>
    )
}

enum TabKey {
    DEVICES = "DEVICES",
    ACTIVITY = "ACTIVITY",
    ROLES = "ROLES",
    LICENSE = "LICENSE",
}

const detailsTabs = Object.values(TabKey)

function useGetUserTabProps(id: string) {
    const localization = useServiceLocalization()
    const location = useLocation()

    const labels: Record<TabKey, string> = {
        DEVICES: localization.getString("devices"),
        ACTIVITY: localization.getString("userAccessActivity"),
        ROLES: localization.getString("roles"),
        LICENSE: localization.getString("licenseInfo"),
    }

    const pathTo: Record<TabKey, ROUTE> = {
        [TabKey.DEVICES]: formatRoutePath(ROUTE.USERS_DETAILS_DEVICES, { id: id }),
        [TabKey.ACTIVITY]: formatRoutePath(ROUTE.USERS_DETAILS_ACTIVITY, { id: id }),
        [TabKey.ROLES]: formatRoutePath(ROUTE.USERS_DETAILS_ROLES, { id: id }),
        [TabKey.LICENSE]: formatRoutePath(ROUTE.USERS_DETAILS_LICENSE, { id: id }),
    }

    const isActive = useMemo<Record<TabKey, boolean>>(() => {
        return {
            [TabKey.DEVICES]: !!matchPath(location.pathname, pathTo[TabKey.DEVICES]),
            [TabKey.ACTIVITY]: !!matchPath(location.pathname, pathTo[TabKey.ACTIVITY]),
            [TabKey.ROLES]: !!matchPath(location.pathname, pathTo[TabKey.ROLES]),
            [TabKey.LICENSE]: !!matchPath(location.pathname, pathTo[TabKey.LICENSE]),
        }
    }, [location.pathname, pathTo])

    return (tab: TabKey): TabProps => ({
        key: tab,
        label: labels[tab],
        to: pathTo[tab],
        active: isActive[tab],
    })
}

function getLicense(
    license: UserLicenseInformation | undefined,
    type: LicenseType
): UserLicenseInformation {
    return (
        license || {
            status: LicenseStatus.NOT_LICENSED,
            licenseType: type,
        }
    )
}

enum Id {
    HEADING = "heading",
}

function getRoleName(role: Role): string {
    return role.name
}
