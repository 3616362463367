import classNames from "classnames/bind"
import * as React from "react"
import styles from "./Clickable.module.scss"

interface ClickableProps {
    children: React.ReactNode
    onClick: (() => void | Promise<void>) | null
    className?: string
    style?: React.CSSProperties
    tabIndex?: number
    disabled?: boolean
    link?: boolean
    ["aria-label"]?: string
}

export const Clickable = React.forwardRef<HTMLButtonElement, ClickableProps>(function (
    { children, onClick, className, style, tabIndex = 0, disabled, link, ...props },
    ref
) {
    return (
        <button
            onClick={onClick || (() => {})}
            className={classNames(styles.button, className, {
                [styles.link]: link,
            })}
            type="button"
            ref={ref}
            style={style || {}}
            tabIndex={tabIndex}
            disabled={disabled}
            aria-label={props?.["aria-label"]}
        >
            {children}
        </button>
    )
})
