import React, { Dispatch, FC, useMemo, useRef, useState } from "react"
import { SearchInput } from "../../../components/search-input/SearchInput.component"
import { Loader } from "../../../components/loader/Loader.component"
import { Card } from "../../../components/card/Card.component"
import styles from "./SearchUserInput.module.scss"
import classNames from "classnames"
import { UserListDataHook } from "../../../services/MspOrgManagement.service"

interface Props {
    userList?: UserListDataHook[]
    loading: boolean
    setUser: Dispatch<UserListDataHook | undefined>
    placeholder?: string
}

export const SearchUserInput: FC<Props> = ({ userList, loading, setUser, placeholder }) => {
    const [searchValue, setSearchValue] = useState("")
    const [selected, setSelected] = useState(false)

    const inputRef = useRef(null)

    const filteredData = useMemo(() => {
        if (!userList) return []
        return userList.filter((user) => new RegExp(searchValue, "i").test(user.name))
    }, [searchValue, userList])

    function onUserSelected(user: UserListDataHook) {
        setSearchValue(user.name)
        setUser(user)
        setSelected(true)
    }

    function onChange(value: string) {
        setSearchValue(value)
        setUser(undefined)
        setSelected(false)
    }

    return (
        <div className={styles.container}>
            <input
                type="text"
                value={selected ? "selected" : ""}
                required
                className={styles.validation}
            />
            <SearchInput placeholder={placeholder} value={searchValue} onChangeValue={onChange} />
            {searchValue && !selected && (
                <Card
                    className={classNames(styles.card, {
                        [styles.loading]: loading,
                    })}
                >
                    <Loader isLoading={loading} center>
                        <ul>
                            {filteredData.map((user) => (
                                <li key={user.name} className={styles.item}>
                                    <button onClick={() => onUserSelected(user)}>
                                        {user.name}
                                    </button>
                                </li>
                            ))}
                        </ul>
                    </Loader>
                </Card>
            )}
        </div>
    )
}
