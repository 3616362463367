import * as React from "react"

import logoImage from "../../../images/logo-horiz-black.svg"
import { ROUTE } from "../../../routes"
import { Background } from "../../../v3/components/background/Background.component"
import styles from "./OIDC.module.scss"
import { Button, Input, ErrorBanner } from "../../components"
import { ForgotPasswordReq } from "../../api/OIDC.api"
import { Link } from "react-router-dom"
import { RouteComponentProps } from "react-router"
import { useServiceLocalization, useServiceOIDC } from "../../services"

export function OIDCForgotPassword({ history }: RouteComponentProps) {
    const localization = useServiceLocalization()
    const oidcService = useServiceOIDC()

    const orgName: string = oidcService.getOrgName()

    const [email, setEmail] = React.useState<string>("")
    const [hasError, setHasError] = React.useState<boolean>(false)

    const form = React.useRef(null)

    React.useEffect(() => {
        if (!orgName) {
            history.push(ROUTE.OIDC_LOGIN)
        }
    }, [history, orgName])

    function validateForm() {
        return email.length > 0 && orgName.length > 0
    }

    function onSubmit(event: React.SyntheticEvent) {
        event.preventDefault()

        const payload: ForgotPasswordReq = {
            username: email.toLowerCase(),
            orgName,
        }

        oidcService.forgotPassword(payload).catch(() => {
            setHasError(true)
        })
    }

    return (
        <div className={styles.oidcLogin}>
            <Background>
                <div className={styles.loginForm}>
                    <div className={styles.form}>
                        {hasError && (
                            <div className={styles.error}>
                                {" "}
                                <ErrorBanner>
                                    {localization.getString("somethingWentWrongDescription")}
                                </ErrorBanner>
                            </div>
                        )}
                        <form onSubmit={onSubmit} ref={form} name="loginform">
                            <img src={logoImage} alt="" className={styles.logo} />
                            <Input
                                autoFocus
                                name="username"
                                id="username"
                                type="email"
                                value={email}
                                onChange={(e) => setEmail(e.target.value)}
                                placeholder={localization.getString("email")}
                            />

                            <Button
                                className={styles.formBtn}
                                type="submit"
                                disabled={!validateForm()}
                            >
                                {localization.getString("resetPassword")}
                            </Button>
                            <br></br>
                            <div className={styles.message}>
                                <Link to={ROUTE.OIDC_LOGIN}>
                                    {localization.getString("cancel")}
                                </Link>
                            </div>
                        </form>
                    </div>
                </div>
            </Background>
        </div>
    )
}
