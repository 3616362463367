import React from "react"

import { ROUTE } from "../../../../routes"
import { useServiceLocalization } from "../../../services"
import { CountWidget } from "../Widget"

interface Props {
    count?: number
}

export function DevicesCount(props: Props): JSX.Element {
    const localization = useServiceLocalization()

    return (
        <CountWidget
            {...props}
            gridArea="device-count"
            title={localization.getString("devices")}
            link={ROUTE.DEVICES}
        />
    )
}
