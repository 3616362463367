import React from "react"
import styles from "./Grid.module.scss"
import { AgGridReact } from "ag-grid-react/lib/agGridReact"
import classNames from "classnames/bind"

export default function () {
    return (
        <div
            id={this.id}
            className={classNames(
                { [styles.gridContainer]: !this.props.autoHeight },
                "ag-theme-material",
                this.props.className
            )}
        >
            <AgGridReact
                onGridReady={this.onGridReady}
                rowData={this.props.rowData}
                defaultColDef={this.defaultColDef}
                columnDefs={this.props.columnDefs}
                detailCellRenderer={this.props.detailCellRenderer}
                detailRowHeight={this.props.detailRowHeight}
                domLayout={this.props.autoHeight ? "autoHeight" : "normal"}
                masterDetail={this.props.masterDetail}
                context={this.props.context}
                suppressContextMenu
                rowModelType={this.props.serverSidePagination ? "serverSide" : "clientSide"}
                pagination={this.props.pagination || this.props.serverSidePagination}
                paginationAutoPageSize
                onPaginationChanged={this.onPaginationChanged}
                onFirstDataRendered={this.onFirstDataRendered}
                rowBuffer="50"
                rowSelection={this.props.multiSelect ? "multiple" : "single"}
                rowMultiSelectWithClick={this.props.multiSelect}
                rowHeight={this.props.rowHeight || (this.props.compact ? "35" : "44")}
                headerHeight="30"
                debug={this.props.debug}
                suppressCopyRowsToClipboard
                suppressRowHoverHighlight={this.props.suppressRowHoverHighlight}
                enableCellTextSelection
                onCellDoubleClicked={this.props.onCellDoubleClicked}
                onRowDoubleClicked={this.props.onRowDoubleClicked}
                onRowSelected={this.props.onRowSelected}
                tooltipShowDelay="0"
                components={this.props.components}
                getRowId={this.props.serverSidePagination && (this.props.getRowId || this.getRowId)}
            ></AgGridReact>
        </div>
    )
}
