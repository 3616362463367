import React from "react"
import { Redirect } from "react-router"

import { ROUTE } from "../../../../../routes"
import { useHasGranularTrustMigrationOccurred } from "../../../../services/Org.service"
import { PreMigrationTrustFactors } from "./trustFactors/preMigrationTrustFactors/PreMigrationTrustFactors.view"

export function TrustScoreSettings(): JSX.Element {
    const { data: hasMigrated, isFetching } = useHasGranularTrustMigrationOccurred()

    if (isFetching) return <React.Fragment />
    if (hasMigrated) return <Redirect to={ROUTE.FACTORS} />
    return <PreMigrationTrustFactors />
}
