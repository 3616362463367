import React, { ReactNode } from "react"
import ClustersSshCaTemplate from "./ClustersSshCa.template"
import { Bind } from "../../../decorators/Bind.decorator"
import { LocalizationService } from "../../../services/localization/Localization.service"

export class ClustersSshCa extends React.Component<ClustersSshCaProps, ClustersSshCaState> {
    public state: ClustersSshCaState = { showPubKey: false }

    public render(): ReactNode {
        return ClustersSshCaTemplate.call(this)
    }

    private localizationService: LocalizationService = new LocalizationService()

    @Bind
    private onTogglePubKey(): void {
        this.setState({
            showPubKey: !this.state.showPubKey,
        })
    }
}

interface ClustersSshCaProps {
    pubKey: string
}

interface ClustersSshCaState {
    showPubKey: boolean
}
