import { AccessTierGroupRes, AdvancedSettingsJson } from "../../api/AccessTierGroup.api"
import { AdvancedSettings, AdvancedSettingsStatus } from "./AccessTier"

export function getAdvancedSettingsFromRes(
    accessTierGroupRes: AccessTierGroupRes
): AdvancedSettings | undefined {
    const advancedSettingsJson = parseAdvancedSettingsJson(accessTierGroupRes)

    return (
        advancedSettingsJson && {
            metricsCollectionAddress:
                advancedSettingsJson.logging?.statsd && advancedSettingsJson.logging.statsd_address
                    ? advancedSettingsJson.logging.statsd_address
                    : undefined,
            eventsRateLimiting: getAdvancedSettingsStatus(
                advancedSettingsJson.events?.access_event_credits_limiting
            ),
            eventKeyRateLimiting: getAdvancedSettingsStatus(
                advancedSettingsJson.events?.access_event_key_limiting
            ),
            forwardTrustCookie: getAdvancedSettingsStatus(
                advancedSettingsJson.hosted_web_services?.forward_trust_cookie
            ),
            enableStrictTransport: getAdvancedSettingsStatus(
                advancedSettingsJson.hosted_web_services?.disable_hsts
            ),
            enablePrivateResourceDiscovery: getAdvancedSettingsStatus(
                advancedSettingsJson.service_discovery?.service_discovery_enable
            ),
        }
    )
}

function parseAdvancedSettingsJson(
    accessTierGroupRes: AccessTierGroupRes
): AdvancedSettingsJson | undefined {
    try {
        return JSON.parse(accessTierGroupRes.advanced_settings)
    } catch (_error) {
        return undefined
    }
}

function getAdvancedSettingsStatus(status?: boolean): AdvancedSettingsStatus {
    switch (status) {
        case undefined:
            return AdvancedSettingsStatus.DEFAULT

        case true:
            return AdvancedSettingsStatus.ENABLED

        case false:
            return AdvancedSettingsStatus.DISABLED
    }
}
