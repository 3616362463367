import React from "react"
import styles from "./ServiceBundleList.module.scss"
import { Toolbar } from "../../../../../components/toolbar/Toolbar.component"
import { Grid } from "../../../../../components/grid/Grid.component"
import { Banner } from "../../../../../components/banner/Banner.component"
import { faExclamationCircle } from "@fortawesome/pro-solid-svg-icons"
import { ServiceBundleNameCellRenderer } from "../name-cell-renderer/ServiceBundleNameCellRenderer.component"
import {
    Button,
    ButtonElement,
    ButtonType,
    IconType,
} from "../../../../../../components/button/Button.component"
import { PageHeading } from "../../../../../../components/page-heading/PageHeading.component"
import { Tooltip } from "../../../../../../v3/components/tooltip/Tooltip.component"

export default function () {
    return (
        <div className={styles.gridWithToolbar}>
            <header className={styles.header}>
                <PageHeading>{this.localizationService.getString("bundles")}</PageHeading>
                <Tooltip title={this.localizationService.getString("refresh")}>
                    <Button
                        icon={IconType.REDO}
                        onClick={this.onRefresh}
                        asElement={ButtonElement.BUTTON}
                        buttonType={ButtonType.SECONDARY}
                    />
                </Tooltip>
            </header>
            {this.state.error && (
                <Banner type="error" icon={faExclamationCircle} className={styles.gridBanner}>
                    {this.localizationService.getString("failedToLoadServiceBundles")}
                </Banner>
            )}
            <Toolbar onSearchChange={this.onFilter}>
                <div> </div>
                {this.props.canAccessServiceBundle && (
                    <Button
                        buttonType={ButtonType.PRIMARY}
                        asElement={ButtonElement.BUTTON}
                        icon={IconType.PLUS}
                        onClick={this.onAddServiceBundle}
                    >
                        {this.localizationService.getString("addBundle")}
                    </Button>
                )}
            </Toolbar>
            <Grid
                onGridReady={this.onGridReady}
                columnDefs={this.columnDefs}
                pagination
                context={{ history: this.props.history }}
                components={{
                    serviceBundleNameCellRenderer: ServiceBundleNameCellRenderer,
                }}
            ></Grid>
        </div>
    )
}
