import React from "react"
import styles from "./TrustScoreSettings.module.scss"
import { OperatingSystemVersions } from "./os/OperatingSystemVersions.component"

export default function () {
    return (
        <div className={styles.pagePadded}>
            <div className={styles.pagePadded}>
                <OperatingSystemVersions />
            </div>
        </div>
    )
}
