import React from "react"
import { useServiceLocalization } from "../../../../../pre-v3/services/localization/Localization.service"
import { FormRow } from "../../../../../components/form/FormRow.component"
import {
    Button,
    ButtonElement,
    ButtonType,
    IconType,
} from "../../../../../components/button/Button.component"
import styles from "./WindowsInstall.module.scss"
import { CopyInput } from "../../../../../components/copy-input/CopyInput.component"
import { getWindowsConnectorDownloadLink } from "../../../../services/Connector.service"
import { AppText } from "../../../../components/app-text/AppText.component"
import { useServiceLinks } from "../../../../../pre-v3/services"

interface WindowsInstallProps {
    fqdn: string
    ApiKey: string
    connectorName: string
    canAccessConnectorInstallStep?: boolean
    latestVersion: string
}
export function WindowsInstall(props: WindowsInstallProps) {
    const localization = useServiceLocalization()
    const links = useServiceLinks()
    return (
        <>
            <FormRow
                label={localization.getString("step", "1")}
                description={localization.getString("downloadInstallerPackage")}
                childrenClassName={styles.rowChild}
            >
                <Button
                    asElement={ButtonElement.LINK}
                    to={getWindowsConnectorDownloadLink(props.latestVersion)}
                    buttonType={ButtonType.SECONDARY}
                    icon={IconType.OUTLINE_DOWNLOAD}
                    type="button"
                >
                    {localization.getString("download")}
                </Button>
            </FormRow>
            <FormRow
                label={localization.getString("step", "2")}
                description={localization.getString("startInstallerExe")}
            />
            <FormRow
                label={localization.getString("step", "3")}
                description={localization.getString("chooseInstallationLocation")}
            />
            <FormRow
                label={localization.getString("step", "4")}
                description={localization.getString("copyCseCommandCenterFqdn")}
                className={styles.rowWithCopy}
                htmlFor={Ids.FQDN}
            >
                <CopyInput
                    text={props.fqdn}
                    hideCopyButton={!props.canAccessConnectorInstallStep}
                    id={Ids.FQDN}
                />
            </FormRow>
            <FormRow
                label={localization.getString("step", "5")}
                description={localization.getString("copyApiKey")}
                className={styles.rowWithCopy}
                htmlFor={Ids.API_KEY}
            >
                <CopyInput
                    text={props.ApiKey}
                    hideCopyButton={!props.canAccessConnectorInstallStep}
                    id={Ids.API_KEY}
                />
            </FormRow>
            <FormRow
                label={localization.getString("step", "6")}
                description={localization.getString("copyConnectorName")}
                className={styles.rowWithCopy}
                htmlFor={Ids.NAME}
            >
                <CopyInput
                    id={Ids.NAME}
                    text={props.connectorName}
                    hideCopyButton={!props.canAccessConnectorInstallStep}
                />
            </FormRow>
            <FormRow
                label={localization.getString("step", "7")}
                description={localization.getString("clickInstall")}
            />
            <FormRow
                label={localization.getString("step", "8")}
                description={
                    <AppText
                        ls={{
                            key: "ifConnectivityCheckWasSuccessful",
                            replaceVals: [
                                links.getLink("windowsInstallForConnectorPreInstallCheckList"),
                            ],
                        }}
                    />
                }
                childrenClassName={styles.longRowChild}
            />
            <FormRow
                label={localization.getString("step", "9")}
                description={<AppText ls="clickFinish" />}
            />
        </>
    )
}

enum Ids {
    NAME = "name",
    API_KEY = "api-key",
    FQDN = "fqdn",
}
