import React, { FC, ReactNode } from "react"
import styles from "./Label.module.scss"
import classNames from "classnames"

enum LabelVariant {
    GENERIC_PRIMARY = "generic-primary",
    GENERIC_SECONDARY = "generic-secondary",
    INFORMATION = "information",
    ERROR = "error",
    WARNING = "warning",
    SUCCESS = "success",
}

interface Props {
    children: ReactNode
    variant: LabelVariant
    darkMode?: boolean
}

const Label: FC<Props> = ({ children, variant, darkMode }) => {
    const className = classNames(styles.label, styles[variant], styles[darkMode ? "dark" : ""])
    return <div className={className}>{children}</div>
}

interface LabelsProp {
    children: ReactNode
    darkMode?: boolean
}

export const GenericPrimaryLabel: FC<LabelsProp> = ({ children, darkMode }) => (
    <Label variant={LabelVariant.GENERIC_PRIMARY} darkMode={darkMode}>
        {children}
    </Label>
)
export const GenericSecondaryLabel: FC<LabelsProp> = ({ children, darkMode }) => (
    <Label variant={LabelVariant.GENERIC_SECONDARY} darkMode={darkMode}>
        {children}
    </Label>
)
export const InformationLabel: FC<LabelsProp> = ({ children, darkMode }) => (
    <Label variant={LabelVariant.INFORMATION} darkMode={darkMode}>
        {children}
    </Label>
)
export const ErrorLabel: FC<LabelsProp> = ({ children, darkMode }) => (
    <Label variant={LabelVariant.ERROR} darkMode={darkMode}>
        {children}
    </Label>
)
export const WarningLabel: FC<LabelsProp> = ({ children, darkMode }) => (
    <Label variant={LabelVariant.WARNING} darkMode={darkMode}>
        {children}
    </Label>
)
export const SuccessLabel: FC<LabelsProp> = ({ children, darkMode }) => (
    <Label variant={LabelVariant.SUCCESS} darkMode={darkMode}>
        {children}
    </Label>
)
