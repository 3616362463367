import { faShieldAlt, faVectorSquare } from "@fortawesome/pro-light-svg-icons"
import { faRocket } from "@fortawesome/pro-regular-svg-icons"
import React from "react"
import { Redirect, Route, Switch } from "react-router-dom"

import banyanRoute from "../../../images/banyan-route.svg"
import { useActionBar } from "../../../pre-v3/services/ActionBar.service"
import { LinkService } from "../../../pre-v3/services/link/Link.service"
import { useServiceLocalization } from "../../../pre-v3/services/localization/Localization.service"
import { ROUTE } from "../../../routes"
import { ErrorBanner } from "../../components/banner/Banner.component"
import { Container } from "../../components/container/Container.component"
import { Loader } from "../../components/loader/Loader.component"
import {
    ContactUsScreen,
    ContactUsScreenSteps,
} from "../../components/page-screen/PageScreen.component"
import { ItpPolicyAdd } from "./add/ItpPolicyAdd.view"
import { ItpPolicyList } from "./list/ItpPolicyList.view"
import { ItpPolicyOverview } from "./overview/ItpPolicyOverview.view"
import styles from "./ItpPolicy.module.scss"
import useTitle from "../../../hooks/useTitle.hook"
import { useFeatureFlags } from "../../../hooks/useFeatureFlags.hook"

export function ItpPolicy(): JSX.Element {
    const {
        data: featureFlags,
        status: featureFlagsStatus,
        error: featureFlagsError,
    } = useFeatureFlags()

    useTitle(["internetThreatProtection", "internetAccess", "adminConsole"])

    if (featureFlagsStatus === "loading") {
        return <Loader isLoading center medium children />
    }

    if (featureFlagsStatus === "error") {
        return (
            <Container>
                <ErrorBanner className={styles.errorBanner}>
                    {String(featureFlagsError)}
                </ErrorBanner>
            </Container>
        )
    }

    if (featureFlags.adminConsole.doShowUpgradeScreenItp) {
        return <UpgradeScreen />
    }

    return (
        <Switch>
            <Route exact path={ROUTE.INTERNET_THREAT_PROTECTION}>
                <ItpPolicyList
                    canCreateItpPolicy={featureFlags.adminConsole.canCreateItpPolicy}
                    canReorderItpPolicy={featureFlags.adminConsole.canReorderItpPolicy}
                    canGenerateReport={featureFlags.adminConsole.canGenerateReport}
                />
            </Route>
            <Route exact path={ROUTE.INTERNET_THREAT_PROTECTION_ADD} component={ItpPolicyAdd} />
            <Route
                exact
                path={ROUTE.INTERNET_THREAT_PROTECTION_DETAILS}
                component={ItpPolicyOverview}
            />
            <Redirect to={ROUTE.INTERNET_THREAT_PROTECTION} />
        </Switch>
    )
}

function UpgradeScreen() {
    const localization = useServiceLocalization()
    const linkService = new LinkService()

    useActionBar({
        title: localization.getString("itpPolicies"),
        items: [
            {
                label: localization.getString("internetThreatProtection"),
            },
        ],
        actions: [],
    })

    return (
        <Container>
            <ContactUsScreen
                image={banyanRoute}
                title={localization.getString(
                    "upgradeForSomething",
                    localization.getString("internetThreatProtection")
                )}
                docsLink={linkService.getLink("itpDoc")}
            >
                <ContactUsScreenSteps
                    steps={[
                        {
                            icon: faShieldAlt,
                            label: localization.getString(
                                "protectUsersFromPhishingAttacksMaliciousWebsitesOrRansomwareByFilteringDomains"
                            ),
                        },
                        {
                            icon: faVectorSquare,
                            label: localization.getString(
                                "enforceAcceptableUsePoliciesByBlockingSpecificCategoriesOfWebsites"
                            ),
                        },
                        {
                            icon: faRocket,
                            label: localization.getString(
                                "provideGranularProtectionForSpecificURLPathsWithinADomain"
                            ),
                        },
                    ]}
                />
            </ContactUsScreen>
        </Container>
    )
}
