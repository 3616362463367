import React from "react"
import { Route, Switch } from "react-router-dom"
import ServicesInfraList from "../../pre-v3/views/services/infra-list/ServicesInfraList"
import ServicesAdd from "../../pre-v3/views/services/add"
import ServicesEdit from "../../pre-v3/views/services/edit"
import { ServicesOverview as ServicesDetails } from "../../pre-v3/views/services/overview/ServicesOverview"
import { ROUTE } from "../../routes"

export const Infrastructure = () => {
    return (
        <Switch>
            <Route path={ROUTE.INFRASTRUCTURE} component={ServicesInfraList} exact />
            <Route path={ROUTE.INFRASTRUCTURE_ADD} component={ServicesAdd} exact />
            <Route path={ROUTE.INFRASTRUCTURE_EDIT} component={ServicesEdit} />
            <Route path={ROUTE.INFRASTRUCTURE_DETAILS} component={ServicesDetails} />
        </Switch>
    )
}
