import { useQuery } from "@tanstack/react-query"
import React from "react"

import { ROUTE } from "../../../../routes"
import { useServiceLocalization } from "../../../services/localization/Localization.service"
import { useServiceStats } from "../../../services/Stats.service"
import { CountWidget } from "../Widget"

export function UserCount() {
    const localization = useServiceLocalization()
    const stats = useServiceStats()

    const { data: count } = useQuery({
        queryKey: ["getUserCount"],
        queryFn: () => stats.getUserCount(),
    })

    return (
        <CountWidget
            gridArea="user-count"
            title={localization.getString("users")}
            count={count}
            link={ROUTE.USERS}
        />
    )
}
