import React from "react"
import { FormLabel, Input } from "../../../../components"
import { ServiceAppType } from "../../../../services/Manage.service"
import styles from "./ServicesAddDnsOverrides.module.scss"

export default function () {
    return (
        <FormLabel
            title={
                this.props.serviceAppType === ServiceAppType.K8S
                    ? this.ls.getString("backendDNSOverrideForServiceDomainName")
                    : this.ls.getString("backendDNSOverrideForServiceDomainNameOptional")
            }
            className={this.props.className}
            htmlFor="backendDNSOverrideForServiceDomainName"
        >
            <Input
                className={styles.formInput}
                placeholder={this.ls.getString("domainNamePlaceholder")}
                disabled={this.props.disabled}
                defaultValue={this.props.initialValue}
                onChange={this.onChange}
                required={this.props.serviceAppType === ServiceAppType.K8S}
            />
        </FormLabel>
    )
}
