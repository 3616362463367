import React, { ReactNode, SyntheticEvent } from "react"
import { ICellRendererParams } from "ag-grid-community"

import { ROUTE, formatRoutePath } from "../../../../../routes"
import { Bind } from "../../../../decorators/Bind.decorator"
import { encodeID } from "../../../../utils/Url.util"
import UsersEmailCellRendererTemplate from "./UsersEmailCellRenderer.template"

export class UsersEmailCellRenderer extends React.Component<
    UsersEmailCellRendererProps,
    UsersEmailCellRendererState
> {
    public static getDerivedStateFromProps(
        props: UsersEmailCellRendererProps
    ): UsersEmailCellRendererState {
        try {
            const emailBase64: string = encodeID(props.value)
            return {
                link: formatRoutePath(ROUTE.USERS_DETAILS, { id: emailBase64 }),
            }
        } catch {
            return { link: "" }
        }
    }

    public state: UsersEmailCellRendererState = { link: "" }

    public render(): ReactNode {
        return UsersEmailCellRendererTemplate.call(this)
    }

    public refresh(): boolean {
        return true
    }

    @Bind
    private clickHandler(event: SyntheticEvent): void {
        event.preventDefault()
        this.props.context.history.push(this.state.link)
    }
}

interface UsersEmailCellRendererProps extends ICellRendererParams {
    context: any
}

interface UsersEmailCellRendererState {
    link: string
}
