import React from "react"
import { useHistory, useLocation } from "react-router-dom"

import { ROUTE } from "../routes"
import { useAuthService } from "../pre-v3/services/Auth.service"
import { Profile } from "../utils/profile.utils"
import { AdminInfo, useAdminInfo } from "./useAdminInfo.hook"
import { AppConsole } from "../utils/consoles.utils"

export function useProtectPages(appConsole: AppConsole): boolean {
    const authService = useAuthService()
    const history = useHistory()
    const location = useLocation()

    const [hasExecuted, setHasExecuted] = React.useState(false)

    const { isLoading } = useAdminInfo({
        onSuccess: (adminInfo) => {
            const assignedConsole = getAssignedConsole(adminInfo)
            if (assignedConsole !== appConsole) {
                return history.push(consoleToRouteDict[assignedConsole])
            }

            if (hasExecuted || adminInfo.isImpersonated) return

            setHasExecuted(true)
        },
        onError: () => {
            authService.setLoginUrl(location.pathname + location.search)
            history.push(ROUTE.LOGIN)
        },
    })

    return isLoading
}

function getAssignedConsole(adminInfo: AdminInfo): AppConsole {
    return adminInfo.isMspOrg ? AppConsole.MSP : profileToConsoleDict[adminInfo.profile]
}

export function getAssignedConsoleRoute(adminInfo: AdminInfo): ROUTE {
    return consoleToRouteDict[getAssignedConsole(adminInfo)]
}

const profileToConsoleDict: Record<Profile, AppConsole> = {
    [Profile.MOM]: AppConsole.MOM,
    [Profile.SUPER_ADMIN]: AppConsole.MOM,
    [Profile.READONLY]: AppConsole.ADMIN,
    [Profile.ADMIN]: AppConsole.ADMIN,
    [Profile.OWNER]: AppConsole.ADMIN,
    [Profile.POLICY_AUTHOR]: AppConsole.ADMIN,
    [Profile.SERVICE_AUTHOR]: AppConsole.ADMIN,
}

const consoleToRouteDict: Record<AppConsole, ROUTE> = {
    [AppConsole.MOM]: ROUTE.ORG_MANAGEMENT,
    [AppConsole.ADMIN]: ROUTE.HOME,
    [AppConsole.MSP]: ROUTE.MSP_ORG_MANAGEMENT,
}
