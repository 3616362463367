import React from "react"
import { Redirect, Route, Switch, useLocation } from "react-router-dom"

import {
    NavigationItemProps,
    TopNavigationBar,
} from "../../components/top-navigation-bar/TopNavigationBar.component"
import { useFeatureFlags } from "../../hooks/useFeatureFlags.hook"
import useTitle from "../../hooks/useTitle.hook"
import { LanguageKey } from "../../pre-v3/services/localization/languages/en-US.language"
import { useServiceLocalization } from "../../pre-v3/services/localization/Localization.service"
import { ROUTE, doesRouteMatch } from "../../routes"
// Test Drive component used for demos
import { OnPremGatewayList as OnPremGateway } from "../../test-drive/on-prem-gateway/list/OnPremGatewayList.view"
import { ErrorBanner } from "../../v3/components/banner/Banner.component"
import { Loader } from "../../v3/components/loader/Loader.component"
import { Connector } from "../../v3/views/connector/Connector.view"
import { AccessTier } from "../access-tier/AccessTier"
import { AccessTierGroups } from "../access-tier-groups/AccessTierGroups.view"
import { Clusters } from "./Clusters"
import { TrustedNetworks } from "./trusted-networks/TrustedNetworks.view"
import { Upsell } from "../../shared/upsell/Upsell.component"

export function Networks() {
    useTitle(["network", "adminConsole"])

    const localization = useServiceLocalization()
    const location = useLocation()

    const featureFlagsQuery = useFeatureFlags()

    if (featureFlagsQuery.status === "loading") {
        return <Loader children isLoading center medium />
    }

    if (featureFlagsQuery.status === "error") {
        return <ErrorBanner>{String(featureFlagsQuery.error)}</ErrorBanner>
    }

    const filteredRoutes = secondLevelRoutes.filter((route) => {
        switch (route) {
            case ROUTE.ACCESS_TIER_GROUPS:
                return (
                    featureFlagsQuery.data.adminConsole.enableAccessTierGroups &&
                    featureFlagsQuery.data.adminConsole.canAccessAccessTierGroups.canAccess
                )

            case ROUTE.CLUSTERS:
                return featureFlagsQuery.data.adminConsole.canAccessClusters

            case ROUTE.ON_PREM_GATEWAY:
                return featureFlagsQuery.data.adminConsole.canAccessOnPremGateway

            default:
                return true
        }
    })

    const getNavigationItemProps = (route: SecondLevelRoute): NavigationItemProps => ({
        key: route,
        label: localization.getString(secondLevelLabels[route]),
        to: route,
        active: doesRouteMatch<SecondLevelRoute>(route, location),
    })

    const accessTiersView = featureFlagsQuery.data.adminConsole.doShowUpsellForAccessTiers
        ? Upsell
        : AccessTier
    const connectorsView = featureFlagsQuery.data.adminConsole.doShowUpsellForConnectors
        ? Upsell
        : Connector
    const clustersView = featureFlagsQuery.data.adminConsole.doShowUpsellForClusters
        ? Upsell
        : Clusters

    return (
        <React.Fragment>
            <header>
                <TopNavigationBar
                    headingTitle={localization.getString("networks")}
                    navigationItems={filteredRoutes}
                    getNavigationItemProps={getNavigationItemProps}
                />
            </header>
            <Switch>
                <Route path={ROUTE.CONNECTORS} component={connectorsView} />
                <Route path={ROUTE.ACCESS_TIERS} component={accessTiersView} />
                {featureFlagsQuery.data.adminConsole.enableAccessTierGroups &&
                    featureFlagsQuery.data.adminConsole.canAccessAccessTierGroups.canAccess && (
                        <Route path={ROUTE.ACCESS_TIER_GROUPS}>
                            <AccessTierGroups
                                privateEdgeCluster={
                                    featureFlagsQuery.data.adminConsole.canAccessAccessTierGroups
                                        .privateEdgeCluster
                                }
                            />
                        </Route>
                    )}
                {featureFlagsQuery.data.adminConsole.canAccessClusters && (
                    <Route path={ROUTE.CLUSTERS} component={clustersView} />
                )}
                {featureFlagsQuery.data.adminConsole.canAccessOnPremGateway && (
                    <Route path={ROUTE.ON_PREM_GATEWAY} component={OnPremGateway} />
                )}
                <Route path={ROUTE.TRUSTED_NETWORKS}>
                    <TrustedNetworks
                        canUpdateTrustedNetwork={
                            featureFlagsQuery.data.adminConsole.canUpdateTrustedNetwork
                        }
                    />
                </Route>
                <Redirect
                    to={
                        featureFlagsQuery.data.adminConsole.emphasizeGlobalEdge
                            ? ROUTE.CONNECTORS
                            : ROUTE.ACCESS_TIERS
                    }
                />
            </Switch>
        </React.Fragment>
    )
}

const secondLevelRoutes = [
    ROUTE.CONNECTORS,
    ROUTE.ACCESS_TIERS,
    ROUTE.ACCESS_TIER_GROUPS,
    ROUTE.CLUSTERS,
    ROUTE.ON_PREM_GATEWAY,
    ROUTE.TRUSTED_NETWORKS,
] satisfies ROUTE[]

type SecondLevelRoute = (typeof secondLevelRoutes)[number]

const secondLevelLabels: Record<SecondLevelRoute, LanguageKey> = {
    [ROUTE.CONNECTORS]: "connectors",
    [ROUTE.ACCESS_TIERS]: "accessTiers",
    [ROUTE.ACCESS_TIER_GROUPS]: "accessTierGroups",
    [ROUTE.CLUSTERS]: "clusters",
    [ROUTE.ON_PREM_GATEWAY]: "onPremGateway",
    [ROUTE.TRUSTED_NETWORKS]: "trustedNetworks",
}
