import React from "react"
import { useServiceLocalization } from "../../services/localization/Localization.service"
import {
    IconDefinition,
    faCopy as faCopySolid,
    faCheckCircle,
} from "@fortawesome/pro-solid-svg-icons"
import styles from "./InputWithCopy.module.scss"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { InputWithAction } from "../input-with-action/InputWithAction.component"
import classNames from "classnames/bind"

export function InputWithCopy(props: InputWithCopyProps) {
    const localization = useServiceLocalization()

    // we'll have to change the icon when we successfully copy the text
    const defaultIcon = faCopySolid
    const [icon, setIcon] = React.useState<IconDefinition>(defaultIcon)
    const [iconClass, setIconClass] = React.useState<string>("")

    const onCopy = () => {
        ;(navigator as any).clipboard.writeText(props.value)

        setIcon(faCheckCircle)
        setIconClass(styles.success)

        setTimeout(() => {
            setIcon(defaultIcon)
            setIconClass("")
        }, 1000)
    }

    return (
        <InputWithAction
            type={!props.hideCopyButton ? "text" : "password"}
            readOnly
            disabled
            value={props.value}
            onAction={!props.hideCopyButton ? onCopy : undefined}
            className={props.className}
        >
            <FontAwesomeIcon icon={icon} className={classNames(iconClass, styles.icon)} />{" "}
            {localization.getString("copy")}
        </InputWithAction>
    )
}

interface InputWithCopyProps {
    value: string
    className?: string
    hideCopyButton?: boolean
}
