import React from "react"

import styles from "./TimeRange.module.scss"
import { TimeInput, TimeInputProps } from "../time-input/TimeInput.component"

type TimeInputPropsWithoutValue = Omit<TimeInputProps, "value" | "onChangeValue">

export interface TimeRangeProps {
    startTime?: string
    endTime?: string
    onTimeChange?: (startTime?: string, endTime?: string) => void
    divider?: string
    startTimeProps: TimeInputPropsWithoutValue
    endTimeProps: TimeInputPropsWithoutValue
}

export const TimeRange = (props: TimeRangeProps) => {
    const { startTimeProps, endTimeProps, divider = "-", startTime, endTime, onTimeChange } = props

    // build both start time and end time if only one is selected
    const startTimeChange = (selectedTime?: string) => {
        onTimeChange?.(selectedTime, endTime)
    }

    const endTimeChange = (selectedTime?: string) => {
        onTimeChange?.(startTime, selectedTime)
    }

    return (
        <div className={styles.container}>
            <TimeInput {...startTimeProps} onChangeValue={startTimeChange} value={startTime} />
            {divider}
            <TimeInput {...endTimeProps} onChangeValue={endTimeChange} value={endTime} />
        </div>
    )
}
