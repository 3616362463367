import React from "react"

import { StatsApi, DeviceStats, ServicesStats, AccessTiersStats } from "../api/Stats.api"
import { Singleton } from "../decorators/Singleton.decorator"
import ServiceContext from "./context"
import { LocalizationService } from "./localization/Localization.service"
import { SecureService, RoleSecure } from "./Secure.service"

@Singleton("StatsService")
export class StatsService {
    public getDevicesStatsDisplayData(): Promise<DevicesStatsDisplayData> {
        return this.statsApi.getDeviceStats().then((response: DeviceStats) => {
            const trustLevelData: DisplayData[] = [
                {
                    displayName: this.ls.getString("allow"),
                    value: response.trust_level.always_allow,
                },
                { displayName: this.ls.getString("high"), value: response.trust_level.high },
                { displayName: this.ls.getString("medium"), value: response.trust_level.medium },
                { displayName: this.ls.getString("low"), value: response.trust_level.low },
                { displayName: this.ls.getString("deny"), value: response.trust_level.always_deny },
                { displayName: this.ls.getString("pending"), value: response.trust_level.unknown },
            ]

            const osData: DisplayData[] = Object.keys(response.os).map((osName) => {
                return { displayName: osName, value: response.os[osName] }
            })

            return { total: response.total, trustLevel: trustLevelData, os: osData }
        })
    }

    public getServicesStatsDisplayData(): Promise<ServicesStatsDisplayData> {
        return this.statsApi.getServicesStats().then((response: ServicesStats) => {
            const typeData: DisplayData[] = [
                { displayName: this.ls.getString("hosted"), value: response.type.hosted },
                { displayName: this.ls.getString("saasApp"), value: response.type.saas_app },
                { displayName: this.ls.getString("idpRouted"), value: response.type.idp_routed },
            ]

            return { total: response.total, type: typeData }
        })
    }

    public getAccessTiersStatsDisplayData(): Promise<AccessTiersStatsDisplayData> {
        return this.statsApi.getAccessTiersStats().then((response: AccessTiersStats) => {
            const typeData: DisplayData[] = [
                { displayName: this.ls.getString("reporting"), value: response.status.reporting },
                { displayName: this.ls.getString("terminated"), value: response.status.terminated },
                { displayName: this.ls.getString("pending"), value: response.status.pending },
                { displayName: this.ls.getString("inactive"), value: response.status.inactive },
            ]

            return { total: response.total, status: typeData }
        })
    }

    public async getPoliciesStatsDisplayData(): Promise<PoliciesStatsDisplayData> {
        const policiesStats = await this.statsApi.getPoliciesStats()

        const typeData: DisplayData[] = [
            {
                displayName: this.ls.getString("enforcing"),
                value: policiesStats.status.enforcing,
            },
            {
                displayName: this.ls.getString("permissive"),
                value: policiesStats.status.permissive,
            },
            {
                displayName: this.ls.getString("inactive"),
                value: policiesStats.status.inactive,
            },
            {
                displayName: this.ls.getString("mixed"),
                value: policiesStats.status.mixed_enforcing_permissive,
            },
        ]

        return { total: policiesStats.total, type: typeData }
    }

    public getRolesStatsDisplayData(): Promise<TotalDisplayData> {
        return this.secureService.getRoles().then((response: RoleSecure[]) => {
            return {
                total: response.length,
            }
        })
    }

    public async getUserCount(): Promise<number> {
        const { total } = await this.statsApi.getUsersStats()
        return total
    }

    private statsApi = new StatsApi()
    private secureService = new SecureService()
    private ls: LocalizationService = new LocalizationService()
}

export interface DevicesStatsDisplayData {
    total: number
    trustLevel: DisplayData[]
    os: DisplayData[]
}

export interface ServicesStatsDisplayData {
    total: number
    type: DisplayData[]
}

export interface AccessTiersStatsDisplayData {
    total: number
    status: DisplayData[]
}

export interface DisplayData {
    displayName: string
    value: number
}

export interface PoliciesStatsDisplayData {
    total: number
    type: DisplayData[]
}

export interface TotalDisplayData {
    total: number
}

export const useServiceStats = () => React.useContext(ServiceContext)?.stats || new StatsService()
