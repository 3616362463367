import React, { ReactNode } from "react"
import ServicesEditDeleteDialogTemplate from "./ServicesEditDeleteDialog.template"
import { ServiceManage, ManageService } from "../../../../services"
import { LocalizationService } from "../../../../services/localization/Localization.service"
import { Bind } from "../../../../decorators/Bind.decorator"

export class ServicesEditDeleteDialog extends React.Component<
    ServicesEditDeleteDialogProps,
    ServicesEditDeleteDialogState
> {
    public state: ServicesEditDeleteDialogState = {}

    public render(): ReactNode {
        return ServicesEditDeleteDialogTemplate.call(this)
    }

    private localizationService: LocalizationService = new LocalizationService()
    private manageService: ManageService = new ManageService()

    @Bind
    private onConfirm(): void {
        if (!this.props.service) {
            this.props.onClose(false)
            return
        }

        this.setState({ loading: true, error: "" })
        this.manageService.deleteRegisteredService(this.props.service.id).then(
            () => {
                this.setState({ loading: false, error: "" })
                this.props.onClose(true)
            },
            (err: Error) => {
                this.setState({ loading: false, error: err.message })
            }
        )
    }
}

interface ServicesEditDeleteDialogProps {
    service: ServiceManage
    open: boolean
    onClose: (success: boolean) => void
}

interface ServicesEditDeleteDialogState {
    loading?: boolean
    error?: string
}
