import React, { FC } from "react"
import { ModalRef } from "../../../../pre-v3/services/Modal.service"
import { MspOrgForm } from "../form/MspOrgForm.component"
import styles from "./createMspOrgModal.module.scss"

interface Props {
    modalRef: ModalRef
}
export const CreateMspOrgModal: FC<Props> = ({ modalRef }) => {
    function onCancel() {
        modalRef.close(null)
    }

    return (
        <div className={styles.form}>
            <MspOrgForm onCancel={onCancel} isCreate afterSubmit={() => modalRef.close(null)} />
        </div>
    )
}
