import React, { ReactNode } from "react"
import TextBlockTemplate from "./TextBlock.template"

export class TextBlock extends React.Component<TextBlockProps, {}> {
    public render(): ReactNode {
        return TextBlockTemplate.call(this)
    }
}

interface TextBlockProps {
    data: {
        jsonString?: string
    }
}
