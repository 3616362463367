import React from "react"
import { useServiceLocalization } from "../../../../pre-v3/services/localization/Localization.service"
import styles from "./TrustIntegrationForm.module.scss"

// Note: Old components, move them to v3
import { InputWithHide } from "../../../../pre-v3/components/input-with-hide/InputWithHide.component"
import { FormLabel } from "../../../../pre-v3/components/form-label/FormLabel"
import { Input } from "../../../components/input/Input.component"

export interface Props {
    clientId: string
    onClientIdChange: (clientId: string) => void
    saasUrl: string
    onSaasUrlChange: (saasUrl: string) => void
    tokenUrl: string
    onTokenUrlChange: (tokenUrl: string) => void
    clientSecret: string
    onClientSecretChange: (apiSecret: string) => void
    disabled: boolean
    canWriteAll?: boolean
}

export function WorkspaceOneApiCredentials(props: Props) {
    const {
        clientId,
        onClientIdChange,
        saasUrl,
        onSaasUrlChange,
        tokenUrl,
        onTokenUrlChange,
        clientSecret,
        onClientSecretChange,
        disabled,
        canWriteAll,
    } = props

    const localization = useServiceLocalization()

    return (
        <>
            <FormLabel title={localization.getString("tokenUrl")}>
                <Input
                    type="text"
                    value={tokenUrl}
                    onChangeValue={onTokenUrlChange}
                    placeholder={localization.getString("tokenUrlPlaceholder")}
                    className={styles.input}
                    disabled={disabled}
                    required
                />
            </FormLabel>
            <FormLabel title={localization.getString("saasUrl")}>
                <Input
                    type="text"
                    value={saasUrl}
                    onChangeValue={onSaasUrlChange}
                    placeholder={localization.getString("saasUrlPlaceholder")}
                    className={styles.input}
                    disabled={disabled}
                    required
                />
            </FormLabel>
            <FormLabel title={localization.getString("clientId")}>
                <Input
                    type="text"
                    value={clientId}
                    onChangeValue={onClientIdChange}
                    placeholder={localization.getString("enterClientId")}
                    className={styles.input}
                    disabled={disabled}
                    required
                />
            </FormLabel>
            <FormLabel title={localization.getString("clientSecret")}>
                {disabled ? (
                    <InputWithHide
                        className={styles.input}
                        disabled
                        value={clientSecret}
                        hideEyeIcon={!props.canWriteAll}
                    />
                ) : (
                    <Input
                        type="text"
                        value={clientSecret}
                        onChangeValue={onClientSecretChange}
                        placeholder={localization.getString("enterClientSecret")}
                        className={styles.input}
                        disabled={disabled}
                        required
                    />
                )}
            </FormLabel>
        </>
    )
}
