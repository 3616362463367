import {
    faCheckCircle,
    faExclamationCircle,
    faQuestionCircle,
    IconDefinition,
} from "@fortawesome/pro-solid-svg-icons"
import React from "react"
import { useHistory, useParams } from "react-router-dom"

import { IconType } from "../../../../pre-v3/services/ActionBar.service"
import { useServiceLocalization } from "../../../../pre-v3/services/localization/Localization.service"
import { LanguageKey } from "../../../../pre-v3/services/localization/languages/en-US.language"
import { useServiceModal } from "../../../../pre-v3/services/Modal.service"
import { decodeID } from "../../../../pre-v3/utils/Url.util"
import { ROUTE } from "../../../../routes"
import { ErrorBanner, SuccessBanner } from "../../../components/banner/Banner.component"
import { Container } from "../../../components/container/Container.component"
import { Loader } from "../../../components/loader/Loader.component"
import { OverviewTopContainer } from "../../../components/overview/OverviewTopContainer/OverviewTopContainer.component"
import { TabBar, TabProps } from "../../../components/tab-bar/TabBar.component"
import {
    RegisteredDomainStatus,
    useDeleteRegisteredDomain,
    useGetClusters,
    useGetRegisteredDomainById,
    useValidateRegisteredDomain,
} from "../../../services/RegisteredDomain.service"
import { RegisteredDomainConfigurationForm } from "../configuration-form/RegisteredDomainConfigurationForm.component"
import { RegisteredDomainDnsForm } from "../dns-form/RegisteredDomainDnsForm.component"
import styles from "./RegisteredDomainOverview.module.scss"
import { DateUtil } from "../../../../pre-v3/utils/Date.util"
import { StatusType } from "../../../components/status/Status.component"
import { ErrorStateScreen } from "../../../components/page-screen/PageScreen.component"
import Illustration from "../../../../images/upgrade-idp.svg"
import { PageHeading } from "../../../../components/page-heading/PageHeading.component"
import { Button, ButtonElement, ButtonType } from "../../../../components/button/Button.component"
import { Tooltip } from "../../../components/tooltip/Tooltip.component"

interface Props {
    enableAccessTierGroups: boolean
    canDeleteRegisteredDomain?: boolean
}

export function RegisteredDomainOverview(props: Props): JSX.Element {
    const params = useParams<{ id: string }>()
    const ls = useServiceLocalization()
    const modalService = useServiceModal()
    const history = useHistory()

    const tabs: TabProps[] = [
        {
            id: "configuration",
            label: ls.getString("configuration"),
            ariaControls: "configuration-tab",
        },
        {
            id: "dnsSettings",
            label: ls.getString("dnsSettings"),
            ariaControls: "dnsSettings-tab",
        },
    ]
    const [currentTab, setCurrentTab] = React.useState("configuration")
    const [validateMessage, setValidateMessage] = React.useState("")

    const clustersQuery = useGetClusters(props.enableAccessTierGroups)

    const registeredDomainQuery = useGetRegisteredDomainById(
        params.id && decodeID(params.id),
        props.enableAccessTierGroups,
        {
            enabled: !!params.id,
        }
    )

    const validateDomain = useValidateRegisteredDomain({
        onSuccess: () => setValidateMessage(ls.getString("validateRegisteredDomainSuccessMessage")),
    })

    const deleteRegisteredDomain = useDeleteRegisteredDomain({
        onSuccess: () => {
            history.replace(ROUTE.REGISTERED_DOMAINS)
        },
    })

    function onValidate() {
        if (!registeredDomainQuery.data) return

        setValidateMessage("")
        validateDomain.mutate(registeredDomainQuery.data.id)
    }

    function onDelete(): void {
        modalService
            .openDelete(
                ls.getString("deleteRegisteredDomain"),
                ls.getString("deleteRegisteredDomainExplanation")
            )
            .onClose(
                () =>
                    registeredDomainQuery.data &&
                    deleteRegisteredDomain.mutate(registeredDomainQuery.data.id)
            )
    }

    function fetchData(): void {
        registeredDomainQuery.refetch()
    }

    if (
        registeredDomainQuery.status === "loading" ||
        clustersQuery.status === "loading" ||
        deleteRegisteredDomain.isLoading ||
        validateDomain.isLoading
    ) {
        return (
            <Loader
                title={ls.getString("loadingSomething", ls.getString("registeredDomain"))}
                center
                medium
            />
        )
    }

    if (!params.id || registeredDomainQuery.status === "error") {
        return (
            <ErrorStateScreen
                title={ls.getString("registeredDomainNotFound")}
                backLink={ROUTE.REGISTERED_DOMAINS}
                buttonText={ls.getString("refresh")}
                onClick={fetchData}
            >
                <div>
                    <img src={Illustration} className={styles.screen} alt="" />
                </div>
            </ErrorStateScreen>
        )
    }

    if (clustersQuery.status === "error") {
        return (
            <Container>
                <ErrorBanner children={String(clustersQuery.error)} />
            </Container>
        )
    }

    const { data: registeredDomain } = registeredDomainQuery

    const statusItems = [
        {
            label: ls.getString("createdAt"),
            value: DateUtil.format(registeredDomain.createdAt),
        },
        {
            label: ls.getString("createdBy"),
            value: registeredDomain.createdBy,
        },
    ]

    return (
        <section aria-labelledby={Id.HEADING} className={styles.container}>
            {deleteRegisteredDomain.error && (
                <ErrorBanner>{deleteRegisteredDomain.error}</ErrorBanner>
            )}
            {validateDomain.error && <ErrorBanner>{validateDomain.error}</ErrorBanner>}
            {validateMessage && <SuccessBanner>{validateMessage}</SuccessBanner>}
            <header className={styles.header} aria-labelledby={Id.HEADING}>
                <PageHeading id={Id.HEADING}>{registeredDomain.name}</PageHeading>
                <div className={styles.actions}>
                    <Tooltip title={ls.getString("validate")}>
                        <Button
                            icon={IconType.CHECK}
                            onClick={onValidate}
                            asElement={ButtonElement.BUTTON}
                            buttonType={ButtonType.SECONDARY}
                            aria-label={ls.getString("validate")}
                        />
                    </Tooltip>
                    {props.canDeleteRegisteredDomain && (
                        <Tooltip title={ls.getString("deleteService")}>
                            <Button
                                icon={IconType.TRASH}
                                onClick={onDelete}
                                asElement={ButtonElement.BUTTON}
                                buttonType={ButtonType.SECONDARY}
                                aria-label={ls.getString("deleteService")}
                            />
                        </Tooltip>
                    )}
                    <Tooltip title={ls.getString("refresh")}>
                        <Button
                            icon={IconType.REDO}
                            onClick={fetchData}
                            asElement={ButtonElement.BUTTON}
                            buttonType={ButtonType.SECONDARY}
                            aria-label={ls.getString("refresh")}
                        />
                    </Tooltip>
                </div>
            </header>
            <OverviewTopContainer
                statusType={statusTypeDict[registeredDomain.status]}
                statusLabel={ls.getString(statusLanguageKeyDict[registeredDomain.status])}
                icon={statusIconDict[registeredDomain.status]}
                statusItems={statusItems}
            />
            <TabBar tabs={tabs} selectedTabId={currentTab} onChange={setCurrentTab} />
            {currentTab === "configuration" && (
                <RegisteredDomainConfigurationForm
                    id="configuration-tab"
                    registeredDomain={registeredDomain}
                    clusters={clustersQuery.data}
                    enableAccessTierGroups={props.enableAccessTierGroups}
                    disabled
                />
            )}
            {currentTab === "dnsSettings" && (
                <RegisteredDomainDnsForm
                    id="dnsSettings-tab"
                    registeredDomain={registeredDomain}
                    disabled
                />
            )}
        </section>
    )
}

const statusIconDict: Record<RegisteredDomainStatus, IconDefinition> = {
    [RegisteredDomainStatus.PENDING]: faQuestionCircle,
    [RegisteredDomainStatus.VERIFIED]: faCheckCircle,
    [RegisteredDomainStatus.FAILED]: faExclamationCircle,
}

const statusLanguageKeyDict: Record<RegisteredDomainStatus, LanguageKey> = {
    [RegisteredDomainStatus.PENDING]: "pending",
    [RegisteredDomainStatus.VERIFIED]: "verified",
    [RegisteredDomainStatus.FAILED]: "failed",
}

const statusTypeDict: Record<RegisteredDomainStatus, StatusType> = {
    [RegisteredDomainStatus.PENDING]: "unknown",
    [RegisteredDomainStatus.VERIFIED]: "success",
    [RegisteredDomainStatus.FAILED]: "error",
}

enum Id {
    HEADING = "heading",
}
