import { IconDefinition } from "@fortawesome/pro-solid-svg-icons"
import React from "react"

import {
    CheckboxGroupBase,
    CheckboxGroupBaseOption,
    CheckboxGroupBaseProps,
} from "../base/CheckboxGroupBase.component"
import { Icon } from "../Icon/Icon.component"
import styles from "./LargeCheckboxGroup.module.scss"

export interface LargeCheckboxGroupProps extends Omit<CheckboxGroupBaseProps, "options"> {
    value: string[]
    options: LargeCheckboxGroupOption[]
    disabled?: boolean
    required?: boolean
    onChange(value: string[]): void
}

export function LargeCheckboxGroup(props: LargeCheckboxGroupProps): JSX.Element {
    const options = React.useMemo(
        () => props.options.map(mapCheckboxGroupBaseOption),
        [props.options]
    )

    return (
        <CheckboxGroupBase
            {...props}
            options={options}
            selectAllClassName={styles.selectAll}
            checkboxItemClassName={styles.checkboxItem}
        />
    )
}

export interface LargeCheckboxGroupOption extends Omit<CheckboxGroupBaseOption, "children"> {
    id: string
    label: string
    icon: IconDefinition
}

function mapCheckboxGroupBaseOption(option: LargeCheckboxGroupOption): CheckboxGroupBaseOption {
    const { label, icon, ...rest } = option
    return {
        ...rest,
        children: (
            <div className={styles.checkboxChildren}>
                <Icon icon={icon} large />
                {label}
            </div>
        ),
        className: styles.checkbox,
        iconClassName: styles.icon,
    }
}
