import { BaseApi, ApiResponse } from "./Base.api"
import { Singleton } from "../decorators/Singleton.decorator"

@Singleton("ReportingApi")
export class ReportingApi extends BaseApi {
    public getUserAccessActivityReport(
        email: string,
        startTime: number,
        endTime: number
    ): Promise<ApiResponse<EndUserAccessActivityRes>> {
        const queryParams: URLSearchParams = new URLSearchParams({
            report_type: ReportType.USER_ACCESS_ACTIVITY,
            email,
            start_time: startTime.toString(),
            end_time: endTime.toString(),
        })
        return this.get(`/api/experimental/v2/report?${queryParams.toString()}`)
    }

    public getServiceAccessActivityReport(
        serviceId: string,
        startTime: number,
        endTime: number
    ): Promise<ApiResponse<ServiceAccessActivityRes>> {
        const queryParams: URLSearchParams = new URLSearchParams({
            report_type: ReportType.SERVICE_ACCESS_ACTIVITY,
            service_id: encodeURIComponent(serviceId),
            start_time: startTime.toString(),
            end_time: endTime.toString(),
        })
        return this.get(`/api/experimental/v2/report?${queryParams.toString()}`)
    }

    public getTopReport<T>(
        reportType: ReportType,
        startTime: number,
        endTime: number,
        limit: number
    ): Promise<ApiResponse<T>> {
        const queryParams: URLSearchParams = new URLSearchParams({
            report_type: reportType,
            limit: limit.toString(),
            start_time: startTime.toString(),
            end_time: endTime.toString(),
        })
        return this.get(`/api/experimental/v2/report?${queryParams.toString()}`)
    }
}

export interface EndUserAccessActivityRes {
    service_devices: AccessActivityRes[]
}

export interface ServiceAccessActivityRes {
    enduser_devices: AccessActivityRes[]
}

export interface TopServicesRes {
    services: (ObjectCount<"name"> & { id: string })[]
}

export interface TopEndUsersRes {
    endusers: ObjectCount<"email">[]
}

export interface TopServicesByPlatformRes {
    services: ServicePlatformAccessRes[]
}

export interface TopServicesByTrustLevelRes {
    services: ServiceTrustLevelAccessRes[]
}

export interface TopServicesByPlatformTrustLevelRes {
    services: ServicePlatformAccessTrustLevelRes[]
}

interface ServicePlatformAccessRes {
    name: string
    id: string
    platform_access_counts: ObjectCount<"platform">[]
}

interface ServiceTrustLevelAccessRes {
    name: string
    id: string
    trustlevel_access_counts: ObjectCount<"trustlevel">[]
}

interface ServicePlatformAccessTrustLevelRes {
    name: string
    id: string
    platforms: PlatformByTrustLevelRes[]
}

interface PlatformByTrustLevelRes {
    name: string
    trustlevel_access_counts: ObjectCount<"trustlevel">[]
}

export interface AccessActivityRes {
    service_name?: string
    service_id?: string
    device_name?: string
    serial_number?: string
    email?: string
    user_name?: string
    last_authorized_timestamp: number
    last_unauthorized_timestamp: number
}

export enum ReportType {
    USER_ACCESS_ACTIVITY = "service_device_accessed_by_user",
    SERVICE_ACCESS_ACTIVITY = "user_device_accessed_service",
    TOP_SERVICES_BY_USERS_ALLOWED = "top_services_by_users_allowed",
    TOP_SERVICES_BY_USERS_BLOCKED = "top_services_by_users_blocked",
    TOP_USERS_BY_SERVICES_ACCESSED = "top_users_by_service_access",
    TOP_SERVICES_ACCESSED_BY_UNREGISTERED_DEVICES = "top_services_allowed_by_unreg_devices",
    TOP_USERS_USING_UNREGISTERED_DEVICES = "top_unreg_device_users_allowed",
    TOP_SERVICES_ALLOWED_BY_PLATFORM = "top_services_allowed_by_platform",
    TOP_SERVICES_BLOCKED_BY_PLATFORM = "top_services_blocked_by_platform",
    TOP_SERVICES_ALLOWED_BY_TRUST_LEVEL = "top_services_allowed_by_trustlevel",
    TOP_SERVICES_BLOCKED_BY_TRUST_LEVEL = "top_services_blocked_by_trustlevel",
    TOP_SERVICES_ALLOWED_BY_PLATFORM_TRUST_LEVEL = "top_services_allowed_by_platform_trustlevel",
    TOP_SERVICES_BLOCKED_BY_PLATFORM_TRUST_LEVEL = "top_services_blocked_by_platform_trustlevel",
}

export enum DevicePlatform {
    ANDROID = "Android",
    APPLE = "Apple",
    IOS = "iOS",
    LINUX = "Linux",
    MACOS = "macOS",
    OTHER = "Other",
    UNKNOWN = "UNKNOWN",
    WINDOWS = "Windows",
    WIN_RT = "WinRT",
    UNREGISTERED = "Unregistered",
}
