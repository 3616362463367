export class MasterService {
    public getService<T>(constructor: any, serviceName: string, args: any[] = []): T {
        if (!this.services[serviceName]) {
            this.services[serviceName] = Reflect.construct(constructor, args)
        }
        return this.services[serviceName]
    }

    private services: { [key: string]: any } = {}
}

declare global {
    interface QueryOptions<ResponseType = void, ErrorType = string, ParameterType = void> {
        onSuccess?(data: ResponseType, params?: ParameterType, context?: any): void
        onError?(err: ErrorType): void
        onSettled?(): void
        enabled?: boolean
        placeholderData?: ResponseType | (() => ResponseType)
        refetchInterval?: number
        keepPreviousData?: boolean
    }
}
