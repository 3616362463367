import React, { FormEvent, useMemo, useState } from "react"

import {
    Button,
    ButtonElement,
    ButtonType,
} from "../../../../../components/button/Button.component"
import { useServiceLocalization } from "../../../../../pre-v3/services/localization/Localization.service"
import { FormUtil } from "../../../../../pre-v3/utils/Form.util"
import { PatternUtil } from "../../../../../pre-v3/utils/Pattern.util"
import { SelectItem } from "../../../../../pre-v3/utils/SelectValue.util"
import { ErrorBanners } from "../../../../components/banner/Banner.component"
import { CheckboxGroup } from "../../../../components/checkbox-group/CheckboxGroup.component"
import { FormButtons } from "../../../../components/form/form-buttons/FormButtons.component"
import { Form } from "../../../../components/form/Form.component"
import { FormGroup } from "../../../../components/form/FormGroup.component"
import { FormRow } from "../../../../components/form/FormRow.component"
import { Input } from "../../../../components/input/Input.component"
import {
    LargeCheckboxGroup,
    LargeCheckboxGroupOption,
} from "../../../../components/large-checkbox-group/LargeCheckboxGroup.component"
import { SelectWithInput } from "../../../../components/select-with-text-input/SelectWithInput.component"
import {
    DesktopPlatform,
    Platform,
    desktopPlatforms,
    maxFilePathLengthDict,
    platformFilePathPlaceholderDict,
    platformIconDict,
    platformLabelDict,
    platformFilePathDescriptionDict,
    prefixOptionsDict,
    validFilePathDict,
} from "../../../../services/shared/Platform"
import { FileCheckConfiguration, NewFile } from "../../../../services/TrustFactor.service"
import styles from "./FileCheckForm.module.scss"

interface Props<File extends NewFile> {
    id?: string
    defaultValue?: File
    onSubmit?: (file: NewFile) => void
    onCancel?: () => void
    submitting?: boolean
    hideActions?: boolean
    errors?: React.ReactNode[]
    compact?: boolean
    canAccessFileCheckFactor?: boolean
}

export function FileCheckForm<File extends NewFile>(props: Props<File>): JSX.Element {
    const localization = useServiceLocalization()

    const [selectedPlatforms, setSelectedPlatforms] = useState(
        (): Exclude<DesktopPlatform, Platform.CHROME>[] => {
            if (!props.defaultValue) return []

            const { configurations } = props.defaultValue

            const selected: Exclude<DesktopPlatform, Platform.CHROME>[] = []

            if (configurations.MACOS?.filePath) selected.push(Platform.MACOS)
            if (configurations.WINDOWS?.filePath) selected.push(Platform.WINDOWS)
            if (configurations.LINUX?.filePath) selected.push(Platform.LINUX)

            return selected
        }
    )

    const platformOptions = useMemo<LargeCheckboxGroupOption[]>(() => {
        return desktopPlatforms.map((platform: DesktopPlatform): LargeCheckboxGroupOption => {
            return {
                id: platform,
                label: localization.getString(platformLabelDict[platform]),
                icon: platformIconDict[platform],
            }
        })
    }, [])

    function onPlatformChange(value: Exclude<DesktopPlatform, Platform.CHROME>[]): void {
        setSelectedPlatforms(value.sort(comparePlatforms))
    }

    function getConfigurationFromForm(
        event: FormEvent<HTMLFormElement>,
        platform: DesktopPlatform
    ): FileCheckConfiguration | undefined {
        const prefix = FormUtil.getFieldValue(event, `${platform}-file-selection`)
        const path = FormUtil.getFieldValue(event, `${platform}-file-value`)
        const sha256 = FormUtil.getFieldValue(event, `${platform}-sha256`)

        if (path) {
            return {
                filePath: {
                    prefix: prefix,
                    path: path,
                },
                sha256Hash: sha256 ? sha256.toLowerCase() : undefined,
            }
        } else {
            return undefined
        }
    }

    function onSubmit(event: FormEvent<HTMLFormElement>): void {
        event.preventDefault()
        event.stopPropagation()

        const name: string = FormUtil.getFieldValue(event, "name")
        const macos: FileCheckConfiguration | undefined = getConfigurationFromForm(
            event,
            Platform.MACOS
        )
        const windows: FileCheckConfiguration | undefined = getConfigurationFromForm(
            event,
            Platform.WINDOWS
        )
        const linux: FileCheckConfiguration | undefined = getConfigurationFromForm(
            event,
            Platform.LINUX
        )

        props.onSubmit?.({
            name: name,
            configurations: {
                [Platform.MACOS]: macos,
                [Platform.WINDOWS]: windows,
                [Platform.LINUX]: linux,
                [Platform.CHROME]: undefined,
            },
        })
    }

    return (
        <Form id={props.id} onSubmit={onSubmit}>
            <FormRow
                label={localization.getString("fileName")}
                htmlFor="name"
                description={localization.getString("fileNameDescription")}
            >
                <Input
                    id="name"
                    name="name"
                    defaultValue={props.defaultValue?.name}
                    placeholder={localization.getString("fileNamePlaceholder")}
                    required
                    disabled={!props.canAccessFileCheckFactor}
                />
            </FormRow>
            <FormRow label={localization.getString("applicableOperatingSystems")}>
                {props.compact ? (
                    <CheckboxGroup
                        options={platformOptions}
                        value={selectedPlatforms}
                        onChange={onPlatformChange}
                        required
                        disabled={!props.canAccessFileCheckFactor}
                    />
                ) : (
                    <LargeCheckboxGroup
                        options={platformOptions}
                        value={selectedPlatforms}
                        onChange={onPlatformChange}
                        required
                        disabled={!props.canAccessFileCheckFactor}
                    />
                )}
            </FormRow>
            {selectedPlatforms.map((p) => (
                <PlatformConfiguration
                    key={p}
                    platform={p}
                    defaultValue={props.defaultValue?.configurations[p]}
                    canAccessFileCheckFactor={props.canAccessFileCheckFactor}
                />
            ))}
            {props.canAccessFileCheckFactor && !props.hideActions && (
                <FormButtons
                    rightButtons={
                        <>
                            {props.onCancel && (
                                <Button
                                    asElement={ButtonElement.BUTTON}
                                    buttonType={ButtonType.SECONDARY}
                                    onClick={props.onCancel}
                                >
                                    {localization.getString("cancel")}
                                </Button>
                            )}
                            <Button
                                asElement={ButtonElement.BUTTON}
                                buttonType={ButtonType.PRIMARY}
                                loading={props.submitting}
                                type="submit"
                            >
                                {localization.getString("save")}
                            </Button>
                        </>
                    }
                >
                    {props.errors && <ErrorBanners errors={props.errors} />}
                </FormButtons>
            )}
        </Form>
    )
}

interface PlatformConfigurationProps {
    platform: Exclude<DesktopPlatform, Platform.CHROME>
    defaultValue?: FileCheckConfiguration
    canAccessFileCheckFactor?: boolean
}

function PlatformConfiguration(props: PlatformConfigurationProps) {
    const localization = useServiceLocalization()

    const options: SelectItem[] = useMemo(() => {
        return prefixOptionsDict[props.platform]
    }, [props.platform])

    const platformLabel = localization.getString(platformLabelDict[props.platform])
    const filePathLabel = localization.getString("filePath")
    const sha256HashLabel = localization.getString("sha256Hash")

    return (
        <FormGroup label={platformLabel} icon={platformIconDict[props.platform]}>
            <FormRow
                label={filePathLabel}
                description={localization.getString(
                    platformFilePathDescriptionDict[props.platform]
                )}
                childrenClassName={styles.doubleFlex}
            >
                <SelectWithInput
                    options={options}
                    name={`${props.platform}-file`}
                    required
                    placeholder={localization.getString(
                        platformFilePathPlaceholderDict[props.platform]
                    )}
                    pattern={validFilePathDict[props.platform].source}
                    maxLength={maxFilePathLengthDict[props.platform]}
                    defaultValue={{
                        value: props.defaultValue?.filePath.path,
                        selection: props.defaultValue?.filePath.prefix,
                    }}
                    inputAriaLabel={`${platformLabel} ${filePathLabel}`}
                    disabled={!props.canAccessFileCheckFactor}
                />
            </FormRow>
            <FormRow
                label={sha256HashLabel}
                description={localization.getString("sha256HashDescription")}
                childrenClassName={styles.doubleFlex}
            >
                <Input
                    name={`${props.platform}-sha256`}
                    type="text"
                    defaultValue={props.defaultValue?.sha256Hash}
                    placeholder={localization.getString("sha256Placeholder")}
                    pattern={PatternUtil.SHA256.source}
                    aria-label={localization.getString(
                        "somethingOptional",
                        `${platformLabel} ${sha256HashLabel}`
                    )}
                    disabled={!props.canAccessFileCheckFactor}
                />
            </FormRow>
        </FormGroup>
    )
}

function comparePlatforms(
    platformA: Exclude<DesktopPlatform, Platform.CHROME>,
    platformB: Exclude<DesktopPlatform, Platform.CHROME>
): number {
    return platformOrder[platformA] - platformOrder[platformB]
}

const platformOrder: Record<Exclude<DesktopPlatform, Platform.CHROME>, number> = {
    [Platform.MACOS]: -1,
    [Platform.WINDOWS]: 0,
    [Platform.LINUX]: 1,
}
