import * as React from "react"
import { Button, ButtonProps } from ".."
import { useServiceLocalization } from "../../services/localization/Localization.service"
import styles from "./CopyButton.module.scss"
import { faCopy, faCheckCircle } from "@fortawesome/pro-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import classnames from "classnames"

export function CopyButton({ copyText, ...props }: ButtonProps & { copyText: string }) {
    const localization = useServiceLocalization()
    const [success, setSuccess] = React.useState(false)

    // show the check icon when we're done
    const icon = success ? faCheckCircle : faCopy

    const performCopy = () => {
        ;(navigator as any).clipboard.writeText(copyText)

        // indicate we did it
        setSuccess(true)

        // reset the success state
        setTimeout(() => setSuccess(false), 1000)
    }

    return (
        <Button
            primary
            {...props}
            onClick={performCopy}
            className={classnames(styles.button, props.className)}
        >
            <FontAwesomeIcon
                icon={icon}
                className={classnames(styles.icon, { [styles.success]: success })}
            />
            &nbsp;{localization.getString("copy")}
        </Button>
    )
}

export function CopyButtonCellRenderer(props: { displayValue: boolean; getValue: () => string }) {
    return (
        <div>
            {props.displayValue && props.getValue()}
            <CopyButton copyText={props.getValue()} primary={false} className={styles.copyButton} />
        </div>
    )
}
