import * as React from "react"
import styles from "./PageScreen.module.scss"

import { Button } from "../button/Button.component"
import { useServiceLocalization } from "../../../pre-v3/services/localization/Localization.service"
import { Link } from "../link/Link.component"
import { IconDefinition } from "@fortawesome/fontawesome-common-types"
import { AppText } from "../app-text/AppText.component"
import { ButtonLink } from "../button-link/ButtonLink.component"
import { useHistory } from "react-router-dom"
import { Icon } from "../Icon/Icon.component"

export function EmptyStateScreen(props: EmptyScreenProps) {
    return (
        <div className={styles.screenContainer}>
            <div className={styles.screenText}>
                {props.title && <h1 className={styles.heading}>{props.title}</h1>}
                {props.subText && <p>{props.subText}</p>}
                {props.children}
            </div>
            {props.buttonProps && (
                <Link to={props.buttonProps.buttonLink}>
                    <Button
                        brand
                        icon={props.buttonProps.buttonIcon}
                        className={props.className}
                        onClick={props.buttonProps.onClick}
                    >
                        {props.buttonProps.buttonText}
                    </Button>
                </Link>
            )}
        </div>
    )
}

export function ErrorStateScreen(props: ErrorStateScreenProps) {
    const history = useHistory()

    function goBack(): void {
        if (history.length > 0) {
            history.goBack()
        } else {
            history.push(props.backLink)
        }
    }

    return (
        <div className={styles.screenContainer}>
            <div className={styles.screenText}>
                {props.title && <h1 className={styles.heading}>{props.title}</h1>}
                {props.subText && <p>{props.subText}</p>}
            </div>
            {props.children}
            <div className={styles.buttons}>
                <Button onClick={goBack}>Go Back</Button>
                <Button brand onClick={props.onClick}>
                    {props.buttonText}
                </Button>
            </div>
        </div>
    )
}

export function ContactUsScreen(props: ContactUsScreenProps) {
    const localization = useServiceLocalization()

    const { children, title, image, docsLink, contactUsLink } = props
    return (
        <div className={styles.contactUs}>
            <img
                className={styles.contactUsImage}
                src={image}
                alt={localization.getString("image")}
            />
            <div className={styles.contactUsSection}>
                <AppText className={styles.contactUsTitle}>{title}</AppText>
                {props.subText && <p className={styles.subText}>{props.subText}</p>}
                {children}
                <AppText className={styles.subText}>
                    {localization.getString("contactSalesToUpgrade")}
                </AppText>
                {(contactUsLink || docsLink) && (
                    <div className={styles.contactUsButtonGroup}>
                        {docsLink && (
                            <ButtonLink className={styles.contactUsButton} to={docsLink}>
                                {localization.getString("learnMoreInDocs")}
                            </ButtonLink>
                        )}
                        {contactUsLink && (
                            <ButtonLink brand className={styles.contactUsButton} to={contactUsLink}>
                                {localization.getString("contactUsToEnable")}
                            </ButtonLink>
                        )}
                    </div>
                )}
            </div>
        </div>
    )
}

export function ContactUsScreenSteps(props: ContactUsScreenStepsProps) {
    const { steps } = props
    const stepsExist = steps.length > 0

    if (!stepsExist) return null

    return (
        <div className={styles.contactUsSteps}>
            {steps.map((step, stepKey) => {
                const { label, icon } = step

                return (
                    <div key={stepKey} className={styles.contactUsStep}>
                        {icon && (
                            <div className={styles.contactUsStepIconWrapper}>
                                <Icon className={styles.contactUsStepIcon} icon={icon} />
                            </div>
                        )}
                        <div className={styles.contactUsStepLabel}>{label}</div>
                    </div>
                )
            })}
        </div>
    )
}

interface ContactUsScreenStepsProps {
    steps: { label: string; icon?: IconDefinition }[]
}

interface ContactUsScreenProps extends Props {
    image: string
    docsLink?: string
    contactUsLink?: string
}

export interface EmptyScreenProps extends Props {
    children?: React.ReactNode
    buttonProps?: ButtonProps
}

interface ButtonProps {
    buttonLink: string
    buttonText: string
    buttonIcon?: IconDefinition
    onClick?: React.MouseEventHandler<HTMLButtonElement>
}

interface Props {
    title?: string
    subText?: React.ReactNode
    children?: React.ReactNode
    className?: string
}

interface ErrorStateScreenProps {
    title: string
    subText?: string
    backLink: string
    buttonText: string
    onClick: () => void
    children?: React.ReactNode
}
