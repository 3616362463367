import React from "react"
import { useHistory, useParams } from "react-router-dom"

import {
    TrustIntegration,
    useGetTrustIntegration,
    useUpdateTrustIntegration,
} from "../../../services/TrustIntegration.service"
import { useServiceLocalization } from "../../../../pre-v3/services/localization/Localization.service"
import { decodeID, encodeID } from "../../../../pre-v3/utils/Url.util"
import { ROUTE, formatRoutePath } from "../../../../routes"
import { Loader } from "../../../components/loader/Loader.component"
import { LargeMessage } from "../../../components/large-message/LargeMessage.component"
import { TrustIntegrationForm } from "../form/TrustIntegrationForm.component"
import trustIntegrationStyles from "../TrustIntegration.module.scss"
import { PageHeading } from "../../../../components/page-heading/PageHeading.component"
import styles from "../TrustIntegration.module.scss"

export function EditTrustIntegration() {
    const localization = useServiceLocalization()
    const params: UrlParams = useParams()
    const history = useHistory()

    const {
        data: trustIntegration,
        isFetching: isFetchingTrustIntegration,
        refetch: refetchTrustIntegration,
        error: trustIntegrationError,
    } = useGetTrustIntegration(decodeID(params.id))

    const {
        mutate: onSubmit,
        isLoading: isSubmitLoading,
        error: editTrustIntegrationError,
    } = useUpdateTrustIntegration({
        onSuccess: (trustIntegration: TrustIntegration) => {
            history.push(
                formatRoutePath(ROUTE.INTEGRATIONS_DETAILS, {
                    id: encodeID(trustIntegration.id),
                })
            )
        },
    })

    const trustIntegrationNotFoundError = localization.getString("trustIntegrationNotFound")
    const trustIntegrationNotFound = trustIntegrationError === trustIntegrationNotFoundError

    const errors = [
        typeof trustIntegrationError === "string" && trustIntegrationError,
        typeof editTrustIntegrationError === "string" && editTrustIntegrationError,
    ]

    function onCancel(): void {
        history.push(
            formatRoutePath(ROUTE.INTEGRATIONS_DETAILS, {
                id: params.id,
            })
        )
    }

    return (
        <div className={styles.container}>
            <header>
                <PageHeading>{localization.getString("editIntegration")}</PageHeading>
            </header>
            <Loader isLoading={isFetchingTrustIntegration} center medium>
                {trustIntegrationNotFound && (
                    <LargeMessage className={trustIntegrationStyles.missingMessage}>
                        {trustIntegrationNotFoundError}
                    </LargeMessage>
                )}
                {trustIntegration && (
                    <TrustIntegrationForm
                        trustIntegration={trustIntegration}
                        onCancel={onCancel}
                        onSubmit={onSubmit}
                        isSubmitLoading={isSubmitLoading}
                        errors={errors}
                    />
                )}
            </Loader>
        </div>
    )
}

interface UrlParams {
    id: string
}
