import {
    IconDefinition,
    faAndroid,
    faApple,
    faLinux,
    faWindows,
} from "@fortawesome/free-brands-svg-icons"

import { LanguageKey } from "../../../pre-v3/services/localization/languages/en-US.language"
import { LocalizationService } from "../../../pre-v3/services/localization/Localization.service"
import { CollectionUtil } from "../../../pre-v3/utils/Collection.util"
import { PatternUtil } from "../../../pre-v3/utils/Pattern.util"
import { SelectItem } from "../../../pre-v3/utils/SelectValue.util"
import { faBrowser } from "@fortawesome/pro-regular-svg-icons"

export enum Platform {
    MACOS = "MACOS",
    WINDOWS = "WINDOWS",
    LINUX = "LINUX",
    IOS = "IOS",
    ANDROID = "ANDROID",
    CHROME = "CHROME",
}

export enum Browser {
    CHROME = "CHROME",
}

export type DesktopPlatform = Platform.MACOS | Platform.WINDOWS | Platform.LINUX | Platform.CHROME

export const allPlatforms: Platform[] = Object.values(Platform)

export const rolePlatforms: Platform[] = Object.values(Platform).filter(
    (p) => p !== Platform.CHROME
)

export const desktopPlatforms: Exclude<DesktopPlatform, Platform.CHROME>[] = [
    Platform.MACOS,
    Platform.WINDOWS,
    Platform.LINUX,
]

export const noPlatforms: Record<Platform, boolean> = {
    [Platform.MACOS]: false,
    [Platform.WINDOWS]: false,
    [Platform.LINUX]: false,
    [Platform.IOS]: false,
    [Platform.ANDROID]: false,
    [Platform.CHROME]: false,
}

export const onlyDesktopPlatforms: Record<Platform, boolean> = {
    [Platform.MACOS]: true,
    [Platform.WINDOWS]: true,
    [Platform.LINUX]: true,
    [Platform.IOS]: false,
    [Platform.ANDROID]: false,
    [Platform.CHROME]: false,
}

export const onlyMac: Record<Platform, boolean> = {
    [Platform.MACOS]: true,
    [Platform.ANDROID]: false,
    [Platform.IOS]: false,
    [Platform.LINUX]: false,
    [Platform.WINDOWS]: false,
    [Platform.CHROME]: false,
}

export const onlyWindows: Record<Platform, boolean> = {
    [Platform.MACOS]: false,
    [Platform.ANDROID]: false,
    [Platform.IOS]: false,
    [Platform.LINUX]: false,
    [Platform.WINDOWS]: true,
    [Platform.CHROME]: false,
}

export const platformLabelDict: Record<Platform, LanguageKey> = {
    [Platform.MACOS]: "macOS",
    [Platform.WINDOWS]: "windows",
    [Platform.LINUX]: "linux",
    [Platform.IOS]: "iOS",
    [Platform.ANDROID]: "android",
    [Platform.CHROME]: "browser", //PM and Design decided to name this as browser in this release for better user understanding. not changing in other places as backend has concept of chrome
}

export const platformIconDict: Record<Platform, IconDefinition> = {
    [Platform.MACOS]: faApple,
    [Platform.WINDOWS]: faWindows,
    [Platform.LINUX]: faLinux,
    [Platform.IOS]: faApple,
    [Platform.ANDROID]: faAndroid,
    [Platform.CHROME]: faBrowser,
}

export const platformFilePathPlaceholderDict: Record<
    Exclude<DesktopPlatform, Platform.CHROME>,
    LanguageKey
> = {
    [Platform.MACOS]: "unixFilePathPlaceholder",
    [Platform.WINDOWS]: "windowsFilePathPlaceholder",
    [Platform.LINUX]: "unixFilePathPlaceholder",
}

export const platformFilePathDescriptionDict: Record<
    Exclude<DesktopPlatform, Platform.CHROME>,
    LanguageKey
> = {
    [Platform.MACOS]: "filePathDescriptionUnix",
    [Platform.WINDOWS]: "filePathDescriptionWindows",
    [Platform.LINUX]: "filePathDescriptionUnix",
}

export function mergeApplicablePlatforms<P extends Platform = Platform>(
    applicablePlatformsA: Record<P, boolean>,
    applicablePlatformsB: Record<P, boolean>
): Record<P, boolean> {
    return CollectionUtil.entries(applicablePlatformsA).reduce(
        (acc, [platform, isApplicable]) => ({
            ...acc,
            [platform]: isApplicable || applicablePlatformsB[platform],
        }),
        {} as Record<P, boolean>
    )
}

const unixPrefixOptions: SelectItem[] = [
    { displayName: "Root (/)", value: "/" },
    { displayName: "$HOME (~/)", value: "~/" },
]

const windowsPrefixOptions: SelectItem[] = [
    { displayName: "C:\\", value: "C:\\" },
    { displayName: "%USERPROFILE%\\", value: "%USERPROFILE%\\" },
]

export const prefixOptionsDict: Record<Exclude<DesktopPlatform, Platform.CHROME>, SelectItem[]> = {
    [Platform.MACOS]: unixPrefixOptions,
    [Platform.WINDOWS]: windowsPrefixOptions,
    [Platform.LINUX]: unixPrefixOptions,
}

export const maxFilePathLengthDict: Record<
    Exclude<DesktopPlatform, Platform.CHROME>,
    number | undefined
> = {
    [Platform.MACOS]: 1024,
    [Platform.WINDOWS]: undefined,
    [Platform.LINUX]: 4096,
}

export const validFilePathDict: Record<Exclude<DesktopPlatform, Platform.CHROME>, RegExp> = {
    [Platform.MACOS]: PatternUtil.UNIX_FILE_PATH,
    [Platform.WINDOWS]: PatternUtil.WINDOWS_FILE_PATH,
    [Platform.LINUX]: PatternUtil.UNIX_FILE_PATH,
}

export function getListOfPlatforms(
    platforms: Record<Platform, boolean>,
    localization: LocalizationService
): string {
    const platformLabels = CollectionUtil.entries(platforms).reduce(
        (acc: string[], [platform, isPresent]: [Platform, boolean]): string[] => {
            return isPresent ? [...acc, localization.getString(platformLabelDict[platform])] : acc
        },
        []
    )

    const formatter = new Intl.ListFormat(localization.getLocale(), { type: "conjunction" })
    return formatter.format(platformLabels)
}
