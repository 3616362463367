import { faCircle } from "@fortawesome/pro-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import classNames from "classnames/bind"
import React from "react"

import { useServiceLocalization, useServiceModal } from "../../../../../../../pre-v3/services"
import { LanguageKey } from "../../../../../../../pre-v3/services/localization/languages/en-US.language"
import { ButtonAsLink } from "../../../../../../components/button/ButtonAsLink.component"
import { Tooltip } from "../../../../../../components/tooltip/Tooltip.component"
import { TrustFactor, TRUST_EFFECT } from "../../../../../../services/TrustProfile.service"
import { EditEffectDialog } from "../EditEffectDialog.component"
import styles from "./TrustFactorEffect.module.scss"

type EditEffectFn = (effect: TRUST_EFFECT) => void

export function TrustFactorEffect(trustFactor: TrustFactor, editEffect: EditEffectFn): JSX.Element {
    const localization = useServiceLocalization()
    const modalService = useServiceModal()

    const onEditEffect: React.MouseEventHandler = (event) => {
        event.preventDefault()

        modalService
            .open(localization.getString("setTrustEffectForFactor", trustFactor.label), {
                component: EditEffectDialog,
                props: { trustFactor },
                withoutBody: true,
                maxWidth: "unset",
            })
            .onClose(editEffect)
    }

    return (
        <React.Fragment>
            <FontAwesomeIcon
                icon={faCircle}
                className={classNames(styles.icon, effectColorDictionary[trustFactor.effect])}
            />
            <Tooltip title={localization.getString("editEffect")}>
                <ButtonAsLink onClick={onEditEffect}>
                    {localization.getString(effectTextDictionary[trustFactor.effect])}
                </ButtonAsLink>
            </Tooltip>
        </React.Fragment>
    )
}

export const trustFactorEffectClassName = styles.cell

const effectTextDictionary: Record<TRUST_EFFECT, LanguageKey> = {
    [TRUST_EFFECT.NOT_EVALUATED]: "notEval",
    [TRUST_EFFECT.ALWAYS_DENY]: "alwaysDeny",
    [TRUST_EFFECT.LOW_TRUST_LEVEL]: "lowTL",
    [TRUST_EFFECT.MEDIUM_TRUST_LEVEL]: "mediumTL",
    [TRUST_EFFECT.NO_EFFECT]: "noEffect",
}

const effectColorDictionary: Record<TRUST_EFFECT, string> = {
    [TRUST_EFFECT.NOT_EVALUATED]: styles.notEvaluatedColor,
    [TRUST_EFFECT.ALWAYS_DENY]: styles.alwaysDenyColor,
    [TRUST_EFFECT.LOW_TRUST_LEVEL]: styles.lowTrustLevelColor,
    [TRUST_EFFECT.MEDIUM_TRUST_LEVEL]: styles.mediumTrustLevelColor,
    [TRUST_EFFECT.NO_EFFECT]: styles.noEffectColor,
}
