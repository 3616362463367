import React from "react"
import { useHistory, useParams } from "react-router-dom"

import { DeleteCancelActions } from "../../../../pre-v3/components/modal/delete-cancel/DeleteCancelActions"
import { MessageContent } from "../../../../pre-v3/components/modal/message/MessageContent"
import { ActionBarItem, IconType } from "../../../../pre-v3/services/ActionBar.service"
import { useServiceLocalization } from "../../../../pre-v3/services/localization/Localization.service"
import { useServiceModal } from "../../../../pre-v3/services/Modal.service"
import { DateUtil } from "../../../../pre-v3/utils/Date.util"
import { UrlUtil, decodeID, encodeID } from "../../../../pre-v3/utils/Url.util"
import { formatRoutePath, ROUTE } from "../../../../routes"
import { ErrorBanner, ErrorBanners } from "../../../components/banner/Banner.component"
import { Container } from "../../../components/container/Container.component"
import { Loader } from "../../../components/loader/Loader.component"
import { OverviewTopContainer } from "../../../components/overview/OverviewTopContainer/OverviewTopContainer.component"
import {
    AppFilter,
    DnsFilters,
    ItpPolicyDetails,
    UserEditableItpPolicyFields,
    excludedDevicesPolicyName,
    isExcludedDevicesPolicy,
    useDeleteItpPolicy,
    useGetDnsFilters,
    useGetITPApplications,
    useGetItpPolicy,
    useGetPoliciesRolesNames,
    useUpdateItpPolicy,
} from "../../../services/ItpPolicy.service"
import { AdminInfo, useGetAdminInfo } from "../../../services/Org.service"
import { ItpPolicyForm, Step, isStep } from "../form/ItpPolicyForm.component"
import styles from "../ItpPolicy.module.scss"
import { LookupDomainModal } from "./LookupDomainModal.component"
import { PageHeading } from "../../../../components/page-heading/PageHeading.component"
import { Button, ButtonElement, ButtonType } from "../../../../components/button/Button.component"
import { Tooltip } from "../../../components/tooltip/Tooltip.component"

export function ItpPolicyOverview(): JSX.Element {
    const params = useParams<{ id: string }>()

    const {
        data: dnsFilters,
        isLoading: isDnsFiltersLoading,
        error: dnsFilterError,
        refetch: refetchDnsFilters,
    } = useGetDnsFilters()

    const {
        data: roles,
        isLoading: isRolesLoading,
        error: rolesError,
        refetch: refetchRoles,
    } = useGetPoliciesRolesNames()

    const {
        data: itpPolicy,
        isLoading: isItpPolicyLoading,
        error: itpPolicyError,
        refetch: refetchItpPolicy,
    } = useGetItpPolicy(decodeID(params.id))

    const {
        data: adminInfo,
        isLoading: isAdminInfoLoading,
        error: adminInfoError,
        refetch: refetchAdminInfo,
    } = useGetAdminInfo()

    const {
        data: itpApplications,
        isLoading: isItpApplicationsLoading,
        error: itpApplicationsError,
    } = useGetITPApplications()

    const errors: React.ReactNode[] = [
        dnsFilterError ? String(dnsFilterError) : null,
        rolesError ? String(rolesError) : null,
        itpPolicyError ? String(itpPolicyError) : null,
        adminInfoError && String(adminInfoError),
        itpApplicationsError && String(itpApplicationsError),
    ]

    if (
        isDnsFiltersLoading ||
        isRolesLoading ||
        isItpPolicyLoading ||
        isAdminInfoLoading ||
        isItpApplicationsLoading
    )
        return <Loader children medium center isLoading />

    if (errors.some(Boolean)) {
        return (
            <Container className={styles.container}>
                <ErrorBanners className={styles.errorBanners} errors={errors} />
            </Container>
        )
    }

    const onRefetchMetadata = () => {
        refetchRoles()
        refetchDnsFilters()
        refetchAdminInfo()
    }

    const refetchPolicy = async (): Promise<ItpPolicyDetails | undefined> => {
        const refetchedItpPolicy = await refetchItpPolicy()
        return refetchedItpPolicy.data
    }

    return (
        <Loaded
            adminInfo={adminInfo!}
            itpPolicy={itpPolicy!}
            dnsFilters={dnsFilters!}
            appFilter={itpApplications!}
            roles={roles!}
            onRefetchMetadata={onRefetchMetadata}
            refetchPolicy={refetchPolicy}
        />
    )
}

interface LoadedProps {
    adminInfo: AdminInfo
    itpPolicy: ItpPolicyDetails
    dnsFilters: DnsFilters
    appFilter: AppFilter[]
    roles: string[]
    onRefetchMetadata(): void
    refetchPolicy(): Promise<ItpPolicyDetails | undefined>
}

function Loaded(props: LoadedProps): JSX.Element {
    const localization = useServiceLocalization()
    const history = useHistory()

    const defaultStep = isExcludedDevicesPolicy(props.itpPolicy) ? Step.ASSIGNMENT : Step.THREATS

    const [step, setStep] = React.useState(() => {
        const stepFromParams = UrlUtil.getUrlParam("step")
        return isStep(stepFromParams) ? stepFromParams : defaultStep
    })
    const [editedItpPolicy, setEditedItpPolicy] = React.useState<UserEditableItpPolicyFields>()

    const {
        isLoading: isUpdatingItpPolicy,
        mutate: updateItpPolicy,
        error: updateItpPolicyError,
    } = useUpdateItpPolicy(props.itpPolicy, {
        onSuccess: (data) => {
            if (props.itpPolicy.id === excludedDevicesPolicyName) {
                history.replace(
                    formatRoutePath(ROUTE.INTERNET_THREAT_PROTECTION_DETAILS, {
                        id: encodeID(data.id),
                    })
                )
            }

            setEditedItpPolicy(undefined)
        },
    })

    const {
        mutateAsync: deleteItpPolicy,
        isLoading: isDeleting,
        error: deleteItpPolicyError,
    } = useDeleteItpPolicy(props.itpPolicy)

    const userEditableItpPolicyFields = editedItpPolicy ?? props.itpPolicy
    const isEdit = Boolean(editedItpPolicy)

    const onEdit = () => {
        setEditedItpPolicy(props.itpPolicy)

        if (step === Step.DEVICES) {
            // Note: Device is not a user editable tab and it will be removed on edit mode.
            onStepChange(defaultStep)
        }
    }

    const deletePolicy = () => deleteItpPolicy()

    const actionItems = useActionBarItems(
        props.itpPolicy,
        props.adminInfo.canWriteAll,
        isEdit,
        onEdit,
        deletePolicy
    )

    async function onRefresh() {
        props.onRefetchMetadata()
        const refetchedItpPolicy = await props.refetchPolicy()

        if (isEdit) {
            setEditedItpPolicy(refetchedItpPolicy)
        }
    }

    const onStepChange = (updatedStep: Step) => {
        setStep(updatedStep)
        UrlUtil.setURlParams("step", updatedStep)
    }

    const isLoading = isUpdatingItpPolicy || isDeleting

    return (
        <Loader medium center isLoading={isLoading}>
            <Container as="section" aria-labelledby={Id.HEADING} className={styles.container}>
                <header className={styles.header}>
                    <PageHeading id={Id.HEADING}>{props.itpPolicy.name}</PageHeading>
                    <div className={styles.actionButtons}>
                        {actionItems.map((item) => (
                            <Tooltip title={item.tooltip} key={item.tooltip}>
                                <Button
                                    key={item.tooltip}
                                    icon={item.icon}
                                    onClick={item.onClick}
                                    asElement={ButtonElement.BUTTON}
                                    buttonType={ButtonType.SECONDARY}
                                    disabled={item.disabled}
                                    aria-label={item.tooltip}
                                >
                                    {item.label || ""}
                                </Button>
                            </Tooltip>
                        ))}
                        <Tooltip title={localization.getString("refresh")}>
                            <Button
                                icon={IconType.REDO}
                                onClick={onRefresh}
                                asElement={ButtonElement.BUTTON}
                                buttonType={ButtonType.SECONDARY}
                                aria-label={localization.getString("refresh")}
                            />
                        </Tooltip>
                    </div>
                </header>
                {deleteItpPolicyError ? (
                    <ErrorBanner
                        className={styles.errorBanners}
                        children={String(deleteItpPolicyError)}
                    />
                ) : (
                    <React.Fragment />
                )}
                <OverviewTopContainer
                    statusContainerClassName={styles.statusContainerClassName}
                    statusType="success"
                    statusLabelTitle={localization.getString("devices")}
                    statusLabel={props.itpPolicy.deviceCount.toString()}
                    hideIcon
                    statusItems={[
                        {
                            label: localization.getString("lastUpdated"),
                            value: DateUtil.format(props.itpPolicy.lastUpdatedAt),
                        },
                        {
                            label: localization.getString("priority"),
                            value: isExcludedDevicesPolicy(props.itpPolicy)
                                ? "-"
                                : props.itpPolicy.priority,
                        },
                    ]}
                    listItems={[
                        {
                            label: localization.getString("description"),
                            value: props.itpPolicy.description ?? "-",
                        },
                        {
                            label: localization.getString("createdAt"),
                            value: DateUtil.format(props.itpPolicy.createdAt),
                        },
                        {
                            label: localization.getString("createdBy"),
                            value: props.itpPolicy.createdBy,
                        },
                        {
                            label: localization.getString("lastUpdatedBy"),
                            value: props.itpPolicy.lastUpdatedBy,
                        },
                    ]}
                />
                <ItpPolicyForm
                    dnsFilters={props.dnsFilters}
                    appFilters={props.appFilter}
                    value={userEditableItpPolicyFields}
                    onChange={setEditedItpPolicy}
                    roles={props.roles}
                    disabled={!isEdit}
                    isEdit={isEdit}
                    step={step}
                    onStepChange={onStepChange}
                    onSubmit={updateItpPolicy}
                    onCancel={() => setEditedItpPolicy(undefined)}
                    formErrors={[updateItpPolicyError ? String(updateItpPolicyError) : null]}
                />
            </Container>
        </Loader>
    )
}

enum Id {
    HEADING = "heading",
}

function useActionBarItems(
    itpPolicy: ItpPolicyDetails,
    canWriteAll: boolean,
    isEdit: boolean,
    onEdit: () => void,
    deleteItpPolicy: () => Promise<void>
): ActionBarItem[] {
    const history = useHistory()
    const localization = useServiceLocalization()
    const modalService = useServiceModal()

    return React.useMemo((): ActionBarItem[] => {
        const onOpenLookupDomain = () => {
            modalService.open(localization.getString("lookupDomain"), {
                component: LookupDomainModal,
                maxWidth: "lg",
            })
        }

        const onDelete = () => {
            modalService
                .open(
                    localization.getString("deleteSomething", localization.getString("itpPolicy")),
                    {
                        component: MessageContent,
                        props: {
                            text: localization.getString("areYouSureYouWantToDeleteThisItpPolicy"),
                        },
                    },
                    {
                        component: DeleteCancelActions,
                    }
                )
                .onClose(async () => {
                    await deleteItpPolicy()
                    history.push(ROUTE.INTERNET_THREAT_PROTECTION)
                })
        }

        const onCopy = () => {
            const queryParams = new URLSearchParams()
            queryParams.set("itpPolicyId", encodeID(itpPolicy.id))

            history.push(`${ROUTE.INTERNET_THREAT_PROTECTION_ADD}?${queryParams.toString()}`)
        }

        const lookupDomainItem: ActionBarItem = {
            icon: IconType.SEARCH,
            onClick: onOpenLookupDomain,
            tooltip: localization.getString("lookupDomain"),
            label: localization.getString("lookupDomain"),
        }

        const editItem: ActionBarItem = {
            icon: IconType.PEN,
            tooltip: isEdit ? "" : localization.getString("edit"),
            onClick: onEdit,
            disabled: isEdit,
        }

        const deleteItem: ActionBarItem = {
            icon: IconType.TRASH,
            onClick: onDelete,
            tooltip: localization.getString("delete"),
        }

        const copyItem: ActionBarItem = {
            icon: IconType.COPY,
            onClick: onCopy,
            tooltip: localization.getString("copy"),
        }

        if (isExcludedDevicesPolicy(itpPolicy)) return canWriteAll ? [editItem] : []

        if (!canWriteAll) return [lookupDomainItem]

        if (isEdit) return [lookupDomainItem, editItem, deleteItem]

        return [lookupDomainItem, editItem, deleteItem, copyItem]
    }, [
        itpPolicy,
        canWriteAll,
        isEdit,
        onEdit,
        deleteItpPolicy,
        history,
        localization,
        modalService,
    ])
}
