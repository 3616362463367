import React, { ReactNode } from "react"
import { LocalizationService } from "../../../../services/localization/Localization.service"
import ServicesAddIncludeDomainsTemplate from "./ServicesAddIncludeDomains.template"

export class ServicesAddIncludeDomains extends React.Component<ServicesAddIncludeDomainsProps, {}> {
    public render(): ReactNode {
        return ServicesAddIncludeDomainsTemplate.call(this)
    }

    private ls: LocalizationService = new LocalizationService()
}

interface ServicesAddIncludeDomainsProps {
    initialValues: string[]
    onChange: (v: string[]) => void
    disabled: boolean
}
