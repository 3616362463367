import React from "react"
import { Redirect, Route, Switch } from "react-router-dom"

import { ROUTE } from "../../../routes"
import { RolesAdd } from "./add/RolesAdd.view"
import { RolesList } from "./list/RolesList.view"
import { RolesOverview } from "./overview/RolesOverview.view"
import useTitle from "../../../hooks/useTitle.hook"

export function Roles() {
    useTitle(["roles", "directory", "adminConsole"])
    return (
        <Switch>
            <Route exact path={ROUTE.ROLES} component={RolesList} />
            <Route exact path={ROUTE.ROLES_ADD} component={RolesAdd} />
            <Route exact path={ROUTE.ROLES_DETAILS} component={RolesOverview} />
            <Redirect to={ROUTE.ROLES} />
        </Switch>
    )
}
