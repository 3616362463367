import React, { ReactNode, SyntheticEvent } from "react"
import CloakExceptionSettingsTemplate from "./CloakExceptionSettings.template"
import { LocalizationService } from "../../../../services/localization/Localization.service"
import ActionBarService from "../../../../services/ActionBar.service"
import { UserService, ManageService } from "../../../../services"
import { UserOrgDetails, CloakExceptions, UserApi } from "../../../../api/User.api"
import { Bind } from "../../../../decorators/Bind.decorator"
import { SelectItem } from "../../../../utils/SelectValue.util"
import { StatusCode } from "../../../../api/Base.api"
import { LinkService } from "../../../../services/link/Link.service"
import { OrgEdition } from "../../../../services"
import { Debounce } from "../../../../decorators/Debounce.decorator"

interface Props {
    canUpdateUnregisteredDeviceConfig?: boolean
    canUpdateDeviceTrustVerfication?: boolean
}

export class CloakExceptionSettings extends React.Component<Props, CloakExceptionSettingsState> {
    public state: CloakExceptionSettingsState = {
        submitting: false,
        responseType: StatusCode.UNAUTHORIZED,
        responseMessage: "",
        edition: OrgEdition.ENTERPRISE,
        authAsyncState: false,
        tokenWhitelistCidrs: [],
        noTokenWhitelistCidrs: [],
    }

    public render(): ReactNode {
        return CloakExceptionSettingsTemplate.call(this)
    }

    public componentDidMount(): void {
        this.actionBarService.setItems(this.localizationService.getString("unregisteredDevices"), [
            { label: this.localizationService.getString("enduserManagement") },
        ])
        this.userService.getEdition().then((edition) => {
            this.setState({ edition })
        })

        this.fetchData()
    }

    private localizationService: LocalizationService = new LocalizationService()
    private linkService: LinkService = new LinkService()
    private actionBarService: ActionBarService = new ActionBarService()
    private userService: UserService = new UserService()
    private manageService: ManageService = new ManageService()
    private userApi: UserApi = new UserApi()

    private responseTypeOptions: SelectItem[] = [
        {
            displayName: this.localizationService.getString("responseRedirect"),
            value: StatusCode.REDIRECT,
        },
        {
            displayName: this.localizationService.getString("responseUnauthorized"),
            value: StatusCode.UNAUTHORIZED,
        },
    ]

    private defaultCloakExceptions: CloakExceptions = {
        token: {
            whitelist_cidrs: [],
        },
        no_token: {
            whitelist_cidrs: [],
            status_code: StatusCode.UNAUTHORIZED,
            message: this.localizationService.getString(
                "pleaseInstallTheCseAppAndRegisterYourDevice"
            ),
        },
    }

    private fetchData(): void {
        this.userService.getUserOrgDetails(true).then((orgDetails: UserOrgDetails) => {
            if (orgDetails.AsyncAuthEnabled) {
                this.setState({ authAsyncState: orgDetails.AsyncAuthEnabled })
            }

            if (orgDetails.CloakExceptions) {
                try {
                    const cloakExceptions: CloakExceptions = JSON.parse(orgDetails.CloakExceptions)
                    this.setState({
                        cloakExceptions: { ...this.defaultCloakExceptions, ...cloakExceptions },
                    })
                    this.onTokenCidrChange(cloakExceptions.token.whitelist_cidrs)
                    this.onNoTokenCidrChange(cloakExceptions.no_token.whitelist_cidrs)
                    this.setState({
                        responseType: cloakExceptions.no_token.status_code,
                        responseMessage: cloakExceptions.no_token.message,
                    })
                } catch {
                    this.setState({ cloakExceptions: this.defaultCloakExceptions })
                }
            } else {
                this.setState({ cloakExceptions: this.defaultCloakExceptions })
            }
        })
    }

    @Bind
    private onTokenCidrChange(values: string[]): void {
        this.setState({ tokenWhitelistCidrs: values })
    }

    @Bind
    private onNoTokenCidrChange(values: string[]): void {
        this.setState({ noTokenWhitelistCidrs: values })
    }

    @Bind
    private onResponseTypeChange(value: number): void {
        this.setState({ responseType: value })
        if (value === StatusCode.REDIRECT) {
            this.setState({ responseMessage: this.linkService.getLink("authFailure") })
        } else {
            this.setState({
                responseMessage: this.localizationService.getString(
                    "pleaseInstallTheCseAppAndRegisterYourDevice"
                ),
            })
        }
    }

    // TODO: Remove debounce when we have a Switch component with a loading state
    @Debounce
    public async onStateChange(authAsyncState: boolean) {
        this.setState({ authAsyncState })

        try {
            await this.userService.updateAuthAsyncState(authAsyncState)

            this.setState({
                success: this.localizationService.getString(
                    "orgWideDeviceTrustVerificationSuccessfullyUpdated"
                ),
            })
        } catch (error) {
            this.setState({ authAsyncState: !authAsyncState })
            this.setState({
                error: this.localizationService.getString(
                    "failedToUpdateOrgWideDeviceTrustVerification"
                ),
                success: "",
            })
        }
    }

    @Bind
    private onSubmit(event: SyntheticEvent): void {
        event.preventDefault()
        if (event.target) {
            this.setState({ success: "", error: "", submitting: true })
            const form: { [key: string]: HTMLInputElement } = event.target as any
            const cloakExceptions: CloakExceptions = {
                token: {
                    whitelist_cidrs: this.state.tokenWhitelistCidrs,
                },
                no_token: {
                    whitelist_cidrs: this.state.noTokenWhitelistCidrs,
                    status_code: this.state.responseType,
                    message:
                        this.state.responseType === StatusCode.REDIRECT
                            ? form["redirectLink"].value
                            : form["unauthorizedMessage"].value,
                },
            }
            this.userApi
                .setCloakExceptions(cloakExceptions)
                .then(
                    () => {
                        this.setState(
                            {
                                success: this.localizationService.getString(
                                    "unregisteredDevicesSuccessfullyUpdated"
                                ),
                            },
                            this.fetchData
                        )
                    },
                    (error: Error) => {
                        this.setState({ error: error.message, success: "" }, this.fetchData)
                    }
                )
                .finally(() => {
                    // refresh unregistered devices settings in orgDetails
                    this.userService.getOrgDetails(true)
                    this.setState({ submitting: false })
                })
        }
    }
}

interface CloakExceptionSettingsState {
    success?: string
    error?: string
    cloakExceptions?: CloakExceptions
    submitting: boolean
    responseType: number
    responseMessage: string
    edition: OrgEdition
    authAsyncState: boolean
    tokenWhitelistCidrs: string[]
    noTokenWhitelistCidrs: string[]
}
