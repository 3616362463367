// external imports
import * as React from "react"
// local imports
import {
    ServiceFormProps,
    useSetErrors,
    useSetShowError,
    useUpdateAttributes,
    useUpdateMetadata,
} from "../service-form"
import { ServiceType, useServiceLocalization, useServiceManage } from "../../../services"
import { FormSection, JsonTextArea } from "../../../components"

export default function CustomServiceForm({ edit }: ServiceFormProps) {
    // grab the info we need
    const localization = useServiceLocalization()
    const updateAttributes = useUpdateAttributes()
    const updateMetadata = useUpdateMetadata()

    const manageService = useServiceManage()

    // delete user_facing tag from metadata template
    // since we added option to set the user facing value in service details section
    delete edit?.spec?.metadata.tags?.user_facing

    // some state for the forms
    const [localMeta, setLocalMeta] = React.useState(
        edit
            ? JSON.stringify({ tags: edit?.spec?.metadata.tags })
            : JSON.stringify({ tags: manageService.getNullServiceMetadata().tags })
    )
    const [localAttributes, setLocalAttributes] = React.useState(
        edit ? JSON.stringify(edit.spec?.spec) : JSON.stringify(manageService.getNullServiceAttr())
    )

    // update the form with any rdp-service specifics
    const setErrors = useSetErrors()
    const showError = useSetShowError()

    React.useEffect(() => {
        try {
            const attrParsed = JSON.parse(localAttributes)
            updateAttributes((attr) => ({
                ...attr,
                ...attrParsed,
            }))
            setErrors((errs) => ({
                ...errs,
                INVALID_ATTRIBUTE_JSON: false,
            }))
        } catch {
            setErrors((errs) => ({
                ...errs,
                INVALID_ATTRIBUTE_JSON: localization.getString("customAttributesMustBeValidJSON"),
            }))

            showError((state) => ({
                ...state,
                INVALID_ATTRIBUTE_JSON: true,
            }))
        }

        try {
            const metaParsed = JSON.parse(localMeta)
            updateMetadata((attr) => {
                return {
                    ...attr,
                    ...metaParsed,
                    tags: {
                        ...attr.tags,
                        ...metaParsed.tags,
                        icon: attr?.tags?.icon || metaParsed.tags?.icon || "",
                        description_link:
                            attr.tags?.description_link || metaParsed.tags?.description_link || "",
                        template: ServiceType.CUSTOM,
                    },
                }
            })

            setErrors((errs) => ({
                ...errs,
                INVALID_META_JSON: false,
            }))
        } catch (e) {
            setErrors((errs) => ({
                ...errs,
                INVALID_META_JSON: localization.getString("customMetadataMustBeValidJSON"),
            }))

            showError((state) => ({
                ...state,
                INVALID_META_JSON: true,
            }))
        }
    }, [
        localMeta,
        localAttributes,
        localization,
        setErrors,
        showError,
        updateAttributes,
        updateMetadata,
    ])

    return (
        <>
            <FormSection title={localization.getString("serviceMetadata")}>
                <JsonTextArea initialValue={localMeta} onValueChange={setLocalMeta} />
            </FormSection>
            <FormSection title={localization.getString("serviceAttributes")}>
                <JsonTextArea initialValue={localAttributes} onValueChange={setLocalAttributes} />
            </FormSection>
        </>
    )
}
