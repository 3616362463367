import { Singleton } from "../decorators/Singleton.decorator"
import { BaseApi } from "./Base.api"

@Singleton("StatsApi")
export class StatsApi extends BaseApi {
    public getDeviceStats(): Promise<DeviceStats> {
        return this.get("/api/v1/devices/stats?active=true")
    }

    public getUnregisteredDevicesStats(): Promise<UnregisteredDevicesStats> {
        return this.get("/api/v1/unregistered_device/endusers/stats?active=true")
    }

    public getServicesStats(): Promise<ServicesStats> {
        return this.get("/api/v1/services/stats")
    }

    public getAccessTiersStats(): Promise<AccessTiersStats> {
        return this.get("/api/v1/access_tiers/stats")
    }

    public getUsersStats(): Promise<UsersStats> {
        return this.get("/api/v1/endusers/stats?active=true")
    }

    public getPoliciesStats(): Promise<PoliciesStats> {
        return this.get("/api/v1/policies/stats")
    }
}

export interface DeviceStats {
    total: number
    trust_level: {
        high: number
        medium: number
        low: number
        always_allow: number
        always_deny: number
        unknown: number
    }
    os: NumberMap
}

export interface UnregisteredDevicesStats {
    total: number
}

export interface ServicesStats {
    total: number
    type: {
        hosted: number
        saas_app: number
        idp_routed: number
    }
}

export interface AccessTiersStats {
    total: number
    status: {
        reporting: number
        terminated: number
        pending: number
        inactive: number
    }
}

export interface UsersStats {
    total: number
}

export interface PoliciesStats {
    total: number
    status: {
        enforcing: number
        permissive: number
        inactive: number
        mixed_enforcing_permissive: number
    }
}
