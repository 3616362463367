import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import * as React from "react"
import styles from "./CodeWithCopy.module.scss"
import { IconDefinition, faCheckCircle } from "@fortawesome/pro-solid-svg-icons"
import { faCopy } from "@fortawesome/pro-light-svg-icons"
import classNames from "classnames"
import { Button } from "../button/Button.component"

export interface CodeWithCopyProps {
    title: string
    text: string
    className?: string
    hideCopyButton?: boolean
}

export function CodeWithCopy({ title, text, className, hideCopyButton }: CodeWithCopyProps) {
    const [icon, setIcon] = React.useState<IconDefinition>(faCopy)
    const [iconStyle, setIconStyle] = React.useState<string>("")

    const onCopy = (event: React.SyntheticEvent) => {
        event.preventDefault()
        ;(navigator as any).clipboard.writeText(text)

        setIcon(faCheckCircle)
        setIconStyle(styles.success)

        setTimeout(() => {
            setIcon(faCopy)
            setIconStyle("")
        }, 2000)
    }

    return (
        <div className={className}>
            <div className={styles.header}>
                <label className={styles.label}>{title}</label>
                {!hideCopyButton && (
                    <Button className={classNames(iconStyle, icon, styles.button)} onClick={onCopy}>
                        <FontAwesomeIcon icon={icon} />
                    </Button>
                )}
            </div>
            <div className={styles.content}>
                <pre className={styles.text}>{text}</pre>
            </div>
        </div>
    )
}
