import React from "react"
import styles from "./SubmitButton.module.scss"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faSpinner } from "@fortawesome/pro-solid-svg-icons"
import classNames from "classnames/bind"

export default function () {
    return (
        <button
            className={classNames(this.props.className, styles.button)}
            type="submit"
            disabled={this.props.loading}
        >
            {this.props.loading && (
                <span className={styles.loadingIcon}>
                    <FontAwesomeIcon spin icon={faSpinner} />
                </span>
            )}
            {this.props.label}
        </button>
    )
}
