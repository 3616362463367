import { useMutation } from "@tanstack/react-query"
import { Singleton } from "../../pre-v3/decorators/Singleton.decorator"
import {
    BackendConnectionStatusRes,
    ServiceTestConnectionApi,
    ServiceTestConnectionRes,
} from "../api/ServiceTestConnection.api"

@Singleton("ServiceTestConnectionService")
export class ServiceTestConnectionService {
    public testConnection(serviceId: string): Promise<ServiceTestConnection> {
        return this.serviceTestConnectionApi
            .testConnection(serviceId)
            .then((res: ServiceTestConnectionRes) => {
                return {
                    frontendResolvable: res.FrontendConnStatus.DomainNameResolutionStatus,
                    frontendReachable: res.FrontendConnStatus.Reachability,
                    errorMsg: res.FrontendConnStatus.ErrorMsg,
                    backend: res.BackendConnStatus.map((backend: BackendConnectionStatusRes) => {
                        return {
                            backendName: backend.SiteName,
                            backendResolvable: backend.AccessTierStatus.DomainNameResolutionStatus,
                            backendReachable: backend.AccessTierStatus.Reachability,
                            errorMsg: backend.AccessTierStatus.ErrorMsg,
                        }
                    }),
                }
            })
    }

    private serviceTestConnectionApi: ServiceTestConnectionApi = new ServiceTestConnectionApi()
}

export function useTestConnectionById(
    options?: QueryOptions<ServiceTestConnection, string, string>
) {
    const serviceTestConnectionService = new ServiceTestConnectionService()
    return useMutation<ServiceTestConnection, string, string>({
        ...options,
        mutationFn: (id: string): Promise<ServiceTestConnection> => {
            return serviceTestConnectionService.testConnection(id)
        },
        onSuccess: options?.onSuccess,
    })
}

export interface ServiceTestConnection {
    frontendResolvable: boolean
    frontendReachable: boolean
    backend: ServiceBackendConnection[]
    errorMsg?: string
}

export interface ServiceBackendConnection {
    backendName: string
    backendResolvable: boolean
    backendReachable: boolean
    errorMsg?: string
}
