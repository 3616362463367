import React, { FormEvent, ReactNode } from "react"
import { RouteComponentProps } from "react-router"

import { ROUTE, formatRoutePath } from "../../../../../routes"
import { LocalizationService } from "../../../../services/localization/Localization.service"
import { Bind } from "../../../../decorators/Bind.decorator"
import { SaasAuthenticatorValue } from "../saas-authenticator/ServicesAddSaasAuthenticator.component"
import {
    SaasMetadata,
    SaasAttr,
    SaasNameIdValue,
    SaasNameIdValueOpts,
} from "../../../../api/Manage.api"
import { FormUtil } from "../../../../utils/Form.util"
import { ManageService, SaasManage, SaasInitialAuth } from "../../../../services/Manage.service"
import { SaasAppUrlType } from "../../../../services/Service.service"
import { UserService } from "../../../../services"
import { UserOrgDetails } from "../../../../api/User.api"
import { encodeID } from "../../../../utils/Url.util"
import ServicesAddSaasTemplate from "./ServicesAddSaas.template"

export class ServicesAddSaas extends React.Component<
    RouteComponentProps<ServicesAddSaasRouteProps>,
    ServicesAddSaasState
> {
    constructor(props: RouteComponentProps<ServicesAddSaasRouteProps>) {
        super(props)
        const type: string = props.match.params.type
        if (type && type === SaasAppUrlType.IDP_ROUTED) {
            this.idpRouted = true
        }
        this.state = {
            loading: false,
            template: this.idpRouted ? SaasInitialAuth.IDP : SaasInitialAuth.BANYAN_TRUST_PROVIDER,
            suppressDTVFlag: false,
            asyncAuthState: false,
            passwordlessAuthentication: false,
            isIdpRouted: type === SaasAppUrlType.IDP_ROUTED || false,
        }
    }

    public componentDidMount(): void {
        this.userService.getUserOrgDetails().then(
            (details: UserOrgDetails) => {
                if (details.AsyncAuthEnabled) {
                    this.setState({ asyncAuthState: true })
                }
            },
            () => {
                this.setState({ asyncAuthState: false })
            }
        )
    }

    public render(): ReactNode {
        return ServicesAddSaasTemplate.call(this)
    }

    private localizationService: LocalizationService = new LocalizationService()
    private manageService: ManageService = new ManageService()
    private userService: UserService = new UserService()

    private submitButton: HTMLButtonElement

    private policyId: string
    private policyEnabled: boolean = false
    private authenticatorValue: SaasAuthenticatorValue
    private idpRouted: boolean

    @Bind
    private onAuthenticatorChange(value: SaasAuthenticatorValue): void {
        this.authenticatorValue = value
    }

    @Bind
    private onPolicyChange(id: string, enabled: boolean): void {
        this.policyId = id
        this.policyEnabled = enabled
    }

    @Bind
    private onSubmitForm(): void {
        this.submitButton.click()
    }

    @Bind
    public handleEnableChange(suppressDTVFlag: boolean) {
        this.setState({ suppressDTVFlag })
    }

    @Bind
    public onPasswordAuthChange(passwordlessAuthentication: boolean) {
        this.setState({ passwordlessAuthentication })
    }

    private mapNameIdValue(selector: SaasNameIdValueOpts, customString: string): SaasNameIdValue {
        switch (selector) {
            case SaasNameIdValueOpts.CUSTOM:
                return {
                    name_id_value_type: selector,
                    passthrough_name_id: false,
                    // this should never be empty in this case
                    name_id_attribute_selector: customString,
                }
            case SaasNameIdValueOpts.PASSTHROUGH_NAME_ID:
                return {
                    passthrough_name_id: true,
                    name_id_value_type: selector,
                    name_id_attribute_selector: "",
                }
            case SaasNameIdValueOpts.LEGACY:
                return {
                    name_id_value_type: selector,
                    passthrough_name_id: false,
                    name_id_attribute_selector: "",
                }
            default:
                return {
                    name_id_value_type: selector,
                    passthrough_name_id: false,
                    name_id_attribute_selector: this.authenticatorValue.nameIdAttributeSelector
                        ? this.authenticatorValue.nameIdAttributeSelector
                        : "",
                }
        }
    }

    @Bind
    private onCreateService(event: FormEvent<HTMLFormElement>): void {
        event.preventDefault()
        if (!event.target) return

        this.setState({ error: "" })
        if (!this.authenticatorValue) {
            this.setState({
                error: this.localizationService.getString("errorInitialAuthenticatorNotSet"),
            })
        }
        const metadata: SaasMetadata = {
            name: FormUtil.getFieldValue(event, "serviceName"),
            description: FormUtil.getFieldValue(event, "serviceDescription"),
            tags: {
                template: this.state.template,
            },
        }
        const nameIdValue = this.mapNameIdValue(
            this.authenticatorValue.nameIdValueType
                ? this.authenticatorValue.nameIdValueType
                : SaasNameIdValueOpts.PASSTHROUGH_NAME_ID,
            this.authenticatorValue.nameIdAttributeSelector
                ? this.authenticatorValue.nameIdAttributeSelector
                : ""
        )
        const attr: SaasAttr = {
            redirect_url: this.authenticatorValue.redirectUri,
            protocol: this.authenticatorValue.protocol,
            audience_uri: this.authenticatorValue.audienceUri,
            name_id_format: this.authenticatorValue.nameIdFormat,
            name_id_value: nameIdValue,
            suppress_device_trust_verification: this.state.suppressDTVFlag,
            enable_service_level_passwordless: this.state.passwordlessAuthentication,
        }
        this.setState({ loading: true })
        this.manageService.createSaasApp(metadata, attr, this.policyId, this.policyEnabled).then(
            (app: SaasManage) => {
                this.setState({ loading: false })
                this.props.history.push(
                    formatRoutePath(ROUTE.SAAS_APPS_CONFIGURATION, {
                        id: encodeID(app.id),
                    })
                )
            },
            (err: Error) => {
                this.setState({ error: err.message, loading: false })
            }
        )
    }
}

interface ServicesAddSaasState {
    loading: boolean
    error?: string
    template: SaasInitialAuth
    suppressDTVFlag: boolean
    asyncAuthState: boolean
    passwordlessAuthentication: boolean
    isIdpRouted: boolean
}

interface ServicesAddSaasRouteProps {
    type: SaasAppUrlType
}
