import React, { ReactNode, SyntheticEvent } from "react"
import TrustProviderIssuingCertsTemplate from "./TrustProviderIssuingCerts.template"
import { LocalizationService } from "../../../../../services/localization/Localization.service"
import { LinkService } from "../../../../../services/link/Link.service"
import { Bind } from "../../../../../decorators/Bind.decorator"
import { UserService } from "../../../../../services/User.service"
import { InfraService } from "../../../../../services/Infra.service"
import { UserApi } from "../../../../../api/User.api"

export class TrustProviderIssuingCerts extends React.Component<
    TrustProviderIssuingCertsProps,
    TrustProviderIssuingCertsState
> {
    public state: TrustProviderIssuingCertsState = {
        submitLoading: false,
        showCertInput: false,
        sshCaPubKey: "",
        issuingCert: "",
        rootCert: "",
        error: "",
        success: "",
    }

    public render(): ReactNode {
        return TrustProviderIssuingCertsTemplate.call(this)
    }

    public componentDidMount(): void {
        this.fetchData()
    }
    private userApi: UserApi = new UserApi()
    private localizationService: LocalizationService = new LocalizationService()
    private infraService: InfraService = new InfraService()
    private userService: UserService = new UserService()
    private linkService: LinkService = new LinkService()

    @Bind
    private handleShowCertInput(): void {
        this.setState({ showCertInput: true })
    }

    @Bind
    private onChange(event: SyntheticEvent): void {
        const value: string = (event.target as HTMLInputElement).value
        this.setState({ rootCert: value })
    }

    @Bind
    private onSubmit(event: Event): void {
        event.preventDefault()
        this.setState({ submitLoading: true, success: "", error: "" })
        this.userApi
            .updateRootCert(this.state.rootCert)
            .then((res) => {
                this.setState({ submitLoading: false, success: res.Message, error: "" })
            })
            .catch((err) => {
                this.setState({ submitLoading: false, success: "", error: err.message })
            })
    }

    @Bind
    private fetchData(): void {
        Promise.all([this.userService.getUserOrgDetails(), this.infraService.getClusters()])
            .then(([userOrgDetails, clusters]) => {
                if (clusters.length) {
                    this.setState({
                        issuingCert: clusters[0].caCert,
                        sshCaPubKey: clusters[0].sshCaPubKey,
                        rootCert: userOrgDetails.RootCert,
                        showCertInput: Boolean(userOrgDetails.RootCert.length),
                    })
                }
            })
            .catch(() => {
                this.setState({ error: this.localizationService.getString("couldNotFetchData") })
            })
    }
}

interface TrustProviderIssuingCertsState {
    submitLoading: boolean
    showCertInput: boolean
    success: string
    error: string
    sshCaPubKey: string
    issuingCert: string
    rootCert: string
}

interface TrustProviderIssuingCertsProps {
    disabled?: boolean
    canAccessTrustProviderCerts?: boolean
}
