import React from "react"

import { Input } from "../../../../../../v3/components/input/Input.component"
import {
    ErrorBanner,
    InfoBanner,
    Form,
    FormRow,
    FormLabel,
    FormColumns,
} from "../../../../../components"
import { Select } from "../../../../../components/select/Select.component"
import styles from "./ApiKeysAdd.module.scss"
import {
    Button,
    ButtonElement,
    ButtonType,
} from "../../../../../../components/button/Button.component"

export default function () {
    return (
        <Form onSubmit={this.onSubmit} floating display="grid" labelWidth={200}>
            <FormColumns right={<h2>{this.ls.getString("newApiKey")}</h2>} />
            <FormRow>
                {this.state.error && <ErrorBanner>{this.state.error}</ErrorBanner>}
                {this.state.success && <InfoBanner>{this.state.success}</InfoBanner>}
            </FormRow>
            <FormLabel title={this.ls.getString("name")} htmlFor="name">
                <Input
                    name="name"
                    id="name"
                    className={styles.formInput}
                    required
                    placeholder={this.ls.getString("name")}
                />
            </FormLabel>
            <FormLabel title={this.ls.getString("description")} htmlFor="description">
                <Input
                    name="description"
                    id="description"
                    className={styles.formInput}
                    placeholder={this.ls.getString("description")}
                />
            </FormLabel>
            <FormLabel title={this.ls.getString("scope")} htmlFor="scope">
                <Select
                    required
                    value={this.state.scope}
                    options={this.state.scopeOptions}
                    onChange={this.onScopeChange}
                />
            </FormLabel>
            <FormColumns
                right={
                    <Button
                        buttonType={ButtonType.PRIMARY}
                        asElement={ButtonElement.BUTTON}
                        loading={this.state.submitting}
                        type="submit"
                    >
                        {this.ls.getString("addApiKey")}
                    </Button>
                }
            />
        </Form>
    )
}
