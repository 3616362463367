import { faCircle } from "@fortawesome/pro-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import React from "react"

import { LanguageKey } from "../../../../pre-v3/services/localization/languages/en-US.language"
import { useServiceLocalization } from "../../../../pre-v3/services/localization/Localization.service"
import {
    statusTypeLabelDict,
    trustLevelLabelDict,
    Status,
    StatusType,
    TrustLevel,
} from "../../../services/Device.service"
import styles from "./TrustLevelCellRenderer.module.scss"

interface Props {
    status: Status
}

export function TrustLevelCellRenderer(props: Props): JSX.Element {
    const localization = useServiceLocalization()

    const [labelKey, className] = getTrustLevelProps(props.status)

    return (
        <React.Fragment>
            <FontAwesomeIcon icon={faCircle} className={className} />
            {localization.getString(labelKey)}
        </React.Fragment>
    )
}

export const trustLevelClassName = styles.cell

export function getTrustLevelProps(status: Status): [LanguageKey, string] {
    switch (status.type) {
        case StatusType.BANNED:
            return [statusTypeLabelDict.BANNED, styles.bannedColor]

        case StatusType.EXPIRED:
            return [statusTypeLabelDict.EXPIRED, styles.expiredColor]

        case StatusType.PENDING:
            return [statusTypeLabelDict.PENDING, styles.pendingColor]

        case StatusType.OVERRIDDEN:
            return [
                trustLevelLabelDict[status.overriddenTrustLevel],
                trustLevelColorDict[status.overriddenTrustLevel],
            ]

        case StatusType.REPORTING:
            return [trustLevelLabelDict[status.trustLevel], trustLevelColorDict[status.trustLevel]]
    }
}

const trustLevelColorDict: Record<TrustLevel, string> = {
    [TrustLevel.ALWAYS_ALLOW]: styles.highTrustLevelColor,
    [TrustLevel.HIGH]: styles.highTrustLevelColor,
    [TrustLevel.MEDIUM]: styles.mediumTrustLevelColor,
    [TrustLevel.LOW]: styles.lowTrustLevelColor,
    [TrustLevel.ALWAYS_DENY]: styles.alwaysDenyColor,
}
