import React from "react"
import {
    faCheckCircle,
    faExclamationCircle,
    faPauseCircle,
    faTimesCircle,
    IconDefinition,
    faQuestionCircle,
} from "@fortawesome/pro-solid-svg-icons"
import classNames from "classnames"
import { useServiceLocalization } from "../../../pre-v3/services/localization/Localization.service"
import { StatusType as GenericStatusType } from "../../../utils/StatusType.utils"
import styles from "./Status.module.scss"
import { LanguageKey } from "../../../pre-v3/services/localization/languages/en-US.language"
import { Icon } from "../Icon/Icon.component"

export interface Props {
    type: StatusType
    label?: string
    icon?: IconDefinition
    hideIcon?: boolean
    iconClassName?: string
}

export function Status(props: Props) {
    const { type, label, icon, iconClassName, hideIcon } = props

    const localization = useServiceLocalization()

    return (
        <div className={styles.container}>
            {!hideIcon && (
                <Icon
                    icon={icon || iconMap[type]}
                    className={classNames(styles.statusIcon, classMap[type], iconClassName)}
                    large
                />
            )}
            <span className={styles.label}>{label || localization.getString(labelMap[type])}</span>
        </div>
    )
}

export type StatusType = "success" | "warning" | "error" | "disabled" | "unknown"
type LabelMap = Record<StatusType, LanguageKey>
type IconMap = Record<StatusType, IconDefinition>
type ClassMap = Record<StatusType, string>

export const labelMap: LabelMap = {
    success: "success",
    warning: "warning",
    error: "error",
    disabled: "disabled",
    unknown: "unknown",
}

export function getStatusType(statusType: GenericStatusType): StatusType {
    return statusTypeFromGeneric[statusType]
}

const statusTypeFromGeneric: Record<GenericStatusType, StatusType> = {
    [GenericStatusType.SUCCESS]: "success",
    [GenericStatusType.WARNING]: "warning",
    [GenericStatusType.ERROR]: "error",
    [GenericStatusType.DISABLED]: "disabled",
    [GenericStatusType.UNKNOWN]: "unknown",
}

const iconMap: IconMap = {
    success: faCheckCircle,
    warning: faExclamationCircle,
    error: faTimesCircle,
    disabled: faPauseCircle,
    unknown: faQuestionCircle,
}

const classMap: ClassMap = {
    success: styles.success,
    warning: styles.warning,
    error: styles.error,
    disabled: styles.disabled,
    unknown: styles.unknown,
}
