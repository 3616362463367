import {
    CellClassParams,
    ColDef,
    GetRowIdFunc,
    GridApi as AgGridApi,
    GridOptions,
    GridReadyEvent,
    IServerSideDatasource,
    IServerSideGetRowsParams,
    RowDragEndEvent,
    RowSelectedEvent,
} from "ag-grid-community"
import { AgGridReact } from "ag-grid-react/lib/agGridReact"
import classNames from "classnames/bind"
import React from "react"

import { useServiceLocalization } from "../../../pre-v3/services"
import { FilterModel, Paginated, PaginatedSearch, SortBy } from "../../../pre-v3/utils/AgGrid.util"
import styles from "./Grid.module.scss"

export type Props<T> = [T] extends [{ id: string }] ? WithId<T> : WithoutId<T>

export function GridComponent<T>(props: Props<T>, ref: React.ForwardedRef<GridApi>): JSX.Element {
    const {
        data,
        columns,
        className,
        getId,
        hasPagination,
        serverSideProps,
        filterAllColumnsValue,
        onDataSelected,
        onRowDragEnd,
    } = props

    const columnDefs = useColumns(columns, serverSideProps?.filterableKeys)

    const isDraggable = typeof onRowDragEnd !== "undefined"
    const isSelectable = typeof onDataSelected !== "undefined"
    const isServerSide = typeof serverSideProps !== "undefined"
    const domLayout: GridOptions<IndexedData<T>>["domLayout"] =
        isDraggable || hasPagination ? "normal" : "autoHeight"

    const getRowId = React.useCallback<GetRowIdFunc>(
        (params) => {
            const [value] = params.data
            return getId?.(value) ?? value.id
        },
        [getId]
    )

    const onRowDragEndFn = React.useCallback(
        (event: RowDragEndEvent) => {
            if (event.overNode && event.node.rowIndex !== null) {
                onRowDragEnd?.({
                    node: {
                        rowIndex: event.node.rowIndex,
                        data: event?.node?.data as T,
                    },
                    overNode: {
                        rowIndex: event.overIndex,
                        data: event?.overNode?.data?.[0] as T,
                    },
                })
            }
        },
        [onRowDragEnd]
    )

    const onRowSelected = React.useCallback(
        (event: RowSelectedEvent<IndexedData<T>>): void => {
            onDataSelected?.(event.api.getSelectedRows().map(getIndexedValue))
        },
        [onDataSelected]
    )

    const indexedData = React.useMemo(() => data?.map(addIndex), [data])

    const gridApi = React.useRef<AgGridApi<IndexedData<T>>>()

    React.useEffect(() => {
        gridApi.current?.sizeColumnsToFit()
    }, [data])

    const onGridReady = (grid: GridReadyEvent<IndexedData<T>>): void => {
        gridApi.current = grid.api
        grid.api.sizeColumnsToFit()

        if (serverSideProps) {
            grid.api.setFilterModel(serverSideProps.filterModel ?? {})
            grid.api.setServerSideDatasource(
                getDataSource(grid.api, serverSideProps.getServerSideData)
            )
        }
    }

    React.useImperativeHandle(
        ref,
        (): GridApi => ({
            deselectData: (): void => gridApi.current?.deselectAll(),
            refreshData: (): void => {
                if (isServerSide) gridApi.current?.refreshServerSide()
                gridApi.current?.deselectAll()
            },
        }),
        [gridApi.current, isServerSide]
    )

    React.useEffect(() => {
        gridApi.current?.setFilterModel(serverSideProps?.filterModel)
    }, [serverSideProps?.filterModel])

    return (
        <AgGridReact
            rowModelType={isServerSide ? "serverSide" : "clientSide"}
            rowData={indexedData}
            columnDefs={columnDefs}
            getRowId={getRowId}
            domLayout={domLayout}
            className={classNames("ag-theme-material", className)}
            pagination={hasPagination}
            paginationAutoPageSize={hasPagination}
            headerHeight={30}
            suppressContextMenu
            suppressCopyRowsToClipboard
            animateRows={isDraggable}
            onGridReady={onGridReady}
            onRowDragEnd={onRowDragEndFn}
            onRowSelected={onRowSelected}
            rowSelection={isSelectable ? "multiple" : undefined}
            rowMultiSelectWithClick={isSelectable}
            quickFilterText={filterAllColumnsValue}
        />
    )
}

export interface GridApi {
    deselectData(): void
    refreshData(): void
}

export const Grid = React.forwardRef(GridComponent) as <T>(
    props: Props<T> & { ref?: React.ForwardedRef<GridApi> }
) => JSX.Element

type IndexedData<T> = [T, number]

function addIndex<T>(value: T, index: number): IndexedData<T> {
    return [value, index]
}

function getIndexedValue<T>([value]: IndexedData<T>): T {
    return value
}

interface ServerSideProps<T> {
    getServerSideData(params: PaginatedSearch): Promise<Paginated<T>>
    filterModel?: FilterModel
    filterableKeys?: string[]
}

export type RowDragEndProps<T> = Record<"node" | "overNode", { rowIndex: number; data: T }>

interface BaseProps<T> {
    data?: T[]
    /**
     * Definitions of the Columns in display order
     */
    columns: Column<T>[]
    className?: string
    hasPagination?: boolean
    serverSideProps?: ServerSideProps<T>
    filterAllColumnsValue?: string
    onDataSelected?(data: T[]): void
    onRowDragEnd?(event: RowDragEndProps<T>): void
}

interface WithId<T extends { id: string }> extends BaseProps<T> {
    /**
     * Determines the unique identifier for each value from the data
     * @param value one Value from the data provided to the Grid
     */
    getId?(value: T): string
}

interface WithoutId<T> extends BaseProps<T> {
    /**
     * Determines the unique identifier for each value from the data
     * @param value one Value from the data provided to the Grid
     */
    getId(value: T): string
}

/**
 * Definition for a Column in the Grid
 */
export interface Column<T> {
    /**
     * A unique identifier
     */
    id: string
    /**
     * Label to display at the top
     */
    name: string
    cellClassName?: string
    /**
     * Determines how the value from the data will display in this column
     * @param value one Value from the data provided to the Grid
     */
    cellRenderer?: keyof T | CellRendererFn<T>
    sorting?: Sorting<T>
    getTooltipValue: keyof T | TooltipValueGetter<T>
    isFilterable?: boolean
    isRowDrag?: boolean | IsRowDragFn<T>
    width?: number
    autoHeight?: boolean
}

type CellRendererFn<T> = (value: T, index: number) => React.ReactNode
type IsRowDragFn<T> = (value: T, index: number) => boolean
type TooltipValueGetter<T> = (value: T, index: number) => string | number
type SortingValueGetter<T> = (value: T, index: number) => string | number

function useColumns<T>(columns: Column<T>[], filterableKeys?: string[]): ColDef<IndexedData<T>>[] {
    const localization = useServiceLocalization()
    const locale = localization.getLocale()

    return React.useMemo((): ColDef<IndexedData<T>>[] => {
        const emptyColumns: ColDef<IndexedData<T>>[] = []

        const { colIds, baseColumns } = columns.reduce(
            (acc, column) => ({
                colIds: acc.colIds.add(column.id),
                baseColumns: [...acc.baseColumns, mapColumn(locale, column)],
            }),
            { colIds: new Set<string>(), baseColumns: emptyColumns }
        )

        return (
            filterableKeys?.reduce(
                (acc, colId) => addHiddenColumns(colIds, acc, colId),
                baseColumns
            ) ?? baseColumns
        )
    }, [columns, filterableKeys, locale])
}

function mapColumn<T>(locale: string, column: Column<T>): ColDef<IndexedData<T>> {
    return {
        colId: column.id,
        headerName: column.name,
        width: column.width,
        cellRenderer:
            typeof column.cellRenderer !== "undefined"
                ? (params: CellClassParams) => {
                      const [value, index] = params.data
                      return (
                          // This component needs a span with the ellipsis style to truncate
                          // text in the cell
                          <span className={classNames(styles.ellipsis, column.cellClassName)}>
                              {typeof column.cellRenderer === "function"
                                  ? column.cellRenderer(value, index)
                                  : value[column.cellRenderer]}
                          </span>
                      )
                  }
                : undefined,
        tooltipValueGetter: (params) => {
            if (!params.data) return

            const [value, index] = params.data
            return typeof column.getTooltipValue === "function"
                ? column.getTooltipValue(value, index)
                : value[column.getTooltipValue]
        },
        cellClass: styles.cell,
        filter: column.isFilterable && "agTextColumnFilter",
        sortable: typeof column.sorting !== "undefined",
        sort:
            column.sorting?.type === SortingKey.SortWithServerSide
                ? column.sorting.isDefault
                : undefined,
        rowDrag:
            typeof column.isRowDrag !== "undefined"
                ? (params) => {
                      if (!params.data) return false

                      const [value, index] = params.data
                      if (typeof column.isRowDrag === "function") {
                          return column.isRowDrag(value, index)
                      }

                      return column.isRowDrag ?? false
                  }
                : undefined,
        comparator: (_valueA, _valueB, nodeA, nodeB) => {
            if (!column.sorting || !nodeA.data || !nodeB.data) return 0

            switch (column.sorting.type) {
                case SortingKey.SortByCustomFn:
                    return column.sorting.sortingFn(nodeA.data[0], nodeB.data[0])

                case SortingKey.SortAlphabetically:
                    const [stringA, stringB] = getString(
                        column.sorting.getString,
                        nodeA.data,
                        nodeB.data
                    )
                    return stringA.localeCompare(stringB, locale)

                case SortingKey.SortByTimeStamp:
                    const [num1, num2] = getNumber(column.sorting.getString, nodeA.data, nodeB.data)

                    return num1 - num2

                case SortingKey.SortWithServerSide:
                    return 0
            }
        },
        suppressMenu: true,
        suppressMovable: true,
        resizable: true,
        autoHeight: column.autoHeight,
        getQuickFilterText: (params) => {
            if (!params.data) return ""

            const [value, index] = params.data
            return String(
                typeof column.getTooltipValue === "function"
                    ? column.getTooltipValue(value, index)
                    : value[column.getTooltipValue]
            )
        },
    }
}

function addHiddenColumns<T>(
    existingColIds: Set<string>,
    prevColumns: ColDef<IndexedData<T>>[],
    colId: string
): ColDef<IndexedData<T>>[] {
    if (existingColIds.has(colId)) return prevColumns

    const hiddenColumn: ColDef<IndexedData<T>> = { colId, hide: true, filter: "agTextColumnFilter" }

    return [...prevColumns, hiddenColumn]
}

enum SortingKey {
    SortByCustomFn = "SortByCustomFn",
    SortAlphabetically = "SortAlphabetically",
    SortWithServerSide = "SortWithServerSide",
    SortByTimeStamp = "SortByTimeStamp",
}

type Sorting<T> =
    | SortByCustomFn<T>
    | SortAlphabetically<T>
    | SortWithServerSide
    | SortByTimeStamp<T>

interface SortByCustomFn<T> {
    type: SortingKey.SortByCustomFn
    sortingFn(valueA: T, valueB: T): number
}

export function sortByCustomFn<T>(sortingFn: (valueA: T, valueB: T) => number): SortByCustomFn<T> {
    return { type: SortingKey.SortByCustomFn, sortingFn }
}

interface SortByTimeStamp<T> {
    type: SortingKey.SortByTimeStamp
    getString: keyof T | SortingValueGetter<T>
}

export function sortByTimeStamp<T>(getString: keyof T | SortingValueGetter<T>): SortByTimeStamp<T> {
    return { type: SortingKey.SortByTimeStamp, getString }
}

interface SortAlphabetically<T> {
    type: SortingKey.SortAlphabetically
    getString: keyof T | SortingValueGetter<T>
}

export function sortAlphabetically<T>(
    getString: keyof T | SortingValueGetter<T>
): SortAlphabetically<T> {
    return { type: SortingKey.SortAlphabetically, getString }
}

interface SortWithServerSide {
    type: SortingKey.SortWithServerSide
    isDefault?: SortBy["sort"]
}

export function sortWithServerSide(isDefault?: SortBy["sort"]): SortWithServerSide {
    return { type: SortingKey.SortWithServerSide, isDefault }
}

function getString<T>(
    getString: keyof T | SortingValueGetter<T>,
    [valueA, indexA]: IndexedData<T>,
    [valueB, indexB]: IndexedData<T>
): [string, string] {
    if (typeof getString === "function")
        return [getString(valueA, indexA) as string, getString(valueB, indexB) as string]

    const stringA = valueA[getString]
    const stringB = valueB[getString]

    if (typeof stringA === "string" && typeof stringB === "string") return [stringA, stringB]

    if (typeof stringA !== "string")
        console.error(getStringError(getString, valueA), { key: getString, value: valueA })
    if (typeof stringB !== "string")
        console.error(getStringError(getString, valueB), { key: getString, value: valueB })

    return ["", ""]
}

function getNumber<T>(
    getString: keyof T | SortingValueGetter<T>,
    [valueA, indexA]: IndexedData<T>,
    [valueB, indexB]: IndexedData<T>
): [number, number] {
    if (typeof getString === "function") {
        return [getString(valueA, indexA) as number, getString(valueB, indexB) as number]
    }

    const insideValueA = valueA[getString]
    const insideValueB = valueB[getString]

    if (insideValueA instanceof Date && insideValueB instanceof Date) {
        return [insideValueA.valueOf(), insideValueB.valueOf()]
    }

    if (typeof insideValueA === "number" && typeof insideValueB === "number") {
        return [insideValueA, insideValueB]
    }

    if (typeof insideValueA !== "number")
        console.error(getNumberError(getString, valueA), { key: getString, value: valueA })
    if (typeof insideValueB !== "number")
        console.error(getNumberError(getString, valueB), { key: getString, value: valueB })

    return [0, 0]
}

function getStringError<T>(key: keyof T, value: T): string {
    return `The key "${key.toString()}" does not provide a string in the row. It provides a "${
        value[key]
    }" instead.`
}

function getNumberError<T>(key: keyof T, value: T): string {
    return `The key "${key.toString()}" does not provide a number in the row. It provides a "${
        value[key]
    }" instead.`
}

// Data Source

function getDataSource<T>(
    gridApi: AgGridApi<IndexedData<T>>,
    getServerSideData: (params: PaginatedSearch) => Promise<Paginated<T>>
): IServerSideDatasource {
    return {
        getRows: async (params: IServerSideGetRowsParams<T>): Promise<void> => {
            gridApi.showLoadingOverlay()

            const { startRow = 0, endRow = 10000, sortModel, filterModel } = params.request

            const serverSideDataParams: PaginatedSearch = {
                skip: startRow,
                limit: endRow - startRow,
                sortModel,
                filterModel,
            }

            try {
                const { data, total } = await getServerSideData(serverSideDataParams)

                params.success({ rowData: data.map(addIndex), rowCount: total })
                if (total === 0) {
                    gridApi.showNoRowsOverlay()
                } else {
                    gridApi.hideOverlay()
                }
            } catch (error) {
                console.error(error)
                params.fail()
                gridApi.hideOverlay()
            }
        },
    }
}
