import classNames from "classnames/bind"
import React from "react"

import { OverflowTooltip } from "../../v3/components/overflowTooltip/OverFlowTooltip.component"
import styles from "./KeyValuePair.module.scss"

export interface Props {
    label: string
    value?: React.ReactNode
    className?: string
}

export function KeyValuePair(props: Props): JSX.Element {
    return (
        <div className={classNames(styles.container, props.className)}>
            <span className={styles.label}>{props.label}</span>
            <div className={styles.value}>
                <OverflowTooltip>{props.value}</OverflowTooltip>
            </div>
        </div>
    )
}

export function KeyValuePairList(
    props: React.PropsWithChildren<{ className?: string }>
): JSX.Element {
    return <div className={classNames(styles.list, props.className)}>{props.children}</div>
}
