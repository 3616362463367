import React from "react"
import styles from "./SimpleTable.module.scss"
import { OverflowTooltip } from "../../../v3/components/overflowTooltip/OverFlowTooltip.component"
import classNames from "classnames/bind"

export default function () {
    return (
        <table className={classNames(this.props.className, styles.simpleTable)}>
            <tbody>
                {this.props.items &&
                    this.props.items.map((item) => (
                        <tr key={item.label}>
                            <td className={styles.label}>
                                <div>{item.label}</div>
                            </td>
                            <td className={classNames(styles.valueCell)}>
                                <div className={classNames(item.valueClassName, styles.value)}>
                                    <OverflowTooltip>{item.value}</OverflowTooltip>
                                </div>
                            </td>
                        </tr>
                    ))}
            </tbody>
        </table>
    )
}
