import { ICellRendererParams } from "ag-grid-community"
import React, { SyntheticEvent } from "react"
import { useHistory } from "react-router"

import { ROUTE, formatRoutePath } from "../../../../routes"
import { PolicyAttachmentType } from "../../../api/Manage.api"
import { useServiceManage } from "../../../services"
import { encodeID } from "../../../utils/Url.util"
import { Link } from "../../../../v3/components/link/Link.component"

export function ServicesCellRenderer(props: ServicesCellRendererProps) {
    const manageService = useServiceManage()
    const history = useHistory()

    const serviceId: string = props.data.id || props.data.serviceId
    const serviceBase64: string = encodeID(serviceId)

    let route = ROUTE.PRIVATE_ACCESS_SERVICES_DETAILS

    // compute the link to the service

    // if we were given a service
    if (props.data.spec) {
        // look up the template to figure out where the link should point
        // if the template is an infrastructure service
        if (manageService.isInfraService(props.data)) {
            route = ROUTE.INFRASTRUCTURE_DETAILS
        } else if (
            props.data.attachedToType &&
            props.data.attachedToType === PolicyAttachmentType.SAAS_APP
        ) {
            route = ROUTE.SAAS_APPS_DETAILS
        }
    }

    // fill in the service id for the link
    const link = serviceBase64 ? formatRoutePath(route, { id: serviceBase64 }) : ""

    // the click handler for the link
    const clickHandler = (event: SyntheticEvent): void => {
        event.preventDefault()
        history.push(link)
    }

    return link ? (
        <Link to={link} onClick={clickHandler}>
            {props.value || props.data.serviceName || props.data.name}
        </Link>
    ) : (
        "-"
    )
}

interface ServicesCellRendererProps extends ICellRendererParams {
    context: any
}
