import React from "react"

import { PageHeading } from "../../../components/page-heading/PageHeading.component"
import { useServiceLocalization } from "../../../pre-v3/services/localization/Localization.service"
import { Container } from "../../../v3/components/container/Container.component"
import { Loader } from "../../../v3/components/loader/Loader.component"
import { TabBar } from "../../../v3/components/tab-bar/TabBar.component"
import { defaultUdpPortNumber } from "../../../v3/services/shared/AccessTier"
import { Cluster } from "../../../v3/services/shared/Cluster"
import { Organization } from "../../../v3/services/shared/Organization"
import {
    AccessTierDetails,
    AccessTierGroup,
    ApiKey,
    CreateAccessTierVariables,
    useGetAccessTiersGroups,
    useGetApiKeys,
} from "../../../v3/services/AccessTier.service"
import { Banner, Variant } from "../../../components/banner/Banner.component"
import { AccessTierConfigureTab } from "./AccessTierConfigureTab.component"
import { AccessTierInstallTab } from "./AccessTierInstallTab.component"
import { AccessTierValidateTab } from "./AccessTierValidateTab.component"
import styles from "./AccessTierCreate.module.scss"

interface Props {
    canAccessAccessTierGroups: boolean
    privateEdgeCluster: Cluster
    organization: Organization
    canAccessAccessTierInstallStep: boolean
}

export function AccessTierCreate(props: Props): JSX.Element {
    const localization = useServiceLocalization()

    const { data: apiKeys, status: apiKeysStatus, error: apiKeyError } = useGetApiKeys()

    const {
        data: accessTierGroups = [],
        status: accessTierGroupsStatus,
        error: accessTierGroupsError,
    } = useGetAccessTiersGroups()

    if (apiKeysStatus === "loading" || accessTierGroupsStatus === "loading") {
        return (
            <Loader
                center
                medium
                title={localization.getString(
                    "loadingSomething",
                    localization.getString("accessTier")
                )}
            />
        )
    }

    if (apiKeysStatus === "error" || accessTierGroupsStatus === "error") {
        return (
            <Container>
                <Banner
                    label={String(apiKeyError ?? accessTierGroupsError)}
                    variant={Variant.ERROR}
                />
            </Container>
        )
    }

    return (
        <CreateWithApiKeys
            apiKeys={apiKeys}
            accessTierGroups={accessTierGroups}
            privateEdgeCluster={props.privateEdgeCluster}
            organization={props.organization}
            canAccessAccessTierGroups={props.canAccessAccessTierGroups}
            canAccessAccessTierInstallStep={props.canAccessAccessTierInstallStep}
        />
    )
}

function Page(props: React.PropsWithChildren): JSX.Element {
    const ls = useServiceLocalization()

    return (
        <Container as="section" className={styles.container} aria-labelledby={Id.HEADING}>
            <header>
                <PageHeading id={Id.HEADING}>{ls.getString("createAccessTier")}</PageHeading>
            </header>
            <p>{ls.getString("createAnAccessTierDesc")}</p>
            {props.children}
        </Container>
    )
}

enum Id {
    HEADING = "heading",
    PAGE = "page",
    TAB_BAR = "tabBar",
}

interface CreateWithApiKeysProps {
    apiKeys: ApiKey[]
    accessTierGroups: AccessTierGroup[]
    privateEdgeCluster: Cluster
    organization: Organization
    canAccessAccessTierGroups: boolean
    canAccessAccessTierInstallStep: boolean
}

function CreateWithApiKeys(props: CreateWithApiKeysProps): JSX.Element {
    const localization = useServiceLocalization()

    const initialState: State = {
        step: Step.CONFIGURE,
        accessTier: {
            name: "",
            apiKey: props.apiKeys[0],
            udpPortNumber: defaultUdpPortNumber,
            isSecure: false,
        },
    }

    const [state, dispatch] = React.useReducer(reducer, initialState)

    const stepOptions = Object.values(Step).map((step) => {
        return {
            id: step,
            label: localization.getString(step),
            ariaControls: `${step}-tab`,
        }
    })

    switch (state.step) {
        case Step.CONFIGURE:
            return (
                <Page key={Id.PAGE}>
                    <TabBar
                        key={Id.TAB_BAR}
                        type="stepper"
                        tabs={stepOptions}
                        selectedTabId={state.step}
                    />
                    <AccessTierConfigureTab
                        accessTier={state.accessTier}
                        originalAccessTier={state.originalAccessTier}
                        apiKeys={props.apiKeys}
                        accessTierGroups={props.accessTierGroups}
                        privateEdgeCluster={props.privateEdgeCluster}
                        canAccessAccessTierGroups={props.canAccessAccessTierGroups}
                        onChange={(accessTier) =>
                            dispatch({ event: Event.ACCESS_TIER_WAS_CONFIGURED, accessTier })
                        }
                        onContinue={(accessTier) =>
                            dispatch({ event: Event.CONFIGURE_FINISHED, accessTier })
                        }
                    />
                </Page>
            )

        case Step.INSTALL:
            return (
                <Page key={Id.PAGE}>
                    <TabBar
                        key={Id.TAB_BAR}
                        type="stepper"
                        tabs={stepOptions}
                        selectedTabId={state.step}
                    />
                    <AccessTierInstallTab
                        accessTier={state.accessTier}
                        organization={props.organization}
                        onContinue={() =>
                            dispatch({
                                event: Event.INSTALLATION_FINISHED,
                                accessTier: state.accessTier,
                            })
                        }
                        onBack={() =>
                            dispatch({
                                event: Event.RETURNED_TO_CONFIGURE,
                                accessTier: state.accessTier,
                            })
                        }
                        onChange={(accessTier) =>
                            dispatch({ event: Event.ACCESS_TIER_UPDATED_FROM_INSTALL, accessTier })
                        }
                        canAccessAccessTierInstallStep={props.canAccessAccessTierInstallStep}
                    />
                </Page>
            )

        case Step.VALIDATE:
            return (
                <Page key={Id.PAGE}>
                    <TabBar
                        key={Id.TAB_BAR}
                        type="stepper"
                        tabs={stepOptions}
                        selectedTabId={state.step}
                    />
                    <AccessTierValidateTab
                        accessTier={state.accessTier}
                        onBack={() =>
                            dispatch({
                                event: Event.RETURNED_TO_INSTALL,
                                accessTier: state.accessTier,
                            })
                        }
                        onChange={(accessTier) =>
                            dispatch({ event: Event.ACCESS_TIER_UPDATED_FROM_VALIDATE, accessTier })
                        }
                    />
                </Page>
            )
    }
}

enum Step {
    CONFIGURE = "configure",
    INSTALL = "install",
    VALIDATE = "validate",
}

type State =
    | {
          step: Step.CONFIGURE
          accessTier: CreateAccessTierVariables
          originalAccessTier?: AccessTierDetails
      }
    | {
          step: Step.INSTALL
          accessTier: AccessTierDetails
      }
    | {
          step: Step.VALIDATE
          accessTier: AccessTierDetails
      }

enum Event {
    ACCESS_TIER_WAS_CONFIGURED = "ACCESS_TIER_WAS_CONFIGURED",
    CONFIGURE_FINISHED = "CONFIGURE_FINISHED",
    ACCESS_TIER_UPDATED_FROM_INSTALL = "ACCESS_TIER_UPDATED_FROM_INSTALL",
    RETURNED_TO_CONFIGURE = "RETURNED_TO_CONFIGURE",
    INSTALLATION_FINISHED = "INSTALLATION_FINISHED",
    RETURNED_TO_INSTALL = "RETURNED_TO_INSTALL",
    ACCESS_TIER_UPDATED_FROM_VALIDATE = "ACCESS_TIER_UPDATED_FROM_VALIDATE",
}

type Action =
    | {
          event: Event.ACCESS_TIER_WAS_CONFIGURED
          accessTier: CreateAccessTierVariables
      }
    | {
          event:
              | Event.CONFIGURE_FINISHED
              | Event.ACCESS_TIER_UPDATED_FROM_INSTALL
              | Event.RETURNED_TO_CONFIGURE
              | Event.INSTALLATION_FINISHED
              | Event.RETURNED_TO_INSTALL
              | Event.ACCESS_TIER_UPDATED_FROM_VALIDATE
          accessTier: AccessTierDetails
      }

function reducer(state: State, action: Action): State {
    switch (action.event) {
        case Event.ACCESS_TIER_WAS_CONFIGURED:
            return state.step === Step.CONFIGURE
                ? { ...state, accessTier: action.accessTier }
                : state

        case Event.CONFIGURE_FINISHED:
            return { step: Step.INSTALL, accessTier: action.accessTier }

        case Event.ACCESS_TIER_UPDATED_FROM_INSTALL:
            return state.step === Step.INSTALL ? { ...state, accessTier: action.accessTier } : state

        case Event.RETURNED_TO_CONFIGURE:
            return {
                step: Step.CONFIGURE,
                accessTier: action.accessTier,
                originalAccessTier: action.accessTier,
            }

        case Event.INSTALLATION_FINISHED:
            return { step: Step.VALIDATE, accessTier: action.accessTier }

        case Event.RETURNED_TO_INSTALL:
            return { step: Step.INSTALL, accessTier: action.accessTier }

        case Event.ACCESS_TIER_UPDATED_FROM_VALIDATE:
            return state.step === Step.VALIDATE
                ? { ...state, accessTier: action.accessTier }
                : state
    }
}
