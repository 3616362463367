import React, { FC, FormEvent, useState } from "react"
import { ModalRef } from "../../../../pre-v3/services/Modal.service"
import { useServiceLocalization } from "../../../../pre-v3/services/localization/Localization.service"
import {
    CreateMspAdminHookData,
    UserListDataHook,
    useCreateMspAdmin,
    useGetUsersList,
} from "../../../services/MspOrgManagement.service"
import styles from "./MspAdminModals.module.scss"
import { Form } from "../../../components/form/Form.component"
import { FormColumn } from "../../../components/form/FormColumn.component"
import { ErrorBanners } from "../../../components/banner/Banner.component"
import { Button } from "../../../components/button/Button.component"
import { SearchUserInput } from "../form/SearchUserInput.compoment"
import { SelectInput } from "../../../components/select-input/SelectInput.component"
interface Props {
    modalRef: ModalRef
    orgId: string
}
export const AddMspAdminModal: FC<Props> = ({ modalRef, orgId }) => {
    const [user, setUser] = useState<UserListDataHook>()
    const [profile, setProfile] = useState<CreateMspAdminHookData["profile"] | "">("")

    const localization = useServiceLocalization()

    const {
        mutateAsync: addAdmin,
        isLoading: isCrateLoading,
        error: createError,
    } = useCreateMspAdmin(orgId)
    const { data: userList, isLoading: isUserListLoading, error: userListError } = useGetUsersList()

    function onAdd(e: FormEvent<HTMLFormElement>) {
        e.preventDefault()
        if (!user || !profile) return
        addAdmin({ profile, email: user?.email }).then(() => {
            modalRef.close(null)
        })
    }

    function onCancel() {
        modalRef.close(null)
    }

    return (
        <div className={styles.container}>
            <Form onSubmit={onAdd}>
                <div className={styles.inputs}>
                    <div className={styles.input}>
                        <FormColumn label={localization.getString("admin")} htmlFor="user">
                            <SearchUserInput
                                userList={userList}
                                loading={isUserListLoading}
                                setUser={setUser}
                                placeholder={localization.getString("searchUserPlaceHolder")}
                            />
                        </FormColumn>
                    </div>
                    <div className={styles.input}>
                        <FormColumn
                            label={localization.getString("adminRolePermissions")}
                            htmlFor="role"
                        >
                            <SelectInput
                                options={["Admin", "ReadOnly"]}
                                value={profile}
                                onChange={setProfile}
                                id="role"
                                name="role"
                                placeholder={localization.getString("selectAnOption")}
                                required
                            />
                        </FormColumn>
                    </div>
                </div>
                <ErrorBanners errors={[createError, userListError]} />
                <div className={styles.buttons}>
                    <Button disabled={isCrateLoading} onClick={onCancel}>
                        {localization.getString("close")}
                    </Button>
                    <Button brand loading={isCrateLoading} type="submit">
                        {localization.getString("save")}
                    </Button>
                </div>
            </Form>
        </div>
    )
}
