import { FontAwesomeIcon, FontAwesomeIconProps } from "@fortawesome/react-fontawesome"
import { faCheckCircle, faTimesCircle } from "@fortawesome/pro-solid-svg-icons"
import classNames from "classnames/bind"
import React from "react"

import { useServiceLocalization } from "../../../../pre-v3/services/localization/Localization.service"
import { ExtendedFactor, TrustFactor } from "../../../services/Device.service"
import { Column, Grid, sortAlphabetically } from "../../../components/grid/Grid.component"
import { InfoIcon } from "../../../components/info-icon/InfoIcon.component"
import { Pill } from "../../../components/pill/Pill.component"
import styles from "./DeviceTrustFactorGrid.module.scss"

interface Props {
    trustFactors: TrustFactor[]
}

export function DeviceTrustFactorGrid(props: Props): JSX.Element {
    const columns = useColumns()

    return <Grid data={props.trustFactors} columns={columns} getId={getId} />
}

function useColumns(): Column<TrustFactor>[] {
    const localization = useServiceLocalization()

    return React.useMemo(() => {
        const nameColumn: Column<TrustFactor> = {
            id: "name",
            name: localization.getString("trustFactor"),
            cellRenderer: nameCellRenderer,
            cellClassName: styles.trustFactorCell,
            getTooltipValue: constEmptyString,
            autoHeight: true,
            sorting: sortAlphabetically("name"),
        }

        const sourceColumn: Column<TrustFactor> = {
            id: "source",
            name: localization.getString("source"),
            cellRenderer: "source",
            cellClassName: styles.sourceCell,
            getTooltipValue: "source",
            sorting: sortAlphabetically("source"),
        }

        return [nameColumn, sourceColumn]
    }, [])
}

function nameCellRenderer(trustFactor: TrustFactor): JSX.Element {
    return (
        <React.Fragment>
            <FontAwesomeIcon {...getIconProps(trustFactor.isSatisfied)} />
            <span className={styles.trustFactorTitle}>
                {trustFactor.name}
                <InfoIcon tooltip={trustFactor.description} />
            </span>
            {trustFactor.extendedFactors && (
                <div className={styles.extendedFactors}>
                    {trustFactor.extendedFactors.map(renderExtendedFactor)}
                </div>
            )}
        </React.Fragment>
    )
}

function getIconProps(isSatisfied: boolean): FontAwesomeIconProps {
    return isSatisfied
        ? { icon: faCheckCircle, className: styles.trustFactorIsSatisfied }
        : { icon: faTimesCircle, className: styles.trustFactorIsNotSatisfied }
}

function renderExtendedFactor(factor: ExtendedFactor): JSX.Element {
    return (
        <Pill
            key={factor.name}
            id={factor.name}
            label={factor.name}
            className={classNames({ [styles.unsatisfiedPill]: !factor.isSatisfied })}
            disabled
        />
    )
}

function constEmptyString(): string {
    return ""
}

function getId(trustFactor: TrustFactor): string {
    return trustFactor.name
}
