import React from "react"
import styles from "./InfoTip.module.scss"
import { Tooltip } from "@mui/material"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faInfoCircle } from "@fortawesome/pro-solid-svg-icons"
import classNames from "classnames/bind"

export default function () {
    return (
        <Tooltip
            className={classNames(styles.tooltipContainer, this.props.className)}
            title={this.props.message}
            placement={this.props.tooltipPlacement || "right"}
            // TODO: remove styles.tooltipElement, its there to patch some issues in mui
            classes={{ popper: styles.tooltip, tooltip: styles.tooltipElement }}
        >
            {this.props.component || (
                <span className={styles.tooltipIcon}>
                    <FontAwesomeIcon icon={faInfoCircle} />
                </span>
            )}
        </Tooltip>
    )
}
