import React, { ReactNode, SyntheticEvent } from "react"
import TrustProviderTokenExpiryTemplate from "./TrustProviderTokenExpiry.template"
import { LocalizationService } from "../../../../../services/localization/Localization.service"
import { Bind } from "../../../../../decorators/Bind.decorator"
import { UserService } from "../../../../../services/User.service"
import { UserOrgDetails, TrustConfig, UserApi } from "../../../../../api/User.api"

export class TrustProviderTokenExpiry extends React.Component<
    TrustProviderTokenExpiryProps,
    TrustProviderTokenExpiryState
> {
    public state: TrustProviderTokenExpiryState = {
        loading: false,
        error: "",
        success: "",
        trustConfig: {
            LoginTokenExpiry: 24,
            ExternalTokenExpiry: 24,
            RegistrationTokenExpiry: 2,
            ReportingTokenExpiry: 168,
            RenewalTokenExpiry: 2,
        },
    }

    public render(): ReactNode {
        return TrustProviderTokenExpiryTemplate.call(this)
    }

    public componentDidMount(): void {
        this.fetchData()
    }

    private localizationService: LocalizationService = new LocalizationService()
    private userApi: UserApi = new UserApi()
    private userService: UserService = new UserService()

    @Bind
    private handleChange(event: SyntheticEvent): void {
        this.setState({ loginTokenExpiry: parseInt((<HTMLInputElement>event.target).value) })
    }

    @Bind
    private onSubmit(event: Event): void {
        event.preventDefault()
        this.setState({ loading: true, success: "", error: "" })
        if (event && event.target) {
            this.userApi
                .updateTrustConfig({
                    ...this.state.trustConfig,
                    LoginTokenExpiry: this.state.loginTokenExpiry!,
                    ExternalTokenExpiry: this.state.loginTokenExpiry!,
                })
                .then((res) => {
                    this.setState({ loading: false, success: res.Message })
                    this.fetchData()
                })
                .catch((error) => {
                    this.setState({ loading: true, error: error.message })
                })
        }
    }

    @Bind
    private fetchData(): void {
        this.userService.getUserOrgDetails().then((details: UserOrgDetails) => {
            try {
                const trustConfig = JSON.parse(details.TrustConfig)
                this.setState({
                    trustConfig: { ...this.state.trustConfig, ...trustConfig },
                    loginTokenExpiry: trustConfig.LoginTokenExpiry,
                })
            } catch (error) {
                this.setState({ error: error as string })
            }
        })
    }
}

interface TrustProviderTokenExpiryState {
    loginTokenExpiry?: number
    trustConfig: TrustConfig
    loading: boolean
    error: string
    success: string
}

type TrustProviderTokenExpiryProps = {
    disabled?: boolean
    canUpdateTokenExpiry?: boolean
}
