import React from "react"

import {
    BaseAvailablePlatforms,
    Props as BaseAvailablePlatformsProps,
} from "../available-platforms/BaseAvailablePlatforms.component"
import styles from "./AvailablePlatforms.module.scss"

export type Props = BaseAvailablePlatformsProps

export function AvailablePlatforms(props: Props): JSX.Element {
    return <BaseAvailablePlatforms {...props} />
}

export const availablePlatformsClassName = styles.cell
