import React from "react"

import crowdStrikeSvg from "../../../../../../../../images/trust-integration/crowd-strike.svg"
import sentinelOneSvg from "../../../../../../../../images/trust-integration/sentinel-one.svg"
import workspaceOneSvg from "../../../../../../../../images/trust-integration/workspace-one.svg"
import { useServiceLocalization } from "../../../../../../../../pre-v3/services"
import { LanguageKey } from "../../../../../../../../pre-v3/services/localization/languages/en-US.language"
import { StringUtil } from "../../../../../../../../pre-v3/utils/String.util"
import {
    SelectInput,
    Option,
} from "../../../../../../../components/select-input/SelectInput.component"
import {
    TrustFactor,
    TrustSourceType,
    IntegrationPartner,
} from "../../../../../../../services/TrustProfile.service"
import { TrustFactorsPerIntegration } from "../useTrustFactors.hook"
import { FactorContainer } from "./FactorContainer.component"
import styles from "./IntegrationFactor.module.scss"

interface Props extends TrustFactorsPerIntegration {
    search: string
    onAddTrustFactor(trustFactor: TrustFactor): void
}

export function IntegrationFactor(props: Props): JSX.Element {
    const { search, onAddTrustFactor, ...integration } = props

    const localizationService = useServiceLocalization()

    const [selectedFactor, setSelectedFactor] = React.useState(integration.trustFactors.unused[0])

    if (!selectedFactor || !isVisible(integration, search)) return <React.Fragment />

    const sourceLabel = localizationService.getString(
        sourceLabelDict[integration.integrationPartner]
    )

    const onTrustFactorChange = (value: string) => {
        const trustFactor = findTrustFactor(value, integration.trustFactors.unused)
        if (trustFactor) setSelectedFactor(trustFactor)
    }

    return (
        <FactorContainer
            sourceLabel={`${sourceLabel} - ${integration.integrationName}`}
            logo={<img src={imgSrcDict[integration.integrationPartner]} alt={sourceLabel} />}
            description={integration.description}
            selectedFactorLabel={`${integration.integrationName} - ${selectedFactor.label}`}
            onAddTrustFactor={() => onAddTrustFactor(selectedFactor)}
        >
            <div className={styles.container}>
                <b>{localizationService.getString("selectAFactor")}</b>
                <SelectInput
                    value={selectedFactor.id}
                    options={integration.trustFactors.unused.map(mapTrustFactorOption)}
                    onChange={onTrustFactorChange}
                    disabled={integration.trustFactors.unused.length <= 1}
                />
            </div>
        </FactorContainer>
    )
}

function isVisible(integration: TrustFactorsPerIntegration, search: string): boolean {
    return (
        !search ||
        StringUtil.caseInsensitiveIncludes(integration.integrationName, search) ||
        StringUtil.caseInsensitiveIncludes(integration.integrationPartner, search) ||
        (integration.description &&
            StringUtil.caseInsensitiveIncludes(integration.description, search)) ||
        integration.trustFactors.unused.some(
            (trustFactor) =>
                StringUtil.caseInsensitiveIncludes(trustFactor.label, search) ||
                StringUtil.caseInsensitiveIncludes(trustFactor.description, search)
        )
    )
}

const sourceLabelDict: Record<IntegrationPartner, LanguageKey> = {
    [TrustSourceType.CROWD_STRIKE]: "crowdstrike",
    [TrustSourceType.SENTINEL_ONE]: "sentinelone",
    [TrustSourceType.WORKSPACE_ONE]: "workspaceone",
}

const imgSrcDict: Record<IntegrationPartner, string> = {
    [TrustSourceType.CROWD_STRIKE]: crowdStrikeSvg,
    [TrustSourceType.SENTINEL_ONE]: sentinelOneSvg,
    [TrustSourceType.WORKSPACE_ONE]: workspaceOneSvg,
}

function mapTrustFactorOption(trustFactor: TrustFactor): Option {
    return { displayName: trustFactor.label, value: trustFactor.id }
}

function findTrustFactor(value: string, trustFactors: TrustFactor[]): TrustFactor | undefined {
    return trustFactors.find((trustFactor) => trustFactor.id === value)
}
