import React from "react"

import { AppBannerContext, AppBannerKey } from "./AppBanner.context"
import { OrgInfo, useHasGranularTrustMigrationOccurred } from "../../services/Org.service"

interface HideBanner {
    hideBanner: true
}

interface ShowBanner {
    hideBanner: false
    onClose(): void
}

type UseGranularTrustMigrationEducationResult = HideBanner | ShowBanner

export function useGranularTrustMigrationEducationBanner(): UseGranularTrustMigrationEducationResult {
    const { hasUserClosedBanner, onUserClosingBanner } =
        React.useContext(AppBannerContext)[AppBannerKey.GRANULAR_TRUST_MIGRATION_EDUCATION]

    const { data: hasGranularTrustMigrationOcurred } = useHasGranularTrustMigrationOccurred({
        placeholderData: { isGranularTrustEnabled: true } as OrgInfo,
    })

    return React.useMemo<UseGranularTrustMigrationEducationResult>(() => {
        if (hasUserClosedBanner || hasGranularTrustMigrationOcurred) return { hideBanner: true }

        return { hideBanner: false, onClose: onUserClosingBanner }
    }, [hasGranularTrustMigrationOcurred, hasUserClosedBanner, onUserClosingBanner])
}
