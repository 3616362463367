import * as React from "react"
import { PolicyAttr, PolicySpec, PolicyAccess } from "../../../api/Secure.api"
import { PolicySecure, useServiceSecure } from "../../../services"
import { AccessGroupList } from "./AccessGroupList"

interface Props {
    setAttr: (attr: PolicyAttr) => void
    setError: (err: string | null) => void
    roles: string[]
    edit?: PolicySecure
    loading: boolean
    disabled?: boolean
}

export function WebPolicyForm({ loading, setAttr, edit, roles, setError, disabled }: Props) {
    // grab service references
    const secureService = useServiceSecure()

    // only parse the spec a single time
    const selectedSpec = React.useMemo(
        () => (edit ? JSON.parse(edit.spec) : null) as PolicySpec,
        [edit]
    )

    const [access, setAccess] = React.useState<PolicyAccess[]>(selectedSpec?.spec.access || [])

    React.useEffect(() => {
        // build up the policy attributes
        let policyAttr: PolicyAttr = secureService.getNullPolicyAttr()
        policyAttr.options = {
            disable_tls_client_authentication: true,
            l7_protocol: "http",
        }
        policyAttr.access = access

        // update the parent state
        setAttr(policyAttr)
    }, [secureService, setAttr, setError, access])

    return (
        <AccessGroupList
            initialState={access}
            setAccess={setAccess}
            disabled={disabled}
            loading={loading}
            roles={roles}
        />
    )
}
