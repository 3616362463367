import * as React from "react"
import styles from "./Widget.module.scss"
import { Link } from "react-router-dom"
import { useServiceLocalization } from "../../services"

interface WidgetProps {
    children?: React.ReactNode
    gridArea?: string
    loading: boolean
    hasData: boolean
}

export function Widget({ gridArea, children, hasData, loading }: WidgetProps) {
    let content = children
    if (loading) {
        content = null
    } else if (!hasData) {
        content = <NoDataOverlay />
    }

    return (
        <figure style={{ gridArea }} className={styles.widgetContainer}>
            {content}
        </figure>
    )
}

export function CountWidget({
    gridArea,
    title,
    count,
    link,
}: {
    title: string
    count?: number
    link: string
    gridArea: string
}) {
    return (
        <Link to={link} style={{ gridArea }}>
            <Widget loading={false} hasData={true}>
                <h3 className={styles.countTitle}>{title}</h3>
                <div className={styles.countValue}>{typeof count === "number" ? count : "-"}</div>
            </Widget>
        </Link>
    )
}

export function NoDataOverlay() {
    const localization = useServiceLocalization()
    return <div className={styles.noDataOverlay}>{localization.getString("noDataToDisplay")}</div>
}
