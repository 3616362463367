import React, { ReactNode } from "react"
import IconSelectTemplate from "./IconSelect.template"
import { fab } from "@fortawesome/free-brands-svg-icons"
import { LocalizationService } from "../../services/localization/Localization.service"
import { Bind } from "../../decorators/Bind.decorator"
import {
    faCloud,
    faServer,
    faUser,
    faLaptop,
    faCog,
    faLocationArrow,
    faProjectDiagram,
    faCube,
    faShieldAlt,
    faLock,
    faSearch,
    IconDefinition,
    IconName,
} from "@fortawesome/pro-solid-svg-icons"

export class IconSelect extends React.Component<IconSelectProps, IconSelectState> {
    public state: IconSelectState = { showDialog: false }

    public render(): ReactNode {
        return IconSelectTemplate.call(this)
    }

    public componentDidMount(): void {
        this.icons = Object.values(fab).filter((a) => !a.iconName.startsWith("font-"))
        this.icons.splice(
            0,
            0,
            faCloud,
            faCog,
            faCube,
            faLaptop,
            faLocationArrow,
            faLock,
            faProjectDiagram,
            faSearch,
            faServer,
            faShieldAlt,
            faUser
        )

        this.setState({ icons: this.icons })

        if (this.props.initialValue) {
            this.setState({
                selectedIcon: this.icons.find((a) => a.iconName === this.props.initialValue),
            })
        }
    }

    private localizationService: LocalizationService = new LocalizationService()
    private icons: IconDefinition[]

    @Bind
    private onShowDialog(): void {
        this.setState({ showDialog: true })
    }

    @Bind
    private onHideDialog(): void {
        this.setState({ showDialog: false })
    }

    @Bind
    private onIconSelected(icon: IconDefinition): void {
        this.setState({ selectedIcon: icon, showDialog: false })
        if (this.props.onChange) {
            this.props.onChange(icon.iconName)
        }
    }

    @Bind
    private onFilter(value: string): void {
        const filteredIcons: IconDefinition[] = this.icons.filter((icon) => {
            return icon.iconName.includes(value)
        })
        this.setState({ icons: filteredIcons })
    }
}

interface IconSelectProps {
    className?: string
    onChange: (icon: IconName) => void
    initialValue?: IconName
    disabled?: boolean
}

interface IconSelectState {
    showDialog: boolean
    icons?: IconDefinition[]
    selectedIcon?: IconDefinition
}
