import { Tooltip } from "@mui/material"
import classNames from "classnames/bind"
import React from "react"
import { Link } from "react-router-dom"
import {
    Button,
    ButtonElement,
    ButtonType,
    IconType,
} from "../../../components/button/Button.component"
import { OverflowTooltip } from "../../../v3/components/overflowTooltip/OverFlowTooltip.component"
import styles from "./ActionBar.module.scss"

export default function () {
    const refreshLabel = this.localizationService.getString("refresh")

    return (
        <div className={styles.actionBar}>
            <div className={styles.outerContainer}>
                <div className={styles.breadcrumbContainer}>
                    {this.state.breadcrumbs.map((crumb, index) => {
                        return (
                            <span className={styles.breadcrumb} key={index}>
                                {crumb.href ? (
                                    <Link key={index} to={crumb.href}>
                                        {crumb.label}
                                    </Link>
                                ) : (
                                    <span>{crumb.label}</span>
                                )}
                                <span className={styles.breadcrumbSeparator}>&#8963;</span>
                            </span>
                        )
                    })}
                </div>
                <div className={styles.actionsContainer}>
                    {this.state.actions.map((a, i) => (
                        <Tooltip
                            key={i}
                            title={a.tooltip}
                            classes={{ tooltip: styles.actionBarItemTooltip }}
                            placement="bottom"
                        >
                            <div>
                                {a.onClick ? (
                                    <Button
                                        className={classNames(styles.actionBarItem, a.className)}
                                        disabled={a.disabled}
                                        onClick={a.onClick}
                                        aria-label={a.tooltip}
                                        asElement={ButtonElement.BUTTON}
                                        buttonType={ButtonType.SECONDARY}
                                        icon={a.icon}
                                    >
                                        {a.label && (
                                            <label className={styles.label}>{a.label}</label>
                                        )}
                                    </Button>
                                ) : (
                                    <Button
                                        className={classNames(styles.actionBarItem, a.className)}
                                        to={a.href}
                                        asElement={ButtonElement.LINK}
                                        buttonType={ButtonType.SECONDARY}
                                        target="_blank"
                                        rel="noreferrer"
                                        icon={a.icon}
                                    >
                                        {a.label && (
                                            <label className={styles.label}>{a.label}</label>
                                        )}
                                    </Button>
                                )}
                            </div>
                        </Tooltip>
                    ))}
                    {this.state.showRefresh && (
                        <Tooltip title={refreshLabel} placement="bottom">
                            <span>
                                <Button
                                    onClick={this.onRefresh}
                                    aria-label={refreshLabel}
                                    className={styles.actionBarItem}
                                    asElement={ButtonElement.BUTTON}
                                    buttonType={ButtonType.SECONDARY}
                                    icon={IconType.REDO}
                                />
                            </span>
                        </Tooltip>
                    )}
                </div>
            </div>
            <h2 className={styles.title}>
                <OverflowTooltip>{this.state.title}</OverflowTooltip>
            </h2>
            {this.state.description}
        </div>
    )
}
