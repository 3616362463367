import React, { useMemo } from "react"
import { useState } from "react"

import {
    Button,
    ButtonElement,
    ButtonType,
    IconType,
} from "../../../../../../../components/button/Button.component"
import { PageBreak } from "../../../../../../../pre-v3/components/page-break/PageBreak.component"
import { useServiceModal } from "../../../../../../../pre-v3/services/Modal.service"
import { useServiceLocalization } from "../../../../../../../pre-v3/services/localization/Localization.service"
import { ROUTE, formatRoutePath } from "../../../../../../../routes"
import { AppText } from "../../../../../../components/app-text/AppText.component"
import { ErrorBanner, InfoBanner } from "../../../../../../components/banner/Banner.component"
import { Column, Grid, RowDragEndProps } from "../../../../../../components/grid/Grid.component"
import { Loader } from "../../../../../../components/loader/Loader.component"
import {
    toTrustProfilesArray,
    TrustProfile,
    useGetTrustProfiles,
    useReorderTrustProfiles,
} from "../../../../../../services/TrustProfile.service"
import { Platform } from "../../../../../../services/shared/Platform"
import { RowTitle } from "../../../../../../components/grid/RowTitle.component"
import AgGridUtil from "../../../../../../../pre-v3/utils/AgGrid.util"
import { encodeID } from "../../../../../../../pre-v3/utils/Url.util"
import {
    AvailablePlatforms,
    availablePlatformsClassName,
} from "../../../../../../components/grid/AvailablePlatforms.component"
import { Link } from "../../../../../../components/link/Link.component"
import { DateUtil } from "../../../../../../../pre-v3/utils/Date.util"
import { getListOfPlatforms } from "../../../../../../services/shared/Platform"
import { CreateTrustProfile } from "./CreateTrustProfileModal.component"
import styles from "./TrustProfileList.module.scss"
import { PageHeading } from "../../../../../../../components/page-heading/PageHeading.component"
import trustStyles from "../../../../../../../pages/trust/Trust.module.scss"
import { Tooltip } from "../../../../../../components/tooltip/Tooltip.component"

interface Props {
    canCreateTrustProfile: boolean
    canReorderTrustProfiles: boolean
}

export function TrustProfileList(props: Props): JSX.Element {
    const localization = useServiceLocalization()
    const modalService = useServiceModal()

    const [allowDrag, setAllowDrag] = useState(false)
    const [showReorderBanner, setShowReorderBanner] = useState(false)
    const [reorderedTrustProfiles, setReorderedTrustProfiles] = useState<TrustProfile[]>([])

    const {
        data: trustProfiles,
        refetch: fetchTrustProfiles,
        isFetching: isTrustProfilesLoading,
        isError: isTrustProfilesError,
        error: trustProfilesError,
    } = useGetTrustProfiles()

    const {
        isLoading: isReOrderLoading,
        error: reOrderError,
        mutateAsync: reorderTrustProfiles,
        reset: resetReorderTrustProfile,
    } = useReorderTrustProfiles()

    const trustProfileArray = trustProfiles ? toTrustProfilesArray(trustProfiles) : []
    const trustProfilesToDisplay = allowDrag ? reorderedTrustProfiles : trustProfileArray
    const noTrustProfileToSort =
        !(isTrustProfilesLoading || isTrustProfilesError) &&
        trustProfiles?.sortedTrustProfiles.length === 0

    const columns = useColumns({
        defaultTrustProfile: trustProfiles?.defaultTrustProfile,
        allowDrag,
    })

    function onRowDragEnd(event: RowDragEndProps<TrustProfile>) {
        const isNotDraggedOverDefaultProfile =
            trustProfiles && event.overNode.data.id !== trustProfiles.defaultTrustProfile.id

        if (isNotDraggedOverDefaultProfile) {
            const reorderedTrustProfile = reorderTrustProfile({
                trustProfiles: reorderedTrustProfiles,
                fromIndex: event.node.rowIndex,
                toIndex: event.overNode.rowIndex,
            })

            if (reorderedTrustProfile) {
                setReorderedTrustProfiles(reorderedTrustProfile)
            }
        }
    }

    function cancelReOrder() {
        setAllowDrag(false)
        resetReorderTrustProfile()
        setReorderedTrustProfiles([])
    }

    async function saveReOrder() {
        await reorderTrustProfiles(reorderedTrustProfiles)
        setAllowDrag(false)
        setReorderedTrustProfiles([])
        setShowReorderBanner(true)
        setTimeout(() => setShowReorderBanner(false), 1000 * 10)
    }

    function createProfile() {
        modalService.open(localization.getString("createTrustProfile"), {
            component: CreateTrustProfile,
            props: {
                initialValue: trustProfiles?.defaultTrustProfile.id,
                trustProfiles: trustProfileArray,
            },
            maxWidth: "lg",
        })
    }

    function onRefresh() {
        fetchTrustProfiles()
    }

    const onReorder: React.MouseEventHandler = (event) => {
        event.preventDefault()
        setReorderedTrustProfiles(trustProfileArray)
        setAllowDrag(!allowDrag)
    }

    return (
        <section aria-labelledby={Id.HEADING} className={trustStyles.section}>
            <header className={trustStyles.header}>
                <PageHeading id={Id.HEADING}>{localization.getString("profiles")}</PageHeading>
                <div className={styles.actionButtons}>
                    {props.canReorderTrustProfiles && (
                        <Tooltip title={localization.getString("reorder")}>
                            <Button
                                buttonType={ButtonType.SECONDARY}
                                asElement={ButtonElement.BUTTON}
                                icon={IconType.SORT}
                                aria-label={localization.getString("reorder")}
                                onClick={onReorder}
                                disabled={
                                    isTrustProfilesLoading ||
                                    isReOrderLoading ||
                                    isTrustProfilesError ||
                                    noTrustProfileToSort
                                }
                            />
                        </Tooltip>
                    )}
                    <Tooltip title={localization.getString("refresh")}>
                        <Button
                            icon={IconType.REDO}
                            onClick={onRefresh}
                            asElement={ButtonElement.BUTTON}
                            buttonType={ButtonType.SECONDARY}
                            aria-label={localization.getString("refresh")}
                        />
                    </Tooltip>
                </div>
            </header>
            <Loader isLoading={isTrustProfilesLoading || isReOrderLoading} center medium>
                {typeof trustProfilesError === "string" && (
                    <ErrorBanner>{trustProfilesError}</ErrorBanner>
                )}
                {showReorderBanner && (
                    <InfoBanner>
                        {localization.getString(
                            "reorderingOfTrustProfilesCanTakeUpTo10MinutesToShowOnDevices"
                        )}
                    </InfoBanner>
                )}
                {!isTrustProfilesError && (
                    <>
                        {allowDrag && (
                            <>
                                <AppText
                                    className={styles.description}
                                    ls={"dragProfilesToDesiredPriorityOrder"}
                                />
                                <AppText
                                    className={styles.helpText}
                                    ls={"aDeviceCanOnlyBeAssignedOneTrustProfile"}
                                />
                            </>
                        )}
                        {!allowDrag && (
                            <section className={styles.header}>
                                <div className={styles.headerLeftContainer}>
                                    <span className={styles.lastUpdatedTitle}>
                                        {localization.getString("deviceCountLastUpdated")}
                                    </span>
                                    <span className={styles.lastUpdatedValue}>
                                        {DateUtil.format(
                                            trustProfiles?.deviceCountLastUpdated ?? 0
                                        )}
                                    </span>
                                </div>
                                {props.canCreateTrustProfile && (
                                    <Button
                                        onClick={createProfile}
                                        buttonType={ButtonType.PRIMARY}
                                        asElement={ButtonElement.BUTTON}
                                        icon={IconType.PLUS}
                                    >
                                        {localization.getString("createProfile")}
                                    </Button>
                                )}
                            </section>
                        )}
                        <div className={styles.grid}>
                            <Grid
                                data={trustProfilesToDisplay}
                                columns={columns}
                                onRowDragEnd={onRowDragEnd}
                            />
                        </div>
                    </>
                )}
            </Loader>
            {allowDrag && (
                <>
                    {typeof reOrderError === "string" && (
                        <ErrorBanner className={styles.errorContainer}>{reOrderError}</ErrorBanner>
                    )}
                    <PageBreak />
                    <div className={styles.buttonGroup}>
                        <Button
                            asElement={ButtonElement.BUTTON}
                            buttonType={ButtonType.SECONDARY}
                            onClick={cancelReOrder}
                        >
                            {localization.getString("cancel")}
                        </Button>
                        <Button
                            onClick={saveReOrder}
                            loading={isReOrderLoading}
                            asElement={ButtonElement.BUTTON}
                            buttonType={ButtonType.PRIMARY}
                        >
                            {localization.getString("save")}
                        </Button>
                    </div>
                </>
            )}
        </section>
    )
}

interface ColumnProps {
    defaultTrustProfile?: TrustProfile
    allowDrag: boolean
}

function useColumns(props: ColumnProps): Column<TrustProfile>[] {
    const { defaultTrustProfile, allowDrag } = props
    const localization = useServiceLocalization()

    return useMemo(() => {
        return [
            {
                id: "priority",
                name: localization.getString("priority"),
                cellRenderer: (trustProfile, index) =>
                    renderPriority(trustProfile, index, defaultTrustProfile),
                getTooltipValue: (trustProfile, index) =>
                    renderPriority(trustProfile, index, defaultTrustProfile),
                isRowDrag: (data) => {
                    return allowDrag && data.id !== defaultTrustProfile?.id
                },
            },
            {
                id: "name",
                name: localization.getString("name"),
                cellRenderer: (trustProfile) => {
                    return (
                        <RowTitle
                            title={trustProfile.name}
                            description={trustProfile.description}
                            route={getRoute(trustProfile) ?? undefined}
                        />
                    )
                },
                getTooltipValue: "name",
            },
            {
                id: "appliedPlatform",
                name: localization.getString("appliedPlatform"),
                cellClassName: availablePlatformsClassName,
                getTooltipValue: (trustProfile) =>
                    getListOfPlatforms(trustProfile.appliedPlatform, localization),
                cellRenderer: (trustProfile) => {
                    return (
                        <AvailablePlatforms
                            isMacOsAvailable={trustProfile.appliedPlatform[Platform.MACOS]}
                            isWindowsAvailable={trustProfile.appliedPlatform[Platform.WINDOWS]}
                            isLinuxAvailable={trustProfile.appliedPlatform[Platform.LINUX]}
                            isIosAvailable={trustProfile.appliedPlatform[Platform.IOS]}
                            isAndroidAvailable={trustProfile.appliedPlatform[Platform.ANDROID]}
                            isChromeBrowserAvailable={trustProfile.appliedPlatform[Platform.CHROME]}
                        />
                    )
                },
            },
            {
                id: "devices",
                name: localization.getString("devices"),
                getTooltipValue: (trustProfile) =>
                    localization.getPluralString("countDevices", trustProfile.deviceCount),
                cellRenderer: (trustProfile) => {
                    const searchParams = AgGridUtil.serializeFilterModel({
                        trustProfile: { filter: encodeID(trustProfile.id) },
                    })

                    return (
                        <Link
                            to={`${ROUTE.DEVICES}?${searchParams.toString()}`}
                            aria-label={localization.getPluralString(
                                "countDevices",
                                trustProfile.deviceCount
                            )}
                        >
                            {trustProfile.deviceCount}
                        </Link>
                    )
                },
            },
        ]
    }, [allowDrag, defaultTrustProfile, localization])
}

interface reorderTrustProfileProps {
    fromIndex: number
    toIndex: number
    trustProfiles: TrustProfile[]
}

function reorderTrustProfile(props: reorderTrustProfileProps): TrustProfile[] | undefined {
    const { fromIndex, toIndex, trustProfiles } = props

    const draggedItem = trustProfiles.find((_, rowIndex) => rowIndex === fromIndex)
    const draggedItemRemoved = trustProfiles.filter((_, rowIndex) => {
        return rowIndex !== fromIndex
    })

    if (draggedItem) {
        const beforeRow = draggedItemRemoved.slice(0, toIndex)
        const afterRow = draggedItemRemoved.slice(toIndex, draggedItemRemoved.length)

        const sortedItems = [...beforeRow, draggedItem, ...afterRow].map((trustProfile, index) => {
            return { ...trustProfile, priority: index + 1 }
        })

        return sortedItems
    }
}

function renderPriority(
    trustProfile: TrustProfile,
    index: number,
    defaultTrustProfile?: TrustProfile
): string | number {
    const isDefaultTrustProfile = trustProfile.id === defaultTrustProfile?.id
    return isDefaultTrustProfile ? "-" : index + 1
}

function getRoute(trustProfile: TrustProfile): ROUTE {
    return formatRoutePath(ROUTE.PROFILES_DETAILS, {
        id: encodeID(trustProfile.id),
    })
}

enum Id {
    HEADING = "heading",
}
