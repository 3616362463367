import {
    IconDefinition,
    faCheckCircle,
    faMinusCircle,
    faTimesCircle,
} from "@fortawesome/pro-solid-svg-icons"
import classNames from "classnames/bind"
import React from "react"

import { LanguageKey } from "../../../pre-v3/services/localization/languages/en-US.language"
import { useServiceLocalization } from "../../../pre-v3/services/localization/Localization.service"
import { DateUtil } from "../../../pre-v3/utils/Date.util"
import { encodeID } from "../../../pre-v3/utils/Url.util"
import { ROUTE, formatRoutePath } from "../../../routes"
import { Column, Grid } from "../../../v3/components/grid/Grid.component"
import { RowTitle } from "../../../v3/components/grid/RowTitle.component"
import { Icon } from "../../../v3/components/Icon/Icon.component"
import { PolicyStatus, ServiceTunnel } from "../../../v3/services/ServiceTunnelV2.service"
import styles from "./ServiceTunnelGrid.module.scss"
import { InformationLabel } from "../../../components/label/Label.component"

interface Props {
    serviceTunnels: ServiceTunnel[]
}

export function ServiceTunnelGrid(props: Props): JSX.Element {
    const columns = useColumns()

    return <Grid data={props.serviceTunnels} columns={columns} />
}

function useColumns(): Column<ServiceTunnel>[] {
    const localization = useServiceLocalization()

    return React.useMemo((): Column<ServiceTunnel>[] => {
        const status: Column<ServiceTunnel> = {
            id: "status",
            name: localization.getString("status"),
            cellRenderer: (serviceTunnel) => (
                <StatusCellRenderer
                    status={getStatus(serviceTunnel)}
                    isStartOnLogin={serviceTunnel.startsOnLogin}
                />
            ),
            getTooltipValue: (serviceTunnel) =>
                localization.getString(statusLabelDict[getStatus(serviceTunnel)]),
            cellClassName: styles.statusCell,
            width: 150,
        }

        const nameColumn: Column<ServiceTunnel> = {
            id: "name",
            name: localization.getString("name"),
            cellRenderer: renderNameCell,
            getTooltipValue: getName,
            width: 120,
        }

        const policyAttachedColumn: Column<ServiceTunnel> = {
            id: "policyAttached",
            name: localization.getString("policyAttached"),
            cellRenderer: getPolicyName,
            getTooltipValue: getPolicyName,
            width: 150,
        }

        const lastUpdatedAtColumn: Column<ServiceTunnel> = {
            id: "lastUpdatedAt",
            name: localization.getString("lastUpdated"),
            cellRenderer: getLastUpdatedAt,
            getTooltipValue: getLastUpdatedAt,
            width: 100,
        }

        const activeConnectionsColumn: Column<ServiceTunnel> = {
            id: "activeConnections",
            name: localization.getString("activeConnections"),
            cellRenderer: renderActiveConnectionsCell,
            getTooltipValue: "activeConnectionsCount",
            width: 70,
        }

        return [
            status,
            nameColumn,
            policyAttachedColumn,
            lastUpdatedAtColumn,
            activeConnectionsColumn,
        ]
    }, [])
}

function getStatus(serviceTunnel: ServiceTunnel): Status {
    switch (serviceTunnel.policy?.status) {
        case undefined:
            return Status.NO_POLICY
        case PolicyStatus.ENFORCING:
            return Status.POLICY_ENFORCING
        case PolicyStatus.PERMISSIVE:
            return Status.POLICY_PERMISSIVE
    }
}

enum Status {
    POLICY_ENFORCING = "POLICY_ENFORCING",
    POLICY_PERMISSIVE = "POLICY_PERMISSIVE",
    NO_POLICY = "NO_POLICY",
}

interface StatusCellRendererProps {
    status: Status
    isStartOnLogin: boolean
}

function StatusCellRenderer({ status, isStartOnLogin }: StatusCellRendererProps): JSX.Element {
    const localization = useServiceLocalization()

    return (
        <React.Fragment>
            <Icon
                icon={statusIconDict[status]}
                className={classNames(styles.statusIcon, {
                    [styles.statusIconEnforcing]: status === Status.POLICY_ENFORCING,
                    [styles.statusIconPermissive]: status === Status.POLICY_PERMISSIVE,
                    [styles.statusIconNoPolicy]: status === Status.NO_POLICY,
                })}
            />
            <div className={styles.statusLabel}>
                {localization.getString(statusLabelDict[status])}
            </div>
            {isStartOnLogin && (
                <InformationLabel>{localization.getString("connectOnLogin")}</InformationLabel>
            )}
        </React.Fragment>
    )
}

const statusIconDict: Record<Status, IconDefinition> = {
    [Status.POLICY_ENFORCING]: faCheckCircle,
    [Status.POLICY_PERMISSIVE]: faMinusCircle,
    [Status.NO_POLICY]: faTimesCircle,
}

const statusLabelDict: Record<Status, LanguageKey> = {
    [Status.POLICY_ENFORCING]: "policyEnforcing",
    [Status.POLICY_PERMISSIVE]: "policyPermissive",
    [Status.NO_POLICY]: "noPolicy",
}

function renderNameCell(serviceTunnel: ServiceTunnel): JSX.Element {
    return (
        <RowTitle
            title={serviceTunnel.name}
            route={formatRoutePath(ROUTE.SERVICE_TUNNELS_DETAILS, {
                id: encodeID(serviceTunnel.id),
            })}
        />
    )
}

function getName(serviceTunnel: ServiceTunnel): string {
    return serviceTunnel.name
}

function getPolicyName(serviceTunnel: ServiceTunnel): string {
    return serviceTunnel.policy?.name ?? "-"
}

function getLastUpdatedAt(serviceTunnel: ServiceTunnel): string {
    return DateUtil.format(serviceTunnel.lastUpdatedAt)
}

function renderActiveConnectionsCell(serviceTunnel: ServiceTunnel): JSX.Element {
    return (
        <RowTitle
            title={serviceTunnel.activeConnectionsCount.toString()}
            route={formatRoutePath(
                ROUTE.DEVICES,
                {},
                { fm_activeServiceTunnel: encodeID(serviceTunnel.id) }
            )}
        />
    )
}
