import React from "react"
import { useHistory } from "react-router-dom"

import { formatRoutePath, ROUTE } from "../../../../routes"
import { PieChart } from "../../../components/charts/PieChart"
import { useServiceLocalization } from "../../../services/localization/Localization.service"
import { PolicyEnforcement } from "../../../services/Service.service"
import { PoliciesStatsDisplayData, useServiceStats } from "../../../services/Stats.service"
import { chartColorMap } from "../utils"
import { Widget } from "../Widget"
import { PoliciesCount } from "./PoliciesCount"

export const Policies = React.memo(function () {
    const localization = useServiceLocalization()
    const stats = useServiceStats()
    const history = useHistory()

    const [data, setData] = React.useState<PoliciesStatsDisplayData | null>(null)
    const [loading, setLoading] = React.useState(true)

    React.useEffect(() => {
        stats
            .getPoliciesStatsDisplayData()
            .then(setData)
            .finally(() => {
                setLoading(false)
            })
    }, [stats])

    const policyStatusData = React.useMemo(() => {
        // we're going to render policy status (active vs inactive)
        // but the API categorizes it in a 4 different groups:
        // inactive, enforcing, permissive, and mixed
        // active = enforcing + permissive + mixed
        const inactive = data?.type[2].value
        const active =
            (data?.type[0].value || 0) + (data?.type[1].value || 0) + (data?.type[3].value || 0)

        return [
            {
                displayName: localization.getString("inactive"),
                value: inactive || 0,
                color: chartColorMap[PolicyEnforcement.INACTIVE],
            },
            {
                displayName: localization.getString("active"),
                value: active || 0,
                color: chartColorMap[PolicyEnforcement.ENFORCING],
            },
        ].filter(({ value }) => value)
    }, [data, localization])

    return (
        <>
            <PoliciesCount count={data?.total} />
            <Widget
                gridArea="enforcement"
                loading={loading}
                hasData={Boolean(data && data.type.length > 0)}
            >
                <PieChart
                    title={localization.getString("allPolicies")}
                    subtitle={localization.getString("byStatus")}
                    data={policyStatusData}
                    options={{
                        cursor: "pointer",
                        point: {
                            events: {
                                click() {
                                    history.push(
                                        formatRoutePath(
                                            ROUTE.ACCESS_POLICIES,
                                            {},
                                            {
                                                fm_status: this.name,
                                            }
                                        )
                                    )
                                },
                            },
                        },
                    }}
                />
            </Widget>
        </>
    )
})
