import React from "react"

import { useServiceLocalization } from "../../../../../../../../../pre-v3/services/localization/Localization.service"
import { useServiceModal } from "../../../../../../../../../pre-v3/services/Modal.service"
import { File } from "../../../../../../../../services/TrustProfile.service"
import {
    PillContainer,
    PillProps,
} from "../../../../../../../../components/pill-container/PillContainer.component"
import { FileModal, AddFileModalActions } from "./FileModal.component"

interface Props {
    files: File[]
    disabled?: boolean
    onMoveFileToUnused(file: File): void
    onEditFile(file: File): void
}

export function FilePills(props: Props): JSX.Element {
    const localization = useServiceLocalization()
    const modalService = useServiceModal()

    const mapFilePill = React.useCallback(
        (file: File): PillProps => {
            const onEditFile = () => {
                modalService
                    .open(
                        localization.getString(
                            "editFactorSomething",
                            localization.getString("fileCheck")
                        ),
                        {
                            component: FileModal,
                            props: { file },
                            maxWidth: "lg",
                        },
                        AddFileModalActions
                    )
                    .onClose(props.onEditFile)
            }

            const onDeleteFile = () => props.onMoveFileToUnused(file)

            return {
                id: file.id,
                label: file.name,
                disabled: props.disabled,
                onClick: onEditFile,
                onDelete: onDeleteFile,
                buttonAriaLabel: props.disabled
                    ? undefined
                    : localization.getString("editSomething", file.name),
                iconAriaLabel: props.disabled
                    ? undefined
                    : localization.getString("removeSomething", file.name),
            }
        },
        [props.disabled, props.onMoveFileToUnused]
    )

    return <PillContainer pills={props.files.map(mapFilePill)} />
}
