import React from "react"

import styles from "./CountWidget.module.scss"
import { Widget } from "./Widget.component"

export interface Props {
    title: string
    count?: number
}

export function CountWidget(props: Props): JSX.Element {
    return (
        <Widget>
            <h2 className={styles.title}>{props.title}</h2>
            <div className={styles.count}>
                {typeof props.count === "number" ? props.count : "-"}
            </div>
        </Widget>
    )
}
