import { faExclamation } from "@fortawesome/pro-solid-svg-icons"
import * as React from "react"
import styles from "./LargeMessage.module.scss"
import classNames from "classnames"
import { IconDefinition } from "@fortawesome/fontawesome-common-types"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"

interface Props {
    icon?: IconDefinition
    className?: string
    children?: React.ReactNode
}

export function LargeMessage(props: Props): JSX.Element {
    const { icon = faExclamation, className, children } = props

    return (
        <div className={classNames(styles.largeMessageContainer, className)}>
            <h2 className={styles.largeMessageIcon}>
                <FontAwesomeIcon icon={icon} size="2x" />
            </h2>
            <div className={styles.largeMessageText}>{children}</div>
        </div>
    )
}
