import {
    GridApi,
    GridReadyEvent,
    RowSelectedEvent,
    RowDoubleClickedEvent,
    ColDef,
} from "ag-grid-community"
import React, { ReactNode } from "react"
import { RouteComponentProps } from "react-router"

import { ROUTE, formatRoutePath } from "../../../../routes"
import { LocalizationService } from "../../../services/localization/Localization.service"
import { InfraService, ClusterInfra } from "../../../services/Infra.service"
import { Bind } from "../../../decorators/Bind.decorator"
import AgGridUtil from "../../../utils/AgGrid.util"
import ClustersListTemplate from "./ClustersList.template"

export class ClustersList extends React.Component<RouteComponentProps, ClustersListState> {
    public state: ClustersListState = {}

    public render(): ReactNode {
        return ClustersListTemplate.call(this)
    }

    private localizationService: LocalizationService = new LocalizationService()
    private infraService: InfraService = new InfraService()

    private gridApi: GridApi
    private columns: ColDef[] = [
        {
            headerName: this.localizationService.getString("status"),
            field: "status",
            width: 65,
            cellRenderer: "clusterStatusCellRenderer",
        },
        {
            headerName: this.localizationService.getString("name"),
            field: "name",
            tooltipValueGetter: AgGridUtil.linkTooltipValueGetter,
            width: 100,
            cellRenderer: "clustersCellRenderer",
            comparator: AgGridUtil.alphaBetComparator,
        },
        {
            headerName: this.localizationService.getString("group"),
            field: "group",
            width: 65,
        },
        {
            headerName: this.localizationService.getString("accessTiers"),
            field: "accessTiers",
            width: 50,
            valueFormatter: AgGridUtil.listLengthFormatter,
            tooltipValueGetter: (params) => {
                return params.value.length
            },
        },
        {
            headerName: this.localizationService.getString("shieldAddress"),
            field: "address",
            width: 80,
            valueFormatter: AgGridUtil.nullableStringFormatter,
        },
    ]

    @Bind
    private onGridReady(event: GridReadyEvent): void {
        this.gridApi = event.api
        this.fetchData()
    }

    @Bind
    private onFilter(value: string): void {
        if (this.gridApi) {
            this.gridApi.setQuickFilter(value)
        }
    }

    @Bind
    private onRowSelected(event: RowSelectedEvent): void {
        if (event && event.data) {
            this.setState({
                selectedRow: event.data,
            })
        }
    }

    @Bind
    private onRowDoubleClicked(event: RowDoubleClickedEvent): void {
        if (event && event.data) {
            this.viewCluster(event.data)
        }
    }

    private viewCluster(cluster: ClusterInfra): void {
        this.props.history.push(
            formatRoutePath(ROUTE.CLUSTERS_DETAILS, { clusterName: cluster.name })
        )
    }

    @Bind
    private fetchData(): void {
        if (this.gridApi) {
            this.gridApi.showLoadingOverlay()
            this.infraService.getClusters().then((clusters: ClusterInfra[]) => {
                this.gridApi.hideOverlay()
                this.gridApi.setRowData(clusters)
            })
        }
    }
}

interface ClustersListState {
    selectedRow?: ClusterInfra
}
