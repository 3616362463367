import React from "react"
import { Route, Switch } from "react-router-dom"
import { ROUTE } from "../../routes"
import useTitle from "../../hooks/useTitle.hook"
import { TrustFactorList } from "../../v3/views/trust-factors/trustFactorList/TrustFactorList.view"
import {
    TrustFactor,
    isFileCheckTrustFactor,
    isPlistTrustFactor,
    isRegistryCheckTrustFactor,
    useGetTrustFactors,
} from "../../v3/services/TrustFactor.service"
import { FileCheck } from "../../v3/views/trust-factors/trustFactorList/fileCheck/FileCheck.view"
import { Plist } from "../../v3/views/trust-factors/trustFactorList/plist/Plist.view"
import { RegistryCheck } from "../../v3/views/trust-factors/trustFactorList/registryCheck/RegistryCheck.view"
import { useFeatureFlags } from "../../hooks/useFeatureFlags.hook"

export function Factors() {
    useTitle(["factors", "trust", "adminConsole"])
    const queryResult = useGetTrustFactors()
    const { data: featureFlags } = useFeatureFlags()
    const fileCheckTrustFactor = React.useMemo(
        () => queryResult.data?.find(isFileCheckTrustFactor),
        [queryResult.data]
    )

    const plistTrustFactor = React.useMemo(
        () => queryResult.data?.find(isPlistTrustFactor),
        [queryResult.data]
    )

    const registryCheckTrustFactor = React.useMemo(
        () => queryResult.data?.find(isRegistryCheckTrustFactor),
        [queryResult.data]
    )

    const onRefetchData = async (): Promise<TrustFactor[] | undefined> => {
        const result = await queryResult.refetch()
        return result.data
    }

    return (
        <Switch>
            <Route path={ROUTE.FACTORS_FILE_CHECK}>
                <FileCheck
                    fileCheckTrustFactor={fileCheckTrustFactor}
                    isLoading={queryResult.isLoading}
                    onRefetchData={onRefetchData}
                    canAccessFileCheckFactor={featureFlags?.adminConsole.canAccessFileCheckFactor}
                />
            </Route>
            <Route path={ROUTE.FACTORS_PLIST}>
                <Plist
                    plistTrustFactor={plistTrustFactor}
                    isLoading={queryResult.isLoading}
                    onRefetchData={onRefetchData}
                    canAccessPListCheckFactor={featureFlags?.adminConsole.canAccessPListCheckFactor}
                />
            </Route>
            <Route path={ROUTE.FACTORS_REGISTRY_CHECK}>
                <RegistryCheck
                    registryCheckTrustFactor={registryCheckTrustFactor}
                    isLoading={queryResult.isLoading}
                    onRefetchData={onRefetchData}
                    canAccessRegistryCheckFactor={
                        featureFlags?.adminConsole.canAccessRegistryCheckFactor
                    }
                />
            </Route>
            <Route>
                <TrustFactorList trustFactors={queryResult.data} onRefetchData={onRefetchData} />
            </Route>
        </Switch>
    )
}
