import { PopoverOrigin } from "@mui/material/Popover"
import React, { ReactNode } from "react"

import { Bind } from "../../decorators/Bind.decorator"
import { AppNavLink } from "../../services/shared/AppNavLink"
import NavMenuButtonTemplate from "./NavMenuButton.template"

export class NavMenuButton extends React.Component<
    React.PropsWithChildren<NavMenuButtonProps>,
    NavMenuButtonState
> {
    public static getDerivedStateFromProps(props: NavMenuButtonProps): NavMenuButtonState {
        return props.menuDirection === NavMenuButtonDirection.LEFT
            ? {
                  anchorOrigin: {
                      vertical: "bottom",
                      horizontal: "right",
                  },
                  transformOrigin: {
                      vertical: "top",
                      horizontal: "right",
                  },
              }
            : {
                  anchorOrigin: {
                      vertical: "bottom",
                      horizontal: -10,
                  },
                  transformOrigin: {
                      vertical: "top",
                      horizontal: "left",
                  },
              }
    }

    public state: NavMenuButtonState = {
        showMenu: false,
        anchorOrigin: {
            vertical: "bottom",
            horizontal: -10,
        },
        transformOrigin: {
            vertical: "top",
            horizontal: "left",
        },
    }

    public render(): ReactNode {
        return NavMenuButtonTemplate.call(this)
    }

    @Bind
    private openMenu(e: Event): void {
        e.preventDefault()
        this.setState({ showMenu: true })
    }

    @Bind
    private closeMenu(): void {
        this.setState({ showMenu: false })
    }

    @Bind
    private onLinkClick(link: AppNavLink): void {
        this.setState({ showMenu: false })
        if (link.onClick) {
            link.onClick()
        }
    }
}

interface NavMenuButtonProps {
    links?: AppNavLink[]
    caret?: boolean
    highlight: boolean
    menuDirection?: NavMenuButtonDirection
    footer: ReactNode
    className?: string
}

interface NavMenuButtonState {
    showMenu?: boolean
    anchorOrigin: PopoverOrigin
    transformOrigin: PopoverOrigin
}

export enum NavMenuButtonDirection {
    LEFT = "LEFT",
    RIGHT = "RIGHT",
}
