import { Tooltip } from "@mui/material"
import React from "react"

import {
    Button,
    ButtonElement,
    ButtonType,
    IconType,
} from "../../../../components/button/Button.component"
import { PageHeading } from "../../../../components/page-heading/PageHeading.component"
import { SearchInput } from "../../../../v3/components/search-input/SearchInput.component"
import { Grid } from "../../../components/grid/Grid.component"
import { ClusterStatusCellRenderer } from "../cell-renderer/status/ClusterStatusCellRenderer.component"
import { ClustersCellRenderer } from "../cell-renderer/ClustersCellRenderer.component"
import styles from "./ClustersList.module.scss"

export default function () {
    return (
        <section aria-labelledby="clusters-heading" className={styles.section}>
            <header className={styles.header}>
                <PageHeading id="clusters-heading">
                    {this.localizationService.getString("clusters")}
                </PageHeading>
                <Tooltip title={this.localizationService.getString("refresh")}>
                    <Button
                        icon={IconType.REDO}
                        onClick={this.fetchData}
                        asElement={ButtonElement.BUTTON}
                        buttonType={ButtonType.SECONDARY}
                        aria-label={this.localizationService.getString("refresh")}
                    />
                </Tooltip>
            </header>
            <div className={styles.toolbar}>
                <SearchInput
                    onChangeValue={this.onFilter}
                    placeholder={this.localizationService.getString("search")}
                />
                <Tooltip
                    title={this.localizationService.getString("addClusterExplanation")}
                    placement="left"
                >
                    <span>
                        <Button
                            asElement={ButtonElement.BUTTON}
                            buttonType={ButtonType.PRIMARY}
                            icon={IconType.PLUS}
                            disabled
                        >
                            {this.localizationService.getString("addCluster")}
                        </Button>
                    </span>
                </Tooltip>
            </div>
            <Grid
                onGridReady={this.onGridReady}
                columnDefs={this.columns}
                pagination
                onRowDoubleClicked={this.onRowDoubleClicked}
                onRowSelected={this.onRowSelected}
                context={{ history: this.props.history }}
                components={{
                    clustersCellRenderer: ClustersCellRenderer,
                    clusterStatusCellRenderer: ClusterStatusCellRenderer,
                }}
            />
        </section>
    )
}
