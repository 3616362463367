import * as React from "react"
import { ModalRef, useServiceLocalization } from "../../../services"
import { Button, ButtonElement, ButtonType } from "../../../../components/button/Button.component"

export function DoneActions({ modalRef }: { modalRef: ModalRef }) {
    const ls = useServiceLocalization()

    return (
        <Button
            onClick={modalRef.close}
            buttonType={ButtonType.PRIMARY}
            asElement={ButtonElement.BUTTON}
        >
            {ls.getString("done")}
        </Button>
    )
}
