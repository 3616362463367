import classNames from "classnames/bind"
import React from "react"

import styles from "./OverviewDetails.module.scss"
import {
    KeyValuePair,
    KeyValuePairList,
} from "../../../components/key-value-pair/KeyValuePair.component"
import { KeyValuePairItems } from "./OverviewTypes"

export interface Props {
    listItems?: KeyValuePairItems[]
    listItemsCol2?: KeyValuePairItems[]
    className?: string
}

export function OverviewDetails(props: Props) {
    const { listItems, listItemsCol2, className } = props

    return (
        <div className={classNames(styles.keyValuePairCols, className)}>
            {listItems && (
                <KeyValuePairList>
                    {listItems.map((item) => (
                        <KeyValuePair {...item} key={item.label} />
                    ))}
                </KeyValuePairList>
            )}
            {listItemsCol2 && (
                <KeyValuePairList>
                    {listItemsCol2.map((item) => (
                        <KeyValuePair {...item} key={item.label} />
                    ))}
                </KeyValuePairList>
            )}
        </div>
    )
}
