import React, { ReactNode } from "react"
import SelectTemplate from "./Select.template"
import { Bind } from "../../decorators/Bind.decorator"
import { LocalizationService } from "../../services/localization/Localization.service"
import { SelectItem, SelectValue, SelectValueUtil } from "../../utils/SelectValue.util"

export class Select extends React.Component<SelectProps, SelectState> {
    public state: SelectState = {
        showDropdown: false,
        anchorWidth: "16px",
        available: [],
        serializedValue: "",
    }

    public componentDidMount(): void {
        this.init()
        this.setSerializedValue()
    }

    public componentDidUpdate(prevProps: SelectProps) {
        if (prevProps.options !== this.props.options || prevProps.value !== this.props.value) {
            this.init()
            this.setSerializedValue()
        }
    }

    public render(): ReactNode {
        return SelectTemplate.call(this)
    }

    private ls: LocalizationService = new LocalizationService()

    private anchor: HTMLElement

    private init(): void {
        if (this.props.multiple) {
            if (Array.isArray(this.props.value) || this.props.value === undefined) {
                const values: string[] = this.props.value || []
                const available: SelectValue[] = []
                const selected: SelectValue[] = []
                if (this.props.options && this.props.options.length > 0) {
                    this.props.options.forEach((o: SelectValue) => {
                        if (SelectValueUtil.stringArrayIncludes(values, o)) {
                            selected.push(o)
                        } else {
                            available.push(o)
                        }
                    })
                }
                this.setState({ available: available, selected: selected })
            } else {
                throw new Error("Multi-select component value must be an array")
            }
        } else {
            if (Array.isArray(this.props.value)) {
                throw new Error(
                    "The select component value must not be an array unless it is a multi-select"
                )
            } else if (this.props.value !== undefined) {
                const idx: number = SelectValueUtil.findIndexOfString(
                    this.props.options,
                    this.props.value
                )
                if (idx >= 0) {
                    this.setState({ selected: this.props.options[idx] })
                } else {
                    this.setState({ selected: undefined })
                }
            }
        }
    }

    private validate(): void {
        if (this.props.options && this.props.options.length > 0) {
            const first: SelectValue = this.props.options[0]
            let invalid: SelectValue | undefined
            if (SelectValueUtil.isSelectItem(first)) {
                invalid = this.props.options.find((o: SelectValue) => !(o as SelectItem).value)
            } else {
                invalid = this.props.options.find((o: SelectValue) => !o)
            }
            if (invalid) {
                throw new Error("Select options must not have a falsy value")
            }
        }
    }

    @Bind
    private onToggleDropdown(): void {
        if (this.props.disabled) {
            return
        }

        if (this.anchor && this.anchor.clientWidth) {
            this.setState({ anchorWidth: this.anchor.clientWidth + "px" })
        }
        this.setState({ showDropdown: !this.state.showDropdown })
    }

    @Bind
    private onCloseDropdown(): void {
        this.setState({ showDropdown: false })
    }

    @Bind
    private onSelect(item: SelectValue): void {
        if (this.props.multiple) {
            if (this.props.onChange) {
                let values: string[] = []
                if (Array.isArray(this.props.value)) {
                    values = [...(this.props.value as string[])]
                }
                this.props.onChange(values.concat([SelectValueUtil.getSerializedValue(item)]))
            }
        } else {
            if (this.props.onChange) {
                this.props.onChange(SelectValueUtil.getSerializedValue(item))
            }
        }

        if (this.props.onSelect) {
            this.props.onSelect(item)
        }

        this.setSerializedValue()
        this.setState({ showDropdown: false })
    }

    @Bind
    private onDeselect(item: SelectValue): void {
        if (this.props.disabled) {
            return
        }

        if (this.props.multiple) {
            if (this.props.onChange) {
                const copy: string[] = [...(this.props.value as string[])]
                const idx: number = SelectValueUtil.findIndexInStringArr(copy, item)
                if (idx >= 0) {
                    copy.splice(idx, 1)
                    this.props.onChange(copy)
                }
            }
            this.setSerializedValue()
        }

        if (this.props.onDeselect) {
            this.props.onDeselect(item)
        }
    }

    @Bind
    private onSelectNoneOption(): void {
        if (this.props.onChange) {
            this.props.onChange("")
        }
        this.setState({ serializedValue: "", showDropdown: false })
    }

    private setSerializedValue(): void {
        let serializedValue: string = ""
        if (this.props.value !== undefined) {
            if (Array.isArray(this.props.value)) {
                if (this.props.value.length > 0) {
                    serializedValue = this.props.value.join("|")
                }
            } else {
                serializedValue = this.props.value
            }
        }
        this.setState({ serializedValue: serializedValue })
    }
}

interface SelectProps {
    className?: string
    id?: string // For use as a FormControl
    name?: string // For use as a FormControl
    value: string | string[]
    options: SelectValue[]
    multiple?: boolean
    required?: boolean
    disabled?: boolean
    placeholder?: string
    noneOption?: string
    onChange?: (selected: string | string[]) => void
    onSelect?: (selected: SelectValue) => void | Promise<void>
    onDeselect?: (selected: SelectValue) => void | Promise<void>
    compact?: boolean
    "aria-label"?: string
}

interface SelectState {
    showDropdown: boolean
    anchorWidth: string

    available: SelectValue[]
    selected?: SelectValue | SelectValue[]
    serializedValue: string
}
