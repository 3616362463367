export function useCopyToClipboard(onCopy?: (text: string) => void) {
    return async (text: string) => {
        try {
            await navigator.clipboard.writeText(text)
            onCopy?.(text)
        } catch (e) {
            console.error(e)
        }
    }
}
