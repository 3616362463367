// external imports
import * as React from "react"
// local imports
import {
    ServiceFormProps,
    useDNSOverride,
    useMetadata,
    useSetErrors,
    useUpdateAttributes,
    useUpdateTags,
} from "../service-form"
import { ServiceTag } from "../../../api/Manage.api"
import { FormLabel, FormSection } from "../../../components"
import { TCPConnectMode, useServiceLocalization } from "../../../services"
import ServiceAttributeForm from "./attributes"
import ServiceConnectionForm from "./connection"
import styles from "./ServiceForm.module.scss"
import { Input } from "../../../../v3/components/input/Input.component"

export default function K8sServiceForm({ edit }: ServiceFormProps) {
    // grab the info we need
    const updateTags = useUpdateTags()
    const localization = useServiceLocalization()
    const updateAttributes = useUpdateAttributes()
    const metadata = useMetadata()
    const dnsOverride = useDNSOverride()
    const setErrors = useSetErrors()

    // some state for the forms
    const tags = edit?.spec?.metadata.tags
    const [clusterName, setClusterName] = React.useState(
        tags ? tags[ServiceTag.KUBE_CLUSTER_NAME] : ""
    )
    const [caKey, setCaKey] = React.useState(tags ? tags[ServiceTag.KUBE_CA_KEY] : "")

    // update the form with any rdp-service specifics
    React.useEffect(() => {
        updateTags((tags) => ({
            ...tags,
            [ServiceTag.BANYAN_PROXY_MODE]: TCPConnectMode.CHAIN,
            [ServiceTag.KUBE_CLUSTER_NAME]: clusterName,
            [ServiceTag.KUBE_CA_KEY]: caKey,
        }))

        updateAttributes((attr) => ({
            ...attr,
            backend: {
                target: {} as any,
                dns_overrides: metadata?.tags.domain
                    ? { [metadata.tags.domain]: dnsOverride! }
                    : {},
                whitelist: [],
                http_connect: true,
                allow_patterns: [
                    { hostnames: metadata?.tags.domain ? [metadata.tags.domain] : [] },
                ],
            },
        }))

        setErrors((errs) => ({
            ...errs,
            KUBE_CA_KEY:
                !caKey && localization.getString("aCaPublicKeyIsRequiredForKubernetesServices"),
            KUBE_OVERRIDE_REQ:
                !dnsOverride &&
                localization.getString("aBackendDNSOverrideIsRequiredForKubernetesServices"),
        }))
    }, [
        dnsOverride,
        metadata?.tags.domain,
        caKey,
        clusterName,
        localization,
        setErrors,
        updateAttributes,
        updateTags,
    ])

    return (
        <>
            <ServiceAttributeForm
                edit={edit}
                hideBackend
                hideBackendDomain
                defaultPort="8443"
                dnsOverrideRequired
            />
            <ServiceConnectionForm edit={edit} defaultAllowOverride />
            <FormSection title={localization.getString("kubernetesSettings")}>
                <FormLabel
                    title={localization.getString("clusterName")}
                    tooltip={localization.getString("kubeClusterNameDescription")}
                    htmlFor="clusterName"
                >
                    <Input
                        className={styles.formInput}
                        placeholder={localization.getString("egCseSecuredCluster")}
                        defaultValue={clusterName}
                        onChange={(e) => setClusterName(e.target.value)}
                    />
                </FormLabel>
                <FormLabel
                    title={localization.getString("kubeOidcProxyCAPublicKey")}
                    htmlFor="kubeOidcProxyCAPublicKey"
                >
                    <textarea
                        id="kubeOidcProxyCAPublicKey"
                        className={styles.formTextArea}
                        placeholder={localization.getString("kubeOidcProxyCAPublicKey")}
                        value={caKey}
                        onChange={(e) => setCaKey(e.target.value)}
                    />
                </FormLabel>
            </FormSection>
        </>
    )
}
