import * as React from "react"
import { useHistory } from "react-router-dom"

import { ROUTE, formatRoutePath } from "../../../../routes"
import {
    AccessTiersStatsDisplayData,
    DisplayData,
    useServiceLocalization,
    useServiceStats,
} from "../../../services"
import { PieChart } from "../../../components/charts/PieChart"
import { chartColorMap } from "../utils"
import { NetAgentStatus } from "../../../api/Infra.api"
import { Widget } from "../Widget"
import { AccessTiersCount } from "./AccessTiersCount"

export const AccessTiers = React.memo(function () {
    const localization = useServiceLocalization()
    const stats = useServiceStats()
    const history = useHistory()

    const [data, setData] = React.useState<AccessTiersStatsDisplayData | null>(null)
    const [loading, setLoading] = React.useState(true)

    React.useEffect(() => {
        stats
            .getAccessTiersStatsDisplayData()
            .then(setData)
            .finally(() => {
                setLoading(false)
            })
    }, [stats])

    return (
        <>
            <AccessTiersCount count={data?.total} />
            <Widget
                gridArea="at-status"
                loading={loading}
                hasData={Boolean(data && data.status.length > 0)}
            >
                <PieChart
                    title={localization.getString("allAccessTiers")}
                    subtitle={localization.getString("byStatus")}
                    data={data?.status
                        .map((datum: DisplayData, i) => ({
                            ...datum,
                            color: [
                                chartColorMap[NetAgentStatus.REPORTING],
                                chartColorMap[NetAgentStatus.TERMINATED],
                                chartColorMap[NetAgentStatus.INACTIVE],
                                chartColorMap[NetAgentStatus.PENDING],
                            ][i],
                        }))
                        .filter(({ value }) => value)}
                    options={{
                        cursor: "pointer",
                        point: {
                            events: {
                                click() {
                                    // navigate the user to the access tier view with the correct filter
                                    history.push(
                                        formatRoutePath(
                                            ROUTE.ACCESS_TIERS,
                                            {},
                                            { fm_status: this.name.toLowerCase() }
                                        )
                                    )
                                },
                            },
                        },
                    }}
                />
            </Widget>
        </>
    )
})
