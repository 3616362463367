import React from "react"
import styles from "./NavBar.module.scss"
import { NavButton } from "../nav-button/NavButton.component"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { NavMenuButton } from "../nav-menu-button/NavMenuButton.component"

export default function () {
    return (
        <ul className={styles.navBar} ref={(c) => (this.navBarContainer = c)}>
            {this.state.links.map((link, index) => {
                const icon = link.icon ? <FontAwesomeIcon icon={link.icon} /> : ""
                return link.divider ? (
                    // render divider
                    <li key={index} className={styles.navBarDivider}></li>
                ) : link.children && link.children.length > 0 ? (
                    // render NavMenuButton
                    <li key={index} className={styles.navButton}>
                        <NavMenuButton links={link.children} highlight caret>
                            {icon}
                            {link.label}
                        </NavMenuButton>
                    </li>
                ) : (
                    // render NavButton
                    <li key={index} className={styles.navButton}>
                        <NavButton href={link.href} exact={link.exact}>
                            {icon}
                            {link.label}
                        </NavButton>
                    </li>
                )
            })}
            {this.state.showMoreButton && (
                <li className={styles.navButton} ref={(c) => (this.moreButton = c)}>
                    <NavMenuButton links={this.state.moreLinks} highlight caret>
                        {this.localizationService.getString("more")}
                    </NavMenuButton>
                </li>
            )}
        </ul>
    )
}
