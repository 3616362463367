import * as React from "react"

import { ROUTE, formatRoutePath } from "../../../../routes"
import { Widget, NoDataOverlay } from "../Widget"
import { Select, Chart } from "../../../components"
import styles from "./Widgets.module.scss"
import { useServiceLocalization, useServiceUser, useServiceReporting } from "../../../services"
import { SelectItem } from "../../../utils/SelectValue.util"
import { TimeRange } from "../../../utils/Date.util"
import { fillSeries1D } from "../utils"
import { encodeID } from "../../../utils/Url.util"

interface Props {
    timeRange: TimeRange
}

export const Top10 = React.memo(function (props: Props) {
    // pull out the services
    const localization = useServiceLocalization()
    const userService = useServiceUser()
    const reportingService = useServiceReporting()

    // select state
    const [selectValue, setSelectValue] = React.useState<Top10Charts>(
        Top10Charts.MOST_ACTIVE_USERS_BY_SERVICES_ACCESSED
    )
    const [options, setOptions] = React.useState<SelectItem[]>([
        {
            displayName: localization.getString("mostActiveUsersByServicesAccessed"),
            value: Top10Charts.MOST_ACTIVE_USERS_BY_SERVICES_ACCESSED,
        },
        {
            displayName: localization.getString("servicesAccessedByUniqueUsers"),
            value: Top10Charts.SERVICES_ACCESSED_BY_UNIQUE_USERS,
        },
    ])

    // chart state
    const [data, setData] = React.useState<
        { displayName: string; value: number | null; id: string }[]
    >([])
    // the click through link
    const [link, setLink] = React.useState<ROUTE>()
    const [loading, setLoading] = React.useState(false)

    // we might need to add additional charts to the select depending on feature flag values
    React.useEffect(() => {
        // if unregistered devices are enabled for this customer we need to add additional graphs
        userService.getUserOrgUnregisteredDevicesEnabled().then((enabled: boolean) => {
            if (enabled) {
                setOptions((opts) => [
                    ...opts,
                    {
                        displayName: localization.getString(
                            "usersAccessingServicesWithUnregisteredDevices"
                        ),
                        value: Top10Charts.USERS_ACCESSING_SERVICES_WITH_UNREGISTERED_DEVICES,
                    },
                    {
                        displayName: localization.getString(
                            "servicesAccessedByUnregisteredDevices"
                        ),
                        value: Top10Charts.SERVICES_ACCESSED_BY_UNREGISTERED_DEVICES,
                    },
                ])
            }
        })
    }, [localization, userService])

    // when the select value changes we need to render a different data set
    React.useEffect(() => {
        setLoading(true)
        // configure the chart to show the information corresponding to the new select value
        if (selectValue === Top10Charts.MOST_ACTIVE_USERS_BY_SERVICES_ACCESSED) {
            reportingService
                .getTopUsersByServicesAccessed(props.timeRange.start!, props.timeRange.end!, 10)
                .then((users) => {
                    // set the chart data
                    setData(
                        users.map((s) => ({ displayName: s.email, value: s.count, id: s.email }))
                    )
                    setLink(ROUTE.USERS_DETAILS)
                    setLoading(false)
                })
        } else if (selectValue === Top10Charts.SERVICES_ACCESSED_BY_UNIQUE_USERS) {
            reportingService
                .getTopServicesAccessedByUniqueUsers(
                    props.timeRange.start!,
                    props.timeRange.end!,
                    10
                )
                .then((services) => {
                    // set the chart data
                    setData(
                        services.map((s) => ({ displayName: s.name, id: s.id, value: s.count }))
                    )
                    setLink(ROUTE.PRIVATE_ACCESS_SERVICES_DETAILS)
                    setLoading(false)
                })
        } else if (selectValue === Top10Charts.SERVICES_ACCESSED_BY_UNREGISTERED_DEVICES) {
            reportingService
                .getTopServicesAccessedByUnregisteredDevices(
                    props.timeRange.start!,
                    props.timeRange.end!,
                    10
                )
                .then((services) => {
                    // set the chart data
                    setData(
                        services.map((s) => ({ displayName: s.name, id: s.id, value: s.count }))
                    )
                    setLink(ROUTE.PRIVATE_ACCESS_SERVICES_DETAILS)
                    setLoading(false)
                })
        } else if (selectValue === Top10Charts.USERS_ACCESSING_SERVICES_WITH_UNREGISTERED_DEVICES) {
            reportingService
                .getTopUsersAccessingServicesWithUnregisteredDevices(
                    props.timeRange.start!,
                    props.timeRange.end!,
                    10
                )
                .then((services) => {
                    // set the chart data
                    setData(
                        services.map((s) => ({ displayName: s.email, value: s.count, id: s.email }))
                    )
                    setLink(ROUTE.USERS_DETAILS)
                    setLoading(false)
                })
        }
    }, [selectValue, reportingService, props.timeRange, localization])

    // figure out the correct axis labels (derived state shouldn't be independent)
    const xAxisLabel = {
        [Top10Charts.MOST_ACTIVE_USERS_BY_SERVICES_ACCESSED]: localization.getString("user"),
        [Top10Charts.SERVICES_ACCESSED_BY_UNIQUE_USERS]: localization.getString("service"),
        [Top10Charts.SERVICES_ACCESSED_BY_UNREGISTERED_DEVICES]: localization.getString("service"),
        [Top10Charts.USERS_ACCESSING_SERVICES_WITH_UNREGISTERED_DEVICES]:
            localization.getString("user"),
    }[selectValue]
    const yAxisLabel = {
        [Top10Charts.MOST_ACTIVE_USERS_BY_SERVICES_ACCESSED]:
            localization.getString("servicesAccessed"),
        [Top10Charts.SERVICES_ACCESSED_BY_UNIQUE_USERS]: localization.getString("uniqueUsers"),
        [Top10Charts.SERVICES_ACCESSED_BY_UNREGISTERED_DEVICES]:
            localization.getString("uniqueUsers"),
        [Top10Charts.USERS_ACCESSING_SERVICES_WITH_UNREGISTERED_DEVICES]:
            localization.getString("servicesAccessed"),
    }[selectValue]

    // make sure there are always 10 options so bar heights and positioning are consistent
    const { filled, idMap } = React.useMemo(
        () => ({
            filled: fillSeries1D(data, 10),
            idMap: data.reduce(
                (acc, point) => ({
                    ...acc,
                    [point.displayName!]: point.id,
                }),
                {}
            ) as StringMap,
        }),
        [data]
    )

    return (
        <Widget gridArea="top-10" loading={false} hasData={true}>
            <h2 className={styles.title}>{localization.getString("top") + " 10"}</h2>
            <Select
                options={options}
                value={selectValue}
                onChange={(val) => setSelectValue(val as Top10Charts)}
                className={styles.select}
            />
            {!filled[0] && !loading ? (
                <NoDataOverlay />
            ) : (
                <Chart
                    options={{
                        chart: {
                            type: "bar",
                            backgroundColor: "transparent",
                        },
                        xAxis: {
                            title: {
                                text: xAxisLabel,
                            },
                            lineWidth: 0,
                            labels: {
                                style: {
                                    color: "#222C36",
                                    fontWeight: "600",
                                },
                                x: 10,
                                align: "left",
                                formatter(ctx) {
                                    // find the id of the entry
                                    const id = idMap[ctx.value]
                                    if (!id || !link) {
                                        return this.axis.defaultLabelFormatter.call(ctx)
                                    }

                                    // render a link to the entity
                                    const preValueStr = ctx.value.toString()
                                    const valueStr =
                                        link === ROUTE.PRIVATE_ACCESS_SERVICES_DETAILS
                                            ? preValueStr.split(".")[0]
                                            : preValueStr

                                    const route = formatRoutePath(link, { id: encodeID(id) })

                                    return `<a href="${route}">${valueStr}</a>`
                                },
                            },
                            categories: filled.map((datum) => datum.displayName),
                        },
                        yAxis: {
                            gridLineWidth: 0,
                            labels: {
                                enabled: false,
                            },
                            title: {
                                text: yAxisLabel,
                                style: { color: "#222C36", fontWeight: "500" },
                                align: "high",
                            },
                        },
                        legend: {
                            enabled: false,
                        },
                        plotOptions: {
                            bar: {
                                dataLabels: {
                                    enabled: true,
                                    align: "left",
                                    color: "#222C36",
                                    x: 1000,
                                },
                                borderColor: undefined,
                                color: "#24B9D7",
                                groupPadding: 0,
                                maxPointWidth: 40,
                            },
                        },
                        series: [
                            {
                                type: "bar",
                                data: filled.map((datum) => datum.value),
                            },
                        ],
                    }}
                />
            )}
        </Widget>
    )
})

enum Top10Charts {
    MOST_ACTIVE_USERS_BY_SERVICES_ACCESSED = "MostActiveUsersByServicesAccessed",
    SERVICES_ACCESSED_BY_UNIQUE_USERS = "servicesAccessedByUniqueUsers",
    USERS_ACCESSING_SERVICES_WITH_UNREGISTERED_DEVICES = "usersAccessingServicesWithUnregisteredDevices",
    SERVICES_ACCESSED_BY_UNREGISTERED_DEVICES = "servicesAccessedByUnregisteredDevices",
}
