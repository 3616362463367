import classNames from "classnames/bind"
import React from "react"

import { Checkbox, CheckboxProps as OriginalCheckboxProps } from "../checkbox/Checkbox.component"
import styles from "./InputWithCheckbox.module.scss"

export interface InputWithCheckboxProps {
    checkboxes: CheckboxProps[]
    children: React.ReactNode
    className?: string
    disabled?: boolean
}

export function InputWithCheckbox(props: InputWithCheckboxProps): JSX.Element {
    return (
        <div className={classNames(styles.container, props.className)}>
            {props.children}
            <div className={styles.checkboxes}>
                {props.checkboxes.map((checkboxProps) =>
                    renderCheckbox({
                        ...checkboxProps,
                        disabled: props.disabled ?? checkboxProps.disabled,
                    })
                )}
            </div>
        </div>
    )
}

export interface CheckboxProps extends OriginalCheckboxProps {
    id: string
}

function renderCheckbox(props: CheckboxProps): JSX.Element {
    return <Checkbox {...props} key={props.id} />
}
