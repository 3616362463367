import React from "react"

import { Input } from "../../../../../../v3/components/input/Input.component"
import styles from "./TrustProviderTokenExpiry.module.scss"
import { ErrorBanner, InfoBanner, Form } from "../../../../../components"
import {
    Button,
    ButtonElement,
    ButtonType,
} from "../../../../../../components/button/Button.component"
import { FormRow } from "../../../../../../components/form/FormRow.component"

export default function () {
    return (
        <div>
            {this.state.loginTokenExpiry !== undefined && (
                <>
                    {this.state.error && <ErrorBanner>{this.state.error}</ErrorBanner>}
                    {this.state.success && <InfoBanner>{this.state.success}</InfoBanner>}
                    <Form onSubmit={this.onSubmit} className={styles.form}>
                        <FormRow
                            label={this.localizationService.getString("setAnExpirationTime")}
                            htmlFor="expirationTimeDescription"
                            description={this.localizationService.getString(
                                "setAnExpirationTimeDesc"
                            )}
                        >
                            <div className={styles.inputLabel}>
                                <Input
                                    type="number"
                                    required
                                    min="8"
                                    max="240"
                                    className={styles.input}
                                    defaultValue={this.state.loginTokenExpiry}
                                    onChange={this.handleChange}
                                    disabled={
                                        this.props.disabled || !this.props.canUpdateTokenExpiry
                                    }
                                />
                                {this.localizationService.getString("hours")}
                            </div>
                        </FormRow>
                        {this.props.canUpdateTokenExpiry && !this.props.disabled && (
                            <FormRow>
                                <Button
                                    buttonType={ButtonType.PRIMARY}
                                    asElement={ButtonElement.BUTTON}
                                    loading={this.state.loading}
                                    type="submit"
                                >
                                    {this.localizationService.getString("update")}
                                </Button>
                            </FormRow>
                        )}
                    </Form>
                </>
            )}
        </div>
    )
}
