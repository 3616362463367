import * as React from "react"
import { PolicyAttr } from "../../../api/Secure.api"
import { JsonTextArea } from "../../../components/json-text-area/JsonTextArea.component"
import { PolicySecure, useServiceLocalization, useServiceSecure } from "../../../services"
import styles from "./PolicyForm.module.scss"

interface Props {
    setAttr: (attr: PolicyAttr) => void
    setError: (err: string | null) => void
    edit?: PolicySecure
    disabled?: boolean
}

export function CustomPolicyForm({ setAttr, edit, setError, disabled }: Props) {
    // grab service references
    const secureService = useServiceSecure()
    const localization = useServiceLocalization()

    // only parse the spec a single time
    const selectedSpec = React.useMemo(() => (edit ? JSON.parse(edit.spec) : null), [edit])

    // form state
    const [customJSON, setCustomJSON] = React.useState<string>(
        selectedSpec
            ? JSON.stringify(selectedSpec.spec)
            : JSON.stringify(secureService.getNullPolicyAttr())
    )

    React.useEffect(() => {
        // build up the policy attributes
        let policyAttr: PolicyAttr = secureService.getNullPolicyAttr()

        try {
            policyAttr = JSON.parse(customJSON) as PolicyAttr
        } catch {
            setError(localization.getString("failedToParseJson"))
            return
        }

        // update the parent state
        setAttr(policyAttr)
    }, [localization, secureService, customJSON, setAttr, setError])

    return (
        <div className={styles.policyAttribute}>
            <JsonTextArea
                initialValue={customJSON}
                onValueChange={setCustomJSON}
                readOnly={disabled}
            />
        </div>
    )
}
