import React, { useEffect, useRef } from "react"
import styles from "./MswSso.module.scss"
import { useHistory } from "react-router-dom"
import { useMswLogin } from "../../services/Login.service"
import { useAuthService } from "../../../pre-v3/services/Auth.service"
import { useUrlSearch } from "../../../pre-v3/utils/Url.util"
import { useServiceLinks } from "../../../pre-v3/services/link/Link.service"
import { BanyanHeader } from "../../components/banyan-header/BanyanHeader.component"
import { Background } from "../../components/background/Background.component"
import sonicWallCSELogo from "../../../images/logo-horiz-black.svg"
import { Card } from "../../components/card/Card.component"
import { useServiceLocalization } from "../../../pre-v3/services"
import { Loader } from "../../components/loader/Loader.component"
import { Banner, Variant } from "../../../components/banner/Banner.component"
import { Button, ButtonElement, ButtonType } from "../../../components/button/Button.component"
import { base64Decode } from "../../../utils/url.utils"

interface MswLoginSearch {
    code?: string
    redirectUrl?: string
    serialNumber?: string
    childorgid?: string
}
interface MswLoginData extends Omit<MswLoginSearch, "childorgid"> {
    childOrgId?: string
}

function useGetMswLoginSearch(): MswLoginData {
    const {
        code,
        redirectUrl,
        serialNumber,
        childorgid: childOrgId,
    } = useUrlSearch<keyof MswLoginSearch>("code", "redirectUrl", "serialNumber", "childorgid")
    return {
        code,
        redirectUrl: redirectUrl && base64Decode(redirectUrl),
        serialNumber,
        childOrgId,
    }
}

export function MswSso() {
    const params = useGetMswLoginSearch()
    const history = useHistory()
    const linkService = useServiceLinks()
    const authService = useAuthService()
    const localization = useServiceLocalization()
    const initialTime = useRef(0)
    const { mutate: login, error: loginError } = useMswLogin({
        onSuccess: ({ consoleAuthToken, cscAuthToken }) => {
            const timePassed = Date.now() - initialTime.current
            const waitTime = 2000
            if (timePassed >= waitTime) return onLogin(consoleAuthToken, cscAuthToken)
            setTimeout(() => onLogin(consoleAuthToken, cscAuthToken), waitTime - timePassed)
        },
    })

    const directLogin = (token: string, cscToken: string) => {
        authService.setMswLogin(token, cscToken, params.redirectUrl)
        history.push(authService.getLoginUrl())
    }

    const impersonateLogin = (token: string, cscToken: string, childOrgId: string) => {
        authService
            .setMswImpersonatedLogin(token, cscToken, childOrgId, params.redirectUrl)
            .then(() => {
                history.push(authService.getLoginUrl())
            })
    }

    const onLogin = (token: string, cscToken: string) => {
        if (params.childOrgId) return impersonateLogin(token, cscToken, params.childOrgId)
        return directLogin(token, cscToken)
    }

    const redirect = () => {
        window.location.replace(params.redirectUrl || linkService.getLink("mswDefaultRedirectUrl"))
    }

    useEffect(() => {
        initialTime.current = Date.now()
        const { code, serialNumber } = params
        login({ code, orgId: serialNumber })
    }, [])

    return (
        <div className={styles.container}>
            <BanyanHeader />
            <Background className={styles.background}>
                <Card className={styles.card}>
                    <img
                        src={sonicWallCSELogo}
                        alt={localization.getString("sonicWallCse")}
                        className={styles.logo}
                    />
                    {loginError ? (
                        <>
                            <div className={styles.banner}>
                                <Banner
                                    label={String(loginError)}
                                    role="alert"
                                    variant={Variant.ERROR}
                                />
                            </div>
                            <Button
                                buttonType={ButtonType.SECONDARY}
                                asElement={ButtonElement.BUTTON}
                                onClick={redirect}
                            >
                                {localization.getString("returnToSonicWall")}
                            </Button>
                        </>
                    ) : (
                        <>
                            <Loader center medium />
                            <p>{localization.getString("mswConnectingMessage")}</p>
                        </>
                    )}
                </Card>
            </Background>
        </div>
    )
}
