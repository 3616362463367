import React from "react"

import { Button, ButtonElement, ButtonType } from "../../../components/button/Button.component"
import { useServiceLocalization } from "../../../pre-v3/services/localization/Localization.service"
import { FormButtons } from "../../../v3/components/form/form-buttons/FormButtons.component"
import { Cluster } from "../../../v3/services/shared/Cluster"
import {
    AccessTierGroupDetails,
    useGetAccessTiers,
    useUpdateAccessTierGroup,
} from "../../../v3/services/AccessTierGroup.service"
import { ErrorToast, SuccessToast, ToastApi } from "../../../components/toast/Toast.components"
import { AccessTierGroupsForm } from "../shared/AccessTierGroupsForm.component"
import styles from "./AccessTierGroupConfigurationForm.module.scss"

interface Props {
    id: string
    accessTierGroup: AccessTierGroupDetails
    privateEdgeCluster: Cluster
    isEditMode: boolean
    onExitEditMode(): void
}

export function AccessTierGroupConfigurationForm(props: Props): JSX.Element {
    const localization = useServiceLocalization()

    const accessTierGroupLabel = localization.getString("accessTierGroup")

    const { data: accessTiers } = useGetAccessTiers(props.privateEdgeCluster, props.accessTierGroup)

    const errorToastRef = React.useRef<ToastApi>(null)
    const successToastRef = React.useRef<ToastApi>(null)

    const [accessTierGroup, setAccessTierGroup] = React.useState(props.accessTierGroup)

    const { mutate: updateAccessTierGroup, isLoading: isUpdatingAccessTierGroup } =
        useUpdateAccessTierGroup(props.privateEdgeCluster, {
            onSuccess: (accessTierGroup) => {
                setAccessTierGroup(accessTierGroup)
                successToastRef.current?.openToast(
                    localization.getString(
                        "somethingHasBeenSuccessfullyUpdated",
                        accessTierGroupLabel,
                        accessTierGroup.name
                    )
                )
                props.onExitEditMode()
            },
            onError: (error) => errorToastRef.current?.openToast(String(error)),
        })

    const onCancelEditMode: React.MouseEventHandler = (e) => {
        e.preventDefault()
        props.onExitEditMode()
        setAccessTierGroup(props.accessTierGroup)
    }

    const onSubmit: React.FormEventHandler = (e) => {
        e.preventDefault()
        updateAccessTierGroup(accessTierGroup)
    }

    return (
        <form id={props.id} className={styles.form} onSubmit={onSubmit}>
            <AccessTierGroupsForm<AccessTierGroupDetails>
                accessTierGroup={props.isEditMode ? accessTierGroup : props.accessTierGroup}
                accessTiers={accessTiers}
                onChange={setAccessTierGroup}
                isEditMode={props.isEditMode}
            />
            {props.isEditMode && (
                <FormButtons
                    leftButtons={
                        <Button
                            asElement={ButtonElement.BUTTON}
                            buttonType={ButtonType.SECONDARY}
                            onClick={onCancelEditMode}
                        >
                            {localization.getString("cancel")}
                        </Button>
                    }
                    rightButtons={
                        <Button
                            asElement={ButtonElement.BUTTON}
                            buttonType={ButtonType.PRIMARY}
                            type="submit"
                            loading={isUpdatingAccessTierGroup}
                        >
                            {localization.getString("editSomething", accessTierGroupLabel)}
                        </Button>
                    }
                />
            )}
            <SuccessToast ref={successToastRef} />
            <ErrorToast ref={errorToastRef} />
        </form>
    )
}
