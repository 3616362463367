import {
    faCheckCircle,
    faExclamation,
    faLocationCircle,
    IconDefinition,
    faDotCircle,
    faTrash,
} from "@fortawesome/pro-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { ColDef, GridApi, GridReadyEvent, ICellRendererParams } from "ag-grid-community"
import classNames from "classnames/bind"
import React, { Fragment, SyntheticEvent, useCallback, useEffect, useRef, useState } from "react"
import { RouteComponentProps } from "react-router"

import { ROUTE, formatRoutePath } from "../../../../../routes"
import {
    ChoiceModal,
    ErrorBanner,
    FormLabel,
    Grid,
    IconButton,
    Input,
    LargeMessage,
    LoadMask,
    MessageContent,
    SimpleTableWithHeader,
} from "../../../../components"
import {
    CloudResource,
    CloudResourceService,
    CloudResourceStatus,
    LocalizationService,
    ModalService,
    ServiceAppType,
    ServiceType,
    UserService,
    useServiceLocalization,
    useServiceModal,
    useServiceUser,
} from "../../../../services"
import {
    SimpleTable,
    SimpleTableItem,
} from "../../../../components/simple-table/SimpleTable.component"
import { DateUtil } from "../../../../utils/Date.util"
import { TabBar } from "../../../../components/tab-bar/TabBar.component"
import { decodeID, encodeID } from "../../../../utils/Url.util"
import AgGridUtil, { KeyValuePair } from "../../../../utils/AgGrid.util"
import { ServicesCellRenderer } from "../../../services/cell-renderer/ServicesCellRenderer"
import { InventoryService, useServiceInventory } from "../../../../services/Inventory.service"
import { DeleteCancelActions } from "../../../../components/modal/delete-cancel/DeleteCancelActions"
import styles from "./CloudResourceOverview.module.scss"
import { PageHeading } from "../../../../../components/page-heading/PageHeading.component"
import {
    Button,
    ButtonElement,
    ButtonType,
    IconType,
} from "../../../../../components/button/Button.component"
import { useGetAdminInfo } from "../../../../../v3/services/Org.service"
import { Tooltip } from "../../../../../v3/components/tooltip/Tooltip.component"

export function CloudResourceOverview(props: CloudResourceOverviewProps) {
    const ls: LocalizationService = useServiceLocalization()
    const inventoryService: InventoryService = useServiceInventory()
    const userService: UserService = useServiceUser()
    const modalService: ModalService = useServiceModal()

    const [loading, setLoading] = useState<boolean>(true)
    const [resource, setResource] = useState<CloudResource>()
    const [resourceServices, setResourceServices] = useState<CloudResourceService[]>([])
    const [tags, setTags] = useState<KeyValuePair[]>([])
    const [statusIcon, setStatusIcon] = useState<IconDefinition>(faLocationCircle)
    const [statusText, setStatusText] = useState<string>()
    const [error, setError] = useState<string>("")

    const [statusInfo, setStatusInfo] = useState<SimpleTableItem[]>([])
    const [resourceInfo, setResourceInfo] = useState<SimpleTableItem[]>([])

    const [ports, setPorts] = useState<KeyValuePair[]>([])
    const gridApi = useRef<GridApi>()

    const [tab, setTab] = useState<number>(1)

    const adminInfoqueryresult = useGetAdminInfo()

    const onOpenDeleteModal = (resource: CloudResourceService) => {
        setError("")
        modalService
            .open(
                ls.getString("confirmResourceServiceDeletion"),
                {
                    component: MessageContent,
                    props: {
                        text: ls.getString("resourceServiceDeletionExplanation"),
                    },
                },
                {
                    component: DeleteCancelActions,
                }
            )
            .onClose(() => {
                if (resource) {
                    inventoryService
                        .deleteResourceServiceAssociation(resource.cloudResourceId)
                        .then(
                            () => {
                                fetchData()
                            },
                            () => {
                                setError(ls.getString("failedToDeleteCloudResourceService"))
                            }
                        )
                }
            })
    }

    const serviceColumnDefs: ColDef[] = [
        {
            headerName: ls.getString("serviceName"),
            field: "serviceId",
            tooltipValueGetter: AgGridUtil.linkTooltipValueGetter,
            cellRenderer: "servicesCellRenderer",
            width: 100,
        },
        {
            headerName: ls.getString("port"),
            field: "port",
            width: 20,
        },
        {
            headerName: "",
            field: "data",
            cellRenderer: "deleteAssociationCellRenderer",
            cellRendererParams: {
                onDelete: onOpenDeleteModal,
            },
            width: 20,
        },
    ]

    const setStatus = useCallback(
        (status: string) => {
            switch (status) {
                case CloudResourceStatus.DISCOVERED:
                    setStatusIcon(faLocationCircle)
                    setStatusText(ls.getString("discovered"))
                    break
                case CloudResourceStatus.PUBLISHED:
                    setStatusIcon(faCheckCircle)
                    setStatusText(ls.getString("published"))
                    break
                case CloudResourceStatus.IGNORED:
                    setStatusIcon(faDotCircle)
                    setStatusText(ls.getString("ignored"))
                    break
                default:
                    setStatusIcon(faLocationCircle)
                    setStatusText(ls.getString("discovered"))
            }
        },
        [ls]
    )

    const setInfo = useCallback(
        (info: CloudResource) => {
            setStatusInfo([
                {
                    label: ls.getString("discoveredOn"),
                    value: info.createdAt ? DateUtil.format(info.createdAt) : "-",
                },
                {
                    label: ls.getString("lastUpdated"),
                    value: info.updatedAt ? DateUtil.format(info.updatedAt) : "-",
                },
            ])

            setResourceInfo([
                {
                    label: ls.getString("resourceType"),
                    value: info.resourceType,
                },
                {
                    label: ls.getString("source"),
                    value: info.cloudProvider,
                },
                {
                    label: ls.getString("account"),
                    value: info.account,
                },
                {
                    label: ls.getString("region"),
                    value: info.region,
                },
            ])
        },
        [ls]
    )

    const onGridReady = (event: GridReadyEvent) => {
        let servicesDetails = []
        gridApi.current = event.api
        if (!gridApi.current) {
            return
        }
        if (gridApi.current && resourceServices) {
            for (let service of resourceServices) {
                servicesDetails.push({
                    serviceId: service.serviceId,
                    serviceName: service.resourceName,
                    port: service.backendPort,
                    cloudResourceId: service.id,
                })
            }
            gridApi.current.setRowData(servicesDetails)
        }
    }

    async function onIgnore(resource: CloudResource) {
        if (resource.id) {
            try {
                await inventoryService.updateResourceStatus(
                    resource.id,
                    resource.status === CloudResourceStatus.IGNORED
                        ? CloudResourceStatus.DISCOVERED
                        : CloudResourceStatus.IGNORED
                )
                fetchData()
            } catch {
                setError(ls.getString("failedToUpdateResourceStatus"))
            }
        }
    }

    const onPublish = useCallback(
        (resource: CloudResource) => {
            modalService.open(ls.getString("publishNewService"), {
                component: ChoiceModal,
                props: {
                    choices: [
                        {
                            title: ls.getString("standardWebsite"),
                            description: ls.getString("standardWebsiteDescription"),
                            link: formatRoutePath(
                                ROUTE.IAAS_DISCOVERY_PUBLISH,
                                { id: encodeID(resource.id) },
                                { type: ServiceType.WEB_USER, appType: ServiceAppType.WEB }
                            ),
                            className: "standardWebsite",
                        },
                        {
                            title: ls.getString("sshService"),
                            description: ls.getString("sshServiceDescription"),
                            link: formatRoutePath(
                                ROUTE.IAAS_DISCOVERY_PUBLISH,
                                { id: encodeID(resource.id) },
                                { type: ServiceType.TCP_USER, appType: ServiceAppType.SSH }
                            ),
                            className: "sshService",
                        },
                        {
                            title: ls.getString("rdpService"),
                            description: ls.getString("rdpServiceDescription"),
                            link: formatRoutePath(
                                ROUTE.IAAS_DISCOVERY_PUBLISH,
                                { id: encodeID(resource.id) },
                                { type: ServiceType.TCP_USER, appType: ServiceAppType.RDP }
                            ),
                            className: "rdpService",
                        },
                        {
                            title: ls.getString("kubernetesService"),
                            description: ls.getString("kubernetesServiceDescription"),
                            link: formatRoutePath(
                                ROUTE.IAAS_DISCOVERY_PUBLISH,
                                { id: encodeID(resource.id) },
                                { type: ServiceType.TCP_USER, appType: ServiceAppType.K8S }
                            ),
                            className: "kubernetesService",
                        },
                        {
                            title: ls.getString("databaseService"),
                            description: ls.getString("databaseServiceDescription"),
                            link: formatRoutePath(
                                ROUTE.IAAS_DISCOVERY_PUBLISH,
                                { id: encodeID(resource.id) },
                                { type: ServiceType.TCP_USER, appType: ServiceAppType.DATABASE }
                            ),
                            className: "databaseService",
                        },
                        {
                            title: ls.getString("otherTCPService"),
                            description: ls.getString("otherTCPServiceDescription"),
                            link: formatRoutePath(
                                ROUTE.IAAS_DISCOVERY_PUBLISH,
                                { id: encodeID(resource.id) },
                                { type: ServiceType.TCP_USER, appType: ServiceAppType.GENERIC }
                            ),
                            className: "otherTCPService",
                        },
                    ],
                },
                maxWidth: "md",
            })
        },
        [ls, modalService]
    )

    const fetchData = useCallback(() => {
        setTab(1)
        setLoading(true)
        setResource(undefined)
        let resourceId: string = ""
        try {
            resourceId = decodeID(props.match.params.id)
        } catch {} // ignore
        if (resourceId) {
            inventoryService
                .getResourceAndServiceDetails({
                    cloud_resource_id: resourceId,
                })
                .then((services: CloudResourceService[]) => {
                    if (services) {
                        setResourceServices(services)
                    }
                })

            inventoryService
                .getCloudResource(resourceId)
                .then((response: CloudResource) => {
                    setResource(response)
                    if (response) {
                        setStatus(response.status)
                        setInfo(response)
                    }
                    if (response.tags) {
                        setTags(response.tags)
                    }
                    if (response.ports) {
                        let portsAndProtocol = inventoryService.getResourcePortAndProtocol(response)
                        setPorts(portsAndProtocol)
                    }
                })
                .catch(() => setResource(undefined))
                .finally(() => setLoading(false))
        } else {
            setLoading(false)
        }
    }, [
        setStatus,
        setInfo,
        setTags,
        ls,
        props.match.params.id,
        inventoryService,
        userService,
        onPublish,
    ])

    useEffect(() => {
        fetchData()
    }, [fetchData])

    const onRefresh = async (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
        event.preventDefault()
        await fetchData()
    }

    return (
        <div className="pre-v3">
            {error && (
                <div className={styles.errorBanner}>{<ErrorBanner>{error}</ErrorBanner>}</div>
            )}
            <div className={styles.sectionContainer}>
                {loading && <LoadMask />}
                {!setResource && !loading && (
                    <LargeMessage icon={faExclamation} className={styles.missingMessage}>
                        {ls.getString("cloudResourceNotFound")}
                    </LargeMessage>
                )}
                {resource && (
                    <>
                        <header className={styles.header}>
                            <PageHeading>{resource.resourceName}</PageHeading>
                            <div className={styles.actionButtons}>
                                {adminInfoqueryresult.data?.canWriteServices && (
                                    <>
                                        <Button
                                            icon={
                                                resource.status === CloudResourceStatus.IGNORED
                                                    ? IconType.LOCATION
                                                    : IconType.EMPTY_SET
                                            }
                                            onClick={() => onIgnore(resource)}
                                            asElement={ButtonElement.BUTTON}
                                            buttonType={ButtonType.SECONDARY}
                                            disabled={
                                                resource.status === CloudResourceStatus.PUBLISHED
                                            }
                                            aria-label={
                                                resource.status === CloudResourceStatus.IGNORED
                                                    ? ls.getString("setToDiscovered")
                                                    : ls.getString("ignore")
                                            }
                                        >
                                            {resource.status === CloudResourceStatus.IGNORED
                                                ? ls.getString("setToDiscovered")
                                                : ls.getString("ignore")}
                                        </Button>
                                        <Button
                                            icon={IconType.UPLOAD}
                                            onClick={() => onPublish(resource)}
                                            asElement={ButtonElement.BUTTON}
                                            buttonType={ButtonType.SECONDARY}
                                            disabled={
                                                resource.status === CloudResourceStatus.IGNORED
                                            }
                                            aria-label={ls.getString("publishAsService")}
                                        >
                                            {ls.getString("publishAsService")}
                                        </Button>
                                    </>
                                )}
                                <Tooltip title={ls.getString("refresh")}>
                                    <Button
                                        icon={IconType.REDO}
                                        onClick={onRefresh}
                                        asElement={ButtonElement.BUTTON}
                                        buttonType={ButtonType.SECONDARY}
                                    />
                                </Tooltip>
                            </div>
                        </header>
                        <div className={styles.container}>
                            <div className={styles.overviewLeftContainer}>
                                <div
                                    className={classNames(styles.resource, {
                                        [styles.discovered]:
                                            resource.status === CloudResourceStatus.DISCOVERED ||
                                            resource.status === "",
                                        [styles.published]:
                                            resource.status === CloudResourceStatus.PUBLISHED,
                                        [styles.ignored]:
                                            resource.status === CloudResourceStatus.IGNORED,
                                    })}
                                >
                                    <p className={styles.statusHeader}>
                                        {ls.getString("resourceStatus")}
                                    </p>
                                    <p>
                                        <FontAwesomeIcon
                                            className={styles.statusIcon}
                                            icon={statusIcon}
                                        />
                                        <span className={styles.statusText}>{statusText}</span>
                                    </p>
                                    <SimpleTable items={statusInfo} />
                                </div>
                                <SimpleTable items={resourceInfo} />
                            </div>
                            <div className={styles.overviewRightContainer}>
                                <TabBar
                                    tabs={[
                                        {
                                            label: ls.getString("specification"),
                                            value: 1,
                                            active: true,
                                        },
                                        {
                                            label: `${ls.getString("services")} (${
                                                resourceServices.length
                                            })`,
                                            value: 2,
                                        },
                                    ]}
                                    onChange={setTab}
                                ></TabBar>
                                <div className={styles.overviewRightGridContainer}>
                                    {tab === 1 && (
                                        <div className={classNames(styles.formFields)}>
                                            <br />
                                            <div className={styles.container}>
                                                <FormLabel
                                                    className={styles.formLabel}
                                                    title={ls.getString("publicIp")}
                                                    htmlFor="publicIp"
                                                >
                                                    <Input
                                                        className={styles.formInput}
                                                        defaultValue={resource.publicIp}
                                                        disabled
                                                    />
                                                </FormLabel>
                                                <FormLabel
                                                    className={styles.formLabel}
                                                    title={ls.getString("publicDomainName")}
                                                    htmlFor="publicDomainName"
                                                >
                                                    <Input
                                                        className={styles.formInput}
                                                        defaultValue={resource.publicDnsName}
                                                        disabled
                                                    />
                                                </FormLabel>
                                            </div>
                                            <div className={styles.container}>
                                                <FormLabel
                                                    className={styles.formLabel}
                                                    title={ls.getString("privateIp")}
                                                    htmlFor="privateIp"
                                                >
                                                    <Input
                                                        className={styles.formInput}
                                                        defaultValue={resource.privateIp}
                                                        disabled
                                                    />
                                                </FormLabel>
                                                <FormLabel
                                                    className={styles.formLabel}
                                                    title={ls.getString("privateDomainName")}
                                                    htmlFor="privateDomainName"
                                                >
                                                    <Input
                                                        className={styles.formInput}
                                                        defaultValue={resource.privateDnsName}
                                                        disabled
                                                    />
                                                </FormLabel>
                                            </div>
                                            <SimpleTableWithHeader
                                                className={styles.table}
                                                items={ports}
                                                keyHeader={ls.getString("port")}
                                                valueHeader={ls.getString("protocol")}
                                            />
                                            <SimpleTableWithHeader
                                                className={styles.table}
                                                items={tags}
                                                keyHeader={ls.getString("tagName")}
                                                valueHeader={ls.getString("value")}
                                            />
                                        </div>
                                    )}
                                    {tab === 2 && (
                                        <Grid
                                            onGridReady={onGridReady}
                                            columnDefs={serviceColumnDefs}
                                            context={{ history: props.history }}
                                            components={{
                                                servicesCellRenderer: ServicesCellRenderer,
                                                deleteAssociationCellRenderer:
                                                    DeleteAssociationCellRenderer,
                                            }}
                                        />
                                    )}
                                </div>
                            </div>
                        </div>
                    </>
                )}
            </div>
        </div>
    )
}

interface CloudResourceOverviewProps extends RouteComponentProps<{ id: string }> {}

function DeleteAssociationCellRenderer(props: DeleteAssociationCellRendererProps) {
    const ls: LocalizationService = useServiceLocalization()
    const clickHandler = (event: SyntheticEvent): void => {
        event.preventDefault()
        if (props.onDelete) {
            props.onDelete(props.data)
        }
    }

    return (
        <Fragment>
            <IconButton
                tooltip={ls.getString("deleteAssociation")}
                icon={faTrash}
                onClick={clickHandler}
            />
        </Fragment>
    )
}

interface DeleteAssociationCellRendererProps extends ICellRendererParams {
    context: any
    onDelete: (data: any) => void
}
