import * as React from "react"

import { ROUTE, formatRoutePath } from "../../../../routes"
import { TrustLevel } from "../../../api/Entity.api"
import { DevicePlatform } from "../../../api/Reporting.api"
import { Chart, FormLabel, ToggleButton } from "../../../components"
import { useServiceLocalization, useServiceReporting } from "../../../services"
import { TimeRange } from "../../../utils/Date.util"
import { encodeID } from "../../../utils/Url.util"
import { chartColorMap } from "../utils"
import { Widget, NoDataOverlay } from "../Widget"
import styles from "./Widgets.module.scss"

interface Props {
    timeRange: TimeRange
}

interface SankeyDataPoint {
    from: string
    to: string
    weight: number
    id?: string
}

export const DevicesSankey = React.memo((props: Props) => {
    const localization = useServiceLocalization()
    const reportingService = useServiceReporting()

    // should we show the blocked or unblocked devices
    const [blocked, setBlocked] = React.useState(false)

    // chart data
    const [loading, setLoading] = React.useState(false)
    const [data, setData] = React.useState<SankeyDataPoint[]>([])

    // load data
    React.useEffect(() => {
        setLoading(true)

        // figure out the right data to load
        const promise = blocked
            ? reportingService.getTopServicesBlockedByPlatformTrustLevel(
                  props.timeRange.start!,
                  props.timeRange.end!
              )
            : reportingService.getTopServicesAllowedByPlatformTrustLevel(
                  props.timeRange.start!,
                  props.timeRange.end!
              )

        // massage the data into something we can use in the sankey diagram
        promise.then((services) => {
            const series: SankeyDataPoint[] = []
            const platformTrustLevelCounts: {
                [platformKey: string]: { [trustLevelKey: string]: number }
            } = {}
            const trustLevelServiceCounts: {
                [trustLevelKey: string]: { [serviceKey: string]: { count: number; id: string } }
            } = {}
            for (const service of services) {
                const serviceName: string = service.serviceName
                for (const platform of service.platforms) {
                    if (!(platform.name in platformTrustLevelCounts)) {
                        platformTrustLevelCounts[platform.name] = {}
                    }
                    for (const trustLevelAccessCount of platform.trustLevelAccessCounts) {
                        if (
                            !(
                                trustLevelAccessCount.trustlevel in
                                platformTrustLevelCounts[platform.name]
                            )
                        ) {
                            platformTrustLevelCounts[platform.name][
                                trustLevelAccessCount.trustlevel
                            ] = 0
                        }
                        platformTrustLevelCounts[platform.name][trustLevelAccessCount.trustlevel] =
                            platformTrustLevelCounts[platform.name][
                                trustLevelAccessCount.trustlevel
                            ] + trustLevelAccessCount.count

                        if (!(trustLevelAccessCount.trustlevel in trustLevelServiceCounts)) {
                            trustLevelServiceCounts[trustLevelAccessCount.trustlevel] = {}
                        }
                        if (
                            !(
                                serviceName in
                                trustLevelServiceCounts[trustLevelAccessCount.trustlevel]
                            )
                        ) {
                            trustLevelServiceCounts[trustLevelAccessCount.trustlevel][serviceName] =
                                {
                                    count: 0,
                                    id: service.id,
                                }
                        }
                        trustLevelServiceCounts[trustLevelAccessCount.trustlevel][
                            serviceName
                        ].count =
                            trustLevelServiceCounts[trustLevelAccessCount.trustlevel][serviceName]
                                .count + trustLevelAccessCount.count
                    }
                }
            }

            for (const [platformKey, platformValue] of Object.entries(platformTrustLevelCounts)) {
                for (const [trustLevelKey, trustLevelValue] of Object.entries(platformValue)) {
                    series.push({ from: platformKey, to: trustLevelKey, weight: trustLevelValue })
                }
            }

            for (const [trustLevelKey, trustLevelValue] of Object.entries(
                trustLevelServiceCounts
            )) {
                for (const [serviceKey, serviceValue] of Object.entries(trustLevelValue)) {
                    series.push({
                        from: trustLevelKey,
                        to: serviceKey,
                        weight: serviceValue.count,
                        id: serviceValue.id,
                    })
                }
            }

            setData(series)
            setLoading(false)
        })
    }, [blocked, props.timeRange, reportingService])

    // figure out the id of each service
    const idMap = React.useMemo(() => {
        const serviceIdMapping: StringMap = {}
        data.forEach((d) => {
            if (d.id) {
                serviceIdMapping[d.to] = d.id
            }
        })

        return serviceIdMapping
    }, [data])

    return (
        <Widget gridArea="device-access" loading={false} hasData={true}>
            <h2 className={styles.title}>
                {localization.getString("topServicesAccessedByUniqueUsers")}
            </h2>
            <div className={styles.toggleContainer}>
                <FormLabel
                    title={localization.getString("showUniqueUsersAccessingAServiceWith")}
                    slim
                    labelClassName={styles.toggleButtonLabel}
                >
                    <ToggleButton
                        items={[
                            {
                                label: localization.getString("authorizedAccess"),
                                onClick: () => setBlocked(false),
                                selected: !blocked,
                            },
                            {
                                label: localization.getString("blockedAttempt"),
                                onClick: () => setBlocked(true),
                                selected: blocked,
                            },
                        ]}
                        className={styles.toggleButton}
                    />
                </FormLabel>
            </div>
            {data.length === 0 && !loading ? (
                <NoDataOverlay />
            ) : (
                <Chart
                    options={{
                        chart: {
                            marginLeft: 250,
                            marginRight: 250,
                        },
                        plotOptions: {
                            sankey: {
                                dataLabels: {
                                    useHTML: true,
                                    style: {
                                        fontSize: "12px",
                                        fontWeight: "600",
                                    },
                                    color: "black",
                                    enabled: true,
                                    align: "left",
                                    crop: false,
                                    overflow: "allow",
                                    padding: 0,
                                    x: 30,
                                    allowOverlap: true,
                                },
                            },
                        },
                        tooltip: {
                            useHTML: true,
                            style: {
                                color: "black",
                                fontWeight: "bold",
                            },
                        },
                        accessibility: {
                            point: {
                                valueDescriptionFormat:
                                    "{index}. {point.from} to {point.to}, {point.weight}.",
                            },
                        },
                        series: [
                            {
                                colors: [
                                    chartColorMap[DevicePlatform.ANDROID],
                                    chartColorMap[DevicePlatform.APPLE],
                                    chartColorMap[DevicePlatform.IOS],
                                    chartColorMap[DevicePlatform.LINUX],
                                    chartColorMap[DevicePlatform.MACOS],
                                    chartColorMap[DevicePlatform.OTHER],
                                ],
                                keys: ["from", "to", "weight"],
                                data: data,
                                type: "sankey",
                                dataLabels: {
                                    nodeFormatter(
                                        this:
                                            | Highcharts.PointLabelObject
                                            | Highcharts.SeriesSankeyDataLabelsFormatterContextObject
                                    ): string | undefined {
                                        if (idMap[this.point.name]) {
                                            const idBase64: string = encodeID(
                                                idMap[this.point.name]
                                            )

                                            const hrefLink = formatRoutePath(
                                                ROUTE.PRIVATE_ACCESS_SERVICES_DETAILS,
                                                { id: idBase64 }
                                            )
                                            const hrefContent = this.point.name.split(".")[0]

                                            return `<a style="color:black;" href=${hrefLink}>${hrefContent}</a>`
                                        } else {
                                            return this.point.name
                                        }
                                    },
                                },
                                name: blocked
                                    ? localization.getString("blockedAttempts")
                                    : localization.getString("authorizedAccess"),
                                nodes: [
                                    {
                                        // Pending API fix
                                        id: DevicePlatform.ANDROID.toLowerCase(),
                                        name: DevicePlatform.ANDROID,
                                        color: chartColorMap[DevicePlatform.ANDROID],
                                        dataLabels: {
                                            align: "right",
                                            x: -30,
                                        },
                                    },
                                    {
                                        id: DevicePlatform.APPLE,
                                        color: chartColorMap[DevicePlatform.APPLE],
                                        dataLabels: {
                                            align: "right",
                                            x: -30,
                                        },
                                    },
                                    {
                                        // Pending API fix
                                        id: DevicePlatform.IOS.toLowerCase(),
                                        name: DevicePlatform.IOS,
                                        color: chartColorMap[DevicePlatform.IOS],
                                        dataLabels: {
                                            align: "right",
                                            x: -30,
                                        },
                                    },
                                    {
                                        id: DevicePlatform.LINUX,
                                        color: chartColorMap[DevicePlatform.LINUX],
                                        dataLabels: {
                                            align: "right",
                                            x: -30,
                                        },
                                    },
                                    {
                                        id: DevicePlatform.MACOS,
                                        color: chartColorMap[DevicePlatform.MACOS],
                                        dataLabels: {
                                            align: "right",
                                            x: -30,
                                        },
                                    },
                                    {
                                        id: DevicePlatform.OTHER,
                                        color: chartColorMap[DevicePlatform.OTHER],
                                        dataLabels: {
                                            align: "right",
                                            x: -30,
                                        },
                                    },
                                    {
                                        id: DevicePlatform.UNKNOWN,
                                        color: chartColorMap[DevicePlatform.UNKNOWN],
                                        dataLabels: {
                                            align: "right",
                                            x: -30,
                                        },
                                    },
                                    {
                                        id: DevicePlatform.UNREGISTERED,
                                        color: chartColorMap[DevicePlatform.UNREGISTERED],
                                        dataLabels: {
                                            align: "right",
                                            x: -30,
                                        },
                                    },
                                    {
                                        id: DevicePlatform.WINDOWS,
                                        color: chartColorMap[DevicePlatform.WINDOWS],
                                        dataLabels: {
                                            align: "right",
                                            x: -30,
                                        },
                                    },
                                    {
                                        id: DevicePlatform.WIN_RT,
                                        color: chartColorMap[DevicePlatform.WIN_RT],
                                        dataLabels: {
                                            align: "right",
                                            x: -30,
                                        },
                                    },
                                    {
                                        id: TrustLevel.ALWAYS_ALLOW,
                                        color: chartColorMap[TrustLevel.ALWAYS_ALLOW],
                                    },
                                    {
                                        id: TrustLevel.HIGH,
                                        color: chartColorMap[TrustLevel.HIGH],
                                    },
                                    {
                                        id: TrustLevel.MID,
                                        color: chartColorMap[TrustLevel.MID],
                                    },
                                    {
                                        id: TrustLevel.LOW,
                                        color: chartColorMap[TrustLevel.LOW],
                                    },
                                    {
                                        id: TrustLevel.ALWAYS_DENY,
                                        color: chartColorMap[TrustLevel.ALWAYS_DENY],
                                    },
                                    {
                                        id: TrustLevel.PENDING,
                                        color: chartColorMap[TrustLevel.PENDING],
                                    },
                                ],
                            },
                        ],
                    }}
                />
            )}
        </Widget>
    )
})
