import classNames from "classnames/bind"
import React from "react"

import { Button, ButtonElement, ButtonType, IconType } from "../button/Button.component"
import { FormLabel, Props as FormLabelProps } from "./FormLabel.component"
import styles from "./FormRow.module.scss"
import { Tooltip } from "../../v3/components/tooltip/Tooltip.component"

interface Props extends FormLabelProps {
    labelClassName?: string
    childrenClassName?: string
    children?: React.ReactNode
    removeLabel?: string
    onRemove?: () => void
}

export function FormRow(props: Props): JSX.Element {
    return (
        <div className={classNames(styles.container, props.className)}>
            <FormLabel
                id={props.id}
                label={props.label}
                description={props.description}
                htmlFor={props.htmlFor}
                className={classNames(styles.formLabel, props.labelClassName)}
                required={props.required}
            />
            <div className={classNames(styles.children, props.childrenClassName)}>
                {props.children}
            </div>
            {props.removeLabel && (
                <Tooltip title={props.removeLabel}>
                    <Button
                        asElement={ButtonElement.BUTTON}
                        buttonType={ButtonType.SECONDARY}
                        className={styles.removeButton}
                        icon={IconType.TRASH}
                        onClick={props.onRemove}
                    />
                </Tooltip>
            )}
        </div>
    )
}
