import React, { ReactNode, SyntheticEvent } from "react"
import TrustProviderDeviceManagerSettingsTemplate from "./TrustProviderDeviceManagerSettings.template"
import { LocalizationService } from "../../../../services/localization/Localization.service"
import { LinkService } from "../../../../services/link/Link.service"
import {
    DeviceManagerName,
    UserApi,
    UserOrgDetails,
    UserOrgDeviceManagerDetails,
    MDMConfig,
} from "../../../../api/User.api"
import { Bind } from "../../../../decorators/Bind.decorator"
import { UserService } from "../../../../services/User.service"
import ActionBarService from "../../../../services/ActionBar.service"
import { SelectItem } from "../../../../utils/SelectValue.util"

interface Props {
    canUpdateTrustProviderDeviceManagerSetting?: boolean
}
export class TrustProviderDeviceManagerSettings extends React.Component<
    Props,
    TrustProviderDeviceManagerSettingsState
> {
    public state: TrustProviderDeviceManagerSettingsState = {
        error: "",
        success: "",
        deviceManagerName: "",
        deviceManagerConfig: {},
        showConfirmDialog: false,
        failMode: FailModes.CLOSED,
    }

    public render(): ReactNode {
        return TrustProviderDeviceManagerSettingsTemplate.call(this)
    }

    public componentDidMount(): void {
        this.actionBarService.setItems(this.localizationService.getString("deviceManager"), [
            { label: this.localizationService.getString("enduserManagement") },
        ])

        this.userService.getUserOrgDetails(true).then((orgDetails: UserOrgDetails) => {
            let mdmConfig: MDMConfig = {}
            if (orgDetails.MDMConfig) {
                try {
                    mdmConfig = JSON.parse(orgDetails.MDMConfig)
                } catch {
                    this.setState({
                        error: this.localizationService.getString(
                            "couldNotParseExistingDeviceManagerConfig"
                        ),
                    })
                }
            }
            let failMode: FailModes = mdmConfig.FailOpen ? FailModes.OPEN : FailModes.CLOSED
            this.setState({
                deviceManagerName: orgDetails.MDMName || DeviceManagerName.NONE,
                deviceManagerConfig: mdmConfig,
                failMode,
            })
        })
    }

    private localizationService: LocalizationService = new LocalizationService()
    private actionBarService: ActionBarService = new ActionBarService()
    private linkService: LinkService = new LinkService()
    private userService: UserService = new UserService()
    private userApi: UserApi = new UserApi()

    private deviceManagerOptions: SelectItem[] = [
        {
            displayName: this.localizationService.getString("none"),
            value: DeviceManagerName.NONE,
        },
        {
            displayName: this.localizationService.getString("workspaceOneUEM"),
            value: DeviceManagerName.AIRWATCH,
        },
        {
            displayName: this.localizationService.getString("deviceCertOnly"),
            value: DeviceManagerName.CERT_ONLY,
        },
    ]
    private deviceManagerDetails: UserOrgDeviceManagerDetails

    private failModeOptions: SelectItem[] = [
        {
            displayName: this.localizationService.getString("failOpenDescription"),
            value: FailModes.OPEN,
        },
        {
            displayName: this.localizationService.getString("failClosedDescription"),
            value: FailModes.CLOSED,
        },
    ]

    @Bind
    private onDeviceManagerChange(value: string): void {
        this.setState({ deviceManagerName: value })
    }

    @Bind
    private onFailModeChange(value: FailModes): void {
        this.setState({ failMode: value })
    }

    @Bind
    private onShowConfirmDialog(): void {
        this.setState({ showConfirmDialog: true })
    }

    @Bind
    private onHideConfirmDialog(): void {
        this.setState({ showConfirmDialog: false })
    }

    @Bind
    private onSubmit(event: SyntheticEvent): void {
        this.setState({ success: "", error: "" })
        event.preventDefault()
        if (event.target) {
            const form: { [key: string]: HTMLInputElement } = event.target as any
            this.deviceManagerDetails = { MDMName: this.state.deviceManagerName, MDMConfig: {} }
            if (this.state.deviceManagerName === DeviceManagerName.NONE) {
                this.deviceManagerDetails.MDMConfig.Config = ""
            } else {
                if (this.state.deviceManagerName === DeviceManagerName.AIRWATCH) {
                    this.deviceManagerDetails.MDMConfig.URL = form["apiHostUrl"].value
                    this.deviceManagerDetails.MDMConfig.Username = form["username"].value
                    this.deviceManagerDetails.MDMConfig.Password = form["password"].value
                    this.deviceManagerDetails.MDMConfig.APIkey = form["apiKey"].value
                    this.deviceManagerDetails.MDMConfig.FailOpen =
                        this.state.failMode === FailModes.OPEN
                }
                // Fall back to existing values
                this.deviceManagerDetails.MDMConfig.RootCA = this.state.deviceManagerConfig.RootCA
                this.deviceManagerDetails.MDMConfig.CNFormat =
                    this.state.deviceManagerConfig.CNFormat
            }
            this.onShowConfirmDialog()
        }
    }

    @Bind
    private onSubmitDeviceCert(event: SyntheticEvent): void {
        event.preventDefault()
        this.setState({ success: "", error: "" })
        if (event.target) {
            const form: { [key: string]: HTMLInputElement } = event.target as any
            this.deviceManagerDetails = {
                MDMName: this.state.deviceManagerName,
                MDMConfig: {
                    // Fall back to existing values
                    ...this.state.deviceManagerConfig,
                    RootCA: form["certRootCa"].value,
                    CNFormat: form["certCommonName"].value,
                },
            }
            this.onShowConfirmDialog()
        }
    }

    @Bind
    private onUpdateDeviceManager(): void {
        this.onHideConfirmDialog()
        this.setState({ loading: true })
        this.userApi.updateDeviceManagerConfig(this.deviceManagerDetails).then(
            () => {
                this.setState({
                    loading: false,
                    success: this.localizationService.getString(
                        "deviceManagerConfigurationUpdated"
                    ),
                })
            },
            (error: Error) => {
                this.setState({ loading: false, error: error.message })
            }
        )
    }
}

enum FailModes {
    OPEN = "open",
    CLOSED = "closed",
}

interface TrustProviderDeviceManagerSettingsState {
    error: string
    success: string
    loading?: boolean
    deviceManagerName: string
    deviceManagerConfig: MDMConfig
    failMode?: FailModes
    showConfirmDialog: boolean
}
