import {
    RoleAttr as RoleSpec,
    RoleMetadataReq,
    RoleSpecReq as RoleReq,
    RoleRes,
} from "../../api/Role.api"
import { PillMultiSelectOption } from "../../components/pill-multi-select/PillMultiSelect.component"

export enum Status {
    ACTIVE = "active",
    INACTIVE = "inactive",
}

export function getStatusFromRes(roleRes: RoleRes): Status {
    return roleRes.Active ? Status.ACTIVE : Status.INACTIVE
}

type MetadataReq = Omit<RoleMetadataReq, "tags">

export function getRoleReq(metadata: MetadataReq, spec: RoleSpec): RoleReq {
    return {
        apiVersion: "rbac.banyanops.com/v1",
        kind: "BanyanRole",
        type: "origin",
        metadata: {
            id: metadata.id,
            name: metadata.name,
            description: metadata.description,
            tags: { template: "USER" },
        },
        spec,
    }
}

export function getMobileDevicesRoleRec(): RoleReq {
    const metadata: MetadataReq = { name: mobileDevicesRoleName, description: "" }
    const spec: RoleSpec = { platform: ["Android", "iOS"] }

    return getRoleReq(metadata, spec)
}

const mobileDevicesRoleName = "MobileDevices"

interface HasName {
    name: string
}

export function isMobileDevicesRole(role: string | HasName): boolean {
    const name = typeof role === "string" ? role : role.name
    return name.toLowerCase() === mobileDevicesRoleName.toLowerCase()
}

export function addMobileDevicesOption(roles: PillMultiSelectOption[]): PillMultiSelectOption[] {
    const option: PillMultiSelectOption = {
        label: mobileDevicesRoleName,
        value: mobileDevicesRoleName,
    }

    return roles.some(({ label }) => isMobileDevicesRole(label)) ? roles : [option, ...roles]
}
