import {
    ColDef,
    GridApi,
    GridReadyEvent,
    ITooltipParams,
    ValueFormatterParams,
} from "ag-grid-community"
import React, { ReactNode } from "react"
import { RouteComponentProps as Props } from "react-router"

import { ROUTE } from "../../../../routes"
import ActionBarService from "../../../services/ActionBar.service"
import { LanguageKey } from "../../../services/localization/languages/en-US.language"
import { LocalizationService } from "../../../services/localization/Localization.service"
import {
    AppPlatform,
    OperatingSystem,
    PreferredApp,
    SettingsService,
} from "../../../services/Settings.service"
import { Bind } from "../../../decorators/Bind.decorator"
import { SettingsApi } from "../../../api/Settings.api"
import AgGridUtil from "../../../utils/AgGrid.util"
import { TrustFactorService } from "../../../services/TrustFactor.service"
import { TrustFactorName } from "../../../api/TrustScore.api"
import { ModalService } from "../../../services/Modal.service"
import { SelectItem } from "../../../utils/SelectValue.util"
import ApplicationCheckTemplate from "./ApplicationCheck.template"
import EditApplicableDeviceOwnershipsModalContent from "./EditApplicableDeviceOwnershipsModalContent"

interface State {
    appliedDevices: SelectItem[] | null
}

export class ApplicationCheck extends React.Component<Props, State> {
    constructor(props: Props) {
        super(props)
        this.state = {
            appliedDevices: null,
        }
    }
    public render(): ReactNode {
        return ApplicationCheckTemplate.call(this)
    }

    public componentDidMount(): void {
        this.actionBarService.setItems(
            this.localizationService.getString("applicationCheck"),
            [
                {
                    label: this.localizationService.getString("trustScoreSettings"),
                },
                {
                    label: this.localizationService.getString("trustFactors"),
                    href: ROUTE.TRUST_FACTOR_SETTINGS,
                },
            ],
            this.fetchData
        )
    }

    public componentWillUnmount(): void {
        this.actionBarService.unregisterRefreshFn(this.fetchData)
    }

    private actionBarService: ActionBarService = new ActionBarService()
    private localizationService: LocalizationService = new LocalizationService()
    private settingsService: SettingsService = new SettingsService()
    private settingsApi: SettingsApi = new SettingsApi()
    private trustFactorService: TrustFactorService = new TrustFactorService()
    private modalService: ModalService = new ModalService()

    private gridApi: GridApi
    private columns: ColDef[] = [
        {
            headerName: this.localizationService.getString("name"),
            field: "name",
            tooltipValueGetter: AgGridUtil.linkTooltipValueGetter,
            cellRenderer: "preferredAppNameCellRenderer",
        },
        {
            headerName: this.localizationService.getString("macOS"),
            field: "platforms",
            tooltipValueGetter: this.osTooltipValueGetter,
            cellRenderer: "preferredAppsOSCellRenderer",
            cellRendererParams: {
                columnOs: OperatingSystem.MACOS,
            },
        },
        {
            headerName: this.localizationService.getString("windows"),
            field: "platforms",
            tooltipValueGetter: this.osTooltipValueGetter,
            cellRenderer: "preferredAppsOSCellRenderer",
            cellRendererParams: {
                columnOs: OperatingSystem.WINDOWS,
            },
        },
        {
            headerName: this.localizationService.getString("linux"),
            field: "platforms",
            tooltipValueGetter: this.osTooltipValueGetter,
            cellRenderer: "preferredAppsOSCellRenderer",
            cellRendererParams: {
                columnOs: OperatingSystem.UBUNTU,
            },
        },
        {
            headerName: this.localizationService.getString("mandatory"),
            field: "mandatory",
            valueFormatter: this.yesNoFormatter,
        },
    ]

    @Bind
    private osTooltipValueGetter(params: ITooltipParams): string {
        const { colDef, data, value } = params

        const { columnOs } = (colDef as ColDef).cellRendererParams

        const platform = value.find(
            (app: AppPlatform) => app.os.toLowerCase() === columnOs.toLowerCase()
        )

        if (!platform) return ""

        const osKey = osDictionary[columnOs as RelevantOs]
        const osString = this.localizationService.getString(osKey)

        return this.localizationService.getString("doesApplyToDevices", data.name, osString)
    }

    @Bind
    private onGridReady(event: GridReadyEvent): void {
        this.gridApi = event.api
        this.fetchData()
    }

    @Bind
    private onSearchChange(value: string): void {
        if (this.gridApi) {
            this.gridApi.setQuickFilter(value)
        }
    }

    @Bind
    private yesNoFormatter(params: ValueFormatterParams): string {
        return this.localizationService.getString(params.value ? "yes" : "no")
    }

    @Bind
    private onAddApp(): void {
        this.props.history.push(ROUTE.APPLICATION_CHECK_ADD)
    }

    @Bind
    private openExclusionModal(): void {
        this.modalService
            .open(
                this.localizationService.getString("editApplicableDeviceOwnerships"),
                {
                    component: EditApplicableDeviceOwnershipsModalContent,
                    props: {
                        initial: this.state.appliedDevices,
                    },
                    withoutBody: true,
                },
                null
            )
            .onClose(this.fetchData)
    }

    @Bind
    private fetchData(): void {
        this.setState({ appliedDevices: null })
        this.settingsApi.getPreferredApps()
        if (this.gridApi) {
            this.gridApi.showLoadingOverlay()
            this.settingsService.getPreferredApps().then((apps: PreferredApp[]) => {
                this.gridApi.setRowData(apps)
                this.gridApi.hideOverlay()
            })
        }
        // look up the exclusions
        this.trustFactorService
            .getAppliedDevicesForTrustFactor(TrustFactorName.PREFERRED_APPS)
            .then((appliedDevices) => {
                this.setState({
                    appliedDevices,
                })
            })
    }
}

type RelevantOs = OperatingSystem.MACOS | OperatingSystem.UBUNTU | OperatingSystem.WINDOWS

const osDictionary: Record<RelevantOs, LanguageKey> = {
    [OperatingSystem.MACOS]: "macOS",
    [OperatingSystem.UBUNTU]: "linux",
    [OperatingSystem.WINDOWS]: "windows",
}
