import React from "react"

const defaultElement: React.ElementType<React.HTMLAttributes<HTMLDivElement>> = "div"

export type Props<E extends React.ElementType = typeof defaultElement> =
    React.ComponentPropsWithoutRef<E> & {
        as?: E
    }

function BoxComponent<E extends React.ElementType = typeof defaultElement>(
    props: Props<E>,
    ref: any
): JSX.Element {
    const { as: Element = defaultElement, ...restProps } = props
    return <Element {...restProps} ref={ref} />
}

export const Box = React.forwardRef(BoxComponent) as <
    E extends React.ElementType = typeof defaultElement,
>(
    props: Props<E> & { ref?: any }
) => JSX.Element
