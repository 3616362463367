import * as React from "react"
import classNames from "classnames"
import styles from "./SimpleTableWithHeader.module.scss"
import { KeyValuePair } from "../../utils/AgGrid.util"
import { LocalizationService, useServiceLocalization } from "../../services"

interface props {
    className?: string
    keyHeader?: string
    valueHeader?: string
    items: KeyValuePair[]
}

export function SimpleTableWithHeader({ className, keyHeader, valueHeader, items }: props) {
    const ls: LocalizationService = useServiceLocalization()
    return (
        <>
            <table className={classNames(styles.table, className)}>
                <tbody>
                    <tr>
                        <th>{keyHeader ? keyHeader : ls.getString("key")}</th>
                        <th>{valueHeader ? valueHeader : ls.getString("value")}</th>
                    </tr>
                    {items.length > 0 ? (
                        items.map((item) => (
                            <tr key={item.key}>
                                <td>
                                    <div>{item.key}</div>
                                </td>
                                <td>
                                    <div>{item.value}</div>
                                </td>
                            </tr>
                        ))
                    ) : (
                        <tr className={styles.noData}>
                            <td colSpan={2}>{ls.getString("noRowstoShow")}</td>
                        </tr>
                    )}
                </tbody>
            </table>
        </>
    )
}
