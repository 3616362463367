import React from "react"
import { Link } from "react-router-dom"

import styles from "./NavigationTabBar.module.scss"

export interface NavigationTabBarProps<Tab> {
    tabs: Tab[]
    getTabProps(tab: Tab): TabProps
}

export interface TabProps {
    key: React.Key
    label: string
    to: string
    active?: boolean
}

export function NavigationTabBar<Tab>(props: NavigationTabBarProps<Tab>): JSX.Element {
    const { tabs } = props

    return (
        <nav className={styles.navigationTabBar}>
            <ul>
                {tabs.map((tab) => (
                    <NavigationTab {...props.getTabProps(tab)} />
                ))}
            </ul>
        </nav>
    )
}

function NavigationTab(props: TabProps): JSX.Element {
    const { label, to, active } = props

    return (
        <li>
            <Link className={styles.tab} to={to} aria-current={active ? "page" : false}>
                {label}
            </Link>
        </li>
    )
}
