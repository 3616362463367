import { faSpinner } from "@fortawesome/pro-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import classNames from "classnames/bind"
import React, { forwardRef, ForwardedRef } from "react"
import { ButtonBase, Props as ButtonBaseProps } from "../button/ButtonBase.component"

import { Link, LinkProps } from "../link/Link.component"
import styles from "./ButtonLink.module.scss"

export interface Props extends LinkProps, ButtonBaseProps<typeof Link> {}

function ButtonLinkComponent(props: Props, ref: ForwardedRef<HTMLAnchorElement>): JSX.Element {
    const { children, className, loading, ...buttonLinkProps } = props

    return (
        <ButtonBase
            {...buttonLinkProps}
            className={classNames(className, styles.button)}
            as={Link}
            ref={ref}
        >
            {loading && <FontAwesomeIcon spin icon={faSpinner} />}
            {children}
        </ButtonBase>
    )
}

export const ButtonLink = forwardRef(ButtonLinkComponent)
