import { IconDefinition } from "@fortawesome/fontawesome-common-types"
import { faSpinner } from "@fortawesome/pro-regular-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import classNames from "classnames/bind"
import * as React from "react"
import styles from "./OnboardingButton.module.scss"

interface OnboardingButtonProps {
    className?: string
    error?: boolean
    loading?: boolean
    type?: "button" | "submit" | "reset"
    icon?: IconDefinition
    disabled?: boolean
    children: React.ReactNode
    setup?: boolean
    textSetup?: boolean
}

export function OnboardingButton({
    icon,
    loading,
    disabled,
    className,
    children,
    type,
    setup,
    textSetup,
}: OnboardingButtonProps) {
    return (
        <>
            <button
                className={classNames(
                    styles.button,
                    className,
                    { [styles.setup]: setup },
                    { [styles.textSetup]: textSetup }
                )}
                type={type || "button"}
                disabled={disabled}
            >
                {loading && (
                    <span className={styles.icon}>
                        <FontAwesomeIcon spin icon={faSpinner} />
                    </span>
                )}
                {!loading && icon && <FontAwesomeIcon className={styles.icon} icon={icon} />}
                {children}
            </button>
        </>
    )
}
