import { faExclamation } from "@fortawesome/pro-solid-svg-icons"
import { Tooltip } from "@mui/material"
import classNames from "classnames"
import React from "react"

import {
    Button,
    ButtonElement,
    ButtonType,
    IconType,
} from "../../../../components/button/Button.component"
import { Switch } from "../../../../v3/components/switch/Switch.component"
import {
    ErrorBanner,
    LoadMask,
    LargeMessage,
    Form,
    FormColumns,
    FormLabel,
    FormSection,
} from "../../../components"
import { ServiceType } from "../../../services/Manage.service"
import { ServicesAddPolicy } from "../add/policy/ServicesAddPolicy.component"
import { ServicesAddSaasAuthenticator } from "../add/saas-authenticator/ServicesAddSaasAuthenticator.component"
import { SaasEditDeleteDialog } from "../service-form/dialog-delete/SaasEditDeleteDialog.component"
import { SaasEditStatusDialog } from "../service-form/dialog-status/SaasEditStatusDialog.component"
import styles from "./ServicesSaasEdit.module.scss"
import { PageHeading } from "../../../../components/page-heading/PageHeading.component"
import { Input } from "../../../../v3/components/input/Input.component"

export default function () {
    const title = this.state.isIdpRouted
        ? this.localizationService.getString("editIdpRoutedService")
        : this.localizationService.getString("editBanyanFederatedSaasService")

    return (
        <div className={styles.pagePadded}>
            <section aria-labelledby="heading">
                <header className={styles.header}>
                    <PageHeading id="heading">{title}</PageHeading>
                    <Tooltip title={this.localizationService.getString("refresh")}>
                        <Button
                            icon={IconType.REDO}
                            onClick={this.fetchData}
                            asElement={ButtonElement.BUTTON}
                            buttonType={ButtonType.SECONDARY}
                            aria-label={this.localizationService.getString("refresh")}
                        />
                    </Tooltip>
                </header>
                {this.state.loading && <LoadMask />}
                {!this.state.service && !this.state.loading && (
                    <LargeMessage icon={faExclamation} className={styles.missingMessage}>
                        {this.localizationService.getString("appNotFound")}
                    </LargeMessage>
                )}
                {this.state.service && (
                    <>
                        <Form onSubmit={this.onEditService}>
                            <button
                                ref={(c) => (this.submitButton = c)}
                                type="submit"
                                className={styles.hiddenSubmitButton}
                            ></button>

                            <FormSection
                                title={this.localizationService.getString("serviceDetails")}
                            >
                                <FormLabel
                                    title={this.localizationService.getString(
                                        this.state.isIdpRouted
                                            ? "idpRoutedServiceName"
                                            : "saasApplicationName"
                                    )}
                                    htmlFor="name"
                                >
                                    <Input
                                        className={styles.formInput}
                                        disabled
                                        required
                                        defaultValue={this.state.service.name}
                                        placeholder={this.localizationService.getString("name")}
                                    />
                                </FormLabel>
                                <FormLabel
                                    title={this.localizationService.getString("description")}
                                    htmlFor="serviceDescription"
                                >
                                    <Input
                                        className={styles.formInput}
                                        defaultValue={this.state.service.description}
                                        placeholder={this.localizationService.getString(
                                            "description"
                                        )}
                                        name="serviceDescription"
                                        id="serviceDescription"
                                    />
                                </FormLabel>
                                <FormLabel
                                    title={this.localizationService.getString("status")}
                                    htmlFor="status"
                                >
                                    <Input
                                        disabled
                                        className={styles.formInput}
                                        value={
                                            this.state.service.enabled
                                                ? this.localizationService.getString("enabled")
                                                : this.localizationService.getString("disabled")
                                        }
                                    />
                                </FormLabel>
                                <FormColumns
                                    right={
                                        <div className={styles.buttons}>
                                            <Button
                                                type="button"
                                                className={styles.statusButton}
                                                onClick={this.openStatusDialog}
                                                asElement={ButtonElement.BUTTON}
                                                buttonType={ButtonType.SECONDARY}
                                            >
                                                {this.state.service.enabled
                                                    ? this.localizationService.getString("disable")
                                                    : this.localizationService.getString("enable")}
                                            </Button>
                                            {this.state.service.enabled ||
                                            this.state.service.policyId ? (
                                                <Tooltip
                                                    title={this.localizationService.getString(
                                                        "serviceDeleteDisabledDescription"
                                                    )}
                                                    placement="right"
                                                >
                                                    <span style={{ display: "inline-block" }}>
                                                        <Button
                                                            type="button"
                                                            disabled
                                                            asElement={ButtonElement.BUTTON}
                                                            buttonType={ButtonType.PRIMARY}
                                                        >
                                                            {this.localizationService.getString(
                                                                "delete"
                                                            )}
                                                        </Button>
                                                    </span>
                                                </Tooltip>
                                            ) : (
                                                <Button
                                                    type="button"
                                                    onClick={this.openDeleteDialog}
                                                    asElement={ButtonElement.BUTTON}
                                                    buttonType={ButtonType.PRIMARY}
                                                >
                                                    {this.localizationService.getString("delete")}
                                                </Button>
                                            )}
                                        </div>
                                    }
                                />
                            </FormSection>
                            <FormSection
                                title={this.localizationService.getString(
                                    "authenticationFederation"
                                )}
                            >
                                <ServicesAddSaasAuthenticator
                                    initialValue={this.state.service}
                                    onChange={this.onAuthenticatorChange}
                                    idpRouted={this.state.isIdpRouted}
                                />
                            </FormSection>
                            {(this.state.asyncAuthState || this.state.isIdpRouted) && (
                                <FormSection
                                    title={this.localizationService.getString(
                                        "advancedConfigurationOptional"
                                    )}
                                >
                                    {this.state.asyncAuthState && (
                                        <FormLabel
                                            inline={false}
                                            title={this.localizationService.getString(
                                                "suppressDeviceTrustVerification"
                                            )}
                                            tooltip={this.localizationService.getString(
                                                "suppressDeviceTrustVerificationDescription"
                                            )}
                                            htmlFor="suppressDeviceTrustVerification"
                                        >
                                            <Switch
                                                className={styles.pageBreakSwitch}
                                                value={this.state.suppressDTVFlag}
                                                onChange={this.onChange}
                                            />
                                        </FormLabel>
                                    )}
                                    {this.state.isIdpRouted && (
                                        <FormLabel
                                            inline={false}
                                            title={this.localizationService.getString(
                                                "passwordlessAuthentication"
                                            )}
                                            tooltip={this.localizationService.getString(
                                                "passwordlessAuthenticationDescription"
                                            )}
                                            htmlFor="passwordlessAuthentication"
                                        >
                                            <Switch
                                                className={styles.pageBreakSwitch}
                                                value={this.state.passwordlessAuthentication}
                                                onChange={this.onPasswordlessAuthChange}
                                            />
                                        </FormLabel>
                                    )}
                                </FormSection>
                            )}
                            <FormSection title={this.localizationService.getString("attachPolicy")}>
                                <ServicesAddPolicy
                                    onChange={this.onPolicyChange}
                                    serviceType={ServiceType.WEB_USER}
                                    initialPolicyId={this.state.service.policyId}
                                    initialEnforcing={this.state.service.policyEnabled}
                                />
                            </FormSection>
                            <FormSection
                                className={classNames(styles.submitSection, styles.buttons)}
                            >
                                {this.state.error && (
                                    <ErrorBanner className={styles.errorBanner}>
                                        {this.state.error}
                                    </ErrorBanner>
                                )}
                                <Button
                                    className={styles.cancelButton}
                                    onClick={this.onCancel}
                                    asElement={ButtonElement.BUTTON}
                                    buttonType={ButtonType.SECONDARY}
                                >
                                    {this.localizationService.getString("cancel")}
                                </Button>
                                <Button
                                    onClick={this.onSubmitForm}
                                    loading={this.state.submitting}
                                    asElement={ButtonElement.BUTTON}
                                    buttonType={ButtonType.PRIMARY}
                                >
                                    {this.localizationService.getString("save")}
                                </Button>
                            </FormSection>
                            <SaasEditStatusDialog
                                service={this.state.service}
                                open={this.state.statusDialogOpen}
                                onClose={this.closeStatusDialog}
                            />
                            <SaasEditDeleteDialog
                                service={this.state.service}
                                open={this.state.deleteDialogOpen}
                                onClose={this.closeDeleteDialog}
                            />
                        </Form>
                    </>
                )}
            </section>
        </div>
    )
}
