import React from "react"

import { Input } from "../../../../v3/components/input/Input.component"
import { Button, ButtonElement, ButtonType } from "../../../../components/button/Button.component"
import { AppText } from "../../../../v3/components/app-text/AppText.component"
import {
    SectionScreen,
    Form,
    FormRow,
    FormLabel,
    ErrorBanner,
    FormColumns,
} from "../../../components"
import { ConfirmationDialog } from "../../../components/dialog/confirmation/ConfirmationDialog.component"
import { OrgEdition } from "../../../services/User.service"
import styles from "./OidcSettings.module.scss"

export default function () {
    return (
        <>
            <Form display="grid" floating labelWidth={200}>
                {this.state.oidcConfig && (
                    <>
                        <FormRow className={styles.formRow}>
                            <h2>{this.localizationService.getString("openIdConnectSettings")}</h2>
                            <AppText
                                ls={{
                                    key: "oidcSettingsDescription",
                                    replaceVals: [this.linkService.getLink("passwordlessDocs")],
                                }}
                            />
                        </FormRow>
                        <FormLabel
                            title={this.localizationService.getString("issuerUrl")}
                            htmlFor="issuerUrl"
                        >
                            <Input
                                className={styles.formInput}
                                disabled
                                value={this.state.oidcConfig.issuerUrl}
                            />
                        </FormLabel>
                        <FormLabel
                            title={this.localizationService.getString("authorizationEndpoint")}
                            htmlFor="authorizationEndpoint"
                        >
                            <Input
                                className={styles.formInput}
                                disabled
                                value={this.state.oidcConfig.authorizationEndpoint}
                            />
                        </FormLabel>
                        <FormLabel
                            title={this.localizationService.getString("tokenEndpoint")}
                            htmlFor="tokenEndpoint"
                        >
                            <Input
                                className={styles.formInput}
                                disabled
                                value={this.state.oidcConfig.tokenEndpoint}
                            />
                        </FormLabel>
                        <FormLabel
                            title={this.localizationService.getString("jwksEndpoint")}
                            htmlFor="jwksEndpoint"
                        >
                            <Input
                                className={styles.formInput}
                                disabled
                                value={this.state.oidcConfig.jwksEndpoint}
                            />
                        </FormLabel>
                        <FormLabel
                            title={this.localizationService.getString("userinfoEndpoint")}
                            htmlFor="userinfoEndpoint"
                        >
                            <Input
                                className={styles.formInput}
                                disabled
                                value={this.state.oidcConfig.userinfoEndpoint}
                            />
                        </FormLabel>
                        <FormLabel
                            title={this.localizationService.getString("oidcDiscoveryEndpoint")}
                            htmlFor="oidcDiscoveryEndpoint"
                        >
                            <Input
                                className={styles.formInput}
                                disabled
                                value={this.state.oidcConfig.oidcDiscoveryEndpoint}
                            />
                        </FormLabel>
                    </>
                )}
                {this.state.passwordlessConfig && this.state.edition === OrgEdition.TEAM ? (
                    <>
                        <FormRow className={styles.formRow}>
                            <h2>
                                {this.localizationService.getString(
                                    "appClientForPasswordlessAuthentication"
                                )}
                            </h2>
                        </FormRow>
                        <FormRow className={styles.formRow}>
                            <SectionScreen
                                className={styles.screen}
                                description={this.localizationService.getString(
                                    "passwordlessUpgradeDescription"
                                )}
                            />
                        </FormRow>
                    </>
                ) : (
                    <>
                        <FormRow className={styles.formRow}>
                            <h2>
                                {this.localizationService.getString(
                                    "appClientForPasswordlessAuthentication"
                                )}
                            </h2>
                            <p>{this.localizationService.getString("passwordlessDescription")}</p>
                        </FormRow>
                        {this.state.error && <ErrorBanner>{this.state.error}</ErrorBanner>}
                        {this.state.passwordlessConfig && (
                            <>
                                <FormLabel
                                    title={this.localizationService.getString("appClientName")}
                                    htmlFor="appClientName"
                                >
                                    <Input
                                        className={styles.formInput}
                                        disabled
                                        value={this.state.appClientName}
                                    />
                                </FormLabel>
                                <FormLabel
                                    title={this.localizationService.getString("clientId")}
                                    htmlFor="clientId"
                                >
                                    <Input
                                        className={styles.formInput}
                                        disabled
                                        value={this.state.passwordlessConfig.ClientID}
                                    />
                                </FormLabel>
                                <FormLabel
                                    title={this.localizationService.getString("clientSecret")}
                                    htmlFor="clientSecret"
                                >
                                    <Input
                                        className={styles.formInput}
                                        disabled
                                        value={this.state.passwordlessConfig.ClientSecret}
                                    />
                                </FormLabel>

                                {this.state.temporarilyShowOidcEndpoints && (
                                    <>
                                        <FormLabel
                                            title={this.localizationService.getString("issuerUrl")}
                                            htmlFor="issuerUrl"
                                        >
                                            <Input
                                                className={styles.formInput}
                                                disabled
                                                value={this.state.passwordlessConfig.Issuer}
                                            />
                                        </FormLabel>
                                        <FormLabel
                                            title={this.localizationService.getString(
                                                "authorizationEndpoint"
                                            )}
                                            htmlFor="authorizationEndpoint"
                                        >
                                            <Input
                                                className={styles.formInput}
                                                disabled
                                                value={
                                                    this.state.passwordlessConfig
                                                        .AuthorizationEndpoint
                                                }
                                            />
                                        </FormLabel>
                                        <FormLabel
                                            title={this.localizationService.getString(
                                                "tokenEndpoint"
                                            )}
                                            htmlFor="tokenEndpoint"
                                        >
                                            <Input
                                                className={styles.formInput}
                                                disabled
                                                value={this.state.passwordlessConfig.TokenEndpoint}
                                            />
                                        </FormLabel>
                                        <FormLabel
                                            title={this.localizationService.getString(
                                                "jwksEndpoint"
                                            )}
                                            htmlFor="jwksEndpoint"
                                        >
                                            <Input
                                                className={styles.formInput}
                                                disabled
                                                value={this.state.passwordlessConfig.JWKSEndpoint}
                                            />
                                        </FormLabel>
                                    </>
                                )}

                                <FormLabel
                                    title={this.localizationService.getString("redirectUri")}
                                    htmlFor="redirectUri"
                                >
                                    <Input
                                        className={styles.formInput}
                                        disabled
                                        value={this.state.passwordlessConfig.RedirectURL}
                                    />
                                </FormLabel>
                            </>
                        )}
                        {this.state.passwordlessConfig ? (
                            <FormColumns
                                right={
                                    <Button
                                        className={styles.button}
                                        onClick={this.onShowDeleteDialog}
                                        asElement={ButtonElement.BUTTON}
                                        buttonType={ButtonType.DESTRUCTIVE}
                                    >
                                        {this.localizationService.getString("delete")}
                                    </Button>
                                }
                            />
                        ) : (
                            <>
                                <FormLabel
                                    title={this.localizationService.getString("redirectUri")}
                                    htmlFor="redirectUri"
                                >
                                    <Input
                                        className={styles.formInput}
                                        value={this.state.passwordlessRedirectUri}
                                        onChange={this.onRedirectUrlChange}
                                        disabled={!this.props.canCreatePasswordLessAuth}
                                    />
                                </FormLabel>
                                {this.props.canCreatePasswordLessAuth && (
                                    <FormColumns
                                        right={
                                            <Button
                                                className={styles.button}
                                                asElement={ButtonElement.BUTTON}
                                                buttonType={ButtonType.PRIMARY}
                                                onClick={this.enablePasswordless}
                                                loading={this.state.loading}
                                                disabled={
                                                    !this.state.passwordlessRedirectUri.length
                                                }
                                            >
                                                {this.localizationService.getString("create")}
                                            </Button>
                                        }
                                    />
                                )}
                            </>
                        )}
                    </>
                )}
                {this.state.externalClientId && (
                    <>
                        <FormRow className={styles.formRow}>
                            <h2>
                                {this.localizationService.getString("appClientForExternalServices")}
                            </h2>
                        </FormRow>
                        <FormLabel
                            title={this.localizationService.getString("appClientName")}
                            htmlFor="appClientName"
                        >
                            <Input
                                className={styles.formInput}
                                disabled
                                value={this.state.externalAppName}
                            />
                        </FormLabel>
                        <FormLabel
                            title={this.localizationService.getString("clientId")}
                            htmlFor="clientId"
                        >
                            <Input
                                className={styles.formInput}
                                disabled
                                value={this.state.externalClientId}
                            />
                        </FormLabel>
                    </>
                )}
            </Form>
            <ConfirmationDialog
                open={this.state.showDeleteDialog}
                onClose={this.onHideDeleteDialog}
                onConfirm={this.deletePasswordless}
                title={this.localizationService.getString("deletePasswordless")}
            >
                {this.localizationService.getString("deletePasswordlessDescription")}
            </ConfirmationDialog>
        </>
    )
}
