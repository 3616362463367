import React from "react"
import styles from "./Badge.module.scss"
import classNames from "classnames/bind"
import { IconDefinition } from "@fortawesome/fontawesome-common-types"
import { Icon } from "../Icon/Icon.component"

export function Badge(props: BadgeProps) {
    return (
        <div className={classNames(styles.container, props.containerClassName)} style={props.style}>
            <div
                className={classNames(
                    props.className,
                    styles.circle,
                    { [styles.success]: props.success },
                    { [styles.warning]: props.warning },
                    { [styles.error]: props.error },
                    { [styles.brand]: props.brand },
                    { [styles.disabled]: props.disabled },
                    { [styles.loading]: props.loading }
                )}
            >
                {props.icon ? (
                    <Icon className={styles.icon} icon={props.icon} spin={props.loading} />
                ) : props.text ? (
                    <div>{props.text}</div>
                ) : (
                    <div>{props.number}</div>
                )}
            </div>
        </div>
    )
}

export interface BadgeProps {
    brand?: boolean
    success?: boolean
    warning?: boolean
    error?: boolean
    disabled?: boolean
    style?: React.CSSProperties
    className?: string
    containerClassName?: string
    number?: number
    text?: string
    icon?: IconDefinition
    loading?: boolean
}
