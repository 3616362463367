import { ROUTE } from "../../routes"
import { ApiResponse, BaseApi, MessageRes } from "./Base.api"
import { Singleton } from "../decorators/Singleton.decorator"
import { OrgEdition } from "../services"

@Singleton("UserApiV1")
export class UserApi extends BaseApi {
    public getUserOrgDetails(): Promise<UserOrgDetails> {
        return this.get("/api/v1/user_org_details")
    }

    public getOrgDetails(): Promise<OrgDetails> {
        return this.get("/api/v1/org_details")
    }

    public getSamlConfig(orgId: string): Promise<SamlConfig> {
        return this.get("/api/v1/admin/saml?OrgID=" + orgId)
    }

    public deleteIDPConfig(): Promise<OrgRes> {
        return this.postForm("/api/v1/update_org", {
            DeviceRegIDPName: DeviceRegistrationProviderType.NONE,
        })
    }

    public getUserMetaData(): Promise<ApiResponse<UserMetaDataReq>> {
        return this.get("/api/experimental/v2/auth_user_metadata")
    }

    public deleteUserMetaData(keys: string[] = []): Promise<ApiResponse<UserMetaDataReq>> {
        return this.delete("/api/experimental/v2/auth_user_metadata", keys)
    }

    public insertUserState(domainName: UserMetaDataReq): Promise<void> {
        return this.put("/api/experimental/v2/auth_user_metadata", domainName)
    }

    public updateIDPConfig(
        userOrgIDPDetails?: UserOrgIDPDetails,
        orgDeviceIDPDetails?: OrgDeviceIDPDetails
    ): Promise<OrgRes> {
        let idp: {} = {}
        if (userOrgIDPDetails) {
            idp = {
                ...idp,
                IDPName: userOrgIDPDetails.IDPName,
                IDPProtocol: userOrgIDPDetails.IDPProto,
                IDPConfig: JSON.stringify(userOrgIDPDetails.IDPConfig),
            }
        }
        if (orgDeviceIDPDetails) {
            idp = {
                ...idp,
                DeviceRegIDPName: orgDeviceIDPDetails.DeviceRegIDPName,
                DeviceRegIDPProtocol: orgDeviceIDPDetails.DeviceRegIDPProto,
                DeviceRegConfig: JSON.stringify(orgDeviceIDPDetails.DeviceRegIDPConfig),
            }
        }
        return this.postForm("/api/v1/update_org", idp)
    }

    public updateDeviceManagerConfig(
        userOrgDeviceManagerDetails: UserOrgDeviceManagerDetails
    ): Promise<OrgRes> {
        return this.postForm("/api/v1/update_org", {
            MDMName: userOrgDeviceManagerDetails.MDMName,
            MDMConfig: JSON.stringify(userOrgDeviceManagerDetails.MDMConfig),
        })
    }

    public updateIdpType(idpType: string): Promise<MessageRes> {
        return this.postForm("/api/v1/update_org", { IDPType: idpType })
    }

    public updateTrustConfig(trustConfig: TrustConfig): Promise<MessageRes> {
        return this.postForm("/api/v1/update_org", { TrustConfig: JSON.stringify(trustConfig) })
    }

    public updateRootCert(rootCert: string): Promise<MessageRes> {
        return this.postForm("/api/v1/update_org", { RootCerts: rootCert })
    }

    public setupSaml(orgID: string, samlConfig: SamlConfig): Promise<MessageRes> {
        return this.postForm("/api/v1/admin/setup_saml?OrgID=" + orgID, samlConfig)
    }

    public getInviteCode(): Promise<InviteCodeConfig> {
        return this.get("/api/v1/invite_code")
    }

    public setInviteCode(inviteCode: string): Promise<InviteCodeConfig> {
        return this.post("/api/v1/invite_code", { invite_code: inviteCode })
    }

    public getAdmins(): Promise<Admin[]> {
        return this.get("/api/v1/admin/list_users")
    }

    public getEndUsersCSV(): Promise<Blob> {
        return this.getBlob("/api/v1/endusers/csv?active=true")
    }

    public updateAdmin(email: string, profile: string, orgName: string): Promise<MessageRes> {
        return this.postForm("/api/v1/admin/update_user", {
            Email: email,
            Profile: profile,
            OrgName: orgName,
        })
    }

    public deleteAdmin(email: string): Promise<MessageRes> {
        return this.delete("/api/v1/admin/delete_user?Email=" + encodeURIComponent(email))
    }

    public addAdmin(admin: AdminReq): Promise<MessageRes> {
        return this.postForm("/api/v1/admin/add_user", {
            ...admin,
            APIServerLocation: window.location.origin + ROUTE.LOGIN_RESET,
        })
    }

    public setCloakExceptions(cloakExceptions: CloakExceptions): Promise<MessageRes> {
        return this.postForm("/api/v1/update_org", {
            CloakExceptions: JSON.stringify(cloakExceptions),
        })
    }

    public setUserInactivityInterval(days: string): Promise<MessageRes> {
        return this.postForm("/api/v1/update_org", {
            EnduserInactivityThresholdDays: days,
        })
    }

    public updateAuthAsyncState(state: boolean): Promise<MessageRes> {
        return this.postForm("/api/v1/update_org", { EnableAsyncAuth: state })
    }
}

interface UserMetaDataReq {
    onboardingServiceType?: string
    onboardingStep?: number
    onboardingConnectorIP?: string
    onboardingService?: string
    onboardingFinished?: boolean
    state?: string
}

export interface UserOrgDetails {
    AccessHistoryHours: number
    AccountType?: string
    CIRegistered: string
    CloakExceptions: string
    ID: string
    DeviceRegIDPName: string
    DeviceRegIDPProto: string
    DeviceRegIDPConfig: string
    DNSConfig: string
    DNSName: string
    Email: string
    First: string
    HashedPassword: string
    HasRefreshToken: boolean
    HistoryHours: number
    IDPConfig: string
    IDPName: string
    IDPType: string
    IDPProto?: string
    ImagesAfter: string
    Last: string
    LastNotifyTime: string
    LastUpdatedAt: number
    LastUpdatedBy: string
    MDMConfig: string
    MDMName: string
    MaxPerRepo: number
    NoVPN: string
    Notifications: string
    NotifyCount: number
    OrgID: string
    OrgName: string
    OrgRegisterDate: string
    Profile:
        | "SuperAdmin"
        | "ReadOnly"
        | "Admin"
        | "Owner"
        | "PolicyAuthor"
        | "ServiceAuthor"
        | "MOM"
    PWLessConfig: string
    RefreshToken: string
    RegisterDate: string
    RegisterKey: string
    RootCert: string
    TokenExpiryHours: number
    TrustConfig: string
    // @cspell:ignore Trustprovider
    TrustproviderURL: string
    UserInitials: string
    AsyncAuthEnabled?: boolean
    RefreshTokenForSAMLEnabled?: boolean
    MFAEnabled?: boolean
    IsAdminBanyanIDP: boolean
    IsAIAssistEnabled: boolean
    IsMSPOrg: boolean
    IsSCIMEnabled: boolean
}

export interface OrgDetails {
    OrgName: string
    OrgID: string
    MDMName: string
    MDMConfig: string
    IDPName: string
    IDPConfig: string
    IDPProto?: string
    DeviceRegIDPName?: string
    DeviceRegIDPProto?: string
    DeviceRegIDPConfig?: string
    DNSName: string
    DNSConfig: string
    TrustConfig: string
    CACerts: string[]
    CAChain: string
    AsyncAuthEnabled: boolean
    IsTestDrive: boolean
    Edition: OrgEdition
    EnduserInactivityThresholdDays: string
}

export interface UserOrgIDPDetails {
    IDPName: string
    IDPProto?: string
    IDPConfig: IDPConfig
}

export interface OrgDeviceIDPDetails {
    DeviceRegIDPName: string
    DeviceRegIDPProto: string
    DeviceRegIDPConfig: IDPConfig
}

export interface SPCertificateInfo {
    Certificate: string
    Expires: number
    Issuer: string
}
export interface SamlConfig {
    CallbackURL: string
    IDPIssuerURL: string
    MetadataURL: string
    RawMetadata: string
    SPMetadataURL: string
    SPCertificateInfo?: SPCertificateInfo
    SPIssuer: string
}

export interface IDPConfig {
    IssuerURL?: string
    ClientID?: string
    ClientSecret?: string
    RedirectURL?: string
    AccessKeyId?: string
    SecretAccessKey?: string
    UserPoolId?: string
    UserPoolDomain?: string
    // @cspell:ignore SSOURL
    SSOURL?: string
    CAData?: string
    UsernameAttr?: string
    EmailAttr?: string
    GroupsAttr?: string
    GroupsDelim?: string
    EntityIssuer?: string
    PWLessConfig?: string
    DeviceRegClientID?: string
    DeviceRegClientSecret?: string
}

export interface TrustConfig {
    LoginTokenExpiry: number
    RegistrationTokenExpiry: number
    ReportingTokenExpiry: number
    RenewalTokenExpiry: number
    ExternalTokenExpiry: number
}

export interface PasswordlessConfig {
    ClientID?: string
    ClientSecret?: string
    Issuer?: string
    AuthorizationEndpoint?: string
    TokenEndpoint?: string
    JWKSEndpoint?: string
    RedirectURL?: string
    DeviceRegClientID?: string
    DeviceRegClientSecret?: string
}

export interface UserOrgDeviceManagerDetails {
    MDMName: string
    MDMConfig: MDMConfig
}

export interface MDMConfig {
    Config?: string
    URL?: string
    Username?: string
    Password?: string
    APIkey?: string
    RootCA?: string
    CNFormat?: string
    FailOpen?: boolean
}

export interface InviteCodeConfig {
    command_center_url: string
    invite_code: string
    org_id: string
}

export interface AdminReq {
    Email: string
    First: string
    Last: string
    Profile: string
}

export interface Admin {
    Email: string
    First: string
    Last: string
    FullName: string
    Profile: string
    Registered?: string
    AccountType?: AccountType
}

enum AccountType {
    SAML = "SAML",
    LOCAL = "LOCAL",
}

export enum IDPName {
    UNSET = "",
    DEFAULT = "DEFAULT",
    OKTA = "OKTA",
    SAML = "SAML",
    COGNITO = "COGNITO",
    ONE_LOGIN = "ONE LOGIN",
    OTHER = "OTHER",
}

export enum IDPProtocol {
    UNSET = "",
    OIDC = "OIDC",
    SAML = "SAML",
}

export enum DeviceRegistrationProviderType {
    NONE = "NONE",
    OIDC = "OIDC",
    SAML = "SAML",
}

export enum DeviceManagerName {
    NONE = "NONE",
    AIRWATCH = "AIRWATCH",
    // @cspell:ignore CERTONLY
    CERT_ONLY = "CERTONLY",
}

export interface CloakExceptions {
    token: {
        whitelist_cidrs: string[]
    }
    no_token: {
        whitelist_cidrs: string[]
        status_code: number
        message: string
    }
}

interface OrgRes {
    Message: string
}
