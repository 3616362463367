import React from "react"
import { useServiceLocalization } from "../../../pre-v3/services/localization/Localization.service"
import { Column, Grid } from "../../../v3/components/grid/Grid.component"
import { SearchInput } from "../../../v3/components/search-input/SearchInput.component"
import { StringUtil } from "../../../pre-v3/utils/String.util"
import {
    mapToSummaryServiceTunnel,
    ServiceTunnelDetail,
} from "../../../v3/services/ServiceTunnelV2.service"

import styles from "./SummaryTable.module.scss"
import { Checkbox } from "../../../v3/components/checkbox/Checkbox.component"

interface Props {
    serviceTunnel: ServiceTunnelDetail
}

export function SummaryTable(props: Props): JSX.Element {
    const columns = useColumns()
    const localization = useServiceLocalization()
    const [searchText, setSearchText] = React.useState("")
    const [filter, setFilter] = React.useState({
        include: false,
        exclude: false,
    })

    const filteredSummary = React.useMemo(() => {
        const summary = mapToSummaryServiceTunnel(props.serviceTunnel, localization)
        if (!searchText && !filter.include && !filter.exclude) {
            return summary
        }

        const filteredSummary = summary.filter((networkSummary) => {
            return (
                !(filter.include && !networkSummary.isInclude) &&
                !(filter.exclude && networkSummary.isInclude)
            )
        })

        return filteredSummary.filter(
            (serviceTunnel) =>
                StringUtil.caseInsensitiveIncludes(serviceTunnel.domainsAppsIps, searchText) ||
                StringUtil.caseInsensitiveIncludes(serviceTunnel.network, searchText) ||
                StringUtil.caseInsensitiveIncludes(serviceTunnel.type, searchText)
        )
    }, [searchText, filter, props.serviceTunnel])

    const onChangeInclude = (selected: boolean) => {
        setFilter({ exclude: false, include: selected })
    }

    const onChangeExclude = (selected: boolean) => {
        setFilter({ include: false, exclude: selected })
    }

    return (
        <div className={styles.summaryTableWrapper}>
            <div className={styles.header}>
                <div className={styles.title}>{localization.getString("summary")}</div>
            </div>

            <div className={styles.toolbar}>
                <SearchInput
                    value={searchText}
                    onChangeValue={setSearchText}
                    placeholder={localization.getString("search")}
                />
                <Checkbox checked={filter.include} onChange={onChangeInclude}>
                    {localization.getString("include")}
                </Checkbox>
                <Checkbox checked={filter.exclude} onChange={onChangeExclude}>
                    {localization.getString("exclude")}
                </Checkbox>
            </div>

            <Grid
                data={filteredSummary}
                columns={columns}
                getId={getId}
                className={styles.summaryTable}
            />
        </div>
    )
}

interface SummaryServiceTunnel {
    domainsAppsIps: string
    type: string // for sorting
    network: string
    isInclude: boolean
}

function useColumns(): Column<SummaryServiceTunnel>[] {
    const localization = useServiceLocalization()
    const includeLabel = localization.getString("include")
    const excludeLabel = localization.getString("exclude")

    return React.useMemo((): Column<SummaryServiceTunnel>[] => {
        const domainsAppsIps: Column<SummaryServiceTunnel> = {
            id: "domainsAppsIps",
            name: localization.getString("domainsAppsIps"),
            cellRenderer: (summaryServiceTunnel) => summaryServiceTunnel.domainsAppsIps,
            getTooltipValue: (summaryServiceTunnel) => summaryServiceTunnel.domainsAppsIps,
            width: 150,
        }

        const includeExclude: Column<SummaryServiceTunnel> = {
            id: "type",
            name: localization.getString("includeExclude"),
            cellRenderer: (summaryServiceTunnel) =>
                summaryServiceTunnel.isInclude ? includeLabel : excludeLabel,
            getTooltipValue: (summaryServiceTunnel) =>
                summaryServiceTunnel.isInclude ? includeLabel : excludeLabel,
            width: 120,
        }

        const network: Column<SummaryServiceTunnel> = {
            id: "network",
            name: localization.getString("network"),
            cellRenderer: (summaryServiceTunnel) => summaryServiceTunnel.network || "-",
            getTooltipValue: (summaryServiceTunnel) => summaryServiceTunnel.network,
            width: 150,
        }

        return [domainsAppsIps, includeExclude, network]
    }, [])
}

function getId(summary: SummaryServiceTunnel): string {
    return `${summary.domainsAppsIps}-${summary.type}-${summary.network}`
}
