import React from "react"
import { Redirect, useHistory, useLocation } from "react-router-dom"

import { useAuthService } from "../../../../pre-v3/services/Auth.service"
import { useServiceLocalization } from "../../../../pre-v3/services/localization/Localization.service"
import { ROUTE } from "../../../../routes"
import { ErrorBanner } from "../../../components/banner/Banner.component"
import { Button, ButtonElement, ButtonType } from "../../../../components/button/Button.component"
import { OldInput } from "../../../components/old-input/OldInput.component"
import { Link } from "../../../components/link/Link.component"
import { PasswordInput } from "../../../components/password-input/PasswordInput.component"
import { LoginPayload, useLogin } from "../../../services/Login.service"
import styles from "../Login.module.scss"
import { RouteUtils } from "../Route.utils"
import { SwitchOrgMessage } from "../SwitchOrgMessage.component"

enum LocalAccountFormState {
    INITIAL = "INITIAL",
    LOADING = "LOADING",
    ERROR = "ERROR",
}

export function LocalAccountForm(): JSX.Element {
    const authService = useAuthService()
    const login = useLogin()

    const history = useHistory()
    const location = useLocation()
    const localization = useServiceLocalization()

    const [state, setState] = React.useState(LocalAccountFormState.INITIAL)
    const [emailAddress, setEmailAddress] = React.useState("")
    const [password, setPassword] = React.useState("")

    const orgName = RouteUtils.getOrgNameFromUrl(location)

    if (!orgName) return <Redirect to={ROUTE.ORG_FORM} />

    const onSubmit: React.FormEventHandler<HTMLFormElement> = async (event) => {
        event.preventDefault()
        setState(LocalAccountFormState.LOADING)

        try {
            const loginPayload: LoginPayload = await login.mutateAsync({
                orgName,
                emailAddress,
                password,
            })

            switch (loginPayload.next) {
                case "configure_mfa":
                    history.push(ROUTE.LOCAL_ACCOUNT_CONFIGURE_MFA, {
                        token: loginPayload.token,
                    })
                    break
                case "mfa":
                    history.push(ROUTE.LOCAL_ACCOUNT_MFA, { token: loginPayload.token })
                    break
                default: {
                    authService.setLogin(loginPayload.token)
                    history.push(authService.getLoginUrl())
                }
            }
        } catch (_error) {
            setState(LocalAccountFormState.ERROR)
        }
    }

    const isSubmitLoading = state === LocalAccountFormState.LOADING
    const isErrorShown = state === LocalAccountFormState.ERROR

    const emailAddressLabel = localization.getString("emailAddress")
    const passwordLabel = localization.getString("password")

    return (
        <React.Fragment>
            <p className={styles.textAlignCenter}>{orgName}</p>
            {isErrorShown && (
                <ErrorBanner className={styles["space-below"]}>
                    {localization.getString("theEmailAddressOrPasswordYouHaveEnteredIsIncorrect")}
                </ErrorBanner>
            )}
            <form onSubmit={onSubmit} className={styles.form}>
                <OldInput
                    type="email"
                    value={emailAddress}
                    onChangeValue={setEmailAddress}
                    required
                    autoFocus
                    aria-label={emailAddressLabel}
                    placeholder={emailAddressLabel}
                    className={styles["space-below"]}
                />
                <PasswordInput
                    value={password}
                    onChangeValue={setPassword}
                    required
                    aria-label={passwordLabel}
                    placeholder={passwordLabel}
                    className={styles["space-below"]}
                />
                <Button
                    asElement={ButtonElement.BUTTON}
                    buttonType={ButtonType.PRIMARY}
                    className={styles.submitButton}
                    type="submit"
                    loading={isSubmitLoading}
                >
                    {localization.getString("login")}
                </Button>
            </form>
            <p className={styles.textAlignCenter}>
                <Link to={RouteUtils.appendOrgToRoute(ROUTE.RESET_PASSWORD_FORM, orgName)}>
                    {localization.getString("forgotYourPassword")}
                </Link>
            </p>
            <SwitchOrgMessage />
        </React.Fragment>
    )
}
