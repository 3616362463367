import React from "react"

import { LoadMask } from "../../../../../../../pre-v3/components/load-mask/LoadMask.component"
import { LanguageKey } from "../../../../../../../pre-v3/services/localization/languages/en-US.language"
import { useServiceLocalization } from "../../../../../../../pre-v3/services/localization/Localization.service"
import { AppText } from "../../../../../../components/app-text/AppText.component"
import { ErrorBanner } from "../../../../../../components/banner/Banner.component"
import { Container as ViewContainer } from "../../../../../../components/container/Container.component"
import { TabBar } from "../../../../../../components/tab-bar/TabBar.component"
import { DetailsAndAssignment } from "./DetailsAndAssignment.component"
import { TrustFactors } from "./TrustFactors.component"
import styles from "./TrustProfileCreate.module.scss"
import { useCreateTrustProfile, steps, BaseStep, Step } from "./useCreateTrustProfile.hook"

export function TrustProfileCreate(): JSX.Element {
    const localization = useServiceLocalization()

    const {
        currentStep,
        onFillDetailsAndAssignment,
        onError,
        onGoBackToDetailsAndAssignmentClick,
    } = useCreateTrustProfile()

    if (currentStep.isLoading) {
        return <LoadMask />
    }

    switch (currentStep.step) {
        case Step.DETAILS_AND_ASSIGNMENT:
            return (
                <Container currentStep={currentStep}>
                    <DetailsAndAssignment
                        currentStep={currentStep}
                        onFillDetailsAndAssignment={onFillDetailsAndAssignment}
                        onError={onError}
                    />
                </Container>
            )

        case Step.TRUST_FACTORS:
            return (
                <Container currentStep={currentStep}>
                    <TrustFactors
                        trustProfile={currentStep.profile}
                        onBack={onGoBackToDetailsAndAssignmentClick}
                    />
                </Container>
            )
    }
}

interface ContainerProps {
    currentStep: BaseStep
    children: React.ReactNode
}

function Container(props: ContainerProps): JSX.Element {
    const { currentStep, children } = props

    const localization = useServiceLocalization()

    const getLabel = React.useCallback(
        (step: Step): string => localization.getString(stepLabelDictionary[step]),
        [localization]
    )

    return (
        <ViewContainer>
            {props.currentStep.profileFetchingError && (
                <ErrorBanner className={styles.errorBanner}>
                    {props.currentStep.profileFetchingError}
                </ErrorBanner>
            )}
            <AppText className={styles.details} ls="createTrustProfileDescription" />
            <TabBar
                type="stepper"
                selectedTabId={currentStep.step}
                tabs={steps.map((step) => {
                    return {
                        label: getLabel(step),
                        id: step,
                        ariaControls: `${step}-tab`,
                    }
                })}
            />
            {children}
        </ViewContainer>
    )
}

const stepLabelDictionary: Record<Step, LanguageKey> = {
    [Step.DETAILS_AND_ASSIGNMENT]: "detailsAndAssignment",
    [Step.TRUST_FACTORS]: "trustFactors",
}
