import React, { ReactNode } from "react"
import ServicesSaasOverviewAttrTemplate from "./ServicesSaasOverviewAttr.template"
import { ManageService, SaasManage } from "../../../../services/Manage.service"
import { Bind } from "../../../../decorators/Bind.decorator"
import { LocalizationService } from "../../../../services/localization/Localization.service"
import { SettingsService, OidcConfig } from "../../../../services/Settings.service"

export class ServicesSaasOverviewAttr extends React.Component<
    ServicesSaasOverviewAttrProps,
    ServicesSaasOverviewAttrState
> {
    public state: ServicesSaasOverviewAttrState = { showConfig: false, samlProxyUrl: "" }

    public render(): ReactNode {
        return ServicesSaasOverviewAttrTemplate.call(this)
    }

    public componentDidMount(): void {
        this.settingsService.getOidcSettings().then((oidc: OidcConfig) => {
            let samlProxyUrl
            try {
                const url: URL = new URL(oidc.issuerUrl)
                samlProxyUrl = url.origin + "/v2/saml/proxy"
            } catch {
                samlProxyUrl = ""
            }
            this.setState({ oidc: oidc, samlProxyUrl })
        })
    }

    private localizationService: LocalizationService = new LocalizationService()
    private settingsService: SettingsService = new SettingsService()
    private manageService: ManageService = new ManageService()

    @Bind
    private onToggle(): void {
        this.setState({ showConfig: !this.state.showConfig })
    }
}

interface ServicesSaasOverviewAttrProps {
    app: SaasManage
}

interface ServicesSaasOverviewAttrState {
    showConfig: boolean
    oidc?: OidcConfig
    samlProxyUrl: string
}
