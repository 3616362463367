// external imports
import * as React from "react"
// local imports
import { ServiceFormProps, useAttributes, useSetErrors, useUpdateTags } from "../service-form"
import { FormSection, Select, FormLabel } from "../../../components"
import { SSHServiceType, useServiceLocalization } from "../../../services"
import ServiceAttributeForm from "./attributes"
import styles from "./ServiceForm.module.scss"
import { ServiceTag } from "../../../api/Manage.api"
import { Input } from "../../../../v3/components/input/Input.component"

export default function SSHServiceForm({ edit, hideBackend }: ServiceFormProps) {
    // some state for the form
    const tags = edit?.spec?.metadata.tags
    let initialHost: string = ""
    let initialCert: SSHServiceType = SSHServiceType.TRUSTCERT
    let initialWriteConfig: boolean = true
    if (tags) {
        if (tags[ServiceTag.SSH_HOST_DIRECTIVE]) {
            initialHost = tags[ServiceTag.SSH_HOST_DIRECTIVE]
        }
        if (tags[ServiceTag.SSH_SERVICE_TYPE]) {
            initialCert = SSHServiceType[tags[ServiceTag.SSH_SERVICE_TYPE]]
        }
        if (tags[ServiceTag.WRITE_SSH_CONFIG] === false) {
            initialWriteConfig = tags[ServiceTag.WRITE_SSH_CONFIG]
        }
    }

    const [host, setHost] = React.useState(initialHost)
    const [cert, setCert] = React.useState(initialCert)
    const [writeConfig, setWriteConfig] = React.useState(initialWriteConfig)

    // grab some service references from context
    const localization = useServiceLocalization()

    // which options we show for the select depends on backend attr
    const { backend } = useAttributes()

    // update the parent state
    const updateTags = useUpdateTags()
    const setErrors = useSetErrors()
    React.useEffect(() => {
        updateTags((tags) => {
            const newTags: AnyMap = {
                ...tags,
                [ServiceTag.SSH_SERVICE_TYPE]: cert,
                [ServiceTag.WRITE_SSH_CONFIG]: writeConfig,
                [ServiceTag.SSH_CHAIN_MODE]: Boolean(backend?.http_connect),
                [ServiceTag.SSH_HOST_DIRECTIVE]: host,
            }

            return newTags
        })
    }, [
        host,
        cert,
        writeConfig,
        backend,
        backend?.http_connect,
        localization,
        setErrors,
        updateTags,
    ])

    return (
        <>
            <ServiceAttributeForm
                edit={edit}
                showAllowPatterns
                defaultPort="8443"
                hideBackend={hideBackend}
            />
            <FormSection title={localization.getString("sshDesktopAppSettings")}>
                <FormLabel
                    title={localization.getString("sshHostDirective")}
                    tooltip={localization.getString("sshHostDirectiveDescription")}
                    htmlFor="sshHostDirective"
                >
                    <div className={styles.inputContainer}>
                        <Input
                            className={styles.formInput}
                            onChange={(e) => setHost(e.target.value)}
                            value={host}
                            placeholder={localization.getString("sshHostDirectivePlaceholder")}
                        />
                    </div>
                </FormLabel>
                <FormLabel
                    title={localization.getString(
                        "howShouldSonicWallCseHandleUserConnectionsToThisSshService"
                    )}
                    htmlFor="handle-user-connections"
                >
                    <div className={styles.inputContainer}>
                        <Select
                            options={[
                                {
                                    displayName: localization.getString("onlyUseTheTrustCert"),
                                    value: SSHServiceType.TRUSTCERT,
                                },
                                {
                                    displayName: localization.getString(
                                        "useBothTheTrustCertAndTheSshCert"
                                    ),
                                    value: SSHServiceType.BOTH,
                                },
                            ]}
                            value={cert}
                            onChange={(e) => setCert(e as SSHServiceType)}
                        />
                    </div>
                </FormLabel>
                <FormLabel
                    title={localization.getString(
                        "shouldTheDesktopAppUpdateTheSshConfigFileOnTheDevice"
                    )}
                    htmlFor="update-ssh-config"
                >
                    <div className={styles.inputContainer}>
                        <Select
                            options={[
                                {
                                    displayName: localization.getString("yes"),
                                    value: true,
                                },
                                {
                                    displayName: localization.getString("no"),
                                    value: false,
                                },
                            ]}
                            //@ts-ignore
                            value={writeConfig}
                            onChange={(e) => setWriteConfig(Boolean(e))}
                        />
                    </div>
                </FormLabel>
            </FormSection>
        </>
    )
}
