import { faCircle } from "@fortawesome/pro-solid-svg-icons"
import classNames from "classnames/bind"
import React from "react"
import { useServiceLocalization } from "../../../../pre-v3/services/localization/Localization.service"
import {
    Device,
    StatusType as DeviceStatusType,
    TrustLevel,
    statusTypeLabelDict,
    trustLevelLabelDict,
} from "../../../services/Device.service"
import { deviceOwnershipLabelDict } from "../../../services/shared/DeviceOwnership"
import {
    OverviewTopContainer,
    Props as OverviewTopContainerProps,
} from "../../../components/overview/OverviewTopContainer/OverviewTopContainer.component"
import { SimpleTableItem } from "../../../components/simple-table/SimpleTable.component"
import { StatusType } from "../../../components/status/Status.component"
import { DateUtil } from "../../../../pre-v3/utils/Date.util"
import styles from "./DeviceOverviewHeader.module.scss"

interface Props {
    device: Device
}

export function DeviceOverviewHeader(props: Props): JSX.Element {
    const localization = useServiceLocalization()

    const listItems = useListItems(props.device)

    const overviewTopContainerProps = useOverviewTopContainerProps(props.device)

    return (
        <React.Fragment>
            <OverviewTopContainer
                {...overviewTopContainerProps}
                statusLabelTitle={localization.getString("trustLevel")}
                icon={faCircle}
                listItems={listItems}
                listClassName={styles.statusList}
            />
        </React.Fragment>
    )
}

type UseOverviewTopContainerProps = Required<
    Pick<OverviewTopContainerProps, "statusType" | "statusLabel" | "statusItems" | "iconClassName">
>

function useOverviewTopContainerProps(device: Device): UseOverviewTopContainerProps {
    const localization = useServiceLocalization()

    return React.useMemo((): UseOverviewTopContainerProps => {
        const statusLabel = localization.getString("status")

        const lastLoginItem: SimpleTableItem = {
            label: localization.getString("lastLogin"),
            value: DateUtil.format(device.lastLoginAt),
        }

        const withDarkerRedClassName = classNames(styles.statusIcon, styles.darkerRed)

        switch (device.status.type) {
            case DeviceStatusType.BANNED:
                return {
                    statusType: "error",
                    statusLabel: localization.getString(statusTypeLabelDict.BANNED),
                    statusItems: [lastLoginItem],
                    iconClassName: withDarkerRedClassName,
                }

            case DeviceStatusType.EXPIRED:
                const expiredAtItem: SimpleTableItem = {
                    label: localization.getString("expiredAt"),
                    value: DateUtil.format(device.status.expiredAt),
                }

                return {
                    statusType: "error",
                    statusLabel: localization.getString(statusTypeLabelDict.EXPIRED),
                    statusItems: [expiredAtItem, lastLoginItem],
                    iconClassName: withDarkerRedClassName,
                }

            case DeviceStatusType.OVERRIDDEN:
                const overriddenStatusItem: SimpleTableItem = {
                    label: statusLabel,
                    value: localization.getString(statusTypeLabelDict.OVERRIDDEN),
                }

                return {
                    statusType: trustLevelToStatusTypeDict[device.status.overriddenTrustLevel],
                    statusLabel: localization.getString(
                        trustLevelLabelDict[device.status.overriddenTrustLevel]
                    ),
                    statusItems: [overriddenStatusItem, lastLoginItem],
                    iconClassName:
                        device.status.overriddenTrustLevel === TrustLevel.ALWAYS_DENY
                            ? withDarkerRedClassName
                            : styles.statusIcon,
                }

            case DeviceStatusType.PENDING:
                const pendingLabel = localization.getString(statusTypeLabelDict.PENDING)

                const pendingStatusItem: SimpleTableItem = {
                    label: statusLabel,
                    value: pendingLabel,
                }

                return {
                    statusType: "disabled",
                    statusLabel: pendingLabel,
                    statusItems: [pendingStatusItem, lastLoginItem],
                    iconClassName: styles.statusIcon,
                }

            case DeviceStatusType.REPORTING:
                const reportingStatusItem: SimpleTableItem = {
                    label: statusLabel,
                    value: localization.getString(statusTypeLabelDict.REPORTING),
                }

                return {
                    statusType: trustLevelToStatusTypeDict[device.status.trustLevel],
                    statusLabel: localization.getString(
                        trustLevelLabelDict[device.status.trustLevel]
                    ),
                    statusItems: [reportingStatusItem, lastLoginItem],
                    iconClassName:
                        device.status.trustLevel === TrustLevel.ALWAYS_DENY
                            ? withDarkerRedClassName
                            : styles.statusIcon,
                }
        }
    }, [device, localization])
}

const trustLevelToStatusTypeDict: Record<TrustLevel, StatusType> = {
    [TrustLevel.ALWAYS_ALLOW]: "success",
    [TrustLevel.HIGH]: "success",
    [TrustLevel.MEDIUM]: "warning",
    [TrustLevel.LOW]: "error",
    [TrustLevel.ALWAYS_DENY]: "error",
}

function useListItems(device: Device): SimpleTableItem[] {
    const localization = useServiceLocalization()

    return React.useMemo((): SimpleTableItem[] => {
        return [
            { label: localization.getString("serialNumber"), value: device.serialNumber },
            {
                label: localization.getString("ownership"),
                value: localization.getString(deviceOwnershipLabelDict[device.deviceOwnership]),
            },
            { label: localization.getString("appVersion"), value: device.appVersion },
            { label: localization.getString("osVersion"), value: device.osVersion },
        ]
    }, [device, localization])
}
