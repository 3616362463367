import { useCallback, useRef, useState } from "react"

export function useDebouncedCallback<T extends (...args: any) => any>(
    callback: T,
    dependencies: any[],
    duration: number = 500
): T {
    const timeout = useRef<number | undefined>()

    const debounced = useCallback((...args: unknown[]) => {
        if (timeout.current) {
            window.clearTimeout(timeout.current)
            timeout.current = undefined
        }
        timeout.current = window.setTimeout(() => {
            callback(...args)
        }, duration)
    }, dependencies)

    return debounced as T
}

export function useDebouncedState<T>(
    initialValue: T,
    duration: number = 500
): [T, (value: T) => void, (value: T) => void] {
    const timeout = useRef<number | undefined>()
    const [internal, setInternal] = useState<T>(initialValue)

    function setState(value: T): void {
        if (timeout.current) {
            window.clearTimeout(timeout.current)
            timeout.current = undefined
        }
        timeout.current = window.setTimeout(() => {
            setInternal(value)
        }, duration)
    }

    // returns value, debounced setValue, and immediate setValue
    return [internal, setState, setInternal]
}
