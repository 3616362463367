import HighCharts from "highcharts"
import HighChartsReact from "highcharts-react-official"
import React from "react"

import styles from "./DoughnutChart.module.scss"

export interface Props<T> {
    /**
     * Data you want to illustrate in the chart.
     */
    data?: T[]
    /**
     * Primary label for the chart. Displayed on the top-left corner.
     */
    title: string
    /**
     * Secondary label for the chart. Displayed on the top-left corner below the
     * title.
     */
    subtitle: string
    /**
     * Describes the value. Displayed in the tooltip when the User hovers over
     * an arc.
     */
    seriesName: string
    /**
     * Describes a single arc. Displayed in the tooltip when the User hovers
     * over an arc and in the legend.
     * @param value Single data point
     */
    getName(value: T): string
    /**
     * Numerical value of a data point. Used to calculate the proportion its arc
     * will take in the chart and displayed in the tooltip when the User hovers
     * over an arc.
     * @param value Single data point
     */
    getValue(value: T): number
    /**
     * Color to represent a data point. Used to color its arc, tooltip, and
     * legend. If not provided, this component will assign colors automatically.
     * @param value Single data point
     */
    getColor?(value: T): Color
    /**
     * Callback used when the User clicks on an arc.
     * @param value Single data point
     */
    onSeriesClick?(value: T): void
}

/**
 * Use a doughnut chart illustrate the numerical proportion of a data set.
 */
export function DoughnutChart<T>(props: Props<T>): JSX.Element {
    const { data, getName, getValue, getColor, ...optionsInput } = props

    const formattedData = React.useMemo(
        () =>
            data?.map<HighCharts.PointOptionsObject>((originalData) => ({
                name: getName(originalData),
                y: getValue(originalData),
                custom: { originalData },
                color: getColor?.(originalData),
            })),
        [data, getName, getValue, getColor]
    )

    const options = getOptions({ ...optionsInput, data: formattedData })

    return (
        <HighChartsReact
            highcharts={HighCharts}
            options={options}
            containerProps={containerProps}
        />
    )
}

export enum Color {
    ALIZARIN_CRIMSON = "#E52F18",
    CARIBBEAN_GREEN = "#00C884",
    SANTA_GRAY = "#A2A5B7",
    SUNGLOW = "#FDCE3A",
}

interface GetOptionsInput<T> {
    title: string
    subtitle: string
    seriesName: string
    data?: HighCharts.PointOptionsObject[]
    onSeriesClick?(value: T): void
}

function getOptions<T>(input: GetOptionsInput<T>): Highcharts.Options {
    return {
        title: {
            text: input.title,
            align: "left",
            style: {
                color: "#222C36",
                fontSize: "14px",
                fontWeight: "600",
                letterSpacing: "0.2px",
            },
        },
        subtitle: {
            text: input.subtitle,
            align: "left",
            style: {
                color: "#222C36",
                fontSize: "13px",
                fontWeight: "400",
            },
        },
        chart: { backgroundColor: "transparent", type: "pie" },
        series: [{ type: "pie", name: input.seriesName, data: input.data }],
        plotOptions: {
            pie: {
                cursor: typeof input.onSeriesClick === "function" ? "pointer" : "default",
                innerSize: "75%",
                showInLegend: true,
                dataLabels: { enabled: false },
                borderColor: undefined,
                point: {
                    events: {
                        click: (event) =>
                            input.onSeriesClick?.(event.point.options.custom?.originalData),
                    },
                },
            },
        },
        legend: {
            enabled: true,
            layout: "vertical",
            align: "right",
            verticalAlign: "middle",
            symbolRadius: 0,
            itemStyle: {
                color: "#222C36",
                fontWeight: "600",
            },
        },
        credits: { enabled: false },
    }
}

const containerProps: React.HTMLAttributes<HTMLDivElement> = { className: styles.container }
