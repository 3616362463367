import React from "react"
import { useHistory, useParams } from "react-router-dom"

import { DeleteCancelActions } from "../../../../../pre-v3/components/modal/delete-cancel/DeleteCancelActions"
import { IconType, useServiceLocalization, useServiceModal } from "../../../../../pre-v3/services"
import { decodeID } from "../../../../../pre-v3/utils/Url.util"
import { ROUTE } from "../../../../../routes"
import {
    File,
    FileCheckTrustFactor,
    TrustFactor,
    NewFile,
    isMissingFilePath,
    useDeleteFile,
    useUpdateFile,
} from "../../../../services/TrustFactor.service"
import { Container } from "../../../../components/container/Container.component"
import { LargeMessage } from "../../../../components/large-message/LargeMessage.component"
import { Loader } from "../../../../components/loader/Loader.component"
import { FileCheckForm } from "./FileCheckForm.component"
import { DeleteFile } from "./DeleteFile.component"
import trustStyles from "../../../../../pages/trust/Trust.module.scss"
import styles from "./FileCheckForm.module.scss"
import { PageHeading } from "../../../../../components/page-heading/PageHeading.component"
import {
    Button,
    ButtonElement,
    ButtonType,
} from "../../../../../components/button/Button.component"
import { Tooltip } from "../../../../components/tooltip/Tooltip.component"

interface Props {
    fileCheckTrustFactor?: FileCheckTrustFactor
    onRefetchData(): Promise<TrustFactor[] | undefined>
    canAccessFileCheckFactor?: boolean
}

export function FileCheckEdit(props: Props): JSX.Element {
    const { id: encodedFileId } = useParams<{ id: string }>()
    const localization = useServiceLocalization()

    const file = React.useMemo((): File | undefined => {
        const fileId = decodeID(encodedFileId)
        return props.fileCheckTrustFactor?.files.find((fileCheck) => fileCheck.id === fileId)
    }, [props.fileCheckTrustFactor?.files, encodedFileId])

    return (
        <Loader
            isLoading={!props.fileCheckTrustFactor}
            title={localization.getString("loadingFile")}
            center
        >
            {file ? (
                <EditFileForm
                    file={file}
                    refetchData={props.onRefetchData}
                    canAccessFileCheckFactor={props.canAccessFileCheckFactor}
                />
            ) : (
                <LargeMessage>{localization.getString("fileNotFound")}</LargeMessage>
            )}
        </Loader>
    )
}

interface EditFileFormProps {
    file: File
    refetchData(): Promise<TrustFactor[] | undefined>
    canAccessFileCheckFactor?: boolean
}

function EditFileForm(props: EditFileFormProps): JSX.Element {
    const history = useHistory()
    const localization = useServiceLocalization()
    const modalService = useServiceModal()

    const [isMissingConfiguration, setIsMissingConfiguration] = React.useState(false)

    const updateFileCheckTrustFactor = useUpdateFile({
        onSuccess: () => history.push(ROUTE.FACTORS_FILE_CHECK),
    })

    const deleteFileCheckTrustFactor = useDeleteFile()

    const onDelete: React.MouseEventHandler = (event) => {
        event.preventDefault()

        modalService
            .open(
                localization.getString("deleteFile"),
                { component: DeleteFile, props, withoutBody: true },
                { component: DeleteCancelActions }
            )
            .onClose(async () => {
                await deleteFileCheckTrustFactor.mutateAsync(props.file)
                history.push(ROUTE.FACTORS_FILE_CHECK)
            })
    }

    function onSubmit(file: NewFile): void {
        setIsMissingConfiguration(false)

        if (Object.values(file.configurations).every(isMissingFilePath)) {
            return setIsMissingConfiguration(true)
        }

        try {
            updateFileCheckTrustFactor.mutate({
                ...props.file,
                ...file,
            })
        } catch (error) {}
    }

    function onCancel(): void {
        history.push(ROUTE.FACTORS)
    }

    return (
        <div className={styles.container}>
            <section aria-labelledby={Id.HEADING} className={trustStyles.section}>
                <header className={trustStyles.header}>
                    <PageHeading id={Id.HEADING}>{props.file.name}</PageHeading>
                    {props.canAccessFileCheckFactor && (
                        <Tooltip title={localization.getString("delete")}>
                            <Button
                                icon={IconType.TRASH}
                                onClick={onDelete}
                                asElement={ButtonElement.BUTTON}
                                buttonType={ButtonType.SECONDARY}
                                aria-label={localization.getString("delete")}
                            />
                        </Tooltip>
                    )}
                </header>
            </section>
            <Container>
                <FileCheckForm
                    defaultValue={props.file}
                    onSubmit={onSubmit}
                    onCancel={onCancel}
                    submitting={updateFileCheckTrustFactor.isLoading}
                    errors={[
                        updateFileCheckTrustFactor.error,
                        deleteFileCheckTrustFactor.error,
                        isMissingConfiguration &&
                            localization.getString(
                                "addAConfigurationToAtLeastOneAvailablePlatform"
                            ),
                    ]}
                    canAccessFileCheckFactor={props.canAccessFileCheckFactor}
                />
            </Container>
        </div>
    )
}

enum Id {
    HEADING = "heading",
}
