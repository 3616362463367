import classNames from "classnames/bind"
import React from "react"

import { useServiceLocalization } from "../../../pre-v3/services/localization/Localization.service"
import { ButtonBase } from "../button/ButtonBase.component"
import { Checkbox, CheckboxProps } from "../checkbox/Checkbox.component"
import styles from "./CheckboxGroupBase.module.scss"

export interface CheckboxGroupBaseProps {
    value: string[]
    options: CheckboxGroupBaseOption[]
    disabled?: boolean
    required?: boolean
    className?: string
    selectAllClassName?: string
    checkboxItemClassName?: string
    onChange(value: string[]): void
}

export function CheckboxGroupBase(props: CheckboxGroupBaseProps): JSX.Element {
    const localization = useServiceLocalization()

    function onChange(item: CheckboxGroupBaseOption, isChecked: boolean) {
        if (isChecked) {
            props.onChange([...props.value, item.id])
        } else {
            props.onChange(props.value.filter((x) => item.id !== x))
        }
    }

    function onSelectAllChange(isChecked: boolean) {
        return isChecked ? props.onChange(props.options.map((o) => o.id)) : props.onChange([])
    }

    const selectAllChecked = props.value.length === props.options.length

    return (
        <div className={classNames(styles.container, props.className)}>
            <Checkbox
                onChange={onSelectAllChange}
                disabled={props.disabled}
                checked={selectAllChecked}
                className={props.selectAllClassName}
            >
                {localization.getString("selectAll")}
            </Checkbox>
            <input
                type="text"
                readOnly
                required={props.required}
                value={props.value.join(", ")}
                hidden
            />
            <div className={classNames(styles.rowContainer)}>
                {props.options.map((option) => {
                    const { id, ...checkboxProps } = option

                    const isChecked = props.value.includes(id)

                    return (
                        <ButtonBase
                            className={classNames(
                                styles.checkboxItem,
                                styles.borderOverride,
                                props.checkboxItemClassName
                            )}
                            onClick={(event: React.MouseEvent<HTMLElement>) => {
                                if (!props.disabled && event.target === event.currentTarget) {
                                    onChange(option, !isChecked)
                                }
                            }}
                            key={id}
                            brand={isChecked}
                            as="div"
                            //@ts-ignore
                            disabled={props.disabled}
                        >
                            <Checkbox
                                {...checkboxProps}
                                checked={isChecked}
                                onChange={(checked) => {
                                    onChange(option, checked)
                                }}
                            />
                        </ButtonBase>
                    )
                })}
            </div>
        </div>
    )
}

export interface CheckboxGroupBaseOption extends Omit<CheckboxProps, "checked" | "onChange"> {
    id: string
}
