import React from "react"

import { Input } from "../../../../../../v3/components/input/Input.component"
import styles from "./ServiceBundleAdd.module.scss"
import {
    Form,
    FormColumns,
    FormLabel,
    FormRow,
    ErrorBanner,
    Select,
    TransferList,
} from "../../../../../components"
import {
    Button,
    ButtonElement,
    ButtonType,
} from "../../../../../../components/button/Button.component"

export default function () {
    return (
        <Form onSubmit={this.onSubmit} display="grid" labelWidth={140} floating>
            <FormRow>{this.state.error && <ErrorBanner>{this.state.error}</ErrorBanner>}</FormRow>
            <FormRow>
                <h2>{this.localizationService.getString("addServiceBundle")}</h2>
                <p>{this.localizationService.getString("serviceBundleDescription")}</p>
            </FormRow>
            <FormLabel title={this.localizationService.getString("name")} htmlFor="bundleName">
                <Input
                    id="bundleName"
                    name="bundleName"
                    className={styles.formInput}
                    required
                    placeholder={this.localizationService.getString("bundleName")}
                />
            </FormLabel>
            <FormLabel
                title={this.localizationService.getString(
                    "somethingOptional",
                    this.localizationService.getString("description")
                )}
                htmlFor="description"
            >
                <Input
                    id="description"
                    name="description"
                    className={styles.formInput}
                    placeholder={this.localizationService.getString("description")}
                />
            </FormLabel>
            <FormLabel title={this.localizationService.getString("services")} htmlFor="services">
                <TransferList
                    id="services"
                    name="services"
                    required={true}
                    options={this.state.services}
                    onChange={this.onServiceChange}
                    value={this.state.serviceIds}
                />
            </FormLabel>
            <FormLabel
                title={this.localizationService.getString("bulkConnect")}
                htmlFor="bulkConnect"
            >
                <Select
                    id="bulkConnect"
                    name="bulkConnect"
                    required
                    value={this.state.bulkConnect}
                    options={this.bulkConnectOptions}
                    onChange={this.onBulkConnectChange}
                />
            </FormLabel>
            <FormColumns
                right={
                    <Button
                        buttonType={ButtonType.PRIMARY}
                        asElement={ButtonElement.BUTTON}
                        type="submit"
                        className={styles.saveButton}
                    >
                        {this.localizationService.getString("save")}
                    </Button>
                }
            />
        </Form>
    )
}
