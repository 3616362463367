import { Singleton } from "../../pre-v3/decorators/Singleton.decorator"
import { BaseApi } from "./Base.api"

@Singleton("UserProfileApi")
export class UserProfileApi extends BaseApi {
    public changePassword(body: ChangePasswordBody): Promise<void> {
        return this.postForm("/api/v1/change_password", body, {
            default: this.localization.getString("failedToChangePassword"),
        })
    }

    public generateRefreshToken(): Promise<RefreshTokenRes> {
        return this.post(
            "/api/v2/refresh_token",
            {},
            {
                default: this.localization.getString("failedToGenerateRefreshToken"),
            }
        )
    }

    public revokeRefreshToken(): Promise<void> {
        return this.post(
            "/api/v1/revoke_token",
            {},
            {
                default: this.localization.getString("failedToRevokeRefreshToken"),
            }
        )
    }
}

interface ChangePasswordBody {
    Password: string
    NewPassword: string
}

interface RefreshTokenRes {
    refresh_token: string
}
