import classNames from "classnames/bind"
import React, { ReactNode } from "react"

import styles from "./FormLabel.module.scss"

export interface Props {
    id?: string
    label: string
    description?: ReactNode
    htmlFor?: string
    className?: string
    labelClassName?: string
    required?: boolean
}

export function FormLabel(props: Props): JSX.Element {
    return (
        <div className={classNames(styles.container, props.className)}>
            <div className={styles.labelContainer}>
                <label
                    id={props.id}
                    className={classNames(styles.label, props.labelClassName)}
                    htmlFor={props.htmlFor}
                >
                    {props.label}
                </label>
                {props.required && <span>*</span>}
            </div>
            {props.description && <div className={styles.subText}>{props.description}</div>}
        </div>
    )
}
