import classNames from "classnames/bind"
import React from "react"

import { CollectionUtil } from "../../../pre-v3/utils/Collection.util"
import styles from "./Slider.module.scss"

export interface Props<T> {
    /**
     * Current value for the Slider
     */
    value: T
    /**
     * All possible values the Slider will display
     */
    data: T[]
    /**
     * Provide the information needed to display the Slider based on the data
     * @param value one Value from the data provided to the Slider
     */
    getSliderProps(value: T): SliderProps
    onChange(value: T): void
    disabled?: boolean
}

/**
 * Display a list of values in a discrete continuum with buttons to select options
 */
export function Slider<T>(props: Props<T>): JSX.Element {
    const { value, data, getSliderProps, onChange, disabled } = props

    const { id: selectedId, color: selectedColor } = getSliderProps(value)

    const valueIndex = React.useMemo(
        () =>
            data.findIndex((v) => {
                return getSliderProps(v).id === selectedId
            }),
        [data, getSliderProps, selectedId]
    )

    const mapOption = React.useCallback(
        (currentValue: T, index: number, allData: T[]) => {
            const isSelected = index === valueIndex
            const { id, label, color } = getSliderProps(currentValue)

            const onSelectValue = () => onChange(currentValue)

            return (
                <React.Fragment key={id}>
                    <Track
                        isSelected={isSelected}
                        isFirst={index === 0}
                        isLast={index === allData.length - 1}
                        isLeft={index < valueIndex}
                        isRight={index > valueIndex}
                        color={selectedColor}
                        onSelectValue={onSelectValue}
                    />
                    <Button
                        label={label}
                        isSelected={isSelected}
                        color={color}
                        onSelectValue={onSelectValue}
                    />
                </React.Fragment>
            )
        },
        [valueIndex, getSliderProps, selectedColor, onChange]
    )

    return (
        <div
            className={classNames(styles.container, {
                [styles.disabled]: disabled,
            })}
        >
            {data.map(mapOption)}
        </div>
    )
}

/**
 * Information needed to display the Slider based on the provided data
 */
export interface SliderProps {
    /**
     * Determines the unique identifier
     */
    id: string
    /**
     * This label will appear in the button below its selection
     */
    label: string
    /**
     * This color will paint the slider, thumb, and button
     */
    color: Color
}

interface TrackProps {
    isSelected: boolean
    isFirst: boolean
    isLast: boolean
    isLeft: boolean
    isRight: boolean
    color: Color
    onSelectValue(): void
}

function Track(props: TrackProps): JSX.Element {
    const colorName = colorNameDictionary[props.color]
    const colorTrackClassName = styles[`${colorName}Track`]
    const leftTrackClassName = classNames(styles.leftTrack, colorTrackClassName)
    const colorThumbClassName = styles[`${colorName}Thumb`]

    const leftClassNameDictionary: Record<string, boolean> = {
        [styles.visible]: !props.isFirst,
        [leftTrackClassName]: props.isLeft || props.isSelected,
        [styles.rightTrack]: props.isRight,
    }
    const rightClassNameDictionary: Record<string, boolean> = {
        [styles.visible]: !props.isLast,
        [leftTrackClassName]: props.isLeft,
        [styles.rightTrack]: props.isRight || props.isSelected,
    }

    const onDrag: React.DragEventHandler<HTMLDivElement> = (event) => {
        event.currentTarget.style.opacity = "1"
    }

    const onDragStart: React.DragEventHandler<HTMLDivElement> = (event) => {
        event.dataTransfer.effectAllowed = "none"
        event.currentTarget.style.opacity = "0"
        event.dataTransfer.setDragImage(event.currentTarget, 0, 0)
    }

    const onSelectValue = (event: React.BaseSyntheticEvent) => {
        event.preventDefault()
        props.onSelectValue()
    }

    return (
        <div className={styles.track} onClick={onSelectValue} onDragEnter={onSelectValue}>
            <div className={classNames(leftClassNameDictionary)} />
            {props.isSelected ? (
                <div
                    draggable
                    onDrag={onDrag}
                    onDragStart={onDragStart}
                    className={classNames(styles.thumb, colorThumbClassName)}
                />
            ) : (
                <Tick {...props} />
            )}
            <div className={classNames(rightClassNameDictionary)} />
        </div>
    )
}

interface TickProps {
    color: Color
    isLeft: boolean
}

function Tick(props: TickProps): JSX.Element {
    const colorName = colorNameDictionary[props.color]
    const colorTickClassName = styles[`${colorName}Tick`]

    return (
        <div className={styles.tick}>
            <div className={classNames({ [colorTickClassName]: props.isLeft })} />
        </div>
    )
}

interface ButtonProps {
    label: string
    isSelected: boolean
    color: Color
    onSelectValue(): void
}

function Button(props: ButtonProps): JSX.Element {
    const colorName = colorNameDictionary[props.color]
    const colorClassName = styles[`${colorName}Button`]

    const onSelectValue = (event: React.BaseSyntheticEvent) => {
        event.preventDefault()
        props.onSelectValue()
    }

    return (
        <button
            onClick={onSelectValue}
            onDragEnter={onSelectValue}
            className={classNames(styles.labelButton, colorClassName, {
                [styles.selected]: props.isSelected,
            })}
        >
            {props.label}
        </button>
    )
}

export enum Color {
    BLUEGRAY_300 = "BLUEGRAY_300",
    GREEN_500 = "GREEN_500",
    RED_500 = "RED_500",
    RED_900 = "RED_900",
    YELLOW_500 = "YELLOW_500",
}

const colorNameDictionary = CollectionUtil.createDictionary(Color)
