import React from "react"

import { useServiceLocalization } from "../../../../../../../pre-v3/services"
import { ROUTE } from "../../../../../../../routes"
import { AppText, LocalizationProps } from "../../../../../../components/app-text/AppText.component"
import { Link } from "../../../../../../components/link/Link.component"
import { MIGRATION_DATE } from "../../../../../../services/TrustProfile.service"
import styles from "./UpcomingProductEnhancement.module.scss"

export function UpcomingProductEnhancement(): JSX.Element {
    const localization = useServiceLocalization()
    const formatter = new Intl.DateTimeFormat(localization.getLocale(), dateFormatOptions)
    const upcomingProductEnhancementGranularTrustScoringLocalized: LocalizationProps = {
        key: "upcomingProductEnhancementGranularTrustScoring",
        replaceVals: [formatter.format(MIGRATION_DATE)],
    }

    return (
        <div className={styles.container}>
            <AppText ls={upcomingProductEnhancementGranularTrustScoringLocalized} />
            <Link to={ROUTE.GRANULAR_TRUST_MIGRATION_EDUCATION} className={styles.link}>
                {localization.getString("learnMoreAboutTheUpcomingChangesHere")}
            </Link>
        </div>
    )
}

const dateFormatOptions: Intl.DateTimeFormatOptions = { dateStyle: "full" }
