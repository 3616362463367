import React from "react"
import { Redirect, Route, Switch, useLocation } from "react-router-dom"

import {
    NavigationTabBar,
    TabProps,
} from "../../components/navigation-tab-bar/NavigationTabBar.component"
import useTitle from "../../hooks/useTitle.hook"
import { LanguageKey } from "../../pre-v3/services/localization/languages/en-US.language"
import { useServiceLocalization } from "../../pre-v3/services/localization/Localization.service"
import { TrustProviderAppDeploymentSettings as Deployment } from "../../pre-v3/views/settings/trust-provider/app-deployment/TrustProviderAppDeploymentSettings.component"
import { TrustProviderDeviceManagerSettings as DeviceManager } from "../../pre-v3/views/settings/trust-provider/device-manager/TrustProviderDeviceManagerSettings.component"
import { doesRouteMatch, ROUTE } from "../../routes"
import { TrustScoreExpiry as Trust } from "../../v3/views/app/settings/trustScoreSettings/trustScoreExpiry/TrustScoreExpiry.view"
import { Bundles } from "../bundles/Bundles"
import styles from "./BanyanClient.module.scss"
import { useFeatureFlags } from "../../hooks/useFeatureFlags.hook"
import { Loader } from "../../v3/components/loader/Loader.component"
import { Upsell } from "../../shared/upsell/Upsell.component"

export function BanyanClient(): JSX.Element {
    useTitle(["sonicWallCseClient", "settings", "adminConsole"])

    const localization = useServiceLocalization()
    const location = useLocation()
    const { data: featureFlags, isLoading: isFeatureFlagsLoading } = useFeatureFlags()

    const getTabProps = (route: ThirdLevelRoute): TabProps => ({
        key: route,
        label: localization.getString(thirdLevelLabels[route]),
        to: route,
        active: doesRouteMatch<ThirdLevelRoute>(route, location),
    })

    if (!featureFlags || isFeatureFlagsLoading) return <Loader medium center />

    return (
        <div className={styles.container}>
            <NavigationTabBar tabs={thirdLevelRoutes} getTabProps={getTabProps} />
            <Switch>
                <Route path={ROUTE.DEPLOYMENT}>
                    <Deployment
                        canUpdateInviteCode={featureFlags.adminConsole.canUpdateInviteCode}
                        canUpdateMdmSetting={featureFlags.adminConsole.canUpdateMdmSetting}
                    />
                </Route>
                <Route path={ROUTE.BUNDLES}>
                    {featureFlags.adminConsole.doShowUpsellForServiceBundle ? (
                        <Upsell />
                    ) : (
                        <Bundles
                            canAccessServiceBundle={
                                featureFlags.adminConsole.canAccessServiceBundle
                            }
                        />
                    )}
                </Route>
                <Route path={ROUTE.SONICWALL_CSE_CLIENT_TRUST}>
                    <Trust
                        canUpdateTrustScopeExpiry={
                            featureFlags.adminConsole.canUpdateTrustScopeExpiry
                        }
                    />
                </Route>
                <Route path={ROUTE.DEVICE_MANAGER}>
                    {featureFlags.adminConsole.doShowUpsellForDeviceManager ? (
                        <Upsell />
                    ) : (
                        <DeviceManager
                            canUpdateTrustProviderDeviceManagerSetting={
                                featureFlags.adminConsole.canUpdateTrustProviderDeviceManagerSetting
                            }
                        />
                    )}
                </Route>
                <Redirect to={ROUTE.DEPLOYMENT} />
            </Switch>
        </div>
    )
}

const thirdLevelRoutes = [
    ROUTE.DEPLOYMENT,
    ROUTE.BUNDLES,
    ROUTE.SONICWALL_CSE_CLIENT_TRUST,
    ROUTE.DEVICE_MANAGER,
] satisfies ROUTE[]

type ThirdLevelRoute = (typeof thirdLevelRoutes)[number]

const thirdLevelLabels: Record<ThirdLevelRoute, LanguageKey> = {
    [ROUTE.DEPLOYMENT]: "deployment",
    [ROUTE.BUNDLES]: "bundles",
    [ROUTE.SONICWALL_CSE_CLIENT_TRUST]: "trust",
    [ROUTE.DEVICE_MANAGER]: "deviceManager",
}
