import React, { useState } from "react"
import { useHistory, useLocation } from "react-router-dom"

import { useServiceLocalization } from "../../../../pre-v3/services/localization/Localization.service"
import { ROUTE, formatRoutePath } from "../../../../routes"
import { Container } from "../../../components/container/Container.component"
import {
    EditableRoleFields,
    Role,
    getEmptyRole,
    useCreateRole,
} from "../../../services/Role.service"
import { Loader } from "../../../components/loader/Loader.component"
import { encodeID } from "../../../../pre-v3/utils/Url.util"
import { AppText } from "../../../components/app-text/AppText.component"
import { LinkService } from "../../../../pre-v3/services"
import { RolesForm } from "../form/RolesForm.component"
import { PageHeading } from "../../../../components/page-heading/PageHeading.component"
import styles from "./RolesAdd.module.scss"

export function RolesAdd() {
    const localization = useServiceLocalization()
    const linkService = new LinkService()
    const history = useHistory()
    const { state } = useLocation<{ copyRole?: Role }>()

    const [role, setRole] = useState<EditableRoleFields>(
        state?.copyRole
            ? {
                  ...state.copyRole,
                  name: localization
                      .getString("copyOfSomething", state.copyRole.name)
                      .split(" ")
                      .join("_"),
              }
            : getEmptyRole
    )

    const {
        mutate: createRole,
        isLoading: isRoleCreating,
        error: createRoleError,
    } = useCreateRole({
        onSuccess: (newRole) =>
            history.push(formatRoutePath(ROUTE.ROLES_DETAILS, { id: encodeID(newRole.id) })),
    })

    function onCancel() {
        history.push(ROUTE.ROLES)
    }

    function onSubmit() {
        createRole(role)
    }

    return (
        <Loader isLoading={isRoleCreating} medium center>
            <section aria-labelledby={Id.HEADING}>
                <header className={styles.header}>
                    <PageHeading id={Id.HEADING}>{localization.getString("roles")}</PageHeading>
                </header>
                <Container className={styles.container}>
                    <AppText
                        ls={{
                            key: "rolesDescription",
                            replaceVals: [linkService.getLink("manageRoles")],
                        }}
                    />
                    <RolesForm
                        value={role}
                        onChange={setRole}
                        onCancel={onCancel}
                        onSubmit={onSubmit}
                        errors={[createRoleError ? String(createRoleError) : null]}
                    />
                </Container>
            </section>
        </Loader>
    )
}
enum Id {
    HEADING = "heading",
}
