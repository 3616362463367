import React from "react"

import { useServiceLinks } from "../../../../pre-v3/services/link/Link.service"
import { useServiceLocalization } from "../../../../pre-v3/services/localization/Localization.service"
import { DateUtil } from "../../../../pre-v3/utils/Date.util"
import { ROUTE, formatRoutePath } from "../../../../routes"
import { Role } from "../../../services/Role.service"
import { AppText } from "../../../components/app-text/AppText.component"
import {
    Column,
    Grid,
    sortAlphabetically,
    sortByTimeStamp,
} from "../../../components/grid/Grid.component"
import { RowTitle } from "../../../components/grid/RowTitle.component"
import styles from "./DeviceRolesGrid.module.scss"
import { encodeID } from "../../../../pre-v3/utils/Url.util"

interface Props {
    roles: Role[]
}

export function DeviceRolesGrid(props: Props): JSX.Element {
    const linkService = useServiceLinks()
    const localization = useServiceLocalization()

    const columns = useColumns()

    return (
        <div className={styles.container}>
            <h6 className={styles.title}>{localization.getString("rolesAssociatedWithDevice")}</h6>
            {props.roles.length > 0 ? (
                <Grid data={props.roles} columns={columns} className={styles.grid} />
            ) : (
                <AppText
                    ls={{
                        key: "thereAreCurrentlyNoRolesAssociatedWithThisDevice",
                        replaceVals: [linkService.getLink("manageRoles")],
                    }}
                    className={styles.noRolesBanner}
                />
            )}
        </div>
    )
}

function useColumns(): Column<Role>[] {
    const localization = useServiceLocalization()

    return React.useMemo((): Column<Role>[] => {
        const nameColumn: Column<Role> = {
            id: "name",
            name: localization.getString("name"),
            cellRenderer: renderRoleName,
            getTooltipValue: constEmptyString,
            sorting: sortAlphabetically("name"),
        }

        const lastUpdatedColumn: Column<Role> = {
            id: "lastUpdated",
            name: localization.getString("lastUpdated"),
            cellRenderer: renderLastUpdated,
            getTooltipValue: renderLastUpdated,
            sorting: sortByTimeStamp("lastUpdatedAt"),
        }

        return [nameColumn, lastUpdatedColumn]
    }, [localization])
}

function renderRoleName(role: Role): JSX.Element {
    return (
        <RowTitle
            title={role.name}
            route={formatRoutePath(ROUTE.ROLES_DETAILS, { id: encodeID(role.id) })}
        />
    )
}

function renderLastUpdated(role: Role): string {
    return DateUtil.format(role.lastUpdatedAt)
}

function constEmptyString(): string {
    return ""
}
