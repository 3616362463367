import { ColDef } from "ag-grid-community"
import * as React from "react"
import styles from "./PublicResourceOverview.module.scss"
import { useServiceLocalization } from "../../../../../pre-v3/services/localization/Localization.service"
import AgGridUtil from "../../../../../pre-v3/utils/AgGrid.util"
import { Grid } from "../../../../../pre-v3/components/grid/Grid.component"
import { ServiceTunnelGrid } from "./ServiceTunnelModal.component"

interface Props {
    serviceTunnels: ServiceTunnelGrid[]
    isDataLoading: boolean
}

export function ServiceTunnelAndStateGrid(props: Props) {
    const localization = useServiceLocalization()

    const column: ColDef[] = [
        {
            headerName: localization.getString("serviceTunnel"),
            field: "tunnelName",
            tooltipValueGetter: AgGridUtil.linkTooltipValueGetter,
            flex: 75,
            comparator: AgGridUtil.alphaBetComparator,
        },
        {
            headerName: localization.getString("state"),
            field: "state",
            tooltipValueGetter: AgGridUtil.linkTooltipValueGetter,
            flex: 75,
            comparator: AgGridUtil.alphaBetComparator,
        },
    ]

    return (
        <div className={styles.gridContainer}>
            <p>
                {localization
                    .getPluralString(
                        "youCurrentlyHaveCountServiceTunnelsActive",
                        props.serviceTunnels?.length || 0
                    )
                    .toString()}
            </p>
            {props.serviceTunnels.length > 0 && !props.isDataLoading && (
                <Grid
                    columnDefs={column}
                    rowData={props.serviceTunnels}
                    pagination
                    autoHeight
                ></Grid>
            )}
        </div>
    )
}
