import { BaseApi, PaginatedResponse } from "./Base.api"
import { Singleton } from "../../pre-v3/decorators/Singleton.decorator"

@Singleton("ServiceTunnelApiV3")
export class ServiceTunnelApi extends BaseApi {
    public getServiceTunnels(): Promise<PaginatedResponse<"service_tunnels", ServiceTunnelRes>> {
        return this.get("/api/v2/service_tunnel", {
            default: this.localization.getString("errorCouldNotFetchServiceTunnels"),
        })
    }

    public getServiceTunnelById(id: string): Promise<ServiceTunnelDetailsRes> {
        return this.get(`/api/v2/service_tunnel/${encodeURIComponent(id)}`, {
            default: this.localization.getString("errorCouldNotFindServiceTunnel"),
        })
    }

    public deleteServiceTunnel(id: string): Promise<void> {
        return this.delete(`/api/v2/service_tunnel/${encodeURIComponent(id)}`, null, {
            400: this.localization.getString("pleaseDetachPoliciesAndThenTryAgain"),
            default: this.localization.getString("errorCouldNotDeleteServiceTunnel"),
        })
    }

    public getPolicyAttachmentByTunnelId(tunnelId: string): Promise<TunnelPolicyAttachmentRes> {
        return this.get(`/api/v2/service_tunnel/${encodeURIComponent(tunnelId)}/security_policy`, {
            400: this.localization.getString("policyNotFound"),
            default: this.localization.getString("errorCouldNotFetchPolicy"),
        })
    }

    public createServiceTunnel(tunnel: ServiceTunnelReq): Promise<ServiceTunnelDetailsRes> {
        return this.post(
            `/api/v2/service_tunnel`,
            {
                kind: "BanyanServiceTunnel",
                // cspell:ignore banyanops
                api_version: "rbac.banyanops.com",
                type: "origin",
                ...tunnel,
            },
            {
                409: this.localization.getString(
                    "somethingNamedAlreadyExists",
                    this.localization.getString("serviceTunnel"),
                    tunnel.metadata.name
                ),
                default: this.localization.getString(
                    "failedToCreateSomething",
                    this.localization.getString("serviceTunnel")
                ),
            }
        )
    }

    public updateTunnel(id: string, tunnel: ServiceTunnelReq): Promise<ServiceTunnelDetailsRes> {
        return this.put(
            `/api/v2/service_tunnel/${encodeURIComponent(id)}`,
            {
                kind: "BanyanServiceTunnel",
                api_version: "rbac.banyanops.com",
                type: "origin",
                ...tunnel,
            },
            {
                default: this.localization.getString(
                    "failedToUpdateSomething",
                    this.localization.getString("serviceTunnel")
                ),
            }
        )
    }

    public attachPolicyToTunnel(
        tunnelId: string,
        policyId: string,
        enabled: boolean
    ): Promise<TunnelPolicyAttachmentRes> {
        return this.post(
            `/api/v2/service_tunnel/${encodeURIComponent(tunnelId)}/security_policy`,
            {
                policy_id: policyId,
                enabled: enabled,
            },
            {
                default: this.localization.getString("failedToAttachPolicy"),
            }
        )
    }

    public detachPolicyFromTunnel(tunnelId: string, policyId: string): Promise<void> {
        return this.delete(
            `/api/v2/service_tunnel/${encodeURIComponent(
                tunnelId
            )}/security_policy/${encodeURIComponent(policyId)}`,
            null,
            {
                default: this.localization.getString("errorDetachingServiceFromPolicy"),
            }
        )
    }
}

export interface ServiceTunnelRes {
    id: string
    name: string
    friendly_name: string
    description: string
    updated_at: number
    active_connections_count: number
    AttachedPolicy: AttachedPolicy | null
    spec: {
        metadata: {
            name: string
            friendly_name: string
            autorun: boolean
            lock_autorun: boolean
            tags: {
                icon?: string
                description_link?: string
            }
        }
        spec: {
            peer_access_tiers: PeerAccessTierRes[]
            name_resolution: {
                dns_search_domains?: string[]
                name_servers?: string[]
            }
        }
    }
}

export interface ServiceTunnelDetailsRes {
    id: string
    name: string
    friendly_name?: string
    description: string
    spec: string
    created_at: number
    created_by: string
    updated_at: number
    updated_by: string
    active_connections_count: number
    AttachedPolicy: AttachedPolicy | null
}

export interface ServiceTunnelSpecJson {
    metadata: {
        autorun: boolean
        lock_autorun: boolean
        tags: {
            icon?: string
            description_link?: string
        }
    }
    spec: {
        peer_access_tiers: PeerAccessTierRes[]
        name_resolution: {
            dns_search_domains: string[]
            name_servers?: string[]
        }
    }
}

export interface AttachedPolicy {
    attached_at: number
    attached_by: string
    policy_id: string
    policy_name: string
    policy_status: boolean
}

export interface ServiceTunnelReq {
    metadata: {
        name: string
        description?: string
        friendly_name: string
        // cspell:ignore autorun
        autorun?: boolean
        lock_autorun?: boolean
        tags?: {
            description_link?: string
            icon?: string
        }
    }
    spec: {
        peer_access_tiers: PeerAccessTierReq[] | undefined
        name_resolution?: {
            dns_search_domains?: string[]
            name_servers?: string[]
        }
    }
}

export interface PeerAccessTierReq {
    cluster: string
    access_tiers?: string[] | null
    connectors?: string[]
    access_tier_group?: string
    public_domains?: IncludeExcludeReq
    public_cidrs?: IncludeExcludeReq
    applications?: IncludeExcludeReq
}

export interface IncludeExcludeReq {
    include: string[]
    exclude: string[]
}

export interface PeerAccessTierRes {
    cluster: string
    access_tiers: string[] | null
    connectors: string[]
    access_tier_group: string
    public_domains?: IncludeExcludeRes
    public_cidrs?: IncludeExcludeRes
    applications?: IncludeExcludeRes
}

export interface IncludeExcludeRes {
    include: string[] | null
    exclude: string[] | null
}

export interface TunnelPolicyAttachmentRes {
    policy_id: string
    policy_name: string
    attached_to_id: string
    enabled: "TRUE" | "FALSE"
    attached_at: number
    attached_to_type: ServiceType
}

export type ServiceType = "service_tunnel"
