import classNames from "classnames/bind"
import React from "react"

import {
    Button,
    ButtonElement,
    ButtonType,
    IconType,
} from "../../../../../../../components/button/Button.component"
import { useServiceLocalization } from "../../../../../../../pre-v3/services/localization/Localization.service"
import { CollectionUtil } from "../../../../../../../pre-v3/utils/Collection.util"
import {
    TrustFactor,
    TRUST_EFFECT,
    effectLabelDictionary,
} from "../../../../../../services/TrustProfile.service"
import { Platform } from "../../../../../../services/shared/Platform"
import {
    AccordionContainer,
    AccordionProps,
} from "../../../../../../components/accordion/Accordion.component"
import { AvailablePlatforms } from "../../../../../../components/available-platforms/AvailablePlatforms.component"
import { InfoIcon } from "../../../../../../components/info-icon/InfoIcon.component"
import { ComplexConfiguration } from "./complexConfiguration/ComplexConfiguration.component"
import { EffectSlider } from "./EffectSlider.component"
import { TrustEffectDescription } from "./TrustEffectDescription.component"
import styles from "./TrustFactorAccordion.module.scss"

export interface Props {
    trustFactors: TrustFactor[]
    onEditTrustFactor(trustFactor: TrustFactor): void
    onRemoveTrustFactor(trustFactor: TrustFactor): void
    disabled?: boolean
}

export function TrustFactorAccordion(props: Props): JSX.Element {
    const accordions = useGetAccordions(
        props.trustFactors,
        props.onEditTrustFactor,
        props.onRemoveTrustFactor,
        props.disabled
    )

    return <AccordionContainer accordions={accordions} />
}

function useGetAccordions(
    trustFactors: TrustFactor[],
    onEdit: (trustFactor: TrustFactor) => void,
    onRemove: (trustFactor: TrustFactor) => void,
    disabled?: boolean
): AccordionProps[] {
    const localization = useServiceLocalization()

    return trustFactors.map((trustFactor) => {
        const onRemoveClick: React.MouseEventHandler = (event) => {
            event.preventDefault()
            onRemove(trustFactor)
        }

        const onEffectChange = (effect: TRUST_EFFECT) => onEdit({ ...trustFactor, effect })

        return {
            defaultOpen: true,
            label: (
                <React.Fragment>
                    <div className={styles.labelContainer}>
                        <b className={styles.label}>{trustFactor.label}</b>
                        <InfoIcon tooltip={trustFactor.description} />
                    </div>
                    <div className={styles.factorDetailsContainer}>
                        <AvailablePlatforms
                            isMacOsAvailable={trustFactor.applicablePlatform[Platform.MACOS]}
                            isWindowsAvailable={trustFactor.applicablePlatform[Platform.WINDOWS]}
                            isLinuxAvailable={trustFactor.applicablePlatform[Platform.LINUX]}
                            isIosAvailable={trustFactor.applicablePlatform[Platform.IOS]}
                            isAndroidAvailable={trustFactor.applicablePlatform[Platform.ANDROID]}
                            isChromeBrowserAvailable={
                                trustFactor.applicablePlatform[Platform.CHROME]
                            }
                        />
                        <span className={styles.factorSource}>{trustFactor.source.name}</span>
                        <EffectLabel effect={trustFactor.effect} />
                    </div>
                    {!disabled && (
                        <Button
                            icon={IconType.TRASH}
                            asElement={ButtonElement.BUTTON}
                            buttonType={ButtonType.SECONDARY}
                            aria-label={localization.getString(
                                "deleteSomething",
                                trustFactor.label
                            )}
                            onClick={onRemoveClick}
                        />
                    )}
                </React.Fragment>
            ),
            children: (
                <div className={styles.trustEffectContainer}>
                    <EffectSlider
                        value={trustFactor.effect}
                        data={relevantEffects}
                        onChange={onEffectChange}
                        disabled={disabled}
                    />
                    <TrustEffectDescription effect={trustFactor.effect} />
                    <ComplexConfiguration
                        className={styles.complexConfiguration}
                        trustFactor={trustFactor}
                        onEdit={onEdit}
                        disabled={disabled}
                    />
                </div>
            ),
        }
    })
}

// TODO: Remove when we remove the Trust Factors view
const relevantEffects: TRUST_EFFECT[] = [
    TRUST_EFFECT.ALWAYS_DENY,
    TRUST_EFFECT.LOW_TRUST_LEVEL,
    TRUST_EFFECT.MEDIUM_TRUST_LEVEL,
    TRUST_EFFECT.NO_EFFECT,
]

interface EffectLabelProps {
    effect: TRUST_EFFECT
}

function EffectLabel(props: EffectLabelProps): JSX.Element {
    const localization = useServiceLocalization()

    const effectName = effectDict[props.effect]
    const effectClassName = styles[effectName]

    return (
        <div className={styles.trustEffectLabel}>
            <div className={classNames(styles.trustEffectCircle, effectClassName)} />
            {localization.getString(effectLabelDictionary[props.effect])}
        </div>
    )
}

const effectDict = CollectionUtil.createDictionary(TRUST_EFFECT)
