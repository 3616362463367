import React, { ReactNode, useRef } from "react"
import { useServiceLocalization } from "../../../../pre-v3/services/localization/Localization.service"
import { LanguageKey } from "../../../../pre-v3/services/localization/languages/en-US.language"
import { useFallbackState } from "../../../../pre-v3/utils/UseFallbackState.hook"
import { TabBar } from "../../../components/tab-bar/TabBar.component"
import {
    AppFilter,
    DnsFilters,
    UserEditableItpPolicyFields,
    isExcludedDevicesPolicy,
} from "../../../services/ItpPolicy.service"
import styles from "../ItpPolicy.module.scss"
import { AssignmentTab } from "./AssignmentTab.component"
import { ContentFilteringTab } from "./ContentFilteringTab.component"
import { ItpPolicyDevicesTab } from "./ItpPolicyDevicesTab.component"
import { ThreatsTab } from "./ThreatsTab.component"
import { AppFilteringTab } from "./AppFilteringTab.component"

export interface ItpPolicyFormProps {
    formErrors?: ReactNode[]
    disabled?: boolean
    isEdit: boolean
    onSubmit?: (formValues: UserEditableItpPolicyFields) => void
    onCancel?: () => void
    dnsFilters: DnsFilters
    appFilters: AppFilter[]
    value: UserEditableItpPolicyFields
    onChange: (updatedValue: UserEditableItpPolicyFields) => void
    roles: string[]
    step?: Step
    onStepChange?: (updatedStep: Step) => void
}

export function ItpPolicyForm(props: ItpPolicyFormProps): JSX.Element {
    const localization = useServiceLocalization()

    const {
        formErrors,
        disabled,
        isEdit,
        onCancel,
        dnsFilters,
        appFilters,
        value: formValues,
        onChange: setFormValues,
        roles,
    } = props

    const formRef = useRef<HTMLFormElement>(null)

    const [step, setStep] = useFallbackState<Step>(Step.THREATS, props.step, props.onStepChange)

    const steps = getSteps(formValues, disabled)

    function getTabProps(currentStep: Step) {
        return {
            id: currentStep,
            label: getStepLabel(currentStep),
            ariaControls: ariaControls[currentStep],
        }
    }

    function getStepLabel(step: Step) {
        return localization.getString(stepLabels[step])
    }

    function updateUserEditableProtectionProfileFields(
        updatedValues: Partial<UserEditableItpPolicyFields>
    ): UserEditableItpPolicyFields {
        const updatedFormValue = {
            ...formValues,
            ...updatedValues,
        }

        setFormValues(updatedFormValue)

        return updatedFormValue
    }

    function onSubmit() {
        props.onSubmit?.(formValues)
    }

    return (
        <div className={styles.protectionProfileForm}>
            {disabled || isEdit ? (
                <div className={styles.stepperContainer}>
                    <TabBar
                        className={styles.stepper}
                        tabs={steps.map(getTabProps)}
                        selectedTabId={step}
                        onChange={(currentStep) => {
                            if (isEdit && formRef.current) {
                                formRef.current.reportValidity()
                                const isValid = formRef?.current?.checkValidity()

                                if (isValid) {
                                    setStep(currentStep)
                                }
                            } else {
                                setStep(currentStep)
                            }
                        }}
                    />
                </div>
            ) : (
                <TabBar
                    type="stepper"
                    tabs={steps.map((step) => {
                        return {
                            id: step,
                            label: getStepLabel(step),
                            ariaControls: `${step}-tab`,
                        }
                    })}
                    onChange={setStep}
                    selectedTabId={step}
                    className={styles.stepper}
                />
            )}
            {step === Step.THREATS && (
                <ThreatsTab
                    threats={dnsFilters.threats}
                    value={formValues}
                    onSubmit={() => {
                        if (isEdit) {
                            onSubmit()
                        } else {
                            setStep(Step.CONTENT_FILTERING)
                        }
                    }}
                    disabled={disabled}
                    errors={formErrors}
                    onBack={onCancel}
                    isEdit={isEdit}
                    onChange={updateUserEditableProtectionProfileFields}
                />
            )}
            {step === Step.CONTENT_FILTERING && (
                <ContentFilteringTab
                    ref={formRef}
                    categories={dnsFilters.categories}
                    value={formValues}
                    onSubmit={() => {
                        if (isEdit) {
                            onSubmit()
                        } else {
                            setStep(Step.APP_FILTERING)
                        }
                    }}
                    disabled={disabled}
                    errors={formErrors}
                    isEdit={isEdit}
                    onChange={updateUserEditableProtectionProfileFields}
                    onBack={() => {
                        if (isEdit) {
                            onCancel?.()
                        } else {
                            setStep(Step.THREATS)
                        }
                    }}
                />
            )}
            {step === Step.APP_FILTERING && (
                <AppFilteringTab
                    ref={formRef}
                    apps={appFilters}
                    value={formValues}
                    onSubmit={() => {
                        if (isEdit) {
                            onSubmit()
                        } else {
                            setStep(Step.ASSIGNMENT)
                        }
                    }}
                    disabled={disabled}
                    errors={formErrors}
                    isEdit={isEdit}
                    onChange={updateUserEditableProtectionProfileFields}
                    onBack={() => {
                        if (isEdit) {
                            onCancel?.()
                        } else {
                            setStep(Step.CONTENT_FILTERING)
                        }
                    }}
                />
            )}
            {step === Step.ASSIGNMENT && (
                <AssignmentTab
                    ref={formRef}
                    value={formValues}
                    roles={roles}
                    onSubmit={onSubmit}
                    disabled={disabled}
                    errors={formErrors}
                    isEdit={isEdit}
                    isExcludedDevicesPolicy={isExcludedDevicesPolicy(formValues)}
                    onChange={updateUserEditableProtectionProfileFields}
                    onBack={() => {
                        if (isEdit) {
                            onCancel?.()
                        } else {
                            setStep(Step.APP_FILTERING)
                        }
                    }}
                />
            )}
            {step === Step.DEVICES && <ItpPolicyDevicesTab />}
        </div>
    )
}

export enum Step {
    THREATS = "THREATS",
    CONTENT_FILTERING = "CONTENT_FILTERING",
    APP_FILTERING = "APP_FILTERING",
    ASSIGNMENT = "ASSIGNMENT",
    DEVICES = "DEVICES",
}

export const ariaControls: Record<Step, string> = {
    [Step.THREATS]: "tab-panel-threats",
    [Step.CONTENT_FILTERING]: "tab-panel-content-filtering",
    [Step.APP_FILTERING]: "tab-panel-app-filtering",
    [Step.ASSIGNMENT]: "tab-panel-assignment",
    [Step.DEVICES]: "tab-panel-devices",
}

export const stepLabels: Record<Step, LanguageKey> = {
    [Step.THREATS]: "threatProtection",
    [Step.CONTENT_FILTERING]: "contentFiltering",
    [Step.APP_FILTERING]: "appFiltering",
    [Step.ASSIGNMENT]: "assignment",
    [Step.DEVICES]: "devices",
}

export function isStep(value = ""): value is Step {
    return value in Step
}

function getSteps(formValues: UserEditableItpPolicyFields, isDisabled = false): Step[] {
    if (isExcludedDevicesPolicy(formValues)) {
        return isDisabled ? [Step.ASSIGNMENT, Step.DEVICES] : [Step.ASSIGNMENT]
    }

    return isDisabled
        ? [Step.THREATS, Step.CONTENT_FILTERING, Step.APP_FILTERING, Step.ASSIGNMENT, Step.DEVICES]
        : [Step.THREATS, Step.CONTENT_FILTERING, Step.APP_FILTERING, Step.ASSIGNMENT]
}
