import classNames from "classnames/bind"
import React, { useState, ReactNode } from "react"
import { useHistory, useParams } from "react-router-dom"

import { useServiceLocalization } from "../../../../pre-v3/services/localization/Localization.service"
import { IconType } from "../../../../pre-v3/services/ActionBar.service"
import { decodeID, encodeID } from "../../../../pre-v3/utils/Url.util"
import { ROUTE, formatRoutePath } from "../../../../routes"
import {
    TrustIntegration,
    useDeleteTrustIntegration,
    useDownloadSyncStats,
    useGetSyncStats,
    useGetTrustIntegration,
} from "../../../services/TrustIntegration.service"
import trustIntegrationStyles from "../TrustIntegration.module.scss"
import { DateUtil } from "../../../../pre-v3/utils/Date.util"
import { TrustIntegrationForm } from "../form/TrustIntegrationForm.component"
import { TrustIntegrationSyncLogs } from "../form/TrustIntegrationSyncStats.component"
import { useServiceModal } from "../../../../pre-v3/services/Modal.service"
import { ErrorBanners } from "../../../components/banner/Banner.component"
import { Loader } from "../../../components/loader/Loader.component"
import { LargeMessage } from "../../../components/large-message/LargeMessage.component"
import { StatusCellRenderer } from "../status-cell-renderer/StatusCellRenderer.component"
import { PageHeading } from "../../../../components/page-heading/PageHeading.component"
import { Button, ButtonElement, ButtonType } from "../../../../components/button/Button.component"
import styles from "./TrustIntegrationOverview.module.scss"
// Note: Old components, move them to v3
import { SimpleTable } from "../../../../pre-v3/components/simple-table/SimpleTable.component"
import { TabBar, Tab } from "../../../../pre-v3/components/tab-bar/TabBar.component"
import { Tooltip } from "../../../components/tooltip/Tooltip.component"

interface Props {
    canWriteAll: boolean
}

export function TrustIntegrationOverview(props: Props): JSX.Element {
    const params = useParams<UrlParams>()
    const localization = useServiceLocalization()
    const history = useHistory()
    const modalService = useServiceModal()

    const tabs: Tab[] = [
        {
            label: localization.getString("specification"),
            value: 1,
        },
        {
            label: localization.getString("syncLog"),
            value: 2,
        },
    ]

    const [activeTab, setActiveTab] = useState<number>(tabs[0].value)

    const {
        data: trustIntegration,
        isFetching: isFetchingTrustIntegration,
        refetch: refetchTrustIntegration,
        error: trustIntegrationError,
    } = useGetTrustIntegration(decodeID(params.id))

    const {
        data: trustIntegrationSyncStats,
        isFetching: isFetchingTrustIntegrationSyncStats,
        refetch: refetchTrustIntegrationSyncStats,
        error: syncStatsError,
    } = useGetSyncStats(decodeID(params.id))

    const {
        mutate: downloadSyncStats,
        isLoading: isDownloadingSyncStats,
        error: downloadSyncStatsError,
    } = useDownloadSyncStats()

    const {
        mutateAsync: deleteTrustIntegration,
        isLoading: isDeletingTrustIntegration,
        error: deleteTrustIntegrationError,
    } = useDeleteTrustIntegration()

    const trustIntegrationNotFoundError = localization.getString("trustIntegrationNotFound")
    const trustIntegrationNotFound = trustIntegrationError === trustIntegrationNotFoundError

    const errors: ReactNode[] = [
        typeof deleteTrustIntegrationError === "string" && deleteTrustIntegrationError,
        typeof downloadSyncStatsError === "string" && downloadSyncStatsError,
        typeof syncStatsError === "string" && syncStatsError,
        typeof trustIntegrationError === "string" && trustIntegrationError,
    ]

    function onRefresh() {
        refetchTrustIntegration()
        refetchTrustIntegrationSyncStats()
    }

    function onEdit(): void {
        if (trustIntegration?.id) {
            history.push(
                formatRoutePath(ROUTE.INTEGRATIONS_EDIT, {
                    id: encodeID(trustIntegration.id),
                })
            )
        }
    }

    function onDelete(): void {
        modalService
            .openConfirmation(
                localization.getString("deleteIntegration"),
                localization.getString("areYouSureYouWantToDeleteThisIntegration")
            )
            .onClose(async () => {
                await deleteTrustIntegration(decodeID(params.id))
                history.push(ROUTE.INTEGRATIONS)
            })
    }

    function onTabChange(value: number): void {
        setActiveTab(value)
    }

    return (
        <section aria-labelledby={Id.HEADING} className={styles.section}>
            <header className={styles.header}>
                <PageHeading id={Id.HEADING}>{trustIntegration?.name}</PageHeading>
                <div className={styles.actionButtons}>
                    <Tooltip title={localization.getString("downloadSyncLogs")}>
                        <Button
                            icon={IconType.DOWNLOAD}
                            onClick={() =>
                                downloadSyncStats([
                                    decodeID(params.id),
                                    localization.getString("trustIntegrationSyncLogs"),
                                ])
                            }
                            asElement={ButtonElement.BUTTON}
                            buttonType={ButtonType.SECONDARY}
                            aria-label={localization.getString("downloadSyncLogs")}
                            disabled={isDownloadingSyncStats || !trustIntegration}
                        />
                    </Tooltip>
                    {props.canWriteAll && (
                        <>
                            <Tooltip title={localization.getString("edit")}>
                                <Button
                                    icon={IconType.PEN}
                                    onClick={onEdit}
                                    asElement={ButtonElement.BUTTON}
                                    buttonType={ButtonType.SECONDARY}
                                    aria-label={localization.getString("edit")}
                                    disabled={!trustIntegration}
                                />
                            </Tooltip>
                            <Tooltip title={localization.getString("delete")}>
                                <Button
                                    icon={IconType.TRASH}
                                    onClick={onDelete}
                                    asElement={ButtonElement.BUTTON}
                                    buttonType={ButtonType.SECONDARY}
                                    aria-label={localization.getString("delete")}
                                    disabled={isDeletingTrustIntegration || !trustIntegration}
                                />
                            </Tooltip>
                        </>
                    )}
                    <Tooltip title={localization.getString("refresh")}>
                        <Button
                            icon={IconType.REDO}
                            onClick={onRefresh}
                            asElement={ButtonElement.BUTTON}
                            buttonType={ButtonType.SECONDARY}
                            aria-label={localization.getString("refresh")}
                        />
                    </Tooltip>
                </div>
            </header>
            {trustIntegrationNotFound && (
                <LargeMessage className={trustIntegrationStyles.missingMessage}>
                    {trustIntegrationNotFoundError}
                </LargeMessage>
            )}
            {!trustIntegrationNotFound && (
                <ErrorBanners errors={errors} className={trustIntegrationStyles.errorContainer} />
            )}
            <div className={classNames(styles.overviewContainer, "pre-v3")}>
                <Loader
                    isLoading={
                        isFetchingTrustIntegrationSyncStats ||
                        isFetchingTrustIntegration ||
                        isDeletingTrustIntegration
                    }
                    center
                    medium
                >
                    {trustIntegration && (
                        <>
                            <div className={styles.overviewLeftContainer}>
                                <div
                                    className={classNames(
                                        trustIntegrationStyles.statusBlock,
                                        mapStatusStyle[trustIntegration.syncStatus]
                                    )}
                                >
                                    <div className={trustIntegrationStyles.status}>
                                        <p className={trustIntegrationStyles.statusLabel}>
                                            {localization.getString("status")}
                                        </p>
                                        <StatusCellRenderer
                                            data={trustIntegration}
                                            value={trustIntegration.syncStatus}
                                        />
                                    </div>
                                    <SimpleTable
                                        className={trustIntegrationStyles.statusTable}
                                        items={[
                                            {
                                                label: localization.getString("lastUpdated"),
                                                value: DateUtil.format(trustIntegration.updatedAt),
                                            },
                                        ]}
                                    />
                                </div>
                                <SimpleTable
                                    className={trustIntegrationStyles.simpleTable}
                                    items={[
                                        ...(trustIntegration.description
                                            ? [
                                                  {
                                                      label: localization.getString("description"),
                                                      value: trustIntegration.description,
                                                  },
                                              ]
                                            : []),
                                        {
                                            label: localization.getString("createdAt"),
                                            value: DateUtil.format(trustIntegration.createdAt),
                                        },
                                        {
                                            label: localization.getString("createdBy"),
                                            value: trustIntegration.createdBy,
                                        },
                                        {
                                            label: localization.getString("lastUpdatedBy"),
                                            value: trustIntegration.lastUpdatedBy,
                                        },
                                    ]}
                                />
                            </div>
                            <div className={styles.overviewRightContainer}>
                                <TabBar tabs={tabs} onChange={onTabChange} />
                                {activeTab === 1 && (
                                    <TrustIntegrationForm
                                        trustIntegration={trustIntegration}
                                        disabled
                                        canWriteAll={props.canWriteAll}
                                    />
                                )}
                                {activeTab === 2 && (
                                    <TrustIntegrationSyncLogs
                                        disabled
                                        initialValue={trustIntegrationSyncStats || []}
                                    />
                                )}
                            </div>
                        </>
                    )}
                </Loader>
            </div>
        </section>
    )
}

interface UrlParams {
    id: string
}

const mapStatusStyle: Record<TrustIntegration["syncStatus"], string> = {
    active: trustIntegrationStyles.success,
    inactive: trustIntegrationStyles.inactive,
    error: trustIntegrationStyles.error,
    pending: trustIntegrationStyles.warning,
}

enum Id {
    HEADING = "heading",
}
