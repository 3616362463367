import React from "react"

import { Banner, Variant, getLinkAction } from "../../components/banner/Banner.component"
import { useServiceLinks } from "../../pre-v3/services/link/Link.service"
import { useServiceLocalization } from "../../pre-v3/services/localization/Localization.service"
import { ROUTE } from "../../routes"
import { AppText } from "../../v3/components/app-text/AppText.component"

export function LicenseEnforcementBanner(): JSX.Element {
    const linkService = useServiceLinks()
    const localizationService = useServiceLocalization()

    return (
        <Banner
            title={localizationService.getString("licensesLimitReached")}
            label={
                <AppText
                    ls={{
                        key: "licensesLimitReachedDescription",
                        replaceVals: [ROUTE.LICENSES],
                    }}
                />
            }
            isLarge
            variant={Variant.ERROR}
            action={getLinkAction(
                localizationService.getString("contactSales"),
                linkService.getLink("salesContact")
            )}
        />
    )
}
