import React, { useState, useEffect, ReactNode } from "react"

import { Button, ButtonElement, ButtonType } from "../../../../components/button/Button.component"
import { useServiceLocalization } from "../../../../pre-v3/services/localization/Localization.service"
import { DateUtil } from "../../../../pre-v3/utils/Date.util"
import { ErrorBanners } from "../../../components/banner/Banner.component"
import { SelectInput } from "../../../components/select-input/SelectInput.component"
import { TrustFactorType as TRUST_FACTOR_NAME } from "../../../services/shared/TrustFactorType"
import { useHasGranularTrustMigrationOccurred } from "../../../services/Org.service"
import {
    CrowdStrikeIntegration,
    SentinelOneIntegration,
    Platform,
    TrustIntegration,
    ZtaSeverity,
    WorkspaceOneIntegration,
} from "../../../services/TrustIntegration.service"
import {
    isCompliant,
    isNotActiveThreat,
    isRegisteredWith,
    isWS1RegisteredWith,
    isZtaScore,
} from "../../../services/TrustProfile.service"
import trustIntegrationStyles from "../TrustIntegration.module.scss"
import { CrowdStrikeApiIntegration } from "./CrowdStrikeApiCredentials.component"
import { PlatformSelection } from "./PlatformSelection.component"
import { SentinelOneApiCredentials } from "./SentinelOneApiCredentials.component"
import styles from "./TrustIntegrationForm.module.scss"
import { Input } from "../../../components/input/Input.component"

// Note: Old components, move them to v3
import { Form } from "../../../../pre-v3/components/form/Form"
import { FormSection } from "../../../../pre-v3/components/form-section/FormSection"
import { FormLabel } from "../../../../pre-v3/components/form-label/FormLabel"
import { FormRow } from "../../../../pre-v3/components/form-row/FormRow"
import { WorkspaceOneApiCredentials } from "./WorkspaceOneApiCredentials.component"

interface Props {
    trustIntegration?: TrustIntegration
    disabled?: boolean
    onSubmit?: (trustIntegration: TrustIntegration) => void
    onCancel?: () => void
    isSubmitLoading?: boolean
    submitText?: string
    errors?: ReactNode[]
    canWriteAll?: boolean
}

export function TrustIntegrationForm(props: Props) {
    const {
        trustIntegration,
        onSubmit,
        disabled,
        onCancel,
        isSubmitLoading,
        submitText,
        errors,
        canWriteAll,
    } = props

    const localization = useServiceLocalization()

    const { data: hasMigrated } = useHasGranularTrustMigrationOccurred()

    const integrationPartnerOptions: IntegrationPartnerOption[] = [
        {
            value: "crowdstrike",
            displayName: localization.getString("crowdstrike"),
        },
        {
            value: "sentinelone",
            displayName: localization.getString("sentinelone"),
        },
        // Decided to not to release in jan due to remaining App work
        // enable when app work is done https://banyan-sec.atlassian.net/browse/BC-11783
        // {
        //     value: "workspaceone",
        //     displayName: localization.getString("workspaceone"),
        // },
    ]

    const ztaScoreOptions: ZtaScoreOptions[] = [
        {
            displayName: localization.getString(
                "moderateAllowOnlyUsersAndDevicesWithACrowdStrikeZTAof65orHigher"
            ),
            value: "65",
        },
        {
            displayName: localization.getString(
                "strictAllowOnlyUsersAndDevicesWithACrowdStrikeZTAof75orHigher"
            ),
            value: "75",
        },
    ]

    const [name, setName] = useState<string>("")
    const [description, setDescription] = useState<string>("")
    const [integrationPartner, setIntegrationPartner] = useState<
        TrustIntegration["integrationPartner"] | ""
    >("")
    const [apiId, setApiId] = useState<string>("")
    const [apiEndpoint, setApiEndpoint] = useState<string>("")
    const [apiKey, setApiKey] = useState<string>("")
    const [apiSecret, setApiSecret] = useState<string>("")
    const [apiKeyExpiryProps, setApiKeyExpiryProps] = useState<ApiKeyExpiryProps>()
    const [ztaScorePlatforms, setZtaScorePlatforms] = useState<Platform[]>([])
    const [registeredPlatforms, setRegisteredPlatforms] = useState<Platform[]>([])
    const [notActiveThreatPlatforms, setNotActiveThreatPlatforms] = useState<Platform[]>([])
    const [ws1RegisteredPlatforms, setWS1RegisteredPlatforms] = useState<Platform[]>([])
    const [compliantPlatforms, setCompliantPlatforms] = useState<Platform[]>([])
    const [ztaSeverity, setZtaSeverity] = useState<ZtaSeverity | "">("")
    const [clientId, setClientId] = useState("")
    const [clientSecret, setClientSecret] = useState("")
    const [tokenUrl, setTokenUrl] = useState("")
    const [saasUrl, setSaasUrl] = useState("")

    const isCrowdStrike = integrationPartner === "crowdstrike"
    const isSentinelOne = integrationPartner === "sentinelone"
    const isWorkspaceOne = integrationPartner === "workspaceone"
    const isEditing = Boolean(trustIntegration) && !disabled

    useEffect(() => {
        if (trustIntegration) {
            setIntegrationPartner(trustIntegration.integrationPartner)
            setName(trustIntegration.name)
            setDescription(trustIntegration.description ?? "")

            if (trustIntegration.integrationPartner === "crowdstrike") {
                const crowdStrikeIntegration = trustIntegration as CrowdStrikeIntegration
                const ztaScore = crowdStrikeIntegration.factors.find(isZtaScore)

                setApiId(crowdStrikeIntegration.apiId)
                setApiSecret(crowdStrikeIntegration.apiSecret)
                setApiEndpoint(crowdStrikeIntegration.apiEndpoint)

                if (ztaScore) {
                    setZtaScorePlatforms(ztaScore.platforms)
                    setZtaSeverity(ztaScore.severity)
                }
            } else if (trustIntegration.integrationPartner === "sentinelone") {
                const sentinelOneIntegration = trustIntegration as SentinelOneIntegration
                const apiKeyExpiryString = sentinelOneIntegration.apiKeyExpiry

                const activeThreats = sentinelOneIntegration.factors.find(isNotActiveThreat)
                const registeredWith = sentinelOneIntegration.factors.find(isRegisteredWith)

                setApiKey(sentinelOneIntegration.apiKey)
                setApiEndpoint(sentinelOneIntegration.apiEndpoint)
                setRegisteredPlatforms(registeredWith?.platforms || [])
                setNotActiveThreatPlatforms(activeThreats?.platforms || [])
                if (apiKeyExpiryString) {
                    const timestamp = DateUtil.convertLargeTimestamp(Number(apiKeyExpiryString))
                    const expiryDATE = new Date(timestamp)
                    const today = new Date()
                    const nextMonth = new Date(new Date().setMonth(today.getMonth() + 1))
                    const isExpired = today > expiryDATE

                    setApiKeyExpiryProps({
                        expiryDate: `${expiryDATE.getMonth()}/${expiryDATE.getDate()}/${expiryDATE.getFullYear()}`,
                        isExpired,
                        isGoingToExpireInAMonth: !isExpired && expiryDATE < nextMonth,
                    })
                }
            } else {
                const workspaceoneIntegration = trustIntegration as WorkspaceOneIntegration
                const isWS1Compliant = workspaceoneIntegration.factors.find(isCompliant)
                const registeredWith = workspaceoneIntegration.factors.find(isWS1RegisteredWith)

                setWS1RegisteredPlatforms(registeredWith?.platforms || [])
                setCompliantPlatforms(isWS1Compliant?.platforms || [])
                setTokenUrl(workspaceoneIntegration.tokenUrl)
                setSaasUrl(workspaceoneIntegration.saasUrl)
                setClientId(workspaceoneIntegration.clientId)
                setClientSecret(workspaceoneIntegration.clientSecret)
            }
        } else {
            setZtaSeverity(ztaScoreOptions[0].value)
            setIntegrationPartner(integrationPartnerOptions[0].value)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [trustIntegration])

    function onFormSubmit() {
        if (onSubmit) {
            const integration = {
                name,
                description,
                integrationPartner,
            } as TrustIntegration

            if (trustIntegration?.id) {
                integration.id = trustIntegration.id
            }

            if (isCrowdStrike) {
                const crowdStrikeIntegration = {
                    ...integration,
                    apiId,
                    apiSecret,
                    apiEndpoint,
                    factors: [
                        {
                            name: TRUST_FACTOR_NAME.ZTA_SCORE,
                            platforms: ztaScorePlatforms,
                            severity: ztaSeverity,
                        },
                    ],
                } as CrowdStrikeIntegration

                onSubmit(crowdStrikeIntegration)
                return
            }
            if (isSentinelOne) {
                const sentinelOneIntegration = {
                    ...integration,
                    apiEndpoint,
                    apiKey,
                    factors: [
                        {
                            name: TRUST_FACTOR_NAME.NOT_ACTIVE_THREAT,
                            platforms: notActiveThreatPlatforms,
                        },
                        { name: TRUST_FACTOR_NAME.REGISTERED_WITH, platforms: registeredPlatforms },
                    ],
                } as SentinelOneIntegration

                onSubmit(sentinelOneIntegration)
                return
            }
            if (isWorkspaceOne) {
                const workspaceoneIntegration = {
                    ...integration,
                    tokenUrl,
                    saasUrl,
                    clientId,
                    clientSecret,
                    factors: [
                        {
                            name: TRUST_FACTOR_NAME.WS1_REGISTERED_WITH,
                            platforms: ws1RegisteredPlatforms,
                        },
                        {
                            name: TRUST_FACTOR_NAME.WS1_IS_COMPLIANT,
                            platforms: compliantPlatforms,
                        },
                    ],
                } satisfies WorkspaceOneIntegration
                onSubmit(workspaceoneIntegration)
            }
        }
    }

    return (
        <Form onSubmit={onFormSubmit} labelWidth={160} display="grid" className={styles.form}>
            <FormSection
                title={localization.getString("integrationDetails")}
                className={styles.formSection}
            >
                <FormLabel
                    title={localization.getString("integrationName")}
                    htmlFor="integrationName"
                >
                    <Input
                        type="text"
                        value={name}
                        onChange={(e) => setName?.(e.target.value)}
                        placeholder={localization.getString("enterIntegrationName")}
                        className={styles.input}
                        disabled={disabled}
                        required
                    />
                </FormLabel>
                <FormLabel title={localization.getString("description")} htmlFor="description">
                    <Input
                        type="text"
                        value={description}
                        onChange={(e) => setDescription?.(e.target.value)}
                        placeholder={localization.getString("enterDescription")}
                        className={styles.input}
                        disabled={disabled}
                    />
                </FormLabel>
                <FormLabel
                    title={localization.getString("integrationPartner")}
                    htmlFor="integrationPartner"
                    inline
                >
                    <SelectInput
                        options={integrationPartnerOptions}
                        value={integrationPartner}
                        className={styles.input}
                        disabled={disabled || isEditing}
                        onChange={setIntegrationPartner}
                        required
                    />
                </FormLabel>
            </FormSection>
            <FormSection
                title={localization.getString("apiCredentials")}
                className={styles.formSection}
            >
                {isCrowdStrike && (
                    <CrowdStrikeApiIntegration
                        apiId={apiId}
                        onApiIdChange={setApiId}
                        onApiEndpointChange={setApiEndpoint}
                        apiEndpoint={apiEndpoint}
                        apiSecret={apiSecret}
                        onApiSecretChange={setApiSecret}
                        disabled={disabled || false}
                        canWriteAll={props.canWriteAll}
                    />
                )}
                {isSentinelOne && (
                    <SentinelOneApiCredentials
                        expiryDate={apiKeyExpiryProps?.expiryDate}
                        apiEndpoint={apiEndpoint}
                        onApiEndpointChange={setApiEndpoint}
                        apiKey={apiKey}
                        onApiKeyChange={setApiKey}
                        disabled={disabled || false}
                        canWriteAll={props.canWriteAll}
                    />
                )}
                {isWorkspaceOne && (
                    <WorkspaceOneApiCredentials
                        clientId={clientId}
                        onClientIdChange={setClientId}
                        saasUrl={saasUrl}
                        onSaasUrlChange={setSaasUrl}
                        tokenUrl={tokenUrl}
                        onTokenUrlChange={setTokenUrl}
                        clientSecret={clientSecret}
                        onClientSecretChange={setClientSecret}
                        disabled={disabled || false}
                        canWriteAll={props.canWriteAll}
                    />
                )}
            </FormSection>
            {!hasMigrated && (
                // TODO: Remove these components once all Orgs migrate
                <FormSection
                    title={localization.getString("importedTrustFactorAndApplicableDeviceTypes")}
                    className={styles.formSection}
                    constrainWidth={false}
                >
                    <PlatformSelection
                        disabled={disabled}
                        ztaScorePlatforms={ztaScorePlatforms}
                        onZtaScorePlatformsChange={setZtaScorePlatforms}
                        integrationPartner={integrationPartner}
                        registeredPlatforms={registeredPlatforms}
                        onRegisteredPlatformsChange={setRegisteredPlatforms}
                        notActiveThreatPlatforms={notActiveThreatPlatforms}
                        onNotActiveThreatPlatformsChange={setNotActiveThreatPlatforms}
                    />
                    {isCrowdStrike && (
                        <FormLabel
                            title={localization.getString("ztaScoreSeverity")}
                            htmlFor="ztaSeverity"
                            inline
                            className={styles.formLabel}
                        >
                            <SelectInput
                                options={ztaScoreOptions}
                                className={styles.ztaSeverity}
                                disabled={disabled}
                                value={ztaSeverity}
                                onChange={setZtaSeverity}
                                required
                            />
                        </FormLabel>
                    )}
                </FormSection>
            )}
            {errors && (
                <ErrorBanners errors={errors} className={trustIntegrationStyles.errorContainer} />
            )}
            {!disabled && (
                <FormRow className={styles.formRow}>
                    {onCancel && (
                        <Button
                            asElement={ButtonElement.BUTTON}
                            buttonType={ButtonType.SECONDARY}
                            onClick={onCancel}
                            className={styles.button}
                        >
                            {localization.getString("cancel")}
                        </Button>
                    )}
                    <Button
                        asElement={ButtonElement.BUTTON}
                        buttonType={ButtonType.PRIMARY}
                        className={styles.button}
                        type="submit"
                        loading={isSubmitLoading}
                    >
                        {submitText || localization.getString("save")}
                    </Button>
                </FormRow>
            )}
        </Form>
    )
}

interface IntegrationPartnerOption {
    value: TrustIntegration["integrationPartner"]
    displayName: string
}

interface ZtaScoreOptions {
    value: ZtaSeverity
    displayName: string
}

type ApiKeyExpiryProps =
    | {
          expiryDate: string
          isExpired: boolean
          isGoingToExpireInAMonth: boolean
      }
    | undefined
