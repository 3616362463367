import React from "react"

import { useServiceLinks } from "../../../pre-v3/services/link/Link.service"
import { useServiceLocalization } from "../../../pre-v3/services/localization/Localization.service"
import { Button, ButtonElement, ButtonType } from "../../../components/button/Button.component"

export interface UpgradeButtonProps {
    className?: string
}

export function UpgradeButton(props: UpgradeButtonProps): JSX.Element {
    const links = useServiceLinks()
    const localization = useServiceLocalization()

    return (
        <Button
            to={links.getLink("teamEditionUpgrade")}
            className={props.className}
            buttonType={ButtonType.PRIMARY}
            asElement={ButtonElement.LINK}
        >
            {localization.getString("upgradeNow")}
        </Button>
    )
}
