import React from "react"
import { Redirect, Route, Switch } from "react-router-dom"

import { ROUTE } from "../../../routes"
import { RegisteredDomainAdd } from "./add/RegisteredDomainAdd.view"
import { RegisteredDomainOverview } from "./overview/RegisteredDomainOverview.view"
import { RegisteredDomainList } from "./list/RegisteredDomainList.view"
import { useFeatureFlags } from "../../../hooks/useFeatureFlags.hook"
import { Loader } from "../../components/loader/Loader.component"
import { useServiceLocalization } from "../../../pre-v3/services/localization/Localization.service"
import { Banner, Variant } from "../../../components/banner/Banner.component"

export function RegisteredDomain(): JSX.Element {
    const { data: featureFlags, isLoading, isError, error: featureFlagError } = useFeatureFlags()
    const localization = useServiceLocalization()
    if (isLoading) {
        return <Loader center medium title={localization.getString("loading")} />
    }

    if (isError) {
        return <Banner label={String(featureFlagError)} variant={Variant.ERROR} />
    }
    return (
        <Switch>
            <Route path={ROUTE.REGISTERED_DOMAINS_ADD} component={RegisteredDomainAdd}>
                <RegisteredDomainAdd
                    enableAccessTierGroups={featureFlags?.adminConsole.enableAccessTierGroups}
                    canAddRegisteredDomain={featureFlags?.adminConsole.canAddRegisteredDomain}
                />
            </Route>
            <Route path={ROUTE.REGISTERED_DOMAINS_DETAILS} component={RegisteredDomainOverview}>
                <RegisteredDomainOverview
                    enableAccessTierGroups={featureFlags?.adminConsole.enableAccessTierGroups}
                    canDeleteRegisteredDomain={featureFlags?.adminConsole.canDeleteRegisteredDomain}
                />
            </Route>
            <Route path={ROUTE.REGISTERED_DOMAINS} component={RegisteredDomainList}>
                <RegisteredDomainList
                    enableAccessTierGroups={featureFlags?.adminConsole.enableAccessTierGroups}
                    canAddRegisteredDomain={featureFlags?.adminConsole.canAddRegisteredDomain}
                />
            </Route>
            <Redirect to={ROUTE.REGISTERED_DOMAINS} />
        </Switch>
    )
}
