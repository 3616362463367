import React, { ReactNode, SyntheticEvent } from "react"
import { ICellRendererParams } from "ag-grid-community"

import { ROUTE, formatRoutePath } from "../../../../../../routes"
import { Bind } from "../../../../../decorators/Bind.decorator"
import { encodeID } from "../../../../../utils/Url.util"
import OrgAdminCellRendererTemplate from "./OrgAdminCellRenderer.template"

export class OrgAdminCellRenderer extends React.Component<
    OrgAdminCellRendererProps,
    OrgAdminCellRendererState
> {
    public static getDerivedStateFromProps(
        props: OrgAdminCellRendererProps
    ): OrgAdminCellRendererState {
        return {
            link: formatRoutePath(props.path, {
                id: encodeID(props.data.email),
            }),
        }
    }

    public state: OrgAdminCellRendererState = { link: "" }

    public render(): ReactNode {
        return OrgAdminCellRendererTemplate.call(this)
    }

    public refresh(): boolean {
        return true
    }

    @Bind
    private clickHandler(event: SyntheticEvent): void {
        event.preventDefault()
        this.props.context.history.push(this.state.link)
    }
}

interface OrgAdminCellRendererProps extends ICellRendererParams {
    context: any
    path: ROUTE
}

interface OrgAdminCellRendererState {
    link: string
}
