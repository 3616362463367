import React from "react"
import styles from "./Banner.module.scss"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import classNames from "classnames/bind"

export default function () {
    return (
        <div className={classNames(styles.banner, this.props.type, this.props.className)}>
            {this.props.icon && (
                <div className={styles.bannerIcon}>
                    <FontAwesomeIcon icon={this.props.icon} />
                </div>
            )}
            <div className={styles.bannerContent}>{this.props.children}</div>
        </div>
    )
}
