import { Cache, CacheBuster } from "../../pre-v3/decorators/Cache.decorator"
import { BaseApi } from "./Base.api"

export class TrustProfileApi extends BaseApi {
    public static FAILED_TO_UPDATE_TRUST_PROFILE = "FAILED_TO_UPDATE_TRUST_PROFILE" as const

    public getTrustProfiles(): Promise<TrustProfileRes[]> {
        return this.get("/api/v2/granular_trust/profile", {
            default: this.localization.getString("failedToFetchTrustProfiles"),
        })
    }

    public reorderTrustProfiles(req: ReorderTrustProfilesReq): Promise<TrustProfileRes[]> {
        return this.put("/api/v2/granular_trust/profile/priority", req, {
            default: this.localization.getString("failedToReorderTrustProfiles"),
        })
    }

    public createTrustProfile(trustProfile: NewTrustProfileReq): Promise<TrustProfileRes> {
        return this.post("/api/v2/granular_trust/profile", trustProfile, {
            409: this.localization.getString(
                "somethingNamedAlreadyExists",
                this.localization.getString("trustProfile"),
                trustProfile.display_name
            ),
            default: this.localization.getString("failedToCreateTrustProfiles"),
        })
    }

    public getDefaultTrustProfile(): Promise<TrustProfileRes> {
        return this.get("/api/v2/granular_trust/profile/default", {
            default: this.localization.getString("trustProfileNotFound"),
        })
    }

    public getPlatformConfig(): Promise<PlatformConfig> {
        return this.get("/api/v1/trustscore_config/device/latest_os", {
            default: this.localization.getString("failedToFetchPlatformConfiguration"),
        })
    }

    public async getTrustProfile(id: string): Promise<TrustProfileRes> {
        const trustProfileNotFound = this.localization.getString("trustProfileNotFound")

        const [profile] =
            (await this.get<TrustProfileRes[]>(`/api/v2/granular_trust/profile?id=${id}`, {
                default: trustProfileNotFound,
            })) ?? []

        if (!profile) throw trustProfileNotFound

        return profile
    }

    public updateTrustProfile(trustProfile: TrustProfileRes): Promise<TrustProfileRes> {
        return this.put("/api/v2/granular_trust/profile", trustProfile, {
            409: this.localization.getString(
                "somethingNamedAlreadyExists",
                this.localization.getString("trustProfile"),
                trustProfile.display_name
            ),
            default: TrustProfileApi.FAILED_TO_UPDATE_TRUST_PROFILE,
        })
    }

    public deleteTrustProfile(req: DeleteTrustProfileReq): Promise<void> {
        return this.delete("/api/v2/granular_trust/profile", req, {
            default: this.localization.getString("failedToDeleteTrustProfiles"),
        })
    }

    public getTrustFactors(): Promise<TrustFactorRes[]> {
        return this.get("/api/v2/granular_trust/factor", {
            default: this.localization.getString("failedToFetchTrustFactors"),
        })
    }

    public getTrustScoreTtl(): Promise<TrustScoreTTLRes> {
        return this.get("/api/v1/trustscore_config/device/trustscore_ttl", {
            default: this.localization.getString("failedToGetTheTrustLevelExpiration"),
        })
    }

    public updateTrustScoreTtl(body: UpdateTrustScoreTTLReq): Promise<void> {
        return this.put("/api/v1/trustscore_config/device/trustscore_ttl", body, {
            default: this.localization.getString("failedToUpdateTheTrustLevelExpiration"),
        })
    }

    @Cache()
    public getBanyanAppVersions(_cacheBuster?: CacheBuster): Promise<BanyanAppVersionsRes> {
        return this.get("/api/v2/granular_trust/extended_factor/banyan_app_versions", {
            default: this.localization.getString("failedToFetchCseAppVersions"),
        })
    }
}

export interface TrustProfileRes {
    profile_id: string
    profile_name: string
    display_name: string
    description: string
    priority: number
    created_at: number
    created_by: string
    updated_at: number
    updated_by: string
    applied_platforms: PlatformRes[]
    assignment_criteria: AssignmentCriteriaRes
    profile_factors: TrustFactorRes[]
    unused_factors: TrustFactorRes[]
    extra_details: TrustProfileExtraDetailsRes
}

export interface NewTrustProfileReq extends Partial<TrustProfileRes> {
    display_name: string
}

export interface AssignmentCriteriaRes {
    assigned_groups: string[]
    assigned_serial_numbers: string[]
    managed_by_mdm: boolean
    device_ownership: DeviceOwnershipRes[]
}

export interface TrustFactorRes {
    id: string
    factor_id: string
    name: string
    display_name: string
    description: string
    source: string
    source_id: string
    type: TrustFactorTypeRes
    applicable_platforms: PlatformRes[]
    // TODO: Remove from the Response after removing the Trust Factor view
    active: boolean
    effect: {
        trust_level: TrustLevelRes
    }
    config?: FactorConfigRes[]
}

export interface AppCheckFactorRes extends TrustFactorRes {
    name: "OrgPreferredAppsRunning"
    preferred_apps_config: PreferredAppConfigRes[]
}

export type TrustFactorTypeRes = "affirmable" | "integration" | "extended"
export type PlatformRes = "macos" | "windows" | "linux" | "ios" | "android" | "chrome"
export type DeviceOwnershipRes =
    | "Corporate Dedicated"
    | "Corporate Shared"
    | "Employee Owned"
    | "Other"
export type TrustLevelRes = "AlwaysDeny" | "Low" | "Medium" | "High"

export interface FactorConfigRes {
    id?: string
    key: string
    value: string
}

interface TrustProfileExtraDetailsRes {
    device_count: number
    last_device_count_sync: number
}

export interface PreferredAppConfigRes {
    id?: string
    mandatory: boolean
    name: string
    platform: PlatformRes
    process: string
}

interface ReorderTrustProfilesReq {
    trust_profile_priorities: ProfilePriorityPairReq[]
}

export interface ProfilePriorityPairReq {
    profile_id: string
    priority: number
}

interface DeleteTrustProfileReq {
    profile_id: string
}

interface TrustScoreTTLRes {
    ttl: number
    units: "hours"
}

interface UpdateTrustScoreTTLReq {
    kind: "BanyanTrustscoreTTLConfig"
    apiVersion: "ts.banyanops.com/v1"
    spec: TrustScoreTTLRes
}

export interface PlatformConfig {
    num_releases: NumberMap
}

export type BanyanAppVersionsRes = Record<PlatformRes, string[]>
