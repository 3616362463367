import { QueryClientProvider } from "@tanstack/react-query"
import React from "react"
import { BrowserRouter, Route, Switch } from "react-router-dom"

import { SmartSearchProvider } from "../../context/SmartSearch.context"
import useTitle from "../../hooks/useTitle.hook"
import { ModalController } from "../../pre-v3/components/modal/controller/ModalController.component"
import { useAuthService } from "../../pre-v3/services/Auth.service"
import { queryClient } from "../../queryClient"
import { ThemeProvider } from "../../theme"
import { ROUTE } from "../../routes"
import { useBroadcastChannelService } from "../../v3/services/BroadcastChannel.service"
import { AppBannerProvider } from "../../v3/views/app/AppBannerProvider.component"
import { ToastProvider } from "../../components/toast/Toast.components"
import { AdminConsole } from "../admin-console/AdminConsole"
import { MomConsole } from "../mom-console/MomConsole"
import { MspConsole } from "../msp-console/MspConsole"
import { Oidc } from "../oidc/Oidc"
import { Unauthenticated } from "../unauthenticated/Unauthenticated"
import { AllRedirects } from "./AllRedirects"

export function App(): JSX.Element {
    return (
        <QueryClientProvider client={queryClient}>
            <BrowserRouter>
                <SmartSearchProvider>
                    <AppBannerProvider>
                        <ThemeProvider>
                            <ToastProvider>
                                <ConfiguredApp />
                                <ModalController />
                            </ToastProvider>
                        </ThemeProvider>
                    </AppBannerProvider>
                </SmartSearchProvider>
            </BrowserRouter>
        </QueryClientProvider>
    )
}

export function ConfiguredApp(): JSX.Element {
    useInit()
    useTitle([])

    return (
        <Switch>
            <Route path={ROUTE.ADMIN_CONSOLE} component={AdminConsole} />
            <Route path={ROUTE.MOM_CONSOLE} component={MomConsole} />
            <Route path={ROUTE.MSP_CONSOLE} component={MspConsole} />
            <Route path={ROUTE.AUTH} component={Unauthenticated} />
            <Route path={ROUTE.OIDC} component={Oidc} />
            <AllRedirects />
        </Switch>
    )
}

function useInit() {
    const broadcastChannelService = useBroadcastChannelService()
    const authService = useAuthService()

    React.useEffect(() => {
        broadcastChannelService.initialize(authService.logout.bind(authService))

        return () => {
            broadcastChannelService.destroy()
        }
    }, [])
}
