import { IconProp } from "@fortawesome/fontawesome-svg-core"
import { faEye, faEyeSlash } from "@fortawesome/pro-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import classNames from "classnames/bind"
import React from "react"

import { OldInput, Props as InputProps } from "../old-input/OldInput.component"
import styles from "./PasswordInput.module.scss"
import { useServiceLocalization } from "../../../pre-v3/services"

export type Props = Omit<InputProps, "type">

/**
 * Provides a password input that allows the User to toggle the visibility of
 * its input
 */
export const PasswordInput = React.forwardRef<HTMLInputElement, Props>((props, ref) => {
    const {
        className,
        onChange: originalOnChange,
        onChangeValue: originalOnChangeValue,
        ...inputProps
    } = props

    const [isHidden, setIsHidden] = React.useState(true)
    const inputRef = React.useRef<HTMLInputElement>(null)
    const localization = useServiceLocalization()

    React.useImperativeHandle(ref, () => inputRef.current!)

    const onChange: React.ChangeEventHandler<HTMLInputElement> = (event) => {
        event.preventDefault()
        originalOnChange?.(event)
        originalOnChangeValue?.(event.target.value)
    }

    const onHiddenClick: React.MouseEventHandler<HTMLButtonElement> = (event) => {
        event.preventDefault()
        setIsHidden(!isHidden)
    }

    const { inputType, eyeIcon } = getHiddenProps(isHidden)

    return (
        <div className={classNames(styles.container, className)}>
            <OldInput
                {...inputProps}
                onChange={onChange}
                type={inputType}
                className={styles.input}
                ref={inputRef}
            />
            <button
                type="button"
                onClick={onHiddenClick}
                className={styles.button}
                disabled={props.disabled}
                tabIndex={-1}
                onMouseDown={(event) => {
                    event.preventDefault()
                }}
                aria-label={`${
                    isHidden ? localization.getString("show") : localization.getString("hide")
                } ${inputProps["aria-label"]}`}
            >
                <FontAwesomeIcon icon={eyeIcon} />
            </button>
        </div>
    )
})

interface HiddenProps {
    inputType: React.HTMLInputTypeAttribute
    eyeIcon: IconProp
}

function getHiddenProps(isHidden: boolean): HiddenProps {
    if (isHidden) {
        return { inputType: "password", eyeIcon: faEye }
    }
    return { inputType: "text", eyeIcon: faEyeSlash }
}
