import React from "react"

import { ButtonAsLink } from "../../../../v3/components/button/ButtonAsLink.component"
import styles from "./PreferredAppsSettings.module.scss"
import { Toolbar } from "../../../components/toolbar/Toolbar.component"
import { IconButton } from "../../../components/icon-button/IconButton.component"
import { faPlus } from "@fortawesome/pro-solid-svg-icons"
import { Grid } from "../../../components/grid/Grid.component"
import { ApplicationNameCellRenderer } from "./name-cell-renderer/ApplicationNameCellRenderer.component"
import { PreferredAppsOSCellRenderer } from "./os-cell-renderer/PreferredAppsOSCellRenderer.component"
import classNames from "classnames/bind"

export default function () {
    // the text to show for the exclusions
    let exclusionText = this.localizationService.getString("none")
    if (this.state.appliedDevices === null) {
        exclusionText = this.localizationService.getString("loading") + "..."
    } else if (this.state.appliedDevices.length > 0) {
        exclusionText = this.state.appliedDevices.map(({ displayName }) => displayName).join(", ")
    }

    return (
        <>
            <div className={styles.preferredAppText}>
                {this.localizationService.getString("preferredAppsExclusionDescription")}
                <ButtonAsLink className={styles.exclusionLink} onClick={this.openExclusionModal}>
                    {exclusionText}
                </ButtonAsLink>
            </div>
            <div className={classNames([styles.gridWithToolbar, styles.cappedGrid])}>
                <Toolbar onSearchChange={this.onSearchChange}>
                    <div></div>
                    <IconButton
                        icon={faPlus}
                        className={styles.toolbarButton}
                        onClick={this.onAddApp}
                        primary
                        label={this.localizationService.getString("addApp")}
                    />
                </Toolbar>
                <div className={styles.gridContainer}>
                    <Grid
                        onGridReady={this.onGridReady}
                        columnDefs={this.columns}
                        context={{ history: this.props.history }}
                        pagination
                        onCellDoubleClicked={this.onCellDoubleClicked}
                        components={{
                            preferredAppsOSCellRenderer: PreferredAppsOSCellRenderer,
                            preferredAppNameCellRenderer: ApplicationNameCellRenderer,
                        }}
                    ></Grid>
                </div>
            </div>
        </>
    )
}
