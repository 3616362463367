import React from "react"
import styles from "./Switch.module.scss"
import classNames from "classnames/bind"

export default function () {
    return (
        <div
            className={classNames(this.props.className, styles.switch, {
                [styles.active]: this.props.value,
                [styles.disabled]: this.props.disabled,
            })}
            onClick={this.onClick}
        >
            <div className={styles.knob}></div>
        </div>
    )
}
