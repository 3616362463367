import React from "react"

import { FilterBar } from "../../../../pre-v3/components/filter-bar/FilterBar.component"
import { useServiceLocalization } from "../../../../pre-v3/services/localization/Localization.service"
import { DataFilter, DataFilterType, FilterModel } from "../../../../pre-v3/utils/AgGrid.util"
import { SelectItem } from "../../../../pre-v3/utils/SelectValue.util"
import {
    AccessActivityFilterById as FilterById,
    Device,
    User,
} from "../../../services/PrivateResource.service"

interface Props {
    filterModel: FilterModel<FilterById>
    getDevices(search: string): Promise<Device[]>
    getUsers(search: string): Promise<User[]>
    onFilter(filterModel: FilterModel<FilterById>): void
}

export function AccessActivityFilterBar(props: Props): JSX.Element {
    const localization = useServiceLocalization()

    const usersFilter: DataFilter<FilterById.USERS> = {
        key: FilterById.USERS,
        displayName: localization.getString("users"),
        type: DataFilterType.MULTILOOKUP,
        dataSource: async (search) => {
            const users = await props.getUsers(search)
            return users.map(mapUserItem)
        },
    }

    const devicesFilter: DataFilter<FilterById.DEVICES> = {
        key: FilterById.DEVICES,
        displayName: localization.getString("devices"),
        type: DataFilterType.MULTILOOKUP,
        dataSource: async (search) => {
            const devices = await props.getDevices(search)
            return devices.map(mapDeviceItem)
        },
    }

    const lastReportedFilter: DataFilter<FilterById.LAST_REPORTED> = {
        key: FilterById.LAST_REPORTED,
        displayName: localization.getString("lastReported"),
        type: DataFilterType.DATETIMERANGE,
    }

    const filters: DataFilter<FilterById>[] = [usersFilter, devicesFilter, lastReportedFilter]

    return (
        <FilterBar filters={filters} initialModel={props.filterModel} onChange={props.onFilter} />
    )
}

function mapDeviceItem(device: Device): SelectItem {
    return {
        displayName: device.name ?? device.serialNumber,
        value: device.serialNumber,
    }
}

function mapUserItem(user: User): SelectItem {
    return {
        displayName: user.name,
        value: user.email,
    }
}
