import React from "react"
import { Switch, Route } from "react-router-dom"

import { ROUTE } from "../../../../../routes"
import { PlistTrustFactor, TrustFactor } from "../../../../services/TrustFactor.service"
import { PlistAdd } from "./PlistAdd.view"
import { PlistList } from "./PlistList.view"
import { PlistEdit } from "./PlistEdit.view"

interface PlistProps {
    plistTrustFactor?: PlistTrustFactor
    isLoading: boolean
    onRefetchData(): Promise<TrustFactor[] | undefined>
    canAccessPListCheckFactor?: boolean
}

export function Plist(props: PlistProps): JSX.Element {
    return (
        <Switch>
            <Route path={ROUTE.FACTORS_PLIST_CREATE}>
                <PlistAdd canAccessPListCheckFactor={props.canAccessPListCheckFactor} />
            </Route>
            <Route path={ROUTE.FACTORS_PLIST_DETAILS}>
                <PlistEdit {...props} />
            </Route>
            <Route>
                <PlistList {...props} />
            </Route>
        </Switch>
    )
}
