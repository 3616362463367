import { faExclamationCircle } from "@fortawesome/pro-solid-svg-icons"
import React from "react"

import { Banner } from "../../../components/banner/Banner.component"
import { Grid } from "../../../components/grid/Grid.component"
import { Toolbar } from "../../../components/toolbar/Toolbar.component"
import { DevicesCellRenderer } from "./devices/DevicesCellRenderer.component"
import { UsersNameCellRenderer } from "./name/UsersNameCellRenderer.component"
import styles from "./ServiceActivityList.module.scss"

export default function () {
    return (
        <div className={styles.gridFullWithToolbar}>
            {this.state.error && (
                <Banner type="error" icon={faExclamationCircle} className={styles.gridBanner}>
                    {this.localizationService.getString("failedToLoadAccessActivity")}
                </Banner>
            )}
            <Toolbar onSearchChange={this.onFilter} />

            <div className={styles.gridContainer}>
                <Grid
                    onGridReady={this.onGridReady}
                    columnDefs={this.columns}
                    context={{ history: this.props.history }}
                    components={{
                        usersNameCellRenderer: UsersNameCellRenderer,
                        devicesCellRenderer: DevicesCellRenderer,
                    }}
                />
            </div>
        </div>
    )
}
