import { faPlus, IconDefinition } from "@fortawesome/pro-solid-svg-icons"
import classNames from "classnames/bind"
import React from "react"

import { useServiceLocalization, useServiceModal } from "../../../../../../../../../pre-v3/services"
import {
    SelectValue,
    SelectValueUtil,
} from "../../../../../../../../../pre-v3/utils/SelectValue.util"
import { Button } from "../../../../../../../../components/button/Button.component"
import { MenuButton } from "../../../../../../../../components/menu/menu-button/MenuButton.component"
import { SearchableMenu } from "../../../../../../../../components/menu/searchable-menu/SearchableMenu.component"
import {
    PillContainer,
    PillProps,
} from "../../../../../../../../components/pill-container/PillContainer.component"
import {
    PlistTrustFactor,
    Plist,
    comparePlist,
} from "../../../../../../../../services/TrustProfile.service"
import styles from "./PlistCheck.module.scss"
import { PlistModalContainer, PlistModalActions } from "./PlistModalContainer.component"

interface PlistCheckProps {
    className?: string
    disabled?: boolean
    trustFactor: PlistTrustFactor
    onEdit(trustFactor: PlistTrustFactor): void
}
export function PlistCheck(props: PlistCheckProps) {
    const { className, disabled, trustFactor, onEdit } = props

    const localization = useServiceLocalization()
    const modalService = useServiceModal()

    const options = getPlistSelectOptions([...trustFactor.unusedPlist, ...trustFactor.usedPlist])
    const value = getPlistSelectValues(trustFactor.usedPlist)

    function movePlistToUsed(plistToMove: Plist) {
        onEdit({
            ...trustFactor,
            usedPlist: [...trustFactor.usedPlist, plistToMove].sort((plistA, plistB) =>
                comparePlist(localization.getLocale(), plistA, plistB)
            ),
            unusedPlist: trustFactor.unusedPlist.filter(
                (plist) => getPlistId(plist) !== getPlistId(plistToMove)
            ),
        })
    }

    function movePlistToUnused(plistToMoveOut: Plist) {
        onEdit({
            ...trustFactor,
            usedPlist: trustFactor.usedPlist.filter(
                (plist) => getPlistId(plist) !== getPlistId(plistToMoveOut)
            ),
            unusedPlist: [...trustFactor.unusedPlist, plistToMoveOut].sort((plistA, plistB) =>
                comparePlist(localization.getLocale(), plistA, plistB)
            ),
        })
    }

    function editPlist(plist: Plist) {
        props.onEdit({
            ...trustFactor,
            usedPlist: trustFactor.usedPlist.map((usedPlist) => {
                return getPlistId(usedPlist) === getPlistId(plist) ? plist : usedPlist
            }),
        })
    }

    function onCreatePlist() {
        modalService
            .open(
                localization.getString("addPropertyList"),
                {
                    component: PlistModalContainer,
                    maxWidth: "lg",
                },
                PlistModalActions
            )
            .onClose(movePlistToUsed)
    }

    function onEditPlist(plistId: string) {
        const plistToEdit = trustFactor.usedPlist.find((plist) => getPlistId(plist) === plistId)

        if (plistToEdit) {
            modalService
                .open(
                    localization.getString("editPropertyList"),
                    {
                        component: PlistModalContainer,
                        props: { plist: plistToEdit },
                        maxWidth: "lg",
                    },
                    PlistModalActions
                )
                .onClose(editPlist)
        }
    }

    return (
        <MultiSelectPill
            className={className}
            disabled={disabled}
            label={localization.getString("propertyList")}
            options={options}
            value={value}
            newOption={{
                label: localization.getString("createAPropertyList"),
                onClick: onCreatePlist,
            }}
            onPillClick={onEditPlist}
            onSelect={(plistId: string) => {
                const plistToMove = trustFactor.unusedPlist.find(
                    (plist) => getPlistId(plist) === plistId
                )

                if (plistToMove) {
                    movePlistToUsed(plistToMove)
                }
            }}
            onUnSelect={(plistId: string) => {
                const plistToMoveOut = trustFactor.usedPlist.find(
                    (plist) => getPlistId(plist) === plistId
                )

                if (plistToMoveOut) {
                    movePlistToUnused(plistToMoveOut)
                }
            }}
        />
    )
}

function getPlistSelectValues(plists: Plist[]): string[] {
    return plists.map(getPlistId)
}

function getPlistSelectOptions(plists: Plist[]): SelectValue[] {
    return plists.map((plist) => {
        return {
            displayName: plist.name,
            value: getPlistId(plist),
        }
    })
}

function getPlistId(plists: Plist): string {
    return plists.id
}

interface MultiSelectPillProps {
    value: string[]
    options: SelectValue[]
    className?: string
    disabled?: boolean
    onPillClick?: (value: string) => void
    newOption?: {
        onClick: () => void
        label?: string
    }
    label: string
    icon?: IconDefinition
    onSelect?: (value: string) => void
    onUnSelect?: (value: string) => void
}

function MultiSelectPill(props: MultiSelectPillProps) {
    const localization = useServiceLocalization()

    const {
        options,
        disabled,
        value,
        onPillClick,
        className,
        newOption,
        label,
        icon = faPlus,
        onSelect,
        onUnSelect,
    } = props
    const { onClick: newOptionClick, label: newOptionLabel = localization.getString("add") } =
        newOption || {}

    const searchableMenuItems = options
        .filter((option) => {
            return !value.find((v) => SelectValueUtil.getSerializedValue(option) === v)
        })
        .map((option) => {
            return {
                label: SelectValueUtil.getSerializedDisplayName(option),
                onClick: () => {
                    onSelect?.(SelectValueUtil.getSerializedValue(option))
                },
            }
        })

    const noSearchableItem = searchableMenuItems.length === 0

    return (
        <div className={classNames(styles.multiSelectPill, className)}>
            {value.length > 0 && (
                <PillContainer
                    pills={value.map((v): PillProps => {
                        const option = options.find((option) => {
                            return SelectValueUtil.getSerializedValue(option) === v
                        })
                        if (!option) {
                            console.warn(
                                `Warning: MultiSelectPill provided value ${v}, is not available in options.`
                            )
                        }

                        const optionValue = option ? SelectValueUtil.getSerializedValue(option) : v
                        const displayName = option
                            ? SelectValueUtil.getSerializedDisplayName(option)
                            : v

                        function onUnselectValue() {
                            onUnSelect?.(v)
                        }

                        function onClick() {
                            onPillClick?.(v)
                        }

                        return {
                            id: optionValue,
                            label: displayName,
                            disabled: disabled,
                            onClick: onClick,
                            onDelete: onUnselectValue,
                            buttonAriaLabel: disabled
                                ? undefined
                                : localization.getString("editSomething", displayName),
                            iconAriaLabel: disabled
                                ? undefined
                                : localization.getString("removeSomething", displayName),
                        }
                    })}
                />
            )}
            {!disabled && (
                <>
                    {noSearchableItem && newOption && (
                        <Button
                            icon={icon}
                            onClick={newOptionClick}
                            className={styles.newOptionButton}
                        >
                            {newOptionLabel}
                        </Button>
                    )}
                    {!noSearchableItem && (
                        <MenuButton label={label} icon={icon} className={styles.menuContainer}>
                            <SearchableMenu
                                items={searchableMenuItems}
                                staticItem={
                                    newOption && {
                                        type: "button",
                                        label: newOptionLabel,
                                        icon: icon,
                                        className: styles.newOptionButton,
                                        onClick: newOptionClick,
                                    }
                                }
                            />
                        </MenuButton>
                    )}
                </>
            )}
        </div>
    )
}
