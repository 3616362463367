import classNames from "classnames"
import React, { FC } from "react"

import { Icon, IconSize, IconType } from "../icon/Icon.component"
import styles from "./Banner.module.scss"
import { Button, ButtonElement, ButtonProps, ButtonType } from "../button/Button.component"

export interface Props {
    variant: Variant
    label: React.ReactNode
    title?: string
    action?: ActionProps
    isLarge?: boolean
    role?: Extract<React.AriaRole, "alert">
    ariaLabel?: string
}

export const Banner: FC<Props> = (props) => {
    const classNameMap: Record<Variant, string> = {
        [Variant.WARNING]: styles.warning,
        [Variant.INFORMATION]: styles.information,
        [Variant.GENERIC_PRIMARY]: styles.genericPrimary,
        [Variant.GENERIC_SECONDARY]: styles.genericSecondary,
        [Variant.ERROR]: styles.error,
        [Variant.SUCCESS]: styles.success,
    }

    return (
        <div
            className={classNames(styles.banner, classNameMap[props.variant], {
                [styles.large]: props.isLarge,
            })}
            role={props.role}
        >
            <div className={styles.group}>
                <Icon
                    size={props.isLarge ? IconSize.X_LARGE : IconSize.LARGE}
                    icon={iconTypeByVariant[props.variant]}
                />
                <div className={styles.children}>
                    <h6 className={styles.title}>{props.title}</h6>
                    <span>{props.label}</span>
                </div>
            </div>
            {props.action && <ActionButton {...props.action} />}
        </div>
    )
}

export function getButtonAction(
    label: string,
    onClick: React.MouseEventHandler<HTMLButtonElement>
): ButtonActionProps {
    return {
        type: ActionType.BUTTON,
        label,
        onClick,
    }
}

export function getLinkAction(label: string, to: string): LinkActionProps {
    return {
        type: ActionType.LINK,
        label,
        to,
    }
}

function ActionButton(props: ActionProps): JSX.Element {
    const commonProps: Pick<ButtonProps, "buttonType" | "small" | "className" | "children"> = {
        buttonType: ButtonType.PRIMARY,
        small: true,
        className: styles.actionButton,
        children: props.label,
    }

    switch (props.type) {
        case ActionType.BUTTON:
            return (
                <Button {...commonProps} asElement={ButtonElement.BUTTON} onClick={props.onClick} />
            )

        case ActionType.LINK:
            return <Button {...commonProps} asElement={ButtonElement.LINK} to={props.to} />
    }
}

enum ActionType {
    BUTTON = "button",
    LINK = "link",
}

type ActionProps = ButtonActionProps | LinkActionProps

interface ButtonActionProps {
    type: ActionType.BUTTON
    label: string
    onClick: React.MouseEventHandler<HTMLButtonElement>
}

interface LinkActionProps {
    type: ActionType.LINK
    label: string
    to: string
}

export enum Variant {
    WARNING = "warning",
    INFORMATION = "information",
    GENERIC_PRIMARY = "generic-primary",
    GENERIC_SECONDARY = "generic-secondary",
    ERROR = "error",
    SUCCESS = "success",
}

const iconTypeByVariant: Record<Variant, IconType> = {
    [Variant.WARNING]: IconType.TRIANGLE_EXCLAMATION,
    [Variant.INFORMATION]: IconType.CIRCLE_INFO,
    [Variant.GENERIC_PRIMARY]: IconType.CIRCLE_INFO,
    [Variant.GENERIC_SECONDARY]: IconType.CIRCLE_INFO,
    [Variant.ERROR]: IconType.CIRCLE_EXCLAMATION,
    [Variant.SUCCESS]: IconType.SOLID_CHECK,
} as const
