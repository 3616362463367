import { DialogContent } from "@mui/material"
import * as React from "react"

import { useServiceLocalization } from "../../../../pre-v3/services/localization/Localization.service"
import { ModalRef } from "../../../../pre-v3/services/Modal.service"
import { Device } from "../../../services/Device.service"
import { ButtonAsLink } from "../../../components/button/ButtonAsLink.component"
import { Column, Grid } from "../../../components/grid/Grid.component"
import styles from "./RemoveDevicesModal.module.scss"

interface Props {
    modalRef: ModalRef
    allowedDevices: Device[]
    bannedDevices: Device[]
}

export const RemoveDevicesModal = (props: Props) => {
    const localization = useServiceLocalization()

    const { length: allowedDevicesLength } = props.allowedDevices
    const { length: bannedDevicesLength } = props.bannedDevices

    return (
        <DialogContent className={styles.container}>
            {allowedDevicesLength > 0 && (
                <DevicesGrid
                    title={localization.getPluralString(
                        "devicesWillBeRemovedFromYourBanyanEnvironment",
                        allowedDevicesLength
                    )}
                    devices={props.allowedDevices}
                />
            )}
            {bannedDevicesLength > 0 && (
                <DevicesGrid
                    title={localization.getPluralString(
                        "devicesAreBannedAndCannotBeRemovedUsingThisMethod",
                        bannedDevicesLength
                    )}
                    devices={props.bannedDevices}
                />
            )}
        </DialogContent>
    )
}

interface DevicesGridProps {
    title: string
    devices: Device[]
}

function DevicesGrid(props: DevicesGridProps): JSX.Element {
    const localization = useServiceLocalization()

    const [showTruncated, setShowTruncated] = React.useState(true)
    const truncatedDevices = React.useMemo(
        (): Device[] => props.devices.slice(0, truncatedLength),
        [props.devices]
    )

    const onToggleTruncatedDevices: React.MouseEventHandler = (event) => {
        event.preventDefault()
        setShowTruncated((currentTruncatedState) => !currentTruncatedState)
    }

    const columns = useColumns()

    const truncatedDevicesDifference = props.devices.length - truncatedLength

    return (
        <React.Fragment>
            <h3 className={styles.title}>{props.title}</h3>
            <Grid
                data={showTruncated ? truncatedDevices : props.devices}
                columns={columns}
                className={styles.grid}
            />
            {truncatedDevicesDifference > 0 && (
                <ButtonAsLink
                    brand
                    className={styles.viewMoreButton}
                    onClick={onToggleTruncatedDevices}
                >
                    {showTruncated
                        ? localization.getString(
                              "plusNumberMore",
                              truncatedDevicesDifference.toString()
                          )
                        : localization.getString("minusShowFewerDevices")}
                </ButtonAsLink>
            )}
        </React.Fragment>
    )
}

function useColumns(): Column<Device>[] {
    const localization = useServiceLocalization()

    return React.useMemo((): Column<Device>[] => {
        const nameColumn: Column<Device> = {
            id: "name",
            name: localization.getString("deviceName"),
            cellRenderer: "name",
            getTooltipValue: constantEmptyString,
        }

        return [nameColumn]
    }, [])
}

const truncatedLength = 3

function constantEmptyString() {
    return ""
}
