import React, { ReactNode } from "react"

import { AppNavLink } from "../../../services/shared/AppNavLink"
import {
    LeftNavItemTemplate,
    LeftNavItemCollapsedTemplate,
    LeftNavExternalLinkItemTemplate,
    LeftNavDividerTemplate,
} from "./LeftNavItem.template"

export class LeftNavItem extends React.Component<LeftNavItemProps, {}> {
    public render(): ReactNode {
        if (this.props.collapsed) {
            return LeftNavItemCollapsedTemplate.call(this)
        } else if (this.props.href && this.props.href.length > 0) {
            if (this.props.href.toLowerCase().startsWith("http")) {
                return LeftNavExternalLinkItemTemplate.call(this)
            } else {
                return LeftNavItemTemplate.call(this)
            }
        } else {
            return LeftNavDividerTemplate.call(this)
        }
    }
}

interface LeftNavItemProps extends Omit<AppNavLink, "onClick"> {
    collapsed: boolean
}
