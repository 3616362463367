import React from "react"
import styles from "./Button.module.scss"
import classNames from "classnames/bind"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faSpinner } from "@fortawesome/pro-solid-svg-icons"

export default function () {
    return (
        <button
            className={classNames(
                styles.button,
                this.props.className,
                { [styles.primary]: this.props.primary },
                { [styles.error]: this.props.error },
                { [styles.textPrimary]: this.props.textPrimary },
                { [styles.brand]: this.props.brand }
            )}
            onClick={this.props.onClick}
            type={this.props.type || "button"}
            ref={this.props.innerRef}
            autoFocus={this.props.autoFocus}
            disabled={this.props.loading || this.props.disabled}
            aria-label={this.props.ariaLabel}
        >
            {this.props.loading && (
                <span className={styles.icon}>
                    <FontAwesomeIcon spin icon={faSpinner} />
                </span>
            )}
            {!this.props.loading && this.props.icon && (
                <FontAwesomeIcon className={styles.icon} icon={this.props.icon} />
            )}
            {this.props.children}
        </button>
    )
}
