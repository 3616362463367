import React from "react"
import styles from "./CodeWithCopy.module.scss"
import classNames from "classnames"
import { Icon, IconType } from "../icon/Icon.component"

enum CopyState {
    ERROR = "error",
    NONE = "none",
    SUCCESS = "success",
}
const iconsMap: Record<CopyState, IconType> = {
    [CopyState.ERROR]: IconType.TIMES_CIRCLE,
    [CopyState.NONE]: IconType.COPY,
    [CopyState.SUCCESS]: IconType.SOLID_CHECK,
}

export interface CodeWithCopyProps {
    title: string
    text: string
    className?: string
    hideCopyButton?: boolean
}

export function CodeWithCopy({
    title,
    text,
    className,
    hideCopyButton,
}: CodeWithCopyProps): JSX.Element {
    const [copyState, setCopyState] = React.useState<CopyState>(CopyState.NONE)

    const onCopy = async (event: React.SyntheticEvent) => {
        event.preventDefault()
        try {
            await navigator.clipboard.writeText(text)
            setCopyState(CopyState.SUCCESS)
        } catch (e) {
            setCopyState(CopyState.ERROR)
            console.error(e)
        } finally {
            setTimeout(() => {
                setCopyState(CopyState.NONE)
            }, 2000)
        }
    }

    return (
        <div className={className}>
            <div className={styles.header}>
                <label className={styles.label}>{title}</label>
                <button
                    className={classNames(
                        {
                            [styles.success]: copyState === CopyState.SUCCESS,
                            [styles.error]: copyState === CopyState.ERROR,
                        },
                        styles.button
                    )}
                    onClick={onCopy}
                >
                    {!hideCopyButton && <Icon icon={iconsMap[copyState]} />}
                </button>
            </div>
            <div className={styles.content}>
                <pre className={styles.text}>{text}</pre>
            </div>
        </div>
    )
}
