import React from "react"

import { DeleteCancelActions } from "../../../../pre-v3/components/modal/delete-cancel/DeleteCancelActions"
import { MessageContent } from "../../../../pre-v3/components/modal/message/MessageContent"
import { useServiceLinks } from "../../../../pre-v3/services/link/Link.service"
import { useServiceLocalization } from "../../../../pre-v3/services/localization/Localization.service"
import { useServiceModal } from "../../../../pre-v3/services/Modal.service"
import { DateUtil } from "../../../../pre-v3/utils/Date.util"
import { encodeID } from "../../../../pre-v3/utils/Url.util"
import { ROUTE, formatRoutePath } from "../../../../routes"
import { AppText } from "../../../components/app-text/AppText.component"
import { ButtonAsLink } from "../../../components/button/ButtonAsLink.component"
import {
    Column,
    Grid,
    sortAlphabetically,
    sortByTimeStamp,
} from "../../../components/grid/Grid.component"
import { RowTitle } from "../../../components/grid/RowTitle.component"
import { User } from "../../../services/User.service"
import styles from "./DeviceUsersGrid.module.scss"

interface Props {
    users: User[]
    isInEditMode: boolean
    onUnregisterUser(user: User): void
}

export function DeviceUsersGrid(props: Props): JSX.Element {
    const shouldShowUnregister = props.isInEditMode && props.users.length > 1

    const linkService = useServiceLinks()
    const localization = useServiceLocalization()
    const modalService = useServiceModal()

    const unregisterUserLabel = localization.getString("unregisterUser")

    const onUnregisterUser = (user: User): void => {
        modalService
            .open(
                unregisterUserLabel,
                {
                    component: MessageContent,
                    props: {
                        text: localization.getString(
                            "unregisteringUserFromThisDeviceWillRevokeTheDevicesCertificate",
                            user.name
                        ),
                    },
                },
                {
                    component: DeleteCancelActions,
                    props: { okString: unregisterUserLabel },
                }
            )
            .onClose(() => props.onUnregisterUser(user))
    }

    const columns = useColumns(shouldShowUnregister, onUnregisterUser)

    return (
        <div className={styles.container}>
            <h6 className={styles.title}>{localization.getString("usersAssociatedWithDevice")}</h6>
            {props.users.length > 0 ? (
                <Grid data={props.users} columns={columns} className={styles.grid} />
            ) : (
                <AppText
                    ls={{
                        key: "thereAreCurrentlyNoUsersAssociatedWithThisDevice",
                        replaceVals: [linkService.getLink("setUpDirectory")],
                    }}
                    className={styles.noUsersBanner}
                />
            )}
        </div>
    )
}

function useColumns(
    shouldShowUnregister: boolean,
    unregisterUser: (user: User) => void
): Column<User>[] {
    const localization = useServiceLocalization()

    return React.useMemo((): Column<User>[] => {
        const nameColumn: Column<User> = {
            id: "name",
            name: localization.getString("name"),
            cellRenderer: renderUserName,
            getTooltipValue: "name",
            sorting: sortAlphabetically("name"),
        }

        const emailColumn: Column<User> = {
            id: "emailAddress",
            name: localization.getString("email"),
            cellRenderer: "email",
            getTooltipValue: "email",
            sorting: sortAlphabetically("email"),
        }

        const lastLoginColumn: Column<User> = {
            id: "lastLogin",
            name: localization.getString("lastLogin"),
            cellRenderer: renderLastLogin,
            getTooltipValue: renderLastLogin,
            sorting: sortByTimeStamp("lastLoginAt"),
        }

        const unregisterColumn: Column<User> = {
            id: "unregister",
            name: "",
            cellRenderer: (user) => {
                const onUnregisterUser: React.MouseEventHandler = (event) => {
                    event.preventDefault()
                    unregisterUser(user)
                }

                return (
                    <ButtonAsLink onClick={onUnregisterUser} brand>
                        {localization.getString("unregister")}
                    </ButtonAsLink>
                )
            },
            getTooltipValue: constEmptyString,
        }

        return [
            nameColumn,
            emailColumn,
            lastLoginColumn,
            ...(shouldShowUnregister ? [unregisterColumn] : []),
        ]
    }, [localization, shouldShowUnregister, unregisterUser])
}

function renderUserName(user: User): JSX.Element {
    return (
        <RowTitle
            title={user.name}
            route={formatRoutePath(ROUTE.USERS_DETAILS, { id: encodeID(user.email) })}
        />
    )
}

function renderLastLogin(user: User): string {
    return DateUtil.format(user.lastLoginAt)
}

function constEmptyString(): string {
    return ""
}
