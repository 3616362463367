import React from "react"

import { useServiceLocalization } from "../../../../../../../pre-v3/services"
import { FormRow } from "../../../../../../components/form/FormRow.component"
import {
    ToggleButton,
    ToggleButtonItem,
} from "../../../../../../components/toggle-button/ToggleButton"
import styles from "./ManagedByMdmToggle.module.scss"

interface Props {
    isManagedByMdm?: boolean
    onManagedByMdmToggle?(): void
    disabled?: boolean
}

export function ManagedByMdmToggle(props: Props): JSX.Element {
    const localization = useServiceLocalization()
    const yesLabel = localization.getString("yes")
    const noLabel = localization.getString("no")

    const items = React.useMemo<ToggleButtonItem[]>(
        () => [
            {
                label: yesLabel,
                onClick: props.isManagedByMdm ? undefined : props.onManagedByMdmToggle,
                selected: props.isManagedByMdm,
            },
            {
                label: noLabel,
                onClick: props.isManagedByMdm ? props.onManagedByMdmToggle : undefined,
                selected: !props.isManagedByMdm,
            },
        ],
        [noLabel, props, yesLabel]
    )

    return (
        <FormRow
            label={localization.getString("includeOnlyMdmManagedDevices")}
            description={localization.getString(
                "cseDeterminesWhetherADeviceIsMdmManagedThroughZeroTouchSettings"
            )}
            childrenClassName={styles.flex}
        >
            <ToggleButton items={items} disabled={props.disabled} />
        </FormRow>
    )
}
