export class SelectValueUtil {
    public static isSelectItem(item: SelectValue): boolean {
        return item.hasOwnProperty("displayName")
    }

    public static arrayIncludes(arr: SelectValue[], i: SelectValue): boolean {
        if ((i as SelectItem).displayName) {
            const item: SelectItem = i as SelectItem
            return arr.find((a) => (a as SelectItem).value === item.value) !== undefined
        } else {
            return arr.includes(i)
        }
    }

    public static stringArrayIncludes(arr: string[], i: SelectValue): boolean {
        if ((i as SelectItem).displayName) {
            const item: SelectItem = i as SelectItem
            return arr.includes(item.value)
        } else {
            return arr.includes(i as string)
        }
    }

    public static startsWith(sv: SelectValue, str: string): boolean {
        if ((sv as SelectItem).value) {
            const item: SelectItem = sv as SelectItem
            return item.displayName.toLowerCase().startsWith(str.toLowerCase())
        } else {
            const value: string = sv as string
            return value.toLowerCase().startsWith(str.toLowerCase())
        }
    }

    public static findIndexInStringArr(arr: string[], i: SelectValue): number {
        return arr.findIndex((str: string) => {
            const item: SelectItem = i as SelectItem
            if (item.displayName) {
                return str === item.value
            } else {
                return str === i
            }
        })
    }

    public static findIndexOfString(arr: SelectValue[], str: string): number {
        return arr.findIndex((a: SelectValue) => {
            const item: SelectItem = a as SelectItem
            if (item.displayName) {
                return item.value === str
            } else {
                return a === str
            }
        })
    }

    public static getSerializedDisplayName(i: SelectValue): string {
        const item: SelectItem = i as SelectItem
        return item.displayName ? item.displayName : (i as string)
    }

    public static getSerializedValue(i: SelectValue): string {
        const item: SelectItem = i as SelectItem
        return item.displayName ? item.value : i
    }
}

export interface SelectItem {
    displayName: string
    value: any
    divisor?: boolean
}

export type SelectValue = string | SelectItem
