import React, { ReactNode } from "react"

import { Button, ButtonElement, ButtonType } from "../../../../components/button/Button.component"
import { useServiceLocalization } from "../../../../pre-v3/services/localization/Localization.service"
import { AppText } from "../../../components/app-text/AppText.component"
import { ErrorBanners } from "../../../components/banner/Banner.component"
import { FormButtons } from "../../../components/form/form-buttons/FormButtons.component"
import { DnsFilter } from "../../../services/ItpPolicy.service"
import { ProfileCategoryHeader } from "../component/ProfileCategoryHeader.component"
import styles from "../ItpPolicy.module.scss"
import { ariaControls, Step } from "./ItpPolicyForm.component"

interface ThreatsTabProps {
    threats: DnsFilter[]
    value: ThreatFormValue
    onSubmit: () => void
    disabled?: boolean
    errors?: ReactNode[]
    onBack?: () => void
    isEdit?: boolean
    onChange: (updatedValue: ThreatFormValue) => void
}

export function ThreatsTab(props: ThreatsTabProps) {
    const {
        threats,
        value: threatFormValue,
        disabled,
        errors,
        onBack,
        isEdit,
        onChange,
        onSubmit,
    } = props

    const localization = useServiceLocalization()

    return (
        <div className={styles.tabContainer} id={ariaControls[Step.THREATS]}>
            <AppText ls="whenToggledONDevicesAssociatedWithThisITPPolicyWillBeBlockedFromAccessingSitesCategorizedAsThisTypeOfThreat" />
            {threats.map((threat, threatIndex) => {
                const isSelected = threatFormValue.threats.includes(threat.value)

                function onValueToggle() {
                    const updatedThreats: string[] = isSelected
                        ? threatFormValue.threats.filter((v) => {
                              return v !== threat.value
                          })
                        : [...threatFormValue.threats, threat.value]

                    onChange({ threats: updatedThreats })
                }

                return (
                    <ProfileCategoryHeader
                        key={threatIndex}
                        disabled={disabled}
                        value={isSelected}
                        onClick={onValueToggle}
                        label={threat.label}
                        description={threat.description}
                    />
                )
            })}
            {!disabled && (
                <FormButtons
                    leftButtons={
                        onBack && (
                            <Button
                                asElement={ButtonElement.BUTTON}
                                buttonType={ButtonType.SECONDARY}
                                type="button"
                                onClick={onBack}
                            >
                                {localization.getString(isEdit ? "cancel" : "back")}
                            </Button>
                        )
                    }
                    rightButtons={
                        <Button
                            onClick={onSubmit}
                            asElement={ButtonElement.BUTTON}
                            buttonType={ButtonType.PRIMARY}
                        >
                            {localization.getString(isEdit ? "save" : "next")}
                        </Button>
                    }
                >
                    {errors && <ErrorBanners errors={errors} />}
                </FormButtons>
            )}
            <div className={styles.bottomPadding} />
        </div>
    )
}

export interface ThreatFormValue {
    threats: string[]
}
