import React from "react"
import { Route, useHistory } from "react-router-dom"
import { UnregisteredDevicesList } from "../../pre-v3/views/unregistered-devices/list/UnregisteredDevicesList.component"
import useTitle from "../../hooks/useTitle.hook"
import { useFeatureFlags } from "../../hooks/useFeatureFlags.hook"

export function UnregisteredDevices() {
    const { data: featureFlags } = useFeatureFlags()
    const history = useHistory()

    useTitle(["unregisteredDevices", "directory", "adminConsole"])

    return (
        <Route component={UnregisteredDevicesList}>
            <UnregisteredDevicesList
                doShowUpsellView={
                    featureFlags?.adminConsole.doShowUpsellForDirectoryUnregisteredDevices
                }
                history={history}
            />
        </Route>
    )
}
