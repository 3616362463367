import React, { ReactNode } from "react"
import ClustersCaCertTemplate from "./ClustersCaCert.template"
import { Bind } from "../../../decorators/Bind.decorator"
import { LocalizationService } from "../../../services/localization/Localization.service"

export class ClustersCaCert extends React.Component<ClustersCaCertProps, ClustersCaCertState> {
    public state: ClustersCaCertState = { showCert: false }

    public render(): ReactNode {
        return ClustersCaCertTemplate.call(this)
    }

    private localizationService: LocalizationService = new LocalizationService()

    @Bind
    private onToggleCert(): void {
        this.setState({
            showCert: !this.state.showCert,
        })
    }

    @Bind
    private copyCert(): void {
        ;(navigator as any).clipboard.writeText(this.props.cert)
    }
}

interface ClustersCaCertProps {
    cert: string
}

interface ClustersCaCertState {
    showCert: boolean
}
