import { BaseApi } from "./Base.api"

export class TrustedNetworksApi extends BaseApi {
    public getTrustedNetworksConfig(): Promise<TrustedNetworkConfigRes> {
        return this.get("/api/v2/trusted_networks", {
            default: this.localization.getString("failedToFetchTrustedNetworkConfiguration"),
        })
    }

    public configureTrustedNetworkConfig(data: TrustedNetworkConfigRes): Promise<void> {
        return this.post(`/api/v2/trusted_networks`, data, {
            400: this.localization.getString("invalidMacAddressDesc"),
            default: this.localization.getString("errorFailedToConfigureTrustedNetwork"),
        })
    }

    public updateTrustedNetworkConfig(data: TrustedNetworkConfigRes): Promise<void> {
        return this.put(`/api/v2/trusted_networks`, data, {
            400: this.localization.getString("invalidMacAddressDesc"),
            default: this.localization.getString("errorFailedToUpdateTrustedNetwork"),
        })
    }
}

export interface TrustedNetworkConfigRes {
    mac_addresses: string[]
    disable_service_tunnel: boolean
}
