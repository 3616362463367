import React from "react"

import { useServiceLocalization } from "../../../../pre-v3/services/localization/Localization.service"
import {
    FilterModel,
    Paginated,
    PaginatedSearch,
    replaceColumnKeys,
} from "../../../../pre-v3/utils/AgGrid.util"
import { replaceKeys } from "../../../../pre-v3/utils/Collection.util"
import { encodeID } from "../../../../pre-v3/utils/Url.util"
import { ROUTE, formatRoutePath } from "../../../../routes"
import { Column, Grid, GridApi, sortWithServerSide } from "../../../components/grid/Grid.component"
import { RowTitle } from "../../../components/grid/RowTitle.component"
import {
    ServiceTunnelDetails as ServiceTunnel,
    ServiceTunnelFilterById as FilterById,
    ServiceTunnelSortById as SortById,
} from "../../../services/PrivateResource.service"

interface Props {
    filterModel: FilterModel<FilterById>
    getServiceTunnels(
        search: PaginatedSearch<SortById, FilterById>
    ): Promise<Paginated<ServiceTunnel>>
}

function NetworkGridComponent(props: Props, ref: React.ForwardedRef<GridApi>): JSX.Element {
    const localization = useServiceLocalization()

    const renderPortsCell_ = renderPortsCell(localization.getLocale())
    const formatPorts_ = formatPorts(localization.getLocale())
    const formatProtocol_ = formatProtocol(localization.getLocale())

    const nameColumn: Column<ServiceTunnel> = {
        id: ColumnId.NAME,
        name: localization.getString("serviceTunnel"),
        cellRenderer: renderNameCell,
        getTooltipValue: "name",
        sorting: sortWithServerSide("asc"),
        isFilterable: true,
    }

    const portsColumn: Column<ServiceTunnel> = {
        id: ColumnId.PORTS,
        name: localization.getString("ports"),
        cellRenderer: renderPortsCell_,
        getTooltipValue: formatPorts_,
        isFilterable: true,
    }

    const protocolsColumn: Column<ServiceTunnel> = {
        id: ColumnId.PROTOCOLS,
        name: localization.getString("protocols"),
        cellRenderer: formatProtocol_,
        getTooltipValue: formatProtocol_,
        isFilterable: true,
    }

    const getServerSideData = (search: PaginatedSearch<SortedColumnId, ColumnId>) =>
        props.getServiceTunnels({
            ...search,
            sortModel: search.sortModel && replaceColumnKeys(search.sortModel, sortByIdDict),
            filterModel: search.filterModel && replaceKeys(search.filterModel, filterByIdDict),
        })

    const columns: Column<ServiceTunnel>[] = [nameColumn, portsColumn, protocolsColumn]

    return (
        <Grid
            columns={columns}
            ref={ref}
            hasPagination
            serverSideProps={{
                getServerSideData,
                filterModel: replaceKeys(props.filterModel, filteredColumnDict),
            }}
        />
    )
}

export const NetworkGrid = React.forwardRef<GridApi, Props>(NetworkGridComponent)

enum ColumnId {
    NAME = "name",
    PORTS = "ports",
    PROTOCOLS = "protocols",
}

type SortedColumnId = ColumnId.NAME

function renderNameCell(serviceTunnel: ServiceTunnel): JSX.Element {
    const route = formatRoutePath(ROUTE.SERVICE_TUNNELS_DETAILS, {
        id: encodeID(serviceTunnel.id),
    })

    return <RowTitle title={serviceTunnel.name} route={route} />
}

function renderPortsCell(locale: string): (serviceTunnel: ServiceTunnel) => string {
    const _formatPorts = formatPorts(locale)

    return (serviceTunnel) => (serviceTunnel.ports.length > 0 ? _formatPorts(serviceTunnel) : "-")
}

function formatPorts(locale: string): (serviceTunnel: ServiceTunnel) => string {
    const formatter = new Intl.ListFormat(locale, { type: "conjunction" })

    return (serviceTunnel) => formatter.format(serviceTunnel.ports.map(stringifyNumber))
}

function formatProtocol(locale: string): (serviceTunnel: ServiceTunnel) => string {
    const formatter = new Intl.ListFormat(locale, { type: "conjunction" })

    return (serviceTunnel) => formatter.format(serviceTunnel.protocols)
}

const sortByIdDict: Record<SortedColumnId, SortById> = {
    [ColumnId.NAME]: SortById.SERVICE_TUNNEL,
}

const filterByIdDict: Record<ColumnId, FilterById> = {
    [ColumnId.NAME]: FilterById.SERVICE_TUNNELS,
    [ColumnId.PORTS]: FilterById.PORTS,
    [ColumnId.PROTOCOLS]: FilterById.PROTOCOLS,
}

const filteredColumnDict: Record<FilterById, ColumnId> = {
    [FilterById.SERVICE_TUNNELS]: ColumnId.NAME,
    [FilterById.PORTS]: ColumnId.PORTS,
    [FilterById.PROTOCOLS]: ColumnId.PROTOCOLS,
}

function stringifyNumber(number: number): string {
    return number.toString()
}
