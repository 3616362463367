import React, { ReactNode } from "react"
import ServicesInfoTemplate from "./ServicesInfo.template"
import {
    ServiceManage,
    ServiceType,
    ServiceAppType,
    SSHServiceType,
    TCPConnectMode,
} from "../../../services/Manage.service"
import {
    ServiceSpec,
    FrontendAddress,
    BackendAttr,
    ServiceTag,
    ExemptedPathPattern,
    ServiceAttr,
} from "../../../api/Manage.api"
import { EnforcementMode } from "../../../services/Infra.service"
import { ToggleButtonItem } from "../../../components/toggle-button/ToggleButton.component"
import { LocalizationService } from "../../../services/localization/Localization.service"
import { ServiceService } from "../../../services"
import { StringUtil } from "../../../utils/String.util"

export class ServicesInfo extends React.Component<ServicesInfoProps, ServicesInfoState> {
    public state: ServicesInfoState = {
        accessTiersHeader: "accessTier",
    }

    public componentDidMount(): void {
        if (this.props.service) {
            this.setBlob()
        }

        if (this.props.linkedResource) {
            this.setState({ showConnectToggle: false })
        }
    }

    public render(): ReactNode {
        return ServicesInfoTemplate.call(this)
    }

    public componentDidUpdate(prevProps: ServicesInfoProps): void {
        if (prevProps.service.id !== this.props.service.id) {
            this.setBlob()
        }
    }

    public setBlob(): void {
        const spec: ServiceSpec | undefined = this.props.service.spec

        if (!spec) {
            this.setState({
                error: this.localizationService.getString("failedToParseJson"),
            })
        } else {
            this.unmarshallData(spec)
            const blob: Blob = new Blob([JSON.stringify(spec, null, 4) + "\n"], {
                type: "application/json",
            })
            this.setState({
                jsonBlob: window.URL.createObjectURL(blob),
            })
        }
    }

    private localizationService: LocalizationService = new LocalizationService()
    private serviceService: ServiceService = new ServiceService()

    private enforcementOptions: ToggleButtonItem[] = [
        {
            label: this.localizationService.getString("siteBasedAccessTier"),
            value: EnforcementMode.ACCESS_TIER,
            onClick: () => {
                this.setState({ enforcementMode: EnforcementMode.ACCESS_TIER })
            },
        },
        {
            label: this.localizationService.getString("hostBasedNetagent"),
            value: EnforcementMode.NET_AGENT,
            onClick: () => {
                this.setState({ enforcementMode: EnforcementMode.NET_AGENT })
            },
        },
    ]

    private unmarshallData(spec: ServiceSpec) {
        if (!spec.metadata.tags) {
            this.setState({ serviceType: ServiceType.CUSTOM })
            this.setupForm(ServiceType.CUSTOM)
            return
        }

        const serviceType: ServiceType = <ServiceType>spec.metadata.tags.template
        if (serviceType === ServiceType.CUSTOM) {
            this.setState({ serviceType })
            this.setupForm(serviceType)
            return
        }
        const serviceAppType: ServiceAppType = <ServiceAppType>spec.metadata.tags.service_app_type
        const domain = spec.metadata.tags.domain
        const port = spec.metadata.tags.port
        const connector: string | undefined = spec.spec.backend?.connector_name

        const hostTagSelectors = spec.spec.attributes.host_tag_selector
        const accessTiers = hostTagSelectors.flatMap((selector) => {
            if (selector["com.banyanops.hosttag.hname"]) {
                return selector["com.banyanops.hosttag.hname"].split("|")
            } else if (selector["com.banyanops.hosttag.site_name"]) {
                return selector["com.banyanops.hosttag.site_name"].split("|")
            } else if (selector["com.banyanops.hosttag.access_tier_group"]) {
                return selector["com.banyanops.hosttag.access_tier_group"]
            }
            return []
        })

        let enforcementMode = spec.metadata.tags.enforcementMode
        if (hostTagSelectors[0]?.["com.banyanops.hosttag.hname"]) {
            enforcementMode = EnforcementMode.NET_AGENT
        } else if (hostTagSelectors[0]?.["com.banyanops.hosttag.site_name"]) {
            enforcementMode = EnforcementMode.ACCESS_TIER
        } else if (hostTagSelectors[0]?.["com.banyanops.hosttag.access_tier_group"]) {
            enforcementMode = EnforcementMode.ACCESS_TIER_GROUP
        }

        let accessTiersHeader =
            this.state.enforcementMode === EnforcementMode.NET_AGENT
                ? "netAgent"
                : enforcementMode === EnforcementMode.ACCESS_TIER
                  ? "accessTier"
                  : "accessTierGroup"
        if (accessTiers.length > 0 && enforcementMode !== EnforcementMode.ACCESS_TIER_GROUP) {
            accessTiersHeader += "s"
        }

        this.enforcementOptions.forEach((e) => {
            e.selected = e.value === enforcementMode
        })

        const { corsExemptions, cidrExemption, pathExemption } = this.serviceService.groupPatterns(
            spec.spec?.http_settings.exempted_paths?.patterns || []
        )
        const existingPathExemptions = pathExemption?.paths
        const existingCidrExemptions = cidrExemption?.source_cidrs

        const backendAttr: BackendAttr = spec.spec.backend

        let customHeaders
        if (Object.entries(spec.spec.http_settings.headers || {}).length > 0) {
            customHeaders = Object.entries(spec.spec.http_settings.headers || {})
        }

        let suppressDTVFlag
        if (spec.spec.http_settings.oidc_settings?.suppress_device_trust_verification) {
            suppressDTVFlag =
                spec.spec.http_settings.oidc_settings?.suppress_device_trust_verification
        }

        let disablePrivateDnsFlag
        if (spec.spec.attributes.disable_private_dns) {
            disablePrivateDnsFlag = spec.spec.attributes.disable_private_dns
        }

        const banyanProxyModeRes = spec.metadata.tags[ServiceTag.BANYAN_PROXY_MODE]
        const sshServiceTypeRes = spec.metadata.tags[ServiceTag.SSH_SERVICE_TYPE]

        this.setState({
            letsEncrypt: spec.spec.cert_settings.letsencrypt,
            serviceType: serviceType,
            serviceAppType: serviceAppType,
            domain: domain,
            port: port,
            enforcementMode: enforcementMode && EnforcementMode[enforcementMode],
            accessTiers,
            accessTiersHeader,
            backendAttr: backendAttr,
            sshHost: spec.metadata.tags[ServiceTag.SSH_HOST_DIRECTIVE],
            sshServiceType: sshServiceTypeRes && SSHServiceType[sshServiceTypeRes],
            sshWriteConfig: spec.metadata.tags[ServiceTag.WRITE_SSH_CONFIG],
            tcpConnectMode: banyanProxyModeRes && TCPConnectMode[banyanProxyModeRes],
            tcpListenPort: spec.metadata.tags[ServiceTag.APP_LISTEN_PORT],
            tcpOverride: spec.metadata.tags[ServiceTag.ALLOW_USER_OVERRIDE],
            kubeClusterName: spec.metadata.tags[ServiceTag.KUBE_CLUSTER_NAME],
            kubeCaKey: spec.metadata.tags[ServiceTag.KUBE_CA_KEY],
            connector,
            userFacing: serviceType !== ServiceType.WEB_USER ? null : true,
            corsPatterns: corsExemptions,
            cidrExemptions: existingCidrExemptions,
            pathExemptions: existingPathExemptions,
            customHeaders: customHeaders,
            suppressDTVFlag: suppressDTVFlag,
            disablePrivateDnsFlag: disablePrivateDnsFlag,
            serviceAccount: spec.spec.http_settings.token_loc,
            rdpProps:
                spec.metadata.tags.rdp_settings &&
                StringUtil.arrayToStringList(spec.metadata.tags.rdp_settings),
            showRdpProps: serviceAppType === ServiceAppType.RDP,
        })

        if (spec.spec?.backend?.dns_overrides) {
            const keys: string[] = Object.keys(spec.spec.backend.dns_overrides)
            if (keys.length > 0) {
                const key: string = keys[0]
                this.setState({ dnsOverride: spec.spec.backend.dns_overrides[key] })
            }
        }

        const includeDomains = spec.metadata.tags[ServiceTag.INCLUDE_DOMAINS]
        if (includeDomains && includeDomains.length > 0) {
            this.setState({
                includeDomains,
            })
        }

        this.setupForm(serviceType, serviceAppType)
    }

    private setupForm(serviceType: ServiceType, serviceAppType?: ServiceAppType): void {
        switch (serviceType) {
            case ServiceType.WEB_USER:
                this.setState({
                    serviceAppType: ServiceAppType.WEB,
                    showAppType: false,
                    showDomain: true,
                    showFrontend: false,
                    showBackend: true,
                    showBackendAllowPatterns: false,
                    showDnsOverride: false,
                    showTls: true,
                    showConnectToggle: false,
                    showCustom: false,
                })
                break
            case ServiceType.TCP_USER:
                this.setState({
                    showAppType: true,
                    showDomain: true,
                    showFrontend: false,
                    showBackend: serviceAppType !== ServiceAppType.K8S,
                    showBackendAllowPatterns: true,
                    showDnsOverride: true,
                    showTls: false,
                    showConnectToggle: serviceAppType !== ServiceAppType.K8S,
                    showSshSettings: serviceAppType === ServiceAppType.SSH,
                    showBanyanproxySettings: serviceAppType !== ServiceAppType.SSH,
                    showKubeSettings: serviceAppType === ServiceAppType.K8S,
                    showCustom: false,
                })
                break
            case ServiceType.TCP_WORKLOAD:
                this.setState({
                    showAppType: false,
                    showDomain: false,
                    showFrontend: true,
                    showBackend: false,
                    showBackendAllowPatterns: true,
                    showDnsOverride: false,
                    showTls: false, // already hidden by showBackend = false
                    showCustom: false,
                })
                break
            default:
                this.setState({
                    showAppType: false,
                    showDomain: false,
                    showFrontend: false,
                    showBackend: false,
                    showBackendAllowPatterns: false,
                    showDnsOverride: false,
                    showTls: false, // already hidden by showBackend = false
                    showCustom: true,
                })
                break
        }
    }
}

interface ServicesInfoProps {
    className: string
    service: ServiceManage
    linkedResource?: boolean
}

interface ServicesInfoState {
    error?: string
    letsEncrypt?: boolean

    enforcementMode?: EnforcementMode
    connector?: string
    serviceType?: ServiceType
    serviceAppType?: ServiceAppType
    domain?: string
    port?: string
    accessTiers?: string[]
    accessTiersHeader?: string
    backendAttr?: BackendAttr
    frontendAddresses?: FrontendAddress[]
    jsonBlob?: string

    sshHost?: string
    sshServiceType?: SSHServiceType
    sshWriteConfig?: boolean

    tcpConnectMode?: TCPConnectMode
    tcpListenPort?: string
    tcpOverride?: boolean

    corsPatterns?: ExemptedPathPattern[]
    pathExemptions?: string[]
    cidrExemptions?: string[]
    customHeaders?: [string, string][]
    userFacing?: boolean | null
    serviceAccount?: ServiceAttr["http_settings"]["token_loc"]

    suppressDTVFlag?: boolean
    disablePrivateDnsFlag?: boolean

    kubeClusterName?: string
    kubeCaKey?: string

    includeDomains?: string[]
    dnsOverride?: string
    rdpProps?: string

    showAppType?: boolean
    showDomain?: boolean
    showFrontend?: boolean
    showEnforcement?: boolean
    showBackend?: boolean
    showBackendAllowPatterns?: boolean
    showDnsOverride?: boolean
    showTls?: boolean
    showConnectToggle?: boolean
    showSshSettings?: boolean
    showBanyanproxySettings?: boolean
    showKubeSettings?: boolean
    showCustom?: boolean
    showRdpProps?: boolean
}
