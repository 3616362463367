import {
    ColDef,
    GridApi,
    GridReadyEvent,
    ICellRendererParams,
    IServerSideDatasource,
    IServerSideGetRowsParams,
    ITooltipParams,
    ValueFormatterParams,
} from "ag-grid-community"
import classNames from "classnames"
import React, { useMemo, useRef } from "react"

import { ErrorBanner } from "../../../../pre-v3/components/banner/Banner.component"
import { Grid } from "../../../../pre-v3/components/grid/Grid.component"
import { useServiceLocalization } from "../../../../pre-v3/services"
import {
    FilterModel,
    Paginated,
    PaginatedSearch,
    SortBy,
} from "../../../../pre-v3/utils/AgGrid.util"
import { UrlUtil, encodeID } from "../../../../pre-v3/utils/Url.util"
import { ROUTE, formatRoutePath } from "../../../../routes"
import { Link } from "../../../components/link/Link.component"
import { Device, useGetDevicesByRoleName } from "../../../services/Device.service"
import styles from "./RoleOverview.module.scss"
import { DateUtil } from "../../../../pre-v3/utils/Date.util"
import { DeviceFilterBar } from "../../devices/list/DeviceFilterBar.component"
import { FilterKey } from "../../devices/list/FilterKey"
import { Loader } from "../../../components/loader/Loader.component"

export interface RoleDevicesTabProps {
    roleName: string
}

export function RoleDevicesTab(props: RoleDevicesTabProps) {
    const columns = useColumns()
    const grid = useRef<GridApi>()
    const [filterModel, setFilterModel] = React.useState<FilterModel>(UrlUtil.readFilter())

    const getDevicesByRoleNameQuery = useGetDevicesByRoleName(props.roleName)

    function onGridReady(event: GridReadyEvent) {
        grid.current = event.api
        event.api.setFilterModel(filterModel)
        event.api.setServerSideDatasource(
            DataSource(event.api, getDevicesByRoleNameQuery.mutateAsync)
        )
    }

    function onFilter(newFilterModel: FilterModel) {
        grid.current?.setFilterModel(newFilterModel)
        setFilterModel(newFilterModel)
        UrlUtil.writeFilter(newFilterModel)
    }

    return (
        <div className={styles.tabContainer}>
            <DeviceFilterBar hasMigrated={true} filterModel={filterModel} onFilter={onFilter} />
            <Loader isLoading={getDevicesByRoleNameQuery.isLoading} medium center>
                {getDevicesByRoleNameQuery.error ? (
                    <ErrorBanner>{String(getDevicesByRoleNameQuery.error)}</ErrorBanner>
                ) : (
                    <React.Fragment />
                )}
            </Loader>
            <Grid
                className={classNames({
                    [styles.hidden]:
                        getDevicesByRoleNameQuery.error || getDevicesByRoleNameQuery.isLoading,
                })}
                onGridReady={onGridReady}
                serverSidePagination
                columnDefs={columns}
            />
        </div>
    )
}

function useColumns(): ColDef[] {
    const localization = useServiceLocalization()

    return useMemo((): ColDef[] => {
        return [
            {
                colId: FilterKey.NAME,
                field: "name",
                headerName: localization.getString("device"),
                cellRenderer: (props: ICellRendererParams<Device>) => {
                    if (props.data) {
                        return (
                            <Link
                                to={formatRoutePath(ROUTE.DEVICES_DETAILS, {
                                    serialNumber: encodeID(props.data.serialNumber),
                                })}
                                aria-label={props.data.name}
                            >
                                {props.data.name}
                            </Link>
                        )
                    }

                    return ""
                },
                filter: "agTextColumnFilter",
            },
            {
                colId: "serialNumber",
                field: "serialNumber",
                headerName: localization.getString("serialNumber"),
                valueFormatter: (props: ValueFormatterParams<Device>) =>
                    props.data?.serialNumber || "",
            },
            {
                colId: "userCount",
                headerName: localization.getString("userCount"),
                cellRenderer: (props: ICellRendererParams<Device>) => {
                    return localization.getString(
                        "noOfUsers",
                        props.data?.userEmails.length.toString() || ""
                    )
                },
                tooltipValueGetter: (props: ITooltipParams<Device>) => {
                    const { userEmails = [] } = props.data || {}

                    return userEmails.length > 0 ? userEmails.join(", ") : "--"
                },
            },
            {
                colId: FilterKey.LAST_LOGIN,
                field: "lastLoginAt",
                headerName: localization.getString("lastLogin"),
                valueFormatter: (props: ValueFormatterParams<Device>) =>
                    DateUtil.format(props.data?.lastLoginAt),
                tooltipValueGetter: (props: ITooltipParams<Device>) =>
                    DateUtil.format(props.data?.lastLoginAt),
                filter: "agTextColumnFilter",
            },
            ...fakeFilterColumns.map((fakeFilterColumn) => {
                return { colId: fakeFilterColumn, hide: true, filter: "agTextColumnFilter" }
            }),
        ]
    }, [])
}

const fakeFilterColumns = [
    FilterKey.PLATFORM,
    FilterKey.OWNERSHIP,
    FilterKey.TRUST_LEVEL,
    FilterKey.STATUS,
    FilterKey.APP_VERSION,
]

function DataSource(
    gridApi: GridApi,
    apiCallback: (filter: PaginatedSearch) => Promise<Paginated<Device>>
): IServerSideDatasource {
    const getRows = (params: IServerSideGetRowsParams) => {
        const skip: number = params.request.startRow || 0
        const limit: number = (params.request.endRow || 0) - (params.request.startRow || 0)
        const filterModel: FilterModel = params.request.filterModel
        const sortModel: SortBy[] = params.request.sortModel

        gridApi.showLoadingOverlay()

        apiCallback({ skip, limit, filterModel, sortModel }).then(
            (res: Paginated<Device>) => {
                params.success({
                    rowData: res.data,
                    rowCount: res.total,
                })
                gridApi.hideOverlay()
                if (res.total === 0) {
                    gridApi.showNoRowsOverlay()
                }
            },
            () => {
                params.fail()
                gridApi.showNoRowsOverlay()
            }
        )
    }

    return { getRows }
}
