import React, { Fragment } from "react"
import styles from "./FilterMenu.module.scss"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faPlus, faChevronRight, faChevronLeft } from "@fortawesome/pro-regular-svg-icons"
import { Popover } from "@mui/material"
import { DataFilterType } from "../../utils/AgGrid.util"
import { faSearch } from "@fortawesome/pro-solid-svg-icons"
import classNames from "classnames/bind"
import { Button, InputWithAction, MultiInput, Lookup, Select } from "../../components"
import { DateTimePicker } from "../../../v3/components/date-time-picker/DateTimePicker.component"

function FilterSelectMenu() {
    return (
        <ul className={classNames(styles.dropdown, this.props.menuClassName)}>
            <li className={styles.filterTitle}>{this.ls.getString("filterByTemplate", [""])}</li>
            {this.props.filters &&
                this.props.filters.map((f) => (
                    <li className={styles.filter} key={f.key} onClick={() => this.onViewFilter(f)}>
                        {f.displayName}
                        <FontAwesomeIcon icon={faChevronRight} className={styles.chevronRight} />
                    </li>
                ))}
        </ul>
    )
}

const FilterKey = ["lastLoginAt", "lastLogin"]

function FilterView() {
    return (
        this.state.selectedFilter && (
            <div className={classNames(this.props.filterViewClassName)}>
                <div className={styles.allFilters} onClick={this.onViewAll}>
                    <FontAwesomeIcon icon={faChevronLeft} className={styles.chevronLeft} />
                    {this.ls.getString("allFilters")}
                </div>
                <div className={styles.filterContainer}>
                    {this.state.selectedFilter.type === DataFilterType.INPUT && (
                        <InputWithAction
                            type="text"
                            autoFocus
                            value={this.state.selectedFilter.value}
                            onChange={this.onInputFilterChange}
                            onAction={this.onFilterSubmit}
                            onKeyPress={this.onFilterKeyPress}
                            placeholder={this.ls.getString("filterByTemplate", [
                                this.state.selectedFilter.displayName,
                            ])}
                        >
                            <FontAwesomeIcon icon={faSearch} />
                        </InputWithAction>
                    )}
                    {this.state.selectedFilter.type === DataFilterType.MULTIINPUT && (
                        <MultiInput
                            autoFocus
                            initialValues={this.state.selectedFilter.value}
                            onSubmit={this.onLookupSelected}
                            onChange={this.onMultiInputFilterChange}
                            placeholder={this.ls.getString("filterByTemplate", [
                                this.state.selectedFilter.displayName,
                            ])}
                        ></MultiInput>
                    )}
                    {this.state.selectedFilter.type === DataFilterType.LIST && (
                        <Select
                            options={this.state.selectedFilter.options}
                            onChange={this.onLookupSelected}
                            placeholder={this.ls.getString("filterByTemplate", [
                                this.state.selectedFilter.displayName,
                            ])}
                        />
                    )}
                    {this.state.selectedFilter.type === DataFilterType.MULTI_LIST && (
                        <Select
                            multiple
                            options={this.state.selectedFilter.options}
                            value={this.state.selectedFilter.value || []}
                            onChange={this.onMultiListFilterChange}
                            placeholder={this.ls.getString("filterByTemplate", [
                                this.state.selectedFilter.displayName,
                            ])}
                        />
                    )}
                    {this.state.selectedFilter.type === DataFilterType.LOOKUP && (
                        <Lookup
                            options={this.state.selectedFilter.options}
                            dataSource={this.state.selectedFilter.dataSource}
                            onSelected={this.onLookupSelected}
                            placeholder={this.ls.getString("filterByTemplate", [
                                this.state.selectedFilter.displayName,
                            ])}
                        />
                    )}
                    {this.state.selectedFilter.type === DataFilterType.MULTILOOKUP && (
                        <Lookup
                            options={this.state.selectedFilter.options}
                            dataSource={this.state.selectedFilter.dataSource}
                            onSelected={this.onLookupSelected}
                            placeholder={this.ls.getString("filterByTemplate", [
                                this.state.selectedFilter.displayName,
                            ])}
                            initialValues={this.state.selectedFilter.value}
                            multi
                        />
                    )}
                    {this.state.selectedFilter.type === DataFilterType.DATETIMERANGE && (
                        <DateTimePicker
                            onConfirm={this.onTimeRangeChange}
                            singleDateLabel={this.ls.getString("filterByASpecificDate")}
                            rangeOfDatesLabel={this.ls.getString("filterByDateRange")}
                            orDivider={this.ls.getString("or")}
                            toDivider={this.ls.getString("to", "")}
                            timeRangeLabel={this.ls.getString(
                                "somethingOptional",
                                this.ls.getString("filterByTimeRange")
                            )}
                            timeRangeValue={this.state.selectedFilter.value}
                            minDateEnabled={!FilterKey.includes(this.state.selectedFilter.key)}
                            maxDateEnabled
                        />
                    )}
                    {this.state.selectedFilter.type === DataFilterType.DEBOUNCEDINPUT && (
                        <InputWithAction
                            type="text"
                            autoFocus
                            value={this.state.selectedFilter.value}
                            onChange={this.onDebouncedInputFilterChange}
                            onAction={this.onFilterSubmit}
                            onKeyPress={this.onFilterKeyPress}
                            required
                            placeholder={this.ls.getString("filterByTemplate", [
                                this.state.selectedFilter.displayName,
                            ])}
                        >
                            <FontAwesomeIcon icon={faSearch} />
                        </InputWithAction>
                    )}
                </div>
            </div>
        )
    )
}

export default function () {
    return (
        <Fragment>
            <Button
                className={classNames(styles.button, this.props.buttonClassName)}
                icon={faPlus}
                innerRef={(c) => (this.anchor = c)}
                onClick={this.openMenu}
            >
                {this.ls.getString("addFilter")}
            </Button>
            <Popover
                anchorEl={this.anchor}
                anchorOrigin={{
                    vertical: "bottom",
                    horizontal: "left",
                }}
                transformOrigin={{
                    vertical: "top",
                    horizontal: "left",
                }}
                PaperProps={{ square: true }}
                open={this.state.showFilterMenu}
                onClose={this.closeMenu}
            >
                {FilterSelectMenu.call(this)}
            </Popover>
            <Popover
                anchorEl={this.anchor}
                anchorOrigin={{
                    vertical: "bottom",
                    horizontal: "left",
                }}
                transformOrigin={{
                    vertical: "top",
                    horizontal: "left",
                }}
                PaperProps={{ square: true }}
                open={this.state.selectedFilter !== undefined}
                onClose={this.closeMenu}
            >
                {FilterView.call(this)}
            </Popover>
        </Fragment>
    )
}
