import {
    QueryKey,
    UseMutationResult,
    UseQueryResult,
    useMutation,
    useQuery,
    useQueryClient,
} from "@tanstack/react-query"

import { DateUtil } from "../../pre-v3/utils/Date.util"
import { UrlUtil } from "../../pre-v3/utils/Url.util"
import { AccessTierApi, AccessTierRes } from "../api/AccessTier.api"
import { ClusterApi, ClusterRes } from "../api/Cluster.api"
import {
    ChallengeRecordRes,
    RegisteredDomainApi,
    RegisteredDomainsItemRes,
    StatusRes,
} from "../api/RegisteredDomain.api"
import { AccessTierGroupApi, AccessTierGroupRes } from "../api/AccessTierGroup.api"

enum RegisteredDomainHookKey {
    GET_REGISTERED_DOMAINS = "registeredDomainService.getRegisteredDomains",
}

const getRegisteredDomainsKey: QueryKey = [RegisteredDomainHookKey.GET_REGISTERED_DOMAINS]

/**
 * Create or Update a Registered Domain
 */
export function useSaveRegisteredDomain(
    enableATG: boolean,
    options?: QueryOptions<RegisteredDomainDetails, string, UnsavedRegisteredDomain>
): UseMutationResult<RegisteredDomainDetails, string, UnsavedRegisteredDomain> {
    const accessTierApi = new AccessTierApi()
    const accessTierGroupApi = new AccessTierGroupApi()
    const clusterApi = new ClusterApi()
    const registeredDomainApi = new RegisteredDomainApi()
    const queryClient = useQueryClient()

    return useMutation({
        ...options,
        mutationFn: async (params: UnsavedRegisteredDomain): Promise<RegisteredDomainDetails> => {
            // There is no update endpoint, so if a user navigates back and changes any properties
            // we will delete the old registered domain and generate a new one.
            if (params.id) {
                try {
                    await registeredDomainApi.deleteRegisteredDomain(params.id)
                } catch {}
            }
            const challengeRecord = await (params.cluster.type === ClusterType.GLOBAL_EDGE
                ? registeredDomainApi.createChallengeRecord({
                      registered_domain_name: params.name,
                  })
                : undefined)

            const registeredDomainRes = await registeredDomainApi.createRegisteredDomain({
                name: params.name,
                cluster_name: params.cluster.name,
                cname:
                    params.accessTier.address || params.accessTierGroup.tunnel_enduser.shared_fqdn,
                description: params.description,
                registered_domain_challenge_id: challengeRecord?.id,
            })

            const [{ access_tiers }, { access_tier_groups }, { Configs }] = await Promise.all([
                accessTierApi.getAccessTiers({ address: registeredDomainRes.cname }),
                enableATG
                    ? accessTierGroupApi.getAccessTierGroups({
                          shared_fqdn: registeredDomainRes.cname,
                      })
                    : { access_tier_groups: [] },
                clusterApi.getClusters(),
            ])

            const [accessTierRes] = access_tiers

            //need to map ATG to registered domain until we have BE fix to get ATG by shared_fqdn
            const accessTierGroupAddressMap = access_tier_groups.reduce<
                Record<string, AccessTierGroupRes>
            >(
                (acc, accessTierGroupRes) => ({
                    ...acc,
                    [accessTierGroupRes.tunnel_enduser.shared_fqdn]: accessTierGroupRes,
                }),
                {}
            )

            const accessTierGroupRes = accessTierGroupAddressMap[registeredDomainRes.cname]
            const clusterRes = Configs?.find(
                (cluster) => cluster.ShieldName === registeredDomainRes.cluster_name
            )

            return mapRegisteredDomainDetails(
                registeredDomainRes,
                clusterRes,
                accessTierRes,
                accessTierGroupRes,
                challengeRecord
            )
        },
        onSuccess: (newDomain) => {
            queryClient.invalidateQueries(getRegisteredDomainsKey)
            queryClient.removeQueries([
                "registeredDomainService.getRegisteredDomainById",
                newDomain.id,
            ])
            options?.onSuccess?.(newDomain)
        },
    })
}

export function useGetRegisteredDomains(
    enableATG: boolean,
    options?: QueryOptions<RegisteredDomain[]>
) {
    const accessTierApi = new AccessTierApi()
    const accessTierGroupApi = new AccessTierGroupApi()
    const registeredDomainsApi = new RegisteredDomainApi()

    return useQuery<RegisteredDomain[], string>({
        ...options,
        queryKey: getRegisteredDomainsKey,
        queryFn: async () => {
            const [{ access_tiers }, { access_tier_groups }, registeredDomainRes] =
                await Promise.all([
                    accessTierApi.getAccessTiers(),
                    enableATG
                        ? accessTierGroupApi.getAccessTierGroups()
                        : { access_tier_groups: [] },
                    registeredDomainsApi.getRegisteredDomains(),
                ])

            const accessTierAddressMap = access_tiers.reduce<Record<string, AccessTierRes>>(
                (acc, accessTierRes) => ({ ...acc, [accessTierRes.address]: accessTierRes }),
                {}
            )
            const accessTierGroupAddressMap = access_tier_groups.reduce<
                Record<string, AccessTierGroupRes>
            >(
                (acc, accessTierGroupRes) => ({
                    ...acc,
                    [accessTierGroupRes.tunnel_enduser.shared_fqdn]: accessTierGroupRes,
                }),
                {}
            )
            return registeredDomainRes.registered_domains.map<RegisteredDomain>((r) =>
                mapRegisteredDomain(r, accessTierAddressMap, accessTierGroupAddressMap)
            )
        },
    })
}

export function useGetRegisteredDomainById(
    id: string,
    enableATG: boolean,
    options?: QueryOptions<RegisteredDomainDetails, unknown>
): UseQueryResult<RegisteredDomainDetails> {
    const accessTierApi = new AccessTierApi()
    const accessTierGroupApi = new AccessTierGroupApi()
    const clusterApi = new ClusterApi()
    const registeredDomainApi = new RegisteredDomainApi()

    return useQuery({
        ...options,
        queryKey: ["registeredDomainService.getRegisteredDomainById", id],
        queryFn: async (): Promise<RegisteredDomainDetails> => {
            const registeredDomainRes = await registeredDomainApi.getRegisteredDomainById(id)

            const [{ access_tiers }, { access_tier_groups }, { Configs }, recordRes] =
                await Promise.all([
                    accessTierApi.getAccessTiers({ address: registeredDomainRes.cname }),
                    enableATG
                        ? accessTierGroupApi.getAccessTierGroups({
                              shared_fqdn: registeredDomainRes.cname,
                          })
                        : { access_tier_groups: [] },
                    clusterApi.getClusters(),
                    registeredDomainRes.registered_domain_challenge_id
                        ? registeredDomainApi.getChallengeRecordById(
                              registeredDomainRes.registered_domain_challenge_id
                          )
                        : undefined,
                ])

            //need to map ATG to registered domain until we have BE fix to get ATG by shared_fqdn
            const accessTierGroupAddressMap = access_tier_groups.reduce<
                Record<string, AccessTierGroupRes>
            >(
                (acc, accessTierGroupRes) => ({
                    ...acc,
                    [accessTierGroupRes.tunnel_enduser.shared_fqdn]: accessTierGroupRes,
                }),
                {}
            )
            const [accessTierRes] = access_tiers
            const accessTierGroupRes = accessTierGroupAddressMap[registeredDomainRes.cname]
            const clusterRes = Configs?.find(
                (cluster) => cluster.ShieldName === registeredDomainRes.cluster_name
            )

            return mapRegisteredDomainDetails(
                registeredDomainRes,
                clusterRes,
                accessTierRes,
                accessTierGroupRes,
                recordRes
            )
        },
    })
}

export function useGetClusters(
    enableATG: boolean,
    ids?: string[],
    options?: QueryOptions<Clusters>
): UseQueryResult<Clusters> {
    const clusterApi = new ClusterApi()
    const accessTierApi = new AccessTierApi()
    const accessTierGroupApi = new AccessTierGroupApi()
    return useQuery({
        ...options,
        queryKey: ["registeredDomainService.getClusters", ids],
        queryFn: async (): Promise<Clusters> => {
            const [
                { Configs: clustersRes },
                { access_tiers: accessTiersRes },
                { access_tier_groups: accessTierGroupsRes },
            ] = await Promise.all([
                clusterApi.getClusters(),
                accessTierApi.getAccessTiers(),
                enableATG ? accessTierGroupApi.getAccessTierGroups() : { access_tier_groups: [] },
            ])
            return ids
                ? mapFilteredClusters(clustersRes, accessTiersRes, accessTierGroupsRes, ids || [])
                : mapClusters(clustersRes, accessTiersRes, accessTierGroupsRes)
        },
    })
}

export function useValidateRegisteredDomain(options?: QueryOptions<void, string, string>) {
    const registeredDomainApi = new RegisteredDomainApi()
    const queryClient = useQueryClient()

    return useMutation<void, string, string>({
        ...options,
        mutationFn: async (id: string): Promise<void> => {
            if (id) {
                await registeredDomainApi.validateRegisteredDomain(id)
            }
        },
        onSuccess: (_data, id) => {
            if (id) {
                queryClient.invalidateQueries(getRegisteredDomainsKey)
                queryClient.removeQueries(["registeredDomainService.getRegisteredDomainById", id])
            }
            options?.onSuccess?.()
        },
    })
}

export function useDeleteRegisteredDomain(options?: QueryOptions) {
    const registeredDomainApi = new RegisteredDomainApi()
    const queryClient = useQueryClient()

    return useMutation<void, string, string>({
        ...options,
        mutationFn: async (id: string): Promise<void> => {
            if (id) {
                await registeredDomainApi.deleteRegisteredDomain(id)
            }
        },
        onSuccess: (_res, id) => {
            if (id) {
                queryClient.removeQueries(["registeredDomainService.getRegisteredDomainById", id])
                queryClient.setQueryData<RegisteredDomain[]>(
                    getRegisteredDomainsKey,
                    (oldDomains) => oldDomains?.filter((domain) => domain.id !== id)
                )
            }
            options?.onSuccess?.()
        },
    })
}

function mapRegisteredDomain(
    res: RegisteredDomainsItemRes,
    accessTierAddressMap: Record<string, AccessTierRes>,
    accessTierGroupAddressMap: Record<string, AccessTierGroupRes>
): RegisteredDomain {
    const accessTier = accessTierAddressMap[res.cname]
    const group = accessTierGroupAddressMap[res.cname]
    return {
        id: res.id,
        name: res.name,
        clusterName: res.cluster_name,
        networkId: accessTier?.id || group?.id,
        networkName: accessTier?.name || group?.name,
        cname: res.cname,
        description: res.description,
        status: res.status as RegisteredDomainStatus,
        createdAt: DateUtil.convertLargeTimestamp(res.created_at),
        createdBy: res.created_by,
    }
}

// Types

export interface RegisteredDomain {
    id?: string
    name: string
    clusterName: string
    networkName?: string
    networkId?: string
    cname: string
    description: string
    status?: RegisteredDomainStatus
    createdAt?: number
    createdBy?: string
}

export interface RegisteredDomainDetails {
    id: string
    name: string
    description: string
    status: RegisteredDomainStatus
    dnsRecords: DnsRecord[]
    createdAt: number
    createdBy: string
    cluster: DomainCluster
}

interface DnsRecord {
    type: "A" | "CNAME" | "TXT"
    name: string
    value: string
}

export enum RegisteredDomainStatus {
    PENDING = "Pending",
    VERIFIED = "Verified",
    FAILED = "Failed",
}

const statusResDict: Record<StatusRes, RegisteredDomainStatus> = {
    Pending: RegisteredDomainStatus.PENDING,
    Verified: RegisteredDomainStatus.VERIFIED,
    Failed: RegisteredDomainStatus.FAILED,
}

/**
 * The Cluster and Access Tier tied to a Registered Domain
 */
type DomainCluster = GlobalEdgeDomainCluster | PrivateEdgeDomainCluster

export interface BaseDomainCluster {
    id: string
    name: string
    type?: ClusterType
}

interface GlobalEdgeDomainCluster extends BaseDomainCluster {
    type: ClusterType.GLOBAL_EDGE
    accessTier: AccessTier
    accessTierGroup: AccessTierGroup
}

interface PrivateEdgeDomainCluster extends BaseDomainCluster {
    type: ClusterType.PRIVATE_EDGE
    /**
     * Older Registered Domains may not be tied to an Access Tier.
     * In this case, the Access Tier key will point to the CNAME.
     */
    accessTier: AccessTier | string
    accessTierGroup: AccessTierGroup | string
}

export enum ClusterType {
    GLOBAL_EDGE = "globalEdge",
    PRIVATE_EDGE = "privateEdge",
}

export interface Cluster {
    id: string
    name: string
    accessTiers: AccessTier[]
    accessTierGroups: AccessTierGroup[]
}

export type Clusters = HasGlobalEdgeCluster | HasPrivateEdgeCluster | HasBothClusters

interface HasGlobalEdgeCluster {
    type: ClustersType.GLOBAL_EDGE
    globalEdgeCluster: Cluster
}

interface HasPrivateEdgeCluster {
    type: ClustersType.PRIVATE_EDGE
    privateEdgeCluster: Cluster
}

interface HasBothClusters {
    type: ClustersType.BOTH
    globalEdgeCluster: Cluster
    privateEdgeCluster: Cluster
}

export enum ClustersType {
    GLOBAL_EDGE = "globalEdge",
    PRIVATE_EDGE = "privateEdge",
    BOTH = "both",
}

export interface AccessTier {
    id: string
    name: string
    address: string
}

export interface AccessTierGroup {
    id: string
    name: string
    tunnel_enduser: {
        shared_fqdn: string
    }
}

export interface UnsavedRegisteredDomain {
    id?: string
    name: string
    description: string
    cluster: BaseDomainCluster
    accessTier: AccessTier
    accessTierGroup: AccessTierGroup
}

// Helper Functions

function mapRegisteredDomainDetails(
    res: RegisteredDomainsItemRes,
    clusterRes?: ClusterRes,
    accessTierRes?: AccessTierRes,
    accessTierGroupRes?: AccessTierGroupRes,
    recordRes?: ChallengeRecordRes
): RegisteredDomainDetails {
    if (!clusterRes) throw new Error("Failed to find the Cluster for the Registered Domain")

    const cluster = getCluster(res, clusterRes, accessTierRes, accessTierGroupRes)

    return {
        id: res.id,
        name: res.name,
        description: res.description,
        status: statusResDict[res.status],
        dnsRecords: getDnsRecords(res, cluster, recordRes),
        createdAt: DateUtil.convertLargeTimestamp(res.created_at),
        createdBy: res.created_by,
        cluster,
    }
}

function getCluster(
    res: RegisteredDomainsItemRes,
    clusterRes: ClusterRes,
    accessTierRes?: AccessTierRes,
    accessTierGroupRes?: AccessTierGroupRes
): DomainCluster {
    const baseCluster: BaseDomainCluster = {
        id: clusterRes.UUID,
        name: clusterRes.ShieldName,
    }

    if (clusterRes.GroupType !== "EDGE") {
        return {
            ...baseCluster,
            type: ClusterType.PRIVATE_EDGE,
            accessTier: accessTierRes ?? res.cname,
            accessTierGroup: accessTierGroupRes ?? res.cname,
        }
    }

    if (!accessTierRes) {
        throw new Error("Failed to find the Access Tier for the Registered Domain")
    }

    return {
        ...baseCluster,
        type: ClusterType.GLOBAL_EDGE,
        accessTier: accessTierRes,
        accessTierGroup: accessTierGroupRes || emptyAccessTierGroup,
    }
}

function getDnsRecords(
    registeredDomainRes: RegisteredDomainsItemRes,
    cluster: DomainCluster,
    recordRes?: ChallengeRecordRes
): DnsRecord[] {
    // Older Registered Domains may not be tied to an Access Tier.
    // In this case, the Access Tier key will point to the CNAME.
    const routingRecord: DnsRecord = {
        type: UrlUtil.isIpV4Address(registeredDomainRes.cname) ? "A" : "CNAME",
        name: registeredDomainRes.name,
        value: registeredDomainRes.cname,
    }

    const acmeChallengeRecord = getAcmeChallengeRecord(registeredDomainRes)

    const otherRecords: DnsRecord[] = acmeChallengeRecord ? [acmeChallengeRecord] : []

    if (cluster.type === ClusterType.PRIVATE_EDGE || !recordRes)
        return [routingRecord, ...otherRecords]

    const txtRecord: DnsRecord = {
        type: "TXT",
        name: recordRes.label,
        value: recordRes.value,
    }

    return [routingRecord, txtRecord, ...otherRecords]
}

function getAcmeChallengeRecord(
    registeredDomainRes: RegisteredDomainsItemRes
): DnsRecord | undefined {
    if (!registeredDomainRes.domain_name || !registeredDomainRes.acme_cname) return undefined

    return {
        type: "CNAME",
        name: registeredDomainRes.domain_name,
        value: registeredDomainRes.acme_cname,
    }
}

function mapClusters(
    clustersRes: ClusterRes[] | null,
    accessTiersRes: AccessTierRes[],
    accessTierGroupsRes: AccessTierGroupRes[]
): Clusters {
    const [firstClusterRes, secondClusterRes, ...otherClusters] = clustersRes ?? []

    const accessTiersResDict = accessTiersRes.reduce(reduceAccessTiersResDict, {})
    const accessTierGroupsResDict = accessTierGroupsRes.reduce(reduceAccessTierGroupsResDict, {})

    if (otherClusters.length > 0) console.error("More than two clusters found")

    if (secondClusterRes) {
        if (firstClusterRes.GroupType === "EDGE" && secondClusterRes.GroupType !== "EDGE") {
            const globalEdgeCluster = mapCluster(
                firstClusterRes,
                accessTiersResDict,
                accessTierGroupsResDict
            )
            const privateEdgeAccessTiers = accessTiersResDict[secondClusterRes.ShieldName] ?? []
            const privateEdgeAccessTierGroups =
                accessTierGroupsResDict[secondClusterRes.ShieldName] ?? []
            return privateEdgeAccessTiers.length > 0 || privateEdgeAccessTierGroups.length > 0
                ? {
                      type: ClustersType.BOTH,
                      globalEdgeCluster,
                      privateEdgeCluster: mapCluster(
                          secondClusterRes,
                          accessTiersResDict,
                          accessTierGroupsResDict
                      ),
                  }
                : {
                      type: ClustersType.GLOBAL_EDGE,
                      globalEdgeCluster,
                  }
        }

        if (firstClusterRes.GroupType !== "EDGE" && secondClusterRes.GroupType === "EDGE") {
            const privateEdgeAccessTiers = accessTiersResDict[firstClusterRes.ShieldName] ?? []
            const globalEdgeCluster = mapCluster(
                secondClusterRes,
                accessTiersResDict,
                accessTierGroupsResDict
            )

            return privateEdgeAccessTiers.length > 0
                ? {
                      type: ClustersType.BOTH,
                      globalEdgeCluster,
                      privateEdgeCluster: mapCluster(
                          firstClusterRes,
                          accessTiersResDict,
                          accessTierGroupsResDict
                      ),
                  }
                : {
                      type: ClustersType.GLOBAL_EDGE,
                      globalEdgeCluster,
                  }
        }

        console.error("More than one cluster of the same type found")
    }

    return firstClusterRes?.GroupType === "EDGE"
        ? {
              type: ClustersType.GLOBAL_EDGE,
              globalEdgeCluster: mapCluster(
                  firstClusterRes,
                  accessTiersResDict,
                  accessTierGroupsResDict
              ),
          }
        : {
              type: ClustersType.PRIVATE_EDGE,
              privateEdgeCluster: mapCluster(
                  firstClusterRes,
                  accessTiersResDict,
                  accessTierGroupsResDict
              ),
          }
}

function mapFilteredClusters(
    clustersRes: ClusterRes[] | null,
    accessTiersRes: AccessTierRes[],
    accessTierGroupRes: AccessTierGroupRes[],
    ids: string[]
): Clusters {
    const [firstClusterRes, secondClusterRes, ...otherClusters] = clustersRes ?? []

    const accessTiersResDict = accessTiersRes.reduce<Record<string, AccessTierRes[]>>(
        (dict, accessTierRes) =>
            ids.includes(accessTierRes.id) ? reduceAccessTiersResDict(dict, accessTierRes) : dict,
        {}
    )
    const accessTierGroupsResDict = accessTierGroupRes.reduce<Record<string, AccessTierGroupRes[]>>(
        (dict, accessTierGroupRes) =>
            ids.includes(accessTierGroupRes.id)
                ? reduceAccessTierGroupsResDict(dict, accessTierGroupRes)
                : dict,
        {}
    )

    if (otherClusters.length > 0) console.error("More than two clusters found")

    const firstClusterAccessTiers = accessTiersResDict[firstClusterRes?.ShieldName] ?? []
    const firstClusterAccessTierGroups = accessTierGroupsResDict[firstClusterRes?.ShieldName] ?? []

    if (
        firstClusterRes &&
        (firstClusterAccessTiers.length > 0 || firstClusterAccessTierGroups.length > 0)
    ) {
        return firstClusterRes.GroupType === "EDGE"
            ? {
                  type: ClustersType.GLOBAL_EDGE,
                  globalEdgeCluster: mapCluster(
                      firstClusterRes,
                      accessTiersResDict,
                      accessTierGroupsResDict
                  ),
              }
            : {
                  type: ClustersType.PRIVATE_EDGE,
                  privateEdgeCluster: mapCluster(
                      firstClusterRes,
                      accessTiersResDict,
                      accessTierGroupsResDict
                  ),
              }
    }

    const secondClusterAccessTiers = accessTiersResDict[secondClusterRes?.ShieldName] ?? []
    const secondClusterAccessTierGroups =
        accessTierGroupsResDict[secondClusterRes?.ShieldName] ?? []

    if (
        secondClusterRes &&
        (secondClusterAccessTiers.length > 0 || secondClusterAccessTierGroups.length > 0)
    ) {
        return secondClusterRes.GroupType === "EDGE"
            ? {
                  type: ClustersType.GLOBAL_EDGE,
                  globalEdgeCluster: mapCluster(
                      secondClusterRes,
                      accessTiersResDict,
                      accessTierGroupsResDict
                  ),
              }
            : {
                  type: ClustersType.PRIVATE_EDGE,
                  privateEdgeCluster: mapCluster(
                      secondClusterRes,
                      accessTiersResDict,
                      accessTierGroupsResDict
                  ),
              }
    }

    throw new Error("No Clusters found")
}

function reduceAccessTiersResDict(
    dict: Record<string, AccessTierRes[]>,
    accessTierRes: AccessTierRes
): Record<string, AccessTierRes[]> {
    if (!accessTierRes.address) return dict
    const { cluster_name } = accessTierRes
    const accessTierResList = dict[cluster_name] ?? []
    return { ...dict, [cluster_name]: [...accessTierResList, accessTierRes] }
}

function reduceAccessTierGroupsResDict(
    dict: Record<string, AccessTierGroupRes[]>,
    accessTierGroupRes: AccessTierGroupRes
): Record<string, AccessTierGroupRes[]> {
    if (!accessTierGroupRes.tunnel_enduser.shared_fqdn) return dict
    const { cluster_name } = accessTierGroupRes
    const accessTierGroupResList = dict[cluster_name] ?? []
    return { ...dict, [cluster_name]: [...accessTierGroupResList, accessTierGroupRes] }
}

function mapCluster(
    clusterRes: ClusterRes,
    accessTiersResDict: Record<string, AccessTierRes[]>,
    accessTierGroupsResDict: Record<string, AccessTierGroupRes[]>
): Cluster {
    return {
        id: clusterRes.UUID,
        name: clusterRes.ShieldName,
        accessTiers: accessTiersResDict[clusterRes.ShieldName] ?? [],
        accessTierGroups: accessTierGroupsResDict[clusterRes.ShieldName] ?? [],
    }
}

const emptyAccessTierGroup: AccessTierGroup = {
    id: "",
    name: "",
    tunnel_enduser: {
        shared_fqdn: "",
    },
}
