export class ApiUtil {
    public static sanitizeObject<T>(obj: T): T {
        for (let key in obj) {
            const value: any = obj[key]
            if (
                value === null ||
                value === undefined ||
                value === "" ||
                (Array.isArray(value) && value.length === 0)
            ) {
                delete obj[key]
            }
        }
        return obj
    }
}
