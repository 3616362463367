import {
    QueryKey,
    UseMutationResult,
    UseQueryResult,
    useMutation,
    useQuery,
    useQueryClient,
} from "@tanstack/react-query"

import { Paginated, PaginatedSearch } from "../../pre-v3/utils/AgGrid.util"
import { convertFromServerTimestamp } from "../../utils/Date.utils"
import { AccessTierApi, AccessTierRes } from "../api/AccessTier.api"
import {
    AccessTierGroupApi,
    AccessTierGroupBody,
    AccessTierGroupRes,
    AdvancedSettingsJson,
    GetAccessTierGroupsParams,
} from "../api/AccessTierGroup.api"
import {
    HostTagSelector,
    RegisteredServiceApi,
    RegisteredServicesRes as RegisteredServiceRes,
    ServiceSpecJson,
} from "../api/RegisteredService.api"
import { ServiceTunnelApi, ServiceTunnelRes } from "../api/ServiceTunnel.api"
import {
    AdvancedSettings,
    AdvancedSettingsStatus,
    NetworkSettings,
    Status,
    getNetagentVersions,
    getTunnelEnduserReq,
    statusFromResDict as statusFromRes,
} from "./shared/AccessTier"
import { getAdvancedSettingsFromRes } from "./shared/AccessTierGroup"
import { Cluster } from "./shared/Cluster"
import { ApiFunction } from "./shared/QueryKey"

const serviceName = "AccessTierGroupService"

function getAccessTierGroupByIdKey(id: string): QueryKey {
    return [ApiFunction.GET_ACCESS_TIER_GROUP_BY_ID, id, serviceName]
}

const getAccessTiersKey = (accessTierGroupId?: string): QueryKey => [
    ApiFunction.GET_ACCESS_TIERS,
    accessTierGroupId,
    serviceName,
]

interface UseGetAccessTierGroupsResult {
    getAccessTierGroups(
        search: PaginatedSearch<string, FilterById>
    ): Promise<Paginated<AccessTierGroup>>
    clearCache(): Promise<void>
}

export function useGetAccessTierGroups(): UseGetAccessTierGroupsResult {
    const accessTierGroupApi = new AccessTierGroupApi()

    const client = useQueryClient()

    return {
        getAccessTierGroups: (search) =>
            client.ensureQueryData({
                queryKey: [ApiFunction.GET_ACCESS_TIER_GROUPS, search, serviceName],
                queryFn: async (): Promise<Paginated<AccessTierGroup>> => {
                    const { access_tier_groups, count } =
                        await accessTierGroupApi.getAccessTierGroups(
                            getAccessTierGroupsParams(search)
                        )
                    return { data: access_tier_groups.map(mapAccessTierGroup), total: count }
                },
            }),
        clearCache: () => client.resetQueries([ApiFunction.GET_ACCESS_TIER_GROUPS]),
    }
}

export function useGetAccessTierGroupById(
    id: string,
    cluster: Cluster,
    options?: QueryOptions<AccessTierGroupDetails>
): UseQueryResult<AccessTierGroupDetails> {
    const accessTierGroupApi = new AccessTierGroupApi()
    const accessTierApi = new AccessTierApi()

    return useQuery({
        ...options,
        queryKey: getAccessTierGroupByIdKey(id),
        queryFn: async (): Promise<AccessTierGroupDetails> => {
            const [accessTierGroupRes, { access_tiers }] = await Promise.all([
                accessTierGroupApi.getAccessTierGroupById(id),
                accessTierApi.getAccessTiers({ cluster_id: cluster.id }),
            ])

            const accessTiers = mapAccessTiersResToAccessTiersInGroup(
                access_tiers,
                accessTierGroupRes
            )
            return mapAccessTierGroupDetails(accessTierGroupRes, accessTiers)
        },
    })
}

export function useCreateAccessTierGroup(
    privateEdgeCluster: Cluster,
    options?: QueryOptions<AccessTierGroupDetails, unknown, CreateAccessTierGroupVariables>
): UseMutationResult<AccessTierGroupDetails, unknown, CreateAccessTierGroupVariables> {
    const accessTierGroupApi = new AccessTierGroupApi()

    const client = useQueryClient()

    return useMutation({
        ...options,
        mutationFn: async (
            accessTierGroup: CreateAccessTierGroupVariables
        ): Promise<AccessTierGroupDetails> => {
            const accessTierGroupRes = await accessTierGroupApi.createAccessTierGroup(
                mapAccessTierGroupBody(accessTierGroup, privateEdgeCluster)
            )

            if (accessTierGroup.accessTiers.length <= 0) {
                return mapAccessTierGroupDetails(accessTierGroupRes, accessTierGroup.accessTiers)
            }

            await accessTierGroupApi.attachAccessTierToGroup(accessTierGroupRes.id, {
                access_tier_ids: accessTierGroup.accessTiers.map((accessTier) => accessTier.id),
            })

            return mapAccessTierGroupDetails(accessTierGroupRes, accessTierGroup.accessTiers)
        },
        onSuccess: (accessTierGroup, variables, context) => {
            client.removeQueries([ApiFunction.GET_ACCESS_TIER_GROUPS])

            client.setQueryData<AccessTierGroupDetails>(
                getAccessTierGroupByIdKey(accessTierGroup.id),
                accessTierGroup
            )

            options?.onSuccess?.(accessTierGroup, variables, context)
        },
    })
}

export function useUpdateAccessTierGroup(
    privateEdgeCluster: Cluster,
    options?: QueryOptions<AccessTierGroupDetails, unknown, AccessTierGroupDetails>
): UseMutationResult<AccessTierGroupDetails, unknown, AccessTierGroupDetails> {
    const accessTierGroupApi = new AccessTierGroupApi()

    const client = useQueryClient()

    return useMutation({
        ...options,
        mutationFn: async (
            accessTierGroup: AccessTierGroupDetails
        ): Promise<AccessTierGroupDetails> => {
            const previousVersion = client.getQueryData<AccessTierGroupDetails>(
                getAccessTierGroupByIdKey(accessTierGroup.id)
            )

            const accessTierGroupRes = await accessTierGroupApi.updateAccessTierGroup(
                accessTierGroup.id,
                mapAccessTierGroupBody(
                    accessTierGroup,
                    privateEdgeCluster,
                    accessTierGroup.extra.accessTierGroupRes
                )
            )

            if (!previousVersion) {
                return mapAccessTierGroupDetails(accessTierGroupRes, accessTierGroup.accessTiers)
            }

            const { toDetach, toAttach } = getChangedAccessTierIds(previousVersion, accessTierGroup)

            if (toDetach.length > 0) {
                await accessTierGroupApi.detachAccessTierToGroup(accessTierGroup.id, {
                    access_tier_ids: toDetach,
                })
            }

            if (toAttach.length > 0) {
                await accessTierGroupApi.attachAccessTierToGroup(accessTierGroupRes.id, {
                    access_tier_ids: toAttach,
                })
            }

            return mapAccessTierGroupDetails(accessTierGroupRes, accessTierGroup.accessTiers)
        },
        onSuccess: (accessTierGroup, variables, context) => {
            client.setQueryData<Paginated<AccessTierGroup>>(
                [ApiFunction.GET_ACCESS_TIER_GROUPS],
                (maybeList) =>
                    maybeList && {
                        ...maybeList,
                        data: maybeList.data.map((group) =>
                            group.id === accessTierGroup.id ? accessTierGroup : group
                        ),
                    }
            )

            client.setQueryData<AccessTierGroupDetails>(
                getAccessTierGroupByIdKey(accessTierGroup.id),
                accessTierGroup
            )

            client.removeQueries([ApiFunction.GET_ACCESS_TIERS])

            options?.onSuccess?.(accessTierGroup, variables, context)
        },
    })
}

export function useDeleteAccessTierGroup(
    accessTierGroup: AccessTierGroupDetails,
    options?: QueryOptions
): UseMutationResult<void> {
    const accessTierGroupApi = new AccessTierGroupApi()

    const client = useQueryClient()

    return useMutation({
        ...options,
        mutationFn: async (): Promise<void> =>
            await accessTierGroupApi.deleteAccessTierGroup(accessTierGroup.id),
        onSuccess: () => {
            client.removeQueries([ApiFunction.GET_ACCESS_TIER_GROUPS])
            client.removeQueries(getAccessTierGroupByIdKey(accessTierGroup.id))
            options?.onSuccess?.()
        },
    })
}

export function useGetAccessTiers(
    privateEdgeCluster: Cluster,
    accessTierGroup?: AccessTierGroup,
    options?: QueryOptions<AccessTier[]>
): UseQueryResult<AccessTier[]> {
    const accessTierApi = new AccessTierApi()
    const registeredServicesApi = new RegisteredServiceApi()
    const serviceTunnelApi = new ServiceTunnelApi()

    return useQuery({
        ...options,
        queryKey: getAccessTiersKey(accessTierGroup?.id),
        queryFn: async (): Promise<AccessTier[]> => {
            const [{ access_tiers }, registeredServicesRes, { service_tunnels }] =
                await Promise.all([
                    accessTierApi.getAccessTiers(),
                    registeredServicesApi.getRegisteredServices(),
                    serviceTunnelApi.getServiceTunnels(),
                ])

            return getFilteredAccessTiers(
                privateEdgeCluster,
                accessTierGroup,
                access_tiers,
                registeredServicesRes,
                service_tunnels
            )
        },
    })
}

// Types

export enum FilterById {
    NAME = "name",
}

export interface AccessTierGroup {
    id: string
    name: string
    sharedAddress: string
    lastUpdatedAt: Date
}

export interface CreateAccessTierGroupVariables {
    name: string
    description?: string
    sharedAddress: string
    accessTiers: AccessTier[]
    groupLevelNetworkSettings?: NetworkSettings
    advancedSettings?: AdvancedSettings
}

export interface AccessTierGroupDetails extends CreateAccessTierGroupVariables {
    id: string
    lastUpdatedAt: Date
    createdAt: Date
    extra: {
        accessTierGroupRes: AccessTierGroupRes
    }
}

export interface AccessTier {
    id: string
    name: string
    status: Status
    netagentVersions: string[]
    netagentInstances: number
    publicAddress: string
    conditions?: Set<AccessTierUnavailabilityCondition>
}

export enum AccessTierUnavailabilityCondition {
    ALREADY_ASSIGNED = "already-assigned",
    VERSION_NOT_SUPPORTED = "version-not-supported",
    HAS_NETWORK_SETTINGS = "has-network-settings",
    SERVICE_ATTACHED = "service-attached",
}

// Helper Functions

function getAccessTierGroupsParams(
    search: PaginatedSearch<string, FilterById>
): Partial<GetAccessTierGroupsParams> {
    return {
        skip: search.skip,
        limit: search.limit,
        access_tier_group_name_like: search.filterModel?.[FilterById.NAME]?.filter,
    }
}

function mapAccessTierGroup(accessTierGroupRes: AccessTierGroupRes): AccessTierGroup {
    return {
        id: accessTierGroupRes.id,
        name: accessTierGroupRes.name,
        sharedAddress: accessTierGroupRes.tunnel_enduser.shared_fqdn,
        lastUpdatedAt: convertFromServerTimestamp(accessTierGroupRes.updated_at),
    }
}

function mapAccessTierGroupDetails(
    accessTierGroupRes: AccessTierGroupRes,
    accessTiers: AccessTier[]
): AccessTierGroupDetails {
    return {
        ...mapAccessTierGroup(accessTierGroupRes),
        description: accessTierGroupRes.description || undefined,
        createdAt: convertFromServerTimestamp(accessTierGroupRes.created_at),
        groupLevelNetworkSettings: {
            cidrs: accessTierGroupRes.tunnel_enduser.cidrs,
            domains: accessTierGroupRes.tunnel_enduser.domains,
        },
        advancedSettings: getAdvancedSettingsFromRes(accessTierGroupRes),
        accessTiers,
        extra: { accessTierGroupRes },
    }
}
function mapAccessTiersResToAccessTiersInGroup(
    accessTierRes: AccessTierRes[],
    accessTierGroup: AccessTierGroupRes
) {
    return accessTierRes
        .filter((accessTier) => accessTier.access_tier_group_id === accessTierGroup.id)
        .map(mapAccessTierRes)
}

function mapAccessTierGroupBody<AccessTierGroup extends CreateAccessTierGroupVariables>(
    accessTierGroup: AccessTierGroup,
    privateEdgeCluster: Cluster,
    accessTierGroupRes?: AccessTierGroupRes
): AccessTierGroupBody {
    return {
        name: accessTierGroup.name,
        description: accessTierGroup.description,
        shared_fqdn: accessTierGroup.sharedAddress,
        cluster_name: privateEdgeCluster.name,
        tunnel_enduser: getTunnelEnduserReq(
            undefined,
            accessTierGroup.groupLevelNetworkSettings,
            accessTierGroupRes?.tunnel_enduser
        ),
        advanced_settings: mapAdvancedSettingsJson(accessTierGroup.advancedSettings),
    }
}

function mapAdvancedSettingsJson(
    advancedSettings: AccessTierGroupDetails["advancedSettings"]
): AdvancedSettingsJson {
    return {
        logging: {
            statsd: !!advancedSettings?.metricsCollectionAddress,
            statsd_address: advancedSettings?.metricsCollectionAddress || undefined,
        },
        events: {
            access_event_credits_limiting:
                advancedSettings?.eventsRateLimiting &&
                fromAdvancedSettingsStatus[advancedSettings.eventsRateLimiting],
            access_event_key_limiting:
                advancedSettings?.eventKeyRateLimiting &&
                fromAdvancedSettingsStatus[advancedSettings.eventKeyRateLimiting],
        },
        hosted_web_services: {
            forward_trust_cookie:
                advancedSettings?.forwardTrustCookie &&
                fromAdvancedSettingsStatus[advancedSettings.forwardTrustCookie],
            disable_hsts:
                advancedSettings?.enableStrictTransport &&
                fromAdvancedSettingsStatus[advancedSettings.enableStrictTransport],
        },
        ...getServiceDiscoveryOptions(advancedSettings),
    }
}

const fromAdvancedSettingsStatus: Record<AdvancedSettingsStatus, boolean | undefined> = {
    [AdvancedSettingsStatus.DEFAULT]: undefined,
    [AdvancedSettingsStatus.ENABLED]: true,
    [AdvancedSettingsStatus.DISABLED]: false,
}

function getServiceDiscoveryOptions(
    advancedSettings: AccessTierGroupDetails["advancedSettings"]
): Pick<AdvancedSettingsJson, "service_discovery" | "miscellaneous"> {
    switch (advancedSettings?.enablePrivateResourceDiscovery) {
        case undefined:
        case AdvancedSettingsStatus.DEFAULT:
            return {}

        case AdvancedSettingsStatus.ENABLED:
            return {
                service_discovery: {
                    service_discovery_enable: true,
                    service_discovery_msg_limit: 100,
                    service_discovery_msg_timeout: 10_000_000_000,
                },
                miscellaneous: { enable_ipv6_resolution: true },
            }

        case AdvancedSettingsStatus.DISABLED:
            return { service_discovery: { service_discovery_enable: false }, miscellaneous: {} }
    }
}

function getFilteredAccessTiers(
    privateEdgeCluster: Cluster,
    accessTierGroup: AccessTierGroup | undefined,
    accessTiersRes: AccessTierRes[],
    registeredServicesRes: RegisteredServiceRes[],
    serviceTunnelsRes: ServiceTunnelRes[]
): AccessTier[] {
    const accessTierNameToRegisteredServicesDict = registeredServicesRes.reduce(
        reduceRegisteredServiceRes,
        {}
    )

    const accessTierNameToServiceTunnelsDict = serviceTunnelsRes.reduce(reduceServiceTunnelRes, {})

    return accessTiersRes.reduce(
        (acc: AccessTier[], accessTierRes: AccessTierRes) =>
            accessTierRes.access_tier_group_id === accessTierGroup?.id ||
            canAttachAccessTier(
                accessTierRes,
                privateEdgeCluster,
                accessTierNameToRegisteredServicesDict,
                accessTierNameToServiceTunnelsDict
            )
                ? [...acc, mapAccessTierRes(accessTierRes)]
                : acc,
        []
    )
}

function reduceRegisteredServiceRes(
    acc: Record<string, RegisteredServiceRes[]>,
    registeredServiceRes: RegisteredServiceRes
): Record<string, RegisteredServiceRes[]> {
    const serviceSpecJson = parseServiceSpecJson(registeredServiceRes)

    if (!serviceSpecJson) return acc

    const accessTierNames = serviceSpecJson.spec?.attributes?.host_tag_selector?.reduce(
        reduceHostTagSelector,
        []
    )

    if (!accessTierNames) return acc

    return accessTierNames.reduce((dict, accessTierName) => {
        const { [accessTierName]: registeredServicesResFromDict = [], ...otherRegisteredServices } =
            dict
        return {
            ...otherRegisteredServices,
            [accessTierName]: [...registeredServicesResFromDict, registeredServiceRes],
        }
    }, acc)
}

function parseServiceSpecJson(
    registeredServiceRes: RegisteredServiceRes
): ServiceSpecJson | undefined {
    try {
        return JSON.parse(registeredServiceRes.ServiceSpec)
    } catch (_error) {
        return undefined
    }
}

function reduceHostTagSelector(acc: string[], hostTagSelector: HostTagSelector): string[] {
    const accessTierNames = hostTagSelector["com.banyanops.hosttag.site_name"]?.split("|")
    return accessTierNames ? [...acc, ...accessTierNames] : acc
}

function reduceServiceTunnelRes(
    acc: Record<string, ServiceTunnelRes[]>,
    serviceTunnelRes: ServiceTunnelRes
): Record<string, ServiceTunnelRes[]> {
    const accessTierNames = serviceTunnelRes.spec.spec.peer_access_tiers.reduce<string[]>(
        (acc, peerAccessTier) => [...acc, ...(peerAccessTier.access_tiers ?? [])],
        []
    )

    return accessTierNames.reduce((dict, accessTierName) => {
        const { [accessTierName]: serviceTunnelsResFromDict = [], ...otherServiceTunnels } = dict
        return {
            ...otherServiceTunnels,
            [accessTierName]: [...serviceTunnelsResFromDict, serviceTunnelRes],
        }
    }, acc)
}

function canAttachAccessTier(
    accessTierRes: AccessTierRes,
    privateEdgeCluster: Cluster,
    accessTierNameToRegisteredServicesDict: Record<string, RegisteredServiceRes[]>,
    accessTierNameToServiceTunnelsDict: Record<string, ServiceTunnelRes[]>
): boolean {
    const registeredServicesRes = accessTierNameToRegisteredServicesDict[accessTierRes.name]
    const serviceTunnelsRes = accessTierNameToServiceTunnelsDict[accessTierRes.name]

    return (
        accessTierRes.cluster_name === privateEdgeCluster.name &&
        !accessTierRes.access_tier_group_id &&
        (!registeredServicesRes || registeredServicesRes.length <= 0) &&
        (!serviceTunnelsRes || serviceTunnelsRes.length <= 0) &&
        !accessTierRes.tunnel_enduser
    )
}

function mapAccessTierRes(accessTierRes: AccessTierRes): AccessTier {
    return {
        id: accessTierRes.id,
        name: accessTierRes.name,
        status: statusFromRes[accessTierRes.status],
        netagentVersions: getNetagentVersions(accessTierRes),
        netagentInstances: accessTierRes.netagents?.length || 0,
        publicAddress: accessTierRes.address,
    }
}

function getChangedAccessTierIds(
    previous: AccessTierGroupDetails,
    updated: AccessTierGroupDetails
) {
    const previousIds = previous.accessTiers.map((accessTier) => accessTier.id)
    const updatedIds = updated.accessTiers.map((accessTier) => accessTier.id)
    return {
        toDetach: previousIds.filter((id) => !updatedIds.includes(id)),
        toAttach: updatedIds.filter((id) => !previousIds.includes(id)),
    }
}
