import { Singleton } from "../decorators/Singleton.decorator"
import { Paginated, PaginatedSearch } from "../utils/AgGrid.util"
import { ApiUtil } from "../utils/Api.util"
import {
    SystemLogItemRes,
    SystemLogProps,
    SystemLogRes,
    SystemLogsApi,
} from "../api/SystemLogs.api"
import { DateUtil } from "../utils/Date.util"
import { LocalizationService } from "./localization/Localization.service"

@Singleton("SystemLogsService")
export class SystemLogsService {
    public getSystemLogs(
        params: PaginatedSearch,
        startTime: number,
        endTime: number
    ): Promise<Paginated<MonitorSystemLog>> {
        const search: SystemLogProps = {
            skip: params.skip,
            limit: params.limit,
            start_time: startTime,
            end_time: endTime,
        }
        if (params.filterModel?.type) {
            search.type = params.filterModel.type.filter
        }
        if (params.filterModel?.adminEmail) {
            search.admin_email = params.filterModel.adminEmail.filter
        }
        if (params.filterModel?.action) {
            search.action = params.filterModel.action.filter
        }

        if (params.sortModel && params.sortModel.length > 0) {
            search.order = params.sortModel[0].sort
        }

        const p: SystemLogProps = ApiUtil.sanitizeObject(search)
        return this.systemLogsApi
            .getSystemLogs(p)
            .then((response: SystemLogRes) => {
                return {
                    data: response.auditlogs
                        ? response.auditlogs.map(this.mapSystemLogItemResToSystemLog)
                        : [],
                    total: response.count,
                }
            })
            .catch((error) => {
                if (error.message === "unauthorized access") {
                    throw new Error(
                        this.localizationService.getString(
                            "yourAccountDoesNotHavePermissionToViewThisPage"
                        )
                    )
                }

                throw error
            })
    }

    private localizationService: LocalizationService = new LocalizationService()
    private systemLogsApi: SystemLogsApi = new SystemLogsApi()

    private mapSystemLogItemResToSystemLog(logs: SystemLogItemRes): MonitorSystemLog {
        return {
            id: logs.id,
            timestamp: DateUtil.formatLargeTimestamp(logs.created_at),
            type: logs.type,
            activity: logs.message,
            action: logs.action,
            adminEmail: logs.admin_email,
            raw: JSON.stringify(logs, null, 4),
        }
    }

    public getActionTypes(): string[] {
        return Object.values(ActionTypes).sort()
    }

    public getActivityTypes(): string[] {
        return Object.values(ActivityTypes).sort()
    }
}

export interface MonitorSystemLog {
    timestamp: string
    type: string
    activity: string
    action?: string
    adminEmail?: string
    id?: string
    raw?: string
}

export enum ActionTypes {
    CREATE = "create",
    UPDATE = "update",
    DELETE = "delete",
    ENABLE = "enable",
    DISABLE = "disable",
}

export enum ActivityTypes {
    ADMIN_SIGN_ON = "admin_sign_on",
    IDP_SETTINGS = "idp_settings",
    MDM_SETTINGS = "mdm_settings",
    POLICY = "policy",
    REGISTERED_SERVICE = "registered_service",
    ROLE = "role",
    SECURITY_ATTACH_POLICY = "security_attach_policy",
    TRUSTSCORE_FACTORS = "trustscore_factors",
    UNKNOWN_DEVICE = "unknown_device",
    DEVICE_REGISTRATION_IDP_SETTINGS = "device_registration_idp_settings",
    ADMIN_USER = "admin_user",
    MDM_DEPLOY_OTP_SKIP_ROLE = "mdm_deploy_otp_skip_role",
    MDM_DEPLOY_KEY = "mdm_deploy_key",
    INVITATION_CODE = "invitation_code",
    TRUSTSCORE_TTL = "trustscore_ttl",
    ENDUSER_DEVICE = "enduser_device",
    PREFERRED_APPLICATIONS = "preferred_applications",
    LATEST_OS_CONFIG = "latest_os_config",
    TRUST_CONFIG = "trust_config",
    ROOT_CERTS = "root_certs",
    SAAS_APPLICATIONS = "saas_applications",
    IDP_ROUTED_APPLICATIONS = "idp_routed_applications",
    ACCESS_TIER = "access_tier",
    SATELLITE = "satellite",
    ACCESS_TIER_TUNNEL = "access_tier_tunnel",
    API_KEY = "api_key",
    ENDUSER = "enduser",
    DEVICE = "device",
    SERVICE_TUNNEL = "service_tunnel",
    REFRESH_TOKEN = "refresh_token",
    ORG = "org",
}
