export function isValidPasswordRegex(password: string): boolean {
    const hasLowerCase = !!"abcdefghijklmnopqrstuvwxyz"
        .split("")
        .find((letter) => password.indexOf(letter) !== -1)

    const hasUpperCase = !!"ABCDEFGHIJKLMNOPQRSTUVWXYZ"
        .split("")
        .find((letter) => password.indexOf(letter) !== -1)

    const hasCorrectLength = password.length >= 8

    return hasLowerCase && hasUpperCase && hasCorrectLength
}
