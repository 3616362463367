import React, { ReactNode } from "react"
import ServicesAddAppTypeTemplate from "./ServicesAddAppType.template"
import { LocalizationService } from "../../../../services/localization/Localization.service"
import { ToggleButtonItem } from "../../../../components/toggle-button/ToggleButton.component"
import { ServiceAppType } from "../../../../services/Manage.service"

export class ServicesAddAppType extends React.Component<
    ServicesAddAppTypeProps,
    ServicesAddAppTypeState
> {
    constructor(props: ServicesAddAppTypeProps) {
        super(props)

        if (props.initialValue) {
            const selectedValue: string = props.initialValue
            this.state.appTypes.forEach((a: ToggleButtonItem) => {
                a.selected = a.value === selectedValue
            })
            const selectedIndex: number = this.state.appTypes.findIndex(
                (a) => a.value === selectedValue
            )
            if (selectedIndex >= 0) {
                this.state.selectedIndex = selectedIndex
            }
        }
    }

    private localizationService: LocalizationService = new LocalizationService()

    public state: ServicesAddAppTypeState = {
        selectedIndex: 0,
        appTypes: [
            {
                label: this.localizationService.getString("ssh"),
                value: ServiceAppType.SSH,
                onClick: () => {
                    this.emit(ServiceAppType.SSH)
                },
                selected: true,
            },
            {
                label: this.localizationService.getString("rdp"),
                value: ServiceAppType.RDP,
                onClick: () => {
                    this.emit(ServiceAppType.RDP)
                },
            },
            {
                label: this.localizationService.getString("kubernetes"),
                value: ServiceAppType.K8S,
                onClick: () => {
                    this.emit(ServiceAppType.K8S)
                },
            },
            {
                label: this.localizationService.getString("genericTcp"),
                value: ServiceAppType.GENERIC,
                onClick: () => {
                    this.emit(ServiceAppType.GENERIC)
                },
            },
            {
                label: this.localizationService.getString("database"),
                value: ServiceAppType.DATABASE,
                onClick: () => {
                    this.emit(ServiceAppType.DATABASE)
                },
            },
        ],
    }

    public render(): ReactNode {
        return ServicesAddAppTypeTemplate.call(this)
    }

    private emit(type: ServiceAppType): void {
        if (this.props.disabled) {
            return
        }

        if (this.props.onChange) {
            this.props.onChange(type)
        }
    }
}

interface ServicesAddAppTypeProps {
    initialValue: ServiceAppType
    disabled: boolean
    onChange: (type: ServiceAppType) => void
}

interface ServicesAddAppTypeState {
    appTypes: ToggleButtonItem[]
    selectedIndex: number
}
