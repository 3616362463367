import React from "react"
import { InputWithAction } from "../input-with-action/InputWithAction.component"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faEye, faEyeSlash } from "@fortawesome/pro-solid-svg-icons"

export default function () {
    const showHideToggle = this.state.value?.length && !this.props.hideEyeIcon
    return (
        <InputWithAction
            type={this.state.hide ? "password" : "text"}
            onChange={this.onChange}
            value={this.state.value}
            onAction={showHideToggle && this.onHide}
            id={this.props.id}
            required={this.props.required}
            placeholder={this.props.placeholder}
            disabled={this.props.disabled}
            className={this.props.className}
        >
            {showHideToggle && <FontAwesomeIcon icon={this.state.hide ? faEye : faEyeSlash} />}
        </InputWithAction>
    )
}
