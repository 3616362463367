import React, { ReactNode, SyntheticEvent } from "react"
import ServiceBundleNameCellRendererTemplate from "./ServiceBundleNameCellRenderer.template"
import { ICellRendererParams } from "ag-grid-community"

import { ROUTE, formatRoutePath } from "../../../../../../routes"
import { Bind } from "../../../../../decorators/Bind.decorator"
import { encodeID } from "../../../../../utils/Url.util"

export class ServiceBundleNameCellRenderer extends React.Component<
    ServiceBundleNameCellRendererProps,
    ServiceBundleNameCellRendererState
> {
    public static getDerivedStateFromProps(
        props: ServiceBundleNameCellRendererProps
    ): ServiceBundleNameCellRendererState {
        return {
            link: formatRoutePath(ROUTE.BUNDLES_DETAILS, {
                id: encodeID(props.data.name),
            }),
        }
    }

    public state: ServiceBundleNameCellRendererState = { link: "" }

    public render(): ReactNode {
        return ServiceBundleNameCellRendererTemplate.call(this)
    }

    public refresh(): boolean {
        return true
    }

    @Bind
    private onClick(event: SyntheticEvent): void {
        event.preventDefault()
        this.props.context.history.push(this.state.link)
    }
}

interface ServiceBundleNameCellRendererProps extends ICellRendererParams {
    context: any
}

interface ServiceBundleNameCellRendererState {
    link: string
}
