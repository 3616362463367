import React from "react"

import { Link, LinkElement } from "../../../components/link/Link.component"
import { ROUTE } from "../../../routes"
import { InfoIcon } from "../info-icon/InfoIcon.component"
import styles from "./RowTitle.module.scss"

interface Props extends MaybeLinkProps {
    title: string
    description?: string
    route?: ROUTE
    image?: string
}

export function RowTitle(props: Props): JSX.Element {
    const { description, ...maybeLinkProps } = props

    return (
        <div className={styles.container}>
            {props.image && (
                <img className={styles.image} src={props.image} alt={props.title}></img>
            )}
            <MaybeLink {...maybeLinkProps} />
            {description && <InfoIcon tooltip={description} className={styles.icon} />}
        </div>
    )
}

export const rowTitleClassName = styles.cell

interface MaybeLinkProps {
    title: string
    route?: ROUTE
}

function MaybeLink(props: MaybeLinkProps): JSX.Element {
    if (!props.route) return <React.Fragment>{props.title}</React.Fragment>

    return (
        <Link to={props.route} asElement={LinkElement.LINK}>
            {props.title}
        </Link>
    )
}
