import React from "react"

import { useServiceLocalization } from "../../../pre-v3/services/localization/Localization.service"
import { StringUtil } from "../../../pre-v3/utils/String.util"
import {
    Application,
    Network,
    NetworkSetting,
    PrivateEdgeATGNetworkSetting,
    PrivateEdgeNetworkSetting,
    PublicInfo,
} from "../../../v3/services/ServiceTunnelV2.service"
import { CollapsibleCard } from "../../../components/collapsible-card/CollapsibleCard.component"
import { PublicInfoCard, PublicInfoCardApi } from "./PublicInfoCard.component"
import styles from "./PrivateEdgeNetworkSettingCard.module.scss"
import { checkPublicInfoDuplicates } from "./checkPublicInfoDuplicates"

export interface Props {
    networkSetting: PrivateEdgeATGNetworkSetting | PrivateEdgeNetworkSetting
    onNetworkSettingChange(networkSetting: NetworkSetting): void
    onRemoveAccessTier(accessTier: NetworkSetting): void
    applications: Application[]
    disabled?: boolean
    hideApplication: boolean
}

export function PrivateEdgeNetworkSettingCard(props: Props): JSX.Element {
    const localization = useServiceLocalization()

    const publicIncludeInfoRef = React.useRef<PublicInfoCardApi>(null)
    const publicExcludeInfoRef = React.useRef<PublicInfoCardApi>(null)

    const onPublicIncludeInfoChange = (publicInfo: PublicInfo): void => {
        const checkingDupes = checkPublicInfoDuplicates(
            publicInfo,
            props.networkSetting.publicExcludeInfo
        )

        publicIncludeInfoRef.current?.triggerDomainInputDuplicateError(checkingDupes.dupeDomains)
        publicIncludeInfoRef.current?.triggerIpRangeInputDuplicateError(checkingDupes.dupeIpRanges)

        if (checkingDupes.dupeDomains.length === 0) {
            publicExcludeInfoRef.current?.triggerDomainInputDuplicateError([])
        }

        if (checkingDupes.dupeIpRanges.length === 0) {
            publicExcludeInfoRef.current?.triggerIpRangeInputDuplicateError([])
        }

        props.onNetworkSettingChange({
            ...props.networkSetting,
            publicIncludeInfo: publicInfo,
        })
    }

    const onPublicExcludeInfoChange = (publicInfo: PublicInfo): void => {
        const checkingDupes = checkPublicInfoDuplicates(
            publicInfo,
            props.networkSetting.publicIncludeInfo
        )

        publicExcludeInfoRef.current?.triggerDomainInputDuplicateError(checkingDupes.dupeDomains)
        publicExcludeInfoRef.current?.triggerIpRangeInputDuplicateError(checkingDupes.dupeIpRanges)

        if (checkingDupes.dupeDomains.length === 0) {
            publicIncludeInfoRef.current?.triggerDomainInputDuplicateError([])
        }

        if (checkingDupes.dupeIpRanges.length === 0) {
            publicIncludeInfoRef.current?.triggerIpRangeInputDuplicateError([])
        }

        props.onNetworkSettingChange({
            ...props.networkSetting,
            publicExcludeInfo: publicInfo,
        })
    }

    const onRemoveNetwork = (): void => {
        props.onRemoveAccessTier(props.networkSetting)
    }

    // pill multi select requires the complete list of items to be passed in to render properly the selected items
    const ignoredApplications = React.useMemo(
        () =>
            [
                ...props.networkSetting.publicIncludeInfo.applications,
                ...props.networkSetting.publicExcludeInfo.applications,
            ].map((app) => app.id),
        [
            props.networkSetting.publicIncludeInfo.applications,
            props.networkSetting.publicExcludeInfo.applications,
        ]
    )

    const cardId = StringUtil.toCamelCase(props.networkSetting.network.name)
    const privateInfoId = `${cardId}PrivateInfo`
    const publicIncludeInfoId = `${cardId}PublicIncludeInfo`
    const publicExcludeInfoId = `${cardId}PublicExcludeInfo`

    const name = props.networkSetting.network.name
    const privateInfo = props.networkSetting.network

    return (
        <CollapsibleCard
            id={cardId}
            title={name}
            removeLabel={localization.getString("removeNetwork")}
            removeMessage={localization.getString(
                "areYouSureYouWantToRemoveSomethingFromYourNetwork",
                name
            )}
            cardAction={onRemoveNetwork}
            disabled={props.disabled}
        >
            <PrivateInfo id={privateInfoId} networkInfo={privateInfo} />

            <PublicInfoCard
                id={publicIncludeInfoId}
                publicInfo={props.networkSetting.publicIncludeInfo}
                onPublicInfoChange={onPublicIncludeInfoChange}
                applications={props.applications}
                cardTitle={localization.getString("publicIncludeInfo")}
                ignoredApplications={ignoredApplications}
                disabled={props.disabled}
                appsInputLabel={localization.getString(
                    "somethingToInclude",
                    localization.getString("apps")
                )}
                appsInputDescription={localization.getString(
                    "somethingToRouteThroughThisNetwork",
                    localization.getString("apps")
                )}
                domainsInputLabel={localization.getString(
                    "somethingToInclude",
                    localization.getString("publicDomains")
                )}
                domainsInputDescription={localization.getString(
                    "somethingToRouteThroughThisNetwork",
                    localization.getString("publicDomains")
                )}
                ipRangesInputLabel={localization.getString(
                    "somethingToInclude",
                    localization.getString("publicIpRanges")
                )}
                ipRangesInputDescription={localization.getString(
                    "somethingToRouteThroughThisNetwork",
                    localization.getString("publicIpRanges")
                )}
                ref={publicIncludeInfoRef}
                hideApplication={props.hideApplication}
            />
            <PublicInfoCard
                id={publicExcludeInfoId}
                publicInfo={props.networkSetting.publicExcludeInfo}
                onPublicInfoChange={onPublicExcludeInfoChange}
                applications={props.applications}
                cardTitle={localization.getString("publicExcludeInfo")}
                ignoredApplications={ignoredApplications}
                disabled={props.disabled}
                appsInputLabel={localization.getString(
                    "somethingToExclude",
                    localization.getString("apps")
                )}
                appsInputDescription={localization.getString(
                    "somethingToExcludeFromThisNetwork",
                    localization.getString("apps")
                )}
                domainsInputLabel={localization.getString(
                    "somethingToExclude",
                    localization.getString("domains")
                )}
                domainsInputDescription={localization.getString(
                    "somethingToExcludeFromThisNetwork",
                    localization.getString("domains")
                )}
                ipRangesInputLabel={localization.getString(
                    "somethingToExclude",
                    localization.getString("ipRanges")
                )}
                ipRangesInputDescription={localization.getString(
                    "somethingToExcludeFromThisNetwork",
                    localization.getString("ips")
                )}
                ref={publicExcludeInfoRef}
                hideApplication={props.hideApplication}
            />
        </CollapsibleCard>
    )
}

interface PrivateInfoProps {
    id: string
    networkInfo: Network
}

function PrivateInfo(props: PrivateInfoProps): JSX.Element {
    const localization = useServiceLocalization()

    return (
        <CollapsibleCard id={props.id} title={localization.getString("privateInfo")} isCardChild>
            <AccessTierDomainIpRangesList
                domains={props.networkInfo.privateDomains}
                ipRanges={props.networkInfo.privateIpRanges}
            />
        </CollapsibleCard>
    )
}

interface AccessTierDomainIpListProps {
    domains: string[]
    ipRanges: string[]
}

function AccessTierDomainIpRangesList(props: AccessTierDomainIpListProps): JSX.Element {
    return (
        <div className={styles.privateAccessTiers}>
            <div className={styles.privateContent}>
                <div className={styles.label}>Private Domains</div>
                <div className={styles.list}>
                    {props.domains.length ? (
                        props.domains.map((domain, index) => {
                            return <div key={index}>{domain}</div>
                        })
                    ) : (
                        <div>{"-"}</div>
                    )}
                </div>
            </div>
            <div className={styles.privateContent}>
                <div className={styles.label}>Private IP Ranges</div>
                <div className={styles.list}>
                    {props.ipRanges ? (
                        props.ipRanges.map((cidr, index) => {
                            return <div key={index}>{cidr}</div>
                        })
                    ) : (
                        <div>{"-"}</div>
                    )}
                </div>
            </div>
        </div>
    )
}
