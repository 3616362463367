import { createTheme, ThemeProvider as MuiThemeProvider } from "@mui/material/styles"
import { ThemeProviderProps } from "@mui/material/styles/ThemeProvider"
import React from "react"

export const theme = createTheme({
    components: {
        MuiButtonBase: { defaultProps: { disableRipple: true } },
        MuiPaper: { defaultProps: { square: false } },
        MuiTooltip: {
            styleOverrides: {
                tooltip: {
                    fontSize: 13,
                    padding: "8px 16px",
                    backgroundColor: "#232635",
                },
            },
        },
        MuiSelect: {
            styleOverrides: {
                select: {
                    fontSize: 14,
                    fontWeight: 500,
                    padding: "12px 16px",
                    borderColor: "#D7D9E4",
                    borderRadius: 4,
                },
            },
        },
        MuiDialogActions: { styleOverrides: { root: { margin: 0 } } },
    },
    typography: {
        fontSize: 12,
    },
})

type Props = Omit<ThemeProviderProps<typeof theme>, "theme">

export function ThemeProvider(props: Props) {
    const { children, ...otherProps } = props
    return React.createElement(MuiThemeProvider, { ...otherProps, theme }, children)
}
