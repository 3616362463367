import React from "react"

import { useServiceLocalization } from "../../../../pre-v3/services/localization/Localization.service"
import { DateUtil } from "../../../../pre-v3/utils/Date.util"
import { StringUtil } from "../../../../pre-v3/utils/String.util"
import { encodeID } from "../../../../pre-v3/utils/Url.util"
import { ROUTE, formatRoutePath } from "../../../../routes"
import { Loader } from "../../../components/loader/Loader.component"
import {
    AccessActivity as AccessActivityType,
    Device,
    useGetDeviceAccessActivity,
} from "../../../services/Device.service"
import { ErrorBanner } from "../../../components/banner/Banner.component"
import {
    Column,
    Grid,
    sortAlphabetically,
    sortByTimeStamp,
} from "../../../components/grid/Grid.component"
import { RowTitle } from "../../../components/grid/RowTitle.component"
import { SearchInput } from "../../../components/search-input/SearchInput.component"
import styles from "./AccessActivity.module.scss"

export interface Api {
    refresh(): void
}

interface Props {
    device: Device
}

export const DeviceAccessActivity = React.forwardRef<Api, Props>((props, ref) => {
    const localization = useServiceLocalization()

    const [search, setSearch] = React.useState("")

    const {
        data: accessActivities,
        status: accessActivitiesStatus,
        error: accessActivitiesError,
        refetch: accessActivitiesRefetch,
    } = useGetDeviceAccessActivity(props.device)

    React.useImperativeHandle(ref, () => ({
        refresh: accessActivitiesRefetch,
    }))

    const columns = useColumns()

    if (accessActivitiesStatus === "loading")
        return (
            <Loader
                center
                medium
                title={localization.getString(
                    "loadingSomething",
                    localization.getString("accessActivities")
                )}
            />
        )

    if (accessActivitiesStatus === "error")
        return <ErrorBanner>{String(accessActivitiesError)}</ErrorBanner>

    const filterActivity = search
        ? accessActivities.filter((accessActivity) => filterAccessActivity(accessActivity, search))
        : accessActivities

    return (
        <React.Fragment>
            <SearchInput
                value={search}
                onChangeValue={setSearch}
                placeholder={localization.getString("search")}
                className={styles.searchInput}
            />
            <Grid data={filterActivity} columns={columns} getId={getUniqueAccessActivityKey} />
        </React.Fragment>
    )
})

DeviceAccessActivity.displayName = "DeviceAccessActivity"

function filterAccessActivity(accessActivity: AccessActivityType, search: string): boolean {
    return (
        StringUtil.caseInsensitiveIncludes(accessActivity.service.name, search) ||
        StringUtil.caseInsensitiveIncludes(accessActivity.user.name, search)
    )
}

function useColumns(): Column<AccessActivityType>[] {
    const localization = useServiceLocalization()

    return React.useMemo((): Column<AccessActivityType>[] => {
        const serviceColumn: Column<AccessActivityType> = {
            id: "service",
            name: localization.getString("service"),
            cellRenderer: (accessActivity) => (
                <RowTitle
                    title={accessActivity.service.name}
                    route={formatRoutePath(ROUTE.PRIVATE_ACCESS_SERVICES_DETAILS, {
                        id: encodeID(accessActivity.service.id),
                    })}
                />
            ),
            getTooltipValue: (accessActivity) => accessActivity.service.name,
            sorting: sortAlphabetically((accessActivity) => accessActivity.service.name),
        }

        const userColumn: Column<AccessActivityType> = {
            id: "user",
            name: localization.getString("user"),
            cellRenderer: (accessActivity) => (
                <RowTitle
                    title={accessActivity.user.name}
                    route={formatRoutePath(ROUTE.USERS_DETAILS, {
                        id: encodeID(accessActivity.user.emailAddress),
                    })}
                />
            ),
            getTooltipValue: (accessActivity) => accessActivity.user.name,
            sorting: sortAlphabetically((accessActivity) => accessActivity.user.name),
        }

        const lastAuthorizedColumn: Column<AccessActivityType> = {
            id: "lastAuthorized",
            name: localization.getString("lastAuthAccess"),
            cellRenderer: (accessActivity) => DateUtil.format(accessActivity.lastAuthorizedAt),
            getTooltipValue: (accessActivity) => DateUtil.format(accessActivity.lastAuthorizedAt),
            sorting: sortByTimeStamp("lastAuthorizedAt"),
        }

        const lastUnauthorizedColumn: Column<AccessActivityType> = {
            id: "lastUnauthorized",
            name: localization.getString("lastUnauthAccess"),
            cellRenderer: (accessActivity) => DateUtil.format(accessActivity.lastUnauthorizedAt),
            getTooltipValue: (accessActivity) => DateUtil.format(accessActivity.lastUnauthorizedAt),
            sorting: sortByTimeStamp("lastUnauthorizedAt"),
        }

        return [serviceColumn, userColumn, lastAuthorizedColumn, lastUnauthorizedColumn]
    }, [])
}

function getUniqueAccessActivityKey(accessActivity: AccessActivityType): string {
    return `${accessActivity.service.id}-${accessActivity.user.emailAddress}-${DateUtil.format(
        accessActivity.lastAuthorizedAt
    )}`
}
