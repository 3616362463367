import { DialogActions, DialogContent } from "@mui/material"
import React from "react"

import { Button, ButtonElement, ButtonType } from "../../../../components/button/Button.component"
import { useServiceLocalization } from "../../../../pre-v3/services/localization/Localization.service"
import { ModalRef, useServiceModal } from "../../../../pre-v3/services/Modal.service"
import {
    NewServiceAccount,
    ServiceAccount,
    useCreateServiceAccount,
} from "../../../services/ServiceAccount.service"
import { ServiceAccountForm } from "../../service-accounts/shared/ServiceAccountForm.component"
import styles from "./CreateServiceAccountModal.module.scss"

interface CreateServiceAccountModalProps {
    modalRef: ModalRef
}

function CreateServiceAccountModal(props: CreateServiceAccountModalProps): JSX.Element {
    const localization = useServiceLocalization()
    const createServiceAccount = useCreateServiceAccount({
        onSuccess: props.modalRef.close,
    })

    const [newServiceAccount, setNewServiceAccount] = React.useState<Partial<NewServiceAccount>>()

    const onCancel: React.MouseEventHandler = (event) => {
        event.preventDefault()
        props.modalRef.cancel(undefined)
    }

    const onSubmit: React.FormEventHandler = (event) => {
        event.preventDefault()
        createServiceAccount.mutate(newServiceAccount as NewServiceAccount)
    }

    return (
        <form onSubmit={onSubmit} className={styles.form}>
            <DialogContent>
                <ServiceAccountForm
                    serviceAccount={newServiceAccount}
                    onChange={setNewServiceAccount}
                />
            </DialogContent>
            <DialogActions>
                <Button
                    asElement={ButtonElement.BUTTON}
                    buttonType={ButtonType.SECONDARY}
                    onClick={onCancel}
                >
                    {localization.getString("cancel")}
                </Button>
                <Button
                    asElement={ButtonElement.BUTTON}
                    buttonType={ButtonType.PRIMARY}
                    type="submit"
                    loading={createServiceAccount.isLoading}
                >
                    {localization.getString("save")}
                </Button>
            </DialogActions>
        </form>
    )
}

export function useCreateServiceAccountModal(
    onSuccess: (serviceAccount: ServiceAccount) => void
): () => ModalRef {
    const localization = useServiceLocalization()
    const modalService = useServiceModal()

    const title = localization.getString("createServiceAccount")

    return React.useCallback<() => ModalRef>(
        (): ModalRef =>
            modalService
                .open(title, {
                    component: CreateServiceAccountModal,
                    withoutBody: true,
                    maxWidth: "md",
                })
                .onClose(onSuccess),
        [modalService, title, onSuccess]
    )
}
