import * as React from "react"
import styles from "./ToggleButton.module.scss"
import classNames from "classnames"
import { Button } from "../button/Button.component"

export interface ToggleButtonProps {
    items: ToggleButtonItem[]
    className?: string
    disabled?: boolean
    required?: boolean
    onToggle?: (item: ToggleButtonItem) => void
}

export function ToggleButton(props: ToggleButtonProps) {
    const [selectedIndex, setIndex] = React.useState<number>(-1)
    React.useEffect(() => {
        setIndex(props.items.findIndex((i) => i.selected!))
    }, [props.items])

    function onToggle(index: number): void {
        if (props.disabled) {
            return
        }
        setIndex(index)

        props.items.forEach((item, i) => (item.selected = index === i))
        if (props.items[index] && props.items[index].onClick) {
            props.items[index].onClick!()
        }

        props.onToggle?.(props.items[index])
    }

    return (
        <div className={classNames(styles.toggleButtonWrapper, props.className)}>
            <input
                type="text"
                required={props.required}
                value={selectedIndex >= 0 ? "selected" : ""}
                onChange={() => {}}
                className={styles.requiredInput}
            />
            {props.items.map((item, i) => {
                const isSelected = i === selectedIndex

                return (
                    !item.hide && (
                        <Button
                            key={i}
                            disabled={!isSelected && props.disabled}
                            className={classNames(styles.toggleButton, {
                                [styles.disabled]: props.disabled,
                                [styles.toggleWithImage]: item.image,
                                [styles.selected]: isSelected,
                            })}
                            onClick={(event) => {
                                event.preventDefault()
                                onToggle(i)
                            }}
                            brand={isSelected}
                            role="checkbox"
                            aria-checked={isSelected}
                            aria-label={item.ariaLabel}
                        >
                            <p className={styles.label}>{item.label}</p>
                            {item.image && (
                                <img className={styles.image} src={item.image} alt={item.altText} />
                            )}
                        </Button>
                    )
                )
            })}
        </div>
    )
}

export interface ToggleButtonItem {
    label?: string
    ariaLabel?: string
    value?: any
    onClick?(): void
    selected?: boolean
    image?: string
    altText?: string
    hide?: boolean
}
