import * as React from "react"
import { useFormLabelWidth } from ".."
import styles from "./FormColumns.module.scss"
import classnames from "classnames"

interface Props {
    left?: React.ReactNode
    right?: React.ReactNode
    className?: string
    leftClassName?: string
    rightClassName?: string
}

export function FormColumns({ left, right, className, leftClassName, rightClassName }: Props) {
    const contextWidth = useFormLabelWidth()
    // this magic 48 is to match the left margin of a form section for
    // list views which likely won't specify a label width
    const labelWidth = typeof contextWidth === "number" ? contextWidth : 48

    return (
        <section className={classnames(styles.container, className)}>
            <div className={leftClassName} style={{ width: labelWidth }}>
                {left}
            </div>
            <div className={classnames(styles.content, rightClassName)}>{right}</div>
        </section>
    )
}
