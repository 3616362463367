import React from "react"
import { useHistory } from "react-router-dom"

import { Button, ButtonElement, ButtonType } from "../../../../components/button/Button.component"
import { ServiceAttr, ServiceMetadata } from "../../../api/Manage.api"
import { PageBreak, ErrorBanner, Form } from "../../../components"
import { useServiceLocalization } from "../../../services/localization/Localization.service"
import { ClusterInfra } from "../../../services/Infra.service"
import { ServiceManage, useServiceManage } from "../../../services/Manage.service"
import { ServiceAppType, ServiceType } from "../../../services/Manage.service"
import ServiceDetailsForm from "./details"
import ServicePolicyForm from "./policy"
import styles from "./ServiceForm.module.scss"

// the props shared by the various forms
export type ServiceFormProps = {
    edit?: ServiceManage | null | undefined
    hideBackend?: boolean
}

export type SubmitHandler = (data: {
    metaData: ServiceMetadata
    attributes: ServiceAttr
    policyID: string
    policyEnabled: boolean
}) => void | Promise<void>

interface Props {
    type: ServiceType
    appType: ServiceAppType
    edit?: ServiceManage
    enableFormField?: boolean
    onSubmit: SubmitHandler
    children: React.ReactNode
    onCancel?: () => void | Promise<void>
    isDisabled?: boolean
    hideCluster?: boolean
    hideDisableDelete?: boolean
}

// the ServiceForm component is responsible for showing the correct form for
// a given type of service. If `edit` is passed, it will show the form modifying
// the provided Service
export default function ServiceForm({
    type,
    appType,
    edit,
    enableFormField,
    onSubmit,
    onCancel,
    children,
    isDisabled,
    hideCluster,
    hideDisableDelete,
}: Props) {
    const history = useHistory()

    // pull out the services we need
    const manageService = useServiceManage()
    const localization = useServiceLocalization()

    // NOTE: no need to worry about initial state for editing, the sub-forms will
    // fill it in when they grab the initial values for their inputs

    // we will handle the actual save and network request so we need some state to track it all
    const initialAttr = manageService.getNullServiceAttr()
    const [attributes, setAttributes] = React.useState({
        ...initialAttr,
        attributes: {
            ...initialAttr.attributes,
            frontend_addresses: edit?.spec?.spec.attributes.frontend_addresses || [],
        },
    })

    const [metaData, setMetaData] = React.useState({
        ...manageService.getNullServiceMetadata(),
        tags: {
            template: type,
            service_app_type: appType,
            protocol: type === ServiceType.WEB_USER ? "https" : "tcp",
        } as AnyMap,
    })
    const [policyID, setPolicyID] = React.useState<string>("")
    const [policyEnabled, setPolicyEnabled] = React.useState(true)
    const [cluster, setCluster] = React.useState<ClusterInfra>()

    // DNSOverride is kind of a special case since it doesn't really belong in any one form.
    // we'll track it as state and make it available for child forms to use where they want
    const [dnsOverride, setDnsOverride] = React.useState<string>("")

    // some state to track the request
    const [loading, setLoading] = React.useState(false)
    const [errors, setErrors] = React.useState<{ [key: string]: string | false | null }>({})
    const [showError, setShowError] = React.useState<{ [key: string]: boolean }>({})
    const [requestError, setRequestError] = React.useState<string | null>(null)
    // the function to call when submitting the form
    const submitForm = async (event: React.SyntheticEvent) => {
        event.preventDefault()

        // track the loading state of the request
        setLoading(true)

        // perform the necessary action
        try {
            await onSubmit({
                attributes,
                metaData,
                policyID,
                policyEnabled,
            })
        } catch (e) {
            const message: string = (e as Error).message || (e as string)
            setRequestError(message)
        }

        // we're not loading anymore
        setLoading(false)
    }

    // if there is a single non-null error then we need to disable the submit button
    const disabled = isDisabled ?? Object.values(errors).find(Boolean)
    // only show the first error we are supposed to
    const error =
        requestError || Object.entries(errors).find(([key, value]) => value && showError[key])?.[1]

    return (
        <ServiceFormContext.Provider
            value={{
                serviceAppType: appType,
                serviceType: type,
                setErrors,
                policyID,
                setPolicyID,
                policyEnabled,
                metaData,
                attributes,
                setCluster,
                cluster,
                setPolicyEnabled,
                updateAttributes: (updater) => setAttributes(updater),
                updateMetadata: (updater) => setMetaData(updater),
                updateTags: (updater) => {
                    setMetaData((old) => ({
                        ...old,
                        tags: updater(old.tags),
                    }))
                },
                dnsOverride,
                setDnsOverride,
                setShowError,
            }}
        >
            <Form onSubmit={submitForm}>
                <ServiceDetailsForm
                    edit={edit}
                    enableFormField={enableFormField}
                    hideCluster={hideCluster}
                    hideDisableDelete={hideDisableDelete}
                    hideConnectOnLogin={appType === ServiceAppType.WEB}
                    showRdpPros={appType === ServiceAppType.RDP}
                />
                {children}
                <ServicePolicyForm edit={edit} />
                <PageBreak />
                <div className={styles.submitSection}>
                    {error && <ErrorBanner className={styles.errorBanner}>{error}</ErrorBanner>}
                    {edit ? (
                        <div className={styles.buttons}>
                            <Button
                                onClick={onCancel || (() => history.goBack())}
                                asElement={ButtonElement.BUTTON}
                                buttonType={ButtonType.SECONDARY}
                                type="button"
                            >
                                {localization.getString("cancel")}
                            </Button>
                            <Button
                                asElement={ButtonElement.BUTTON}
                                buttonType={ButtonType.PRIMARY}
                                type="submit"
                                loading={loading}
                                disabled={Boolean(disabled)}
                                className={styles.submitButton}
                            >
                                {localization.getString("save")}
                            </Button>
                        </div>
                    ) : (
                        <Button
                            asElement={ButtonElement.BUTTON}
                            buttonType={ButtonType.PRIMARY}
                            type="submit"
                            loading={loading}
                            disabled={Boolean(disabled)}
                        >
                            {localization.getString("registerService")}
                        </Button>
                    )}
                </div>
            </Form>
        </ServiceFormContext.Provider>
    )
}
// in order to prevent cumbersome prop drilling, we'll embed the data and mutators in context
const ServiceFormContext = React.createContext({
    policyID: "",
    policyEnabled: true,
    dnsOverride: null as string | null,
    serviceAppType: ServiceAppType.GENERIC,
    serviceType: ServiceType.CUSTOM,
    setPolicyID: (val: string) => {},
    setPolicyEnabled: (val: boolean) => {},
    setDnsOverride: (val: string) => {},
    metaData: {},
    attributes: {},
    updateMetadata: (updater: (old: ServiceMetadata) => ServiceMetadata) => {},
    updateAttributes: (updater: (old: ServiceAttr) => ServiceAttr) => {},
    updateTags: (updater: (old: ServiceMetadata["tags"]) => ServiceMetadata["tags"]) => {},
    setErrors: (updater: (old: ErrorMap) => ErrorMap) => {},
    setShowError: (updater: (old: { [key: string]: boolean }) => { [key: string]: boolean }) => {},
    cluster: undefined as ClusterInfra | undefined,
    setCluster: (newValue: ClusterInfra | undefined) => {},
})

// pull stuff out of context (we have to memoize these functions so that we don't cause infinite loops)
export const useUpdateMetadata = () => {
    const updater = React.useContext(ServiceFormContext).updateMetadata
    // eslint-disable-next-line react-hooks/exhaustive-deps
    return React.useMemo(() => updater, [])
}
export const useUpdateAttributes = () => {
    const updater = React.useContext(ServiceFormContext).updateAttributes
    // eslint-disable-next-line react-hooks/exhaustive-deps
    return React.useMemo(() => updater, [])
}
export const useMetadata = () => React.useContext(ServiceFormContext).metaData as ServiceMetadata
export const useAttributes = () => React.useContext(ServiceFormContext).attributes as ServiceAttr
export const useUpdateTags = () => {
    const updater = React.useContext(ServiceFormContext).updateTags
    // eslint-disable-next-line react-hooks/exhaustive-deps
    return React.useMemo(() => updater, [])
}
export const useSetErrors = () => {
    const updater = React.useContext(ServiceFormContext).setErrors
    // eslint-disable-next-line react-hooks/exhaustive-deps
    return React.useMemo(() => updater, [])
}
export const useSetShowError = () => {
    const updater = React.useContext(ServiceFormContext).setShowError
    // eslint-disable-next-line react-hooks/exhaustive-deps
    return React.useMemo(() => updater, [])
}

// we need to track the cluster id
export const useSetCluster = () => {
    const updater = React.useContext(ServiceFormContext).setCluster
    // eslint-disable-next-line react-hooks/exhaustive-deps
    return React.useMemo(() => updater, [])
}
export const useCluster = () => React.useContext(ServiceFormContext).cluster

// some meta data is used very frequently, dry up the lookup in the metadata object
export const useClusterName = () => useMetadata().cluster
export const useDomain = () => useMetadata().tags.domain

// the policy state is tracked separately from metadata and attributes
export const useSetPolicyID = () => {
    const updater = React.useContext(ServiceFormContext).setPolicyID
    // eslint-disable-next-line react-hooks/exhaustive-deps
    return React.useMemo(() => updater, [])
}
export const usePolicyEnabled = () => React.useContext(ServiceFormContext).policyEnabled
export const useSetPolicyEnabled = () => {
    const updater = React.useContext(ServiceFormContext).setPolicyEnabled
    // eslint-disable-next-line react-hooks/exhaustive-deps
    return React.useMemo(() => updater, [])
}

export const useDNSOverride = () => React.useContext(ServiceFormContext).dnsOverride
export const useSetDNSOverride = () => {
    const updater = React.useContext(ServiceFormContext).setDnsOverride
    // eslint-disable-next-line react-hooks/exhaustive-deps
    return React.useMemo(() => updater, [])
}

export const useServiceAppType = () =>
    React.useContext(ServiceFormContext).serviceAppType as ServiceAppType
export const useServiceType = () => React.useContext(ServiceFormContext).serviceType as ServiceType

type ErrorMap = { [key: string]: string | false | null }
