import React, { useEffect, useMemo, useState } from "react"
import { LocalizationService } from "../../../../pre-v3/services"
import { StringUtil } from "../../../../pre-v3/utils/String.util"
import { SearchInput } from "../../search-input/SearchInput.component"
import { MenuItemProps } from "../menu-item/MenuItem.component"
import { Menu, MenuProps } from "../Menu.component"
import styles from "./SearchableMenu.module.scss"

export function SearchableMenu(props: SearchableMenuProps) {
    const [search, setSearch] = useState<string>("")
    const localization: LocalizationService = new LocalizationService()

    const items: MenuItemProps[] = useMemo(() => {
        if (props.loading) {
            return [
                {
                    type: "loading",
                },
            ]
        }

        const menuItems: MenuItemProps[] = search
            ? props.items.filter((i) => {
                  if (i.type !== "divider") {
                      return i.label && StringUtil.caseInsensitiveIncludes(i.label, search)
                  }
                  return false
              })
            : props.items

        const showNewEntry =
            search.length > 0 && props.allowNewEntry && !props.items.find((i) => i.label === search)

        const newEntry: MenuItemProps[] = showNewEntry
            ? [
                  {
                      type: "button",
                      label: localization.getString("addTemplate", search),
                      closeMenuOnClick: true,
                      onClick: () => props.onNewEntrySelect?.(search),
                  },
              ]
            : []

        const staticMenu: MenuItemProps[] = props.staticItem
            ? [{ type: "divider" }, props.staticItem]
            : []

        return [...newEntry, ...menuItems, ...staticMenu]
    }, [props.loading, props.items, props.staticItem, props.allowNewEntry, search])

    function onSearchChange(value: string): void {
        setSearch(value)
        props.onSearchChange?.(value)
    }

    useEffect(() => {
        onSearchChange("")
    }, [])

    return (
        <div>
            <SearchInput
                autoFocus={props.autoFocus}
                className={styles.search}
                value={search}
                onChangeValue={onSearchChange}
                placeholder={props.searchPlaceholder || localization.getString("search")}
            />
            <Menu {...props} items={items} />
        </div>
    )
}

interface SearchableMenuProps extends MenuProps {
    allowNewEntry?: boolean
    onNewEntrySelect?: (value: string) => void
    staticItem?: MenuItemProps
    onSearchChange?: (search: string) => void
    searchPlaceholder?: string
    autoFocus?: boolean
    loading?: boolean
}
