import React from "react"
import styles from "./TextBlock.module.scss"

export default function () {
    return (
        <div className={styles.container}>
            <p className={styles.text}>{this.props.data.jsonString}</p>
        </div>
    )
}
