import React from "react"
import styles from "./OrgAdminAdd.module.scss"
import { Select } from "../../../../../components/select/Select.component"
import {
    Form,
    FormColumns,
    FormLabel,
    ErrorBanner,
    WarningBanner,
    InfoBanner,
    FormRow,
} from "../../../../../components"
import {
    Button,
    ButtonElement,
    ButtonType,
} from "../../../../../../components/button/Button.component"
import { AdminActionBar } from "../../../../../../pages/directory/admins/AdminActionBar.component"
import { Input } from "../../../../../../v3/components/input/Input.component"

export default function () {
    return (
        <AdminActionBar isMsp={this.state.isMspOrg}>
            <Form onSubmit={this.onSubmit} display="grid" labelWidth={115} floating>
                <FormRow>
                    {this.state.error && <ErrorBanner>{this.state.error}</ErrorBanner>}
                    {!this.state.error && !this.state.success && this.state.warning && (
                        <WarningBanner>
                            {this.localizationService.getString("addAdminLocalDescription")}
                        </WarningBanner>
                    )}
                    {this.state.success && <InfoBanner>{this.state.success}</InfoBanner>}
                </FormRow>
                <FormColumns right={<h2>{this.localizationService.getString("newAdmin")}</h2>} />
                <FormLabel
                    title={this.localizationService.getString("firstName")}
                    htmlFor="firstName"
                >
                    <Input
                        id="firstName"
                        className={styles.formInput}
                        required
                        placeholder={this.localizationService.getString("firstName")}
                    />
                </FormLabel>
                <FormLabel
                    title={this.localizationService.getString("lastName")}
                    htmlFor="lastName"
                >
                    <Input
                        id="lastName"
                        className={styles.formInput}
                        required
                        placeholder={this.localizationService.getString("lastName")}
                    />
                </FormLabel>
                <FormLabel title={this.localizationService.getString("email")} htmlFor="email">
                    <Input
                        id="email"
                        className={styles.formInput}
                        type="email"
                        required
                        placeholder={this.localizationService.getString("email")}
                    />
                </FormLabel>
                <FormLabel title={this.localizationService.getString("profile")} htmlFor="profile">
                    <Select
                        required
                        value={this.state.profile}
                        options={this.state.isMspOrg ? this.mspProfileOptions : this.profileOptions}
                        onChange={this.onProfileChange}
                    />
                </FormLabel>
                <FormColumns
                    right={
                        <Button
                            buttonType={ButtonType.PRIMARY}
                            asElement={ButtonElement.BUTTON}
                            loading={this.state.submitting}
                            type="submit"
                        >
                            {this.localizationService.getString("addAdmin")}
                        </Button>
                    }
                />
            </Form>
        </AdminActionBar>
    )
}
