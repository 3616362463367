import React, { ReactNode, SyntheticEvent, RefObject } from "react"
import InputWithActionTemplate from "./InputWithAction.template"

export interface InputWithActionProps {
    type?: string
    disabled?: boolean
    required?: boolean
    readOnly?: boolean
    placeholder?: string
    value?: string
    id?: string
    autoFocus?: boolean
    innerRef?: RefObject<HTMLInputElement>
    onKeyPress?: (event: KeyboardEvent) => void
    onKeyDown?: (event: KeyboardEvent) => void
    onChange?: (event: SyntheticEvent) => void
    onAction?: () => void
    className?: string
    actionClassName?: string
}

export class InputWithAction extends React.Component<
    React.PropsWithChildren<InputWithActionProps>,
    {}
> {
    public render(): ReactNode {
        return InputWithActionTemplate.call(this)
    }
}
