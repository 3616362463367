import { BaseApi } from "./Base.api"

export class TrustFactorApi extends BaseApi {
    public getTrustFactors(): Promise<TrustFactorRes[]> {
        return this.get("/api/v2/granular_trust/factor", {
            default: this.localization.getString("failedToFetchTrustFactors"),
        })
    }

    public createTrustFactor(body: NewTrustFactorReq): Promise<TrustFactorRes> {
        return this.post("/api/v2/granular_trust/extended_factor", body, {
            409: this.localization.getString(
                "somethingNamedAlreadyExists",
                this.localization.getString("trustFactor"),
                body.display_name
            ),
            default: this.localization.getString("failedToCreateTrustFactor"),
        })
    }

    public updateTrustFactor(body: TrustFactorReq): Promise<TrustFactorRes> {
        return this.patch("/api/v2/granular_trust/extended_factor/definition", body, {
            default: this.localization.getString("failedToUpdateTrustFactor"),
        })
    }

    public deleteTrustFactor(body: DeleteTrustFactorReq): Promise<void> {
        return this.delete("/api/v2/granular_trust/extended_factor", body, {
            default: this.localization.getString("failedToDeleteTrustFactor"),
        })
    }

    public updateTrustFactorsRemediation(
        body: UpdateTrustFactorRemediationReq[]
    ): Promise<TrustFactorRes[]> {
        if (body.length <= 0) return Promise.resolve([])

        return this.patch("/api/v2/granular_trust/factor/remediation", body, {
            default: this.localization.getString("failedToUpdateRemediation"),
        })
    }
}

export interface TrustFactorRes {
    factor_id: string
    name: string
    display_name: string
    applicable_platforms: PlatformRes[]
    source: string
    type: TrustFactorTypeRes
    config?: ConfigRes[]
    factor_usage?: TrustProfileRes[]
    remediations: RemediationRes[]
}

interface NewTrustFactorReq {
    display_name: string
    source: string
    type: TrustFactorTypeRes
    config: ConfigRes[]
}

interface TrustFactorReq {
    factor_id: string
    name: string
    display_name: string
    type: TrustFactorTypeRes
    config: ConfigRes[]
    source: TrustFactorSource
}

interface DeleteTrustFactorReq {
    factor_id: string
    source: string
    type: TrustFactorTypeRes
}

type TrustFactorTypeRes = "affirmable" | "integration" | "extended"

export enum TrustFactorSource {
    fileCheck = "File Check",
    pList = "Plist",
    registryKey = "Registry Key",
}

export type PlatformRes = "macos" | "windows" | "linux" | "ios" | "android" | "chrome"

export interface ConfigRes {
    key: string
    value: string
}

export interface TrustProfileRes {
    profile_id: string
    profile_name: string
}

export interface UpdateTrustFactorRemediationReq {
    factor_id: string
    remediations: RemediationRes[]
}

export interface RemediationRes {
    platform: PlatformRes
    message: string
}
