import React, { FormEvent, forwardRef, ReactNode, Ref } from "react"

import {
    Button,
    ButtonElement,
    ButtonType,
    IconType,
} from "../../../../components/button/Button.component"
import { useServiceLocalization } from "../../../../pre-v3/services/localization/Localization.service"
import { FormUtil } from "../../../../pre-v3/utils/Form.util"
import { ErrorBanners } from "../../../components/banner/Banner.component"
import { Form } from "../../../components/form/Form.component"
import { FormRow } from "../../../components/form/FormRow.component"
import { FormButtons } from "../../../components/form/form-buttons/FormButtons.component"
import { Input } from "../../../components/input/Input.component"
import {
    PillMultiSelect,
    PillMultiSelectOption,
} from "../../../components/pill-multi-select/PillMultiSelect.component"
import { addMobileDevicesOption, isMobileDevicesRole } from "../../../services/shared/Role"
import { ProfileCategoryHeader } from "../component/ProfileCategoryHeader.component"
import styles from "../ItpPolicy.module.scss"
import { Step, ariaControls } from "./ItpPolicyForm.component"
import { TextArea } from "../../../components/input/TextArea.component"

interface AssignmentTabProps {
    value: AssignmentFormValues
    onSubmit: () => void
    disabled?: boolean
    errors?: ReactNode[]
    onBack: () => void
    onChange: (updatedValue: AssignmentFormValues) => void
    roles: string[]
    isEdit: boolean
    isExcludedDevicesPolicy: boolean
}

function AssignmentTabComponent(props: AssignmentTabProps, ref: Ref<HTMLFormElement>) {
    const localization = useServiceLocalization()

    const { value, disabled, errors, roles, isEdit, onChange, onBack, isExcludedDevicesPolicy } =
        props
    const { roles: selectedRoles, name, description = "", blockPageMessage } = value

    const parsedRoleOptions = useParsedRoleOptions(roles, isExcludedDevicesPolicy)

    function onSubmit(event: FormEvent<HTMLFormElement>) {
        event.preventDefault()

        props.onSubmit()
    }

    function onNameChange(event: React.ChangeEvent<HTMLInputElement>) {
        onChange({
            ...value,
            name: FormUtil.getInputValue(event),
        })
    }

    function onBlockPageMessageChange(event: React.ChangeEvent<HTMLTextAreaElement>) {
        onChange({
            ...value,
            blockPageMessage: FormUtil.getInputValue(event),
        })
    }

    function onDescriptionChange(event: React.ChangeEvent<HTMLTextAreaElement>) {
        onChange({
            ...value,
            description: FormUtil.getInputValue(event),
        })
    }

    function onSelectedRolesChange(updatedSelectedRoles: string[]) {
        onChange({
            ...value,
            roles: updatedSelectedRoles,
        })
    }

    function isPillDisabled(roleName: string): boolean {
        return isExcludedDevicesPolicy && isMobileDevicesRole(roleName)
    }

    return (
        <Form
            className={styles.tabContainer}
            onSubmit={onSubmit}
            id={ariaControls[Step.ASSIGNMENT]}
            ref={ref}
        >
            <ProfileCategoryHeader
                label={localization.getString("generalInformation")}
                description={localization.getString(
                    isExcludedDevicesPolicy
                        ? "nameAndAssignRolesToYourItpPolicyBelow"
                        : "nameDescribeAndAssignRolesToYourItpPolicyBelow"
                )}
                className={styles.assignmentTabCategoryRow}
                headerClassName={styles.assignmentTabCategoryRowHeader}
                hideSwitch
                disabled
            >
                <div className={styles.generalInformation}>
                    <FormRow
                        label={localization.getString("nameYourITPPolicy")}
                        description={localization.getString(
                            "weRecommendYouChooseANameThatRepresentsTheUsersAssignedToThisITPPolicy"
                        )}
                        htmlFor={AssignmentTabFields.NAME}
                    >
                        <Input
                            id={AssignmentTabFields.NAME}
                            name="name"
                            value={name}
                            onChange={onNameChange}
                            required
                            disabled={disabled || isExcludedDevicesPolicy}
                            placeholder={localization.getString("enterItpPolicyName")}
                        />
                    </FormRow>
                    {!isExcludedDevicesPolicy && (
                        <FormRow
                            label={localization.getString(
                                "somethingOptional",
                                localization.getString("description")
                            )}
                            description={localization.getString("trustProfileDescription")}
                            htmlFor={AssignmentTabFields.DESCRIPTION}
                        >
                            <TextArea
                                id={AssignmentTabFields.DESCRIPTION}
                                name="description"
                                className={styles.fullWidthTextarea}
                                value={description}
                                onChange={onDescriptionChange}
                                disabled={disabled}
                                placeholder={localization.getString(
                                    "somethingOptional",
                                    localization.getString("description")
                                )}
                            />
                        </FormRow>
                    )}
                    <PillMultiSelect
                        label={localization.getString("whoShouldThisITPPolicyBeAssignedTo")}
                        description={localization.getString("mustSelectOneOrMoreRoles")}
                        placeholder={localization.getString("attachARole")}
                        options={parsedRoleOptions}
                        value={selectedRoles}
                        onChange={onSelectedRolesChange}
                        className={styles.attachRoles}
                        icon={IconType.LINK}
                        required
                        disabled={disabled}
                        isPillDisabled={isPillDisabled}
                    />
                </div>
            </ProfileCategoryHeader>
            {!isExcludedDevicesPolicy && (
                <ProfileCategoryHeader
                    label={localization.getString("blockPageExperience")}
                    description={localization.getString(
                        "createABlockPageWithYourOwnMessagingAndPreviewIt"
                    )}
                    className={styles.assignmentTabCategoryRow}
                    headerClassName={styles.assignmentTabCategoryRowHeader}
                    hideSwitch
                    disabled
                >
                    <div className={styles.generalInformation}>
                        <FormRow
                            label={localization.getString("whatShouldTheBlockPageMessageSay")}
                            description={localization.getString(
                                "thisMessageWillBeDisplayedOnALandingPageWhenAUserIsDeniedAccessToARestrictedDomainInTheITPPolicy"
                            )}
                        >
                            <TextArea
                                value={blockPageMessage}
                                className={styles.fullWidthTextarea}
                                onChange={onBlockPageMessageChange}
                                required
                                disabled={disabled}
                                placeholder={localization.getString("writeABlockPageMessageHere")}
                            />
                        </FormRow>
                    </div>
                </ProfileCategoryHeader>
            )}
            {!disabled && (
                <FormButtons
                    leftButtons={
                        <Button
                            asElement={ButtonElement.BUTTON}
                            buttonType={ButtonType.SECONDARY}
                            type="button"
                            onClick={onBack}
                        >
                            {localization.getString(isEdit ? "cancel" : "back")}
                        </Button>
                    }
                    rightButtons={
                        <Button
                            asElement={ButtonElement.BUTTON}
                            buttonType={ButtonType.PRIMARY}
                            type="submit"
                        >
                            {localization.getString("save")}
                        </Button>
                    }
                >
                    {errors && <ErrorBanners errors={errors} />}
                </FormButtons>
            )}
            <div className={styles.bottomPadding} />
        </Form>
    )
}

export const AssignmentTab = forwardRef(AssignmentTabComponent)
export interface AssignmentFormValues {
    name: string
    description?: string
    roles: string[]
    blockPageMessage: string
}

enum AssignmentTabFields {
    NAME = "itpPolicyName",
    DESCRIPTION = "itpPolicyDescription",
}

function useParsedRoleOptions(
    roles: string[],
    isExcludedDevicesPolicy: boolean
): PillMultiSelectOption[] {
    const localization = useServiceLocalization()

    const anyLabel = localization.getString("any").toLocaleUpperCase()

    return React.useMemo((): PillMultiSelectOption[] => {
        const parsedRoleOptions = roles.map(mapRoleOption)

        if (isExcludedDevicesPolicy) return addMobileDevicesOption(parsedRoleOptions)

        const anyOption: PillMultiSelectOption = {
            label: anyLabel,
            value: anyLabel,
        }

        return [anyOption, ...parsedRoleOptions]
    }, [roles, isExcludedDevicesPolicy, anyLabel])
}

function mapRoleOption(role: string): PillMultiSelectOption {
    return { label: role, value: role }
}
