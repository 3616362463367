import React from "react"
import { FormLabel } from "../../../../pre-v3/components/form-label/FormLabel"
import { useServiceLocalization } from "../../../../pre-v3/services/localization/Localization.service"
import styles from "./TrustIntegrationForm.module.scss"

// Note: Old components, move them to v3
import { InputWithHide } from "../../../../pre-v3/components/input-with-hide/InputWithHide.component"
import { Input } from "../../../components/input/Input.component"

export interface Props {
    apiEndpoint: string
    onApiEndpointChange: (apiEndpoint: string) => void
    disabled?: boolean
    apiKey: string
    onApiKeyChange: (apiKey: string) => void
    expiryDate?: string
    canWriteAll?: boolean
}

export function SentinelOneApiCredentials(props: Props) {
    const {
        apiEndpoint,
        onApiEndpointChange,
        disabled,
        apiKey,
        onApiKeyChange,
        expiryDate,
        canWriteAll,
    } = props

    const localization = useServiceLocalization()

    return (
        <>
            <FormLabel title={localization.getString("apiEndpoint")}>
                <Input
                    type="text"
                    value={apiEndpoint}
                    onChange={(e) => onApiEndpointChange(e.target.value)}
                    placeholder={localization.getString("enterApiEndpoint")}
                    className={styles.input}
                    disabled={disabled}
                    required
                />
            </FormLabel>
            <FormLabel title={localization.getString("apiKey")}>
                {disabled ? (
                    <InputWithHide
                        className={styles.input}
                        disabled
                        value={apiKey}
                        hideEyeIcon={!canWriteAll}
                    />
                ) : (
                    <Input
                        type="text"
                        value={apiKey}
                        onChange={(e) => onApiKeyChange?.(e.target.value)}
                        placeholder={localization.getString(
                            "enterSomething",
                            localization.getString("apiKey")
                        )}
                        className={styles.input}
                        required
                    />
                )}
            </FormLabel>
            {expiryDate && (
                <FormLabel title={localization.getString("apiKeyExpiration")}>
                    <Input
                        type="text"
                        value={expiryDate}
                        className={styles.input}
                        disabled
                        required
                    />
                </FormLabel>
            )}
        </>
    )
}
