import React from "react"
import styles from "./IconButton.module.scss"
import classNames from "classnames/bind"
import { Tooltip } from "@mui/material"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"

export function IconButtonTemplate() {
    return (
        <button
            type="button"
            id={this.props.id}
            disabled={this.props.disabled}
            onClick={this.props.onClick}
            className={classNames(styles.iconButton, this.props.className, {
                [styles.iconButtonPrimary]: this.props.primary,
                [styles.iconButtonBrand]: this.props.brand,
                [styles.iconButtonWithText]: this.props.label,
            })}
        >
            {this.props.icon && (
                <FontAwesomeIcon icon={this.props.icon} inverse={this.props.primary} />
            )}
            {this.props.icon && this.props.label && <span className={styles.iconSpacing}></span>}
            <span className={styles.textLabel}>{this.props.label}</span>
        </button>
    )
}

export function IconButtonWithTooltipTemplate() {
    return (
        <Tooltip
            className={this.props.className}
            title={this.props.tooltip}
            placement={this.props.tooltipPlacement || "bottom"}
        >
            <span className={styles.buttonWrapper}>
                <button
                    type="button"
                    id={this.props.id}
                    disabled={this.props.disabled}
                    onClick={this.props.onClick}
                    className={classNames(styles.iconButton, {
                        [styles.iconButtonPrimary]: this.props.primary,
                        [styles.iconButtonBrand]: this.props.brand,
                        [styles.iconButtonWithText]: this.props.label,
                    })}
                >
                    {this.props.icon && (
                        <FontAwesomeIcon icon={this.props.icon} inverse={this.props.primary} />
                    )}
                    {this.props.icon && this.props.label && (
                        <span className={styles.iconSpacing}></span>
                    )}
                    <span className={styles.textLabel}>{this.props.label}</span>
                </button>
            </span>
        </Tooltip>
    )
}
