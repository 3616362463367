import React, { ReactNode } from "react"
import TabBarTemplate from "./TabBar.template"
import { Bind } from "../../decorators/Bind.decorator"

export class TabBar extends React.Component<TabBarProps, TabBarState> {
    constructor(props: TabBarProps) {
        super(props)

        if (props.tabs && props.tabs.length > 0) {
            this.state = { activeTab: props.tabs[0].value }
            for (let i = 0; i < props.tabs.length; i++) {
                if (props.tabs[i].active) {
                    this.state.activeTab = props.tabs[i].value
                    break
                }
            }
        }
    }

    public state: TabBarState

    public render(): ReactNode {
        return TabBarTemplate.call(this)
    }

    @Bind
    private onTabClick(value: number): void {
        this.setState({ activeTab: value })

        if (this.props.onChange) {
            this.props.onChange(value)
        }
    }
}

interface TabBarProps {
    tabs: Tab[]
    onChange: (value: number) => void
}

interface TabBarState {
    activeTab: number
}

export interface Tab {
    label: string
    value: number
    active?: boolean
    disabled?: boolean
}
