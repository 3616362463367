import { useQuery } from "@tanstack/react-query"
import * as React from "react"

import { StatsService, useServiceLinks, useServiceLocalization } from "../../services"
import styles from "./NoDataView.module.scss"
import { AccessTiersCount } from "./widgets/AccessTiersCount"
import { DevicesCount } from "./widgets/DevicesCount"
import { PoliciesCount } from "./widgets/PoliciesCount"
import { RoleCount } from "./widgets/RoleCount"
import { Services } from "./widgets/Services"
import { UserCount } from "./widgets/UserCount"

interface Props {
    headingId?: string
}

export function NoDataView(props: Props): JSX.Element {
    const localization = useServiceLocalization()
    const linkService = useServiceLinks()

    const { data } = useQuery({
        queryKey: ["getStatistics"],
        queryFn: () => getStatistics(),
    })

    return (
        <>
            <section className={styles.dashboardGrid}>
                <DevicesCount count={data?.devicesCount} />
                <UserCount />
                <Services />
                <PoliciesCount count={data?.policiesCount} />
                <RoleCount />
                <AccessTiersCount count={data?.accessTiersCount} />
            </section>
            <div className={styles.learnMoreContainer}>
                <div className={styles.contentContainer}>
                    <div className={styles.content}>
                        {localization.getString(
                            "unlockTheSonicWallCseDashboardByAccessingYourFirstService"
                        )}
                    </div>
                    <a href={linkService.getLink("reportingDocs")} className={styles.link}>
                        {localization.getString("learnMore")}
                    </a>
                </div>
            </div>
        </>
    )
}

interface Statistics {
    accessTiersCount: number
    devicesCount: number
    policiesCount: number
}

async function getStatistics(): Promise<Statistics> {
    const statsService = new StatsService()

    const [accessTiersStats, devicesStats, policiesStats] = await Promise.all([
        statsService.getAccessTiersStatsDisplayData(),
        statsService.getDevicesStatsDisplayData(),
        statsService.getPoliciesStatsDisplayData(),
    ])

    return {
        accessTiersCount: accessTiersStats.total,
        devicesCount: devicesStats.total,
        policiesCount: policiesStats.total,
    }
}
