import React from "react"
import { useHistory } from "react-router-dom"

import { useServiceLocalization } from "../../../../../pre-v3/services/localization/Localization.service"
import { ROUTE } from "../../../../../routes"
import { NewPlist, useCreatePropertyList } from "../../../../services/TrustFactor.service"
import { Container } from "../../../../components/container/Container.component"
import { PlistForm } from "./PlistForm.component"
import { PageHeading } from "../../../../../components/page-heading/PageHeading.component"
import trustStyles from "../../../../../pages/trust/Trust.module.scss"
import styles from "./PlistForm.module.scss"

interface Props {
    canAccessPListCheckFactor?: boolean
}

export function PlistAdd(props: Props): JSX.Element {
    const history = useHistory()
    const localization = useServiceLocalization()

    const {
        mutateAsync: createPlistTrustFactor,
        error: createPlistTrustFactorError,
        isLoading: createPlistTrustFactorLoading,
    } = useCreatePropertyList({ onSuccess: () => history.push(ROUTE.FACTORS_PLIST) })

    function onSubmit(plist: NewPlist): void {
        createPlistTrustFactor(plist)
    }

    const errors = [typeof createPlistTrustFactorError === "string" && createPlistTrustFactorError]

    return (
        <div className={styles.container}>
            <section aria-labelledby={Id.HEADING} className={trustStyles.section}>
                <header className={trustStyles.header}>
                    <PageHeading id={Id.HEADING}>
                        {localization.getString("addPropertyList")}
                    </PageHeading>
                </header>
            </section>
            <Container>
                <PlistForm
                    onSubmit={onSubmit}
                    cancelHref={ROUTE.FACTORS_PLIST}
                    submitting={createPlistTrustFactorLoading}
                    errors={errors}
                    canAccessPListCheckFactor={props.canAccessPListCheckFactor}
                />
            </Container>
        </div>
    )
}

enum Id {
    HEADING = "heading",
}
