import React from "react"
import { useHistory } from "react-router"

import { ROUTE } from "../../../../routes"
import {
    ModalRef,
    ServiceManage,
    useServiceLocalization,
    useServiceManage,
} from "../../../services"
import { ErrorBanner, FormLabel, Form, FormRow, FormColumns, Input } from "../../../components"
import { FormUtil } from "../../../utils/Form.util"
import styles from "./ServicesOverview.module.scss"
import classnames from "classnames"
import { Button, ButtonElement, ButtonType } from "../../../../components/button/Button.component"

interface Props {
    service: ServiceManage
    modalRef: ModalRef
}

export function CreateServiceCloneModal({ service, modalRef }: Props) {
    const localization = useServiceLocalization()
    const manageService = useServiceManage()
    const history = useHistory()

    const [loading, setLoading] = React.useState(false)
    const [error, setError] = React.useState<string>("")

    const spec = service?.spec
    const metadata = spec?.metadata
    const attributes = spec?.spec

    const submitForm = async (event: React.FormEvent<HTMLFormElement>) => {
        setLoading(true)
        event.preventDefault()
        const serviceName: string = FormUtil.getFieldValue(event, "serviceName")
        const serviceDomainName: string = FormUtil.getFieldValue(event, "serviceDomainName")

        if (!spec || !metadata || !attributes) {
            return
        }

        metadata.name = serviceName
        metadata.tags.domain = serviceDomainName
        attributes.cert_settings.dns_names = [serviceDomainName]
        attributes.attributes.tls_sni = [serviceDomainName]
        if (attributes.http_settings.oidc_settings) {
            attributes.http_settings.oidc_settings.service_domain_name = `https://${serviceDomainName}`
        }

        try {
            const response: ServiceManage = await manageService.createRegisteredService({
                metadata,
                type: spec.kind,
                attributes,
                policyId: service?.policyId,
                enabled: service?.policyEnabled,
            })
            setLoading(false)
            const targetPath = manageService.isInfraService(service)
                ? ROUTE.INFRASTRUCTURE
                : ROUTE.HOSTED_WEBSITES
            history.push(targetPath)
            modalRef.close(response)
        } catch (e) {
            const err = e
                ? (e as Error).message || (e as string)
                : localization.getString("failedToCreateService")
            setError(err)
            setLoading(false)
        }
    }

    const classes = classnames(styles.container, "pre-v3")

    return (
        <div className={classes}>
            <Form onSubmit={submitForm} display="grid" labelWidth={180} floating>
                <FormRow>{error && <ErrorBanner>{error}</ErrorBanner>}</FormRow>
                <FormLabel title={localization.getString("serviceName")} htmlFor="serviceName">
                    <Input
                        className={styles.formInput}
                        required
                        placeholder={localization.getString("serviceName")}
                    />
                </FormLabel>
                <FormLabel
                    title={localization.getString("serviceDomainName")}
                    htmlFor="serviceDomainName"
                >
                    <Input
                        className={styles.formInput}
                        required
                        placeholder={localization.getString("serviceDomainName")}
                    />
                </FormLabel>
                <FormColumns
                    right={
                        <div className={styles.footer}>
                            <Button
                                onClick={modalRef.cancel}
                                buttonType={ButtonType.SECONDARY}
                                asElement={ButtonElement.BUTTON}
                            >
                                {localization.getString("cancel")}
                            </Button>
                            <Button
                                buttonType={ButtonType.PRIMARY}
                                asElement={ButtonElement.BUTTON}
                                type="submit"
                                className={styles.submitButton}
                            >
                                {localization.getString("cloneService")}
                            </Button>
                        </div>
                    }
                />
            </Form>
        </div>
    )
}
