export class DateUtil {
    public static MINUTE: number = 1000 * 60
    public static HOUR: number = DateUtil.MINUTE * 60
    public static DAY: number = DateUtil.HOUR * 24
    public static readonly CUSTOM_TIMERANGE_NAME: string = "Custom"
    public static readonly ALL: string = "All"

    /**
     * @deprecated Use the Date's toLocaleString method with a locale
     */
    public static format(timestamp?: number): string {
        return timestamp && timestamp > 0 ? new Date(timestamp).toLocaleString() : "-"
    }

    //format date to YYYY-MM-DD
    public static formatToISOdate(timestamp?: number): string {
        return timestamp && timestamp > 0 ? new Date(timestamp).toISOString().split("T")[0] : "-"
    }

    public static formatToLocalDateStr(timestamp: number): string {
        return timestamp > 0 ? new Date(timestamp).toLocaleDateString() : ""
    }

    // format to DD/MM
    public static formatToShortLocalDateStr(timestamp: number, language: string): string {
        return timestamp > 0
            ? new Date(timestamp).toLocaleDateString(language, {
                  day: "numeric",
                  month: "numeric",
              })
            : ""
    }

    public static formatToLocalDateTimeStr(timestamp: number, language: string) {
        if (timestamp === 0) return ""
        const date = new Date(timestamp)
        return new Intl.DateTimeFormat(language, {
            weekday: "long",
            day: "numeric",
            month: "short",
            hour: "numeric",
            minute: "numeric",
            hour12: true,
        }).format(date)
    }

    public static formatLargeTimestamp(largeTimestamp?: number): string {
        return largeTimestamp && largeTimestamp > 0
            ? DateUtil.format(largeTimestamp / 1000000)
            : "-"
    }

    /**
     * @deprecated Use convertFromServerTimestamp from Date.utils.ts instead
     */
    public static convertLargeTimestamp(largeTimestamp: number): number {
        return Math.floor(largeTimestamp / 1000000)
    }

    //convert timestamp: 1635834955645 to large timestamp: 1635834955645000000
    /**
     * @deprecated Use convertToServerTimestamp from Date.utils.ts instead
     */
    public static convertToLargeTimestamp(timestamp: number): number {
        return Math.floor(timestamp * 1000000)
    }

    public static getTimeRanges(): TimeRange[] {
        const timeRanges: TimeRange[] = [
            {
                name: TimeRangeName.LAST_12_HOURS,
                delta: DateUtil.HOUR * 12,
            },
            {
                name: TimeRangeName.LAST_DAY,
                delta: DateUtil.DAY,
            },
            {
                name: TimeRangeName.LAST_TWO_DAYS,
                delta: DateUtil.DAY * 2,
            },
            {
                name: TimeRangeName.LAST_WEEK,
                delta: DateUtil.DAY * 7,
            },
            {
                name: TimeRangeName.LAST_30_DAYS,
                delta: DateUtil.DAY * 30,
            },
            {
                name: TimeRangeName.LAST_60_DAYS,
                delta: DateUtil.DAY * 60,
            },
            {
                name: TimeRangeName.LAST_90_DAYS,
                delta: DateUtil.DAY * 90,
            },
        ]
        return timeRanges.map(DateUtil.convertDeltaToTimeRange)
    }

    public static convertDeltaToTimeRange(time: TimeRange) {
        if (time?.delta) {
            time.start = Date.now() - time.delta
            time.end = Date.now()
        } else {
            time.start = 0
            time.end = Date.now()
        }
        return time
    }

    public static serializeTimeRange(timeRange: TimeRange): string {
        if (timeRange.name && timeRange.name !== DateUtil.CUSTOM_TIMERANGE_NAME) {
            return timeRange.name
        }
        return timeRange.start + "," + timeRange.end
    }

    public static deserializeTimeRange(str: string): TimeRange {
        // if serialized value is "Last Week" (any prefixed timerange)
        if (!str.includes(",") && str !== DateUtil.CUSTOM_TIMERANGE_NAME) {
            const delta: number = this.getTimeRanges().find((t) => t.name === str)?.delta || 0
            return this.convertDeltaToTimeRange({
                name: str,
                delta: delta,
            })
        }
        // if serialized value is "1646128843984,1646388043984"
        else {
            const dateArr: string[] = str.split(",")
            const date1: number = parseInt(dateArr[0]) || 0
            const date2: number = parseInt(dateArr[1]) || Date.now()
            return {
                start: date1,
                end: date2,
                delta: date2 - date1,
                name: DateUtil.CUSTOM_TIMERANGE_NAME,
            }
        }
    }

    public static timeRangeToUserReadableStr(timeRange: TimeRange): string {
        if (timeRange.name && timeRange.name !== DateUtil.CUSTOM_TIMERANGE_NAME) {
            return timeRange.name
        } else if (timeRange.start && timeRange.end) {
            return DateUtil.format(timeRange.start) + " -> " + DateUtil.format(timeRange.end)
        } else {
            return TimeRangeName.ALL
        }
    }

    public static isExpired(time: number): boolean {
        return Date.now() > time
    }

    /**
     * Returns the amount of milliseconds until target time
     * minus the delta if present. Usefult to get the number of milliseconds
     * until a specific amount of time before the target.
     */
    public static timeUntil(target: number, delta?: HumanTimespan): number {
        if (delta) {
            return target - DateUtil.timespanToTime(delta) - Date.now()
        } else {
            return target - Date.now()
        }
    }

    /**
     * Returns true if time will expire in (timespan from now)
     * ex. time = 12:00 Jan 1, timespan = 15 min; will return true if
     * 12:00 Jan 1 is less than 15 minutes from now
     */
    public static willExpireIn(time: number, timespan: HumanTimespan): boolean {
        const target: number = time - DateUtil.timespanToTime(timespan)
        return !DateUtil.isExpired(time) && DateUtil.isExpired(target)
    }

    /**
     * Returns true if it has been timespan since time
     * ex. time = 12:00 Jan 1, timespan = 15 minutes, will return true if
     * it has been more than 15 minutes since 12:00 Jan 1
     */
    public static hasBeenSince(time: number, timespan: HumanTimespan): boolean {
        const target: number = time + DateUtil.timespanToTime(timespan)
        return DateUtil.isExpired(target)
    }

    public static timespanToTime(timespan: HumanTimespan): number {
        const days: number = timespan.days || 0
        const hours: number = timespan.hours || 0
        const minutes: number = timespan.minutes || 0
        const seconds: number = timespan.seconds || 0
        const milliseconds: number = timespan.milliseconds || 0

        return (
            milliseconds +
            seconds * 1000 +
            minutes * 60 * 1000 +
            hours * 60 * 60 * 1000 +
            days * 24 * 60 * 60 * 1000
        )
    }

    public static timeToTimeSpan(time: number): HumanTimespan {
        if (!time || time < 0) {
            return {}
        } else {
            var days = Math.floor(time / DateUtil.DAY)
            var hours = Math.floor((time % DateUtil.DAY) / DateUtil.HOUR)
            var minutes = Math.floor(((time % DateUtil.DAY) % DateUtil.HOUR) / DateUtil.MINUTE)
            var seconds = Math.round((time % DateUtil.MINUTE) / 1000)
            return {
                days,
                hours,
                minutes,
                seconds,
            }
        }
    }
}

export interface TimeRange {
    name: string
    start?: number
    end?: number
    delta?: number
}

export enum TimeRangeName {
    LAST_12_HOURS = "Last 12 Hours",
    LAST_DAY = "Last Day",
    LAST_WEEK = "Last Week",
    LAST_TWO_DAYS = "Last Two Days",
    LAST_90_DAYS = "Last 90 Days",
    LAST_60_DAYS = "Last 60 Days",
    LAST_30_DAYS = "Last 30 Days",
    LAST_2_WEEKS = "Last 2 Weeks",
    LAST_1_WEEK = "Last 1 Week",
    ALL = "All",
}

export interface HumanTimespan {
    days?: number
    hours?: number
    minutes?: number
    seconds?: number
    milliseconds?: number
}
