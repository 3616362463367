export function toLocalizedList(
    values: string[] | undefined,
    locale: Intl.BCP47LanguageTag,
    listFormatType: Intl.ListFormatType
): string {
    if (!values || values.length <= 0) return "-"

    const formatter = new Intl.ListFormat(locale, {
        type: listFormatType,
    })

    return formatter.format(values)
}
