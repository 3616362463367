import React from "react"
import styles from "./ToggleButton.module.scss"
import classNames from "classnames/bind"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"

export default function () {
    return (
        <div
            className={classNames(
                styles.toggleButtonContainer,
                { [styles.disabled]: this.props.disabled },
                this.props.className
            )}
        >
            {this.props.items.map((button, i) => (
                <button
                    key={i}
                    disabled={this.props.disabled}
                    className={classNames(styles.toggleButton, {
                        [styles.toggleButtonActive]: i === this.state.selectedIndex,
                    })}
                    onClick={() => {
                        this.onToggle(i)
                    }}
                    type="button"
                >
                    {button.icon && (
                        <FontAwesomeIcon icon={button.icon} className={styles.buttonIcon} />
                    )}
                    {button.label}
                </button>
            ))}
        </div>
    )
}
