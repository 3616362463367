import * as React from "react"
import { Singleton } from "../../decorators/Singleton.decorator"
import { links } from "./links"
import ServiceContext from "../context"

type Links = typeof links
export type LinkKey = keyof Links

@Singleton("LinkService")
export class LinkService {
    constructor(serviceName: string = "LinkService") {
        this.links = links
    }

    private serviceName: string = "LinkService"
    private links: Links

    public getLink(link: LinkKey): string {
        return this.links[link]
    }
}

export const useServiceLinks = () => React.useContext(ServiceContext)?.links || new LinkService()
