import classNames from "classnames"
import React, { ReactNode } from "react"

import styles from "./FormButtons.module.scss"

export interface FormButtonsProps {
    leftButtons?: React.ReactNode
    rightButtons?: React.ReactNode
    className?: String
    children?: ReactNode
}

export function FormButtons(props: FormButtonsProps): JSX.Element {
    return (
        <div className={classNames(props.className, styles.container)}>
            {props.children}
            <div className={styles.buttonContainer}>
                <div className={styles.left}>{props.leftButtons}</div>
                <div className={styles.right}>{props.rightButtons}</div>
            </div>
        </div>
    )
}
