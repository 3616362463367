import classNames from "classnames/bind"
import React from "react"

import { useServiceLocalization } from "../../../pre-v3/services"
import styles from "./Widget.module.scss"
import { Loader } from "../loader/Loader.component"

export interface Props {
    children: React.ReactNode
    isLoading?: boolean
    isEmpty?: boolean
    className?: string
}

export function Widget(props: Props): JSX.Element {
    const localization = useServiceLocalization()

    if (props.isEmpty)
        return (
            <Container className={classNames(styles.noData, props.className)}>
                {localization.getString("noDataToDisplay")}
            </Container>
        )

    return (
        <Container className={props.className}>
            <Loader isLoading={props.isLoading ?? false}>{props.children}</Loader>
        </Container>
    )
}

function Container(props: React.PropsWithChildren<{ className?: string }>): JSX.Element {
    return (
        <figure className={classNames(styles.container, props.className)}>{props.children}</figure>
    )
}
