import { UseQueryResult, useMutation, useQuery, useQueryClient } from "@tanstack/react-query"
import { LanguageKey } from "../../pre-v3/services/localization/languages/en-US.language"
import { Paginated, PaginatedSearch } from "../../pre-v3/utils/AgGrid.util"
import {
    ApplicationDetailRes,
    ApplicationsApi,
    ApplicationRes,
    ApplicationSearch,
    Status,
    UserDeviceRes,
    CustomApplicationRes,
    AppType,
} from "../api/Applications.api"
import { StatusType } from "../components/status/Status.component"
import { IconDefinition } from "@fortawesome/fontawesome-common-types"
import { faLocationCircle, faCheckCircle, faDotCircle } from "@fortawesome/pro-solid-svg-icons"
import { DateUtil } from "../../pre-v3/utils/Date.util"
import {
    IncludeExcludeReq,
    IncludeExcludeRes,
    PeerAccessTierReq,
    PeerAccessTierRes,
    ServiceTunnelApi,
    ServiceTunnelReq,
    ServiceTunnelRes,
} from "../api/ServiceTunnel.api"
import { ProtectionProfileApi, ProtectionProfileRes } from "../api/ProtectionProfile.api"
import { excludedDevicesPolicyName } from "./ItpPolicy.service"

export async function getPublicResources(
    params: PaginatedSearch
): Promise<Paginated<PublicResource>> {
    const publicResourceApi: ApplicationsApi = new ApplicationsApi()
    const search: ApplicationSearch = {
        skip: params.skip,
        limit: params.limit,
    }

    if (params.filterModel?.name) {
        search.application_name = params.filterModel.name.filter
    }

    if (params.filterModel?.status) {
        search.status = params.filterModel.status.filter
    }

    if (params.filterModel?.type) {
        search.app_type = params.filterModel.type.filter as AppType
    }

    if (params.sortModel && params.sortModel.length > 0) {
        search.order = params.sortModel[0].sort
        switch (params.sortModel[0].colId) {
            case "name":
                search.order_by = "application_name"
                break
            case "numberOfUserDevice":
                search.order_by = "number_of_user_device"
                break
            default:
                search.order_by = params.sortModel[0].colId
        }
    }
    return publicResourceApi.getApplications(search).then((response) => {
        return {
            total: response.count,
            data:
                response.application_inventory
                    ?.map(mapResource)
                    .filter((app) => app.type !== ApplicationType.CONSUMER) || [],
        }
    })
}

function mapResource(response: ApplicationRes): PublicResource {
    return {
        id: response.application_id,
        name: response.application_name,
        type: response.app_type,
        numberOfUserDevice: response.number_of_devices,
        category: response.category,
        logoBitmap: response.logo_bitmap,
        status: response.status,
        sensitivity: response.sensitivity.length > 0 ? response.sensitivity.split(",") : [],
        isBnnDLP: response.bnn_dlp,
        isBnnITP: response.bnn_itp,
        isBnnFed: response.bnn_fed,
        isBnnTunnel: response.bnn_tunnel,
    }
}

export async function getUserDeviceInfoByID(
    id: string,
    search: PaginatedSearch
): Promise<Paginated<UserDevice>> {
    const publicResourceApi: ApplicationsApi = new ApplicationsApi()

    return publicResourceApi
        .getUsersAndDevicesInfo(id, {
            skip: search.skip,
            limit: search.limit,
        })
        .then((response) => {
            return {
                total: response.count,
                data: response.users_devices?.map(mapUserDevice) || [],
            }
        })
}

function mapUserDevice(response: UserDeviceRes): UserDevice {
    return {
        deviceName: response.device_name,
        userName: response.user_name,
        timestamp: DateUtil.formatLargeTimestamp(response.timestamp),
        roles: response.roles,
    }
}

export function useGetUserDeviceInfoById(
    id: string,
    params: PaginatedSearch = { skip: 0, limit: 1000 },
    options?: QueryOptions<Paginated<UserDevice>>
) {
    return useQuery<Paginated<UserDevice>, string>({
        ...options,
        queryKey: ["publicResourceService.getUserDeviceInfoById", id],
        queryFn: async () => getUserDeviceInfoByID(id, params),
    })
}

export function useGetPublicResourceById(id: string, options?: QueryOptions<PublicResourceDetail>) {
    const publicResourceApi = new ApplicationsApi()
    return useQuery<PublicResourceDetail, string>({
        ...options,
        queryKey: ["publicResourceService.getPublicResourceById", id],
        queryFn: async (): Promise<PublicResourceDetail> => {
            const res: ApplicationDetailRes = await publicResourceApi.getApplicationById(id)
            const parsedResult: PublicResourceDetail = {
                name: res.application_name,
                isBlock: res.block,
                description: res.description,
                isDlpPolicy: res.dlp_policy,
                domains: res.domains || [],
                isIdentityProviderFederated: res.identityProvider_federated,
                ignore: res.ignore,
                isIpAllowed: res.ip_allowed,
                logoBitmap: res.logo_bitmap,
                networks: res.networks || [],
                sensitivity: res.sensitivity.length > 0 ? res.sensitivity.split(",") : [],
                status: res.status,
                category: res.category,
                helpfulLinks: res.helpful_links?.length > 0 ? res.helpful_links : [],
                isBnnDLP: res.bnn_dlp,
                isBnnFed: res.bnn_fed,
                isBnnITP: res.bnn_itp,
                isBnnTunnel: res.bnn_tunnel,
                appUrl: res.url,
                type: res.app_type,
            }
            return parsedResult
        },
    })
}

export function useUpdateResourceStatus(id: string, options?: QueryOptions<void, string, boolean>) {
    const queryClient = useQueryClient()
    const publicResourceApi = new ApplicationsApi()

    return useMutation<void, string, boolean>({
        ...options,
        mutationFn: async (isIgnore: boolean): Promise<void> => {
            await publicResourceApi.updateApplicationStatus(id, { ignore: isIgnore })
        },
        onSuccess: () => {
            queryClient.refetchQueries(["publicResourceService.getPublicResourceById", id])
            options?.onSuccess?.()
        },
    })
}

export function useGetItpPoliciesWithAppInfo(
    options?: QueryOptions<ItpPolicyListWithAppFilter[]>
): UseQueryResult<ItpPolicyListWithAppFilter[]> {
    const protectionProfileApi = new ProtectionProfileApi()

    return useQuery({
        ...options,
        queryKey: ["publicResourceService.getItpPoliciesWithAppInfo"],
        queryFn: async (): Promise<ItpPolicyListWithAppFilter[]> => {
            const protectionProfilesRes = await protectionProfileApi.getProtectionProfiles()

            return protectionProfilesRes
                .filter((profile) => profile.profile_name !== excludedDevicesPolicyName)
                .map((response) => mapItpPolicyResToItpPolicyWithAppInfo(response))
        },
    })
}

export function useCreateCustomApplication() {
    const applicationsApi = new ApplicationsApi()
    return useMutation<string, string, CustomApplication>({
        mutationFn: (app: CustomApplication) =>
            applicationsApi.createCustomApplication(
                mapCustomApplicationToCustomApplicationRes(app)
            ),
    })
}

export function useUpdateCustomApplication(id: string) {
    const applicationsApi = new ApplicationsApi()
    const queryClient = useQueryClient()
    return useMutation<string, string, CustomApplication>({
        mutationFn: (app: CustomApplication) =>
            applicationsApi.updateCustomApplication(
                id,
                mapCustomApplicationToCustomApplicationRes(app)
            ),
        onSuccess: () => {
            queryClient.invalidateQueries(["publicResourceService.getPublicResourceById", id])
        },
    })
}

export function useGetServiceTunnels() {
    const serviceTunnelApi = new ServiceTunnelApi()

    return useQuery<ServiceTunnel[], string>({
        queryKey: ["publicResources.getServiceTunnels"],
        queryFn: async () => {
            const { service_tunnels } = await serviceTunnelApi.getServiceTunnels()
            return service_tunnels.map(mapServiceTunnelRes)
        },
    })
}

export function useUpdateServiceTunnel() {
    const serviceTunnelApi = new ServiceTunnelApi()
    const queryClient = useQueryClient()
    return useMutation<void, string, ServiceTunnel>({
        mutationFn: async (tunnel: ServiceTunnel) => {
            await serviceTunnelApi.updateTunnel(tunnel.id, mapServiceTunnelReq(tunnel))
        },
        onSuccess: () => {
            queryClient.invalidateQueries(["publicResources.getServiceTunnels"])
            queryClient.invalidateQueries([
                "publicResourceService.getServiceTunnelsWithAppFilterInfo",
            ])
        },
    })
}

export function useDeleteCustomApp() {
    const applicationApi = new ApplicationsApi()
    const queryClient = useQueryClient()
    return useMutation<void, string, string>({
        mutationFn: async (id: string) => {
            await applicationApi.deleteCustomApplication(id)
        },
        onSuccess: () => {
            queryClient.invalidateQueries(["publicResourceService.getPublicResources"])
        },
    })
}

function mapIncludeExclude(element?: IncludeExcludeRes): IncludeExcludeReq | undefined {
    if (!element) return

    return {
        include: element.include ?? [],
        exclude: element.exclude ?? [],
    }
}

function mapPeerAccessTierRes(res: PeerAccessTierRes): PeerAccessTierReq {
    return {
        ...res,
        public_domains: mapIncludeExclude(res.public_domains),
        public_cidrs: mapIncludeExclude(res.public_cidrs),
        applications: mapIncludeExclude(res.applications),
    }
}

function mapServiceTunnelRes(res: ServiceTunnelRes): ServiceTunnel {
    const [peerAccessTier] = res.spec.spec.peer_access_tiers

    return {
        id: res.id,
        name: res.name,
        activeConnectionsCount: res.active_connections_count,
        applicationInclude: peerAccessTier.applications?.include ?? [],
        applicationExclude: peerAccessTier.applications?.exclude ?? [],
        metadata: res.spec.metadata,
        peerAccessTier: mapPeerAccessTierRes(peerAccessTier),
    }
}

function mapServiceTunnelReq(tunnel: ServiceTunnel): ServiceTunnelReq {
    return {
        metadata: tunnel.metadata,
        spec: {
            peer_access_tiers: [
                {
                    ...tunnel.peerAccessTier,
                    applications: {
                        include: tunnel.applicationInclude,
                        exclude: tunnel.applicationExclude,
                    },
                },
            ],
        },
    }
}

function mapItpPolicyResToItpPolicyWithAppInfo(
    res: ProtectionProfileRes
): ItpPolicyListWithAppFilter {
    return {
        id: res.profile_id,
        name: res.profile_name,
        deviceCount: res.extra_details.device_count,
        allowApps: res.allow_applications,
        blockApps: res.block_applications,
    }
}

function mapCustomApplicationToCustomApplicationRes(app: CustomApplication): CustomApplicationRes {
    const { name, description, url, logo, domains, helpfulLinks } = app
    return {
        application_name: name,
        description,
        url,
        logo,
        domains,
        helpful_links: helpfulLinks,
    }
}

export interface ServiceTunnelWithAppFilter {
    id: string
    name: string
    activeConnectionsCount: number
    includeApps: string[]
    excludeApps: string[]
}

export enum PublicResourceStatus {
    PROTECTED = "Protected",
    IGNORED = "Ignored",
    DISCOVERED = "Discovered",
}

export enum ApplicationType {
    CONSUMER = "consumer",
    SAAS = "saas",
    CUSTOM = "custom",
}

export interface ItpPolicyListWithAppFilter {
    id: string
    name: string
    deviceCount: number
    allowApps: string[]
    blockApps: string[]
}

export const statusMap: Record<PublicResourceStatus, StatusType> = {
    Protected: "success",
    Discovered: "unknown",
    Ignored: "disabled",
}

export const labelMap: Record<PublicResourceStatus, LanguageKey> = {
    Protected: "protected",
    Discovered: "discovered",
    Ignored: "ignored",
}

export const iconMap: Record<PublicResourceStatus, IconDefinition> = {
    Discovered: faLocationCircle,
    Ignored: faDotCircle,
    Protected: faCheckCircle,
}

export interface PublicResource {
    id: string
    name: string
    type: AppType
    numberOfUserDevice: number
    status: Status
    category: string
    logoBitmap: string
    sensitivity: string[]
    isBnnITP: boolean
    isBnnTunnel: boolean
    isBnnDLP: boolean
    isBnnFed: boolean
}

export interface PublicResourceDetail {
    name: string
    isBlock: boolean
    description: string
    isDlpPolicy: boolean
    domains: string[]
    isIdentityProviderFederated: boolean
    ignore: boolean
    isIpAllowed: boolean
    logoBitmap: string
    networks: string[]
    sensitivity: string[]
    status: Status
    category: string
    helpfulLinks: string[]
    isBnnITP: boolean
    isBnnTunnel: boolean
    isBnnDLP: boolean
    isBnnFed: boolean
    appUrl: string
    type: AppType
}

export enum SecurityAction {
    BNN_TUNNEL = "Bnn_Tunnel",
    BNN_ITP = "Bnn_Itp",
    BNN_DLP = "Bnn_Dlp",
    BNN_FED = "Bnn_Fed",
}

export interface UserDevice {
    deviceName: string
    userName: string
    timestamp: string
    roles: string[]
}

export interface CustomApplication {
    name: string
    description?: string
    url?: string
    logo?: string
    domains: string[]
    helpfulLinks?: string[]
}

export interface ServiceTunnel {
    id: string
    name: string
    activeConnectionsCount: number
    applicationInclude: string[]
    applicationExclude: string[]
    metadata: ServiceTunnelReq["metadata"]
    peerAccessTier: PeerAccessTierReq
}
