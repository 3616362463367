import React from "react"

import { AppBannerContext, AppBannerKey, Context } from "./AppBanner.context"

export function AppBannerProvider(props: React.PropsWithChildren<{}>): JSX.Element {
    const [
        hasUserClosedGranularTrustMigrationEducationBanner,
        setHasUserClosedGranularTrustMigrationEducationBanner,
    ] = React.useState(false)
    const [hasUserClosedReturnToOnboardingBanner, setHasUserClosedReturnToOnboardingBanner] =
        React.useState(false)

    const value = React.useMemo<Context>(
        () => ({
            [AppBannerKey.GRANULAR_TRUST_MIGRATION_EDUCATION]: {
                hasUserClosedBanner: hasUserClosedGranularTrustMigrationEducationBanner,
                onUserClosingBanner: () =>
                    setHasUserClosedGranularTrustMigrationEducationBanner(true),
            },
            [AppBannerKey.RETURN_TO_ONBOARDING]: {
                hasUserClosedBanner: hasUserClosedReturnToOnboardingBanner,
                onUserClosingBanner: () => setHasUserClosedReturnToOnboardingBanner(true),
            },
        }),
        [hasUserClosedGranularTrustMigrationEducationBanner, hasUserClosedReturnToOnboardingBanner]
    )

    return <AppBannerContext.Provider {...props} value={value} />
}
