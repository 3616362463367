import React from "react"
import styles from "./LeftNavItem.module.scss"
import { NavLink } from "react-router-dom"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { Tooltip } from "@mui/material"
import { Link } from "../../../../v3/components/link/Link.component"

export function LeftNavItemTemplate() {
    return (
        <div className={styles.leftNavItem}>
            <NavLink
                activeClassName={styles.activeLink}
                className={styles.leftNavItemLink}
                to={this.props.href}
            >
                <div className={styles.leftNavItemIcon}>
                    <FontAwesomeIcon icon={this.props.icon} />
                </div>
                <span className={styles.leftNavItemLabel}>{this.props.label}</span>
            </NavLink>
        </div>
    )
}

export function LeftNavItemCollapsedTemplate() {
    return (
        <div className={styles.leftNavItemCollapsed}>
            {this.props.href && (
                <Tooltip title={this.props.label} placement="right">
                    <NavLink
                        activeClassName={styles.activeLink}
                        className={styles.leftNavItemLink}
                        to={this.props.href}
                    >
                        <div className={styles.leftNavItemIcon}>
                            <FontAwesomeIcon icon={this.props.icon} />
                        </div>
                    </NavLink>
                </Tooltip>
            )}
        </div>
    )
}

export function LeftNavExternalLinkItemTemplate() {
    return (
        <Link
            to={this.props.href}
            className={styles.leftNavItemLink}
            target={this.props.target || "_self"}
        >
            <div className={styles.leftNavItemIcon}>
                <FontAwesomeIcon icon={this.props.icon} />
            </div>
            <span className={styles.leftNavItemLabel}>{this.props.label}</span>
        </Link>
    )
}

export function LeftNavDividerTemplate() {
    return <div className={styles.leftNavItemDivider}>{this.props.label}</div>
}
