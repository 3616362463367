import React, { useRef } from "react"
import { Input } from "../input/Input.component"

import { SelectInput, Option } from "../select-input/SelectInput.component"
import styles from "./SelectWithInput.module.scss"

export type SelectInputValue = { selection: string; value: string }

export interface SelectWithInputProps {
    options: Option[]
    name: string
    placeholder?: string
    value?: SelectInputValue
    defaultValue?: Partial<SelectInputValue>
    pattern?: string
    maxLength?: number
    onChange?: (value: SelectInputValue) => void
    disabled?: boolean
    required?: boolean
    id?: string
    inputAriaLabel?: string
}

export function SelectWithInput(props: SelectWithInputProps): JSX.Element {
    const {
        options,
        disabled,
        required,
        value,
        name,
        onChange,
        defaultValue,
        pattern,
        maxLength,
        id,
        placeholder,
        inputAriaLabel,
    } = props

    const localDefaultSelection =
        options[0] && (typeof options[0] === "string" ? options[0] : options[0].value)

    const { selection, value: inputValue } = value || {}
    const { selection: defaultSelection = localDefaultSelection, value: defaultInputValue = "" } =
        defaultValue || {}

    const inputRef = useRef<HTMLInputElement>(null)
    const selectionRef = useRef<HTMLInputElement>(null)

    function onSelectionChange(updatedSelectionValue: string) {
        onChange?.({
            selection: updatedSelectionValue,
            value: inputRef.current?.value || "",
        })
    }

    function onInputChange(event: React.ChangeEvent<HTMLInputElement>) {
        onChange?.({
            selection: selectionRef.current?.value || "",
            value: event.target.value,
        })
    }

    return (
        <div className={styles.SelectInput}>
            <SelectInput
                ref={selectionRef}
                selectClassName={styles.selectionSelect}
                className={styles.selection}
                defaultValue={typeof selection === "string" ? undefined : defaultSelection}
                value={selection}
                options={options}
                disabled={disabled}
                onChange={onSelectionChange}
                name={`${name}-selection`}
                id={id && `${id}-selection`}
            />
            <Input
                ref={inputRef}
                className={styles.value}
                defaultValue={typeof inputValue === "string" ? undefined : defaultInputValue}
                value={inputValue}
                pattern={pattern}
                maxLength={maxLength}
                type="text"
                disabled={disabled}
                required={required}
                onChange={onInputChange}
                name={`${name}-value`}
                id={id && `${id}-value`}
                placeholder={placeholder}
                aria-label={inputAriaLabel}
            />
        </div>
    )
}
