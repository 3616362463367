import React, { Fragment } from "react"
import styles from "./ServicesSaasOverview.module.scss"
import { LoadMask } from "../../../components/load-mask/LoadMask.component"
import { LargeMessage } from "../../../components/large-message/LargeMessage.component"
import { faExclamation } from "@fortawesome/pro-solid-svg-icons"
import classNames from "classnames/bind"
import { ServiceManageStatus } from "../../../services/Manage.service"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { SimpleTable } from "../../../components/simple-table/SimpleTable.component"
import { TabBar } from "../../../components/tab-bar/TabBar.component"
import { ServicesSaasOverviewAttr } from "./attr/ServicesSaasOverviewAttr.component"
import { ServiceActivityList } from "../activity-list/ServiceActivityList.component"
import { PageHeading } from "../../../../components/page-heading/PageHeading.component"
import {
    Button,
    ButtonElement,
    ButtonType,
    IconType,
} from "../../../../components/button/Button.component"
import { Tooltip } from "../../../../v3/components/tooltip/Tooltip.component"

export default function () {
    return (
        <section aria-labelledby="heading" className={styles.section}>
            <header className={styles.header}>
                <PageHeading id="heading">{this.state.service?.name}</PageHeading>
                <div className={styles.actionButtons}>
                    {this.state.canEdit && (
                        <Tooltip title={this.localizationService.getString("edit")}>
                            <Button
                                icon={IconType.PEN}
                                onClick={this.onEdit}
                                asElement={ButtonElement.BUTTON}
                                buttonType={ButtonType.SECONDARY}
                                aria-label={this.localizationService.getString("edit")}
                            />
                        </Tooltip>
                    )}
                    <Tooltip title={this.localizationService.getString("refresh")}>
                        <Button
                            icon={IconType.REDO}
                            onClick={this.fetchData}
                            asElement={ButtonElement.BUTTON}
                            buttonType={ButtonType.SECONDARY}
                            aria-label={this.localizationService.getString("refresh")}
                        />
                    </Tooltip>
                </div>
            </header>
            <div className={classNames(styles.overviewContainer, "pre-v3")}>
                {this.state.loading && <LoadMask></LoadMask>}
                {!this.state.loading && !this.state.service && (
                    <LargeMessage icon={faExclamation} className={styles.missingMessage}>
                        {this.localizationService.getString("appNotFound")}
                    </LargeMessage>
                )}
                {this.state.service && (
                    <Fragment>
                        <div className={styles.overviewLeftContainer}>
                            <div
                                className={classNames(styles.policy, {
                                    [styles.policyEnforcing]:
                                        this.state.service.status ===
                                        ServiceManageStatus.POLICY_ENFORCING,
                                    [styles.policyPermissive]:
                                        this.state.service.status ===
                                        ServiceManageStatus.POLICY_PERMISSIVE,
                                    [styles.noPolicy]:
                                        this.state.service.status === ServiceManageStatus.NO_POLICY,
                                })}
                            >
                                <p className={styles.statusHeader}>
                                    {this.localizationService.getString("serviceStatus")}
                                </p>
                                <p>
                                    <FontAwesomeIcon
                                        className={styles.statusIcon}
                                        icon={this.state.serviceIcon}
                                    />
                                    <span className={styles.statusText}>
                                        {this.state.serviceStatus}
                                    </span>
                                </p>
                                <SimpleTable
                                    className={styles.policyTable}
                                    items={this.state.policyData}
                                />
                            </div>
                            <SimpleTable items={this.state.serviceData} />
                        </div>
                        <div className={styles.overviewRightContainer}>
                            <TabBar tabs={this.tabs} onChange={this.onTabChange}></TabBar>
                            <div className={styles.overviewRightGridContainer}>
                                {this.state.tab === 1 && (
                                    <ServicesSaasOverviewAttr app={this.state.service} />
                                )}
                                {this.state.tab === 2 && (
                                    <ServiceActivityList
                                        serviceId={this.id}
                                        history={this.props.history}
                                    />
                                )}
                            </div>
                        </div>
                    </Fragment>
                )}
            </div>
        </section>
    )
}
