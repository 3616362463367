import React from "react"
import classNames from "classnames/bind"
import styles from "./PageBreak.module.scss"

export function PageBreak({ children, className, hideLine }: PageBreakProps) {
    return (
        <div className={classNames(styles.pageBreak, className)}>
            {children && <h3 className={styles.content}>{children}</h3>}
            {!hideLine && <hr className={styles.line} />}
        </div>
    )
}

interface PageBreakProps {
    className?: string
    children?: any
    hideLine?: boolean
}
