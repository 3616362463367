import { faSpinner } from "@fortawesome/pro-regular-svg-icons"
import { faCheck, faTimes } from "@fortawesome/pro-solid-svg-icons"
import { Tooltip } from "@mui/material"
import React from "react"

import {
    ModalApi as BaseModalApi,
    ModalAction,
    ModalWithoutRef,
} from "../../../components/modal/Modal.component"
import { useServiceLocalization } from "../../../pre-v3/services/localization/Localization.service"
import { Badge } from "../../../v3/components/badge/Badge.component"
import {
    AccessTierDetails,
    ConnectionResult,
    Status,
    useTestConnection,
} from "../../../v3/services/AccessTier.service"
import { ErrorToast, ToastApi } from "../../../components/toast/Toast.components"
import styles from "./TestConnectionModal.module.scss"

export { ModalAction } from "../../../components/modal/Modal.component"

export type ModalApi = BaseModalApi<void>

interface Props {
    accessTier: AccessTierDetails
    footer?: React.ReactNode
    footerWhenError?: React.ReactNode
}

export const TestConnectionModal = React.forwardRef<ModalApi, Props>((props, ref) => {
    const ls = useServiceLocalization()

    const modalRef = React.useRef<ModalApi>(null)
    const toastRef = React.useRef<ToastApi>(null)

    const {
        mutate: testConnection,
        data: connectionResult,
        isLoading: isTesting,
        isError: didTestingFailed,
    } = useTestConnection(props.accessTier, {
        onError: (error) =>
            typeof error === "string" ? toastRef.current?.openToast(error) : console.error(error),
    })

    React.useImperativeHandle(ref, () => ({
        open: () => {
            if (!modalRef.current) return Promise.resolve({ action: ModalAction.DISMISS })
            try {
                testConnection()
            } finally {
                return modalRef.current.open()
            }
        },
        complete: () => modalRef.current?.complete(),
        dismiss: () => modalRef.current?.dismiss(),
    }))

    const { resolutionError, reachabilityError } = useGetErrorMessage(connectionResult)

    const hasError =
        props.accessTier.status !== Status.REPORTING ||
        connectionResult !== ConnectionResult.SUCCESS ||
        didTestingFailed

    return ModalWithoutRef(
        {
            id: Id.TEST_CONNECTION_MODAL,
            title: ls.getString("testConnection"),
            childrenClassName: styles.children,
            children: (
                <React.Fragment>
                    <ErrorToast ref={toastRef} />
                    <Header>{ls.getString("status")}</Header>
                    <Header>{ls.getString("connectivity")}</Header>
                    <Header>{ls.getString("test")}</Header>
                    <StatusCell
                        isLoading={isTesting}
                        isError={didTestingFailed || Boolean(resolutionError)}
                        errorReason={resolutionError}
                    />
                    <ConnectivityCell fromText={ls.getString("accessTierIPAddress")} />
                    <TestCell
                        title={ls.getString("address")}
                        description={ls.getString("publicAddressResolved")}
                    />

                    <StatusCell
                        isLoading={isTesting}
                        isError={didTestingFailed || Boolean(reachabilityError || resolutionError)}
                        errorReason={reachabilityError}
                    />
                    <ConnectivityCell
                        fromText={ls.getString("internet")}
                        toText={props.accessTier.name}
                    />
                    <TestCell
                        title={ls.getString("reachability")}
                        description={ls.getString("publicAddressReachableOnIPAndPort")}
                    />

                    <StatusCell
                        isLoading={isTesting}
                        isError={didTestingFailed || props.accessTier.status !== Status.REPORTING}
                    />
                    <ConnectivityCell
                        fromText={props.accessTier.name}
                        toText={ls.getString("commandCtr")}
                    />
                    <TestCell
                        title={ls.getString("statusReporting")}
                        description={ls.getString("accessTierFullyOperationalAndReporting")}
                    />
                </React.Fragment>
            ),
            footer: (
                <div className={styles.footer}>
                    {hasError && props.footerWhenError ? props.footerWhenError : props.footer}
                </div>
            ),
        },
        modalRef
    )
})

enum Id {
    TEST_CONNECTION_MODAL = "testConnectionModal",
}

interface ErrorMessage {
    resolutionError?: string
    reachabilityError?: string
}

function useGetErrorMessage(connectionResult?: ConnectionResult): ErrorMessage {
    const ls = useServiceLocalization()

    switch (connectionResult) {
        case ConnectionResult.REACHABILITY_ERROR:
            return { reachabilityError: ls.getString("anErrorOccurredWhileSendingAnHttpRequest") }

        case ConnectionResult.RESOLUTION_ERROR:
            return {
                resolutionError: ls.getString("anErrorOccurredWhileResolvingAnAccessTierHost"),
            }

        case ConnectionResult.SUCCESS:
        case undefined:
            return {}
    }
}

export function Header(props: React.PropsWithChildren): JSX.Element {
    return <h2 className={styles.header}>{props.children}</h2>
}

interface ConnectivityCellProps {
    fromText: string
    toText?: string
}

export function ConnectivityCell(props: ConnectivityCellProps): JSX.Element {
    const localization = useServiceLocalization()
    return (
        <p>
            {props.fromText}
            {props.toText && (
                <React.Fragment>
                    <br />
                    {localization.getString("to", props.toText)}
                </React.Fragment>
            )}
        </p>
    )
}

interface TestCellProps {
    title: string
    description: string
}

export function TestCell(props: TestCellProps): JSX.Element {
    return (
        <div>
            {props.title}
            <br />
            {props.description}
        </div>
    )
}

interface StatusCellProps {
    isLoading: boolean
    isError: boolean
    errorReason?: string
}

export function StatusCell(props: StatusCellProps): JSX.Element {
    const content = props.isLoading ? (
        <Badge icon={faSpinner} loading />
    ) : props.isError ? (
        <Badge icon={faTimes} error />
    ) : (
        <Badge icon={faCheck} success />
    )

    return (
        <Tooltip placement="top" title={props.errorReason ?? ""}>
            <div className={styles.statusCell}>{content}</div>
        </Tooltip>
    )
}
