import { IconDefinition } from "@fortawesome/fontawesome-svg-core"
import { faComments, faQuestionCircle } from "@fortawesome/pro-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import React from "react"

import cseLogo from "../../../images/cse-horiz-dark.svg"
import { useServiceLinks } from "../../../pre-v3/services/link/Link.service"
import { useServiceLocalization } from "../../../pre-v3/services/localization/Localization.service"
import styles from "./BanyanHeader.module.scss"

export interface Props {
    /**
     * Show the button that links to Community Support
     */
    showCommunitySupport?: boolean
    /**
     * Additional styling
     */
    className?: string
}

/**
 * The header used in the Banyan Console application
 */
export function BanyanHeader(props: Props): JSX.Element {
    const links = useServiceLinks()
    const localization = useServiceLocalization()

    return (
        <header className={props.className}>
            <nav className={styles.nav}>
                <a href={links.getLink("corporate")} target="_blank" rel="noopener noreferrer">
                    <img src={cseLogo} alt={localization.getString("sonicWallCse")} width="220px" />
                </a>
                <div className={styles.links}>
                    {props.showCommunitySupport && (
                        <a
                            href={links.getLink("communitySupport")}
                            target="_blank"
                            rel="noopener noreferrer"
                            className={styles.link}
                        >
                            <LinkContent
                                icon={faComments}
                                label={localization.getString("communitySupport")}
                            />
                        </a>
                    )}
                    <a
                        href={links.getLink("documentationQuickStart")}
                        target="_blank"
                        rel="noopener noreferrer"
                        className={styles.link}
                    >
                        <LinkContent
                            icon={faQuestionCircle}
                            label={localization.getString("helpDocs")}
                        />
                    </a>
                </div>
            </nav>
        </header>
    )
}

interface LinkContentProps {
    icon: IconDefinition
    label: string
}

function LinkContent(props: LinkContentProps): JSX.Element {
    return (
        <React.Fragment>
            <FontAwesomeIcon className={styles.icon} icon={props.icon} />
            <span className={styles.label}>{props.label}</span>
        </React.Fragment>
    )
}
