import { PatternUtil } from "./Pattern.util"

export class StringUtil {
    public static isTrue(value: string | boolean | undefined): boolean {
        if (typeof value === "string") {
            return value.toLowerCase() === "true"
        } else {
            return !!value
        }
    }

    public static getNumber(value: string | number | undefined): number {
        if (!value) {
            return 0
        }

        if (typeof value === "string") {
            const num: number = parseInt(value, 10)
            return isNaN(num) ? 0 : num
        }

        return value
    }

    public static isRegex(value: string): boolean {
        const regTest = /^\/.*\/$/
        if (regTest.test(value)) {
            const sliced = new RegExp(value.slice(1, -1), "i")
            try {
                new RegExp(sliced)
                return true
            } catch {
                return false
            }
        } else {
            return false
        }
    }

    public static caseInsensitiveIncludes(value: string, substr: string): boolean {
        return value.toLowerCase().includes(substr.toLowerCase())
    }

    public static serializeArray(arr: string[], separator: string = "|"): string {
        if (!arr) {
            return ""
        }
        return arr.join(separator)
    }

    public static deserializeArray(str: string, separator: string = "|"): string[] {
        if (!str) {
            return []
        }
        return str.split(separator).map((s) => s.trim())
    }

    public static fromBoolean(bool: boolean): "true" | "false" {
        return bool ? "true" : "false"
    }

    public static truncate(str: string, length: number): string {
        if (!str) {
            return ""
        }
        if (str.length <= length) {
            return str
        }
        return str.substring(0, length) + "..."
    }

    public static toCamelCase(string: string): string {
        const [firstWord, ...otherWords] = words(string)

        if (!firstWord) return ""

        return otherWords.reduce(StringUtil.reduceToCamelCase, firstWord.toLowerCase())
    }

    public static capitalize(word: string): string {
        return word.substr(0, 1).toUpperCase() + word.substr(1).toLowerCase()
    }

    private static reduceToCamelCase(acc: string, word: string): string {
        return acc + StringUtil.capitalize(word)
    }

    public static stringListToArray(list: string): string[] {
        return list.split("\n").reduce<string[]>((acc, line) => {
            const trimmed = line.trim()
            return trimmed ? [...acc, trimmed] : acc
        }, [])
    }

    public static arrayToStringList(arr: string[]): string {
        return arr.join("\n")
    }

    public static stringListToArrayList(list: string): string[] {
        if (!list) return []
        const arr = list.split(",")
        return arr.map((value) => value.trim().replace(/\n/g, "")).filter((val) => val)
    }
}

function words(string: string): string[] {
    return string.match(PatternUtil.WORDS) ?? []
}
