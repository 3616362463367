import React from "react"
import classNames from "classnames"

import styles from "./Status.module.scss"
import { useServiceLocalization } from "../../pre-v3/services/localization/Localization.service"
import { StatusType, statusLabelKey } from "../../utils/StatusType.utils"
import { Icon, IconType } from "../icon/Icon.component"

export interface Props {
    type: StatusType
    label?: string
    icon?: IconType
    hideIcon?: boolean
    iconClassName?: string
}

export function Status(props: Props) {
    const { type, label, icon, iconClassName, hideIcon } = props

    const localization = useServiceLocalization()

    return (
        <div className={styles.container}>
            {!hideIcon && (
                <Icon
                    icon={icon || iconMap[type]}
                    className={classNames(styles.statusIcon, classMap[type], iconClassName)}
                />
            )}
            <span className={styles.label}>
                {label || localization.getString(statusLabelKey[type])}
            </span>
        </div>
    )
}

type IconMap = Record<StatusType, IconType>
type ClassMap = Record<StatusType, string>

const iconMap: IconMap = {
    [StatusType.SUCCESS]: IconType.CHECK_SOLID,
    [StatusType.WARNING]: IconType.CIRCLE_EXCLAMATION,
    [StatusType.ERROR]: IconType.TIMES_CIRCLE,
    [StatusType.DISABLED]: IconType.PAUSE_CIRCLE,
    [StatusType.UNKNOWN]: IconType.QUESTION_CIRCLE,
}

const classMap: ClassMap = {
    [StatusType.SUCCESS]: styles.success,
    [StatusType.WARNING]: styles.warning,
    [StatusType.ERROR]: styles.error,
    [StatusType.DISABLED]: styles.disabled,
    [StatusType.UNKNOWN]: styles.unknown,
}
