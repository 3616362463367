import classNames from "classnames"
import React from "react"
import { Link } from "react-router-dom"
import { Link as LinkComponent } from "../link/Link.component"

import { useServiceLocalization } from "../../pre-v3/services/localization/Localization.service"
import logoSmall from "../../images/logo-brand.svg"
import logoLarge from "../../images/logo-horiz-black.svg"
import { Icon, IconType, IconSize } from "../icon/Icon.component"
import styles from "./LeftNavigationBar.module.scss"
import { AppConsole } from "../../utils/consoles.utils"

export { IconType } from "../icon/Icon.component"

export interface LeftNavigationBarProps<NavigationItem> {
    label: string
    navigationItems: NavigationItem[]
    initiallyExpanded: boolean
    routeToHome: string
    routeToProfile: string
    className?: string
    isImpersonated: boolean
    getNavigationItemProps(item: NavigationItem): NavigationItemProps
    onSignOut(): void
    onBackToParentConsole(): void
    parentConsole: AppConsole | undefined
}

export interface NavigationItemProps {
    key: React.Key
    icon: IconType
    label: string
    to: string
    active?: boolean
}

export function LeftNavigationBar<NavigationItem>(
    props: LeftNavigationBarProps<NavigationItem>
): JSX.Element {
    const [expanded, setExpanded] = React.useState(props.initiallyExpanded)
    const localization = useServiceLocalization()

    const containerClasses = classNames(props.className, styles.container, {
        [styles.expanded]: expanded,
    })

    const onToggleExpanded: React.MouseEventHandler = (event) => {
        event.preventDefault()
        setExpanded((prevExpanded) => !prevExpanded)
    }

    const onSignOut: React.MouseEventHandler = (event) => {
        event.preventDefault()
        props.onSignOut()
    }

    const onBackToParentConsole: React.MouseEventHandler = (event) => {
        event.preventDefault()
        props.onBackToParentConsole()
    }

    const expandButtonLabel = localization.getString(expanded ? "collapseNav" : "expandNav")
    const signOutLabel = localization.getString("signOut")

    const mapNavigationItem = (item: NavigationItem): JSX.Element => {
        const navigationItemProps = props.getNavigationItemProps(item)

        return (
            <li key={navigationItemProps.key}>
                <Link
                    to={navigationItemProps.to}
                    className={styles.navigationLink}
                    aria-current={navigationItemProps.active ? "page" : false}
                    aria-label={navigationItemProps.label}
                >
                    <Icon icon={navigationItemProps.icon} />
                    <span className={styles.navigationLinkLabel}>{navigationItemProps.label}</span>
                </Link>
            </li>
        )
    }

    return (
        <nav className={containerClasses} aria-label={props.label}>
            <Link className={styles.logo} to={props.routeToHome}>
                <img
                    src={expanded ? logoLarge : logoSmall}
                    alt={localization.getString("sonicWallCseLogo")}
                />
            </Link>

            <button
                className={styles.expandButton}
                onClick={onToggleExpanded}
                aria-label={expandButtonLabel}
            >
                <Icon
                    icon={expanded ? IconType.ARROW_FROM_RIGHT : IconType.ARROW_FROM_LEFT}
                    size={IconSize.SMALL}
                />
                <span className={styles.expandButtonLabel}>{expandButtonLabel}</span>
            </button>

            <ul className={styles.navigationLinks}>
                {props.navigationItems.map(mapNavigationItem)}
            </ul>

            <div className={styles.links}>
                {props.isImpersonated && props.parentConsole && (
                    <LinkComponent className={styles.goBack} onClick={onBackToParentConsole}>
                        {localization.getString(
                            "backToParentConsole",
                            localization.getString(props.parentConsole)
                        )}
                    </LinkComponent>
                )}
            </div>

            <button className={styles.signOut} onClick={onSignOut} aria-label={signOutLabel}>
                <Icon icon={IconType.SIGN_OUT} size={IconSize.SMALL} />
                <span className={styles.signOutLabel}>{signOutLabel}</span>
            </button>
        </nav>
    )
}
