import React from "react"

import { useDebouncedCallback } from "../pre-v3/utils/UseDebouncedCallback.hook"
import { useBroadcastChannelService } from "../v3/services/BroadcastChannel.service"

export function useActivityMonitor() {
    const broadcastChannelService = useBroadcastChannelService()

    const onInteraction = useDebouncedCallback(() => {
        broadcastChannelService.broadcastMessage({ type: "updateLastInteractionTime" })
    }, [broadcastChannelService.broadcastMessage])

    React.useEffect(() => {
        broadcastChannelService.broadcastMessage({ type: "startActivityMonitor" })
        window.addEventListener("mousemove", onInteraction)
        window.addEventListener("keypress", onInteraction)

        return () => {
            broadcastChannelService.broadcastMessage({ type: "stopActivityMonitor" })
            window.removeEventListener("mousemove", onInteraction)
            window.removeEventListener("keypress", onInteraction)
        }
    })
}
