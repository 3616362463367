import React from "react"

import {
    CheckboxGroupBase,
    CheckboxGroupBaseOption,
    CheckboxGroupBaseProps,
} from "../base/CheckboxGroupBase.component"
import styles from "./CheckboxGroup.module.scss"

export interface CheckboxGroupProps extends Omit<CheckboxGroupBaseProps, "options"> {
    value: string[]
    options: CheckboxGroupOption[]
    disabled?: boolean
    required?: boolean
    onChange(value: string[]): void
}

export function CheckboxGroup(props: CheckboxGroupProps): JSX.Element {
    const options = React.useMemo(
        () => props.options.map(mapCheckboxGroupBaseOption),
        [props.options]
    )

    return (
        <CheckboxGroupBase
            {...props}
            options={options}
            selectAllClassName={styles.selectAll}
            checkboxItemClassName={styles.checkboxItem}
        />
    )
}

export interface CheckboxGroupOption extends Omit<CheckboxGroupBaseOption, "children"> {
    id: string
    label: string
}

function mapCheckboxGroupBaseOption(option: CheckboxGroupOption): CheckboxGroupBaseOption {
    const { label, ...rest } = option
    return { ...rest, children: label }
}
