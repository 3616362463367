import React from "react"
import styles from "./ServicesEditStatusDialog.module.scss"
import { ConfirmationDialog, LoadMask, Banner } from "../../../../components"
import { faExclamationCircle } from "@fortawesome/pro-solid-svg-icons"

export default function () {
    return (
        <ConfirmationDialog
            open={this.props.open}
            onClose={this.props.onClose}
            onConfirm={this.onConfirm}
            title={
                this.props.service.enabled
                    ? this.localizationService.getString("disableService")
                    : this.localizationService.getString("enableService")
            }
            confirmLabel={
                this.props.service.enabled
                    ? this.localizationService.getString("disable")
                    : this.localizationService.getString("enable")
            }
        >
            {this.state.loading && <LoadMask />}
            {this.state.error && (
                <Banner className={styles.errorBanner} type="error" icon={faExclamationCircle}>
                    {this.state.error}
                </Banner>
            )}
            {this.props.service.enabled
                ? this.localizationService.getString("disableServiceDescription")
                : this.localizationService.getString("enableServiceDescription")}
        </ConfirmationDialog>
    )
}
