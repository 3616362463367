import { ColDef, GridApi, GridReadyEvent } from "ag-grid-community"
import React, { useRef, useState } from "react"
import { Link } from "react-router-dom"
import { Grid } from "../../../pre-v3/components/grid/Grid.component"
import {
    LocalizationService,
    useServiceLocalization,
} from "../../../pre-v3/services/localization/Localization.service"
import { IconType } from "../../../pre-v3/services/ActionBar.service"
import AgGridUtil from "../../../pre-v3/utils/AgGrid.util"
import { ErrorBanner, MenuItem } from "../../../pre-v3/components"
import { Toolbar } from "../../../pre-v3/components/toolbar/Toolbar.component"
import { MenuButton } from "../../../v3/components/menu/menu-button/MenuButton.component"
import { SelectItem } from "../../../pre-v3/utils/SelectValue.util"
import { faFilter } from "@fortawesome/pro-solid-svg-icons"
import classNames from "classnames/bind"
import styles from "./DataPolicyList.module.scss"
import { DataPolicyStatus, DataPolicyService, useServiceDataPolicy } from "../svc"
import { StatusCellRenderer } from "../cell-renderers/status/StatusCellRenderer"
import { IconButton } from "../../../pre-v3/components/icon-button/IconButton.component"
import { faPlus } from "@fortawesome/pro-light-svg-icons"
import { useServiceModal } from "../../../pre-v3/services"
import { ROUTE, formatRoutePath } from "../../../routes"
import { PageHeading } from "../../../components/page-heading/PageHeading.component"
import { Button, ButtonElement, ButtonType } from "../../../components/button/Button.component"
import useTitle from "../../../hooks/useTitle.hook"

export function DataPolicyList() {
    useTitle(["dataLostPrevention", "internetAccess", "adminConsole"])
    const ls: LocalizationService = useServiceLocalization()
    const [statusFilterLabel, setStatusFilterLabel] = React.useState<string>(
        DataPolicyStatus.ALLSTATUSES
    )
    const dataPolicyService: DataPolicyService = useServiceDataPolicy()
    const modalService = useServiceModal()

    const columnDefs: ColDef[] = [
        {
            headerName: ls.getString("status"),
            field: "status",
            flex: 90,
            cellRenderer: "statusCellRenderer",
            filter: "agSetColumnFilter",
            comparator: AgGridUtil.alphaBetComparator,
        },
        {
            headerName: ls.getString("policyName"),
            field: "name",
            tooltipValueGetter: AgGridUtil.linkTooltipValueGetter,
            flex: 250,
            cellRenderer: "nameCellRenderer",
            comparator: AgGridUtil.alphaBetComparator,
            filter: "agSetColumnFilter",
        },
        {
            headerName: "Services",
            field: "attachments.length",
            tooltipValueGetter: AgGridUtil.linkTooltipValueGetter,
            flex: 125,
        },
        {
            headerName: "Last Updated",
            field: "updated_at",
            valueFormatter: AgGridUtil.dateFormatter,
            flex: 125,
        },
    ]

    const statusFilterOptions: SelectItem[] = [
        {
            displayName: ls.getString("allStatuses"),
            value: "",
        },
        {
            displayName: DataPolicyStatus.ENFORCING,
            value: DataPolicyStatus.ENFORCING,
        },
        {
            displayName: DataPolicyStatus.PERMISSIVE,
            value: DataPolicyStatus.PERMISSIVE,
        },
        {
            displayName: DataPolicyStatus.INACTIVE,
            value: DataPolicyStatus.INACTIVE,
        },
    ]

    const gridApi = useRef<GridApi>()
    const [error, setError] = useState<string>("")

    function onGridReady(event: GridReadyEvent) {
        gridApi.current = event.api
        fetchData()
    }

    const onStatusFilterChange = (filter: SelectItem) => (): void => {
        if (!gridApi.current) {
            return
        }

        gridApi.current.getFilterInstance("status")?.setModel({
            type: "equals",
            filter:
                !filter.value || filter.value === DataPolicyStatus.ALLSTATUSES ? "" : filter.value,
        })

        gridApi.current.onFilterChanged()

        setStatusFilterLabel(filter.displayName)
    }

    const onFilter = (value: string): void => {
        gridApi.current?.setQuickFilter(value)
    }

    const fetchData = () => {
        if (gridApi.current) {
            setError("")
            gridApi.current.setRowData(dataPolicyService.getDataPolicies())
        }
    }

    const openModal = () => {}

    return (
        <div className={styles.gridWithToolbar}>
            <section aria-labelledby={Id.HEADING}>
                <header className={styles.header}>
                    <PageHeading id={Id.HEADING}>{ls.getString("dataLostPrevention")}</PageHeading>
                    <Button
                        icon={IconType.REDO}
                        onClick={fetchData}
                        asElement={ButtonElement.BUTTON}
                        buttonType={ButtonType.SECONDARY}
                        aria-label={ls.getString("refresh")}
                    />
                </header>
                {error && <ErrorBanner>{error}</ErrorBanner>}
                <Toolbar onSearchChange={onFilter}>
                    <MenuButton
                        className={styles.filterButton}
                        label={statusFilterLabel}
                        icon={faFilter}
                    >
                        {statusFilterOptions.map((filter: SelectItem) => (
                            <MenuItem onClick={onStatusFilterChange(filter)} key={filter.value}>
                                {filter.displayName}
                            </MenuItem>
                        ))}
                    </MenuButton>
                    <IconButton
                        icon={faPlus}
                        brand
                        label="Create DLP Policy"
                        onClick={openModal}
                    ></IconButton>
                </Toolbar>
                <div className={styles.gridContainer}>
                    <Grid
                        className={styles.grid}
                        onGridReady={onGridReady}
                        columnDefs={columnDefs}
                        pagination
                        components={{
                            nameCellRenderer: NameCellRenderer,
                            statusCellRenderer: StatusCellRenderer,
                        }}
                    />
                </div>
            </section>
        </div>
    )
}

function NameCellRenderer(props: any) {
    if (props.data && props.data.id) {
        return (
            <Link
                className={classNames(styles.nameLink)}
                to={formatRoutePath(ROUTE.DATA_LOST_PREVENTION_DETAILS, {
                    id: props.data.id,
                })}
            >
                {props.data.name ? props.data.name : props.data.id}
            </Link>
        )
    } else {
        return (
            <span>{props.data.resourceName ? props.data.resourceName : props.data.resourceId}</span>
        )
    }
}

enum Id {
    HEADING = "heading",
}
