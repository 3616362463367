import React from "react"
import HighCharts from "highcharts"
import HighChartsReact from "highcharts-react-official"
import HighChartsSankey from "highcharts/modules/sankey"
import histogram from "highcharts/modules/histogram-bellcurve"

histogram(HighCharts)
HighChartsSankey(HighCharts)

interface Props {
    options: Highcharts.Options
    onChartReady?: (chart: Highcharts.Chart) => void
    title?: string
    subtitle?: string
}

// set global options
HighCharts.setOptions({
    lang: {
        thousandsSep: ",",
    },
})

export function Chart(props: Props) {
    return (
        <HighChartsReact
            highcharts={HighCharts}
            options={{
                title: {
                    text: props.title,
                    align: "left",
                    style: {
                        color: "#222C36",
                        fontSize: "14px",
                        fontWeight: "600",
                        letterSpacing: "0.2px",
                    },
                },
                subtitle: {
                    text: props.subtitle,
                    align: "left",
                    style: {
                        color: "#222C36",
                        fontSize: "13px",
                        fontWeight: "400",
                    },
                },
                credits: {
                    enabled: false,
                },
                plotOptions: {
                    column: {
                        colorByPoint: true,
                    },
                },
                ...props.options,
            }}
            callback={props.onChartReady}
            containerProps={{ style: { height: "100%" } }}
        />
    )
}
