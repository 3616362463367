import React, { ReactNode } from "react"
import InfoTipTemplate from "./InfoTip.template"

export class InfoTip extends React.Component<InfoTipProps, {}> {
    public render(): ReactNode {
        return InfoTipTemplate.call(this)
    }
}

interface InfoTipProps {
    message: string
    className?: string
    component?: React.ReactNode
}
