import React, { ChangeEvent, useRef } from "react"

import { useServiceLocalization } from "../../services/localization/Localization.service"
import { FormUtil } from "../../utils/Form.util"
import { Input } from "../../../v3/components/input/Input.component"

const POSITIVE_NUMBER_REGEX = /^\d*$/

interface PortInputProps
    extends Omit<
        React.DetailedHTMLProps<React.InputHTMLAttributes<HTMLInputElement>, HTMLInputElement>,
        "placeholder" | "type" | "inputMode" | "min" | "max" | "onChange"
    > {
    onChange?: (value: string) => void
}

const MIN = 0
const MAX = 65535

export function PortInput(props: PortInputProps) {
    const { onChange, ...restProps } = props
    const localization = useServiceLocalization()

    const portInputRef = useRef<HTMLInputElement>(null)

    function handleOnChange(event: ChangeEvent<HTMLInputElement>) {
        const value = event.target.value

        const isValidInput: boolean = FormUtil.isValidInput(value, POSITIVE_NUMBER_REGEX)

        if (isValidInput) {
            onChange?.(event.target.value)

            handleValidate(event.target.value)
        }
    }

    function handleValidate(value: string) {
        const valueNumber = Number(value)

        let errorMsg: string = ""

        if (valueNumber < MIN || valueNumber > MAX) {
            errorMsg = localization.getString("pleaseEnterAValidPort")
        }

        portInputRef?.current?.setCustomValidity?.(errorMsg)
    }

    return (
        <Input
            {...restProps}
            inputMode="numeric"
            onChange={handleOnChange}
            placeholder={localization.getString("port")}
            ref={portInputRef}
            type="text"
        />
    )
}
