import { faRocket, faShieldAlt, faVectorSquare } from "@fortawesome/pro-regular-svg-icons"
import { faFilter } from "@fortawesome/pro-solid-svg-icons"
import { ColDef, GridApi, GridReadyEvent } from "ag-grid-community"
import classNames from "classnames/bind"
import React, { useRef, useState } from "react"

import { ROUTE, formatRoutePath } from "../../../../../routes"
import { ErrorBanner, MenuItem, Tooltip } from "../../../../components"
import { StatusCellRenderer } from "../cell-renderers/status/StatusCellRenderer"
import { Grid } from "../../../../components/grid/Grid.component"
import { Toolbar } from "../../../../components/toolbar/Toolbar.component"
import {
    CloudResource,
    CloudResourceStatus,
    LocalizationService,
    OrgEdition,
    UserService,
    useServiceLinks,
    useServiceLocalization,
    useServiceUser,
} from "../../../../services"
import AgGridUtil from "../../../../utils/AgGrid.util"
import { encodeID } from "../../../../utils/Url.util"
import { MenuButton } from "../../../../components/menu-button/MenuButton.component"
import { SelectItem } from "../../../../utils/SelectValue.util"
import { InventoryService, useServiceInventory } from "../../../../services/Inventory.service"
import {
    ContactUsScreen,
    ContactUsScreenSteps,
} from "../../../../../v3/components/page-screen/PageScreen.component"
import { Link } from "../../../../../v3/components/link/Link.component"
import { Container } from "../../../../../v3/components/container/Container.component"
import banyanRoute from "../../../../../images/banyan-route.svg"
import styles from "./CloudResourceList.module.scss"
import { PageHeading } from "../../../../../components/page-heading/PageHeading.component"
import { IconType } from "../../../../../components/icon/Icon.component"
import {
    ButtonElement,
    ButtonType,
    Button,
} from "../../../../../components/button/Button.component"

export function CloudResourceList(): JSX.Element {
    const ls: LocalizationService = useServiceLocalization()
    const inventoryService: InventoryService = useServiceInventory()
    const userService: UserService = useServiceUser()
    const linkService = useServiceLinks()
    const [statusFilterLabel, setStatusFilterLabel] = React.useState<string>(
        CloudResourceStatus.ALL_STATUSES
    )
    const [sourceFilterLabel, setSourceFilterLabel] = React.useState<string>(
        ls.getString("allSources")
    )

    const [isTeamEdition, setIsTeamEdition] = React.useState<boolean>(false)

    const columnDefs: ColDef[] = [
        {
            headerName: ls.getString("status"),
            field: "status",
            flex: 50,
            cellRenderer: "statusCellRenderer",
            filter: "agTextColumnFilter",
            comparator: AgGridUtil.alphaBetComparator,
        },
        {
            headerName: ls.getString("resourceName"),
            field: "resourceName",
            tooltipValueGetter: AgGridUtil.linkTooltipValueGetter,
            flex: 140,
            comparator: AgGridUtil.alphaBetComparator,
            cellRenderer: "nameCellRenderer",
        },
        {
            headerName: ls.getString("source"),
            field: "cloudProvider",
            flex: 60,
            filter: "agTextColumnFilter",
        },
        {
            headerName: ls.getString("resourceType"),
            field: "resourceType",
            flex: 60,
            comparator: AgGridUtil.alphaBetComparator,
        },
        {
            headerName: ls.getString("privateAddress"),
            field: "privateIp",
            flex: 80,
            cellRenderer: "privateAddressCellRenderer",
            comparator: AgGridUtil.alphaBetComparator,
            valueFormatter: AgGridUtil.nullableStringFormatter,
        },
        {
            headerName: ls.getString("account"),
            field: "account",
            flex: 60,
        },
    ]

    const statusFilterOptions: SelectItem[] = [
        {
            displayName: ls.getString("allStatuses"),
            value: CloudResourceStatus.ALL_STATUSES,
        },
        {
            displayName: ls.getString("discovered"),
            value: CloudResourceStatus.DISCOVERED,
        },
        {
            displayName: ls.getString("ignored"),
            value: CloudResourceStatus.IGNORED,
        },
        {
            displayName: ls.getString("published"),
            value: CloudResourceStatus.PUBLISHED,
        },
    ]

    const gridApi = useRef<GridApi>()
    const [error, setError] = useState<string>("")

    const onGridReady = (event: GridReadyEvent) => {
        gridApi.current = event.api
        fetchData()
    }

    const onStatusFilterChange = (filter: SelectItem) => (): void => {
        if (!gridApi.current) {
            return
        }

        gridApi.current.getFilterInstance("status")?.setModel({
            type: "equals",
            filter:
                !filter.value || filter.value === CloudResourceStatus.ALL_STATUSES
                    ? ""
                    : filter.value,
        })

        gridApi.current.onFilterChanged()

        setStatusFilterLabel(filter.displayName)
    }

    const onSourceFilterChange = (filter: SelectItem) => (): void => {
        if (!gridApi.current) {
            return
        }

        gridApi.current.getFilterInstance("cloudProvider")?.setModel({
            type: "equals",
            filter:
                !filter.value || filter.value === ls.getString("allSources") ? "" : filter.value,
        })

        gridApi.current.onFilterChanged()

        setSourceFilterLabel(filter.displayName)
    }

    const onFilter = (value: string): void => {
        gridApi.current?.setQuickFilter(value)
    }

    const fetchData = () => {
        if (gridApi.current) {
            setError("")
            gridApi.current.showLoadingOverlay()
            inventoryService.getCloudResources().then(
                (inventory) => {
                    if (gridApi.current) {
                        gridApi.current.hideOverlay()
                        gridApi.current.setRowData(inventory)
                    }
                },
                () => {
                    if (gridApi.current) {
                        gridApi.current.showNoRowsOverlay()
                    }
                    setError(ls.getString("errorCouldNotFetchCloudResourceInventory"))
                }
            )
        }

        userService.getEdition().then((edition: OrgEdition) => {
            setIsTeamEdition(edition === OrgEdition.TEAM)
        })
    }

    const onRefresh: React.MouseEventHandler<HTMLButtonElement> = (event) => {
        event.preventDefault()
        fetchData()
    }

    return isTeamEdition ? (
        <Container>
            <ContactUsScreen
                image={banyanRoute}
                title={ls.getString("upgradeForSomething", ls.getString("iaasInventory"))}
                docsLink={linkService.getLink("iaasDiscoveryDoc")}
            >
                <ContactUsScreenSteps
                    steps={[
                        {
                            icon: faShieldAlt,
                            label: ls.getString("providesAListOfAllDiscoveredIaasResourcesDesc"),
                        },
                        {
                            icon: faVectorSquare,
                            label: ls.getString("byConnectingTheIaasProvidersDesc"),
                        },
                        {
                            icon: faRocket,
                            label: ls.getString("itCanBeDifficultForAdminsDesc"),
                        },
                    ]}
                />
            </ContactUsScreen>
        </Container>
    ) : (
        <section className={styles.sectionContainer}>
            <header className={styles.header} aria-labelledby={Id.HEADING}>
                <PageHeading id={Id.HEADING}>{ls.getString("iaasDiscovery")}</PageHeading>
                <Tooltip title={ls.getString("refresh")}>
                    <Button
                        icon={IconType.REDO}
                        onClick={onRefresh}
                        asElement={ButtonElement.BUTTON}
                        buttonType={ButtonType.SECONDARY}
                    />
                </Tooltip>
            </header>
            <section className={styles.container}>
                {error && <ErrorBanner>{error}</ErrorBanner>}
                <Toolbar onSearchChange={onFilter}>
                    <MenuButton
                        className={styles.filterButton}
                        label={statusFilterLabel}
                        icon={faFilter}
                    >
                        {statusFilterOptions.map((filter: SelectItem) => (
                            <MenuItem onClick={onStatusFilterChange(filter)} key={filter.value}>
                                {filter.displayName}
                            </MenuItem>
                        ))}
                    </MenuButton>
                    <MenuButton
                        className={classNames(styles.filterButton, styles.menuButton)}
                        label={sourceFilterLabel}
                        icon={faFilter}
                    >
                        {inventoryService.getCloudProviderOptions().map((filter: SelectItem) => (
                            <MenuItem onClick={onSourceFilterChange(filter)} key={filter.value}>
                                {filter.displayName}
                            </MenuItem>
                        ))}
                    </MenuButton>
                </Toolbar>
                <div className={styles.gridContainer}>
                    <Grid
                        onGridReady={onGridReady}
                        columnDefs={columnDefs}
                        pagination
                        components={{
                            nameCellRenderer: NameCellRenderer,
                            statusCellRenderer: StatusCellRenderer,
                            privateAddressCellRenderer: PrivateAddressCellRenderer,
                        }}
                    />
                </div>
            </section>
        </section>
    )
}

function NameCellRenderer(props: CellRendererProps): JSX.Element {
    return props.data?.id ? (
        <Link to={formatRoutePath(ROUTE.IAAS_DISCOVERY_DETAILS, { id: encodeID(props.data.id) })}>
            {props.data.resourceName ? props.data.resourceName : props.data.resourceId}
        </Link>
    ) : (
        <span>{props.data.resourceName ? props.data.resourceName : props.data.resourceId}</span>
    )
}

function PrivateAddressCellRenderer(props: CellRendererProps): string {
    const inventoryService: InventoryService = useServiceInventory()
    return props.data ? inventoryService.getResourceAddress(props.data) : ""
}

enum Id {
    HEADING = "heading",
}

interface CellRendererProps {
    data: CloudResource
}
