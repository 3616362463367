import { ReactNode } from "react"

import { Bind } from "../../decorators/Bind.decorator"
import { LocalizationService } from "../../services/localization/Localization.service"
import { AppNavLink } from "../../services/shared/AppNavLink"
import { BaseMenu } from "../base-menu/BaseMenu.component"
import NavBarTemplate from "./NavBar.template"

export class NavBar extends BaseMenu<NavBarProps, NavBarState> {
    public state: NavBarState = {
        links: this.props.links,
        moreLinks: [],
        showMoreButton: true,
        showMenu: false,
    }

    public componentDidMount(): void {
        window.addEventListener("resize", this.onResize)

        for (let i = 0; i < this.navBarContainer.children.length; i++) {
            const child: HTMLElement = <HTMLElement>this.navBarContainer.children[i]
            this.childrenWidths.push(this.getWidth(child))
        }
        this.moreButtonWidth = this.moreButton.offsetWidth

        this.onResize()
    }

    public render(): ReactNode {
        return NavBarTemplate.call(this)
    }

    public componentWillUnmount(): void {
        window.removeEventListener("resize", this.onResize)
    }

    private localizationService: LocalizationService = new LocalizationService()

    private navBarContainer: HTMLElement
    private moreButton: HTMLElement
    private childrenWidths: number[] = []
    private moreButtonWidth: number

    @Bind
    private onResize(): void {
        const containerWidth: number = this.navBarContainer.offsetWidth
        let widthSum: number = 100 + this.moreButtonWidth
        this.state.links = []
        this.state.moreLinks = []
        for (let i = 0; i < this.props.links.length; i++) {
            widthSum += this.childrenWidths[i]
            if (widthSum < containerWidth) {
                this.state.links.push(this.props.links[i])
            } else {
                this.state.moreLinks.push(...this.getChildLinks(this.props.links[i]))
            }
        }
        // remove last divider
        if (this.state.moreLinks.length > 0) {
            this.state.moreLinks.pop()
        }
        this.setState({
            links: this.state.links,
            moreLinks: this.state.moreLinks,
            showMoreButton: this.state.moreLinks.length > 0,
            showMenu: false,
        })
    }

    private getWidth(element: HTMLElement): number {
        const style: CSSStyleDeclaration = window.getComputedStyle(element)
        const margin: number =
            parseFloat(<string>style.marginLeft) + parseFloat(<string>style.marginRight)
        let width: number = element.offsetWidth
        if (!isNaN(margin)) {
            width += margin
        }
        return width
    }

    private getChildLinks(link: AppNavLink): AppNavLink[] {
        const children: AppNavLink[] = []

        if (link.children && link.children.length > 0) {
            link.children.forEach((child: AppNavLink) => {
                if (!child.divider) {
                    children.push(child)
                }
            })
            children.push({ divider: true })
        } else if (!link.divider) {
            children.push(link)
            children.push({ divider: true })
        }

        return children
    }
}

interface NavBarProps {
    links: AppNavLink[]
}

interface NavBarState {
    links: AppNavLink[]
    moreLinks: AppNavLink[]
    showMoreButton: boolean
    showMenu: boolean
}
