import React from "react"

import { Input } from "../../../../../v3/components/input/Input.component"
import styles from "./TrustProviderDeviceManagerSettings.module.scss"
import { Select } from "../../../../components/select/Select.component"
import { DeviceManagerName } from "../../../../api/User.api"
import { ConfirmationDialog } from "../../../../components/dialog/confirmation/ConfirmationDialog.component"
import { AppText } from "../../../../../v3/components/app-text/AppText.component"
import {
    Form,
    FormColumns,
    FormLabel,
    ErrorBanner,
    InfoBanner,
    FormRow,
} from "../../../../components"
import {
    Button,
    ButtonElement,
    ButtonType,
} from "../../../../../components/button/Button.component"

export default function () {
    return (
        <>
            <Form display="grid" labelWidth={200} floating onSubmit={this.onSubmit}>
                <FormRow>
                    {this.state.error && <ErrorBanner>{this.state.error}</ErrorBanner>}
                    {this.state.success && <InfoBanner>{this.state.success}</InfoBanner>}
                </FormRow>
                <FormColumns
                    right={
                        <div className={styles.formColumn}>
                            <h2>{this.localizationService.getString("enterpriseDeviceManager")}</h2>
                            <AppText
                                ls={{
                                    key: "enterpriseDeviceManagerExplanation",
                                    replaceVals: [this.linkService.getLink("deviceManagerDocs")],
                                }}
                            />
                        </div>
                    }
                />
                <FormLabel
                    title={this.localizationService.getString("deviceManagerName")}
                    htmlFor="deviceManagerName"
                >
                    <Select
                        id="deviceManagerName"
                        name="deviceManagerName"
                        required
                        value={this.state.deviceManagerName}
                        options={this.deviceManagerOptions}
                        onChange={this.onDeviceManagerChange}
                        disabled={!this.props.canUpdateTrustProviderDeviceManagerSetting}
                    />
                </FormLabel>
                {this.state.deviceManagerName === DeviceManagerName.AIRWATCH && (
                    <>
                        <FormColumns
                            right={
                                <h3>
                                    {this.localizationService.getString("deviceManagerApiConfig")}
                                </h3>
                            }
                        />
                        <FormLabel
                            title={this.localizationService.getString("apiHostUrl")}
                            htmlFor="apiHostUrl"
                        >
                            <Input
                                id="apiHostUrl"
                                name="apiHostUrl"
                                defaultValue={this.state.deviceManagerConfig.URL}
                                className={styles.formInput}
                                required
                                placeholder={this.localizationService.getString("apiHostUrl")}
                                disabled={!this.props.canUpdateTrustProviderDeviceManagerSetting}
                            />
                        </FormLabel>
                        <FormLabel
                            title={this.localizationService.getString("username")}
                            htmlFor="username"
                        >
                            <Input
                                id="username"
                                name="username"
                                defaultValue={this.state.deviceManagerConfig.Username}
                                className={styles.formInput}
                                required
                                placeholder={this.localizationService.getString("username")}
                                disabled={!this.props.canUpdateTrustProviderDeviceManagerSetting}
                            />
                        </FormLabel>
                        <FormLabel
                            title={this.localizationService.getString("password")}
                            htmlFor="password"
                        >
                            <Input
                                id="password"
                                name="password"
                                defaultValue={this.state.deviceManagerConfig.Password}
                                className={styles.formInput}
                                type="password"
                                required
                                placeholder={this.localizationService.getString("password")}
                                disabled={!this.props.canUpdateTrustProviderDeviceManagerSetting}
                            />
                        </FormLabel>
                        <FormLabel
                            title={this.localizationService.getString("apiKey")}
                            htmlFor="apiKey"
                        >
                            <Input
                                id="apiKey"
                                name="apiKey"
                                defaultValue={this.state.deviceManagerConfig.APIkey}
                                className={styles.formInput}
                                required
                                placeholder={this.localizationService.getString("apiKey")}
                                disabled={!this.props.canUpdateTrustProviderDeviceManagerSetting}
                            />
                        </FormLabel>
                        <FormLabel
                            title={this.localizationService.getString("failMode")}
                            htmlFor="failMode"
                        >
                            <Select
                                id="failMode"
                                name="failMode"
                                value={this.state.failMode}
                                options={this.failModeOptions}
                                onChange={this.onFailModeChange}
                                disabled={!this.props.canUpdateTrustProviderDeviceManagerSetting}
                            />
                        </FormLabel>
                    </>
                )}
                {this.props.canUpdateTrustProviderDeviceManagerSetting &&
                    this.state.deviceManagerName !== DeviceManagerName.CERT_ONLY && (
                        <FormColumns
                            right={
                                <Button
                                    buttonType={ButtonType.PRIMARY}
                                    asElement={ButtonElement.BUTTON}
                                    loading={this.state.loading}
                                    type="submit"
                                >
                                    {this.localizationService.getString("updateApiConfig")}
                                </Button>
                            }
                        />
                    )}
                <ConfirmationDialog
                    open={this.state.showConfirmDialog}
                    title={this.localizationService.getString("deviceManagerApiConfig")}
                    confirmLabel={this.localizationService.getString("continue")}
                    onClose={this.onHideConfirmDialog}
                    onConfirm={this.onUpdateDeviceManager}
                >
                    <p>{this.localizationService.getString("deviceManagerConfirmDescription")}</p>
                </ConfirmationDialog>
            </Form>
            {this.state.deviceManagerName !== DeviceManagerName.NONE && (
                <Form display="grid" floating labelWidth={200} onSubmit={this.onSubmitDeviceCert}>
                    <FormColumns
                        right={
                            <h3>{this.localizationService.getString("deviceCertificateConfig")}</h3>
                        }
                    />
                    <FormLabel
                        title={this.localizationService.getString("certRootCa")}
                        htmlFor="certRootCa"
                    >
                        <Input
                            id="certRootCa"
                            name="certRootCa"
                            defaultValue={this.state.deviceManagerConfig.RootCA}
                            className={styles.formInput}
                            required
                            placeholder={this.localizationService.getString("certRootCa")}
                            disabled={!this.props.canUpdateTrustProviderDeviceManagerSetting}
                        />
                    </FormLabel>
                    <FormLabel
                        title={this.localizationService.getString("certCommonName")}
                        htmlFor="certCommonName"
                    >
                        <Input
                            id="certCommonName"
                            name="certCommonName"
                            defaultValue={this.state.deviceManagerConfig.CNFormat}
                            className={styles.formInput}
                            required
                            placeholder={this.localizationService.getString("certCommonName")}
                            disabled={!this.props.canUpdateTrustProviderDeviceManagerSetting}
                        />
                    </FormLabel>
                    {this.props.canUpdateTrustProviderDeviceManagerSetting && (
                        <FormColumns
                            right={
                                <Button
                                    buttonType={ButtonType.PRIMARY}
                                    asElement={ButtonElement.BUTTON}
                                    loading={this.state.loading}
                                    type="submit"
                                >
                                    {this.localizationService.getString("updateDeviceCert")}
                                </Button>
                            }
                        />
                    )}
                </Form>
            )}
        </>
    )
}
