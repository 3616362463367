import {
    ColDef,
    GridApi,
    GridReadyEvent,
    ValueFormatterParams,
    RowDoubleClickedEvent,
    GetRowIdParams,
    ICellRendererParams,
} from "ag-grid-community"
import React, { ReactNode } from "react"
import { RouteComponentProps } from "react-router"

import { ROUTE, formatRoutePath } from "../../../../../routes"
import { Profile } from "../../../../../utils/profile.utils"
import { AccountType } from "../../../../../v3/services/UserProfile.service"
import { UserOrgDetails } from "../../../../api/User.api"
import { Bind } from "../../../../decorators/Bind.decorator"
import { LocalizationService } from "../../../../services/localization/Localization.service"
import ActionBarService from "../../../../services/ActionBar.service"
import { AdminUser, OrgEdition, UserService } from "../../../../services/User.service"
import AgGridUtil from "../../../../utils/AgGrid.util"
import { encodeID } from "../../../../utils/Url.util"
import OrgAdminSettingsTemplate from "./OrgAdminSettings.template"
import { OrgAdminCellRenderer } from "./cell-renderer/OrgAdminCellRenderer.component"

interface Props extends RouteComponentProps {
    canAdd: boolean
}

export class OrgAdminSettings extends React.Component<Props, OrgAdminSettingsState> {
    public state: OrgAdminSettingsState = {
        userCanAddAdmin: false,
        edition: OrgEdition.ENTERPRISE,
        isMspOrg: false,
        isBanyanIdp: false,
        addPath: ROUTE.ADMINS_ADD,
        detailsPath: ROUTE.ADMINS_DETAILS,
    }

    public render(): ReactNode {
        return OrgAdminSettingsTemplate.call(this)
    }

    public componentDidMount(): void {
        this.actionBarService.setItems(
            this.localizationService.getString("manageAdmins"),
            [
                {
                    label: this.localizationService.getString("adminSettings"),
                },
            ],
            this.fetchData
        )
    }

    public componentWillUnmount(): void {
        this.actionBarService.unregisterRefreshFn(this.fetchData)
    }

    private localizationService: LocalizationService = new LocalizationService()
    private actionBarService: ActionBarService = new ActionBarService()
    private userService: UserService = new UserService()

    private gridApi: GridApi

    private columns: ColDef[] = [
        {
            headerName: this.localizationService.getString("email"),
            field: "email",
            tooltipValueGetter: AgGridUtil.linkTooltipValueGetter,
            width: 100,
            cellRenderer: (props: ICellRendererParams<AdminUser>) => (
                <OrgAdminCellRenderer {...props} path={this.state.detailsPath} />
            ),
            comparator: AgGridUtil.alphaBetComparator,
        },
        {
            headerName: this.localizationService.getString("name"),
            field: "name",
            width: 70,
            comparator: AgGridUtil.alphaBetComparator,
        },
        {
            headerName: this.localizationService.getString("type"),
            field: "accountType",
            width: 50,
            valueFormatter: this.accountTypeFormatter,
        },
        {
            headerName: this.localizationService.getString("profile"),
            field: "profile",
            width: 50,
            cellRenderer: (data: GetRowIdParams<AdminUser>) =>
                getProfileName(data.data, this.state.isMspOrg),
        },
        {
            headerName: this.localizationService.getString("status"),
            field: "registered",
            width: 50,
            valueFormatter: this.statusFormatter,
        },
    ]

    @Bind
    private accountTypeFormatter(params: ValueFormatterParams): string {
        return params.value === "LOCAL"
            ? this.localizationService.getString("local")
            : this.localizationService.getString("samlOnly")
    }

    @Bind
    private statusFormatter(params: ValueFormatterParams): string {
        return params.value
            ? this.localizationService.getString("verified")
            : this.localizationService.getString("invited")
    }

    @Bind
    private onAddAdmin(): void {
        this.props.history.push(this.state.addPath)
    }

    @Bind
    private onGridReady(event: GridReadyEvent): void {
        this.gridApi = event.api
        this.fetchData()
    }

    @Bind
    private onFilter(value: string): void {
        if (this.gridApi) {
            this.gridApi.setQuickFilter(value)
        }
    }

    @Bind
    private onRowDoubleClicked(params: RowDoubleClickedEvent): void {
        this.props.history.push(
            formatRoutePath(this.state.detailsPath, { id: encodeID(params.data.email) })
        )
    }

    @Bind
    private async fetchData() {
        if (this.gridApi) {
            this.gridApi.showLoadingOverlay()
            const [admins, isBanyanIdp, userOrgDetails] = await Promise.all([
                this.userService.getAdmins(),
                this.userService.getIsBanyanIdp(),
                this.userService.getUserOrgDetails(true),
            ])
            this.setState({ isBanyanIdp })
            this.setState({ isMspOrg: userOrgDetails.IsMSPOrg })

            const addPath = getAddPath(userOrgDetails)
            const detailsPath = getDetailsPath(userOrgDetails)
            this.setState({ addPath })
            this.setState({ detailsPath })

            if (
                userOrgDetails.AccountType === AccountType.LOCAL ||
                userOrgDetails.Profile === Profile.OWNER
            ) {
                this.setState({ userCanAddAdmin: true })
            }

            this.gridApi.setRowData(admins)
            this.gridApi.hideOverlay()
        }
    }
}

interface OrgAdminSettingsState {
    userCanAddAdmin: boolean
    edition: OrgEdition
    isMspOrg: boolean
    isBanyanIdp: boolean
    addPath: ROUTE
    detailsPath: ROUTE
}

function getProfileName(data: AdminUser, isMspOrg: boolean): string {
    const localization = new LocalizationService()
    if (data.profile === Profile.READONLY && isMspOrg) {
        return localization.getString("operator")
    } else {
        return data.profile
    }
}

function getAddPath(userOrgDetails: UserOrgDetails) {
    if (userOrgDetails.IsMSPOrg) return ROUTE.MSP_MANAGE_ADMIN_ADD
    if (userOrgDetails.Profile === Profile.SUPER_ADMIN || userOrgDetails.Profile === Profile.MOM)
        return ROUTE.MOM_MANGE_ADMINS_ADD
    return ROUTE.ADMINS_ADD
}

function getDetailsPath(userOrgDetails: UserOrgDetails) {
    if (userOrgDetails.IsMSPOrg) return ROUTE.MSP_MANAGE_ADMIN_DETAILS
    if (userOrgDetails.Profile === Profile.SUPER_ADMIN || userOrgDetails.Profile === Profile.MOM)
        return ROUTE.MOM_MANGE_ADMINS_DETAILS
    return ROUTE.ADMINS_DETAILS
}
