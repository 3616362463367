import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import React from "react"

import { useServiceLocalization } from "../../../../pre-v3/services/localization/Localization.service"
import { FilterModel } from "../../../../pre-v3/utils/AgGrid.util"
import { DateUtil } from "../../../../pre-v3/utils/Date.util"
import { encodeID } from "../../../../pre-v3/utils/Url.util"
import { ROUTE, formatRoutePath } from "../../../../routes"
import { Device, DeviceService } from "../../../services/Device.service"
import { platformIconDict, platformLabelDict } from "../../../services/shared/Platform"
import { Column, Grid, GridApi, sortWithServerSide } from "../../../components/grid/Grid.component"
import { RowTitle } from "../../../components/grid/RowTitle.component"
import styles from "./DeviceGrid.module.scss"
import {
    TrustLevelCellRenderer,
    getTrustLevelProps,
    trustLevelClassName,
} from "./TrustLevelCellRenderer.component"
import { FilterKey, allKeys } from "./FilterKey"

interface Props {
    hasMigrated: boolean
    filterModel: FilterModel
    onDevicesSelected(devices: Device[]): void
}

function DeviceGridComponent(props: Props, ref: React.ForwardedRef<GridApi>): JSX.Element {
    const deviceService = new DeviceService()

    const columns = useColumns(props.hasMigrated)

    return (
        <Grid
            columns={columns}
            onDataSelected={props.onDevicesSelected}
            ref={ref}
            hasPagination
            serverSideProps={{
                getServerSideData: (params) => deviceService.getDevices(params),
                filterModel: props.filterModel,
                filterableKeys: allKeys,
            }}
        />
    )
}

export const DeviceGrid = React.forwardRef<GridApi, Props>(DeviceGridComponent)

function useColumns(hasMigrated: boolean): Column<Device>[] {
    const localization = useServiceLocalization()

    return React.useMemo<Column<Device>[]>(() => {
        const trustLevelColumn: Column<Device> = {
            id: FilterKey.TRUST_LEVEL,
            name: localization.getString("trustLevel"),
            cellRenderer: (device) => <TrustLevelCellRenderer status={device.status} />,
            cellClassName: trustLevelClassName,
            getTooltipValue: (device) => {
                const [labelKey] = getTrustLevelProps(device.status)
                return localization.getString(labelKey)
            },
            isFilterable: true,
        }

        const nameColumn: Column<Device> = {
            id: FilterKey.NAME,
            name: localization.getString("device"),
            cellRenderer: renderDeviceName,
            getTooltipValue: "name",
            isFilterable: true,
            sorting: sortWithServerSide("asc"),
        }

        const platformColumn: Column<Device> = {
            id: FilterKey.PLATFORM,
            name: localization.getString("os"),
            cellRenderer: renderDevicePlatform,
            getTooltipValue: (device) => localization.getString(platformLabelDict[device.platform]),
            isFilterable: true,
            width: 80,
            sorting: sortWithServerSide(),
        }

        const trustProfileColumn: Column<Device> = {
            id: FilterKey.TRUST_PROFILE,
            name: localization.getString("trustProfile"),
            cellRenderer: renderDeviceTrustProfile,
            getTooltipValue: (device) =>
                device.trustProfile?.name ??
                localization.getString("noTrustProfileMatchesThisDevice"),
            isFilterable: true,
        }

        const lastLoginColumn: Column<Device> = {
            id: FilterKey.LAST_LOGIN,
            name: localization.getString("lastLogin"),
            cellRenderer: formatLastLogin,
            getTooltipValue: formatLastLogin,
            isFilterable: true,
            sorting: sortWithServerSide(),
        }

        return [
            trustLevelColumn,
            nameColumn,
            platformColumn,
            ...(hasMigrated ? [trustProfileColumn] : []),
            lastLoginColumn,
        ]
    }, [hasMigrated, localization])
}

function renderDeviceName(device: Device): JSX.Element {
    return (
        <RowTitle
            title={device.name}
            route={formatRoutePath(ROUTE.DEVICES_DETAILS, {
                serialNumber: encodeID(device.serialNumber),
            })}
        />
    )
}

function renderDevicePlatform(device: Device): JSX.Element {
    return (
        <FontAwesomeIcon icon={platformIconDict[device.platform]} className={styles.platformIcon} />
    )
}

function renderDeviceTrustProfile(device: Device): React.ReactNode {
    if (!device.trustProfile) return "--"

    return (
        <RowTitle
            title={device.trustProfile.name}
            route={formatRoutePath(ROUTE.PROFILES_DETAILS, {
                id: encodeID(device.trustProfile.id),
            })}
        />
    )
}

function formatLastLogin(device: Device): string {
    return DateUtil.format(device.lastLoginAt)
}
