import { faPen, faPlus, faTrash } from "@fortawesome/pro-regular-svg-icons"
import { ColDef, ColumnApi, GridReadyEvent, ICellRendererParams } from "ag-grid-community"
import classNames from "classnames"
import React, { ReactNode, useRef, useState } from "react"
import { Grid } from "../../../../../pre-v3/components/grid/Grid.component"
import { LinkService, ModalService } from "../../../../../pre-v3/services"
import { LocalizationService } from "../../../../../pre-v3/services/localization/Localization.service"
import { Accordion } from "../../../../components/accordion/Accordion.component"
import { AppText } from "../../../../components/app-text/AppText.component"
import { Button } from "../../../../components/button/Button.component"
import { FormRow } from "../../../../components/form/FormRow.component"
import { IconButton } from "../../../../components/icon-button/IconButton.component"
import { ServiceExemption, WebService } from "../../../../services/HostedService.service"
import styles from "./AccessPermissions.module.scss"
import { AddEditExemptionModal } from "./AddEditExemption.component"

interface Props {
    initialValue?: WebService
    onChange?: (data: ServiceExemption[]) => void
    disabled?: boolean
}

export function Exemptions(props: Props) {
    const { initialValue, disabled, onChange } = props
    const localization = new LocalizationService()
    const linkService = new LinkService()
    const modalService = new ModalService()

    const [totalExemptions, setTotalExemptions] = useState<number>(
        initialValue?.exemptions?.length || 0
    )
    const [isExemptionOpen, setIsExemptionOpen] = useState(totalExemptions > 0 || false)
    const [exemptionData, setExemptionData] = useState<ServiceExemption[]>(
        initialValue?.exemptions || []
    )

    function onOpenAddExemption(
        exemption?: ServiceExemption,
        isEdit?: boolean,
        index?: number
    ): void {
        modalService
            .open(localization.getString("addEditExemption"), {
                component: AddEditExemptionModal,
                props: {
                    serviceExemption: exemption,
                },
                maxWidth: "lg",
            })
            .onClose((data) => {
                if (isEdit && index) {
                    onUpdate(data, index)
                } else {
                    onAdd(data)
                }
            })
    }
    const gridColumnApi = useRef<ColumnApi>()
    const columnDefs: ColDef[] = [
        {
            headerName: localization.getString("summary"),
            field: "data",
            cellRenderer: SummaryCellRenderer,
            flex: 150,
        },
        {
            headerName: "",
            field: "data",
            cellRenderer: ExemptionActions,
            sortable: false,
            flex: 50,
        },
    ]

    function onChangeData(data: ServiceExemption[]) {
        onChange?.(data)
    }

    function onAdd(exemption: ServiceExemption): void {
        setExemptionData((prev: ServiceExemption[]) => {
            const newExemptions: ServiceExemption[] = [...prev, exemption]
            setTotalExemptions(newExemptions.length)
            onChangeData(newExemptions)
            return newExemptions
        })
    }

    function onRemove(exemption: ServiceExemption, index: number): void {
        setExemptionData((prev: ServiceExemption[]) => {
            const updatedExemptions: ServiceExemption[] = prev.filter((item, d) => d !== index)
            setTotalExemptions(updatedExemptions.length)
            onChangeData(updatedExemptions)
            return updatedExemptions
        })
    }

    function onUpdate(exemption: ServiceExemption, index: number): void {
        setExemptionData((prev: ServiceExemption[]) => {
            const updatedExemptions: ServiceExemption[] = prev.map((item, i) =>
                i + 1 === index ? exemption : item
            )
            setTotalExemptions(updatedExemptions.length)
            onChangeData(updatedExemptions)
            return updatedExemptions
        })
    }

    function ExemptionActions(params: ICellRendererParams<ServiceExemption>) {
        const data: ServiceExemption | undefined = params.data
        if (props.disabled || !data) {
            return <></>
        }
        return (
            <div className={styles.exemptionActions}>
                <IconButton
                    className={styles.actionButton}
                    action
                    icon={faPen}
                    onClick={() => onOpenAddExemption(data, true, params.rowIndex + 1)}
                    aria-label={localization.getString("addEditExemption")}
                />
                <IconButton
                    className={styles.actionButton}
                    action
                    aria-label="Remove"
                    icon={faTrash}
                    onClick={() => onRemove(data, params.rowIndex)}
                />
            </div>
        )
    }

    return (
        <Accordion
            open={isExemptionOpen}
            disabled={disabled}
            label={localization.getString("exemptionsOptional")}
            onToggle={setIsExemptionOpen}
            children={
                <div className={styles.container}>
                    <FormRow
                        label={localization.getString("exemptions")}
                        description={
                            <AppText
                                ls={{
                                    key: "exemptSpecificRequestsDescription",
                                    replaceVals: [linkService.getLink("docSite")],
                                }} //TODO: update with actual link
                            />
                        }
                    >
                        <Button
                            onClick={() => onOpenAddExemption()}
                            icon={faPlus}
                            disabled={props.disabled}
                        >
                            {localization.getString("addExemption")}
                        </Button>
                    </FormRow>
                    <Grid
                        onGridReady={(event: GridReadyEvent) => {
                            gridColumnApi.current = event.columnApi
                        }}
                        className={classNames({ [styles.hiddenGrid]: totalExemptions < 1 })}
                        autoHeight
                        rowData={exemptionData}
                        columnDefs={columnDefs}
                    />
                </div>
            }
        />
    )
}

function SummaryCellRenderer(params: ICellRendererParams<ServiceExemption>): ReactNode {
    const exemption = params.data
    if (!exemption) {
        return ""
    }
    return <AppText className={styles.textOverflow}>{getExemptionSummary(exemption)}</AppText>
}

function getExemptionSummary(exemption: ServiceExemption): string {
    const localization: LocalizationService = new LocalizationService()
    // too difficult to localize
    const summary: string = localization.getString(
        "exemptionTemplate",
        isEnumeratedMethods(exemption.methods) ? `**${joinAndTruncate(exemption.methods)}** ` : "",
        exemption.sourceCidrs.length > 0
            ? `from **${joinAndTruncate(exemption.sourceCidrs)}** `
            : "",
        isEnumerated(exemption.origin)
            ? `with origin header **${joinAndTruncate(exemption.origin)}** `
            : "",
        isEnumeratedPaths(exemption.paths)
            ? `to path(s) **${joinAndTruncate(exemption.paths)}** `
            : "",
        isEnumerated(exemption.target) ? `targeting **${joinAndTruncate(exemption.target)}** ` : "",
        isEnumerated(exemption.mandatoryHeaders)
            ? `with **${joinAndTruncate(exemption.mandatoryHeaders)}** headers `
            : ""
    )
    return summary
}

function isEnumerated(arr: string[]): boolean {
    return arr.length > 0 && !arr.includes("*")
}

function isEnumeratedPaths(arr: string[]): boolean {
    return arr.length > 0 && !arr.includes("/*")
}

function isEnumeratedMethods(arr: string[]): boolean {
    return arr.length < 6 && !arr.includes("*")
}

function joinAndTruncate(arr: string[]): string {
    const str = arr.join(", ")
    if (str.length > 32) {
        return str.slice(0, 32) + "..."
    } else {
        return str
    }
}
