import React, { ReactNode } from "react"
import ModalBaseTemplate from "./ModalBase.template"
import { Bind } from "../../../decorators/Bind.decorator"
import { ModalService, ModalRef } from "../../../services/Modal.service"

export class ModalBase extends React.Component<ModalBaseProps, {}> {
    public render(): ReactNode {
        return ModalBaseTemplate.call(this)
    }

    private modalService: ModalService = new ModalService()

    @Bind
    private onCancel(): void {
        this.modalService.cancel(this.props.modalRef.id)
    }
}

interface ModalBaseProps {
    modalRef: ModalRef
}
