import React from "react"
import { Redirect, Route, RouteComponentProps, Switch } from "react-router-dom"

import { useFeatureFlags } from "../../../hooks/useFeatureFlags.hook"
import useTitle from "../../../hooks/useTitle.hook"
import { OrgAdminAdd as AdminsAdd } from "../../../pre-v3/views/settings/org/admin/add/OrgAdminAdd.component"
import { OrgAdminDetail as AdminsDetails } from "../../../pre-v3/views/settings/org/admin/detail/OrgAdminDetail.component"
import { OrgAdminSettings as AdminsList } from "../../../pre-v3/views/settings/org/admin/OrgAdminSettings.component"
import { ROUTE } from "../../../routes"
import { Loader } from "../../../v3/components/loader/Loader.component"

export function Admins() {
    useTitle(["admins", "directory", "adminConsole"])

    const { data: featureFlags } = useFeatureFlags()

    if (!featureFlags) return <Loader center medium />

    return (
        <Switch>
            {featureFlags.adminConsole.canAddAdmin && (
                <Route path={ROUTE.ADMINS_ADD} component={AdminsAdd} />
            )}
            <Route
                path={ROUTE.ADMINS_DETAILS}
                component={(props: RouteComponentProps<{ id: string }>) => (
                    <AdminsDetails
                        canDelete={featureFlags.adminConsole.canDeleteAdmin}
                        canUpdate={featureFlags.adminConsole.canUpdateAdmin}
                        {...props}
                    />
                )}
            />
            <Route
                path={ROUTE.ADMINS}
                component={(props: RouteComponentProps) => (
                    <AdminsList canAdd={featureFlags.adminConsole.canAddAdmin} {...props} />
                )}
            />
            <Redirect to={ROUTE.ADMINS} />
        </Switch>
    )
}
