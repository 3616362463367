import React, { ReactNode, SyntheticEvent } from "react"

import { StringUtil } from "../../utils/String.util"
import TransferListTemplate from "./TransferList.template"
import { Bind } from "../../decorators/Bind.decorator"
import { LocalizationService } from "../../services/localization/Localization.service"
import { SelectItem, SelectValue, SelectValueUtil } from "../../utils/SelectValue.util"

export class TransferList extends React.Component<TransferListProps, TransferListState> {
    public state: TransferListState = {
        available: [],
        selected: [],

        availableFilter: "",
        selectedFilter: "",

        availableFilteredList: [],
        selectedFilteredList: [],
    }

    public componentDidMount(): void {
        this.init()
    }

    public componentDidUpdate(prevProps: TransferListProps) {
        if (prevProps.options !== this.props.options || prevProps.value !== this.props.value) {
            this.init()
        }
    }

    public render(): ReactNode {
        return TransferListTemplate.call(this)
    }

    private ls: LocalizationService = new LocalizationService()

    private init(): void {
        if (Array.isArray(this.props.value) || this.props.value === undefined) {
            const values: string[] = this.props.value || []
            const available: SelectValue[] = []
            const selected: SelectValue[] = []

            if (this.props.options && this.props.options.length > 0) {
                this.props.options.forEach((option: SelectValue) => {
                    if (SelectValueUtil.stringArrayIncludes(values, option)) {
                        selected.push(option)
                    } else {
                        available.push(option)
                    }
                })
            }

            this.setState({
                available,
                selected,
                availableFilteredList: available.filter(
                    (option: SelectValue) =>
                        this.state.availableFilter.length === 0 ||
                        StringUtil.caseInsensitiveIncludes(
                            SelectValueUtil.getSerializedDisplayName(option),
                            this.state.availableFilter
                        )
                ),
                selectedFilteredList: selected.filter(
                    (option: SelectValue) =>
                        this.state.selectedFilter.length === 0 ||
                        StringUtil.caseInsensitiveIncludes(
                            SelectValueUtil.getSerializedDisplayName(option),
                            this.state.selectedFilter
                        )
                ),
            })
        } else {
            throw new Error("Multi-select component value must be an array")
        }
    }

    private validate(): void {
        if (this.props.options && this.props.options.length > 0) {
            const first: SelectValue = this.props.options[0]
            let invalid: SelectValue | undefined
            if (SelectValueUtil.isSelectItem(first)) {
                invalid = this.props.options.find((o: SelectValue) => !(o as SelectItem).value)
            } else {
                invalid = this.props.options.find((o: SelectValue) => !o)
            }
            if (invalid) {
                throw new Error("Select options must not have a falsy value")
            }
        }
    }

    private onSelect(item: SelectValue): void {
        if (this.props.disabled) {
            return
        }

        if (this.props.onChange) {
            const values: string[] = [...(this.props.value as string[])]
            this.props.onChange(values.concat([SelectValueUtil.getSerializedValue(item)]))
        }
    }

    private onDeselect(item: SelectValue): void {
        if (this.props.disabled) {
            return
        }
        if (this.props.onChange) {
            const copy: string[] = [...(this.props.value as string[])]
            const idx: number = SelectValueUtil.findIndexInStringArr(copy, item)

            if (idx >= 0) {
                copy.splice(idx, 1)
                this.props.onChange(copy)
            }
        }
    }

    @Bind
    private onSelectAll(): void {
        if (
            this.props.disabled ||
            !this.props.onChange ||
            !this.state.availableFilteredList.length
        ) {
            return
        }

        const result: string[] = Array.from(
            new Set(this.state.selected.concat(this.state.availableFilteredList))
        ).map((item) => SelectValueUtil.getSerializedValue(item))

        this.props.onChange(result)
    }

    @Bind
    private onDeselectAll(): void {
        if (
            this.props.disabled ||
            !this.props.onChange ||
            !this.state.selectedFilteredList.length
        ) {
            return
        }

        const result: string[] = this.state.selected
            .filter(
                (item) =>
                    !StringUtil.caseInsensitiveIncludes(
                        SelectValueUtil.getSerializedDisplayName(item),
                        this.state.selectedFilter
                    )
            )
            .map((item) => SelectValueUtil.getSerializedValue(item))

        this.props.onChange(result)
    }

    private onFilterChange(isChangingAvailableList: boolean, e: SyntheticEvent): void {
        const value: string = (e.target as HTMLInputElement).value
        const list: SelectValue[] = isChangingAvailableList
            ? this.state.available
            : this.state.selected
        const filteredListName: string = isChangingAvailableList
            ? "availableFilteredList"
            : "selectedFilteredList"

        if (isChangingAvailableList) {
            this.setState({
                availableFilter: value,
            })
        } else {
            this.setState({
                selectedFilter: value,
            })
        }

        const updatedList = {
            [filteredListName]: list.filter((item: SelectValue) =>
                StringUtil.caseInsensitiveIncludes(
                    SelectValueUtil.getSerializedDisplayName(item),
                    value
                )
            ),
        }

        this.setState(Object.assign(updatedList))
    }

    private onFilterClear(isChangingAvailable: boolean): void {
        const filteredListName: string = isChangingAvailable
            ? "availableFilteredList"
            : "selectedFilteredList"

        if (isChangingAvailable) {
            this.setState({
                availableFilter: "",
                availableFilteredList: this.state.available,
            })
        } else {
            this.setState({
                selectedFilter: "",
                selectedFilteredList: this.state.selected,
            })
        }
    }
}

interface TransferListProps {
    id?: string // For use as a FormControl
    name?: string // For use as a FormControl
    value: string[]
    options: SelectValue[]
    required?: boolean
    disabled?: boolean
    className?: string
    onChange: (selected: string[]) => void
}

interface TransferListState {
    available: SelectValue[]
    selected: SelectValue[]

    availableFilter: string
    selectedFilter: string

    availableFilteredList: SelectValue[]
    selectedFilteredList: SelectValue[]
}
