import React, { ReactNode, SyntheticEvent } from "react"
import { RouteComponentProps } from "react-router"

import { Profile } from "../../../../../../utils/profile.utils"
import { resetMultiFactorAuthentication } from "../../../../../../v3/services/AdminSettings.service"
import { AccountType } from "../../../../../../v3/services/UserProfile.service"
import { UserApi, UserOrgDetails } from "../../../../../api/User.api"
import { Bind } from "../../../../../decorators/Bind.decorator"
import ActionBarService from "../../../../../services/ActionBar.service"
import { LocalizationService } from "../../../../../services/localization/Localization.service"
import { ModalService } from "../../../../../services/Modal.service"
import { AdminUser, UserService } from "../../../../../services/User.service"
import { UrlUtil, decodeID } from "../../../../../utils/Url.util"
import OrgAdminDetailTemplate from "./OrgAdminDetail.template"
import { SelectItem } from "../../../../../utils/SelectValue.util"

export class OrgAdminDetail extends React.Component<Props, OrgAdminDetailState> {
    constructor(props: Props) {
        super(props)

        try {
            this.email = decodeID(this.props.match.params.id)
        } catch {
            this.state.loading = false
        }
    }

    public state: OrgAdminDetailState = {
        loading: true,
        showDeleteDialog: false,
        userIsOwner: false,
        showResetMfa: false,
        isMspOrg: false,
    }

    public render(): ReactNode {
        return OrgAdminDetailTemplate.call(this)
    }

    public componentDidMount(): void {
        this.actionBarService.setItems(
            this.email || this.localizationService.getString("adminSettings"),
            [
                {
                    label: this.localizationService.getString("adminSettings"),
                },
                {
                    label: this.localizationService.getString("manageAdmins"),
                    href: UrlUtil.getParentPath(this.props.location.pathname),
                },
            ],
            this.fetchData
        )

        this.fetchData()
    }

    public componentWillUnmount(): void {
        this.actionBarService.unregisterRefreshFn(this.fetchData)
    }

    private localizationService: LocalizationService = new LocalizationService()
    private actionBarService: ActionBarService = new ActionBarService()
    private modalService: ModalService = new ModalService()
    private userService: UserService = new UserService()
    private userApi: UserApi = new UserApi()

    private email: string
    private orgName: string

    private profileOptions: string[] = [
        Profile.READONLY,
        Profile.ADMIN,
        Profile.SERVICE_AUTHOR,
        Profile.POLICY_AUTHOR,
    ]

    private mspProfileOptions: SelectItem[] = [
        {
            displayName: this.localizationService.getString("admin"),
            value: Profile.ADMIN,
        },
        {
            displayName: this.localizationService.getString("operator"),
            value: Profile.READONLY,
        },
    ]

    @Bind
    private onProfileChange(value: Profile): void {
        if (this.state.admin) {
            this.state.admin.profile = value
            this.setState({ admin: this.state.admin })
        }
    }

    @Bind
    private onResetMfa(event: React.MouseEvent): void {
        event.preventDefault()

        const { admin, showResetMfa } = this.state

        if (!admin || !showResetMfa) return

        this.modalService
            .openConfirmation(
                this.localizationService.getString("resetMultiFactorAuthentication"),
                this.localizationService.getString("resetMultiFactorAuthenticationMessage")
            )
            .onClose(async () => {
                this.setState({ submitting: true, success: "", error: "" })

                // TODO: When we update this component, we should distinguish between
                // if the reset failed because a reset already happened or if it failed
                // for some other reason.
                try {
                    await resetMultiFactorAuthentication(admin.email)
                } finally {
                    this.setState({
                        submitting: false,
                        success: this.localizationService.getString("resetSuccessful"),
                    })
                }
            })
    }

    @Bind
    private onSubmit(event: SyntheticEvent): void {
        event.preventDefault()
        if (this.state.admin) {
            this.setState({ submitting: true, success: "", error: "" })
            this.userApi
                .updateAdmin(this.state.admin.email, this.state.admin.profile, this.orgName)
                .then(
                    () => {
                        this.setState({
                            submitting: false,
                            success: this.localizationService.getString("userUpdateSuccessful"),
                        })
                    },
                    (err: Error) => {
                        this.setState({ submitting: false, error: err.message })
                    }
                )
        }
    }

    @Bind
    private onShowDeleteDialog(): void {
        this.setState({ showDeleteDialog: true })
    }

    @Bind
    private onHideDeleteDialog(): void {
        this.setState({ showDeleteDialog: false })
    }

    @Bind
    private onDelete(): void {
        if (this.state.admin) {
            this.setState({ error: "", success: "", showDeleteDialog: false })
            this.userApi.deleteAdmin(this.state.admin.email).then(
                () => {
                    this.props.history.push(UrlUtil.getParentPath(this.props.location.pathname))
                    return
                },
                (err: Error) => {
                    this.setState({ error: err.message })
                }
            )
        }
    }

    @Bind
    private fetchData(): void {
        this.setState({ loading: true })

        this.userService.getUserOrgDetails().then((orgDetails: UserOrgDetails) => {
            if (orgDetails.Profile === Profile.OWNER) {
                this.profileOptions = this.profileOptions.concat(Profile.OWNER)
            }
            this.orgName = orgDetails.OrgName

            this.setState({
                isMspOrg: orgDetails.IsMSPOrg,
                email: orgDetails.Email,
                userIsOwner: orgDetails.Profile === Profile.OWNER,
                showResetMfa:
                    orgDetails.AccountType === AccountType.LOCAL &&
                    (orgDetails.MFAEnabled ?? false),
            })
        })

        if (this.email) {
            this.userService.getAdmin(this.email).then(
                (admin: AdminUser) => {
                    this.setState({ loading: false, admin })
                },
                () => {
                    this.setState({ loading: false })
                }
            )
        }
    }
}

export interface OrgAdminDetailRouteParams {
    id: string
}

interface Props extends RouteComponentProps<OrgAdminDetailRouteParams> {
    canDelete: boolean
    canUpdate: boolean
}

interface OrgAdminDetailState {
    loading: boolean
    admin?: AdminUser
    email?: string
    submitting?: boolean
    userIsOwner?: boolean
    success?: string
    error?: string
    showDeleteDialog: boolean
    showResetMfa: boolean
    isMspOrg: boolean
}
