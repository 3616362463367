import * as React from "react"

import { LocalizationService } from "../../../../../pre-v3/services"
import {
    UserLicenseInformation,
    LicenseStatus,
    licenseInfoTypeMapToUpper,
    licenseLevelMap,
} from "../../../../services/UserLicenseUsage.service"
import { Column, Grid } from "../../../../components/grid/Grid.component"
import {
    ErrorLabel,
    GenericPrimaryLabel,
    InformationLabel,
} from "../../../../../components/label/Label.component"
import { User, licenseStatusMap } from "../../../../services/User.service"
import { useFormatDateToLocale } from "../../../../../hooks/useFormateDateToLocale.hook"
import { LicenseInformation } from "../../../../services/Org.service"
import { LanguageKey } from "../../../../../pre-v3/services/localization/languages/en-US.language"

interface Props {
    licenseUsage: UserLicenseInformation[]
    user: User
    licenseInfo: LicenseInformation
}

export function UserLicenseInfoList(props: Props) {
    const localization = new LocalizationService()
    const formatDateOfAction = useFormatDateOfAction()

    function getLicenseTypeLevel(userLicenseInfo: UserLicenseInformation): string {
        return getLicense(userLicenseInfo, props.licenseInfo)
    }

    const licenseColumns: Column<UserLicenseInformation>[] = [
        {
            id: "licenseType",
            name: localization.getString("activeLicense"),
            cellRenderer: getLicenseTypeLevel,
            getTooltipValue: getLicenseTypeLevel,
        },
        {
            id: "status",
            name: localization.getString("status"),
            cellRenderer: (userLicenseInfo: UserLicenseInformation) => {
                return mapStatusLabel(userLicenseInfo.status)
            },
            getTooltipValue: (userLicenseInfo: UserLicenseInformation) =>
                localization.getString(getLicenseStatus(userLicenseInfo)),
        },
        {
            id: "dateOfAction",
            name: localization.getString("dateOfAction"),
            cellRenderer: formatDateOfAction,
            getTooltipValue: formatDateOfAction,
        },
        {
            id: "adminOfAction",
            name: localization.getString("adminOfAction"),
            cellRenderer: getAdminOfAction,
            getTooltipValue: getAdminOfAction,
        },
    ]

    function getId(licenseInfo: UserLicenseInformation): string {
        return `${licenseInfo.licenseType}-${props.user}`
    }

    function mapStatusLabel(status: LicenseStatus): JSX.Element {
        switch (status) {
            case LicenseStatus.LICENSED:
                return <InformationLabel>{localization.getString("licensed")}</InformationLabel>
            case LicenseStatus.REVOKED:
                return <ErrorLabel darkMode>{localization.getString("revoked")}</ErrorLabel>
            default:
                return (
                    <GenericPrimaryLabel>
                        {localization.getString("notLicensed")}
                    </GenericPrimaryLabel>
                )
        }
    }

    return (
        <React.Fragment>
            <Grid data={props.licenseUsage} columns={licenseColumns} getId={getId} />
        </React.Fragment>
    )
}

function getLicense(
    userLicenseInfo: UserLicenseInformation,
    licenseInfo: LicenseInformation
): string {
    const licenseTypeKey = userLicenseInfo.licenseType

    const level = licenseInfo[licenseTypeKey]
    return `${licenseInfoTypeMapToUpper[userLicenseInfo.licenseType]} ${
        level !== "none" ? licenseLevelMap[level] : ""
    }`
}

function getLicenseStatus(licenseInfo: UserLicenseInformation): LanguageKey {
    return licenseStatusMap[licenseInfo.status]
}

function getAdminOfAction(licenseInfo: UserLicenseInformation): string {
    switch (licenseInfo.status) {
        case LicenseStatus.LICENSED:
        case LicenseStatus.REVOKED:
            return licenseInfo.adminOfAction === "" ? "-" : licenseInfo.adminOfAction
        default:
            return "-"
    }
}

function useFormatDateOfAction() {
    const formatDateOfAction = useFormatDateToLocale()
    return (licenseInfo: UserLicenseInformation) => {
        switch (licenseInfo.status) {
            case LicenseStatus.LICENSED:
            case LicenseStatus.REVOKED:
                return formatDateOfAction(licenseInfo.dateOfAction)
            default:
                return "-"
        }
    }
}
