import classnames from "classnames/bind"
import React from "react"
import { Link as RouterLink } from "react-router-dom"

import styles from "./Link.module.scss"

/**
 * Render the Link following Banyan's branding
 * @deprecated Use the Button/Link component from the base component folder instead
 */

export const Link = React.forwardRef<HTMLAnchorElement & HTMLButtonElement, LinkProps>(
    (props, ref) => {
        const { children, className, to, ...linkProps } = props
        const newClassName = classnames(styles.link, className)

        if (props.to.startsWith("http") || props.to.startsWith("mailto:")) {
            return (
                <a
                    {...linkProps}
                    href={props.to}
                    target="_blank"
                    rel="noreferrer"
                    className={newClassName}
                    ref={ref}
                >
                    {children}
                </a>
            )
        }

        return (
            <RouterLink {...linkProps} to={to} className={newClassName} ref={ref}>
                {children}
            </RouterLink>
        )
    }
)

Link.displayName = "Link"

export interface LinkProps {
    children: React.ReactNode
    className?: string
    onClick?: React.MouseEventHandler<HTMLAnchorElement>
    to: string
}
