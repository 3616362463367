import React from "react"
import styles from "./ServicesStatusCellRenderer.module.scss"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import {
    faCheckCircle,
    faMinusCircle,
    faTimesCircle,
    faPauseCircle,
} from "@fortawesome/pro-solid-svg-icons"
import classNames from "classnames/bind"

export function ServicesStatusEnforcingTemplate() {
    return (
        <div className={styles.container}>
            <div className={classNames(styles.statusIcon, styles.statusIconEnforcing)}>
                <FontAwesomeIcon icon={faCheckCircle} />
            </div>
            <div className={styles.statusLabel}>
                {this.localizationService.getString("policyEnforcing")}
            </div>
        </div>
    )
}

export function ServicesStatusPermissiveTemplate() {
    return (
        <div className={styles.container}>
            <div className={classNames(styles.statusIcon, styles.statusIconPermissive)}>
                <FontAwesomeIcon icon={faMinusCircle} />
            </div>
            <div className={styles.statusLabel}>
                {this.localizationService.getString("policyPermissive")}
            </div>
        </div>
    )
}

export function ServicesStatusNoPolicyTemplate() {
    return (
        <div className={styles.container}>
            <div className={classNames(styles.statusIcon, styles.statusIconNoPolicy)}>
                <FontAwesomeIcon icon={faTimesCircle} />
            </div>
            <div className={styles.statusLabel}>
                {this.localizationService.getString("noPolicy")}
            </div>
        </div>
    )
}

export function ServicesStatusInactiveTemplate() {
    return (
        <div className={styles.container}>
            <div className={classNames(styles.statusIcon, styles.statusIconInactive)}>
                <FontAwesomeIcon icon={faPauseCircle} />
            </div>
            <div className={styles.statusLabel}>
                {this.localizationService.getString("inactive")}
            </div>
        </div>
    )
}
