import React from "react"
import { useLocation } from "react-router-dom"

import { useServiceActionBar } from "../../../../pre-v3/services/ActionBar.service"
import { FilterModel } from "../../../../pre-v3/utils/AgGrid.util"
import { UrlUtil } from "../../../../pre-v3/utils/Url.util"
import { GridApi } from "../../../components/grid/Grid.component"
import {
    ResourceIpFilterById as FilterById,
    PrivateResourceDetails as PrivateResource,
    useGetResourceIpsByPrivateResource,
    useGetServiceTunnels,
} from "../../../services/PrivateResource.service"
import { ResourceIpFilterBar } from "./ResourceIpFilterBar.component"
import { ResourceIpGrid } from "./ResourceIpGrid.component"
import styles from "./ResourceIpPanel.module.scss"

interface Props {
    id: string
    privateResource: PrivateResource
}

export function ResourceIpPanel(props: Props): JSX.Element {
    const actionBarService = useServiceActionBar()
    const location = useLocation()

    const gridRef = React.useRef<GridApi>(null)

    const [filterModel, setFilterModel] = React.useState(
        UrlUtil.readFilter<FilterById>(location.search)
    )

    const onFilter = (newFilterModel: FilterModel<FilterById>): void => {
        setFilterModel(newFilterModel)
        UrlUtil.writeFilter(newFilterModel)
    }

    const { getResourceIps, clearCache } = useGetResourceIpsByPrivateResource(props.privateResource)

    const { data: serviceTunnels = [], refetch: refetchServiceTunnels } = useGetServiceTunnels()

    React.useEffect(() => {
        const refresh = async () => {
            await clearCache()
            gridRef.current?.refreshData()
            refetchServiceTunnels()
        }

        actionBarService.registerRefreshFn(refresh)
        return () => actionBarService.unregisterRefreshFn(refresh)
    }, [clearCache, gridRef.current?.refreshData])

    return (
        <div id={props.id} className={styles.container}>
            <ResourceIpFilterBar
                filterModel={filterModel}
                serviceTunnels={serviceTunnels}
                onFilter={onFilter}
            />
            <ResourceIpGrid
                filterModel={filterModel}
                getResourceIps={getResourceIps}
                ref={gridRef}
            />
        </div>
    )
}
