import { ICellRendererParams } from "ag-grid-community"
import React from "react"
import { CopyButton } from "../../copy-button/CopyButton.component"
import styles from "./CopyCellRenderer.module.scss"

export function CopyCellRenderer(props: ICellRendererParams) {
    function onCopy(): void {
        navigator.clipboard.writeText(props.value)
    }

    return (
        <div className={styles.container}>
            <span className={styles.label}>{props.value}</span>
            <CopyButton className={styles.button} action onCopy={onCopy} />
        </div>
    )
}
