import React, { FC, useEffect, useLayoutEffect, useRef, useState } from "react"
import { createPortal } from "react-dom"
import styles from "./SonicWallCurtain.module.scss"
import classNames from "classnames"
import { useServiceLocalization } from "../../pre-v3/services"
import { isSonicWallDomain } from "../../utils/url.utils"
import { MswUrl } from "../../pre-v3/services/Auth.store"
import { Loader } from "../../v3/components/loader/Loader.component"
import { ErrorToast, ToastApi } from "../toast/Toast.components"

const iframeWidth = 1138
const iframeHeight = 689

interface WindowMessage {
    closeCurtain: boolean
    service: string
    navigateUrl: string
}

interface Props {
    generateUrl: () => Promise<MswUrl>
}

export const SonicWallCurtain: FC<Props> = ({ generateUrl }) => {
    const [url, setUrl] = useState<MswUrl | undefined>()
    const [isUrlLoading, setIsUrlLoading] = useState(false)

    const [open, setOpen] = useState(false)
    const [isResizing, setIsResizing] = useState(false)
    const [iframeScale, setIframeScale] = useState(1)
    const [isIframeReady, setIsIframeReady] = useState(false)
    const iframeRef = useRef<HTMLIFrameElement>(null)

    const localization = useServiceLocalization()
    const toastApi = useRef<ToastApi>(null)

    useEffect(() => {
        if (!iframeRef.current || !url) return

        const sendMessage = (payload: Record<string, unknown>) => {
            if (!iframeRef.current) return
            iframeRef.current.contentWindow?.postMessage(payload, new URL(url).origin)
        }
        const onMessage = (e: MessageEvent<WindowMessage>) => {
            if (!isSonicWallDomain(e.origin)) return
            if (e.data.closeCurtain) {
                setOpen(false)
                const { service, navigateUrl } = e.data
                if (!service || !navigateUrl) return
                sendMessage({
                    closedCurtain: true,
                    service,
                    navigateUrl,
                })
            }
        }

        window.addEventListener("message", onMessage)
        return () => window.removeEventListener("message", onMessage)
    }, [url])

    const toggleOpen = () => {
        if (open) return setOpen(false)
        setIsUrlLoading(true)
        setOpen(true)

        generateUrl()
            .then((url) => {
                setUrl(url)
            })
            .catch(() => {
                toastApi.current?.openToast(
                    localization.getString("weEncounteredAProblemOpeningTheCaptureSecurityCenter")
                )
                setOpen(false)
            })
            .finally(() => setIsUrlLoading(false))
    }

    useLayoutEffect(() => {
        const scaleIframe = () => {
            const windowHeight = window.innerHeight
            const height = (90 * windowHeight) / 100
            const width = (height * iframeWidth) / iframeHeight

            setIframeScale(width / iframeWidth)
            setIsResizing(true)
            setTimeout(() => setIsResizing(false), 100)
        }

        scaleIframe()

        window.addEventListener("resize", scaleIframe)

        return () => window.removeEventListener("resize", scaleIframe)
    }, [])

    const curtainLabel = localization.getString("sonicWallCurtain")

    return createPortal(
        <div
            className={classNames(styles.container, {
                [styles.open]: open,
                [styles.resizing]: isResizing,
            })}
        >
            <iframe
                style={{
                    width: `${iframeWidth}px`,
                    height: `${iframeHeight}px`,
                    transform: `scale(${iframeScale})`,
                    opacity: open ? 1 : 0,
                }}
                id="msw-curtain"
                title={curtainLabel}
                src={url}
                className={styles.iframe}
                ref={iframeRef}
                onLoad={() => setIsIframeReady(true)}
                aria-hidden={!open}
            />
            {(isUrlLoading || !isIframeReady) && (
                <div className={styles.loading} style={{ width: `${iframeWidth * iframeScale}px` }}>
                    <Loader
                        center
                        medium
                        title={localization.getString("loadingSomething", curtainLabel)}
                    />
                </div>
            )}
            <button
                aria-label={localization.getString("sonicWallCurtainToggleButton")}
                className={styles.toggleButton}
                onClick={toggleOpen}
            />
            <ErrorToast ref={toastApi} />
        </div>,
        document.body
    )
}
