import React, { ReactNode, SyntheticEvent } from "react"
import ServicesAddSaasAuthenticatorTemplate from "./ServicesAddSaasAuthenticator.template"
import { LocalizationService } from "../../../../services/localization/Localization.service"
import { Bind } from "../../../../decorators/Bind.decorator"
import { SaasAuthProtocol, SaasNameIdFormat, SaasNameIdValueOpts } from "../../../../api/Manage.api"
import { SettingsService, OidcConfig } from "../../../../services/Settings.service"
import { ToggleButtonItem } from "../../../../components/toggle-button/ToggleButton.component"
import { SelectItem } from "../../../../utils/SelectValue.util"

export class ServicesAddSaasAuthenticator extends React.Component<
    ServicesAddSaasAuthenticatorProps,
    ServicesAddSaasAuthenticatorState
> {
    public state: ServicesAddSaasAuthenticatorState = {
        authProtocolOption: SaasAuthProtocol.OIDC,
        redirectUri: "",
        loading: true,
        audienceUri: "",
        nameIdFormat: SaasNameIdFormat.PERSISTENT,
        nameIdAttributeSelector: "",
        nameIdValueType: SaasNameIdValueOpts.PASSTHROUGH_NAME_ID,
    }

    public componentDidMount(): void {
        this.settingsService.getOidcSettings().then((oidc: OidcConfig) => {
            let newState: ServicesAddSaasAuthenticatorState = this.state

            if (oidc.redirectUrl) {
                this.defaultRedirectUri = oidc.redirectUrl
                newState.redirectUri = oidc.redirectUrl
            }

            if (this.props.initialValue) {
                newState = {
                    ...newState,
                    authProtocolOption: this.props.initialValue.protocol,
                    redirectUri: this.props.initialValue.redirectUri,
                    audienceUri: this.props.initialValue.audienceUri,
                    nameIdFormat: this.props.initialValue.nameIdFormat || this.state.nameIdFormat,
                    nameIdValueType:
                        this.props.initialValue.nameIdValueType || this.state.nameIdValueType,
                    nameIdAttributeSelector: this.props.initialValue.nameIdAttributeSelector,
                }
            }

            this.authProtocolTypes.forEach((e) => {
                e.selected = e.value === newState.authProtocolOption
            })

            this.setState({ ...newState, loading: false }, () => {
                if (this.props.onChange) {
                    this.props.onChange({
                        protocol: this.state.authProtocolOption,
                        redirectUri: this.state.redirectUri,
                        audienceUri: this.state.audienceUri,
                        nameIdFormat: this.state.nameIdFormat,
                        nameIdValueType: this.state.nameIdValueType,
                        nameIdAttributeSelector: this.state.nameIdAttributeSelector,
                    })
                }
            })
        })
    }

    public render(): ReactNode {
        return ServicesAddSaasAuthenticatorTemplate.call(this)
    }

    private localizationService: LocalizationService = new LocalizationService()
    private settingsService: SettingsService = new SettingsService()
    private defaultRedirectUri: string = ""

    private authProtocolTypes: ToggleButtonItem[] = [
        {
            label: this.localizationService.getString("oidc"),
            value: SaasAuthProtocol.OIDC,
            onClick: () => {
                this.onAuthenticatorChange(SaasAuthProtocol.OIDC)
            },
        },
        {
            label: this.localizationService.getString("saml"),
            value: SaasAuthProtocol.SAML,
            onClick: () => {
                this.onAuthenticatorChange(SaasAuthProtocol.SAML)
            },
        },
    ]

    private nameIdValueOptions: SelectItem[] = [
        {
            displayName: this.localizationService.getString("custom"),
            value: SaasNameIdValueOpts.CUSTOM,
        },
        {
            displayName: this.localizationService.getString("usePassthroughNameId"),
            value: SaasNameIdValueOpts.PASSTHROUGH_NAME_ID,
        },
        {
            displayName: this.localizationService.getString("legacyCompatibilityMode"),
            value: SaasNameIdValueOpts.LEGACY,
        },
    ]

    private nameIdOptions: SelectItem[] = [
        {
            displayName: this.localizationService.getString(SaasNameIdFormat.UNSPECIFIED),
            value: SaasNameIdFormat.UNSPECIFIED,
        },
        {
            displayName: this.localizationService.getString(SaasNameIdFormat.EMAIL),
            value: SaasNameIdFormat.EMAIL,
        },
        {
            displayName: this.localizationService.getString(SaasNameIdFormat.TRANSIENT),
            value: SaasNameIdFormat.TRANSIENT,
        },
        {
            displayName: this.localizationService.getString(SaasNameIdFormat.PERSISTENT),
            value: SaasNameIdFormat.PERSISTENT,
        },
    ]

    @Bind
    private onAuthenticatorChange(value: SaasAuthProtocol): void {
        // If selected auth protocol is OIDC, prepopulate default redirectUrl. Otherwise clear the field
        let newRedirectUri: string = ""
        if (this.state.authProtocolOption !== value) {
            if (value === SaasAuthProtocol.OIDC) {
                newRedirectUri = this.defaultRedirectUri
            } else {
                newRedirectUri = ""
            }
        }

        this.setState({ authProtocolOption: value, redirectUri: newRedirectUri }, () => {
            if (this.props.onChange) {
                this.props.onChange({ protocol: value, redirectUri: this.state.redirectUri })
            }
        })
    }

    @Bind
    private onRedirectUrlChange(event: SyntheticEvent): void {
        if (event && event.target) {
            const value: string = (<HTMLInputElement>event.target).value
            this.setState({ redirectUri: value })

            if (this.props.onChange) {
                this.props.onChange({
                    redirectUri: value,
                    protocol: this.state.authProtocolOption,
                    audienceUri: this.state.audienceUri,
                    nameIdFormat: this.state.nameIdFormat,
                    nameIdValueType: this.state.nameIdValueType,
                    nameIdAttributeSelector: this.state.nameIdAttributeSelector,
                })
            }
        }
    }

    @Bind
    private onAudienceUrlChange(event: SyntheticEvent): void {
        if (event && event.target) {
            const value: string = (<HTMLInputElement>event.target).value
            this.setState({ audienceUri: value })

            if (this.props.onChange) {
                this.props.onChange({
                    audienceUri: value,
                    protocol: this.state.authProtocolOption,
                    redirectUri: this.state.redirectUri,
                    nameIdFormat: this.state.nameIdFormat,
                    nameIdValueType: this.state.nameIdValueType,
                    nameIdAttributeSelector: this.state.nameIdAttributeSelector,
                })
            }
        }
    }

    @Bind
    private onNameIdFormatChange(nameIdFormat: SaasNameIdFormat): void {
        this.setState({ nameIdFormat })
        if (this.props.onChange) {
            this.props.onChange({
                nameIdFormat,
                audienceUri: this.state.audienceUri,
                protocol: this.state.authProtocolOption,
                redirectUri: this.state.redirectUri,
                nameIdValueType: this.state.nameIdValueType,
                nameIdAttributeSelector: this.state.nameIdAttributeSelector,
            })
        }
    }

    @Bind
    private onNameIdSelectorChange(nameIdValueType: SaasNameIdValueOpts): void {
        this.setState({ nameIdValueType })
        if (this.props.onChange) {
            this.props.onChange({
                nameIdValueType,
                audienceUri: this.state.audienceUri,
                protocol: this.state.authProtocolOption,
                redirectUri: this.state.redirectUri,
                nameIdFormat: this.state.nameIdFormat,
                nameIdAttributeSelector: this.state.nameIdAttributeSelector,
            })
        }
    }

    @Bind
    private onCustomNameIdValueChange(event: SyntheticEvent): void {
        if (event && event.target) {
            const value: string = (<HTMLInputElement>event.target).value
            this.setState({ nameIdAttributeSelector: value })

            if (this.props.onChange) {
                this.props.onChange({
                    nameIdAttributeSelector: value,
                    audienceUri: this.state.audienceUri,
                    protocol: this.state.authProtocolOption,
                    redirectUri: this.state.redirectUri,
                    nameIdFormat: this.state.nameIdFormat,
                    nameIdValueType: this.state.nameIdValueType,
                })
            }
        }
    }
}

interface ServicesAddSaasAuthenticatorProps {
    className: string
    initialValue: SaasAuthenticatorValue
    defaultAuthProtocol: boolean
    onChange: (value: SaasAuthenticatorValue) => void
    idpRouted?: boolean
}

interface ServicesAddSaasAuthenticatorState {
    loading: boolean
    authProtocolOption: SaasAuthProtocol
    redirectUri: string
    audienceUri?: string
    nameIdFormat: SaasNameIdFormat
    nameIdAttributeSelector?: string
    nameIdValueType: SaasNameIdValueOpts
}

export interface SaasAuthenticatorValue {
    protocol: SaasAuthProtocol
    redirectUri: string
    audienceUri?: string
    nameIdFormat?: SaasNameIdFormat
    nameIdValueType?: SaasNameIdValueOpts
    nameIdAttributeSelector?: string
}
