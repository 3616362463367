import { UseQueryResult, useMutation, useQuery, useQueryClient } from "@tanstack/react-query"

import { LocalizationService, RoleSecure, SecureService } from "../../pre-v3/services"
import { LanguageKey } from "../../pre-v3/services/localization/languages/en-US.language"
import { Paginated, PaginatedSearch } from "../../pre-v3/utils/AgGrid.util"
import { DateUtil } from "../../pre-v3/utils/Date.util"
import { RoleApi, RoleRes, RoleSpecReq } from "../api/Role.api"
import { EndUser, Group, IDPUsers, LicenseStatusRes, UserApi, UserSearch } from "../api/User.api"
import { StatusType } from "../components/status/Status.component"
import { ApiFunction } from "./shared/QueryKey"
import { Status, getStatusFromRes } from "./shared/Role"
import { OrgService } from "./Org.service"
import JsonStableStringify from "json-stable-stringify"

export class UserService {
    public async getUsers(
        params: PaginatedSearch = { skip: 0, limit: 1000 },
        startTime?: number,
        endTime?: number
    ): Promise<Paginated<User>> {
        const search: Partial<UserSearch> = {
            skip: params.skip,
            limit: params.limit,
            active: "true",
        }
        if (params.filterModel?.email) {
            search.email = params.filterModel.email.filter
        }

        if (params.filterModel?.name) {
            search.name = params.filterModel.name.filter
        }

        if (params.filterModel?.roles) {
            search.role_names = params.filterModel.roles.filter
        }

        if (params.filterModel?.siaLicense) {
            search.sia =
                params.filterModel.siaLicense.filter &&
                buildLicenseFilter(params.filterModel.siaLicense.filter)
        }

        if (params.filterModel?.spaLicense) {
            search.spa =
                params.filterModel.spaLicense.filter &&
                buildLicenseFilter(params.filterModel.spaLicense.filter)
        }

        if (params.sortModel && params.sortModel.length > 0) {
            search.order = params.sortModel[0].sort
            switch (params.sortModel[0].colId) {
                case "lastLoginAt":
                    search.order_by = "last_login"
                    break
                default:
                    search.order_by = params.sortModel[0].colId
            }
        }

        if (startTime !== undefined && endTime) {
            search.last_login_after = startTime
            search.last_login_before = endTime
        }

        const isBanyanIdp: boolean = await this.orgService.isBanyanIdp()
        let adminList: string[] = []
        if (isBanyanIdp) {
            adminList = await this.getUsersByGroup(UserGroup.ALL_ADMINS)
        }

        const [{ count, endusers }, rolesRes] = await Promise.all([
            this.userApi.getUsers(search),
            this.roleApi.getRoles(),
        ])

        return {
            data: endusers.map((endUser) => this.mapEnduser(endUser, rolesRes, adminList)),
            total: count,
        }
    }

    public async getUserByEmail(email: string): Promise<User> {
        const search: Partial<UserSearch> = {
            active: "true",
            exact_email: email,
        }
        const isBanyanIdp: boolean = await this.orgService.isBanyanIdp()
        const adminList: string[] = isBanyanIdp
            ? await this.getUsersByGroup(UserGroup.ALL_ADMINS)
            : []

        const { endusers } = await this.userApi.getUsers(search)

        const isAdminUser: boolean = isBanyanIdp ? await this.isAdminUser(email) : false

        if (endusers && endusers.length > 0) {
            const found = endusers.find((e) => e.email.toLowerCase() === email.toLowerCase())
            if (found) {
                const rolesRes = await this.roleApi.getRoles()

                return this.mapEnduser(endusers[0], rolesRes, adminList, isAdminUser)
            }
        }

        return Promise.reject(this.ls.getString("userNotFound"))
    }

    public async getUsersByDeviceSerialNumber(serialNumber: string): Promise<User[]> {
        const isBanyanIdp: boolean = await this.orgService.isBanyanIdp()

        const search: Partial<UserSearch> = {
            active: "true",
            serial_number: serialNumber,
        }

        const [{ endusers }, rolesRes, adminList] = await Promise.all([
            this.userApi.getUsers(search),
            this.roleApi.getRoles(),
            isBanyanIdp ? this.getUsersByGroup(UserGroup.ALL_ADMINS) : Promise.resolve([]),
        ])

        return endusers.map((endUser) => this.mapEnduser(endUser, rolesRes, adminList))
    }

    private mapEnduser(
        endUser: EndUser,
        rolesRes: RoleRes[],
        adminList?: string[],
        isAdminUser?: boolean
    ): UserWithLicenses {
        return {
            id: endUser.id,
            name: endUser.name,
            email: endUser.email,
            roles: getRoles(endUser, rolesRes, adminList),
            lastLoginAt: DateUtil.convertLargeTimestamp(endUser.last_login!),
            invitedAt: DateUtil.convertLargeTimestamp(endUser.invited_at!),
            status: endUser.status === "invited" ? "invited" : "active",
            source: "BNN",
            ...(isAdminUser && { isAdminUser }),
            spa: userLicenseStatusMap[endUser.SPA],
            sia: userLicenseStatusMap[endUser.SIA],
        }
    }

    private async isAdminUser(email: string): Promise<boolean> {
        const groups = await this.getUserGroupByEmail(email)
        return groups.some((group) => group.groupName === UserGroup.ALL_ADMINS)
    }

    private getUsersByGroup(groupName: UserGroup): Promise<string[]> {
        return this.userApi.getUsersByGroup(groupName).then((users: IDPUsers) => {
            return users.data.Users?.map(({ Username }) => Username)
        })
    }

    public switchBanyanIDPToLocalIDP(): Promise<void> {
        return this.userApi.switchBanyanIDPToLocalIDP()
    }

    public async addUser(user: User, roles: RoleSecure[]): Promise<void> {
        const isAdmin: boolean = user.isAdminUser || false
        await this.userApi.addUser({ email: user.email, name: user.name, isAdmin })
        if (roles) {
            for (const role of roles) {
                if (
                    !this.secureService.isGeneratedRole(role.name) &&
                    this.secureService.isEmailRole(role)
                ) {
                    try {
                        await this.secureService.addUserToEmailRole(user.email, role)
                    } catch {} //ignore
                }
            }
        }
    }

    private async getUserGroupByEmail(email: string): Promise<IDPGroup[]> {
        const { Groups } = (await this.userApi.getUserGroupByEmail(email))?.data
        return Groups.map((value) => {
            return {
                groupName: value.GroupName,
            }
        })
    }

    public updateUser(email: string, isAdmin?: boolean): Promise<Group> {
        const groupNames: string[] = isAdmin ? Object.values(UserGroup) : [UserGroup.ALL_USERS]
        return this.userApi.updateUser(email, groupNames)
    }

    private userApi: UserApi = new UserApi()
    private orgService: OrgService = new OrgService()
    private roleApi: RoleApi = new RoleApi()
    private secureService: SecureService = new SecureService()
    private ls: LocalizationService = new LocalizationService()
}

const serviceName = "UserService"

function buildLicenseFilter(licenseFilter: string) {
    return licenseFilter
        .split("|")
        .map((license) => {
            return userLicenseStatusMapParams[license]
        })
        .join("|")
}

export function useGetUserByEmail(email: string, options?: QueryOptions<User>) {
    const userService = new UserService()
    return useQuery<User, string>({
        ...options,
        queryKey: [ApiFunction.GET_USER_BY_EMAIL, email, serviceName],
        queryFn: async (): Promise<User> => {
            return userService.getUserByEmail(email)
        },
        enabled: Boolean(email),
    })
}

export function useGetUserBySerialNumber(serialNumber: string, options?: QueryOptions<User[]>) {
    const userService = new UserService()
    return useQuery<User[], string>({
        ...options,
        queryKey: ["userService.getUserBySerialNumber", serialNumber],
        queryFn: async (): Promise<User[]> => {
            return userService.getUsersByDeviceSerialNumber(serialNumber)
        },
        enabled: Boolean(serialNumber),
    })
}

export function useAddUser(options?: QueryOptions<void, string, [User, RoleSecure[]]>) {
    const userService = new UserService()
    return useMutation<void, string, [User, RoleSecure[]]>({
        ...options,
        mutationFn: async ([user, roles]): Promise<void> => {
            return userService.addUser(user, roles)
        },
    })
}

export function useGetEndUsersCSV(options?: QueryOptions<Blob, string>) {
    const userApi = new UserApi()
    return useMutation<Blob, string>({
        ...options,
        mutationFn: (): Promise<Blob> => {
            return userApi.getEndUsersCSV()
        },
        onSuccess: options?.onSuccess,
    })
}

export function useDeleteUsers(options?: QueryOptions<void, string, string[]>) {
    const userApi = new UserApi()
    return useMutation<void, string, string[]>({
        ...options,
        mutationFn: (devicesIds: string[]): Promise<void> => {
            return userApi.deleteUsers(devicesIds)
        },
        onSuccess: options?.onSuccess,
    })
}

export function useUpdateUser(options?: QueryOptions<Group>) {
    const queryClient = useQueryClient()
    const userService = new UserService()
    return useMutation<Group, string, [string, boolean]>({
        ...options,
        mutationFn: async ([email, isAdmin]): Promise<Group> => {
            return userService.updateUser(email, isAdmin)
        },
        onSuccess: (data) => {
            queryClient.removeQueries([ApiFunction.GET_USER_BY_EMAIL, data.data.Email])
            options?.onSuccess?.(data)
        },
    })
}

export function useGetUsers(
    params: PaginatedSearch = { skip: 0, limit: 1000 },
    options?: QueryOptions<Paginated<User>>
): UseQueryResult<Paginated<User>> {
    const userService = new UserService()

    const paramsKey: string = JsonStableStringify(params)

    return useQuery({
        ...options,
        queryKey: ["userService.getUsers", paramsKey],
        queryFn: () => {
            return userService.getUsers(params)
        },
    })
}

export type UserStatus = "active" | "invited"

export type UserEntitySource = "BNN" | "COG" | "IDP"

export interface User {
    email: string
    id: string
    invitedAt?: number
    lastLoginAt?: number
    name: string
    roles: Role[]
    status?: UserStatus
    source?: UserEntitySource
    isAdminUser?: boolean
    licenses?: UserLicense
}

export interface UserWithLicenses extends User {
    spa: LicenseStatus
    sia: LicenseStatus
}

export interface UserWithLicenses extends User {
    spa: LicenseStatus
    sia: LicenseStatus
}

export interface Role {
    id: string
    name: string
    status: Status
    lastUpdatedAt: number
}

export enum UserGroup {
    ALL_ADMINS = "AllAdmins",
    ALL_USERS = "AllUsers",
}

export const statusMap: Record<UserStatus, StatusType> = {
    active: "success",
    invited: "disabled",
}

export const labelMap: Record<UserStatus, LanguageKey> = {
    active: "active",
    invited: "invited",
}
export interface IDPGroup {
    groupName: string
}

// Helper Functions

function getRoles(endUser: EndUser, rolesRes: RoleRes[], adminList?: string[]): Role[] {
    const { security_roles = [] } = endUser

    const isInAdminList = adminList?.includes(endUser.email) ?? false

    return rolesRes.reduce<Role[]>((acc, roleRes) => {
        const hasRole = security_roles.includes(roleRes.RoleName)
        // manually append All Admin role because the End User API doesn't include generated roles
        const isAdmin = roleRes.RoleName === UserGroup.ALL_ADMINS && isInAdminList

        if (!hasRole && !isAdmin) return acc

        const roleSpec: RoleSpecReq = JSON.parse(roleRes.RoleSpec)

        const role: Role = {
            id: roleRes.RoleID,
            name: roleSpec.metadata.name,
            status: getStatusFromRes(roleRes),
            lastUpdatedAt: DateUtil.convertLargeTimestamp(roleRes.LastUpdatedAt),
        }

        return [...acc, role]
    }, [])
}

export enum LicenseStatus {
    NOT_LICENSED = "NOT_LICENSED",
    LICENSED = "LICENSED",
    REVOKED = "REVOKED",
}

interface NotLicensedInformation {
    licenseType: LicenseType
    status: LicenseStatus.NOT_LICENSED
}

interface LicensedInformation {
    status: LicenseStatus.LICENSED
    licenseType: LicenseType
    dateOfAction: Date
    adminOfAction?: string
    licenseId: string
}

interface RevokedInformation {
    status: LicenseStatus.REVOKED
    licenseType: LicenseType
    dateOfAction: Date
    adminOfAction: string
    licenseId: string
}

export type UserLicenseInformation =
    | NotLicensedInformation
    | LicensedInformation
    | RevokedInformation

export type UserLicense = Record<LicenseType, UserLicenseInformation>

export enum LicenseType {
    SPA = "spa",
    SIA = "sia",
}

export const licenseStatusMap: Record<LicenseStatus, LanguageKey> = {
    NOT_LICENSED: "notLicensed",
    LICENSED: "licensed",
    REVOKED: "revoked",
}

export const userLicenseStatusMap: Record<LicenseStatusRes, LicenseStatus> = {
    NOTLICENSED: LicenseStatus.NOT_LICENSED,
    LICENSED: LicenseStatus.LICENSED,
    REVOKED: LicenseStatus.REVOKED,
}

export const userLicenseStatusMapParams: Record<string, LicenseStatusRes> = {
    [LicenseStatus.NOT_LICENSED]: "NOTLICENSED",
    [LicenseStatus.LICENSED]: "LICENSED",
    [LicenseStatus.REVOKED]: "REVOKED",
}
