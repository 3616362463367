import classNames from "classnames"
import React from "react"

import { Step as Tab, State as TabState } from "../base/Step.component"
import styles from "./TabBar.module.scss"

export { State as TabState } from "../base/Step.component"

export interface TabBarProps<TabId = string> {
    tabs: TabProps<TabId>[]
    selectedTabId: TabId
    onChange?: (updatedTabId: TabId) => void
    className?: string
    disabled?: boolean
    type?: TabBarType
}

export function TabBar<TabId extends TabIdType>(props: TabBarProps<TabId>): JSX.Element {
    const { tabs, className, disabled, type = "default", selectedTabId, onChange } = props

    const selectedTabIndex = tabs.findIndex((tab) => tab.id === selectedTabId)

    return (
        <ul role="tablist" className={classNames(styles.tabBar, className)}>
            {tabs.map((tab, currentTabIndex) => {
                const { id: currentTabId, ariaControls, label } = tab

                function onClick() {
                    onChange?.(currentTabId)
                }

                return (
                    <Tab
                        key={currentTabId}
                        id={String(currentTabId)}
                        role="tab"
                        label={label}
                        aria-controls={ariaControls}
                        state={getTabState({
                            currentTabId,
                            selectedTabId,
                            disabled,
                            currentTabIndex,
                            selectedTabIndex,
                            type,
                        })}
                        onClick={onChange && onClick}
                        number={type === "stepper" ? currentTabIndex + 1 : undefined}
                    />
                )
            })}
        </ul>
    )
}

export interface TabProps<TabId = string> {
    id: TabId
    label: string
    /**
     * Which tab panel is controlled by this tab? Identified by tab panel's ID.
     */
    ariaControls: string
}

type TabBarType = "default" | "stepper"
type TabIdType = string | number

interface getTabStateProps {
    currentTabId: TabIdType
    selectedTabId: TabIdType
    disabled?: boolean
    type: TabBarType
    currentTabIndex: number
    selectedTabIndex: number
}

function getTabState(props: getTabStateProps): TabState {
    const { currentTabId, selectedTabId, disabled, type, selectedTabIndex, currentTabIndex } = props

    if (disabled || (type === "stepper" && currentTabIndex > selectedTabIndex)) {
        return TabState.DISABLED
    }

    return currentTabId === selectedTabId ? TabState.ACTIVE : TabState.SOLID
}
