import { QueryClient, QueryClientConfig } from "@tanstack/react-query"

export const queryClientOptions: QueryClientConfig = {
    defaultOptions: {
        queries: {
            refetchOnWindowFocus: false,
            retry: false,
            staleTime: 1000 * 60,
        },
    },
}

export const queryClient = new QueryClient(queryClientOptions)
