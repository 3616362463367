import React from "react"

export enum AppBannerKey {
    GRANULAR_TRUST_MIGRATION_EDUCATION = "GRANULAR_TRUST_MIGRATION_EDUCATION",
    RETURN_TO_ONBOARDING = "RETURN_TO_ONBOARDING",
}

interface BannerProps {
    hasUserClosedBanner: boolean
    onUserClosingBanner(): void
}

export type Context = Record<AppBannerKey, BannerProps>

const initialContext: Context = Object.values(AppBannerKey).reduce(
    createInitalContext,
    {} as Context
)

function createInitalContext(context: Context, key: AppBannerKey): Context {
    const initialBannerProps: BannerProps = {
        hasUserClosedBanner: false,
        onUserClosingBanner: () => {
            throw new Error(
                "The React component tree is missing the OnboardingBannerProvider component"
            )
        },
    }

    return { ...context, [key]: initialBannerProps }
}

export const AppBannerContext = React.createContext(initialContext)
