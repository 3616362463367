import React, { useMemo, useState } from "react"

import { Status } from "../../../components/status/Status.component"
import { useServiceLocalization } from "../../../pre-v3/services"
import { toLocalizedList } from "../../../utils/String.utils"
import { SearchInput } from "../../../v3/components/search-input/SearchInput.component"
import { Column, Grid } from "../../../v3/components/grid/Grid.component"
import {
    Status as StatusType,
    statusLabelKey,
    statusTypeKey,
} from "../../../v3/services/shared/AccessTier"
import { AccessTier } from "../../../v3/services/AccessTierGroup.service"
import styles from "./AccessTierInGroup.module.scss"
import { MenuButton } from "../../../v3/components/menu/menu-button/MenuButton.component"
import { faFilter } from "@fortawesome/pro-regular-svg-icons"
import { MenuItemProps } from "../../../v3/components/menu/menu-item/MenuItem.component"
import { Menu } from "../../../v3/components/menu/Menu.component"
import { ROUTE, formatRoutePath } from "../../../routes"
import { encodeID } from "../../../pre-v3/utils/Url.util"
import { StringUtil } from "../../../pre-v3/utils/String.util"
import { Link, LinkElement } from "../../../components/link/Link.component"

interface Props {
    id: string
    accessTiers: AccessTier[]
}

export function AccessTiersInGroup(props: Props): JSX.Element {
    const { accessTiers } = props
    const [searchName, setSearchName] = useState("")
    const { selectedStatus, statusMenuOptions } = useStatusOptions()
    const localization = useServiceLocalization()

    const filteredAccessTiers = useMemo(() => {
        if (!selectedStatus.label && !searchName) return accessTiers

        function filterData(accessTier: AccessTier) {
            const isSameStatus = selectedStatus.value
                ? accessTier.status === selectedStatus.value
                : true
            const nameMatch = searchName
                ? StringUtil.caseInsensitiveIncludes(accessTier.name, searchName)
                : true
            return isSameStatus && nameMatch
        }

        return accessTiers.filter(filterData)
    }, [searchName, accessTiers, selectedStatus])

    const columns: Column<AccessTier>[] = [
        {
            id: "status",
            name: localization.getString("status"),
            cellRenderer: (accessTiers) => (
                <Status
                    type={statusTypeKey[accessTiers.status]}
                    label={localization.getString(statusLabelKey[accessTiers.status])}
                />
            ),
            getTooltipValue: "status",
        },
        {
            id: "name",
            name: localization.getString("name"),
            cellRenderer: (accessTier) => (
                <Link
                    to={formatRoutePath(ROUTE.ACCESS_TIERS_DETAILS, {
                        id: encodeID(accessTier.id),
                    })}
                    asElement={LinkElement.LINK}
                >
                    {accessTier.name}
                </Link>
            ),
            getTooltipValue: "name",
        },
        {
            id: "netagentVersion",
            name: localization.getString("version"),
            cellRenderer: (accessTier) =>
                toLocalizedList(
                    accessTier.netagentVersions,
                    localization.getLocale(),
                    "conjunction"
                ),
            getTooltipValue: "netagentVersions",
        },
        {
            id: "netagentInstances",
            name: localization.getString("instances"),
            cellRenderer: "netagentInstances",
            getTooltipValue: "netagentInstances",
        },
        {
            id: "publicAddress",
            name: localization.getString("publicAddress"),
            cellRenderer: "publicAddress",
            getTooltipValue: "publicAddress",
        },
    ]

    return (
        <div className={styles.container}>
            <div className={styles.search}>
                <SearchInput
                    value={searchName}
                    onChangeValue={setSearchName}
                    placeholder={localization.getString("searchByName")}
                />
                <MenuButton label={selectedStatus.label} icon={faFilter}>
                    <Menu items={statusMenuOptions} />
                </MenuButton>
            </div>

            <Grid
                columns={columns}
                hasPagination
                data={filteredAccessTiers}
                className={styles.grid}
            />
        </div>
    )
}

type StatusOptions = Record<StatusOptionsKey, { value: StatusType | ""; label: string }>
type StatusOptionsKey = StatusType | "all"
type StatusOptionsItem = StatusOptions[StatusOptionsKey]
function useStatusOptions() {
    const localization = useServiceLocalization()
    const statusOptions = {
        all: { value: "", label: localization.getString("allStatuses") },
        [StatusType.INACTIVE]: {
            value: StatusType.INACTIVE,
            label: localization.getString("inactive"),
        },
        [StatusType.PENDING]: {
            value: StatusType.PENDING,
            label: localization.getString("pending"),
        },
        [StatusType.REPORTING]: {
            value: StatusType.REPORTING,
            label: localization.getString("reporting"),
        },
        [StatusType.TERMINATED]: {
            value: StatusType.TERMINATED,
            label: localization.getString("terminated"),
        },
    } satisfies StatusOptions
    const [selectedStatus, setSelectedStatus] = useState<StatusOptionsItem>(statusOptions.all)

    const statusMenuOptions: MenuItemProps[] = Object.values(statusOptions).map((statusItem) => ({
        label: statusItem.label,
        onClick: () => setSelectedStatus(statusItem),
    }))

    return { selectedStatus, statusMenuOptions }
}
