import React from "react"
import styles from "./TabBar.module.scss"
import classNames from "classnames/bind"

export default function () {
    return (
        <ul className={styles.tabBar} role="tablist">
            {this.props.tabs &&
                this.props.tabs.map((tab) => (
                    <li
                        className={styles.tabContainer}
                        key={tab.value}
                        role="tab"
                        aria-selected={tab.value === this.state.activeTab}
                    >
                        <button
                            className={classNames(styles.tab, {
                                [styles.tabActive]: tab.value === this.state.activeTab,
                            })}
                            onClick={() => this.onTabClick(tab.value)}
                        >
                            {tab.label}
                        </button>
                    </li>
                ))}
        </ul>
    )
}
