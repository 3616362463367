import { faPlus, IconDefinition } from "@fortawesome/pro-solid-svg-icons"
import classNames from "classnames/bind"
import React from "react"

import { useServiceLocalization, useServiceModal } from "../../../../../../../../../pre-v3/services"
import {
    SelectValue,
    SelectValueUtil,
} from "../../../../../../../../../pre-v3/utils/SelectValue.util"
import { Button } from "../../../../../../../../components/button/Button.component"
import { MenuButton } from "../../../../../../../../components/menu/menu-button/MenuButton.component"
import { SearchableMenu } from "../../../../../../../../components/menu/searchable-menu/SearchableMenu.component"
import {
    PillContainer,
    PillProps,
} from "../../../../../../../../components/pill-container/PillContainer.component"
import {
    RegistryCheckTrustFactor,
    RegistryCheck,
    compareRegistryCheck,
} from "../../../../../../../../services/TrustProfile.service"
import styles from "./RegistryCheck.module.scss"
import {
    RegistryCheckModalContainer,
    RegistryCheckModalActions,
} from "./RegistryCheckModalContainer.component"

interface Props {
    className?: string
    disabled?: boolean
    trustFactor: RegistryCheckTrustFactor
    onEdit(trustFactor: RegistryCheckTrustFactor): void
}
export function RegistryCheckComponent(props: Props): JSX.Element {
    const { className, disabled, trustFactor, onEdit } = props

    const localization = useServiceLocalization()
    const modalService = useServiceModal()

    const options = getRegistryCheckSelectOptions([
        ...trustFactor.unusedRegistryCheck,
        ...trustFactor.usedRegistryCheck,
    ])
    const value = getRegistryCheckSelectValues(trustFactor.usedRegistryCheck)

    function moveRegistryCheckToUsed(registryCheckToMove: RegistryCheck) {
        onEdit({
            ...trustFactor,
            usedRegistryCheck: [...trustFactor.usedRegistryCheck, registryCheckToMove].sort(
                (registryCheckA, registryCheckB) =>
                    compareRegistryCheck(localization.getLocale(), registryCheckA, registryCheckB)
            ),
            unusedRegistryCheck: trustFactor.unusedRegistryCheck.filter(
                (registryCheck) =>
                    getRegistryCheckId(registryCheck) !== getRegistryCheckId(registryCheckToMove)
            ),
        })
    }

    function moveRegistryCheckToUnused(registryCheckToMoveOut: RegistryCheck) {
        onEdit({
            ...trustFactor,
            usedRegistryCheck: trustFactor.usedRegistryCheck.filter(
                (registryCheck) =>
                    getRegistryCheckId(registryCheck) !== getRegistryCheckId(registryCheckToMoveOut)
            ),
            unusedRegistryCheck: [...trustFactor.unusedRegistryCheck, registryCheckToMoveOut].sort(
                (registryCheckA, registryCheckB) =>
                    compareRegistryCheck(localization.getLocale(), registryCheckA, registryCheckB)
            ),
        })
    }

    function editRegistryCheck(registryCheck: RegistryCheck) {
        props.onEdit({
            ...trustFactor,
            usedRegistryCheck: trustFactor.usedRegistryCheck.map((usedRegistryCheck) => {
                return getRegistryCheckId(usedRegistryCheck) === getRegistryCheckId(registryCheck)
                    ? registryCheck
                    : usedRegistryCheck
            }),
        })
    }

    function onCreateRegistryCheck() {
        modalService
            .open(
                localization.getString("addRegistryKey"),
                {
                    component: RegistryCheckModalContainer,
                    maxWidth: "lg",
                },
                RegistryCheckModalActions
            )
            .onClose(moveRegistryCheckToUsed)
    }

    function onEditRegistryCheck(registryCheckId: string) {
        const registryCheckToEdit = trustFactor.usedRegistryCheck.find(
            (registryCheck) => getRegistryCheckId(registryCheck) === registryCheckId
        )

        if (registryCheckToEdit) {
            modalService
                .open(
                    localization.getString("editRegistryCheck"),
                    {
                        component: RegistryCheckModalContainer,
                        props: { registryCheck: registryCheckToEdit },
                        maxWidth: "lg",
                    },
                    RegistryCheckModalActions
                )
                .onClose(editRegistryCheck)
        }
    }

    return (
        <MultiSelectPill
            className={className}
            disabled={disabled}
            label={localization.getString("registryKey")}
            options={options}
            value={value}
            newOption={{
                label: localization.getString("addARegistryKey"),
                onClick: onCreateRegistryCheck,
            }}
            onPillClick={onEditRegistryCheck}
            onSelect={(registryCheckId: string) => {
                const registryCheckToMove = trustFactor.unusedRegistryCheck.find(
                    (registryCheck) => getRegistryCheckId(registryCheck) === registryCheckId
                )

                if (registryCheckToMove) {
                    moveRegistryCheckToUsed(registryCheckToMove)
                }
            }}
            onUnSelect={(registryCheckId: string) => {
                const registryCheckToMoveOut = trustFactor.usedRegistryCheck.find(
                    (registryCheck) => getRegistryCheckId(registryCheck) === registryCheckId
                )

                if (registryCheckToMoveOut) {
                    moveRegistryCheckToUnused(registryCheckToMoveOut)
                }
            }}
        />
    )
}

function getRegistryCheckSelectValues(registryChecks: RegistryCheck[]): string[] {
    return registryChecks.map(getRegistryCheckId)
}

function getRegistryCheckSelectOptions(registryChecks: RegistryCheck[]): SelectValue[] {
    return registryChecks.map((registryCheck) => {
        return {
            displayName: registryCheck.name,
            value: getRegistryCheckId(registryCheck),
        }
    })
}

function getRegistryCheckId(registryChecks: RegistryCheck): string {
    return registryChecks.id
}

interface MultiSelectPillProps {
    value: string[]
    options: SelectValue[]
    className?: string
    disabled?: boolean
    onPillClick?: (value: string) => void
    newOption?: {
        onClick: () => void
        label?: string
    }
    label: string
    icon?: IconDefinition
    onSelect?: (value: string) => void
    onUnSelect?: (value: string) => void
}

function MultiSelectPill(props: MultiSelectPillProps) {
    const localization = useServiceLocalization()

    const {
        options,
        disabled,
        value,
        onPillClick,
        className,
        newOption,
        label,
        icon = faPlus,
        onSelect,
        onUnSelect,
    } = props
    const { onClick: newOptionClick, label: newOptionLabel = localization.getString("add") } =
        newOption || {}

    const searchableMenuItems = options
        .filter((option) => {
            return !value.find((v) => SelectValueUtil.getSerializedValue(option) === v)
        })
        .map((option) => {
            return {
                label: SelectValueUtil.getSerializedDisplayName(option),
                onClick: () => {
                    onSelect?.(SelectValueUtil.getSerializedValue(option))
                },
            }
        })

    const noSearchableItem = searchableMenuItems.length === 0

    return (
        <div className={classNames(styles.multiSelectPill, className)}>
            {value.length > 0 && (
                <PillContainer
                    pills={value.map((v): PillProps => {
                        const option = options.find((option) => {
                            return SelectValueUtil.getSerializedValue(option) === v
                        })
                        if (!option) {
                            console.warn(
                                `Warning: MultiSelectPill provided value ${v}, is not available in options.`
                            )
                        }

                        const optionValue = option ? SelectValueUtil.getSerializedValue(option) : v
                        const displayName = option
                            ? SelectValueUtil.getSerializedDisplayName(option)
                            : v

                        function onUnselectValue() {
                            onUnSelect?.(v)
                        }

                        function onClick() {
                            onPillClick?.(v)
                        }

                        return {
                            id: optionValue,
                            label: displayName,
                            disabled: disabled,
                            onClick: onClick,
                            onDelete: onUnselectValue,
                            buttonAriaLabel: disabled
                                ? undefined
                                : localization.getString("editSomething", displayName),
                            iconAriaLabel: disabled
                                ? undefined
                                : localization.getString("removeSomething", displayName),
                        }
                    })}
                />
            )}
            {!disabled && (
                <>
                    {noSearchableItem && newOption && (
                        <Button
                            icon={icon}
                            onClick={newOptionClick}
                            className={styles.newOptionButton}
                        >
                            {newOptionLabel}
                        </Button>
                    )}
                    {!noSearchableItem && (
                        <MenuButton label={label} icon={icon} className={styles.menuContainer}>
                            <SearchableMenu
                                items={searchableMenuItems}
                                staticItem={
                                    newOption && {
                                        type: "button",
                                        label: newOptionLabel,
                                        icon: icon,
                                        className: styles.newOptionButton,
                                        onClick: newOptionClick,
                                    }
                                }
                            />
                        </MenuButton>
                    )}
                </>
            )}
        </div>
    )
}
