import * as React from "react"
import { Chart } from "."

interface Props {
    data?: { displayName: string; value: number; color?: string }[]
    title?: string
    subtitle?: string
    options?: Highcharts.PlotBarOptions
    showLegend?: boolean
    showXAxis?: boolean
    showYAxis?: boolean
}

export function StackedBarChart(props: Props) {
    return (
        <Chart
            title={props.title}
            subtitle={props.subtitle}
            options={{
                chart: {
                    backgroundColor: "transparent",
                    type: "bar",
                },
                legend: {
                    enabled: props.showLegend,
                    layout: "horizontal",
                    align: "center",
                    verticalAlign: "bottom",
                    symbolRadius: 0,
                    itemStyle: {
                        color: "#222C36",
                        fontWeight: "600",
                    },
                },
                xAxis: {
                    visible: props.showXAxis,
                    lineWidth: 0,
                    minorGridLineWidth: 0,
                },
                yAxis: {
                    visible: props.showYAxis,
                    lineColor: "transparent",
                    lineWidth: 0,
                    minorGridLineWidth: 0,
                    labels: {
                        enabled: false,
                    },
                    minorTickLength: 0,
                    tickLength: 0,
                },
                plotOptions: {
                    bar: {
                        pointWidth: 50,
                        borderWidth: 0,
                        ...props.options,
                    },
                    series: {
                        stacking: "normal",
                        dataLabels: {
                            enabled: false,
                        },
                    },
                },
                series: props.data?.map((d) => {
                    return {
                        name: d.displayName,
                        data: [d.value],
                        type: "bar",
                        color: d.color,
                    }
                }),
            }}
        />
    )
}
