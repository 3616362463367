import React, { ReactNode } from "react"
import TrustProviderAppDeploymentSettingsTemplate from "./TrustProviderAppDeploymentSettings.template"
import { LocalizationService } from "../../../../services/localization/Localization.service"
import { LinkService } from "../../../../services/link/Link.service"
import ActionBarService from "../../../../services/ActionBar.service"

interface Props {
    canUpdateInviteCode?: boolean
    canUpdateMdmSetting?: boolean
}

export class TrustProviderAppDeploymentSettings extends React.Component<Props, {}> {
    public render(): ReactNode {
        return TrustProviderAppDeploymentSettingsTemplate.call(this)
    }

    public componentDidMount(): void {
        this.actionBarService.setItems(this.localizationService.getString("appDeployment"), [
            {
                label: this.localizationService.getString("desktopAndMobile"),
            },
        ])
    }

    private localizationService: LocalizationService = new LocalizationService()
    private actionBarService: ActionBarService = new ActionBarService()
    private linkService: LinkService = new LinkService()
}
