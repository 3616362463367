import * as React from "react"
import styles from "./Status.module.scss"
import classNames from "classnames/bind"
import { InfoTip } from "../info-tip/InfoTip.component"

export function BaseStatus({
    tooltip,
    text,
    icon,
    iconClass,
}: {
    tooltip?: string
    text?: string
    iconClass: string
    icon: React.ReactNode
}) {
    const iconComponent = <span className={classNames(styles.statusIcon, iconClass)}>{icon}</span>
    // if there's a tooltip but no text, wrap the icon in the tooltip
    if (tooltip && !text) {
        return (
            <div className={styles.container}>
                <InfoTip message={tooltip} component={iconComponent} />
            </div>
        )
    }

    return (
        <div className={styles.container}>
            {iconComponent}
            {text && <div className={styles.statusLabel}>{text}</div>}
            {tooltip && <InfoTip message={tooltip} className={styles.failedTooltip} />}
        </div>
    )
}
