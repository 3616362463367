import React, { ReactNode } from "react"
import ServicesEditStatusDialogTemplate from "./ServicesEditStatusDialog.template"
import { ServiceManage, ManageService } from "../../../../services/Manage.service"
import { LocalizationService } from "../../../../services/localization/Localization.service"
import { Bind } from "../../../../decorators/Bind.decorator"

export class ServicesEditStatusDialog extends React.Component<
    ServicesEditStatusDialogProps,
    ServicesEditStatusDialogState
> {
    public state: ServicesEditStatusDialogState = {}

    public render(): ReactNode {
        return ServicesEditStatusDialogTemplate.call(this)
    }

    private localizationService: LocalizationService = new LocalizationService()
    private manageService: ManageService = new ManageService()

    @Bind
    private onConfirm(): void {
        if (!this.props.service) {
            this.props.onClose(false)
            return
        }
        this.setState({ loading: true, error: "" })
        if (this.props.service.enabled) {
            this.manageService.disableRegisteredService(this.props.service.id).then(
                () => {
                    this.setState({ loading: false, error: "" })
                    this.props.onClose(true)
                },
                () => {
                    this.setState({
                        loading: false,
                        error: this.localizationService.getString("failedToUpdateServiceStatus"),
                    })
                }
            )
        } else {
            this.manageService.enableRegisteredService(this.props.service.id).then(
                () => {
                    this.setState({ loading: false, error: "" })
                    this.props.onClose(true)
                },
                () => {
                    this.setState({
                        loading: false,
                        error: this.localizationService.getString("failedToUpdateServiceStatus"),
                    })
                }
            )
        }
    }
}

interface ServicesEditStatusDialogProps {
    service: ServiceManage
    open: boolean
    onClose: (success: boolean) => void
}

interface ServicesEditStatusDialogState {
    loading?: boolean
    error?: string
}
