import React from "react"
import { Route, Switch } from "react-router-dom"

import { useFeatureFlags } from "../../../hooks/useFeatureFlags.hook"
import { LicenseEnforcementBanner } from "../../../pages/shared/LicenseEnforcementBanner.component"
import { ROUTE } from "../../../routes"
import { Loader } from "../../components/loader/Loader.component"
import { useGetAdminInfo, useHasGranularTrustMigrationOccurred } from "../../services/Org.service"
import { DeviceList } from "./list/DeviceList.view"
import { DeviceOverview } from "./overview/DeviceOverview.view"
import useTitle from "../../../hooks/useTitle.hook"

export function Devices(): JSX.Element {
    useTitle(["devices", "directory", "adminConsole"])

    const adminInfoQuery = useGetAdminInfo()

    const hasGranularTrustMigrationOcurredResponse = useHasGranularTrustMigrationOccurred()

    const { data: featureFlags, status: featureFlagsStatus } = useFeatureFlags()

    if (
        adminInfoQuery.status !== "success" ||
        hasGranularTrustMigrationOcurredResponse.status !== "success" ||
        featureFlagsStatus !== "success"
    ) {
        return <Loader children isLoading center />
    }

    const { canWriteAll } = adminInfoQuery.data
    const hasMigrated = hasGranularTrustMigrationOcurredResponse.data

    return (
        <React.Fragment>
            {featureFlags.adminConsole.showLicenseEnforcementBannerInDeviceDirectory && (
                <LicenseEnforcementBanner />
            )}
            <Switch>
                <Route path={ROUTE.DEVICES_DETAILS}>
                    <DeviceOverview canWriteAll={canWriteAll} />
                </Route>
                <Route>
                    <DeviceList canWriteAll={canWriteAll} hasMigrated={hasMigrated} />
                </Route>
            </Switch>
        </React.Fragment>
    )
}
