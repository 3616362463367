import { UseQueryResult, useQuery, useQueryClient } from "@tanstack/react-query"

import { useServiceLocalization } from "../../pre-v3/services/localization/Localization.service"
import { Paginated, PaginatedSearch } from "../../pre-v3/utils/AgGrid.util"
import { DateUtil } from "../../pre-v3/utils/Date.util"
import { PatternUtil } from "../../pre-v3/utils/Pattern.util"
import { AccessTierApi, AccessTierRes } from "../api/AccessTier.api"
import { ConnectorApi, ConnectorRes } from "../api/Connector.api"
import { DeviceApi, DeviceRes } from "../api/Device.api"
import {
    PRIVATE_RESOURCE_NOT_FOUND,
    AccessActivityOrderByReq,
    AccessActivityRes,
    GetAccessActivitiesParams,
    GetPrivateResourcesParams,
    GetResourceIpsParams,
    GetServiceTunnelsParams,
    NetworkTypeRes,
    ResourceIpOrderByReq,
    ResourceIpRes,
    ServiceTunnelOrderByReq,
    ServiceTunnelDetailsRes,
    PrivateResourceApi,
    PrivateResourceDetailsRes,
    PrivateResourceOrderByReq,
    PrivateResourceRes,
} from "../api/PrivateResource.api"
import { ServiceTunnelApi } from "../api/ServiceTunnel.api"
import { UserApi } from "../api/User.api"
import { ApiFunction } from "./shared/QueryKey"

const serviceName = "PrivateResourceService"

interface UseGetPrivateResourcesResult {
    getPrivateResources(
        search: PaginatedSearch<SortById, FilterById>
    ): Promise<Paginated<PrivateResource>>
    clearCache(): Promise<void>
}

export function useGetPrivateResources(): UseGetPrivateResourcesResult {
    const privateResourceApi = new PrivateResourceApi()

    const queryClient = useQueryClient()

    return {
        getPrivateResources: (search) =>
            queryClient.ensureQueryData({
                queryKey: [ApiFunction.GET_PRIVATE_RESOURCES, search, serviceName],
                queryFn: async (): Promise<Paginated<PrivateResource>> => {
                    const { resources, count } = await privateResourceApi.getPrivateResources(
                        getPrivateResourcesParams(search)
                    )
                    return { data: resources.map(mapPrivateResourceRes), total: count }
                },
            }),
        clearCache: () => queryClient.resetQueries([ApiFunction.GET_PRIVATE_RESOURCES]),
    }
}

export function useGetPrivateResourceById(
    id: string
): UseQueryResult<PrivateResourceDetails | null> {
    const privateResourceApi = new PrivateResourceApi()

    return useQuery({
        queryKey: [ApiFunction.GET_PRIVATE_RESOURCE_BY_ID, id, serviceName],
        queryFn: async (): Promise<PrivateResourceDetails | null> => {
            try {
                const privateResourceRes = await privateResourceApi.getPrivateResourceById(id)
                return mapPrivateResourceDetailsRes(privateResourceRes)
            } catch (error) {
                if (error === PRIVATE_RESOURCE_NOT_FOUND) return null
                throw error
            }
        },
    })
}

interface UseGetNetworksResult {
    getNetworks(search: string): Promise<Network[]>
    clearCache(): Promise<void>
}

export function useGetNetworks(): UseGetNetworksResult {
    const accessTierApi = new AccessTierApi()
    const connectorApi = new ConnectorApi()

    const queryClient = useQueryClient()
    const localization = useServiceLocalization()

    const sortNetworks = (a: Network, b: Network): number =>
        a.name.localeCompare(b.name, localization.getLocale())

    return {
        getNetworks: (search) =>
            queryClient.ensureQueryData({
                queryKey: [ApiFunction.GET_NETWORKS, search, serviceName],
                queryFn: async (): Promise<Network[]> => {
                    const [{ access_tiers }, { satellites }] = await Promise.all([
                        accessTierApi.getAccessTiers({ name: search }),
                        connectorApi.getConnectors({ display_name: search }),
                    ])

                    return [
                        ...access_tiers.map(mapAccessTierResToNetwork),
                        ...satellites.map(mapConnectorResToNetwork),
                    ].sort(sortNetworks)
                },
            }),
        clearCache: () => queryClient.resetQueries([ApiFunction.GET_NETWORKS]),
    }
}

export function useGetServiceTunnels(): UseQueryResult<ServiceTunnel[]> {
    const serviceTunnelApi = new ServiceTunnelApi()

    return useQuery({
        queryKey: [ApiFunction.GET_SERVICE_TUNNELS, serviceName],
        queryFn: async () => {
            const { service_tunnels } = await serviceTunnelApi.getServiceTunnels()
            return service_tunnels
        },
    })
}

interface UseGetUsersResult {
    getUsers(search: string): Promise<User[]>
    clearCache(): Promise<void>
}

export function useGetUsers(): UseGetUsersResult {
    const userApi = new UserApi()

    const queryClient = useQueryClient()

    return {
        getUsers: (search) =>
            queryClient.ensureQueryData({
                queryKey: [ApiFunction.GET_USERS, search, serviceName],
                queryFn: async (): Promise<User[]> => {
                    const { endusers } = await userApi.getUsers({ active: "true", name: search })
                    return endusers
                },
            }),
        clearCache: () => queryClient.resetQueries([ApiFunction.GET_USERS]),
    }
}

interface UseGetDevicesResult {
    getDevices(search: string): Promise<Device[]>
    clearCache(): Promise<void>
}

export function useGetDevices(): UseGetDevicesResult {
    const deviceApi = new DeviceApi()

    const queryClient = useQueryClient()

    return {
        getDevices: (search) =>
            queryClient.ensureQueryData({
                queryKey: [ApiFunction.GET_DEVICES, search, serviceName],
                queryFn: async (): Promise<Device[]> => {
                    const { devices } = await deviceApi.getDevices({ name: search })
                    return devices.map(mapDeviceRes)
                },
            }),
        clearCache: () => queryClient.resetQueries([ApiFunction.GET_DEVICES]),
    }
}

interface UseGetServiceTunnelsResult {
    getServiceTunnels(
        search: PaginatedSearch<ServiceTunnelSortById, ServiceTunnelFilterById>
    ): Promise<Paginated<ServiceTunnelDetails>>
    clearCache(): Promise<void>
}

export function useGetServiceTunnelsByPrivateResource(
    privateResource: PrivateResourceDetails
): UseGetServiceTunnelsResult {
    const privateResourceApi = new PrivateResourceApi()

    const queryClient = useQueryClient()

    return {
        getServiceTunnels: (search) =>
            queryClient.ensureQueryData({
                queryKey: [
                    ApiFunction.GET_SERVICE_TUNNELS,
                    privateResource.id,
                    search,
                    serviceName,
                ],
                queryFn: async (): Promise<Paginated<ServiceTunnelDetails>> => {
                    const { service_tunnels, count } =
                        await privateResourceApi.getServiceTunnelsByPrivateResourceId(
                            privateResource.id,
                            getServiceTunnelsParams(search)
                        )

                    return {
                        data: service_tunnels.map(mapServiceTunnelDetailsRes),
                        total: count,
                    }
                },
            }),
        clearCache: () => queryClient.resetQueries([ApiFunction.GET_SERVICE_TUNNELS]),
    }
}

interface UseGetAccessActivitiesResult {
    getAccessActivities(
        search: PaginatedSearch<AccessActivitySortById, AccessActivityFilterById>
    ): Promise<Paginated<AccessActivity>>
    clearCache(): Promise<void>
}

export function useGetAccessActivitiesByPrivateResource(
    privateResource: PrivateResourceDetails
): UseGetAccessActivitiesResult {
    const privateResourceApi = new PrivateResourceApi()

    const queryClient = useQueryClient()

    return {
        getAccessActivities: (search) =>
            queryClient.ensureQueryData({
                queryKey: [
                    ApiFunction.GET_ACCESS_ACTIVITIES,
                    privateResource.id,
                    search,
                    serviceName,
                ],
                queryFn: async (): Promise<Paginated<AccessActivity>> => {
                    const { user_device_info, count } =
                        await privateResourceApi.getAccessActivitiesByPrivateResourceId(
                            privateResource.id,
                            getAccessActivitiesParams(search)
                        )

                    return {
                        data: user_device_info.map(mapAccessActivityRes),
                        total: count,
                    }
                },
            }),
        clearCache: () => queryClient.resetQueries([ApiFunction.GET_ACCESS_ACTIVITIES]),
    }
}

interface UseGetResourceIpsResult {
    getResourceIps(
        search: PaginatedSearch<ResourceIpSortById, ResourceIpFilterById>
    ): Promise<Paginated<ResourceIp>>
    clearCache(): Promise<void>
}

export function useGetResourceIpsByPrivateResource(
    privateResource: PrivateResourceDetails
): UseGetResourceIpsResult {
    const privateResourceApi = new PrivateResourceApi()

    const queryClient = useQueryClient()

    return {
        getResourceIps: (search) =>
            queryClient.ensureQueryData({
                queryKey: [ApiFunction.GET_RESOURCE_IPS, privateResource.id, search, serviceName],
                queryFn: async (): Promise<Paginated<ResourceIp>> => {
                    const { ip, count } =
                        await privateResourceApi.getResourceIpsByPrivateResourceId(
                            privateResource.id,
                            getResourceIpsParams(search)
                        )

                    return {
                        data: ip.map(mapResourceIpRes),
                        total: count,
                    }
                },
            }),
        clearCache: () => queryClient.resetQueries([ApiFunction.GET_RESOURCE_IPS]),
    }
}

// Types

export enum SortById {
    NAME = "name",
    NETWORK = "network",
    UNIQUE_USERS_DEVICES = "uniqueUsersDevices",
    LAST_REPORTED = "lastReported",
}

export enum FilterById {
    NETWORKS = "networks",
    SERVICE_TUNNELS = "serviceTunnels",
    USERS = "users",
    ADDRESS = "address",
}

export interface PrivateResource {
    id: string
    address: string
    addressedWith: AddressedWith
    network: Network
    serviceTunnels: ServiceTunnel[]
    uniqueUsersDevicesCount: number
    lastReportedAt: number
}

export interface PrivateResourceDetails {
    id: string
    address: string
    addressedWith: AddressedWith
    network: Network
}

export enum AddressedWith {
    IP = "IP",
    FQDN = "FQDN",
}

export enum NetworkType {
    AccessTier = "ACCESS_TIER",
    Connector = "CONNECTOR",
}

export interface Network {
    id: string
    name: string
    type: NetworkType
}

export enum ServiceTunnelSortById {
    SERVICE_TUNNEL = "serviceTunnel",
}

export enum ServiceTunnelFilterById {
    SERVICE_TUNNELS = "serviceTunnels",
    PORTS = "ports",
    PROTOCOLS = "protocols",
}

export interface ServiceTunnel {
    id: string
    name: string
}

export interface ServiceTunnelDetails {
    id: string
    name: string
    ports: number[]
    protocols: string[]
}

export interface User {
    id: string
    name: string
    email: string
}

export interface Device {
    id: string
    name?: string
    serialNumber: string
}

export enum AccessActivitySortById {
    USER = "user",
    DEVICE = "device",
    LAST_REPORTED = "lastReported",
}

export enum AccessActivityFilterById {
    USERS = "users",
    DEVICES = "devices",
    LAST_REPORTED = "lastReported",
}

export interface AccessActivity {
    id: string
    user: User
    device: Device
    lastReportedAt: number
}

export enum ResourceIpSortById {
    LAST_SEEN = "lastSeen",
    IP = "ip",
}

export enum ResourceIpFilterById {
    IPS = "ips",
    SERVICE_TUNNELS = "serviceTunnels",
}

export interface ResourceIp {
    ip: string
    serviceTunnels: ServiceTunnel[]
    lastSeenAt: number
}

// Helper Functions

const networkTypeResDict: Record<NetworkTypeRes, NetworkType> = {
    access_tier: NetworkType.AccessTier,
    connector: NetworkType.Connector,
}

function mapPrivateResourceDetailsRes(
    res: PrivateResourceRes | PrivateResourceDetailsRes
): PrivateResourceDetails {
    return {
        id: res.id,
        address: res.address,
        addressedWith: PatternUtil.IPV4_ADDRESS.test(res.address)
            ? AddressedWith.IP
            : AddressedWith.FQDN,
        network: {
            id: res.network.network_id,
            name: res.network.network_name,
            type: networkTypeResDict[res.network.network_type],
        },
    }
}

function mapPrivateResourceRes(res: PrivateResourceRes): PrivateResource {
    return {
        ...mapPrivateResourceDetailsRes(res),
        serviceTunnels: res.service_tunnel,
        uniqueUsersDevicesCount: res.unique_count,
        lastReportedAt: DateUtil.convertLargeTimestamp(res.updated_at),
    }
}

function mapServiceTunnelDetailsRes(res: ServiceTunnelDetailsRes): ServiceTunnelDetails {
    return {
        id: res.id,
        name: res.name,
        ports: res.protocols.includes("icmp")
            ? res.dst_ports.filter((port) => port !== 0)
            : res.dst_ports,
        protocols: res.protocols,
    }
}

function mapAccessActivityRes(res: AccessActivityRes): AccessActivity {
    return {
        id: `${res.enduser.enduser_id}-${res.device.device_id}`,
        user: {
            id: res.enduser.enduser_id,
            name: res.enduser.user_name,
            email: res.enduser.email,
        },
        device: {
            id: res.device.device_id,
            name: res.device.device_name || undefined,
            serialNumber: res.device.serialnumber,
        },
        lastReportedAt: DateUtil.convertLargeTimestamp(res.updated_at),
    }
}

function mapResourceIpRes(res: ResourceIpRes): ResourceIp {
    return {
        ip: res.dst_ip,
        serviceTunnels: res.service_tunnel,
        lastSeenAt: DateUtil.convertLargeTimestamp(res.last_seen_at),
    }
}

function mapDeviceRes(res: DeviceRes): Device {
    return {
        id: res.id,
        name: res.name || undefined,
        serialNumber: res.serial_number,
    }
}

function getPrivateResourcesParams(
    search: PaginatedSearch<SortById, FilterById>
): Partial<GetPrivateResourcesParams> {
    return {
        skip: search.skip,
        limit: search.limit,
        sort: search.sortModel?.[0]?.sort,
        order_by: search.sortModel?.[0]?.colId
            ? orderByReqDict[search.sortModel[0].colId]
            : undefined,
        network_id: search.filterModel?.networks?.filter.split("|"),
        service_tunnel_id: search.filterModel?.serviceTunnels?.filter.split("|"),
        enduser_id: search.filterModel?.users?.filter.split("|"),
        address: search.filterModel?.address?.filter.split("|"),
    }
}

const orderByReqDict: Record<SortById, PrivateResourceOrderByReq> = {
    [SortById.NAME]: "address",
    [SortById.NETWORK]: "network_name",
    [SortById.UNIQUE_USERS_DEVICES]: "unique_count",
    [SortById.LAST_REPORTED]: "updated_at",
}

function getServiceTunnelsParams(
    search: PaginatedSearch<ServiceTunnelSortById, ServiceTunnelFilterById>
): Partial<GetServiceTunnelsParams> {
    return {
        skip: search.skip,
        limit: search.limit,
        sort: search.sortModel?.[0]?.sort,
        order_by: search.sortModel?.[0]?.colId
            ? serviceTunnelOrderByReqDict[search.sortModel[0].colId]
            : undefined,
        service_tunnel_id: search.filterModel?.serviceTunnels?.filter.split("|"),
        dst_port: search.filterModel?.ports?.filter.split("|").reduce(reduceParseIntegers, []),
        protocol: search.filterModel?.protocols?.filter.split("|"),
    }
}

const serviceTunnelOrderByReqDict: Record<ServiceTunnelSortById, ServiceTunnelOrderByReq> = {
    [ServiceTunnelSortById.SERVICE_TUNNEL]: "service_tunnel_name",
}

function getAccessActivitiesParams(
    search: PaginatedSearch<AccessActivitySortById, AccessActivityFilterById>
): Partial<GetAccessActivitiesParams> {
    const timeRange = search.filterModel?.lastReported?.filter
        ? DateUtil.deserializeTimeRange(search.filterModel.lastReported.filter)
        : undefined

    return {
        skip: search.skip,
        limit: search.limit,
        sort: search.sortModel?.[0]?.sort,
        order_by: search.sortModel?.[0]?.colId
            ? accessActivityOrderByReqDict[search.sortModel[0].colId]
            : undefined,
        email: search.filterModel?.users?.filter.split("|"),
        serial_number: search.filterModel?.devices?.filter.split("|"),
        start_time: timeRange?.start && DateUtil.convertToLargeTimestamp(timeRange.start),
        end_time: timeRange?.end && DateUtil.convertToLargeTimestamp(timeRange.end),
    }
}

const accessActivityOrderByReqDict: Record<AccessActivitySortById, AccessActivityOrderByReq> = {
    [AccessActivitySortById.USER]: "user_name",
    [AccessActivitySortById.DEVICE]: "device_name",
    [AccessActivitySortById.LAST_REPORTED]: "updated_at",
}

function getResourceIpsParams(
    search: PaginatedSearch<ResourceIpSortById, ResourceIpFilterById>
): Partial<GetResourceIpsParams> {
    return {
        skip: search.skip,
        limit: search.limit,
        sort: search.sortModel?.[0]?.sort,
        order_by: search.sortModel?.[0]?.colId
            ? resourceIpOrderByReqDict[search.sortModel[0].colId]
            : undefined,
        dst_ip: search.filterModel?.ips?.filter.split("|"),
        service_tunnel_id: search.filterModel?.serviceTunnels?.filter.split("|"),
    }
}

const resourceIpOrderByReqDict: Record<ResourceIpSortById, ResourceIpOrderByReq> = {
    [ResourceIpSortById.LAST_SEEN]: "updated_at",
    [ResourceIpSortById.IP]: "dst_ip",
}

function mapAccessTierResToNetwork(res: AccessTierRes): Network {
    return {
        id: res.id,
        name: res.name,
        type: NetworkType.AccessTier,
    }
}

function mapConnectorResToNetwork(res: ConnectorRes): Network {
    return {
        id: res.id,
        name: res.display_name,
        type: NetworkType.Connector,
    }
}

function reduceParseIntegers(integers: number[], value: string): number[] {
    const integer = Number.parseInt(value)
    return Number.isNaN(integer) ? integers : [...integers, integer]
}
