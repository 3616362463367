import DialogContent from "@mui/material/DialogContent"
import React from "react"

import { ModalRef, useServiceLocalization } from "../../../../../../../../pre-v3/services"
import { CollectionUtil } from "../../../../../../../../pre-v3/utils/Collection.util"
import { SearchInput } from "../../../../../../../components/search-input/SearchInput.component"
import { TrustFactors, IntegrationTrustFactors } from "../useTrustFactors.hook"
import styles from "./AddTrustFactorModal.module.scss"
import { BanyanFactor } from "./BanyanFactor.component"
import { IntegrationFactor } from "./IntegrationFactor.component"

interface Props {
    modalRef: ModalRef
    banyanTrustFactors: TrustFactors
    integrationTrustFactors: IntegrationTrustFactors
}

export function AddTrustFactorModal(props: Props): JSX.Element {
    const localization = useServiceLocalization()

    const [search, setSearch] = React.useState("")

    return (
        <DialogContent className={styles.container}>
            <SearchInput
                placeholder={localization.getString("search")}
                value={search}
                onChangeValue={setSearch}
            />
            {props.banyanTrustFactors.unused.map(
                (trustFactor): JSX.Element => (
                    <BanyanFactor
                        key={trustFactor.id}
                        trustFactor={trustFactor}
                        search={search}
                        onAddTrustFactor={props.modalRef.close}
                    />
                )
            )}
            {CollectionUtil.entries(props.integrationTrustFactors).map(
                ([partner, { selected, unselected }]): JSX.Element => {
                    if (selected)
                        return (
                            <IntegrationFactor
                                key={selected.integrationId}
                                {...selected}
                                search={search}
                                onAddTrustFactor={props.modalRef.close}
                            />
                        )

                    return (
                        <React.Fragment key={partner}>
                            {Object.values(unselected).map((integration, index) => {
                                if (!integration)
                                    return <React.Fragment key={`${partner}-${index}`} />
                                return (
                                    <IntegrationFactor
                                        key={integration.integrationId}
                                        {...integration}
                                        search={search}
                                        onAddTrustFactor={props.modalRef.close}
                                    />
                                )
                            })}
                        </React.Fragment>
                    )
                }
            )}
        </DialogContent>
    )
}
