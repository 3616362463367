import { BaseApi } from "./Base.api"
import { Singleton } from "../../pre-v3/decorators/Singleton.decorator"

@Singleton("ServiceTestConnectionApi")
export class ServiceTestConnectionApi extends BaseApi {
    public testConnection(serviceId: string): Promise<ServiceTestConnectionRes> {
        return this.post(
            "/api/v1/service_connection_test",
            {
                ServiceID: serviceId,
            },
            {
                default: this.localization.getString("failedToTestServiceConnection"),
            }
        )
    }
}

export interface ServiceTestConnectionRes {
    FrontendConnStatus: ServiceConnectionStatusRes
    BackendConnStatus: BackendConnectionStatusRes[]
}

interface ServiceConnectionStatusRes {
    DomainNameResolutionStatus: boolean
    Reachability: boolean
    ErrorMsg: string
}

export interface BackendConnectionStatusRes {
    SiteName: string
    AccessTierStatus: ServiceConnectionStatusRes
}
