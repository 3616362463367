import { AuthStore } from "../../pre-v3/services/Auth.store"
import { LocalizationService } from "../../pre-v3/services/localization/Localization.service"

export abstract class BaseApi {
    constructor() {
        this.parseResponse = this.parseResponse.bind(this)
    }

    protected get<R>(url: string, errorMap: ErrorMap): Promise<R> {
        const headers: Headers = this.getHeaders()
        return fetch(url, {
            method: "GET",
            headers: headers,
        }).then((r) => this.parseResponse(r, errorMap))
    }

    protected getBlob(url: string, errorMap: ErrorMap): Promise<Blob> {
        const headers: Headers = this.getHeaders()
        return fetch(url, {
            method: "GET",
            headers: headers,
        }).then((r) => this.parseBlobResponse(r, errorMap))
    }

    protected post<T, R>(url: string, body: T, errorMap: ErrorMap): Promise<R> {
        const headers: Headers = this.getHeaders()
        return fetch(url, {
            method: "POST",
            headers: headers,
            body: JSON.stringify(body),
        }).then((r) => this.parseResponse(r, errorMap))
    }

    protected postUpload<R>(url: string, file: File, errorMap: ErrorMap): Promise<R> {
        const headers: Headers = this.getHeaders()
        return fetch(url, {
            method: "POST",
            headers: headers,
            body: file,
        }).then((r) => this.parseResponse(r, errorMap))
    }

    protected postForm<T, R>(url: string, body: T, errorMap: ErrorMap): Promise<R> {
        const headers: Headers = this.getHeaders("application/x-www-form-urlencoded; charset=UTF-8")
        return fetch(url, {
            method: "POST",
            headers: headers,
            body: new URLSearchParams(<any>body),
        }).then((r) => this.parseResponse(r, errorMap))
    }

    protected postAuth<T, R>(url: string, token: string, body: T, errorMap: ErrorMap): Promise<R> {
        const headers: Headers = new Headers()
        headers.set("Authorization", `Bearer ${token}`)
        headers.set("Content-Type", "application/json")
        return fetch(url, {
            method: "POST",
            headers: headers,
            body: JSON.stringify(body),
        }).then((r) => this.parseResponse(r, errorMap))
    }

    protected put<T, R>(url: string, body: T, errorMap: ErrorMap): Promise<R> {
        const headers: Headers = this.getHeaders()
        return fetch(url, {
            method: "put",
            headers: headers,
            body: JSON.stringify(body),
        }).then((r) => this.parseResponse(r, errorMap))
    }

    protected async patch<T, R>(url: string, body: T, errorMap: ErrorMap): Promise<R> {
        const headers: Headers = this.getHeaders()

        const response = await fetch(url, {
            method: "PATCH",
            headers: headers,
            body: JSON.stringify(body),
        })

        return this.parseResponse(response, errorMap)
    }

    protected delete<T, R>(url: string, body: T, errorMap: ErrorMap): Promise<R> {
        const headers: Headers = this.getHeaders()
        return fetch(url, {
            method: "delete",
            headers: headers,
            body: JSON.stringify(body),
        }).then((r) => this.parseResponse(r, errorMap))
    }

    protected localization: LocalizationService = new LocalizationService()
    private authStore: AuthStore = new AuthStore()

    private getHeaders(contentType: string = "application/json"): Headers {
        const token: string = this.authStore.getJwtToken()
        const headers: Headers = new Headers()
        headers.set("Authorization", `Bearer ${token}`)
        headers.set("Content-Type", contentType)
        return headers
    }

    private parseResponse<R>(r: Response, errorMap: ErrorMap): Promise<R> {
        const isV2: boolean = r.url.includes("/v2/")
        if (r.status >= 200 && r.status < 300) {
            return r.text().then((text: string) => {
                if (text.length > 0) {
                    let json: any = {}
                    try {
                        json = JSON.parse(text)
                        if (isV2 && json.data !== undefined) {
                            return Promise.resolve(json.data)
                        } else {
                            return Promise.resolve(json)
                        }
                    } catch {
                        return Promise.resolve(text)
                    }
                }
                return Promise.resolve()
            })
        } else {
            return this.parseErrorResponse(r, errorMap, isV2)
        }
    }

    private parseBlobResponse<R>(r: Response, errorMap: ErrorMap): Promise<Blob> {
        const isV2: boolean = r.url.includes("/v2/")
        if (r.status >= 200 && r.status < 300) {
            return Promise.resolve(r.blob())
        } else {
            return this.parseErrorResponse(r, errorMap, isV2)
        }
    }

    private parseErrorResponse<R>(r: Response, errorMap: ErrorMap, isV2: boolean): Promise<R> {
        if (isV2) {
            return r.json().then(
                (res: V2Response<R>) => {
                    return Promise.reject(this.getError(res.error_code, errorMap))
                },
                (e: any) => {
                    return Promise.reject(this.localization.getString("errorNetworkRequestFailed"))
                }
            )
        } else {
            return Promise.reject(errorMap.default)
        }
    }

    private getError(code: number, errorMap: ErrorMap): string {
        return errorMap[code] || errorMap.default
    }
}

interface ErrorMap {
    [key: number]: string
    default: string
}

interface V2Response<R> {
    data: R
    error_code: number
}

export interface PaginationOptions<OrderBy extends string = string> {
    skip: number
    limit: number
    order?: "asc" | "desc"
    order_by?: OrderBy
    sort?: "asc" | "desc"
}

export type PaginatedResponse<PropertyName extends string, T> = {
    [N in PropertyName]: T[]
} & { count: number }
