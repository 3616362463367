import classNames from "classnames"
import React, { useMemo } from "react"
import { Link } from "react-router-dom"

import styles from "./TopNavigationBar.module.scss"
import { getFullName, useAdminInfo } from "../../hooks/useAdminInfo.hook"
import { useFeatureFlags } from "../../hooks/useFeatureFlags.hook"
import { ROUTE } from "../../routes"
import { SmartSearchBar } from "./SmartSearchBar.component"

export interface TopNavigationBarProps<NavigationItem> {
    headingTitle: string
    navigationItems: NavigationItem[]
    className?: string
    getNavigationItemProps(item: NavigationItem): NavigationItemProps
}

export interface NavigationItemProps {
    key: React.Key
    label: string
    to: string
    active?: boolean
    isExternalLink?: boolean
}

export function TopNavigationBar<NavigationItem>(
    props: TopNavigationBarProps<NavigationItem>
): JSX.Element {
    const classes = classNames(styles.container, props.className)

    const { data: adminInfo } = useAdminInfo()
    const { data: featureFlags } = useFeatureFlags()

    const fullName = useMemo(() => {
        if (!adminInfo) return
        return adminInfo.isImpersonated
            ? undefined
            : getFullName(adminInfo.firstName, adminInfo.lastName)
    }, [adminInfo])

    return (
        <div className={classes}>
            <div className={styles.topNavigationBackground} />
            <div className={styles.topNavigationBackgroundOverlay} />
            <nav className={styles.topNavigationBar} aria-labelledby={Element.HEADER_TITLE}>
                <div className={styles.topContainer}>
                    <h1 id={Element.HEADER_TITLE} className={styles.headingTitle}>
                        {props.headingTitle}
                    </h1>
                    <div className={styles.profileLinks}>
                        {fullName && (
                            <Link to={ROUTE.PROFILE} className={styles.profileLink}>
                                {fullName}
                            </Link>
                        )}
                        {adminInfo && (
                            <Link
                                to={ROUTE.PROFILE}
                                className={classNames(styles.profileLink, styles.orgLink)}
                            >
                                {adminInfo.orgName}
                            </Link>
                        )}
                    </div>
                </div>
                <div className={styles.bottomContainer}>
                    <ul>
                        {props.navigationItems.map((item) => {
                            const navigationItemProps = props.getNavigationItemProps(item)

                            return (
                                <li key={navigationItemProps.key}>
                                    {navigationItemProps.isExternalLink ? (
                                        <a
                                            href={navigationItemProps.to}
                                            className={styles.topNavMenuItem}
                                            target="_blank"
                                            rel="noreferrer"
                                        >
                                            {navigationItemProps.label}
                                        </a>
                                    ) : (
                                        <Link
                                            className={styles.topNavMenuItem}
                                            to={navigationItemProps.to}
                                            aria-current={
                                                navigationItemProps.active ? "page" : false
                                            }
                                        >
                                            {navigationItemProps.label}
                                        </Link>
                                    )}
                                </li>
                            )
                        })}
                    </ul>
                    {adminInfo?.orgName &&
                    featureFlags?.adminConsole.isAiAssistedAdminSearchEnabled ? (
                        <SmartSearchBar
                            organizationName={adminInfo.orgName}
                            className={styles.flex}
                        />
                    ) : (
                        <React.Fragment />
                    )}
                </div>
            </nav>
        </div>
    )
}

enum Element {
    HEADER_TITLE = "header-title",
}
