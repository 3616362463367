import React from "react"

import { useServiceLocalization } from "../../../pre-v3/services/localization/Localization.service"
import { StringUtil } from "../../../pre-v3/utils/String.util"
import { ROUTE } from "../../../routes"
import { ErrorBanner } from "../../../v3/components/banner/Banner.component"
import { Loader } from "../../../v3/components/loader/Loader.component"
import { SearchInput } from "../../../v3/components/search-input/SearchInput.component"
import { ServiceTunnel, useGetServiceTunnels } from "../../../v3/services/ServiceTunnelV2.service"
import { ServiceTunnelGrid } from "./ServiceTunnelGrid.component"
import styles from "./ServiceTunnelList.module.scss"
import { PageHeading } from "../../../components/page-heading/PageHeading.component"
import { Button, ButtonElement, ButtonType } from "../../../components/button/Button.component"
import { IconType } from "../../../components/icon/Icon.component"
import { useHistory } from "react-router-dom"
import { Tooltip } from "../../../v3/components/tooltip/Tooltip.component"

interface Props {
    canAddServiceTunnel: boolean
    enableATG: boolean
}

export function ServiceTunnelList(props: Props): JSX.Element {
    const serviceTunnelsQuery = useGetServiceTunnels(props.enableATG)

    const onRefresh: React.MouseEventHandler<HTMLButtonElement> = (event) => {
        event.preventDefault()
        serviceTunnelsQuery.refetch()
    }

    switch (serviceTunnelsQuery.status) {
        case "loading": {
            return <Loader isLoading children center medium />
        }

        case "error": {
            return (
                <div className={styles.container}>
                    <ErrorBanner children={String(serviceTunnelsQuery.error)} />
                </div>
            )
        }

        case "success": {
            return (
                <Loaded
                    canAddServiceTunnel={props.canAddServiceTunnel}
                    serviceTunnels={serviceTunnelsQuery.data}
                    onRefresh={onRefresh}
                />
            )
        }
    }
}

interface LoadedProps {
    canAddServiceTunnel: boolean
    serviceTunnels: ServiceTunnel[]
    onRefresh: React.MouseEventHandler<HTMLButtonElement>
}

function Loaded(props: LoadedProps): JSX.Element {
    const localization = useServiceLocalization()
    const history = useHistory()
    const [searchText, setSearchText] = React.useState("")

    const filteredServiceTunnels = React.useMemo(() => {
        return searchText
            ? props.serviceTunnels.filter(
                  (serviceTunnel) =>
                      StringUtil.caseInsensitiveIncludes(serviceTunnel.name, searchText) ||
                      (serviceTunnel.policy?.name
                          ? StringUtil.caseInsensitiveIncludes(
                                serviceTunnel.policy.name,
                                searchText
                            )
                          : false)
              )
            : props.serviceTunnels
    }, [props.serviceTunnels, searchText])

    function onServiceTunnelAdd() {
        history.push(ROUTE.SERVICE_TUNNELS_ADD)
    }

    return (
        <div className={styles.container}>
            <header className={styles.header}>
                <PageHeading>{localization.getString("serviceTunnels")}</PageHeading>
                <Tooltip title={localization.getString("refresh")}>
                    <Button
                        icon={IconType.REDO}
                        onClick={props.onRefresh}
                        asElement={ButtonElement.BUTTON}
                        buttonType={ButtonType.SECONDARY}
                    />
                </Tooltip>
            </header>

            <div className={styles.toolbar}>
                <SearchInput
                    value={searchText}
                    onChangeValue={setSearchText}
                    placeholder={localization.getString("search")}
                />
                {props.canAddServiceTunnel && (
                    <Button
                        buttonType={ButtonType.PRIMARY}
                        asElement={ButtonElement.BUTTON}
                        icon={IconType.PLUS}
                        onClick={onServiceTunnelAdd}
                    >
                        {localization.getString("addServiceTunnel")}
                    </Button>
                )}
            </div>
            <ServiceTunnelGrid serviceTunnels={filteredServiceTunnels} />
        </div>
    )
}
