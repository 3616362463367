import React from "react"
import { Redirect, Route, Switch, useLocation } from "react-router-dom"

import sonicWallCSELogo from "../../../images/logo-horiz-black.svg"
import { useServiceLocalization } from "../../../pre-v3/services"
import { ROUTE } from "../../../routes"
import { Background } from "../../components/background/Background.component"
import { Card } from "../../components/card/Card.component"
import { BanyanHeader } from "../../components/banyan-header/BanyanHeader.component"
import { LocalAccountForm } from "./local-account-form/LocalAccountForm.view"
import styles from "./Login.module.scss"
import { OrgForm } from "./org-form/OrgForm.view"
import { ResetPasswordForm } from "./reset-password-form/ResetPasswordForm.view"
import { ConfigureMfa } from "./configure-mfa/ConfigureMfa.view"
import { MfaForm } from "./mfa-form/MfaForm.view"

/**
 * Login page
 */
export function Login(): JSX.Element {
    const localization = useServiceLocalization()
    const location = useLocation()

    // TODO: Show banner when the Console logs the User out due to inactivity
    // https://banyan-sec.atlassian.net/browse/BC-5975

    return (
        <div className={styles.page}>
            <BanyanHeader />
            <Background className={styles.container}>
                <Card className={styles.card}>
                    <img
                        src={sonicWallCSELogo}
                        alt={localization.getString("sonicWallCse")}
                        className={styles.logo}
                    />
                    <Switch>
                        <Route path={ROUTE.ORG_FORM} component={OrgForm} />
                        <Route path={ROUTE.LOCAL_ACCOUNT_FORM} component={LocalAccountForm} />
                        <Route path={ROUTE.LOCAL_ACCOUNT_MFA} component={MfaForm} />
                        <Route path={ROUTE.LOCAL_ACCOUNT_CONFIGURE_MFA} component={ConfigureMfa} />
                        <Route path={ROUTE.RESET_PASSWORD_FORM} component={ResetPasswordForm} />
                        <Redirect to={{ pathname: ROUTE.ORG_FORM, search: location.search }} />
                    </Switch>
                </Card>
            </Background>
        </div>
    )
}
