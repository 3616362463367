import React from "react"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { IconDefinition } from "@fortawesome/pro-solid-svg-icons"
import classNames from "classnames"

import styles from "./Icon.module.scss"

/**
 * @deprecated Use the Icon component from the base component folder instead
 */
export interface IconProps {
    className?: string
    small?: boolean
    large?: boolean
    spin?: boolean
    icon: IconDefinition
}

export const Icon = React.forwardRef<SVGElement, IconProps>((props, ref) => {
    const { className, small, large, icon, ...otherProps } = props

    return (
        <FontAwesomeIcon
            {...otherProps}
            icon={icon}
            className={classNames(styles.default, className, {
                [styles.small]: small,
                [styles.large]: large,
            })}
            forwardedRef={ref}
            fixedWidth
        />
    )
})

Icon.displayName = "Icon"
