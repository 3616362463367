import React, { ReactNode } from "react"
import TrustProviderDownloadAppTemplate from "./TrustProviderDownloadApp.template"
import { LocalizationService } from "../../../../../services/localization/Localization.service"
import { LinkService } from "../../../../../services/link/Link.service"

export class TrustProviderDownloadApp extends React.Component<{}, {}> {
    public render(): ReactNode {
        return TrustProviderDownloadAppTemplate.call(this)
    }

    private localizationService: LocalizationService = new LocalizationService()
    private linkService: LinkService = new LinkService()
}
