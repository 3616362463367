import React, { ReactNode } from "react"
import { Bind } from "../../decorators/Bind.decorator"
import { LocalizationService } from "../../services/localization/Localization.service"
import { DataFilter, DataFilterType, FilterModel } from "../../utils/AgGrid.util"
import { DateUtil, TimeRange } from "../../utils/Date.util"
import { FilterMenuApi } from "../filter-menu/FilterMenu.component"
import FilterBarTemplate from "./FilterBar.template"

export class FilterBar extends React.Component<FilterBarProps, FilterBarState> {
    public state: FilterBarState = { model: {}, pills: [] }

    public componentDidMount(): void {
        if (this.props.initialModel) {
            this.onFilterChange(this.props.initialModel, true)
        }
    }

    public render(): ReactNode {
        return FilterBarTemplate.call(this)
    }

    private filterMenuApi: FilterMenuApi
    private ls: LocalizationService = new LocalizationService()

    @Bind
    private onFilterMenuReady(api: FilterMenuApi): void {
        if (api) {
            this.filterMenuApi = api
        }
    }

    @Bind
    private onShowMenu(): void {
        if (this.filterMenuApi) {
            this.filterMenuApi.show()
        }
    }

    @Bind
    private onFilterClick(key: string): void {
        if (this.filterMenuApi) {
            this.filterMenuApi.show(key)
        }
    }

    @Bind
    private onFilterChange(model: FilterModel, skipEmit?: boolean): void {
        const pills: Pill[] = []
        for (const key in model) {
            const value: string = model[key]?.filter ?? ""
            const filter = this.props.filters.find((f) => f.key === key)

            if (filter && value) {
                //render formatted date in filterbar
                if (filter.type === DataFilterType.DATETIMERANGE) {
                    const deSerializedTimerange: TimeRange = DateUtil.deserializeTimeRange(value)
                    pills.push({
                        key: key,
                        displayName: this.getDataFilterDisplayName(key),
                        value: DateUtil.timeRangeToUserReadableStr(deSerializedTimerange),
                    })
                } else {
                    let selectedValue: string = value

                    if (Array.isArray(filter.options)) {
                        const options = filter.options
                        const selectOption = options.find((option) => {
                            if (typeof option === "string") {
                                return option === value
                            } else {
                                return option.value === value
                            }
                        })

                        if (selectOption) {
                            selectedValue =
                                typeof selectOption === "string"
                                    ? selectOption
                                    : selectOption.displayName
                        }
                    }

                    pills.push({
                        key: key,
                        displayName: this.getDataFilterDisplayName(key),
                        value: selectedValue,
                    })
                }
            }
        }
        this.setState({ model: model, pills: pills })
        if (!skipEmit) {
            this.emit(model)
        }
    }

    @Bind
    private onRemoveFilter(key: string): void {
        const idx: number = this.state.pills.findIndex((p) => p.key === key)
        if (idx >= 0) {
            this.state.pills.splice(idx, 1)
        }
        const dataFilter: DataFilter | undefined = this.props.filters.find((f) => f.key === key)
        if (dataFilter) {
            dataFilter.value = ""
        }

        const { [key]: removedKey, ...updatedModel } = this.state.model

        this.setState({ model: updatedModel, pills: this.state.pills })
        this.emit(updatedModel)
    }

    private emit(model: FilterModel): void {
        if (this.props.onChange) {
            this.props.onChange(model)
        }
    }

    private getDataFilterDisplayName(key: string): string {
        if (this.props.filters && this.props.filters.length > 0) {
            const filter: DataFilter | undefined = this.props.filters.find((f) => f.key === key)
            if (filter) {
                return filter.displayName || ""
            }
        }
        return ""
    }
}

interface FilterBarProps {
    filters: DataFilter[]
    initialModel: FilterModel
    className?: string
    onChange?: (model: FilterModel) => void
}

interface FilterBarState {
    model: FilterModel
    pills: Pill[]
}

interface Pill {
    key: string
    displayName: string
    value: string
}
