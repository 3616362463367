import { Accordion as MuiAccordion, AccordionDetails } from "@mui/material"
import classNames from "classnames"
import React, { ReactNode } from "react"
import { useFallbackState } from "../../../pre-v3/utils/UseFallbackState.hook"
import { HorizontalLine } from "../form/HorizontalLine.component"
import styles from "./Accordion.module.scss"
import {
    AccordionSummaryPlain,
    AccordionSummaryProps,
    AccordionSummaryWithExpand,
    AccordionSummaryWithSwitch,
} from "./AccordionSummary.component"

export interface AccordionProps {
    children: ReactNode
    type?: AccordionType
    label: ReactNode
    tooltipInfo?: string
    description?: ReactNode
    titleChildren?: ReactNode
    defaultOpen?: boolean
    open?: boolean
    onToggle?: (updatedOpen: boolean) => void
    disabled?: boolean
    className?: string
    switchTooltipText?: string
}

export function Accordion(props: AccordionProps): JSX.Element {
    const {
        children,
        type = "default",
        label,
        description,
        tooltipInfo,
        disabled,
        className,
        titleChildren,
        switchTooltipText,
    } = props

    const [open, setOpen] = useFallbackState(
        props?.defaultOpen ?? false,
        props.open,
        props.onToggle
    )
    const AccordionSummary = accordionSummaryDict[type]

    return (
        <MuiAccordion
            elevation={0}
            className={classNames(styles.accordion, className)}
            classes={{ expanded: styles.accordionExpanded }}
            expanded={open}
        >
            <AccordionSummary
                label={label}
                description={description}
                tooltipInfo={tooltipInfo}
                titleChildren={titleChildren}
                value={open}
                onChange={setOpen}
                disabled={disabled}
                switchTooltipText={switchTooltipText}
            />
            {open && (
                <AccordionDetails className={styles.accordionDetails}>
                    <HorizontalLine className={styles.pageBreak} />
                    {children}
                </AccordionDetails>
            )}
        </MuiAccordion>
    )
}

export interface AccordionContainerProps {
    className?: string
    accordions: AccordionProps[]
    type?: "default" | "switch" | "plain"
}

export function AccordionContainer(props: AccordionContainerProps): JSX.Element {
    const { accordions, type, className } = props

    return (
        <div className={classNames(styles.accordionContainer, className)}>
            {accordions.map((accordion, accordianIndex) => {
                return <Accordion key={accordianIndex} type={type} {...accordion} />
            })}
        </div>
    )
}

export type AccordionType = "default" | "switch" | "plain"

const accordionSummaryDict: Record<AccordionType, React.FC<AccordionSummaryProps>> = {
    default: AccordionSummaryWithExpand,
    switch: AccordionSummaryWithSwitch,
    plain: AccordionSummaryPlain,
}
