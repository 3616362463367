import * as React from "react"
import { Singleton } from "../decorators/Singleton.decorator"
import { ServiceSpec } from "../api/Manage.api"
import { LocalizationService } from "./localization/Localization.service"
import { DateUtil } from "../utils/Date.util"
import ServiceContext from "./context"
import { KeyValuePair } from "../utils/AgGrid.util"
import {
    CloudResourceRes,
    CloudResourceServiceRes,
    CloudResourceServiceReq,
    InventoryApi,
} from "../api/Inventory.api"
import { ManageService, ServiceManage, ServiceManageStatus } from "./Manage.service"
import { SelectItem } from "../utils/SelectValue.util"
import { InfraService } from "./Infra.service"

@Singleton("InventoryService")
export class InventoryService {
    public getCloudResources(): Promise<CloudResource[]> {
        return this.inventoryApi.getCloudResources().then((response) => {
            const cloudResources: CloudResource[] = response.result
                ? response.result.map(this.mapCloudResource)
                : []
            return cloudResources
        })
    }

    public getCloudResource(id: string): Promise<CloudResource> {
        return this.inventoryApi.getCloudResource(id).then((response) => {
            const cloudResource: CloudResource = this.mapCloudResource(response)
            return cloudResource
        })
    }

    //returns cloud resource and service details associated within an org
    public getResourceAndServiceDetails(
        search: CloudResourceServiceReq
    ): Promise<CloudResourceService[]> {
        return this.inventoryApi.getResourceAndServiceDetails(search).then((response) => {
            const cloudResourceServices: CloudResourceService[] = response.result
                ? response.result.map(this.mapCloudResourceService)
                : []
            return cloudResourceServices
        })
    }

    public publishResource(serviceId: string, cloudResourceId: string): Promise<void> {
        return this.inventoryApi.publishResource({
            cloud_resource_id: cloudResourceId,
            service_id: serviceId,
        })
    }

    public updateResourceStatus(
        id: string,
        resourceStatus: CloudResourceStatus
    ): Promise<CloudResource> {
        return this.inventoryApi.updateResourceStatus(id, { status: resourceStatus }).then()
    }

    public getResourceAddress(resource: CloudResource): string {
        // get resource private/public ip/address by priority = Pvt IP || Pvt dns || Pub IP || Pub dns
        let privateAddress = resource.privateIp ? resource.privateIp : resource.privateDnsName
        let publicAddress = resource.publicIp ? resource.publicIp : resource.publicDnsName

        return privateAddress ? privateAddress : publicAddress
    }

    public getResourcePortAndProtocol(resource: CloudResource): any[] {
        const portsAndProtocol = resource.ports.split(",").map((p) => p.split("/"))
        const arrayObject = portsAndProtocol.map(function (item) {
            return { key: item[0], value: item[1] } as KeyValuePair
        })

        return arrayObject
    }

    public deleteResourceServiceAssociation(id: string): Promise<CloudResourceService> {
        return this.inventoryApi.deleteResourceServiceAssociation(id).then()
    }

    private mapCloudResource(cloudResource: CloudResourceRes): CloudResource {
        return {
            id: cloudResource.id,
            resourceId: cloudResource.resource_id,
            resourceName: cloudResource.resource_name,
            resourceType: cloudResource.resource_type,
            publicDnsName: cloudResource.public_dns_name,
            privateDnsName: cloudResource.private_dns_name,
            publicIp: cloudResource.public_ip,
            privateIp: cloudResource.private_ip,
            region: cloudResource.region,
            status: cloudResource.status,
            cloudProvider: cloudResource.cloud_provider,
            createdAt: DateUtil.convertLargeTimestamp(cloudResource.created_at),
            updatedAt: DateUtil.convertLargeTimestamp(cloudResource.updated_at),
            account: cloudResource.account,
            ports: cloudResource.ports,
            tags: cloudResource.tags
                ? cloudResource.tags.map((c) => {
                      return {
                          key: c.name,
                          value: c.value,
                      }
                  })
                : [],
        }
    }

    private mapCloudResourceService(
        cloudServiceResource: CloudResourceServiceRes
    ): CloudResourceService {
        return {
            id: cloudServiceResource.id,
            orgId: cloudServiceResource.org_id,
            cloudResourceId: cloudServiceResource.cloud_resource_id,
            serviceId: cloudServiceResource.service_id,
            createdAt: cloudServiceResource.created_at,
            createdBy: cloudServiceResource.created_by,
            updatedAt: cloudServiceResource.updated_at,
            updatedBy: cloudServiceResource.updated_by,
            frontendPort: cloudServiceResource.port,
            backendPort: cloudServiceResource.backend_port,
            serviceName: cloudServiceResource.service_name,
            serviceType: cloudServiceResource.service_type,
            resourceId: cloudServiceResource.resource_id,
            resourceName: cloudServiceResource.resource_name,
            resourceType: cloudServiceResource.resource_type,
            status: cloudServiceResource.status,
        }
    }

    public mapCloudResourceToServiceManage(resource: CloudResource): ServiceManage {
        //add random string to service name to be able to avoid overwriting of services with same name
        var randomString = (Math.floor(Math.random() * (100 - 1 + 1)) + 1).toString()

        var descriptionString = "Published from Resource: " + resource.resourceName

        const spec: ServiceSpec = this.manageService.getNullServiceSpec()
        //update icon tag
        spec.metadata.tags = {
            icon: "cloud",
        }
        return {
            id: resource.id,
            name: resource.resourceName + "-" + randomString,
            clusterName: "",
            enabled: false,
            createdAt: resource.createdAt || 0,
            createdBy: "",
            description: descriptionString,
            lastUpdatedAt: resource.updatedAt || 0,
            lastUpdatedBy: "",
            oidcEnabled: false,
            oidcSpec: "",
            spec: spec,
            type: "",
            version: 0,
            policyId: "",
            policyName: "",
            policyAttachedAt: 0,
            policyEnabled: false,
            status: ServiceManageStatus.INACTIVE,
        }
    }

    public getCloudProviderOptions(): SelectItem[] {
        return [
            { displayName: this.localizationService.getString("allSources"), value: "All Sources" },
            { displayName: this.localizationService.getString("aws"), value: "AWS" },
            { displayName: this.localizationService.getString("gcp"), value: "GCP" },
            { displayName: this.localizationService.getString("oci"), value: "OCI" },
            { displayName: this.localizationService.getString("azure"), value: "Azure" },
            { displayName: this.localizationService.getString("vmware"), value: "VMware" },
        ]
    }

    private inventoryApi: InventoryApi = new InventoryApi()
    private manageService: ManageService = new ManageService()
    private localizationService: LocalizationService = new LocalizationService()
    private infraService: InfraService = new InfraService()
}

export enum CloudResourceStatus {
    PUBLISHED = "published",
    DISCOVERED = "Discovered",
    IGNORED = "Ignored",
    ALL_STATUSES = "All Statuses",
}

export interface CloudResource {
    id: string
    resourceId: string
    resourceName: string
    resourceType: string
    publicDnsName: string
    privateDnsName: string
    publicIp: string
    privateIp: string
    region: string
    status: string
    cloudProvider: string
    createdAt: number
    updatedAt: number
    account: string
    ports: string
    tags?: KeyValuePair[]
}

export interface CloudResourceService {
    id: string
    orgId: string
    cloudResourceId: string
    serviceId: string
    createdAt?: number
    createdBy?: string
    updatedAt?: number
    updatedBy?: string
    frontendPort?: number
    backendPort?: string
    serviceName: string
    serviceType: string
    resourceId?: string
    resourceName: string
    resourceType?: string
    status?: string
}

export const useServiceInventory = () =>
    React.useContext(ServiceContext)?.inventory || new InventoryService()
