import React from "react"

import { useServiceLocalization } from "../../../../../../../pre-v3/services/localization/Localization.service"
import { Slider, SliderProps, Color } from "../../../../../../components/slider/Slider.component"
import {
    TRUST_EFFECT,
    effectShortLabelDictionary,
} from "../../../../../../services/TrustProfile.service"

interface Props {
    value: TRUST_EFFECT
    // TODO: Remove when we remove the Trust Factors view
    data: TRUST_EFFECT[]
    onChange(effect: TRUST_EFFECT): void
    disabled?: boolean
}

export function EffectSlider(props: Props): JSX.Element {
    const localization = useServiceLocalization()

    const getSliderProps = React.useCallback(
        (selectedEffect: TRUST_EFFECT): SliderProps => {
            return {
                id: selectedEffect,
                label: localization.getString(effectShortLabelDictionary[selectedEffect]),
                color: effectColorDictionary[selectedEffect],
            }
        },
        [localization]
    )

    // TODO: Hardcode data when we remove the Trust Factors view
    return <Slider {...props} getSliderProps={getSliderProps} />
}

const effectColorDictionary: Record<TRUST_EFFECT, Color> = {
    [TRUST_EFFECT.NOT_EVALUATED]: Color.BLUEGRAY_300,
    [TRUST_EFFECT.ALWAYS_DENY]: Color.RED_900,
    [TRUST_EFFECT.LOW_TRUST_LEVEL]: Color.RED_500,
    [TRUST_EFFECT.MEDIUM_TRUST_LEVEL]: Color.YELLOW_500,
    [TRUST_EFFECT.NO_EFFECT]: Color.GREEN_500,
}
