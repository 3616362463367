import React, { ReactNode } from "react"
import ServiceContext from "../context"
import { Singleton } from "../../decorators/Singleton.decorator"
import { Locale } from "./Locale.enum"
import { enUSLanguage, LanguageDictionary, LanguageKey } from "./languages/en-US.language"
import { PatternUtil } from "../../utils/Pattern.util"

/**
 * Definitions:
 * DataStore - the complete set of all data in LocalizationService; a collection of Packages
 * Package - all localizations for a single app (APM, EUM, SIM...); a collection of Dictionaries
 * Dictionary - a set of translations for a single locale for a single app (ex. en-US for APM)
 */
type Dictionary = LanguageDictionary | undefined
type Package = Map<Locale, Dictionary> | undefined
type DataStore = Map<string, Package>

@Singleton("LocalizationService")
export class LocalizationService {
    constructor() {
        this.setLocale(this.getDefaultLocale())

        const map: Map<Locale, LanguageDictionary> = new Map()
        map.set(Locale.en_US, enUSLanguage)
        this.registerLocalPackage(this.packageKey, map)
    }

    private packageKey: string = "banyan-localization"
    private dataStore: DataStore = new Map()
    private locale: Locale
    private pluralRules: Intl.PluralRules = new Intl.PluralRules()
    private ordinalRules: Intl.PluralRules = new Intl.PluralRules(undefined, { type: "ordinal" })

    static mockImplementation: any

    public getLocale(): string {
        return this.locale
    }

    public setLocale(locale: Locale): void {
        this.locale = locale
        this.pluralRules = new Intl.PluralRules(locale)
        this.ordinalRules = new Intl.PluralRules(locale, { type: "ordinal" })
    }

    public hasPackage(packageKey: string): boolean {
        return this.dataStore.has(packageKey)
    }

    public registerLocalPackage(
        packageKey: string,
        packageMap: Map<Locale, LanguageDictionary>
    ): void {
        this.dataStore.set(packageKey, packageMap)
    }

    public getString(key: LanguageKey, ...replaceVals: string[]): string {
        const pack: Package = this.dataStore.get(this.packageKey)
        if (pack) {
            const dictionary: Dictionary = pack.get(this.locale)
            if (dictionary) {
                const str: string = dictionary[key]
                if (str && str.length > 0) {
                    return this.doTokenReplacement(str, replaceVals)
                }
                // string not found in dictionary
                return this.getStringDefaultLocale(this.packageKey, key, ...replaceVals)
            } else {
                // dictionary not found
                return this.getStringDefaultLocale(this.packageKey, key, ...replaceVals)
            }
        } else {
            // package not found
            return this.getErrorResponse(this.packageKey, key, "package not found")
        }
    }

    public getPluralString(key: string, count: number, ...replaceVals: string[]): string {
        const pack = this.dataStore.get(this.packageKey)

        if (!pack) {
            // package not found
            return this.getErrorResponse(this.packageKey, key, "package not found")
        }

        const dictionary = pack.get(this.locale)

        if (!dictionary) {
            // dictionary not found
            return this.getPluralStringDefaultLocale(this.packageKey, key, count, ...replaceVals)
        }

        const pluralType = this.pluralRules.select(count)

        const str = dictionary[`${key}_${pluralType}` as LanguageKey]

        if (!str || str.length <= 0) {
            // string not found in dictionary
            return this.getPluralStringDefaultLocale(this.packageKey, key, count, ...replaceVals)
        }

        return this.doTokenReplacement(str, [count, ...replaceVals])
    }

    public getOrdinalString(key: string, count: number, ...replaceVals: string[]): string {
        const pack = this.dataStore.get(this.packageKey)

        if (!pack) {
            // package not found
            return this.getErrorResponse(this.packageKey, key, "package not found")
        }

        const dictionary = pack.get(this.locale)

        if (!dictionary) {
            // dictionary not found
            return this.getPluralStringDefaultLocale(this.packageKey, key, count, ...replaceVals)
        }

        const ordinalType = this.ordinalRules.select(count)

        const str = dictionary[`${key}_ordinal_${ordinalType}` as LanguageKey]

        if (!str) {
            // string not found in dictionary
            return this.getPluralStringDefaultLocale(this.packageKey, key, count, ...replaceVals)
        }

        return this.doTokenReplacement(str, [count, ...replaceVals])
    }

    public replaceJSX(key: LanguageKey, ...replaceVals: JSX.Element[]): ReactNode {
        const string = this.getString(key)

        if (replaceVals.length === 0) {
            return string
        }

        return string
            .split(PatternUtil.I8N_TEMPLATE_REPLACEMENT_WITHOUT_GROUP)
            .map((str, strIndex) => {
                let node: ReactNode = str
                const isOdd = Boolean(strIndex % 2)

                if (isOdd) {
                    const template = new RegExp(PatternUtil.I8N_TEMPLATE_REPLACEMENT.source).exec(
                        str
                    )

                    const key = template && (template[1] || template[3])
                    const children = template && template[2]
                    const jsxElement = key && replaceVals[Number(key)]

                    if (jsxElement) {
                        node = children
                            ? React.cloneElement(jsxElement, {
                                  children,
                              })
                            : jsxElement
                    }
                }

                return React.createElement(React.Fragment, { key: strIndex }, node)
            })
    }

    protected getDefaultLocale(): Locale {
        return Locale.en_US
    }

    protected doTokenReplacement(
        templateString: string,
        replaceVals: Array<string | number>
    ): string {
        if (replaceVals.length === 0) {
            return templateString
        }
        return templateString.replace(
            PatternUtil.I8N_TEMPLATE,
            (_fullMatch: string, matchContents: number) => {
                return `${replaceVals[matchContents]}`
            }
        )
    }

    protected getErrorResponse(
        requestPackage: string,
        requestKey: string,
        errorMessage: string
    ): string {
        const requestPackageKeyPair = `${requestPackage}:${requestKey}`
        if (this.isDebugMode()) {
            console.error(Error(`${requestPackageKeyPair} ${errorMessage}`))
        }
        return requestPackageKeyPair
    }

    protected getPluralErrorResponse(
        requestPackage: string,
        requestKey: string,
        pluralType: string,
        errorMessage: string
    ): string {
        const requestPackageKeyPair = `${requestPackage}:${requestKey}_${pluralType}`
        if (this.isDebugMode()) {
            console.error(Error(`${requestPackageKeyPair} ${errorMessage}`))
        }
        return requestPackageKeyPair
    }

    protected isDebugMode(): boolean {
        // TODO - add better platform-level support
        return true
    }

    private getStringDefaultLocale(
        packageKey: string,
        key: LanguageKey,
        ...replaceVals: string[]
    ): string {
        const pack: Package = this.dataStore.get(packageKey)
        if (pack) {
            const dictionary: Dictionary = pack.get(this.getDefaultLocale())
            if (dictionary) {
                const str: string = dictionary[key]
                if (str && str.length > 0) {
                    return this.doTokenReplacement(str, replaceVals)
                }
                // string not found in dictionary
                return this.getErrorResponse(packageKey, key, "string not found")
            } else {
                // dictionary not found
                return this.getErrorResponse(packageKey, key, "dictionary not found")
            }
        } else {
            // package not found
            return this.getErrorResponse(packageKey, key, "package not found")
        }
    }

    private getPluralStringDefaultLocale(
        packageKey: string,
        key: string,
        count: number,
        ...replaceVals: string[]
    ): string {
        const defaultLocale = this.getDefaultLocale()
        const pluralRules = new Intl.PluralRules(defaultLocale)
        const pluralType = pluralRules.select(count)

        const pack = this.dataStore.get(packageKey)

        if (!pack) {
            // package not found
            return this.getPluralErrorResponse(packageKey, key, pluralType, "package not found")
        }

        const dictionary = pack.get(defaultLocale)

        if (!dictionary) {
            // dictionary not found
            return this.getPluralErrorResponse(packageKey, key, pluralType, "dictionary not found")
        }

        const str = dictionary[`${key}_${pluralType}` as LanguageKey]

        if (!str || str.length <= 0) {
            // string not found in dictionary
            return this.getPluralErrorResponse(packageKey, key, pluralType, "string not found")
        }

        return this.doTokenReplacement(str, [count, ...replaceVals])
    }
}

export const useServiceLocalization = () =>
    React.useContext(ServiceContext)?.localization || new LocalizationService()
