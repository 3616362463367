import React from "react"
import { ColDef, ITooltipParams, ValueFormatterParams } from "ag-grid-community"
import { useServiceLocalization } from "../../../../pre-v3/services/localization/Localization.service"
import AgGridUtil from "../../../../pre-v3/utils/AgGrid.util"
import { TrustIntegrationsSyncStats } from "../../../services/TrustIntegration.service"
import { SelectItem } from "../../../../pre-v3/utils/SelectValue.util"
import styles from "./TrustIntegrationForm.module.scss"
import { DateUtil } from "../../../../pre-v3/utils/Date.util"
import { chartColorMap } from "../../../../pre-v3/views/dashboard/utils"
import { TrustLevel } from "../../../../pre-v3/api/Entity.api"
import { LargeMessage } from "../../../components/large-message/LargeMessage.component"

// Note: Old components, move them to v3
import { StackedBarChart } from "../../../../pre-v3/components/charts/StackedBarChart"
import { Grid } from "../../../../pre-v3/components/grid/Grid.component"

const syncStatMap: StringMap = {
    completed: "COMPLETED",
    failed: "FAILED",
    partial: "PARTIAL",
    unevaluated: "UNEVALUATED",
}

interface Props {
    disabled?: boolean
    initialValue: TrustIntegrationsSyncStats[]
}

export function TrustIntegrationSyncLogs(props: Props) {
    const localization = useServiceLocalization()

    const syncTarget: number = props.initialValue[0]?.numDevicesSynced || 0
    const syncStatObj = props.initialValue[0] || {}
    var series: SelectItem[] = []
    for (const [key, value] of Object.entries(syncStatObj)) {
        if (syncStatMap[key]) {
            series.push({
                displayName: syncStatMap[key],
                value: typeof value === "number" ? value : 0,
            })
        }
    }

    const getStatusLabel = (params: ValueFormatterParams | ITooltipParams): string => {
        return localization.getString(params.value)
    }

    const columnDefs: ColDef[] = [
        {
            headerName: localization.getString("lastSyncLogged"),
            field: "lastSyncAt",
            width: 100,
            valueFormatter: AgGridUtil.largeTimestampFormatter,
        },
        {
            headerName: localization.getString("status"),
            field: "status",
            valueFormatter: getStatusLabel,
            tooltipValueGetter: getStatusLabel,
            width: 65,
        },
        {
            headerName: localization.getString("recordsTargeted"),
            field: "numDevicesSynced",
            width: 75,
            filter: "agTextColumnFilter",
        },
        {
            headerName: localization.getString("completed"),
            field: "completed",
            width: 60,
        },
        {
            headerName: localization.getString("partial"),
            field: "partial",
            width: 60,
        },
        {
            headerName: localization.getString("failed"),
            field: "failed",
            width: 60,
        },
        {
            headerName: localization.getString("unevaluated"),
            field: "unevaluated",
            width: 60,
        },
    ]

    return (
        <>
            {!props.initialValue.length && (
                <LargeMessage className={styles.missingMessage}>
                    {localization.getString("trustIntegrationSyncLogsNotFound")}
                </LargeMessage>
            )}
            {props.initialValue.length > 0 && (
                <>
                    <div className={styles.syncStats}>
                        <div>
                            <p className={styles.headerText}>
                                {localization.getString("thisTrustIntegrationWasLastSyncedOn")}
                            </p>
                            <p className={styles.lastEvaluated}>
                                {props.initialValue[0]?.lastSyncAt
                                    ? DateUtil.formatLargeTimestamp(
                                          props.initialValue[0].lastSyncAt
                                      )
                                    : "-"}
                            </p>
                        </div>
                        <div>
                            <p className={styles.headerText}>
                                {localization.getString("setToSyncEvery")}
                            </p>
                            <p className={styles.lastEvaluated}>
                                {localization.getString("20Minutes")}
                            </p>
                        </div>
                    </div>
                    <div className={styles.container}>
                        <StackedBarChart
                            title={localization
                                .getString("syncTargetRecords", syncTarget.toString())
                                .toUpperCase()}
                            showLegend
                            showXAxis={false}
                            showYAxis={false}
                            data={
                                series.length > 0
                                    ? series
                                          .map((datum: SelectItem, i) => ({
                                              ...datum,
                                              color: [
                                                  chartColorMap[TrustLevel.LOW],
                                                  chartColorMap[TrustLevel.HIGH],
                                                  chartColorMap[TrustLevel.MID],
                                              ][i],
                                          }))
                                          .filter(({ value }) => value)
                                    : []
                            }
                        />
                    </div>
                    <div className={styles.gridContainer}>
                        <Grid
                            className={styles.grid}
                            rowData={props.initialValue}
                            columnDefs={columnDefs}
                        />
                    </div>
                </>
            )}
        </>
    )
}
