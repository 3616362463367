import * as React from "react"
import { ModalRef } from "../../../services/Modal.service"
import styles from "./MessageContent.module.scss"

interface MessageListContentProps {
    modalRef: ModalRef
    text: string
    list: string[]
}

export const MessageListContent = ({ list, text }: MessageListContentProps) => (
    <div className={styles.content}>
        {text}
        {list.length > 0 && (
            <div className={styles.list}>
                {list.map((item) => (
                    <div className={styles.listItem} key={item}>
                        {item}
                    </div>
                ))}
            </div>
        )}
    </div>
)
