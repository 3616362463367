import { faSearch, faTimes } from "@fortawesome/pro-solid-svg-icons"
import classNames from "classnames/bind"
import React, { useRef } from "react"
import { Icon } from "../Icon/Icon.component"
import { Input, InputProps } from "../input/Input.component"

import styles from "./SearchInput.module.scss"

export interface Props extends Omit<InputProps, "type"> {
    value: string
    onChangeValue(value: string): void
}

export function SearchInput(props: Props): JSX.Element {
    const { className, ...inputProps } = props

    const inputRef = useRef<HTMLInputElement>(null)

    const onEmptyClick: React.MouseEventHandler<HTMLButtonElement> = (event) => {
        event.preventDefault()
        if (inputProps.value) {
            props.onChangeValue("")
        } else {
            inputRef.current?.focus()
        }
    }

    const icon = props.value ? faTimes : faSearch

    return (
        <div className={classNames(styles.container, className)}>
            <Input {...inputProps} ref={inputRef} className={styles.input} />
            <button
                type="button"
                onClick={onEmptyClick}
                className={styles.button}
                disabled={props.disabled}
                tabIndex={-1}
                onMouseDown={(event) => event.preventDefault()}
            >
                <Icon icon={icon} className={styles.icon} />
            </button>
        </div>
    )
}
