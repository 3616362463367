import OrgSignOnSettingsTemplate from "./OrgSignOnSettings.template"
import React, { FormEvent, ReactNode, SyntheticEvent } from "react"
import { Bind } from "../../../../decorators/Bind.decorator"
import { DateUtil } from "../../../../utils/Date.util"
import { LocalizationService } from "../../../../services/localization/Localization.service"
import { SamlConfig, UserApi, UserOrgDetails } from "../../../../api/User.api"
import { SelectItem } from "../../../../utils/SelectValue.util"
import { UserService, OrgEdition, ModalService } from "../../../../services"
import { OrgApi } from "../../../../../v3/api/Org.api"
import { IdpType } from "../../../../../v3/services/UserProfile.service"
import { MessageContent, OkCancelActions } from "../../../../components"
import { AppText } from "../../../../../v3/components/app-text/AppText.component"
import { UserService as UserServiceV3 } from "../../../../../v3/services/User.service"
import ActionBarService from "../../../../services/ActionBar.service"

export class OrgSignOnSettings extends React.Component<
    OrgSignOnSettingsProps,
    OrgSignOnSettingsState
> {
    public state: OrgSignOnSettingsState = {
        loading: false,
        ssoMethod: IdpType.BANYAN,
        showConfirmationDialog: false,
        edition: OrgEdition.ENTERPRISE,
        totpEnabled: false,
        isBanyanIDP: false,
        isLoading: false,
        isLocalSettingsLoading: false,
    }

    public render(): ReactNode {
        return OrgSignOnSettingsTemplate.call(this)
    }

    public componentDidMount(): void {
        this.fetchData()
        this.actionBarService.setItems(this.localizationService.getString("adminSignOn"), [
            {
                label: this.localizationService.getString("adminSettings"),
            },
        ])
    }

    localizationService: LocalizationService = new LocalizationService()
    userService: UserService = new UserService()
    userApi: UserApi = new UserApi()
    orgApi: OrgApi = new OrgApi()
    modalService: ModalService = new ModalService()
    userServiceV3: UserServiceV3 = new UserServiceV3()
    private actionBarService = new ActionBarService()

    submitButton: HTMLButtonElement | null

    ssoOptions: SelectItem[] = [
        {
            displayName: this.localizationService.getString("sonicWallCseUsernamePassword"),
            value: IdpType.BANYAN,
        },
        {
            displayName: this.localizationService.getString("singleSignOnSaml20"),
            value: IdpType.SAML,
        },
    ]

    private updateSAMLConfig(orgDetails: UserOrgDetails) {
        this.userApi.getSamlConfig(orgDetails.OrgID).then((samlConfig: SamlConfig) => {
            this.setState({
                callbackURL: samlConfig.CallbackURL,
                ssoMethod: IdpType.SAML,
                idpIssuer: samlConfig.IDPIssuerURL,
                idpMetadataUrl: samlConfig.MetadataURL,
                idpRawMetadata: samlConfig.RawMetadata,
                spMetadataUrl: samlConfig.SPMetadataURL,
                spCertificateInfo: {
                    Certificate: samlConfig.SPCertificateInfo?.Certificate,
                    Expires: DateUtil.formatLargeTimestamp(
                        samlConfig.SPCertificateInfo?.Expires || 0
                    ),
                    Issuer: samlConfig.SPCertificateInfo?.Issuer,
                },
                spIssuer: samlConfig.SPIssuer,
            })
        })
    }

    @Bind
    onConfigure(): void {
        this.modalService
            .open(
                this.localizationService.getString(
                    "configureSomething",
                    this.localizationService.getString("signOnSettings")
                ),
                {
                    component: MessageContent,
                    props: {
                        text: (
                            <AppText
                                ls={{
                                    key: "areYouSureYouWantToConfigureSomething",
                                    replaceVals: [
                                        this.localizationService.getString("signOnSettings"),
                                    ],
                                }}
                            />
                        ),
                    },
                    maxWidth: "sm",
                },
                {
                    component: OkCancelActions,
                    props: { okString: this.localizationService.getString("confirm") },
                }
            )
            .onClose(() => {
                this.setState({ isLoading: true })
                this.userServiceV3
                    .switchBanyanIDPToLocalIDP()
                    .then(() => {
                        this.setState({ isLoading: false })
                        this.userService
                            .getUserOrgDetails(true)
                            .then((orgDetails: UserOrgDetails) => {
                                this.setState({
                                    orgName: orgDetails.OrgName,
                                    orgId: orgDetails.OrgID,
                                    ssoMethod: IdpType.BANYAN,
                                    ssoUrl:
                                        "https://" +
                                        window.location.hostname +
                                        "/api/v1/sso?orgname=" +
                                        orgDetails.OrgName,
                                    totpEnabled: !!orgDetails.MFAEnabled,
                                    isBanyanIDP: Boolean(orgDetails.IsAdminBanyanIDP),
                                })
                            })
                            .catch((e) => {
                                this.setState({ isLoading: false })
                                this.setState({ error: e })
                            })
                    })
                    .catch((e) => {
                        this.setState({ isLoading: false })
                        this.setState({ configureError: e })
                    })
            })
    }

    @Bind
    onMethodChange(value: IdpType): void {
        this.setState({ ssoMethod: value })
    }

    @Bind
    onConfirmationDialogOpen(): void {
        this.setState({ showConfirmationDialog: true })
    }

    @Bind
    onConfirmationDialogClose(): void {
        this.setState({ showConfirmationDialog: false })
    }

    @Bind
    onConfirm(): void {
        if (this.submitButton) {
            this.submitButton.click()
        }
        this.setState({ showConfirmationDialog: false })
    }

    @Bind
    onSubmit(event: SyntheticEvent): void {
        if (event.target) {
            this.setState({ loading: true, error: "", success: "" })
            const form: { [key: string]: HTMLInputElement } = event.target as any
            const promises: Promise<any>[] = []
            if (this.state.ssoMethod === IdpType.SAML) {
                if (!form["idpMetadataUrl"].value && !form["idpRawMetadata"].value) {
                    this.setState({
                        loading: false,
                        error: this.localizationService.getString(
                            "idpMetadataUrlOrIdpRawMetadataMustBeSet"
                        ),
                    })
                    return
                }
                const samlConfig: SamlConfig = {
                    CallbackURL: form["ssoUrl"].value,
                    IDPIssuerURL: form["idpIssuer"].value,
                    MetadataURL: form["idpMetadataUrl"].value,
                    RawMetadata: form["idpRawMetadata"].value,
                    SPMetadataURL: "",
                    SPIssuer: form["spIssuer"].value,
                }
                promises.push(this.userApi.setupSaml(this.state.orgId!, samlConfig))
            }
            promises.push(this.userApi.updateIdpType(this.state.ssoMethod))
            Promise.all(promises).then(
                () => {
                    this.setState({
                        loading: false,
                        success: this.localizationService.getString("orgSettingsUpdateSuccessful"),
                    })
                    this.userService.getUserOrgDetails(true).then((orgDetails: UserOrgDetails) => {
                        if (this.state.ssoMethod === IdpType.SAML) {
                            this.updateSAMLConfig(orgDetails)
                        }
                    })
                },
                (err: Error) => {
                    this.setState({ loading: false, error: err.message })
                }
            )
        }
    }

    @Bind
    onSubmitOtp(event: FormEvent<HTMLFormElement>): void {
        event.preventDefault()

        this.setState({
            isLocalSettingsLoading: true,
            localSettingError: "",
            localSettingSuccess: "",
        })
        this.orgApi
            .patchOrgSettings({ MFAEnabled: this.state.totpEnabled })
            .then(() => {
                this.setState({
                    localSettingSuccess: this.localizationService.getString(
                        "localAdminSettingsUpdateSuccessful"
                    ),
                })
            })
            .catch((e) => {
                this.setState({ localSettingError: e })
            })
            .finally(() => {
                this.setState({ isLocalSettingsLoading: false })
            })
    }

    @Bind
    async fetchData(): Promise<void> {
        this.userService.getUserOrgDetails(true).then((orgDetails: UserOrgDetails) => {
            if (orgDetails.IDPType === IdpType.SAML) {
                this.updateSAMLConfig(orgDetails)
            }
            this.setState({
                orgName: orgDetails.OrgName,
                orgId: orgDetails.OrgID,
                ssoMethod: IdpType.BANYAN,
                ssoUrl:
                    "https://" +
                    window.location.hostname +
                    "/api/v1/sso?orgname=" +
                    orgDetails.OrgName,
                totpEnabled: !!orgDetails.MFAEnabled,
                isBanyanIDP: Boolean(orgDetails.IsAdminBanyanIDP),
            })
        })
    }
}

interface OrgSignOnSettingsProps {
    canConfigureAdminSignOnSettings: boolean
}

interface OrgSignOnSettingsState {
    loading: boolean
    error?: string
    success?: string
    isLocalSettingsLoading: boolean
    localSettingError?: string
    localSettingSuccess?: string

    orgName?: string
    orgId?: string
    ssoUrl?: string
    ssoMethod: IdpType
    callbackURL?: string
    spIssuer?: string

    idpIssuer?: string
    idpMetadataUrl?: string
    idpRawMetadata?: string

    showConfirmationDialog: boolean

    spMetadataUrl?: string
    spCertificateInfo?: SPCertificateInfoDisplay

    edition: OrgEdition

    totpEnabled: boolean
    isBanyanIDP: boolean
    isLoading: boolean
    configureError?: string
}

export interface SPCertificateInfoDisplay {
    Certificate?: string
    Expires?: string
    Issuer?: string
}
