import React, { FC, ReactNode } from "react"
import styles from "./MspLayout.module.scss"

interface Props {
    children: ReactNode
    isMspOrg: boolean
}
export const MSPLayout: FC<Props> = ({ children, isMspOrg }) => {
    if (!isMspOrg) return <>{children}</>

    return <div className={styles.container}>{children}</div>
}
