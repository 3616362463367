import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query"
import { OrgApi } from "../api/Org.api"

const QUERY_KEYS = {
    IS_GEOLOCATION_ENABLES: ["settingService.getIsGeolocationEnabled"],
} as const

export function useGetOrgIsGeolocationEnabled() {
    const orgApi = new OrgApi()
    return useQuery<boolean, string>({
        queryKey: QUERY_KEYS.IS_GEOLOCATION_ENABLES,
        queryFn: async () => {
            const orgInfo = await orgApi.getOrgDetails()
            return orgInfo.IsGeoLocationEnabled
        },
    })
}

interface SetGeolocationContext {
    previousValue: boolean | undefined
    newValue: boolean | undefined
}
export function useOptimisticSetGeolocation(options?: QueryOptions<void, string, boolean>) {
    const orgApi = new OrgApi()
    const queryClient = useQueryClient()

    return useMutation<void, string, boolean, SetGeolocationContext>({
        ...options,
        mutationFn: (isGeolocationEnabled: boolean) =>
            orgApi.patchOrgSettings({ IsGeoLocationEnabled: isGeolocationEnabled }),
        onMutate: async (isGeolocationEnabled: boolean) => {
            await queryClient.cancelQueries(QUERY_KEYS.IS_GEOLOCATION_ENABLES)
            const previousValue = queryClient.getQueryData<boolean>(
                QUERY_KEYS.IS_GEOLOCATION_ENABLES
            )
            const newValue = isGeolocationEnabled

            queryClient.setQueryData<boolean>(QUERY_KEYS.IS_GEOLOCATION_ENABLES, () => newValue)
            return { previousValue, newValue }
        },
        onError: (error, args, context) => {
            queryClient.setQueryData<boolean>(
                QUERY_KEYS.IS_GEOLOCATION_ENABLES,
                () => context?.previousValue
            )
            options?.onError?.(error)
        },
    })
}
