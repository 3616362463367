import React from "react"
import styles from "./Status.module.scss"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faCheckCircle } from "@fortawesome/pro-solid-svg-icons"
import { BaseStatus } from "./BaseStatus"

export function StatusSuccess({ tooltip, text }: { tooltip?: string; text?: string }) {
    return (
        <BaseStatus
            text={text}
            tooltip={tooltip}
            icon={<FontAwesomeIcon icon={faCheckCircle} />}
            iconClass={styles.statusIconVerified}
        />
    )
}
