import React, { ReactNode } from "react"
import ToggleButtonTemplate from "./ToggleButton.template"
import { IconProp } from "@fortawesome/fontawesome-svg-core"
import { Bind } from "../../decorators/Bind.decorator"

export class ToggleButton extends React.Component<ToggleButtonProps, ToggleButtonState> {
    public state: ToggleButtonState = { selectedIndex: -1 }

    public render(): ReactNode {
        return ToggleButtonTemplate.call(this)
    }

    public componentDidMount(): void {
        this.setState({ selectedIndex: this.props.items.findIndex((i) => i.selected!) })
    }

    @Bind
    private onToggle(index: number): void {
        if (this.props.disabled) {
            return
        }

        this.setState({ selectedIndex: index })
        this.props.items.forEach((item, i) => (item.selected = index === i))
        if (this.props.items[index] && this.props.items[index].onClick) {
            this.props.items[index].onClick!()
        }
    }
}

interface ToggleButtonProps {
    items: ToggleButtonItem[]
    className?: string
    disabled?: boolean
}

interface ToggleButtonState {
    selectedIndex: number
}

export interface ToggleButtonItem {
    icon?: IconProp
    label?: string
    value?: any
    onClick?: Function
    selected?: boolean
}
