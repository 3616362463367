import React, { ReactNode } from "react"
import GridTemplate from "./Grid.template"
import { AgGridReactProps } from "ag-grid-react"
import {
    GridReadyEvent,
    GridApi,
    ColDef,
    GetRowIdParams,
    PaginationChangedEvent,
} from "ag-grid-community"
import { Debounce } from "../../decorators/Debounce.decorator"
import { Bind } from "../../decorators/Bind.decorator"
import AgGridUtil from "../../utils/AgGrid.util"
import { UrlUtil } from "../../utils/Url.util"

export type {
    ColDef,
    GridApi,
    GridReadyEvent,
    ICellRendererParams,
    IServerSideDatasource,
    IServerSideGetRowsParams,
    ValueFormatterParams,
    ValueGetterParams,
} from "ag-grid-community"

export class Grid<TData> extends React.Component<GridProps<TData>, {}> {
    public static ID: number = 1
    public static getId(): number {
        return Grid.ID++
    }
    private static isSafari: boolean =
        // @ts-ignore
        /constructor/i.test(window.HTMLElement) ||
        (function (p) {
            return p.toString() === "[object SafariRemoteNotification]"
            // @ts-ignore
        })(!window["safari"] || (typeof safari !== "undefined" && safari.pushNotification))

    public render(): ReactNode {
        return GridTemplate.call(this)
    }

    public componentDidMount(): void {
        window.addEventListener("resize", this.resizeGrid)
        this.setHeight()
    }

    public componentWillUnmount(): void {
        window.removeEventListener("resize", this.resizeGrid)
    }

    private gridApi: GridApi
    private id: string = "grid_" + Grid.getId()
    private columnDefs: ColDef[]
    private isFirstRender = true

    private defaultColDef: ColDef = {
        sortable: true,
        suppressMenu: true,
        suppressMovable: true,
        resizable: true,
        tooltipValueGetter: AgGridUtil.defaultTooltipValueGetter,
    }

    @Bind
    private setHeight(): void {
        if (this.props.autoHeight) {
            return
        }
        const ele: HTMLElement = <HTMLElement>document.getElementById(this.id)
        if (ele) {
            ele.style.height = "100%"
            window.setTimeout(() => {
                ele.style.height = ele.clientHeight + "px"
            }, 10)
        }
    }

    /**
     * Flexbox on safari cannot size down, it checks the content width
     * which is the previous size of the grid, it then sets that as its
     * minimum width. This means if you are resizing the grid smaller, the minimum
     * width is set to its previous width and therefore it doesn't ever get smaller
     */
    @Bind
    private setWidthSafariHack(): void {
        const ele: HTMLElement = <HTMLElement>document.getElementById(this.id)
        if (ele) {
            ele.style.width = "50px"
            const parentWidth: number | undefined = ele.parentElement?.clientWidth
            window.setTimeout(() => {
                ele.style.width = parentWidth ? Math.max(parentWidth - 5, 100) + "px" : "100px"
                if (this.gridApi) {
                    this.gridApi.sizeColumnsToFit()
                }
            }, 0)
        }
    }

    @Bind
    private onFirstDataRendered(): void {
        this.restorePagination()
        this.resizeGrid()
        this.isFirstRender = false
    }

    @Bind
    private getRowId(params: GetRowIdParams): string {
        if (params.data.id) {
            //data must return unique row id
            return params.data.id
        } else {
            throw new Error("'getRowId' callback must be provided for the grid to load properly")
        }
    }

    @Bind
    private onGridReady(event: GridReadyEvent): void {
        this.gridApi = event.api

        const setColumnVisible = event.columnApi.setColumnVisible
        event.columnApi.setColumnVisible = (field, isVisible) => {
            setColumnVisible.call(this.gridApi, field, isVisible)
            this.resizeGrid()
        }

        if (this.props.onGridReady) {
            this.props.onGridReady(event)
        }
    }

    @Bind
    private onPaginationChanged(event: PaginationChangedEvent) {
        if (!this.isFirstRender) {
            this.savePagination()
        }
    }

    @Bind
    private restorePagination() {
        const pageNumber = UrlUtil.getUrlParam("page")
        const parsedPageNumber = Number(pageNumber)

        if (pageNumber && !isNaN(parsedPageNumber) && parsedPageNumber > 1) {
            this.gridApi.paginationGoToPage(parsedPageNumber - 1)
        }
    }

    @Bind
    private savePagination() {
        const pageNumber = this.gridApi.paginationGetCurrentPage() + 1

        UrlUtil.setURlParams("page", pageNumber > 1 ? pageNumber.toString() : "")
    }

    @Debounce
    private resizeGrid(): void {
        this.setHeight()
        if (Grid.isSafari) {
            this.setWidthSafariHack()
        } else {
            if (this.gridApi) {
                this.gridApi.sizeColumnsToFit()
            }
        }
    }
}

export interface GridProps<TData> extends AgGridReactProps<TData> {
    serverSidePagination?: boolean
    compact?: boolean
    multiSelect?: boolean
    autoHeight?: boolean
    className?: string
}
