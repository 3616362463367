import { useQuery } from "@tanstack/react-query"
import * as React from "react"
import { ApiResponse } from "../api/Base.api"
import {
    EndUserAccessActivityRes,
    ReportingApi,
    AccessActivityRes,
    ServiceAccessActivityRes,
    TopServicesRes,
    TopEndUsersRes,
    ReportType,
    TopServicesByPlatformRes,
    TopServicesByTrustLevelRes,
    TopServicesByPlatformTrustLevelRes,
} from "../api/Reporting.api"
import { Singleton } from "../decorators/Singleton.decorator"
import { DateUtil } from "../utils/Date.util"
import ServiceContext from "./context"

@Singleton("ReportingService")
export class ReportingService {
    public getAccessActivityForUser(
        email: string,
        startTime: number,
        endTime: number
    ): Promise<AccessActivity[]> {
        return this.reportingApi
            .getUserAccessActivityReport(email, startTime, endTime)
            .then((response: ApiResponse<EndUserAccessActivityRes>) => {
                return response.data.service_devices
                    ? response.data.service_devices.map(this.mapResToAccessActivity)
                    : []
            })
    }

    public getAccessActivityForService(
        serviceId: string,
        startTime: number,
        endTime: number
    ): Promise<AccessActivity[]> {
        return this.reportingApi
            .getServiceAccessActivityReport(serviceId, startTime, endTime)
            .then((response: ApiResponse<ServiceAccessActivityRes>) => {
                return response.data.enduser_devices
                    ? response.data.enduser_devices.map(this.mapResToAccessActivity)
                    : []
            })
    }

    public getTopServicesAccessedByUniqueUsers(
        startTime: number,
        endTime: number,
        limit: number
    ): Promise<TopService[]> {
        return this.reportingApi
            .getTopReport<TopServicesRes>(
                ReportType.TOP_SERVICES_BY_USERS_ALLOWED,
                startTime,
                endTime,
                limit
            )
            .then((response: ApiResponse<TopServicesRes>) => {
                return (
                    response.data?.services.map((s) => {
                        return { name: s.name, id: s.id, count: s.count }
                    }) || []
                )
            })
    }

    public getTopServicesAccessedByUniqueUsersBlocked(
        startTime: number,
        endTime: number,
        limit: number
    ): Promise<TopService[]> {
        return this.reportingApi
            .getTopReport<TopServicesRes>(
                ReportType.TOP_SERVICES_BY_USERS_BLOCKED,
                startTime,
                endTime,
                limit
            )
            .then((response: ApiResponse<TopServicesRes>) => {
                return (
                    response.data?.services.map((s) => {
                        return { name: s.name, id: s.id, count: s.count }
                    }) || []
                )
            })
    }

    public getTopUsersByServicesAccessed(
        startTime: number,
        endTime: number,
        limit: number
    ): Promise<TopUser[]> {
        return this.reportingApi
            .getTopReport<TopEndUsersRes>(
                ReportType.TOP_USERS_BY_SERVICES_ACCESSED,
                startTime,
                endTime,
                limit
            )
            .then((response: ApiResponse<TopEndUsersRes>) => {
                return (
                    response.data?.endusers.map((s) => {
                        return { email: s.email, count: s.count }
                    }) || []
                )
            })
    }

    public getTopServicesAccessedByUnregisteredDevices(
        startTime: number,
        endTime: number,
        limit: number
    ): Promise<TopService[]> {
        return this.reportingApi
            .getTopReport<TopServicesRes>(
                ReportType.TOP_SERVICES_ACCESSED_BY_UNREGISTERED_DEVICES,
                startTime,
                endTime,
                limit
            )
            .then((response: ApiResponse<TopServicesRes>) => {
                return (
                    response.data?.services.map((s) => {
                        return { name: s.name, id: s.id, count: s.count }
                    }) || []
                )
            })
    }

    public getTopUsersAccessingServicesWithUnregisteredDevices(
        startTime: number,
        endTime: number,
        limit: number
    ): Promise<TopUser[]> {
        return this.reportingApi
            .getTopReport<TopEndUsersRes>(
                ReportType.TOP_USERS_USING_UNREGISTERED_DEVICES,
                startTime,
                endTime,
                limit
            )
            .then((response: ApiResponse<TopEndUsersRes>) => {
                return (
                    response.data?.endusers.map((s) => {
                        return { email: s.email, count: s.count }
                    }) || []
                )
            })
    }

    public getTopServicesAllowedByPlatform(
        startTime: number,
        endTime: number,
        limit: number
    ): Promise<TopServiceByPlatform[]> {
        return this.reportingApi
            .getTopReport<TopServicesByPlatformRes>(
                ReportType.TOP_SERVICES_ALLOWED_BY_PLATFORM,
                startTime,
                endTime,
                limit
            )
            .then((response) => {
                return (
                    response.data?.services.map((s) => {
                        return {
                            serviceName: s.name,
                            id: s.id,
                            platformAccessCounts: s.platform_access_counts || [],
                        }
                    }) || []
                )
            })
    }

    public getTopServicesBlockedByPlatform(
        startTime: number,
        endTime: number,
        limit: number
    ): Promise<TopServiceByPlatform[]> {
        return this.reportingApi
            .getTopReport<TopServicesByPlatformRes>(
                ReportType.TOP_SERVICES_BLOCKED_BY_PLATFORM,
                startTime,
                endTime,
                limit
            )
            .then((response) => {
                return (
                    response.data?.services.map((s) => {
                        return {
                            serviceName: s.name,
                            id: s.id,
                            platformAccessCounts: s.platform_access_counts || [],
                        }
                    }) || []
                )
            })
    }

    public getTopServicesAllowedByTrustLevel(
        startTime: number,
        endTime: number,
        limit: number
    ): Promise<TopServiceByTrustLevel[]> {
        return this.reportingApi
            .getTopReport<TopServicesByTrustLevelRes>(
                ReportType.TOP_SERVICES_ALLOWED_BY_TRUST_LEVEL,
                startTime,
                endTime,
                limit
            )
            .then((response) => {
                return (
                    response.data?.services.map((s) => {
                        return {
                            serviceName: s.name,
                            id: s.id,
                            trustLevelAccessCounts: s.trustlevel_access_counts || [],
                        }
                    }) || []
                )
            })
    }

    public getTopServicesBlockedByTrustLevel(
        startTime: number,
        endTime: number,
        limit: number
    ): Promise<TopServiceByTrustLevel[]> {
        return this.reportingApi
            .getTopReport<TopServicesByTrustLevelRes>(
                ReportType.TOP_SERVICES_BLOCKED_BY_TRUST_LEVEL,
                startTime,
                endTime,
                limit
            )
            .then((response) => {
                return (
                    response.data?.services.map((s) => {
                        return {
                            serviceName: s.name,
                            id: s.id,
                            trustLevelAccessCounts: s.trustlevel_access_counts || [],
                        }
                    }) || []
                )
            })
    }

    public getTopServicesAllowedByPlatformTrustLevel(
        startTime: number,
        endTime: number
    ): Promise<TopServiceByPlatformTrustLevel[]> {
        return this.reportingApi
            .getTopReport<TopServicesByPlatformTrustLevelRes>(
                ReportType.TOP_SERVICES_ALLOWED_BY_PLATFORM_TRUST_LEVEL,
                startTime,
                endTime,
                10
            )
            .then((response) => {
                return (
                    response.data?.services.map((s) => {
                        return {
                            serviceName: s.name,
                            id: s.id,
                            platforms:
                                s.platforms?.map((p) => {
                                    return {
                                        name: p.name,
                                        trustLevelAccessCounts: p.trustlevel_access_counts || [],
                                    }
                                }) || [],
                        }
                    }) || []
                )
            })
    }

    public getTopServicesBlockedByPlatformTrustLevel(
        startTime: number,
        endTime: number
    ): Promise<TopServiceByPlatformTrustLevel[]> {
        return this.reportingApi
            .getTopReport<TopServicesByPlatformTrustLevelRes>(
                ReportType.TOP_SERVICES_BLOCKED_BY_PLATFORM_TRUST_LEVEL,
                startTime,
                endTime,
                10
            )
            .then((response) => {
                return (
                    response.data?.services.map((s) => {
                        return {
                            serviceName: s.name,
                            id: s.id,
                            platforms:
                                s.platforms?.map((p) => {
                                    return {
                                        name: p.name,
                                        trustLevelAccessCounts: p.trustlevel_access_counts || [],
                                    }
                                }) || [],
                        }
                    }) || []
                )
            })
    }

    public async areThereAccessedServices(): Promise<boolean> {
        const { start = 0, end = Date.now() } = DateUtil.convertDeltaToTimeRange({
            name: "1 Month",
            delta: DateUtil.DAY * 30,
        })

        const services = await this.getTopServicesAccessedByUniqueUsers(start, end, 1)
        return services.length > 0
    }

    private reportingApi: ReportingApi = new ReportingApi()

    private mapResToAccessActivity(res: AccessActivityRes): AccessActivity {
        return {
            serviceId: res.service_id,
            serviceName: res.service_name || res.service_id,
            email: res.email,
            name: res.user_name || res.email,
            deviceFriendlyName: res.device_name || res.serial_number,
            serial: res.serial_number,
            lastAuthorizedTimestamp: res.last_authorized_timestamp
                ? DateUtil.convertLargeTimestamp(res.last_authorized_timestamp)
                : 0,
            lastUnauthorizedTimestamp: res.last_unauthorized_timestamp
                ? DateUtil.convertLargeTimestamp(res.last_unauthorized_timestamp)
                : 0,
        }
    }
}

export function useGetAccessActivityForService(
    serviceId: string,
    startTime: number,
    endTime: number,
    options?: QueryOptions<AccessActivity[]>
) {
    const reportingService = new ReportingService()
    return useQuery<AccessActivity[], string>({
        ...options,
        queryKey: ["reportingService.getAccessActivityForService", serviceId],
        queryFn: () => reportingService.getAccessActivityForService(serviceId, startTime, endTime),
    })
}

export function useGetAccessActivityForUser(
    email: string,
    startTime: number,
    endTime: number,
    options?: QueryOptions<AccessActivity[]>
) {
    const reportingService = new ReportingService()
    return useQuery<AccessActivity[], string>({
        ...options,
        queryKey: ["reportingService.getAccessActivityForUser", email],
        queryFn: () => reportingService.getAccessActivityForUser(email, startTime, endTime),
    })
}

export interface AccessActivity {
    serviceId?: string
    serviceName?: string
    email?: string
    name?: string
    serial?: string
    deviceFriendlyName?: string
    lastAuthorizedTimestamp: number
    lastUnauthorizedTimestamp: number
}

interface TopService {
    name: string
    id: string
    count: number
}

interface TopUser {
    email: string
    count: number
}

interface TopServiceByPlatform {
    serviceName: string
    id: string
    platformAccessCounts: ObjectCount<"platform">[]
}

interface TopServiceByTrustLevel {
    serviceName: string
    id: string
    trustLevelAccessCounts: ObjectCount<"trustlevel">[]
}

interface TopServiceByPlatformTrustLevel {
    serviceName: string
    id: string
    platforms: PlatformByTrustLevel[]
}

interface PlatformByTrustLevel {
    name: string
    trustLevelAccessCounts: ObjectCount<"trustlevel">[]
}

export const useServiceReporting = () =>
    React.useContext(ServiceContext)?.reporting || new ReportingService()
