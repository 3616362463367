import * as React from "react"
import { ModalRef } from "../../../services/Modal.service"
import styles from "./DeleteCancelActions.module.scss"
import { useServiceLocalization } from "../../../services/localization/Localization.service"
import { Button, ButtonElement, ButtonType } from "../../../../components/button/Button.component"

interface DeleteCancelActionsProps {
    hideCancel?: boolean
    modalRef: ModalRef
    cancelString?: string
    okString?: string
    disabled?: boolean
}

export function DeleteCancelActions({
    hideCancel,
    modalRef,
    cancelString,
    okString,
    disabled,
}: DeleteCancelActionsProps) {
    const localization = useServiceLocalization()

    return (
        <div className={styles.buttons}>
            {!hideCancel && (
                <Button
                    className={styles.cancelBtn}
                    onClick={modalRef.cancel}
                    asElement={ButtonElement.BUTTON}
                    buttonType={ButtonType.SECONDARY}
                >
                    {cancelString || localization.getString("cancel")}
                </Button>
            )}
            <Button
                onClick={modalRef.close}
                disabled={disabled}
                asElement={ButtonElement.BUTTON}
                buttonType={ButtonType.DESTRUCTIVE}
            >
                {okString || localization.getString("delete")}
            </Button>
        </div>
    )
}
