import React from "react"

import { Singleton } from "../decorators/Singleton.decorator"
import { EventsApi, EventRes, EventCountRes, EventItemRes } from "../api/Events.api"
import { EventProps } from "../api/EventProps.types"
import { Paginated } from "../utils/AgGrid.util"
import { ApiUtil } from "../utils/Api.util"
import { useGetEventTypes } from "../../v3/api/EventTypes.api"

@Singleton("EventsService")
class EventsServiceClass {
    public getEvents(props: EventProps, isEventChartEnable: boolean): Promise<EventsData> {
        if (isEventChartEnable)
            props.event_window_width_mins = this.getIntervalInMins(
                this.getWindow(props.before, props.after)
            )
        const p: EventProps = ApiUtil.sanitizeObject(props)
        const chartProps = isEventChartEnable ? this.getChartProps(p) : undefined

        return Promise.all([
            this.eventsApi.getEvents(p),
            this.eventsApi.getEventsCount(p),
            chartProps ? this.eventsApi.getEventsCount(chartProps) : undefined,
        ]).then(
            ([events, count, chartCount]: [EventRes, EventCountRes, EventCountRes | undefined]) => {
                return {
                    data: events.data.map(this.mapEventItemResToEvent),
                    total: count.data,
                    chartData: chartCount
                        ? this.mapEventCountDetailsResToChartData(chartCount.details)
                        : [],
                }
            }
        )
    }

    private getChartProps(p: EventProps) {
        const props = { ...p }
        const fourteenDaysInMs = 1000 * 60 * 60 * 24 * 14

        if (!props.before) props.before = Date.now()
        if (!props.after) props.after = props.before - fourteenDaysInMs
        if (props.before - props.after > fourteenDaysInMs)
            props.before = props.after + fourteenDaysInMs
        return props
    }

    private getWindow(before?: number, after?: number) {
        const fourteenDaysInMins = 14 * 24 * 60
        if (!before || !after) return fourteenDaysInMins
        const fourteenDaysInMs = fourteenDaysInMins * 60 * 1000
        const msWindow = before - after
        if (msWindow > fourteenDaysInMs) return fourteenDaysInMins
        return Math.ceil(msWindow / 1000 / 60)
    }
    private getIntervalInMins(windowInMins: number) {
        const oneDayInMins = 24 * 60
        if (windowInMins > oneDayInMins * 7) return Interval.SIX_HOURS
        if (windowInMins > oneDayInMins * 3) return Interval.THREE_HOURS
        if (windowInMins > oneDayInMins) return Interval.ONE_HOUR
        if (windowInMins > 12 * 60) return Interval.THIRTY_MINUTES
        return Interval.FIFTEEN_MINUTES
    }

    private eventsApi: EventsApi = new EventsApi()

    private mapEventItemResToEvent(e: EventItemRes): MonitorEvent {
        return {
            timestamp: e.created_at,
            type: e.type,
            subType: e.sub_type,
            message: e.message,
            severity: e.severity,
            id: e.id,
            userEmail: e.user_principal?.user?.email,
            model: e.user_principal?.device?.model,
            deviceId: e.user_principal?.device?.id,
            deviceSerial: e.user_principal?.device?.serial_number,
            serviceName: e.service?.name,
            externalId: e.external_id,
            raw: JSON.stringify(e, null, 4),
        }
    }

    private mapEventCountDetailsResToChartData(response: EventCountRes["details"]): ChartData[] {
        return response.map((res) => ({
            dateTime: res.start,
            totalEvents: res.num_total_events,
            widthMins: res.width_mins,
        }))
    }
}

export enum Interval {
    SIX_HOURS = 60 * 6,
    THREE_HOURS = 60 * 3,
    ONE_HOUR = 60,
    THIRTY_MINUTES = 30,
    FIFTEEN_MINUTES = 15,
    NULL = 0,
}

export type EventsService = EventsServiceClass

export function useEventsService(): EventsServiceClass {
    return React.useMemo(() => new EventsServiceClass(), [])
}

export function useGetPrivateAccessEventTypes() {
    return useGetEventTypes("private_access")
}

export function useGetInternetAccessEventTypes() {
    return useGetEventTypes("internet_access")
}

export interface MonitorEvent {
    timestamp: number
    type: string
    subType?: string
    message: string
    severity: string
    userEmail?: string
    model?: string
    id?: string
    deviceId?: string
    externalId: string
    deviceSerial?: string
    serviceName?: string
    raw: string
}

export interface ChartData {
    dateTime: string
    totalEvents: number
    widthMins: Interval
}

export interface EventsData extends Paginated<MonitorEvent> {
    chartData: ChartData[]
}
