import { faPlus } from "@fortawesome/pro-regular-svg-icons"
import React from "react"

import { useServiceLocalization, useServiceModal } from "../../../../../../../pre-v3/services"
import { TrustFactor } from "../../../../../../services/TrustProfile.service"
import styles from "./AddTrustFactorsButton.module.scss"
import { AddTrustFactorModal } from "./addTrustFactorModal/AddTrustFactorModal.component"
import { TrustFactors, IntegrationTrustFactors } from "./useTrustFactors.hook"
import { Button } from "../../../../../../components/button/Button.component"

interface Props {
    banyanTrustFactors: TrustFactors
    integrationTrustFactors: IntegrationTrustFactors
    isDisabled: boolean
    onAddTrustFactor(trustFactor: TrustFactor): void
}

export function AddTrustFactorsButton(props: Props): JSX.Element {
    const localization = useServiceLocalization()
    const modalService = useServiceModal()

    const onClick: React.MouseEventHandler = (event) => {
        event.preventDefault()

        modalService
            .open(localization.getString("addTrustFactor"), {
                component: AddTrustFactorModal,
                props,
                withoutBody: true,
                fullWidth: true,
                maxWidth: "md",
            })
            .onClose(props.onAddTrustFactor)
    }

    return (
        <Button
            id="add-trust-factor-button"
            onClick={onClick}
            disabled={props.isDisabled}
            className={styles.button}
            icon={faPlus}
        >
            {localization.getString("addTrustFactor")}
        </Button>
    )
}
