import React from "react"

import { Link } from "../../../../../v3/components/link/Link.component"

export default function () {
    const value = this.props.value || "-"
    return this.state.serial.toLowerCase() === "unknown" ? (
        value
    ) : (
        <Link to={this.state.link} onClick={this.clickHandler}>
            {value}
        </Link>
    )
}
