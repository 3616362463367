import React from "react"

import { Button, ButtonElement, ButtonType } from "../../../../components/button/Button.component"
import ActionBarService from "../../../../pre-v3/services/ActionBar.service"
import { LanguageKey } from "../../../../pre-v3/services/localization/languages/en-US.language"
import { useServiceLocalization } from "../../../../pre-v3/services/localization/Localization.service"
import { DateUtil } from "../../../../pre-v3/utils/Date.util"
import {
    Device,
    RemoteDiagnosticsStatus,
    useDownloadDeviceRemoteDiagnosticsLogs,
    useGetDeviceRemoteDiagnostics,
    useRequestDeviceRemoteDiagnostics,
} from "../../../services/Device.service"
import {
    ErrorBanner,
    WarningBanner,
    SuccessBanner,
    ErrorBanners,
} from "../../../components/banner/Banner.component"
import { Loader } from "../../../components/loader/Loader.component"
import { Tooltip } from "../../../components/tooltip/Tooltip.component"
import styles from "./RemoteDiagnostics.module.scss"

interface Props {
    tabPanelId?: string
    device: Device
    canWriteAll: boolean
}

export function RemoteDiagnostics(props: Props): JSX.Element {
    const localization = useServiceLocalization()

    const actionBarService = new ActionBarService()

    const getRemoteDiagnosticsQuery = useGetDeviceRemoteDiagnostics(props.device, {
        enabled: props.canWriteAll,
    })

    React.useEffect(() => {
        actionBarService.registerRefreshFn(getRemoteDiagnosticsQuery.refetch)

        return () => {
            actionBarService.unregisterRefreshFn(getRemoteDiagnosticsQuery.refetch)
        }
    }, [getRemoteDiagnosticsQuery.refetch])

    const requestDeviceRemoteDiagnostics = useRequestDeviceRemoteDiagnostics(props.device)

    const downloadDeviceRemoteDiagnosticsLogs = useDownloadDeviceRemoteDiagnosticsLogs(props.device)

    if (!props.canWriteAll) {
        return (
            <Container {...props} errors={[]}>
                <div className={styles.buttons}>
                    <Tooltip
                        title={localization.getString(
                            "yourProfileDoesNotPermitActionAsItDoesNotHaveAdminPrivilege"
                        )}
                    >
                        <span>
                            <Button
                                asElement={ButtonElement.BUTTON}
                                buttonType={ButtonType.SECONDARY}
                                disabled
                            >
                                {localization.getString("runDiagnostics")}
                            </Button>
                        </span>
                    </Tooltip>
                </div>
            </Container>
        )
    }

    if (getRemoteDiagnosticsQuery.status === "loading") {
        return <Loader children isLoading center medium />
    }

    if (getRemoteDiagnosticsQuery.status === "error") {
        return <ErrorBanner>{String(getRemoteDiagnosticsQuery.error)}</ErrorBanner>
    }

    const { data: remoteDiagnostics } = getRemoteDiagnosticsQuery
    const errors = [
        requestDeviceRemoteDiagnostics.isError && String(requestDeviceRemoteDiagnostics.error),
        downloadDeviceRemoteDiagnosticsLogs.isError &&
            String(downloadDeviceRemoteDiagnosticsLogs.error),
    ]

    switch (remoteDiagnostics.status) {
        case RemoteDiagnosticsStatus.INACTIVE:
            return (
                <Container {...props} errors={errors}>
                    <div className={styles.buttons}>
                        <Button
                            asElement={ButtonElement.BUTTON}
                            buttonType={ButtonType.PRIMARY}
                            onClick={() => requestDeviceRemoteDiagnostics.mutate()}
                            loading={requestDeviceRemoteDiagnostics.isLoading}
                        >
                            {localization.getString("runDiagnostics")}
                        </Button>
                    </div>
                </Container>
            )

        case RemoteDiagnosticsStatus.ERROR:
            return (
                <Container {...props} errors={errors}>
                    <div>
                        <p className={styles.headerText}>{localization.getString("status")}</p>
                        <ErrorBanner>{remoteDiagnostics.errorMessage}</ErrorBanner>
                    </div>
                    <Timestamp
                        messageKey="remoteDiagnosticRequestTimestamp"
                        timestamp={remoteDiagnostics.requestedAt}
                    />
                    <div className={styles.buttons}>
                        <Button
                            asElement={ButtonElement.BUTTON}
                            buttonType={ButtonType.PRIMARY}
                            onClick={() => requestDeviceRemoteDiagnostics.mutate()}
                            loading={requestDeviceRemoteDiagnostics.isLoading}
                        >
                            {localization.getString("runDiagnostics")}
                        </Button>
                    </div>
                </Container>
            )

        case RemoteDiagnosticsStatus.EXPIRED:
            return (
                <Container {...props} errors={errors}>
                    <div className={styles.buttons}>
                        <Button
                            asElement={ButtonElement.BUTTON}
                            buttonType={ButtonType.PRIMARY}
                            onClick={() => requestDeviceRemoteDiagnostics.mutate()}
                            loading={requestDeviceRemoteDiagnostics.isLoading}
                        >
                            {localization.getString("runDiagnostics")}
                        </Button>
                    </div>
                </Container>
            )

        case RemoteDiagnosticsStatus.PENDING:
            return (
                <Container {...props} errors={errors}>
                    <div>
                        <p className={styles.headerText}>{localization.getString("status")}</p>
                        <WarningBanner>
                            {localization.getString("remoteDiagnosticsPendingMsg")}
                        </WarningBanner>
                    </div>
                    <Timestamp
                        messageKey="remoteDiagnosticRequestTimestamp"
                        timestamp={remoteDiagnostics.requestedAt}
                    />
                </Container>
            )

        case RemoteDiagnosticsStatus.SUCCESS:
            return (
                <Container {...props} errors={errors}>
                    <div>
                        <p className={styles.headerText}>{localization.getString("status")}</p>
                        <SuccessBanner>
                            {localization.getString("remoteDiagnosticsSuccessMsg")}
                        </SuccessBanner>
                    </div>
                    <Timestamp
                        messageKey="remoteDiagnosticRequestTimestamp"
                        timestamp={remoteDiagnostics.requestedAt}
                    />
                    <Timestamp
                        messageKey="remoteDiagnosticLogsCollectedTimestamp"
                        timestamp={remoteDiagnostics.logsCollectedAt}
                    />
                    <div className={styles.buttons}>
                        <Button
                            asElement={ButtonElement.BUTTON}
                            buttonType={ButtonType.SECONDARY}
                            onClick={() =>
                                downloadDeviceRemoteDiagnosticsLogs.mutate(remoteDiagnostics)
                            }
                        >
                            {localization.getString("downloadLogs")}
                        </Button>
                        <Button
                            asElement={ButtonElement.BUTTON}
                            buttonType={ButtonType.PRIMARY}
                            onClick={() => requestDeviceRemoteDiagnostics.mutate()}
                            loading={requestDeviceRemoteDiagnostics.isLoading}
                        >
                            {localization.getString("runDiagnostics")}
                        </Button>
                    </div>
                </Container>
            )
    }
}

interface ContainerProps {
    tabPanelId?: string
    children: React.ReactNode
    errors: React.ReactNode[]
}

function Container(props: ContainerProps): JSX.Element {
    const localization = useServiceLocalization()

    return (
        <div id={props.tabPanelId} className={styles.container}>
            <label className={styles.title}>{localization.getString("remoteDiagnostics")}</label>
            <p className={styles.description}>{localization.getString("remoteDiagnosticsDesc")}</p>
            {props.children}
            <ErrorBanners errors={props.errors} />
        </div>
    )
}

interface TimestampProps {
    messageKey: LanguageKey
    timestamp: number
}

function Timestamp(props: TimestampProps): JSX.Element {
    const localization = useServiceLocalization()

    return (
        <div>
            <p className={styles.headerText}>{localization.getString(props.messageKey)}</p>
            <p className={styles.lastEvaluated}>{DateUtil.format(props.timestamp)}</p>
        </div>
    )
}

function getRemoteDiagnosticsQueryKey(device: Device): string[] {
    return ["getDeviceRemoteDiagnostics", device.id]
}
