import {
    QueryKey,
    UseMutationResult,
    UseQueryResult,
    useMutation,
    useQuery,
    useQueryClient,
} from "@tanstack/react-query"

import { Profile, profileResDict } from "../../utils/profile.utils"
import { OrgApi, UserOrgDetailsRes } from "../api/Org.api"
import { UserProfileApi } from "../api/UserProfile.api"

enum UserProfileHookKey {
    GET_USER_PROFILE = "userProfileService.getUserProfile",
}

const getUserProfileKey: QueryKey = [UserProfileHookKey.GET_USER_PROFILE]

export function useGetUserProfile(
    options?: QueryOptions<UserProfile, unknown, void>
): UseQueryResult<UserProfile, unknown> {
    const orgApi = new OrgApi()

    return useQuery({
        ...options,
        queryKey: getUserProfileKey,
        queryFn: async (): Promise<UserProfile> => {
            const userOrgDetailsRes = await orgApi.getUserOrgDetails()

            return {
                fullName: `${userOrgDetailsRes.First} ${userOrgDetailsRes.Last}`,
                emailAddress: userOrgDetailsRes.Email,
                orgName: userOrgDetailsRes.OrgName,
                accountType: accountTypeResDict[userOrgDetailsRes.AccountType],
                idpType: idpTypeResDict[userOrgDetailsRes.IDPType],
                profile: profileResDict[userOrgDetailsRes.Profile],
                hasRefreshToken: userOrgDetailsRes.HasRefreshToken,
                isRefreshTokenEnabledForSaml: userOrgDetailsRes.RefreshTokenForSAMLEnabled,
            }
        },
    })
}

export function useChangePassword(
    options?: QueryOptions<void, unknown, ChangePasswordVariables>
): UseMutationResult<void, unknown, ChangePasswordVariables> {
    const userProfileApi = new UserProfileApi()

    return useMutation<void, unknown, ChangePasswordVariables>({
        ...options,
        mutationFn: ({ currentPassword, newPassword }) =>
            userProfileApi.changePassword({
                Password: currentPassword,
                NewPassword: newPassword,
            }),
    })
}

export function useGenerateRefreshToken(
    options?: QueryOptions<string>
): UseMutationResult<string, unknown, void> {
    const userProfileApi = new UserProfileApi()

    return useMutation({
        ...options,
        mutationFn: async () => {
            const { refresh_token } = await userProfileApi.generateRefreshToken()
            return refresh_token
        },
    })
}

export function useRevokeRefreshToken(
    options?: QueryOptions
): UseMutationResult<void, unknown, void> {
    const userProfileApi = new UserProfileApi()

    const queryClient = useQueryClient()

    return useMutation({
        ...options,
        mutationFn: () => userProfileApi.revokeRefreshToken(),
        onSuccess: () => {
            queryClient.setQueryData<UserProfile>(getUserProfileKey, (oldData) =>
                oldData ? { ...oldData, hasRefreshToken: false } : undefined
            )
            options?.onSuccess?.()
        },
    })
}

export interface UserProfile {
    fullName: string
    emailAddress: string
    orgName: string
    accountType: AccountType
    idpType: IdpType
    profile: Profile
    hasRefreshToken: boolean
    isRefreshTokenEnabledForSaml: boolean
}

export enum AccountType {
    SAML = "SAML",
    LOCAL = "LOCAL",
}

const accountTypeResDict: Record<UserOrgDetailsRes["AccountType"], AccountType> = {
    SAML: AccountType.SAML,
    LOCAL: AccountType.LOCAL,
}

export enum IdpType {
    SAML = "SAML",
    BANYAN = "BANYAN",
}

const idpTypeResDict: Record<UserOrgDetailsRes["IDPType"], IdpType> = {
    SAML: IdpType.SAML,
    BANYAN: IdpType.BANYAN,
}

interface ChangePasswordVariables {
    currentPassword: string
    newPassword: string
}
