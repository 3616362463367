import classnames from "classnames/bind"
import React from "react"
import { Link as RouterLink } from "react-router-dom"

import styles from "./Link.module.scss"

/**
 * A link styled component that can be used as a button or an anchor tag.
 *
 * @param children The text to display inside the link.
 * @param className The class name to apply to the link.
 * @param to The URL to link to.
 * @param asElement The element to render the link as (Button or Anchor).
 */
export const Link = React.forwardRef<HTMLButtonElement & HTMLAnchorElement, LinkProps>(
    (props, ref) => {
        const { children, className, ...restProps } = props

        const classes = classnames(className, styles.link)

        if (restProps.asElement === LinkElement.LINK) {
            const { to, asElement, ...linkProps } = restProps
            if (to.startsWith("http") || to.startsWith("mailto:")) {
                return (
                    <a
                        {...linkProps}
                        href={to}
                        target="_blank"
                        rel="noreferrer"
                        className={classes}
                        ref={ref}
                    >
                        {children}
                    </a>
                )
            }

            return (
                <RouterLink {...linkProps} to={to} className={classes}>
                    {children}
                </RouterLink>
            )
        }

        const { asElement, ...buttonRest } = restProps

        return (
            <button {...buttonRest} className={classes} type={buttonRest.type} ref={ref}>
                {children}
            </button>
        )
    }
)

Link.displayName = "Link"

export type LinkProps =
    | (React.ButtonHTMLAttributes<HTMLButtonElement> & {
          asElement?: LinkElement.BUTTON
      })
    | (React.AnchorHTMLAttributes<HTMLAnchorElement> & {
          to: string
          asElement: LinkElement.LINK
      })

export enum LinkElement {
    BUTTON = "button",
    LINK = "link",
}
