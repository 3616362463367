import classNames from "classnames/bind"
import React from "react"

import { useServiceLocalization } from "../../../../../../../../pre-v3/services"
import {
    SelectInput,
    Option,
} from "../../../../../../../components/select-input/SelectInput.component"
import { ZtaScoreTrustFactor, Severity } from "../../../../../../../services/TrustProfile.service"
import styles from "./ZtaScore.module.scss"
import { LanguageKey } from "../../../../../../../../pre-v3/services/localization/languages/en-US.language"

interface Props {
    trustFactor: ZtaScoreTrustFactor
    className?: string
    onEdit(trustFactor: ZtaScoreTrustFactor): void
    disabled?: boolean
}

export function ZtaScore(props: Props): JSX.Element {
    const localization = useServiceLocalization()
    const moderateDisplayName = localization.getString("moderate")
    const strictDisplayName = localization.getString("strict")

    const severityOptions = React.useMemo(
        (): Option[] => [
            { displayName: moderateDisplayName, value: Severity.MODERATE },
            { displayName: strictDisplayName, value: Severity.STRICT },
        ],
        [moderateDisplayName, strictDisplayName]
    )

    const onChange = (severity: Severity) => props.onEdit({ ...props.trustFactor, severity })

    return (
        <div className={classNames(styles.container, props.className)}>
            <b className={styles.label}>{localization.getString("setZtaScoreSeverity")}</b>
            <div className={styles.severityContainer}>
                <SelectInput
                    options={severityOptions}
                    value={props.trustFactor.severity}
                    onChange={onChange}
                    className={styles.severitySelect}
                    disabled={props.disabled}
                />
                <div>
                    {localization.getString(severityDescriptionDict[props.trustFactor.severity])}
                </div>
            </div>
        </div>
    )
}

const severityDescriptionDict: Record<Severity, LanguageKey> = {
    [Severity.MODERATE]: "allowOnlyDevicesWithACrowdStrikeZtaScoreOf65OrHigher",
    [Severity.STRICT]: "allowOnlyDevicesWithACrowdStrikeZtaScoreOf75OrHigher",
}
