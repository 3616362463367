import { GridReadyEvent, GridApi } from "ag-grid-community"
import React, { useRef, useState, useEffect } from "react"
import { useHistory } from "react-router-dom"

import { ROUTE, formatRoutePath } from "../../../../routes"
import {
    TrustIntegration,
    useGetTrustIntegrations,
} from "../../../services/TrustIntegration.service"
import { useServiceLocalization } from "../../../../pre-v3/services/localization/Localization.service"
import AgGridUtil from "../../../../pre-v3/utils/AgGrid.util"
import { encodeID } from "../../../../pre-v3/utils/Url.util"
import { IconType } from "../../../../pre-v3/services/ActionBar.service"
import { SearchInput } from "../../../components/search-input/SearchInput.component"
import { ErrorBanner } from "../../../components/banner/Banner.component"
import { Loader } from "../../../components/loader/Loader.component"
import { StatusCellRenderer } from "../status-cell-renderer/StatusCellRenderer.component"
import { RowTitle } from "../../../components/grid/RowTitle.component"
import styles from "./TrustIntegrationList.module.scss"
import trustStyles from "../../../../pages/trust/Trust.module.scss"

// Note: Old components, move them to v3
import { Grid } from "../../../../pre-v3/components/grid/Grid.component"
import { PageHeading } from "../../../../components/page-heading/PageHeading.component"
import { Button, ButtonElement, ButtonType } from "../../../../components/button/Button.component"
import { Tooltip } from "../../../components/tooltip/Tooltip.component"

interface Props {
    canWriteAll: boolean
}

export function TrustIntegrationsList(props: Props): JSX.Element {
    const localization = useServiceLocalization()
    const history = useHistory()
    const grid = useRef<GridApi>()

    const [search, setSearch] = useState<string>("")

    const {
        data,
        refetch: fetchTrustIntegrations,
        isFetching: isTrustIntegrationLoading,
        isError: isTrustIntegrationError,
        error: trustIntegrationError,
    } = useGetTrustIntegrations()

    useEffect(() => {
        grid.current?.setQuickFilter?.(search)
    }, [search])

    function onGridReady(event: GridReadyEvent) {
        grid.current = event.api
    }

    function addIntegration() {
        history.push(ROUTE.INTEGRATIONS_CREATE)
    }

    function getColumns() {
        return [
            {
                headerName: localization.getString("status"),
                field: "syncStatus",
                flex: 65,
                cellRenderer: StatusCellRenderer,
                comparator: AgGridUtil.alphaBetComparator,
            },
            {
                headerName: localization.getString("name"),
                field: "name",
                tooltipValueGetter: AgGridUtil.linkTooltipValueGetter,
                cellRenderer: NameCellRenderer,
                comparator: AgGridUtil.alphaBetComparator,
                flex: 120,
            },
            {
                headerName: localization.getString("integrationType"),
                field: "type",
                flex: 90,
                comparator: AgGridUtil.alphaBetComparator,
                valueFormatter: AgGridUtil.nullableStringFormatter,
            },
            {
                headerName: localization.getString("trustFactorsEnabled"),
                flex: 100,
                field: "enabledSignals",
                sortable: false,
            },
            {
                headerName: localization.getString("lastBatchSync"),
                field: "lastBatchUpdatedAt",
                flex: 100,
                valueFormatter: AgGridUtil.dateFormatter,
            },
        ]
    }

    return (
        <section aria-labelledby={Id.HEADING} className={trustStyles.section}>
            <header className={trustStyles.header}>
                <PageHeading id={Id.HEADING}>{localization.getString("integrations")}</PageHeading>
                <Tooltip title={localization.getString("refresh")}>
                    <Button
                        icon={IconType.REDO}
                        onClick={() => fetchTrustIntegrations()}
                        asElement={ButtonElement.BUTTON}
                        buttonType={ButtonType.SECONDARY}
                        aria-label={localization.getString("refresh")}
                    />
                </Tooltip>
            </header>
            <section className={styles.headerSection}>
                <SearchInput
                    placeholder={localization.getString("search")}
                    onChangeValue={setSearch}
                    value={search}
                />
                {props.canWriteAll && (
                    <Button
                        buttonType={ButtonType.PRIMARY}
                        asElement={ButtonElement.BUTTON}
                        onClick={addIntegration}
                        icon={IconType.PLUS}
                    >
                        {localization.getString("addIntegration")}
                    </Button>
                )}
            </section>
            <Loader isLoading={isTrustIntegrationLoading} center medium>
                {!isTrustIntegrationError && (
                    <div className={styles.gridContainer}>
                        <Grid
                            onGridReady={onGridReady}
                            columnDefs={getColumns()}
                            rowData={data}
                            pagination
                            context={{ history }}
                        />
                    </div>
                )}
                {typeof trustIntegrationError === "string" && (
                    <ErrorBanner>{trustIntegrationError}</ErrorBanner>
                )}
            </Loader>
        </section>
    )
}

interface NameCellRendererProps {
    value: TrustIntegration["name"]
    data: TrustIntegration
}

function NameCellRenderer(props: NameCellRendererProps) {
    return (
        <RowTitle
            title={props.value}
            route={formatRoutePath(ROUTE.INTEGRATIONS_DETAILS, {
                id: encodeID(props.data.id),
            })}
        />
    )
}

enum Id {
    HEADING = "heading",
}
