import { ColDef } from "ag-grid-community"
import React, { FormEvent, useMemo } from "react"

import { Button, ButtonElement, ButtonType } from "../../../../components/button/Button.component"
import { Grid } from "../../../../pre-v3/components/grid/Grid.component"
import { useServiceLinks } from "../../../../pre-v3/services/link/Link.service"
import { useServiceLocalization } from "../../../../pre-v3/services/localization/Localization.service"
import { AppText } from "../../../components/app-text/AppText.component"
import { ErrorBanner } from "../../../components/banner/Banner.component"
import { CopyCellRenderer } from "../../../components/cell-renderers/copy/CopyCellRenderer.component"
import { Form } from "../../../components/form/Form.component"
import { FormButtons } from "../../../components/form/form-buttons/FormButtons.component"
import { FormColumn } from "../../../components/form/FormColumn.component"
import { RegisteredDomainDetails } from "../../../services/RegisteredDomain.service"
import styles from "./RegisteredDomainDnsForm.module.scss"

export function RegisteredDomainDnsForm(props: RegisteredDomainDnsFormProps) {
    const ls = useServiceLocalization()
    const linkService = useServiceLinks()

    const columns: ColDef[] = useMemo((): ColDef[] => {
        return [
            {
                headerName: ls.getString("type"),
                field: "type",
                width: 150,
            },
            {
                headerName: ls.getString("name"),
                field: "name",
                flex: 1,
                cellRenderer: CopyCellRenderer,
            },
            {
                headerName: ls.getString("value"),
                field: "value",
                flex: 1,
                cellRenderer: CopyCellRenderer,
            },
        ]
    }, [])

    function onSubmit(event: FormEvent<HTMLFormElement>): void {
        event.preventDefault()

        props.onValidate?.()
    }

    function onBack(): void {
        props.onBack?.()
    }

    return (
        <Form onSubmit={onSubmit} id={props.id}>
            <FormColumn
                label={ls.getString("addTheseDNSRecordsForYourDomain")}
                description={
                    <AppText
                        ls={{
                            key: "dnsRegistrarHelpText",
                            replaceVals: [linkService.getLink("registeredDomain")],
                        }}
                    />
                }
            >
                <Grid
                    className={styles.grid}
                    rowData={props.registeredDomain?.dnsRecords}
                    columnDefs={columns}
                />
            </FormColumn>
            {!props.disabled && (
                <FormButtons
                    rightButtons={
                        <>
                            <Button
                                asElement={ButtonElement.BUTTON}
                                buttonType={ButtonType.SECONDARY}
                                type="button"
                                onClick={onBack}
                            >
                                {ls.getString("back")}
                            </Button>
                            <Button
                                type="submit"
                                asElement={ButtonElement.BUTTON}
                                buttonType={ButtonType.PRIMARY}
                                loading={props.isValidating}
                            >
                                {ls.getString("validate")}
                            </Button>
                        </>
                    }
                >
                    {props.error && <ErrorBanner>{props.error}</ErrorBanner>}
                </FormButtons>
            )}
        </Form>
    )
}

interface RegisteredDomainDnsFormProps {
    id?: string
    registeredDomain?: RegisteredDomainDetails
    error?: string | null
    disabled?: boolean
    onBack?: () => void
    onValidate?: () => void
    isValidating?: boolean
}
