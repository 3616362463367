import React from "react"

import useTitle from "../../../hooks/useTitle.hook"
import { useServiceLocalization } from "../../../pre-v3/services/localization/Localization.service"
import { AppBanner } from "../app/AppBanner.component"
import { Redirect, Route, Switch, useLocation } from "react-router-dom"
import { ROUTE, doesRouteMatch } from "../../../routes"
import { InternetAccessEvents } from "./InternetAccessEvents.view"
import { PrivateAccessEvents } from "./PrivateAccessEvents.view"
import { LanguageKey } from "../../../pre-v3/services/localization/languages/en-US.language"
import {
    NavigationTabBar,
    TabProps,
} from "../../../components/navigation-tab-bar/NavigationTabBar.component"
import styles from "./Events.module.scss"
import { useFeatureFlags } from "../../../hooks/useFeatureFlags.hook"
import { Loader } from "../../components/loader/Loader.component"

export function Events(): JSX.Element {
    useTitle(["events", "home", "adminConsole"])

    const localization = useServiceLocalization()
    const location = useLocation()
    const { data: featureFlags } = useFeatureFlags()
    if (!featureFlags) return <Loader center medium />
    const canAccessPrivateEvents: boolean =
        featureFlags.adminConsole.canAccessPrivateAccessEventsOnly
    const canAccessInternetEvents: boolean =
        featureFlags.adminConsole.canAccessInternetAccessEventsOnly
    const canAccessBothEvents: boolean =
        featureFlags.adminConsole.canAccessPrivateAccessEventsOnly &&
        featureFlags.adminConsole.canAccessInternetAccessEventsOnly

    const isEditionBasedOrgEvents: boolean =
        !canAccessPrivateEvents && !canAccessInternetEvents && !canAccessBothEvents
    function getTabs() {
        if (canAccessBothEvents) return thirdLevelRoutes
        if (canAccessPrivateEvents)
            return thirdLevelRoutes.filter((route) => route === ROUTE.PRIVATE_ACCESS_EVENTS)
        if (canAccessInternetEvents)
            return thirdLevelRoutes.filter((route) => route === ROUTE.INTERNET_ACCESS_EVENTS)
        return thirdLevelRoutes
    }

    const getTabProps = (route: ThirdLevelRoute): TabProps => ({
        key: route,
        label: localization.getString(thirdLevelLabels[route]),
        to: route,
        active: doesRouteMatch<ThirdLevelRoute>(route, location),
    })

    return (
        <React.Fragment>
            <AppBanner />
            <div className={styles.mainContainer}>
                <NavigationTabBar tabs={getTabs()} getTabProps={getTabProps} />
                <Switch>
                    {(canAccessPrivateEvents || canAccessBothEvents || isEditionBasedOrgEvents) && (
                        <Route path={ROUTE.PRIVATE_ACCESS_EVENTS} component={PrivateAccessEvents} />
                    )}
                    {(canAccessInternetEvents ||
                        canAccessBothEvents ||
                        isEditionBasedOrgEvents) && (
                        <Route
                            path={ROUTE.INTERNET_ACCESS_EVENTS}
                            component={InternetAccessEvents}
                        />
                    )}
                    <Redirect
                        to={
                            canAccessPrivateEvents || canAccessBothEvents || isEditionBasedOrgEvents
                                ? ROUTE.PRIVATE_ACCESS_EVENTS
                                : ROUTE.INTERNET_ACCESS_EVENTS
                        }
                    />
                </Switch>
            </div>
        </React.Fragment>
    )
}

enum Id {
    HEADING = "heading",
}

const thirdLevelRoutes = [
    ROUTE.PRIVATE_ACCESS_EVENTS,
    ROUTE.INTERNET_ACCESS_EVENTS,
] satisfies ROUTE[]

type ThirdLevelRoute = (typeof thirdLevelRoutes)[number]

const thirdLevelLabels: Record<ThirdLevelRoute, LanguageKey> = {
    [ROUTE.PRIVATE_ACCESS_EVENTS]: "privateAccess",
    [ROUTE.INTERNET_ACCESS_EVENTS]: "internetAccess",
}
