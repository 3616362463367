import React, { ReactNode } from "react"
import JsonTextAreaTemplate from "./JsonTextArea.template"
import { LocalizationService } from "../../services/localization/Localization.service"
import { Bind } from "../../decorators/Bind.decorator"
import { IEditorProps } from "react-ace"

export class JsonTextArea extends React.Component<JsonTextAreaProps, JsonTextAreaState> {
    public state: JsonTextAreaState = { json: "", foldChildren: false }

    public render(): ReactNode {
        return JsonTextAreaTemplate.call(this)
    }

    public componentDidMount(): void {
        let obj: any
        try {
            obj = JSON.parse(this.props.initialValue)
        } catch {
            this.setState({
                json: this.localizationService.getString("failedToParseJson"),
            })
            return
        }
        this.setState({
            json: JSON.stringify(obj, null, 5),
            foldChildren: Boolean(this.props.defaultFoldChildren),
        })
    }

    private localizationService: LocalizationService = new LocalizationService()
    private name: string = "ACE_EDITOR_" + Math.random()
    private editor: IEditorProps

    @Bind
    private onValueChange(value: string): void {
        this.setState({ json: value })
        if (this.props.onValueChange) {
            this.props.onValueChange(value)
        }
    }

    @Bind
    private onCopy(): void {
        ;(navigator as any).clipboard.writeText(this.state.json)
    }

    @Bind
    private onToggleFold(): void {
        if (this.editor) {
            this.setState({ foldChildren: !this.state.foldChildren }, () => {
                if (this.state.foldChildren) {
                    this.editor.getSession().foldAll(1)
                } else {
                    this.editor.getSession().unfold()
                }
            })
        }
    }

    @Bind
    private onLoad(editor: IEditorProps): void {
        if (editor) {
            this.editor = editor
            if (this.props.defaultFoldChildren) {
                setTimeout(() => {
                    editor.getSession().foldAll(1)
                }, 100)
            }
        }
    }
}

interface JsonTextAreaProps {
    id?: string
    className?: string
    initialValue: string
    readOnly?: boolean
    defaultFoldChildren?: boolean
    height?: string
    onValueChange?: (value: string) => void
}

interface JsonTextAreaState {
    json: string
    foldChildren: boolean
}
