import { faSpinner } from "@fortawesome/pro-regular-svg-icons"
import { faCheck, faTimes } from "@fortawesome/pro-solid-svg-icons"
import { Tooltip } from "@mui/material"
import classNames from "classnames/bind"
import * as React from "react"
import { useHistory } from "react-router-dom"

import { Button, ButtonElement, ButtonType } from "../../../../components/button/Button.component"
import { ModalRef, useServiceLocalization } from "../../../../pre-v3/services"
import { ROUTE, formatRoutePath } from "../../../../routes"
import { Badge } from "../../../components/badge/Badge.component"
import { encodeID } from "../../../../pre-v3/utils/Url.util"
import { ServiceTestConnection } from "../../../services/ServiceTestConnection.service"
import { FormButtons } from "../../../components/form/form-buttons/FormButtons.component"
import { Container } from "../../../components/container/Container.component"
import { Loader } from "../../../components/loader/Loader.component"
import { LargeMessage } from "../../../components/large-message/LargeMessage.component"
import { WebService } from "../../../services/HostedService.service"
import styles from "./HostedWebsiteOverview.module.scss"

interface Props {
    modalRef?: ModalRef
    readOnly?: boolean
    data?: ServiceTestConnection
    isTestConnectionLoading: boolean
    service: WebService
    error?: string
    isContinueWithError?: boolean
    onSubmit?: (service: WebService) => void
}

export function ServiceTestConnectionModal(props: Props) {
    const { modalRef, readOnly, data, isTestConnectionLoading, error, isContinueWithError } = props

    const ls = useServiceLocalization()
    const history = useHistory()

    const onSubmit = () => {
        if (props.onSubmit) {
            props.onSubmit(props.service)
        } else {
            history.push(
                formatRoutePath(ROUTE.HOSTED_WEBSITES_DETAILS, {
                    id: encodeID(props.service.id!),
                })
            )
        }
        modalRef?.close("")
    }

    return (
        <Loader isLoading={isTestConnectionLoading} medium center>
            <Container className={styles.container}>
                {data ? (
                    <>
                        {!readOnly && isContinueWithError && (
                            <p className={styles.title}>
                                {ls.getString("yourHostedWebsiteConnectionWasNotSuccessfulDesc")}
                            </p>
                        )}
                        <div className={styles.testConnectionContainer}>
                            <h2 className={classNames(styles.formLabel, styles.header)}>
                                {ls.getString("status")}
                            </h2>
                            <h2 className={classNames(styles.formLabel, styles.header)}>
                                {ls.getString("connectivity")}
                            </h2>
                            <h2 className={classNames(styles.formLabel, styles.header)}>
                                {ls.getString("test")}
                            </h2>

                            {/* frontend status */}
                            <StatusCell
                                isLoading={isTestConnectionLoading}
                                isTestSuccess={Boolean(data?.frontendResolvable)}
                                errorReason={data?.errorMsg}
                            />
                            <ConnectivityCell />
                            <TestCell
                                title={ls.getString("hostnameFound")}
                                description={ls.getString(
                                    "serviceDomainNameIsResolvableAndRoutesToAccessTier"
                                )}
                            />
                            <StatusCell
                                isLoading={isTestConnectionLoading}
                                isTestSuccess={Boolean(data?.frontendReachable)}
                                errorReason={data?.errorMsg}
                            />
                            <ConnectivityCell />
                            <TestCell
                                title={ls.getString("reachable")}
                                description={ls.getString(
                                    "accessTierIsReachableOnSelectedIpAndPort"
                                )}
                            />

                            {/* backend statuses for each access tier */}
                            {data?.backend?.map?.((status) => {
                                return (
                                    <React.Fragment key={status.backendName}>
                                        <StatusCell
                                            isLoading={isTestConnectionLoading}
                                            isTestSuccess={status.backendResolvable}
                                            errorReason={status.errorMsg}
                                        />
                                        <ConnectivityCell isBackEnd fromText={status.backendName} />
                                        <TestCell
                                            title={ls.getString("dnsOrIpFound")}
                                            description={ls.getString(
                                                "backendHostnameOrIpIsResolvable"
                                            )}
                                        />
                                        <StatusCell
                                            isLoading={isTestConnectionLoading}
                                            isTestSuccess={status.backendReachable}
                                            errorReason={status.errorMsg}
                                        />
                                        <ConnectivityCell isBackEnd fromText={status.backendName} />
                                        <TestCell
                                            title={ls.getString("reachable")}
                                            description={ls.getString(
                                                "backendHostAndPortReachableFromAccessTier"
                                            )}
                                        />
                                    </React.Fragment>
                                )
                            })}
                        </div>
                    </>
                ) : (
                    <LargeMessage>{error}</LargeMessage>
                )}
                {!readOnly && isContinueWithError ? (
                    <FormButtons
                        rightButtons={
                            <>
                                <Button
                                    asElement={ButtonElement.BUTTON}
                                    buttonType={ButtonType.SECONDARY}
                                    className={styles.submitButton}
                                    onClick={modalRef?.cancel}
                                    disabled={isTestConnectionLoading}
                                >
                                    {ls.getString("cancel")}
                                </Button>
                                <Button
                                    asElement={ButtonElement.BUTTON}
                                    buttonType={ButtonType.PRIMARY}
                                    className={styles.submitButton}
                                    onClick={onSubmit}
                                    disabled={isTestConnectionLoading}
                                >
                                    {ls.getString("continueAnyway")}
                                </Button>
                            </>
                        }
                    />
                ) : (
                    <FormButtons
                        rightButtons={
                            <Button
                                asElement={ButtonElement.BUTTON}
                                buttonType={ButtonType.PRIMARY}
                                className={styles.submitButton}
                                onClick={modalRef?.close}
                                disabled={isTestConnectionLoading}
                            >
                                {ls.getString("done")}
                            </Button>
                        }
                    />
                )}
            </Container>
        </Loader>
    )
}

interface ConnectivityCellProps {
    fromText?: string
    toText?: string
    isBackEnd?: boolean
}

interface TestCellProps {
    title: string
    description: string
}

interface StatusCellProps {
    isLoading: boolean
    isTestSuccess: boolean
    errorReason?: string
}

function ConnectivityCell(props: ConnectivityCellProps) {
    const ls = useServiceLocalization()
    return (
        <p>
            {props.fromText ? (
                <span className={styles.bold}>{props.fromText}</span>
            ) : (
                ls.getString("internet")
            )}
            <br />
            {props.isBackEnd ? ls.getString("backend") : ls.getString("toAccessTier")}
        </p>
    )
}

function TestCell(props: TestCellProps) {
    return (
        <p>
            <span className={styles.bold}>{props.title}</span>
            <br />
            {props.description}
        </p>
    )
}

function StatusCell(props: StatusCellProps) {
    let content = props.isTestSuccess ? (
        <Badge icon={faCheck} success />
    ) : (
        <Badge icon={faTimes} error />
    )
    if (props.isLoading) {
        content = <Badge icon={faSpinner} loading />
    }

    return (
        <Tooltip placement="top" title={props.errorReason || ""}>
            <div className={styles.statusCell}>{content}</div>
        </Tooltip>
    )
}
