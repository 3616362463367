import React, { FC, useState } from "react"
import { ModalRef } from "../../../../pre-v3/services/Modal.service"
import { useServiceLocalization } from "../../../../pre-v3/services/localization/Localization.service"
import { FormColumn } from "../../../components/form/FormColumn.component"
import { Input } from "../../../components/input/Input.component"
import { Form } from "../../../components/form/Form.component"
import { Button } from "../../../components/button/Button.component"
import styles from "./MspAdminModals.module.scss"
import { SelectInput } from "../../../components/select-input/SelectInput.component"
import {
    MspAdminInfo,
    useDeleteMspAdmin,
    useEditMspAdmin,
} from "../../../services/MspOrgManagement.service"
import { ErrorBanners } from "../../../components/banner/Banner.component"

interface ModalData extends MspAdminInfo {
    orgId: string
}

interface Props {
    modalRef: ModalRef
    data: ModalData
}
export const EditMspAdminModal: FC<Props> = ({ modalRef, data }) => {
    const [profile, setProfile] = useState<MspAdminInfo["profile"]>(data.profile)
    const localization = useServiceLocalization()

    const {
        mutate: editAdmin,
        isLoading: isEditAdminLoading,
        error: editAdminError,
    } = useEditMspAdmin(data.orgId, modalRef)

    const {
        mutate: deleteAdmin,
        isLoading: isDeleteAdminLoading,
        error: deleteAdminError,
    } = useDeleteMspAdmin(data.orgId, modalRef)

    function onEdit() {
        editAdmin({
            email: data.email,
            profile,
        })
    }

    function onDelete() {
        deleteAdmin(data.email)
    }

    return (
        <div className={styles.container}>
            <Form>
                <div className={styles.inputs}>
                    <div className={styles.input}>
                        <FormColumn label={localization.getString("admin")} htmlFor="admin">
                            <Input
                                value={data.userName}
                                type="text"
                                id="admin"
                                name="admin"
                                disabled
                            />
                        </FormColumn>
                    </div>
                    <div className={styles.input}>
                        <FormColumn label={localization.getString("role")} htmlFor="role">
                            <SelectInput
                                options={["Admin", "ReadOnly"]}
                                value={profile}
                                onChange={setProfile}
                                id="role"
                                name="role"
                            />
                        </FormColumn>
                    </div>
                </div>
                <ErrorBanners errors={[editAdminError, deleteAdminError]} />
                <div className={styles.buttons}>
                    <Button
                        error
                        disabled={isEditAdminLoading}
                        loading={isDeleteAdminLoading}
                        onClick={onDelete}
                    >
                        {localization.getString("removeFromOrganization")}
                    </Button>
                    <Button
                        brand
                        disabled={isDeleteAdminLoading}
                        loading={isEditAdminLoading}
                        onClick={onEdit}
                    >
                        {localization.getString("save")}
                    </Button>
                </div>
            </Form>
        </div>
    )
}
