import { faInfoCircle } from "@fortawesome/pro-regular-svg-icons"
import React from "react"

import { Icon } from "../Icon/Icon.component"
import { Tooltip } from "../tooltip/Tooltip.component"
import styles from "./InfoIcon.module.scss"

export interface Props {
    /**
     * Text to display in the tooltip
     */
    tooltip: string
    /**
     * Additional styling
     */
    className?: string
}

/**
 * Informational icon that displays information in a tooltip
 */
export function InfoIcon(props: Props): JSX.Element {
    return (
        <Tooltip title={props.tooltip} className={props.className}>
            <Icon className={styles.icon} icon={faInfoCircle} />
        </Tooltip>
    )
}
