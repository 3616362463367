import React, { ReactNode, SyntheticEvent, RefObject } from "react"
import LookupTemplate from "./Lookup.template"
import { Bind } from "../../decorators/Bind.decorator"
import { Debounce } from "../../decorators/Debounce.decorator"
import { SelectItem, SelectValue, SelectValueUtil } from "../../utils/SelectValue.util"

export class Lookup extends React.Component<LookupProps, LookupState> {
    public state: LookupState = { query: "", results: [], selected: [] }

    public componentDidMount(): void {
        if (this.props.initialValues && this.props.initialValues.length > 0) {
            this.setState({ selected: this.props.initialValues })
        }
    }

    public render(): ReactNode {
        return LookupTemplate.call(this)
    }

    private inputRef?: RefObject<HTMLInputElement> = React.createRef<HTMLInputElement>()
    private listRef?: HTMLElement

    @Bind
    private onKeyDown(event: KeyboardEvent): void {
        if (this.listRef && this.state.results && this.state.results.length > 0) {
            if (event.key === "ArrowDown") {
                const anchor: HTMLAnchorElement = this.listRef.firstChild
                    ?.firstChild as HTMLAnchorElement
                anchor.focus()
            }
        }
    }

    @Bind
    private onAnchorKeyDown(event: KeyboardEvent): void {
        const anchor: HTMLAnchorElement = event.target as HTMLAnchorElement
        if (anchor) {
            if (event.key === "ArrowUp") {
                if (
                    anchor.parentElement &&
                    anchor.parentElement.previousSibling &&
                    anchor.parentElement.previousSibling.firstChild
                ) {
                    if (anchor.parentElement.previousSibling.firstChild.nodeName === "A") {
                        ;(
                            anchor.parentElement.previousSibling.firstChild as HTMLAnchorElement
                        ).focus()
                    }
                } else if (this.inputRef && this.inputRef.current) {
                    this.inputRef.current.focus()
                }
            } else if (event.key === "ArrowDown") {
                if (
                    anchor.parentElement &&
                    anchor.parentElement.nextSibling &&
                    anchor.parentElement.nextSibling.firstChild
                ) {
                    if (anchor.parentElement.nextSibling.firstChild.nodeName === "A") {
                        ;(anchor.parentElement.nextSibling.firstChild as HTMLAnchorElement).focus()
                    }
                }
            }
        }
    }

    @Bind
    private onChange(event: Event): void {
        if (event && event.target) {
            const value: string = (event.target as any).value
            this.setState({ query: value }, () => {
                this.onLookup(this.state.query)
            })
        }
    }

    @Bind
    private onSelected(event: SyntheticEvent, value: SelectValue): void {
        event.preventDefault()

        if (this.props.multi) {
            const idx: number = this.state.results.indexOf(value)
            if (idx >= 0) {
                this.state.results.splice(idx, 1)
            }

            this.state.selected.push(value)
            this.setState({ selected: this.state.selected, results: this.state.results }, () => {
                this.inputRef?.current?.focus()
            })
            if (this.props.onSelected) {
                this.props.onSelected(this.state.selected)
            }
        } else {
            const query: string = (value as SelectItem).displayName || (value as string)
            this.setState({ query: query, results: [] })
            if (this.props.onSelected) {
                this.props.onSelected(value)
            }
        }
    }

    @Bind
    private onDeselect(event: SyntheticEvent, value: SelectValue): void {
        event.preventDefault()

        const idx: number = this.state.selected.indexOf(value)
        if (idx >= 0) {
            this.state.selected.splice(idx, 1)
            this.setState({ selected: this.state.selected })
            this.onLookup(this.state.query)
            if (this.props.onSelected) {
                this.props.onSelected(this.state.selected)
            }
        }
    }

    @Debounce
    private onLookup(value: string): void {
        if (value && value.length) {
            if (this.props.dataSource) {
                this.setState({ loading: true })
                this.props
                    .dataSource(value.toLowerCase())
                    .then((data: SelectValue[]) => {
                        const results: SelectValue[] = data.filter(
                            (d) => !SelectValueUtil.arrayIncludes(this.state.selected, d)
                        )
                        this.setState({ results: results })
                    })
                    .catch(() => {
                        this.setState({ results: [] })
                    })
                    .finally(() => {
                        this.setState({ loading: false })
                    })
            } else if (this.props.options) {
                const results: SelectValue[] = this.props.options.filter((i: SelectValue) => {
                    return (
                        !SelectValueUtil.arrayIncludes(this.state.selected, i) &&
                        SelectValueUtil.startsWith(i, value)
                    )
                })
                this.setState({ results: results })
            } else {
                this.setState({ results: [] })
            }
        } else {
            this.setState({ results: [] })
        }
    }
}

/**
 * Usage:
 * If the entire set of options is known
 * use @param options
 * If the set of options is dynamic
 * use @param dataSource
 * If both are provided, dataSource will be used
 */
interface LookupProps {
    options?: SelectValue[]
    initialValues?: string[]
    dataSource?: (search: string) => Promise<SelectValue[]>
    onSelected: (value: SelectValue | SelectValue[]) => void
    placeholder?: string
    multi?: boolean
}

interface LookupState {
    query: string
    results: SelectValue[]
    selected: SelectValue[]
    loading?: boolean
}
