import React from "react"
import { useHistory } from "react-router-dom"

import { useServiceLocalization } from "../../../../../pre-v3/services/localization/Localization.service"
import { ROUTE } from "../../../../../routes"
import { NewFile, isMissingFilePath, useCreateFile } from "../../../../services/TrustFactor.service"
import { Container } from "../../../../components/container/Container.component"
import { FileCheckForm } from "./FileCheckForm.component"
import { PageHeading } from "../../../../../components/page-heading/PageHeading.component"
import trustStyles from "../../../../../pages/trust/Trust.module.scss"
import styles from "./FileCheckForm.module.scss"

interface Props {
    canAccessFileCheckFactor?: boolean
}

export function FileCheckAdd(props: Props): JSX.Element {
    const history = useHistory()
    const localization = useServiceLocalization()

    const [isMissingConfiguration, setIsMissingConfiguration] = React.useState(false)

    const createFileCheckTrustFactor = useCreateFile({
        onSuccess: () => history.push(ROUTE.FACTORS_FILE_CHECK),
    })

    function onSubmit(file: NewFile): void {
        setIsMissingConfiguration(false)

        if (Object.values(file.configurations).every(isMissingFilePath)) {
            return setIsMissingConfiguration(true)
        }

        try {
            createFileCheckTrustFactor.mutate(file)
        } catch (error) {}
    }

    function onCancel(): void {
        history.push(ROUTE.FACTORS_FILE_CHECK)
    }

    return (
        <div className={styles.container}>
            <section aria-labelledby={Id.HEADING} className={trustStyles.section}>
                <header className={trustStyles.header}>
                    <PageHeading id={Id.HEADING}>{localization.getString("addFile")}</PageHeading>
                </header>
            </section>
            <Container>
                <FileCheckForm
                    onSubmit={onSubmit}
                    onCancel={onCancel}
                    submitting={createFileCheckTrustFactor.isLoading}
                    errors={[
                        createFileCheckTrustFactor.error,
                        isMissingConfiguration &&
                            localization.getString(
                                "addAConfigurationToAtLeastOneAvailablePlatform"
                            ),
                    ]}
                    canAccessFileCheckFactor={props.canAccessFileCheckFactor}
                />
            </Container>
        </div>
    )
}

enum Id {
    HEADING = "heading",
}
