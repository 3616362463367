import React from "react"
import styles from "./ModalBase.module.scss"
import { Dialog, DialogTitle, DialogContent, DialogActions } from "@mui/material"
import { faTimes } from "@fortawesome/pro-light-svg-icons"
import { IconButton } from "../../icon-button/IconButton.component"

export default function () {
    const modalRef = this.props.modalRef
    return (
        <Dialog
            open
            onClose={this.onCancel}
            maxWidth={modalRef.maxWidth}
            fullWidth={modalRef.fullWidth}
            classes={dialogClasses}
        >
            <div className={styles.header}>
                <DialogTitle>{modalRef.title}</DialogTitle>
                <IconButton className={styles.closeIcon} icon={faTimes} onClick={this.onCancel} />
            </div>
            {modalRef.withoutBody ? (
                modalRef.content
            ) : (
                <DialogContent>{modalRef.content}</DialogContent>
            )}
            {modalRef.actions && (
                <DialogActions className={styles.actions}>{modalRef.actions}</DialogActions>
            )}
        </Dialog>
    )
}

const dialogClasses = { container: styles.container, paper: styles.paper }
