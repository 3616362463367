import React, { ReactNode } from "react"
import ServicesEditDeleteDialog from "./ServicesEditDeleteDialog.template"
import { SaasManage, ManageService } from "../../../../services/Manage.service"
import { LocalizationService } from "../../../../services/localization/Localization.service"
import { Bind } from "../../../../decorators/Bind.decorator"

export class SaasEditDeleteDialog extends React.Component<
    SaasEditDeleteDialogProps,
    SaasEditDeleteDialogState
> {
    public state: SaasEditDeleteDialogState = {}

    public render(): ReactNode {
        return ServicesEditDeleteDialog.call(this)
    }

    private localizationService: LocalizationService = new LocalizationService()
    private manageService: ManageService = new ManageService()

    @Bind
    private onConfirm(): void {
        if (!this.props.service) {
            this.props.onClose(false)
            return
        }

        this.setState({ loading: true, error: "" })
        this.manageService.deleteSaasApp(this.props.service.id).then(
            () => {
                this.setState({ loading: false, error: "" })
                this.props.onClose(true)
            },
            (err: Error) => {
                this.setState({ loading: false, error: err.message })
            }
        )
    }
}

interface SaasEditDeleteDialogProps {
    service: SaasManage
    open: boolean
    onClose: (success: boolean) => void
}

interface SaasEditDeleteDialogState {
    loading?: boolean
    error?: string
}
