import React from "react"
import styles from "./UsersEmailCellRenderer.module.scss"
import { Link } from "../../../../../v3/components/link/Link.component"

export default function () {
    return (
        <Link to={this.state.link} onClick={this.clickHandler} className={styles.nameValue}>
            {this.props.value}
        </Link>
    )
}
