import { useQueryClient } from "@tanstack/react-query"
import React, { useEffect, useState } from "react"
import { useHistory, useParams } from "react-router-dom"

import { ROUTE, formatRoutePath } from "../../../../routes"
import { decodeID, encodeID } from "../../../utils/Url.util"
import { ErrorBanner } from "../../../../v3/components/banner/Banner.component"
import { Container } from "../../../../v3/components/container/Container.component"
import { Loader } from "../../../../v3/components/loader/Loader.component"
import { helperGetServiceTunnel } from "../../../../v3/services/ServiceTunnel.service"
import { useServiceLocalization } from "../../../services/localization/Localization.service"
import { SaasManage, ServiceManage, useServiceManage } from "../../../services/Manage.service"
import { useActionBar } from "../../../services/ActionBar.service"

export function ServiceOverviewFallBackRoute() {
    const params = useParams<{ id: string }>()
    const queryClient = useQueryClient()
    const localization = useServiceLocalization()
    const manageService = useServiceManage()
    const history = useHistory()

    const [error, setError] = useState<string>("")
    const [isLoading, setIsLoading] = useState<boolean>(true)

    const serviceId: string = decodeID(params.id)

    async function fetchData() {
        try {
            const hostedService: ServiceManage = await manageService.getRegisteredService(serviceId)
            if (manageService.isInfraService(hostedService)) {
                history.replace(
                    formatRoutePath(ROUTE.INFRASTRUCTURE_DETAILS, {
                        id: encodeID(serviceId),
                    })
                )
            } else {
                history.replace(
                    formatRoutePath(ROUTE.HOSTED_WEBSITES_DETAILS, { id: encodeID(serviceId) })
                )
            }
        } catch {
            try {
                const saasApp: SaasManage = await manageService.getSaasApp(serviceId)
                if (saasApp?.id) {
                    history.replace(
                        formatRoutePath(ROUTE.SAAS_APPS_DETAILS, {
                            id: encodeID(saasApp.id),
                        })
                    )
                }
            } catch {
                try {
                    const tunnel = await helperGetServiceTunnel(queryClient, serviceId)
                    if (tunnel.id) {
                        history.replace(
                            formatRoutePath(ROUTE.SERVICE_TUNNELS_DETAILS, {
                                id: encodeID(tunnel.id),
                            })
                        )
                    }
                } catch {
                    setError(localization.getString("serviceNotFound"))
                }
            }
        }
        setIsLoading(false)
    }

    useActionBar({
        title: "",
        items: [
            {
                label: localization.getString("manageServices"),
                href: "",
            },
        ],
    })

    useEffect(() => {
        fetchData()
    }, [])

    return (
        <Loader isLoading={isLoading} center medium>
            <Container>{error && <ErrorBanner>{error}</ErrorBanner>}</Container>
        </Loader>
    )
}
