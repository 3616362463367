import React from "react"

import { FilterBar } from "../../../../pre-v3/components/filter-bar/FilterBar.component"
import { useServiceLocalization } from "../../../../pre-v3/services/localization/Localization.service"
import { DataFilter, DataFilterType, FilterModel } from "../../../../pre-v3/utils/AgGrid.util"
import { SelectItem } from "../../../../pre-v3/utils/SelectValue.util"
import { FilterById, Network, ServiceTunnel, User } from "../../../services/PrivateResource.service"

interface Props {
    filterModel: FilterModel<FilterById>
    serviceTunnels: ServiceTunnel[]
    getNetworks(search: string): Promise<Network[]>
    getUsers(search: string): Promise<User[]>
    onFilter(filterModel: FilterModel<FilterById>): void
}

export function PrivateResourceFilterBar(props: Props): JSX.Element {
    const localization = useServiceLocalization()

    const networksFilter: DataFilter<FilterById.NETWORKS> = {
        key: FilterById.NETWORKS,
        displayName: localization.getString("networks"),
        type: DataFilterType.MULTILOOKUP,
        dataSource: async (search) => {
            const networks = await props.getNetworks(search)
            return networks.map(mapSelectItem)
        },
    }

    const serviceTunnelsFilter: DataFilter<FilterById.SERVICE_TUNNELS> = {
        key: FilterById.SERVICE_TUNNELS,
        displayName: localization.getString("serviceTunnels"),
        type: DataFilterType.MULTI_LIST,
        options: props.serviceTunnels.map(mapSelectItem),
    }

    const usersFilter: DataFilter<FilterById.USERS> = {
        key: FilterById.USERS,
        displayName: localization.getString("users"),
        type: DataFilterType.MULTILOOKUP,
        dataSource: async (search) => {
            const users = await props.getUsers(search)
            return users.map(mapSelectItem)
        },
    }

    const addressesFilter: DataFilter<FilterById.ADDRESS> = {
        key: FilterById.ADDRESS,
        displayName: localization.getString("addresses"),
        type: DataFilterType.MULTIINPUT,
    }

    const filters: DataFilter<FilterById>[] = [
        networksFilter,
        serviceTunnelsFilter,
        usersFilter,
        addressesFilter,
    ]

    return (
        <FilterBar filters={filters} initialModel={props.filterModel} onChange={props.onFilter} />
    )
}

interface GenericItem {
    id: string
    name: string
}

function mapSelectItem<Item extends GenericItem>(item: Item): SelectItem {
    return {
        displayName: item.name,
        value: item.id,
    }
}
