import React from "react"

import {
    Button,
    ButtonElement,
    ButtonType,
} from "../../../../../components/button/Button.component"
import { Switch } from "../../../../../v3/components/switch/Switch.component"
import { Form, FormLabel, FormSection, ErrorBanner } from "../../../../components"
import { ServiceType } from "../../../../services/Manage.service"
import { ServicesAddPolicy } from "../policy/ServicesAddPolicy.component"
import { ServicesAddSaasAuthenticator } from "../saas-authenticator/ServicesAddSaasAuthenticator.component"
import styles from "./ServicesAddSaas.module.scss"
import { PageHeading } from "../../../../../components/page-heading/PageHeading.component"
import { Input } from "../../../../../v3/components/input/Input.component"

export default function () {
    return (
        <section aria-labelledby="heading">
            <header className={styles.header}>
                <PageHeading id="heading">
                    {this.idpRouted
                        ? this.localizationService.getString("registerIdpRoutedService")
                        : this.localizationService.getString("registerCseFederatedSaasService")}
                </PageHeading>
            </header>
            <Form onSubmit={this.onCreateService} className={styles.container} labelWidth="100%">
                <button
                    ref={(c) => (this.submitButton = c)}
                    type="submit"
                    className={styles.hiddenSubmitButton}
                />
                <FormSection title={this.localizationService.getString("serviceDetails")}>
                    <FormLabel
                        title={this.localizationService.getString(
                            this.idpRouted ? "idpRoutedServiceName" : "saasApplicationName"
                        )}
                        htmlFor="serviceName"
                    >
                        <Input
                            className={styles.formInput}
                            required
                            placeholder={this.localizationService.getString(
                                this.idpRouted
                                    ? "enterIdpRoutedServiceName"
                                    : "enterSaasApplicationName"
                            )}
                            id="serviceName"
                            name="serviceName"
                        />
                    </FormLabel>
                    <FormLabel
                        title={this.localizationService.getString(
                            "somethingOptional",
                            this.localizationService.getString("description")
                        )}
                        htmlFor="serviceDescription"
                    >
                        <Input
                            className={styles.formInput}
                            placeholder={this.localizationService.getString(
                                "leaveBlankToHideDescription"
                            )}
                            id="serviceDescription"
                            name="serviceDescription"
                        />
                    </FormLabel>
                </FormSection>
                <FormSection title={this.localizationService.getString("authenticationFederation")}>
                    <ServicesAddSaasAuthenticator
                        className={styles.attributeContainer}
                        onChange={this.onAuthenticatorChange}
                        idpRouted={this.idpRouted}
                    />
                </FormSection>
                {(this.state.asyncAuthState || this.state.isIdpRouted) && (
                    <FormSection
                        title={this.localizationService.getString("advancedConfigurationOptional")}
                        collapsible
                    >
                        {this.state.asyncAuthState && (
                            <FormLabel
                                inline={false}
                                title={this.localizationService.getString(
                                    "suppressDeviceTrustVerification"
                                )}
                                tooltip={this.localizationService.getString(
                                    "suppressDeviceTrustVerificationDescription"
                                )}
                                htmlFor="suppressDeviceTrustVerification"
                            >
                                <Switch
                                    className={styles.pageBreakSwitch}
                                    value={this.state.suppressDTVFlag}
                                    onChange={this.handleEnableChange}
                                ></Switch>
                            </FormLabel>
                        )}
                        {this.state.isIdpRouted && (
                            <FormLabel
                                inline={false}
                                title={this.localizationService.getString(
                                    "passwordlessAuthentication"
                                )}
                                tooltip={this.localizationService.getString(
                                    "passwordlessAuthenticationDescription"
                                )}
                                htmlFor="passwordlessAuthentication"
                            >
                                <Switch
                                    className={styles.pageBreakSwitch}
                                    value={this.state.passwordlessAuthentication}
                                    onChange={this.onPasswordAuthChange}
                                ></Switch>
                            </FormLabel>
                        )}
                    </FormSection>
                )}
                <FormSection title={this.localizationService.getString("attachPolicy")}>
                    <ServicesAddPolicy
                        className={styles.attributeContainer}
                        onChange={this.onPolicyChange}
                        serviceType={ServiceType.WEB_USER}
                    />
                </FormSection>
                <FormSection className={styles.submitSection}>
                    {this.state.error && (
                        <ErrorBanner className={styles.errorBanner}>{this.state.error}</ErrorBanner>
                    )}
                    <Button
                        buttonType={ButtonType.PRIMARY}
                        asElement={ButtonElement.BUTTON}
                        loading={this.state.loading}
                    >
                        {this.localizationService.getString("register")}
                    </Button>
                </FormSection>
            </Form>
        </section>
    )
}
