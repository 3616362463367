import classNames from "classnames/bind"
import React from "react"

import { useServiceLocalization } from "../../../../../../../pre-v3/services/localization/Localization.service"
import {
    TRUST_EFFECT,
    effectLabelDictionary,
    effectDescriptionDictionary,
} from "../../../../../../services/TrustProfile.service"
import styles from "./TrustEffectDescription.module.scss"

interface Props {
    effect: TRUST_EFFECT
    className?: string
}

export function TrustEffectDescription(props: Props): JSX.Element {
    const localization = useServiceLocalization()

    return (
        <div className={classNames(styles.container, props.className)}>
            <span className={styles.title}>
                {localization.getString(effectLabelDictionary[props.effect])}
            </span>
            <span>{localization.getString(effectDescriptionDictionary[props.effect])}</span>
        </div>
    )
}
