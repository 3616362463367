import React, { FC, useMemo } from "react"
import {
    Button,
    ButtonElement,
    ButtonType,
} from "../../../../../components/button/Button.component"
import styles from "./DeletePublicResourceModal.module.scss"
import { Loader } from "../../../../components/loader/Loader.component"
import {
    ItpPolicyListWithAppFilter,
    useDeleteCustomApp,
    useGetItpPoliciesWithAppInfo,
    useGetServiceTunnels,
} from "../../../../services/PublicResource.service"
import { ErrorBanners } from "../../../../components/banner/Banner.component"
import { useHistory } from "react-router"
import { ROUTE } from "../../../../../routes"
import { useServiceLocalization } from "../../../../../pre-v3/services/localization/Localization.service"

interface Props {
    appId: string
    name: string
    closeModal: () => void
}

export const DeletePublicResourceAction: FC<Props> = ({ closeModal, appId, name }) => {
    const localization = useServiceLocalization()
    const history = useHistory()

    const {
        isLoading: isServiceTunnelsLoading,
        error: serviceTunnelListError,
        data: serviceTunnels,
    } = useGetServiceTunnels()

    const {
        mutateAsync: deleteApp,
        error: deleteAppError,
        isLoading: deleteAppIsLoading,
    } = useDeleteCustomApp()

    const {
        data: itpPolices,
        error: itpPolicesError,
        isLoading: isItpPolicesLoading,
    } = useGetItpPoliciesWithAppInfo()

    const attachedTunnels = useMemo(() => {
        if (!serviceTunnels) return []
        return serviceTunnels.filter(
            (service) =>
                service.applicationExclude?.includes(appId) ||
                service.applicationInclude?.includes(appId)
        )
    }, [serviceTunnels])
    const attachedItpPolices = useMemo(() => {
        if (!itpPolices) return [] as ItpPolicyListWithAppFilter[]
        return itpPolices.filter(
            (police) => police.allowApps.includes(appId) || police.blockApps.includes(appId)
        )
    }, [itpPolices])

    const isLoading = useMemo(
        () => isServiceTunnelsLoading || deleteAppIsLoading || isItpPolicesLoading,
        [isServiceTunnelsLoading, deleteAppIsLoading, isItpPolicesLoading]
    )

    function onDelete() {
        if (isLoading || !serviceTunnels) return
        deleteApp(appId).then(() => {
            history.push(ROUTE.APP_DISCOVERY)
            closeModal()
        })
    }

    const errors = useMemo(() => {
        return [
            ...(serviceTunnelListError ? [serviceTunnelListError] : []),
            ...(deleteAppError ? [deleteAppError] : []),
            ...(typeof itpPolicesError === "string" ? [itpPolicesError] : []),
        ]
    }, [serviceTunnelListError, deleteAppError])

    if (isServiceTunnelsLoading || isItpPolicesLoading)
        return (
            <div className={styles.loader}>
                <Loader center medium />
            </div>
        )

    return (
        <div className={styles.main}>
            {!!attachedTunnels.length ? (
                <>
                    <p>{localization.getString("customAppDeleteTunnelsExplanation", name || "")}</p>

                    <div>
                        <span>
                            {localization.getString(
                                attachedTunnels.length > 1 ? "serviceTunnels" : "serviceTunnel"
                            )}
                            :
                        </span>
                        <ul>
                            {attachedTunnels.map((tunnel) => (
                                <li key={tunnel.id}>{tunnel.name}</li>
                            ))}
                        </ul>
                    </div>
                </>
            ) : !!attachedItpPolices.length ? (
                <>
                    <p>
                        {localization.getString(
                            "customAppDeleteItpPoliciesExplanation",
                            name || ""
                        )}
                    </p>
                    <div>
                        <span>
                            {localization.getString(
                                attachedItpPolices.length > 1 ? "itpPolicies" : "itpPolicy"
                            )}
                            :
                        </span>
                        <ul>
                            {attachedItpPolices.map((police) => (
                                <li key={police.id}>{police.name}</li>
                            ))}
                        </ul>
                    </div>
                </>
            ) : (
                <p>{localization.getString("emptyAppDeleteMessage", name || "")}</p>
            )}
            <div className={styles.buttons}>
                <Button
                    asElement={ButtonElement.BUTTON}
                    buttonType={ButtonType.SECONDARY}
                    onClick={() => {
                        closeModal()
                    }}
                    disabled={deleteAppIsLoading}
                >
                    {localization.getString("cancel")}
                </Button>
                <Button
                    type="button"
                    asElement={ButtonElement.BUTTON}
                    buttonType={ButtonType.DESTRUCTIVE}
                    onClick={onDelete}
                    disabled={isLoading || !!attachedTunnels.length}
                    loading={isLoading}
                >
                    {localization.getString("delete")}
                </Button>
            </div>
            <ErrorBanners errors={errors} />
        </div>
    )
}
