import { IconDefinition } from "@fortawesome/fontawesome-common-types"
import React, { ReactNode } from "react"
import { useCallbackRef } from "../../../../pre-v3/utils/UseCallbackRef"
import {
    Button,
    ButtonElement,
    ButtonType,
    IconType,
} from "../../../../components/button/Button.component"
import { MenuItemProps } from "../menu-item/MenuItem.component"
import { MenuPopover } from "../menu-popover/MenuPopover.component"
import { Menu } from "../Menu.component"

export function MenuButton(props: MenuButtonProps) {
    const [ref, anchorEl] = useCallbackRef<HTMLButtonElement & HTMLAnchorElement>()
    return (
        <>
            <Button
                asElement={ButtonElement.BUTTON}
                buttonType={ButtonType.SECONDARY}
                ref={ref}
                type="button"
                className={props.className}
                icon={IconType.PLUS}
                disabled={props.disabled}
            >
                {props.label}
            </Button>
            <MenuPopover anchorEl={anchorEl}>
                {props.menuItems ? <Menu items={props.menuItems} /> : props.children}
            </MenuPopover>
        </>
    )
}

export interface MenuButtonProps {
    label: string
    icon?: IconDefinition
    children?: ReactNode
    className?: string
    menuItems?: MenuItemProps[]
    disabled?: boolean
    popoverClassName?: string
}
