import React from "react"
import styles from "./PolicyStatusCellRenderer.module.scss"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faCheckCircle, faPauseCircle } from "@fortawesome/pro-solid-svg-icons"
import classNames from "classnames/bind"

export function PolicyStatusAttachedTemplate() {
    return (
        <div className={styles.container}>
            <div className={classNames(styles.statusIcon, styles.statusIconAttached)}>
                <FontAwesomeIcon icon={faCheckCircle} />
            </div>
            <div className={styles.statusLabel}>{this.localizationService.getString("active")}</div>
        </div>
    )
}

export function PolicyStatusInactiveTemplate() {
    return (
        <div className={styles.container}>
            <div className={classNames(styles.statusIcon, styles.statusIconInactive)}>
                <FontAwesomeIcon icon={faPauseCircle} />
            </div>
            <div className={styles.statusLabel}>
                {this.localizationService.getString("inactive")}
            </div>
        </div>
    )
}
