import * as React from "react"
import { useHistory } from "react-router-dom"

import { ROUTE } from "../../../../routes"
import { TrustLevel } from "../../../api/Entity.api"
import { DevicePlatform } from "../../../api/Reporting.api"
import { PieChart } from "../../../components/charts/PieChart"
import {
    DevicesStatsDisplayData,
    DisplayData,
    useServiceLocalization,
    useServiceStats,
} from "../../../services"
import { chartColorMap } from "../utils"
import { Widget } from "../Widget"
import { DevicesCount } from "./DevicesCount"
import { StringUtil } from "../../../utils/String.util"
import { TrustLevel as DeviceTrustLevel } from "../../../../v3/services/Device.service"
import { Platform } from "../../../../v3/services/shared/Platform"
import AgGridUtil from "../../../utils/AgGrid.util"

export const Devices = React.memo(function () {
    const localization = useServiceLocalization()
    const stats = useServiceStats()
    const history = useHistory()

    const [data, setData] = React.useState<DevicesStatsDisplayData | null>(null)
    const [loading, setLoading] = React.useState(true)

    React.useEffect(() => {
        stats
            .getDevicesStatsDisplayData()
            .then(setData)
            .finally(() => {
                setLoading(false)
            })
    }, [stats])

    return (
        <>
            <DevicesCount count={data?.total} />
            <Widget gridArea="os" loading={loading} hasData={Boolean(data && data.os.length > 0)}>
                <PieChart
                    title={localization.getString("allDevices")}
                    subtitle={localization.getString("byOperatingSystem")}
                    data={data?.os.map((datum: DisplayData, i) => ({
                        ...datum,
                        color: [
                            chartColorMap[DevicePlatform.ANDROID],
                            chartColorMap[DevicePlatform.APPLE],
                            chartColorMap[DevicePlatform.IOS],
                            chartColorMap[DevicePlatform.LINUX],
                            chartColorMap[DevicePlatform.MACOS],
                            chartColorMap[DevicePlatform.OTHER],
                            chartColorMap[DevicePlatform.UNKNOWN],
                            chartColorMap[DevicePlatform.UNREGISTERED],
                            chartColorMap[DevicePlatform.WINDOWS],
                            chartColorMap[DevicePlatform.WIN_RT],
                            // couldn't figure out which platform two colors are for
                            "#A073AB",
                            "#662A74",
                        ][i],
                    }))}
                    options={{
                        cursor: "pointer",
                        point: {
                            events: {
                                click() {
                                    const platform = devicePlatformDict[this.name]
                                    // navigate the user to the device view with the correct filter
                                    const searchParams = AgGridUtil.serializeFilterModel({
                                        platform: {
                                            filter: platform?.toString() || "",
                                        },
                                    })
                                    history.push(`${ROUTE.DEVICES}?${searchParams.toString()}`)
                                },
                            },
                        },
                    }}
                />
            </Widget>
            <Widget
                gridArea="trust-level"
                loading={loading}
                hasData={Boolean(data && data.trustLevel.length > 0)}
            >
                <PieChart
                    title={localization.getString("allDevices")}
                    subtitle={localization.getString("byTrustLevel")}
                    data={data?.trustLevel
                        .map((point: DisplayData, i) => ({
                            ...point,
                            color: [
                                chartColorMap[TrustLevel.ALWAYS_ALLOW],
                                chartColorMap[TrustLevel.HIGH],
                                chartColorMap[TrustLevel.MID],
                                chartColorMap[TrustLevel.LOW],
                                chartColorMap[TrustLevel.ALWAYS_DENY],
                                chartColorMap[TrustLevel.PENDING],
                            ][i],
                        }))
                        .filter(({ value }) => value)}
                    options={{
                        cursor: "pointer",
                        point: {
                            events: {
                                click() {
                                    const displayName: string = this.name.toLowerCase()

                                    //get list of defined trust levels
                                    const trustLevels: string[] = Object.values(TrustLevel)

                                    //find trust level that matches to the chart display name
                                    const trustLevel: TrustLevel = trustLevels.find((t) =>
                                        StringUtil.caseInsensitiveIncludes(t, displayName)
                                    ) as TrustLevel

                                    // navigate the user to the device view with the correct filter
                                    if (trustLevel) {
                                        const deviceTrustLevel = trustLevelDict[trustLevel]

                                        const searchParams = AgGridUtil.serializeFilterModel({
                                            trustLevel: {
                                                filter: deviceTrustLevel?.toString() || "",
                                            },
                                        })
                                        history.push(`${ROUTE.DEVICES}?${searchParams.toString()}`)
                                    }
                                },
                            },
                        },
                    }}
                />
            </Widget>
        </>
    )
})

const devicePlatformDict: Record<string, Platform | undefined> = {
    [DevicePlatform.ANDROID]: Platform.ANDROID,
    [DevicePlatform.APPLE]: Platform.MACOS,
    [DevicePlatform.IOS]: Platform.IOS,
    [DevicePlatform.LINUX]: Platform.LINUX,
    [DevicePlatform.MACOS]: Platform.MACOS,
    [DevicePlatform.OTHER]: undefined,
    [DevicePlatform.UNKNOWN]: undefined,
    [DevicePlatform.UNREGISTERED]: undefined,
    [DevicePlatform.WINDOWS]: Platform.WINDOWS,
    [DevicePlatform.WIN_RT]: Platform.WINDOWS,
}

const trustLevelDict: Record<TrustLevel, DeviceTrustLevel | undefined> = {
    [TrustLevel.NONE]: undefined,
    [TrustLevel.ALWAYS_ALLOW]: DeviceTrustLevel.ALWAYS_ALLOW,
    [TrustLevel.HIGH]: DeviceTrustLevel.HIGH,
    [TrustLevel.MID]: DeviceTrustLevel.MEDIUM,
    [TrustLevel.LOW]: DeviceTrustLevel.LOW,
    [TrustLevel.ALWAYS_DENY]: DeviceTrustLevel.ALWAYS_DENY,
    [TrustLevel.PENDING]: undefined,
}
