import React, { useMemo } from "react"

import { useServiceLocalization } from "../../../../../../../pre-v3/services"
import {
    CheckboxGroup,
    CheckboxGroupOption,
} from "../../../../../../components/checkbox-group/CheckboxGroup.component"
import { FormColumn } from "../../../../../../components/form/FormColumn.component"
import {
    DeviceOwnership,
    allDeviceOwnership,
    noDeviceOwnership,
    deviceOwnershipLabelDict,
} from "../../../../../../services/shared/DeviceOwnership"

interface Props {
    deviceOwnership?: Record<DeviceOwnership, boolean>
    onDeviceOwnershipChange?(deviceOwnership: Record<DeviceOwnership, boolean>): void
    disabled?: boolean
}

export function DeviceOwnershipInput(props: Props): JSX.Element {
    const localization = useServiceLocalization()

    const { deviceOwnership, onDeviceOwnershipChange, disabled } = props

    const selectedDeviceOwnership = React.useMemo(
        () => allDeviceOwnership.filter((ownership) => deviceOwnership?.[ownership]),
        [deviceOwnership]
    )

    const onChange = React.useCallback(
        (deviceOwnership: DeviceOwnership[]): void => {
            onDeviceOwnershipChange?.(
                deviceOwnership.reduce(reduceDeviceOwnerships, noDeviceOwnership)
            )
        },
        [onDeviceOwnershipChange]
    )

    const ownershipOptions: CheckboxGroupOption[] = useMemo(() => {
        return allDeviceOwnership.map((o) => {
            return {
                id: o,
                label: localization.getString(deviceOwnershipLabelDict[o]),
            }
        })
    }, [])

    return (
        <FormColumn label={localization.getString("selectOneOrMoreDeviceOwnershipOptions")}>
            <CheckboxGroup
                value={selectedDeviceOwnership}
                options={ownershipOptions}
                onChange={onChange}
                disabled={disabled}
            />
        </FormColumn>
    )
}

function reduceDeviceOwnerships(
    appliedPlatform: Record<DeviceOwnership, boolean>,
    deviceOwnership: DeviceOwnership
): Record<DeviceOwnership, boolean> {
    return { ...appliedPlatform, [deviceOwnership]: true }
}
