import React from "react"
import { useLocation } from "react-router-dom"

import { DeleteCancelActions } from "../../../../pre-v3/components/modal/delete-cancel/DeleteCancelActions"
import { IconType } from "../../../../pre-v3/services/ActionBar.service"
import { useServiceLocalization } from "../../../../pre-v3/services/localization/Localization.service"
import { ModalService } from "../../../../pre-v3/services/Modal.service"
import { FilterModel } from "../../../../pre-v3/utils/AgGrid.util"
import { UrlUtil } from "../../../../pre-v3/utils/Url.util"
import {
    Device,
    StatusType,
    useDownloadDevicesCsv,
    useGetServiceTunnels,
    useGetTrustProfiles,
    useRemoveDevices,
} from "../../../services/Device.service"
import { useGetRoles } from "../../../services/Role.service"
import { ErrorBanners } from "../../../components/banner/Banner.component"
import { GridApi } from "../../../components/grid/Grid.component"
import { Loader } from "../../../components/loader/Loader.component"
import { DeviceFilterBar } from "./DeviceFilterBar.component"
import { DeviceGrid } from "./DeviceGrid.component"
import styles from "./DeviceList.module.scss"
import { RemoveDevicesModal } from "./RemoveDevicesModal.component"
import { PageHeading } from "../../../../components/page-heading/PageHeading.component"
import { Button, ButtonElement, ButtonType } from "../../../../components/button/Button.component"
import { Tooltip } from "../../../components/tooltip/Tooltip.component"

interface Props {
    canWriteAll: boolean
    hasMigrated: boolean
}

export function DeviceList(props: Props): JSX.Element {
    const location = useLocation()
    const localization = useServiceLocalization()

    const removeDevicesLabel = localization.getString(
        "removeSomething",
        localization.getString("devices")
    )

    const modalService = new ModalService()

    const gridRef = React.useRef<GridApi>(null)

    const [filterModel, setFilterModel] = React.useState<FilterModel>(
        UrlUtil.readFilter(location.search)
    )
    const [selectedDevices, setSelectedDevices] = React.useState<Device[]>([])

    const onFilter = (newFilterModel: FilterModel): void => {
        setFilterModel(newFilterModel)
        UrlUtil.writeFilter(newFilterModel)
    }

    const onDeselectDevices = () => {
        gridRef.current?.deselectData()
        setSelectedDevices([])
    }

    const onRefresh = () => {
        onDeselectDevices()
        gridRef.current?.refreshData()
    }

    const getRolesQuery = useGetRoles()

    const getServiceTunnelsQuery = useGetServiceTunnels()

    const getTrustProfilesQuery = useGetTrustProfiles(props.hasMigrated)

    const removeDevices = useRemoveDevices()

    const onRemoveDevices: React.MouseEventHandler = (event) => {
        event.preventDefault()

        const emptyAcc: RemoveDevicesAcc = {
            allowedDevices: [],
            bannedDevices: [],
        }

        const { allowedDevices, bannedDevices } = selectedDevices.reduce(
            reduceDevicesToRemove,
            emptyAcc
        )

        modalService
            .open(
                removeDevicesLabel,
                {
                    component: RemoveDevicesModal,
                    props: { allowedDevices, bannedDevices },
                    withoutBody: true,
                    maxWidth: "850px",
                },
                {
                    component: DeleteCancelActions,
                    props: { okString: removeDevicesLabel, disabled: allowedDevices.length <= 0 },
                }
            )
            .onClose(async () => {
                await removeDevices.mutateAsync(allowedDevices)
                onRefresh()
            })
            .onCancel(onDeselectDevices)
    }

    const downloadDevicesCsv = useDownloadDevicesCsv()

    if (
        getRolesQuery.status === "loading" ||
        getServiceTunnelsQuery.status === "loading" ||
        getTrustProfilesQuery.status === "loading"
    ) {
        return <Loader children isLoading center />
    }

    if (
        getRolesQuery.status === "error" ||
        getServiceTunnelsQuery.status === "error" ||
        getTrustProfilesQuery.status === "error"
    ) {
        return (
            <div className={styles.container}>
                <ErrorBanners
                    errors={[
                        getRolesQuery.isError && String(getRolesQuery.error),
                        getServiceTunnelsQuery.isError && String(getServiceTunnelsQuery.error),
                        getTrustProfilesQuery.isError && String(getTrustProfilesQuery.error),
                    ]}
                />
            </div>
        )
    }

    return (
        <section aria-labelledby={Id.HEADING} className={styles.container}>
            <header className={styles.header}>
                <PageHeading id={Id.HEADING}>{localization.getString("devices")}</PageHeading>
                <div className={styles.actionButtons}>
                    {props.canWriteAll && (
                        <Tooltip
                            title={localization.getString(
                                selectedDevices.length
                                    ? "removeDevices"
                                    : "removeDeviceDisabledExplanation"
                            )}
                        >
                            <Button
                                icon={IconType.TRASH}
                                onClick={onRemoveDevices}
                                asElement={ButtonElement.BUTTON}
                                buttonType={ButtonType.SECONDARY}
                                disabled={!selectedDevices.length}
                                aria-label={localization.getString(
                                    selectedDevices.length
                                        ? "removeDevices"
                                        : "removeDeviceDisabledExplanation"
                                )}
                            />
                        </Tooltip>
                    )}
                    <Tooltip title={localization.getString("downloadDevices")}>
                        <Button
                            icon={IconType.FILE_DOWNLOAD}
                            onClick={() => downloadDevicesCsv.mutate()}
                            asElement={ButtonElement.BUTTON}
                            buttonType={ButtonType.SECONDARY}
                            aria-label={localization.getString("downloadDevices")}
                        />
                    </Tooltip>
                    <Tooltip title={localization.getString("refresh")}>
                        <Button
                            icon={IconType.REDO}
                            onClick={onRefresh}
                            asElement={ButtonElement.BUTTON}
                            buttonType={ButtonType.SECONDARY}
                            aria-label={localization.getString("refresh")}
                        />
                    </Tooltip>
                </div>
            </header>
            <ErrorBanners
                errors={[
                    downloadDevicesCsv.isError && String(downloadDevicesCsv.error),
                    removeDevices.isError && String(removeDevices.error),
                ]}
            />
            <DeviceFilterBar
                hasMigrated={props.hasMigrated}
                roles={getRolesQuery.data}
                serviceTunnels={getServiceTunnelsQuery.data}
                trustProfiles={getTrustProfilesQuery.data}
                filterModel={filterModel}
                onFilter={onFilter}
            />
            <DeviceGrid
                hasMigrated={props.hasMigrated}
                onDevicesSelected={setSelectedDevices}
                filterModel={filterModel}
                ref={gridRef}
            />
        </section>
    )
}

enum Id {
    HEADING = "heading",
}

interface RemoveDevicesAcc {
    allowedDevices: Device[]
    bannedDevices: Device[]
}

function reduceDevicesToRemove(acc: RemoveDevicesAcc, device: Device): RemoveDevicesAcc {
    if (device.status.type === StatusType.BANNED) {
        return { ...acc, bannedDevices: [...acc.bannedDevices, device] }
    }

    return { ...acc, allowedDevices: [...acc.allowedDevices, device] }
}
