import { faTrash } from "@fortawesome/pro-regular-svg-icons"
import classNames from "classnames"
import React, { ReactNode, useMemo, useState } from "react"
import { MenuItemProps } from "../menu/menu-item/MenuItem.component"
import styles from "./PillMultiSelect.module.scss"
import { SearchableMenu } from "../menu/searchable-menu/SearchableMenu.component"
import { useCallbackRef } from "../../../pre-v3/utils/UseCallbackRef"
import { MenuPopover } from "../menu/menu-popover/MenuPopover.component"
import { PillContainer } from "../pill-container/PillContainer.component"
import { FormRow } from "../form/FormRow.component"
import { PillProps } from "../pill/Pill.component"
import { useFallbackState } from "../../../pre-v3/utils/UseFallbackState.hook"
import { Input } from "../input/Input.component"
import { Tooltip } from "../tooltip/Tooltip.component"
import { IconButton } from "../icon-button/IconButton.component"
import { useServiceLocalization } from "../../../pre-v3/services"
import {
    Button,
    ButtonElement,
    ButtonType,
    IconType,
} from "../../../components/button/Button.component"

export interface PillMultiSelectProps {
    id?: string
    options: PillMultiSelectOption[]
    value?: string[]
    disabled?: boolean
    onChange?: (updatedValue: string[]) => void
    required?: boolean
    icon?: IconType
    className?: string
    /**
     * PillMultiSelect component requires label prop as it is used in FormRow component.
     * remove nested FormRow component/dependency in UI redesign project
     */
    label: string
    placeholder: string
    description?: string
    defaultValue?: string[]
    removeLabel?: string
    clearSelection?: boolean
    onRemove?: () => void
    staticItem?: MenuItemProps
    allowNewEntry?: boolean
    onSearchChange?: (search: string) => void
    loading?: boolean
    isPillDisabled?: (value: string) => boolean
    onPillClick?: (pill: string) => void
    onRemoveAll?: () => void
    ignoreOptions?: string[]
    labelClassName?: string
    children?: ReactNode
    disableButton?: boolean
}

export function PillMultiSelect(props: PillMultiSelectProps): JSX.Element {
    const localization = useServiceLocalization()

    const {
        disabled,
        options,
        required,
        icon = IconType.PLUS,
        className,
        label,
        placeholder,
        description,
        staticItem,
        isPillDisabled,
        ignoreOptions,
        disableButton,
    } = props

    const [ref, anchorEl] = useCallbackRef<HTMLButtonElement & HTMLAnchorElement>()
    const [allowNewEntry, setAllowNewEntry] = useState<boolean>(!!props.allowNewEntry)

    const [value, onSetValue] = useFallbackState(
        props.defaultValue || [],
        props.value,
        props.onChange
    )

    function onSelect(valueToAdd: string): void {
        const newValue: string[] = [...value, valueToAdd]
        onSetValue(newValue)
    }

    function onDelete(valueToRemove: string): void {
        onSetValue(value.filter((v) => v !== valueToRemove))
    }

    function onSearchChange(search: string): void {
        setAllowNewEntry(!!props.allowNewEntry && !value.includes(search))
        props.onSearchChange?.(search)
    }

    function onClearSelectionClick() {
        onSetValue([])
    }

    const parsedOptions: MenuItemProps[] = useMemo(() => {
        const filteredOption =
            value.length > 0 || ignoreOptions?.length
                ? options.filter((option) => {
                      if (option.type === "divider") {
                          return true
                      } else {
                          return (
                              !ignoreOptions?.includes(option.value) &&
                              !value.includes(option.value)
                          )
                      }
                  })
                : options

        return filteredOption.map((option): MenuItemProps => {
            return {
                type: option.type || "button",
                label: option.label,
                closeMenuOnClick: false,
                onClick: option.type === "divider" ? undefined : () => onSelect(option.value),
            }
        })
    }, [options, value, onSetValue, ignoreOptions])

    const pills: PillProps[] = useMemo(() => {
        return value
            .map((v) => {
                const selectedOption = options.find((option) => {
                    if (option.type !== "divider") {
                        return option.value === v
                    }
                    return false
                })

                return {
                    id: v,
                    label: selectedOption?.label || v,
                    onDelete: () => onDelete(v),
                    disabled: disabled || isPillDisabled?.(v),
                    onClick:
                        typeof props.onPillClick === "function"
                            ? () => props.onPillClick?.(v)
                            : undefined,
                }
            })
            .filter((x) => x) as PillProps[]
    }, [value, options, disabled])

    const pillsExist = pills.length > 0

    return (
        <div className={styles.rowContainer}>
            <div className={styles.multiSearchableSelect}>
                <FormRow
                    id={props.id}
                    description={description}
                    label={label}
                    labelClassName={props.labelClassName}
                >
                    {!disabled && (
                        <>
                            <div className={classNames(className, styles.multiSelectWrapper)}>
                                <div className={styles.actionButtons}>
                                    {pillsExist && !disabled && props.clearSelection && (
                                        <Button
                                            icon={IconType.TRASH}
                                            asElement={ButtonElement.BUTTON}
                                            buttonType={ButtonType.DESTRUCTIVE}
                                            onClick={onClearSelectionClick}
                                            type="button"
                                        >
                                            {localization.getString("removeAll")}
                                        </Button>
                                    )}
                                    {props.children && props.children}
                                    <Button
                                        ref={ref}
                                        icon={icon}
                                        disabled={disabled || disableButton}
                                        asElement={ButtonElement.BUTTON}
                                        buttonType={ButtonType.SECONDARY}
                                        type="button"
                                    >
                                        {placeholder}
                                    </Button>
                                </div>
                                <Input
                                    className={styles.fakeMultiSelectInput}
                                    disabled={disabled}
                                    value={value.join("")}
                                    required={required}
                                    aria-label={label}
                                    onChange={() => {}}
                                />
                            </div>
                            <MenuPopover anchorEl={anchorEl}>
                                <SearchableMenu
                                    loading={props.loading}
                                    allowNewEntry={allowNewEntry}
                                    onNewEntrySelect={onSelect}
                                    autoFocus
                                    items={[...parsedOptions]}
                                    staticItem={staticItem}
                                    onSearchChange={onSearchChange}
                                />
                            </MenuPopover>
                        </>
                    )}
                </FormRow>
                {pillsExist && <PillContainer className={styles.pillContainer} pills={pills} />}
            </div>
            {props.removeLabel && (
                <Tooltip title={props.removeLabel}>
                    <IconButton
                        className={styles.removeButton}
                        action
                        icon={faTrash}
                        onClick={props.onRemove}
                    />
                </Tooltip>
            )}
        </div>
    )
}

interface DividerOption {
    type: "divider"
    label: string
}

interface SelectableOption {
    type?: Exclude<MenuItemProps["type"], "divider">
    label: string
    value: string
}
export type PillMultiSelectOption = SelectableOption | DividerOption
