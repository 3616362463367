import { UseQueryResult, useQuery } from "@tanstack/react-query"

import { DateUtil } from "../pre-v3/utils/Date.util"
import { Profile, profileResDict } from "../utils/profile.utils"
import { OrgApi, OrgDetailsRes, UserOrgDetailsRes } from "../v3/api/Org.api"
import { Edition, editionResMap } from "../v3/services/shared/Edition"
import { ApiFunction } from "../v3/services/shared/QueryKey"

export function useAdminInfo(
    options?: QueryOptions<AdminInfo, unknown>
): UseQueryResult<AdminInfo, unknown> {
    const orgApi = new OrgApi()

    return useQuery({
        ...options,
        queryKey: [ApiFunction.GET_ADMIN_INFO],
        queryFn: async (): Promise<AdminInfo> => {
            const [orgDetailsRes, userOrgDetailsRes] = await Promise.all([
                orgApi.getOrgDetails(),
                orgApi.getUserOrgDetails(),
            ])

            return mapAdminInfo(orgDetailsRes, userOrgDetailsRes)
        },
    })
}

export function getFullName(firstName?: string, lastName?: string): string {
    if (firstName && lastName) return `${firstName} ${lastName}`
    if (firstName) return firstName
    if (lastName) return lastName
    return ""
}

// Types

interface BaseAdminInfo {
    profile: Profile
    orgId: string
    orgName: string
    edition: Edition
    createdAt: Date
    isGlobalEdge: boolean
    isPrivateEdge: boolean
    isMspOrg: boolean
    orgType?: string
}

export type AdminInfo = ImpersonatedAdminInfo | NonImpersonatedAdminInfo

interface NonImpersonatedAdminInfo extends BaseAdminInfo {
    isImpersonated: false
    adminId: string
    firstName?: string
    lastName?: string
    emailAddress: string
}

interface ImpersonatedAdminInfo extends BaseAdminInfo {
    isImpersonated: true
}

// Helper Functions

function mapAdminInfo(
    orgDetailsRes: OrgDetailsRes,
    userOrgDetailsRes: UserOrgDetailsRes
): AdminInfo {
    const baseAdminInfo = mapBaseAdminInfo(orgDetailsRes, userOrgDetailsRes)

    if (
        !userOrgDetailsRes.ID ||
        ((!userOrgDetailsRes.First || !userOrgDetailsRes.Last) && !userOrgDetailsRes.FullName) ||
        !userOrgDetailsRes.Email
    ) {
        return { ...baseAdminInfo, isImpersonated: true }
    }

    return {
        ...baseAdminInfo,
        ...getName(userOrgDetailsRes),
        isImpersonated: false,
        adminId: userOrgDetailsRes.ID,
        emailAddress: userOrgDetailsRes.Email,
    }
}

function mapBaseAdminInfo(
    orgDetailsRes: OrgDetailsRes,
    userOrgDetailsRes: UserOrgDetailsRes
): BaseAdminInfo {
    return {
        profile: profileResDict[userOrgDetailsRes.Profile],
        orgId: orgDetailsRes.OrgID,
        orgName: orgDetailsRes.OrgName,
        edition: editionResMap[orgDetailsRes.Edition],
        createdAt: new Date(DateUtil.convertLargeTimestamp(orgDetailsRes.CreatedAt)),
        isGlobalEdge: orgDetailsRes.GlobalEdge || false,
        isPrivateEdge: orgDetailsRes.PrivateEdge || false,
        isMspOrg: orgDetailsRes.IsMSPOrg || false,
        orgType: orgDetailsRes.OrgType || undefined,
    }
}

function getName(
    userOrgDetailsRes: UserOrgDetailsRes
): Pick<NonImpersonatedAdminInfo, "firstName" | "lastName"> {
    if (userOrgDetailsRes.First && userOrgDetailsRes.Last) {
        return { firstName: userOrgDetailsRes.First, lastName: userOrgDetailsRes.Last }
    }

    if (userOrgDetailsRes.First) return { firstName: userOrgDetailsRes.First, lastName: undefined }
    if (userOrgDetailsRes.Last) return { firstName: undefined, lastName: userOrgDetailsRes.Last }

    const [firstName, lastName] = userOrgDetailsRes.FullName.split(" ")
    return { firstName, lastName }
}
