import React, { ReactNode } from "react"
import SimpleTableTemplate from "./SimpleTable.template"

export class SimpleTable extends React.Component<SimpleTableProps, {}> {
    public render(): ReactNode {
        return SimpleTableTemplate.call(this)
    }
}

interface SimpleTableProps {
    className?: string
    items: SimpleTableItem[]
    valueClassName?: string
}

export interface SimpleTableItem {
    label: string
    value: string | ReactNode
    valueClassName?: string
}
