import classNames from "classnames/bind"
import React from "react"

import { HiddenInput } from "../../../components/input/HiddenInput.component"
import { useFallbackState } from "../../../pre-v3/utils/UseFallbackState.hook"
import styles from "./Switch.module.scss"

export function Switch(props: SwitchProps) {
    const [isSwitchOn, setIsSwitchOn] = useFallbackState(
        props.defaultValue ?? false,
        props.value,
        props.onChange
    )

    return (
        <label
            aria-label={props["aria-label"]}
            className={classNames(props.className, styles.switch, {
                [styles.isSwitchOn]: isSwitchOn,
                [styles.disabled]: props.disabled,
            })}
        >
            <HiddenInput
                id={props.id}
                type="checkbox"
                checked={isSwitchOn}
                onChange={(event) => setIsSwitchOn(event.target.checked)}
                disabled={props.disabled}
                name={props.name}
            />
            <span className={styles.knob} />
        </label>
    )
}

export interface SwitchProps {
    id?: string
    defaultValue?: boolean
    value?: boolean
    onChange?: (checked: boolean) => void
    disabled?: boolean
    className?: string
    name?: string
    ["aria-label"]?: string
}
