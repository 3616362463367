import React, { ReactNode } from "react"
import LoadMaskTemplate from "./LoadMask.template"

/**
 * @deprecated use Loader component instead
 */

/**
 * @deprecated use Loader component instead
 */

export class LoadMask extends React.Component<LoadMaskProps, LoadMaskState> {
    public static getDerivedStateFromProps(props: LoadMaskProps): LoadMaskState {
        return { top: props.top || 100 }
    }

    public state: LoadMaskState = { top: 100 }

    public render(): ReactNode {
        return LoadMaskTemplate.call(this)
    }
}

interface LoadMaskProps {
    top?: number
    label?: string
}

interface LoadMaskState {
    top: number
}
