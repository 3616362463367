import React from "react"
import styles from "./ServicesSaasConfig.module.scss"
import { InputWithCopy } from "../../../components/input-with-copy/InputWithCopy"
import { LoadMask } from "../../../components/load-mask/LoadMask.component"
import { LargeMessage } from "../../../components/large-message/LargeMessage.component"
import { faExclamation } from "@fortawesome/pro-solid-svg-icons"
import { SaasAuthProtocol, SaasNameIdValueOpts } from "../../../api/Manage.api"
import { Form, FormColumns, FormLabel, FormRow, Input } from "../../../components"
import { Button, ButtonElement, ButtonType } from "../../../../components/button/Button.component"
import { AppText } from "../../../../v3/components/app-text/AppText.component"
import { PageHeading } from "../../../../components/page-heading/PageHeading.component"

export default function () {
    return (
        <div className={styles.pagePadded}>
            <section aria-labelledby="heading">
                <header className={styles.appHeader}>
                    <PageHeading id="heading">
                        {this.localizationService.getString("saasApplications")}
                    </PageHeading>
                </header>
                {this.state.loading && <LoadMask></LoadMask>}
                {!this.state.loading && !this.state.app && (
                    <LargeMessage icon={faExclamation} className={styles.missingMessage}>
                        {this.localizationService.getString("appNotFound")}
                    </LargeMessage>
                )}
                {this.state.app && (
                    <>
                        <h2 className={styles.header}>
                            {this.localizationService.getString("almostDone")}!
                        </h2>
                        <AppText
                            ls={{
                                key: "saasRegistrationDescription",
                                replaceVals: [this.linkService.getLink("saasInstallDocs")],
                            }}
                        />
                        <Form display="grid" floating labelWidth={240}>
                            <FormRow>
                                <h2 className={styles.contentHeader}>
                                    {this.localizationService.getString(
                                        "configurationInformationForYourSaasAppClient"
                                    )}
                                </h2>
                            </FormRow>
                            <FormLabel
                                title={this.localizationService.getString("appClientName")}
                                htmlFor="appClientName"
                            >
                                <InputWithCopy value={this.state.app.name} />
                            </FormLabel>
                            {this.state.app.protocol === SaasAuthProtocol.OIDC &&
                                this.state.oidc && (
                                    <>
                                        <FormLabel
                                            title={this.localizationService.getString("clientId")}
                                            htmlFor="clientId"
                                        >
                                            <InputWithCopy value={this.state.app.clientId} />
                                        </FormLabel>
                                        <FormLabel
                                            title={this.localizationService.getString(
                                                "clientSecret"
                                            )}
                                            htmlFor="clientSecret"
                                        >
                                            <InputWithCopy value={this.state.app.clientSecret} />
                                        </FormLabel>
                                    </>
                                )}
                            <FormLabel
                                title={
                                    this.state.app.protocol === SaasAuthProtocol.SAML
                                        ? this.localizationService.getString("redirectUrlSamlAcs")
                                        : this.localizationService.getString("redirectUri")
                                }
                                htmlFor="redirectUri"
                            >
                                <InputWithCopy value={this.state.app.redirectUri} />
                            </FormLabel>
                            {this.state.app.protocol === SaasAuthProtocol.SAML && (
                                <>
                                    <FormLabel
                                        title={this.localizationService.getString(
                                            "audienceUriDescription"
                                        )}
                                        htmlFor="audienceUriDescription"
                                    >
                                        <InputWithCopy value={this.state.app.audienceUri} />
                                    </FormLabel>
                                    <FormLabel
                                        title={this.localizationService.getString("metadataUrl")}
                                        htmlFor="metadataUrl"
                                    >
                                        <InputWithCopy value={this.state.app.metadataUrl} />
                                    </FormLabel>

                                    <FormLabel
                                        title={this.localizationService.getString("nameIdFormat")}
                                        htmlFor="nameIdformat"
                                    >
                                        <Input
                                            className={styles.formInput}
                                            value={this.state.app.nameIdFormat}
                                            disabled
                                        />
                                    </FormLabel>
                                    <FormLabel
                                        title={this.localizationService.getString(
                                            "nameIdValueType"
                                        )}
                                        htmlFor="nameIdValueType"
                                    >
                                        <Input
                                            className={styles.formInput}
                                            value={this.localizationService
                                                .getString(
                                                    this.manageService.mapToLocalizationString(
                                                        this.state.app.nameIdValueType
                                                    )
                                                )
                                                .toLowerCase()}
                                            disabled
                                        />
                                    </FormLabel>
                                    {this.state.app.nameIdValueType ===
                                        SaasNameIdValueOpts.CUSTOM && (
                                        <FormLabel
                                            title={this.localizationService.getString(
                                                "nameIdCustomAttribute"
                                            )}
                                            htmlFor="nameIdAttributeSelector"
                                        >
                                            <Input
                                                className={styles.formInput}
                                                value={this.state.app.nameIdAttributeSelector}
                                                disabled
                                            />
                                        </FormLabel>
                                    )}
                                </>
                            )}
                            {this.state.app.protocol === SaasAuthProtocol.OIDC &&
                                this.state.oidc && (
                                    <>
                                        <FormRow>
                                            <h2 className={styles.contentHeader}>
                                                {this.localizationService.getString(
                                                    "yourGlobalOpenIdConnectSettings"
                                                )}
                                            </h2>
                                        </FormRow>
                                        <FormLabel
                                            title={this.localizationService.getString("issuerUrl")}
                                            htmlFor="issuerUrl"
                                        >
                                            <InputWithCopy value={this.state.oidc.issuerUrl} />
                                        </FormLabel>
                                        <FormLabel
                                            title={this.localizationService.getString(
                                                "authorizationEndpoint"
                                            )}
                                            htmlFor="authorizationEndpoint"
                                        >
                                            <InputWithCopy
                                                value={this.state.oidc.authorizationEndpoint}
                                            />
                                        </FormLabel>
                                        <FormLabel
                                            title={this.localizationService.getString(
                                                "tokenEndpoint"
                                            )}
                                            htmlFor="tokenEndpoint"
                                        >
                                            <InputWithCopy value={this.state.oidc.tokenEndpoint} />
                                        </FormLabel>
                                        <FormLabel
                                            title={this.localizationService.getString(
                                                "jwksEndpoint"
                                            )}
                                            htmlFor="jwksEndpoint"
                                        >
                                            <InputWithCopy value={this.state.oidc.jwksEndpoint} />
                                        </FormLabel>
                                        <FormLabel
                                            title={this.localizationService.getString(
                                                "userinfoEndpoint"
                                            )}
                                            htmlFor="userinfoEndpoint"
                                        >
                                            <InputWithCopy
                                                value={this.state.oidc.userinfoEndpoint}
                                            />
                                        </FormLabel>
                                        <FormLabel
                                            title={this.localizationService.getString(
                                                "oidcDiscoveryEndpoint"
                                            )}
                                            htmlFor="oidcDiscoveryEndpoint"
                                        >
                                            <InputWithCopy
                                                value={this.state.oidc.oidcDiscoveryEndpoint}
                                            />
                                        </FormLabel>
                                        <FormLabel
                                            title={this.localizationService.getString("scope")}
                                            htmlFor="scope"
                                        >
                                            <InputWithCopy value={this.state.oidc.scope} />
                                        </FormLabel>
                                    </>
                                )}
                            <FormColumns
                                right={
                                    <Button
                                        asElement={ButtonElement.BUTTON}
                                        buttonType={ButtonType.PRIMARY}
                                        onClick={this.onContinue}
                                        className={styles.button}
                                    >
                                        {this.localizationService.getString("continue")}
                                    </Button>
                                }
                            />
                        </Form>
                    </>
                )}
            </section>
        </div>
    )
}
