import React from "react"
import styles from "./LeftNav.module.scss"
import {
    faArrowLeft as falArrowLeft,
    faArrowRight as falArrowRight,
} from "@fortawesome/pro-light-svg-icons"
import { IconButton } from "../icon-button/IconButton.component"
import classNames from "classnames/bind"
import { LeftNavItem } from "./item/LeftNavItem.component"

export default function () {
    return (
        <div
            className={classNames(styles.leftNav, {
                [styles.leftNavCollapsed]: this.state.collapsed,
            })}
        >
            <div>
                {this.props.links.map((link, index) => {
                    return (
                        <LeftNavItem
                            key={index}
                            label={link.label}
                            href={link.href}
                            icon={link.icon}
                            collapsed={this.state.collapsed}
                        />
                    )
                })}
            </div>
            <div className={styles.leftNavArrow}>
                <IconButton
                    small
                    onClick={this.toggleCollapsed}
                    icon={this.state.collapsed ? falArrowRight : falArrowLeft}
                />
            </div>
        </div>
    )
}
