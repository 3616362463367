/**
 * OmittedDateTimeProps
 * https://html.spec.whatwg.org/multipage/input.html#input-type-attr-summary
 *
 * inputs of types date, month, week, time, datetime-local, and datetime don't support the following props
 */
export type OmittedDateTimeProps =
    | "accept"
    | "alt"
    | "checked"
    | "dirname"
    | "formaction"
    | "formenctype"
    | "formmethod"
    | "formnovalidate"
    | "formtarget"
    | "height"
    | "maxlength"
    | "minlength"
    | "multiple"
    | "pattern"
    | "placeholder"
    | "popovertarget"
    | "popovertargetaction"
    | "src"
    | "size"
    | "width"
    | "type"
    | "role"

export const timeDefaults = {
    startTime: "00:00:00",
    endTime: "23:59:59",
    minDays: 14,
}
