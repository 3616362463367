import React from "react"
import { FormLabel } from "../../../../components"
import { MultiInput } from "../../../../components/multi-input/MultiInput.component"
import styles from "./ServicesAddIncludeDomains.module.scss"

export default function () {
    return (
        <FormLabel
            className={this.props.className}
            title={this.ls.getString("domainsToProxy")}
            tooltip={this.ls.getString("domainsToProxyDescription")}
            htmlFor="domainsToProxy"
        >
            <MultiInput
                className={styles.input}
                initialValues={this.props.initialValues}
                onChange={this.props.onChange}
                placeholder={this.ls.getString("domainsToProxyPlaceholder")}
                disabled={this.props.disabled}
            />
        </FormLabel>
    )
}
