import * as React from "react"
import { useHistory } from "react-router"
import classNames from "classnames/bind"
import styles from "./ChoiceModal.module.scss"
import { ModalRef, useServiceModal, OrgEdition, useServiceUser } from "../../services"
import { PremiumFeatureTape, PremiumFeatureTag, Clickable } from "../"

interface Props {
    description: string
    choices: ChoiceModalChoice[]
    mockEdition?: OrgEdition
    modalRef: ModalRef
}

export function ChoiceModal({ description, choices, modalRef, mockEdition }: Props) {
    const modalService = useServiceModal()
    const history = useHistory()
    const userService = useServiceUser()

    const openLink = (link: string) => () => {
        history.push(link)
        if (modalRef) {
            modalService.close(modalRef.id)
        }
    }

    // look up the current edition
    const [edition, setEdition] = React.useState<OrgEdition>(OrgEdition.ENTERPRISE)
    React.useEffect(() => {
        // if we don't have a mock edition, load it
        if (!mockEdition) {
            userService.getEdition().then(setEdition)
        } else {
            setEdition(mockEdition)
        }
    }, [userService, mockEdition])

    return (
        <React.Fragment>
            <p>{description}</p>
            {choices.map((choice) => {
                // the choice needs to be disabled if its locked in team edition
                // and we are currently running in team edition
                const disabled = choice.teamEditionLocked && edition === OrgEdition.TEAM

                return (
                    <Clickable
                        onClick={openLink(choice.link)}
                        key={choice.title}
                        className={classNames(choice.className, styles.optionContainer)}
                        disabled={disabled}
                        aria-label={choice.title}
                    >
                        {disabled && choice.teamEditionLocked && <PremiumFeatureTape />}
                        <p className={styles.optionHeader}>
                            {choice.title}
                            {disabled && <PremiumFeatureTag />}
                        </p>
                        <p className={styles.optionText}>{choice.description}</p>
                    </Clickable>
                )
            })}
        </React.Fragment>
    )
}

export type ChoiceModalChoice = {
    title: string
    description: string
    link: string
    className: string
    teamEditionLocked?: boolean
}
