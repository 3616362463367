import { LanguageKey } from "../pre-v3/services/localization/languages/en-US.language"
import { ProfileRes } from "../v3/api/Org.api"

export enum Profile {
    MOM = "MOM",
    SUPER_ADMIN = "SuperAdmin",
    READONLY = "ReadOnly",
    ADMIN = "Admin",
    OWNER = "Owner",
    POLICY_AUTHOR = "PolicyAuthor",
    SERVICE_AUTHOR = "ServiceAuthor",
}

export const profileResDict: Record<ProfileRes, Profile> = {
    MOM: Profile.MOM,
    SuperAdmin: Profile.SUPER_ADMIN,
    ReadOnly: Profile.READONLY,
    Admin: Profile.ADMIN,
    Owner: Profile.OWNER,
    PolicyAuthor: Profile.POLICY_AUTHOR,
    ServiceAuthor: Profile.SERVICE_AUTHOR,
}

export const profileLabelDict: Record<Profile, LanguageKey> = {
    [Profile.MOM]: "mom",
    [Profile.SUPER_ADMIN]: "superAdmin",
    [Profile.READONLY]: "readOnly",
    [Profile.ADMIN]: "admin",
    [Profile.OWNER]: "owner",
    [Profile.POLICY_AUTHOR]: "policyAuthor",
    [Profile.SERVICE_AUTHOR]: "serviceAuthor",
}

export function isProfileAdmin(profile: Profile): boolean {
    return [Profile.SUPER_ADMIN, Profile.ADMIN, Profile.OWNER].includes(profile)
}
