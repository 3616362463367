import React, { FC, useMemo } from "react"
import { useServiceLocalization } from "../../../pre-v3/services"
import { StatsDisplay } from "./StatsDisplay.components"
import { CallToAction } from "./CallToAction.component"
import styles from "./LicensesCard.module.scss"
import classNames from "classnames"
import { LicenseLevel } from "../../../v3/services/shared/LicenseInformation"
import { LicenseType } from "../../../v3/services/User.service"
import { Card } from "../../../components/card/Card.component"

interface Props {
    licenseName: LicenseType
    licenseType: LicenseLevel
    licensesCount: number
    usageCount: number
}

export const LicensesCard: FC<Props> = ({
    licenseName,
    licenseType,
    licensesCount,
    usageCount,
}) => {
    const localization = useServiceLocalization()
    const haveLicensesSubscription = licenseType !== LicenseLevel.NONE
    const usagePercentage = useMemo(() => {
        if (licensesCount === 0) return 0
        return Math.floor((usageCount * 100) / licensesCount)
    }, [licensesCount, usageCount])

    const callToActionText = useMemo(() => {
        if (licenseType === LicenseLevel.ADVANCED) return

        if (licenseType === LicenseLevel.NONE)
            return localization.getString(
                "exploreSomeLicenseForEnhancedFeatures",
                localization.getString(licenseName)
            )

        return localization.getString("goAdvancedForMoreCoverage")
    }, [licenseType, licenseName])

    return (
        <Card className={classNames(styles.card, { [styles.empty]: !haveLicensesSubscription })}>
            <p>
                {localization.getString(licenseName)}{" "}
                {haveLicensesSubscription && localization.getString(licenseType)}
            </p>
            <div className={styles.cardInfo}>
                <div className={styles.leftContainer}>
                    <div className={styles.stats}>
                        {haveLicensesSubscription ? (
                            <>
                                <StatsDisplay
                                    displayNumber={licensesCount}
                                    label={localization.getString("licensesInPlan")}
                                />
                                <StatsDisplay
                                    isInUse
                                    displayNumber={usageCount}
                                    label={localization.getString("licensesInUse")}
                                    licenseType={licenseName}
                                />
                            </>
                        ) : (
                            <p>
                                {localization.getString(
                                    "youDoNotHaveALicenseSubscriptionForThisPlan"
                                )}
                            </p>
                        )}
                    </div>
                    {haveLicensesSubscription && (
                        <p
                            className={classNames(styles.usage, {
                                [styles.danger]: usagePercentage >= 100,
                            })}
                        >
                            {localization.getString("licenseUsage")}: {usagePercentage}%
                        </p>
                    )}
                </div>
                {callToActionText && <CallToAction text={callToActionText} />}
            </div>
        </Card>
    )
}
