import React, { FormEvent, useState } from "react"

import styles from "./ConnectorConfigurationForm.module.scss"
import { ModalRef } from "../../../../../pre-v3/services/Modal.service"
import { useServiceLocalization } from "../../../../../pre-v3/services/localization/Localization.service"
import { Container } from "../../../../components/container/Container.component"
import { ErrorBanner } from "../../../../components/banner/Banner.component"
import {
    Button,
    ButtonElement,
    ButtonType,
} from "../../../../../components/button/Button.component"
import { NewApiKey, useCreateApiKey } from "../../../../services/ApiKey.service"
import { ApiKeyScope } from "../../../../api/ApiKey.api"
import { Form } from "../../../../components/form/Form.component"
import { FormRow } from "../../../../components/form/FormRow.component"
import { Input } from "../../../../components/input/Input.component"
import { FormButtons } from "../../../../components/form/form-buttons/FormButtons.component"

interface Props {
    modalRef: ModalRef
    scope: ApiKeyScope
}

export function ApiKeyAddModal(props: Props): JSX.Element {
    const localization = useServiceLocalization()
    const [name, setName] = useState("")
    const [description, setDescription] = useState("")

    const {
        mutateAsync: createApiKey,
        isLoading: isDataLoading,
        error: createApiKeyError,
    } = useCreateApiKey()

    async function onSubmit(e: FormEvent): Promise<void> {
        e.preventDefault()
        try {
            const apiKey: NewApiKey = {
                name,
                description,
                scope: props.scope,
            }

            const newApiKey = await createApiKey(apiKey)
            props.modalRef.close(newApiKey)
        } catch (error) {}
    }

    return (
        <Container className={styles.modalContainer}>
            {typeof createApiKeyError === "string" && (
                <ErrorBanner>{createApiKeyError}</ErrorBanner>
            )}
            <Form onSubmit={onSubmit}>
                <FormRow label={localization.getString("name")}>
                    <Input
                        type="text"
                        placeholder={localization.getString("name")}
                        value={name}
                        onChangeValue={setName}
                        required
                    />
                </FormRow>
                <FormRow label={localization.getString("description")}>
                    <Input
                        type="text"
                        placeholder={localization.getString("description")}
                        value={description}
                        onChangeValue={setDescription}
                    />
                </FormRow>
                <FormRow label={localization.getString("scope")}>
                    <Input
                        type="text"
                        placeholder={localization.getString("name")}
                        defaultValue={props.scope}
                        disabled
                    />
                </FormRow>
                <FormButtons
                    rightButtons={
                        <>
                            <Button
                                asElement={ButtonElement.BUTTON}
                                buttonType={ButtonType.SECONDARY}
                                onClick={props.modalRef.cancel}
                            >
                                {localization.getString("cancel")}
                            </Button>
                            <Button
                                asElement={ButtonElement.BUTTON}
                                buttonType={ButtonType.PRIMARY}
                                type="submit"
                                loading={isDataLoading}
                            >
                                {localization.getString("save")}
                            </Button>
                        </>
                    }
                ></FormButtons>
            </Form>
        </Container>
    )
}

interface AddFileModalActionsProps {
    error?: string
    submitting?: boolean
}

export function AddFileModalActions(props: AddFileModalActionsProps) {
    const localization = useServiceLocalization()

    return (
        <div className={styles.actionContainer}>
            {props.error && <ErrorBanner className={styles.banner}>{props.error}</ErrorBanner>}
            <Button
                className={styles.button}
                form="file-modal-form"
                asElement={ButtonElement.BUTTON}
                buttonType={ButtonType.PRIMARY}
                type="submit"
                loading={props.submitting}
            >
                {localization.getString("save")}
            </Button>
        </div>
    )
}
