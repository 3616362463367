import classnames from "classnames/bind"
import React from "react"
import { Link as RouterLink } from "react-router-dom"

import { Icon, IconType } from "../icon/Icon.component"
import styles from "./Button.module.scss"

export { IconType } from "../icon/Icon.component"

export const Button = React.forwardRef<HTMLButtonElement & HTMLAnchorElement, ButtonProps>(
    (props, ref) => {
        const { children, icon, buttonType, className, small, ...restProps } = props

        const classes = classnames(props.className, className, styles.button, {
            [styles.primary]: buttonType === ButtonType.PRIMARY,
            [styles.secondary]: buttonType === ButtonType.SECONDARY,
            [styles.secondarySolid]: buttonType === ButtonType.SECONDARY_SOLID,
            [styles.destructive]: buttonType === ButtonType.DESTRUCTIVE,
            [styles.destructiveSolid]: buttonType === ButtonType.DESTRUCTIVE_SOLID,
            [styles.withIcon]: icon && children,
            [styles.noChildren]: !children,
            [styles.disabled]: restProps.disabled,
            [styles.small]: small,
        })

        if (restProps.asElement === ButtonElement.LINK) {
            const { to, asElement, ...linkProps } = restProps
            if (to.startsWith("http") || to.startsWith("mailto:")) {
                return (
                    <a
                        {...linkProps}
                        href={to}
                        target="_blank"
                        rel="noreferrer"
                        className={classes}
                        ref={ref}
                    >
                        {icon && <Icon icon={icon} />}
                        {children}
                    </a>
                )
            }

            return (
                <RouterLink {...linkProps} to={to} className={classes}>
                    {icon && <Icon icon={icon} />}
                    {children}
                </RouterLink>
            )
        }

        const { asElement, loading, ...buttonRest } = restProps
        const buttonClasses = classnames(classes, {
            [styles.loading]: loading,
            [styles.withIcon]: (icon || loading) && children,
            [styles.withoutIcon]: !icon && !loading && children,
        })

        return (
            <button {...buttonRest} className={buttonClasses} type={buttonRest.type} ref={ref}>
                {icon && !loading && <Icon icon={icon} />}
                {loading && <Icon spin icon={IconType.SPINNER} />}
                {children}
            </button>
        )
    }
)

Button.displayName = "Button"

export type ButtonProps =
    | ((React.ButtonHTMLAttributes<HTMLButtonElement> & {
          asElement?: ButtonElement.BUTTON
          loading?: boolean
      }) &
          CustomButtonProps)
    | ((React.AnchorHTMLAttributes<HTMLAnchorElement> & {
          to: string
          asElement: ButtonElement.LINK
          disabled?: boolean
      }) &
          CustomButtonProps)

interface CustomButtonProps {
    buttonType: ButtonType
    icon?: IconType
    small?: boolean
}

export enum ButtonElement {
    BUTTON = "button",
    LINK = "link",
}

export enum ButtonType {
    PRIMARY = "primary",
    SECONDARY = "secondary",
    SECONDARY_SOLID = "secondary-solid",
    DESTRUCTIVE = "destructive",
    DESTRUCTIVE_SOLID = "destructive-solid",
}
