import React, { ReactNode, SyntheticEvent } from "react"
import { RouteComponentProps } from "react-router"

import { ROUTE } from "../../../../../../routes"
import { LocalizationService } from "../../../../../services/localization/Localization.service"
import ActionBarService from "../../../../../services/ActionBar.service"
import { SettingsService, ServiceBundle } from "../../../../../services/Settings.service"
import { ManageService, ServiceManage } from "../../../../../services/Manage.service"
import { Bind } from "../../../../../decorators/Bind.decorator"
import { SelectItem } from "../../../../../utils/SelectValue.util"
import ServiceBundleAddTemplate from "./ServiceBundleAdd.template"

export class ServiceBundleAdd extends React.Component<RouteComponentProps, ServiceBundleAddState> {
    public state: ServiceBundleAddState = { bulkConnect: true, serviceIds: [], services: [] }

    public render(): ReactNode {
        return ServiceBundleAddTemplate.call(this)
    }

    public componentDidMount(): void {
        this.actionBarService.setItems(this.localizationService.getString("addBundle"), [
            {
                label: this.localizationService.getString("desktopAndMobile"),
            },
            {
                label: this.localizationService.getString("serviceBundles"),
                href: ROUTE.BUNDLES,
            },
        ])
        this.fetchData()
    }

    private localizationService: LocalizationService = new LocalizationService()
    private actionBarService: ActionBarService = new ActionBarService()
    private settingsService: SettingsService = new SettingsService()
    private manageService: ManageService = new ManageService()

    private bulkConnectOptions: SelectItem[] = [
        {
            displayName: this.localizationService.getString(
                "enableOneClickConnectivityToAllServicesInBundle"
            ),
            value: true,
        },
        {
            displayName: this.localizationService.getString(
                "disableOneClickConnectivityToAllServicesInBundle"
            ),
            value: false,
        },
    ]

    @Bind
    private onBulkConnectChange(value: boolean): void {
        this.setState({ bulkConnect: value })
    }

    @Bind
    private onServiceChange(value: string[]): void {
        this.setState({ serviceIds: value })
    }

    @Bind
    private fetchData(): void {
        this.manageService.getRegisteredServices().then(
            (services: ServiceManage[]) => {
                this.setState({
                    services: services.map((service) => {
                        return { displayName: service.name, value: service.id }
                    }),
                })
            },
            () => {
                this.setState({
                    error: this.localizationService.getString("failedToLoadServices"),
                })
            }
        )
    }

    @Bind
    private onSubmit(event: SyntheticEvent): void {
        this.setState({ error: "" })
        event.preventDefault()
        if (event.target && this.state.serviceIds.length > 0) {
            const form: { [key: string]: HTMLInputElement } = <any>event.target
            const bundle: ServiceBundle = {
                name: form.bundleName.value,
                desc: form.description.value,
                bulkConnect: this.state.bulkConnect,
                serviceIds: this.state.serviceIds,
            }
            this.settingsService.createServiceBundle(bundle).then(
                () => {
                    this.props.history.push(ROUTE.BUNDLES)
                },
                (err: Error) => {
                    this.setState({ error: err.message })
                }
            )
        }
    }
}

interface ServiceBundleAddState {
    error?: string
    bulkConnect: boolean
    serviceIds: string[]
    services: SelectItem[]
}
