// external imports
import * as React from "react"
// local imports
import { ServiceFormProps, useAttributes, useUpdateTags } from "../service-form"
import ServiceConnectionForm from "./connection"
import ServiceAttributesForm from "./attributes"
import { ServiceTag } from "../../../api/Manage.api"
import { TCPConnectMode } from "../../../services"

export default function GenericServiceForm({ edit, hideBackend }: ServiceFormProps) {
    // grab the info we need
    const attributes = useAttributes()
    const updateTags = useUpdateTags()

    // update the form with any rdp-service specifics
    React.useEffect(() => {
        updateTags((tags) => ({
            ...tags,
            [ServiceTag.BANYAN_PROXY_MODE]: attributes.backend?.http_connect
                ? TCPConnectMode.CHAIN
                : TCPConnectMode.TCP,
        }))
    }, [attributes.backend?.http_connect, updateTags])

    return (
        <>
            <ServiceAttributesForm
                edit={edit}
                defaultPort="8443"
                showAllowPatterns
                hideBackend={hideBackend}
            />
            <ServiceConnectionForm edit={edit} defaultAllowOverride showIncludeDomains />
        </>
    )
}
