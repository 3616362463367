import React from "react"
import { Route, RouteComponentProps, Switch, useHistory } from "react-router-dom"

import useTitle from "../../hooks/useTitle.hook"
import { ApiKeysAdd } from "../../pre-v3/views/settings/org/api-keys/add/ApiKeysAdd.component"
import { ApiKeysDetail as ApiKeysDetails } from "../../pre-v3/views/settings/org/api-keys/detail/ApiKeysDetail.component"
import { ApiKeysSettings as ApiKeysList } from "../../pre-v3/views/settings/org/api-keys/ApiKeysSettings.component"
import { ROUTE } from "../../routes"
import { Loader } from "../../v3/components/loader/Loader.component"
import { useFeatureFlags } from "../../hooks/useFeatureFlags.hook"
import { useServiceLocalization } from "../../pre-v3/services"
import { Banner, Variant } from "../../components/banner/Banner.component"

export function ApiKeys(): JSX.Element {
    useTitle(["apiKeys", "identityAndAccess", "settings", "adminConsole"])
    const featureFlagsQuery = useFeatureFlags()
    const localization = useServiceLocalization()
    const history = useHistory()

    if (featureFlagsQuery.status === "loading") {
        return <Loader center medium title={localization.getString("loading")} />
    }

    if (featureFlagsQuery.status === "error") {
        return <Banner label={String(featureFlagsQuery.error)} variant={Variant.ERROR} />
    }

    return (
        <Switch>
            <Route path={ROUTE.API_KEYS_ADD} component={ApiKeysAdd} />
            <Route
                path={ROUTE.API_KEYS_DETAILS}
                component={(props: RouteComponentProps<{ id: string }>) => (
                    <ApiKeysDetails
                        canAccessApiKey={featureFlagsQuery.data.adminConsole.canAccessApiKey}
                        {...props}
                    />
                )}
            />
            <Route path={ROUTE.API_KEYS}>
                <ApiKeysList
                    history={history}
                    canAccessApiKey={featureFlagsQuery.data.adminConsole.canAccessApiKey}
                />
            </Route>
        </Switch>
    )
}
