import React from "react"

import { useServiceLocalization } from "../../../../../../../pre-v3/services"
import { FormRow } from "../../../../../../components/form/FormRow.component"
import {
    MultiInput,
    Props as MultiInputProps,
} from "../../../../../../components/multi-input/MultiInput.component"
import styles from "./DetailsAndAssignmentForm.module.scss"

interface Props extends Omit<MultiInputProps, "label"> {}

export function SerialNumbersInput(props: Props): JSX.Element {
    const localization = useServiceLocalization()

    return (
        <FormRow
            label={localization.getString("enterDeviceSerialNumbers")}
            description={localization.getString(
                "deviceSerialNumbersCanBeFoundOnTheDeviceDetailsPage"
            )}
            className={styles.multiInput}
        >
            <MultiInput {...props} label={localization.getString("serialNumbers")} />
        </FormRow>
    )
}
