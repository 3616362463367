import React from "react"
import { useHistory } from "react-router-dom"

import { Button, ButtonElement, ButtonType } from "../../../../components/button/Button.component"
import { useServiceLocalization } from "../../../../pre-v3/services/localization/Localization.service"
import { decodeID, encodeID, useUrlSearch } from "../../../../pre-v3/utils/Url.util"
import { ROUTE, formatRoutePath } from "../../../../routes"
import { ErrorBanners } from "../../../components/banner/Banner.component"
import { Container } from "../../../components/container/Container.component"
import { Loader } from "../../../components/loader/Loader.component"
import {
    useCreateItpPolicy,
    useEmptyItpPolicyFields,
    useGetDnsFilters,
    useGetITPApplications,
    useGetItpPolicy,
    useGetPoliciesRolesNames,
} from "../../../services/ItpPolicy.service"
import styles from "../ItpPolicy.module.scss"
import { ItpPolicyForm } from "../form/ItpPolicyForm.component"
import { PageHeading } from "../../../../components/page-heading/PageHeading.component"

export function ItpPolicyAdd(): JSX.Element {
    const localization = useServiceLocalization()
    const history = useHistory()
    const params = useUrlSearch("itpPolicyId")

    const itpPolicyIdToCopy = params?.itpPolicyId || ""
    const isCopyEnabled = Boolean(itpPolicyIdToCopy)

    const [protectionProfile, setProtectionProfile] = React.useState(useEmptyItpPolicyFields())

    const {
        isFetching: isFetchingDnsFilters,
        data: dnsFilters,
        error: dnsFilterError,
        isError: isDnsError,
    } = useGetDnsFilters()

    const {
        data: itpApplications,
        isFetching: isItpApplicationsLoading,
        error: itpApplicationsError,
    } = useGetITPApplications()

    const {
        isFetching: isFetchingRoles,
        data: roles,
        error: rolesError,
        isError: isRolesError,
    } = useGetPoliciesRolesNames()

    const {
        isLoading: isCreatingItpPolicy,
        mutate: createItpPolicy,
        error: createItpPolicyError,
    } = useCreateItpPolicy({
        onSuccess: (newItpPolicy) => {
            history.push(
                formatRoutePath(ROUTE.INTERNET_THREAT_PROTECTION_DETAILS, {
                    id: encodeID(newItpPolicy.id),
                })
            )
        },
    })

    const {
        data: itpPolicyToCopy,
        isFetching: isFetchingItpPolicy,
        error: itpPolicyError,
    } = useGetItpPolicy(decodeID(itpPolicyIdToCopy), {
        enabled: isCopyEnabled,
    })

    React.useEffect(() => {
        if (itpPolicyToCopy) {
            setProtectionProfile({
                ...itpPolicyToCopy,
                name: localization.getString("copyOfSomething", itpPolicyToCopy.name),
            })
        }
    }, [itpPolicyToCopy, setProtectionProfile, localization])

    const isLoading =
        isFetchingDnsFilters ||
        isFetchingRoles ||
        isCreatingItpPolicy ||
        isFetchingItpPolicy ||
        isItpApplicationsLoading
    const isError = isDnsError || isRolesError

    const errors: React.ReactNode[] = [
        dnsFilterError ? String(dnsFilterError) : null,
        rolesError ? String(rolesError) : null,
        itpPolicyError ? String(itpPolicyError) : null,
        itpApplicationsError && String(itpApplicationsError),
    ]
    const formErrors: React.ReactNode[] = [
        createItpPolicyError ? String(createItpPolicyError) : null,
    ]

    function onCancel() {
        history.push(ROUTE.INTERNET_THREAT_PROTECTION)
    }

    return (
        <Loader medium center isLoading={isLoading}>
            <Container as="section" aria-labelledby={Id.HEADING} className={styles.container}>
                <header className={styles.header}>
                    <PageHeading id={Id.HEADING}>
                        {localization.getString("createItpPolicy")}
                    </PageHeading>
                </header>
                <ErrorBanners className={styles.errorBanners} errors={errors} />
                {!isError && (
                    <ItpPolicyForm
                        dnsFilters={dnsFilters!}
                        appFilters={itpApplications!}
                        value={protectionProfile}
                        onChange={setProtectionProfile}
                        onSubmit={createItpPolicy}
                        onCancel={onCancel}
                        roles={roles!}
                        formErrors={formErrors}
                        isEdit={false}
                    />
                )}
                {isError && (
                    <Button
                        asElement={ButtonElement.BUTTON}
                        buttonType={ButtonType.SECONDARY}
                        type="button"
                        onClick={onCancel}
                    >
                        {localization.getString("back")}
                    </Button>
                )}
            </Container>
        </Loader>
    )
}

enum Id {
    HEADING = "heading",
}
