import React from "react"
import styles from "./ServicesSaasOverviewAttr.module.scss"
import { SaasAppType, SaasAuthProtocol, SaasNameIdValueOpts } from "../../../../api/Manage.api"
import { Form, FormLabel, Button, InputWithCopy, Input } from "../../../../components"

export default function () {
    return (
        <div className={styles.container}>
            {this.props.app && (
                <>
                    <p className={styles.title}>
                        {this.localizationService.getString("saasClientApplicationConfiguration")}
                    </p>
                    <Button onClick={this.onToggle}>
                        {this.localizationService.getString(
                            this.state.showConfig ? "hideConfig" : "showConfig"
                        )}
                    </Button>
                    {this.state.showConfig && (
                        <Form display="grid" labelWidth={180} className={styles.footer} slim>
                            <FormLabel
                                title={this.localizationService.getString("appClientName")}
                                htmlFor="appClientName"
                            >
                                <InputWithCopy value={this.props.app.name} />
                            </FormLabel>

                            {this.props.app.protocol === SaasAuthProtocol.OIDC && (
                                <>
                                    <FormLabel
                                        title={this.localizationService.getString("clientId")}
                                        htmlFor="clientId"
                                    >
                                        <InputWithCopy value={this.props.app.clientId} />
                                    </FormLabel>
                                    <FormLabel
                                        title={this.localizationService.getString("clientSecret")}
                                        htmlFor="clientSecret"
                                    >
                                        <InputWithCopy value={this.props.app.clientSecret} />
                                    </FormLabel>
                                </>
                            )}
                            <FormLabel
                                title={
                                    this.props.app.protocol === SaasAuthProtocol.SAML
                                        ? this.localizationService.getString("redirectUrlSamlAcs")
                                        : this.localizationService.getString("redirectUri")
                                }
                                htmlFor="redirectUri"
                            >
                                <InputWithCopy value={this.props.app.redirectUri} />
                            </FormLabel>
                            {this.props.app.protocol === SaasAuthProtocol.SAML &&
                                this.props.app.type === SaasAppType.IDP_FIRST && (
                                    <>
                                        <FormLabel
                                            title={this.localizationService.getString(
                                                "samlProxyUrl"
                                            )}
                                            htmlFor="samlProxyUrl"
                                        >
                                            <InputWithCopy value={this.state.samlProxyUrl} />
                                        </FormLabel>
                                        <FormLabel
                                            title={this.localizationService.getString(
                                                "saasAppServiceId"
                                            )}
                                            htmlFor="saasAppServiceId"
                                        >
                                            <InputWithCopy value={this.props.app.id} />
                                        </FormLabel>
                                    </>
                                )}
                            {this.props.app.protocol === SaasAuthProtocol.SAML && (
                                <>
                                    <FormLabel
                                        title={this.localizationService.getString(
                                            "audienceUriDescription"
                                        )}
                                        htmlFor="audienceUriDescription"
                                    >
                                        <InputWithCopy value={this.props.app.audienceUri} />
                                    </FormLabel>
                                    <FormLabel
                                        title={this.localizationService.getString("metadataUrl")}
                                        htmlFor="metadataUrl"
                                    >
                                        <InputWithCopy value={this.props.app.metadataUrl} />
                                    </FormLabel>
                                    <FormLabel
                                        title={this.localizationService.getString("nameIdFormat")}
                                        htmlFor="nameIdformat"
                                    >
                                        <Input
                                            className={styles.formInput}
                                            value={this.props.app.nameIdFormat}
                                            disabled
                                        />
                                    </FormLabel>
                                    <FormLabel
                                        title={this.localizationService.getString(
                                            "nameIdValueType"
                                        )}
                                        htmlFor="nameIdValueType"
                                    >
                                        <Input
                                            className={styles.formInput}
                                            value={this.localizationService
                                                .getString(
                                                    this.manageService.mapToLocalizationString(
                                                        this.props.app.nameIdValueType
                                                    )
                                                )
                                                .toLowerCase()}
                                            disabled
                                        />
                                    </FormLabel>
                                    {this.props.app.nameIdValueType ===
                                        SaasNameIdValueOpts.CUSTOM && (
                                        <>
                                            <FormLabel
                                                title={this.localizationService.getString(
                                                    "nameIdCustomAttribute"
                                                )}
                                                htmlFor="nameIdAttributeSelector"
                                            >
                                                <Input
                                                    className={styles.formInput}
                                                    value={this.props.app.nameIdAttributeSelector}
                                                    disabled
                                                />
                                            </FormLabel>
                                        </>
                                    )}
                                </>
                            )}
                            {this.props.app.protocol === SaasAuthProtocol.OIDC &&
                                this.state.oidc && (
                                    <>
                                        <FormLabel
                                            title={this.localizationService.getString("issuerUrl")}
                                            htmlFor="issuerUrl"
                                        >
                                            <InputWithCopy value={this.state.oidc.issuerUrl} />
                                        </FormLabel>
                                        <FormLabel
                                            title={this.localizationService.getString(
                                                "authorizationEndpoint"
                                            )}
                                            htmlFor="authorizationEndpoint"
                                        >
                                            <InputWithCopy
                                                value={this.state.oidc.authorizationEndpoint}
                                            />
                                        </FormLabel>
                                        <FormLabel
                                            title={this.localizationService.getString(
                                                "tokenEndpoint"
                                            )}
                                            htmlFor="tokenEndpoint"
                                        >
                                            <InputWithCopy value={this.state.oidc.tokenEndpoint} />
                                        </FormLabel>
                                        <FormLabel
                                            title={this.localizationService.getString(
                                                "jwksEndpoint"
                                            )}
                                            htmlFor="jwksEndpoint"
                                        >
                                            <InputWithCopy value={this.state.oidc.jwksEndpoint} />
                                        </FormLabel>
                                        <FormLabel
                                            title={this.localizationService.getString(
                                                "userinfoEndpoint"
                                            )}
                                            htmlFor="userinfoEndpoint"
                                        >
                                            <InputWithCopy
                                                value={this.state.oidc.userinfoEndpoint}
                                            />
                                        </FormLabel>
                                        <FormLabel
                                            title={this.localizationService.getString(
                                                "oidcDiscoveryEndpoint"
                                            )}
                                            htmlFor="oidcDiscoveryEndpoint"
                                        >
                                            <InputWithCopy
                                                value={this.state.oidc.oidcDiscoveryEndpoint}
                                            />
                                        </FormLabel>
                                        <FormLabel
                                            title={this.localizationService.getString("scope")}
                                            htmlFor="scope"
                                        >
                                            <InputWithCopy value={this.state.oidc.scope} />
                                        </FormLabel>
                                    </>
                                )}
                        </Form>
                    )}
                </>
            )}
        </div>
    )
}
