import {
    UseMutationResult,
    UseQueryResult,
    useMutation,
    useQuery,
    useQueryClient,
} from "@tanstack/react-query"
import React from "react"
import { fetchEventSource, EventSourceMessage } from "@microsoft/fetch-event-source"

import { UrlUtil } from "../../pre-v3/utils/Url.util"
import { BaseApi } from "./Base.api"

enum LlmQueryErrorCode {
    NoRecommendationMatches = 9000,
    NoQuestionsFound = 9001,
    ValidationFailure = 9002,
    FeedbackExists = 9003,
    AuthFailure = 9004,
    InvalidAnswer = 9005,
}

class LlmQueryApi extends BaseApi {
    public authenticate() {
        return this.post<undefined, AuthenticationRes>("/api/v2/llm/authorize", undefined, {
            default: this.localization.getString(
                "failedToGetSomething",
                this.localization.getString("llmAuthentication")
            ),
        })
    }
}

enum LlmQueryKey {
    AUTHENTICATE = "@llmQuery/authenticate",
    GET_ANSWERS = "@llmQuery/getAnswers",
    GET_ANSWER = "@llmQuery/getAnswer",
    ASK_QUESTION = "@llmQuery/askQuestion",
    GET_RECOMMENDATIONS = "@llmQuery/getRecommendations",
}

function useAuthenticate() {
    const llmQueryApi = new LlmQueryApi()

    const client = useQueryClient()

    return useMutation({
        mutationKey: [LlmQueryKey.AUTHENTICATE],
        mutationFn: () => llmQueryApi.authenticate(),
        cacheTime: Infinity,
        onSuccess: (data) => client.setQueryData([LlmQueryKey.AUTHENTICATE], data),
    })
}

export function useGetAnswers() {
    const llmFetcher = useLlmFetcher<AnswerRes[]>()

    return useQuery<AnswerRes[]>({
        queryKey: [LlmQueryKey.GET_ANSWERS],
        queryFn: () => llmFetcher("/v1/llm/answers"),
    })
}

export function useGetAnswer(id: string): UseQueryResult<AnswerRes | null> {
    const llmFetcher = useLlmFetcher<AnswerRes>()

    return useQuery<AnswerRes | null>({
        queryKey: [LlmQueryKey.GET_ANSWER, id],
        queryFn: async () => {
            try {
                return await llmFetcher(`/v1/llm/answers/${id}`)
            } catch (error) {
                return typeof error === "object" &&
                    error &&
                    "data" in error &&
                    typeof error.data === "object" &&
                    error.data &&
                    "error_code" in error.data &&
                    error.data.error_code === LlmQueryErrorCode.NoQuestionsFound
                    ? null
                    : Promise.reject(error)
            }
        },
    })
}

export function useAskQuestion(
    currentPageUrl: string,
    orgName: string,
    handleMessage: (msg: EventSourceMessage) => void
) {
    const llmStreamFetcher = useLlmStreamFetcher<AnswerRes, AskQuestionReq>(handleMessage)

    return useMutation<AnswerRes, unknown, string>({
        mutationKey: [LlmQueryKey.ASK_QUESTION],
        mutationFn: (prompt: string) => {
            return llmStreamFetcher("/v1/llm/answers", {
                method: "POST",
                body: {
                    prompt: prompt,
                    metadata: { current_page_url: currentPageUrl, org_name: orgName },
                },
            })
        },
    })
}

export function useProvideFeedback(): UseMutationResult<
    void,
    { data?: { error_code?: number } },
    [string, FeedbackReq]
> {
    const llmFetcher = useLlmFetcher<void, FeedbackReq>()
    const client = useQueryClient()

    return useMutation<void, { data?: { error_code?: number } }, [string, FeedbackReq]>({
        mutationFn: async ([id, feedback]: [string, FeedbackReq]) => {
            try {
                return await llmFetcher(`/v1/llm/answers/${id}/feedback`, {
                    method: "POST",
                    body: feedback,
                })
            } catch (error) {
                return typeof error === "object" &&
                    error &&
                    "statusCode" in error &&
                    error.statusCode === 204
                    ? undefined
                    : Promise.reject(error)
            }
        },
        onMutate: ([id]) => {
            client.setQueryData<AnswerRes>(
                [LlmQueryKey.GET_ANSWER, id],
                (answer) => answer && { ...answer, has_provided_feedback: true }
            )
            client.setQueryData<AnswerRes>([LlmQueryKey.GET_ANSWER, "last"], (answer) =>
                answer?.answer_id.toString() === id
                    ? { ...answer, has_provided_feedback: true }
                    : answer
            )
        },
        onError: (error, [id]) => {
            if (error.data?.error_code !== LlmQueryErrorCode.FeedbackExists) {
                client.setQueryData<AnswerRes>(
                    [LlmQueryKey.GET_ANSWER, id],
                    (answer) => answer && { ...answer, has_provided_feedback: false }
                )
                client.setQueryData<AnswerRes>([LlmQueryKey.GET_ANSWER, "last"], (answer) =>
                    answer?.answer_id.toString() === id
                        ? { ...answer, has_provided_feedback: false }
                        : answer
                )
            }
        },
    })
}

export function useGetRecommendations(
    getRecommendationsParams: Partial<GetRecommendationsParams> = {}
) {
    const llmFetcher = useLlmFetcher<string[]>()

    return useQuery<string[]>({
        queryKey: [LlmQueryKey.GET_RECOMMENDATIONS, getRecommendationsParams],
        queryFn: () => {
            const params = `?${UrlUtil.convertToURLSearchParams(
                getRecommendationsParams
            ).toString()}`

            return llmFetcher(`/v1/llm/recommendations${params}`)
        },
    })
}

export interface AuthenticationRes {
    token: string
    query_service_url: string
}

export interface AnswerRes {
    answer_id: number
    prompt: string
    has_response: boolean
    response: string
    has_provided_feedback: boolean
    sources: SourceRes[]
    created_at: string
}

interface SourceRes {
    label: string
    url: string
}

export interface AskQuestionReq {
    prompt: string
    metadata: {
        current_page_url: string
        org_name: string
    }
}

export interface FeedbackReq {
    was_helpful: boolean
    created_at: string
}

export interface GetRecommendationsParams {
    current_page_url: string
}

// Fetcher

interface LlmFetcherOptions<Body = unknown> {
    method?: "GET" | "POST"
    body?: Body
}

function useLlmFetcher<Result, Body = unknown>(): (
    path: string,
    options?: LlmFetcherOptions<Body>
) => Promise<Result> {
    const { mutateAsync: authenticate } = useAuthenticate()
    const queryClient = useQueryClient()
    const maybeAuth = queryClient.getQueryData<AuthenticationRes>([LlmQueryKey.AUTHENTICATE])

    return React.useCallback(
        async (path, options) => {
            const auth = maybeAuth ?? (await authenticate())

            try {
                return await fetcher(path, auth, options)
            } catch (error) {
                if (
                    typeof error === "object" &&
                    error &&
                    "data" in error &&
                    typeof error.data === "object" &&
                    error.data &&
                    "error_code" in error.data &&
                    error.data.error_code !== LlmQueryErrorCode.AuthFailure
                ) {
                    return Promise.reject(error)
                }

                const newAuth = await authenticate()
                return fetcher(path, newAuth, options)
            }
        },
        [maybeAuth, authenticate]
    )
}

async function fetcher<Result, Body = unknown>(
    path: string,
    auth: AuthenticationRes,
    options?: LlmFetcherOptions<Body>
): Promise<Result> {
    const headers = new Headers()
    headers.set("Authorization", `Bearer ${auth.token}`)
    headers.set("Content-Type", "application/json")

    const response = await fetch(`${auth.query_service_url}${path}`, {
        headers,
        method: options?.method,
        body: options?.body && JSON.stringify(options.body),
    })

    if (response.status === 204)
        return Promise.reject({ statusCode: 204, statusMessage: response.statusText })

    const json = await response.json()

    return response.ok
        ? json
        : Promise.reject({
              statusCode: response.status,
              statusMessage: response.statusText,
              data: json,
          })
}

function useLlmStreamFetcher<Result, Body = unknown>(
    handleMessage: (msg: EventSourceMessage) => void
): (path: string, options?: LlmFetcherOptions<Body>) => Promise<Result> {
    const { mutateAsync: authenticate } = useAuthenticate()
    const queryClient = useQueryClient()
    const maybeAuth = queryClient.getQueryData<AuthenticationRes>([LlmQueryKey.AUTHENTICATE])

    return React.useCallback(
        async (path, options) => {
            const auth = maybeAuth ?? (await authenticate())

            try {
                return await streamFetcher(path, auth, options, handleMessage)
            } catch (error) {
                if (
                    typeof error === "object" &&
                    error &&
                    "data" in error &&
                    typeof error.data === "object" &&
                    error.data &&
                    "error_code" in error.data &&
                    error.data.error_code !== LlmQueryErrorCode.AuthFailure
                ) {
                    return Promise.reject(error)
                }

                const newAuth = await authenticate()
                return await streamFetcher(path, newAuth, options, handleMessage)
            }
        },
        [maybeAuth, authenticate]
    )
}

async function streamFetcher<Result, Body = unknown>(
    path: string,
    auth: AuthenticationRes,
    options?: LlmFetcherOptions<Body>,
    handleMessageEvent?: (msg: EventSourceMessage) => void
): Promise<Result> {
    const ctrl = new AbortController()
    let response: string = ""

    await fetchEventSource(`${auth.query_service_url}${path}?stream=true`, {
        headers: {
            Authorization: `Bearer ${auth.token}`,
            "Content-Type": "application/json",
        },
        method: options?.method,
        body: options?.body && JSON.stringify(options?.body),
        signal: ctrl.signal,
        openWhenHidden: true,
        onmessage: (ev) => {
            handleMessageEvent?.(ev)
            response = ev.data
        },
        onerror: (e) => {
            if (!!e) {
                console.log(e)
            }
            // ctrl.abort will not work here, need to throw an error
            throw e
        },
    })

    return JSON.parse(response)
}
