import React from "react"
import styles from "./LargeMessage.module.scss"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import classNames from "classnames/bind"

export default function () {
    return (
        <div className={classNames(styles.largeMessageContainer, this.props.className)}>
            <h2 className={styles.largeMessageIcon}>
                <FontAwesomeIcon icon={this.props.icon} size="2x" />
            </h2>
            <div className={styles.largeMessageText}>{this.props.children}</div>
        </div>
    )
}
