import {
    IconDefinition,
    faPauseCircle,
    faTimesCircle,
    faMinusCircle,
    faCheckCircle,
} from "@fortawesome/pro-solid-svg-icons"
import React, { ReactNode, Fragment } from "react"
import { RouteComponentProps } from "react-router"
import { Link } from "react-router-dom"

import { ROUTE, formatRoutePath } from "../../../../routes"
import {
    SaasManage,
    ManageService,
    ServiceManageStatus,
    SaasInitialAuth,
} from "../../../services/Manage.service"
import { SimpleTableItem } from "../../../components/simple-table/SimpleTable.component"
import { decodeID, encodeID } from "../../../utils/Url.util"
import { LocalizationService } from "../../../services/localization/Localization.service"
import { UserService } from "../../../services/User.service"
import { UserOrgDetails } from "../../../api/User.api"
import { Tab } from "../../../components/tab-bar/TabBar.component"
import { Bind } from "../../../decorators/Bind.decorator"
import { DateUtil } from "../../../utils/Date.util"
import { SaasAppUrlType } from "../../../services/Service.service"
import ServicesSaasOverviewTemplate from "./ServicesSaasOverview.template"

export class ServicesSaasOverview extends React.Component<
    RouteComponentProps<ServicesSaasOverviewRouteParams>,
    ServicesSaasOverviewState
> {
    constructor(props: RouteComponentProps<ServicesSaasOverviewRouteParams>) {
        super(props)

        try {
            this.id = decodeID(this.props.match.params.id)
        } catch {
            this.state.loading = false
        }
    }

    public state: ServicesSaasOverviewState = {
        loading: true,
        serviceIcon: faPauseCircle,
        tab: 1,
        canEdit: false,
    }

    public componentDidMount(): void {
        this.userService.getUserOrgDetails().then((details: UserOrgDetails) => {
            if (this.userService.canCreateServices(details.Profile)) {
                this.setState({ canEdit: true })
            }
        })

        this.fetchData()
    }

    public render(): ReactNode {
        return ServicesSaasOverviewTemplate.call(this)
    }

    private localizationService: LocalizationService = new LocalizationService()
    private manageService: ManageService = new ManageService()
    private userService: UserService = new UserService()

    private id: string

    private tabs: Tab[] = [
        {
            label: this.localizationService.getString("overview"),
            value: 1,
            active: true,
        },
        {
            label: this.localizationService.getString("accessActivity"),
            value: 2,
        },
    ]

    @Bind
    private onTabChange(value: any): void {
        this.setState({ tab: value })
    }

    @Bind
    private onEdit(): void {
        if (this.id) {
            this.props.history.push(
                formatRoutePath(ROUTE.SAAS_APPS_EDIT, { id: encodeID(this.id) })
            )
        }
    }

    private setServiceData(): void {
        if (this.state.service) {
            const service: SaasManage = this.state.service
            this.setState({
                serviceData: [
                    {
                        label: this.localizationService.getString("initialAuth"),
                        value:
                            service.initialAuth === SaasInitialAuth.IDP
                                ? this.localizationService.getString("yourIdp")
                                : this.localizationService.getString("cseTrustProvider"),
                    },
                    {
                        label: this.localizationService.getString("authProtocol"),
                        value: service.protocol,
                    },
                    {
                        label: this.localizationService.getString("createdAt"),
                        value: DateUtil.format(service.createdAt),
                    },
                    {
                        label: this.localizationService.getString("createdBy"),
                        value: service.createdBy,
                    },
                    {
                        label: this.localizationService.getString("lastUpdated"),
                        value: DateUtil.format(service.lastUpdatedAt),
                    },
                    {
                        label: this.localizationService.getString("lastUpdatedBy"),
                        value: service.lastUpdatedBy || "-",
                    },
                ],
            })
        }
    }

    private setPolicyData(): void {
        if (this.state.service) {
            const service: SaasManage = this.state.service
            this.setState({
                policyData: [
                    {
                        label: this.localizationService.getString("policyAttached"),
                        value: this.getAttachedPolicy(service),
                    },
                    {
                        label: this.localizationService.getString("attachedOn"),
                        value: DateUtil.format(service.policyAttachedAt),
                    },
                ],
                serviceStatus: this.getServiceStatus(service),
                serviceIcon: this.getServiceIcon(service),
            })
        }
    }

    private getAttachedPolicy(service: SaasManage): ReactNode {
        if (service.policyName) {
            return React.createElement(
                Link,
                { to: formatRoutePath(ROUTE.ACCESS_POLICIES_DETAILS, { id: service.policyId! }) },
                service.policyName
            )
        } else {
            const link: ReactNode = React.createElement(
                Link,
                { to: formatRoutePath(ROUTE.SAAS_APPS_EDIT, { id: encodeID(this.id) }) },
                this.localizationService.getString("attachAPolicy")
            )
            return React.createElement(
                Fragment,
                {},
                `${this.localizationService.getString("none")} - `,
                link
            )
        }
    }

    private getServiceStatus(service: SaasManage): string {
        switch (service.status) {
            case ServiceManageStatus.POLICY_ENFORCING:
                return this.localizationService.getString("policyEnforcing")
            case ServiceManageStatus.POLICY_PERMISSIVE:
                return this.localizationService.getString("policyPermissive")
            case ServiceManageStatus.NO_POLICY:
                return this.localizationService.getString("noPolicy")
            default:
                return this.localizationService.getString("inactive")
        }
    }

    private getServiceIcon(service: SaasManage): IconDefinition {
        switch (service.status) {
            case ServiceManageStatus.POLICY_ENFORCING:
                return faCheckCircle
            case ServiceManageStatus.POLICY_PERMISSIVE:
                return faMinusCircle
            case ServiceManageStatus.NO_POLICY:
                return faTimesCircle
            default:
                return faPauseCircle
        }
    }

    @Bind
    private fetchData(): void {
        this.setState({ loading: true })
        if (this.id) {
            this.manageService
                .getSaasApp(this.id)
                .then((app: SaasManage) => {
                    if (app && app.id) {
                        this.setState({ service: app }, () => {
                            this.setServiceData()
                            this.setPolicyData()
                        })
                    }
                })
                .finally(() => {
                    this.setState({ loading: false })
                })
        } else {
            this.setState({ loading: false })
        }
    }
}

interface ServicesSaasOverviewRouteParams {
    id: string
    type: SaasAppUrlType
}

interface ServicesSaasOverviewState {
    loading: boolean
    canEdit: boolean
    service?: SaasManage
    serviceData?: SimpleTableItem[]
    policyData?: SimpleTableItem[]
    serviceStatus?: string
    serviceIcon: IconDefinition
    tab?: number
}
