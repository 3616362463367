import classNames from "classnames"
import React, { ReactNode } from "react"

// Old component
import { AccordionSummaryWithSwitch } from "../../../components/accordion/AccordionSummary.component"
import { HorizontalLine } from "../../../components/form/HorizontalLine.component"
import styles from "./ProfileCategoryHeader.module.scss"

interface ProfileCategoryHeaderProps {
    children?: ReactNode
    onClick?: (updatedValue: boolean) => void
    label: string
    description: string
    className?: string
    value?: boolean
    showSwitch?: boolean
    disabled?: boolean
    headerClassName?: string
    hideSwitch?: boolean
}

export function ProfileCategoryHeader(props: ProfileCategoryHeaderProps) {
    const { onClick, label, description, children, value, hideSwitch, disabled } = props

    return (
        <div className={classNames(styles.wrapper, { [styles.hideSwitch]: hideSwitch })}>
            <AccordionSummaryWithSwitch
                label={label}
                tooltipInfo={description}
                value={value}
                disabled={disabled}
                onChange={onClick}
            />
            {children && (
                <div className={styles.content}>
                    <HorizontalLine className={styles.pageBreak} />
                    {children}
                </div>
            )}
        </div>
    )
}
