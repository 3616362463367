import { Singleton } from "../../pre-v3/decorators/Singleton.decorator"
import { BaseApi } from "./Base.api"

@Singleton("DeviceGeoLocationApi")
export class DeviceGeoLocationApi extends BaseApi {
    public getCountries(): Promise<CountryRes> {
        return this.get(`/api/v2/geo_location_service/all_possible_countries`, {
            default: this.localization.getString("failedToGetCountries"),
        })
    }
}

export interface CountryRes {
    geo_manager_ready: boolean
    countries: string[]
}
