import React from "react"
import { Redirect, Route, Switch, useLocation } from "react-router-dom"

import {
    NavigationTabBar,
    TabProps,
} from "../../components/navigation-tab-bar/NavigationTabBar.component"
import useTitle from "../../hooks/useTitle.hook"
import { LanguageKey } from "../../pre-v3/services/localization/languages/en-US.language"
import { useServiceLocalization } from "../../pre-v3/services/localization/Localization.service"
import { IssuedCertificatesList as IssuedCertificates } from "../../pre-v3/views/issued-certificates/list/IssuedCertificatesList.component"
import { doesRouteMatch, ROUTE } from "../../routes"
import { RegisteredDomain as RegisteredDomains } from "../../v3/views/registered-domains/RegisteredDomain.view"
import styles from "./Certificates.module.scss"
import { Loader } from "../../v3/components/loader/Loader.component"
import { useFeatureFlags } from "../../hooks/useFeatureFlags.hook"
import { Banner, Variant } from "../../components/banner/Banner.component"
import { Upsell } from "../../shared/upsell/Upsell.component"

export function Certificates(): JSX.Element {
    useTitle(["certificates", "settings", "adminConsole"])

    const localization = useServiceLocalization()
    const location = useLocation()
    const { data: featureFlags, isLoading, isError, error: featureFlagError } = useFeatureFlags()
    if (isLoading) {
        return <Loader center medium title={localization.getString("loading")} />
    }

    if (isError) {
        return <Banner label={String(featureFlagError)} variant={Variant.ERROR} />
    }
    const getTabProps = (route: ThirdLevelRoute): TabProps => ({
        key: route,
        label: localization.getString(thirdLevelLabels[route]),
        to: route,
        active: doesRouteMatch<ThirdLevelRoute>(route, location),
    })

    const registeredDomainsView = featureFlags.adminConsole.doShowUpsellForRegisteredDomain
        ? Upsell
        : RegisteredDomains

    return (
        <div className={styles.container}>
            <NavigationTabBar tabs={thirdLevelRoutes} getTabProps={getTabProps} />
            <Switch>
                <Route path={ROUTE.REGISTERED_DOMAINS} component={registeredDomainsView} />
                <Route path={ROUTE.ISSUED_CERTIFICATES}>
                    <IssuedCertificates
                        canDeleteIssuedCertificate={
                            featureFlags.adminConsole.canDeleteIssuedCertificate
                        }
                    />
                </Route>
                <Redirect to={ROUTE.REGISTERED_DOMAINS} />
            </Switch>
        </div>
    )
}

const thirdLevelRoutes = [ROUTE.REGISTERED_DOMAINS, ROUTE.ISSUED_CERTIFICATES] satisfies ROUTE[]

type ThirdLevelRoute = (typeof thirdLevelRoutes)[number]

const thirdLevelLabels: Record<ThirdLevelRoute, LanguageKey> = {
    [ROUTE.REGISTERED_DOMAINS]: "registeredDomains",
    [ROUTE.ISSUED_CERTIFICATES]: "issuedCertificates",
}
