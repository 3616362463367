import React from "react"

import { useServiceLocalization } from "../../../../../pre-v3/services/localization/Localization.service"
import { DateUtil } from "../../../../../pre-v3/utils/Date.util"
import { encodeID } from "../../../../../pre-v3/utils/Url.util"
import { formatRoutePath, ROUTE } from "../../../../../routes"
import {
    Column,
    Grid,
    sortAlphabetically,
    sortByTimeStamp,
} from "../../../../components/grid/Grid.component"
import { RowTitle } from "../../../../components/grid/RowTitle.component"
import { Service, ServiceType } from "../../../../services/Connector.service"

interface Props {
    services: Service[]
}

export function ConnectorServices(props: Props): JSX.Element {
    const columns = useColumns()

    return <Grid data={props.services} columns={columns} />
}

function useColumns(): Column<Service>[] {
    const localization = useServiceLocalization()

    return React.useMemo(
        (): Column<Service>[] => [
            {
                id: "name",
                name: localization.getString("name"),
                cellRenderer: renderName,
                getTooltipValue: "name",
                sorting: sortAlphabetically("name"),
            },
            {
                id: "updatedAt",
                name: localization.getString("lastUpdated"),
                cellRenderer: formatUpdatedAt,
                getTooltipValue: formatUpdatedAt,
                sorting: sortByTimeStamp("updatedAt"),
            },
        ],
        [localization]
    )
}

function renderName(service: Service): JSX.Element {
    return <RowTitle title={service.name} route={getRoute(service)} />
}

function getRoute(service: Service): ROUTE | undefined {
    if (!service.type) return undefined

    const targetPath =
        service.type === ServiceType.WEB
            ? ROUTE.HOSTED_WEBSITES_DETAILS
            : ROUTE.INFRASTRUCTURE_DETAILS

    return formatRoutePath(targetPath, { id: encodeID(service.id) })
}

function formatUpdatedAt(service: Service): string {
    return DateUtil.format(service.updatedAt)
}
