import {
    QueryKey,
    UseMutationResult,
    UseQueryResult,
    useMutation,
    useQuery,
    useQueryClient,
} from "@tanstack/react-query"

import { LocalizationService } from "../../pre-v3/services/localization/Localization.service"
import { LanguageKey } from "../../pre-v3/services/localization/languages/en-US.language"
import { CollectionUtil } from "../../pre-v3/utils/Collection.util"
import { TrustIntegrationRes, TrustIntegrationApi } from "../api/TrustIntegration.api"
import {
    AppCheckFactorRes,
    AssignmentCriteriaRes,
    BanyanAppVersionsRes,
    DeviceOwnershipRes,
    FactorConfigRes,
    NewTrustProfileReq,
    PlatformRes,
    PreferredAppConfigRes,
    ProfilePriorityPairReq,
    TrustFactorRes,
    TrustLevelRes,
    TrustProfileApi,
    TrustProfileRes,
} from "../api/TrustProfile.api"
import {
    CrowdStrikeIntegration,
    SentinelOneIntegration,
    TrustIntegration,
    WorkspaceOneIntegration,
} from "./TrustIntegration.service"
import { DateUtil } from "../../pre-v3/utils/Date.util"
import { Cache, CacheBuster } from "../../pre-v3/decorators/Cache.decorator"
import { Singleton } from "../../pre-v3/decorators/Singleton.decorator"
import { DeviceOwnership, noDeviceOwnership } from "./shared/DeviceOwnership"
import {
    DesktopPlatform,
    Platform,
    mergeApplicablePlatforms,
    noPlatforms,
    onlyDesktopPlatforms,
    onlyMac,
    onlyWindows,
} from "./shared/Platform"
import {
    TrustFactorType,
    isTrustFactorType,
    nameDictionary,
    descriptionDictionary,
} from "./shared/TrustFactorType"
import { NewPlist, NewRegistryCheck, TrustFactorService } from "./TrustFactor.service"

@Singleton("TrustProfileService")
export class TrustProfileService {
    private trustFactorService = new TrustFactorService()
    private trustIntegrationApi = new TrustIntegrationApi()
    private trustProfileApi = new TrustProfileApi()
    private localizationService = new LocalizationService()

    @Cache()
    public async getTrustProfiles(cacheBuster?: CacheBuster): Promise<TrustProfiles> {
        const trustProfiles = await this.trustProfileApi.getTrustProfiles()

        try {
            return this.mapTrustProfiles(trustProfiles)
        } catch (error) {
            console.error(error)
            throw this.localizationService.getString("failedToFetchTrustProfiles")
        }
    }

    public async reorderTrustProfiles(trustProfiles: TrustProfile[]): Promise<TrustProfiles> {
        const updatedTrustProfiles = await this.trustProfileApi.reorderTrustProfiles({
            trust_profile_priorities: trustProfiles.map(mapPair),
        })

        try {
            return this.mapTrustProfiles(updatedTrustProfiles)
        } catch (error) {
            console.error(error)
            throw this.localizationService.getString("failedToReorderTrustProfiles")
        }
    }

    public async createTrustProfile(
        trustProfile: NewTrustProfileDetail
    ): Promise<TrustProfileDetail> {
        const allTrustFactors = await this.trustProfileApi.getTrustFactors()
        const factorsDict = allTrustFactors.reduce(reduceFactorsDict, {})
        const emptyFactors: TrustFactorRes[] = []

        const request: NewTrustProfileReq = {
            ...detailsAndAssignmentRes(trustProfile),
            profile_factors: trustProfile.trustFactors.reduce(
                (acc, trustFactor) => reduceTrustFactorsRes(factorsDict, acc, trustFactor),
                emptyFactors
            ),
        }

        const [newTrustProfile, integrations, versions] = await Promise.all([
            this.trustProfileApi.createTrustProfile(request),
            this.trustIntegrationApi.getTrustIntegrations(),
            this.trustProfileApi.getBanyanAppVersions(),
        ])

        return this.mapTrustProfileDetail(
            newTrustProfile,
            integrations,
            getDefaultBanyanAppVersions(versions)
        )
    }

    public async updateDetailsAndAssignment(
        trustProfile: UpdateTrustProfile
    ): Promise<TrustProfileDetail> {
        const profileRes = await this.trustProfileApi.getTrustProfile(trustProfile.id)

        const [updatedProfileRes, integrations, versions] = await Promise.all([
            this.trustProfileApi.updateTrustProfile({
                ...updateDetailsAndAssignment(profileRes, trustProfile),
                profile_id: trustProfile.id,
                display_name: trustProfile.name,
                description: trustProfile.description ?? "",
            }),
            this.trustIntegrationApi.getTrustIntegrations(),
            this.trustProfileApi.getBanyanAppVersions(),
        ])

        return this.mapTrustProfileDetail(
            updatedProfileRes,
            integrations,
            getDefaultBanyanAppVersions(versions)
        )
    }

    public async getTrustProfile(id?: string): Promise<TrustProfileDetail> {
        const [trustProfile, integrations, versions] = await Promise.all([
            id
                ? this.trustProfileApi.getTrustProfile(id)
                : this.trustProfileApi.getDefaultTrustProfile(),
            this.trustIntegrationApi.getTrustIntegrations(),
            this.trustProfileApi.getBanyanAppVersions(),
        ])

        return this.mapTrustProfileDetail(
            trustProfile,
            integrations,
            getDefaultBanyanAppVersions(versions)
        )
    }

    public async updateTrustProfileFactors(
        trustProfileId: string,
        trustFactors: TrustFactor[]
    ): Promise<TrustProfileDetail> {
        const profileRes = await this.trustProfileApi.getTrustProfile(trustProfileId)
        const emptyFactors: TrustFactorRes[] = []

        const profileResWithUpdates: TrustProfileRes = {
            ...profileRes,
            profile_factors: trustFactors.reduce(
                (acc, trustFactor) =>
                    reduceTrustFactorsRes(getFactorsDict(profileRes), acc, trustFactor),
                emptyFactors
            ),
        }

        const [updatedProfileRes, integrations, versions] = await Promise.all([
            this.trustProfileApi.updateTrustProfile(profileResWithUpdates),
            this.trustIntegrationApi.getTrustIntegrations(),
            this.trustProfileApi.getBanyanAppVersions(),
        ])

        return this.mapTrustProfileDetail(
            updatedProfileRes,
            integrations,
            getDefaultBanyanAppVersions(versions)
        )
    }

    public async updateTrustProfileEffect(
        profileId: string,
        factorId: string,
        effect: TRUST_EFFECT
    ): Promise<TrustProfileDetail> {
        const profileRes: TrustProfileRes = await this.trustProfileApi.getDefaultTrustProfile()
        const factor: TrustFactorRes | undefined = profileRes.profile_factors?.find(
            (f) => f.id === factorId
        )
        if (factor) {
            if (effect === TRUST_EFFECT.NOT_EVALUATED) {
                factor.active = false
            } else {
                factor.active = true
                factor.effect = {
                    trust_level: trustEffectToTrustLevel[effect],
                }
            }
        } else {
            return Promise.reject(
                this.localizationService.getString("errorTrustFactorNotFoundRefreshAndTryAgain")
            )
        }

        const [newProfileRes, integrations, versions] = await Promise.all([
            this.trustProfileApi.updateTrustProfile(profileRes),
            this.trustIntegrationApi.getTrustIntegrations(),
            this.trustProfileApi.getBanyanAppVersions(),
        ])

        return this.mapTrustProfileDetail(
            newProfileRes,
            integrations,
            getDefaultBanyanAppVersions(versions)
        )
    }

    public async updateTrustProfileOsVersion(
        profileId: string,
        config: Record<Exclude<Platform, Platform.CHROME>, number>
    ): Promise<TrustProfile> {
        const profileRes: TrustProfileRes = await this.trustProfileApi.getDefaultTrustProfile()
        const trustFactorRes: TrustFactorRes | undefined = profileRes.profile_factors?.find(
            (f) => f.name === TrustFactorType.OPERATING_SYSTEM_VERSION
        )
        if (trustFactorRes?.name === TrustFactorType.OPERATING_SYSTEM_VERSION) {
            trustFactorRes.config = [
                {
                    key: "uptodateos.android",
                    value: config[Platform.ANDROID].toString(),
                },
                {
                    key: "uptodateos.ios",
                    value: config[Platform.IOS].toString(),
                },
                {
                    key: "uptodateos.macos",
                    value: config[Platform.MACOS].toString(),
                },
                {
                    key: "uptodateos.windows",
                    value: config[Platform.WINDOWS].toString(),
                },
                {
                    key: "uptodateos.linux",
                    value: config[Platform.LINUX].toString(),
                },
                {
                    key: "uptodateos.ubuntu",
                    value: config[Platform.LINUX].toString(),
                },
                {
                    key: "uptodateos.fedora",
                    value: config[Platform.LINUX].toString(),
                },
                {
                    key: "uptodateos.centos",
                    value: config[Platform.LINUX].toString(),
                },
            ]
            const newProfileRes: TrustProfileRes =
                await this.trustProfileApi.updateTrustProfile(profileRes)
            return this.mapTrustProfile(newProfileRes)
        } else {
            return Promise.reject(
                this.localizationService.getString("errorTrustFactorNotFoundRefreshAndTryAgain")
            )
        }
    }

    public async updateTrustProfileAppCheck(
        profileId: string,
        config: AppCheckConfig[]
    ): Promise<TrustProfile> {
        const profileRes: TrustProfileRes = await this.trustProfileApi.getDefaultTrustProfile()
        const trustFactorRes: TrustFactorRes | undefined = profileRes.profile_factors?.find(
            (f) => f.name === TrustFactorType.APPLICATION_CHECK
        )
        if (trustFactorRes && trustFactorRes.name === TrustFactorType.APPLICATION_CHECK) {
            const appCheckFactorRes: AppCheckFactorRes = trustFactorRes as AppCheckFactorRes
            appCheckFactorRes.preferred_apps_config = []
            for (const app of config) {
                if (app.processName[Platform.MACOS]) {
                    appCheckFactorRes.preferred_apps_config.push({
                        name: app.name,
                        mandatory: app.mandatory,
                        platform: "macos",
                        process: app.processName[Platform.MACOS],
                    })
                }
                if (app.processName[Platform.WINDOWS]) {
                    appCheckFactorRes.preferred_apps_config.push({
                        name: app.name,
                        mandatory: app.mandatory,
                        platform: "windows",
                        process: app.processName[Platform.WINDOWS],
                    })
                }
                if (app.processName[Platform.LINUX]) {
                    appCheckFactorRes.preferred_apps_config.push({
                        name: app.name,
                        mandatory: app.mandatory,
                        platform: "linux",
                        process: app.processName[Platform.LINUX],
                    })
                }
            }
            const newProfileRes: TrustProfileRes =
                await this.trustProfileApi.updateTrustProfile(profileRes)
            return this.mapTrustProfile(newProfileRes)
        } else {
            return Promise.reject(
                this.localizationService.getString("errorTrustFactorNotFoundRefreshAndTryAgain")
            )
        }
    }

    public updateTrustProfileIntegration(
        profileId: string,
        integration: TrustIntegration
    ): Promise<TrustProfile> {
        if (integration.integrationPartner === "crowdstrike") {
            return this.updateTrustProfileCrowdStrike(
                profileId,
                integration as CrowdStrikeIntegration
            )
        } else if (integration.integrationPartner === "sentinelone") {
            return this.updateTrustProfileSentinelOne(
                profileId,
                integration as SentinelOneIntegration
            )
        } else if (integration.integrationPartner === "workspaceone") {
            return this.updateTrustProfileWorkspaceOne(
                profileId,
                integration as WorkspaceOneIntegration
            )
        } else {
            return Promise.reject(this.localizationService.getString("errorUnknownIntegrationType"))
        }
    }

    public async removeTrustProfileIntegration(
        profileId: string,
        integrationId: string
    ): Promise<TrustProfile> {
        const profileRes: TrustProfileRes = await this.trustProfileApi.getDefaultTrustProfile()
        const updatedFactors: TrustFactorRes[] = profileRes.profile_factors.filter(
            (f) => f.source_id !== integrationId
        )
        profileRes.profile_factors = updatedFactors
        const newProfileRes: TrustProfileRes =
            await this.trustProfileApi.updateTrustProfile(profileRes)
        return this.mapTrustProfile(newProfileRes)
    }

    public async deleteTrustProfile(trustProfileId: string): Promise<void> {
        await this.trustProfileApi.deleteTrustProfile({ profile_id: trustProfileId })

        // The BE doesn't reassign priorities after deleting, so we after to do it here
        const trustProfiles = await this.trustProfileApi.getTrustProfiles()
        await this.trustProfileApi.reorderTrustProfiles({
            trust_profile_priorities: [...trustProfiles]
                .sort(compareTrustProfile)
                .map(({ profile_id }, index) => ({
                    profile_id,
                    priority: index + 1,
                })),
        })
    }

    public async getTrustFactors(): Promise<TrustFactor[]> {
        const [trustFactors, integrations, versions] = await Promise.all([
            this.trustProfileApi.getTrustFactors(),
            this.trustIntegrationApi.getTrustIntegrations(),
            this.trustProfileApi.getBanyanAppVersions(),
        ])

        // We need to combine the different configurable Trust Factors to show
        // them as one in the UI.
        const { nonConfigurableTrustFactors, fileCheckInfo, plistInfo, registryCheckInfo } =
            trustFactors.reduce(
                (acc: TrustFactorAcc, trustFactorRes: TrustFactorRes): TrustFactorAcc =>
                    this.reduceTrustFactorToCombine(
                        acc,
                        trustFactorRes.name === TrustFactorType.OPERATING_SYSTEM_VERSION
                            ? { ...trustFactorRes, config: [] }
                            : trustFactorRes,
                        integrations,
                        getDefaultBanyanAppVersions(versions)
                    ),
                emptyTrustFactorAcc
            )

        const fileCheck: FileCheckTrustFactor = {
            ...this.getFileCheckTrustFactorInfo(),
            ...fileCheckInfo,
            effect: fileCheckInfo.effect ?? TRUST_EFFECT.NO_EFFECT,
            usedFiles: [],
            unusedFiles: fileCheckInfo.files.sort(this.compareFiles.bind(this)),
        }

        const plist: PlistTrustFactor = {
            ...this.getPlistTrustFactorInfo(),
            ...plistInfo,
            applicablePlatform: onlyMac,
            effect: plistInfo.effect ?? TRUST_EFFECT.NO_EFFECT,
            usedPlist: [],
            unusedPlist: plistInfo.plists.sort(this.comparePlists.bind(this)),
        }

        const registryCheck: RegistryCheckTrustFactor = {
            ...this.getRegistryCheckTrustFactorInfo(),
            ...registryCheckInfo,
            applicablePlatform: onlyWindows,
            effect: registryCheckInfo.effect ?? TRUST_EFFECT.NO_EFFECT,
            usedRegistryCheck: [],
            unusedRegistryCheck: registryCheckInfo.registryChecks.sort(
                this.compareRegistryChecks.bind(this)
            ),
        }

        return [...nonConfigurableTrustFactors, fileCheck, plist, registryCheck].sort(
            this.compareTrustFactor.bind(this)
        )
    }

    public async createFileCheckTrustFactor(file: NewFile): Promise<File> {
        const fileCheck = await this.trustFactorService.createFileCheckTrustFactor(file)
        return { ...fileCheck, factorId: fileCheck.id }
    }

    public async updateFileCheckTrustFactor(file: File): Promise<File> {
        const fileCheck = await this.trustFactorService.updateFileCheckTrustFactor({
            ...file,
            id: file.factorId,
        })

        return { ...fileCheck, id: file.id, factorId: fileCheck.id }
    }

    public async createPlistTrustFactor(plist: NewPlist): Promise<Plist> {
        const updatedPlist = await this.trustFactorService.createPlistTrustFactor(plist)

        return { ...updatedPlist, factorId: updatedPlist.id }
    }

    public async updatePlistTrustFactor(plist: Plist): Promise<Plist> {
        const updatedPlist = await this.trustFactorService.updatePlistTrustFactor({
            ...plist,
            id: plist.factorId,
        })

        return { ...updatedPlist, id: plist.id, factorId: updatedPlist.id }
    }

    public async createRegistryCheckTrustFactor(
        registryCheck: NewRegistryCheck
    ): Promise<RegistryCheck> {
        const updatedRegistryCheck =
            await this.trustFactorService.createRegistryCheckTrustFactor(registryCheck)

        return { ...updatedRegistryCheck, factorId: updatedRegistryCheck.id }
    }

    public async updateRegistryCheckTrustFactor(
        registryCheck: RegistryCheck
    ): Promise<RegistryCheck> {
        const updatedRegistryCheck = await this.trustFactorService.updateRegistryCheckTrustFactor({
            ...registryCheck,
            id: registryCheck.factorId,
        })

        return { ...updatedRegistryCheck, id: registryCheck.id, factorId: updatedRegistryCheck.id }
    }

    private mapTrustProfiles(trustProfiles: TrustProfileRes[]): TrustProfiles {
        const { defaultTrustProfile, userTrustProfiles, deviceCountLastUpdated } =
            separateDefaultProfile(trustProfiles)

        return {
            sortedTrustProfiles: this.sortMapTrustProfiles(userTrustProfiles),
            defaultTrustProfile: this.mapTrustProfile(defaultTrustProfile),
            deviceCountLastUpdated,
        }
    }

    private sortMapTrustProfiles(trustProfiles: TrustProfileRes[]): TrustProfileDetail[] {
        return [...trustProfiles].sort(compareTrustProfile).map(this.mapTrustProfile.bind(this))
    }

    private mapTrustProfile(trustProfile: TrustProfileRes): TrustProfile {
        return {
            id: trustProfile.profile_id,
            name: trustProfile.display_name,
            // Turn empty strings into undefined
            description: trustProfile.description || undefined,
            appliedPlatform:
                trustProfile.applied_platforms?.reduce(reducePlatforms, noPlatforms) ?? noPlatforms,
            deviceCount: trustProfile.extra_details.device_count,
        }
    }

    private mapTrustProfileDetail(
        trustProfile: TrustProfileRes,
        integrations: TrustIntegrationRes[],
        defaultBanyanAppVersions: Record<DesktopPlatform, string>
    ): TrustProfileDetail {
        // We need to combine the different configurable Trust Factors to show
        // them as one in the UI.
        const {
            nonConfigurableTrustFactors: usedNonConfigurableTrustFactors,
            fileCheckInfo: usedFileCheckInfo,
            plistInfo: usedPlistInfo,
            registryCheckInfo: usedRegistryCheckInfo,
        } = trustProfile.profile_factors.reduce(
            (acc: TrustFactorAcc, trustFactorRes: TrustFactorRes): TrustFactorAcc =>
                this.reduceTrustFactorToCombine(
                    acc,
                    trustFactorRes,
                    integrations,
                    defaultBanyanAppVersions
                ),
            emptyTrustFactorAcc
        )

        const {
            nonConfigurableTrustFactors: unusedNonConfigurableTrustFactors,
            fileCheckInfo: unusedFileCheckInfo,
            plistInfo: unusedPlistInfo,
            registryCheckInfo: unusedRegistryCheckInfo,
        } = trustProfile.unused_factors.reduce(
            (acc: TrustFactorAcc, trustFactorRes: TrustFactorRes): TrustFactorAcc =>
                this.reduceTrustFactorToCombine(
                    acc,
                    trustFactorRes,
                    integrations,
                    defaultBanyanAppVersions
                ),
            emptyTrustFactorAcc
        )

        const fileCheck: FileCheckTrustFactor = {
            ...this.getFileCheckTrustFactorInfo(),
            applicablePlatform: mergeApplicablePlatforms(
                usedFileCheckInfo.applicablePlatform,
                unusedFileCheckInfo.applicablePlatform
            ),
            effect: combineTrustEffect(usedFileCheckInfo.effect, unusedFileCheckInfo.effect),
            usedFiles: usedFileCheckInfo.files.sort(this.compareFiles.bind(this)),
            unusedFiles: unusedFileCheckInfo.files.sort(this.compareFiles.bind(this)),
        }

        const plist: PlistTrustFactor = {
            ...this.getPlistTrustFactorInfo(),
            applicablePlatform: onlyMac,
            effect: combineTrustEffect(usedPlistInfo.effect, unusedPlistInfo.effect),
            usedPlist: usedPlistInfo.plists.sort(this.comparePlists.bind(this)),
            unusedPlist: unusedPlistInfo.plists.sort(this.comparePlists.bind(this)),
        }

        const registryCheck: RegistryCheckTrustFactor = {
            ...this.getRegistryCheckTrustFactorInfo(),
            applicablePlatform: onlyWindows,
            effect: combineTrustEffect(
                usedRegistryCheckInfo.effect,
                unusedRegistryCheckInfo.effect
            ),
            usedRegistryCheck: usedRegistryCheckInfo.registryChecks.sort(
                this.compareRegistryChecks.bind(this)
            ),
            unusedRegistryCheck: unusedRegistryCheckInfo.registryChecks.sort(
                this.compareRegistryChecks.bind(this)
            ),
        }

        const hasUsedFiles = fileCheck.usedFiles.length > 0
        const hasUsedPlist = plist.usedPlist.length > 0
        const hasUsedRegistryCheck = registryCheck.usedRegistryCheck.length > 0

        return {
            ...this.mapTrustProfile(trustProfile),
            isDefaultProfile: trustProfile.profile_name === TrustProfileName.DEFAULT,
            priority: trustProfile.priority,
            createdBy: trustProfile.created_by || undefined,
            createdAt: new Date(DateUtil.convertLargeTimestamp(trustProfile.created_at)),
            lastUpdatedBy: trustProfile.updated_by || undefined,
            lastUpdatedAt: new Date(DateUtil.convertLargeTimestamp(trustProfile.updated_at)),
            assignment: mapAssignment(trustProfile.assignment_criteria),
            trustFactors: [
                ...usedNonConfigurableTrustFactors,
                ...(hasUsedFiles ? [fileCheck] : []),
                ...(hasUsedPlist ? [plist] : []),
                ...(hasUsedRegistryCheck ? [registryCheck] : []),
            ].sort(this.compareTrustFactor.bind(this)),
            unusedTrustFactors: [
                ...unusedNonConfigurableTrustFactors,
                ...(!hasUsedFiles ? [fileCheck] : []),
                ...(!hasUsedPlist ? [plist] : []),
                ...(!hasUsedRegistryCheck ? [registryCheck] : []),
            ].sort(this.compareTrustFactor.bind(this)),
        }
    }

    private reduceTrustFactorToCombine(
        acc: TrustFactorAcc,
        trustFactorRes: TrustFactorRes,
        integrations: TrustIntegrationRes[],
        defaultBanyanAppVersions: Record<DesktopPlatform, string>
    ): TrustFactorAcc {
        const { source } = trustFactorRes
        if (source === TrustFactorType.PLIST) return addPlistToAcc(trustFactorRes, acc)
        if (source === TrustFactorType.FILE_CHECK) return addFileToAcc(trustFactorRes, acc)
        if (source === TrustFactorType.REGISTRY_CHECK) {
            return addRegistryCheckToAcc(trustFactorRes, acc)
        }

        return {
            ...acc,
            nonConfigurableTrustFactors: [
                ...acc.nonConfigurableTrustFactors,
                this.mapNonConfigurableTrustFactor(
                    trustFactorRes,
                    integrations,
                    defaultBanyanAppVersions
                ),
            ],
        }
    }

    private mapNonConfigurableTrustFactor(
        factorRes: TrustFactorRes,
        integrations: TrustIntegrationRes[],
        defaultBanyanAppVersions: Record<DesktopPlatform, string>
    ): TrustFactor {
        const factor: TrustFactor = {
            ...this.getFactorLabelAndDescription(factorRes),
            id: factorRes.id || factorRes.factor_id,
            name: factorRes.name,
            source: this.getTrustSource(factorRes, integrations),
            applicablePlatform: factorRes.applicable_platforms.reduce(reducePlatforms, noPlatforms),
            effect: getTrustEffect(factorRes),
        }

        if (factor.name === TrustFactorType.BANYAN_APP_VERSION) {
            const banyanAppVersion: BanyanAppVersionTrustFactor = {
                ...factor,
                name: TrustFactorType.BANYAN_APP_VERSION,
                configuration:
                    factorRes.config?.reduce(
                        reduceBanyanAppVersionConfiguration,
                        defaultBanyanAppVersions
                    ) ?? defaultBanyanAppVersions,
            }

            return banyanAppVersion
        }

        if (factor.name === TrustFactorType.ZTA_SCORE) {
            const maybeSeverity = factorRes.config?.find(({ key }) => key === "expected.value")
                ?.value

            const ztaScore: ZtaScoreTrustFactor = {
                ...factor,
                name: TrustFactorType.ZTA_SCORE,
                severity:
                    (maybeSeverity && severityFromResDict[maybeSeverity]) || Severity.MODERATE,
            }

            return ztaScore
        }

        if (factor.name === TrustFactorType.OPERATING_SYSTEM_VERSION) {
            const osFactor: OsVersionFactor = {
                ...factor,
                name: TrustFactorType.OPERATING_SYSTEM_VERSION,
                config: mapTrustFactorResToOsVersionConfig(factorRes),
            }

            return osFactor
        } else if (factor.name === TrustFactorType.APPLICATION_CHECK) {
            const appFactor: AppCheckFactor = {
                ...factor,
                name: TrustFactorType.APPLICATION_CHECK,
                config: mapTrustFactorResToAppCheckConfig(factorRes as AppCheckFactorRes),
            }

            return appFactor
        }

        if (factor.name === TrustFactorType.CHROME_BROWSER_VERSION) {
            const osFactor: ChromeBrowserVersionFactor = {
                ...factor,
                name: TrustFactorType.CHROME_BROWSER_VERSION,
                config: mapBrowserVersion(factorRes),
            }

            return osFactor
        }

        if (factor.name === TrustFactorType.DEVICE_GEOLOCATION) {
            const geolocationFactor: GeolocationTrustFactor = {
                ...factor,
                name: TrustFactorType.DEVICE_GEOLOCATION,
                config: mapGeoLocationFactor(factorRes),
            }

            return geolocationFactor
        }

        return factor
    }

    private getTrustSource(
        factor: TrustFactorRes,
        integrations: TrustIntegrationRes[]
    ): TrustSource {
        const banyanLabel = this.localizationService.getString("sonicWallCse")

        if (factor.type !== "integration")
            return { type: TrustSourceType.BANYAN, name: banyanLabel }

        const integration = integrations.find(
            (i) => i.id === factor.source_id || i.name === factor.source
        )

        if (!integration) {
            console.warn(
                `The Trust Factor named "${factor.name}" doesn't have an accompanying Integration named "${factor.source}"`
            )
            return { type: TrustSourceType.BANYAN, name: banyanLabel }
        }

        return {
            type: partnerDict[integration.integration_partner],
            name: integration.name,
            description: integration.description || undefined,
            integrationId: integration.id,
        }
    }

    private compareTrustFactor(trustFactorA: TrustFactor, trustFactorB: TrustFactor): number {
        const locale = this.localizationService.getLocale()

        const compareSource = trustFactorA.source.type.localeCompare(
            trustFactorB.source.type,
            locale
        )

        if (compareSource !== 0) return compareSource

        const compareSourceName = trustFactorA.source.name.localeCompare(
            trustFactorB.source.name,
            locale
        )

        if (compareSourceName !== 0) return compareSourceName

        return trustFactorA.label.localeCompare(trustFactorB.label, locale)
    }

    private compareFiles(fileA: File, fileB: File): number {
        return compareFiles(this.localizationService.getLocale(), fileA, fileB)
    }

    private comparePlists(plistA: Plist, plistB: Plist): number {
        return comparePlist(this.localizationService.getLocale(), plistA, plistB)
    }

    private compareRegistryChecks(
        registryCheckA: RegistryCheck,
        registryCheckB: RegistryCheck
    ): number {
        return compareRegistryCheck(
            this.localizationService.getLocale(),
            registryCheckA,
            registryCheckB
        )
    }

    private async updateTrustProfileCrowdStrike(
        profileId: string,
        integration: CrowdStrikeIntegration
    ): Promise<TrustProfile> {
        const profileRes: TrustProfileRes = await this.trustProfileApi.getDefaultTrustProfile()
        let isNewFactor: boolean = false
        let existing: TrustFactorRes | undefined = profileRes.profile_factors?.find(
            (f) => f.source_id === integration.id
        ) // is integration currently part of this profile?
        if (!existing) {
            existing = profileRes.unused_factors?.find((f) => f.source_id === integration.id)
            isNewFactor = true
        } // if not, is it available to this profile?

        if (existing) {
            // if found, update the properties
            const ztaScore = integration.factors.find((f) => f.name === TrustFactorType.ZTA_SCORE)
            existing.config = [
                {
                    key: "expected.value",
                    value: ztaScore?.severity.toString() || "65",
                },
                {
                    key: "targetplatform.macos",
                    value: ztaScore?.platforms.includes("macos") ? "y" : "n",
                },
                {
                    key: "targetplatform.windows",
                    value: ztaScore?.platforms.includes("windows") ? "y" : "n",
                },
                {
                    key: "targetplatform.linux",
                    value: ztaScore?.platforms.includes("linux") ? "y" : "n",
                },
            ]

            if (isNewFactor) {
                existing.active = true
                profileRes.profile_factors.push(existing)
            }
        } else {
            // otherwise throw an error
            return Promise.reject(
                this.localizationService.getString("errorTrustFactorNotFoundRefreshAndTryAgain")
            )
        }

        const newProfileRes: TrustProfileRes =
            await this.trustProfileApi.updateTrustProfile(profileRes)
        return this.mapTrustProfile(newProfileRes)
    }

    private async updateTrustProfileSentinelOne(
        profileId: string,
        integration: SentinelOneIntegration
    ): Promise<TrustProfile> {
        const profileRes: TrustProfileRes = await this.trustProfileApi.getDefaultTrustProfile()

        let existingActiveThreat: TrustFactorRes | undefined = profileRes.profile_factors?.find(
            (f) => f.source_id === integration.id && f.name === TrustFactorType.NOT_ACTIVE_THREAT
        )
        let isNewActiveThreat: boolean = false
        let existingRegisteredWith: TrustFactorRes | undefined = profileRes.profile_factors?.find(
            (f) => f.source_id === integration.id && f.name === TrustFactorType.REGISTERED_WITH
        )
        let isNewRegisteredWith: boolean = false

        if (!existingActiveThreat) {
            existingActiveThreat = profileRes.unused_factors?.find(
                (f) =>
                    f.source_id === integration.id && f.name === TrustFactorType.NOT_ACTIVE_THREAT
            )
            isNewActiveThreat = true
        }
        if (!existingRegisteredWith) {
            existingRegisteredWith = profileRes.unused_factors?.find(
                (f) => f.source_id === integration.id && f.name === TrustFactorType.REGISTERED_WITH
            )
            isNewRegisteredWith = true
        }

        if (existingActiveThreat) {
            const activeThreat = integration.factors.find(
                (f) => f.name === TrustFactorType.NOT_ACTIVE_THREAT
            )
            existingActiveThreat.config = [
                {
                    key: "targetplatform.macos",
                    value: activeThreat?.platforms.includes("macos") ? "y" : "n",
                },
                {
                    key: "targetplatform.windows",
                    value: activeThreat?.platforms.includes("windows") ? "y" : "n",
                },
                {
                    key: "targetplatform.linux",
                    value: activeThreat?.platforms.includes("linux") ? "y" : "n",
                },
            ]

            if (isNewActiveThreat) {
                existingActiveThreat.active = true
                profileRes.profile_factors.push(existingActiveThreat)
            }
        }

        if (existingRegisteredWith) {
            const activeThreat = integration.factors.find(
                (f) => f.name === TrustFactorType.REGISTERED_WITH
            )
            existingRegisteredWith.config = [
                {
                    key: "targetplatform.macos",
                    value: activeThreat?.platforms.includes("macos") ? "y" : "n",
                },
                {
                    key: "targetplatform.windows",
                    value: activeThreat?.platforms.includes("windows") ? "y" : "n",
                },
                {
                    key: "targetplatform.linux",
                    value: activeThreat?.platforms.includes("linux") ? "y" : "n",
                },
            ]

            if (isNewRegisteredWith) {
                existingRegisteredWith.active = true
                profileRes.profile_factors.push(existingRegisteredWith)
            }
        }

        const newProfileRes: TrustProfileRes =
            await this.trustProfileApi.updateTrustProfile(profileRes)
        return this.mapTrustProfile(newProfileRes)
    }

    private async updateTrustProfileWorkspaceOne(
        profileId: string,
        integration: WorkspaceOneIntegration
    ): Promise<TrustProfile> {
        const profileRes: TrustProfileRes = await this.trustProfileApi.getDefaultTrustProfile()

        let existingIsCompliant: TrustFactorRes | undefined = profileRes.profile_factors?.find(
            (f) => f.source_id === integration.id && f.name === TrustFactorType.WS1_IS_COMPLIANT
        )
        let isNewCompliantWith: boolean = false
        let existingRegisteredWith: TrustFactorRes | undefined = profileRes.profile_factors?.find(
            (f) => f.source_id === integration.id && f.name === TrustFactorType.WS1_REGISTERED_WITH
        )
        let isNewRegisteredWith: boolean = false

        if (!existingIsCompliant) {
            existingIsCompliant = profileRes.unused_factors?.find(
                (f) => f.source_id === integration.id && f.name === TrustFactorType.WS1_IS_COMPLIANT
            )
            isNewCompliantWith = true
        }
        if (!existingRegisteredWith) {
            existingRegisteredWith = profileRes.unused_factors?.find(
                (f) =>
                    f.source_id === integration.id && f.name === TrustFactorType.WS1_REGISTERED_WITH
            )
            isNewRegisteredWith = true
        }

        if (existingIsCompliant) {
            const isCompliant = integration.factors.find(
                (f) => f.name === TrustFactorType.WS1_IS_COMPLIANT
            )
            existingIsCompliant.config = [
                {
                    key: "targetplatform.macos",
                    value: isCompliant?.platforms.includes("macos") ? "y" : "n",
                },
                {
                    key: "targetplatform.windows",
                    value: isCompliant?.platforms.includes("windows") ? "y" : "n",
                },
                {
                    key: "targetplatform.linux",
                    value: isCompliant?.platforms.includes("linux") ? "y" : "n",
                },
            ]

            if (isNewCompliantWith) {
                existingIsCompliant.active = true
                profileRes.profile_factors.push(existingIsCompliant)
            }
        }

        if (existingRegisteredWith) {
            const activeThreat = integration.factors.find(
                (f) => f.name === TrustFactorType.WS1_REGISTERED_WITH
            )
            existingRegisteredWith.config = [
                {
                    key: "targetplatform.macos",
                    value: activeThreat?.platforms.includes("macos") ? "y" : "n",
                },
                {
                    key: "targetplatform.windows",
                    value: activeThreat?.platforms.includes("windows") ? "y" : "n",
                },
                {
                    key: "targetplatform.linux",
                    value: activeThreat?.platforms.includes("linux") ? "y" : "n",
                },
            ]

            if (isNewRegisteredWith) {
                existingRegisteredWith.active = true
                profileRes.profile_factors.push(existingRegisteredWith)
            }
        }

        const newProfileRes: TrustProfileRes =
            await this.trustProfileApi.updateTrustProfile(profileRes)
        return this.mapTrustProfile(newProfileRes)
    }

    private getFactorLabelAndDescription(factorRes: TrustFactorRes): {
        label: string
        description: string
    } {
        if (!isTrustFactorType(factorRes.name)) {
            return { label: factorRes.display_name, description: factorRes.description }
        }

        return {
            label: this.localizationService.getString(nameDictionary[factorRes.name]),
            description: this.localizationService.getString(descriptionDictionary[factorRes.name]),
        }
    }

    private getFileCheckTrustFactorInfo(): Pick<
        FileCheckTrustFactor,
        "id" | "name" | "label" | "description" | "source"
    > {
        const fileCheckType = TrustFactorType.FILE_CHECK
        return {
            id: fileCheckType,
            name: fileCheckType,
            label: this.localizationService.getString(nameDictionary[fileCheckType]),
            description: this.localizationService.getString(descriptionDictionary[fileCheckType]),
            source: {
                type: TrustSourceType.BANYAN,
                name: this.localizationService.getString("sonicWallCse"),
            },
        }
    }

    private getPlistTrustFactorInfo(): Pick<
        PlistTrustFactor,
        "id" | "name" | "label" | "description" | "source"
    > {
        const fileCheckType = TrustFactorType.PLIST

        return {
            id: fileCheckType,
            name: fileCheckType,
            label: this.localizationService.getString(nameDictionary[fileCheckType]),
            description: this.localizationService.getString(descriptionDictionary[fileCheckType]),
            source: {
                type: TrustSourceType.BANYAN,
                name: this.localizationService.getString("sonicWallCse"),
            },
        }
    }

    private getRegistryCheckTrustFactorInfo(): Pick<
        RegistryCheckTrustFactor,
        "id" | "name" | "label" | "description" | "source"
    > {
        const registryCheckType = TrustFactorType.REGISTRY_CHECK

        return {
            id: registryCheckType,
            name: registryCheckType,
            label: this.localizationService.getString(nameDictionary[registryCheckType]),
            description: this.localizationService.getString(
                descriptionDictionary[registryCheckType]
            ),
            source: {
                type: TrustSourceType.BANYAN,
                name: this.localizationService.getString("sonicWallCse"),
            },
        }
    }
}

enum TrustProfileHookKey {
    GET_TRUST_PROFILES = "trustProfileService.getTrustProfiles",
    GET_TRUST_PROFILE = "trustProfileService.getTrustProfile",
    GET_TRUST_FACTORS = "trustProfileService.getTrustFactors",
    GET_BANYAN_APP_VERSION = "trustProfileService.getBanyanAppVersions",
    GET_TRUST_LEVEL_EXPIRATION_IN_HOURS = "trustProfileService.getTrustLevelExpirationInHours",
}

const getTrustProfilesKey: QueryKey = [TrustProfileHookKey.GET_TRUST_PROFILES]

export function useGetTrustProfiles(
    options?: QueryOptions<TrustProfiles>
): UseQueryResult<TrustProfiles> {
    const trustProfileService = new TrustProfileService()

    return useQuery({
        ...options,
        queryKey: getTrustProfilesKey,
        queryFn: () => trustProfileService.getTrustProfiles({ force: true }),
    })
}

export function useReorderTrustProfiles(
    options?: QueryOptions<TrustProfiles, unknown, TrustProfile[]>
): UseMutationResult<TrustProfiles, unknown, TrustProfile[]> {
    const trustProfileService = new TrustProfileService()

    const queryClient = useQueryClient()

    return useMutation({
        ...options,
        mutationFn: (trustProfiles) => trustProfileService.reorderTrustProfiles(trustProfiles),
        onSuccess: (updatedTrustProfiles) => {
            queryClient.setQueryData(getTrustProfilesKey, updatedTrustProfiles)
            options?.onSuccess?.(updatedTrustProfiles)
        },
    })
}

export function useCreateTrustProfile(
    options?: QueryOptions<TrustProfileDetail, unknown, NewTrustProfileDetail>
): UseMutationResult<TrustProfileDetail, unknown, NewTrustProfileDetail> {
    const trustProfileService = new TrustProfileService()

    const queryClient = useQueryClient()

    return useMutation({
        ...options,
        mutationFn: (newTrustProfile) => trustProfileService.createTrustProfile(newTrustProfile),
        onSuccess: (trustProfile) => {
            queryClient.removeQueries(getTrustProfilesKey)
            options?.onSuccess?.(trustProfile)
        },
    })
}

function getTrustProfileKey(id = "default"): QueryKey {
    return [TrustProfileHookKey.GET_TRUST_PROFILE, id]
}

export function useGetTrustProfile(
    id?: string,
    options?: QueryOptions<TrustProfileDetail>
): UseQueryResult<TrustProfileDetail> {
    const trustProfileService = new TrustProfileService()

    return useQuery({
        ...options,
        queryKey: getTrustProfileKey(id),
        queryFn: () => trustProfileService.getTrustProfile(id),
    })
}

export function useUpdateDetailsAndAssignment(
    options?: QueryOptions<TrustProfileDetail, unknown, UpdateTrustProfile>
): UseMutationResult<TrustProfileDetail, unknown, UpdateTrustProfile> {
    const trustProfileService = new TrustProfileService()

    const queryClient = useQueryClient()

    return useMutation<TrustProfileDetail, unknown, UpdateTrustProfile>({
        ...options,
        mutationFn: (trustProfile) => trustProfileService.updateDetailsAndAssignment(trustProfile),
        onSuccess: (updatedTrustProfile) => {
            queryClient.setQueryData(
                getTrustProfileKey(updatedTrustProfile.id),
                updatedTrustProfile
            )
            queryClient.removeQueries(getTrustProfilesKey)
            options?.onSuccess?.(updatedTrustProfile)
        },
    })
}

export function useDeleteTrustProfile(
    id: string,
    options?: QueryOptions
): UseMutationResult<void, unknown, void> {
    const trustProfileService = new TrustProfileService()

    const queryClient = useQueryClient()

    return useMutation({
        ...options,
        mutationFn: () => trustProfileService.deleteTrustProfile(id),
        onSuccess: () => {
            queryClient.removeQueries(getTrustProfilesKey)
            queryClient.removeQueries(getTrustProfileKey(id))
            options?.onSuccess?.()
        },
    })
}

const getTrustFactorsKey: QueryKey = [TrustProfileHookKey.GET_TRUST_FACTORS]

export function useGetTrustFactors(
    options?: QueryOptions<TrustFactor[]>
): UseQueryResult<TrustFactor[]> {
    const trustProfileService = new TrustProfileService()

    return useQuery({
        ...options,
        queryKey: getTrustFactorsKey,
        queryFn: () => trustProfileService.getTrustFactors(),
    })
}

export function useUpdateTrustProfileFactors(
    trustProfile: TrustProfileDetail,
    options?: QueryOptions<TrustProfileDetail, unknown, TrustFactor[]>
): UseMutationResult<TrustProfileDetail, unknown, TrustFactor[]> {
    const trustProfileService = new TrustProfileService()

    const queryClient = useQueryClient()

    return useMutation({
        ...options,
        mutationFn: (trustFactors) =>
            trustProfileService.updateTrustProfileFactors(trustProfile.id, trustFactors),
        onSuccess: (updatedTrustProfile) => {
            queryClient.setQueryData(
                getTrustProfileKey(updatedTrustProfile.id),
                updatedTrustProfile
            )
            options?.onSuccess?.(updatedTrustProfile)
        },
    })
}

export function useUpdateTrustProfileEffect(
    id?: string,
    options?: QueryOptions<
        TrustProfileDetail,
        unknown,
        [TRUST_EFFECT, TrustFactor, TrustProfileDetail]
    >
): UseMutationResult<TrustProfileDetail, unknown, [TRUST_EFFECT, TrustFactor, TrustProfileDetail]> {
    const trustProfileService = new TrustProfileService()

    const queryClient = useQueryClient()

    return useMutation({
        ...options,
        mutationFn: ([effect, trustFactor, trustProfile]) =>
            trustProfileService.updateTrustProfileEffect(trustProfile.id, trustFactor.id, effect),
        onSuccess: (newTrustProfile) => {
            queryClient.setQueryData(getTrustProfileKey(id), newTrustProfile)
            options?.onSuccess?.(newTrustProfile)
        },
    })
}

export function useCreateFileCheckTrustFactor(
    options?: QueryOptions<File, unknown, NewFile>
): UseMutationResult<File, unknown, NewFile> {
    const trustProfileService = new TrustProfileService()

    return useMutation<File, unknown, NewFile>({
        ...options,
        mutationFn: (newFile) => trustProfileService.createFileCheckTrustFactor(newFile),
    })
}

export function useUpdateFileCheckTrustFactor(
    options?: QueryOptions<File, unknown, File>
): UseMutationResult<File, unknown, File> {
    const trustProfileService = new TrustProfileService()

    return useMutation<File, unknown, File>({
        ...options,
        mutationFn: (updatedFile) => trustProfileService.updateFileCheckTrustFactor(updatedFile),
    })
}

export function useCreatePropertyListCheckTrustFactor(
    options?: QueryOptions<Plist, unknown, NewPlist>
): UseMutationResult<Plist, unknown, NewPlist> {
    const trustProfileService = new TrustProfileService()

    return useMutation<Plist, unknown, NewPlist>({
        ...options,
        mutationFn: (newPropertyList) =>
            trustProfileService.createPlistTrustFactor(newPropertyList),
    })
}

export function useUpdatePropertyListCheckTrustFactor(
    options?: QueryOptions<Plist, unknown, Plist>
): UseMutationResult<Plist, unknown, Plist> {
    const trustProfileService = new TrustProfileService()

    return useMutation<Plist, unknown, Plist>({
        ...options,
        mutationFn: (updatedPropertyList) =>
            trustProfileService.updatePlistTrustFactor(updatedPropertyList),
    })
}

export function useCreateRegistryCheckTrustFactor(
    options?: QueryOptions<RegistryCheck, unknown, NewRegistryCheck>
): UseMutationResult<RegistryCheck, unknown, NewRegistryCheck> {
    const trustProfileService = new TrustProfileService()

    return useMutation<RegistryCheck, unknown, NewRegistryCheck>({
        ...options,
        mutationFn: (newRegistryCheck) =>
            trustProfileService.createRegistryCheckTrustFactor(newRegistryCheck),
    })
}

export function useUpdateRegistryCheckTrustFactor(
    options?: QueryOptions<RegistryCheck, unknown, RegistryCheck>
): UseMutationResult<RegistryCheck, unknown, RegistryCheck> {
    const trustProfileService = new TrustProfileService()

    return useMutation<RegistryCheck, unknown, RegistryCheck>({
        ...options,
        mutationFn: (updatedRegistryCheck) =>
            trustProfileService.updateRegistryCheckTrustFactor(updatedRegistryCheck),
    })
}

export function useGetBanyanAppVersions(
    options?: QueryOptions<Record<Platform, string[]>>
): UseQueryResult<Record<Platform, string[]>> {
    const trustProfileApi = new TrustProfileApi()

    return useQuery({
        ...options,
        queryKey: [TrustProfileHookKey.GET_BANYAN_APP_VERSION],
        queryFn: async () => {
            const versions = await trustProfileApi.getBanyanAppVersions()

            return {
                [Platform.MACOS]: versions.macos,
                [Platform.WINDOWS]: versions.windows,
                [Platform.LINUX]: versions.linux,
                [Platform.IOS]: versions.ios,
                [Platform.ANDROID]: versions.android,
            }
        },
    })
}

const getTrustLevelExpirationInHoursKey: QueryKey = [
    TrustProfileHookKey.GET_TRUST_LEVEL_EXPIRATION_IN_HOURS,
]

export function useGetTrustLevelExpirationInHours(
    options?: QueryOptions<number>
): UseQueryResult<number> {
    const trustProfileApi = new TrustProfileApi()

    return useQuery({
        ...options,
        queryKey: getTrustLevelExpirationInHoursKey,
        queryFn: async () => {
            const { ttl, units: unit } = await trustProfileApi.getTrustScoreTtl()
            const expiration = turnTtlIntoTrustLevelExpirationInHours(ttl, unit)

            if (typeof expiration === "string") return Promise.reject(expiration)

            return expiration
        },
    })
}

export function useSetTrustLevelExpirationInHours(
    options?: QueryOptions<void, unknown, number>
): UseMutationResult<void, unknown, number> {
    const trustProfileApi = new TrustProfileApi()

    const queryClient = useQueryClient()

    return useMutation({
        ...options,
        mutationFn: async (expiration) =>
            trustProfileApi.updateTrustScoreTtl({
                kind: "BanyanTrustscoreTTLConfig",
                apiVersion: "ts.banyanops.com/v1",
                spec: { ttl: expiration, units: "hours" },
            }),
        onSuccess: (_data, expiration) => {
            queryClient.setQueryData(getTrustLevelExpirationInHoursKey, expiration)
            options?.onSuccess?.()
        },
    })
}

export const MIGRATION_DATE = new Date("Feb 01 2023 PDT")
export const FAILED_TO_EDIT_EFFECT = TrustProfileApi.FAILED_TO_UPDATE_TRUST_PROFILE
export const FAILED_TO_SET_TRUST_LEVEL_EXPIRATION = "FAILED_TO_SET_TRUST_LEVEL_EXPIRATION" as const

interface TrustProfiles {
    sortedTrustProfiles: TrustProfile[]
    defaultTrustProfile: TrustProfile
    deviceCountLastUpdated: number
}

export function toTrustProfilesArray(trustProfiles: TrustProfiles): TrustProfile[] {
    return [...trustProfiles.sortedTrustProfiles, trustProfiles.defaultTrustProfile]
}

export interface TrustProfile {
    id: string
    name: string
    description?: string
    appliedPlatform: Record<Platform, boolean>
    deviceCount: number
}

// A Default Profile doesn't have neither createdBy nor lastUpdatedBy
export interface TrustProfileDetail extends TrustProfile {
    isDefaultProfile: boolean
    priority: number
    createdBy?: string
    createdAt: Date
    lastUpdatedBy?: string
    lastUpdatedAt: Date
    assignment: Assignment
    trustFactors: TrustFactor[]
    unusedTrustFactors: TrustFactor[]
}

export interface NewTrustProfile extends Omit<TrustProfile, "id" | "deviceCount"> {
    assignment: Assignment
}

export interface NewTrustProfileDetail extends NewTrustProfile {
    trustFactors: TrustFactor[]
    unusedTrustFactors: TrustFactor[]
}

interface UpdateTrustProfile extends NewTrustProfile {
    id: string
}

interface Assignment {
    userGroups: string[]
    serialNumbers: string[]
    isManagedByMdm: boolean
    deviceOwnership: Record<DeviceOwnership, boolean>
}

export interface TrustFactor {
    id: string
    name: string
    label: string
    description: string
    source: TrustSource
    applicablePlatform: Record<Platform, boolean>
    effect: TRUST_EFFECT
}

export enum TrustSourceType {
    BANYAN = "BANYAN",
    CROWD_STRIKE = "CROWD_STRIKE",
    SENTINEL_ONE = "SENTINEL_ONE",
    WORKSPACE_ONE = "WORKSPACE_ONE",
}

type TrustSource = BanyanTrustSource | IntegrationTrustSource

interface BanyanTrustSource {
    type: TrustSourceType.BANYAN
    name: string
}

export type IntegrationPartner =
    | TrustSourceType.CROWD_STRIKE
    | TrustSourceType.SENTINEL_ONE
    | TrustSourceType.WORKSPACE_ONE

export interface IntegrationTrustSource {
    type: IntegrationPartner
    name: string
    description?: string
    integrationId: string
}

export function isBanyanAppVersionTrustFactor(
    value: TrustFactor
): value is BanyanAppVersionTrustFactor {
    return value.name === TrustFactorType.BANYAN_APP_VERSION
}

export function isFileCheckTrustFactor(value: TrustFactor): value is FileCheckTrustFactor {
    return value.name === TrustFactorType.FILE_CHECK
}

export function isZtaScoreTrustFactor(value: TrustFactor): value is ZtaScoreTrustFactor {
    return value.name === TrustFactorType.ZTA_SCORE
}

export function isOsVersionTrustFactor(value: TrustFactor): value is OsVersionFactor {
    return value.name === TrustFactorType.OPERATING_SYSTEM_VERSION
}

export function isGeolocationTrustFactor(value: TrustFactor): value is GeolocationTrustFactor {
    return value.name === TrustFactorType.DEVICE_GEOLOCATION
}

export function isAppCheckTrustFactor(value: TrustFactor): value is AppCheckFactor {
    return value.name === TrustFactorType.APPLICATION_CHECK
}

export function isPlistTrustFactor(value: TrustFactor): value is PlistTrustFactor {
    return value.name === TrustFactorType.PLIST
}

export function isRegistryCheckTrustFactor(value: TrustFactor): value is RegistryCheckTrustFactor {
    return value.name === TrustFactorType.REGISTRY_CHECK
}

export function isChromeBrowserVersionTrustFactor(
    value: TrustFactor
): value is ChromeBrowserVersionFactor {
    return value.name === TrustFactorType.CHROME_BROWSER_VERSION
}

export interface PlistTrustFactor extends TrustFactor {
    name: TrustFactorType.PLIST
    usedPlist: Plist[]
    unusedPlist: Plist[]
}

export interface Plist {
    id: string
    factorId: string
    name: string
    configuration: PlistConfiguration
}

export interface PlistConfiguration {
    filePath: { prefix: string; path: string }
    key: string
    value: string
}

export interface RegistryCheckTrustFactor extends TrustFactor {
    name: TrustFactorType.REGISTRY_CHECK
    usedRegistryCheck: RegistryCheck[]
    unusedRegistryCheck: RegistryCheck[]
}

export interface RegistryCheck {
    id: string
    factorId: string
    name: string
    configuration: RegistryCheckConfiguration
}

export interface RegistryCheckConfiguration {
    keyPath: { prefix: string; path: string }
    key: string
    value: string
}

export interface BanyanAppVersionTrustFactor extends TrustFactor {
    name: TrustFactorType.BANYAN_APP_VERSION
    configuration: Record<DesktopPlatform, string>
}

export interface FileCheckTrustFactor extends TrustFactor {
    name: TrustFactorType.FILE_CHECK
    usedFiles: File[]
    unusedFiles: File[]
}
export interface File {
    id: string
    factorId: string
    name: string
    configurations: FileCheckConfigurations
}

export interface NewFile {
    name: string
    configurations: FileCheckConfigurations
}

type FileCheckConfigurations = Record<DesktopPlatform, FileCheckConfiguration | undefined>

export interface FileCheckConfiguration {
    filePath: { prefix: string; path: string }
    sha256Hash?: string
}

export function compareFiles(locale: string, fileA: File, fileB: File): number {
    return fileA.name.localeCompare(fileB.name, locale)
}

export function comparePlist(locale: string, plistA: Plist, plistB: Plist): number {
    return plistA.name.localeCompare(plistB.name, locale)
}

export function compareRegistryCheck(
    locale: string,
    registryCheckA: RegistryCheck,
    registryCheckB: RegistryCheck
): number {
    return registryCheckA.name.localeCompare(registryCheckB.name, locale)
}

export interface ZtaScoreTrustFactor extends TrustFactor {
    name: TrustFactorType.ZTA_SCORE
    severity: Severity
}

export interface GeolocationTrustFactor extends TrustFactor {
    name: TrustFactorType.DEVICE_GEOLOCATION
    config: GeoLocationConfig
}

export enum GeoLocationFactor {
    ALLOW_UNKNOWN_GEO_COUNTRY = "IS_ALLOW_COUNTRY",
    BLOCKED_COUNTRIES = "BLOCK_COUNTRY_LIST",
}

const geoLocationFactorKeys: Record<GeoLocationFactor, string> = {
    [GeoLocationFactor.ALLOW_UNKNOWN_GEO_COUNTRY]: "geolocation.allow_unknown_geo_country",
    [GeoLocationFactor.BLOCKED_COUNTRIES]: "geolocation.blocked_countries",
}

type GeoLocationConfig = Record<GeoLocationFactor, string>

export enum Severity {
    MODERATE = "65",
    STRICT = "75",
}

const severityFromResDict: Record<string, Severity> = {
    "65": Severity.MODERATE,
    "75": Severity.STRICT,
}

export enum OsVersionFactorPlatform {
    ANDROID = "ANDROID",
    FEDORA = "FEDORA",
    IOS = "IOS",
    MACOS = "MACOS",
    UBUNTU = "UBUNTU",
    WINDOWS = "WINDOWS",
    CHROME_OS = "CHROME_OS",
    ORACLE_LINUX = "ORACLE_LINUX",
}

const osVersionFactorKeys: Record<OsVersionFactorPlatform, string> = {
    [OsVersionFactorPlatform.ANDROID]: "uptodateos.value.android",
    [OsVersionFactorPlatform.FEDORA]: "uptodateos.value.fedora",
    [OsVersionFactorPlatform.IOS]: "uptodateos.value.ios",
    [OsVersionFactorPlatform.MACOS]: "uptodateos.value.macos",
    [OsVersionFactorPlatform.UBUNTU]: "uptodateos.value.ubuntu",
    [OsVersionFactorPlatform.WINDOWS]: "uptodateos.value.windows",
    [OsVersionFactorPlatform.ORACLE_LINUX]: "uptodateos.value.oracle_linux",
    // cspell:ignore chromeos
    [OsVersionFactorPlatform.CHROME_OS]: "uptodateos.value.chromeos",
}

export interface OsVersionFactor extends TrustFactor {
    name: TrustFactorType.OPERATING_SYSTEM_VERSION
    config: OsVersionConfig
}

type OsVersionConfig = Record<OsVersionFactorPlatform, string>

export enum BrowserVersionFactor {
    CHROME = "CHROME",
}

const browserVersionFactorKeys: Record<BrowserVersionFactor, string> = {
    [BrowserVersionFactor.CHROME]: "chrome.browser.platform.chrome",
}

export interface ChromeBrowserVersionFactor extends TrustFactor {
    name: TrustFactorType.CHROME_BROWSER_VERSION
    config: BrowserVersionConfig
}

type BrowserVersionConfig = Record<BrowserVersionFactor, string>

export interface AppCheckFactor extends TrustFactor {
    name: TrustFactorType.APPLICATION_CHECK
    config: AppCheckConfig[]
}

export interface AppCheckConfig {
    name: string
    mandatory: boolean
    processName: {
        [Platform.MACOS]: string
        [Platform.WINDOWS]: string
        [Platform.LINUX]: string
    }
}

function getDefaultBanyanAppVersions(
    versionsRes: BanyanAppVersionsRes
): Record<DesktopPlatform, string> {
    return {
        [Platform.MACOS]: versionsRes.macos[0],
        [Platform.WINDOWS]: versionsRes.windows[0],
        [Platform.LINUX]: versionsRes.linux[0],
        [Platform.CHROME]: "1",
    }
}

function reduceBanyanAppVersionConfiguration(
    acc: Record<DesktopPlatform, string>,
    config: FactorConfigRes
): Record<DesktopPlatform, string> {
    const platform = getPlatformFromConfig(config)

    if (!platform) return acc

    return { ...acc, [platform]: config.value }
}

function getPlatformFromConfig(config: FactorConfigRes): Platform | null {
    const platformString = config.key.split(".").pop()

    if (!platformString) return null

    return fromPlatformString[platformString as PlatformRes] ?? null
}

export enum TRUST_EFFECT {
    // TODO: Remove NOT_EVALUATED once we remove the pre-migration Trust Factors view
    NOT_EVALUATED = "NOT_EVALUATED",
    ALWAYS_DENY = "ALWAYS_DENY",
    LOW_TRUST_LEVEL = "LOW_TRUST_LEVEL",
    MEDIUM_TRUST_LEVEL = "MEDIUM_TRUST_LEVEL",
    NO_EFFECT = "NO_EFFECT",
}

function minTrustEffect(trustEffectA: TRUST_EFFECT, trustEffectB: TRUST_EFFECT): TRUST_EFFECT {
    return trustEffectPriority[trustEffectA] < trustEffectPriority[trustEffectB]
        ? trustEffectA
        : trustEffectB
}

const trustEffectPriority: Record<TRUST_EFFECT, number> = {
    [TRUST_EFFECT.NOT_EVALUATED]: 0,
    [TRUST_EFFECT.ALWAYS_DENY]: 1,
    [TRUST_EFFECT.LOW_TRUST_LEVEL]: 2,
    [TRUST_EFFECT.MEDIUM_TRUST_LEVEL]: 3,
    [TRUST_EFFECT.NO_EFFECT]: 4,
}

export const effectShortLabelDictionary: Record<TRUST_EFFECT, LanguageKey> = {
    [TRUST_EFFECT.NOT_EVALUATED]: "notEvaluated",
    [TRUST_EFFECT.ALWAYS_DENY]: "alwaysDeny",
    [TRUST_EFFECT.LOW_TRUST_LEVEL]: "lowTL",
    [TRUST_EFFECT.MEDIUM_TRUST_LEVEL]: "mediumTL",
    [TRUST_EFFECT.NO_EFFECT]: "noEffect",
}

export const effectLabelDictionary: Record<TRUST_EFFECT, LanguageKey> = {
    [TRUST_EFFECT.NOT_EVALUATED]: "notEvaluated",
    [TRUST_EFFECT.ALWAYS_DENY]: "alwaysDeny",
    [TRUST_EFFECT.LOW_TRUST_LEVEL]: "lowTrustLevel",
    [TRUST_EFFECT.MEDIUM_TRUST_LEVEL]: "mediumTrustLevel",
    [TRUST_EFFECT.NO_EFFECT]: "noEffect",
}

export const effectDescriptionDictionary: Record<TRUST_EFFECT, LanguageKey> = {
    [TRUST_EFFECT.NOT_EVALUATED]: "notEvaluatedDescription",
    [TRUST_EFFECT.ALWAYS_DENY]: "alwaysDenyDescription",
    [TRUST_EFFECT.LOW_TRUST_LEVEL]: "lowTrustLevelDescription",
    [TRUST_EFFECT.MEDIUM_TRUST_LEVEL]: "mediumTrustLevelDescription",
    [TRUST_EFFECT.NO_EFFECT]: "noEffectDescription",
}

interface HasName {
    name: string
}

export function isNotActiveThreat<T extends HasName>(value: T): boolean {
    return value.name === TrustFactorType.NOT_ACTIVE_THREAT
}

export function isRegisteredWith<T extends HasName>(value: T): boolean {
    return value.name === TrustFactorType.REGISTERED_WITH
}

export function isCompliant<T extends HasName>(value: T): boolean {
    return value.name === TrustFactorType.WS1_IS_COMPLIANT
}

export function isWS1RegisteredWith<T extends HasName>(value: T): boolean {
    return value.name === TrustFactorType.WS1_REGISTERED_WITH
}

export function isZtaScore<T extends HasName>(value: T): boolean {
    return value.name === TrustFactorType.ZTA_SCORE
}

enum TrustProfileName {
    DEFAULT = "granular.trust.default",
}

interface SeparateDefaultProfileResult {
    defaultTrustProfile: TrustProfileRes
    userTrustProfiles: TrustProfileRes[]
    deviceCountLastUpdated: number
}

function separateDefaultProfile(trustProfiles: TrustProfileRes[]): SeparateDefaultProfileResult {
    const { defaultTrustProfile, userTrustProfiles, deviceCountLastUpdated } = trustProfiles.reduce(
        reduceSeparateDefaultProfile,
        startingResult
    )

    if (!defaultTrustProfile || typeof deviceCountLastUpdated !== "number")
        throw new Error("No Default Profile was found")

    return { defaultTrustProfile, userTrustProfiles, deviceCountLastUpdated }
}

interface ReduceSeparateDefaultProfileResult {
    defaultTrustProfile?: TrustProfileRes
    userTrustProfiles: TrustProfileRes[]
    deviceCountLastUpdated?: number
}

const startingResult: ReduceSeparateDefaultProfileResult = { userTrustProfiles: [] }

function reduceSeparateDefaultProfile(
    acc: ReduceSeparateDefaultProfileResult,
    trustProfile: TrustProfileRes
): ReduceSeparateDefaultProfileResult {
    const deviceCountLastUpdatedRes = DateUtil.convertLargeTimestamp(
        trustProfile.extra_details.last_device_count_sync
    )
    const deviceCountLastUpdated =
        typeof acc.deviceCountLastUpdated === "number"
            ? Math.max(acc.deviceCountLastUpdated, deviceCountLastUpdatedRes)
            : deviceCountLastUpdatedRes

    if (trustProfile.profile_name === TrustProfileName.DEFAULT) {
        if (acc.defaultTrustProfile) {
            throw new Error("No two Default Profiles can exist at the same time")
        }

        return { ...acc, defaultTrustProfile: trustProfile, deviceCountLastUpdated }
    }

    return {
        ...acc,
        userTrustProfiles: [...acc.userTrustProfiles, trustProfile],
        deviceCountLastUpdated,
    }
}

function compareTrustProfile(
    trustProfileA: TrustProfileRes,
    trustProfileB: TrustProfileRes
): number {
    return trustProfileA.priority - trustProfileB.priority
}

function getFactorsDict(trustProfile: TrustProfileRes): Record<string, TrustFactorRes> {
    const profileFactorsDict = trustProfile.profile_factors.reduce(reduceFactorsDict, {})
    return trustProfile.unused_factors.reduce(reduceFactorsDict, profileFactorsDict)
}

function reduceFactorsDict(
    acc: Record<string, TrustFactorRes>,
    factor: TrustFactorRes
): Record<string, TrustFactorRes> {
    return { ...acc, [factor.id]: factor, [factor.factor_id]: factor, [factor.name]: factor }
}

function reduceTrustFactorsRes(
    trustFactorDict: Record<string, TrustFactorRes>,
    acc: TrustFactorRes[],
    trustFactor: TrustFactor
): TrustFactorRes[] {
    if (isPlistTrustFactor(trustFactor)) {
        return [
            ...acc,
            ...trustFactor.usedPlist.map((plist) => {
                const originalFactor = trustFactorDict[plist.id] ?? trustFactorDict[plist.factorId]
                return getBaseFactorRes(originalFactor, trustFactor)
            }),
        ]
    }

    if (isFileCheckTrustFactor(trustFactor)) {
        return [
            ...acc,
            ...trustFactor.usedFiles.map((file) => {
                const originalFactor = trustFactorDict[file.id] ?? trustFactorDict[file.factorId]
                return getBaseFactorRes(originalFactor, trustFactor)
            }),
        ]
    }

    if (isRegistryCheckTrustFactor(trustFactor)) {
        return [
            ...acc,
            ...trustFactor.usedRegistryCheck.map((registryCheck) => {
                const originalFactor =
                    trustFactorDict[registryCheck.id] ?? trustFactorDict[registryCheck.factorId]
                return getBaseFactorRes(originalFactor, trustFactor)
            }),
        ]
    }

    return [...acc, mapTrustFactorsRes(trustFactorDict, trustFactor)]
}

function mapTrustFactorsRes(
    trustFactorDict: Record<string, TrustFactorRes>,
    trustFactor: TrustFactor
): TrustFactorRes {
    const originalFactor = trustFactorDict[trustFactor.id] ?? trustFactorDict[trustFactor.name]

    if (isBanyanAppVersionTrustFactor(trustFactor)) {
        return getBanyanAppVersionFactorRes(originalFactor, trustFactor)
    }

    if (isZtaScoreTrustFactor(trustFactor)) {
        return getZtaFactorRes(originalFactor, trustFactor)
    }

    if (isOsVersionTrustFactor(trustFactor)) {
        return getOsVersionFactorRes(originalFactor, trustFactor)
    }

    if (isAppCheckTrustFactor(trustFactor)) {
        return getAppCheckFactorRes(originalFactor, trustFactor)
    }

    if (isChromeBrowserVersionTrustFactor(trustFactor)) {
        return getBrowserVersionFactorRes(originalFactor, trustFactor)
    }

    if (isGeolocationTrustFactor(trustFactor)) {
        return getGeoLocationFactorRes(originalFactor, trustFactor)
    }

    return getBaseFactorRes(originalFactor, trustFactor)
}

function getBaseFactorRes(original: TrustFactorRes, trustFactor: TrustFactor): TrustFactorRes {
    return {
        ...original,
        // TODO: Remove this check once we remove Trust Factors view
        ...(trustFactor.effect === TRUST_EFFECT.NOT_EVALUATED
            ? { active: false }
            : {
                  active: true,
                  effect: {
                      ...original.effect,
                      trust_level: trustEffectToTrustLevel[trustFactor.effect],
                  },
              }),
    }
}

function getBanyanAppVersionFactorRes(
    original: TrustFactorRes,
    trustFactor: BanyanAppVersionTrustFactor
): TrustFactorRes {
    return {
        ...getBaseFactorRes(original, trustFactor),
        config: CollectionUtil.entries(trustFactor.configuration).map(
            mapBanyanAppVersionConfiguration
        ),
    }
}

function mapBanyanAppVersionConfiguration([platform, version]: [
    Platform,
    string,
]): FactorConfigRes {
    return { key: `app.platform.${toPlatformString[platform]}`, value: version }
}

function getZtaFactorRes(
    original: TrustFactorRes,
    trustFactor: ZtaScoreTrustFactor
): TrustFactorRes {
    return {
        ...getBaseFactorRes(original, trustFactor),
        config: [{ key: "expected.value", value: trustFactor.severity }],
    }
}

function getOsVersionFactorRes(
    original: TrustFactorRes,
    trustFactor: OsVersionFactor
): TrustFactorRes {
    return {
        ...getBaseFactorRes(original, trustFactor),
        config: [
            {
                key: osVersionFactorKeys.ANDROID,
                value: trustFactor.config[OsVersionFactorPlatform.ANDROID],
            },
            {
                key: osVersionFactorKeys.IOS,
                value: trustFactor.config[OsVersionFactorPlatform.IOS],
            },
            {
                key: osVersionFactorKeys.MACOS,
                value: trustFactor.config[OsVersionFactorPlatform.MACOS],
            },
            {
                key: osVersionFactorKeys.WINDOWS,
                value: trustFactor.config[OsVersionFactorPlatform.WINDOWS],
            },
            {
                key: osVersionFactorKeys.UBUNTU,
                value: trustFactor.config[OsVersionFactorPlatform.UBUNTU],
            },
            {
                key: osVersionFactorKeys.FEDORA,
                value: trustFactor.config[OsVersionFactorPlatform.FEDORA],
            },
            {
                key: osVersionFactorKeys.CHROME_OS,
                value: trustFactor.config[OsVersionFactorPlatform.CHROME_OS],
            },
            {
                key: osVersionFactorKeys.ORACLE_LINUX,
                value: trustFactor.config[OsVersionFactorPlatform.ORACLE_LINUX],
            },
        ],
    }
}

function getBrowserVersionFactorRes(
    original: TrustFactorRes,
    trustFactor: ChromeBrowserVersionFactor
): TrustFactorRes {
    return {
        ...getBaseFactorRes(original, trustFactor),
        config: [
            {
                key: browserVersionFactorKeys.CHROME,
                value: trustFactor.config[BrowserVersionFactor.CHROME],
            },
        ],
    }
}

function getGeoLocationFactorRes(
    original: TrustFactorRes,
    trustFactor: GeolocationTrustFactor
): TrustFactorRes {
    return {
        ...getBaseFactorRes(original, trustFactor),
        config: [
            {
                key: geoLocationFactorKeys.IS_ALLOW_COUNTRY,
                value: trustFactor.config[GeoLocationFactor.ALLOW_UNKNOWN_GEO_COUNTRY],
            },
            {
                key: geoLocationFactorKeys.BLOCK_COUNTRY_LIST,
                value: trustFactor.config[GeoLocationFactor.BLOCKED_COUNTRIES],
            },
        ],
    }
}

function getAppCheckFactorRes(
    original: TrustFactorRes,
    trustFactor: AppCheckFactor
): AppCheckFactorRes {
    return {
        ...getBaseFactorRes(original, trustFactor),
        name: "OrgPreferredAppsRunning",
        preferred_apps_config: trustFactor.config.reduce(reducePreferredAppsConfigRes, []),
    }
}

function reducePreferredAppsConfigRes(
    configsRes: PreferredAppConfigRes[],
    config: AppCheckConfig
): PreferredAppConfigRes[] {
    return CollectionUtil.entries(config.processName).reduce((acc, [platform, process]) => {
        if (!process) return acc

        const configRes: PreferredAppConfigRes = {
            name: config.name,
            mandatory: config.mandatory,
            platform: toPlatformString[platform],
            process,
        }

        return [...acc, configRes]
    }, configsRes)
}

const partnerDict: Record<TrustIntegrationRes["integration_partner"], IntegrationPartner> = {
    crowdstrike: TrustSourceType.CROWD_STRIKE,
    sentinelone: TrustSourceType.SENTINEL_ONE,
    workspaceone: TrustSourceType.WORKSPACE_ONE,
}

function reducePlatforms(
    acc: Record<Platform, boolean>,
    platform: PlatformRes
): Record<Platform, boolean> {
    return { ...acc, [fromPlatformString[platform]]: true }
}

const fromPlatformString: Record<PlatformRes, Platform> = {
    macos: Platform.MACOS,
    windows: Platform.WINDOWS,
    linux: Platform.LINUX,
    ios: Platform.IOS,
    android: Platform.ANDROID,
    chrome: Platform.CHROME,
}

function mapAssignment(assignment: AssignmentCriteriaRes): Assignment {
    return {
        userGroups: assignment.assigned_groups,
        serialNumbers: assignment.assigned_serial_numbers,
        isManagedByMdm: assignment.managed_by_mdm,
        deviceOwnership: assignment.device_ownership.reduce(
            reduceDeviceOwnership,
            noDeviceOwnership
        ),
    }
}

function reduceDeviceOwnership(
    deviceOwnershipDict: Record<DeviceOwnership, boolean>,
    deviceOwnership: DeviceOwnershipRes
): Record<DeviceOwnership, boolean> {
    return { ...deviceOwnershipDict, [deviceOwnership]: true }
}

function mapPair(trustProfile: TrustProfile, index: number): ProfilePriorityPairReq {
    return { profile_id: trustProfile.id, priority: index + 1 }
}

function getTrustEffect(trustFactor: TrustFactorRes): TRUST_EFFECT {
    // TODO: Remove the active check when we remove Trust Factor view
    if (!trustFactor.active) return TRUST_EFFECT.NOT_EVALUATED
    return trustLevelToTrustEffect[trustFactor.effect.trust_level]
}

const trustLevelToTrustEffect: Record<TrustLevelRes, TRUST_EFFECT> = {
    AlwaysDeny: TRUST_EFFECT.ALWAYS_DENY,
    Low: TRUST_EFFECT.LOW_TRUST_LEVEL,
    Medium: TRUST_EFFECT.MEDIUM_TRUST_LEVEL,
    High: TRUST_EFFECT.NO_EFFECT,
}

const trustEffectToTrustLevel: Record<
    Exclude<TRUST_EFFECT, TRUST_EFFECT.NOT_EVALUATED>,
    TrustLevelRes
> = {
    [TRUST_EFFECT.ALWAYS_DENY]: "AlwaysDeny",
    [TRUST_EFFECT.LOW_TRUST_LEVEL]: "Low",
    [TRUST_EFFECT.MEDIUM_TRUST_LEVEL]: "Medium",
    [TRUST_EFFECT.NO_EFFECT]: "High",
}

function mapTrustFactorResToOsVersionConfig(factor: TrustFactorRes): OsVersionConfig {
    const versionConfig: OsVersionConfig = {
        [OsVersionFactorPlatform.ANDROID]: "",
        [OsVersionFactorPlatform.FEDORA]: "",
        [OsVersionFactorPlatform.IOS]: "",
        [OsVersionFactorPlatform.MACOS]: "",
        [OsVersionFactorPlatform.UBUNTU]: "",
        [OsVersionFactorPlatform.WINDOWS]: "",
        [OsVersionFactorPlatform.CHROME_OS]: "",
        [OsVersionFactorPlatform.ORACLE_LINUX]: "",
    }

    if (Array.isArray(factor.config)) {
        factor.config.forEach((config: FactorConfigRes) => {
            switch (config.key) {
                case osVersionFactorKeys.ANDROID: {
                    versionConfig[OsVersionFactorPlatform.ANDROID] = config.value
                    break
                }
                case osVersionFactorKeys.MACOS: {
                    versionConfig[OsVersionFactorPlatform.MACOS] = config.value
                    break
                }
                case osVersionFactorKeys.WINDOWS: {
                    versionConfig[OsVersionFactorPlatform.WINDOWS] = config.value
                    break
                }
                case osVersionFactorKeys.IOS: {
                    versionConfig[OsVersionFactorPlatform.IOS] = config.value
                    break
                }
                case osVersionFactorKeys.FEDORA: {
                    versionConfig[OsVersionFactorPlatform.FEDORA] = config.value
                    break
                }
                case osVersionFactorKeys.UBUNTU: {
                    versionConfig[OsVersionFactorPlatform.UBUNTU] = config.value
                    break
                }
                case osVersionFactorKeys.CHROME_OS: {
                    versionConfig[OsVersionFactorPlatform.CHROME_OS] = config.value
                    break
                }
                case osVersionFactorKeys.ORACLE_LINUX: {
                    versionConfig[OsVersionFactorPlatform.ORACLE_LINUX] = config.value
                    break
                }
            }
        })
    }

    return versionConfig
}

function mapBrowserVersion(factor: TrustFactorRes): BrowserVersionConfig {
    const versionConfig: BrowserVersionConfig = {
        [BrowserVersionFactor.CHROME]: "",
    }

    if (Array.isArray(factor.config)) {
        factor.config.forEach((config: FactorConfigRes) => {
            switch (config.key) {
                case browserVersionFactorKeys.CHROME: {
                    versionConfig[BrowserVersionFactor.CHROME] = config.value
                    break
                }
            }
        })
    }

    return versionConfig
}

function mapGeoLocationFactor(factor: TrustFactorRes): GeoLocationConfig {
    const versionConfig: GeoLocationConfig = {
        [GeoLocationFactor.ALLOW_UNKNOWN_GEO_COUNTRY]: "",
        [GeoLocationFactor.BLOCKED_COUNTRIES]: "",
    }
    if (Array.isArray(factor.config)) {
        factor.config.forEach((config: FactorConfigRes) => {
            switch (config.key) {
                case geoLocationFactorKeys.IS_ALLOW_COUNTRY: {
                    versionConfig[GeoLocationFactor.ALLOW_UNKNOWN_GEO_COUNTRY] = config.value
                    break
                }
                case geoLocationFactorKeys.BLOCK_COUNTRY_LIST: {
                    versionConfig[GeoLocationFactor.BLOCKED_COUNTRIES] = config.value
                    break
                }
            }
        })
    }

    return versionConfig
}

function mapTrustFactorResToAppCheckConfig(factor: AppCheckFactorRes): AppCheckConfig[] {
    const config: AppCheckConfig[] = []
    const appMap: Map<string, AppCheckConfig> = new Map()
    if (Array.isArray(factor.preferred_apps_config)) {
        factor.preferred_apps_config.forEach((app: PreferredAppConfigRes) => {
            if (appMap.has(app.name)) {
                const existing: AppCheckConfig = appMap.get(app.name)!
                if (app.platform === "macos") {
                    existing.processName[Platform.MACOS] = app.process
                } else if (app.platform === "windows") {
                    existing.processName[Platform.WINDOWS] = app.process
                } else if (app.platform === "linux") {
                    existing.processName[Platform.LINUX] = app.process
                }
            } else {
                appMap.set(app.name, {
                    name: app.name,
                    mandatory: app.mandatory,
                    processName: {
                        MACOS: app.platform === "macos" ? app.process : "",
                        WINDOWS: app.platform === "windows" ? app.process : "",
                        LINUX: app.platform === "linux" ? app.process : "",
                    },
                })
            }
        })
        return Array.from(appMap.values())
    }
    return config
}

function detailsAndAssignmentRes(trustProfile: NewTrustProfile): NewTrustProfileReq {
    return {
        display_name: trustProfile.name,
        description: trustProfile.description ?? "",
        applied_platforms: CollectionUtil.entries(trustProfile.appliedPlatform).reduce(
            reduceAppliedPlatformsRes,
            []
        ),
        assignment_criteria: {
            assigned_groups: trustProfile.assignment.userGroups,
            assigned_serial_numbers: trustProfile.assignment.serialNumbers,
            managed_by_mdm: trustProfile.assignment.isManagedByMdm,
            device_ownership: CollectionUtil.entries(
                trustProfile.assignment.deviceOwnership
            ).reduce(reduceDeviceOwnershipRes, []),
        },
    }
}

function updateDetailsAndAssignment(
    freshProfile: TrustProfileRes,
    trustProfile: UpdateTrustProfile
): TrustProfileRes {
    return { ...freshProfile, ...detailsAndAssignmentRes(trustProfile) }
}

function reduceAppliedPlatformsRes(
    platforms: PlatformRes[],
    [platform, isApplied]: [Platform, boolean]
): PlatformRes[] {
    return isApplied ? [...platforms, toPlatformString[platform]] : platforms
}

const toPlatformString: Record<Platform, PlatformRes> = {
    [Platform.MACOS]: "macos",
    [Platform.WINDOWS]: "windows",
    [Platform.LINUX]: "linux",
    [Platform.IOS]: "ios",
    [Platform.ANDROID]: "android",
    [Platform.CHROME]: "chrome",
}

function reduceDeviceOwnershipRes(
    deviceOwnerships: DeviceOwnershipRes[],
    [deviceOwnership, isApplied]: [DeviceOwnership, boolean]
): DeviceOwnershipRes[] {
    return isApplied
        ? [...deviceOwnerships, deviceOwnershipResDict[deviceOwnership]]
        : deviceOwnerships
}

const deviceOwnershipResDict: Record<DeviceOwnership, DeviceOwnershipRes> = {
    [DeviceOwnership.CORPORATE_DEDICATED]: "Corporate Dedicated",
    [DeviceOwnership.CORPORATE_SHARED]: "Corporate Shared",
    [DeviceOwnership.EMPLOYEE_OWNED]: "Employee Owned",
    [DeviceOwnership.OTHER]: "Other",
}

function turnTtlIntoTrustLevelExpirationInHours(ttl: number, unit?: "hours"): number | string {
    switch (unit) {
        case "hours":
            return ttl
    }

    console.error(`We do not recognize "${unit}" as a valid unit for the Trust Score TTL.`)
    return FAILED_TO_SET_TRUST_LEVEL_EXPIRATION
}

// File Check utilities

interface TrustFactorAcc {
    nonConfigurableTrustFactors: TrustFactor[]
    fileCheckInfo: {
        files: File[]
        effect?: TRUST_EFFECT
        applicablePlatform: Record<Platform, boolean>
    }
    plistInfo: {
        plists: Plist[]
        effect?: TRUST_EFFECT
    }
    registryCheckInfo: {
        registryChecks: RegistryCheck[]
        effect?: TRUST_EFFECT
    }
}

const emptyTrustFactorAcc: TrustFactorAcc = {
    nonConfigurableTrustFactors: [],
    fileCheckInfo: {
        files: [],
        applicablePlatform: onlyDesktopPlatforms,
    },
    plistInfo: {
        plists: [],
    },
    registryCheckInfo: { registryChecks: [] },
}

function addFileToAcc(trustFactorRes: TrustFactorRes, acc: TrustFactorAcc): TrustFactorAcc {
    const file: File = {
        id: trustFactorRes.id || trustFactorRes.factor_id,
        factorId: trustFactorRes.factor_id,
        name: trustFactorRes.display_name,
        configurations:
            trustFactorRes.config?.reduce(
                reduceFileCheckConfigurations,
                noFileCheckConfigurations
            ) ?? noFileCheckConfigurations,
    }

    return {
        ...acc,
        fileCheckInfo: {
            files: [...acc.fileCheckInfo.files, file],
            effect: accumulateTrustEffect(trustFactorRes, acc.fileCheckInfo.effect),
            applicablePlatform: trustFactorRes.active
                ? mergeApplicablePlatforms(
                      acc.fileCheckInfo.applicablePlatform,
                      trustFactorRes.applicable_platforms.reduce(reducePlatforms, noPlatforms)
                  )
                : acc.fileCheckInfo.applicablePlatform,
        },
    }
}

function addPlistToAcc(trustFactorRes: TrustFactorRes, acc: TrustFactorAcc): TrustFactorAcc {
    const plist: Plist = {
        id: trustFactorRes.id || trustFactorRes.factor_id,
        factorId: trustFactorRes.factor_id,
        name: trustFactorRes.display_name,
        configuration:
            trustFactorRes.config?.reduce(reducePlistConfiguration, noPlistConfiguration) ??
            noPlistConfiguration,
    }

    return {
        ...acc,
        plistInfo: {
            effect: accumulateTrustEffect(trustFactorRes, acc.plistInfo.effect),
            plists: [...acc.plistInfo.plists, plist],
        },
    }
}

function addRegistryCheckToAcc(
    trustFactorRes: TrustFactorRes,
    acc: TrustFactorAcc
): TrustFactorAcc {
    const registryCheck: RegistryCheck = {
        id: trustFactorRes.id || trustFactorRes.factor_id,
        factorId: trustFactorRes.factor_id,
        name: trustFactorRes.display_name,
        configuration:
            trustFactorRes.config?.reduce(
                reduceRegistryCheckConfiguration,
                noRegistryCheckConfiguration
            ) ?? noRegistryCheckConfiguration,
    }

    return {
        ...acc,
        registryCheckInfo: {
            effect: accumulateTrustEffect(trustFactorRes, acc.registryCheckInfo.effect),
            registryChecks: [...acc.registryCheckInfo.registryChecks, registryCheck],
        },
    }
}

/**
 * Accumulate Trust Effects as you add more Trust Factors from the response
 */
function accumulateTrustEffect(
    trustFactorRes: TrustFactorRes,
    previousTrustEffect?: TRUST_EFFECT
): TRUST_EFFECT | undefined {
    if (!trustFactorRes.active) return previousTrustEffect

    const newTrustEffect = getTrustEffect(trustFactorRes)

    if (!previousTrustEffect) return newTrustEffect

    return minTrustEffect(previousTrustEffect, newTrustEffect)
}

/**
 * Combine the Trust Effect from two Factors
 */
function combineTrustEffect(
    trustEffectA?: TRUST_EFFECT,
    trustEffectB?: TRUST_EFFECT
): TRUST_EFFECT {
    if (!trustEffectA) return trustEffectB ?? TRUST_EFFECT.NO_EFFECT

    return trustEffectB ? minTrustEffect(trustEffectA, trustEffectB) : trustEffectA
}

export const noPlistConfiguration: PlistConfiguration = {
    filePath: { prefix: "", path: "" },
    key: "",
    value: "",
}

export const noFileCheckConfigurations: FileCheckConfigurations = {
    [Platform.MACOS]: undefined,
    [Platform.WINDOWS]: undefined,
    [Platform.LINUX]: undefined,
    [Platform.CHROME]: undefined,
}

export const noRegistryCheckConfiguration: RegistryCheckConfiguration = {
    keyPath: { prefix: "", path: "" },
    key: "",
    value: "",
}

function reducePlistConfiguration(
    configuration: PlistConfiguration,
    res: FactorConfigRes
): PlistConfiguration {
    const configKeyParts = res.key.split(".")
    const configurationType = configKeyParts.pop()

    if (
        !configurationType ||
        !(
            configurationType === "path" ||
            configurationType === "prefix" ||
            configurationType === "key" ||
            configurationType === "value"
        )
    ) {
        console.warn(`Config has a key labeled "${res.key}" that we don't recognize`, res)
        return configuration
    }

    return {
        filePath: {
            prefix: configurationType === "prefix" ? res.value : configuration.filePath.prefix,
            path: configurationType === "path" ? res.value : configuration.filePath.path,
        },
        key: configurationType === "key" ? res.value : configuration.key,
        value: configurationType === "value" ? res.value : configuration.value,
    }
}

function reduceFileCheckConfigurations(
    configurations: FileCheckConfigurations,
    res: FactorConfigRes
): FileCheckConfigurations {
    // Here's an example of a key we should expect: file.path.windows
    // Three terms separated by periods
    // The first term is always file
    // The second term is either `path` or `sha256`
    // The third term is either `macos`, `windows`, or `linux`
    const configKeyParts = res.key.split(".")
    const platformString = configKeyParts.pop()

    if (!platformString) {
        console.warn("Config has no key", res)
        return configurations
    }

    const platform = fromPlatformString[platformString as PlatformRes]

    if (!platform) {
        console.warn(
            `Config has a platform labeled "${platformString}" that we don't recognize`,
            res
        )
        return configurations
    }

    if (platform === Platform.ANDROID || platform === Platform.IOS) {
        console.warn(
            `File Check can only accept Config for Desktop Platforms, not "${platformString}"`,
            res
        )
        return configurations
    }

    const configurationType = configKeyParts.pop()

    if (
        !configurationType ||
        !(
            configurationType === "path" ||
            configurationType === "prefix" ||
            configurationType === "sha256"
        )
    ) {
        console.warn(`Config has a key labeled "${res.key}" that we don't recognize`, res)
        return configurations
    }

    const existingConfiguration = configurations[platform]

    const configuration: FileCheckConfiguration = {
        filePath: {
            prefix:
                configurationType === "prefix"
                    ? res.value
                    : existingConfiguration?.filePath.prefix ?? "",
            path:
                configurationType === "path"
                    ? res.value
                    : existingConfiguration?.filePath.path ?? "",
        },
        sha256Hash: configurationType === "sha256" ? res.value : existingConfiguration?.sha256Hash,
    }

    return { ...configurations, [platform]: configuration }
}

function reduceRegistryCheckConfiguration(
    configuration: RegistryCheckConfiguration,
    res: FactorConfigRes
): RegistryCheckConfiguration {
    const configKeyParts = res.key.split(".")
    const configurationType = configKeyParts.pop()

    if (
        !configurationType ||
        !(
            configurationType === "path" ||
            configurationType === "prefix" ||
            configurationType === "key" ||
            configurationType === "value"
        )
    ) {
        console.warn(`Config has a key labeled "${res.key}" that we don't recognize`, res)
        return configuration
    }

    return {
        keyPath: {
            prefix: configurationType === "prefix" ? res.value : configuration.keyPath.prefix,
            path: configurationType === "path" ? res.value : configuration.keyPath.path,
        },
        key: configurationType === "key" ? res.value : configuration.key,
        value: configurationType === "value" ? res.value : configuration.value,
    }
}
