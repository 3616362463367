import { Location } from "history"

import { UrlUtil } from "../../../pre-v3/utils/Url.util"
import { ROUTE } from "../../../routes"

export class RouteUtils {
    public static appendOrgToRoute(route: ROUTE, orgName: string): string {
        const searchParams = new URLSearchParams()
        searchParams.set(orgNameQuery, orgName)
        return `${route}?${searchParams.toString()}`
    }

    static getOrgNameFromUrl(location: Location): string | null {
        if (UrlUtil.hasTeamEditionRedirectUrl()) {
            return UrlUtil.getOrgNameFromUrl()
        }

        const params = new URLSearchParams(location.search)
        return params.get(orgNameQuery) ?? params.get(orgNameQueryV1)
    }
}

const orgNameQuery = "org-name" as const
const orgNameQueryV1 = "org" as const
