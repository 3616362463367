import React from "react"

import { LanguageKey } from "../../../pre-v3/services/localization/languages/en-US.language"
import { useServiceLocalization } from "../../../pre-v3/services/localization/Localization.service"
import { Accordion } from "../../../v3/components/accordion/Accordion.component"
import { Input } from "../../../v3/components/input/Input.component"
import { ToggleButton, ToggleButtonItem } from "../../../v3/components/toggle-button/ToggleButton"
import { AdvancedSettings, AdvancedSettingsStatus } from "../../../v3/services/shared/AccessTier"
import { FormRow } from "../../../components/form/FormRow.component"
import styles from "./AccessTierAdvancedConfiguration.module.scss"

interface Props {
    advancedSettings?: AdvancedSettings
    isDisabled: boolean
    onChange(advancedSettings?: AdvancedSettings): void
}

export function AccessTierAdvancedConfiguration(props: Props): JSX.Element {
    const localization = useServiceLocalization()

    const [status, setStatus] = React.useState(() =>
        getAdvancedConfigStatus(props.advancedSettings)
    )

    const onToggleOverallStatus = (status: OverallStatus) => {
        setStatus(status)
        if (status === OverallStatus.DEFAULT) props.onChange(undefined)
    }

    const onMetricsCollectionAddress = (metricsCollectionAddress: string) =>
        props.onChange({ ...props.advancedSettings, metricsCollectionAddress })
    const onEventsRateLimiting = (eventsRateLimiting: AdvancedSettingsStatus) =>
        props.onChange({ ...props.advancedSettings, eventsRateLimiting })
    const onEventKeyRateLimiting = (eventKeyRateLimiting: AdvancedSettingsStatus) =>
        props.onChange({ ...props.advancedSettings, eventKeyRateLimiting })
    const onForwardTrustCookie = (forwardTrustCookie: AdvancedSettingsStatus) =>
        props.onChange({ ...props.advancedSettings, forwardTrustCookie })
    const onStrictTransportEnabled = (enableStrictTransport: AdvancedSettingsStatus) =>
        props.onChange({ ...props.advancedSettings, enableStrictTransport })
    const onPrivateResourceDiscoveryEnable = (
        enablePrivateResourceDiscovery: AdvancedSettingsStatus
    ) => props.onChange({ ...props.advancedSettings, enablePrivateResourceDiscovery })

    const advancedSettingsLabel = localization.getString("advancedSettings")
    const eventsRateLimitingLabel = localization.getString("eventsRateLimiting")
    const eventKeyRateLimitingLabel = localization.getString("eventKeyRateLimiting")
    const forwardTrustCookieLabel = localization.getString("forwardTrustCookie")

    const statusToggleItems = Object.values(OverallStatus).map<ToggleButtonItem>(
        (overallStatus) => {
            const [labelKey, ariaLabelKey] = overallStatusLabel[overallStatus]

            return {
                label: localization.getString(labelKey),
                ariaLabel: localization.getString(ariaLabelKey, advancedSettingsLabel),
                onClick: () => onToggleOverallStatus(overallStatus),
                selected: status === overallStatus,
            }
        }
    )

    return (
        <Accordion
            label={
                <FormRow
                    label={advancedSettingsLabel}
                    className={styles.accordionLabel}
                    childrenClassName={styles.flex}
                >
                    <ToggleButton disabled={props.isDisabled} items={statusToggleItems} />
                </FormRow>
            }
            type="plain"
            open={status === OverallStatus.CUSTOM}
        >
            <div className={styles.accordionContent}>
                <FormRow
                    label={localization.getString("metricsCollectionAddress")}
                    description={localization.getString("metricsCollectionAddressDesc")}
                    htmlFor={Id.METRICS_COLLECTION_ADDRESS}
                >
                    <Input
                        id={Id.METRICS_COLLECTION_ADDRESS}
                        type="text"
                        placeholder={localization.getString("metricsCollectionAddressExample")}
                        value={props.advancedSettings?.metricsCollectionAddress ?? ""}
                        onChangeValue={onMetricsCollectionAddress}
                        disabled={props.isDisabled}
                    />
                </FormRow>
                <FormRow
                    label={eventsRateLimitingLabel}
                    description={localization.getString("eventsRateLimitingDesc")}
                    childrenClassName={styles.flex}
                >
                    <RenderToggle
                        label={eventsRateLimitingLabel}
                        status={props.advancedSettings?.eventsRateLimiting}
                        onStatusChange={onEventsRateLimiting}
                        isDisabled={props.isDisabled}
                    />
                </FormRow>
                <FormRow
                    label={eventKeyRateLimitingLabel}
                    description={localization.getString("eventKeyRateLimitingDesc")}
                    childrenClassName={styles.flex}
                >
                    <RenderToggle
                        label={eventKeyRateLimitingLabel}
                        status={props.advancedSettings?.eventKeyRateLimiting}
                        onStatusChange={onEventKeyRateLimiting}
                        isDisabled={props.isDisabled}
                    />
                </FormRow>
                <FormRow
                    label={forwardTrustCookieLabel}
                    description={localization.getString("forwardTrustCookieDesc")}
                    childrenClassName={styles.flex}
                >
                    <RenderToggle
                        label={forwardTrustCookieLabel}
                        status={props.advancedSettings?.forwardTrustCookie}
                        onStatusChange={onForwardTrustCookie}
                        isDisabled={props.isDisabled}
                    />
                </FormRow>
                <FormRow
                    label={localization.getString("enableStrictTransport")}
                    description={localization.getString("enableStrictTransportDesc")}
                    childrenClassName={styles.flex}
                >
                    <RenderToggle
                        label={localization.getString("strictTransportSecurity")}
                        status={props.advancedSettings?.enableStrictTransport}
                        onStatusChange={onStrictTransportEnabled}
                        isDisabled={props.isDisabled}
                    />
                </FormRow>
                <FormRow
                    label={localization.getString("enablePrivateResourceDiscovery")}
                    description={localization.getString(
                        "enablePrivateResourceDiscoveryDescription"
                    )}
                    childrenClassName={styles.flex}
                >
                    <RenderToggle
                        label={localization.getString("privateResourceDiscovery")}
                        status={props.advancedSettings?.enablePrivateResourceDiscovery}
                        onStatusChange={onPrivateResourceDiscoveryEnable}
                        isDisabled={props.isDisabled}
                    />
                </FormRow>
            </div>
        </Accordion>
    )
}

enum Id {
    METRICS_COLLECTION_ADDRESS = "metricsCollectionAddress",
}

interface ToggleProps {
    label: string
    status?: AdvancedSettingsStatus
    isDisabled: boolean
    onStatusChange(status: AdvancedSettingsStatus): void
}

function RenderToggle(props: ToggleProps): JSX.Element {
    const localization = useServiceLocalization()

    const onDefaultChange = () => props.onStatusChange(AdvancedSettingsStatus.DEFAULT)
    const onEnabledChange = () => props.onStatusChange(AdvancedSettingsStatus.ENABLED)
    const onDisabledChange = () => props.onStatusChange(AdvancedSettingsStatus.DISABLED)

    const toggleItems: ToggleButtonItem[] = [
        {
            label: localization.getString("default"),
            ariaLabel: localization.getString("defaultSomething", props.label),
            onClick: onDefaultChange,
            selected: !props.status || props.status === AdvancedSettingsStatus.DEFAULT,
        },
        {
            label: localization.getString("enabled"),
            ariaLabel: localization.getString("enabledSomething", props.label),
            onClick: onEnabledChange,
            selected: props.status === AdvancedSettingsStatus.ENABLED,
        },
        {
            label: localization.getString("disabled"),
            ariaLabel: localization.getString("disabledSomething", props.label),
            onClick: onDisabledChange,
            selected: props.status === AdvancedSettingsStatus.DISABLED,
        },
    ]

    return <ToggleButton disabled={props.isDisabled} items={toggleItems} />
}

enum OverallStatus {
    DEFAULT = "DEFAULT",
    CUSTOM = "CUSTOM",
}

const overallStatusLabel: Record<OverallStatus, [LanguageKey, LanguageKey]> = {
    [OverallStatus.DEFAULT]: ["default", "defaultSomething"],
    [OverallStatus.CUSTOM]: ["custom", "customSomething"],
}

function getAdvancedConfigStatus(advancedSettings?: AdvancedSettings): OverallStatus {
    if (!advancedSettings) return OverallStatus.DEFAULT

    return advancedSettings.metricsCollectionAddress ||
        (advancedSettings.eventsRateLimiting &&
            advancedSettings.eventsRateLimiting !== AdvancedSettingsStatus.DEFAULT) ||
        (advancedSettings.eventKeyRateLimiting &&
            advancedSettings.eventKeyRateLimiting !== AdvancedSettingsStatus.DEFAULT) ||
        (advancedSettings.forwardTrustCookie &&
            advancedSettings.forwardTrustCookie !== AdvancedSettingsStatus.DEFAULT) ||
        (advancedSettings.enableStrictTransport &&
            advancedSettings.enableStrictTransport !== AdvancedSettingsStatus.DEFAULT) ||
        (advancedSettings.enablePrivateResourceDiscovery &&
            advancedSettings.enablePrivateResourceDiscovery !== AdvancedSettingsStatus.DEFAULT)
        ? OverallStatus.CUSTOM
        : OverallStatus.DEFAULT
}
