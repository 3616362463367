import React, { ReactNode } from "react"
import LargeMessageTemplate from "./LargeMessage.template"
import { IconDefinition } from "@fortawesome/pro-solid-svg-icons"

export class LargeMessage extends React.Component<React.PropsWithChildren<LargeMessageProps>, {}> {
    public render(): ReactNode {
        return LargeMessageTemplate.call(this)
    }
}

interface LargeMessageProps {
    icon: IconDefinition
    className?: string
}
