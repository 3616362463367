import { DialogContent } from "@mui/material"
import React from "react"

import { useServiceLocalization } from "../../../../../pre-v3/services/localization/Localization.service"
import { RegistryCheck, TrustProfile } from "../../../../services/TrustFactor.service"
import { AppText } from "../../../../components/app-text/AppText.component"
import styles from "./DeleteRegistryCheck.module.scss"

interface Props {
    registryCheck: RegistryCheck
}

export function DeleteRegistryCheck(props: Props): JSX.Element {
    const localization = useServiceLocalization()

    return (
        <DialogContent>
            {props.registryCheck.trustProfiles.length > 0 && (
                <React.Fragment>
                    <AppText>
                        {localization.getPluralString(
                            "registryCheckIsIncludedInTrustProfile",
                            props.registryCheck.trustProfiles.length,
                            props.registryCheck.name
                        )}
                    </AppText>
                    <ul className={styles.trustProfileList}>
                        {props.registryCheck.trustProfiles.map(mapTrustProfileListItem)}
                    </ul>
                </React.Fragment>
            )}
            <AppText
                ls={{
                    key: "areYouSureYouWantToDeleteSomething",
                    replaceVals: [props.registryCheck.name],
                }}
            />
        </DialogContent>
    )
}

function mapTrustProfileListItem(trustProfile: TrustProfile): JSX.Element {
    return <li key={trustProfile.id}>{trustProfile.name}</li>
}
