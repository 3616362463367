import { useLocation } from "react-router-dom"
import AgGridUtil, { FilterModel } from "./AgGrid.util"
import { Addr } from "netaddr"
import { PatternUtil } from "./Pattern.util"

export class UrlUtil {
    public static getOrgNameFromUrl(): string {
        const host: string = window.location.hostname
        return host.substr(0, host.indexOf("."))
    }

    public static isLocalhost(): boolean {
        const isLocalhost = Boolean(
            window.location.hostname === "localhost" ||
                // [::1] is the IPv6 localhost address.
                window.location.hostname === "[::1]" ||
                // 127.0.0.1/8 is considered localhost for IPv4.
                window.location.hostname.match(
                    /^127(?:\.(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)){3}$/
                )
        )

        return isLocalhost
    }

    public static appendHttps(url: string): string {
        if (PatternUtil.HTTPS_REGEX.test(url)) {
            return url
        }
        return "https://" + url
    }

    public static isIpV4Address(str: string): boolean {
        const regex = /^((25[0-5]|(2[0-4]|1\d|[1-9]|)\d)\.?\b){4}$/
        return regex.test(str)
    }

    public static getBackendUrl(): URL {
        const reactAppProxy: string | undefined = process.env.REACT_APP_PROXY

        return new URL(this.isLocalhost() && reactAppProxy ? reactAppProxy : window.location.origin)
    }

    public static getAppProxyHostname(): string | undefined {
        const reactAppProxy: string | undefined = process.env.REACT_APP_PROXY

        return reactAppProxy && new URL(reactAppProxy).hostname
    }

    public static getOrgDomain(): string {
        const host: string = this.getBackendUrl().hostname

        return host.substr(host.indexOf(".") + 1, host.length - 1)
    }

    public static hasTeamEditionRedirectUrl(): boolean {
        const redirectRegEx = new RegExp(
            `${this.getOrgNameFromUrl()}.auth(-.+)?.(bnntest|banyanops).com`
        )
        return redirectRegEx.test(window.location.hostname)
    }

    public static getParentPath(path: string): string {
        if (path && path.length > 0) {
            path = path.substr(0, path.length - 1)
            const stack: string[] = path.split("/")
            stack.pop()
            return stack.join("/")
        }
        return ""
    }

    public static readFilter<FilterById extends string>(
        urlSearchParams = window.location.search
    ): FilterModel<FilterById> {
        return AgGridUtil.deserializeFilterModel(urlSearchParams)
    }

    public static writeFilter(filter: FilterModel): void {
        const pathname: string =
            window.location.pathname + "?" + AgGridUtil.serializeFilterModel(filter)
        window.history.replaceState(null, "", pathname)
    }

    public static setURlParams(key: string, value?: string) {
        const currentUrlParams = new URLSearchParams(window.location.search)

        if (value) {
            currentUrlParams.set(key, value)
        } else {
            currentUrlParams.delete(key)
        }

        const updatedUrl = `${window.location.pathname}?${currentUrlParams.toString()}`
        window.history.replaceState(null, "", updatedUrl)
    }

    public static getUrlParam(key: string): string {
        const currentUrlParams = new URLSearchParams(window.location.search)

        return currentUrlParams.get(key) ?? ""
    }

    public static convertToURLSearchParams(obj: Object): URLSearchParams {
        const params: URLSearchParams = new URLSearchParams()
        for (const [key, value] of Object.entries(obj)) {
            if (!value) continue

            if (!Array.isArray(value)) {
                params.set(key, value)
                continue
            }

            value.forEach((v) => params.append(key, v))
        }
        return params
    }

    public static doesCidrCollide(first: string, second: string): boolean {
        try {
            return Addr(first).intersect(Addr(second))
        } catch {
            return false
        }
    }

    public static getCidrCollisions(cidrs: string[]): Set<string> {
        if (cidrs.length > 1000) {
            // Don't crash the browser
            return new Set()
        }
        const collisions: Set<string> = new Set()
        for (let i = 0; i < cidrs.length; i++) {
            const first: string = cidrs[i]
            for (let j = i + 1; j < cidrs.length; j++) {
                const second: string = cidrs[j]
                if (UrlUtil.doesCidrCollide(first, second)) {
                    collisions.add(first)
                    collisions.add(second)
                }
            }
        }
        return collisions
    }
}

export function encodeID(id: string) {
    return btoa(encodeURIComponent(id))
}

export function decodeID(id: string): string {
    try {
        return decodeURIComponent(atob(id))
    } catch (error) {
        console.error(error)
        return ""
    }
}

export function useUrlSearch<Key extends string>(...keys: Key[]): Record<Key, string | undefined> {
    const location = useLocation()
    const params = new URLSearchParams(location.search)

    const entries = keys.map((key) => [key, params.get(key) || undefined])
    return Object.fromEntries(entries)
}
