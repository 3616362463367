import { faPlus } from "@fortawesome/pro-regular-svg-icons"
import React, { FormEvent, ReactNode, useEffect, useMemo, useState } from "react"

import { Button, ButtonElement, ButtonType } from "../../../../components/button/Button.component"
import { LocalizationService } from "../../../../pre-v3/services/localization/Localization.service"
import { CollectionUtil } from "../../../../pre-v3/utils/Collection.util"
import { PatternUtil } from "../../../../pre-v3/utils/Pattern.util"
import { useDebouncedState } from "../../../../pre-v3/utils/UseDebouncedCallback.hook"
import { Form } from "../../../components/form/Form.component"
import { Input } from "../../../components/input/Input.component"
import { FormRow } from "../../../components/form/FormRow.component"
import { Accordion } from "../../../components/accordion/Accordion.component"
import { PillMultiSelect } from "../../../components/pill-multi-select/PillMultiSelect.component"
import { EditableRoleFields, useGetGroupNames } from "../../../services/Role.service"
import { useGetServiceAccounts } from "../../../services/ServiceAccount.service"
import { FormColumn } from "../../../components/form/FormColumn.component"
import {
    CheckboxGroup,
    CheckboxGroupOption,
} from "../../../components/checkbox-group/CheckboxGroup.component"
import {
    DeviceOwnership,
    allDeviceOwnership,
    deviceOwnershipLabelDict,
} from "../../../services/shared/DeviceOwnership"
import {
    LargeCheckboxGroup,
    LargeCheckboxGroupOption,
} from "../../../components/large-checkbox-group/LargeCheckboxGroup.component"
import {
    Platform,
    rolePlatforms,
    platformIconDict,
    platformLabelDict,
} from "../../../services/shared/Platform"
import { Switch } from "../../../components/switch/Switch.component"
import { useCreateServiceAccountModal } from "../modal/UseCreateServiceAccountModal.hook"
import { FormButtons } from "../../../components/form/form-buttons/FormButtons.component"
import { ErrorBanners } from "../../../components/banner/Banner.component"
import { useGetDevices } from "../../../services/Device.service"
import { MenuItemProps } from "../../../components/menu/menu-item/MenuItem.component"
import { MenuButton } from "../../../components/menu/menu-button/MenuButton.component"
import { useGetUsers } from "../../../services/User.service"
import styles from "./RolesForm.module.scss"

interface RolesFormProps {
    value: EditableRoleFields
    onChange?: (updatedValue: EditableRoleFields) => void
    disabled?: boolean
    isEdit?: boolean
    onCancel?: () => void
    onSubmit?: (value: EditableRoleFields) => void
    errors?: ReactNode[]
}

export function RolesForm(props: RolesFormProps) {
    const ls: LocalizationService = new LocalizationService()

    const [emailSearch, setEmailSearch] = useDebouncedState<string>("")
    const [serialNumberSearch, setSerialNumberSearch] = useDebouncedState<string>("")
    const [serviceAccountsSearch, setServiceAccountsSearch] = useDebouncedState<string>("")

    const groupNamesQuery = useGetGroupNames()
    const usersQuery = useGetUsers({
        skip: 0,
        limit: 10,
        filterModel: {
            email: { filter: emailSearch },
        },
    })
    const serviceAccountsQuery = useGetServiceAccounts({
        skip: 0,
        limit: 10,
        filterModel: {
            name: { filter: serviceAccountsSearch },
        },
    })
    const devicesQuery = useGetDevices({
        skip: 0,
        limit: 10,
        filterModel: { serialNumber: { filter: serialNumberSearch } },
    })

    const usersOptions = useMemo(() => {
        return (
            usersQuery.data?.data.map((user) => {
                return {
                    label: user.email,
                    value: user.email,
                }
            }) || []
        )
    }, [usersQuery.data])

    const groupsOptions = useMemo(() => {
        return (
            groupNamesQuery.data?.map((group) => {
                return {
                    label: group,
                    value: group,
                }
            }) || []
        )
    }, [groupNamesQuery.data])

    const deviceOwnershipOptions: CheckboxGroupOption[] = useMemo(() => {
        return allDeviceOwnership.map((o) => {
            return {
                id: o,
                label: ls.getString(deviceOwnershipLabelDict[o]),
            }
        })
    }, [])

    const platformOptions: LargeCheckboxGroupOption[] = useMemo(() => {
        return rolePlatforms.map((platform: Platform) => {
            return {
                id: platform,
                label: ls.getString(platformLabelDict[platform]),
                icon: platformIconDict[platform],
            }
        })
    }, [])

    const serialNumberOptions = useMemo(() => {
        return (
            devicesQuery.data?.data?.map((device) => {
                return {
                    label: device.serialNumber,
                    value: device.serialNumber,
                }
            }) || []
        )
    }, [devicesQuery.data])

    const serviceAccountOptions = useMemo(() => {
        return (
            serviceAccountsQuery.data?.data.map((serviceAccount) => ({
                label: serviceAccount.name,
                value: serviceAccount.name,
            })) || []
        )
    }, [serviceAccountsQuery.data])

    const apiErrors: string[] = [groupNamesQuery, usersQuery, serviceAccountsQuery, devicesQuery]
        .filter((query) => query.error)
        .map((query) => String(query.error))

    const [selectedRules, setSelectedRules] = useState<string[]>([])

    const rules: Record<string, RoleAttribute> = {
        email: {
            key: "email",
            label: ls.getString("byEmail"),
            onClick: () => onAddRule("email"),
            onRemove: () => props.onChange?.({ ...props.value, emails: [] }),
        },
        group: {
            key: "group",
            label: ls.getString("byGroup"),
            onClick: () => onAddRule("group"),
            onRemove: () => props.onChange?.({ ...props.value, groups: [] }),
        },
        ownership: {
            key: "ownership",
            label: ls.getString("byDeviceOwnership"),
            onClick: () => onAddRule("ownership"),
            onRemove: () => props.onChange?.({ ...props.value, deviceOwnership: [] }),
        },
        platform: {
            key: "platform",
            label: ls.getString("byPlatform"),
            onClick: () => onAddRule("platform"),
            onRemove: () => props.onChange?.({ ...props.value, platforms: [] }),
        },
        serialNumber: {
            key: "serialNumber",
            label: ls.getString("bySerialNumber"),
            onClick: () => onAddRule("serialNumber"),
            onRemove: () => props.onChange?.({ ...props.value, serialNumbers: [] }),
        },
        deviceRegistration: {
            key: "deviceRegistration",
            label: ls.getString("byDeviceRegistration"),
            onClick: () => onAddRule("deviceRegistration"),
            onRemove: () => props.onChange?.({ ...props.value, byDeviceRegistration: false }),
        },
        deviceManagement: {
            key: "deviceManagement",
            label: ls.getString("byDeviceManagement"),
            onClick: () => onAddRule("deviceManagement"),
            onRemove: () => props.onChange?.({ ...props.value, byDeviceManagement: false }),
        },
        serviceAccount: {
            key: "serviceAccount",
            label: ls.getString("byServiceAccount"),
            onClick: () => onAddRule("serviceAccount"),
            onRemove: () => props.onChange?.({ ...props.value, serviceAccounts: [] }),
        },
    }

    const createServiceAccountModal = useCreateServiceAccountModal((serviceAccount) => {
        onValueChange({ serviceAccounts: [...props.value.serviceAccounts, serviceAccount.name] })
    })

    const availableRules = useMemo(calculateAvailableRules, [props.value, selectedRules])

    function calculateAvailableRules(): RoleAttribute[] {
        const calculated: RoleAttribute[] = []

        if (
            !CollectionUtil.isTruthy(props.value.emails) &&
            !CollectionUtil.isTruthy(props.value.groups) &&
            !selectedRules.includes("email") &&
            !selectedRules.includes("group")
        ) {
            calculated.push(rules.email)
            calculated.push(rules.group)
        }

        if (
            !CollectionUtil.isTruthy(props.value.deviceOwnership) &&
            !selectedRules.includes("ownership")
        ) {
            calculated.push(rules.ownership)
        }

        if (
            !CollectionUtil.isTruthy(props.value.platforms) &&
            !selectedRules.includes("platform")
        ) {
            calculated.push(rules.platform)
        }

        if (
            !CollectionUtil.isTruthy(props.value.serialNumbers) &&
            !selectedRules.includes("serialNumber")
        ) {
            calculated.push(rules.serialNumber)
        }

        if (!props.value.byDeviceRegistration && !selectedRules.includes("deviceRegistration")) {
            calculated.push(rules.deviceRegistration)
        }

        if (!props.value.byDeviceManagement && !selectedRules.includes("deviceManagement")) {
            calculated.push(rules.deviceManagement)
        }

        if (
            !CollectionUtil.isTruthy(props.value.serviceAccounts) &&
            !selectedRules.includes("serviceAccount")
        ) {
            calculated.push(rules.serviceAccount)
        }

        const groupIdx = calculated.findIndex((item) => item.key === "group")
        if (groupIdx > -1 && groupIdx !== calculated.length - 1) {
            calculated.splice(groupIdx + 1, 0, { type: "divider" } as any)
        }

        const serviceAccountIdx = calculated.findIndex((item) => item.key === "serviceAccount")
        if (
            serviceAccountIdx > -1 &&
            serviceAccountIdx !== 0 &&
            calculated[serviceAccountIdx - 1].type !== "divider"
        ) {
            calculated.splice(serviceAccountIdx, 0, { type: "divider" } as any)
        }

        return calculated
    }

    useEffect(() => {
        const selected: string[] = []

        if (CollectionUtil.isTruthy(props.value.emails)) {
            selected.push("email")
        }

        if (CollectionUtil.isTruthy(props.value.groups)) {
            selected.push("group")
        }

        if (CollectionUtil.isTruthy(props.value.deviceOwnership)) {
            selected.push("ownership")
        }

        if (CollectionUtil.isTruthy(props.value.platforms)) {
            selected.push("platform")
        }

        if (CollectionUtil.isTruthy(props.value.serialNumbers)) {
            selected.push("serialNumber")
        }

        if (props.value.byDeviceRegistration) {
            selected.push("deviceRegistration")
        }

        if (props.value.byDeviceManagement) {
            selected.push("deviceManagement")
        }

        if (CollectionUtil.isTruthy(props.value.serviceAccounts)) {
            selected.push("serviceAccount")
        }

        setSelectedRules(selected)
    }, [props.disabled])

    function onAddRule(rule: string): void {
        if (!rules[rule]) {
            return
        }

        setSelectedRules((prev) => [...prev, rule])
    }

    function onRemoveRule(rule: string): void {
        if (!rules[rule]) {
            return
        }

        rules[rule].onRemove?.()
        setSelectedRules((prev) => [...prev].filter((r) => r !== rule))
    }

    function onValueChange(partial: Partial<EditableRoleFields>) {
        props.onChange?.({ ...props.value, ...partial })
    }

    function onSubmit(event: FormEvent): void {
        event.preventDefault()
        props.onSubmit?.(props.value)
    }

    return (
        <Form onSubmit={onSubmit} className={styles.form}>
            <ErrorBanners errors={apiErrors} />
            <Accordion label={ls.getString("generalInformation")} type="plain" open>
                <div className={styles.accordionContent}>
                    <FormRow label={ls.getString("roleName")} htmlFor={RolesFormFields.NAME}>
                        <Input
                            id={RolesFormFields.NAME}
                            required
                            disabled={props.disabled || props.isEdit}
                            value={props.value.name}
                            onChange={(evt) => onValueChange({ name: evt.target.value })}
                            pattern={PatternUtil.ROLE_NAME.source}
                            title={ls.getString("roleNameMustBeAlphanumeric")}
                        />
                    </FormRow>
                    <FormRow
                        label={ls.getString("somethingOptional", ls.getString("description"))}
                        description={ls.getString(
                            "anOptionalFieldShownInTheWebConsoleToAdminUsersOnlyForYourOwnInternalNotes"
                        )}
                    >
                        <Input
                            disabled={props.disabled}
                            value={props.value.description}
                            onChange={(evt) => onValueChange({ description: evt.target.value })}
                            title={ls.getString("roleNameMustBeAlphanumeric")}
                        />
                    </FormRow>
                </div>
            </Accordion>
            {selectedRules.length > 0 && (
                <Accordion
                    type="plain"
                    open
                    label={ls.getString("roleAttributes")}
                    tooltipInfo={ls.getString("roleAttributesDescription")}
                >
                    <div className={styles.accordionContent}>
                        {selectedRules.includes("email") && (
                            <PillMultiSelect
                                label={ls.getString("byEmail")}
                                description={ls.getString("byEmailDescription")}
                                placeholder={ls.getString("addEmailAddress")}
                                removeLabel={
                                    props.disabled ? undefined : ls.getString("removeAttribute")
                                }
                                onRemove={props.disabled ? undefined : () => onRemoveRule("email")}
                                options={usersOptions}
                                value={props.value.emails}
                                onChange={(v) => onValueChange({ emails: v })}
                                onSearchChange={setEmailSearch}
                                loading={usersQuery.isLoading}
                                disabled={props.disabled}
                                clearSelection
                                allowNewEntry
                            />
                        )}
                        {selectedRules.includes("group") && (
                            <PillMultiSelect
                                label={ls.getString("byGroup")}
                                description={ls.getString("byGroupDescription")}
                                placeholder={ls.getString("addGroups")}
                                removeLabel={
                                    props.disabled ? undefined : ls.getString("removeAttribute")
                                }
                                onRemove={props.disabled ? undefined : () => onRemoveRule("group")}
                                options={groupsOptions}
                                value={props.value.groups}
                                onChange={(v) => onValueChange({ groups: v })}
                                disabled={props.disabled}
                                allowNewEntry
                                clearSelection
                            />
                        )}
                        {selectedRules.includes("ownership") && (
                            <FormColumn
                                label={ls.getString("byDeviceOwnership")}
                                description={ls.getString("byDeviceOwnershipDescription")}
                                removeLabel={
                                    props.disabled ? undefined : ls.getString("removeAttribute")
                                }
                                onRemove={
                                    props.disabled ? undefined : () => onRemoveRule("ownership")
                                }
                            >
                                <CheckboxGroup
                                    options={deviceOwnershipOptions}
                                    value={props.value.deviceOwnership}
                                    onChange={(v: DeviceOwnership[]) =>
                                        onValueChange({ deviceOwnership: v })
                                    }
                                    disabled={props.disabled}
                                />
                            </FormColumn>
                        )}
                        {selectedRules.includes("platform") && (
                            <FormColumn
                                label={ls.getString("byPlatform")}
                                description={ls.getString("byPlatformDescription")}
                                removeLabel={
                                    props.disabled ? undefined : ls.getString("removeAttribute")
                                }
                                onRemove={
                                    props.disabled ? undefined : () => onRemoveRule("platform")
                                }
                            >
                                <LargeCheckboxGroup
                                    options={platformOptions}
                                    value={props.value.platforms}
                                    onChange={(v: Platform[]) => onValueChange({ platforms: v })}
                                    disabled={props.disabled}
                                />
                            </FormColumn>
                        )}
                        {selectedRules.includes("serialNumber") && (
                            <PillMultiSelect
                                disabled={props.disabled}
                                label={ls.getString("bySerialNumber")}
                                description={ls.getString("bySerialNumberDescription")}
                                placeholder={ls.getString("addSerialNumber")}
                                options={serialNumberOptions}
                                onChange={(v) => onValueChange({ serialNumbers: v })}
                                value={props.value.serialNumbers}
                                allowNewEntry
                                onSearchChange={setSerialNumberSearch}
                                loading={devicesQuery.isLoading}
                                clearSelection
                                removeLabel={
                                    props.disabled ? undefined : ls.getString("removeAttribute")
                                }
                                onRemove={
                                    props.disabled ? undefined : () => onRemoveRule("serialNumber")
                                }
                            />
                        )}
                        {selectedRules.includes("deviceRegistration") && (
                            <FormRow
                                label={ls.getString("byDeviceRegistration")}
                                description={ls.getString("byDeviceRegistrationDescription")}
                                removeLabel={
                                    props.disabled ? undefined : ls.getString("removeAttribute")
                                }
                                onRemove={
                                    props.disabled
                                        ? undefined
                                        : () => onRemoveRule("deviceRegistration")
                                }
                            >
                                <Switch
                                    disabled={props.disabled}
                                    value={props.value.byDeviceRegistration}
                                    onChange={(v) => onValueChange({ byDeviceRegistration: v })}
                                />
                            </FormRow>
                        )}
                        {selectedRules.includes("deviceManagement") && (
                            <FormRow
                                label={ls.getString("byDeviceManagement")}
                                description={ls.getString("byDeviceManagementDescription")}
                                removeLabel={
                                    props.disabled ? undefined : ls.getString("removeAttribute")
                                }
                                onRemove={
                                    props.disabled
                                        ? undefined
                                        : () => onRemoveRule("deviceManagement")
                                }
                            >
                                <Switch
                                    disabled={props.disabled}
                                    value={props.value.byDeviceManagement}
                                    onChange={(v) => onValueChange({ byDeviceManagement: v })}
                                />
                            </FormRow>
                        )}
                        {selectedRules.includes("serviceAccount") && (
                            <PillMultiSelect
                                disabled={props.disabled}
                                label={ls.getString("byServiceAccount")}
                                description={ls.getString("byServiceAccountDescription")}
                                placeholder={ls.getString("attachServiceAccount")}
                                options={serviceAccountOptions}
                                value={props.value.serviceAccounts}
                                onChange={(v) => onValueChange({ serviceAccounts: v })}
                                onSearchChange={setServiceAccountsSearch}
                                staticItem={{
                                    label: ls.getString("createServiceAccount"),
                                    icon: faPlus,
                                    onClick: createServiceAccountModal,
                                }}
                                allowNewEntry
                                clearSelection
                                loading={serviceAccountsQuery.isLoading}
                                removeLabel={
                                    props.disabled ? undefined : ls.getString("removeAttribute")
                                }
                                onRemove={
                                    props.disabled
                                        ? undefined
                                        : () => onRemoveRule("serviceAccount")
                                }
                            />
                        )}
                    </div>
                </Accordion>
            )}
            {!props.disabled && availableRules.length > 0 && (
                <MenuButton
                    className={styles.addAttributeButton}
                    icon={faPlus}
                    label={ls.getString("addRoleAttribute")}
                    menuItems={availableRules}
                />
            )}
            {!props.disabled && (
                <FormButtons
                    rightButtons={
                        props.onCancel && (
                            <>
                                <Button
                                    asElement={ButtonElement.BUTTON}
                                    buttonType={ButtonType.SECONDARY}
                                    onClick={props.onCancel}
                                >
                                    {ls.getString("cancel")}
                                </Button>
                                <Button
                                    asElement={ButtonElement.BUTTON}
                                    buttonType={ButtonType.PRIMARY}
                                    type="submit"
                                >
                                    {ls.getString("save")}
                                </Button>
                            </>
                        )
                    }
                >
                    {props.errors && <ErrorBanners errors={props.errors}></ErrorBanners>}
                </FormButtons>
            )}
        </Form>
    )
}

interface RoleAttribute extends MenuItemProps {
    key: string
    onRemove: () => void
}

enum RolesFormFields {
    NAME = "roleName",
}
