import React from "react"

import styles from "./AccessTierInstallationInstruction.module.scss"

interface Props {
    title: string
    children?: React.ReactNode
}

export function AccessTierInstallationInstruction(props: Props): JSX.Element {
    return (
        <div>
            <div className={styles.title}>{props.title}</div>
            <div className={styles.children}>{props.children}</div>
        </div>
    )
}
