import { IconDefinition } from "@fortawesome/fontawesome-common-types"
import { faTimes } from "@fortawesome/pro-regular-svg-icons"
import classNames from "classnames"
import React, { useMemo } from "react"
import { LocalizationService } from "../../../pre-v3/services"
import { useCallbackRef } from "../../../pre-v3/utils/UseCallbackRef"
import { useFallbackState } from "../../../pre-v3/utils/UseFallbackState.hook"
import { Button } from "../button/Button.component"
import { IconButton } from "../icon-button/IconButton.component"
import { Icon } from "../Icon/Icon.component"
import { MenuItemProps } from "../menu/menu-item/MenuItem.component"
import { MenuPopover } from "../menu/menu-popover/MenuPopover.component"
import { SearchableMenu } from "../menu/searchable-menu/SearchableMenu.component"
import styles from "./MenuSelect.module.scss"

export function MenuSelect(props: MenuSelectProps) {
    const {
        options,
        additionalOption,
        disabled,
        icon,
        label,
        required,
        noDataMessage,
        searchPlaceholder,
        buttonClassName,
    } = props

    const localization: LocalizationService = new LocalizationService()

    const [ref, anchorEl] = useCallbackRef<HTMLButtonElement>()
    const [selectedValue, setSelectedValue] = useFallbackState<string>(
        props.defaultValue || "",
        props.value,
        props.onSelect
    )
    const selectedLabel = useMemo(() => {
        if (selectedValue) {
            const selectedOption = options.find((option) => {
                if (option.type !== "divider") {
                    return option.value === selectedValue
                }

                return false
            })

            if (selectedOption) {
                return selectedOption.label
            } else {
                console.error(
                    "Error: MenuSelect - value does not exist in options",
                    selectedValue,
                    options
                )
            }
        }

        return ""
    }, [selectedValue, options])

    function onSelect(option: SelectableOption): void {
        setSelectedValue(option.value)
    }

    function onClear(): void {
        setSelectedValue("")
    }

    const menuItems: MenuItemProps[] = useMemo(() => {
        const filteredOption = selectedValue
            ? options.filter((option: MenuSelectOption) => {
                  if (option.type === "divider") {
                      return true
                  } else {
                      return option.value !== selectedValue
                  }
              })
            : options

        return filteredOption.map((option: MenuSelectOption) => {
            return {
                type: option.type || "button",
                label: option.label,
                onClick: option.type === "divider" ? undefined : () => onSelect(option),
            }
        })
    }, [options, selectedValue])

    const staticItem: MenuItemProps | undefined = useMemo(() => {
        return additionalOption
            ? {
                  type: "button",
                  label: additionalOption.label,
                  icon: additionalOption.icon,
                  onClick: () => additionalOption?.onClick?.(),
              }
            : undefined
    }, [additionalOption])

    return (
        <>
            {selectedLabel ? (
                <div className={styles.container}>
                    <div
                        className={classNames(styles.label, {
                            [styles.clickable]: !disabled,
                        })}
                        ref={ref as any}
                    >
                        <Icon icon={icon} />
                        {selectedLabel}
                    </div>
                    <IconButton
                        aria-label={localization.getString("remove")}
                        icon={faTimes}
                        onClick={onClear}
                        action
                        style={{
                            visibility: disabled ? "hidden" : "visible",
                        }}
                    />
                </div>
            ) : (
                <>
                    <div className={styles.menuSelectWrapper}>
                        <Button
                            className={buttonClassName}
                            ref={ref}
                            icon={icon}
                            disabled={disabled}
                        >
                            {label}
                        </Button>
                        <input
                            className={styles.fakeInput}
                            disabled={disabled}
                            value={selectedValue}
                            required={required}
                            onChange={() => {}}
                        />
                    </div>
                </>
            )}
            {!disabled && (
                <MenuPopover anchorEl={anchorEl}>
                    <SearchableMenu
                        items={menuItems}
                        noDataMessage={noDataMessage}
                        staticItem={staticItem}
                        searchPlaceholder={searchPlaceholder}
                        autoFocus
                    />
                </MenuPopover>
            )}
        </>
    )
}

export interface MenuSelectProps {
    options: MenuSelectOption[]
    defaultValue?: string
    value?: string
    onSelect?: (value: string) => void
    disabled?: boolean
    required?: boolean
    icon: IconDefinition
    label: string
    buttonClassName?: string

    noDataMessage?: string
    searchPlaceholder?: string

    additionalOption?: {
        onClick?: () => void
        icon?: IconDefinition
        label?: string
    }
}

interface DividerOption {
    type: "divider"
    label: string
}

interface SelectableOption {
    type?: Exclude<MenuItemProps["type"], "divider">
    label: string
    value: string
}
export type MenuSelectOption = SelectableOption | DividerOption
