import React from "react"

import {
    BrowserVersionFactor,
    ChromeBrowserVersionFactor,
} from "../../../../../../../services/TrustProfile.service"
import { IconDefinition, faChrome } from "@fortawesome/free-brands-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { useServiceLocalization } from "../../../../../../../../pre-v3/services"
import styles from "./OperatingSystem.module.scss"
import classNames from "classnames/bind"
import { Input } from "../../../../../../../components/input/Input.component"
import { LanguageKey } from "../../../../../../../../pre-v3/services/localization/languages/en-US.language"
import { AppText } from "../../../../../../../components/app-text/AppText.component"
import { InfoIcon } from "../../../../../../../components/info-icon/InfoIcon.component"

interface Props {
    trustFactor: ChromeBrowserVersionFactor //We are configuring only chrome in this release. Will be updated to accomodate more browsers
    className?: string
    onEdit(trustFactor: ChromeBrowserVersionFactor): void
    disabled?: boolean
}

export function BrowserVersion(props: Props): JSX.Element {
    const ls = useServiceLocalization()

    const selectedBrowsers = Object.values(BrowserVersionFactor)

    function onEdit(browser: BrowserVersionFactor) {
        return (value: string) => {
            props.onEdit({
                ...props.trustFactor,
                config: {
                    ...props.trustFactor.config,
                    [browser]: value,
                },
            })
        }
    }

    return (
        <div className={classNames(styles.outerContainer, props.className)}>
            <AppText ls="selectBrowserVersionMsg" className={styles.selectedPlatformsDesc} />
            {selectedBrowsers.map((browser) => {
                return (
                    <RenderBrowserInput
                        icon={iconDict[browser]}
                        platform={browser}
                        description={ls.getString(descriptionDict[browser])}
                        placeholder={ls.getString(placeholderDict[browser])}
                        onChange={onEdit(browser)}
                        value={props.trustFactor.config?.[browser]}
                        disabled={props.disabled}
                        key={browser}
                    />
                )
            })}
        </div>
    )
}

interface BrowserInputProps {
    platform: BrowserVersionFactor
    icon?: IconDefinition
    onChange: (value: string) => void
    value: string
    disabled?: boolean
    description: string
    placeholder: string
}

function RenderBrowserInput(props: BrowserInputProps) {
    const ls = useServiceLocalization()

    return (
        <div className={styles.container}>
            {props.icon ? (
                <FontAwesomeIcon icon={props.icon} className={styles.icon} fixedWidth />
            ) : (
                <label className={styles.label}>{ls.getString(labelDict[props.platform])}</label>
            )}
            <div className={styles.platform}>
                {ls.getString(labelDict[props.platform])}
                <InfoIcon tooltip={props.description} />
            </div>
            <span>{ls.getString("greaterThanOrEqualToSign")}</span>
            <Input
                className={styles.select}
                value={props.value}
                onChange={(e) => {
                    props.onChange(e.target.value)
                }}
                disabled={props.disabled}
                placeholder={props.placeholder}
            />
        </div>
    )
}

const labelDict: Record<BrowserVersionFactor, LanguageKey> = {
    [BrowserVersionFactor.CHROME]: "chrome",
}

const descriptionDict: Record<BrowserVersionFactor, LanguageKey> = {
    [BrowserVersionFactor.CHROME]: "chromeBrowserVersionFormat",
}

const placeholderDict: Record<BrowserVersionFactor, LanguageKey> = {
    [BrowserVersionFactor.CHROME]: "chromeBrowserVersionPlaceholder",
}

export const iconDict: Record<BrowserVersionFactor, IconDefinition> = {
    [BrowserVersionFactor.CHROME]: faChrome,
}
