import { faExclamation } from "@fortawesome/pro-solid-svg-icons"
import React from "react"

import {
    Button,
    ButtonElement,
    ButtonType,
} from "../../../../../../components/button/Button.component"
import {
    LoadMask,
    LargeMessage,
    Select,
    Form,
    ErrorBanner,
    InfoBanner,
    TransferList,
    FormColumns,
    FormRow,
    FormLabel,
} from "../../../../../components"
import styles from "./EditServiceBundle.module.scss"
import { Input } from "../../../../../../v3/components/input/Input.component"

export default function () {
    return (
        <div>
            {this.state.loading && <LoadMask />}
            {!this.state.loading && !this.state.serviceBundle && (
                <LargeMessage icon={faExclamation} className={styles.missingMessage}>
                    {this.localizationService.getString("serviceBundleNotFound")}
                </LargeMessage>
            )}
            {this.state.serviceBundle && (
                <Form onSubmit={this.onSubmit} floating display="grid" labelWidth={175}>
                    <FormColumns right={<h2>{this.state.serviceBundle.name}</h2>} />
                    <FormRow>
                        {this.state.error && <ErrorBanner>{this.state.error}</ErrorBanner>}
                        {this.state.success && <InfoBanner>{this.state.success}</InfoBanner>}
                    </FormRow>
                    <FormLabel title={this.localizationService.getString("name")} htmlFor="name">
                        <Input
                            defaultValue={this.state.serviceBundle.name}
                            className={styles.formInput}
                            required
                            placeholder={this.localizationService.getString("bundleName")}
                            id="name"
                            name="name"
                        />
                    </FormLabel>
                    <FormLabel
                        title={this.localizationService.getString(
                            "somethingOptional",
                            this.localizationService.getString("description")
                        )}
                        htmlFor="description"
                    >
                        <Input
                            defaultValue={this.state.serviceBundle.desc}
                            className={styles.formInput}
                            placeholder={this.localizationService.getString("description")}
                            id="description"
                            name="description"
                        />
                    </FormLabel>
                    <FormLabel
                        title={this.localizationService.getString("bulkConnect")}
                        htmlFor="bulkConnect"
                    >
                        <Select
                            required
                            value={this.state.serviceBundle.bulkConnect}
                            options={this.bulkConnectOptions}
                            onChange={this.onBulkConnectChange}
                            id="bulkConnect"
                            name="bulkConnect"
                        />
                    </FormLabel>
                    <FormLabel
                        title={this.localizationService.getString("services")}
                        htmlFor="services"
                    >
                        <TransferList
                            id="services"
                            name="services"
                            required={true}
                            options={this.state.services}
                            onChange={this.onServiceChange}
                            value={this.state.serviceIds}
                        />
                    </FormLabel>
                    <FormColumns
                        right={
                            <div className={styles.buttons}>
                                <Button
                                    className={styles.deleteButton}
                                    onClick={this.onOpenDeleteModal}
                                    asElement={ButtonElement.BUTTON}
                                    buttonType={ButtonType.DESTRUCTIVE}
                                >
                                    {this.localizationService.getString("delete")}
                                </Button>
                                <Button
                                    className={styles.submitButton}
                                    type="submit"
                                    asElement={ButtonElement.BUTTON}
                                    buttonType={ButtonType.PRIMARY}
                                >
                                    {this.localizationService.getString("update")}
                                </Button>
                            </div>
                        }
                    />
                </Form>
            )}
        </div>
    )
}
