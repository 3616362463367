import React, { useEffect, useState } from "react"
import { useServiceLocalization } from "../../../../../pre-v3/services/localization/Localization.service"
import { Accordion } from "../../../../components/accordion/Accordion.component"
import { FormRow } from "../../../../components/form/FormRow.component"
import { Input } from "../../../../components/input/Input.component"
import { SelectInput } from "../../../../components/select-input/SelectInput.component"
import {
    getServiceAccountTypes,
    ServiceAccountAccess,
    WebService,
} from "../../../../services/HostedService.service"
import styles from "./AccessPermissions.module.scss"

interface Props {
    initialValue?: WebService
    onChange?: (data: ServiceAccountAccess) => void
    disabled?: boolean
}

export function ServiceAccountAccessContainer(props: Props) {
    const { initialValue, disabled } = props
    const localization = useServiceLocalization()

    const [serviceAccountAccess, setServiceAccountAccess] = useState<ServiceAccountAccess>(
        initialValue?.serviceAccountAccess || { type: "authorization", enabled: false, value: "" }
    )

    useEffect(() => {
        props.onChange?.(serviceAccountAccess)
    }, [serviceAccountAccess])

    return (
        <Accordion
            open={serviceAccountAccess.enabled}
            disabled={disabled}
            type="switch"
            label={localization.getString("enableServiceAccountAccess")}
            onToggle={(value) =>
                setServiceAccountAccess({ ...serviceAccountAccess, enabled: value })
            }
            children={
                <div className={styles.container}>
                    <FormRow
                        label={localization.getString(
                            "howShouldTheServiceAccountCredentialsBeSent"
                        )}
                    >
                        <SelectInput
                            disabled={disabled}
                            options={getServiceAccountTypes()}
                            onChange={(accessType) =>
                                setServiceAccountAccess({
                                    ...serviceAccountAccess,
                                    type: accessType,
                                })
                            }
                            value={serviceAccountAccess.type}
                        />
                    </FormRow>
                    {serviceAccountAccess.type !== "authorization" && (
                        <FormRow
                            label={
                                serviceAccountAccess.type === "custom"
                                    ? localization.getString("headerName")
                                    : localization.getString("parameterName")
                            }
                        >
                            <Input
                                type="text"
                                placeholder={
                                    serviceAccountAccess.type === "custom"
                                        ? localization.getString("headerName")
                                        : localization.getString("parameterName")
                                }
                                className={styles.formInput}
                                value={serviceAccountAccess.value}
                                onChange={(e) =>
                                    setServiceAccountAccess({
                                        ...serviceAccountAccess,
                                        value: e.target.value,
                                    })
                                }
                                disabled={disabled}
                            />
                        </FormRow>
                    )}
                </div>
            }
        />
    )
}
