import { faCheckSquare, faSquare } from "@fortawesome/pro-regular-svg-icons"
import classNames from "classnames/bind"
import React, { ChangeEvent, useEffect, useState } from "react"

import { Icon } from "../Icon/Icon.component"
import styles from "./Checkbox.module.scss"

export function Checkbox(props: CheckboxProps) {
    const {
        children,
        defaultChecked,
        checked: originalChecked,
        onChange: originalOnChange,
        iconClassName,
        ...inputProps
    } = props

    const [checked, setChecked] = useState<boolean>(!!originalChecked || !!defaultChecked)

    useEffect(() => {
        if (originalChecked !== undefined) {
            setChecked(!!originalChecked)
        }
    }, [originalChecked])

    function onChange(event: ChangeEvent<HTMLInputElement>) {
        originalOnChange?.(event.target.checked)

        if (originalChecked === undefined) {
            setChecked(!checked)
        }
    }

    return (
        <label
            className={classNames(
                styles.container,
                { [styles.disabled]: props.disabled },
                props.className
            )}
        >
            <input
                {...inputProps}
                className={styles.htmlInput}
                type="checkbox"
                checked={originalChecked}
                onChange={onChange}
            />
            <Icon icon={checked ? faCheckSquare : faSquare} className={iconClassName} />
            {children}
        </label>
    )
}

export interface CheckboxProps
    extends Omit<React.InputHTMLAttributes<HTMLInputElement>, "onChange" | "hidden" | "type"> {
    children?: React.ReactNode
    defaultChecked?: boolean
    checked?: boolean
    onChange?: (checked: boolean) => void
    disabled?: boolean
    iconClassName?: string
}
