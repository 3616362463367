import { IconProp } from "@fortawesome/fontawesome-svg-core"
import { faApple, faWindows, faLinux, faAndroid } from "@fortawesome/free-brands-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import React from "react"

import styles from "./BaseAvailablePlatforms.module.scss"
import { faBrowser } from "@fortawesome/pro-regular-svg-icons"

export interface Props {
    isMacOsAvailable: boolean
    isWindowsAvailable: boolean
    isLinuxAvailable: boolean
    isIosAvailable: boolean
    isAndroidAvailable: boolean
    isChromeBrowserAvailable: boolean
}

/**
 * Meant only to be expanded for other contexts
 */
export function BaseAvailablePlatforms(props: Props): JSX.Element {
    return (
        <React.Fragment>
            {props.isMacOsAvailable ? <Icon icon={faApple} /> : <Bullet />}
            {props.isWindowsAvailable ? <Icon icon={faWindows} /> : <Bullet />}
            {props.isLinuxAvailable ? <Icon icon={faLinux} /> : <Bullet />}
            {props.isIosAvailable ? <span className={styles.ios}>iOS</span> : <Bullet />}
            {props.isAndroidAvailable ? <Icon icon={faAndroid} /> : <Bullet />}
            {props.isChromeBrowserAvailable ? <Icon icon={faBrowser} /> : <Bullet />}
        </React.Fragment>
    )
}

interface IconProps {
    icon: IconProp
}

function Icon(props: IconProps): JSX.Element {
    return <FontAwesomeIcon {...props} className={styles.logo} />
}

function Bullet(): JSX.Element {
    return <span className={styles.bullet}>•</span>
}
