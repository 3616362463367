import { Singleton } from "../../pre-v3/decorators/Singleton.decorator"
import { UrlUtil } from "../../pre-v3/utils/Url.util"
import { BaseApi, PaginatedResponse, PaginationOptions } from "./Base.api"

export const PRIVATE_RESOURCE_NOT_FOUND = "PRIVATE_RESOURCE_NOT_FOUND"

@Singleton("PrivateResourceApi")
export class PrivateResourceApi extends BaseApi {
    public getPrivateResources(
        getPrivateResourcesParams: Partial<GetPrivateResourcesParams> = {}
    ): Promise<PaginatedResponse<"resources", PrivateResourceRes>> {
        const params = `?${UrlUtil.convertToURLSearchParams(getPrivateResourcesParams).toString()}`

        return this.get(`/api/v2/tunnel_resource${params}`, {
            default: this.localization.getString(
                "failedToGetSomething",
                this.localization.getString("privateResources")
            ),
        })
    }

    public getPrivateResourceById(id: string): Promise<PrivateResourceDetailsRes> {
        return this.get(`/api/v2/tunnel_resource/${id}`, {
            400: PRIVATE_RESOURCE_NOT_FOUND,
            default: this.localization.getString(
                "failedToGetSomething",
                this.localization.getString("privateResource")
            ),
        })
    }

    public getServiceTunnelsByPrivateResourceId(
        id: string,
        getServiceTunnelsParams: Partial<GetServiceTunnelsParams> = {}
    ): Promise<PaginatedResponse<"service_tunnels", ServiceTunnelDetailsRes>> {
        const params = `?${UrlUtil.convertToURLSearchParams(getServiceTunnelsParams).toString()}`

        return this.get(`/api/v2/tunnel_resource/${id}/service_tunnels${params}`, {
            404: this.localization.getString(
                "somethingNotFound",
                this.localization.getString("privateResource")
            ),
            default: this.localization.getString(
                "failedToGetSomething",
                this.localization.getString("serviceTunnels")
            ),
        })
    }

    public getAccessActivitiesByPrivateResourceId(
        id: string,
        getAccessActivitiesParams: Partial<GetAccessActivitiesParams> = {}
    ): Promise<PaginatedResponse<"user_device_info", AccessActivityRes>> {
        const params = `?${UrlUtil.convertToURLSearchParams(getAccessActivitiesParams).toString()}`

        return this.get(`/api/v2/tunnel_resource/${id}/access_activity${params}`, {
            404: this.localization.getString(
                "somethingNotFound",
                this.localization.getString("privateResource")
            ),
            default: this.localization.getString(
                "failedToGetSomething",
                this.localization.getString("accessActivities")
            ),
        })
    }

    public getResourceIpsByPrivateResourceId(
        id: string,
        getResourceIpsParams: Partial<GetResourceIpsParams> = {}
    ): Promise<PaginatedResponse<"ip", ResourceIpRes>> {
        const params = `?${UrlUtil.convertToURLSearchParams(getResourceIpsParams).toString()}`

        return this.get(`/api/v2/tunnel_resource/${id}/ips${params}`, {
            404: this.localization.getString(
                "somethingNotFound",
                this.localization.getString("privateResource")
            ),
            default: this.localization.getString(
                "failedToGetSomething",
                this.localization.getString("resourceIps")
            ),
        })
    }
}

export type PrivateResourceOrderByReq =
    | "unique_count"
    | "address"
    // TODO: Remove once we remove Tunnel Inventory
    // https://banyan-sec.atlassian.net/browse/BC-10405
    | "status"
    | "network_name"
    | "updated_at"

export interface GetPrivateResourcesParams extends PaginationOptions<PrivateResourceOrderByReq> {
    network_id: string[]
    service_tunnel_id: string[]
    enduser_id: string[]
    address: string[]
}

export interface PrivateResourceRes {
    id: string
    address: string
    network: NetworkRes
    service_tunnel: ServiceTunnelRes[]
    unique_count: number
    updated_at: number
}

export interface PrivateResourceDetailsRes {
    id: string
    address: string
    network: NetworkRes
    // TODO: Remove once we remove Tunnel Inventory
    // https://banyan-sec.atlassian.net/browse/BC-10405
    status: "discovered" | "ignore" | "published"
    discovered_at: number
    last_updated_at: number
}

export type NetworkTypeRes = "access_tier" | "connector"

interface NetworkRes {
    network_id: string
    network_name: string
    network_type: NetworkTypeRes
}

interface ServiceTunnelRes {
    id: string
    name: string
}

export type ServiceTunnelOrderByReq = "service_tunnel_name"

export interface GetServiceTunnelsParams extends PaginationOptions<ServiceTunnelOrderByReq> {
    service_tunnel_id: string[]
    dst_port: number[]
    protocol: string[]
}

export interface ServiceTunnelDetailsRes {
    id: string
    name: string
    dst_ports: number[]
    protocols: string[]
}

export type AccessActivityOrderByReq = "user_name" | "device_name" | "updated_at"

export interface GetAccessActivitiesParams extends PaginationOptions<AccessActivityOrderByReq> {
    email: string[]
    serial_number: string[]
    start_time: number
    end_time: number
    // TODO: Remove once we remove Tunnel Inventory
    // https://banyan-sec.atlassian.net/browse/BC-10405
    user_name: string
    device_name: string
}

export interface AccessActivityRes {
    enduser: {
        enduser_id: string
        user_name: string
        email: string
        // TODO: Remove once we remove Tunnel Inventory
        // https://banyan-sec.atlassian.net/browse/BC-10405
        group_name: string
        role_names: string
    }
    device: {
        device_id: string
        device_name: string
        serialnumber: string
    }
    updated_at: number
}

export type ResourceIpOrderByReq = "dst_ip" | "updated_at"

export interface GetResourceIpsParams extends PaginationOptions<ResourceIpOrderByReq> {
    dst_ip: string[]
    service_tunnel_id: string[]
}

export interface ResourceIpRes {
    dst_ip: string
    last_seen_at: number
    service_tunnel: ServiceTunnelRes[]
}
